import React, { Component } from "react";

class CityWiseAnalytics extends Component {
  render() {
    return (
      <div>
           {console.log("aaaaaaaa", this.props.data)} 
           {this.props.data.length > 0 ?
           <div>
          {this.props.data.map((data) => (
            <>
              <div className="row mt-1 mb-1">
                <div className="col-md-12 mt-1 mb-1 cityName">
                  {data.city}
                </div>
                {data.details.map((details) => (
                  <>
                    {details.name === "NewUsers" ||
                      details.name === "TotalUsers" ||
                      details.name === "BounceRate" ? (
                      <>
                        <div className="col-md-4 userListLabeltext">
                          {details.name}
                        </div>
                        <div className="col-md-1 userListLabeltext colonDiv" style={{padding:'0px', textAlign:'right'}}>
                          :
                        </div>
                        <div className="col-md-5 userListLabeltextBolds">
                          {details.name === "BounceRate"
                            ? parseFloat(details.val).toFixed(2) * 100 +
                            "%"
                            : details.val}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ))}
              </div>
              {/* <hr /> */}
            </>
          ))}
          </div>
    :'No data found'}
         </div>
    );
  }
}
export default CityWiseAnalytics;
