import React, { Component } from "react";
import * as Icon from "react-feather";
import Dashboardimage from "../images/left_icons/dashboard.png";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
class MapUnmap extends Component {
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper mapunmap_div">
          <Header heading="Map / Unmap Accounts" headerImage={Dashboardimage} />
          <div className="container">
          <Tabs>
    <TabList>
      <Tab>Map Accounts</Tab>
      <Tab>UnMap Accounts</Tab>
    </TabList>
    <TabPanel>
        <div className="row">
        <div className="col-md-4 location_search">
            <input className="form-control" type="text" placeholder="Auto complete" />
            <div className="location_search_drop">
                <ul class="nav flex-column">
                <li class="nav-item">
                    <span>Xilligence</span>
                </li>
                <li class="nav-item">
                    <span>Axis Bank</span>
                </li>
                <li class="nav-item">
                    <span>Lokal</span>
                </li>
                </ul>
            </div>
        </div>
        <div className="col-md-2 text-center mb-3">
            <div className="border_center_div">Map to</div>
        </div>
        <div className="col-md-4 location_search">
            <input className="form-control" type="text" placeholder="Auto complete" />
            <div className="location_search_drop">
            <ul class="nav flex-column">
                <li class="nav-item">
                    <span>Xilligence Pvt Ltd </span>
                </li>
                <li class="nav-item">
                    <span>Axis Bank</span>
                </li>
                <li class="nav-item">
                    <span>Lokal</span>
                </li>
                </ul>
            </div>
        </div>
        <div className="col-md-2 text-right">
            <button type="button" className="btn-search">Map<Icon.ArrowDown width="16" height="16" className="ml-2"/></button>
        </div>
        </div>
        <div className="col-md-12 tableResponsive">
            <div className="tableOuterWidth table_mapwidth">
                <table className="table table_width_md">
                    <thead>
                    <tr>
                    <th className="col-6">Account</th>
                    <th className="col-6">Mapped to</th>
                    </tr>
                    </thead>
                    <div className="table_Outerscroll"  style={{overflow: "overlay"}}>
                    <tbody  className="table_Outertbody">
                        <tr>
                            <td className="col-6">Xilligence</td>
                            <td className="col-6">Xilligence Pvt Ltd</td>
                        </tr>
                    </tbody>
                    <tbody  className="table_Outertbody">
                        <tr>
                            <td className="col-6">Axis</td>
                            <td className="col-6">Axis Bank</td>
                        </tr>
                    </tbody>
                    <tbody  className="table_Outertbody">
                        <tr>
                            <td className="col-6">Local</td>
                            <td className="col-6">Local</td>
                        </tr>
                    </tbody>
                    </div>
                </table>
            </div>
        </div>
    </TabPanel>
    <TabPanel>
    <div className="row">
        <div className="col-md-4 location_search">
            <input className="form-control" type="text" placeholder="Auto complete" />
            <div className="location_search_drop">
                <ul class="nav flex-column">
                <li class="nav-item">
                    <span>Xilligence</span>
                </li>
                <li class="nav-item">
                    <span>Axis Bank</span>
                </li>
                </ul>
            </div>
        </div>
        <div className="col-md-2 ml-auto">
            <button type="button" className="btn-search">UnMap<Icon.ArrowUp width="16" height="16" className="ml-2"/></button>
        </div>
        </div>
    </TabPanel>
  </Tabs>
        
          </div>
          <Footer/>
        </div>

      </div>
    );
  }
}

export default MapUnmap;
