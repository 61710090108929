import React, { Component } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Dashboardimage from "../images/left_icons/business.png";
import * as Icon from "react-feather";
import Footer from "../layout/footer";
class TermsCondition extends Component {
   goBack = () => {
      window.history.back()
          }
  render() {
    return (
        <div className="main_wrapper">
        {/* <LeftMenu /> */}
        <div className="userListWrapper">
          {/* <Header heading="Terms & Conditon" headerImage={Dashboardimage} /> */}
          <div className="container">
          <div className="main-wrapper1 terms-wrapper mt-3">
         <div  onClick={this.goBack} className="back-to-link text-success">
            <Icon.ArrowLeft />
            Back
          </div>
          <div class="terms-head pb-4">TERMS AND CONDITIONS</div>
          <div class=" terms-wrapper terms-wrapper-popup">
   <p class="terms-p-bold">This is the Terms of Service for this website or mobile app.</p>
   <p class="terms-p-bold">In addition, in order to use any of our services, users must be 18+ Years of age and Citizens of India.</p>
   <p class="terms-p-bold">General</p>
   <p>Please read this Terms of Service policy and in order to use our products and services. By use of our products and services you acknowledge that you have read, understood and have irrevocably agreed to our following Terms of Service conditions and agree to be bound to our <span class="terms-p-bold">Terms of Service</span> by contractual terms, as well as to our <span class="terms-p-bold">Privacy Policy</span>.</p>
   <p class="terms-p-bold">Definitions</p>
   <p>“We”, “Our”, “Us” - refers to the owner(s) of this website, mobile app.</p>
   <p>“You”, “Your”, “User”, “Client” - refer to the individual User of the mobile app or website and its associated services.</p>
   <p> “Affiliate”, “Partner”, “Service Provider” - refer to associated partners that we may engage with or whose services that we use such as  &amp; equipment providers, third party technology providers or any third party service providers that we may engage with in order to provide services for the User of the apps &amp; website.</p>
   <p>“Personal Information”, “Personally Identifiable Information”, “PII” - refer to information that you provide us that identifies you like:&nbsp;</p>
   <ul>
      <li>
         <p>Name, Phone Number, Home Address, Email address.</p>
      </li>
      <li>
         <p>Financial Information like Bank Accounts</p>
      </li>
   </ul>
   <p class="terms-p-bold">What this Service is For</p>
   <p class="terms-p-bold">In addition, in order to use any of our services , users must be 18+ Years of age and Citizens of India.</p>
   <p class="terms-p-bold">Partners &amp; Affiliates</p>
   <p>Any advice or recommendation is not endorsed by our technology partners, affiliates or service providers in any capacity. You acknowledge understanding and agreeing to this fact and you agree that they have no liability to you in any manner or capacity for your use of our services, regardless of outcomes.</p>
   <ul>
      <li>
         <p>Our services may provide information in the form of articles, videos, graphics or links to other websites and content. Some of the content may be provided by  professionals. Our partners do not endorse or recommend any information, as viewed on our platforms, services, websites or apps.</p>
      </li>
      <li>
         <p>The Information and Services that we provide or that of our partners, affiliates, associated service providers or licensors are provided on an "as is" and “as available’ basis, and without any warranties or conditions (express or implied, including the implied warranties of merchantability, accuracy, or fitness for a particular purpose). To the fullest extent of the law possible, they shall in no event be liable to you or any party or user in regards to any actions taken by you based on any information found on our services provided by us, other users or third parties or partners. As well, the use of any information to verify credentials or providers  is not approved by us and is prohibited.</p>
      </li>
      <li>
         <p>We or our partners and affiliates do not endorse or recommend any information on third party websites or apps that may be linked to from our service.</p>
      </li>
      <li>
         <p>You should only follow a course of action that you have discussed with a qualified  professional and not simply by following any content viewed on this service. You understand and agree to this fact. Only you are responsible for the information or advice that you follow and you agree that we or our partners are not responsible in any way for the consequences of your actions.</p>
      </li>
   </ul>
   <p class="terms-p-bold">Unapproved Uses of Our Service</p>
   <p>You agree to not copy our information, content, details of our partners, providers, details of professionals whether logged in our logged out of our service, through manual or automated means.</p>
   <p>In general you agree to not use our services in a manner other than what it is intended for - namely for you (or your family) to get access to  professionals and for viewing content on  information. No other use of our services is acceptable.</p>
   <p class="terms-p-bold">Limitations of Liability &amp; Indemnification</p>
   <p>You agree to not include our technology partners in any legal action in relation to your  any advice that you have taken or any  related action or  procedure that you have embarked on using our services.</p>
   <p>Should you add your dependent’s personal and/or  information to the services then you are responsible for the information that you provide including ensuring that you have permission to do so and that the information is correct. You agree that you are liable for misinformation, lack of permission to share any personal information of a dependent and that you indemnify us in case of any claim or liability that arises from improper or unauthorized use of such information.</p>
   <p class="terms-p-bold">You Are Responsible For Your Account</p>
   <p>You agree to keep your login, password and account details private at all times. You agree that we are not responsible for any hacking or breach of your account due to any sharing of your account or password information. You must keep your information private at all times.</p>
   <p>Furthermore, you must not use someone else’s credentials and access their account.</p>
   <p>If you suspect that someone else has your credentials, account information and is using your account, you must contact us immediately and inform us. Please email support@Tagtree.com.</p>
   <p class="terms-p-bold">Your Data and Information that You Provide</p>
   <p class="terms-p-bold">Please read our Privacy Policy for more details on how we handle your data.</p>
   <ul>
      <li>
         <p>Note that we do not sell your personal data or personal identifying data to anyone.</p>
      </li>
      <li>
         <p>You understand and acknowledge that we have no control over or have any liability as it relates to personal information  that you choose to give to a  provider or  practitioner, using our platform and services.</p>
      </li>
      <li>
         <p>You may voluntarily choose to use services such as uploading a personal record to the platform or wish to receive records such as prescriptions from the  provider that you choose to engage with. This information can be sent through email, SMS and similar means whose details you provide us and provider and practitioner for this purpose. Should you provide a wrong email or phone number to which this information is sent, we are not liable in any way for the wrong party receiving this information. It is up to you to ensure that the information that you provide is 100% accurate.</p>
      </li>
      <li>
         <p>In addition, should you change your number or give it up, you must update your information with us. You agree that we are not responsible for any issues or problems arising with you not informing us of any changes in your contact details and updating your information on the platform.</p>
      </li>
   </ul>
   <p class="terms-p-bold">Suspension or Termination of Account</p>
   <p>We reserve the right to suspend or cancel an account for any reason, including:</p>
   <ul>
      <li>
         <p>Harassment of others including  providers and  practitioners</p>
      </li>
      <li>
         <p>Sending purposely misleading information to our partner  providers or practitioners</p>
      </li>
      <li>
         <p>Using the system to surveil our partners</p>
      </li>
      <li>
         <p>Copying our Intellectual Property</p>
      </li>
      <li>
         <p>Scraping data from our services</p>
      </li>
      <li>
         <p>Using an account that does not belong to you.</p>
      </li>
      <li>
         <p>Trying to get credentials of accounts that do not belong to you.</p>
      </li>
      <li>
         <p>Any other reason that we see fit.</p>
      </li>
   </ul>
   <p>Should you wish to delete your account please email <a href="mailTo:support@purplehealth.com">support@Tagtree.com</a>.</p>
   <p class="terms-p-bold">Notifications and Communications</p>
   <ul>
      <li>
         <p>In order to remind you of appointments, booking confirmations, telehealth consultations, canceled appointments or for any reason related to our services, you agree to allow us to send you notices and information by SMS, in-app mobile push notifications, browser notifications, messages, emails or phone calls. You may manage these notifications as appropriate in any available settings in our services related to notifications or in your mobile phone or computer device &amp; browser settings.</p>
      </li>
      <li>
         <p>Should you opt out of receiving notifications, you acknowledge that you may miss important notifications such as reminders for events. In addition, should you opt out of notifications and communications, the services may not work properly or may not work at all.</p>
      </li>
      <li>
         <p>You also agree that we may send your Personal Information or Personal  Information when you use our services to consult with a provider or professional such as. We may send information via email, sms, in-app push-notification as it relates, related services which you have voluntarily expressly opted into and consented to allow. Should these communications be intercepted or viewed by someone else, while unlikely, you agree to release us from any liability arising from any interception or unauthorized access.</p>
      </li>
   </ul>
   <p class="terms-p-bold">Refund of Fees Paid</p>
   <p class="terms-p-bold">Ownership, Trademarks and Copyrights</p>
   <ul>
      <li>
         <p>All of the software, content and intellectual property is owned by us or has been licensed by us. We give users such as yourself a limited permission to use our services and access our content. This does not give any permanent access or re-sale or commercial rights to our content or services. You agree to not try to copy, sell or license our content or services or any of our intellectual property.</p>
      </li>
   </ul>
   <p class="terms-p-bold">User Generated Content</p>
   <p>There may be parts of our service or platform that allows for user generated. In those instances:</p>
   <ul>
      <li>
         <p>As a user, you agree that any user generated content shall automatically be licensed to us free of charge and free of any legal, trademark or intellectual property encumbrances and free for us to use, should we wish to use it, at any time without any required payment of any sort and in any manner that we choose. This is a perpetual, worldwide license.</p>
      </li>
      <li>
         <p>You also agree to not upload or save any trademarked content on our services or platforms and that we are not liable for any such misuse by you and that you indemnify us for any IP or trademarked content that is uploaded by you.</p>
      </li>
      <li>
         <p>You agree that we are not liable for any of the content uploaded by users. As expressed elsewhere in the Terms of Service, we express no warranties or recommend any such content to follow or believe, which has been stated in other parts of this Terms of Service.</p>
      </li>
      <li>
         <p>You agree to not upload pornography, illegal content or any other content that may harm others, damage the service or platform in any way such as malicious code or anything similar.</p>
      </li>
      <li>
         <p>You also agree that we are free to delete or modify any such content that is posted or uploaded by you.</p>
      </li>
   </ul>
   <p class="terms-p-bold">Severability</p>
   <p>If any part of the Agreement is held by a legal court, or legal arbitrator, to be unenforceable then that provision will be excluded but this shall not invalidate the rest of the agreement which shall remain and considered to be valid.</p>
   <p class="terms-p-bold">Jurisdiction and Disputes</p>
   <p>This Agreement is governed by the Laws of India and any legal dispute shall be resolved in the State of Kerala in the City of Thiruvananthapuram.</p>
   <p>The dispute resolution method shall be by arbitration for which we (or based on its approval a partner or affiliate) will appoint an arbitrator and the arbitration shall be conducted in English in accordance with the Arbitration and Conciliation Act, 1996.</p>
   <p class="terms-p-bold">We May Update This Agreement</p>
   <p>You acknowledge, understand and agree that we may update these Terms of Service from time to time and that the latest Terms of Service will be in effect. We may require you to re-accept the Terms of Use from time to time if we feel that the changes have been substantial enough to warrant that effort.</p>
</div>
</div>
</div>
          <Footer/>
        </div>
          </div>
    );
  }
}

export default TermsCondition;
