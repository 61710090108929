import React, { Component } from "react";
import "../../css/bootstrap.css";
import "../../css/style.css";
import deleteImg from "../../images/delete.png";
import rolesimage from "../../images/left_icons/roles.png";
import Checked from "../../images/checked.png";
import Edit from "../../images/edit.png";
import LeftMenu from "../../layout/leftMenu";
import { backendApi } from "../../apiService";
import { Link } from "react-router-dom";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import * as Icon from "react-feather";
import $ from "jquery";
class CategoriesSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoriesList: [],
      // roleList: [],
      // approved: false,
      // userID: window.sessionStorage.getItem("userId"),
      // isLogin: window.sessionStorage.getItem("isLogin"),
      // userRole: window.sessionStorage.getItem("role"),
      // userOrganization: window.sessionStorage.getItem("organization"),
      // organizationAllotment:
      //   window.sessionStorage.getItem("organizationAllotment") === "undefined"
      //     ? []
      //     : JSON.parse(window.sessionStorage.getItem("organizationAllotment")),
      // modules: JSON.parse(window.sessionStorage.getItem("modules")),
      // moduleActive: false,
      // organizationListActive: false,
      // moduleId: [],
      // organizationListId: [],
      // userRoleid: "",
      // userListModules: [],
      // searchValue: "",
      categoryEditName:"",
      saveOrUpdate:"Save",
      categoryId:""
    };
  }
  changeStatus(e, moduleId) {
    $("#moduleIds" + moduleId).toggleClass("active");
    let newArray = [...this.state.moduleId, moduleId];
    if (this.state.moduleId.includes(moduleId)) {
      newArray = newArray.filter((checked) => checked !== moduleId);
    }
    if (this.state.modules.length == newArray.length) {
      $("#modulesSelectAll").prop("checked", true);
    }
    this.setState({
      moduleId: newArray,
    });
  }
  saveModules() {
    $(".loder_div").attr("hidden", false);
    if(this.state.saveOrUpdate==="Save"){
    var req = { 
      "functionName": "createCategory",
      "category": this.state.categoryEditName
    };
  }else{
    var req = { 
      "functionName": "updateCategory",
      "id":this.state.categoryId,
      "category":this.state.categoryEditName
    }
    ;
  }
    backendApi(req).then((response) => {
      $(".loder_div").attr("hidden", true);
      if (response.success === "1") {
        alert("Saved successfully");
       this.removeModal();
        this.getCategoreList();
      }
    });
  }
  componentDidMount() {
    this.getCategoreList();
    $("#editCommentPopup").attr("hidden", true);
  }
  toggleModal(data,type) {
      $(".loder_div").attr("hidden", true);
          $("body").addClass("isActive");
      this.setState({
        categoryEditName:type=="Save"?"":data.category,
        saveOrUpdate:type,
        categoryId:type=="Save"?"":data.id,
      });
   
  }
  removeModal() {
    $("body").removeClass("isActive");
    $(".modules_div").removeClass("active");
    $("#modulesSelectAll").prop("checked", false);
  }
  getCategoreList() {
    $(".loder_div").attr("hidden", false);
    var req = { 
        "functionName": "CategoryFullListing"
      }
      ;
    backendApi(req).then((response) => {
      $(".loder_div").attr("hidden", true);
      if (response.success === "1") {
        this.setState({ categoriesList: response.data });
      } else {
        this.setState({ categoriesList: [] });
      }
    });
  }
  deleteCategory(data) {
    // debugger;
    $(".loder_div").attr("hidden", false);
    var req = { 
      "functionName": "deleteCategory",
      "id":data.id
    };
    backendApi(req).then((response) => {
      $(".loder_div").attr("hidden", true);
      if (response.success === "1") {
        alert("Category Deleted Successfully")
        this.getCategoreList()
      } 
    });
  }
  render() {
    return (
      <div className="main_wrapper">
        {/* {this.state.isLogin === "true" &&
        (this.state.userRole === "ROLE_SUPER_ADMIN" ||
          this.state.userRole === "ROLE_ADMIN" ||
          this.state.userRole === "ORG_ADMIN") ? ( */}
          <>
            <LeftMenu></LeftMenu>
            <div className="userListWrapper">
              {this.props.emailflag == undefined && (
                <Header heading="Categories" headerImage={rolesimage} />
              )}
              <div className="container-fluid">
                <div className="col-md-12 tableResponsive">
                <div
                    className="btn-addNew"
                    onClick={() => this.toggleModal("","Save")}
                  >
                    Add New Category{" "}
                    <span>
                      <Icon.PlusCircle />{" "}
                    </span>
                  </div>
                  
                  <div className="tableOuterWidth table_width_sm">
                    {this.state.categoriesList.length > 0 ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="col-1">Sl. No.</th>
                            <th className="col-7">
                            Categories
                            </th>
                            <th className="col-2">Edit Categories</th>
                            <th className="col-2"> Delete Categories</th>
                          </tr>
                        </thead>
                        <div class="table_Outerscroll">
                          {this.state.categoriesList.map((data, i) => (
                            <tbody className="table_Outertbody">
                              <tr>
                                <td className="col-1">{i + 1}</td>
                                <td className="col-7">
                                  &nbsp;{data.category}&nbsp;
                                </td>
                                <td className="col-2">
                                  <span>
                                    {data.is_privileged !== 1 &&
                                      data.role !== "ROLE_SUPER_ADMIN" && (
                                          <img
                                           onClick={() => this.toggleModal(data,"Update")}
                                            src={Edit}
                                            alt="delete"
                                            width="20"
                                            className="iconAction"
                                          />
                                     )}
                                  </span>
                                </td>
                                <td className="col-2">
                                  <span className="switch round"></span>
                                  <span>
                                    {/* {data.is_privileged !== 1 && */}
                                      {/* data.role !== "ROLE_SUPER_ADMIN" && ( */}
                                          <img
                                           onClick={() => this.deleteCategory(data)}
                                            src={deleteImg}
                                            alt="delete"
                                            width="20"
                                            className="iconAction"
                                          />
                                     {/* )} */}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </div>
                      </table>
                    ) : (
                      <h3>No Data Found</h3>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </>
        {/* ) : (
          ""
        )} */}
        <div className="loder_div" hidden />
        <div className="userList_modal ">
          <div className="userListmodal_inner p-2 modal-sm">
          {/* <div class="userListmodal_head">
            <span class="subhead">Categories</span>
          </div> */}
          <div className="row">
                <div className="col-md-12 m-auto">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Add Category</label>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Enter Category Name"
                      onChange={(event) => this.setState({ categoryEditName: event.target.value })}
                      value={this.state.categoryEditName}
                    />
                  </div>
                </div>
              </div>
            <div className="userlistModal_footer">
              <button
                className="btn btn-success"
                onClick={() => this.saveModules()}
              >
               {this.state.saveOrUpdate}
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => this.removeModal()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CategoriesSettings;
