import React, { useState, useEffect } from "react";
import Dashboardimage from "../images/left_icons/dashboard.png";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { apiCallingPost,backendDashboardApi } from "../apiService";
import Pagination from "react-js-pagination";
import UserCreationAdd from "./userCreationAdd";
import Select from "react-select";

function UserCreation() {
    const [zoneChangeset, setZoneChangeSet] = useState(false)
    const [stateChangeset, setStateChangeset] = useState(false)
    const [stateZoneChangeset, setStateZoneChangeSet] = useState(false)
    const [districtChangeset, setDistrictChangeset] = useState(false)
    const [addFlag, setAddFlag] = useState(false)
    const [editFlag, setEditFlag] = useState(false)
    const [userCreationList, setUserCreationList] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [dataValue, setDataValue] = useState([])
    const [activePage, setActivePage] = useState(1)
    const [totalCount, setTotalCount] = useState("")
    const [selectVerification, setSelectVerification] = useState("")
    const [verificationArray] = useState([
        {
            value: "Select",
            id: ""
        },
        {
            value: "Verified",
            id: true
        },
        {
            value: "Not-Verified",
            id: false
        }
    ])
    const zoneChange = () => {
        setZoneChangeSet(true)
        setStateChangeset(false)
        setStateZoneChangeSet(false)
        setDistrictChangeset(false)
    }
    const stateChange = () => {
        setStateChangeset(true)
        setZoneChangeSet(false)
        setStateZoneChangeSet(false)
        setDistrictChangeset(false)
    }
    const stateZoneChange = () => {
        setStateZoneChangeSet(true)
        setZoneChangeSet(false)
        setStateChangeset(false)
        setDistrictChangeset(false)
    }
    const districtChange = () => {
        setDistrictChangeset(true)
        setStateZoneChangeSet(false)
        setZoneChangeSet(false)
        setStateChangeset(false)
    }
    const addHandle = () => {
        setAddFlag(prevFlag => !prevFlag);
        setSearchValue("")
        handleUserCreationList()
    }
    const addHandleCancel = () => {
        setEditFlag(false);
        setSearchValue("")
        handleUserCreationList()
    }
    const addHandle1 = (data) => {
        setDataValue(data)
        setEditFlag(prevFlag => !prevFlag);
    }
    useEffect(() => {
        handleUserCreationList();
    }, []);

    const handleUserCreationList = () => {
        const postData = {
            "functionName": "usersListAccountManagers",
            "siteId": window.sessionStorage.getItem("switchingOrgId") === ""
                ? window.sessionStorage.getItem("organizationId")
                : window.sessionStorage.getItem("switchingOrgId"),
            "offset": "0",
            "max": "10",
            "userName": "",
            "isApproved": selectVerification
        }
        backendDashboardApi(postData).then((data) => {
            console.log("🚀 ~ apiCallingPost ~ postData:", data)
            if (data.success === "1") {
                setUserCreationList(data.usersList)
                setTotalCount(data.totalCount)
                setActivePage(1)
            } else {
            }
        });
    }

    const handleSearch = () => {
        const postData = {
            "functionName": "usersListAccountManagers",
            "siteId": window.sessionStorage.getItem("switchingOrgId") === ""
                ? window.sessionStorage.getItem("organizationId")
                : window.sessionStorage.getItem("switchingOrgId"),
            "offset": "0",
            "max": "",
            "userName": searchValue,
            "isApproved": selectVerification
        }
        backendDashboardApi(postData).then((data) => {
            console.log("🚀 ~ apiCallingPost ~ postData:", data)
            if (data.success === "1") {
                setUserCreationList(data.usersList)
                setTotalCount(data.totalCount)
                setActivePage(1)
            } else {
            }
        });
    }
    const handleClear = () => {
        setSearchValue("")
        handleUserCreationList();
        setActivePage(1)
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        var offset;
        var max;
        if (pageNumber === "1") {
            offset = "0";
            max = 10;
        } else {
            offset =
                parseInt(pageNumber - 1) * 10;
            max = 10;
        }
        setActivePage(pageNumber);
        const postData = {
            "functionName": "usersListAccountManagers",
            "siteId": window.sessionStorage.getItem("switchingOrgId") === ""
                ? window.sessionStorage.getItem("organizationId")
                : window.sessionStorage.getItem("switchingOrgId"),
            "offset": offset,
            "max": max,
            "userName": searchValue,
            "isApproved": selectVerification
        };
        backendDashboardApi(postData).then((data) => {
            console.log("🚀 ~ apiCallingPost ~ postData:", data)
            if (data.success === "1") {
                setUserCreationList(data.usersList)
                setTotalCount(data.totalCount)
            } else {
            }
        });
    };

    const handleVerificationSelect = (event) => {
        setSelectVerification(event.target.value)
    }
    return (
        <div className="main_wrapper dsh-wrapper">
            <LeftMenu />
            <div className="userListWrapper">
                <Header heading="User Creation" headerImage={Dashboardimage} />
                <div className="container">
                    <div className="organisation-div organization-card user-creation pt-4 pb-0">
                        <div className="row">
                            <div class="col-md-3 subhead">User Creation</div>
                            {addFlag === true || editFlag === true ? "" :
                                <div className="col-lg-2 col-xl-3 d-none d-lg-inline-block">
                                    <select

                                        onChange={(e) => handleVerificationSelect(e)}
                                        //    value={selectedParentIdNew[`selectedParentId${outerIndex}-${i}`]}
                                        className="form-control-db"
                                    >
                                        {/* <option value="">Select</option> */}

                                        {verificationArray.map((dataValue) => (
                                            <option key={dataValue.id} value={dataValue.id}>
                                                {dataValue.value}
                                            </option>
                                        ))}
                                    </select>
                                </div>}
                            {addFlag === true || editFlag === true ? "" :
                                <div className="col-md-4 col-lg-3 m-0 text-right">
                                    <input type="text" placeholder="Search"
                                        className="form-control-serch w-100"
                                        value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                </div>}
                            <div className="col-xl-3 col-lg-4 col-md-5 m-0 text-right ml-auto">
                                {addFlag === true || editFlag === true ? "" : <>
                                    <button className="db-btn mr-3" onClick={() => handleSearch()}>
                                        Search
                                    </button>
                                    <button className="db-btn mr-3" onClick={() => handleClear()}>
                                        Clear
                                    </button>
                                </>}
                                {editFlag === false ?
                                    <button className="db-btn" onClick={() => addHandle()}>
                                        {!addFlag ? "Add" : "cancel"}
                                    </button> : <button className="db-btn" onClick={() => addHandleCancel()}>
                                        cancel
                                    </button>}
                            </div>
                        </div>
                        <hr></hr>
                        {!addFlag && !editFlag &&
                            <div className="col-md-7 tableResponsive mx-0">
                                <div className="tableOuterWidth" style={{ minWidth: "auto" }}>
                                    <table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th className="col-1">Sl. No.</th>
                                                <th className="col-3">Name</th>
                                                <th className="col-4">User Name</th>
                                                <th className="col-2">Status</th>
                                                <th className="col-2">Action</th>
                                            </tr>
                                        </thead>
                                        <div class="">
                                            {userCreationList && userCreationList.map((data, index) =>
                                                <tbody className="table_Outertbody">
                                                    <tr>
                                                        <td className="col-1">
                                                            <p className="mb-0">{index + 1}</p>
                                                        </td>
                                                        <td className="col-3">
                                                            <p className="mb-0">{data.FirstName +" "+ data.LastName}</p>
                                                        </td>
                                                        <td className="col-4">
                                                            <p className="mb-0">{data.email_id}</p>
                                                        </td>
                                                        <td className="col-2">
                                                            <p className="mb-0">{data.isApproved === 1 ? "Verified" : "Not-Verified"}</p>
                                                        </td>
                                                        <td className="col-2">
                                                            <p className="text-primary cursor-pointer mb-0" onClick={() => addHandle1(data)}>Edit </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </div>
                                    </table>
                                </div>
                            </div>}
                    </div>
                    {addFlag &&
                        <UserCreationAdd addHandle={addHandle} />
                    }
                    {editFlag &&
                        <UserCreationAdd dataValue={dataValue} edit={"edit"} addHandle={addHandleCancel} />
                    }

                    {totalCount > 10 && editFlag === false && addFlag === false ?
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={totalCount}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                        />
                        : ""}

                </div>
                <Footer />
            </div>
            <div className="loder_div" hidden />
        </div>
    );
}
export default UserCreation;
