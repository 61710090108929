import React, { useState } from "react";
import "../../css/bootstrap.css";
import "../../css/style.css";
import rightArrow from "../../images/arrow.png";
import LeftMenu from "../../layout/leftMenu";
import { backendApi, re } from "../../apiService";
import rolesimage from "../../images/left_icons/roles.png";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import * as Icon from "react-feather";
var phNum = /^\d{10}$/;
var emailValidation =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
function CreateNewLocationForm(props) {
  const location = useLocation();
  const [Title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [description, setdescription] = useState(window.sessionStorage.getItem("switchUserOrganization") !== ""
    ? window.sessionStorage.getItem("switchUserOrganization")
    : window.sessionStorage.getItem("organization"));
  const [phone, setPhone] = useState("");
  const [adminstriveArea, setadminstriveArea] = useState("");
  const [locality, setlocality] = useState("");
  const [address, setaddress] = useState("");
  const [postalCode, setpostalCode] = useState("");
  const [websiteUrl, setwebsiteUrl] = useState("");
  const [Verificationmethod, setVerificationmethod] = useState("");
  const [VerificationCodeEmail, setVerificationCodeEmail] = useState("");
  const [verificationRole, setverificationRole] = useState("");
  const [VerificationmethodPhoneNumber, setVerificationmethodPhoneNumber] = useState("");
  const [VerificationCode, setVerificationCode] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  
  useEffect(() => {
    console.log(location.state.auditClaimData,"kkkkkkkkkkkkkkk");
    setlocality(location.state.auditClaimData.address ? location.state.auditClaimData.address.split(',').at(-3) : "")
    setadminstriveArea(location.state.auditClaimData.address ? location.state.auditClaimData.address.split(',').at(-2) : "")
    setpostalCode(location.state.auditClaimData.address ?location.state.auditClaimData.address.split(',').pop() : "")
    setFirstName(location.state.auditClaimData.location_name);
    setTitle(location.state.auditClaimData.location_name);
    setwebsiteUrl(JSON.parse(window.sessionStorage.getItem("dataPushData")).domain?.JSON.parse(window.sessionStorage.getItem("dataPushData")).domain)
    setPhone(JSON.parse(window.sessionStorage.getItem("dataPushData")).mobile_no?.JSON.parse(window.sessionStorage.getItem("dataPushData")).mobile_no)
    setVerificationCodeEmail(JSON.parse(window.sessionStorage.getItem("dataPushData")).email_id?.JSON.parse(window.sessionStorage.getItem("dataPushData")).email_id)
    let array = [location.state.auditClaimData.address];
    let string = array[0];
    let stringArray = string.split(',');
    stringArray.splice(-3);
    let newString = stringArray.join(',');
    array[0] = setaddress(newString);
  }, []);
  //first
  const createNewLocation = (e) => {
    e.preventDefault();
    console.log("loggg", phNum.test(phone));
    console.log("loggg", phone);
    if (phone !== "" && !phNum.test(phone)) {
      alert("Phone Number Is Not Valid");
      return false;
    } 
    var req = {
      functionName:"createNewLocation",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      name:firstName,
      title:Title,
      Description:description,
      PhoneNo:"+91"+phone,
      AdministrativeArea:adminstriveArea,
      Locality:locality,
      Address:address,
      WebsiteUrl:websiteUrl,
      postalCode:postalCode,
      validate:false,
    };
    console.log(req, "reqreqreqreqreqreqreq");
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((result) => {
      $(".loder_div").attr("hidden", true);
      if (result.success === "1") {
        window.sessionStorage.setItem(
          "createNewLocationId",
          result.data.name.split("/").pop()
        );
        recallCreateLocation();
      } else {
        alert(result.data.error.details[0].reason)
      }
    });
  };
  //second
  const recallCreateLocation = () => {
    var req = {
      functionName: "createNewLocation",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      name: firstName,
      title: Title,
      Description: description,
      PhoneNo: "+91" + phone,
      AdministrativeArea: adminstriveArea,
      Locality: locality,
      Address: address,
      WebsiteUrl: websiteUrl,
      postalCode: postalCode,
      validate: true,
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((result) => {
        $(".loder_div").attr("hidden", true);
      if (result.success === "1") {
        alert(
          "Location created successfully. Please follow the verification steps"
        );
        $("#createLocationForm").attr("hidden", true);
        $("#phoneNumberDiv").attr("hidden", false);
      } else {
      }
    });
  };
  //third
  const verificationCode = (e) => {
    setVerificationCode("")
    e.preventDefault();
    const apiReq = {
      functionName: "createNewLocationVerify",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      method: Verificationmethod,
      phoneNumber: "+91" + VerificationmethodPhoneNumber,
      locationid: window.sessionStorage.getItem("createNewLocationId"),
    };
    $(".loder_div").attr("hidden", false);
    backendApi(apiReq).then((result) => {
        $(".loder_div").attr("hidden", true);
      if (result.success === "1") {
        $("#phoneNumberDiv").attr("hidden", true);
        $("#verificationCode").attr("hidden", false);
        window.sessionStorage.setItem(
          "verificationPin",
          result.data.verification.name.split("/").pop()
        );
      }
    });
  };
  //fourth
  const verificationSuccsess = (e) => {
    e.preventDefault();
    const apiReq = {
      "functionName": "GBPVerifyPin",
      "siteId": window.sessionStorage.getItem("switchingOrgId") === ""
        ? window.sessionStorage.getItem("organizationId")
        : window.sessionStorage.getItem("switchingOrgId"),
      "locationid": window.sessionStorage.getItem("createNewLocationId"),
      "verificationID": window.sessionStorage.getItem("verificationPin"),
      "pin": VerificationCode
    };
    $(".loder_div").attr("hidden", false);
    backendApi(apiReq).then((result) => {
        $(".loder_div").attr("hidden", true);
      if (result.success === "1") {
        $("#verificationCode").attr("hidden", true);
        $("#verificationCodeEmailId").attr("hidden", false);
      }
      else { alert(result.data.error.message) }
    });
  };
  //lastStep
  const verifyEmailId = (e) => {
    e.preventDefault();
    const apiReq = {
      "functionName": "InviteAdminToLocationPush",
      "siteId": window.sessionStorage.getItem("switchingOrgId") === ""
        ? window.sessionStorage.getItem("organizationId")
        : window.sessionStorage.getItem("switchingOrgId"),
      "email": VerificationCodeEmail,
      "role": 'MANAGER',
      "locationid": window.sessionStorage.getItem("createNewLocationId"),
    }
    $(".loder_div").attr("hidden", false);
    backendApi(apiReq).then((result) => {
        $(".loder_div").attr("hidden", true);
      if (result.success === "1") {
        $("#verificationSuccsess").attr("hidden", false);
        $("#verificationCodeEmailId").attr("hidden", true);
      }
    });
  };
  const backAfterCompliting = () => {
    window.location.href = "/claimBusiness"
  };
  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Create New Google Location" headerImage={rolesimage} />
        <div className="container-fluid">
          <>
            <div className="tableOuterWidth">
              <table className="table">
                <thead>
                  <tr>
                    <th className="col-1">ID</th>
                    <th className="col-2" style={{ maxWidth: "12%" }}>
                      Business Name
                    </th>
                    <th className="col-7" style={{ maxWidth: "63%" }}>
                      Address
                    </th>
                    {/* <th className="col-1">Status</th> */}
                  </tr>
                </thead>
                <div class="table_Outerscroll">
                  <tbody className="table_Outertbody">
                    <tr>
                      <td className="col-1">
                        {location.state.auditClaimData.id}
                      </td>
                      <td className="col-2" style={{ maxWidth: "12%" }}>
                        {location.state.auditClaimData.location_name}
                      </td>
                      <td className="col-7" style={{ maxWidth: "63%" }}>
                        {location.state.auditClaimData.address}
                      </td>
                      {/* {flag == false && (
                          <td>
                            <button
                              className="btn btn-primary"
                              // onClick={(e) =>
                              //   pushClick(data)
                              // }
                            >
                              Push
                            </button>
                          </td>
                        )} */}
                      {/* <td className="col-1">
                          {location.state.auditClaimData.status}
                        </td> */}
                    </tr>
                  </tbody>
                </div>
              </table>
            </div>
            <form className="user_formdiv col-md-10" id="createLocationForm">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1"> Name</label>
                    <input
                      type="text"
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                      className="form-control"
                      id="firstName"
                      aria-describedby="emailHelp"
                      placeholder="Name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      onChange={(e) => setTitle(e.target.value)}
                      value={Title}
                      aria-describedby="emailHelp"
                      placeholder="Title"
                    />
                  </div>
                </div>
                <hr></hr>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Description</label>
                    <input
                      value={description}
                      onChange={(e) => setdescription(e.target.value)}
                      className="form-control"
                      id="emailId"
                      aria-describedby="emailHelp"
                      placeholder="Description"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Phone No</label>
                    <input
                      type="text"
                      className="form-control"
                      id="phoneNo"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      maxLength={10}
                      aria-describedby="emailHelp"
                      placeholder="Phone No"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Administrative Area
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phoneNo"
                      // maxLength={10}
                      value={adminstriveArea}
                      onChange={(e) => setadminstriveArea(e.target.value)}
                      aria-describedby="emailHelp"
                      placeholder=" Administrative Area"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Locality</label>
                    <input
                      type="text"
                      className="form-control"
                      id="phoneNo"
                      // maxLength={10}
                      value={locality}
                      onChange={(e) => setlocality(e.target.value)}
                      aria-describedby="emailHelp"
                      placeholder="Locality"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      id="phoneNo"
                      // maxLength={10}
                      onChange={(e) => setaddress(e.target.value)}
                      value={address}
                      aria-describedby="emailHelp"
                      placeholder="Address"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1"> Postal Code</label>
                    <input
                      type="text"
                      onChange={(e) => setpostalCode(e.target.value)}
                      value={postalCode}
                      className="form-control"
                      id="firstName"
                      aria-describedby="emailHelp"
                      placeholder="Name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Website Url</label>
                    <input
                      type="text"
                      className="form-control"
                      id="phoneNo"
                      // maxLength={10}
                      onChange={(e) => setwebsiteUrl(e.target.value)}
                      value={websiteUrl}
                      aria-describedby="emailHelp"
                      placeholder="Website Url"
                    />
                  </div>
                </div>
              </div>
              <div className="form_bttn">
                <button
                  type="submit"
                  className="btn btn-primary submitButton cancel_bttn"
                  onClick={(e) => createNewLocation(e)}
                >
                  <span>Create Location</span>
                </button>
                <Link to="/claimBusiness"
                  className="btn btn-primary submitButton cancel_bttn"
                >
                  <span >Cancel</span>
                </Link>
              </div>
            </form>
          </>
        </div>
        <div className="container-fluid" id="phoneNumberDiv" hidden>
          <>
            <form className="user_formdiv col-md-10">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      {" "}
                      Verification method
                    </label>
                    <select
                      value={Verificationmethod}
                      onChange={(e) => setVerificationmethod(e.target.value)}
                    >
                      <option value="0">Select</option>
                      <option value="PHONE_CALL">PHONE_CALL</option>
                      <option value="SMS">SMS</option>
                      {/* <option value="EMAIL">EMAIL</option>
                        <option value="ADDRESS">ADDRESS</option> */}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Mobile Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="phoneNo"
                      onChange={(e) =>
                        setVerificationmethodPhoneNumber(e.target.value)
                      }
                      value={VerificationmethodPhoneNumber}
                      maxLength={10}
                      aria-describedby="emailHelp"
                      placeholder="Mobile Number"
                    />
                  </div>
                </div>
              </div>
              <div className="form_bttn">
                <button
                  type="submit"
                  className="btn btn-primary submitButton cancel_bttn"
                  onClick={(e) => verificationCode(e)}
                >
                  <span>Submit</span>
                </button>
                <Link to="/claimBusiness"
                  className="btn btn-primary submitButton cancel_bttn"
                >
                  <span >Cancel</span>
                </Link>
              </div>
            </form>
          </>
        </div>
        <div className="container-fluid" id="verificationCode" hidden>
          <>
            <form className="user_formdiv col-md-10">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Verification Code
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phoneNo"
                      maxLength={10}
                      onChange={(e) => setVerificationCode(e.target.value)}
                      value={VerificationCode}
                      aria-describedby="emailHelp"
                      placeholder="Verification Code"
                    />
                  </div>
                </div>
              </div>
              <div className="form_bttn">
                <button
                  onClick={(e) => verificationSuccsess(e)}
                  type="submit"
                  className="btn btn-primary submitButton cancel_bttn"
                >
                  <span >Verify</span>
                </button>
                <Link to="/claimBusiness"
                  className="btn btn-primary submitButton cancel_bttn"
                >
                  <span >Cancel</span>
                </Link>
                <button
                  type="submit"
                  className="btn btn-primary submitButton cancel_bttn"
                  onClick={(e) => verificationCode(e)}
                >
                  <span>Retry</span>
                </button>
              </div>
            </form>
          </>
        </div>
        <div className="container-fluid" id="verificationSuccsess" hidden>
          <>
            {/* <form className="user_formdiv col-md-10"> */}
            <div>
              <button
                // type="submit"
                className="btn btn-primary d-flex align-items-center"
                onClick={(e) => backAfterCompliting(e)}
              >
                <Icon.ArrowLeft className="mr-1" width={18} />Back
              </button>
            </div>
            <div>
              Verification Completed Successfully and location reflected
              in GBP soon !.
            </div>
            {/* <div className="form_bttn">
              <button
                type="submit"
                className="btn btn-primary submitButton cancel_bttn"
             
                
              >
                <span to="/userList">Back</span>
              </button>
            </div> */}
            {/* </form> */}
          </>
        </div>
        <div className="container-fluid" id="verificationCodeEmailId" hidden>
          <>
            <form className="user_formdiv col-md-10">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Verification EmailId
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phoneNo"
                      // maxLength={10}
                      onChange={(e) => setVerificationCodeEmail(e.target.value)}
                      value={VerificationCodeEmail}
                      aria-describedby="emailHelp"
                      placeholder="Verification EmailId"
                    />
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Verification EmailId
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phoneNo"
                      // maxLength={10}
                      onChange={(e) => setverificationRole(e.target.value)}
                      value={verificationRole}
                      aria-describedby="emailHelp"
                      placeholder="Verification Code"
                    />
                  </div>
                </div> */}
              </div>
              <div className="form_bttn">
                <button
                  onClick={(e) => verifyEmailId(e)}
                  type="submit"
                  className="btn btn-primary submitButton cancel_bttn"
                >
                  <span >Verify</span>
                </button>
                <Link to="/claimBusiness"
                  className="btn btn-primary submitButton cancel_bttn"
                >
                  <span >Cancel</span>
                </Link>
                {/* <button
                  type="submit"
                  className="btn btn-primary submitButton cancel_bttn"
                >
                  <span >Retry</span>
                </button> */}
              </div>
            </form>
          </>
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
}
export default CreateNewLocationForm;
