import React, { useState, useEffect, useRef } from 'react'
import { backendDashboardApi } from "../apiService";
import Header from "../layout/header-invoice.jsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import GenerateInvoiceTable from '../invoices/generateInvoiceTable.jsx'

const GenerateInvoice = () => {
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedApprovedDate, setSelectedApprovedDate] = useState(null);
  const [areaOffice, setAreaOffice] = useState('');
  const [receivedAmount, setReceivedAmount] = useState('');
  const [areaOfficeList, setAreaOfficeList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    handleAreaOfficeListing();

  }, []);

  const handleAreaOfficeListing = () => {
    const postData = {
      "functionName": "areaOfficeListing",
      "accountId": window.sessionStorage.getItem("switchingOrgId"),
    }
    backendDashboardApi(postData).then((data) => {
      console.log("🚀 ~ backendDashboardApi ~ data setAreaOfficeList:", data)
      if (data.success === "1") {
        setAreaOfficeList(data.result)
      } else {
        setAreaOfficeList([])
      }
    });
  }

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setReceivedAmount(value);
    }
  };

  const handleStartDateSelect = (date) => {
    console.log("Selected start date:", date);
    setSelectedStartDate(date);
  };

  const handleStartDateChange = (date) => {
    console.log("Date changed:", date);
    setSelectedStartDate(date);
  };
  const handleApprovedDateSelect = (date) => {
    console.log("Selected start date:", date);
    setSelectedApprovedDate(date);
  };

  const handleApprovedDateChange = (date) => {
    console.log("Date changed:", date);
    setSelectedApprovedDate(date);
  };

  const viewInvoice = () => {
    if (!areaOffice) {
      alert("Area Office Required")
      return
    }
    if (!selectedStartDate) {
      alert("Invoice Date Required")
      return
    }
    if (!selectedApprovedDate) {
      alert("Approved Date Required")
      return
    }
    // if (!receivedAmount) {
    //   alert("Received Amount Required")
    //   return
    // }
    let invoiceDate = Date.parse(selectedStartDate);
    let approvedDate = Date.parse(selectedApprovedDate);
    const postData = {
      "functionName": "generateInvoice",
      "accountId": window.sessionStorage.getItem("switchingOrgId"),
      "invoiceDate": invoiceDate,
      "areaOffice": areaOffice,
      "createdOn": new Date().getTime(),
      "createdBy": window.sessionStorage.getItem("userId"),
      "approvedDate": approvedDate,
      "amountReceived": receivedAmount ? receivedAmount : 0
    }
    backendDashboardApi(postData).then((data) => {
      if (data.success === "1") {
        navigate('/invoices/invoicedownload', {
          state: {
            selectedStartDate,
            areaOffice,
            receivedAmount
          }
        });
        setAreaOffice("");
        setReceivedAmount("");
        setSelectedApprovedDate("");
        setSelectedStartDate("");
      } else {
        alert(data.errorMessage);
      }
    });

  };

  return (
    <div className="main_wrapper">
      {/* <Header /> */}
      <div className="container download-invoice-wrapper mt-5">
        <div className="InvoicesRow" style={{ alignItems: "center" }}>
          <div class="subhead ml-2 mb-2">Generate Invoice</div>
          <div className="col-md-12 ">
            <label>Select Area Office<sup style={{ color: "red" }}>*</sup></label>
            <select className="form-control" value={areaOffice}
              onChange={(e) => {
                setAreaOffice(e.target.value);
              }}>
              <option value="">Select</option>
              {areaOfficeList && areaOfficeList.map((data) => (
                <option value={data.area_office}>{data.area_office}</option>
              ))}
            </select>
          </div>
          <div className="col-md-12">
            <label>Invoice Date<sup style={{ color: "red" }}>*</sup></label>
            <DatePicker
              className="form-control datepickerInvoiceInput"
              selected={selectedStartDate}
              onSelect={handleStartDateSelect}
              onChange={handleStartDateChange}
              showMonthDropdown
              autoComplete='off'
              showYearDropdown
              dropdownMode="select"
              placeholderText='Select Invoice Date'
              dateFormat="dd/MM/yyyy"
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          </div>
          <div className="col-md-12">
            <label>Approved Date<sup style={{ color: "red" }}>*</sup></label>
            <DatePicker
              className="form-control datepickerInvoiceInput"
              selected={selectedApprovedDate}
              onSelect={handleApprovedDateSelect}
              onChange={handleApprovedDateChange}
              showMonthDropdown
              autoComplete='off'
              showYearDropdown
              dropdownMode="select"
              placeholderText='Select Approved Date'
              dateFormat="dd/MM/yyyy"
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          </div>
          <div className="col-md-12">
            <label>Received Amount</label>
            <input type="text" className="form-control" placeholder='Received Amount' value={receivedAmount}
              onChange={handleInputChange}
            // onChange={(e) => {
            //   setReceivedAmount(e.target.value);  
            // }}
            />
          </div>
          <div className="col-md-12 text-right">
            <button className="btn btn-primary btn-dwonlodInvoice BtnSubmitInv" type="submit" onClick={() => viewInvoice()}>Generate Invoice</button>
          </div>
        </div>
        {/* <div className="mt-5">
          <GenerateInvoiceTable />
        </div> */}
      </div>
    </div>
  );
};

export default GenerateInvoice;
