import React, { Component } from "react";
import {apiCallingPost } from "../apiService";
import MultiGraph from "./multigraph";
import GoogleAnalyticsimage from "../images/left_icons/google_analytics.png";
import Header from "../layout/header";
import LeftMenu from "../layout/leftMenu.jsx";
import infoimg from "../images/info.png"
import $ from "jquery";
import CityWiseAnalytics from "./citywiseAnalytics";
import AnalyticsUser from "./analytics-users";
import AnalyticsLanding from "./analytics-landing";
class AnalyticsGraphCopy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key:new Date(),
      x_months: [],
      graphMinValue: '',
      graphMaxValue: '',
      Sessions: "",
      NewUsers: "",
      ActiveUsers: "",
      BounceRate: "",
      Conversions: "",
      GoalConversionRate: "",
      UserEngagementDuration: "",
      AverageSessionDuration: "",
      TotalUsers: "",
      reportDatas: [],
      duration: "1-month",
      gaReportDatas: [],
      isLoader: 0,
      cityoptionResult: [],
      citywise: [],
      optionCountry: [],
      optionCity: [],
      optionCity1: [],
      CountriesData: [],
      citiesData: [],
      selCity: "",
      selOrganisation: "",
      selState: "",
      selCountry: "",
      series: [],
      isData:0,
      showFunctionalComponent: false,
    };
    this.analyticsData = this.analyticsData.bind(this);
    this.cityHandleChange = this.cityHandleChange.bind(this);
    this.countryHandleChange = this.countryHandleChange.bind(this);
    this.citywiseHandleChange = this.citywiseHandleChange.bind(this);
  }
  componentDidMount() {
    $("#btnClear").hide();
    $(".analyticsData").click(function () {
      $(".analyticsData").removeClass("active");
      $(this).addClass("active");
    });
    this.analyticsData();
    this.getSessionGraphData();
  }

  analyticsData() {
    // debugger
    this.setState({ isLoader: 0 });
    console.log("lllllllllllllllllllllllll", this.state.selOrganisation)
    const analyticsData = {
      functionName: "googleAnalyticsReport",
      duration: this.state.duration,
      propertyId: this.state.selOrganisation
    };
    console.log("mmmmmmmmmmmmmmmmmmm", analyticsData)
    apiCallingPost(analyticsData).then((data) => {
      $(".loder_div").attr("hidden", true);
      console.log("mmmmmmmmmmmmmmmmmmm", data)
      if (Object.keys(data).toString().indexOf("datas") === -1) {
        //  alert("no")
        this.setState(
          {
            Sessions: '',
            //   citiesData: cityoptionResult2,
            NewUsers: '',
            //ActiveUsers:data.data.totalActiveUsers,
            BounceRate: '',
            Conversions: '',
            GoalConversionRate: '',
            UserEngagementDuration: '',
            AverageSessionDuration: '',
            TotalUsers: '',
            reportDatas: [],
            originalreportDatas: [],
            isLoader: 0,
            citywise: [],
            optionCountry: [],
            CountriesData: [],
          }
        );
      }
      else {
        // alert("yes")
        let cityoptionResult = data.datas.filter(function (e) {
          return e.city.trim() !== "(not set)";
        })
          .reduce(function (r, a) {
            r[a.city] = r[a.city] || [];
            r[a.city].push(a);
            return r;
          }, Object.create(null));

        let optionCountries = data.datas
          .filter(function (e) {
            return e.city.trim() !== "(not set)";
          })
          .reduce(function (r, a) {
            r[a.country.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())] =
              r[a.country.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())] || [];
            r[
              a.country.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()).toString()
            ].push(a);
            return r;
          }, Object.create(null));
        let gaReportDatas = data.datas.sort((a, b) => {
          return parseFloat(a.details[1].val) - parseFloat(b.details[1].val);
        })
        console.log("gaReportDatas",gaReportDatas)
        this.setState(
          {
            Sessions: data.totalset.Sessions,
            //   citiesData: cityoptionResult2,
            NewUsers: data.totalset.NewUsers,
            //ActiveUsers:data.data.totalActiveUsers,
            BounceRate: data.totalset.BounceRate,
            Conversions: data.totalset.Conversions,
            GoalConversionRate: data.totalset.GoalConversionRate,
            UserEngagementDuration: data.totalset.UserEngagementDuration,
            AverageSessionDuration: data.totalset.AverageSessionDuration,
            TotalUsers: data.totalset.TotalUsers,
            reportDatas: gaReportDatas,
            originalreportDatas: gaReportDatas,
            isLoader: 1,
            citywise: Object.keys(cityoptionResult),
            optionCountry: Object.keys(optionCountries),
            CountriesData: optionCountries,
          }
        );
      }
    });
  }
  countryHandleChange(e) {
    let uniqueoptionCity = [];
    let City = [];
    if (e.target.value !== "") {
      $("#btnClear").show();
      console.log("country========", e.target.value);
      console.log("CountriesData", this.state.CountriesData[e.target.value]);
      let cityoptionResult = this.state.CountriesData[e.target.value].reduce(
        function (r, a) {
          r[a.city.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())] =
            r[a.city.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())] || [];
          r[a.city.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())].push(a);
          return r;
        },
        Object.create(null)
      );
      City = Object.keys(cityoptionResult);
      if (City.length > 0) {
        City.push.apply(City, Object.keys(cityoptionResult));
        uniqueoptionCity = [...new Set(City)];
      } else {
        uniqueoptionCity = Object.keys(cityoptionResult);
      }
      console.log("uniqueoptionCity=======", uniqueoptionCity);
      var totalSessions = 0;
      var totalNewUsers = 0;
      var totalActiveUsers = 0;
      var totalBounceRate = 0.0;
      var totalConversions = 0;
      var totalGoalConversionRate = 0;
      var totalUserEngagementDuration = 0;
      var totalAverageSessionDuration = 0.0;
      var totalTotalUsers = 0;
      var responseSize = 0;
      console.log("totalTotalUsers========", totalTotalUsers);
      let gaReportDatas = this.state.CountriesData[e.target.value].sort((a, b) => {
        return parseFloat(a.details[1].val) - parseFloat(b.details[1].val);
      })
      this.state.CountriesData[e.target.value].forEach((data) => {
        responseSize++;
        data.details.forEach((data2) => {
          var sessionVal = 1;
          if (data2.name === "Sessions") {
            sessionVal = parseInt(data2.val);
          }
          switch (data2.name) {
            case "Sessions":
              totalSessions = totalSessions + parseInt(data2.val);
              break;
            case "NewUsers":
              totalNewUsers = totalNewUsers + parseInt(data2.val);
              break;
            case "ActiveUsers":
              totalActiveUsers = totalActiveUsers + parseInt(data2.val);
              break;
            case "BounceRate":
              totalBounceRate = totalBounceRate + parseFloat(data2.val.replace("%", ""));
              break;
            case "Conversions":
              totalConversions = totalConversions + parseInt(data2.val);
              totalGoalConversionRate = totalGoalConversionRate + (parseInt(data2.val) / parseInt(sessionVal)) * 100;
              break;
            //case "GoalConversionRate":totalSessions=totalSessions+`${row.metricValues[i].value}`;break;
            case "UserEngagementDuration":
              totalUserEngagementDuration = totalUserEngagementDuration + parseInt(data2.val);
              break;
            case "AverageSessionDuration":
              totalAverageSessionDuration = totalAverageSessionDuration + parseFloat(data2.val);
              break;
            case "TotalUsers":
              totalTotalUsers = totalTotalUsers + parseInt(data2.val);
              break;
            default:
              break;
          }
        });
      });
      var minutesAverageSessionDuration = Math.floor(totalAverageSessionDuration.toFixed(2) / 60000);
      var secondsAverageSessionDuration = ((totalAverageSessionDuration.toFixed(2) % 60000) / 1000).toFixed(0);
      var minutesUserEngagementDuration = Math.floor(totalUserEngagementDuration / 60000);
      var secondsUserEngagementDuration = ((totalUserEngagementDuration % 60000) / 1000).toFixed(0);
      console.log("totalTotalUsers========", cityoptionResult);
      this.setState({
        selCountry: e.target.value,
        cityoptionResult: uniqueoptionCity,
        optionCity: Object.keys(cityoptionResult),
        optionCity1: cityoptionResult,
        Sessions: totalSessions,
        reportDatas: gaReportDatas,
        originalreportDatas: gaReportDatas,
        NewUsers: totalNewUsers,
        BounceRate: ((totalBounceRate.toFixed(2) / (responseSize === 0 ? 1 : responseSize)) * 100).toFixed(2) + "%",
        Conversions: totalConversions,
        GoalConversionRate: totalGoalConversionRate,
        UserEngagementDuration: minutesUserEngagementDuration !== "0" ? minutesUserEngagementDuration + " min " : "" + secondsUserEngagementDuration + " sec",
        AverageSessionDuration: minutesAverageSessionDuration !== "0" ? minutesAverageSessionDuration + " min " : "" + secondsAverageSessionDuration + " sec",
        TotalUsers: totalTotalUsers,
        citiesData: cityoptionResult,
      });
    } else {
      $("#btnClear").hide();
      this.setState(
        {
          selCountry: e.target.value,
          selCity: "",
          cityoptionResult: [],
        }
      );
      this.analyticsData();
    }
  }
  organisationChange(e) {
    $(".loder_div").attr("hidden", false);
    this.setState(
      {
        showFunctionalComponent: false
      })
    this.setState(
      {
        selOrganisation: e.target.value,
      }, () => {
        this.setState(prevState => ({
          showFunctionalComponent: !prevState.showFunctionalComponent
        }));
        this.analyticsData();
        this.getSessionGraphData()
      });
  }
  cityHandleChange(e) {
    let filterCityResultDatas;
    if (e.target.value === "") {
      $("#btnClear").hide();
      this.setState(
        {
          selCountry: "",
          selCity: e.target.value,
          cityoptionResult: [],
        })
      this.analyticsData();
      console.log(this.state.gaReportDatas, "city empty=-===", this.state.selCountry);
    } else {
      $("#btnClear").show();
      filterCityResultDatas = this.state.optionCity1[e.target.value].filter((x) => {
        return (
          this.equalsIgnoringCase(x.country, this.state.selCountry) &&
          this.equalsIgnoringCase(x.city, e.target.value)
        );
      });
    }
    console.log("filterCityResultDatas====", filterCityResultDatas);
    var totalSessions = 0;
    var totalNewUsers = 0;
    var totalActiveUsers = 0;
    var totalBounceRate = 0.0;
    var totalConversions = 0;
    var totalGoalConversionRate = 0;
    var totalUserEngagementDuration = 0;
    var totalAverageSessionDuration = 0.0;
    var totalTotalUsers = 0;
    var responseSize = 0;
    filterCityResultDatas.forEach((data) => {
      responseSize++;
      data.details.forEach((data2) => {
        var sessionVal = 1;
        if (data2.name === "Sessions") {
          sessionVal = parseInt(data2.val);
        }
        switch (data2.name) {
          case "Sessions":
            totalSessions = totalSessions + parseInt(data2.val);
            break;
          case "NewUsers":
            totalNewUsers = totalNewUsers + parseInt(data2.val);
            break;
          case "ActiveUsers":
            totalActiveUsers = totalActiveUsers + parseInt(data2.val);
            break;
          case "BounceRate":
            totalBounceRate =
              totalBounceRate + parseFloat(data2.val.replace("%", ""));
            break;
          case "Conversions":
            totalConversions = totalConversions + parseInt(data2.val);
            totalGoalConversionRate = totalGoalConversionRate + (parseInt(data2.val) / parseInt(sessionVal)) * 100;
            break;
          //case "GoalConversionRate":totalSessions=totalSessions+`${row.metricValues[i].value}`;break;
          case "UserEngagementDuration":
            totalUserEngagementDuration = totalUserEngagementDuration + parseInt(data2.val);
            break;
          case "AverageSessionDuration":
            totalAverageSessionDuration = totalAverageSessionDuration + parseFloat(data2.val);
            break;
          case "TotalUsers":
            totalTotalUsers = totalTotalUsers + parseInt(data2.val);
            break;
          default:
            break;
        }
      });
    });
    var minutesAverageSessionDuration = Math.floor(totalAverageSessionDuration.toFixed(2) / 60000);
    var secondsAverageSessionDuration = ((totalAverageSessionDuration.toFixed(2) % 60000) / 1000).toFixed(0);
    var minutesUserEngagementDuration = Math.floor(totalUserEngagementDuration / 60000);
    var secondsUserEngagementDuration = ((totalUserEngagementDuration % 60000) / 1000).toFixed(0);
    this.setState({
      Sessions: totalSessions,
      NewUsers: totalNewUsers,
      BounceRate: ((totalBounceRate.toFixed(2) / (responseSize === 0 ? 1 : responseSize)) * 100).toFixed(2) + "%",
      Conversions: totalConversions,
      GoalConversionRate: totalGoalConversionRate,
      UserEngagementDuration: minutesUserEngagementDuration !== "0" ? minutesUserEngagementDuration + " min " : "" + secondsUserEngagementDuration + " sec",
      AverageSessionDuration: minutesAverageSessionDuration !== "0" ? minutesAverageSessionDuration + " min " : "" + secondsAverageSessionDuration + " sec",
      TotalUsers: totalTotalUsers,
      // gaReportDealers: Object.keys(result),
      // gaReportGroupedDatas: result,
      selCity: e.target.value,
    },
    );
  }
  getSessionGraphData() {
    const analyticsData = {
      functionName: "gaSessionGraph",
      propertyId: this.state.selOrganisation
    };
    console.log("analyticsDataanalyticsDataanalyticsData", analyticsData)
    apiCallingPost(analyticsData).then((data) => {
     console.log("......................................",data);    
      if (Object.keys(data).toString().indexOf("series") === -1) {        
        this.setState({
          series: [],
          x_months: [],
          graphMinValue: "",
          graphMaxValue: "",
          isData:0
        });
      }
      else {        
        this.setState({
          series: data.series,
          x_months: data.categories,
          graphMinValue: data.minValue,
          graphMaxValue: data.maxValue,
         // key:new Date(),
          isData:1
        });
      }
    });
  }
  equalsIgnoringCase(text, other) {
    return text.localeCompare(other, undefined, { sensitivity: "base" }) === 0;
  }
  startWithIgnoringCase(text, other) {
    return (text.toLowerCase().startsWith(other.toLowerCase()))
  }
  citywiseHandleChange(e) {
    let filterCitygaReportDatas;
    if (e.target.value === "") {
      filterCitygaReportDatas = this.state.originalreportDatas;
    } else {
      filterCitygaReportDatas = this.state.originalreportDatas.filter((x) => {
        return this.startWithIgnoringCase(x.city, e.target.value);
      });
    }
    this.setState({
      reportDatas: filterCitygaReportDatas,
    });
  }
  getClearData() {
    this.analyticsData();
    $("#btnClear").hide();
    this.setState({
      cityoptionResult: [],
      selCountry: '',
      selCity: ''
    });
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          <Header
            heading="Google Analytics"
            headerImage={GoogleAnalyticsimage}
          />
          <div className="user_listdiv">
            <div className="mainContentDashboard analyticsDiv container-fluid">
              {/* <div className="user_analytics_btn">
                <div className="topnav_activehbtn">
                  <div className="topnav_activenumbr">
                    <span>{window.sessionStorage.getItem("ActiveUsers") ? window.sessionStorage.getItem("ActiveUsers") : "0"}</span>
                  </div>
                  <div className="topnav_activetext">
                    <span>Active Users</span>
                  </div>
                </div>

              </div> */}
             
              <div className="row col-lg-12 pb-3 pt-3">
                <div className="col-lg-3">
                  <select
                    className="ga-slect-field"
                    name="selectName"
                    value={this.state.selOrganisation}
                    onChange={(e) => { this.organisationChange(e); }}
                  >
                    <option value="">Organisation</option>
                    <option value="308126302">Lokal</option>
                    <option value="309820967">Xilligence</option>
                  </select>
                </div>

                <div className="col-lg-3">
                  <select
                    value={this.state.selCountry}
                    className="ga-slect-field"
                    id="selstate"
                    onChange={this.countryHandleChange}
                  >
                    <option value="" >Country</option>
                    {this.state.optionCountry.map((data) => (
                      <>
                        <option value={data} className="valueSelected">{data}</option>
                      </>
                    ))}
                  </select>
                </div>
                <div className="col-lg-3">
                  <select
                    className="ga-slect-field"
                    id="selcity"
                    value={this.state.selCity}
                    onChange={(e) => {
                      this.cityHandleChange(e);
                      //   this.cityAnalyticHandleChange(e);
                    }}
                  >
                    {this.state.cityoptionResult.length !== 0 ? (
                      <option value="">City</option>
                    ) : (
                      <option value="0">City</option>
                    )}
                    {this.state.cityoptionResult.map((data) => (
                      <>
                        <option value={data}>{data}</option>
                      </>
                    ))}
                  </select>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <button
                    // className="btn-clear-search w-100"
                    className="btn-cancel"
                    id="btnClear"
                    onClick={() => this.getClearData()}
                  >
                    Clear
                  </button>
                </div>
                {/* <div className="col-lg-2">
                  <select
                    className="ga-slect-field "

                  >
                    <option value="" >Business Name</option>

                  </select>
                </div>
                <div className="col-lg-2">
                  <DatePicker
                    selected={this.state.startDate}
                    name="startDate"
                    showMonthDropdown
                    dateFormat="dd/MM/yyyy"
                    className="form-control ga-slect-field "
                    placeholderText="From Date"
                  />
                </div>
                <div className="col-lg-2">
                  <DatePicker
                    selected={this.state.startDate}
                    name="startDate"
                    showMonthDropdown
                    dateFormat="dd/MM/yyyy"
                    className="form-control ga-slect-field "
                    placeholderText="To Date"
                  />
                </div> */}
              </div>
              {this.state.isLoader === 0 ? <div className="loder-wrapper"></div> : ""}
              {this.state.selOrganisation == "" ? 
              <AnalyticsLanding />
            //  {/* <div className="row col-md-12 userCountDivlist chooseOrgList"> 
            //     <div className="organiztionChoose">
            //       <span className="chooseOrgName">Please Choose An Organization</span>
            //     </div>
            //   </div> */}
              :
              <>
              <div className="row col-md-12  ga-month-outer">
                <div className="col-md-6">
                  <span className="mainHeadGoogleAnalytics">Google Analytics</span>
                </div>
              </div>
              <div className="row ml-1 mt-2">
                {/* <div className="row"> */}
                <div className="col-md-4 analyticsDiv">
                  <div className="row">
                    <div className="col-md-5 analyticsData">
                      <div className="analytics_home">Sessions</div>
                      <div className="analyticsHomeCount user-analytics">
                        {this.state.Sessions}
                      </div>
                    </div>
                    <div className="col-md-5 analyticsData">
                      <div className="analytics_home">New Users</div>
                      <div className="analyticsHomeCount user-analytics">
                        {this.state.NewUsers}
                      </div>
                    </div>
                    <div className="col-md-5 analyticsData">
                      <div className="analytics_home">GCR
                        <a href="#open-modal"> <img src={infoimg} alt="info" className="info_icon" /></a>

                      </div>
                      <div className="analyticsHomeCount user-analytics">
                        {this.state.GoalConversionRate}
                      </div>
                    </div>
                    <div className="col-md-5 analyticsData">
                      <div className="analytics_home">UED
                        <a href="#open-modal1"> <img src={infoimg} alt="info" className="info_icon" /></a>
                      </div>

                      <div className="analyticsHomeCount user-analytics">
                        {this.state.UserEngagementDuration}
                      </div>
                    </div>
                    <div className="col-md-5 analyticsData">
                      <div className="analytics_home">Total Users</div>
                      <div className="analyticsHomeCount user-analytics">
                        {this.state.TotalUsers}
                      </div>
                    </div>
                    <div className="col-md-5 analyticsData">
                      <div className="analytics_home">Bounce Rate</div>
                      <div className="analyticsHomeCount user-analytics user-sec2">
                        {this.state.BounceRate}
                      </div>
                    </div>
                    <div className="col-md-5 analyticsData">
                      <div className="analytics_home">ASD
                        <a href="#open-modal2"> <img src={infoimg} alt="info" className="info_icon" /></a>
                      </div>
                      <div className="analyticsHomeCount user-analytics">
                        {this.state.AverageSessionDuration}
                      </div>
                    </div>
                    <div className="col-md-5 analyticsData">
                      <div className="analytics_home">Conversions</div>
                      <div className="analyticsHomeCount user-analytics user-sec2">
                        {this.state.Conversions}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 analyticsDatas">
                 <MultiGraph xArray={this.state.series} yArray={this.state.x_months} minValue={this.state.graphMinValue} maxValue={this.state.graphMaxValue} name={"analytics"} />
                  {/* <Chart options={this.state.options} series={this.state.series} type="line" height={500} /> */}
                </div>
               { this.state.showFunctionalComponent &&
                <AnalyticsUser userDataInThirtyMin={this.state.selOrganisation}/>
              }
                <div className="col-md-6 ">
                  <div className="users-list-head">City wise analytics</div>
                  <div className="user-list-label-sub">
                    <div className="m-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="topnav-searchs">
                            <span className="topnav-posrel">
                              <input onKeyUp={this.citywiseHandleChange} type="text" placeholder="Search by City" className="topnav-searchinputss" style={{ width: '50%' }} />
                            </span>
                          </div>
                        </div>
                      </div>
                      <CityWiseAnalytics data={this.state.reportDatas} />
                    </div>
                  </div>
                </div>
                <div id="open-modal" className="modal-window">
                  <div>
                    <a href="#!" title="Close" className="modal-close">&times;</a>
                    <div className="reviewEdit">
                      <div className="row">
                        <div className="col-md-12  reviewTitle">
                          <p className="main-head-modal">Grievance Redressal System</p>
                          <div>The receipt and processing of complaints from citizens and consumers</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="open-modal1" className="modal-window">
                  <div>
                    <a href="#!" title="Close" className="modal-close">&times;</a>
                    <div className="reviewEdit">
                      <div className="row">
                        <div className="col-md-12 reviewTitle">
                          <p className="main-head-modal">User Engagement Duration</p>

                          <div>measures how frequently and for how long users interact with your website or application.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </>}
            </div>
          </div>
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}
export default AnalyticsGraphCopy;
