import React, { Component, Suspense } from "react";
import LeftMenu from "../../layout/leftMenu";
import $ from "jquery";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
let folderName = window.sessionStorage.getItem("local_page_Folder_Name");
let LocalPage = React.lazy(() =>
import("../Project_Folder/../"+folderName+"/local_page")
);

class Index extends Component {
  componentDidMount() {
    $("#headerName").text("Local Pages");
  }
  render() {
    return (
      <div className="col-md-12">
          <LeftMenu></LeftMenu>
          <Header />
            <Suspense fallback={<div></div>}>
              <section>
                <LocalPage />
              </section>
            </Suspense>
            </div>
    );
  }
}

export default Index;
