import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import $, { data } from "jquery";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import pushNoDataTemplate from "../docs/pushNoDataTemplate.xlsx";
import Auditimage from "../images/left_icons/processing.png";
import DragDropFile from "../audit/dragDropFile";
import * as XLSX from "xlsx";
import DataInput from "../audit/dataInput";
import { backendApi, pythonBackendApi } from "../apiService";
import * as Icon from "react-feather";
const excelMime = [
  "application/vnd.ms-excel",
  "application/msexcel",
  "application/x-msexcel",
  "application/x-ms-excel",
  "application/x-excel",
  "application/x-dos_ms_excel",
  "application/xls",
  "application/x-xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
const csvDownload = "Address Processing ";
var recordData = [];
class GbpMultipleLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AddressCleaningData: [],
      file: null,
      buffer: null,
      uploaded: false,
      dataList: [],
      fileName: "",
      getAddressCleaningDataByID: [],
      gbp: [],
    };
    this.handleFile = this.handleFile.bind(this);
  }
  componentDidMount() {
    this.getGBPLocationsData();
    // this.dataCsallAotuFillExecel()
  }
  getGBPLocationsData() {
    var fullDataArray = [];
    var req = {
      functionName: "notFountClaimList",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        fullDataArray.push(...data.data);
        fullDataArray.push(...data.data3);
        this.setState({
          gbp: fullDataArray,
        });
      } else {
        alert("Failed");
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  saveAndNExtPAge() {
    var saveArray = [];
    var sessionArray = [];
    for (const obj of this.state.dataList) {
      console.log("address", obj);
      let objData = {
        full_name: obj.BusinessName,
        address: obj.Address,
        mobile_no: obj.PhoneNo,
        email_id: obj.VerificationEmail,
        locality: obj.Locality,
        postalCode: obj.PostalCode,
        storeCode: obj.StoreCode,
        administrativeArea: obj.AdministrativeArea,
        account_id: window.sessionStorage.getItem("account_id"),
        site_id:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        location_id: "",
        verification_code: "",
        g_pin: "",
        status: "Push Initiated",
        created_by: window.sessionStorage.getItem("userId"),
      };
      saveArray.push(objData);
    }
    var api = {
      functionName: "insertGbp_localDB",
      objectArray: saveArray,
    };
    backendApi(api).then((data) => {
      if (data.success === "1") {
        window.location.href = `/claim_business/listOfPushSaveLocations/${data.UID}`;
      }
    });
  }
  OutTable = () => {
    return (
      <>
        <div className="col-md-12 tableResponsive table_bulkupload tableResponsive">
          <div className="tableOuterWidth">
            <table className="table fileupload-table mb-0">
              <thead>
                <tr>
                  <th className="col-1">Store Code</th>
                  <th className="col-1">Business Name</th>
                  <th className="col-1">Address</th>
                  <th className="col-3">Phone No</th>
                  <th className="col-3">Verification Email</th>
                  <th className="col-3">Description</th>
                  <th className="col-3">Locality</th>
                  <th className="col-3">PostalCode</th>
                  <th className="col-3">Website Url</th>
                  <th className="col-3">Administrative Area</th>
                </tr>
              </thead>
              <div className="table_Outerscroll">
                {/* {(window.sessionStorage.getItem("pushFromNodata")==0)? */}
                <>
                  {/* {console.log("this.state.dataList", this.state.dataList)} */}

                  {this.state.dataList.map((r, i) => (
                    <tbody key={i} className="table_Outertbody">
                      <tr>
                        <td className="col-1">{r.StoreCode}</td>
                        <td className="col-1">{r.BusinessName}</td>
                        <td className="col-1">{r.Address}</td>
                        <td className="col-1">{r.PhoneNo}</td>
                        <td className="col-1">{r.VerificationEmail}</td>
                        <td className="col-1">{r.Description}</td>
                        <td className="col-1">{r.Locality}</td>
                        <td className="col-1">{r.PostalCode}</td>
                        <td className="col-3">{r.WebsiteUrl}</td>
                        <td className="col-3">{r.AdministrativeArea}</td>
                      </tr>
                    </tbody>
                  ))}
                </>
              </div>
            </table>
          </div>
        </div>
        <div className="button-wrapper mt-2 mb-4">
          <button
            className="btn-cancel ml-3"
            onClick={() => {
              window.location.reload();
            }}
          >
            Cancel
          </button>
          <button
            className="btn-search ml-3"
            onClick={() => this.saveAndNExtPAge()}
          >
            Save & Next
          </button>
        </div>
      </>
    );
  };
  async handleFile(files, e) {
    var buffer;
    if (files) {
      if (!excelMime.includes(files.type)) {
        alert("Please upload valid excel file");
        $(".loder_div").attr("hidden", true);
        return false;
      }
      $(".loder_div").attr("hidden", false);
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          isLoader: 0,
        });
        /* Parse data */
        const ab = e.target.result;
        const wb = XLSX.read(ab, { type: "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { raw: false, header: 1 });
        let DataList = [];
        let DataListTemp = [];
        let keys = [];
        keys = data[0];
        let record = {};
        for (let i = 1; i < data.length; i++) {
          let dd = data[i];
          if (dd.length > 0) {
            for (let j = 0; j < dd.length; j++) {
              record[keys[j].trim().split(" ").join("")] =
                dd[j] === undefined ? "" : dd[j].toString().trim();
            }
            let index = DataList.findIndex(
              (d) =>
                d.StoreCode === record.StoreCode &&
                d.BusinessName === record.BusinessName &&
                d.Address === record.Address &&
                d.Status === record.Status &&
                d.Phone === record.Phone &&
                d.Description === record.Description &&
                d.Locality === record.Locality &&
                d.WebsiteUrl === record.WebsiteUrl &&
                d.AdminstriveArea === record.AdministrativeArea &&
                d.postalCode === record.postalCode
              // d.AdditionalPhone === record.AdditionalPhone &&
              // d.Website === record.Website &&
              // d.Regularhours === record.Regularhours &&
              // d.Storecode === record.Storecode &&
              // d.OpeningDate === record.OpeningDate
            );
            // { console.log(record, "recordrecordrecordrecordrecordrecord") }
            if (index === -1) {
              DataList.push(record);
            }
            DataListTemp.push(record);
            record = {};
          }
        }
        $(".loder_div").attr("hidden", true);
        /* Update state */
        this.setState(
          {
            dataList: DataList,
            fileName: files.name,
            uploaded: true,
            isLoader: 1,
          },
          () => {
            if (DataListTemp.length !== DataList.length) {
              alert("File contains duplicate record(s)");
            }
            // else{this.onSaveGbp()}
          }
        );
      };
      reader.readAsArrayBuffer(files);
    }
  }
  downloadTemplate() {
    let header = [
      //   "Serial No:",
      "Business Name",
      //   "Address",
      "Address",
      "Phone No",
      "Verification Email",
      "Description",
      "Locality",
      "WebsiteUrl",
      "AdminstriveArea",
      "PostalCode",
      //   "Category",
      //   "Status",
      //   "Additional Phone(Phone1&Phone2)",
      //   "Website",
      //   "Regular Hour(s)(Day, Opening Hour:Minutes - Closing Hour:Minutes|etc)",
      //   "More hours",
      //   "Appointment link",
      //   "Products",
      //   "Store code",
      //   "Opening Date(YYYY-MM)",
      //   "Services",
    ];
    console.log(this.state.gbp, " this.state.gbp this.state.gbp");
    let data = this.state.gbp.map((headers) => ({
      "Store Code": headers.reference_code,
      "Business Name": headers.location_name,
      Address: headers.address,
      "Phone No": headers.phone_no ? headers.phone_no : "",
      "Verification Email": "",
      Description:
        window.sessionStorage.getItem("switchUserOrganization") !== ""
          ? window.sessionStorage.getItem("switchUserOrganization")
          : window.sessionStorage.getItem("organization"),
      Locality: headers.address ? headers.address.split(",").at(-3) : "",
      "Website Url": "",
      "Postal Code": headers.address ? headers.address.split(",").pop() : "",
      "Administrative Area": headers.address
        ? headers.address.split(",").at(-2)
        : "",
      //   "Regular Hour(s)(Day, Opening Hour:Minutes - Closing Hour:Minutes|etc)":
      //     "",
      //   "Store code": "",
      //   "Opening Date(YYYY-MM)": "",
    }));
    //let date = "Multiple locations GBP";
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          <Header heading="Bulk Upload" headerImage={Auditimage} />
          <div className="row mb-3 ml-1">
            <Icon.ArrowLeftCircle onClick={() => window.history.back()} />
          </div>
          <div className="container-fluid">
            <div className="file_upload_outer mt-3 container">
              <DragDropFile handleFile={this.handleFile}>
                <div className="form-group formBottom" id="form-fileupload">
                  <DataInput handleFile={this.handleFile} />
                </div>
              </DragDropFile>
              {window.sessionStorage.getItem("pushFromNodata") == 0 ? (
                <>
                  <a
                    href={pushNoDataTemplate}
                    className="col-lg-3 col-md-12 TemplateDowloadDiv"
                  >
                    <a>
                      <Icon.Download /> Download template
                    </a>
                  </a>
                </>
              ) : (
                <div
                  className="col-lg-3 col-md-12 TemplateDowloadDiv"
                  title="Download template"
                  onClick={() => this.downloadTemplate()}
                >
                  {" "}
                  <Icon.Download /> Download template
                </div>
              )}

              {this.state.uploaded === true && this.OutTable()}
            </div>
          </div>
          <Footer />
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}
export default GbpMultipleLocation;
