import React, { Component } from "react";
import "../css/bootstrap.css";
import LiveMonitoringimage from "../images/left_icons/live.png";
import "../css/style.css";
import close from "../images/close.png";
import checked from "../images/checked.png";

import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import $ from "jquery";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import { backendApi, pythonBackendApi } from "../apiService";
import Online08 from "../images/online08.png";
import Online09 from "../images/online09.png";

class Monitoring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vmList: [],
      dataId: "",
      online: [],
    };
  }

  componentDidMount() {
    this.vmList();
    // setInterval(() => {
    //   this.vmLiveStatus();
    // }, 60000);

    $("#vmModal").attr("hidden", true);
  }
  saveVm() {
    var flag = true;
    let vmName = $("#vmName").val();
    let ipAddress = $("#ipAddress").val();
    let username = $("#username").val();
    let password = $("#password").val();
    let active = $("#checkboxid").is(":checked");
    let dataId = $("#dataId").val();
    let status = $("#statusValue").find("option:selected").val();
    if (vmName === "") {
      alert("Vm Name Required");
      flag = false;
    } else if (ipAddress === "") {
      alert("Ip Address Required");
      flag = false;
    } else if (username === "") {
      alert("Username Required");
      flag = false;
    } else if (password === "") {
      alert("Password Required");
      flag = false;
    }
    else if (status === "0") {
      alert("Schedule Required");
      flag = false;
    }

    if (flag == true) {
      $(".loder_div").attr("hidden", false);
      var apidata = {
        functionName: dataId === "" ? "saveVm" : "updateVm",
        userId:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        Id: dataId === "" ? "" : dataId,
        vm_name: vmName,
        vm_ip: ipAddress,
        vm_user: username,
        password: password,
        is_active: active,
        schedule: status
      };
      console.log("apidata", apidata);
      backendApi(apidata).then((data) => {
        if (data.success === "1") {
          if (dataId === "") {
            alert("Vm Added SuccessFully");
          } else {
            alert("Vm Updated SuccessFully");
          }

          $(".loder_div").attr("hidden", true);
          $("#vmModal").attr("hidden", true);
          this.vmList();
        } else {
          alert("Vm Added Failed");
          $(".loder_div").attr("hidden", true);
        }
      });
    }
  }
  vmList() {
    $(".loder_div").attr("hidden", false);
    var apidata = {
      functionName: "vmList",
    };
    backendApi(apidata).then((data) => {
      if (data.success == 1) {
        $(".loder_div").attr("hidden", true);
        this.setState({
          vmList: data.data,
        });
        const online = data.data.filter((obj) => obj.status == "Live");
        this.setState({
          online: online,
        });
      } else {
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  getVmStatus() {
    $(".loder_div").attr("hidden", false);
    var apidata = {
      method: "POST",
      body: JSON.stringify({}),
    };
    pythonBackendApi("checkvmStatus", apidata).then((data) => {
      if (data.success == 1) {
        $(".loder_div").attr("hidden", true);
        this.vmList();
      } else {
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  buttonClick(value) {
    if (!window.confirm("Do you want to Restart VM?")) {
      return false;
    }
    $(".loder_div").attr("hidden", false);
    var apidata = {
      method: "POST",
      body: JSON.stringify({
        masterId: value
      }),
    };
    pythonBackendApi("rebootVm", apidata).then((data) => {
      $(".loder_div").attr("hidden", true);
      alert("Process Started Successfully");
    });
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          <Header heading="Live Monitoring" headerImage={LiveMonitoringimage} />

          <div className="container-fluid">
            <div className="row row-search mb-2">
              <div className="col-md-6">
                <p>
                <Link to="/dashboard" className="back-to-link" title="Back">
                    <Icon.ArrowLeftCircle />
                  </Link>
                  Online Machine:{" "}
                  <span className="onlineVm mr-3">
                    {" "}
                    <b>{this.state.online.length}</b>
                  </span>
                  Offline Machine:{" "}
                  <span className="offlineVm">
                    {" "}
                    <b>{this.state.vmList.length - this.state.online.length}</b>
                  </span>
                </p>
              </div>
              <div className="col-md-6 btnsRight">
                <Link
                  className="btn-addNew mr-2 mb-2"
                  to="#"
                  onClick={() => {
                    $("#vmModal").attr("hidden", false);
                    $("#vmName").val("");
                    $("#ipAddress").val("");
                    $("#username").val("");
                    $("#password").val("");
                    $("#checkboxid").prop("checked", true);
                    $("#dataId").val("");
                    this.setState({
                      dataId: "",
                    });
                  }}
                >
                  Add New VM{" "}
                  <span>
                    <Icon.PlusCircle />{" "}
                  </span>
                </Link>
                <Link
                  className="btn-info btn mr-2"
                  to="#"
                  onClick={() => {
                    this.getVmStatus();
                  }}
                >
                  Get VM Status{" "}
                </Link>
                <Link
                  className="btn-info btn mr-2"
                  to="/liveMonitoringProcessing"
                  // onClick={() => {
                  //   this.getVmStatus();
                  // }}
                >
                  Change VM Schedule{" "}
                </Link>
              </div>
            </div>

            <div className="virtual_div">
              <div className="row">
                {this.state.vmList.map((item) => (
                  <div className="col-lg-2 col-md-3 col-sm-4 col-6 mb-4">
                    <div className="virtual_card">
                      <Icon.Edit
                        onClick={() => {
                          this.setState({
                            dataId: item.id,
                          });
                          $("#vmModal").attr("hidden", false);
                          $("#vmName").val(item.vm_name);
                          $("#ipAddress").val(item.vm_ip);
                          $("#username").val(item.vm_user);
                          $("#password").val(item.password);
                          $("#checkboxid").prop("checked", item.is_active);
                          $("#dataId").val(item.id);
                          $("#statusValue").val(item.schedule);

                        }}
                        className="icon_common virtual_edit_icon"
                      />

                      <div className="virtual_cardbody">
                        <div className="class_monitor_icon">
                          {item.status == "Live" ? (
                            <img src={Online09} />
                          ) : (
                            <img src={Online08} />
                          )}

                          {/* <span className="switch round"></span>
                          <span>
                            <label className="switch_items">
                              <input
                                id={"status" + item.id}
                                name={"status" + item.id}
                                type="checkbox"
                                checked={item.is_active}
                              />
                              <span className="switch round"></span>
                            </label>
                          </span>
                          <hr className="mt-3 my-0" /> */}
                        </div>

                        <ul className="nav flex-column virtual_ul">
                          <li className="nav-item">
                            {/* <div className="virtual_leftspan">
                              <span className="leftspan_style">ID</span>
                              <span className="leftspan_style ml-auto mr-2">
                                :
                              </span>
                            </div> */}
                            <div className="virtual_rightspan">
                              <span>{item.id}</span>
                            </div>
                          </li>
                          <li className="nav-item">
                            <div className="virtual_rightspan">
                              <span>{item.vm_ip}</span>
                            </div>
                          </li>
                          <li className="nav-item">
                            <div className="virtual_rightspan">
                              <span>{item.vm_name}</span>
                            </div>
                          </li>

                          <li className="nav-item">
                            <div className="virtual_rightspan">
                              <span>Schedule:</span><span>{item.schedule}</span>
                            </div>
                          </li>
                          <button
                            id="serviceAreaEditButton"
                            type="button"
                            className="btn-save button-sm"
                            onClick={() =>
                              this.buttonClick(item.id)
                            }
                          >
                            Restart
                          </button>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <span id="dataId" hidden></span>
          </div>
          <div className="module_popup vmModal" id="vmModal" hidden>
            <div className="module_innerpopup module_bodypop">
              <div className="module_headdiv">
                {this.state.dataId ? "Update New VM" : "Add New VM"}
                <div className="module_close">
                  <Icon.XCircle
                    color="red"
                    onClick={() => {
                      $("#vmModal").attr("hidden", true);
                      $("#vmName").val("");
                      $("#ipAddress").val("");
                      $("#username").val("");
                      $("#password").val("");
                      $("#statusValue").val("");
                    }}
                  />
                </div>
              </div>
              <div className="module_body">
                <h5 className="mainHead_popup">
                  {this.state.dataId ? "Update New VM" : "Add New VM"}
                </h5>
                <div className="row tag_formLabel ">
                  <label for="" className="m-0 col-sm-3">
                    Vm Name
                  </label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" id="vmName" />
                  </div>
                </div>
                <div className="row tag_formLabel ">
                  <label for="" className="col-sm-3 col-form-label">
                    Ip Address
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="ipAddress"
                    />
                  </div>
                </div>
                <div className="row tag_formLabel ">
                  <label for="" className="col-sm-3 col-form-label">
                    Username
                  </label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" id="username" />
                  </div>
                </div>
                <div className="row tag_formLabel ">
                  <label for="" className="col-sm-3 col-form-label">
                    Password
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                    />
                  </div>
                </div>
                <div className="row tag_formLabel ">
                  <label for="" className="col-sm-3 col-form-label">
                    Schedule
                  </label>
                  <div className="col-sm-9">
                    <select className="form-control" id="statusValue">
                      {" "}
                      <option value="0">Select Schedule </option>
                      <option value="Audit">Audit</option>
                      <option value="Competitor Analysis">Competitor Analysis</option>
                      <option value="Product Catalog">Product Catalog</option>
                      <option value="Insights">Insights</option>
                      <option value="Fraud">Fraud</option>
                      <option value="Visibility">Visibility</option>
                      <option value="Review">Review</option>
                    </select>
                  </div>
                </div>
                <div className="row tag_formLabel ">
                  <label for="" className="col-sm-3 col-form-label">
                    Active
                  </label>
                  <div className="col-sm-9">
                    <span className="switch round"></span>
                    <span>
                      <label className="switch_items">
                        <input
                          className="form-control w-auto mt-2 d-none"
                          name="checkboxid"
                          type="checkbox"
                          id="checkboxid"
                          disabled={this.state.dataId ? false : true}
                        />
                        <span className="switch round"></span>
                      </label>
                    </span>
                    <hr className="mt-3 my-0" />
                  </div>
                </div>


                {/* <input
                      type="checkbox"
                      className="form-control w-auto mt-2"
                      id="checkboxid"
                      disabled={this.state.dataId ? false : true}
                    /> */}
              </div>

              <div class="button-wrapper">
                <button
                  type="button"
                  class="btn-save"
                  onClick={() => this.saveVm()}
                >
                  Save
                </button>
                <button
                  type="button"
                  class="btn-cancel"
                  onClick={() => {
                    $("#vmModal").attr("hidden", true);
                    $("#vmName").val("");
                    $("#ipAddress").val("");
                    $("#username").val("");
                    $("#password").val("");
                    $("#statusValue").val("");
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}

export default Monitoring;
