import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { backendApi } from "../apiService";
import moment from "moment";

const RequestInsights = ({ onUpdateInsightsData }) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${day}-${month.toUpperCase()}-${year}`;
  };

  const isValidDateRange = () => {
    if (!startDate || !endDate) return false;

    const eightDaysAgo = new Date();
    eightDaysAgo.setDate(eightDaysAgo.getDate() - 8);

    return (
      startDate <= endDate &&
      endDate <= eightDaysAgo
    );
  };

  const requestInsightData = () => {
    if (!isValidDateRange()) {
        alert("Invalid date range. Please check your dates.");
        return;
      }
    const apiReq = {
      functionName: "requestInsightData",
      siteId:  window.sessionStorage.getItem("switchingOrgId") === ""
      ? window.sessionStorage.getItem("organizationId")
      : window.sessionStorage.getItem("switchingOrgId"),
      sec_user_id:  window.sessionStorage.getItem("userId"),
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      action: "Insert",
    };
    console.log("req", apiReq);
    backendApi(apiReq).then((data) => {
      if (data.success == "1") {
        alert("Insights Data Requested Succesfully.The requested data will be reflected within 2 to 4 hours");
        setStartDate();
        setEndDate();
        onUpdateInsightsData();
      } else {
        alert("Error requesting Insights Data");
      }
    });
  };

  return (
    <div className="row">
      <div className="row mt-3 ">
        <div className="col-lg-3 col-md-6 tag_formCntrl">
          <label className="m-0">
            Start Date<sup style={{ color: "red" }}>*</sup>{" "}
          </label>
          <DatePicker
            onChange={(date) => setStartDate(date)}
            selected={startDate}
            name="startDate"
            showMonthDropdown
            dateFormat="dd/MM/yyyy"
            placeholderText="Start Date"
            className="form-control"
          />
        </div>

        <div className="col-lg-3 col-md-6 tag_formCntrl">
          <label className="m-0">
            End Date<sup style={{ color: "red" }}>*</sup>{" "}
          </label>
          <DatePicker
            onChange={(date) => setEndDate(date)}
            selected={endDate}
            name="startDate"
            showMonthDropdown
            dateFormat="dd/MM/yyyy"
            placeholderText="End Date"
            className="form-control"
          />
        </div>
        <div className="col-lg-6 col-md-6 tag_formCntrl insight-margin" >
          
          <button
          type="button"
          className="btn-save ml-3 "
          onClick={() => requestInsightData()}
        >
          Request
        </button>
        </div>
       
      </div>
    </div>
  );
};

export default RequestInsights;
