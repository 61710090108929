import React, { Component } from "react";

class chooseOrganization extends Component {

    render() {
        return (
            <div className="row col-md-12 userCountDivlist chooseOrgList"> 
            <div className="organiztionChoose">
              <span className="chooseOrgName">Please Choose An Organization</span>
            </div>
          </div>
        );
    }
}
export default chooseOrganization;
