import React, { useEffect } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Dashboardimage from "../images/left_icons/business.png";
import LeftMenu from "../layout/leftMenu";
import { useState } from "react";
import $ from "jquery";
import * as Icon from "react-feather";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { apiCallingPost } from "../apiService";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

let stringComparison = require("string-comparison");
let cos = stringComparison.levenshtein;
let matchingLocationId;

export default function PushNoDataFromGoogle() {
  const [claimedData, setClaimedData] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [claimedTitle, setclaimedTitle] = useState("");
  const [selectPlace, setSelectPlace] = useState("");
  const [selectPlaceId, setSelectPlaceId] = useState(0);
  const [flag, setflag] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    getauditGoogleData();
    claimAddress(location.state.auditClaimData);
  }, []);

  const getauditGoogleData = () => {
    $(".loder_div").attr("hidden", false);
    let newArray = [];
    var apiJson = {
      functionName: "getDataForClaimFromGoogle",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      account_id: window.sessionStorage.getItem("account_id"),
    };
    apiCallingPost(apiJson).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        data.data.forEach((item) => {
          let auditClaimAddress = location.state.auditClaimData.address;
          let auditCompare = item.addressLines;
          let percentage = cos.similarity(auditClaimAddress, auditCompare);
          item.percentage = percentage * 100;
          newArray.push(item);
        });
        newArray.sort(function (a, b) {
          return b.percentage - a.percentage;
        });
        setDataList(newArray);
      } else {
        setTimeout(() => {
          redirectToGBPFrom(location.state.auditClaimData);
        }, "3000");
      }
    });
  };
  const claimAddress = (data) => {
    setclaimedTitle(data.location_name);
    var apiJson = {
      functionName: "getDataFromAuditClaim",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      query: data.location_name + "," + data.address,
    };
    apiCallingPost(apiJson).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (Object.keys(data.data).length > 0 && data.success === "1") {
        setClaimedData(data.data.googleLocations);
        dataList.forEach((firstObject) =>
          data.data.googleLocations.forEach((secondObject) => {
            if (firstObject.location.title === secondObject.title) {
              alert(
                "tableListingGoogle" + secondObject.title.split(" ").join("_")
              );
              $(
                "#tableListingGoogle" + secondObject.title.split(" ").join("_")
              ).addClass("greenActive");
            }
          })
        );
      }
      // } else {
      //   setTimeout(() => {
      //     redirectToGBPFrom(location.state.auditClaimData);
      //   }, "3000");
      //   setClaimedData([]);
      // }
    });
  };
  // const deleteDuplicationLocation = (id) => {
  //   if (!window.confirm("Do you want to delete this location?")) {
  //     return false;
  //   }
  //   $(".loder_div").attr("hidden", false);
  //   const req = {
  //     functionName: "deleteDuplicateLocation",
  //     site_id:
  //       window.sessionStorage.getItem("switchingOrgId") === ""
  //         ? window.sessionStorage.getItem("organizationId")
  //         : window.sessionStorage.getItem("switchingOrgId"),
  //     location_id: id,
  //   };
  //   apiCallingPost(req).then((data) => {
  //     $(".loder_div").attr("hidden", true);
  //     if (data.data.success === "1") {
  //       alert("Location deleted successfully!");
  //     } else {
  //       alert("Something went wrong in deleting location");
  //     }
  //   });
  // };
  // const mergeLocation = (id) => {
  //   if (!window.confirm("Do you want to merge this location?")) {
  //     return false;
  //   }
  //   if (selectPlace === "") {
  //     alert("Select location to be merged");
  //     return false;
  //   }
  //   $(".loder_div").attr("hidden", false);
  //   const req = {
  //     functionName: "mergeDuplicateLocation",
  //     site_id:
  //       window.sessionStorage.getItem("switchingOrgId") === ""
  //         ? window.sessionStorage.getItem("organizationId")
  //         : window.sessionStorage.getItem("switchingOrgId"),
  //     location_id: id,
  //     placeId: selectPlaceId,
  //   };
  //   apiCallingPost(req).then((data) => {
  //     $(".loder_div").attr("hidden", true);
  //     if (data.data.success === "1") {
  //       alert("Location merged successfully!");
  //     } else {
  //       alert("Something went wrong in merging location");
  //     }
  //   });
  // };
  const selectPlaceLocation = (place, id, e) => {
    if (selectPlaceId !== 0) {
      $("#selectPlace" + selectPlaceId).removeClass("active");
      $("#flexCheckDefault" + selectPlaceId).prop("checked", false);
    }
    if (e.target.checked === true) {
      $("#selectPlace" + id).addClass("active");
      setSelectPlaceId(id);
      setSelectPlace(place.location.metadata.placeId);
    } else {
      setSelectPlace("");
      $("#selectPlace" + id).removeClass("active");
      setSelectPlaceId(0);
    }
  };

  const requesttoAccess = () => {
    if (!window.confirm("Do you want to Request To Access?")) {
      return false;
    }
    $(".loder_div").attr("hidden", false);
    var apiJson = {
      functionName: "claim_initiated_Save",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      account_id: window.sessionStorage.getItem("account_id"),
      audit_data_from_excel_id: location.state.auditClaimData.id,
      created_by: location.state.auditClaimData.created_by,
      comment: "Push Initiated",
    };
    apiCallingPost(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        alert("Request To Access Succesfully");
      } else {
        $(".loder_div").attr("hidden", true);
        alert("Request To Access Failed");
      }
    });
  };
  const redirectToGBPFrom = (data) => {
    navigate("/claim_business/PushNodataFromGBP", {
      state: {
        auditClaimData: data,
      },
    });
    // window.location.href = "/claim_business/PushNodataFromGoogle";
  };
  const pushClick = (pushData) => {
    // window.sessionStorage.removeItem("dataPushData")
    navigate("/createNewLocation", {
      state: {
        auditClaimData: pushData,
      },
    });
  };
  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Not Found" headerImage={Dashboardimage} />
        <div className="container">
          <div className="row">
            {/* <div className="col-md-12">
              <h3 className="popup-head">Google Locations</h3>
            </div> */}
            <div className="row mb-3 ml-1">
              <Link to="/claimBusiness" className="back-to-link mr-3">
                <Icon.ArrowLeftCircle />
              </Link>
            </div>
            <div className="w-100">
              <div className="sub_from_audit">From Audit</div>
            </div>
            <div className="col-md-12 tableResponsive">
              <div className="tableOuterWidth">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="col-1">ID</th>
                      <th className="col-2" style={{ maxWidth: "12%" }}>
                        Business Name
                      </th>
                      <th className="col-7" style={{ maxWidth: "63%" }}>
                        Address
                      </th>
                      {/* <th className="col-1">Status</th> */}
                    </tr>
                  </thead>
                  <div class="table_Outerscroll">
                    <tbody className="table_Outertbody">
                      <tr>
                        <td className="col-1">
                          {console.log(location.state.auditClaimData.id)}
                          {location.state.auditClaimData.id}
                        </td>
                        <td className="col-2" style={{ maxWidth: "12%" }}>
                          {location.state.auditClaimData.location_name}
                        </td>
                        <td className="col-7" style={{ maxWidth: "63%" }}>
                          {location.state.auditClaimData.address}
                        </td>
                        <td>
                          <button
                            className="btn btn-primary"
                            onClick={(e) =>
                              pushClick(location.state.auditClaimData)
                            }
                          >
                            Push
                          </button>
                        </td>
                        {/* <td className="col-1">
                          {location.state.auditClaimData.status}
                        </td> */}
                      </tr>
                    </tbody>
                  </div>
                </table>
              </div>
            </div>

            <>
              <div className="row col-lg-12 claimPrim">
                <>
                  <div className="col-lg-4">
                    <div className="w-100">
                      <div className="sub_from_audit">From Google</div>
                    </div>
                    {claimedData
                      .filter((obj) => obj.location.title == claimedTitle)
                      .map((data, i) => {
                        return (
                          <div className="content-sub-wrapper">
                            {console.log(claimedData, "claimedDataclaimedData")}
                            <div
                              id={"selectPlace" + (i + 1)}
                              className="checkWrap"
                            >
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onChange={(e) =>
                                  selectPlaceLocation(data, i + 1, e)
                                }
                                id={"flexCheckDefault" + (i + 1)}
                              />
                            </div>
                            <div
                              className="content-sub"
                              key={data.location.title}
                            >
                              {data.requestAdminRightsUri !== "" && (
                                <div
                                  className="col-12 text-right mb-2"
                                  style={{ top: "-10px", height: "0" }}
                                >
                                  <div className="verified">Verified</div>
                                </div>
                              )}
                              <div className="row">
                                <div className="col-4">
                                  <label className="col-form-label">
                                    Title
                                  </label>
                                </div>
                                <div className="col-1">
                                  <label className="col-form-label">:</label>
                                </div>
                                <div className="col-6">
                                  {" "}
                                  <label className="col-form-label">
                                    {" "}
                                    {data.location.title}
                                  </label>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-4">
                                  <label className="col-form-label">
                                    Phone No.
                                  </label>
                                </div>
                                <div className="col-1">
                                  <label className="col-form-label">:</label>
                                </div>
                                <div className="col-6">
                                  {" "}
                                  <label className="col-form-label">
                                    {" "}
                                    {data.location.phoneNumbers.primaryPhone}
                                  </label>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-4">
                                  <label className="col-form-label">
                                    Region Code
                                  </label>
                                </div>
                                <div className="col-1">
                                  <label className="col-form-label">:</label>
                                </div>
                                <div className="col-6">
                                  {" "}
                                  <label className="col-form-label">
                                    {" "}
                                    {data.location.storefrontAddress.regionCode}
                                  </label>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-4">
                                  <label className="col-form-label">
                                    Administartion
                                  </label>
                                </div>
                                <div className="col-1">
                                  <label className="col-form-label">:</label>
                                </div>
                                <div className="col-6">
                                  {" "}
                                  <label className="col-form-label">
                                    {" "}
                                    {
                                      data.location.storefrontAddress
                                        .administrativeArea
                                    }
                                  </label>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-4">
                                  <label className="col-form-label">
                                    Locality
                                  </label>
                                </div>
                                <div className="col-1">
                                  <label className="col-form-label">:</label>
                                </div>
                                <div className="col-6">
                                  {" "}
                                  <label className="col-form-label">
                                    {" "}
                                    {data.location.storefrontAddress.locality}
                                  </label>
                                </div>
                              </div>
                              <div className="address-section-bg row ml-0">
                                <div className="col-4 pl-0">
                                  <label className="col-form-label">
                                    Address
                                  </label>
                                </div>
                                <div className="col-1 pl-0">
                                  <label className="col-form-label">:</label>
                                </div>
                                <div className="col-6 pl-2">
                                  {data.location.storefrontAddress.addressLines.map(
                                    (data) => (
                                      <div className="col-form-label py-0">
                                        {" "}
                                        {data}
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-4">
                                  <label className="col-form-label">
                                    Latitude
                                  </label>
                                </div>
                                <div className="col-1">
                                  <label className="col-form-label">:</label>
                                </div>
                                <div className="col-6">
                                  {" "}
                                  <label className="col-form-label">
                                    {" "}
                                    {data.location.latlng.latitude}
                                  </label>
                                </div>

                                <div className="col-4">
                                  <label className="col-form-label">
                                    Longitude
                                  </label>
                                </div>
                                <div className="col-1">
                                  <label className="col-form-label">:</label>
                                </div>
                                <div className="col-6">
                                  {" "}
                                  <label className="col-form-label">
                                    {" "}
                                    {data.location.latlng.longitude}
                                  </label>
                                </div>
                              </div>
                              <hr></hr>
                              <div
                                className="button-wrapper text-center"
                                id="requestAccessUrl"
                              >
                                <button
                                  class="btn-cancel"
                                  onClick={() => window.history.back()}
                                >
                                  Cancel
                                </button>

                                {data.requestAdminRightsUri ? (
                                  <a
                                    href={data.requestAdminRightsUri}
                                    target="_blank"
                                  >
                                    <button
                                      class="btn-submit"
                                      onClick={requesttoAccess}
                                    >
                                      Request to Access
                                    </button>
                                  </a>
                                ) : (
                                  <a
                                    href={
                                      "https://business.google.com/create?fp=" +
                                      data.location.metadata.mapsUri.split(
                                        "cid="
                                      )[1] +
                                      "&getstarted&lis=1"
                                    }
                                    target="_blank"
                                  >
                                    <button class="btn-submit">
                                      Request Claim
                                    </button>
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </>

                {/* )} */}
                <div className="col-lg-8">
                  <div className="w-100">
                    <div className="sub_from_audit">From GBP</div>
                  </div>
                  <div className="tableResponsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="col-1">Title</th>
                          <th className="col-2">Address</th>
                          <th className="col-2">Created On</th>
                          <th className="col-2">Administrative Area</th>
                          <th className="col-2"></th>
                        </tr>
                      </thead>
                      <div class="table_Outerscroll">
                        {dataList.length !== 0 ? (
                          <>
                            {dataList.map((item, i) => {
                              let active = false;
                              let colour = "yellow";
                              //! In the case of permanently deleted status, the class for redActive has been added in css.
                              //! Search for redActive
                              //? Condition for checking duplicate starts here
                              // item.has_voice_of_merchant === 0 &&
                              //   item.can_modify_service_list === 0 &&
                              //   item.place_id != "" &&
                              //   (colour = "yellow");
                              //? Condition for checking duplicate ends here
                              claimedData.length !== 0 &&
                                claimedData.forEach((firstObject, list) => {
                                  if (
                                    item.title.includes(
                                      firstObject.location.title
                                    )
                                  ) {
                                    active = true;
                                    $("#requestAccessUrl").attr("hidden", true);
                                  }
                                });
                              return (
                                <>
                                  {claimedData.length !== 0 ? (
                                    <>
                                      {active === true && (
                                        <tbody className="table_Outertbody">
                                          <tr
                                            className={
                                              active === true
                                                ? i == 0
                                                  ? "table_Outertbody"
                                                  : i == 1
                                                  ? "table_Outertbody lightGreenActive"
                                                  : "table_Outertbody yellowActive"
                                                : "table_Outertbody"
                                            }
                                          >
                                            <td className="col-1">
                                              {item.title}
                                            </td>
                                            <td className="col-2">
                                              {item.addressLines}
                                            </td>
                                            <td className="col-2">
                                              {moment(item.created_on).format(
                                                "DD MMM YYYY hh:mm a"
                                              )}
                                            </td>
                                            <td className="col-2">
                                              {item.locality} ,{" "}
                                              {item.administrativeArea}
                                            </td>
                                            <td className="col-2">
                                              {item.has_voice_of_merchant ===
                                                1 && (
                                                <p>
                                                  <Icon.CheckCircle
                                                    style={{ color: "GREEN" }}
                                                    className="icon_common"
                                                  />
                                                  Verified
                                                </p>
                                              )}
                                              {item.has_voice_of_merchant ===
                                                0 &&
                                                item.can_modify_service_list ===
                                                  0 &&
                                                item.place_id != "" && (
                                                  <>
                                                    <p>
                                                      <Icon.AlertTriangle
                                                        style={{ color: "RED" }}
                                                        className="icon_common"
                                                      />
                                                      Duplicate
                                                    </p>
                                                    <Icon.Trash2
                                                      color="red"
                                                      className="mr-2"
                                                      // onClick={() =>
                                                      //   deleteDuplicationLocation(
                                                      //     item.location_id
                                                      //   )
                                                      // }
                                                    >
                                                      Delete
                                                    </Icon.Trash2>
                                                    <button
                                                      className="btn btn-info"
                                                      // onClick={() =>
                                                      //   mergeLocation(
                                                      //     item.location_id
                                                      //   )
                                                      // }
                                                    >
                                                      Merge
                                                    </button>
                                                  </>
                                                )}

                                              {item.has_voice_of_merchant ===
                                                0 &&
                                                item.place_id == "" && (
                                                  <p>
                                                    <Icon.CheckCircle
                                                      style={{
                                                        color: "#e5a924",
                                                      }}
                                                      className="icon_common"
                                                    />
                                                    Pending edits
                                                  </p>
                                                )}
                                            </td>
                                          </tr>
                                        </tbody>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <tbody className="table_Outertbody">
                                        <tr
                                          className={
                                            active === true
                                              ? "table_Outertbody greenActive"
                                              : "table_Outertbody"
                                          }
                                        >
                                          <td className="col-1">
                                            {item.title}
                                          </td>
                                          <td className="col-2">
                                            {item.addressLines}
                                          </td>
                                          <td className="col-2">
                                            {moment(item.created_on).format(
                                              "DD MMM YYYY hh:mm a"
                                            )}
                                          </td>
                                          <td className="col-2">
                                            {item.locality} ,{" "}
                                            {item.administrativeArea}
                                          </td>
                                          <td className="col-2">
                                            <p>
                                              <Icon.CheckCircle
                                                style={{ color: "GREEN" }}
                                                className="icon_common"
                                              />
                                              {item.percentage.toFixed(2)}
                                              {"% match"}
                                            </p>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <tr>No Data Found</tr>
                          </>
                        )}
                      </div>
                    </table>
                  </div>
                </div>
              </div>
            </>
          </div>
          {/* <div className="content-sub-wrapper">
            {claimedData
              .filter((obj) => obj.location.title === claimedTitle)
              .map((data) => {
                console.log("hiiiii", data);
                return (
                  <div className="content-sub" key={data.location.title}>
                    <div className="row">
                      <div className="col-3">
                        <label className="col-form-label">Title</label>
                      </div>
                      <div className="col-1">
                        <label className="col-form-label">:</label>
                      </div>
                      <div className="col-7">
                        {" "}
                        <label className="col-form-label">
                          {" "}
                          {data.location.title}
                        </label>
                      </div>
                    </div>
                    <div className="address-section-bg row ml-0">
                      <div className="col-3 pl-0">
                        <label className="col-form-label">Address</label>
                      </div>
                      <div className="col-1 pl-0">
                        <label className="col-form-label">:</label>
                      </div>
                      <div className="col-7 pl-2">
                        {data.location.storefrontAddress.addressLines.map(
                          (data) => (
                            <div className="col-form-label py-0"> {data},</div>
                          )
                        )}
                        {data.location.storefrontAddress.locality},<br />
                        {data.location.storefrontAddress.administrativeArea}
                        ,<br />
                        {data.location.storefrontAddress.postalCode}
                      </div>
                    </div>
                    <hr></hr>
                    <div className="button-wrapper text-center">
                      <button
                        class="btn-cancel"
                        onClick={() => this.cancelPopUp()}
                      >
                        Cancel
                      </button>

                      {data.requestAdminRightsUri ? (
                        <a href={data.requestAdminRightsUri} target="_blank">
                          <button class="btn-submit">Request to Access</button>
                        </a>
                      ) : (
                        <a
                          href={
                            "https://business.google.com/create?fp=" +
                            data.location.metadata.mapsUri.split("cid=")[1] +
                            "&getstarted&lis=1"
                          }
                          target="_blank"
                        >
                          <button class="btn-submit">Request Claim</button>
                        </a>
                      )}
                    </div>
                  </div>
                );
              })}
          </div> */}
          {/* <div className="col-md-4">
            <div className="content-sub-wrapper">
              {claimedData
                .filter((obj) => obj.location.title === claimedTitle)
                .map((data) => (
                  <div className="content-sub" key={data.location.title}>
                    {data.requestAdminRightsUri !== "" && (
                      <div
                        className="col-12 text-right"
                        style={{ top: "-10px", height: "0" }}
                      >
                        <div className="verified">Verified</div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-3">
                        <label className="col-form-label">Title</label>
                      </div>
                      <div className="col-1">
                        <label className="col-form-label">:</label>
                      </div>
                      <div className="col-7">
                        {" "}
                        <label className="col-form-label">
                          {" "}
                          {data.location.title}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <label className="col-form-label">Phone No.</label>
                      </div>
                      <div className="col-1">
                        <label className="col-form-label">:</label>
                      </div>
                      <div className="col-7">
                        {" "}
                        <label className="col-form-label">
                          {" "}
                          {data.location.phoneNumbers.primaryPhone}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <label className="col-form-label">Region Code</label>
                      </div>
                      <div className="col-1">
                        <label className="col-form-label">:</label>
                      </div>
                      <div className="col-7">
                        {" "}
                        <label className="col-form-label">
                          {" "}
                          {data.location.storefrontAddress.regionCode}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <label className="col-form-label">Administartion</label>
                      </div>
                      <div className="col-1">
                        <label className="col-form-label">:</label>
                      </div>
                      <div className="col-7">
                        {" "}
                        <label className="col-form-label">
                          {" "}
                          {data.location.storefrontAddress.administrativeArea}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <label className="col-form-label">Locality</label>
                      </div>
                      <div className="col-1">
                        <label className="col-form-label">:</label>
                      </div>
                      <div className="col-7">
                        {" "}
                        <label className="col-form-label">
                          {" "}
                          {data.location.storefrontAddress.locality}
                        </label>
                      </div>
                    </div>
                    <div className="address-section-bg row ml-0">
                      <div className="col-3 pl-0">
                        <label className="col-form-label">Address</label>
                      </div>
                      <div className="col-1 pl-0">
                        <label className="col-form-label">:</label>
                      </div>
                      <div className="col-7 pl-2">
                        {data.location.storefrontAddress.addressLines.map(
                          (data) => (
                            <div className="col-form-label py-0"> {data}</div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 row">
                        <div className="col-3">
                          <label className="col-form-label">Latitude</label>
                        </div>
                        <div className="col-1">
                          <label className="col-form-label">:</label>
                        </div>
                        <div className="col-7">
                          {" "}
                          <label className="col-form-label">
                            {" "}
                            {data.location.latlng.latitude}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="col-3">
                          <label className="col-form-label">Longitude</label>
                        </div>
                        <div className="col-1">
                          <label className="col-form-label">:</label>
                        </div>
                        <div className="col-7">
                          {" "}
                          <label className="col-form-label">
                            {" "}
                            {data.location.latlng.longitude}
                          </label>
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="button-wrapper text-center">
                      <button
                        class="btn-cancel"
                        onClick={() => this.cancelPopUp()}
                      >
                        Cancel
                      </button>

                      {data.requestAdminRightsUri ? (
                        <a href={data.requestAdminRightsUri} target="_blank">
                          <button class="btn-submit">Request to Access</button>
                        </a>
                      ) : (
                        <a
                          href={
                            "https://business.google.com/create?fp=" +
                            data.location.metadata.mapsUri.split("cid=")[1] +
                            "&getstarted&lis=1"
                          }
                          target="_blank"
                        >
                          <button class="btn-submit">Request Claim</button>
                        </a>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div> */}
          {/* <div className="col-md-4">
              <div className="content-sub-wrapper">
                {claimedData
                  .filter((obj) => obj.location.title === claimedTitle)
                  .map((data) => (
                    <div className="content-sub" key={data.location.title}>
                      <div className="row">
                        <div className="col-3">
                          <label className="col-form-label">Title</label>
                        </div>
                        <div className="col-1">
                          <label className="col-form-label">:</label>
                        </div>
                        <div className="col-7">
                          {" "}
                          <label className="col-form-label">
                            {" "}
                            {data.location.title}
                          </label>
                        </div>
                      </div>

                      <div className="address-section-bg row ml-0">
                        <div className="col-3 pl-0">
                          <label className="col-form-label">Address</label>
                        </div>
                        <div className="col-1 pl-0">
                          <label className="col-form-label">:</label>
                        </div>
                        <div className="col-7 pl-2">
                          {data.location.storefrontAddress.addressLines.map(
                            (data) => (
                              <div className="col-form-label py-0">
                                {" "}
                                {data},
                              </div>
                            )
                          )}
                          {data.location.storefrontAddress.locality},
                          {data.location.storefrontAddress.administrativeArea},
                          {data.location.storefrontAddress.postalCode}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 row">
                          <div className="col-3">
                            <label className="col-form-label">Latitude</label>
                          </div>
                          <div className="col-1">
                            <label className="col-form-label">:</label>
                          </div>
                          <div className="col-7">
                            {" "}
                            <label className="col-form-label">
                              {" "}
                              {data.location.latlng.latitude}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div className="col-3">
                            <label className="col-form-label">Longitude</label>
                          </div>
                          <div className="col-1">
                            <label className="col-form-label">:</label>
                          </div>
                          <div className="col-7">
                            {" "}
                            <label className="col-form-label">
                              {" "}
                              {data.location.latlng.longitude}
                            </label>
                          </div>
                        </div>
                      </div>
                      <hr></hr>
                      <div className="button-wrapper text-center">
                        <button
                          class="btn-cancel"
                          onClick={() => this.cancelPopUp()}
                        >
                          Cancel
                        </button>

                        {data.requestAdminRightsUri ? (
                          <a href={data.requestAdminRightsUri} target="_blank">
                            <button class="btn-submit">
                              Request to Access
                            </button>
                          </a>
                        ) : (
                          <a
                            href={
                              "https://business.google.com/create?fp=" +
                              data.location.metadata.mapsUri.split("cid=")[1] +
                              "&getstarted&lis=1"
                            }
                            target="_blank"
                          >
                            <button class="btn-submit">Request Claim</button>
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div> */}
        </div>
        {/* <div className="popup-overlay" id="markedSubModal">
            <div className="popup-contentDivSm">
              <div className="modal-popup pb-3">
                <div className="module_close">
                  <Icon.XCircle
                    color="red"
                    onClick={() => {
                      setclaimedTitle("");
                      $("#markedSubModal").attr("hidden", true);
                    }}
                  />
                </div>
                <div className="content">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="popup-head">Google Locations</h3>
                    </div>
                  </div>
                  <hr className="mt-0"></hr>
                  <div className="content-sub-wrapper">
                    {claimedData
                      .filter((obj) => obj.location.title === claimedTitle)
                      .map((data) => (
                        <div className="content-sub" key={data.location.title}>
                          {data.requestAdminRightsUri !== "" && (
                            <div
                              className="col-12 text-right"
                              style={{ top: "-10px", height: "0" }}
                            >
                              <div className="verified">Verified</div>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-3">
                              <label className="col-form-label">Title</label>
                            </div>
                            <div className="col-1">
                              <label className="col-form-label">:</label>
                            </div>
                            <div className="col-7">
                              {" "}
                              <label className="col-form-label">
                                {" "}
                                {data.location.title}
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3">
                              <label className="col-form-label">
                                Phone No.
                              </label>
                            </div>
                            <div className="col-1">
                              <label className="col-form-label">:</label>
                            </div>
                            <div className="col-7">
                              {" "}
                              <label className="col-form-label">
                                {" "}
                                {data.location.phoneNumbers.primaryPhone}
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3">
                              <label className="col-form-label">
                                Region Code
                              </label>
                            </div>
                            <div className="col-1">
                              <label className="col-form-label">:</label>
                            </div>
                            <div className="col-7">
                              {" "}
                              <label className="col-form-label">
                                {" "}
                                {data.location.storefrontAddress.regionCode}
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3">
                              <label className="col-form-label">
                                Administartion
                              </label>
                            </div>
                            <div className="col-1">
                              <label className="col-form-label">:</label>
                            </div>
                            <div className="col-7">
                              {" "}
                              <label className="col-form-label">
                                {" "}
                                {
                                  data.location.storefrontAddress
                                    .administrativeArea
                                }
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3">
                              <label className="col-form-label">Locality</label>
                            </div>
                            <div className="col-1">
                              <label className="col-form-label">:</label>
                            </div>
                            <div className="col-7">
                              {" "}
                              <label className="col-form-label">
                                {" "}
                                {data.location.storefrontAddress.locality}
                              </label>
                            </div>
                          </div>
                          <div className="address-section-bg row ml-0">
                            <div className="col-3 pl-0">
                              <label className="col-form-label">Address</label>
                            </div>
                            <div className="col-1 pl-0">
                              <label className="col-form-label">:</label>
                            </div>
                            <div className="col-7 pl-2">
                              {data.location.storefrontAddress.addressLines.map(
                                (data) => (
                                  <div className="col-form-label py-0">
                                    {" "}
                                    {data}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 row">
                              <div className="col-3">
                                <label className="col-form-label">
                                  Latitude
                                </label>
                              </div>
                              <div className="col-1">
                                <label className="col-form-label">:</label>
                              </div>
                              <div className="col-7">
                                {" "}
                                <label className="col-form-label">
                                  {" "}
                                  {data.location.latlng.latitude}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6 row">
                              <div className="col-3">
                                <label className="col-form-label">
                                  Longitude
                                </label>
                              </div>
                              <div className="col-1">
                                <label className="col-form-label">:</label>
                              </div>
                              <div className="col-7">
                                {" "}
                                <label className="col-form-label">
                                  {" "}
                                  {data.location.latlng.longitude}
                                </label>
                              </div>
                            </div>
                          </div>
                          <hr></hr>
                          <div className="button-wrapper text-center">
                            <button
                              class="btn-cancel"
                              onClick={() => this.cancelPopUp()}
                            >
                              Cancel
                            </button>

                            {data.requestAdminRightsUri ? (
                              <a
                                href={data.requestAdminRightsUri}
                                target="_blank"
                              >
                                <button class="btn-submit">
                                  Request to Access
                                </button>
                              </a>
                            ) : (
                              <a
                                href={
                                  "https://business.google.com/create?fp=" +
                                  data.location.metadata.mapsUri.split(
                                    "cid="
                                  )[1] +
                                  "&getstarted&lis=1"
                                }
                                target="_blank"
                              >
                                <button class="btn-submit">
                                  Request Claim
                                </button>
                              </a>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
}
