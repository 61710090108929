import React, { Component } from "react";
import "../../css/bootstrap.css";
import "../../css/style.css";
import deleteImg from "../../images/close.png";
import Checked from "../../images/checked.png";
import Edit from "../../images/edit.png";
import LeftMenu from "../../layout/leftMenu";
import { backendApi } from "../../apiService";
import { Link } from "react-router-dom";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import * as Icon from "react-feather";
import rolesimage from "../../images/left_icons/roles.png";

import $ from "jquery";

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteList: [],
      roleList: [],
      approved: false,
      userID: window.sessionStorage.getItem("userId"),
      isLogin: window.sessionStorage.getItem("isLogin"),
      userRole: window.sessionStorage.getItem("role"),
      userOrganization: window.sessionStorage.getItem("organization"),
      modules: [
        {
          moduleId: 1,
          moduleName: "Module Management",
        },
        {
          moduleId: 2,
          moduleName: "User Management",
        },
        {
          moduleId: 3,
          moduleName: "Role Management",
        },
        {
          moduleId: 4,
          moduleName: "Product Catalog",
        },
        {
          moduleId: 5,
          moduleName: "Local Page CMS",
        },
        {
          moduleId: 6,
          moduleName: "Local Page ",
        },
        {
          moduleId: 7,
          moduleName: "Audit",
        },
        {
          moduleId: 8,
          moduleName: "Review",
        },
        {
          moduleId: 9,
          moduleName: "Google Analytics",
        },
        {
          moduleId: 11,
          moduleName: "Competiter Analysis",
        },
      ],
      moduleActive: false,
      moduleId: [],
      moduleName: [],
      userRoleid: "",
      userListModules: [],
      deleteDataId: "",
      emailFilter:
        window.sessionStorage.getItem("role") == "ORG_ADMIN"
          ? window.sessionStorage
            .getItem("emailsession")
            .split("@")[1]
            .split(".")[0]
          : this.props.organizationemail,
    };
    this.changeEdit=this.changeEdit.bind(this)
  }
  changeStatus(e, moduleId, moduleName) {
    $("#moduleId" + moduleId).toggleClass("active");

    let newArray = [...this.state.moduleId, moduleId];
    let newArray2 = [...this.state.moduleName, moduleName];
    if (this.state.moduleId.includes(moduleId)) {
      newArray = newArray.filter((checked) => checked !== moduleId);
      newArray2 = newArray2.filter((checked) => checked !== moduleName);
    }
    this.setState({
      moduleId: newArray,
      moduleName: newArray2,
    });
  }
  deleteModules() {
    var req = {
      functionName: "clearData",
      siteId: this.state.deleteDataId,
      modules: this.state.moduleName,
    };
    // {
    //   function: "addModulesForUser",
    //   userId: window.sessionStorage.getItem("userId"),
    //   moduleUserId: this.state.userRoleid,
    //   moduleIdsList: this.state.moduleId,
    // }
    backendApi(req).then((response) => {
      if (response.success === "1") {
        alert("Data Deleted successfully");
        window.location.reload();
      }
    });
  }

  async componentDidMount() {
    // this.getRoles();
    this.getSiteList();

    // $('.add_usser_listplus').click(function(){
    //   alert('ok')
    // })
  }
  toggleModal(id) {
    $("body").addClass("isActive");
    this.setState({ deleteDataId: id });
    // var currentUser = this.state.siteList.filter((obj) => obj.id === id);
    // currentUser[0].modules.forEach((module) => {
    //   array.push(module.moduleId);
    //   $("#moduleId" + module.moduleId).addClass("active");
    // });
    // this.setState({
    //   userRoleid: id,
    //   moduleId: array,
    // });

    //  currentUser.map((data)=>(

    //   data.forEach(siteList => {
    //     siteList.modules.forEach(module=>{
    //       $('#moduleId'+module.moduleId).toggleClass('active')
    //     })

    //   })
    //  ))
  }
  removeModal() {
    $("body").removeClass("isActive");
    $(".modules_div").removeClass("active");
  }

  // getRoles() {
  //   var req = {
  //     functionName: "roleList",
  //   };
  //   backendApi(req).then((response) => {
  //     if (response.success === "1") {
  //       this.setState({ roleList: response.result }, () => {
  //         console.log(this.state.roleList, "roleLiiiiiiiiiiist");
  //       });
  //     }
  //   });
  // }

  // getModules() {
  //   var req = {
  //     method: "POST",
  //     body: JSON.stringify({}),
  //   };
  //   backendApi("moduleListing", req).then((response) => {
  //     if (response.success === "1") {
  //       this.setState({
  //         modules: response.data,
  //       });
  //     }
  //   });
  // }

  getSiteList() {
    var req = {
      functionName: "getSite",
    };
    backendApi(req).then((response) => {
      if (response.success === "1") {
        this.setState({ siteList: response.data });
      }
    });
  }
  approveSite(id, status, email, name, mobile) {
    var statusTrue =
      $('input[name="status' + id + '"]:checked').val() === "on" ? true : false;
    var req = {
      functionName: "changeSiteStatus",
      siteId: id,
      status: statusTrue,
    };
    backendApi(req).then((response) => {
      if (response.success === "1") {
        alert("Status Changed");
        this.getSiteList();
      }
    });
  }
  changeEdit(id) { window.sessionStorage.setItem("switchingOrgId",id) }

  selectAll(checked) {
    var array = [];
    if (checked) {
      this.state.modules.map((obj) => array.push(obj.moduleName));

      // moduleUser[0].modules.forEach((module) => {
      // console.log("")
      // });

      this.setState({
        moduleActive: true,
        moduleName: array,
      });
    } else {
      this.setState({
        moduleActive: false,
        moduleName: [],
      });
    }
  }
  render() {
    return (
      <div className="main_wrapper">
        {this.state.isLogin === "true" &&
          (this.state.userRole === "ROLE_SUPER_ADMIN" ||
            this.state.userRole === "ROLE_ADMIN" ||
            this.state.userRole === "ORG_ADMIN") ? (
          <>
            <LeftMenu></LeftMenu>

            <div className="userListWrapper">
              <Header
                heading="Organization Settings"
                headerImage={rolesimage}
              />
              <div className="container-fluid">
                <div className="row justify-content-between mb-3 mx-0">
                  <div className="searchSection">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search by keyword"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-search" type="button">
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <button className="btn-addNew">
                      <Link
                        to={{
                          pathname: "#",
                        }}
                      >
                        New Organization
                      </Link>
                    </button>
                  </div>
                </div>

                <div className="col-md-12 tableResponsive">
                  <div className="tableOuterWidth table_width_md">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="col-1">ID</th>
                          <th className="col-3">Organization</th>
                          <th className="col-2">Domain</th>
                          <th className="col-2">Update </th>
                          <th className="col-2">Active / Deactive</th>
                          <th className="col-2">Clean Up</th>
                        </tr>
                      </thead>
                      <div class="table_Outerscroll">
                        {this.state.siteList.map((data, i) => (
                          <tbody className="table_Outertbody">
                            <tr>
                              <td className="col-1">{data.id}</td>
                              <td className="col-3">{data.organisation_name}
                              </td>
                              <td className="col-2">{data.domain}</td>
                              <td className="col-2">
                                <span onClick={() =>
                                  this.changeEdit(data.id )
                                }
                                 >
                                  <Link
                                    to={"/settings"}
                                    onClick
                                    state={{
                                      id: data.id,
                                      data: data,
                                    }}
                                  >
                                    <img
                                      src={Edit}
                                      alt="delete"
                                      width="20"
                                      className="iconAction"
                                    />
                                  </Link>
                                </span>
                              </td>
                              <td className="col-2">
                                <span className="switch round"></span>
                                <span>
                                  <label className="switch_items">
                                    <input
                                      id={"status" + data.id}
                                      name={"status" + data.id}
                                      type="checkbox"
                                      defaultChecked={
                                        data.is_active === 1 ? true : false
                                      }
                                      onClick={() =>
                                        this.approveSite(
                                          data.id,
                                          true,
                                          data.email_id,
                                          data.first_name,
                                          data.mobile_no
                                        )
                                      }
                                    />
                                    <span className="switch round"></span>
                                  </label>
                                </span>
                              </td>

                              <td className="col-2">
                                <button
                                  className="p-0 ml-2 add_usser_listplus border-0"
                                  onClick={() => this.toggleModal(data.id)}
                                >
                                  <Icon.PlusCircle />
                                </button>
                              </td>
                            </tr>

                          </tbody>
                        ))}
                      </div>
                    </table>
                  </div>
                </div>
              </div>
              <Footer/>
            </div>
          </>
        ) : (
          ""
        )}

        <div className="userList_modal">
          <div className="userListmodal_inner">
            <div className="userListmodal_head">
              <span className="subhead">Modules</span>
              <label className="mb-0">
                <input
                  type="checkbox"
                  onChange={(e) => this.selectAll(e.target.checked)}
                />
                <span>Select All</span>
              </label>
            </div>
            <div className="userlistmodal_body">
              {this.state.modules.map((item) => (
                <div
                  className={
                    this.state.moduleActive === true
                      ? "modules_div active"
                      : "modules_div "
                  }
                  id={"moduleId" + item.moduleId}
                  onClick={() =>
                    this.changeStatus(
                      this,
                      item.moduleId,
                      item.moduleName,
                      this.state.moduleId
                    )
                  }
                >
                  {item.moduleName}
                </div>
              ))}
            </div>

            <div className="userlistModal_footer">
              <button
                className="btn btn-success"
                onClick={() => this.deleteModules()}
              >
                Delete
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => this.removeModal()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserList;
