import { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import * as Icon from "react-feather";
import $ from "jquery";
import { backendDashboardApi, apiCallingPost } from "../apiService";
import AWS from "aws-sdk";
import { MdClearAll } from "react-icons/md";
var s3;
var divisionlistValue = []
var setSavedValuesNew = []
var headerValue
var popupSelectValue;
function OrganisationReport() {
  const [activePage, setActivePage] = useState(1);
  const [list, setList] = useState([]);
  const [s3BucketMainFolder, setS3BucketMainFolder] = useState("production")
  const [s3SubFolder, setS3SubFolder] = useState("customdashboard")
  const [accessKey, setAccessKey] = useState(process.env.REACT_APP_TAGTREE_S3_ACCESS_KEY)
  const [secretKey, setSecretKey] = useState(process.env.REACT_APP_TAGTREE_S3_SECRET_KEY)
  const [s3Bucket, setS3bucket] = useState("tagtree")
  const [s3Region, setS3Region] = useState("us-east-1")
  const [uploadFlag, setUploadFlag] = useState(false)
  const [headerList, setHeaderList] = useState([])
  const [divivisionList, setDivivisionList] = useState([])
  const [modalType, setModalType] = useState(null);
  const [inputFieldValue, setInputFieldValue] = useState("");
  const [organizationDivisionMasterId, setOrganizationDivisionMasterId] = useState("");
  const [organizationDivisionId, setOrganizationDivisionId] = useState("");
  const [selectedZone, setSelectedZone] = useState({ [`selectedParentIds0_0`]: null });
  const [savedValues, setSavedValues] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [selectedParentId, setSelectedParentId] = useState(null);
  const [selectedParentIdNew, setSelectedParentIdNew] = useState({ [`selectedParentId0`]: null });
  const [firstSelectValue, setFirstSelectValue] = useState(null);
  const [buttonShow, setButtonShow] = useState(null)
  const [currentlyEditedRow, setCurrentlyEditedRow] = useState(null);
  const arrayObject = [
    {
      "mastername": "Zone",
      "name": "zone_name",
      "id": "zone_id"
    },
    {
      "mastername": "Region",
      "name": "state_name",
      "id": "state_id"
    },
    {
      "mastername": "State Zone",
      "name": "state_zone_name",
      "id": "state_zone_id"
    },
    {
      "mastername": "District",
      "name": "district_name",
      "id": "district_id"
    },
    {
      "mastername": "City",
      "name": "city_name",
      "id": "city_id"
    },
    {
      "mastername": "Locality",
      "name": "locality_name",
      "id": "locality_id"
    },
    {
      "mastername": "Dealer",
      "name": "dealer_master_name",
      "id": "dealer_master_id"
    },

  ];

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = 10;
    } else {
      offset =
        parseInt(pageNumber - 1) * 10;
      max = 10;
    }
    setActivePage(pageNumber);
    const postData = {
      "functionName": "getDealersByAccountId",
      "accountId": window.sessionStorage.getItem("switchingOrgId") === ""
        ? window.sessionStorage.getItem("organizationId")
        : window.sessionStorage.getItem("switchingOrgId"),
      "searchText": searchText,
      "offset": offset,
      "max": max
    };
    backendDashboardApi(postData).then((data) => {
      setTotalCount(data.totalCount)
      var a = data.result.map((obj) => ({
        ...obj,
        divisionList: divisionlistValue.map((dataValue) => dataValue.accountDivisionName),
      }));
      setList(a);

    });
  };

  const NewChatPopup = (type, details, headerIndex) => {
    for (let index = 1; index < headerIndex; index++) {
      divisionlistValue.push([])
    }
    setOrganizationDivisionId(details.organisationDivisionId)
    setOrganizationDivisionMasterId(details.organization_division_master_id)
    setModalType(type);
    $("#modal_one").addClass("active");
  }
  const CloseAgentPopup = () => {
    setModalType(null);
    // setSelectedParentIdNew({ [`selectedParentId0`]: null })
    setSelectedParentIdNew((prevSelectedParentIdNew) => {
      const updatedSelectedParentIdNew = { ...prevSelectedParentIdNew };
      for (const key in updatedSelectedParentIdNew) {
        updatedSelectedParentIdNew[key] = null;
      }
      return updatedSelectedParentIdNew;
    });
  
    // Reset other relevant state values
    setInputFieldValue(null);
  
    // Clear the dropdowns in divisionlistValue
    for (let i = 1; i < divisionlistValue.length; i++) {
      divisionlistValue[i] = [];
    }
  
    $("#modal_one").removeClass("active");
  }
  useEffect(() => {
    const fetchData = async () => {
      await headerListingApi(); // Wait for headerListingApi to finish before proceeding
      await divisionDetailApi();
      var bucketParams = {
        Bucket: s3Bucket,
      };
      AWS.config.update({
        accessKeyId: accessKey,
        secretAccessKey: secretKey,
        region: s3Region,
      });
      s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: bucketParams,
      });
      listingApi(); // Call listingApi after headerListingApi
    };

    fetchData();

  }, []);
  const handleSearch = (event) => {
    setSearchText(event.target.value)
  }
  const searchClick = () => {
    const postData = {
      "functionName": "getDealersByAccountId",
      "accountId": window.sessionStorage.getItem("switchingOrgId") === ""
        ? window.sessionStorage.getItem("organizationId")
        : window.sessionStorage.getItem("switchingOrgId"),
      "searchText": searchText,
      "offset": 0,
      "max": 10
    };
    backendDashboardApi(postData).then((data) => {
      setTotalCount(data.totalCount)
      var a = data.result.map((obj) => ({
        ...obj,
        divisionList: divisionlistValue.map((dataValue) => dataValue.accountDivisionName),
      }));
      setList(a);

    });
  }
  const listingApi = () => {
    const postData = {
      "functionName": "getDealersByAccountId",
      "accountId": window.sessionStorage.getItem("switchingOrgId") === ""
        ? window.sessionStorage.getItem("organizationId")
        : window.sessionStorage.getItem("switchingOrgId"),
      "searchText": searchText,
      "offset": 0,
      "max": 10
    };
    backendDashboardApi(postData).then((data) => {
      if (data.success == 1) {
        setTotalCount(data.totalCount)
        var a = data.result.map((obj) => ({
          ...obj,
          divisionList: divisionlistValue.map((dataValue) => dataValue.accountDivisionName),
        }));
        setList(a);
      }
      else {
        setList([])
      }
    });
  };
  const divisionDetailApi = async () => {
    const postData = {
      "functionName": "getAccountDivisionDetails",
      "accountId": window.sessionStorage.getItem("switchingOrgId") === ""
        ? window.sessionStorage.getItem("organizationId")
        : window.sessionStorage.getItem("switchingOrgId"),
    };

    return backendDashboardApi(postData)
      .then((data) => {
        setDivivisionList(data.result);
        divisionlistValue[0] = data.result.result
      })
      .catch((error) => {
        throw error; // Propagate the error to the caller
      });
  };

  const headerListingApi = () => {
    return new Promise((resolve, reject) => {
      const postData = {
        "functionName": "getOrganisationDivisionByAccount",
        "accountId": window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      };
      backendDashboardApi(postData).then((data) => {
        if (data.success == 1) {
          setHeaderList(data.result.filter((h) => h.is_active === true));
          headerValue = data.result
          const activeZoneHeaders = headerValue.filter((h) => h.is_active === true);
          const numberOfActiveZoneHeaders = activeZoneHeaders.length;
          resolve(); // Resolve the promise after setting the state
        } else {
          reject(new Error("Failed to fetch header listing")); // Reject the promise if fetching fails
        }
      }).catch(error => {
        reject(error); // Catch any errors and reject the promise
      });
    });
  };

  const fileUploadHandle = (e) => {
    var fileObj = e.target.files[0]
    // if (fileObj.size > 2097152) {
    //   alert("File size must under 2MB!");
    //   return;
    // }
    let str = fileObj.name
    var last = str.substring(str.lastIndexOf("/") + 1, str.length);
    let fileName = new Date().getTime() + last;
    let fullFilePath = s3BucketMainFolder + "/" + s3SubFolder + "/" + fileName;
    setUploadFlag(true)
    const postData = {
      "functionName": "saveDealerUploadHistory",
      "account_id": window.sessionStorage.getItem("switchingOrgId") === ""
        ? window.sessionStorage.getItem("organizationId")
        : window.sessionStorage.getItem("switchingOrgId"),
      "userId": window.sessionStorage.getItem("switchUserId") === ""
        ? window.sessionStorage.getItem("userId")
        : window.sessionStorage.getItem("switchUserId"),
      "createdOnDate": new Date().getTime(),
      "file_name": fileName
    };
    backendDashboardApi(postData).then((data) => {
      if (data.success == 1) {
        const postdata = {
          "functionName": "saveDealersDataFomExcel",
          "accountId": window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
          "fileId": data.fileId
        }
        backendDashboardApi(postdata).then((data) => {

        })
        if (fileObj)
          s3.upload(
            {
              Key: fullFilePath,
              Body: fileObj,
              ContentType: "application/vnd.ms-excel",
              ACL: "public-read"
            },
            (err, data) => {
              if (err) {
                alert(
                  "There was an error uploading your file: " + err.message
                );
                return null;
              } else {
                setUploadFlag(true)
                listingApi()
                alert("File uploaded successfully");
              }
            }
          );


      }
    });

  }
  const handleInputField = (e) => {
    setInputFieldValue(e.target.value); // Update the state with the input value
  };
  const Save = () => {

    const postdata = {
      "functionName": "saveAccountDivisionDetails",
      "account_id": window.sessionStorage.getItem("switchingOrgId") === ""
        ? window.sessionStorage.getItem("organizationId")
        : window.sessionStorage.getItem("switchingOrgId"),
      "name": inputFieldValue,
      "organizationDivisionId": organizationDivisionId,
      "organizationDivisionMasterId": organizationDivisionMasterId,
      "parentAccountDivisionDetailsId": selectedParentId != null ? selectedParentId : ""
    }

    backendDashboardApi(postdata).then((data) => {
      if(data.success == "1"){
      listingApi();
   
      alert("Saved Successfully")
      CloseAgentPopup();
      divisionDetailApi()
      setOrganizationDivisionMasterId("")
      setSelectedParentId(null)
      setOrganizationDivisionId("")
      setInputFieldValue("")
      }
      else{
        setOrganizationDivisionMasterId("")
        setSelectedParentId(null)
        setOrganizationDivisionId("")
        setInputFieldValue("")
        alert(data.errorMessage)
      }
    })
  }
  const Edit = (obj, rowIndex) => {
    if (buttonShow !== null && buttonShow !== rowIndex) {
      // Hide select boxes and show "Edit" buttons for the previously edited row
      const prevSaveButtons = document.querySelectorAll(`#save${list[buttonShow].id}`);
      prevSaveButtons.forEach((element) => {
        element.style.display = "none";
      });
      const prevCancelButtons = document.querySelectorAll(`#cancel${list[buttonShow].id}`);
      prevCancelButtons.forEach((element) => {
        element.style.display = "none";
      });

      const prevEditButtons = document.querySelectorAll(`#edit${list[buttonShow].id}`);
      prevEditButtons.forEach((element) => {
        element.style.display = "block";
      });

      const prevDropValuesElements = document.querySelectorAll(`.dropValues${list[buttonShow].id}`);
      prevDropValuesElements.forEach((element) => {
        element.style.display = "none";
      });

      const prevListDatas = document.querySelectorAll(`.listValues${list[buttonShow].id}`);
      prevListDatas.forEach((element) => {
        element.style.display = "block";
      });
    }

    // Set the state of the currently edited row
    setButtonShow(rowIndex);

    // Show select box and "Save" button for the clicked row
    const saveButtons = document.querySelectorAll(`#save${obj.id}`);
    saveButtons.forEach((element) => {
      element.style.display = "block";
    });
    const cancelButtons = document.querySelectorAll(`#cancel${obj.id}`);
    cancelButtons.forEach((element) => {
      element.style.display = "block";
    });

    const editButtons = document.querySelectorAll(`#edit${obj.id}`);
    editButtons.forEach((element) => {
      element.style.display = "none";
    });

    const dropValuesElements = document.querySelectorAll(`.dropValues${obj.id}`);
    dropValuesElements.forEach((element) => {
      element.style.display = "block";
    });

    const listDatas = document.querySelectorAll(`.listValues${obj.id}`);
    listDatas.forEach((element) => {
      element.style.display = "none";
    });
    console.log('obj...........', obj);
    console.log('divisionlistValue...........', divisionlistValue);

    headerList.forEach((headerObj, colIndex) => {
      let divisionId = obj[arrayObject.filter((arrayData) => arrayData.mastername === headerObj.organisatonDivisionMasterName)[0].id];

      if (divisionId) {
        handleFirstSelectFieldChange1(divisionId, rowIndex, colIndex, "");

      
        setSavedValuesNew.push({
          mastername: divisionlistValue[colIndex].find((zone) => zone.id === divisionId)?.masterName,
          accountDivisionName: divisionlistValue[colIndex].find((zone) => zone.id === divisionId)?.accountDivisionName,
          id: divisionlistValue[colIndex].find((zone) => zone.id === divisionId)?.id,
        })
      
      }

   

    });
  }

  const SaveDetailsOfLocality = (ids) => {

    console.log("JJJJJJJJJJJJJJJ....", setSavedValuesNew)
    let combinedObject = {
      id: ids,
      zone_id: null,
      zone_name: null,
      state_id: null,
      state_name: null,
      dealer_id:null,
      dealer_name:null,
      state_zone_id: null,
      state_zone_name: null,
      district_id: null,
      district_name: null,
      city_id: null,
      city_name: null,
      locality_id: null,
      locality_name: null,
    };
    setSavedValuesNew.forEach(savedValue => {
      switch (savedValue.mastername) {
        case "Zone":
          combinedObject.zone_id = savedValue.id;
          combinedObject.zone_name = savedValue.accountDivisionName;
          break;
        case "Region":
          combinedObject.state_id = savedValue.id;
          combinedObject.state_name = savedValue.accountDivisionName;
          break;
          case "Dealer":
          combinedObject.dealer_id = savedValue.id;
          combinedObject.dealer_name = savedValue.accountDivisionName;
          break;
        case "State Zone":
          combinedObject.state_zone_id = savedValue.id;
          combinedObject.state_zone_name = savedValue.accountDivisionName;
          break;
        case "District":
          combinedObject.district_id = savedValue.id;
          combinedObject.district_name = savedValue.accountDivisionName;
          break;
        case "Locality":
          combinedObject.locality_id = savedValue.id;
          combinedObject.locality_name = savedValue.accountDivisionName;
          break;
        case "City":
          combinedObject.city_id = savedValue.id;
          combinedObject.city_name = savedValue.accountDivisionName;
          break;

      }
    });

    const postData = {
      functionName: "saveDealer",
      dealers: [combinedObject],
    };
    console.log("before save", postData)

    backendDashboardApi(postData).then((data) => {
      // listingApi();
      handlePageChange(activePage)
      clearAll()
      CancelDetailsOfLocality()

    })

  };
  const CancelDetailsOfLocality = () => {
    setSavedValuesNew = []
    // setSavedValues([])
    const prevSaveButtons = document.querySelectorAll(`#save${list[buttonShow].id}`);
      prevSaveButtons.forEach((element) => {
        element.style.display = "none";
      });
      const prevCancelButtons = document.querySelectorAll(`#cancel${list[buttonShow].id}`);
      prevCancelButtons.forEach((element) => {
        element.style.display = "none";
      });
      const prevEditButtons = document.querySelectorAll(`#edit${list[buttonShow].id}`);
      prevEditButtons.forEach((element) => {
        element.style.display = "block";
      });

      const prevDropValuesElements = document.querySelectorAll(`.dropValues${list[buttonShow].id}`);
      prevDropValuesElements.forEach((element) => {
        element.style.display = "none";
      });

      const prevListDatas = document.querySelectorAll(`.listValues${list[buttonShow].id}`);
      prevListDatas.forEach((element) => {
        element.style.display = "block";
      });
  }
  const clearAll = () => {
    // setSavedValues([])
    setSavedValuesNew = []
  }

  const handleFirstSelectFieldChange = (event, i) => {
    const selectedValue = event.target.value;
    const states = divisionlistValue[i].find(zone => zone.id == selectedValue)?.result || [];

    divisionlistValue[i + 1] = states
    setFirstSelectValue((prevFirstSelectValue) => selectedValue);
    setSelectedParentId(selectedValue);

    setSelectedParentIdNew({
      ...selectedParentIdNew,
      [`selectedParentId${i}`]: selectedValue,
    });

  };
  const handleFirstSelectFieldChange1 = (selectedValue, rowIndex, colIndex, mode) => {
debugger
    console.log('index values....', rowIndex, '...', colIndex);
    const states = divisionlistValue[colIndex].find(zone => zone.id == selectedValue)?.result || [];
    console.log('dropdown changed....', states);
    divisionlistValue[colIndex + 1] = states
    setSelectedZone((prevSelectedZone) => ({
      ...prevSelectedZone,
      [`selectedParentIds${rowIndex}_${colIndex}`]: selectedValue,
    }));
    if (mode === "dropdown") {
      headerList.forEach((headerObj, colIndexSub) => {
        if (colIndex < colIndexSub) {
          setSelectedZone((prevSelectedZone) => ({
            ...prevSelectedZone,
            [`selectedParentIds${rowIndex}_${colIndexSub}`]: [],
          }));
          divisionlistValue[colIndexSub + 1] = []
        }
      });    
      if (selectedValue !== "") {
        setSavedValuesNew.push({
          mastername: divisionlistValue[colIndex].find(zone => zone.id == selectedValue)?.masterName,
          accountDivisionName: divisionlistValue[colIndex].find(zone => zone.id == selectedValue)?.accountDivisionName,
          id: divisionlistValue[colIndex].find(zone => zone.id == selectedValue)?.id
        })       
      } else {   
        setSavedValuesNew = setSavedValuesNew.slice(0, parseInt(colIndex));     
        for (let colIndexSub = colIndex; colIndexSub < headerList.length; colIndexSub++) {      
          setSavedValuesNew.push({
            mastername: divisionlistValue[colIndexSub].find(zone => zone.id == selectedValue)?.masterName ?? divisionlistValue[colIndex][0].masterName,
            accountDivisionName: divisionlistValue[colIndexSub].find(zone => zone.id == selectedValue)?.accountDivisionName ?? null,
            id: divisionlistValue[colIndexSub].find(zone => zone.id == selectedValue)?.id ?? null
          })        
        }    
      }
    }

  };
  return (
    <div className="organisation-div organization-card bg-white">
      <div class="subhead">Division Mapping</div>
      <div className="row">
        <div className="col-12 col-md-12 col-lg-4 d-flex align-items-center mb-2">
          <input type="text" class="form-control mb-0" placeholder="Search by Dealer's Name,Address,Storecode" onChange={handleSearch}
            value={searchText} />
          <button class="db-btn ml-2" onClick={() => searchClick()}>Search</button>
        </div>
        <div className="col-12 col-md-12 col-lg-8">
          <div class="file_upload_outer mb-0 file_upload-report">
            <div>
              <div class="form-group formBottom" id="form-fileupload">
                <Icon.UploadCloud className="uploadCloudIcon" />
                <div class="form-label-fileuploaddiv">
                  <label class="form-label-fileupload" for="file">Drag &amp; Drop your file &nbsp;</label>
                  <input type="file" class="" accept="xlsx,xlsb,xlsm,xls,jpg,jpeg,png" />
                  <span class="form-fileupload-span" >Or</span>
                </div>
                <div class="upload-btn-wrapper">
                  <button class="form-fileupload-btn">Upload a file</button>
                  <input type="file" name="file" onChange={(e) => fileUploadHandle(e)} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <hr></hr>
        </div>
        <div className="col-md-12 tableResponsive mt-4 table_outer_box">
          <div className="tableOuterWidth">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th className="px-1" style={{width:"50px"}}>#</th>
                  <th className="col-2">Dealer's Name / Address</th>
                  {headerList.filter((data) => data.is_active === true).map((item, headerIndex) =>

                    <>

                      <th className="col-2">{item.organisatonDivisionMasterName}

                        <button
                          type="button"
                          className="db-btn d-block"
                          onClick={() => {
                            NewChatPopup(item.organisatonDivisionMasterName, item, headerIndex);
                          }}
                        >
                          Add {item.organisatonDivisionMasterName}
                        </button></th>
                    </>
                  )}
                </tr>
              </thead>
              <div class="table_Outerscroll" style={{maxHeight:"fit-content"}}>
                {list.map((data, rowIndex) => (
                  <tbody className="table_Outertbody" key={rowIndex}>
                    <tr>

                      <td className="px-1" style={{width:"50px"}}>{(activePage - 1) * 10 + rowIndex + 1}</td>
                      <td className="col-2">
                        <b>{data.title}</b> <br />
                        <b>{data.storeCode}</b><br />
                        {data.addressLines}
                      </td>
                      {headerList
                        .filter((headerData) => headerData.is_active === true)
                        .map((headerData, colIndex) => (
                          <td key={colIndex} className="col-2">

                            <div style={{ display: "none" }} className={"dropValues" + data.id} id={"dropValues" + data.id}>
                              <select
                                className="form-control mb-0"
                                onChange={(e) => handleFirstSelectFieldChange1(e.target.value, rowIndex, colIndex, "dropdown")}
                                value={selectedZone[`selectedParentIds${rowIndex}_${colIndex}`]}
                              >
                                <option value="">
                                  Select {headerData.organisatonDivisionMasterName}
                                </option>
                                {divisionlistValue[colIndex] &&
                                  divisionlistValue[colIndex].map((dataValue) => (
                                    <option key={dataValue.id} value={dataValue.id} >
                                      {dataValue.accountDivisionName}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div style={{ display: "block" }} className={"listValues" + data.id} id={"listValues" + data.id}>
                              <div >
                                <p>{data[arrayObject.filter((arrayData) => arrayData.mastername === headerData.organisatonDivisionMasterName)[0].name]}</p>
                              </div>
                            </div>
                          </td>
                        ))}

                      <td>
                        <div style={{ display: buttonShow === rowIndex ? "none" : "block" }} id={"edit" + data.id}>
                          <Icon.Edit size={14} className="" onClick={() => Edit(data, rowIndex)} 
                          style={{marginTop:"-15px"}}/>
                        </div>
                        <div style={{ display: buttonShow === rowIndex ? "block" : "none" }} id={"save" + data.id}>
                          <button
                            type="button"
                            className="db-btn  mr-2"
                            onClick={() => SaveDetailsOfLocality(data.id)}
                          >
                            Save
                          </button>
                        </div>
                        <br/>
                        <div style={{ display: buttonShow === rowIndex ? "block" : "none" }} id={"cancel" + data.id}>
                          <button
                            type="button"
                            className="db-btn  mr-2"
                            onClick={() => CancelDetailsOfLocality(data.id)}
                          >
                            Cancel
                          </button>
                        </div>
                      </td>

                    </tr>
                  </tbody>
                ))}
              </div>
            </table>
          </div>
        </div>
        {totalCount > 10 &&
          <div className="justify-content-end w-100 d-flex">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={totalCount}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </div>}
      </div>
      <div className="newagent_popupmodal" id="modal_one">
        <div className="newagent_popupinner">
          <Icon.XSquare
            className="new_chatclose"
            onClick={() => {
              CloseAgentPopup();
            }}
          />
          <div className="form_row">
            {console.log("headerListheaderList", headerList, modalType)}
            {headerList.filter((h) => h.organisatonDivisionMasterName === modalType && h.is_active) ? (
              headerList.filter((h) => h.is_active === true).map((h, index) => {
                if (h.organisatonDivisionMasterName === modalType && h.is_active === true) {
                  return (
                    <div key={index}>
                      {console.log("headerListheaderList111111111", h.organisatonDivisionMasterName === modalType && h.is_active === true)}
                      {Array.from({ length: index + 1 }).map((_, i) => (
                        <div className="form_row" key={i}>
                          {i < index ? (
                            // Select Dropdown
                            <>
                              <label className="custom-dashboard-label">
                                {headerList[i].organisatonDivisionMasterName}
                              </label>
                              <select
                                onChange={(e) => handleFirstSelectFieldChange(e, i)}
                                value={selectedParentIdNew[`selectedParentId${i}`]}
                                className="form-control mb-2"
                              >
                                <option value="">Select</option>
                                {divisionlistValue[i] && divisionlistValue[i].map((dataValue) => (
                                  <option key={dataValue.id} value={dataValue.id}>
                                    {dataValue.accountDivisionName}
                                  </option>
                                ))}
                              </select>
                            </>
                          ) : (
                            // Input Field
                            <>
                              <label className="custom-dashboard-label">{modalType}</label>
                              <input
                                type="text"
                                placeholder={modalType}
                                className="form-control mb-2"
                                value={inputFieldValue}
                                onChange={handleInputField}
                              />
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  );
                }
                return null;
              })
            ) : null}
          </div>
          <div className="newchatpop_footer">
            <button
              type="button"
              className="db-btn  mr-2"
              onClick={Save}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default OrganisationReport;
