import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';

const LeadsGraph = () => {
    const chartRef = useRef(null);

    useEffect(() => {
        const options = {
            series: [{
                name: 'series1',
                data: [31, 40, 28, 51, 42, 109, 100,200, 300, 230, 240, 225],
            }],
            chart: {
                height: 140,
                type: 'area',
                toolbar: {
                    show: false
                  }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'text',
                categories: ["Jun 04", "Jun 05", "Jun 06", "Jun 07", "Jun 08", "Jun 09", "Jun 10", "Jun 11", "Jun 12", "Jun 13", "Jun 14", "Jun 15"]
                // categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
            },
            yaxis: {
                labels: {
                    show: false, // Hide y-axis labels
                },
            },
            grid: {
                show: false
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm'
                },
            },
            stroke: {
                show: true,
                // curve: 'straight',
                // lineCap: 'butt',
                colors: "#3ABBB5",
                width: 1,
                fill: "linear-gradient(180deg, rgba(58, 187, 181, 0.20) -43.26%, rgba(255, 255, 255, 0.00) 166.12%)",
                // dashArray: 0, 
            },
            responsive: [
              {
                breakpoint: 991,
                options: {
                  chart: {
                    height: 200,
                  }
                },
              }],
           
        };

        const chart = new ApexCharts(chartRef.current, options);
        chart.render();

        return () => {
            chart.destroy();
        };
    }, []);

    return (
        <div id="chart" ref={chartRef}></div>
    );
};

export default LeadsGraph;
