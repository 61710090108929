import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import Reviewimage from "../images/left_icons/review.png";
import { backendApi, apiCallingPost } from "../apiService";
import ReactStars from "react-rating-stars-component";
import * as Icon from "react-feather";
import $ from "jquery";
import { Link } from "react-router-dom";
import stringreplacenpm from "stringreplacenpm";
class NewTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      file: null,
      buffer: null,
      convertText: "",
      sourceText: "",
      status: "",
      src: "",
      imageLanguage: "",
      imageLanguageLabel: "",
      translateLanguage: "",
      languageListImage: [],
      languageListTTS: [],
      uploaded: false,
      dataList: [],
      excelData: [],
      googleData: [],
      keyword: [],
      keywordLength: 0,
      reviewDatas: [],
      getCompanyData: [],
      companyName: "",
      displayName: "",
      createTime: "",
      comment: "",
      starRating: "",
      reviewList: [],
      rateChange: "",
      messageChange: "",
      reviewId: "",
      replyComments: "",
      radioComment: "",
      profilePhotoUrl: "",
      sentimentalList: [],
      revId: "",
      startDate: "",
      endDate: "",
      newRating: "",
      resultProductData: [],
      resultReviewarray: [],
      getlocationData: [],
      sentimentalDataList: [],
      allLocationdata: [],
      flagdata: [],
      getLocationAccount: [],
      getLocationTitle: [],
      getTitleName: "",
      getCityName: "",
      getCityList: [],
      businessName: [],
      getbusinessList: "",
      getaccountByLocationList: [],
      starReview: "",
      reply: "",
      sentimental: "",
    };
  }
  componentDidMount() {
    $("#templateView").hide();
    this.getreviewlist();
  }
  templateShow() {

    $("#templateView").show();

  }
  templateViewClose() {
    $("#templateView").hide();

    $("#ratedropdown").val(0)


    this.setState({
      messageChange: "",
      templateId: "",
    });
  }

  getreviewlist() {
    $(".loder_div").attr("hidden", false);
    var apidata = {
      functionName: "reviewTemplateListing",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };

    backendApi(apidata).then((data) => {
      $(".loder_div").attr("hidden", true);
      this.setState({
        reviewList: data.result,
      });
    });
  }
  saveTemplate() {
    var apidata = {
      functionName: "reviewTemplateSave",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      template: stringreplacenpm(this.state.messageChange),
      starRate: this.state.rateChange,
      templateId:
        this.state.templateId === undefined ? "" : this.state.templateId,
    };

    backendApi(apidata).then((data) => {
      if (data.success === "1") {
       
          alert(data.successMessage);
          this.getreviewlist();
          $("#templateView").hide();
      
    $("#ratedropdown").val(0)


    this.setState({
      messageChange: "",
      templateId: "",
    });
        
      } else {
        return false;
      }
    });
  }
  editReview(message, templateId, starRate) {
  
    //$('#ratedropdown [value="' + starRate + '"]').attr("selected", "true");
    $("#ratedropdown").val(starRate)
    $("#templateView").show();

    this.setState({
      messageChange: message,
      templateId: templateId,
      rateChange: starRate,
    });
  }
  deleteReview(Id) {
    if (!window.confirm("Do you want to Delete Template?")) {
      return false;
    }
    if (Id != "") {
      var apidata = {
        templateId: Id,
        functionName: "reviewTemplateDelete",
      };
      backendApi(apidata).then((data) => {
        if (data.success === "1") {
          this.getreviewlist();
        } else {
          return false;
        }
      });
    }
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          <Header heading="Template" headerImage={Reviewimage} />
          <div className="container">
          <div className="back_tocatalog row">
            <div className="col-lg-12 d-flex">
              <div className="back_tocatalog_main">
                <Link to={window.sessionStorage.getItem("reviewrouter")} className="back-to-link">
                  <Icon.ArrowLeftCircle />
                </Link>
              </div>
              <div className="text-right mb-2 btn_popupdiv ml-auto">
              <button type="button" className="btn-search">
                <Icon.Plus onClick={() => this.templateShow()} />
              </button>
            </div>
             
            </div>
          </div>
           
            <div className="new_template">
              <div className="col-md-12 tableResponsive">
                <div className="tableOuterWidth">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-4">Star</th>
                        <th className="col-5">Reply</th>
                        <th className="col-3">Action</th>
                      </tr>
                    </thead>
                    <div className="table_Outerscroll">
                      {this.state.reviewList.sort((a, b) => a.star_rate - b.star_rate)
                      .map((item) => (
                        <tbody className="table_Outertbody" key={item.id}>
                          <tr>
                            <td className="col-4 react_star_size">
                              <span>      <ReactStars
                              key={item.star_rate}
                              count={ item.star_rate}
                              size={24}
                              color2={'#ffd700'} /></span>
                              
                            </td>
                            <td className="col-5">{item.template}</td>
                            <td className="col-3">
                              {" "}
                              <span
                         
                                onClick={() =>
                                  this.editReview(
                                    item.template,
                                    item.id,
                                    item.star_rate
                                  )
                                }
                              >
                                <Icon.Edit className="text-primary mr-2" width="18"/>
                              </span>
                              <span
                          className="text-danger"
                          onClick={() => this.deleteReview(item.id)}
                        >
                          <Icon.Trash width="18"/>
                        </span>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </div>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="loder_div" hidden />
        <div className="template_popup template_popup_wraper" id="templateView">
          <div className="review_owner_dropdown">
            <div className="review_owner_top">
              <div class="reply_popup_head d-flex align-items-center popup_review_common">
                <span class="popup_reply_head">
                  <Icon.FileText />
                  Template
                </span>
                <span class="ml-auto close_popup_review">
                  <Icon.X onClick={() => this.templateViewClose()} />
                </span>
              </div>
            </div>
            <div className="review_Popupdiv">
                <div className="row">
                    <div className="col-md-4">
                    <div className="popup_formgroup">
                  <label>Rate</label>
                  <select
                    className="form-control rating_optionselect"
                    id="ratedropdown"
                    onChange={(e) =>
                      this.setState({
                        rateChange: e.target.value,
                      })
                    }>
                    <option value="0">Select Star Rate</option>
                    <option value="1">
                    <span>&#9733;</span>
                  </option>
                  <option value="2">
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                  </option>
                  <option value="3">
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                  </option>
                  <option value="4">
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                  </option>
                  <option value="5">
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                  </option>
                </select>
              </div>
                    </div>
                    <div className="col-md-8">
                    <div className="popup_formgroup">
                <label>Message</label>
                <textarea
                  className="form-control"
                  value={this.state.messageChange}
                  onChange={(e) =>
                    this.setState({
                      messageChange: e.target.value,
                    })
                  }
                />
              </div>
                    </div>
                </div>
              <div className="popup_formgroup text-right mt-1">
                <button className="btn btn-secondary mr-2" type="button"
                  onClick={() => this.templateViewClose()}>
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.saveTemplate()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="loder_div" hidden />
        <Footer/>
      </div>
    );
  }
}
export default NewTemplate;
