import React from "react";
function Alertmsg() {
  return (
    <div className="alert_msg">
      <div className="alert_msgcontent">Login Success</div>
    </div>
  );
}

export default Alertmsg;
