import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import $ from "jquery";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import GooglePostimage from "../images/left_icons/Google-Post.png";
import PostImg from "../images/bg_01.jpg";
import video from "../video/videoplayback.mp4";
import VideoPlayBtn from "../images/video-play-button.png";
import Pagination from "react-js-pagination";
import { Link, useNavigate } from "react-router-dom";
import * as Icon from "react-feather";
import { useEffect, useState } from "react";
import { apiCallingPost } from "../apiService";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
var XLSX = require("xlsx");
var tempPostList = [];
export default function PostListing() {
  const [localPostList, setLocalPostList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const [globalFilterLocationsList, setGlobalFilterLocationsList] = useState(
    []
  );
  const [tempFilterLocationsList, setTempFilterLocationsList] = useState([]);
  const [filterLocationsList, setFilterLocationsList] = useState([]);
  const [tempLocationsList, setTempLocationsList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [backupdata, setbackupdata] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [response, setResponse] = useState("Waiting For Data....");
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  // var globalFilterLocationsList = [];
  // constructor(props) {
  //     super(props);
  //     this.state = {
  //         startDate: "",
  //         endDate: "",
  //     }
  // }
  useEffect(() => {
    $("#editMatchAddress").attr("hidden", true);
    $("#watchVideo").attr("hidden", true);
    // getLocationsList();
    newGetLocationsList();
    getAdministrativeArea();
  }, []);

  const getAdministrativeArea = () => {
    const businessAccountData = {
      functionName: "getAdministrativeArea",
      account_id: window.sessionStorage.getItem("account_id"),
    };
    apiCallingPost(businessAccountData).then((data) => {
      if (data.success === "1") {
        setStatesList(data.data.map((obj) => obj.AdministrativeArea));
        // this.setState({
        //   getLocationTitle: data.data.map((obj) => obj.AdministrativeArea),
        // });
      } else {
        setStatesList([]);
        $(".loder_div").attr("hidden", true);
      }
    });
  };

  // const getLocationsList = () => {
  //   $(".loder_div").attr("hidden", false);
  //   var req = {
  //     functionName: "GetLocationsUnderAccount",
  //     siteId:
  //       window.sessionStorage.getItem("switchingOrgId") === ""
  //         ? window.sessionStorage.getItem("organizationId")
  //         : window.sessionStorage.getItem("switchingOrgId"),
  //   };
  //   apiCallingPost(req).then((data) => {
  //     // console.log("", data.data);
  //     if (data.success === "1") {
  //       setLocationsList(data.data.locations);
  //       setTempLocationsList(data.data.locations);
  //       getPostList(data.data.locations);
  //     } else {
  //       $(".loder_div").attr("hidden", true);
  //       alert("Something went wrong");
  //       console.log(data);
  //     }
  //   });
  // };
  const newGetLocationsList = () => {
    $(".loder_div").attr("hidden", false);
    const apiJson = {
      functionName: "getMasterDataForFilter",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    apiCallingPost(apiJson).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        setLocationsList(data.data);
        setFilterLocationsList(data.data);
        setGlobalFilterLocationsList(data.data);
        setTempFilterLocationsList(data.data);
        setTempLocationsList(data.data);
        getPostList(data.data);
      } else {
        setResponse("No posts(s) available");
        $(".loder_div").attr("hidden", true);
        alert("Something went wrong");
        // console.log(data);
      }
      // if (data.success === "1") {
      //   // setLocationsList((data) => [...data, data])
      //   setLocationsList(data.data);
      //   // setTemporaryLocationsList(
      //   //   data.data.map((data) => ({ ...data, checked: false }))
      //   // );
      //   // globalLocationId = data.data.length;
      //   // data.data.forEach((obj) => {
      //   //   !administrativeAreaList.includes(obj.administrativeArea) &&
      //   //     administrativeAreaList.push(obj.administrativeArea);
      //   //   !localityList.includes(obj.locality) &&
      //   //     localityList.push(obj.locality);
      //   // });
      //   // setLocalityListBackup(localityList);
      // } else {
      //   setLocationsList([]);
      // }
    });
  };
  const getPostList = (locations) => {
    var count = locations.length;
    tempPostList = [];
    locations.forEach(async (obj, i) => {
      var req = {
        functionName: "PostListing",
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        location_id: obj.location_id,
      };
      await apiCallingPost(req).then((data) => {
        count = count - 1;
        // console.log(count);

        if (data.success === "1") {
          if (data.data.localPosts) {
            tempPostList.push(...data.data.localPosts);
            // setLocalPostList((object) => [...object, ...data.data.localPosts]);
          }
          // console.log(data,"data");
        }
        if (count === 0) {
          if (tempPostList.length == 0) {
            setResponse("No posts(s) available");
          }

          const res = tempPostList
            .slice(0)
            .sort((a, b) => a.createTime.localeCompare(b.createTime));
          setTotalCount(tempPostList.length);
          // var reverseDealerDatas = res.reverse();
          const indexOfLastPost = currentPage * 25;
          const indexOfFirstPost = indexOfLastPost - 25;
          let newRes = res.reverse();
          const currentPosts = newRes.slice(indexOfFirstPost, indexOfLastPost);
          // console.log("postListssss", newRes);
          // setDataList(currentPosts);
          setbackupdata(newRes);
          // setclaimList(tempList);
          setLocalPostList(currentPosts);
          $(".loder_div").attr("hidden", true);
        }
      });
    });
  };
  const handleChangeStart = (value) => {
    this.setState({
      startDate: value,
    });
  };
  const handleChangeEnd = (values) => {
    this.setState({
      endDate: values,
    });
  };
  const deletePost = (name, postData) => {
    let postId = name.split("/")[5];
    let locationId = name.split("/")[3];
    var req = {
      localPostId: postId.toString(),
      location_id: locationId,
      functionName: "DeletePost",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    if (!window.confirm("Do you want to delete this post?")) return false;
    console.log("postDatapostData", postData);
    $(".loder_div").attr("hidden", false);
    apiCallingPost(req).then((data, i) => {
      setLocalPostList([]);
      $(".loder_div").attr("hidden", true);
      // console.log(i);
      if (data.success == "1") {
        const historyApiReq = {
          functionName: "postHistory",
          posted_by:
            window.sessionStorage.getItem("switchUserId") === ""
              ? window.sessionStorage.getItem("userId")
              : window.sessionStorage.getItem("switchUserId"),
          status: "Deleted",
          title: postData?.event?.title ? postData?.event?.title : "",
          site_id:
            window.sessionStorage.getItem("switchingOrgId") === ""
              ? window.sessionStorage.getItem("organizationId")
              : window.sessionStorage.getItem("switchingOrgId"),
          startDate_year: postData?.event?.schedule?.startDate?.year,
          startDate_month: postData?.event?.schedule?.startDate?.month,
          startDate_day: postData?.event?.schedule?.startDate?.day,
          startTime_hours: postData?.event?.schedule?.startTime?.hours,
          startTime_minutes: postData?.event?.schedule?.startTime?.minutes,
          endDate_year: postData?.event?.schedule?.endDate?.year,
          endDate_month: postData?.event?.schedule?.endDate?.month,
          endDate_day: postData?.event?.schedule?.endDate?.day,
          endTime_hours: postData?.event?.schedule?.endTime?.hours,
          endTime_minutes: postData?.event?.schedule?.endTime?.minutes,
          actionType: postData?.callToAction?.actionType
            ? postData?.callToAction?.actionType
            : "",
          action_url: postData?.callToAction?.url
            ? postData?.callToAction?.url
            : "",
          bannerImages: postData?.media ? postData?.media[0]?.googleUrl : "",
          eventType: postData.topicType,
          location_id: postData.name.split("/")[3],
          post_id: postData.name.split("/").pop(),
        };
        apiCallingPost(historyApiReq).then((obj) => {
          alert("Deleted Successfully!!!");
          window.location.reload();
        });
      }
    });
  };
  const handleActivePage = (e) => {
    setCurrentPage(e);
    const indexOfLastPost = e * 25;
    const indexOfFirstPost = indexOfLastPost - 25;
    let tempList = backupdata;
    const currentPosts = tempList.slice(indexOfFirstPost, indexOfLastPost);
    setLocalPostList(currentPosts);
  };
  const filterLocations = (e) => {
    let demoArray = [];
    if (selectedState != "" || selectedCity != "") {
      demoArray = tempFilterLocationsList;
    } else {
      demoArray = locationsList;
    }
    var filterArray = demoArray.filter(
      (data) =>
        data.storeCode.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.addressLines
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        (
          data.storeCode +
          ", " +
          data.addressLines +
          ", " +
          data.locality +
          ", " +
          data.AdministrativeArea
        )
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
    );
    if (e.target.value == "") {
      debugger;
      let res = backupdata;
      const indexOfLastPost = 1 * 25;
      const indexOfFirstPost = indexOfLastPost - 25;
      const currentPosts = res.slice(indexOfFirstPost, indexOfLastPost);
      // setFilterLocationsList([]);
      // globalFilterLocationsList = [];
      setGlobalFilterLocationsList(demoArray);
      // setTempFilterLocationsList(tempLocationsList);
      setLocalPostList(currentPosts);
      setLocationsList(tempLocationsList);
    } else {
      setGlobalFilterLocationsList(filterArray);
    }
  };
  const filterPost = (e) => {
    $("#LocationSelected").val(
      `${e.storeCode}-${e.title}, ${e.addressLines}, ${e.locality}, ${e.AdministrativeArea}`
    );
    searchPostList(e);
  };
  const searchPostList = (obj) => {
    setLocationsList([obj]);
    $(".loder_div").attr("hidden", false);
    setCurrentPage(1);
    var req = {
      functionName: "PostListing",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      location_id: obj.location_id,
    };
    apiCallingPost(req).then((data) => {
      debugger;
      // alert(currentPage);
      // tempPostList.push(...data.data.localPosts);
      if (Object.keys(data.data).length > 0) {
        const res = data.data.localPosts
          .slice(0)
          .sort((a, b) => a.createTime.localeCompare(b.createTime));
        setTotalCount(data.data.localPosts.length);
        const indexOfLastPost = 1 * 25;
        const indexOfFirstPost = indexOfLastPost - 25;
        const currentPosts = res.slice(indexOfFirstPost, indexOfLastPost);
        setLocalPostList(currentPosts);
        $(".loder_div").attr("hidden", true);
      } else {
        setLocalPostList([]);
        setTotalCount(0);
        setResponse("No Post(s) available");
        $(".loder_div").attr("hidden", true);
      }
      // setDataList(currentPosts);
    });
  };
  const handleChangeState = (value) => {
    $("#cityId").val("");
    $("#LocationSelected").val("");
    if (value !== "") {
      // this.setState({
      //   businessNameFilter: "",
      // });
      const businessAccountData = {
        functionName: "getLocalityUnderAdministrativeArea",
        account_id: window.sessionStorage.getItem("account_id"),
        AdministrativeArea: value,
      };
      setSelectedState(value);
      apiCallingPost(businessAccountData).then((data) => {
        if (data.success === "1") {
          const businessAccountData = {
            functionName: "getLocationSearch",
            account_id: window.sessionStorage.getItem("account_id"),
            AdministrativeArea: value,
            locality: "",
          };
          apiCallingPost(businessAccountData).then((response) => {
            setGlobalFilterLocationsList(response.data);
            setTempFilterLocationsList(response.data);
            // this.setState({

            //   globalFilterLocationsList: response.data,
            //   tempSearchLocationsList: response.data,
            // });
          });
          setCityList(data.data.map((obj) => obj.locality));
          // this.setState({
          //   cityList: data.data.map((obj) => obj.locality),
          //   AdministrativeArea: value,
          // });
        } else {
          // this.setState({
          //   getCityList: [],
          // });
          $(".loder_div").attr("hidden", true);
        }
      });
    } else {
      setGlobalFilterLocationsList(tempLocationsList);
      setTempFilterLocationsList(tempLocationsList);
    }
  };
  const handleChangeCity = (value, AdministrativeArea) => {
    // this.setState({
    //   LocalityList: value,
    // });
    setSelectedCity(value);
    const businessAccountData = {
      functionName: "getLocationSearch",
      account_id: "115844776645416812845",
      AdministrativeArea: AdministrativeArea,
      locality: value,
    };
    apiCallingPost(businessAccountData).then((data) => {
      if (data.success === "1") {
        setGlobalFilterLocationsList(data.data);
        setTempFilterLocationsList(data.data);
        // this.setState({
        //   globalFilterLocationsList: data.data,
        //   tempSearchLocationsList: data.data,
        // });
      } else {
        setGlobalFilterLocationsList([]);
        setTempFilterLocationsList([]);
        // this.setState({
        //   globalFilterLocationsList: [],
        //   tempSearchLocationsList: [],
        // });
        $(".loder_div").attr("hidden", true);
      }
    });
    // this.setState({
    //   getLocation: value,
    // });

    // this.setState({
    //   businessName: filterbusiness,
    // });
  };
  const exportPosts = () => {
    const data = backupdata.map((object) => ({
      "Business Name": locationsList
        .filter((obj) => obj.location_id.includes(object.name.split("/")[3]))
        .map((object) => object.title)[0],
      "Business Address": locationsList
        .filter((obj) => obj.location_id.includes(object.name.split("/")[3]))
        .map(
          (object) =>
            object.storeCode +
            "- " +
            object.addressLines +
            ", " +
            object.locality +
            ", " +
            object.AdministrativeArea
        )[0],
      "Post Type": object.topicType,
      "Post Status": object.state,
      "Post Date/ Update Date": `Posted On: ${moment(object.createTime).format(
        "MMM DD, YYYY hh:mm a"
      )} ${
        object?.updateTime &&
        `Post Updated On: ${moment(object.updateTime).format(
          "MMM DD, YYYY hh:mm a"
        )}`
      }`,
      "Post Media": object?.media ? object?.media.googleUrl : "None",
      "Post URL": object.searchUrl,
      "Post Summary": object.summary,
      "Call To Action": object?.callToAction
        ? `${object.callToAction.actionType.replace("-", " ")}-${
            object.callToAction.url
          }`
        : "None",
    }));
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Add a custom number format for the rating column
    worksheet["!cols"] = [
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 50 },
      { width: 50 },
      { width: 50 },
    ];
    // worksheet['!numfmt'] = [{}, {}, { numFmt: '[>=1][<2]★;General;General;@' }];
    worksheet["!cols"].splice(3, 0, { width: 20 }); // Add a column with width 20 at index 3 (after the 'Rating' column)

    // Add the worksheet to a new workbook and save it
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Post History");
    XLSX.writeFile(workbook, "GooglePosts.xlsx");
  };
  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header heading=" Google Post" headerImage={GooglePostimage} />
        <div className="container-fluid">
          <div className="subhead">Post(s) History</div>

          {/*  */}
          <div className="row row-search">
            <div className="col-lg-2 col-md-4 col-sm-6">
              {/* {console.log("xxxxxxxxxxxx", addressarray)} */}
              <select
                className="form-control"
                id="stateId"
                // value={this.state.getCityList}
                onChange={(e) => handleChangeState(e.target.value)}
              >
                <option value="">State</option>
                {statesList.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <select
                className="form-control"
                id="cityId"
                onChange={(e) =>
                  handleChangeCity(e.target.value, selectedState)
                }
              >
                <option value="">City</option>
                {cityList.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="col-lg-5 col-md-4 location_search">
              <input
                className="form-control"
                type="text"
                id="LocationSelected"
                onChange={(e) => filterLocations(e)}
                placeholder="Search by location"
              />
              <div className="autocomplete__list location_search_drop">
                <ul class="nav flex-column">
                  {globalFilterLocationsList.map((data) => (
                    <li
                      key={data.location_id}
                      onMouseDown={() => filterPost(data)}
                    >
                      {data.storeCode}- {data.title}-{data.addressLines},
                      {data.locality},{data.AdministrativeArea}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <button className="btn btn-info" onClick={() => exportPosts()}>
                Export
              </button>
            </div>
          </div>
          {/*  */}
          <div className="col-md-12 tableResponsive">
            <div className="tableOuterWidth">
              {localPostList.length > 0 ? (
                <table className="table">
                  <thead>
                    <tr>
                      <th className="col-2">Business Name</th>
                      {/* <th>Refer Code</th> */}
                      <th className="col-2">Address & Link to the post</th>
                      <th className="col-1">URL(Call to Action)</th>
                      <th className="col-1">Status</th>
                      <th className="col-2">Post date/Update date</th>
                      <th className="col-1">Post Type: Photo/Video</th>
                      <th className="col-2">Details</th>
                      <th className="col-1 tag_postListLastChild">
                        Edit/Copy/Delete
                      </th>
                      {/* <th>Views/Clicks</th> */}
                    </tr>
                  </thead>
                  <div className="table_Outerscroll">
                    {localPostList.map((data) => {
                      // console.log(data, "datatatatatata");
                      let name = locationsList
                        .filter((obj) =>
                          obj.location_id.includes(data.name.split("/")[3])
                        )
                        .map((object) => object.title);
                      let address = locationsList
                        .filter((obj) =>
                          obj.location_id.includes(data.name.split("/")[3])
                        )
                        .map(
                          (object) =>
                            object.storeCode +
                            "- " +
                            object.addressLines +
                            ", " +
                            object.locality +
                            ", " +
                            object.AdministrativeArea
                        );
                      let Locality = locationsList
                        .filter((obj) =>
                          obj.location_id.includes(data.name.split("/")[3])
                        )
                        .map((object) => object);
                      // console.log("Locality", Locality);
                      // console.log("adressssss", address);
                      return (
                        <tbody className="table_Outertbody">
                          <tr>
                            <td className="col-2">{name}</td>
                            <td className="col-2">
                              <a
                                href={data.searchUrl}
                                target="_blank"
                                className="google_post_href"
                              >
                                {address}
                              </a>
                            </td>
                            <td className="col-1">
                              {data.callToAction ? (
                                <a href={data.callToAction.url} target="_blank">
                                  {data.callToAction.actionType.replace(
                                    "_",
                                    " "
                                  )}
                                </a>
                              ) : (
                                "None"
                              )}
                            </td>
                            <td className="col-1">
                              {data.state}
                              <br />
                              {data.state === "Failed" && (
                                <button class="btn btn-primary fetch_btn">
                                  Re-Submit
                                </button>
                              )}
                            </td>
                            <td className="col-2">
                              Post date : <br />
                              {moment(data.createTime).format(
                                "DD-MMM-YY, h:mm a"
                              )}{" "}
                              -
                              <br />
                              Update date : <br />
                              {moment(data.updateTime).format(
                                "DD-MMM-YY, h:mm a"
                              )}
                            </td>
                            <td className="col-1">
                              {data.media && (
                                <span
                                  onClick={() => {
                                    $("#editMatchAddress").attr(
                                      "hidden",
                                      false
                                    );
                                    $("#postImage").attr(
                                      "src",
                                      data.media[0].googleUrl
                                    );
                                  }}
                                >
                                  <>
                                    <img
                                      className="tag_postImg"
                                      src={data.media[0].googleUrl}
                                    />{" "}
                                  </>
                                </span>
                              )}
                            </td>
                            <td className="col-2">{data.summary}</td>
                            <td className="col-1 tag_postListLastChild">
                              {data.topicType !== "ALERT" && (
                                <Link
                                  to="/createNewPost"
                                  state={{
                                    completeLocationData: locationsList.filter(
                                      (obj) => {
                                        return (
                                          obj.location_id ===
                                          data.name.split("/")[3]
                                        );
                                      }
                                    ),
                                    selectedLocations: [
                                      data.name.split("/")[3],
                                    ],
                                    PostEditField: data,
                                  }}
                                >
                                  <Icon.Edit
                                    style={{
                                      marginRight: "10px",
                                      width: "17px",
                                    }}
                                  />
                                </Link>
                              )}
                              {data.topicType !== "ALERT" &&
                                locationsList.length > 1 && (
                                  <Icon.Copy
                                    onClick={() =>
                                      navigate("/googlepost/copyPost", {
                                        state: {
                                          copyField: data,
                                          PostEditField: [],
                                          completeLocationData:
                                            locationsList.filter((obj) => {
                                              return (
                                                obj.location_id ===
                                                data.name.split("/")[3]
                                              );
                                            }),
                                          allLocationsData: locationsList,
                                        },
                                      })
                                    }
                                    style={{
                                      marginRight: "10px",
                                      width: "17px",
                                    }}
                                  />
                                )}
                              <Icon.Trash
                                style={{ width: "17px", color: "red" }}
                                onClick={() => deletePost(data.name, data)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}

                    {/* {localPostList ? (
                        <>
                          {localPostList.map((data) => (

                          ))}
                        </>
                      ) : (
                        "No Data found"
                      )} */}

                    {/* <tr>
                        <td>ID-124</td>
                        <td>Ref-124</td>
                        <td>
                          <a href="#">117-axis-test</a>
                        </td>
                        <td>
                          <a href="www.google.com">Google</a>
                        </td>
                        <td>
                          Failed
                          <br />
                          <button class="btn btn-primary fetch_btn">
                            Re-Submit
                          </button>
                        </td>
                        <td>
                          Post date : 18-11-2022
                          <br />
                          Update date : 18-11-2022
                        </td>
                        <td>
                          <span
                            onClick={() => {
                              $("#watchVideo").attr("hidden", false);
                            }}
                          >
                            <img className="tag_postImg" src={VideoPlayBtn} />
                          </span>
                          <br />
                          <p>
                            {" "}
                            <b>Comments</b> : Lorem ipsum may be used as a
                            placeholder before final copy is available.
                          </p>
                        </td>
                        <td>
                          <Icon.Edit
                            style={{ marginRight: "8px", width: "15px" }}
                          />
                          <Icon.Trash style={{ width: "15px" }} />
                        </td>
                        <td>
                          Views - 10 <br />
                          Clicks - 100
                        </td>
                      </tr> */}
                  </div>
                </table>
              ) : (
                response
              )}
              <div
                className="module_popup editMatchAddress"
                id="editMatchAddress"
                hidden
              >
                <div className="module_innerpopup">
                  <div className="module_close">
                    <Icon.XCircle
                      color="red"
                      onClick={() => {
                        $("#editMatchAddress").attr("hidden", true);
                      }}
                    />
                  </div>
                  <div className="module_body">
                    <div className="form-group row">
                      <img
                        id="postImage"
                        src={PostImg}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div
                className="module_popup editMatchAddress"
                id="watchVideo"
                hidden
              >
                <div className="module_innerpopup">
                  <div className="module_close">
                    <Icon.XCircle
                      color="red"
                      onClick={() => {
                        $("#watchVideo").attr("hidden", true);
                      }}
                    />
                  </div>
                  <div className="module_body">
                    <video width="100%" height="500" controls>
                      <source src={video} type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>
              {/*  */}
              {/*  */}
              <a href="/googlepost/index" class="tag_float" title="Add Post">
                <Icon.Plus color="#fff" class="tag_my_float" />
              </a>
              {/*  */}
            </div>
            <div className="pagination_main  tag_postListNext">
              <Pagination
                hideDisabled
                activePage={currentPage}
                itemsCountPerPage="25"
                totalItemsCount={totalCount}
                pageRangeDisplayed="10"
                onChange={(e) => handleActivePage(e)}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden></div>
    </div>
  );
}
