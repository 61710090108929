import React, { Component } from "react";
import { useNavigate } from "react-router-dom";

import "../css/bootstrap.css";
import "../css/style.css";
import ProductCatalogimage from "../images/left_icons/Product-Catalog.png";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import DragDropFile from "./dragDropFile";
import DataInput from "./dataInput";
import ImageList from "./imageList";
import cloudImg from "../images/cloud-computing.png";
import deleteImg from "../images/Group122.png";
import { backendApi, apiCallingPost, pythonBackendApi } from "../apiService";
import * as XLSX from "xlsx";
import uploadImg from "../images/upload.png";
import $ from "jquery";
import closeImg1 from "../images/close1.png";
import ExcelUploadPopup from "./uploadExcelpopup";
import * as Icon from "react-feather";
import mainDataExcel from "../docs/Main_excel_template.xlsx";
import { Link } from "react-router-dom";
var saveMode = "";

const excelMime = [
  "application/vnd.ms-excel",
  "application/msexcel",
  "application/x-msexcel",
  "application/x-ms-excel",
  "application/x-excel",
  "application/x-dos_ms_excel",
  "application/xls",
  "application/x-xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      buffer: null,
      convertText: "",
      sourceText: "",
      status: "",
      src: "",
      imageLanguage: "",
      imageLanguageLabel: "",
      translateLanguage: "",
      languageListImage: [],
      languageListTTS: [],
      uploaded: false,
      dataList: [],
      excelData: [],
      googleData: [],
      mappingData: [],
      productDetails: [],
      dataUploadType: "",
      imageList: [],
      imagestatus: "",
      showMappingEditPopup: false,
      fileName: "",
      masterGBPData: [],
      hasGroup: false,
      groupItems: "",
      moreDetailsMasterId: "",
      moreDetailsIndex: "",
    };
    this.handleFile = this.handleFile.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.cancelPopup = this.cancelPopup.bind(this);
  }
  componentDidMount() {
    $("#excelUpdatePopup").hide();
    // this.getExcelData();
    this.getMasterGBPData();
    this.getAlias();
    //this.getImage();
  }
  getMasterGBPData() {
    $(".loder_div").attr("hidden", false);
    var apiData = {
      functionName: "getMasterGBPData",
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      role:
        window.sessionStorage.getItem("switchUserRole") === ""
          ? window.sessionStorage.getItem("role")
          : window.sessionStorage.getItem("switchUserRole"),
    };
    apiCallingPost(apiData).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        this.setState({
          masterGBPData: data.data,
        });
      }
    });
  }
  getAlias() {
    $(".loder_div").attr("hidden", false);
    var apidata = {
      functionName: "getAlias",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    apiCallingPost(apidata).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        this.setState({
          mappingData: data.mappingdata,
          productDetails: data.productDetailsData,
          imageList: data.imagedata,
        });
      }
    });
  }
  // getExcelData() {
  //   var apidata = {
  //     functionName: "GetProductCatalogExcel",
  //     createdBy:
  //       window.sessionStorage.getItem("switchUserId") === ""
  //         ? window.sessionStorage.getItem("userId")
  //         : window.sessionStorage.getItem("switchUserId"),
  //     siteId:
  //       window.sessionStorage.getItem("switchingOrgId") === ""
  //         ? window.sessionStorage.getItem("organizationId")
  //         : window.sessionStorage.getItem("switchingOrgId"),
  //   };
  //   apiCallingPost(apidata).then((data) => {
  //     console.log("data=========", data);
  //     if (data.success === "1") {
  //       this.setState({
  //         uploaded: false,
  //         excelData: data.data,
  //       });
  //     }
  //   });
  // }
  fullViewExcel(a, b) {
    $("." + a)
      .parents("." + b)
      .addClass("fullViewdiv");
  }
  onCloseDiv(a, b) {
    $("." + a)
      .parents("." + b)
      .removeClass("fullViewdiv");
  }
  OutTable = () => {
    return (
      <div>
        <div className="col-md-12 tableResponsive">
          <table className="table fileupload-table mb-0">
            <thead>
              <tr>
                <th className="col-2">Resource</th>
                <th className="col-2">Case Title</th>
                <th className="col-2">Account Name</th>
                <th className="col-2">Account Number</th>
                <th className="col-2">Status</th>
                <th className="col-2">Category-Products</th>
              </tr>
            </thead>
            <div className="table_Outerscroll">
              {this.state.dataList.map((r, i) => (
                <tbody key={i} className="table_Outertbody">
                  <tr>
                    <td className="col-2">{r.Resource}</td>
                    <td className="col-2">{r.CaseTitle}</td>
                    <td className="col-2">{r.AccountName}</td>
                    <td className="col-2">{r.AccountNumber}</td>
                    <td className="col-2">{r.Status}</td>
                    <td className="col-2">
                      {r.Category} - {r.Products}
                    </td>
                  </tr>
                </tbody>
              ))}
            </div>
          </table>
        </div>
        <div className="button-wrapper mb-4 mt-3">
          <button className="btn-submit ml-3" onClick={() => this.onSave()}>
            Upload File
          </button>
          <button className=" btn-cancel ml-3" onClick={() => this.onCancel()}>
            Cancel
          </button>
        </div>
      </div>
    );
  };
  onCancel() {
    this.setState({
      uploaded: false,
      dataList: [],
      fileName: "",
    });
  }
  onSave() {
    $("#editCommentPopup").attr("hidden", false);
    $("#editCommentPopupHeader").text("Insert Comment");
  }
  saveAndStartUpload() {
    if ($("#editCommentPopupTextBox").val() === "") {
      alert("Enter comment please");
      return false;
    }
    // else if (this.state.hasGroup == true && this.state.groupItems == "") {
    //   alert("Enter Group Name please");
    //   return false;
    // }
    $(".loder_div").attr("hidden", false);
    var apidata = {
      method: "POST",
      body: JSON.stringify({
        fileName: this.state.fileName,
        dataList: this.state.dataList,
        createdBy:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        comment: $("#editCommentPopupTextBox").val(),
      }),
    };
    pythonBackendApi("uploadExcelData", apidata).then((data) => {
      window.location.href = "/livedetails/" + data.masterId;
    });
  }
  saveMoreDetails() {
    const apiReq = {
      method: "POST",
      body: JSON.stringify({
        id: this.state.moreDetailsMasterId,
        has_group: this.state.hasGroup == true ? 1 : 0,
        group_name: this.state.groupItems,
      }),
    };
    pythonBackendApi("updateGBPCategory", apiReq).then((data) => {
      if (data.success == 1) {
        alert("Saved successfully");
        $("#editMoreDetailsPopup").attr("hidden", true);
        this.getMasterGBPData();
        this.setState({
          moreDetailsMasterId: "",
          hasGroup: false,
          groupItems: "",
        });
      } else {
        alert("Something went wrong while saving group");
      }
    });
  }
  async handleFile(files) {
    if (files) {
      if (!excelMime.includes(files.type)) {
        alert("Please upload valid excel file");
        return false;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          isLoader: 0,
        });
        /* Parse data */
        const ab = e.target.result;
        const wb = XLSX.read(ab, { type: "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { raw: false, header: 1 });
        let DataList = [];
        let DataListTemp = [];

        let keys = [];
        keys = data[0];
        console.log("keys======", keys);
        let record = {};
        for (let i = 1; i < data.length; i++) {
          let dd = data[i];
          if (dd.length > 0) {
            for (let j = 0; j < dd.length; j++) {
              record[keys[j].trim().split(" ").join("")] =
                dd[j] === undefined ? "" : dd[j].toString().trim();
            }
            DataList.push(record);

            DataListTemp.push(record);
            record = {};
          }
        }
        /* Update state */
        console.log("DataList====", DataList);
        this.setState(
          {
            dataList: DataList,
            fileName: files.name,
            uploaded: true,
            isLoader: 1,
          },
          () => {
            if (DataListTemp.length !== DataList.length) {
              alert("File contains duplicate record(s)");
            }
          }
        );
      };
      reader.readAsArrayBuffer(files);
    }
  }
  addEditSpec(datatype) {
    this.setState({
      dataUploadType: datatype,
    });
    $("#excelUpdatePopup").show();
  }
  cancelPopup() {
    this.setState({
      dataUploadType: "",
    });
    $("#excelUpdatePopup").hide();
  }
  handleCallbackStatus = (status) => {
    this.setState({ imagestatus: status });
  };
  editMapping(editMode, data) {
    if (editMode === "Product Mapping") {
      saveMode = editMode;
      $("#editPopupHeader").text("Edit " + editMode);
      $("#editMappingPopup").attr("hidden", false);
      $("#idTextBox").val(data.id);
      $("#nameTextBox").val(data.name);
      $("#aliasTextBox").val(data.alias);
    } else if (editMode === "Product Details") {
      saveMode = editMode;
      $("#editPopupHeader").text("Edit " + editMode);
      $("#editMappingPopup").attr("hidden", false);
      $("#idTextBox").val(data.id);
      $("#nameTextBox").val(data.product);
      $("#aliasTextBox").val(data.details);
    }
  }
  saveProductDetailMapping() {
    var req = "";
    if (saveMode === "Product Mapping") {
      req = {
        functionName: "editMappingDetails",
        mappingName: $("#nameTextBox").val(),
        mappingAlias: $("#aliasTextBox").val(),
        mappingId: $("#idTextBox").val(),
      };
    } else if (saveMode === "Product Details") {
      req = {
        functionName: "editProductDetails",
        productName: $("#nameTextBox").val(),
        productDetails: $("#aliasTextBox").val(),
        productId: $("#idTextBox").val(),
      };
    }
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        alert(data.successMessage);
        $("#editPopupHeader").text("");
        $("#editMappingPopup").attr("hidden", true);
        this.getMasterGBPData();
        this.getAlias();
      }
    });
  }
  deleteProductDetailsMapping(MODE, id) {
    var req = "";
    if (MODE === "Mapping") {
      req = {
        functionName: "deleteMappingDetails",
        mappingId: id,
      };
    } else if (MODE === "Details") {
      req = {
        functionName: "deleteProductDetails",
        productId: id,
      };
    }
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        alert("Deleted Successfully");
        this.getMasterGBPData();
        this.getAlias();
      } else {
        alert("Something went wrong while deleting");
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  processProductCatalogData(id) {
    $(".loder_div").attr("hidden", false);
    var req = {
      method: "POST",
      body: JSON.stringify({
        createdBy:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        id: id,
      }),
    };
    pythonBackendApi("processProductCatalogData", req);
    $(".loder_div").attr("hidden", true);
    window.location.reload();
  }
  deleteProductCatalogExcel(id) {
    const apiReq = {
      functionName: "deleteProductCatalog",
      id: id,
    };
    console.log("apiReq", apiReq);
    backendApi(apiReq).then((data) => {
      alert("Data deleted successfully");
      this.getMasterGBPData();
    });
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          {/* <div className="dash_heading">
            <div className="leftdash_heading">
              <Icon.Home />
              <span>Product Catalog</span>
            </div>
            <div className="rightdash_heading">
              <ul>
                <li>
                  <Icon.User />
                  <div className="dash_listdrop">
                    <ul>
                      <li>
                        <a href="">
                          <Icon.Settings />
                          Settings
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <Icon.LogOut />
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div> */}

          <Header heading="Product Catalog" headerImage={ProductCatalogimage} />
          <div className="container-fluid">
            <div className="upload-left-section">
              <div className="file_upload_outer">
                <DragDropFile handleFile={this.handleFile}>
                  <div
                    className="form-group formBottom pl-4 justify-content-start"
                    id="form-fileupload"
                  >
                    <DataInput handleFile={this.handleFile} />
                  </div>
                </DragDropFile>
                <a
                  href={mainDataExcel}
                  className="col-lg-4 col-md-12 TemplateDowloadDiv"
                >
                  <Icon.Download /> Download template
                </a>
                {this.state.uploaded === true && this.OutTable()}
              </div>
              <div className="exel_largewindow">
                <div className="exelsheet-wrapper">
                  <div className="exelsheet-head">
                    <Link
                      className="exelsheet-heading exelsheet-complete-report"
                      to="/productCatCompleteReport"
                    >
                      Complete Report
                    </Link>

                    {/* <div className="text-right ml-auto d-flex align-items-center">
                      <p className="exelsheet-status exelsheet-completed mr-2">
                        Completed
                      </p>
                      <p className="exelsheet-status exelsheet-failed mr-2">
                        Failed
                      </p>
                      <p className="exelsheet-running exelsheet-status">
                        Running
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="exel_largewindow">
                <div className="exelsheet-wrapper">
                  <div className="exelsheet-head">
                    <p className="exelsheet-heading">Uploaded Files</p>

                    <div className="text-right ml-auto d-flex align-items-center">
                      {/* <Link to="/livedetails">
                        <button type="button" className="btn btn-success">
                          Live Details
                        </button>
                      </Link> */}
                      <Icon.XSquare
                        className="close_window"
                        onClick={() =>
                          this.onCloseDiv("close_window", "exel_largewindow")
                        }
                      />
                    </div>
                  </div>
                  {this.state.masterGBPData.length !== 0 ? (
                    <>
                      <div className="exelsheet_head">
                        <div className="exelsheet-row">
                          <div className="exelsheet-column col-md-1">Sl.no</div>
                          <div className="exelsheet-column col-md-2">
                            File name
                          </div>
                          <div className="exelsheet-column col-md-2">
                            Comment
                          </div>
                          <div className="exelsheet-column col-md-2">
                            Created On
                          </div>
                          <div className="exelsheet-column col-md-2">
                            Created By
                          </div>
                          <div className="exelsheet-column col-md-3">
                            Action
                          </div>
                          {/* <div className="exelsheet-column col-md-1">
                            Status
                          </div> */}
                        </div>
                      </div>
                      <div
                        className="exelsheet-body"
                        key={this.state.masterGBPData.length}
                      >
                        {this.state.masterGBPData.map((data, i) => (
                          <div className="exelsheet-row" key={data.id}>
                            <div className="exelsheet-column col-md-1">
                              {i + 1}
                            </div>
                            <div className="exelsheet-column col-md-2">
                              {data.file_name}
                            </div>
                            <div className="exelsheet-column col-md-2">
                              {data.comment}
                            </div>
                            <div className="exelsheet-column col-md-2">
                              {data.created_on}
                              {/* ({data.account_number}) */}
                            </div>
                            <div className="exelsheet-column col-md-2">
                              {data.createdBy}
                              {/* ({data.account_number}) */}
                            </div>
                            <div className="exelsheet-column col-md-3">
                              <span title="View More Details" className="mr-2">
                                <Link
                                  to={"/livedetails/" + data.id}
                                  state={{ tableId: data.id }}
                                >
                                  <Icon.Info />
                                </Link>
                              </span>
                              <span title="Add Group" classname="mr-2">
                                <Icon.Plus
                                  onClick={() => {
                                    this.setState({
                                      moreDetailsMasterId: data.id,
                                      moreDetailsIndex: i,
                                      hasGroup:
                                        data.has_group == 1 ? true : false,
                                    });
                                    $("#editMoreDetailsPopup").attr(
                                      "hidden",
                                      false
                                    );
                                  }}
                                />
                              </span>
                              <span title="Delete">
                                <Icon.Trash
                                  onClick={() => {
                                    this.deleteProductCatalogExcel(data.id);
                                  }}
                                />
                              </span>
                            </div>
                            {/* <div className="exelsheet-column col-md-1">
                              {data.process_status}
                            </div> */}
                          </div>
                        ))}
                      </div>
                      <div className="exelsheet_footer">
                        <button
                          type="button"
                          className="fullview_exel"
                          onClick={() =>
                            this.fullViewExcel(
                              "fullview_exel",
                              "exel_largewindow"
                            )
                          }
                        >
                          <Icon.Maximize />
                          <Icon.Maximize2 className="icon_maxi_div" />
                        </button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="exel_largewindow01">
                <div className="exelsheet-wrapper">
                  <div className="exelsheet-head">
                    <p className="exelsheet-heading">Mapping</p>
                    <div className="text-right ml-auto d-flex align-items-center">
                      <button
                        className="image-list-upload"
                        type="button"
                        onClick={() => this.addEditSpec("mapping")}
                      >
                        <img src={uploadImg} width={17} className="mr-1"></img>{" "}
                        Upload
                      </button>
                      <Icon.XSquare
                        className="close_window"
                        onClick={() =>
                          this.onCloseDiv("close_window", "exel_largewindow01")
                        }
                      />
                    </div>
                  </div>
                  {this.state.mappingData.length !== 0 ? (
                    <>
                      <div className="exelsheet_head">
                        <div className="exelsheet-row">
                          <div className="exelsheet-column col-md-2">Sl.no</div>
                          <div className="exelsheet-column col-md-2">Name</div>
                          <div className="exelsheet-column col-md-4">
                            Address
                          </div>
                          <div className="exelsheet-column col-md-2">
                            Instruction
                          </div>
                          <div className="exelsheet-column col-md-2">
                            Status
                          </div>
                        </div>
                      </div>
                      <div className="exelsheet-body">
                        {this.state.mappingData.map((data, i) => (
                          <div className="exelsheet-row" key={data.id}>
                            <div className="exelsheet-column col-md-2">
                              {i + 1}
                            </div>
                            <div className="exelsheet-column col-md-2">
                              {data.name}
                            </div>
                            <div className="exelsheet-column col-md-4">
                              {data.alias}
                            </div>
                            <div className="exelsheet-column col-md-2">
                              <span
                                className="btn-yellow"
                                onClick={() =>
                                  this.deleteProductDetailsMapping(
                                    "Mapping",
                                    data.id
                                  )
                                }
                              >
                                Delete
                              </span>
                            </div>
                            <div className="exelsheet-column col-md-2">
                              <span
                                className="btn-active"
                                onClick={() =>
                                  this.editMapping("Product Mapping", data)
                                }
                              >
                                Edit
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="exelsheet_footer">
                        <button
                          type="button"
                          className="fullview_exel01"
                          onClick={() =>
                            this.fullViewExcel(
                              "fullview_exel01",
                              "exel_largewindow01"
                            )
                          }
                        >
                          <Icon.Maximize />
                          <Icon.Maximize2 className="icon_maxi_div" />
                        </button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="exel_largewindow02">
                <div className="exelsheet-wrapper">
                  <div className="exelsheet-head">
                    <p className="exelsheet-heading">Product Details</p>

                    <div className="text-right ml-auto d-flex align-items-center">
                      <button
                        className="image-list-upload"
                        type="button"
                        onClick={() => this.addEditSpec("productDetail")}
                      >
                        <img src={uploadImg} width={17} className="mr-1"></img>{" "}
                        Upload
                      </button>
                      <Icon.XSquare
                        className="close_window"
                        onClick={() =>
                          this.onCloseDiv("close_window", "exel_largewindow02")
                        }
                      />
                    </div>
                  </div>
                  {this.state.productDetails.length !== 0 ? (
                    <>
                      <div className="exelsheet_head">
                        <div className="exelsheet-row">
                          <div className="exelsheet-column col-md-2">Sl.no</div>
                          <div className="exelsheet-column col-md-2">Name</div>
                          <div className="exelsheet-column col-md-4">
                            Description
                          </div>
                          <div className="exelsheet-column col-md-2">
                            Instruction
                          </div>
                          <div className="exelsheet-column col-md-2">
                            Status
                          </div>
                        </div>
                      </div>
                      <div className="exelsheet-body">
                        {this.state.productDetails.map((data, i) => (
                          <div className="exelsheet-row" key={data.id}>
                            <div className="exelsheet-column col-md-2">
                              {i + 1}
                            </div>
                            <div className="exelsheet-column col-md-2">
                              {data.product}
                            </div>
                            <div className="exelsheet-column col-md-4">
                              {data.details}
                            </div>
                            <div className="exelsheet-column col-md-2">
                              <span
                                className="btn-yellow"
                                onClick={() =>
                                  this.deleteProductDetailsMapping(
                                    "Details",
                                    data.id
                                  )
                                }
                              >
                                Delete
                              </span>
                            </div>
                            <div className="exelsheet-column col-md-2">
                              <span
                                className="btn-active"
                                onClick={() =>
                                  this.editMapping("Product Details", data)
                                }
                              >
                                Edit
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="exelsheet_footer">
                        <button
                          type="button"
                          className="fullview_exel02"
                          onClick={() =>
                            this.fullViewExcel(
                              "fullview_exel02",
                              "exel_largewindow02"
                            )
                          }
                        >
                          <Icon.Maximize />
                          <Icon.Maximize2 className="icon_maxi_div" />
                        </button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <ImageList
              key={this.state.imageList.length + new Date()}
              listImage={this.state.imageList}
              setImageStatus={(status) => {
                console.log("home status===", status);
                if (status == "1") {
                  //this.getImage();
                }
              }}
            />
          </div>
          <Footer />
        </div>
        <div className="popup-overlay" id="excelUpdatePopup">
          <div className="popup-contentDiv">
            <div className="modal-popup">
              <span className="closepopupqual ">
                Upload Files
                <img
                  id="txtCancel"
                  onClick={() => this.cancelPopup()}
                  src={closeImg1}
                  alt="Close"
                  className="img-fluid"
                  width="30"
                />
              </span>
              <div className="content">
                <ExcelUploadPopup
                  key={this.state.mappingData.length}
                  dataType={this.state.dataUploadType}
                  setStatus={(status) => {
                    if (status == "1") {
                      this.getAlias();
                    }
                    this.setState({
                      dataUploadType: "",
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div id="editMappingPopup" className="module_popup" hidden>
          <div className="module_innerpopup">
            <div className="module_close">
              <Icon.XSquare
                onClick={() => {
                  $("#editMappingPopup").attr("hidden", true);
                }}
              />
            </div>
            <div id="editPopupHeader" className="module_header"></div>
            <div className="module_body">
              <div className="module_popupsearch_wrpr">
                <div className="module_popupsearch" hidden>
                  <input id="idTextBox" type="text" placeholder="Name" />
                </div>
                <div className="module_popupsearch">
                  <input id="nameTextBox" type="text" placeholder="Name" />
                </div>
                <div className="module_popupsearch">
                  <textarea id="aliasTextBox" type="text" placeholder="Alias" />
                </div>
                <div className="module_popupsearch">
                  <button
                    className="btn-submit"
                    type="button"
                    onClick={() => this.saveProductDetailMapping()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="editCommentPopup" className="module_popup" hidden>
          <div className="module_innerpopup">
            <div className="module_close">
              <Icon.XSquare
                onClick={() => {
                  $("#editCommentPopup").attr("hidden", true);
                }}
              />
            </div>
            <div id="editCommentPopupHeader" className="module_header"></div>
            <div className="module_body">
              <div className="module_popupsearch_wrpr">
                <div className="module_popupsearch">
                  <input
                    id="editCommentPopupTextBox"
                    type="text"
                    placeholder="Name"
                  />
                </div>
                <div className="module_popupsearch">
                  <button
                    className="btn-submit"
                    type="button"
                    onClick={() => this.saveAndStartUpload()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="editMoreDetailsPopup" className="module_popup" hidden>
          <div className="module_innerpopup">
            <div className="module_close">
              <Icon.XSquare
                onClick={() => {
                  $("#editMoreDetailsPopup").attr("hidden", true);
                }}
              />
            </div>
            <div
              id="editMoreDetailsPopupHeader"
              className="module_header"
            ></div>
            <div className="module_body">
              <div className="module_popupsearch_wrpr">
                <div className="module_popupsearch">
                  <label>Does this GBP have groups?</label>
                  <select
                    onChange={(e) =>
                      this.setState({
                        hasGroup: e.target.value == 1 ? true : false,
                      })
                    }
                    defaultValue={this.state.hasGroup == true ? "1" : "0"}
                  >
                    <option value="0" defaultChecked>
                      No
                    </option>
                    <option value="1">Yes</option>
                  </select>
                </div>
                {this.state.hasGroup == true && (
                  <div className="module_popupsearch">
                    <input
                      id="groupItems"
                      defaultValue={this.state.masterGBPData[
                        this.state.moreDetailsIndex
                      ]?.group_name?.toString()}
                      onChange={(e) =>
                        this.setState({
                          groupItems: e.target.value,
                        })
                      }
                      type="text"
                      placeholder="Name"
                    />
                  </div>
                )}
                <div className="module_popupsearch">
                  <button
                    className="btn-submit"
                    type="button"
                    onClick={() => this.saveMoreDetails()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}

export default Home;
