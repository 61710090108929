import React, { useState, useEffect, useRef } from 'react'
import { backendDashboardApi } from "../apiService";

const DealerShipInvoice = ({ selectedStartDate, areaOffice, invoiceStatus, finalisedData, handleIncrementApiCount, onDataReceived }) => {

    const [dealerShipInvoiceList, setDealerShipInvoiceList] = useState([]);
    const [totalconvergenceePlatform, setTotalconvergenceePlatform] = useState("");
    const [totalsocialMediaCreationAndManagement, setTotalsocialMediaCreationAndManagement] = useState("");
    const [totalmediaAddSpentBudgetted, setTotalmediaAddSpentBudgetted] = useState("");
    const [totalmediaAdSpendActual, setTotalmediaAdSpendActual] = useState("");
    const [grandTotalOftotalAmount, setGrandTotalOftotalAmount] = useState("");


    const [dealerShipInvoiceListPV, setDealerShipInvoiceListPV] = useState([]);
    const [totalconvergenceePlatformPV, setTotalconvergenceePlatformPV] = useState("");
    const [totalsocialMediaCreationAndManagementPV, setTotalsocialMediaCreationAndManagementPV] = useState("");
    const [totalmediaAddSpentBudgettedPV, setTotalmediaAddSpentBudgettedPV] = useState("");
    const [totalmediaAdSpendActualPV, setTotalmediaAdSpendActualPV] = useState("");
    const [grandTotalOftotalAmountPV, setGrandTotalOftotalAmountPV] = useState("");

    const [dealerShipInvoiceListSCV, setDealerShipInvoiceListSCV] = useState([]);
    const [totalconvergenceePlatformSCV, setTotalconvergenceePlatformSCV] = useState("");
    const [totalsocialMediaCreationAndManagementSCV, setTotalsocialMediaCreationAndManagementSCV] = useState("");
    const [totalmediaAddSpentBudgettedSCV, setTotalmediaAddSpentBudgettedSCV] = useState("");
    const [totalmediaAdSpendActualSCV, setTotalmediaAdSpendActualSCV] = useState("");
    const [grandTotalOftotalAmountSCV, setGrandTotalOftotalAmountSCV] = useState("");

    let formattedStartDate = new Date(selectedStartDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
    });
    useEffect(() => {
        debugger
        if (finalisedData == null && invoiceStatus !== "Finalized") {
            handleDealerShipInvoiceListCV();
            handleDealerShipInvoiceListPV();
            handleDealerShipInvoiceListSCV();
        } else {
            if (finalisedData) {
                console.log("finalised", finalisedData)
                //dealershipCv
                setDealerShipInvoiceList(finalisedData.dealershipCv.invoiceBudgetDetails)
                setTotalconvergenceePlatform(finalisedData.dealershipCv.totalconvergenceePlatform)
                setTotalsocialMediaCreationAndManagement(finalisedData.dealershipCv.totalsocialMediaCreationAndManagement)
                setTotalmediaAddSpentBudgetted(finalisedData.dealershipCv.totalmediaAddSpentBudgetted)
                setTotalmediaAdSpendActual(finalisedData.dealershipCv.totalmediaAdSpendActual)
                setGrandTotalOftotalAmount(finalisedData.dealershipCv.grandTotalOftotalAmount)
                handleIncrementApiCount();
                //dealershipPv
                setDealerShipInvoiceListPV(finalisedData.dealershipPv.invoiceBudgetDetails)
                setTotalconvergenceePlatformPV(finalisedData.dealershipPv.totalconvergenceePlatform)
                setTotalsocialMediaCreationAndManagementPV(finalisedData.dealershipPv.totalsocialMediaCreationAndManagement)
                setTotalmediaAddSpentBudgettedPV(finalisedData.dealershipPv.totalmediaAddSpentBudgetted)
                setTotalmediaAdSpendActualPV(finalisedData.dealershipPv.totalmediaAdSpendActual)
                setGrandTotalOftotalAmountPV(finalisedData.dealershipPv.grandTotalOftotalAmount)
                //dealershipScv
                setDealerShipInvoiceListSCV(finalisedData.dealershipScv.invoiceBudgetDetails)
                setTotalconvergenceePlatformSCV(finalisedData.dealershipScv.totalconvergenceePlatform)
                setTotalsocialMediaCreationAndManagementSCV(finalisedData.dealershipScv.totalsocialMediaCreationAndManagement)
                setTotalmediaAddSpentBudgettedSCV(finalisedData.dealershipScv.totalmediaAddSpentBudgetted)
                setTotalmediaAdSpendActualSCV(finalisedData.dealershipScv.totalmediaAdSpendActual)
                setGrandTotalOftotalAmountSCV(finalisedData.dealershipScv.grandTotalOftotalAmount)
            }
        }
    }, [finalisedData]);

    const handleDealerShipInvoiceListCV = () => {
        const postData = {
            "functionName": "invoiceBudgetDetails",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "areaOffice": areaOffice,
            "userId": window.sessionStorage.getItem("userId"),
            "fromDate": formattedStartDate,
            "type": "CV"
        }
        backendDashboardApi(postData).then((data) => {
            console.log("🚀 ~ backendDashboardApi ~ data handleDealerShipInvoiceList:", data)
            if (data.success === "1") {
                onDataReceived("dealershipCv", data.result[0])
                setDealerShipInvoiceList(data.result[0].invoiceBudgetDetails)
                setTotalconvergenceePlatform(data.result[0].totalconvergenceePlatform)
                setTotalsocialMediaCreationAndManagement(data.result[0].totalsocialMediaCreationAndManagement)
                setTotalmediaAddSpentBudgetted(data.result[0].totalmediaAddSpentBudgetted)
                setTotalmediaAdSpendActual(data.result[0].totalmediaAdSpendActual)
                setGrandTotalOftotalAmount(data.result[0].grandTotalOftotalAmount)
                handleIncrementApiCount();
            } else {
                setDealerShipInvoiceList([])
            }
        });
    }

    const handleDealerShipInvoiceListPV = () => {
        const postData = {
            "functionName": "invoiceBudgetDetails",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "areaOffice": areaOffice,
            "userId": window.sessionStorage.getItem("userId"),
            "fromDate": formattedStartDate,
            "type": "PV"
        }
        backendDashboardApi(postData).then((data) => {
            console.log("🚀 ~ backendDashboardApi ~ data handleDealerShipInvoiceList:", data)
            if (data.success === "1") {
                onDataReceived("dealershipPv", data.result[0])
                setDealerShipInvoiceListPV(data.result[0].invoiceBudgetDetails)
                setTotalconvergenceePlatformPV(data.result[0].totalconvergenceePlatform)
                setTotalsocialMediaCreationAndManagementPV(data.result[0].totalsocialMediaCreationAndManagement)
                setTotalmediaAddSpentBudgettedPV(data.result[0].totalmediaAddSpentBudgetted)
                setTotalmediaAdSpendActualPV(data.result[0].totalmediaAdSpendActual)
                setGrandTotalOftotalAmountPV(data.result[0].grandTotalOftotalAmount)
                handleIncrementApiCount();
            } else {
                setDealerShipInvoiceListPV([])
            }
        });
    }


    const handleDealerShipInvoiceListSCV = () => {
        const postData = {
            "functionName": "invoiceBudgetDetails",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "areaOffice": areaOffice,
            "userId": window.sessionStorage.getItem("userId"),
            "fromDate": formattedStartDate,
            "type": "SCV"
        }
        backendDashboardApi(postData).then((data) => {
            console.log("🚀 ~ backendDashboardApi ~ data handleDealerShipInvoiceList:", data)
            if (data.success === "1") {
                onDataReceived("dealershipScv", data.result[0])
                setDealerShipInvoiceListSCV(data.result[0].invoiceBudgetDetails)
                setTotalconvergenceePlatformSCV(data.result[0].totalconvergenceePlatform)
                setTotalsocialMediaCreationAndManagementSCV(data.result[0].totalsocialMediaCreationAndManagement)
                setTotalmediaAddSpentBudgettedSCV(data.result[0].totalmediaAddSpentBudgetted)
                setTotalmediaAdSpendActualSCV(data.result[0].totalmediaAdSpendActual)
                setGrandTotalOftotalAmountSCV(data.result[0].grandTotalOftotalAmount)
                handleIncrementApiCount();
            } else {
                setDealerShipInvoiceListSCV([])
            }
        });
    }



    var container = { width: "711px", margin: "0px auto", padding: "5px" };
    var page = { width: "711px", padding: 0, margin: "0 auto", background: "white" };
    var colContent = { fontSize: "6px", margin: "2px 0px 0px", padding: "5px" }
    var col1 = { width: "711px", float: "left", textAlign: "center" }
    var tableStr = {
        width: "711px", float: "left", display: "flex", fontSize: "6px", fontWeight: "600", borderBottom: "2px solid rgb(178 199 233)",
        border: "1px solid #000", borderRadius: "0px", background: "#d9e7fd", borderBottom: "none"
    }

    var tableStrBdy = {
        width: "711px", float: "left", display: "flex", fontSize: "6px", fontWeight: "600", borderBottom: "2px solid rgb(178 199 233)",
        border: "1px solid #000", borderRadius: "0px", borderTop: "none"
    }
    var tableStrBdyBrdr = {
        width: "711px", float: "left", display: "flex", fontSize: "6px", fontWeight: "600", border: "none",
        borderRadius: "0px", borderTop: "none"
    }
    var tblHeadSl = { fontSize: "6px", width: "50px", textAlign: "left", border: "none", borderRight: "1px solid #000", padding: "5px 3px", textAlign: "center", verticalAlign: "middle", borderRadius: "0px", margin: "0px", fontWeight: "normal" }
    var tblHeadSlBtm = { fontSize: "6px", width: "50px", textAlign: "left", border: "none", padding: "5px 3px", textAlign: "center", verticalAlign: "middle", borderRadius: "0px", margin: "0px", fontWeight: "normal" }
    var tblHead = { fontSize: "6px", width: "85px", textAlign: "left", border: "none", borderRight: "1px solid #000", padding: "5px 3px", textAlign: "center", verticalAlign: "middle", borderRadius: "0px", margin: "0px", fontWeight: "normal" }

    var tblHeadRight = { fontSize: "6px", width: "85px", textAlign: "left", border: "none", padding: "5px 3px", textAlign: "center", verticalAlign: "middle", borderRadius: "0px", margin: "0px", fontWeight: "normal" }

    var tblHeadDealer = { fontSize: "6px", width: "100px", textAlign: "left", border: "none", borderRight: "1px solid #000", padding: "5px 3px", verticalAlign: "middle", borderRadius: "0px", margin: "0px", fontWeight: "normal" }
    var tblHeadDealerBtm = { fontSize: "6px", width: "100px", textAlign: "left", border: "none", padding: "5px 3px", verticalAlign: "middle", borderRadius: "0px", margin: "0px", fontWeight: "normal" }


    var tblHeadLocation = { fontSize: "6px", width: "100px", textAlign: "left", border: "none", borderRight: "1px solid #000", padding: "5px 3px", verticalAlign: "middle", borderRadius: "0px", margin: "0px", fontWeight: "normal" }
    var tblHeadLocationBtm = { fontSize: "6px", width: "100px", textAlign: "left", border: "none", padding: "5px 3px", verticalAlign: "middle", borderRadius: "0px", margin: "0px", fontWeight: "normal" }

    var tblHeadArea = { fontSize: "6px", width: "85px", textAlign: "left", border: "none", borderRight: "1px solid #000", padding: "5px 3px", verticalAlign: "middle", borderRadius: "0px", margin: "0px", fontWeight: "normal" }

    var tblHeadAreaRightNone = { fontSize: "6px", width: "85px", textAlign: "left", border: "none", padding: "5px 3px", verticalAlign: "middle", borderRadius: "0px", margin: "0px", fontWeight: "normal" }


    var tblHeadAreaBtm = { fontSize: "6px", width: "85px", textAlign: "left", border: "none", padding: "5px 3px", verticalAlign: "middle", borderRadius: "0px", margin: "0px", fontWeight: "normal" }

    var tblHeadAreaRight = { fontSize: "6px", width: "85px", textAlign: "right", border: "none", borderRight: "1px solid #000", padding: "5px 3px", verticalAlign: "middle", borderRadius: "0px", margin: "0px", fontWeight: "normal" }
    var tblHeadAreaRightBtm = { fontSize: "6px", width: "85px", textAlign: "right", border: "none", padding: "5px 3px", verticalAlign: "middle", borderRadius: "0px", margin: "0px", fontWeight: "normal" }

    return (
        <div id="container" class="container1" style={container}>
            {/* CV Table */}
            {dealerShipInvoiceList.length > 0 ?
                <div class="invoice-page page" style={page}>
                    <div class="subpage" style={{ clear: "both" }}>
                        <div className="table1">
                            <div style={{ padding: "0px" }}>
                                <div style={tableStr}>
                                    <p style={tblHeadSl}><b>Sl.No</b></p>
                                    <p style={tblHeadDealer}><b>Dealership Name</b></p>
                                    <p style={tblHeadLocation}><b>Location</b></p>
                                    <p style={tblHeadArea}><b>Area Office</b> </p>
                                    <p style={tblHead}><b>Segment</b></p>
                                    <p style={tblHead}><b>ConvergenSEE Platform</b></p>
                                    <p style={tblHead}><b> Social Media Creation and Managemen</b></p>
                                    <p style={tblHead}><b>Media Ad Spend (Budgeted)</b></p>
                                    <p style={tblHead}><b>Media Ad Spend (Actuals)</b> </p>
                                    <p style={tblHead}><b>Total amount</b></p>
                                    <p style={tblHead}><b> Dealer Share</b></p>
                                    <p style={tblHead}><b>Dealer Share with GST</b></p>
                                    <p style={tblHead}><b>AO Share</b></p>
                                    <p style={tblHead}><b>AO Share with GST</b></p>
                                    
                                </div>
                            </div>
                            <div style={{ borderRadius: "0px", boxShadow: "none", margin: "0px", padding: "0px" }}>
                                {dealerShipInvoiceList.map((item, index) => (
                                    <div key={index} style={tableStrBdy}>
                                        <p style={tblHeadSl}>{index + 1}</p>
                                        <p style={tblHeadDealer}> {item.dealerName} </p>
                                        <p style={tblHeadLocation}>{item.locality}</p>
                                        <p style={tblHeadArea}>{item.area_office} </p>
                                        <p style={tblHeadArea}>{item.segment}</p>

                                        <p style={tblHeadAreaRight}>{item.convergenceePlatform.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                                        <p style={tblHeadAreaRight}>{item.socialMediaCreationAndManagement.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                                        <p style={tblHeadAreaRight}>{item.mediaAddSpentBudgetted.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                                        <p style={tblHeadAreaRight}>{item.mediaAdSpendActual.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                                        <p style={tblHeadAreaRight}>{item.totalAmount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                                        <p style={tblHeadAreaRight}>00</p>
                                        <p style={tblHeadAreaRight}>00</p>
                                        <p style={tblHeadAreaRight}>00</p>
                                        <p style={tblHeadAreaRight}>00</p>

                                    </div>
                                ))}


                                <div style={tableStrBdyBrdr}>
                                    <p style={tblHeadSlBtm}>  </p>
                                    <p style={tblHeadDealerBtm}>  </p>
                                    <p style={tblHeadLocationBtm}> </p>
                                    <p style={tblHeadAreaBtm}>  </p>
                                    <p style={tblHeadAreaBtm}> </p>

                                    <p style={tblHeadAreaRightBtm}><b>{totalconvergenceePlatform.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</b></p>
                                    <p style={tblHeadAreaRightBtm}><b>{totalsocialMediaCreationAndManagement.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</b></p>
                                    <p style={tblHeadAreaRightBtm}><b>{totalmediaAddSpentBudgetted.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</b></p>
                                    <p style={tblHeadAreaRightBtm}><b>{totalmediaAdSpendActual.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</b> </p>
                                    <p style={tblHeadAreaRightBtm}><b>{grandTotalOftotalAmount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</b></p>
                                   
                                    <p style={tblHeadAreaRightBtm}><b> 00</b></p>
                                    <p style={tblHeadAreaRightBtm}><b> 00</b></p>
                                    <p style={tblHeadAreaRightBtm}><b>00</b> </p>
                                    <p style={tblHeadAreaRightBtm}><b>00</b></p>


                                </div>
                                <div style={tableStrBdyBrdr}>
                                    <p style={tblHeadSlBtm}> &nbsp; </p>
                                    <p style={tblHeadDealerBtm}>&nbsp;  </p>
                                    <p style={tblHeadLocationBtm}> &nbsp;</p>
                                    <p style={tblHeadAreaBtm}> &nbsp; </p>
                                    <p style={tblHeadAreaBtm}> &nbsp;</p>

                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>

                                </div>
                                <div style={tableStrBdyBrdr}>
                                    <p style={tblHeadSlBtm}> &nbsp; </p>
                                    <p style={tblHeadDealerBtm}> &nbsp; </p>
                                    <p style={tblHeadLocationBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaBtm}> &nbsp; </p>
                                    <p style={tblHeadAreaBtm}>&nbsp; </p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                </div>
                                <br />
                                <br />
                            </div>




                        </div>
                    </div>
                </div>
                : ""}
            <br />
            {/* PV table */}

            {dealerShipInvoiceListPV.length > 0 ?
                <div class="invoice-page page" style={page}>
                    <div class="subpage" style={{ clear: "both" }}>
                        <div className="table1">
                            <div style={{ padding: "0px" }}>
                                <div style={tableStr}>
                                    <p style={tblHeadSl}><b>Sl.No</b></p>
                                    <p style={tblHeadDealer}><b>Dealership Name</b></p>
                                    <p style={tblHeadLocation}><b>Location</b></p>
                                    <p style={tblHeadArea}><b>Area Office</b> </p>
                                    <p style={tblHead}><b>Segment</b></p>
                                    <p style={tblHead}><b>ConvergenSEE Platform</b></p>
                                    <p style={tblHead}><b> Social Media Creation and Managemen</b></p>
                                    <p style={tblHead}><b>Media Ad Spend (Budgeted)</b></p>
                                    <p style={tblHead}><b>Media Ad Spend (Actuals)</b> </p>
                                    <p style={tblHead}><b>Total amount</b></p>
                                    <p style={tblHead}><b> Dealer Share</b></p>
                                    <p style={tblHead}><b>Dealer Share with GST</b></p>
                                    <p style={tblHead}><b>AO Share</b></p>
                                    <p style={tblHead}><b>AO Share with GST</b></p>
                                </div>
                            </div>
                            <div style={{ borderRadius: "0px", boxShadow: "none", margin: "0px", padding: "0px" }}>
                                {dealerShipInvoiceListPV.map((item, index) => (
                                    <div key={index} style={tableStrBdy}>
                                        <p style={tblHeadSl}>{index + 1}</p>
                                        <p style={tblHeadDealer}> {item.dealerName} </p>
                                        <p style={tblHeadLocation}>{item.locality}</p>
                                        <p style={tblHeadArea}>{item.area_office} </p>
                                        <p style={tblHeadArea}>{item.segment}</p>

                                        <p style={tblHeadAreaRight}>{item.convergenceePlatform.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                                        <p style={tblHeadAreaRight}>{item.socialMediaCreationAndManagement.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                                        <p style={tblHeadAreaRight}>{item.mediaAddSpentBudgetted.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                                        <p style={tblHeadAreaRight}>{item.mediaAdSpendActual.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                                        <p style={tblHeadAreaRight}>{item.totalAmount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
 <p style={tblHeadAreaRight}>00</p>
                                        <p style={tblHeadAreaRight}>00</p>
                                        <p style={tblHeadAreaRight}>00</p>
                                        <p style={tblHeadAreaRight}>00</p>
                                    </div>
                                ))}


                                <div style={tableStrBdyBrdr}>
                                    <p style={tblHeadSlBtm}>  </p>
                                    <p style={tblHeadDealerBtm}>  </p>
                                    <p style={tblHeadLocationBtm}> </p>
                                    <p style={tblHeadAreaBtm}>  </p>
                                    <p style={tblHeadAreaBtm}> </p>

                                    <p style={tblHeadAreaRightBtm}><b>{totalconvergenceePlatformPV.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</b></p>
                                    <p style={tblHeadAreaRightBtm}><b>{totalsocialMediaCreationAndManagementPV.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</b></p>
                                    <p style={tblHeadAreaRightBtm}><b>{totalmediaAddSpentBudgettedPV.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</b></p>
                                    <p style={tblHeadAreaRightBtm}><b>{totalmediaAdSpendActualPV.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</b> </p>
                                    <p style={tblHeadAreaRightBtm}><b>{grandTotalOftotalAmountPV.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</b></p>
                                    
                                    <p style={tblHeadAreaRightBtm}><b> 00</b></p>
                                    <p style={tblHeadAreaRightBtm}><b> 00</b></p>
                                    <p style={tblHeadAreaRightBtm}><b>00</b> </p>
                                    <p style={tblHeadAreaRightBtm}><b>00</b></p>
                                </div>
                                <div style={tableStrBdyBrdr}>
                                    <p style={tblHeadSlBtm}> &nbsp; </p>
                                    <p style={tblHeadDealerBtm}>&nbsp;  </p>
                                    <p style={tblHeadLocationBtm}> &nbsp;</p>
                                    <p style={tblHeadAreaBtm}> &nbsp; </p>
                                    <p style={tblHeadAreaBtm}> &nbsp;</p>

                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>

                                </div>
                                <div style={tableStrBdyBrdr}>
                                    <p style={tblHeadSlBtm}> &nbsp; </p>
                                    <p style={tblHeadDealerBtm}> &nbsp; </p>
                                    <p style={tblHeadLocationBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaBtm}> &nbsp; </p>
                                    <p style={tblHeadAreaBtm}>&nbsp; </p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                </div>
                                <br />
                                <br />
                                <br />
                                <br />
                            </div>




                        </div>
                    </div>
                </div>
                : ""}
            <br />

            {/* SCV Table */}

            {dealerShipInvoiceListSCV.length > 0 ?
                <div class="invoice-page page" style={page}>
                    <div class="subpage" style={{ clear: "both" }}>
                        <div className="table1">
                            <div style={{ padding: "0px" }}>
                                <div style={tableStr}>
                                    <p style={tblHeadSl}><b>Sl.No</b></p>
                                    <p style={tblHeadDealer}><b>Dealership Name</b></p>
                                    <p style={tblHeadLocation}><b>Location</b></p>
                                    <p style={tblHeadArea}><b>Area Office</b> </p>
                                    <p style={tblHead}><b>Segment</b></p>
                                    <p style={tblHead}><b>ConvergenSEE Platform</b></p>
                                    <p style={tblHead}><b> Social Media Creation and Managemen</b></p>
                                    <p style={tblHead}><b>Media Ad Spend (Budgeted)</b></p>
                                    <p style={tblHead}><b>Media Ad Spend (Actuals)</b> </p>
                                    <p style={tblHead}><b>Total amount</b></p>
                                    <p style={tblHead}><b> Dealer Share</b></p>
                                    <p style={tblHead}><b>Dealer Share with GST</b></p>
                                    <p style={tblHead}><b>AO Share</b></p>
                                    <p style={tblHead}><b>AO Share with GST</b></p>
                                </div>
                            </div>
                            <div style={{ borderRadius: "0px", boxShadow: "none", margin: "0px", padding: "0px" }}>
                                {dealerShipInvoiceListSCV.map((item, index) => (
                                    <div key={index} style={tableStrBdy}>
                                        <p style={tblHeadSl}>{index + 1}</p>
                                        <p style={tblHeadDealer}> {item.dealerName} </p>
                                        <p style={tblHeadLocation}>{item.locality}</p>
                                        <p style={tblHeadArea}>{item.area_office} </p>
                                        <p style={tblHeadArea}>{item.segment}</p>

                                        <p style={tblHeadAreaRight}>{item.convergenceePlatform.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                                        <p style={tblHeadAreaRight}>{item.socialMediaCreationAndManagement.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                                        <p style={tblHeadAreaRight}>{item.mediaAddSpentBudgetted.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                                        <p style={tblHeadAreaRight}>{item.mediaAdSpendActual.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                                        <p style={tblHeadAreaRight}>{item.totalAmount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                                        <p style={tblHeadAreaRight}><b> 00</b></p>
                                    <p style={tblHeadAreaRight}><b> 00</b></p>
                                    <p style={tblHeadAreaRight}><b>00</b> </p>
                                    <p style={tblHeadAreaRight}><b>00</b></p>
                                    </div>
                                ))}


                                <div style={tableStrBdyBrdr}>
                                    <p style={tblHeadSlBtm}>  </p>
                                    <p style={tblHeadDealerBtm}>  </p>
                                    <p style={tblHeadLocationBtm}> </p>
                                    <p style={tblHeadAreaBtm}>  </p>
                                    <p style={tblHeadAreaBtm}> </p>

                                    <p style={tblHeadAreaRightBtm}><b>{totalconvergenceePlatformSCV.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</b></p>
                                    <p style={tblHeadAreaRightBtm}><b>{totalsocialMediaCreationAndManagementSCV.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</b></p>
                                    <p style={tblHeadAreaRightBtm}><b>{totalmediaAddSpentBudgettedSCV.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</b></p>
                                    <p style={tblHeadAreaRightBtm}><b>{totalmediaAdSpendActualSCV.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</b> </p>
                                    <p style={tblHeadAreaRightBtm}><b>{grandTotalOftotalAmountSCV.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</b></p>
                                   
                                    <p style={tblHeadAreaRightBtm}><b> 00</b></p>
                                    <p style={tblHeadAreaRightBtm}><b> 00</b></p>
                                    <p style={tblHeadAreaRightBtm}><b>00</b> </p>
                                    <p style={tblHeadAreaRightBtm}><b>00</b></p>

                                </div>
                                <div style={tableStrBdyBrdr}>
                                    <p style={tblHeadSlBtm}> &nbsp; </p>
                                    <p style={tblHeadDealerBtm}>&nbsp;  </p>
                                    <p style={tblHeadLocationBtm}> &nbsp;</p>
                                    <p style={tblHeadAreaBtm}> &nbsp; </p>
                                    <p style={tblHeadAreaBtm}> &nbsp;</p>

                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>

                                </div>
                                <div style={tableStrBdyBrdr}>
                                    <p style={tblHeadSlBtm}> &nbsp; </p>
                                    <p style={tblHeadDealerBtm}> &nbsp; </p>
                                    <p style={tblHeadLocationBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaBtm}> &nbsp; </p>
                                    <p style={tblHeadAreaBtm}>&nbsp; </p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                    <p style={tblHeadAreaRightBtm}>&nbsp;</p>
                                </div>
                                <br />
                                <br />
                                <br />
                                <br />
                            </div>




                        </div>
                    </div>
                </div>
                : ""}
            <br />
        </div>
    );
};

export default DealerShipInvoice;