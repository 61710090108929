import React, { useEffect } from "react";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import Dashboardimage from "../../images/left_icons/business.png";
import LeftMenu from "../../layout/leftMenu";
import { useState } from "react";
import $ from "jquery";
import * as Icon from "react-feather";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { backendApi, apiCallingPost } from "../../apiService";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

let stringComparison = require("string-comparison");
let cos = stringComparison.levenshtein;
let matchingLocationId;

export default function PushDataListingPage() {
  const [claimedData, setClaimedData] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [claimedTitle, setclaimedTitle] = useState("");
  const [selectPlace, setSelectPlace] = useState("");
  const [selectPlaceId, setSelectPlaceId] = useState(0);
  const [pushDataList, setPushDataList] = useState([]);
  const [flag, setflag] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    console.log("props of pushDataListingPage", location);
    setPushDataList((obj) => [location.state.auditClaimData]);
  }, []);

  const redirectToGBPFrom = (data) => {
    navigate("/claim_business/PushNodataFromGBP", {
      state: {
        auditClaimData: data,
      },
    });
    // window.location.href = "/claim_business/PushNodataFromGoogle";
  };
  const pushClick = (pushData) => {
    // window.sessionStorage.removeItem("dataPushData")
    navigate("/claim_business/createNewLocationForm", {
      state: {
        auditClaimData: pushDataList,
      },
    });
    // let objData = {
    //   full_name: obj.BusnessName,
    //   address: obj.Adress,
    //   mobile_no: obj.PhoneNo,
    //   email_id: obj.VerificationEmail,
    //   locality: obj.Locality,
    //   postalCode: obj.PostalCode,
    //   administrativeArea: obj.AdminstriveArea,
    //   account_id: window.sessionStorage.getItem("account_id"),
    //   site_id:
    //     window.sessionStorage.getItem("switchingOrgId") === ""
    //       ? window.sessionStorage.getItem("organizationId")
    //       : window.sessionStorage.getItem("switchingOrgId"),
    //   location_id: "",
    //   verification_code: "",
    //   g_pin: "",
    //   status: "",
    //   created_by: obj.CreatedBy,
    // };
    // var api = {
    //   functionName: "insertGbp_localDB",
    //   objectArray: objData,
    // };
    // console.log(api, "apiapiapi");
    // backendApi(api).then((data) => {
    //   if (data.success === "1") {
    //     window.location.href = `/claim_business/nodataFoundBulkUpload/${data.UID}`;
    //   }
    // });
  };
  const createNewLocation = (e) => {
    console.log("pushData", pushDataList);
  };
  const saveNewLocations = (e) => {
    e.preventDefault();
    console.log("pushData", pushDataList);
    let saveArray = [];
    for (const obj of pushDataList) {
      let objData = {
        full_name: obj.location_name,
        address: obj.address,
        mobile_no: obj.phone_no,
        email_id: "",
        locality: obj.address.split(/[\s,]+/).at(-2),
        administrativeArea: obj.address.split(/[\s,]+/).at(-1),
        postalCode: obj.address.split(/[\s,]+/).pop(),
        account_id: window.sessionStorage.getItem("account_id"),
        site_id:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        location_id: "",
        verification_code: "",
        g_pin: "",
        status: "Push Initiated",
        created_by: obj.created_by,
      };
      saveArray.push(objData);
    }
    var api = {
      functionName: "insertGbp_localDB",
      objectArray: saveArray,
    };
    console.log(api, "apiapiapi");
    backendApi(api).then((data) => {
      if (data.success === "1") {
        navigate(`/pushGBPData`);
      }
    });
  };
  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Not Found" headerImage={Dashboardimage} />
        <div className="container">
          <div className="row">
            {/* <div className="col-md-12">
              <h3 className="popup-head">Google Locations</h3>
            </div> */}
            <div className="row mb-3 ml-1">
              <Link to="/claimBusiness" className="back-to-link mr-3">
                <Icon.ArrowLeftCircle />
              </Link>
            </div>
            <div className="w-100">
              <div className="sub_from_audit">From Audit</div>
            </div>
            {/* <button
              className="btn btn-info"
              onClick={(e) => createNewLocation(e)}
            >
              Push Location(s) to GBP
            </button> */}
            <button
              className="btn btn-info"
              onClick={(e) => saveNewLocations(e)}
            >
              Save Locations
            </button>
            <div className="col-md-12 tableResponsive">
              <div className="tableOuterWidth">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="col-1">ID</th>
                      <th className="col-2" style={{ maxWidth: "12%" }}>
                        Business Name
                      </th>
                      <th className="col-7" style={{ maxWidth: "63%" }}>
                        Address
                      </th>
                      {/* <th className="col-1">Status</th> */}
                    </tr>
                  </thead>
                  <div class="table_Outerscroll">
                    <tbody className="table_Outertbody">
                      <tr>
                        <td className="col-1">
                          {console.log(location.state.auditClaimData.id)}
                          {location.state.auditClaimData.id}
                        </td>
                        <td className="col-2" style={{ maxWidth: "12%" }}>
                          {location.state.auditClaimData.location_name}
                        </td>
                        <td className="col-7" style={{ maxWidth: "63%" }}>
                          {location.state.auditClaimData.address}
                        </td>
                        {/* <td>
                          <button
                            className="btn btn-primary"
                            onClick={(e) =>
                              pushClick(location.state.auditClaimData)
                            }
                          >
                            Push
                          </button>
                        </td> */}
                        {/* <td className="col-1">
                          {location.state.auditClaimData.status}
                        </td> */}
                      </tr>
                    </tbody>
                  </div>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
}
