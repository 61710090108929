import React, { useState, useEffect } from "react";
import SaveAndValidation from "./settingsSaveAndValidation";
function SettingSocialMediaTags(props) {
  var orgadmin = window.sessionStorage.getItem("role") == "ORG_ADMIN";
  const [socialTags, setsocialTags] = useState(
    props.listingValuesForPassing.social_tags != "undefined "
      ? props.listingValuesForPassing.social_tags
      : ""
  );
  useEffect(() => {
    if (
      props.listingValuesForPassing.social_tags &&
      (props.listingValuesForPassing.social_tags != "null" ||
        props.listingValuesForPassing.social_tags != "undefined")
    ) {
      setsocialTags(props.listingValuesForPassing.social_tags);
      window.sessionStorage.setItem(
        "social_tags",
        props.listingValuesForPassing.social_tags
      );
    } else {
      setsocialTags("");
    }
    ////////////////
  }, [props.listingValuesForPassing.social_tags]);
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="user_formdiv">
          <div className="userform_head">Social media tags</div>
          <div className="row userrow_form">
            <div className="col-md-4">
              <label className="col-form-label">Social media tags</label>
            </div>
            <div className="col-md-1">
              <span>:</span>
            </div>
            <div className="col-md-7">
              <input
                type="text"
                id="socialtags"
                onChange={(e) => {
                  setsocialTags(e.target.value);
                }}
                value={socialTags}
                className="form-control"
                placeholder="Social Tags"
              />
            </div>
          </div>
          <SaveAndValidation
             getUserData={() => props.getUserData()}
            valueForSaved={[socialTags]}
            saveMode={"socialMediaTag"}
            organizationId={props.organizationId}
          />
        </div>
      </div>
    </div>
  );
}
export default SettingSocialMediaTags;
