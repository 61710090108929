import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import rolesimage from "../images/left_icons/roles.png";
import bank1Image from "../images/bank1.png";
import LeftMenu from "../layout/leftMenu";
import { backendApi } from "../apiService";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import * as Icon from "react-feather";
import $ from "jquery";
import MultiProgress from "react-multi-progress";

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      roleList: [],
      approved: false,
      userID: window.sessionStorage.getItem("userId"),
      isLogin: window.sessionStorage.getItem("isLogin"),
      userRole: window.sessionStorage.getItem("role"),
      userOrganization: window.sessionStorage.getItem("organization"),
      organizationAllotment:
        window.sessionStorage.getItem("organizationAllotment") === "undefined"
          ? []
          : JSON.parse(window.sessionStorage.getItem("organizationAllotment")),
      modules: JSON.parse(window.sessionStorage.getItem("modules")),
      moduleActive: false,
      organizationListActive: false,
      moduleId: [],
      organizationListId: [],
      userRoleid: "",
      userListModules: [],
      searchValue: "",
      emailFilter:
        window.sessionStorage.getItem("role") == "ORG_ADMIN"
          ? window.sessionStorage.getItem("emailsession") != ""
            ? window.sessionStorage
                .getItem("emailsession")
                .split("@")[1]
                .split(".")[0]
            : ""
          : this.props.organizationemail,
    };
  }

  changeStatus(e, moduleId) {
    $("#moduleIds" + moduleId).toggleClass("active");

    let newArray = [...this.state.moduleId, moduleId];

    if (this.state.moduleId.includes(moduleId)) {
      newArray = newArray.filter((checked) => checked !== moduleId);
    }
    if (this.state.modules.length == newArray.length) {
      $("#modulesSelectAll").prop("checked", true);
    }
    this.setState({
      moduleId: newArray,
    });
  }
  changeOrgStatus(e, orgId) {
    $("#organizationId" + orgId).toggleClass("active");

    let newArray = [...this.state.organizationListId, orgId];
    if (this.state.organizationListId.includes(orgId)) {
      newArray = newArray.filter((checked) => checked !== orgId);
    }

    this.setState({
      organizationListId: newArray,
    });
  }
  saveModules() {
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "addModulesForUser",
      userId: window.sessionStorage.getItem("userId"),
      moduleUserId: this.state.userRoleid,
      moduleIdsList: this.state.moduleId,
    };

    backendApi(req).then((response) => {
      $(".loder_div").attr("hidden", true);
      if (response.success === "1") {
        alert("Modules added successfully");
        window.location.reload();
      }
    });
  }

  saveOrgAllotment() {
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "addorgForUser",
      user_id: this.state.userRoleid,
      organisation_id: this.state.organizationListId,
    };

    backendApi(req).then((response) => {
      $(".loder_div").attr("hidden", true);
      if (response.success === "1") {
        alert("Organization(s) added successfully");
        window.location.reload();
      }
    });
  }
  componentDidMount() {
    this.getRoles();
    this.getUserList();
    // $('.add_usser_listplus').click(function(){
    //   alert('ok')
    // })
    $("#editCommentPopup").attr("hidden", true);
  }
  toggleModal(id) {
    $(".loder_div").attr("hidden", false);
    var apiReq = {
      id: id,
      functionName: "getUserModuleList",
    };
    var array = [];
    backendApi(apiReq).then((response) => {
      $(".loder_div").attr("hidden", true);
      console.log("datatat", response.data[1]);
      if (this.state.modules.length == response.data[1].length) {
        $("#modulesSelectAll").prop("checked", true);
      } else {
        $("#modulesSelectAll").prop("checked", false);
      }
      if (response.data[1].length > 0) {
        response.data[1].forEach((module) => {
          $("body").addClass("isActive");
          array.push(module.moduleId);
          $("#moduleIds" + module.moduleId).addClass("active");
        });
      } else {
        $("body").addClass("isActive");
      }
      this.setState({
        userRoleid: id,
        moduleId: array,
      });
    });
    // debugger;
    // console.log("jokkkkkeeeeee", this.state.userList);
    // var currentUser = this.state.userList.filter((obj) => obj.id === id);
    // if (currentUser[0].modules) {
    // currentUser[0].modules.forEach((module) => {
    //   array.push(module.moduleId);
    //   $("#moduleIds" + module.moduleId).addClass("active");
    // });
    // }

    //  currentUser.map((data)=>(

    //   data.forEach(userList => {
    //     userList.modules.forEach(module=>{
    //       $('#moduleId'+module.moduleId).toggleClass('active')
    //     })

    //   })
    //  ))
  }
  toggleModal2(id) {
    $(".loder_div").attr("hidden", false);
    var apiReq = {
      id: id,
      functionName: "getUserModuleList",
    };
    var array = [];
    backendApi(apiReq).then((response) => {
      $("#editCommentPopup").attr("hidden", false);
      $(".loder_div").attr("hidden", true);
      if (response.success == "1") {
        response.data[0].forEach((obj) => {
          array.push(obj.id);
          $("#organizationId" + obj.id).addClass("active");
        });
        this.setState({
          userRoleid: id,
          organizationListId: array,
        });
      }
    });
    
  }

  removeModal() {
    $("body").removeClass("isActive");
    $(".modules_div").removeClass("active");
    $("#modulesSelectAll").prop("checked", false);
    // this.state.modules.forEach((obj) => {
    //   $("#moduleIds" + obj.moduleId).removeClass("active");
    // });
  }
  getRoles() {
    var req = {
      functionName: "roleList",
    };
    backendApi(req).then((response) => {
      if (response.success === "1") {
        this.setState({ roleList: response.result }, () => {});
      }
    });
  }

  // getModules() {
  //   var req = {
  //     method: "POST",
  //     body: JSON.stringify({}),
  //   };
  //   backendApi("moduleListing", req).then((response) => {
  //     if (response.success === "1") {
  //       this.setState({
  //         modules: response.data,
  //       });
  //     }
  //   });
  // }

  getUserList() {
    // debugger;
    $(".loder_div").attr("hidden", false);
    var req = {
      userId: this.state.userID,
      role: this.state.userRole,
      organization: this.state.userOrganization,
      functionName: "userlist",
    };
    const apiReq = {
      userId: this.state.userID,
      role: this.state.userRole,
      organization: this.state.userOrganization,
      functionName: "userlistNew",
      searchParam: this.state.searchValue,
    };
    backendApi(apiReq).then((response) => {
      $(".loder_div").attr("hidden", true);
      if (response.success === "1") {
        console.log("loooooog", response);
        this.setState({ userList: response.data });
      } else {
        this.setState({ userList: [] });
      }
    });
  }
  approveUser(id, status, email, name, mobile) {
    $(".loder_div").attr("hidden", false);
    var statusTrue =
      $('input[name="status' + id + '"]:checked').val() === "on" ? true : false;
    // {
    //   "userId":"41",
    //   "emailId":"sooraj1@yopmail.com",
    //   "approveStatus":true,
    //   "userName":"Sooraj",
    //   "mobileNo":"8606070077"
    // }
    var req = {
      functionName: "approveUser",
      approveStatus: statusTrue,
      userId: id,
      emailId: email,
      userName: name,
      mobileNo: mobile,
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((response) => {
      $(".loder_div").attr("hidden", true);
      if (response.success === "1") {
        $(".loder_div").attr("hidden", true);
        alert("Status Changed");
        this.getUserList();
      }
    });
  }
  changeRole(roleid, userole) {
    if (roleid !== "Select") {
      var req = {
        functionName: "updateRole",
        listUserId: userole,
        roleId: roleid,
      };
      $(".loder_div").attr("hidden", false);
      backendApi(req).then((response) => {
        if (response.success === "1") {
          alert("Role changed successfully");
          $(".loder_div").attr("hidden", true);
          this.getUserList();
        }
      });
    }
  }
  selectAll(checked) {
    var array = [];
    if (checked) {
      this.state.modules.map((obj) => array.push(obj.moduleId));

      // moduleUser[0].modules.forEach((module) => {
      // console.log("")
      // });

      this.setState({
        moduleActive: true,
        moduleId: array,
      });
    } else {
      this.setState({
        moduleActive: false,
        moduleId: [],
      });
    }
  }
  selectAllOrgs(checked) {
    var array = [];
    if (checked) {
      this.state.organizationAllotment.map((obj) => array.push(obj.id));
      this.setState({
        organizationListActive: true,
        organizationListId: array,
      });
    } else {
      this.setState({
        organizationListActive: false,
        organizationListId: [],
      });
    }
  }
  render() {
    return (
      <div className="main_wrapper">
        {this.state.isLogin === "true" &&
        (this.state.userRole === "ROLE_SUPER_ADMIN" ||
          this.state.userRole === "ROLE_ADMIN" ||
          this.state.userRole === "ORG_ADMIN") ? (
          <>
            <LeftMenu></LeftMenu>

            <div className="userListWrapper">
                <Header heading="Heading" headerImage={rolesimage} />
                <div className="bankComparison">
                  <div className="bankCompare">
                    <div className="bankHead">
                      <div className="bank1logo">
                        <img src={bank1Image} alt="bank1Image" className="img-fluid w-100"/>
                        <div className="bank-deatils">
                          <div className="bankName">
                            Axis Bank
                          </div>
                          <div className="bankSubname">
                            Financial Services
                          </div>
                        </div>
                      </div>
                      <div className="bankvslogo">
                        VS
                      </div>
                      <div className="bank2logo">
                        <img src={bank1Image} alt="bank1Image" className="img-fluid w-100"/>
                        <div className="bank-deatils">
                          <div className="bankName">
                            Axis Bank
                          </div>
                          <div className="bankSubname">
                            Financial Services
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bankDataComparison">
                      {/* <div className="bank1data">
                        1
                      </div> */}
                      <div className="bankCommondata">
                      
                       <div className="bankCommonDataNames">
                      1
                        </div>
                       <div className="bankCommonDataNames">Address Match</div>
                       <div className="bankCommonDataNames">2</div>
                      </div>
                      <div className="bankCommondata">
                       <div className="bankCommonDataNames">3</div>
                       <div className="bankCommonDataNames">Business Match</div>
                       <div className="bankCommonDataNames">4</div>
                      </div>
                      <div className="bankCommondata">
                       <div className="bankCommonDataNames">5</div>
                       <div className="bankCommonDataNames">Match Phone No</div>
                       <div className="bankCommonDataNames">6</div>
                      </div>
                      {/* <div className="bank2data">
                       3 
                      </div> */}
                    
                    </div>
                    {/* <div className="row">
                      <div className="col-md-4">
                      <MultiProgress
			elements={[
				{
					value: 35,
					color: "blue",
          showPercentage: true,
          textColor: "white",
				},
			]}
		/>
                      </div>
                      <div className="col-md-4">
                      <div className="bankCommonDataNames">Address Match</div>
                      </div>
                      <div className="col-md-4">
                      <MultiProgress
			elements={[
				{
					value: 35,
					color: "blue",
          showPercentage: true,
          textColor: "white",
				},
			]}
		/>
                      </div>
                    </div> */}
                 <div>
                  
                 </div>
                  </div>
                  <div className="bankCompare">
                 
                  </div>
                </div>
              <Footer />
            </div>
          </>
        ) : (
          ""
        )}
        <div className="loder_div" hidden />
      </div>
    );
  }
}

export default UserList;
