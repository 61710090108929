import React, { useEffect, useState } from 'react'
import Img from "../images/arrow-up.png"
import Sales from "./db-sales-card"
import Zones from "./db-zones-card"
import Graph from "./db-graph"
import Table from "./db-table"
import { apiCallingPost } from "../apiService";
import LeadsGenerated from './leads-generated'
import TopPerformnce from './top-performance'
import TopPerformnceCRE from './top-performance-cre'
import GbpSearches from './gbp-searches'
import SocialMediaEng from './social-meida-engagement'
function Dashboardmain({formattedDates}) {

    const Select = () => {
        document.querySelectorAll('.an-select-drop').forEach(function (div) {
            div.classList.toggle('an-select-toggle');

        });
    };
    const [budgetList, setBudgetList] = useState([]);
    const [graphArrayOne, setGraphArrayOne] = useState([]);
    const [graphArrayTwo, setGraphArrayTwo] = useState([]);
    const [graphArrayThree, setGraphArrayThree] = useState([]);
    const [leadsList, setLeadsList] = useState([]);
    const [topPerformingList, setTopPerformingList] = useState([]);
    const [productValueResult, setProductValueResult] = useState("");
    const [activeButton, setActiveButton] = useState('');
    const [activeButtonState, setActiveButtonState] = useState('');
    const [stateHeading, setStateHeading] = useState('');
    const [stateZoneHeading, setStateZoneHeading] = useState('');

    useEffect(() => {
        getProductTypeValue()
        budgetListing()
        leadsListing()
        graphData()
    }, [formattedDates]);
    const getProductTypeValue = () => {
        const postData = {
            "functionName": "getOrganizationSettings",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
        }
        apiCallingPost(postData).then((data) => {
            console.log("🚀setProductValueResult ~ apiCallingPost ~ data:", data.result.isHighValue)
            if (data.success === "1") {
                setProductValueResult(data.result.isHighValue)
                if (data.result.isHighValue === true) {
                    topPerforming()
                }
            } else {
            }
        });
    }
    const budgetListing = () => {
        const postData = {
            "functionName": "budgetAllocationDashboard",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "fromDate": formattedDates[0],
            "toDate":formattedDates[1],
            "userId": window.sessionStorage.getItem("userId"),
        }
        apiCallingPost(postData).then((data) => {
            console.log("dataaaaaaaaa", data)
            if (data.success === "1") {
                setBudgetList(data.accountBudgetMasterDetails)
            } else {
                setBudgetList([])
            }
        });
    };
    const leadsListing = () => {
        const postData = {
            "functionName": "getleadsDetailsByAccountId",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "userId": window.sessionStorage.getItem("userId"),
            "startDate":formattedDates[0],
            "endDate":formattedDates[1],
        };
        apiCallingPost(postData).then((data) => {
            console.log("leads data", data)
            if (data.success === "1") {
                setLeadsList(data.result)
            } else {
                setLeadsList([])
            }
        });
    };
    const zonalGraphData = (typeId) => {
        setActiveButton(typeId);
        setActiveButtonState("")
        const postData = {
            "functionName": "userDashboard",
            "userId": window.sessionStorage.getItem("userId"),
            "zoneId": "",
            "stateId": "",
            "stateZoneId": "",
            "districtId": "",
            "cityId": "",
            "localityId": "",
            "fromDate": formattedDates[0],
            "toDate":formattedDates[1],
            "typeId": typeId ? typeId : ""
        }
        apiCallingPost(postData).then((data) => {
            console.log("zonalgraph dataaaaaaaaaaaaa", data)
            if (data.success === "1") {
                setGraphArrayOne(data.array1)
            } else {

            }
        });
    };
    const stateGraphData = (typeId) => {
        setActiveButtonState(typeId)
        setActiveButton("");
        const postData = {
            "functionName": "userDashboard",
            "userId":window.sessionStorage.getItem("userId"),
            "zoneId": "",
            "stateId": "",
            "stateZoneId": "",
            "districtId": "",
            "cityId": "",
            "localityId": "",
            "fromDate": formattedDates[0],
            "toDate":formattedDates[1],
            "typeId": typeId ? typeId : ""
        }
        apiCallingPost(postData).then((data) => {
            console.log("graph dataaaaaaaaaaaaa", data)
            if (data.success === "1") {
                setGraphArrayTwo(data.array2)
            } else {

            }
        });
    };
    const graphData = (data, mode, type) => {
        setActiveButton("");
        setActiveButtonState("")
        setStateHeading(type)
        setStateZoneHeading(type)
        const postData = {
            "functionName": "userDashboard",
            "userId": window.sessionStorage.getItem("userId"),
            "zoneId": mode === "zoneId" ? data : "",
            "stateId": "",
            "stateZoneId": "",
            "districtId": "",
            "cityId": "",
            "localityId": "",
            "fromDate": formattedDates[0],
            "toDate":formattedDates[1],
            "typeId": "" 
        }
        apiCallingPost(postData).then((data) => {
            console.log("graph dataaaaaaaaaaaaa", data)
            if (data.success === "1") {
                setGraphArrayOne(data.array1)
                setGraphArrayTwo(data.array2)
                setStateHeading(data.array2[0].masterName)
                setGraphArrayThree(data.array3)
                setStateZoneHeading(data.array3[0].masterName)
            } else {

            }
        });
    };
    const graphDataClick = (data, mode, type) => {
        setActiveButton("");
        setActiveButtonState("")
        setStateHeading(type)
        setStateZoneHeading(type)
        const postData = {
            "functionName": "userDashboard",
            "userId":window.sessionStorage.getItem("userId"),
            "zoneId": mode === "zoneId" ? data : "",
            "stateId": "",
            "stateZoneId": "",
            "districtId": "",
            "cityId": "",
            "localityId": "",
            "fromDate": formattedDates[0],
            "toDate":formattedDates[1],
            "typeId": "" 
        }
        apiCallingPost(postData).then((data) => {
            console.log("graph dataaaaaaaaaaaaa", data)
            if (data.success === "1") {
                setGraphArrayOne(data.array1)
                setGraphArrayTwo(data.array2)
                setGraphArrayThree(data.array3)
            } else {

            }
        });
    };
    const topPerforming = () => {
        const postData = {
            "functionName": "topPerformingProductList",
            "userId":window.sessionStorage.getItem("userId"),
            "startDate":formattedDates[0],
            "endDate":formattedDates[1],
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
        }
        apiCallingPost(postData).then((data) => {
            console.log("top performing", data)
            if (data.success === "1") {
                setTopPerformingList(data.result)
            } else {

            }
        });
    };
    const [activeChild, setActiveChild] = useState(null);
    const handleChildClick = (childId) => {
        setActiveChild(activeChild === childId ? null : childId);
    };

    const [activeChild1, setActiveChild1] = useState(null);
    const handleChildClick1 = (childId) => {
        setActiveChild1(activeChild1 === childId ? null : childId);
    };
    return (
        <div className='db-main pt-3'>
            <div className='container-fluid'>
                <div className="d-lg-flex">
                    <Sales formattedDates={formattedDates}/>
                    <LeadsGenerated/>
                    {budgetList.length > 0 && budgetList.map((data) => (
                        <div className="db-cards-right">
                            <div class="card db-height">
                                <div class="card-body mb-0 p-0 pt-2">
                                    <div className="d-md-flex">
                                        <div className="db-card-width">
                                            <div className='db-cards-top db-cards-bar'>
                                                <h5 class="card-title">{'\u20B9'}{data.overallSpent}{data.ovarAllSpendSymbol && <span className='db-cad-top-units'>({data.ovarAllSpendSymbol})</span>}
                                                </h5>
                                                <div className="progress custom-progress">
                                                    <div className="progress-bar" role="progressbar" aria-valuenow="65"
                                                        aria-valuemin="0" aria-valuemax="100" style={{ width: (data.overallSpent > 0 && data.overallBudget > 0 ? (((parseFloat(data.overallSpent.replace(/[^0-9.]/g, '')) / parseFloat(data.overallBudget.replace(/[^0-9.]/g, ''))) * 100).toFixed(2)):0) }}>
                                                    </div>
                                                    <span className='db-custom-span'>{data.overallSpent > 0 && data.overallBudget > 0 ? (((parseFloat(data.overallSpent.replace(/[^0-9.]/g, '')) / parseFloat(data.overallBudget.replace(/[^0-9.]/g, ''))) * 100).toFixed(2))+'%':0+'%'}</span>
                                                </div>
                                            </div>
                                            <h6 class="card-subtitle mb-3">Budget Spent</h6>
                                        </div>
                                        <div className="db-card-width">
                                            <div className='db-cards-top'>
                                                <h5 class="card-title">{'\u20B9'}{data.overallBudget} {data.ovarAllBudgetSymbol && <span className='db-cad-top-units'>({data.ovarAllBudgetSymbol})</span>}
                                                </h5>
                                                <span className='db-right-btn'>{formattedDates[0] === formattedDates[1] ? formattedDates[0] :formattedDates[0]+"-"+formattedDates[1]}</span>
                                            </div>
                                            <h6 class="card-subtitle mb-3">Overall Budget</h6>
                                        </div>
                                    </div>
                                    <div className="row db-budget-allocation">
                                        <div className="d-md-flex px-2 gap-cards pb-2">
                                            <div class="card bg-transparent border-0 shadow-none cards-width px-0 justify-content-center text-center w-100 pb-2 mb-0 pt-0">
                                                <div className='db-head'>
                                                    Budget Allocation :
                                                </div>
                                            </div>
                                            {budgetList[0].budgetAllocation.map((item) => (
                                                <div class="card bg-white shadow-none me-2 cards-width">
                                                    <div className='db-cards-top an-selectBy' onClick={() => handleChildClick1(item)}>
                                                        <h5 class="db-card-title">{item.name}</h5>
                                                        <span className='db-right-number'>
                                                            {item.budgetAmount}
                                                            {item.budgetSymbol &&
                                                                <span className='db-percentage'>({item.budgetSymbol})</span>}
                                                        </span>
                                                    </div>
                                                    <div className={`an-select-drop ${activeChild1 === item ? 'an-select-toggle' : ''}`}
                                                    >
                                                        <div className='db-cards-top card-drop-bottom' >
                                                            <h5 class="db-card-title">Allocated</h5>
                                                            <span className='db-right-number'>
                                                                {item.budgetAmount}
                                                                {item.budgetSymbol &&
                                                                    <span className='db-percentage'>({item.budgetSymbol})</span>}
                                                            </span>
                                                        </div>
                                                        <div className='db-cards-top card-drop-bottom' >
                                                            <h5 class="db-card-title">Spent</h5>
                                                            <span className='db-right-number'>
                                                                {item.spentAmount}
                                                                {item.spentSymbol &&
                                                                    <span className='db-percentage'>({item.spentSymbol})</span>}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>))}
                </div>
                <div className="d-lg-flex mb-1">
                    {graphArrayOne.length > 0 && (
                        <>
                        <div className="db-cards-40">
                            <div class="card db-height">
                                <div class="card-body">
                                    <div className='db-cards-top mb-2'>
                                        <h5 class="card-title card-title-small">{graphArrayOne[0].masterName} Sales Distribution <span className='db-cad-top-units-blue'>{formattedDates[0] === formattedDates[1] ? formattedDates[0] :formattedDates[0]+"-"+formattedDates[1]}</span>
                                        </h5>
                                        <div class="btn-group">
                                            <span className={`btn btn-link ${activeButton === '' ? 'active' : ''}`} aria-current="page" onClick={() => zonalGraphData("")}>All</span>
                                            <span className={`btn btn-link ${activeButton === '1' ? 'active' : ''}`} onClick={() => zonalGraphData("1")}>Pv</span>
                                            <span className={`btn btn-link ${activeButton === '2' ? 'active' : ''}`} onClick={() => zonalGraphData("2")}>Cv</span>
                                            <span className={`btn btn-link ${activeButton === '3' ? 'active' : ''}`} onClick={() => zonalGraphData("3")}>SCv</span>
                                        </div>
                                    </div>
                                    <h6 class="card-subtitle mb-4">{graphArrayOne[0].masterName}</h6>
                                    {graphArrayOne[0].Array1.map((item) => (
                                        <div className='d-flex mb-4' onClick={() => graphDataClick(item.id, "zoneId", item.name)}>
                                            <div style={{ width: (item.value) + "%" }} className={`progress-focus ${activeChild === 'one' ? 'active' : ''}`} onClick={() => handleChildClick('one')}>
                                                <div class="progress-bar" role="progressbar" style={{ width: "100%", opacity: (item.value / 100) }} aria-valuenow={item.value} aria-valuemin="0" aria-valuemax="100"><span className='db-line-span'>{item.value}%</span></div>
                                            </div>
                                            <div className='db-lines'>
                                                <div className='db-top-lines' >{item.name}</div>
                                            </div>
                                        </div>))}
                                </div>
                            </div>
                        </div>

                        </>
                    )}                   
                        <TopPerformnce/>
                        <TopPerformnceCRE/>
                       
                </div>
                <div className="d-md-flex db-card-wrap mt-4">
                    
                <GbpSearches/>
                <SocialMediaEng/>
                </div>
            </div>
        </div>
    )
}

export default Dashboardmain;