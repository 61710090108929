import React from "react";

function DisplaySecondGoogleData({ secondGoogleData }) {
	return secondGoogleData?.length !== 0 ? (
		<table className="table">
			<thead className="table_theadTop">
				<tr>
					{/* <th className="col-6">Username</th> */}
					<th className="col-8">Question/Answer</th>
					<th className="col-4">Status</th>
				</tr>
			</thead>
			<div className="table_rightOuterscroll" style={{ overflow: "overlay" }}>
				{secondGoogleData.map((data, i) => {
					let { question_answer } = data;
					question_answer = JSON.parse(question_answer);
					if (question_answer.length > 0) {
						return question_answer.map((eachQA) => (
							<tbody className="table_righttbody" key={i + 1}>
								<tr>
									{/* <td className="col-6">{JSON.parse(eachQA.question_answer)}</td> */}
									<td className="col-8">
										<div>
											<strong>Question: </strong>
											{eachQA.question}
										</div>
										<div>
											<p style={{ textAlign: "right" }}>
												<em>Asked by {eachQA.question_asked_by}</em>
											</p>
											<p style={{ textAlign: "right" }}>
												<em>{`(${eachQA.time_asked})`}</em>
											</p>
										</div>
										<div>
											<strong>Answer: </strong>
											{eachQA.answer}
										</div>
										<div>
											<p style={{ textAlign: "right" }}>
												<em>Answered by {eachQA.answered_by}</em>
											</p>
											<p style={{ textAlign: "right" }}>
												<em>{`(${eachQA.answered_time})`}</em>
											</p>
										</div>
									</td>
									<td className="col-4">{data.status}</td>
								</tr>
							</tbody>
						));
					}
				})}
			</div>
		</table>
	) : (
		<></>
	);
}

export default DisplaySecondGoogleData;
