import React, { useEffect, useState } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import QandA from "../images/left_icons/qa.png";
import { Link, useNavigate } from "react-router-dom";
import * as Icon from "react-feather";
import Modal from "react-modal";
import Pagination from "react-js-pagination";
import { backendApi } from "../apiService";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Switch from "react-switch";
import { FiEye } from "react-icons/fi";
import AddCampaignPopup from "../adv_generator/addCampaignPopup";

function Campaign() {
  const navigate = useNavigate();
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [campaignData, setCampaignData] = useState([]);
  const [campaignData1, setCampaignData1] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [siteQuestion, setSiteQuestion] = useState([]);
  const [publicQuestion, setPublicQuestion] = useState([]);
  const [newQuestion, setNewQuestion] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const [editCampaign, setEditCampaign] = useState("");

  const openEditModal = () => {
    setEditModalIsOpen(true);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    const itemsPerPage = 10;
    const indexOfLastPost = pageNumber * itemsPerPage;
    const indexOfFirstPost = indexOfLastPost - itemsPerPage;
    const slicedArrays = campaignData.slice(indexOfFirstPost, indexOfLastPost);
    setCampaignData1(slicedArrays);
  };
  useEffect(() => {
    campaignInit();
    campaignHistory();
  }, []);

  const campaignInit = () => {
    const postData = {
      functionName: "campaign_Listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          console.log("SAdfafsf", data);
          setCampaignData(data.dataSet);
          setCampaignData1(data.dataSet.slice(0, 10));
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };
  const campaignHistory = () => {
    const postData = {
      functionName: "campaign_question_listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          console.log("SAdfafsf", data);
          setPublicQuestion(data.publicQuestion);
          setSiteQuestion(data.siteQuestion);
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };

  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  const closePopup = () => {
    setShowModal(false);
    setEditModalIsOpen(false);
    document.body.className = "";
  };

  const questionSave = () => {
    if (newQuestion === "") {
      alert("Please enter a question");
      return false;
    }
    let question=newQuestion.replace(/['"]/g, "\\$&");
    var postData;
    if (isPublic === true) {
      postData = {
        functionName: "campaign_add_question",
        site_id: "",
        question_text: question,
        is_public: "1",
      };
    } else {
      postData = {
        functionName: "campaign_add_question",
        site_id:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        created_by:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
        question_text: question,
        is_public: "0",
      };
    }
    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          closePopup();
          campaignInit();
          campaignHistory();
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };

  const questionStatus = () => {
    setIsPublic(!isPublic);
  };

  const statusChanging = (data, activeCase) => {
    let postData = {
      functionName: "campaign_question_activeDeactive",
      is_active: activeCase,
      id: data.id,
    };
    backendApi(postData)
      .then((response) => {
        if (response?.success === "1") {
          campaignHistory();
        }
      })
      .catch((error) => {
        console.error("Error fetching version list:", error);
      });
  };

  const statusChangingCampaign = (data, activeCase) => {
    let postData = {
      functionName: "campaign_activeDeactive",
      is_active: activeCase,
      id: data.id,
    };
    backendApi(postData)
      .then((response) => {
        if (response?.success === "1") {
          campaignInit();
        }
      })
      .catch((error) => {
        console.error("Error fetching version list:", error);
      });
  };
  const createRequirement = (data) => {
    navigate("/create-requirement", {
      state: {
        campaignData: data,
      },
    });
  };
  const editCampaignDetails = (campaign) => {
    setEditCampaign(campaign);
    setEditModalIsOpen(true);
  };
  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Campaign" headerImage={QandA} />
        <div className="container-fluid">
          <div className="container">
            <div className="text-right mb-2 btn_popupdiv ml-auto overflow-hidden"></div>
            <Link className="btn-addNew" onClick={openEditModal}>
              Create campaign
              <span>
                <Icon.PlusCircle className="ml-1" />
              </span>
            </Link>
            <br />
            {campaignData?.length > 0 ? (
              <div className="col-md-12 tableResponsive mt-4">
                <div className="tableOuterWidth">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th className="col-1">#</th>
                        <th className="col-2">Campaign Name</th>
                        <th className="col-4">Campaign Description</th>
                        <th className="col-1">Status</th>
                        <th className="col-2">Date</th>
                        <th className="col-2">Action</th>
                      </tr>
                    </thead>
                    {campaignData1?.map((data, i) => (
                      <div className="table_Outerscroll">
                        <tbody className="table_Outertbody">
                          <tr>
                            <td className="col-1">{i + 1}</td>
                            <td className="col-2">
                              <span className="d-flex align-items-center" style={{gap:'10px'}}>
                              <span>
                                {data.campaign_name}{" "}
                              </span>
                              <span className="ml-auto">
                                  <Icon.Edit2 style={{width:'15px',height:'15px'}}
                                    onClick={() => editCampaignDetails(data)}
                                  />
                                </span>
                                </span>
                            </td>
                            <td className="col-4">
                              <span>{data.campaign_summary}</span>
                            </td>
                            <td className="col-1">
                              <Switch
                                className="switch_case"
                                checked={data.is_active === 1}
                                onChange={(checked) => {
                                  statusChangingCampaign(
                                    data,
                                    data.is_active === 1 ? 0 : 1
                                  );
                                }}
                              ></Switch>
                            </td>
                            <td className="col-2">
                              <div className="d-flex align-items-center">
                                {formatDate(data.created_on)}
                              </div>
                            </td>
                            <td className="col-2">
                              <button
                                type="button"
                                class="btn btn-outline-primary "
                                onClick={() => createRequirement(data)}
                              >
                                Create Requirement
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </div>
                    ))}
                  </table>
                </div>
              </div>
            ) : (
              <div className="col-md-12 tableResponsive mt-4">
                <div className="summery-heading mb-2">No Campaigns Added</div>
              </div>
            )}
            {campaignData?.length > 10 && (
              <div className="d-flex align-items-center">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={campaignData.length}
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageChange(e)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="loder_div" hidden />
      {showModal && (
        <div className="adv_modal">
          <div className="adv_modal_inner ">
            <div className="adv_modal_header d-flex align-itesm-center">
              <h3 class="image-heading">Add Questions</h3>
              <button
                type="button"
                className="close ml-auto"
                onClick={() => closePopup()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <Tabs>
              <TabList onClick={() => setEditingIndex(-1)}>
                <Tab>Add Question</Tab>
                <Tab>History</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col-md-8">
                    <div className="form-group mb-2 mb-md-0">
                      <label for="exampleInputEmail1">Add Question</label>
                      <span className="red ml-1">*</span>{" "}
                      <div className="d-md-flex">
                        <input
                          type="text"
                          name="festivalName"
                          id="festivalName"
                          onChange={(e) => setNewQuestion(e.target.value)}
                          className="form-control"
                          value={newQuestion}
                          placeholder="Add Question"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group mb-2 mb-md-0">
                      <label for="exampleInputEmail1">Public</label>
                      <div className="d-md-flex">
                        <Switch
                          className="switch_case"
                          onChange={(e) => questionStatus(e)}
                          value={isPublic}
                          checked={isPublic}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-right">
                  <button
                    type="submit"
                    class="btn btn-success mr-2"
                    onClick={questionSave}
                  >
                    Save
                  </button>
                  <button
                    type="submit"
                    class="btn btn-secondary"
                    onClick={() => closePopup()}
                  >
                    Cancel
                  </button>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="col-md-12 tableResponsive tagImageEditrListTble ">
                  <div className="tableOuterWidth table_width_md_sm">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="col-1">Sl </th>
                          <th className="col-7">Question</th>
                          {/* <th className="col-2 ">Description</th> */}
                          <th className="col-2">Status</th>
                          <th className="col-2">Created Date</th>
                        </tr>
                      </thead>
                      <div className="table_Outerscroll tableheight">
                        <div>Campaign Questions</div>
                        <br />
                        {siteQuestion?.map((data, i) => (
                          <tbody className="table_Outertbody">
                            <tr>
                              <td className="col-1">{i + 1}</td>
                              <td className="col-7">
                                <span>{data.question_text}</span>
                              </td>
                              <td className="col-2">
                                <Switch
                                  className="switch_case"
                                  checked={data.is_active === 1}
                                  onChange={(checked) => {
                                    statusChanging(
                                      data,
                                      data.is_active === 1 ? 0 : 1
                                    );
                                  }}
                                ></Switch>
                              </td>
                              <td className="col-2">
                                <div className="d-flex align-items-center">
                                  {formatDate(data.created_on)}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                        <div>Public Questions</div>
                        <br />
                        {publicQuestion?.map((data, i) => (
                          <tbody className="table_Outertbody">
                            <tr>
                              <td className="col-1">{i + 1}</td>
                              <td className="col-7">
                                <span>{data.question_text}</span>
                              </td>
                              <td className="col-2">
                                <Switch
                                  className="switch_case"
                                  checked={data.is_active === 1}
                                  onChange={(checked) => {
                                    statusChanging(
                                      data,
                                      data.is_active === 1 ? 0 : 1
                                    );
                                  }}
                                ></Switch>
                              </td>
                              <td className="col-2">
                                <div className="d-flex align-items-center">
                                  {formatDate(data.created_on)}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </div>
                    </table>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      )}
      {editModalIsOpen && (
        <AddCampaignPopup
          onClose={closePopup}
          campaignGetData={campaignInit}
          editProps={editCampaign}
        />
      )}
    </div>
  );
}

export default Campaign;
