import React, { Component } from "react";
import * as Icon from "react-feather";
import LeftMenu from "../layout/leftMenu";
import { Link } from "react-router-dom";
import LocalPagesimage from "../images/left_icons/Local-Page.png";
import Header from "../layout/header";
import Footer from "../layout/footer";
import "../css/bootstrap.css";
import "../css/style.css";
import ModuleList from "../admin/module_list";
import $ from "jquery";
import Samle01 from "../images/images1.jpg";
class Index extends Component {
  componentDidMount() {
    $("#headerName").text("Local Pages");
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper">
        <Header heading="Local Pages" headerImage={LocalPagesimage} />
          <div className="user_listdiv local_page noborder_design">
            <div className="localpage_looking">
              <label>Looking for</label>
              <select className="form-control">
                <option>1</option>
                <option>2</option>
              </select>
              <button type="button">
                <Icon.Search />
              </button>
            </div>
            <div className="tagtree_boxdesign">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="local_pageimg">
                        <img src={Samle01} />
                      </div>
                      <div className="local_lagecontnt">
                        <h5>Heading</h5>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </p>
                        <div className="local_largecenter">
                          <h5>Open Now!</h5>
                          <p>7.00 AM to 2.30 PM</p>
                          <button type="button">
                            <Icon.ArrowRight />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="local_pageimg">
                        <img src={Samle01} />
                      </div>
                      <div className="local_lagecontnt">
                        <h5>Heading</h5>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </p>
                        <div className="local_largecenter">
                          <h5>Open Now!</h5>
                          <p>7.00 AM to 2.30 PM</p>
                          <button type="button">
                            <Icon.ArrowRight />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="local_pageimg">
                        <img src={Samle01} />
                      </div>
                      <div className="local_lagecontnt">
                        <h5>Heading</h5>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </p>
                        <div className="local_largecenter">
                          <h5>Open Now!</h5>
                          <p>7.00 AM to 2.30 PM</p>
                          <button type="button">
                            <Icon.ArrowRight />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="local_pageimg">
                        <img src={Samle01} />
                      </div>
                      <div className="local_lagecontnt">
                        <h5>Heading</h5>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </p>
                        <div className="local_largecenter">
                          <h5>Open Now!</h5>
                          <p>7.00 AM to 2.30 PM</p>
                          <button type="button">
                            <Icon.ArrowRight />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="local_pageimg">
                        <img src={Samle01} />
                      </div>
                      <div className="local_lagecontnt">
                        <h5>Heading</h5>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </p>
                        <div className="local_largecenter">
                          <h5>Open Now!</h5>
                          <p>7.00 AM to 2.30 PM</p>
                          <button type="button">
                            <Icon.ArrowRight />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer/>
        </div>
      </div>
    );
  }
}

export default Index;
