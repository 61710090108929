import React, { useState, useEffect, useRef } from 'react'
import { backendDashboardApi } from "../apiService";

const Invoice = ({ selectedStartDate, areaOffice,invoiceStatus,finalisedData, handleIncrementApiCount, receivedAmount, onDataReceived }) => {

  const [invoiceReportList, setInvoiceReportList] = useState([])
  const [subTotalamount, setSubTotalAmount] = useState("")
  const [taxDetails, setTaxDetails] = useState([])
  const [GSTAmount, setGSTAmount] = useState("")
  const [GSTApplicable, setGSTApplicable] = useState("")
  const [totalAmount, setTotalAmount] = useState("")
  const [balanceReceivable, setBalanceReceivable] = useState("")
  const [invoiceNo, setInvoiceNo] = useState("")
  const [approvedDate, setApprovedDate] = useState("")
  const [addressDetails, setAddressDetails] = useState([])

  let formattedStartDate = new Date(selectedStartDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
  });


  let invoiceDate = `${selectedStartDate.getDate()}. ${selectedStartDate.toLocaleString('default', { month: 'short' })}. ${selectedStartDate.getFullYear()}`;

  useEffect(() => {
    if(finalisedData == null && invoiceStatus !== "Finalized"){
      handleInvoiceReportList();
    }else{
      if(finalisedData){
        setInvoiceReportList(finalisedData.descriptionData)
        setSubTotalAmount(finalisedData.subTotal)
        setTaxDetails(finalisedData.taxDetails.taxDetails)
        setGSTAmount(finalisedData.taxDetails.GSTAmount)
        setGSTApplicable(finalisedData.taxDetails.GSTApplicable)
        setTotalAmount(finalisedData.totalAmount)
        setBalanceReceivable(finalisedData.balanceReceivable)
        setInvoiceNo(finalisedData.invoiceNo)
        // setApprovedDate(data.result.approvedDate)
        const timestamp = finalisedData.approvedDate;
        const approvedDate = new Date(timestamp);
        const formattedApprovedDate = `${approvedDate.getDate().toString().padStart(2, '0')}.${(approvedDate.getMonth() + 1).toString().padStart(2, '0')}.${approvedDate.getFullYear()}`;
        setApprovedDate(formattedApprovedDate);
        setAddressDetails(finalisedData.addressDetails)
        handleIncrementApiCount();
      }      
    }

  }, [finalisedData]);

  const handleInvoiceReportList = () => {

    const postData = {

      "functionName": "invoiceDetails",
      "accountId": window.sessionStorage.getItem("switchingOrgId"),
      "userId": window.sessionStorage.getItem("userId"),
      "areaOffice": areaOffice,
      "date": formattedStartDate,
    }
    backendDashboardApi(postData).then((data) => {
      console.log("🚀 ~ backendDashboardApi ~ postData: handleInvoiceReportList", data.result)
      if (data.success === "1") {
        onDataReceived(data.result);
        setInvoiceReportList(data.result.descriptionData)
        setSubTotalAmount(data.result.subTotal)
        setTaxDetails(data.result.taxDetails.taxDetails)
        setGSTAmount(data.result.taxDetails.GSTAmount)
        setGSTApplicable(data.result.taxDetails.GSTApplicable)
        setTotalAmount(data.result.totalAmount)
        setBalanceReceivable(data.result.balanceReceivable)
        setInvoiceNo(data.result.invoiceNo)
        // setApprovedDate(data.result.approvedDate)
        const timestamp = data.result.approvedDate;
        const approvedDate = new Date(timestamp);
        const formattedApprovedDate = `${approvedDate.getDate().toString().padStart(2, '0')}.${(approvedDate.getMonth() + 1).toString().padStart(2, '0')}.${approvedDate.getFullYear()}`;
        setApprovedDate(formattedApprovedDate);
        setAddressDetails(data.result.addressDetails)
        handleIncrementApiCount();
      } else {
        setInvoiceReportList([])
      }
    });
  }

  const [container] = useState({ width: "711px", margin: "0px auto", padding: "5px" });
  const [page] = useState({ width: "711px", padding: 0, margin: "0 auto", background: "white" });
  const [logo] = useState({ float: "left", padding: "20px 0px 5px 0px", width: "200px" });
  const [logoImg] = useState({ width: "100%" });
  const [colContent] = useState({ fontSize: "12px", margin: "2px 0px 0px" });
  const [colContentDue] = useState({ fontSize: "12px", margin: "2px 0px 15px" });
  const [col1] = useState({ width: "237px", float: "left" });
  const [marginBottom] = useState({ fontSize: "12px", fontWeight: "bolder", margin: "8px 0 2px" });
  const [tableStr] = useState({ width: "711px", float: "left", display: "flex", justifyContent: "space-between", fontSize: "12px", fontWeight: "600", marginTop: "15px", borderBottom: "2px solid #242424" });
  const [tableHeadMain] = useState({ marginBottom: "0px", fontSize: "12px", fontWeight: "bolder" });
  const [tableHead] = useState({ marginBottom: "0px", fontSize: "12px" });
  const [tableTd] = useState({ width: "711px", float: "left", display: "flex", justifyContent: "space-between", fontSize: "12px", borderBottom: "1px solid #ccc", padding: "8px 0px" });
  const [subTotal] = useState({ width: "360px", float: "right" });
  const [tableTdSub] = useState({ display: "flex", justifyContent: "space-between", fontSize: "12px", padding: "4px 0px", borderBottom: "2px solid #242424" });
  const [tableTdSubCon] = useState({ display: "flex", justifyContent: "space-between", fontSize: "12px", padding: "4px 0px" });
  const [totalAmnt] = useState({ padding: "20px 0 8px" });
  const [tableAlignmnt] = useState({ width: "350px", textAlign: "right", marginBottom: "5px" });
  const [infos] = useState({ width: "711px", float: "left", marginTop: "30px" });
  const [marginP] = useState({ margin: "30px  0  20px", fontSize: "12px" });

  return (
    <div id="container" class="container" style={container}>
      <div class="page" style={page}>
        <div class="subpage" style={{ clear: "both" }}>
          <div class="order-detail-header" style={{ width: "100%", float: "left", marginBottom: "8px", display: "flex", justifyContent: "end" }}>
            <div style={logo}>
              <img src="https://www.convergensee.com/wp-content/themes/woodmart-child/new-convergee/images/logo-new.png" alt="" style={logoImg} />
            </div>
          </div>
          <div class="address">
            <span>
              <h4 style={{ fontSize: "16px", fontWeight: "600" }}>TAX INVOICE</h4>
            </span>
          </div>

          {addressDetails.map((item) => (
            <div style={col1}>
              <p style={colContent} className='InvFontSize'>To,</p>
              <p style={colContent} className='InvFontSize'><h4 style={marginBottom} className='InvFontSize' > {item.organisation_name}</h4></p>
              <p style={colContent} className='InvFontSize'>{item.address1}<br />
                {item.address2}, {item.address3} <br />
                {item.locality}, {item.administrative_area} <br />
                India - {item.pin_code}</p>
              <p style={colContent} className='InvFontSize'><h4 style={marginBottom} className='InvFontSize'>GSTIN : </h4>{item.gstNo}</p>
              <p style={colContent} className='InvFontSize'>Mail Approval dated {approvedDate}</p>
            </div>
          ))}



          <div style={col1}>
            <p style={colContent}>&nbsp;</p>
            <p style={colContent} className='InvFontSize'><h4 style={marginBottom} className='InvFontSize'> Invoice Date</h4></p>
            <p style={colContent} className='InvFontSize'>{invoiceDate}</p>
            <p style={colContent} className='InvFontSize'><h4 style={marginBottom} className='InvFontSize'>Invoice Number </h4></p>
            <p style={colContent} className='InvFontSize'>{invoiceNo}</p>
            <p style={colContent} className='InvFontSize'><h4 style={marginBottom} className='InvFontSize'>GSTIN </h4></p>
            <p style={colContent} className='InvFontSize'>27AAICC8260D1ZO</p>
            <p style={colContent} className='InvFontSize'><h4 style={marginBottom} className='InvFontSize'>Vendor Code </h4></p>
            <p style={colContent} className='InvFontSize'>CIMPL1ZO</p>
          </div>
          <div style={col1}>
            <p style={colContent}>&nbsp;</p>
            <p style={colContent} className='InvFontSize'>Hiranandani Business Park
              Hiranandani LightHall <br />
              A-507, Level 5<br />
              Saki Vihar Road, Chandivali<br />
              Mumbai , Maharashtra - 400072<br /></p>
            <p style={colContent} className='InvFontSize'><h4 style={marginBottom} className='InvFontSize'> MSME - Udyam Reg No </h4></p>
            <p style={colContent} className='InvFontSize'> UDYAM-MH-19-0117979</p>
          </div>



          <div className="table">
            <div style={tableStr} className='InvFontSize'>
              <p style={tableHeadMain} className='InvFontSize'>Description</p>
              <p style={tableHeadMain} className='InvFontSize'>Amount (in INR)</p>
            </div>

            {invoiceReportList.map((item) => (
              <>
                <div style={tableTd} className='InvFontSize'>
                  <p style={tableHead} className='InvFontSize'>{item.name} Segment - {areaOffice} AO</p>
                  <p style={tableHead} className='InvFontSize'></p>
                </div>
                {item.descriptionDetails.map((data) => (
                  <div style={tableTd} className='InvFontSize'>
                    <p style={tableHead} className='InvFontSize'>{data.descriptionName}</p>
                    <p style={tableHead} className='InvFontSize'>{data.value.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                  </div>
                ))}
              </>))}


            <div className="subTotal InvFontSize" style={subTotal}>
              <div style={tableTdSub} className='InvFontSize'>
                <p style={tableAlignmnt} className='InvFontSize'>Subtotal</p>
                <p style={tableAlignmnt} className='InvFontSize'> {subTotalamount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
              </div>


              <div style={tableTdSubCon}>
                <p style={tableAlignmnt} className='InvFontSize'>GST Applicable</p>
                <p style={tableAlignmnt} className='InvFontSize'> {GSTApplicable}</p>
              </div>
              {taxDetails.map((item) => (
                <div style={tableTdSubCon}>
                  <p style={tableAlignmnt} className='InvFontSize'>{item.taxName}</p>
                  <p style={tableAlignmnt} className='InvFontSize'>{item.taxValue.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                </div>
              ))}
              <div style={tableTdSub} className='InvFontSize'>
                <p style={tableAlignmnt} className='InvFontSize'>GST Amount</p>
                <p style={tableAlignmnt} className='InvFontSize'>{GSTAmount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
              </div>



              <div className="totalAmnt" style={totalAmnt}>
                <div style={tableTdSub} className='InvFontSize'>
                  <p style={tableAlignmnt} className='InvFontSize'><b>TOTAL</b></p>
                  <p style={tableAlignmnt} className='InvFontSize'> <b>{totalAmount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</b></p>
                </div>
                {receivedAmount ? 
                <div style={tableTdSub} className='InvFontSize'>
                  <p style={tableAlignmnt} className='InvFontSize'>Amount Received</p>
                  <p style={tableAlignmnt} className='InvFontSize'> <b>{parseFloat(receivedAmount).toLocaleString('en-IN', { style: 'currency', currency: 'INR' }) }</b></p>
              
                </div>
                :""}
              </div>
              <div style={tableTdSubCon} className='InvFontSize'>
                <p style={tableAlignmnt} className='InvFontSize'>Balance Receivable</p>
                <p style={tableAlignmnt} className='InvFontSize'><b>{balanceReceivable.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</b></p>
              </div>
            </div>

          </div>
          <div className="DueDateInfo" style={infos}>
            <p style={colContentDue} className='InvFontSize'>  <b>Due Date: 21. Feb. 2023</b></p>
            <p style={colContent} className='InvFontSize'>Online Transfers to be routed to:</p>
            <p style={colContent} className='InvFontSize'>Account Name : ConvergenSEE India MarTech Private Limited</p>
            <p style={colContent} className='InvFontSize'>Bank Name : Kotak Bank Limited, Hiranandani Branch, Powai, Mumbai - 400076</p>
            <p style={colContent} className='InvFontSize'>Account Number : 3114209246</p>
            <p style={colContent} className='InvFontSize'>IFSC : KKBK0000663</p>
            <p style={colContent} className='InvFontSize'>PAN : AAICC8260D</p>
            <p style={marginP} className='InvFontSize'>Make all cheques payable to: ConvergenSEE India MarTech Private Limited</p>
            <br />
          </div>

          <div className="signature" style={infos}>
            <p style={colContent} className='InvFontSize'><b>FOR ConvergenSEE India MarTech Private Limited</b></p>
            <br />
            <p>Signature</p>
            <p style={colContent} className='InvFontSize'><b>Authorised Signatory</b></p>
            <br />
            <br />
          </div>
          <br />
        </div>
      </div>
    </div>
  );
};

export default Invoice;