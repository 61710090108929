import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import GooglePostimage from "../images/left_icons/Google-Post.png";
import * as Icon from "react-feather";
import $, { data } from "jquery";
import XillImg from "../images/xill_sm.png";
import GooglePreviewImg from "../images/google_preview.PNG";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import moment from "moment";
import ScaleLoader from "react-spinners/ScaleLoader";
import { apiCallingPost } from "../apiService";
var globalLocationId = 0;
function GooglePreviewForCopy() {
  const location = useLocation();
  const [completeLocationsData, setCompleteLocationData] = useState([]);
  const [selectedLocationsArray, setSelectedLocationsArray] = useState([]);
  const [copyData, setCopyData] = useState(location.state.copyField);
  const [createPostButton, setCreatePostButton] = useState(false);
  const [
    selectedLocationsCompleteDataArray,
    setSelectedLocationsCompleteDataArray,
  ] = useState([]);
  const [allLocationsData, setAllLocationData] = useState([]);
  useEffect(() => {
    setCompleteLocationData(location.state.completeLocationData);
    console.log(
      "selectedLocationsselectedLocationsselectedLocations",
      location
    );
    setAllLocationData(
      location.state.allLocationsData.filter((obj) => {
        return obj.title !== location.state.completeLocationData[0].title;
      })
    );
  }, []);
  const postEvent = (topic) => {
    let imagesList = [];
    if (copyData.media) {
      copyData.media.forEach((obj, i) => {
        imagesList.push({
          mediaFormat: "PHOTO",
          sourceUrl: obj.googleUrl,
        });
      });
    }

    var totalCount = selectedLocationsArray.length;
    selectedLocationsArray.forEach(async (obj, i) => {
      var req = {
        title: copyData.event
          ? copyData.event.title
          : copyData.summary.slice(0, copyData.summary.indexOf(" ")),
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        ...(copyData.summary && {
          summary: copyData.summary,
        }),
        startDate_year: copyData.event
          ? copyData.event.schedule.startDate.year
          : moment(new Date()).get("year"),
        startDate_month: copyData.event
          ? copyData.event.schedule.startDate.month
          : moment(new Date()).get("month") + 1,
        startDate_day: copyData.event
          ? copyData.event.schedule.startDate.day
          : moment(new Date()).get("date"),
        ...(copyData.event &&
          Object.keys(copyData.event.schedule.startTime).length !== 0 && {
            startTime_hours: copyData.event
              ? copyData.event.schedule.startTime.hours
              : "00",
            startTime_minutes: copyData.event
              ? copyData.event.schedule.startTime.minutes
              : "00",
          }),
        endDate_year: copyData.event
          ? copyData.event.schedule.endDate.year
          : moment(new Date()).get("year"),
        endDate_month: copyData.event
          ? copyData.event.schedule.endDate.month
          : moment(new Date()).get("month") + 1,
        endDate_day: copyData.event
          ? copyData.event.schedule.endDate.day
          : moment(new Date()).get("date"),
        ...(copyData.event &&
          Object.keys(copyData.event.schedule.endTime).length !== 0 && {
            endTime_hours: copyData.event
              ? copyData.event.schedule.endTime.hours
              : "23",
            endTime_minutes: copyData.event
              ? copyData.event.schedule.endTime.minutes
              : "59",
          }),

        ...(copyData.callToAction && {
          callToAction: {
            actionType: copyData.callToAction.actionType,
            url: copyData.callToAction.url,
          },
        }),
        ...(copyData.offer && {
          offer: copyData.offer,
        }),
        bannerImages: imagesList[0],
        eventType: copyData.topicType,
        location_id: obj,
        functionName: topic,
      };
      console.log("request", req);
      $("#PendingPosting" + obj).attr("hidden", true);
      $("#scaleLoader" + obj).removeAttr("hidden");
      await apiCallingPost(req).then((data) => {
        console.log(totalCount);
        totalCount = totalCount - 1;
        if (data.success === "1") {
          // alert("Posted Successfully!!!");
          $("#SuccessPosting" + obj).removeAttr("hidden");
          $("#scaleLoader" + obj).attr("hidden", true);
          $("#FailedPosting" + obj).attr("hidden", true);
          $(".loder_div").attr("hidden", true);
          if (totalCount === 0) {
            window.location.href = "/googlepost/postListing";
          }
        } else {
          $("#PendingPosting" + obj).attr("hidden", true);
          $("#SuccessPosting" + obj).attr("hidden", true);
          $("#scaleLoader" + obj).attr("hidden", true);
          $("#FailedPosting" + obj).removeAttr("hidden");
          // data.errorMessage
          //   ? alert(data.errorMessage)
          //   : alert("Something went wrong");
        }
        // if (i == location.state.selectedLocations.length) {
        //   window.location.href = "/googlepost/postListing";
        // }
      });
    });
  };
  const selectLocation = (e, data, id) => {
    if (
      e.target.checked === true &&
      !selectedLocationsArray.includes(data.location_id)
    ) {
      setSelectedLocationsCompleteDataArray((object) => [...object, data]);
      setSelectedLocationsArray((object) => [...object, data.location_id]);
      if (selectedLocationsArray.length + 1 === allLocationsData.length) {
        $("#selectAllLocations").prop("checked", true);
      }
      setCreatePostButton(true);
    } else if (e.target.checked === false) {
      let index = selectedLocationsArray.indexOf(data.location_id);
      selectedLocationsArray.splice(index, 1);
      selectedLocationsCompleteDataArray.splice(index, 1);
      if (selectedLocationsArray.length !== allLocationsData.length) {
        $("#selectAllLocations").prop("checked", false);
      }
      if (selectedLocationsArray.length === 0) {
        setCreatePostButton(false);
      } else {
        setCreatePostButton(true);
      }
    }
  };
  const selectAllLocations = (e) => {
    globalLocationId = allLocationsData.length;
    if (e.target.checked === true) {
      for (let i = 0; i < globalLocationId; i++) {
        $("#selectLocationPost" + i).prop("checked", true);
      }
      if (selectedLocationsArray.length > 0) {
        let filteredArray = allLocationsData.filter(
          (value) => !selectedLocationsArray.includes(value.location_id)
        );
        filteredArray.map((obj) => {
          selectedLocationsArray.push(obj.location_id);
          selectedLocationsCompleteDataArray.push(obj);
        });
      } else {
        allLocationsData.map((obj) => {
          selectedLocationsArray.push(obj.location_id);
          selectedLocationsCompleteDataArray.push(obj);
        });
      }
      if (selectedLocationsArray.length === 0) {
        setCreatePostButton(false);
      } else {
        setCreatePostButton(true);
      }
    } else {
      for (let i = 0; i < globalLocationId; i++) {
        $("#selectLocationPost" + i).prop("checked", false);
      }
      setSelectedLocationsArray([]);
      setSelectedLocationsCompleteDataArray([]);
      setCreatePostButton(false);
    }
    console.log(selectedLocationsArray, "gooooooooooooooooooo");
    var selectAllList = [];
    // let includes = locationsList.some(item => selectedLocationsArray.includes(item.name.split("/")[1]))
    // console.log("foundfound", filteredArray);
  };
  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Preview Post" headerImage={GooglePostimage} />
        <div className="container mt-3">
          <div className="row google_review_row">
            <div className="left_review_row">
              <div className="left_googlePreview">
                <div className="leftside_shieldcontt">
                  {copyData.media ? (
                    <img src={copyData.media[0].googleUrl} />
                  ) : (
                    <img src={GooglePreviewImg} />
                  )}
                  {/* <img src={GooglePreviewImg} /> */}
                </div>
                <div className="leftside_shidfoot">
                  <h3 className="subhead">
                    {copyData.event && copyData.event.title}
                  </h3>
                  {/* <p>
                    Happy International Men's Day to all our dear important men
                    of Xilligence.
                  </p> */}
                </div>
                <div className="tabs-wrapper">
                  <Tabs>
                    <TabList>
                      <Tab>{copyData.topicType}</Tab>
                    </TabList>

                    <TabPanel>
                      <p className="mb-0">{copyData.summary}</p>
                      {copyData.offer && (
                        <>
                          {copyData.offer.couponCode && (
                            <p className="mb-0">{copyData.offer.couponCode}</p>
                          )}
                          {copyData.offer.redeemOnlineUrl && (
                            <p className="mb-0">
                              {copyData.offer.redeemOnlineUrl}
                            </p>
                          )}
                          {copyData.offer.termsConditions && (
                            <p className="mb-0">
                              {copyData.offer.termsConditions}
                            </p>
                          )}
                        </>
                      )}
                      {/* <p className="mb-0">Maharashtra 400706</p>
                      <p className="mb-0">Building, Girgaon, Mumbai</p> */}
                    </TabPanel>
                  </Tabs>
                </div>
                <div className="preview_timer">
                  {copyData.event && (
                    <>
                      <div className="preview_innertimer">
                        <Icon.Clock className="timmer_icon" />
                        <p>
                          <span className="ml-2 timer_openclose">
                            {moment(
                              new Date(
                                copyData.event.schedule.startDate.year,
                                copyData.event.schedule.startDate.month,
                                copyData.event.schedule.startDate.day
                              )
                            ).format("YYYY-MMM-DD")}
                            ,{" "}
                            {Object.keys(copyData.event.schedule.startTime)
                              .length !== 0
                              ? copyData.event.schedule.startTime.hours +
                                ":" +
                                copyData.event.schedule.startTime.minutes
                              : "00:00"}
                          </span>{" "}
                        </p>
                      </div>

                      <div className="preview_innertimer">
                        <Icon.Clock className="timmer_icon" />
                        <p>
                          <span className="ml-2 timer_openclose">
                            {moment(
                              new Date(
                                copyData.event.schedule.endDate.year,
                                copyData.event.schedule.endDate.month,
                                copyData.event.schedule.endDate.day
                              )
                            ).format("YYYY-MMM-DD")}
                            ,
                            {Object.keys(copyData.event.schedule.endTime)
                              .length !== 0
                              ? copyData.event.schedule.endTime.hours +
                                ":" +
                                copyData.event.schedule.endTime.minutes
                              : "00:00"}
                          </span>{" "}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-10 tableResponsive ">
              {/* row right_review_row */}
              {/* <div className="table_leftOuterWidth">
                <table className="table">
                  <div className="table_Outerscroll">
                    {completeLocationsData.map((data, i) => (
                      <tbody className="stickyDiv table_Outertbody">
                        <tr>
                          <td className="col-3">{data.name.split("/")[1]}</td>
                          <td className="col-3">
                            <p className="mb-0">
                              <b>{data.title}</b>
                            </p>
                            <p className="mb-0">
                              {data.storefrontAddress.addressLines.join()},{" "}
                              {data.storefrontAddress.locality},{" "}
                              {data.storefrontAddress.administrativeArea},{" "}
                              {data.storefrontAddress.postalCode}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </div>
                </table>
              </div> */}
              <div className="table_leftOuterWidth">
                {/* <div className="tableOuterWidth"> */}
                <table className="table">
                  <thead className="table_theadTop">
                    <tr>
                      <th className="col-1">
                        <input
                          type="checkbox"
                          name=""
                          id="selectAllLocations"
                          onClick={(e) => selectAllLocations(e)}
                        />
                      </th>
                      <th className="col-3">Title</th>
                      <th className="col-3">Address</th>
                      <th className="col-3">Action</th>
                    </tr>
                  </thead>
                  <div className="table_Outerscroll">
                    {allLocationsData.map((data, i) => (
                      <tbody className="stickyDiv table_Outertbody">
                        <tr>
                          <td className="col-1">
                            <input
                              id={"selectLocationPost" + i}
                              type="checkbox"
                              name=""
                              onClick={(e) => selectLocation(e, data, i)}
                            />
                          </td>
                          <td className="col-3">{data.title}</td>
                          <td className="col-3">
                            <p className="mb-0">
                              <b>{data.title}</b>
                            </p>
                            <p className="mb-0">
                              {data.addressLines}, {data.locality},{" "}
                              {data.administrativeArea}, {data.postalCode}
                            </p>
                          </td>
                          <td className="col-3">
                            <div className="d-flex align-items-center">
                              <ScaleLoader
                                id={"scaleLoader" + data.location_id}
                                hidden
                                color="#36d7b7"
                                height={20}
                                width={2}
                              />
                              <span id={"PendingPosting" + data.location_id}>
                                <Icon.CheckCircle className="pending_svg" />
                                <span className="text-nowrap">
                                  Ready for Post
                                </span>
                              </span>
                              <span
                                hidden
                                id={"SuccessPosting" + data.location_id}
                              >
                                <Icon.CheckCircle className="pending_svg" />
                                <span className="text-nowrap">
                                  Posted Successfully
                                </span>
                              </span>
                              <span
                                hidden
                                id={"FailedPosting" + data.location_id}
                              >
                                <Icon.CheckCircle className="pending_svg" />
                                <span className="text-nowrap">
                                  Failed to Post
                                </span>
                              </span>
                            </div>
                            {/* <div
                              hidden
                              id={"SuccessfullPosted" + i}
                              className="d-flex align-items-center"
                            >
                              <Icon.CheckCircle className="pending_svg" />
                              <span className="text-nowrap">
                                Posted Successfully
                              </span>
                            </div> */}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </div>
                </table>
              </div>
              <div className="button-wrapper">
                <button
                  class="btn-cancel"
                  onClick={() => window.history.back()}
                >
                  Cancel
                </button>
                {createPostButton === true ? (
                  <button
                    class="btn-submit"
                    onClick={() => {
                      postEvent("PostEvent");
                    }}
                  >
                    Post
                  </button>
                ) : (
                  <button class="btn btn-secondary" disabled>
                    Post
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
}

export default GooglePreviewForCopy;
