import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as Icon from "react-feather";
import dataProcessImg from "../images/dataProcess.gif";
import Accessibiltyimage from "../images/left_icons/Accessibility-and-visibility.png";
import { Link } from "react-router-dom";
import { backendApi, pythonBackendApi, apiCallingPost } from "../apiService";
import Header from "../layout/header";
import moment from "moment";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu";
import $ from "jquery";
import ReviewAnalyticsGraph from "./reviewAnalyticsGraph";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";

const ReviewAnalyticsTable = () => {
  let d = new Date();
  const location = useLocation();
  const [VisibilityAccuracy, setVisibilityAccuracy] = useState([]);
  const [getLocationTitle, setGetLocationTitle] = useState([]);
  const [globalFilterLocationsList, setGlobalFilterLocationsList] = useState(
    []
  );
  const [tempSearchLocationsList, setTempSearchLocationsList] = useState([]);
  const [getCityList, setGetCityList] = useState([]);
  const [allLocationdata, setAllLocationdata] = useState([]);
  const [positiveArray, setPositiveArray] = useState([]);
  const [negativeArray, setNegativeArray] = useState([]);
  const [mixedArray, setMixedArray] = useState([]);
  const [neutralArray, setNeutralArray] = useState([]);
  const [datesArray, setDatesArray] = useState([]);
  const [completeDataArray, setCompleteDataArray] = useState([]);
  const [statusOnlyArray, setStatusOnlyArray] = useState([]);
  const [businessNameFilter, setBusinessNameFilter] = useState("");
  const [LocalityList, setLocalityList] = useState("");
  const [clearFlag, setClearFlag] = useState(0);
  const [getTitleName, setGetTitleName] = useState("");
  const [AdministrativeArea, setAdministrativeArea] = useState("");
  const [starReview, setStarReview] = useState("");
  const [startDate, setStartDate] = useState(d.setDate(d.getDate() - 30));
  const [endDate, setEndDate] = useState(new Date());
  //   const [visibility, setvisibility] = useState([]);
  //   const [accuracy, setaccuracy] = useState([]);
  useEffect(() => {
    // getVisibilityAccuracy();
    getAdministrativeArea();
    getLocationSearch();
    reviewAnalysisGraph();
  }, [clearFlag]);
  const reviewAnalysisGraph = () => {
    $(".loder_div").attr("hidden", false);
    const reviewAnalysisGraphAPI = {
      functionName: "reviewAnalysisGraph",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      location_id: getTitleName,
      fromDate: moment(endDate).format("YYYY-MM-DD"),
      toDate: moment(startDate).format("YYYY-MM-DD"),
      starValue: "",
      sentiment: "",
      administrativeArea: AdministrativeArea,
      locality: LocalityList,
    };
    apiCallingPost(reviewAnalysisGraphAPI).then((data) => {
      if (data.success == "1") {
        let positiveArray = [];
        let negativeArray = [];
        let mixedArray = [];
        let neutralArray = [];
        let datesArray = [];
        let completeArray = [];
        let apiData = data.data;
        let sortedArray = [];
        // data.data.forEach((response, i) => {
        //   let object = "";
        //   if (i - 1 != "-1") {
        //     console.log(
        //       "response.datetimeval",
        //       new Date(response.datetimeval).getTime()
        //     );
        //     console.log(
        //       "data.data",
        //       new Date(data.data[i - 1].datetimeval).getTime()
        //     );
        //     object = {
        //       date: response.datetimeval,
        //     };
        //     if (
        //       new Date(data.data[i - 1].datetimeval).getTime() ==
        //       new Date(response.datetimeval).getTime()
        //     ) {
        //       object = {
        //         date: response.datetimeval,
        //         ...(response.STATUS == "Positive" ||
        //           (response.STATUS == "POSITIVE" && {
        //             positive: response.COUNT,
        //           })),
        //         ...(response.STATUS == "Negative" ||
        //           (response.STATUS == "NEGATIVE" && {
        //             nagative: response.COUNT,
        //           })),
        //         ...(response.STATUS == "MIXED" ||
        //           (response.STATUS == "Mixed" && {
        //             mixed: response.COUNT,
        //           })),
        //         ...(response.STATUS == "Neutral" ||
        //           (response.STATUS == "NEUTRAL" && {
        //             neutral: response.COUNT,
        //           })),
        //       };
        //       completeArray[i] = object;
        //     } else {
        //       object = {
        //         date: response.datetimeval,
        //         ...(response.STATUS == "Positive" ||
        //           (response.STATUS == "POSITIVE" && {
        //             positive: response.COUNT,
        //           })),
        //         ...(response.STATUS == "Negative" ||
        //           (response.STATUS == "NEGATIVE" && {
        //             nagative: response.COUNT,
        //           })),
        //         ...(response.STATUS == "MIXED" ||
        //           (response.STATUS == "Mixed" && {
        //             mixed: response.COUNT,
        //           })),
        //         ...(response.STATUS == "Neutral" ||
        //           (response.STATUS == "NEUTRAL" && {
        //             neutral: response.COUNT,
        //           })),
        //       };
        //       completeArray.push(object);
        //     }
        //   } else {
        //     let object = {
        //       date: response.datetimeval,
        //       ...(response.STATUS == "Positive" ||
        //         (response.STATUS == "POSITIVE" && {
        //           positive: response.COUNT,
        //         })),
        //       ...(response.STATUS == "Negative" ||
        //         (response.STATUS == "NEGATIVE" && {
        //           nagative: response.COUNT,
        //         })),
        //       ...(response.STATUS == "MIXED" ||
        //         (response.STATUS == "Mixed" && {
        //           mixed: response.COUNT,
        //         })),
        //       ...(response.STATUS == "Neutral" ||
        //         (response.STATUS == "NEUTRAL" && {
        //           neutral: response.COUNT,
        //         })),
        //     };
        //     completeArray.push(object);
        //   }
        // });
        for (let i = 0; i < apiData.length; i++) {
         // debugger;
          let object = "";
          if (i - 1 != -1) {
            // debugger;
            object = {
              date: apiData[i].datetimeval,
            };
            if (
              new Date(apiData[i - 1].datetimeval).getTime() ==
              new Date(apiData[i].datetimeval).getTime()
            ) {
              let object = completeArray[completeArray.length - 1];
              console.log("hiiii", object);
              if (object) {
                if (
                  apiData[i].STATUS == "Positive" ||
                  apiData[i].STATUS == "POSITIVE"
                ) {
                  object.positive = apiData[i].COUNT;
                } else if (
                  apiData[i].STATUS == "Negative" ||
                  apiData[i].STATUS == "NEGATIVE"
                ) {
                  object.negative = apiData[i].COUNT;
                } else if (
                  apiData[i].STATUS == "NEUTRAL" ||
                  apiData[i].STATUS == "Neutral"
                ) {
                  object.neutral = apiData[i].COUNT;
                } else if (
                  apiData[i].STATUS == "Mixed" ||
                  apiData[i].STATUS == "MIXED"
                ) {
                  object.mixed = apiData[i].COUNT;
                }
              }
            } else {
              object = {
                date: apiData[i].datetimeval,
                ...((apiData[i].STATUS == "Positive" ||
                  apiData[i].STATUS == "POSITIVE") && {
                  positive: apiData[i].COUNT,
                }),
                ...((apiData[i].STATUS == "Negative" ||
                  apiData[i].STATUS == "NEGATIVE") && {
                  negative: apiData[i].COUNT,
                }),
                ...((apiData[i].STATUS == "MIXED" ||
                  apiData[i].STATUS == "Mixed") && {
                  mixed: apiData[i].COUNT,
                }),
                ...((apiData[i].STATUS == "Neutral" ||
                  apiData[i].STATUS == "NEUTRAL") && {
                  neutral: apiData[i].COUNT,
                }),
              };
              completeArray.push(object);
            }
          } else {
            let object = {
              date: apiData[i].datetimeval,
            };
            if (
              apiData[i].STATUS == "Positive" ||
              apiData[i].STATUS == "POSITIVE"
            ) {
              object.positive = apiData[i].COUNT;
            } else if (
              apiData[i].STATUS == "Negative" ||
              apiData[i].STATUS == "NEGATIVE"
            ) {
              object.negative = apiData[i].COUNT;
            } else if (
              apiData[i].STATUS == "NEUTRAL" ||
              apiData[i].STATUS == "Neutral"
            ) {
              object.neutral = apiData[i].COUNT;
            } else if (
              apiData[i].STATUS == "Mixed" ||
              apiData[i].STATUS == "MIXED"
            ) {
              object.mixed = apiData[i].COUNT;
            }
            // let object = {
            //   date: apiData[i].datetimeval,
            //   ...(apiData[i].STATUS == "Positive" ||
            //     (apiData[i].STATUS == "POSITIVE" && {
            //       positive: apiData[i].COUNT,
            //     })),
            //   ...(apiData[i].STATUS == "Negative" ||
            //     (apiData[i].STATUS == "NEGATIVE" && {
            //       nagative: apiData[i].COUNT,
            //     })),
            //   ...(apiData[i].STATUS == "MIXED" ||
            //     (apiData[i].STATUS == "Mixed" && {
            //       mixed: apiData[i].COUNT,
            //     })),
            //   ...(apiData[i].STATUS == "Neutral" ||
            //     (apiData[i].STATUS == "NEUTRAL" && {
            //       neutral: apiData[i].COUNT,
            //     })),
            // };
            completeArray.push(object);
          }
        }
        console.log("completeArraycompleteArray", completeArray);
        for (let i = 0; i < completeArray.length; i++) {
          let numArr = [
            {
              name: "positive",
              value: completeArray[i]?.positive
                ? completeArray[i]?.positive
                : 0,
            },
            {
              name: "negative",
              value: completeArray[i]?.negative
                ? completeArray[i]?.negative
                : 0,
            },
            {
              name: "mixed",
              value: completeArray[i]?.mixed ? completeArray[i]?.mixed : 0,
            },
            {
              name: "neutral",
              value: completeArray[i]?.neutral ? completeArray[i]?.neutral : 0,
            },
          ];
          numArr.sort(function (a, b) {
            return b.value - a.value;
          });
          if (numArr[0].value == numArr[1].value) {
            sortedArray.push({
              data: completeArray[i].date,
              status: "mixed",
            });
          } else {
            sortedArray.push({
              data: completeArray[i].date,
              status: numArr[0].name,
            });
          }
          console.log("numArrnumArr", numArr);

          // let positive = completeArray[i]?.positive
          //   ? completeArray[i]?.positive
          //   : 0;
          // let negative = completeArray[i]?.negative
          //   ? completeArray[i]?.negative
          //   : 0;
          // let neutral = completeArray[i]?.neutral
          //   ? completeArray[i]?.neutral
          //   : 0;
          // let mixed = completeArray[i]?.mixed ? completeArray[i]?.mixed : 0;
        }
        console.log("sortedArraysortedArray", sortedArray);
        setStatusOnlyArray(sortedArray);
        setCompleteDataArray(completeArray);
        setDatesArray(datesArray);
        // setPositiveArray(positiveArray);
        // setNegativeArray(negativeArray);
        // setMixedArray(mixedArray);
        // setNeutralArray(neutralArray);
      } else {
        setDatesArray([]);
        // setPositiveArray([]);
        // setNegativeArray([]);
        // setMixedArray([]);
        // setNeutralArray([]);
      }
      $(".loder_div").attr("hidden", true);

      //   console.log(datesArray, "datesArraydatesArray");
    });
  };
  const getAdministrativeArea = () => {
    const businessAccountData = {
      functionName: "getAdministrativeArea",
      account_id: window.sessionStorage.getItem("account_id"),
    };
    apiCallingPost(businessAccountData).then((data) => {
      if (data.success === "1") {
        setGetLocationTitle(data.data.map((obj) => obj.AdministrativeArea));
        // this.setState({
        //   getLocationTitle: data.data.map((obj) => obj.AdministrativeArea),
        // });
      } else {
        // $(".loder_div").attr("hidden", true);
      }
    });
  };

  const getLocationSearch = () => {
    const businessAccountData = {
      functionName: "getLocationSearch",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea: "",
      locality: "",
    };
    // $(".loder_div").attr("hidden", false);
    apiCallingPost(businessAccountData).then((data) => {
      // $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        setAllLocationdata(data.data);
        setGlobalFilterLocationsList(data.data);
      } else {
        setAllLocationdata([]);
        setGlobalFilterLocationsList([]);
        // $(".loder_div").attr("hidden", true);
      }
    });
  };

  const handleChangeState = (value) => {
    // $("#cityId").val("");
    if (value !== "") {
      setBusinessNameFilter("");
      setLocalityList("");
      setGetTitleName("");
      const businessAccountData = {
        functionName: "getLocalityUnderAdministrativeArea",
        account_id: window.sessionStorage.getItem("account_id"),
        AdministrativeArea: value,
      };
      // $(".loder_div").attr("hidden", false);
      apiCallingPost(businessAccountData).then((data) => {
        // $(".loder_div").attr("hidden", true);
        if (data.success === "1") {
          const businessAccountData = {
            functionName: "getLocationSearch",
            account_id: window.sessionStorage.getItem("account_id"),
            AdministrativeArea: value,
            locality: "",
          };
          backendApi(businessAccountData).then((response) => {
            setGlobalFilterLocationsList(response.data);
            setTempSearchLocationsList(response.data);
          });
          setGetCityList(data.data.map((obj) => obj.locality));
          setAdministrativeArea(value);
        } else {
          setGetCityList([]);
          // $(".loder_div").attr("hidden", true);
        }
      });
    }
  };
  const handleChangeCity = (value, AdministrativeArea) => {
    setLocalityList(value);
    setGetTitleName("");
    setBusinessNameFilter("");
    const businessAccountData = {
      functionName: "getLocationSearch",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea: AdministrativeArea,
      locality: value,
    };
    apiCallingPost(businessAccountData).then((data) => {
      if (data.success === "1") {
        setGlobalFilterLocationsList(data.data);
        setTempSearchLocationsList(data.data);
      } else {
        setGlobalFilterLocationsList([]);
        setTempSearchLocationsList([]);
        // $(".loder_div").attr("hidden", true);
      }
    });
  };
  const filterLocations = (e) => {
    setBusinessNameFilter(e.target.value);
    var array = [];
    if (AdministrativeArea != "" && LocalityList != "") {
      // this.setState({
      //   globalFilterLocationsList: tempSearchLocationsList,
      // });
      array = tempSearchLocationsList;
    } else {
      array = allLocationdata;
      // this.setState({
      //   globalFilterLocationsList: allLocationdata
      // });
    }
    var filterArray = array.filter(
      (data) =>
        data.storeCode.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.addressLines
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        (
          data.storeCode +
          "," +
          data.addressLines +
          "," +
          data.locality +
          "," +
          data.AdministrativeArea
        )
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        (data.locality + "," + data.AdministrativeArea)
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
    );
    setGlobalFilterLocationsList(filterArray);
  };
  const getBusinessChange = (location_id, data) => {
    // let filterData = this.state.allLocationdata.filter((obj, i) => {
    //   return obj.location_id === item;
    // });
    // console.log("dataaaa",data)
    setGetTitleName(location_id);
    setBusinessNameFilter(
      `${data.storeCode}- ${data.title}-${data.addressLines},${data.locality},${data.AdministrativeArea}`
    );
  };

  const star = (values) => {
    // alert(values)
    setStarReview(values);
  };
  const getClearData = () => {
    let d = new Date();
    setAdministrativeArea("");
    setLocalityList("");
    setGetTitleName("");
    setStartDate(d.setDate(d.getDate() - 30));
    setEndDate(new Date());
    setClearFlag(clearFlag + 1);
    // reviewAnalysisGraph();
  };
  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header heading="Review Analytics" headerImage={Accessibiltyimage} />
        <div className="container-fluid">
          <div className="row row-search px-2 mt-2">
            {/* <div className="col-lg-2 col-md-4 col-sm-6">
                  <select className="form-control">
                    <option value="">India</option>
                  </select>
                </div> */}
            <div className="col-lg-2 col-md-4 col-sm-6">
              {/* {console.log("xxxxxxxxxxxx", addressarray)} */}
              <select
                className="form-control"
                id="stateId"
                value={AdministrativeArea}
                onChange={(e) => handleChangeState(e.target.value)}
              >
                <option value="">State</option>
                {getLocationTitle.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <select
                className="form-control"
                id="cityId"
                value={LocalityList}
                onChange={(e) =>
                  handleChangeCity(e.target.value, AdministrativeArea)
                }
              >
                <option value="">City</option>
                {getCityList.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="col-lg-5 col-md-4 location_search">
              <input
                className="form-control"
                value={businessNameFilter}
                onChange={(e) => filterLocations(e)}
                placeholder="Search by location"
              />
              <div className="autocomplete__list location_search_drop">
                <ul class="nav flex-column">
                  {globalFilterLocationsList.map((data) => (
                    <li
                      key={data.location_id}
                      onMouseDown={() =>
                        getBusinessChange(data.location_id, data)
                      }
                    >
                      {data.storeCode}- {data.title}-{data.addressLines},
                      {data.locality},{data.AdministrativeArea}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                name="startDate"
                showMonthDropdown
                showYearDropdown
                autoComplete="off"
                dateFormat="dd/MM/yyyy"
                className="form-control"
                placeholderText="From Date"
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                name="startDate"
                id="endDate"
                showMonthDropdown
                autoComplete="off"
                showYearDropdown
                dateFormat="dd/MM/yyyy"
                className="form-control"
                placeholderText="To Date"
              />
            </div>
            <div className="col-lg-1 col-md-4 col-sm-6 pr-0">
              <button
                className="btn-search w-100"
                onClick={() => reviewAnalysisGraph()}
              >
                Search
              </button>
            </div>
            <div className="col-lg-1 col-md-4 col-sm-6 pr-0">
              <button
                className="btn-clear-search w-100"
                onClick={() => getClearData()}
              >
                Clear
              </button>
            </div>
          </div>

          <div className="mt-3">
            <ReviewAnalyticsGraph
              key={completeDataArray}
              // positiveArray={positiveArray}
              // negativeArray={negativeArray}
              // mixedArray={mixedArray}
              // neutralArray={neutralArray}
              // datesArray={datesArray}
              statusOnlyArray={statusOnlyArray}
              completeDataArray={completeDataArray}
            />
          </div>

          <div className="reviewAnalyticsMain mt-3 mb-5">
            {completeDataArray.map((obj) => (
              <div className="reviewAnalyticsData">
                <div className="dataTablereview">
                  Date : {moment(obj.date).format("YYYY-MM-DD")}
                </div>

                <div className="dataContentReview">
                  <div className="contentText">
                    <span>Positive: </span>
                    {obj?.positive ? obj?.positive : 0}
                  </div>
                  <div className="contentText">
                    <span>Negative: </span>
                    {obj?.negative ? obj?.negative : 0}
                  </div>
                  <div className="contentText">
                    <span>Mixed: </span>
                    {obj?.mixed ? obj?.mixed : 0}
                  </div>
                  <div className="contentText">
                    <span>Neutral: </span>
                    {obj?.neutral ? obj?.neutral : 0}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
};
export default ReviewAnalyticsTable;
