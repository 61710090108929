import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LeftMenu from "../layout/leftMenu";
import Lock from "../images/left_icons/lock2.png";
import Header from "../layout/header";
import Footer from "../layout/footer";
import * as Icon from "react-feather";
import { MdHistory } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";
// import Loader from "../images/ideas.jpg";
import Switch from "react-switch";
import { backendApi, re } from "../apiService";
import Select from "react-select";
import $ from "jquery";
import AddGroup from "./components/addGroup";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import GroupsList from "./components/groupsList";
function AddUser() {
  const [invitePopup, setInvitePopup] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [approverGroup, setApproverGroup] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [organizationList, setOrganizationList] = useState("");
  const [selectedApproverGrp, setSelectedApproverGrp] = useState("");
  const [contentApproverList, setContentApproverList] = useState("");
  const [groupName, setGroupName] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [userApproverMessage, setUserApproverMessage] = useState(
    "Waiting for Data..."
  );
  useEffect(() => {
    contentInit();
    approverList();
    getAdministrativeArea();
    getSiteByUser();
  }, []);

  const getSiteByUser = () => {
    const api = {
      functionName: "getSiteByUser",
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      role:
        window.sessionStorage.getItem("switchUserRole") === ""
          ? window.sessionStorage.getItem("role")
          : window.sessionStorage.getItem("switchUserRole"),
    };
    const site_id =
      window.sessionStorage.getItem("switchingOrgId") === ""
        ? window.sessionStorage.getItem("organizationId")
        : window.sessionStorage.getItem("switchingOrgId");

    backendApi(api).then((data) => {
      console.log("🚀 ~ backendApi ~ data:", data);
      setOrganizationList(data.data.filter((o) => o.id == site_id)[0]);
    });
  };
  const approverList = () => {
    $(".loder_div").attr("hidden", false);
    const api = {
      functionName: "content_approver_list",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(api).then((data) => {
      if (data.success === "1") {
        if (data.userList.length > 0) {
          setContentApproverList(data.userList);
          setUserApproverMessage("");
          $(".loder_div").attr("hidden", true);
        } else {
          setUserApproverMessage("No Data found");
          setContentApproverList([]);
          $(".loder_div").attr("hidden", true);
        }
      } else {
        alert("Something went wrong");
        setUserApproverMessage("No Data found");
        setContentApproverList([]);
      }
    });
  };
  const getAdministrativeArea = () => {
    const businessAccountData = {
      functionName: "getAdministrativeArea",
      account_id: window.sessionStorage.getItem("account_id"),
    };
    backendApi(businessAccountData).then((data) => {
      if (data.success === "1") {
        setStatesList(data.data.map((obj) => obj.AdministrativeArea));
      } else {
        setStatesList([]);
      }
    });
  };
  const contentInit = () => {
    const api = {
      functionName: "content_init",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(api).then((response) => {
      if (response.success === "1") {
        console.log("🚀 ~ backendApi ~ response:", response);
        setApproverGroup(
          response.content_approver_group.map((data) => ({
            ...data,
            value: data.id,
            label: data.group_name,
          }))
        );
      } else {
        setApproverGroup([]);
      }
    });
  };
  const saveUser = () => {
    if (name === "") {
      alert("Name required!!");
      return false;
    } else if (email === "") {
      alert("Email-Id required");
      return false;
    } else if (!re.test(email)) {
      alert("Invalid Email Id ");
      return false;
    } else if (selectedApproverGrp === "") {
      alert("Please select a group");
      return false;
    }
    const api = {
      functionName: "content_approver_save",
      full_name: name,
      email_id: email,
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      content_approver_group_id: selectedApproverGrp.id,
      created_by:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };
    let domain = organizationList.domain;
    if (!email.includes(domain)) {
      if (
        window.confirm(
          "Email domain is not same of this organization. Do you want to proceed?"
        )
      ) {
        backendApi(api).then((data) => {
          if (data.success === "1") {
            alert("Saved Successfully");
            contentInit();
            setInvitePopup(false);
            approverList();
            setName("");
            setEmail("");
            setSelectedApproverGrp("");
          } else alert("Something went wrong");
        });
      }
    } else {
      backendApi(api).then((data) => {
        if (data.success === "1") {
          alert("Saved Successfully");
          contentInit();
          setInvitePopup(false);
          approverList();
        } else alert("Something went wrong");
      });
    }
  };
  const changeUserStatus = (checked, id) => {
    console.log("🚀 ~ changeUserStatus ~ checked:", checked);
    const backupList = contentApproverList;
    setContentApproverList(
      contentApproverList.map((obj) => {
        if (obj.id == id) {
          return {
            ...obj,
            is_active: checked === false ? 0 : 1,
          };
        }
        return { ...obj };
      })
    );
    const api = {
      functionName: "content_approver_activeDeactive",
      id: id,
      is_active: checked === false ? "0" : "1",
    };
    backendApi(api).then((data) => {
      if (data.success === "1") {
        alert("Status changed successfully!!");
      } else {
        alert("Something went wrong!");
        setContentApproverList(backupList);
      }
    });
  };
  const closePopup = () => {
    setInvitePopup(false);
    setName("");
    setEmail("");
    setSelectedApproverGrp("");
  };

  const toggleModal3 = () => {
    $("#assignGroup").attr("hidden", false);
  };

  const activeDeactive = (checked, id) => {
    console.log("🚀 ~ activeDeactive ~ checked:", checked);
    const api = {
      functionName: "content_approver_group_activeDeactive",
      is_active: checked === true ? 1 : 0,
      content_approver_group_id: id,
    };
    backendApi(api).then((data) => {
      if (data.success === "1") {
        alert("Status changed successfully!!");
        contentInit();
      }
    });
  };

  const editModer = (f) => {
    setEditMode(f);
  };

  // const editGroup = (data) => {};
  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Content Approver" headerImage={Lock} />
        <div className="user_listdiv">
          <div class="row userrow_form col-md-12"></div>
        </div>
        <div className="col-md-12 tableResponsive px-2">
          <div className="tableOuterWidth table_width_md m-auto">
            <button
              className="btn btn-save pull-right mb-3"
              onClick={() => setInvitePopup(true)}
            >
              Create Approver
            </button>
            {contentApproverList && contentApproverList.length > 0 ? (
              <table className="table">
                <thead>
                  <tr>
                    <th className="col-1">#</th>
                    <th className="col-2">Name</th>
                    <th className="col-2">Email Id</th>
                    <th className="col-2">Role</th>
                    <th className="col-3">Locations</th>
                    <th className="col-1">Action</th>
                  </tr>
                </thead>
                <div class="table_Outerscroll">
                  {contentApproverList.length > 0 &&
                    contentApproverList.map((data, i) => {
                      return (
                        <tbody className="table_Outertbody">
                          <tr>
                            <td className="col-1">{i + 1}</td>
                            <td className="col-2">{data.fullName}</td>
                            <td className="col-2">{data.email_id}</td>
                            <td className="col-2">{data.role}</td>
                            <td className="col-3">{data.locations}</td>
                            <td className="col-1">
                              <Switch
                                className="switch_case"
                                onChange={(e) => changeUserStatus(e, data.id)}
                                checked={data.is_active}
                              />
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                </div>
              </table>
            ) : (
              <>{userApproverMessage}</>
            )}
          </div>
        </div>
        {invitePopup && (
          <div className="adv_modal">
            <div className="adv_modal_inner ">
              <div className="adv_modal_header d-flex align-itesm-center">
                <h3 class="image-heading">Invite / Add Users</h3>
                <Icon.XCircle
                  color="red"
                  className="ml-auto"
                  onClick={() => closePopup()}
                />
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-2 mb-md-0">
                    <label for="exampleInputEmail1">
                      Name<sup style={{ color: "red" }}>*</sup>
                    </label>
                    <div className="d-md-flex">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Full Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2 mb-md-0">
                    <label for="exampleInputEmail1">
                      Email Id<sup style={{ color: "red" }}>*</sup>
                    </label>
                    <div className="d-md-flex">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter email-id"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* </div>
              <div className="row"> */}
                <div className="col-md-6">
                  <div className="form-group mb-2 mb-md-0">
                    <label for="exampleInputEmail1">
                      Approver Group<sup style={{ color: "red" }}>*</sup>
                    </label>
                    <div className="d-md-flex align-items-center">
                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Enter email-id"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      /> */}
                      <Select
                        className="w-75"
                        options={approverGroup}
                        onChange={(e) => setSelectedApproverGrp(e)}
                      />
                      <span
                        className="p-0 ml-2 add_usser_listplus"
                        title="Add New Group"
                        onClick={() => {
                          toggleModal3();
                        }}
                      >
                        <button className="btn btn-info">New Group</button>
                        {/* <Icon.PlusCircle /> */}
                      </span>
                    </div>
                  </div>
                </div>
                {selectedApproverGrp !== "" &&
                  selectedApproverGrp.locations !== "" && (
                    <div className="loc-selected w-100 col-md-12">
                      <b>Locations Selected:</b> {selectedApproverGrp.locations}
                    </div>
                  )}
              </div>
              <div className="text-right">
                <button
                  type="submit"
                  class="btn btn-success mr-2"
                  onClick={saveUser}
                >
                  Save
                </button>
                <button
                  type="submit"
                  class="btn btn-secondary"
                  onClick={() => closePopup()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        <div id="assignGroup" className="adv_modal" hidden>
          <div className="adv_modal_inner">
            <Tabs>
              <TabList>
                <Tab>Add New Group</Tab>
                <Tab>History</Tab>
              </TabList>
              <TabPanel>
                <AddGroup
                  editMode={editMode}
                  key={editMode}
                  setEditMode={editModer}
                  statesList={statesList}
                  contentInit={contentInit}
                />
              </TabPanel>
              <TabPanel>
                <GroupsList
                  key={editMode}
                  contentInit={contentInit}
                  editMode={editMode}
                  setEditMode={editModer}
                  approverGroup={approverGroup}
                  activeDeactive={activeDeactive}
                  statesList={statesList}
                />
              </TabPanel>
            </Tabs>
          </div>
        </div>
        <div className="loder_div" hidden />
        <Footer />
      </div>
    </div>
  );
}

export default AddUser;
