import React, { useEffect, useState } from "react";
import Header from "../layout/header";
import LeftMenu from "../layout/leftMenu";
import Footer from "../layout/footer";
import LiveMonitoringimage from "../images/left_icons/live.png";
import { backendApi, pythonBackendApi } from "../apiService";
import moment from "moment";
import $ from "jquery";
import * as Icon from "react-feather";

function VMProcessStatus() {
  const [processName, setProcessName] = useState("fulldataFromGoogle.py");
  const [callingNewprocessPageStatus, setCallingNewprocessPageStatus] =
    useState(0);
  const [statusList, setStatusList] = useState([]);
  useEffect(() => {
    // getProcessInfo(processName);
    getListOfProcess();
  }, [callingNewprocessPageStatus]);
  const getListOfProcess = () => {
    $(".loder_div").attr("hidden", false);
    var apidata = {
      functionName: "listStatus",
    };
    backendApi(apidata).then((data) => {
      //   console.log("hihihihihihihi", data);
      setStatusList(data.data);
      $(".loder_div").attr("hidden", true);
    });
  };
  const getProcessInfo = () => {
    $(".loder_div").attr("hidden", false);
    var apidata = {
      method: "POST",
      body: JSON.stringify({
        processName: processName,
      }),
    };
    pythonBackendApi("vmFunctionRunningStatus", apidata).then((data) => {
      //   console.log("hihihihihihihi", data);
      setCallingNewprocessPageStatus(callingNewprocessPageStatus + 1);
      $(".loder_div").attr("hidden", true);
    });
  };
  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header heading="Role List" headerImage={LiveMonitoringimage} />
        <div className="container-fluid">
          <div className="row justify-content-between mb-3 mx-0">
            <div className="searchSection">
              <div className="input-group mb-3">
                <select
                  className="form-control mr-2"
                  onChange={(e) => setProcessName(e.target.value)}
                >
                  <option value="">Select Process</option>
                  <option value="fulldataFromGoogle.py">Audit</option>
                </select>

                <div className="input-group-append">
                  <button
                    className="btn btn-search"
                    type="button"
                    onClick={() => getProcessInfo()}
                  >
                    Check Status
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="searchSection">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search by keyword"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <div className="input-group-append">
              <button
                className="btn btn-search"
                type="button"
                onClick={() => searchRoles()}
              >
                Search
              </button>
            </div>
          </div>
        </div> */}

          <div className="col-md-12 tableResponsive">
            <div className="tableOuterWidth">
              <table className="table">
                <thead>
                  <tr>
                    <th className="col-1">Sl. No</th>
                    <th className="col-3">Machine Name</th>
                    <th className="col-2">Date/Time</th>
                    <th className="col-2">VM Id.</th>
                    <th className="col-4">File Name</th>
                  </tr>
                </thead>
                <div class="table_Outerscroll">
                  <>
                    {statusList.map((data) => {
                      let DateTime = data.date_time;
                      DateTime = moment(DateTime).format("DD-MM-YY hh:mm a");
                      return (
                        <tbody className="table_Outertbody">
                          <tr>
                            <td className="col-1">{data.id}</td>
                            <td className="col-3">{data.machine_name}</td>

                            <td className="col-2">{DateTime}</td>
                            <td className="col-2">{data.vm}</td>
                            <td className="col-4">
                              {data.function_name}{" "}
                              {data.status == 0 ? (
                                <span title="Down">
                                  <Icon.AlertTriangle
                                    style={{ color: "red" }}
                                    className="icon_common"
                                  />
                                </span>
                              ) : (
                                <span title="Running">
                                  <Icon.CheckCircle
                                    style={{ color: "green" }}
                                    className="icon_common"
                                  />
                                </span>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </>
                </div>
              </table>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
}

export default VMProcessStatus;
