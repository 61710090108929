import React, { useState, useEffect } from 'react';
import * as Icon from "react-feather";
import ReactApexChart from "react-apexcharts";
import Chart from 'react-apexcharts';
import { backendDashboardApi } from "../apiService";
function DashboardGraph() {
  const [data, setData] = useState(null);
  const [selectedOption, setSelectedOption] = useState('all');
  useEffect(() => {
    pieChart()
  }, []);
  const pieChart = () => {
    const postData = {
      "functionName": "socialMediaAnalyticalPieChart",
      "accountId": window.sessionStorage.getItem("switchingOrgId")
    };
    backendDashboardApi(postData).then((data) => {
      if (data.success === "1") {
        const transformedData = transformData(data.result);
        setData(transformedData)
      } else {

      }
    });
  };

  const transformData = (apiResult) => {
    return {
      all: {
        series: apiResult.all.series,
        options: {
          chart: {
            type: 'pie',
            width: '100%',
          },
          labels: apiResult.all.labels,
          theme: {
            monochrome: {
              enabled: true,
              shadeTo: 'light', // You can adjust this to 'dark' for darker shades
              shadeIntensity: 0.6,
            }
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -5,
              }
            }
          },
          dataLabels: {
            formatter(val, opts) {
              const name = opts.w.globals.labels[opts.seriesIndex];
              return [name, val.toFixed(1) + '%'];
            },
            style: {
              fontSize: '13px',
              fontFamily: 'Gilroy-Regular',
              colors: ['#000']
            },
            dropShadow: {
              enabled: false,
            }
          },
          legend: {
            show: false,
          },
        },
      },
      organic: {
        series: apiResult.organic.series,
        options: {
          chart: {
            type: 'pie',
            width: '100%',
          },
          labels: apiResult.organic.labels,
          theme: {
            monochrome: {
              enabled: true,
              shadeTo: 'light',
              shadeIntensity: 0.6,
            }
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -5
              }
            }
          },
          dataLabels: {
            formatter(val, opts) {
              const name = opts.w.globals.labels[opts.seriesIndex];
              return [name, val.toFixed(1) + '%'];
            }
          },
          legend: {
            show: false
          },
        },
      },
      inorganic: {
        series: apiResult['in-organic'].series,
        options: {
          chart: {
            type: 'pie',
            width: '100%',
          },
          labels: apiResult['in-organic'].labels,
          theme: {
            monochrome: {
              enabled: true,
              shadeTo: 'light',
              shadeIntensity: 0.6,
            }
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -5
              }
            }
          },
          dataLabels: {
            formatter(val, opts) {
              const name = opts.w.globals.labels[opts.seriesIndex];
              return [name, val.toFixed(1) + '%'];
            }
          },
          legend: {
            show: false
          },
        },
      },
    };
  };

  const renderChart = (chartData) => {
    const numberArrayFloat = chartData.series.map(str => parseFloat(str));
    return (
      <ReactApexChart options={chartData.options} series={numberArrayFloat} type="pie" />
    );
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };
  return (
    <div className="db-cards-25 ">
      <div className="card">
        <div className="card-body">
          <div className='db-cards-top mb-0 db-cards-check align-items-start'>
            <div className="form-check mb-0 pl-0">
              <div className="custom-control custom-radio">
                <input type="radio" id="flexRadioDefault1" name="flexRadioDefault" className="custom-control-input"
                  checked={selectedOption === 'all'}
                  onChange={() => handleOptionChange('all')}
                />
                <label className="custom-control-label" for="flexRadioDefault1">All</label>
              </div>
            </div>
            {/* <div className="form-check mb-0">
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={selectedOption === 'all'}
                onChange={() => handleOptionChange('all')} />
              <label className="form-check-label" for="flexRadioDefault1">
                All
              </label>
            </div> */}
            {/* <div className="form-check mb-0">
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={selectedOption === 'organic'}
                onChange={() => handleOptionChange('organic')} />
              <label className="form-check-label" for="flexRadioDefault2">
                Organic
              </label>
            </div> */}
            <div className="form-check mb-0 pl-0">
              <div className="custom-control custom-radio">
                <input type="radio" id="flexRadioDefault2" name="flexRadioDefault" className="custom-control-input"
                  checked={selectedOption === 'organic'}
                  onChange={() => handleOptionChange('organic')}
                />
                <label className="custom-control-label" for="flexRadioDefault2">Organic</label>
              </div>
            </div>
            {/* <div className="form-check mb-0">
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked={selectedOption === 'inorganic'}
                onChange={() => handleOptionChange('inorganic')} />
              <label className="form-check-label" for="flexRadioDefault3">
                Inorganic
              </label>
            </div> */}
            <div className="form-check mb-0 pl-0">
              <div className="custom-control custom-radio">
                <input type="radio" id="flexRadioDefault3" name="flexRadioDefault" className="custom-control-input"
                  checked={selectedOption === 'inorganic'}
                  onChange={() => handleOptionChange('inorganic')}
                />
                <label className="custom-control-label" for="flexRadioDefault3">Inorganic</label>
              </div>
            </div>
            <div className="">
              {data && renderChart(data[selectedOption])}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardGraph;