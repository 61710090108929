import React, { Component, createRef } from "react";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import AllSearches from "../GBP/all_searches";
import TotalView from "../GBP/total_view";
import Gbpimage from "../images/left_icons/GBP.png";
import { backendApi, apiCallingPost } from "../apiService";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import moment from "moment";
import $, { data } from "jquery";
import AWS from "aws-sdk";
// import { Upload } from "@aws-sdk/lib-storage";
// import { S3Client, S3 } from "@aws-sdk/client-s3";
import Slider from "react-slick";
import "../css/slick.css";
import "../css/slick-theme.css";
import axios from "axios";
// const token =
//   "ya29.a0AeTM1ieUd4Lw3f4_IcGaMdwYu-MZU6Nmiqa7aRvNh4owGKmJ5VGdUl-CC4-TN97cIJyyLVsNDsfmXDcCBI8ugXteeYa7mcFhSAvKy0vs4durcDqceW-tk2vLjbXL_OHTt-mqFhLKElPi76hUCtlGarRmx4Zj8UN7Pk62QJ6tLEFaqygmLTlXSSMMgLvYLvggslTKWUaq8lQJaCgYKASMSARISFQHWtWOmwRmmWbCjeo481m-EoqzJ1Q0211";
var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1201,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

var settingsSlider = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1201,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
var s3Region;
var s3bucketAccessKey;
var s3bucketSecretKey;
var s3Bucket;
var s3BucketMainFolder;
var S3BucketImagesFolder;
var s3;
var partSize = 1024 * 1024 * 5;
var partNum = 0;
var globalLocationId = 0;
let finalarray = [];
export default class PhotosGbp extends Component {
  constructor(props) {
    super(props);
    // this.selectLocationRef = createRef(null);
    this.state = {
      imageUrlListStatus: 0,
      imageUrl: [],
      insightsStartDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ).toISOString(),
      insightsEndDate: new Date().toISOString(),
      locationsList: [],
      temporaryLocationsList: [],
      localityList: [],
      localityListBackup: [],
      locationsList: [],
      administrativeAreaList: [],
      selectLocation: "",
      locationMedia: [],
      selectedLocationId: "",
      setCategory: "CATEGORY_UNSPECIFIED",
      administrativeArea: "",
      stateLocationList: [],
      cityLocationList: [],
      globalFilterLocationsList: [],
      accountList: [],
      tempSearchLocationsList: [],
      errorMessage: "PLease select a location above...",
    };
    this.selectLocation = this.selectLocation.bind(this);
  }
  componentDidMount() {
    // this.getLocationsList(); //!this is the old function to call api from google. Not required anymore
    // this.newGetLocationsList(); //? this is the api will be used to fetch the list of locations under an organization
    this.getAdministrativeArea();
    window.sessionStorage.setItem("selectedLocation", "");
    this.initialData();
    this.getAccountLocations();
  }
  getS3Credentials = () => {
    const credentialReq = {
      functionName: "s3Credentials",
    };
    backendApi(credentialReq).then((data) => {
      // setS3Credentials(data.data[0]);
      s3Region = "us-east-1";
      s3bucketAccessKey = data.data[0].s3bucketAccessKey;
      s3bucketSecretKey = data.data[0].s3bucketSecretKey;
      s3Bucket = data.data[0].s3bucket;
      s3BucketMainFolder = data.data[0].s3Folder;
      S3BucketImagesFolder = "images";
      var bucketParams = {
        Bucket: s3Bucket,
      };
      AWS.config.update({
        accessKeyId: s3bucketAccessKey,
        secretAccessKey: s3bucketSecretKey,
        region: s3Region,
      });
      s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: bucketParams,
      });
      console.log("s3", s3);
    });
  };

  newGetLocationsList = () => {
    $(".loder_div").attr("hidden", false);
    const apiJson = {
      functionName: "getMasterDataForFilter",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    apiCallingPost(apiJson).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success == "1") {
        this.setState(
          {
            locationsList: data.data,
            temporaryLocationsList: data.data,
          },
          () => {
            if (window.sessionStorage?.getItem("fromClaimData")) {
              let fromClaimData = JSON.parse(
                window.sessionStorage.getItem("fromClaimData")
              );
              let filteredLocations = this.state.locationsList.filter(
                (obj) => obj.location_id == fromClaimData.google_location_id
              );
              // $("#locationSelect").val(fromClaimData.google_location_id);
              // this.selectLocationRef.value = fromClaimData.google_location_id;
              // console.log("hiiii", filteredLocations);
              this.setState(
                {
                  selectedLocationId: filteredLocations[0].location_id,
                },
                () => console.log(this.state.selectedLocationId)
              );
              this.getLocationMedia(filteredLocations[0]);
              window.sessionStorage.setItem(
                "selectedLocation",
                JSON.stringify(filteredLocations[0])
              );
              window.sessionStorage?.removeItem("fromClaimData");
            } else if (window.sessionStorage?.getItem("fromGBP")) {
              let fromGBPData = JSON.parse(
                window.sessionStorage.getItem("fromGBP")
              );
              let filteredLocations = this.state.locationsList.filter(
                (obj) => obj.location_id == fromGBPData
              );
              this.setState(
                {
                  selectedLocationId: filteredLocations[0].location_id,
                },
                () => console.log(this.state.selectedLocationId)
              );
              // $("#locationSelect").val(fromGBPData);
              // this.selectLocationRef.value = fromGBPData.google_location_id;
              // console.log("hiiii", filteredLocations);
              // $("#locationSelect").val(fromGBPData);
              this.getLocationMedia(filteredLocations[0]);
              window.sessionStorage.setItem(
                "selectedLocation",
                JSON.stringify(filteredLocations[0])
              );
              window.sessionStorage?.removeItem("fromGBP");
            } else {
              // document.getElementById("locationSelect").value =
              //   this.state.locationsList.location_id;
              // $("#locationSelect").val(this.state.locationsList.location_id);
              this.setState(
                {
                  selectedLocationId: this.state.locationsList[0].location_id,
                },
                () => console.log(this.state.selectedLocationId)
              );
              this.getLocationMedia(this.state.locationsList[0]);
              window.sessionStorage.setItem(
                "selectedLocation",
                JSON.stringify(this.state.locationsList[0])
              );
            }
          }
        );
        globalLocationId = data.data.length;
      }
    });
  };

  getLocationMedia(location_id) {
    this.setState({ locationMedia: [] });
    $(".loder_div").attr("hidden", false);
    const apiRequest = {
      functionName: "GetLocationMedia",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      location_id: location_id,
    };
    backendApi(apiRequest).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        this.setState({
          locationMedia: data.data.mediaItems,
        });
        $("#multipleImagesSlider").attr("hidden", false);
      } else {
        this.setState({
          errorMessage: "No Media Found",
        });
      }
    });
  }
  selectLocation(object) {
    if (object.target.value === "0") {
      window.sessionStorage.setItem("selectedLocation", "");
      this.setState({
        selectLocation: [],
        locationMedia: [],
      });
      return false;
    }
    var location = [];
    location = this.state.locationsList.filter(
      (obj) => obj.location_id == object.target.value
    );
    // alert(object.target.value);
    window.sessionStorage.setItem(
      "selectedLocation",
      JSON.stringify(location[0])
    );
    this.setState(
      {
        selectLocation: location[0],
        selectedLocationId: object.target.value,
      },
      () => {
        this.getLocationMedia(this.state.selectLocation);
      }
    );
  }
  deleteLocationMedia = (location) => {
    $(".loder_div").attr("hidden", false);
    const apiReq = {
      functionName: "deleteLocationMedia",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      location_id: location.name.split("/")[3],
      media_id: location.name.split("/")[5],
    };
    backendApi(apiReq).then((data) => {
      if (data.success === "1") {
        const postData = {
          functionName: "gbpImageUploadUpdate",
          site_id:
            window.sessionStorage.getItem("switchingOrgId") === ""
              ? window.sessionStorage.getItem("organizationId")
              : window.sessionStorage.getItem("switchingOrgId"),
          location_id: location.name.split("/")[3],
        };
        backendApi(postData).then((data) => {});
        alert("Deleted Successfully");
        window.location.reload();
      } else {
        alert("Something went wrong whil deleting Image");
      }
    });
  };
  // multipartUpload = async (image) => {
  //   // alert("hi");
  //   console.log("imageimageimageimage", image);
  //   var path = (window.URL || window.webkitURL).createObjectURL(image);
  //   console.log("pathpathpathpathpath", path);
  //   let idCardBase64 = "";
  //   const instance = axios.create({
  //     baseURL:
  //       "https://mybusiness.googleapis.com/v4/accounts/113149385002384039024/locations/53904911361864879",
  //     headers: { Authorization: `Bearer ${token}` },
  //   });
  //   let reader = new FileReader();
  //   reader.readAsDataURL(image);
  //   reader.onload = function () {
  //     //   console.log(reader.result);
  //     let imageBase64 = reader.result;
  //     instance
  //       .post("/media:startUpload")
  //       .then(async (res) => {
  //         console.log("First axios call is success");
  //         console.log("============", res.data);
  //         await axios
  //           .post(
  //             `https://mybusiness.googleapis.com/upload/v1/media/${res.data.resourceName}?upload_type=media`,
  //             path,
  //             {
  //               headers: {
  //                 Authorization: `Bearer ${token}`,
  //               },
  //             }
  //           )
  //           .then(async (response) => {
  //             console.log("second axios call is success");
  //             let result = {
  //               success: "1",
  //               errorMessage: "",
  //               successMessage: "Success",
  //               data: res.data,
  //             };
  //             let body = {
  //               mediaFormat: "PHOTO",
  //               locationAssociation: {
  //                 category: "CATEGORY_UNSPECIFIED",
  //               },
  //               dataRef: {
  //                 resourceName: res.data.resourceName,
  //               },
  //             };
  //             console.log("resultresultresultresultresulte==========", result);
  //             console.log("bodybodybodybody", body);
  //             await instance
  //               .post("/media", body)
  //               .then((result) => {
  //                 console.log("third axios call is success");
  //                 console.log("third axios result================", result);
  //               })
  //               .catch((err) => {
  //                 console.log("inside third axios error");
  //                 let result = {
  //                   success: "0",
  //                   errorMessage: err.message,
  //                   successMessage: "",
  //                   data: err.response.data.error.details[0].errorDetails,
  //                 };
  //                 console.log(
  //                   "resultresultresultresultresult+++++++++++++++++",
  //                   result
  //                 );
  //                 // return callBack(result);
  //               });
  //           })
  //           .catch((err) => {
  //             console.log("inside second axios error");
  //             let result = {
  //               success: "0",
  //               errorMessage: err.message,
  //               successMessage: "",
  //               data: err.response.data,
  //             };
  //             console.log("responseresponseresponse+++++++++++++++++", err);
  //             console.log(
  //               "resultresultresultresultresult+++++++++++++++++",
  //               result
  //             );
  //           });
  //       })
  //       .catch((err) => {
  //         console.log("Error inside First Axios post call........");
  //         let response = {
  //           success: "0",
  //           errorMessage: err.message,
  //           successMessage: "",
  //           data: err.response.data,
  //         };
  //         // console.log("responseresponseresponse+++++++++++++++++", err);
  //         console.log(
  //           "This is the error result of First Axios Post call",
  //           response
  //         );
  //       });
  //   };
  // };
  //   getBase64(file, cb) {
  //     let reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = function () {
  //       cb(reader.result);
  //     };
  //     reader.onerror = function (error) {
  //       console.log("Error: ", error);
  //     };
  //   }
  //   multipartUpload = (image) => {
  //     let s3BucketMainFolder1 = s3BucketMainFolder + "/" + S3BucketImagesFolder;
  //     let fileName = this.uuidv4() + image.name;
  //     let fullFilePath = s3BucketMainFolder1 + "/" + fileName;
  //     s3.createMultipartUpload(function (mpErr, multipart) {
  //       if (mpErr) {
  //         console.log("Error!", mpErr);
  //         return;
  //       }
  //       console.log("Got upload ID", multipart.UploadId);

  //       // Grab each partSize chunk and upload it as a part
  //       for (
  //         var rangeStart = 0;
  //         rangeStart < image.size;
  //         rangeStart += partSize
  //       ) {
  //         partNum++;
  //         var end = Math.min(rangeStart + partSize, image.size),
  //           partParams = {
  //             Body: image.slice(rangeStart, end),
  //             Bucket: s3Bucket,
  //             Key: fullFilePath,
  //             PartNumber: String(partNum),
  //             UploadId: multipart.UploadId,
  //           };

  //         // Send a single part
  //         console.log(
  //           "Uploading part: #",
  //           partParams.PartNumber,
  //           ", Range start:",
  //           rangeStart
  //         );
  //         uploadPart(s3, multipart, partParams);
  //       }
  //     });
  //   };
  //   multipartUpload = async (image) => {
  //     let bucket = "tagtree.s3.ap-south-1.amazonaws.com";
  //     let s3BucketMainFolder1 = s3BucketMainFolder + "/" + S3BucketImagesFolder;
  //     let fileName = this.uuidv4() + image.name;
  //     let fullFilePath = s3BucketMainFolder1 + "/" + fileName;
  //     const target = { Bucket: bucket, Key: fullFilePath, Body: image };
  //     const creds = {
  //       accessKeyId: s3bucketAccessKey,
  //       secretAccessKey: s3bucketSecretKey,
  //     };
  //     console.log("hello.....");
  //     try {
  //       const parallelUploads3 = new Upload({
  //         client: new S3({
  //           apiVersion: "2006-03-01",
  //           region: "ap-south-1",
  //           credentials: creds,
  //         }),
  //         leavePartsOnError: false,
  //         params: target,
  //       });
  //       console.log("depp star.....");

  //       parallelUploads3.on("httpUploadProgress", (progress) => {
  //         console.log("processsss", progress);
  //       });
  //       await parallelUploads3.done();
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };
  getAccountLocations() {
    $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "getAccountLocations",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      accounts_id: window.sessionStorage.getItem("account_id"),
    };
    apiCallingPost(reviewAPIData).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        this.setState({
          accountList: data.data,
          backupAccountList: data.data,
        });
      } else {
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  handleChangeState = (value) => {
    if (!value) {
      this.setState({
        administrativeArea: "",
        localityList: "",
        cityLocationList: [],
        globalFilterLocationsList: this.state.tempSearchLocationsList,
      });
      return;
    }
    const accountList = this.state.backupAccountList.filter(
      (eachAcc) => eachAcc.administrativeArea === value
    );
    this.setState({
      accountList,
    });
    const businessAccountData = {
      functionName: "getLocalityUnderAdministrativeArea",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea: value,
    };
    apiCallingPost(businessAccountData).then((data) => {
      if (data.success === "1") {
        const businessAccountData = {
          functionName: "getLocationSearch",
          account_id: window.sessionStorage.getItem("account_id"),
          AdministrativeArea: value,
          locality: "",
        };
        backendApi(businessAccountData).then((response) => {
          this.setState({
            globalFilterLocationsList: response.data,
            tempSearchLocationsList: response.data,
          });
        });
        this.setState({
          cityLocationList: data.data.map((obj) => obj.locality),
          administrativeArea: value,
        });
      } else {
        this.setState({
          cityLocationList: [],
        });
      }
    });
  };
  handleChangeCity(value, AdministrativeArea) {
    if (!value) {
      return;
    }
    const accountList = this.state.backupAccountList.filter(
      (eachAcc) => eachAcc.locality === value
    );
    this.setState({
      accountList,
    });
    this.setState({
      locality: value,
    });

    const businessAccountData = {
      functionName: "getLocationSearch",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea,
      locality: value,
    };
    apiCallingPost(businessAccountData).then((data) => {
      if (data.success === "1") {
        this.setState({
          globalFilterLocationsList: data.data,
          tempSearchLocationsList: data.data,
        });
      } else {
        this.setState({
          globalFilterLocationsList: [],
          tempSearchLocationsList: [],
        });
      }
    });
  }
  filterLocations(e) {
    this.setState({
      businessNameFilter: e.target.value,
    });
    var array = [];
    if (this.state.administrativeArea != "" && this.state.locality != "") {
      array = this.state.tempSearchLocationsList;
    } else {
      array = this.state.allLocationdata;
    }
    var filterArray = array.filter(
      (data) =>
        data.storeCode.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.addressLines
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        (
          data.storeCode +
          "," +
          data.addressLines +
          "," +
          data.locality +
          "," +
          data.AdministrativeArea
        )
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        (data.locality + "," + data.AdministrativeArea)
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
    );
    this.setState({
      globalFilterLocationsList: filterArray,
    });
  }
  getBusinessChange(locationId) {
    const accountList = this.state.backupAccountList.filter(
      (eachAcc) => eachAcc.location_id === locationId
    );
    this.getLocationMedia(locationId);
    // console.log("🚀 ~ file: googleBusinessLocation.jsx:239 ~ GoogleBusinessLocation ~ getBusinessChange ~ this.state.backupAccountList:", this.state.backupAccountList);
    this.setState(
      {
        businessNameFilter: `${accountList[0].storeCode}- ${accountList[0].title}-${accountList[0].addressLines},
      ${accountList[0].locality}, ${accountList[0].AdministrativeArea}`,
      },
      () =>
        console.log(
          "🚀 ~ file: photosGbp.jsx:638 ~ PhotosGbp ~ getBusinessChange ~ accountList:",
          this.state.accountList
        )
    );
  }
  getClearData() {
    this.setState({
      accountList: this.state.backupAccountList,
      administrativeArea: "",
      locality: "",
      businessNameFilter: "",
    });
  }

  handleChangeCity(value, AdministrativeArea) {
    console.log("hiiii", value);
    this.setState({
      LocalityList: value,
      getTitleName: "",
    });
    this.setState({
      businessNameFilter: "",
      businessAddress: "",
    });

    const businessAccountData = {
      functionName: "getLocationSearch",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea: AdministrativeArea,
      locality: value,
    };
    // $(".loder_div").attr("hidden", false);
    apiCallingPost(businessAccountData).then((data) => {
      // $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        this.setState({
          globalFilterLocationsList: data.data,
          tempSearchLocationsList: data.data,
          // locationIdsList: data.data.map((obj) => obj.location_id),
        });
        finalarray = [];
      } else {
        this.setState({
          globalFilterLocationsList: [],
          tempSearchLocationsList: [],
          // locationIdsList: [],
        });
        // $(".loder_div").attr("hidden", true);
      }
    });
  }

  getLocationSearch() {
    const businessAccountData = {
      functionName: "getLocationSearch",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea: "",
      locality: "",
    };
    apiCallingPost(businessAccountData).then((data) => {
      if (data.success === "1") {
        this.setState(
          {
            allLocationdata: data.data,
            businessName: data.data,
            globalFilterLocationsList: data.data,
          },
          () => {
            console.log("alllLocationData", this.state.allLocationdata);
          }
        );
      } else {
      }
    });
  }
  NewSearchAPIFunction() {
    this.getGbpData(this.state.selectedLocationId);
  }
  getAdministrativeArea() {
    const businessAccountData = {
      functionName: "getAdministrativeArea",
      account_id: window.sessionStorage.getItem("account_id"),
    };
    apiCallingPost(businessAccountData).then((data) => {
      if (data.success === "1") {
        this.setState({
          stateLocationList: data.data.map((obj) => obj.AdministrativeArea),
        });
      } else {
        // $(".loder_div").attr("hidden", true);
      }
    });
  }
  initialData() {
    const businessAccountData = {
      functionName: "getLocalityUnderAdministrativeArea",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea: "",
    };
    // $(".loder_div").attr("hidden", false);
    apiCallingPost(businessAccountData).then(async (data) => {
      this.setState({
        cityLocationList: data.data.map((obj) => obj.locality),
      });
      // this.handleChangeCity(data.data[0].locality, value);
      const businessAccountData2 = {
        functionName: "getLocationSearch",
        account_id: window.sessionStorage.getItem("account_id"),
        AdministrativeArea: "",
        locality: "",
      };
      apiCallingPost(businessAccountData2).then((newObj) => {
        this.setState({
          globalFilterLocationsList: newObj.data,
          tempSearchLocationsList: newObj.data,
        });
      });
    });
  }
  render() {
    return (
      <div className="main_wrapper ">
        <LeftMenu />
        <div className="userListWrapper">
          <Header heading="Photos" headerImage={Gbpimage} />
          <div className="container-fluid">
            <div className="row row-search  mb-2  ">
              {/* <div className="col-lg-2 col-md-4 col-sm-6">
                <select className="form-control">
                  <option value="">India</option>
                  <option value="">Sri Lanka</option>
                </select>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <select className="form-control">
                  <option value="0">State</option>
                  <option value="">Kerala</option>
                </select>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <select className="form-control">
                  <option value="0">City</option>
                  <option value="">Tvm</option>
                </select>
              </div> */}
              {/* <div className="col-lg-2 col-md-4 col-sm-6">
                <ReactDatePicker
                  selected={new Date(this.state.insightsEndDate)}
                  showMonthDropdown
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  className="form-control"
                  placeholderText="From"
                  onChange={(obj) => {
                    let endDate = new Date(obj);
                    this.setState({
                      insightsEndDate: endDate.toISOString(),
                      insightsDataKey: endDate.toISOString(),
                    });
                  }}
                />
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <ReactDatePicker
                  selected={new Date(this.state.insightsEndDate)}
                  showMonthDropdown
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  className="form-control"
                  placeholderText="To"
                  onChange={(obj) => {
                    let endDate = new Date(obj);
                    this.setState({
                      insightsEndDate: endDate.toISOString(),
                      insightsDataKey: endDate.toISOString(),
                    });
                  }}
                />
              </div> */}
              {/* <div className="col-lg-3 col-md-4 col-sm-6 location_search">
                <input
                  className="form-control"
                  type="text"
                  id="LocationSelected"
                  placeholder="Location"
                />
                <div className="location_search_drop">
                  <ul class="nav flex-column">
                    {this.state.locationsList.map((data, i) => (
                      <li
                        class="nav-item"
                        onClick={() => this.selectLocation(data)}
                      >
                        <span>{data.title}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <button className="btn-search">Search</button>
              </div> */}
            </div>
            {/* <hr /> */}

            {/* <div className="user_listview"> */}
            {/* <div className="user_listviewhead">
                <span>Add Photos</span>
              </div> */}
            {/* {this.state.selectLocation !== "" && ( */}
            {/* <div className="addPhotosDiv_gbp">
              <div className="addPhotoIconCam">
                <Icon.Camera />
              </div>
              <div className="editInfo col-lg-12 ">
                <div className="row ">
                  <div className="col-lg-4 col-md-6 col-12 ">
                    <select
                      id="categoryDropdown"
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.value !== "") {
                          this.setState({
                            setCategory: e.target.value,
                          });
                        }
                      }}
                    >
                      <option value="CATEGORY_UNSPECIFIED">General</option>
                      <option
                        disabled={this.state.imageUrl.length > 1 ? true : false}
                        id="logoId"
                        value="LOGO"
                      >
                        Logo
                      </option>
                      <option
                        disabled={this.state.imageUrl.length > 1 ? true : false}
                        id="coverId"
                        value="COVER"
                      >
                        Cover
                      </option>
                      <option value="EXTERIOR">Exterior</option>
                      <option value="INTERIOR">Interior</option>
                      <option value="PRODUCT">Product</option>
                      <option value="AT_WORK">At Work</option>
                      <option value="FOOD_AND_DRINK">Food & Drink</option>
                      <option value="MENU">Menu</option>
                      <option value="COMMON_AREA">Common Area</option>
                      <option value="ROOMS">Rooms</option>
                      <option value="TEAMS">Teams</option>
                      <option value="ADDITIONAL">Additional</option>
                    </select>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <select id="formatTypeDropdown" className="form-control">
                      <option value="PHOTO">Photo</option>
                      <option value="VIDEO">Video</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div
                    hidden={
                      this.state.imageUrl.length > 1 &&
                      (this.state.setCategory === "LOGO" ||
                        this.state.setCategory === "LOGO")
                        ? true
                        : false
                    }
                    className="col-lg-3 col-md-12 tag_info_contentlocation"
                  >
                    <div className="right_google_popup">
                      <label>
                        <Icon.Camera />
                        Add photos
                        <input
                          type="file"
                          multiple={
                            this.state.setCategory === "LOGO" ||
                            this.state.setCategory === "COVER"
                              ? false
                              : true
                          }
                          id="imageUpload"
                          onChange={(e) =>
                            this.handleImageUpload(e.target.files)
                          }
                        />
                      </label>
                    </div>
                  </div>
                  <div className="tag_photoSave">
                    {this.state.imageUrl.length !== 0 ? (
                      <Link
                        to="/SelectGBPLocations"
                        state={{
                          images: this.state.imageUrl,
                          category: this.state.setCategory,
                          mediaFormat: $("#formatTypeDropdown").val(),
                        }}
                      >
                        <button
                          id="websiteUriEditButton"
                          type="button"
                          className="btn btn-success"
                          // onClick={() => this.postPhotoToGBP()}
                        >
                          Choose Locations
                        </button>
                      </Link>
                    ) : (
                      <button
                        id="websiteUriEditButton"
                        className="btn btn-secondary"
                        // onClick={() => this.postPhotoToGBP()}
                      >
                        Choose Photo(s) to Upload
                      </button>
                    )}
                  </div>
                </div>
                <div id="multipleImagesUploadingSlider" hidden>
                  <div className="tag_GBPUpload">
                    {this.state.imageUrl.map((data, i) => (
                      <div className="tag_singleImg">
                        <span className="tag_close_popup_imageUpload">
                          <Icon.X
                            onClick={() => {
                              let items = [...this.state.imageUrl];
                              items.splice(i, 1);
                              this.setState({ imageUrl: items });
                            }}
                          />
                        </span>
                        <img src={data} alt="Gbpimage" />
                      </div>
                    ))}
                  </div>
                </div>

              </div>
            </div> */}
            <div className="row">
              {/* <div className="col-lg-4 col-md-6 col-12 ">
                <label>Please Select the Location</label>
                <select
                  className="form-control"
                  id="locationSelect"
                  value={this.state.selectedLocationId}
                  onChange={(e) => this.selectLocation(e)}
                >
                  <option value="0">Select</option>
                  {this.state.locationsList.map((data, i) => (
                    <option value={data.location_id}>
                      {data.title}, {data.addressLines}, {data.locality},{" "}
                      {data.administrativeArea}
                    </option>
                  ))}
                </select>
              </div> */}
            </div>
            <div className="row row-search px-2 mt-2">
              <div className="col-lg-2 col-md-4 col-sm-6">
                <select
                  className="form-control"
                  id="stateId"
                  value={this.state.administrativeArea}
                  onChange={(e) => this.handleChangeState(e.target.value)}
                >
                  <option value="">State</option>
                  {this.state.stateLocationList.map((item) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <select
                  className="form-control"
                  id="cityId"
                  value={this.state.locality}
                  onChange={(e) =>
                    this.handleChangeCity(
                      e.target.value,
                      this.state.administrativeArea
                    )
                  }
                >
                  <option value="">City</option>
                  {this.state.cityLocationList.map((item) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-5 col-md-4 location_search">
                <input
                  className="form-control"
                  value={this.state.businessNameFilter}
                  onChange={(e) => this.filterLocations(e)}
                  placeholder="Search by location"
                />
                <div className="autocomplete__list location_search_drop">
                  <ul class="nav flex-column">
                    {this.state.globalFilterLocationsList.map((data) => (
                      <li
                        key={data.location_id}
                        onMouseDown={() =>
                          this.getBusinessChange(data.location_id, data)
                        }
                      >
                        {data.storeCode}- {data.title}-{data.addressLines},
                        {data.locality},{data.AdministrativeArea}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* <div className="col-lg-1 col-md-4 col-sm-6 pr-0">
								<button className="btn-search w-100" onClick={() => this.getGbpData(this.state.locationIdsList)}>
									Search
								</button>
							</div>*/}
              <div className="col-lg-1 col-md-4 col-sm-6 pr-0">
                <button
                  className="btn-clear-search w-100"
                  onClick={() => this.getClearData()}
                >
                  Clear
                </button>
              </div>

              <div className="col-lg-1 col-md-4 col-sm-6 pr-0">
                {this.state.selectedLocationId !== "0" ? (
                  <div className="pull-right right-Btn-gbp">
                    <Link
                      className="btn btn-primary photoGbpHistory"
                      to="/PhotosUploadHistory"
                      // class="tag_float"
                      title="Add Post"
                      state={{
                        selectLocation:
                          this.state.selectLocation !== ""
                            ? this.state.selectLocation
                            : "",
                      }}
                    >
                      History
                    </Link>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {console.log(
              "🚀 ~ file: photosGbp.jsx:1200 ~ PhotosGbp ~ render ~ this.state.locationMedia:",
              this.state.locationMedia
            )}
            <></>
            {this.state.locationMedia.length > 0 ? (
              <div id="multipleImagesSlider" className="tag_Lists mt-3">
                <div className="tag_googledisplayImagesList">
                  <div className="tag_google">
                    <div className="row">
                      {this.state.locationMedia.map((data, i) => (
                        <>
                          <div className=" tag_imgWrap">
                            <div className="multipleImagesSlider">
                              <img
                                id={"Gbpimage" + i}
                                src={data.googleUrl}
                                alt="Gbpimage"
                              />
                              <span className="close_popup_imageUpload">
                                <Icon.X
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Do you want to delete this photo?"
                                      )
                                    ) {
                                      this.deleteLocationMedia(data);
                                    }
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>

                {/* </Slider> */}
              </div>
            ) : (
              <div>{this.state.errorMessage}</div>
            )}
            {/* )} */}

            {/* </div> */}

            <Link
              to="/addPhotos"
              class="tag_float"
              title="Add Post"
              state={{
                selectLocation:
                  this.state.selectLocation !== ""
                    ? this.state.selectLocation
                    : "",
              }}
            >
              <Icon.Plus color="#fff" class="tag_my_float" />
            </Link>
          </div>
          <Footer />
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}
