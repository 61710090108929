import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import { Link } from "react-router-dom";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import Auditimage from "../images/left_icons/audit.png";

import * as Icon from "react-feather";

export default function MasterDataList() {
    return (
        <div className="main_wrapper">
            <LeftMenu></LeftMenu>
            <div className="userListWrapper">
                <Header heading="Audit" headerImage={Auditimage} />
                <div className="user_listdiv removeBorder">
                    <div
                        className="col-md-12 downloadTemplateDiv">
                        <Icon.Download /> Download template
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table table-striped audit_table">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Batch</th>
                                            <th>Date</th>
                                            <th>User </th>
                                            <th>Process</th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>43- axis-test.xlsx</td>
                                            <td>11 Nov 2022 07:44 pm</td>
                                            <td>Unni KR</td>
                                            <td>
                                                <div class="create_popst_btn01">
                                                    <div class="create_popst_btn01">
                                                        <button
                                                            type="button"
                                                            class="btn btn-primary">
                                                            Start
                                                        </button>
                                                    </div>
                                                    <div class="create_popst_btn01">
                                                        <button
                                                            type="button"
                                                            class="btn btn-secondary">
                                                            Compare
                                                        </button>
                                                    </div>
                                                    <div class="create_popst_btn01">
                                                        <button
                                                            type="button"
                                                            class="btn btn-secondary">
                                                            Compare
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <Link title=" Show more">
                                                    <Icon.Eye />
                                                </Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="editCommentPopup" className="module_popup" hidden>
                    <div className="module_innerpopup">
                        <div className="module_close">
                            <Icon.XCircle />
                        </div>
                        <div id="editCommentPopupHeader" className="module_header"></div>
                        <div className="module_body">
                            <div className="module_popupsearch_wrpr">

                                <div className="py-3">
                                    <div class="did-floating-label-content">

                                        <select>
                                            <option value="">Select</option>
                                            <option> </option>
                                        </select>
                                        <label class="did-floating-label">Organization</label>
                                    </div>
                                </div>


                                <div className="module_popupsearchBtn">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="loder_div" hidden /> */}
                <Footer/>
            </div>
        </div>
            )
}


