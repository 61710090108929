import React, { Component } from "react";
import "../css/bootstrap.css";
import LiveMonitoringimage from "../images/left_icons/locateIT.png";
import "../css/style.css";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import $ from "jquery";
import Pagination from "react-js-pagination";
import * as Icon from "react-feather";
import { backendApi, pythonBackendApi } from "../apiService";
import moment from "moment";

class LocateIt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getLocationListData: [],
      count: "",
      setPageOffset: 0,
      setActivePage: 1,
    };
  }
  componentDidMount() {
    this.setState({
      setActivePage: Number(
        window.sessionStorage.getItem("activePageNumberlocateIt")
      ),
    });
    this.getLocationList();
    $("#nodata").hide()
  }
  handlePageChange(pageNumber) {
    this.setState({ setActivePage: pageNumber });

    var offset;
    if (pageNumber === "1") {
      offset = "0";
    } else {
      offset = parseInt(pageNumber - 1) * 25;
    }

    this.setState({ setActivePage: pageNumber, setPageOffset: offset });

    let dataAddress = $("#searchAddressText").val();
    let PostData = {
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      functionName: "getLocationList",
      offset: offset.toString(),
      search: (dataAddress = "" ? "" : dataAddress),
    };
    window.sessionStorage.setItem("activePageNumberlocateIt", pageNumber);
    window.sessionStorage.setItem("offsetlocateIt", offset);

    backendApi(PostData).then((data) => {
      for (let i = 0; i < data.data.length; i++) {
        $("#searchAddressvaluetext" + i).attr("hidden", true);
        $("#savebutton" + i).attr("hidden", true);
        $("#titleEditIconMatch" + i).attr("hidden", false);
        $("#searchCustomeraddress" + i).show();
        $("#titleEditIconMatch" + i).removeAttr("hidden");
      }
      if (data.success === "1") {
        this.setState({
          getLocationListData: data.data[0],
          setcount: data.data[1][0].count,
        });

        if (data.totalComplete !== data.totalCount) {
          $(".dataProcessImg").show();
        } else {
          $(".dataProcessImg").hide();
        }
      } else {
        this.setState({
          getLocationListData: [],
          setcount: 0,
        });
      }
    });
  }
  getLocationList(i) {
    var req = {
      functionName: "getLocationList",
      search: "",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      offset: window.sessionStorage.getItem("offsetlocateIt") == null ?0:window.sessionStorage.getItem("offsetlocateIt"),
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((data) => {
      $("#searchAddressvaluetext" + i).attr("hidden", true);
      $("#savebutton" + i).attr("hidden", true);
      $("#titleEditIconMatch" + i).attr("hidden", false);
      $("#searchCustomeraddress" + i).show();
      $("#titleEditIconMatch" + i).removeAttr("hidden");
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        this.setState({
          getLocationListData: data.data[0],
          setcount: data.data[1][0].count,
        });
      } else {
        this.setState({
          getLocationListData: [],
          setcount: 0,
        });
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  textAreachange(i) {
    $("#searchCustomeraddress" + i).hide();
    $("#searchAddressvaluetext" + i).attr("hidden", false);
    $("#savebutton" + i).attr("hidden", false);
    $("#titleEditIconMatch" + i).attr("hidden", true);
  }

  saveforAddress(i, search, id) {
    if ($("#searchAddressvaluetext" + i).val() === "") {
      alert("Search Criteria cannot be empty");
      return false;
    }
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "getLocationListUpdate",
      masterId: id,
      appUserEditedAddress:
        search === "" ? search : $("#searchAddressvaluetext" + i).val(),
    };
    backendApi(req).then((data) => {
      if (data.success === "1") {
        let dataIdValue = id;
        $("#saveSuccess" + id).css("display", "block");
        setTimeout(() => {
          $("#saveSuccess" + id).css("display", "none");
        }, 3000);
        $(".loder_div").attr("hidden", true);
        // $("#searchAddressvaluetext" + i).hide();
        // $("#searchAddressvaluetext" + i).hide();
        // $("#savebutton" + i).hide();
        // $("#titleEditIconMatch" + i).attr("hidden", false);

        this.getLocationList(i);
      } else {
        alert("Something went wrong while updating Search Criteria");
        $(".loder_div").attr("hidden", true);
      }
    });
  }

  handleSearch() {
    $("#nodata").show()
    this.setState({ setActivePage: 1 });

    let dataAddress = $("#searchAddressText").val();

    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "getLocationList",
      search: (dataAddress = "" ? "" : dataAddress),
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      offset: "",
    };

    backendApi(req).then((data) => {
      for (let i = 0; i < data.data.length; i++) {
        $("#searchAddressvaluetext" + i).attr("hidden", true);
        $("#savebutton" + i).attr("hidden", true);
        $("#titleEditIconMatch" + i).attr("hidden", false);
        $("#searchCustomeraddress" + i).show();
        $("#titleEditIconMatch" + i).removeAttr("hidden");
      }
      if (data.success === "1") {
        this.setState({
          getLocationListData: data.data[0],
          setcount: data.data[1][0].count,
        });

        $(".loder_div").attr("hidden", true);
      } else {
        this.setState({
          getLocationListData: [],
          setcount: 0,
        });
        $(".loder_div").attr("hidden", true);
      }
    });
  }

  clearFilters() {
    $("#searchAddressText").val("");

    this.getLocationList();
        window.sessionStorage.setItem("offsetlocateIt",0)
        this.setState({ setActivePage: 1 });
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          <Header heading="Locate IT" headerImage={LiveMonitoringimage} />
          <div className="container-fluid LocateIt">
            <div className="row row-search">
              <div className="col-md-2 col-lg-2 col-sm-3 col-5">
              <div class="total_list">
              <span class="audit-txt">Total :</span>
              <span class="audit-txt-bld">{this.state.setcount}</span>
            </div>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-4 col-7">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Address"
                  id="searchAddressText"
                />
              </div>
              <div className="col-lg-6 col-md-5 col-sm-5">
                <button
                  className="btn-search"
                  id="txtFile"
                  type="button"
                  onClick={() => this.handleSearch()}
                >
                  Search
                </button>
                <button
                  id="txtFile"
                  type="button"
                  className="btn btn-clear-search fetchDataBtn"
                  onClick={() => this.clearFilters()}
                >
                  Clear Search
                </button>
              </div>
            </div>
            {this.state.setcount > 25 && (
              <div className="pagination_main">
                <Pagination
                  hideDisabled
                  activePage={this.state.setActivePage}
                  itemsCountPerPage={25}
                  totalItemsCount={this.state.setcount}
                  pageRangeDisplayed={10}
                  onChange={(e) => this.handlePageChange(e)}
                />
              </div>
            )}
            <div className="col-md-12 tableResponsive">
              <div className="tableOuterWidth ">
                <table className="table mb-0 table_width_lg2">
                  <thead>
                    <tr>
                      <th className="col-1 id_size">ID</th>
                      <th className="col-1">Date & Time</th>
                      <th className="col-1">Location Name</th>
                      <th className="col-1">Location Manager Name</th>
                      <th className="col-2">Location Manager Phone Number</th>
                      <th className="col-2">Google Address</th>
                      <th className="col-2">Latitude-Longitude</th>
                      <th className="col-2">Customer Address</th>
                    </tr>
                  </thead>
                  <span id="dataIdValue" hidden></span>
                  <div className="table_Outerscroll">
                    {this.state.getLocationListData.length > 0 ? (
                      <>
                        {this.state.getLocationListData.map((data, i) => (
                          <tbody className="table_Outertbody" key={i + 1}>
                            <tr>
                              <td className="col-1 id_size">{data.id}</td>
                              <td className="col-1">{(data.created_date)?moment(data.created_date).format("DD/MM/YYYY" ):""}<br></br>
                              {(data.created_date)?
                              (<span>({moment(data.created_date).format("hh:mm A" )})</span>):""}</td>
                              <td className="col-1">{data.owner_name?data.owner_name:""}</td>
                              <td className="col-1">{data.location_name?data.location_name:""}</td>
                              <td className="col-2">{data.owner_phone_no?data.owner_phone_no:""}</td>
                              <td className="col-2">{data.address?data.address:""}</td>
                              <td className="col-2">{data.latitude}-{data.longitude}</td>
                              <td className="col-2">
                                {/* {data.appUserEditedAddress} */}
                                <div className="searchbuttonDiv">
                                
                                  <p
                                    id={"searchCustomeraddress" + i}
                                    className="mb-0 succes_textdiv"
                                  >
                                    {data.appUserEditedAddress}
                                  </p>
                                  <textarea
                                    type="text"
                                    className="form-control searchEditInput"
                                    placeholder="Search"
                                    id={"searchAddressvaluetext" + i}
                                    hidden
                                  ></textarea>
                                  <span id={"savebutton" + i} hidden>
                                    <Icon.Save
                                      className="audit-save-icon"
                                      onClick={() =>
                                        this.saveforAddress(
                                          i,
                                          data.appUserEditedAddress,
                                          data.id
                                        )
                                      }
                                    />
                                  </span>
                                  <Icon.Edit
                                    id={"titleEditIconMatch" + i}
                                    className="audit-search-icon"
                                    onClick={() => {
                                      this.textAreachange(
                                        i,
                                        data.appUserEditedAddress
                                      );
                                      $("#searchAddressvaluetext" + i).val(
                                        data.appUserEditedAddress
                                      );
                                      $("#dataIdValue").text(data.id);
                                    }}
                                  />
                                </div>
                                <span
                                  className="success-msg"
                                  id={"saveSuccess" + data.id}
                                >
                                  Saved successfully
                                </span>
                                
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </>
                    ) : (
                    <p id="nodata">No Data Found</p>
                    )}
                  </div>
                </table>
              </div>
            </div>
            {this.state.setcount > 25 && (
              <div className="pagination_main">
                <Pagination
                  hideDisabled
                  activePage={this.state.setActivePage}
                  itemsCountPerPage={25}
                  totalItemsCount={this.state.setcount}
                  pageRangeDisplayed={10}
                  onChange={(e) => this.handlePageChange(e)}
                />
              </div>
            )}
          </div>
         
          <Footer/>
        </div>

        <div className="loder_div" hidden />
        <div
            className="module_popup editMatchAddress"
            id="editMatchAddress"
            hidden
          >
            <div className="module_innerpopup">
              <div className="module_close">
                <Icon.XSquare
                  color="red"
                  onClick={() => {
                    $("#editMatchAddress").attr("hidden", true);
                  }}
                />
              </div>
              <div className="module_body">
                <div className="form-group row">
                  <div className="col-sm-12">
                    <textarea
                      type="text"
                      className="form-control"
                      rows="5"
                      placeholder="Edit address"
                    />
                  </div>
                </div>
              </div>
              <div className="module_popupsearch">
                <button type="button" className="btn btn-primary">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    $("#editMatchAddress").attr("hidden", true);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
      </div>
    );
  }
}

export default LocateIt;
