import React, { Component } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import QandA from "../images/left_icons/qa.png";
import Logo from '../images/convergensee.png'
class Invoice extends Component {
    render() {
        return (
            <div className="main_wrapper">
                <LeftMenu />
                <div className="userListWrapper invoice-page">
                    <Header heading="Campaign" headerImage={QandA} />
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <div className="row gy-3 my-3">
                                        <div className="col-6">
                                            <h1 className="invoice-header">TAX INVOICE</h1>
                                        </div>
                                        <div className="col-6">
                                            <a className="d-block text-right" href="#!">
                                                <img src={Logo} className="img-fluid invoice-logo" alt="Logo" width="250" height="60" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <h4 className="invoice-header4">To,</h4>
                                        </div>
                                        <div className="col-12 col-md-4 col-sm-6">
                                            <address className="invoice-address">
                                                <p className="invoice_para invoice_para_bold">Mahindra & Mahindra Ltd</p>
                                                <p className="invoice_para">Automotive Division</p>
                                                <p className="invoice_para">Akurli Road, Kandivali East</p>
                                                <p className="invoice_para">Mumbai, Maharashtra</p>
                                                <p className="invoice_para">India - 400101</p>
                                                <p className="invoice_para"><span className="invoice_para_bold">GSTIN : </span>27AAACM3025E1ZZ</p>
                                                <p className="invoice_para"></p> Mail Approval dated 09.01.2023
                                            </address>
                                        </div>
                                        <div className="col-12 col-md-4 col-sm-6">
                                            <address className="invoice-address">
                                                <p className="invoice_para"><span>Invoice Date :</span>14. Feb. 2023</p>
                                                <p className="invoice_para"><span className="invoice_para_bold">Invoice Number : </span>CIMPL-194-22/23</p>
                                                <p className="invoice_para"><span className="invoice_para_bold">GSTIN : </span>27AAICC8260D1ZO</p>
                                                <p className="invoice_para"><span className="invoice_para_bold">Vendor Code : </span>CIMPL1ZO</p>
                                            </address>
                                        </div>
                                        <div className="col-12 col-md-4 col-sm-6">
                                            <address className="invoice-address">
                                                <p className="invoice_para">Hiranandani Business Park</p>
                                                <p className="invoice_para">Hiranandani LightHall</p>
                                                <p className="invoice_para"> A-507, Level 5</p>
                                                <p className="invoice_para">Saki Vihar Road, Chandivali</p>
                                                <p className="invoice_para">Mumbai , Maharashtra - 400072</p>
                                                <p className="invoice_para"><span className="invoice_para_bold">MSME - Udyam Reg No. : </span> UDYAM-MH-19-0117979</p>
                                            </address>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ minWidth: '200px' }} className="invoice_para_bold">Description</th>
                                                    <th colSpan="2" scope="col" className="text-right text-nowrap invoice_para_bold">Amount (in INR)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colSpan="3"><span className="invoice_para_bold">Personal Vehicle Segment - Mumbai AO</span></td>
                                                </tr>
                                                <tr>
                                                    <td>Social Media Content Creation & Management</td>
                                                    <td colSpan="2" className="text-right">₹1,08,000.00</td>
                                                </tr>
                                                <tr>
                                                    <td>Marketing Ad Spend</td>
                                                    <td colSpan="2" className="text-right">₹54,878.53</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3"><span className="invoice_para_bold">Commercial Vehicle Segment - Mumbai AO</span></td>
                                                </tr>
                                                <tr>
                                                    <td>Social Media Content Creation & Management</td>
                                                    <td colSpan="2" className="text-right">₹40,000.00</td>
                                                </tr>
                                                <tr>
                                                    <td>Marketing Ad Spend</td>
                                                    <td colSpan="2" className="text-right">₹39,889.56</td>
                                                </tr>
                                                <tr>
                                                    <td>SAC Code - 998311</td>
                                                    <td colSpan="2" className="text-right"></td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td className="invoice_tfoot_border"></td>
                                                    <td className="text-right"><span className="text-nowrap">Subtotal</span></td>
                                                    <td className="text-right"><span className="text-nowrap">₹2,42,768.09</span></td>
                                                </tr>
                                                <tr>
                                                    <td className="invoice_tfoot_border"></td>
                                                    <td className="text-right invoice_tfoot_border">
                                                        <span className="text-nowrap">GST Applicable</span>
                                                    </td>
                                                    <td className="text-right invoice_tfoot_border">
                                                        <span className="text-nowrap">18%</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="invoice_tfoot_border"></td>
                                                    <td className="text-right invoice_tfoot_border">
                                                        <span className="text-nowrap">SGST @ 9%</span>
                                                    </td>
                                                    <td className="text-right invoice_tfoot_border">
                                                        <span className="text-nowrap">21,849.13</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="invoice_tfoot_border"></td>
                                                    <td className="text-right invoice_tfoot_border">
                                                        <span className="text-nowrap">CGST @ 9%</span>
                                                    </td>
                                                    <td className="text-right invoice_tfoot_border">
                                                        <span className="text-nowrap">21,849.13</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="invoice_tfoot_border"></td>
                                                    <td className="text-right">
                                                        <span className="text-nowrap">GST Amount</span>
                                                    </td>
                                                    <td className="text-right">
                                                        <span className="text-nowrap">₹43,698.26</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="invoice_tfoot_border"></td>
                                                    <td className="text-right">
                                                        <span className="text-nowrap invoice_para_bold">TOTAL</span>

                                                    </td>
                                                    <td className="text-right">
                                                        <span className="text-nowrap invoice_para_bold">₹2,86,466.35</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="invoice_tfoot_border"></td>
                                                    <td className="text-right invoice_tfoot_border">
                                                        <span>Balance Receivable</span>
                                                    </td>
                                                    <td className="text-right invoice_tfoot_border">
                                                        <span className="text-nowrap invoice_para_bold">₹2,86,466.35</span>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                    <div className="invoice-content">
                                        <p className="invoice_para invoice_para_bold mb-4">Due Date : 21. Feb. 2023</p>
                                        <p className="invoice_para">Online Transfers to be routed to :</p>
                                        <p className="invoice_para">Account Name : ConvergenSEE India MarTech Private Limited</p>
                                        <p className="invoice_para">Bank Name : Kotak Bank Limited, Hiranandani Branch, Powai, Mumbai - 400076</p>
                                        <p className="invoice_para">Account Number : 3114209246</p>
                                        <p className="invoice_para">IFSC : KKBK0000663</p>
                                        <p className="invoice_para">PAN : AAICC8260D</p>
                                        <p className="invoice_para my-4">Make all cheques payable to : ConvergenSEE India MarTech Private Limited</p>
                                    </div>
                                    <div className="invoice_signature">
                                        <h6 className="invoice_para_bold invoice_header6">FOR ConvergenSEE India MarTech Private Limited</h6>
                                        <div className="inner_invoice_signature">
                                            <div className="left_invoice_signature">
                                                <h5 className="invoice_header5 invoice_para_bold">Viswanathan Kalyanasundaram</h5>
                                            </div>
                                            <div className="right_invoice_signature">
                                                <p className="invoice_para mb-0">Digitally signed by</p>
                                                <p className="invoice_para invoice_para_bold text-uppercase mb-0">Viswanathan Kalyanasundaram</p>
                                                <p className="invoice_para mb-0">Date: 2023.02.14</p>
                                                <p className="invoice_para mb-0">12:27:04 + 05'30'</p>
                                            </div>
                                        </div>
                                        <h6 className="invoice_para_bold invoice_header6">Authorised Signatory</h6>
                                    </div>
                                    <hr />
                                    <div className="invoice_year_table">
                                        <h3 className="invoice_header5 invoice_para_bold text-capitalize invoice_table_head ">Month 2023, January</h3>
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="invoice_para_bold">Row Labels</th>
                                                        <th scope="col" className="invoice_para_bold">Count of Customer Phone number</th>
                                                        <th scope="col" className="invoice_para_bold">Count of Enquiry</th>
                                                        <th scope="col" className="invoice_para_bold">Count of Booking</th>
                                                        <th scope="col" className="invoice_para_bold">Count of Retail</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Lucknow</td>
                                                        <td>5424</td>
                                                        <td>507</td>
                                                        <td>61</td>
                                                        <td>26</td>
                                                    </tr>
                                                    <tr>
                                                        <td>A Automovers</td>
                                                        <td>186</td>
                                                        <td>53</td>
                                                        <td>4</td>
                                                        <td>3</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Abhinandan Autozone</td>
                                                        <td>326</td>
                                                        <td>27</td>
                                                        <td>6</td>
                                                        <td>1</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td className="invoice_para_bold">Grand Total</td>
                                                        <td className="invoice_para_bold">5424</td>
                                                        <td className="invoice_para_bold">507</td>
                                                        <td className="invoice_para_bold">61</td>
                                                        <td className="invoice_para_bold">26</td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <h3 className="invoice_header5 invoice_para_bold text-capitalize invoice_table_head ">Mumbai AO PV Performance report - Jan 2023</h3>
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="invoice_para_bold">Area Office</th>
                                                        <th scope="col" className="invoice_para_bold">Dealer Name</th>
                                                        <th scope="col" className="invoice_para_bold">Location</th>
                                                        <th scope="col" className="invoice_para_bold">Segment</th>
                                                        <th scope="col" className="invoice_para_bold">Objective</th>
                                                        <th scope="col" className="invoice_para_bold">Vehicle</th>
                                                        <th scope="col" className="invoice_para_bold">Results</th>
                                                        <th scope="col" className="invoice_para_bold">Reach</th>
                                                        <th scope="col" className="invoice_para_bold">Impressions</th>
                                                        <th scope="col" className="invoice_para_bold">Cost per results</th>
                                                        <th scope="col" className="invoice_para_bold">Amount spent (INR)</th>
                                                        <th scope="col" className="invoice_para_bold">Link clicks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Mumbai</td>
                                                        <td>Aakash Autom</td>
                                                        <td>Dhule</td>
                                                        <td>PV</td>
                                                        <td>Lead Gen</td>
                                                        <td>Bolero</td>
                                                        <td>31</td>
                                                        <td>21,880</td>
                                                        <td>53,554</td>
                                                        <td>₹40</td>
                                                        <td>₹1,250</td>
                                                        <td>352</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mumbai</td>
                                                        <td>Aakash Autom</td>
                                                        <td>Dhule</td>
                                                        <td>PV</td>
                                                        <td>Lead Gen</td>
                                                        <td>XUV300</td>
                                                        <td>8</td>
                                                        <td>11,712</td>
                                                        <td>27,643</td>
                                                        <td>₹156</td>
                                                        <td>₹1,250</td>
                                                        <td>295</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mumbai</td>
                                                        <td>Bhavin Whee</td>
                                                        <td>Nashik</td>
                                                        <td>PV</td>
                                                        <td>Lead Gen</td>
                                                        <td>Bolero</td>
                                                        <td>68</td>
                                                        <td>43,984</td>
                                                        <td>1,01,399</td>
                                                        <td>₹29</td>
                                                        <td>₹1,999</td>
                                                        <td>706</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan="9" class="invoice_tfoot_border"></td>
                                                        <td><span className="invoice_para_bold text-nowrap">TOTAL</span></td>
                                                        <td  colSpan="2"><span className="invoice_para_bold text-nowrap invoice_tfoot_border">₹54,879</span></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <h3 className="invoice_header5 invoice_para_bold text-capitalize invoice_table_head ">Mumbai AO CV Performance report - Jan 2023</h3>
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="invoice_para_bold">Area Office</th>
                                                        <th scope="col" className="invoice_para_bold">Dealer Name</th>
                                                        <th scope="col" className="invoice_para_bold">Location</th>
                                                        <th scope="col" className="invoice_para_bold">Segment</th>
                                                        <th scope="col" className="invoice_para_bold">Objective</th>
                                                        <th scope="col" className="invoice_para_bold">Vehicle</th>
                                                        <th scope="col" className="invoice_para_bold">Results</th>
                                                        <th scope="col" className="invoice_para_bold">Reach</th>
                                                        <th scope="col" className="invoice_para_bold">Impressions</th>
                                                        <th scope="col" className="invoice_para_bold">Cost per results</th>
                                                        <th scope="col" className="invoice_para_bold">Amount spent (INR)</th>
                                                        <th scope="col" className="invoice_para_bold">Link clicks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Mumbai</td>
                                                        <td>Aakash Autom</td>
                                                        <td>Dhule</td>
                                                        <td>CV</td>
                                                        <td>Lead Gen</td>
                                                        <td>MaXX Pik-Up</td>
                                                        <td>44</td>
                                                        <td>60,448</td>
                                                        <td>1,18,882</td>
                                                        <td>₹68</td>
                                                        <td>₹3,000</td>
                                                        <td>817</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mumbai</td>
                                                        <td>Bhavin Whee</td>
                                                        <td>Nashik</td>
                                                        <td>CV</td>
                                                        <td>Lead Gen</td>
                                                        <td>MaXX Pik-Up</td>
                                                        <td>83</td>
                                                        <td>67,855</td>
                                                        <td>1,32,966</td>
                                                        <td>₹36</td>
                                                        <td>₹3,000</td>
                                                        <td>748</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mumbai</td>
                                                        <td>Bhavna Autom</td>
                                                        <td>obiles Nerul</td>
                                                        <td>CV</td>
                                                        <td>Lead Gen</td>
                                                        <td>MaXX Pik-Up</td>
                                                        <td>48</td>
                                                        <td>71,503</td>
                                                        <td>1,21,724</td>
                                                        <td>₹83</td>
                                                        <td>₹4,000</td>
                                                        <td>570</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan="9" class="invoice_tfoot_border"></td>
                                                        <td><span className="invoice_para_bold text-nowrap">TOTAL</span></td>
                                                        <td  colSpan="2"><span className="invoice_para_bold text-nowrap invoice_tfoot_border">₹39,890</span></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div className="table-responsive mt-5">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="invoice_para_bold">S.No</th>
                                                        <th scope="col" className="invoice_para_bold">Dealership Name</th>
                                                        <th scope="col" className="invoice_para_bold">Location</th>
                                                        <th scope="col" className="invoice_para_bold">Area Office</th>
                                                        <th scope="col" className="invoice_para_bold">Segment</th>
                                                        <th scope="col" className="invoice_para_bold"> ConvergenSEE Platform</th>
                                                        <th scope="col" className="invoice_para_bold">Social Media Creation and Management</th>
                                                        <th scope="col" className="invoice_para_bold">Media Ad Spend (Budgeted)</th>
                                                        <th scope="col" className="invoice_para_bold"> Media Ad Spend (Actuals)</th>
                                                        <th scope="col" className="invoice_para_bold">Total amount</th>
                                                        <th scope="col" className="invoice_para_bold">Dealer Share</th>
                                                        <th scope="col" className="invoice_para_bold">Dealer Share with GST</th>
                                                        <th scope="col" className="invoice_para_bold">AO Share</th>
                                                        <th scope="col" className="invoice_para_bold">AO Share with GST</th>
                                                        <th scope="col" className="invoice_para_bold">Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>428</td>
                                                        <td>Aakash Automotive</td>
                                                        <td>Dhule</td>
                                                        <td>CV</td>
                                                        <td>Mumbai AO</td>
                                                        <td>Commercial Vehicle</td>
                                                        <td>₹1,050.00</td>
                                                        <td>₹3,000.00</td>
                                                        <td>₹3,000.00</td>
                                                        <td> ₹2,999.68</td>
                                                        <td>₹7,049.68</td>
                                                        <td>₹1,050.00</td>
                                                        <td>₹1,239.00</td>
                                                        <td>₹5,999.68</td>
                                                        <td>₹7,079.62</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan="5" class="invoice_tfoot_border"></td>
                                                        <td><span className="invoice_para_bold text-nowrap">₹30,000.00</span></td>
                                                        <td><span className="invoice_para_bold text-nowrap">₹1,07,999.99</span></td>
                                                        <td><span className="invoice_para_bold text-nowrap">₹54,900.00</span></td>
                                                        <td><span className="invoice_para_bold text-nowrap">  ₹54,878.53</span></td>
                                                        <td><span className="invoice_para_bold text-nowrap">  ₹1,92,878.52</span></td>
                                                        <td><span className="invoice_para_bold text-nowrap">₹30,000.00</span></td>
                                                        <td><span className="invoice_para_bold text-nowrap">₹35,400.00</span></td>
                                                        <td><span className="invoice_para_bold text-nowrap"> ₹1,62,878.52</span></td>
                                                        <td><span className="invoice_para_bold text-nowrap">  ₹1,92,196.65</span></td>
                                                        <td><span className="invoice_para_bold text-nowrap"></span></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <h3 class="invoice_header5 invoice_para_bold text-capitalize invoice_table_head ">Personal Vehicle</h3>
                                        <div className="col-md-5">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="invoice_para_bold">PERSONAL VEHICLE</th>
                                                        <th scope="col" className="invoice_para_bold">Current Media Ad Spend</th>
                                                        <th scope="col" className="invoice_para_bold">New Media Ad Spend</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Aakash Automotive</td>
                                                        <td>3200</td>
                                                        <td>2500</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Bhavin Wheels</td>
                                                        <td>3200</td>
                                                        <td>4000</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Bhavna Automobiles</td>
                                                        <td>3200</td>
                                                        <td>4000</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="loder_div" hidden />
            </div>
        );
    }
}

export default Invoice;
