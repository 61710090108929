import React, { Component } from "react";
import "../css/bootstrap.css";
import LiveMonitoringimage from "../images/left_icons/Google-Chat.png";
import Pagination from "react-js-pagination";
import "../css/style.css";
import Multiselect from "multiselect-react-dropdown";
import { Link } from "react-router-dom";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AWS from "aws-sdk";
import LeftMenu from "../layout/leftMenu.jsx";
import { apiCallingPost, backendApi } from "../apiService";
import $ from "jquery";
import * as Icon from "react-feather";
const s3ImageUrl =
  "http://tagtree.s3-website.ap-south-1.amazonaws.com/production/images";

var s3Region;
var s3bucketAccessKey;
var s3bucketSecretKey;
var s3Bucket;
var s3BucketMainFolder;
var S3BucketImagesFolder = "images";
var bucketParams;
var s3;
const timeList = [];

for (let hour = 0; hour < 24; hour++) {
  for (let minute = 0; minute < 60; minute += 30) {
    const time = `${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}`;
    timeList.push(time);
  }
}
var dataflag = true;
let optionsarray = [];
class ChatForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listSupport: [],
      username: "",
      resolvedOn: "",
      issues: "",
      status: "",
      Id: "",
      reply: "",
      activeLink: null,
      convertedText: "",
      commentList: [],
      dropdownList: [],
      selectedValue: [],
      IdforEdit: "",
      userList: [],
      startTime: "",
      endTime: "",
      listAgentsarray: [],
      optionvalues: [],
      managerid: [],
      imageUrlListStatus: 0,
      imageUrl: [],
      selectedvaluesforEdit: [],
      imageName: "",
      totalCount: 0,
      currentPage: 1,
      claimList: [],
    };
    this.handleImageUpload = this.handleImageUpload.bind(this);
  }

  componentDidMount() {
    console.log("dataflag", dataflag);
    this.listAgents();
    $("#chatModal").attr("hidden", true);
    this.getS3Credentials();
    if (window.sessionStorage.getItem("role") === "ROLE_USER") {
      $("#roleSelector").attr("hidden", true);
    } else if (window.sessionStorage.getItem("role") !== "ROLE_SUPER_ADMIN") {
      $("#roleSelector").attr("hidden", false);
      this.getUserList("");
    }
    // else {
    //   $("#roleSelector").attr("hidden", true);
    // }
    if (window.sessionStorage.getItem("switchingOrgId") !== "") {
      this.getUserList(window.sessionStorage.getItem("switchingOrgId"));
    }
    if (window.sessionStorage.getItem("switchUserId") !== "") {
      this.getUserList(window.sessionStorage.getItem("switchingOrgId"));
      $("#roleSelector").attr("hidden", false);
      $("#goBackSelector").attr("hidden", false);
    }
  }
  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  getS3Credentials = () => {
    const credentialReq = {
      functionName: "s3Credentials",
    };
    apiCallingPost(credentialReq).then((data) => {
      s3Region = "us-east-1";
      s3bucketAccessKey = process.env.REACT_APP_TAGTREE_S3_ACCESS_KEY;
      s3bucketSecretKey = process.env.REACT_APP_TAGTREE_S3_SECRET_KEY;
      s3Bucket = "tagtree";
      s3BucketMainFolder = "production";
      S3BucketImagesFolder = "images";
      bucketParams = {
        Bucket: s3Bucket,
      };
      AWS.config.update({
        accessKeyId: s3bucketAccessKey,
        secretAccessKey: s3bucketSecretKey,
        region: s3Region,
      });
      s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: bucketParams,
      });
      console.log("s3", s3);
    });
  };
  async handleImageUpload(image) {
    let s3BucketMainFolder1 = s3BucketMainFolder + "/" + S3BucketImagesFolder;
    var ttalCount = image.length;
    console.log("datatta", image);
    for (let i = 0; i < image.length; i++) {
      console.log("imageimage", image[i]);
      if (image[i]) {
        $(".loder_div").attr("hidden", false);
        let fileName = this.uuidv4() + image[i].name;
        let fullFilePath = s3BucketMainFolder1 + "/" + fileName;
        s3.upload(
          {
            Key: fullFilePath,
            Body: image[i],
            ACL: "public-read",
          },
          (err, data) => {
            console.log("voooooooooooo", data);
            if (err) {
              alert("There was an error uploading your image");
              $(".loder_div").attr("hidden", true);
            } else if (data) {
              this.state.imageUrl.splice(0, this.state.imageUrl.length);
              this.state.imageUrl.push(data.Location);
              this.setState({
                imageUrlListStatus: i + 1,
              });
              ttalCount = ttalCount - 1;
              if (ttalCount === 0) $(".loder_div").attr("hidden", true);
            }
          }
        );
      } else {
        ttalCount = ttalCount - 1;
        console.log("voooooooooooo", ttalCount);
        alert("Files Size must be less than 5MB or greater than 10KB");
        $(".loder_div").attr("hidden", true);
        this.multipartUpload(image[i]);
      }
    }
  }
  validatePreviewParams = () => {
    // this.handleImageUpload();
    this.createdAgent();
  };
  getUserList = (e) => {
    var req = {
      functionName: "listUserByOrganization",
      siteId: e === "" ? window.sessionStorage.getItem("organizationId") : e,
    };
    // $(".loder_div").attr("hidden", false);
    apiCallingPost(req).then((data) => {
      if (data.success === "1") {
        // $(".loder_div").attr("hidden", true);

        let array = data.result.filter(
          (item) =>
            item.role == "ROLE_ADMIN" ||
            item.role == "ORG_ADMIN" ||
            item.role == "ROLE_SUPER_ADMIN" ||
            item.role == "CHAT_MANAGER"
        );

        let finalarray = array.map((item) => ({
          name: item.first_name,
          id: item.id,
        }));
        this.setState({
          dropdownList: finalarray,
        });
      } else {
        // $(".loder_div").attr("hidden", true);
      }
    });
  };

  createdAgent() {
    var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    const phoneRegex = /^\d{10}$/;
    var flag = true;

    let arr = optionsarray.map((obj) => obj.id);
    let arr2 = this.state.selectedValue.map((obj) => obj.id);

    $(".loder_div").attr("hidden", false);
    if ($("#agent_code").val() == "") {
      alert("Agent Code Required");
      $("#agent_code").focus();
      flag = false;
    } else if ($("#agent_name").val() == "") {
      flag = false;
      alert("Agent Name  Required");
      $("#agent_name").focus();
    } else if ($("#agent_emailid").val() == "") {
      flag = false;
      alert("Agent E-mail Id Required");
      $("#agent_emailid").focus();
    } else if (!pattern.test($("#agent_emailid").val())) {
      alert("Not a Valid E-mail Address");
      $("#agent_emailid").focus();
      flag = false;
    } else if ($("#agent_mobileno").val() == "") {
      flag = false;
      $("#agent_mobileno").focus();
      alert("Agent Mobile Number Required");
    } else if (!phoneRegex.test($("#agent_mobileno").val())) {
      flag = false;
      $("#agent_mobileno").focus();
      alert("Not a Valid Phone Number");
    } else if (this.state.startTime == "") {
      flag = false;
      alert("Start Time Required");
      $("#startTime").focus();
    } else if (this.state.endTime == "") {
      $("#endTime").focus();
      flag = false;
      alert("End Time is Required");
    }

    // else if(dataflag == true && arr.length ==0){
    //         alert("Please select atleast one manager arr");
    //         flag = false
    // }
    else if (dataflag == false && arr.length == 0) {
      alert("Please Select Aleast One Manager");
      flag = false;
    } else if (dataflag == false && arr.length != 0) {
      flag = true;
    } else if (dataflag == false && arr2.length != 0) {
      flag = true;
    } else if (arr2.length == 0) {
      alert("Please Select Atleast One Manager");
      flag = false;
    }
    console.log("arr", arr);
    console.log("arr2", arr2);
    console.log("dataflag22", dataflag);

    // else if (arr2.length == 0) {
    //   alert("Please select atleast one manager2");
    //   flag = false;

    // }

    // else if (arr.length == 0 && arr2.length != 0) {
    //   alert("Please select atleast one manager2");
    //   flag = false;
    // }
    // else if (this.state.managerid.length != 0 && arr2.length == 0) {
    //   alert("Please select atleast one manager");
    //   flag = false;
    // }

    // function localhost(postData) {
    //   return axios
    //     .post("http://localhost:8080/createAgentDetails", postData)
    //     .then((data) => data.data)
    //     .then((res) => res);
    // }

    if (flag == true) {
      const reviewAPIData = {
        functionName: "createAgentDetails",
        id: this.state.managerid.length == 0 ? "" : this.state.managerid,
        agent_name: $("#agent_name").val(),
        agent_emailid: $("#agent_emailid").val(),
        agent_mobileno: $("#agent_mobileno").val(),
        created_by: window.sessionStorage.getItem("userId"),
        start_time: this.state.startTime,
        end_time: this.state.endTime,
        agent_images: this.state.imageUrl[0],
        ORGANIZATIONNAME: window.sessionStorage.getItem("organization"),
        site_id:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        agent_code: $("#agent_code").val(),
        chat_agent_manager_id: arr.length == 0 ? arr2 : arr,
      };
      console.log("reqq", reviewAPIData);
      backendApi(reviewAPIData).then((data) => {
        console.log("createAgent", data);
        if (data.success === "1") {
          $(".loder_div").attr("hidden", true);
          $("#chatModal").attr("hidden", true);
          this.listAgents();
          this.setState({
            selectedValue: [],
          });
        } else {
          $(".loder_div").attr("hidden", true);
          alert("Failed");
        }
      });
    }
  }

  onSelect(selectedList, selectedItem) {
    // this.setState({
    //   selectedValue:selectedList,
    //   dropdownList:selectedList
    // })
    optionsarray = selectedList;
    dataflag = false;
    function localhost(postData) {
      return axios
        .post("http://localhost:8080/managerAdd", postData)
        .then((data) => data.data)
        .then((res) => res);
    }

    const reviewAPIData = {
      functionName: "managerAdd",

      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      created_by: window.sessionStorage.getItem("userId"),
      chat_agent_id: $("#managerid").text(),
      chat_agent_manager_id: selectedItem.id,
    };
    $(".loder_div").attr("hidden", false);
    localhost(reviewAPIData).then((data) => {
      if (data.response.success === "1") {
        $(".loder_div").attr("hidden", true);
        this.listAgents();
      } else {
        $(".loder_div").attr("hidden", true);
        alert("Failed");
      }
    });
  }
  onRemove(selectedList, selectedItem) {
    optionsarray = selectedList;
    dataflag = false;
    // this.setState({
    //   selectedValue:selectedList,
    //   dropdownList:selectedList
    // })
    function localhost(postData) {
      return axios
        .post("http://localhost:8080/deleteManagerId", postData)
        .then((data) => data.data)
        .then((res) => res);
    }

    const reviewAPIData = {
      functionName: "deleteManagerId",
      id: $("#managerid").text(),
      chat_agent_manager_id: selectedItem.id,
    };
    $(".loder_div").attr("hidden", false);
    localhost(reviewAPIData).then((data) => {
      if (data.response.success === "1") {
        $(".loder_div").attr("hidden", true);
        this.listAgents();
      } else {
        $(".loder_div").attr("hidden", true);
        alert("Failed");
      }
    });
  }
  deleteManger(id) {
    if (!window.confirm("Do you want to delete the Agent?")) {
      return false;
    }
    // function localhost(postData) {
    //   return axios
    //     .post("http://localhost:8080/deleteAgents", postData)
    //     .then((data) => data.data)
    //     .then((res) => res);
    // }

    const reviewAPIData = {
      functionName: "deleteAgent",
      id: id,
    };
    $(".loder_div").attr("hidden", false);
    backendApi(reviewAPIData).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        this.listAgents();
      } else {
        $(".loder_div").attr("hidden", true);
        alert("Failed");
      }
    });
  }

  listAgents() {
    // function localhost(postData) {
    //   return axios
    //     .post("http://localhost:8080/listAgents", postData)
    //     .then((data) => data.data)
    //     .then((res) => res);
    // }

    const reviewAPIData = {
      functionName: "listAgents",

      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };

    backendApi(reviewAPIData).then((data) => {
      console.log("list", data);
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);

        // const uniqueObjects = {};
        // const newArray = [];

        // for (const obj of data.result) {
        //   const key = JSON.stringify(obj);
        //   if (!uniqueObjects[key]) {
        //     newArray.push(obj);
        //     uniqueObjects[key] = true;
        //   }
        // }
        // const result = Object.values(
        //   newArray.reduce((acc, curr) => {
        //     if (acc[curr.chat_agent_id]) {
        //       if (
        //         !acc[curr.chat_agent_id].first_name.includes(curr.first_name)
        //       ) {
        //         acc[curr.chat_agent_id].first_name.push(curr.first_name);
        //       }
        //       if (!acc[curr.chat_agent_id].user_id.includes(curr.user_id)) {
        //         acc[curr.chat_agent_id].user_id.push(curr.user_id);
        //       }
        //     } else {
        //       acc[curr.chat_agent_id] = {
        //         ...curr,
        //         first_name: [curr.first_name],
        //         user_id: [curr.user_id],
        //       };
        //     }
        //     return acc;
        //   }, {})
        // );

        // let resultarray = result.reverse();

        // const indexOfLastPost = this.state.currentPage * 25;
        // const indexOfFirstPost = indexOfLastPost - 25;
        // const currentPosts = resultarray.slice(
        //   indexOfFirstPost,
        //   indexOfLastPost
        // );
        this.setState({
          totalCount: data.result.length,
          listAgentsarray: data.result,
          claimList: data.result,
        });
      } else {
        $(".loder_div").attr("hidden", true);
        alert("Failed");
        this.setState({
          totalCount: 0,
          listAgentsarray: [],
        });
      }
    });
  }
  handleActivePage = (e) => {
    const indexOfLastPost = e * 25;
    const indexOfFirstPost = indexOfLastPost - 25;
    let tempList = this.state.claimList;
    const currentPosts = tempList.slice(indexOfFirstPost, indexOfLastPost);

    this.setState({
      currentPage: e,
      listAgentsarray: currentPosts,
      claimList: tempList,
    });
  };
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper ">
          <Header heading="Chat Form" headerImage={LiveMonitoringimage} />

          <div className="container-fluid">
            <div className="tag_chat_btn">
              <button
                type="button"
                className="btn-addNew"
                onClick={() => {
                  $("#chatModal").attr("hidden", false);
                  this.setState({
                    startTime: "",
                    endTime: "",
                    selectedValue: [],
                    imageUrl: [],
                    managerid: "",
                  });
                  $("#chatModal").attr("hidden", false);

                  $("#agent_name").val("");
                  $("#agent_code").val("");
                  $("#agent_mobileno").val("");
                  $("#agent_emailid").val("");
                }}
              >
                New Chat{" "}
                <span>
                  <Icon.PlusCircle />{" "}
                </span>
              </button>
            </div>
            <div
              className="module_popup tag_chat chatModal "
              id="chatModal"
              hidden
            >
              <div className="module_innerpopup">
                <div className="module_close">
                  <Icon.XCircle
                    onClick={() => {
                      $("#chatModal").attr("hidden", true);
                      this.setState({
                        startTime: "",
                        endTime: "",
                        selectedValue: [],
                      });

                      $("#agent_name").val("");
                      $("#agent_code").val("");
                      $("#agent_mobileno").val("");
                      $("#agent_emailid").val("");
                    }}
                  />
                </div>

                <div className="module_body">
                  <h5 className="mainHead_popup">
                    {this.state.managerid != "" ? "Update Agent" : "Add Agent"}
                  </h5>
                  <div className="row">
                    <div class="col-lg-6 col-md-6    tag_formLabel">
                      <label class="m-0">Agent Code</label>
                      <input type="text" class="form-control" id="agent_code" />
                    </div>
                    <div class="col-lg-6 col-md-6   tag_formLabel">
                      <label class="m-0">Agent Name</label>
                      <input type="text" class="form-control" id="agent_name" />
                    </div>
                    <div class="col-lg-6 col-md-6   tag_formLabel">
                      <label class="m-0">Agent Emailid </label>
                      <input
                        type="text"
                        class="form-control"
                        id="agent_emailid"
                      />
                    </div>
                    <div class="col-lg-6 col-md-6   tag_formLabel">
                      <label class="m-0">Agent Mobile number</label>
                      <input
                        type="text"
                        class="form-control"
                        id="agent_mobileno"
                      />
                    </div>

                    <div className="col-lg-12 col-md-12  tag_formLabel   ">
                      <label>Agent Photo</label>
                      <div className="">
                        <div className="info_contentlocation">
                          <div className="right_google_popup">
                            <label>
                              <Icon.Camera />
                              Add photo
                              <input
                                type="file"
                                id="imageUpload"
                                onChange={(e) => {
                                  this.handleImageUpload(e.target.files);
                                  e.target.value = "";
                                }}
                              />
                            </label>
                          </div>
                        </div>
                        {/* ===========  UPLOADED IMAGE PREVIEW BEGINS HERE =========== */}

                        {this.state.imageUrl[0] !== "undefined" &&
                          this.state.imageUrl[0] !== undefined && (
                            <div className="tag_chatWraper">
                              <div className="row">
                                <>
                                  <img src={this.state.imageUrl[0]} />
                                  <span className="close_popup_imageUpload">
                                    <Icon.X
                                      onClick={() => {
                                        let items = [this.state.imageUrl];
                                        items.splice(0, 1);
                                        this.setState({ imageUrl: items });
                                      }}
                                    />
                                  </span>
                                </>
                              </div>
                            </div>
                          )}

                        {/* ===========  UPLOADED IMAGE PREVIEW ENDS HERE =========== */}
                        {/*  */}
                      </div>
                    </div>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-6 col-md-6   tag_formLabel">
                          <label>Start Time</label>

                          <select
                            name="startTime"
                            id="startTime"
                            value={this.state.startTime}
                            className="form-control"
                            onChange={(e) =>
                              this.setState({ startTime: e.target.value })
                            }
                          >
                            <option value="">Select</option>
                            {timeList.map((data) => (
                              <option value={data}>{data}</option>
                            ))}
                          </select>
                        </div>
                        <div className="col-lg-6 col-md-6  tag_formLabel">
                          <label>End Time</label>
                          <select
                            name="endTime"
                            id="endTime"
                            value={this.state.endTime}
                            className="form-control"
                            onChange={(e) =>
                              this.setState({ endTime: e.target.value })
                            }
                          >
                            <option value="">Select</option>
                            {timeList.map((data) => (
                              <option value={data}>{data}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-lg-12 col-md-12  tag_formLabel">
                    <label>BOT Message</label>
                    <textarea className="form-control" />
                  </div> */}
                    <div className="col-lg-6 col-md-6  tag_formLabel">
                      <label>Manager</label>
                      <Multiselect
                        placeholder="Select Manager"
                        options={this.state.dropdownList} // Options to display in the dropdown
                        selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                        onSelect={this.onSelect} // Function will trigger on select event
                        onRemove={this.onRemove} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                        customCloseIcon={<Icon.X />}
                      />
                    </div>
                  </div>
                  <div class="button-wrapper">
                    <button
                      type="button"
                      class="btn-submit"
                      onClick={() => this.validatePreviewParams()}
                    >
                      {this.state.managerid != "" ? "Update" : "Save"}
                    </button>
                    <button
                      type="button"
                      class="btn-cancel"
                      onClick={() => {
                        $("#chatModal").attr("hidden", true);
                        this.setState({
                          startTime: "",
                          endTime: "",
                          selectedValue: [],
                          imageUrl: [],
                        });

                        $("#agent_name").val("");
                        $("#agent_code").val("");
                        $("#agent_mobileno").val("");
                        $("#agent_emailid").val("");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                {/* <div className="module_popupsearch">
                  <button type="button" className="btn btn-primary">
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      $("#chatModal").attr("hidden", true);
                    }}
                  >
                    Cancel
                  </button>
                </div> */}
              </div>
            </div>
            <div className="col-md-12 tableResponsive">
              {this.state.totalCount > 25 && (
                <div className="pagination_main">
                  <Pagination
                    hideDisabled
                    activePage={this.state.currentPage}
                    itemsCountPerPage={25}
                    totalItemsCount={this.state.totalCount}
                    pageRangeDisplayed={10}
                    onChange={(e) => this.handleActivePage(e)}
                  />
                </div>
              )}
              <div className="tableOuterWidth table_width_lg">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="col-1">ID</th>
                      <th className="col-1">Name</th>
                      <th className="col-1">Code</th>
                      <th className="col-2">Email</th>
                      <th className="col-2">Mobile Number</th>
                      <th className="col-1">Agent Photo </th>
                      <th className="col-1">Work time</th>
                      <th className="col-2">Manager</th>
                      <th className="col-2">Action</th>
                    </tr>
                  </thead>
                  <div className="table_Outerscroll">
                    {console.log("agentsss", this.state.listAgentsarray)}
                    {this.state.listAgentsarray.map((item) => (
                      <tbody className="table_Outertbody">
                        <tr>
                          <td className="col-1">{item.id}</td>
                          <td className="col-1">{item.first_name}</td>
                          <td className="col-1">{item.agent_code}</td>
                          <td className="col-2">{item.email_id}</td>
                          <td className="col-2">{item.mobile_no}</td>

                          <td className="col-1">
                            {item.agent_images == "undefined" ? (
                              <span></span>
                            ) : (
                              <img
                                className="img-fluid"
                                src={item.agent_images}
                              />
                            )}
                          </td>

                          <td className="col-1">
                            {item.start_time} to {item.end_time}
                          </td>
                          {/* <td className="col-3">BOT Message</td> */}
                          <td className="col-2">{item.first_name + ""}</td>
                          {/* <td className="col-1">
                            <div className="process_dflex_right">
                              <Link
                                to={"/historyLocation/" + item.chat_agent_id}
                                // state={{
                                //   tableId: data.id,
                                //   status: data.status,
                                // }}
                                id="linkId"
                                title=" Show locations"
                                className="linkButton mr-0"
                              >
                                <Icon.Eye />
                              </Link>
                            </div>
                          </td> */}
                          <td className="col-3">
                            {" "}
                            <Link
                              to={"/historyLocation/" + item.chat_agent_id}
                              // state={{
                              //   tableId: data.id,
                              //   status: data.status,
                              // }}
                              id="linkId"
                              title=" Show locations"
                              className="linkButton mr-1"
                            >
                              <Icon.Eye />
                            </Link>
                            <Icon.Edit
                              className="mr-1"
                              onClick={() => {
                                // const names = item.first_name;
                                // const ids = item.user_id;
                                // const array = [];
                                // for (let i = 0; i < names.length; i++) {
                                //   array.push({
                                //     name: names[i],
                                //     id: ids[i],
                                //   });
                                // }
                                // this.setState({
                                //   startTime: item.start_time,
                                //   endTime: item.end_time,
                                //   selectedValue: array,
                                //   managerid: item.chat_agent_id,
                                //   imageUrl: [item.agent_images],
                                // });
                                $("#chatModal").attr("hidden", false);

                                $("#agent_name").val(item.first_name);
                                $("#agent_code").val(item.agent_code);
                                $("#agent_mobileno").val(item.mobile_no);
                                $("#agent_emailid").val(item.email_id);
                                $("#startTime").val(item.start_time);
                                $("#endTime").val(item.end_time);
                              }}
                            />
                            <Icon.Trash
                              style={{ color: "red" }}
                              onClick={() =>
                                this.deleteManger(item.chat_agent_id)
                              }
                            />
                          </td>
                          {/* <td className="col-1">
                            {" "}
                            <Icon.Trash
                              style={{ color: "red" }}
                              onClick={() =>
                                this.deleteManger(item.chat_agent_id)
                              }
                            />
                          </td> */}
                        </tr>
                      </tbody>
                    ))}
                  </div>
                  <div id="managerid" hidden>
                    {this.state.managerid}
                  </div>
                </table>
              </div>
            </div>
          </div>
          <div
            className="module_popup editMatchAddress"
            id="editMatchAddress"
            hidden
          >
            <div className="module_innerpopup">
              <div className="module_close">
                <Icon.XCircle
                  color="red"
                  onClick={() => {
                    $("#editMatchAddress").attr("hidden", true);
                  }}
                />
              </div>

              <div className="module_body">
                {/* <h5 className="modal-head text-center">
                  Heading
                </h5> */}
                <div className="form-group row">
                  {/* <label for="" className="col-sm-2 col-form-label">
                    Email
                  </label> */}
                  <div className="col-sm-12">
                    <textarea
                      type="text"
                      className="form-control"
                      rows="5"
                      placeholder="Edit address"
                    />
                  </div>
                </div>
              </div>
              <div className="module_popupsearch">
                <button type="button" className="btn btn-primary">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    $("#editMatchAddress").attr("hidden", true);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}

export default ChatForm;
