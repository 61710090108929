import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import exportFromJSON from "export-from-json";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import { backendApi, pythonBackendApi } from "../apiService";
import Pagination from "react-js-pagination";
import Header from "../layout/header";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu";
import Fraudimage from "../images/left_icons/fraud.png";
import $ from "jquery";
import dataProcessImg from "../images/dataProcess.gif";
import ScaleLoader from "react-spinners/ScaleLoader";
import DisplaySecondGoogleData from "./DisplaySecondGoogleData";
const FraudDetails = () => {
  const location = useLocation();
  let pathname = location.pathname;
  const tableId = pathname.split("/")[2];
  const [excelData, setExcelData] = useState([]);
  const [googleData, setGoogleData] = useState([]);
  const [secondGoogleData, setSecondGoogleData] = useState([]);
  const [count, setcount] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [inQueue, setInQueue] = useState(0);
  const [totalCompleted, setTotalCompleted] = useState(0);
  const [totalFailed, setTotalFailed] = useState(0);
  const [progress, setprogress] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [fraudDetectionStatus, setFraudDetectionStatus] = useState("");
  useEffect(() => {
    getAuditExcelDataByMasterId();
    setActivePage(Number(window.sessionStorage.getItem("pageNumberfraud")));
    $("#reviewsTable").attr("hidden", true);
    $("#postsTable").attr("hidden", true);
  }, []);
  useEffect(() => {
    if (excelData.length > 0) {
      const fraudStatus = new Array(
        ...new Set(excelData.map((data) => data.process_status))
      );
      if (fraudStatus.includes("Started")) {
        setFraudDetectionStatus("Started");
      } else if (
        !fraudStatus.includes("Started") &&
        fraudStatus.includes("Completed")
      ) {
        setFraudDetectionStatus("Completed");
      } else {
        setFraudDetectionStatus("");
      }
    }
  }, [excelData]);
  const getAuditExcelDataByMasterId = () => {
    setLoading(true);
    $(".loder_div").attr("hidden", false);
    const req = {
      functionName: "getPrimaryGoogleDataByMasterId",
      masterId: tableId,
      offset: window.sessionStorage.getItem("offsetfraud"),
      search: "",
      startId: "",
      endId: "",
    };
    setGoogleData([]);
    setSecondGoogleData([]);
    backendApi(req).then((result) => {
      if (result.success != "1") {
        setLoading(false);
        $(".loder_div").attr("hidden", true);
        setExcelData([]);
        setTotalCount([]);
        return;
      }
      for (let i = 0; i < result.data?.length; i++) {
        $("#radiotf" + i).prop("checked", false);
      }
      const [statusInQueue, statusCompleted, statusFailed, progress] =
        result.statusFraud;
      setTotalCompleted(statusCompleted);
      setInQueue(statusInQueue);
      setTotalFailed(statusFailed);
      setprogress(progress);
      setExcelData(result.data);
      setTotalCount(result.totalDataReviews);
      setcount(result.totalCnt[0].totalcnt);
      $(".loder_div").attr("hidden", true);
      if (result.totalCompletedReviews !== result.totalDataReviews) {
        $(".dataProcessImg").show();
      } else {
        $(".dataProcessImg").hide();
      }
    });
  };
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    let offset;
    let fromdata = $("#fromData").val();
    let todata = $("#toData").val();
    if (pageNumber === "1") {
      offset = "0";
    } else {
      offset = parseInt(pageNumber - 1) * 25;
    }
    setActivePage(pageNumber);
    setPageOffset(offset);
    let PostData = {
      functionName: "getPrimaryGoogleDataByMasterId",
      masterId: tableId,
      offset: offset.toString(),
      search:
        $("#searchAddress").val() === " " ? "" : $("#searchAddress").val(),
      startId: fromdata,
      endId: todata,
    };
    window.sessionStorage.setItem("pageNumberfraud", pageNumber);
    window.sessionStorage.setItem("offsetfraud", offset);
    setGoogleData([]);
    setSecondGoogleData([]);
    backendApi(PostData).then((data) => {
      for (let i = 0; i < data.data.length; i++) {
        $("#radiotf" + i).prop("checked", false);
      }
      if (data.success === "1") {
        setExcelData(data.data);
        setcount(data.totalCnt[0].totalcnt);
        setTotalCount(data.totalDataReviews);
        setTotalCompleted(data.totalCompletedReviews);
        const [statusInQueue, statusCompleted, statusFailed, progress] =
          data.statusFraud;
        setTotalCompleted(statusCompleted);
        setInQueue(statusInQueue);
        setTotalFailed(statusFailed);
        setprogress(progress);
      } else {
        setExcelData([]);
        setcount(0);
      }
    });
  };
  const handleProcessData = (id) => {
    $(".loder_div").attr("hidden", false);
    $("#reviewsTable").attr("hidden", true);
    $("#postsTable").attr("hidden", true);
    setGoogleData([]);
    const apidata = { functionName: "getFraudDataByGoogleId", dataId: id };
    backendApi(apidata).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        $("#reviewsTable").attr("hidden", false);
        $("#postsTable").attr("hidden", false);
        setGoogleData(data.data);
        setSecondGoogleData(data.data1);
      } else {
        $(".loder_div").attr("hidden", true);
        $("#reviewsTable").attr("hidden", false);
        $("#postsTable").attr("hidden", false);
        setSecondGoogleData([]);
      }
    });
  };
  const migrateReview = (masterId) => {
    $(".loder_div").attr("hidden", false);
    const apidata = {
      method: "POST",
      body: JSON.stringify({
        masterId: tableId,
        userId:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
      }),
    };
    pythonBackendApi("migrateReview", apidata).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success !== "1") {
        alert("Failed");
        return;
      }
      alert("Started Running Fraud Detection...");
      getAuditExcelDataByMasterId();
    });
  };
  const runFraud = () => {
    $(".loder_div").attr("hidden", false);
    const apidata = {
      method: "POST",
      body: JSON.stringify({
        userId:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        masterId: tableId,
      }),
    };
    pythonBackendApi("runFraud", apidata).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success != "1") {
        alert("Failed");
        return;
      }
      alert("Running Fraud");
      getAuditExcelDataByMasterId();
    });
  };
  const downloadFraudExcel = () => {
    $(".loder_div").attr("hidden", false);
    const apidata = {
      functionName: "downloadFraudExcel",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      masterId: tableId,
    };
    backendApi(apidata).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        const successFileName = "fraud-audit-excel";
        const exportType = "xls";
        exportXlsx(data.data, successFileName, exportType);
      } else {
        alert("Failed to Download Fraud Excel");
        $(".loder_div").attr("hidden", true);
      }
    });
  };
  const exportXlsx = (data, fileName, exportType) => {
    exportFromJSON({ data, fileName, exportType });
  };
  const handleSort = (e) => {
    setActivePage(1);
    let flagvalue = true;
    let fromdata = $("#fromData").val();
    let todata = $("#toData").val();
    let fromdatavalue = "";
    let todatavalue = "";

    if (todata === "") {
      todata = 500000;
    }
    if (fromdata === "") {
      fromdata = 1;
    }
    if (todata < fromdata) {
      alert("Please Provide Valid ID");
      flagvalue = false;
    } else if (fromdata !== "" && todata !== "") {
      fromdatavalue = fromdata;
      todatavalue = todata;
      flagvalue = true;
    }
    // if (fromdata > todata || (fromdata == "" && todata != "") || (fromdata != "" && todata == "")) {
    // 	alert("Please Provide Serial No.");
    // 	flagvalue = false;
    // }
    if (flagvalue == true) {
      const req = {
        functionName: "getPrimaryGoogleDataByMasterId",
        masterId: tableId,
        offset: "",
        search:
          $("#searchAddress").val() === " " ? "" : $("#searchAddress").val(),
        startId: fromdatavalue,
        endId: todatavalue,
      };
      setGoogleData([]);
      setSecondGoogleData([]);
      $(".loder_div").attr("hidden", false);
      backendApi(req).then((data) => {
        for (let i = 0; i < data.data.length; i++) {
          $("#radiotf" + i).prop("checked", false);
        }
        if (data.success === "1") {
          $(".loder_div").attr("hidden", true);
          setExcelData(data.data);
          setcount(data.totalCnt[0].totalcnt);
        } else {
          setExcelData([]);
          setcount(0);
          $(".loder_div").attr("hidden", true);
        }
      });
    }
  };
  function allowNumbersOnly(e) {
    const code = e.which ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
      e.preventDefault();
    }
  }
  const clearFilters = () => {
    $("#searchAddress").val("");
    $("#fromData").val("");
    $("#toData").val("");
    getAuditExcelDataByMasterId();
  };
  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header heading="Fraud Details" headerImage={Fraudimage} />
        <div className="container-fluid competitor_div">
          {/* <div className="back_tocatalog_main">
            <Link to="/fraud" className="back-to-link">
              <Icon.ArrowLeft />
              Back to Fraud
            </Link>
          </div> */}
          <div className="user_listdiv_sub">
            <div className="back_tocatalog row">
              <div className="col-lg-12 d-flex justify-content-between">
                <div className="d-flex">
                  <div className="back_tocatalog_main">
                    <Link to="/fraud" className="back-to-link">
                      <Icon.ArrowLeftCircle />
                    </Link>
                  </div>
                  {fraudDetectionStatus === "Completed" ? (
                    <button
                      id="txtFile"
                      type="button"
                      className={`btn ${
                        fraudDetectionStatus === "Started"
                          ? "btn-disabled"
                          : "btn-info"
                      }`}
                      disabled={fraudDetectionStatus === "Started"}
                      onClick={() => runFraud()}
                    >
                      Re-Run Fraud Detection
                    </button>
                  ) : (
                    <button
                      id="txtFile"
                      type="button"
                      className={`btn ${
                        fraudDetectionStatus === "Started"
                          ? "btn-disabled"
                          : "btn-info"
                      }`}
                      disabled={fraudDetectionStatus === "Started"}
                      onClick={() => migrateReview()}
                    >
                      Start Fraud Detection
                    </button>
                  )}
                  <button
                    id="txtFile"
                    type="button"
                    className="btn-download"
                    onClick={(e) => downloadFraudExcel()}
                  >
                    Download Fraud Excel
                  </button>
                </div>
                <div>
                  {totalCount && (
                    <span
                      className="audit-list-box"
                      style={{ color: "rgb(73, 92, 131)" }}
                    >
                      <span className="audit-txt">Total: </span>
                      <span className="audit-txt-bld">{totalCount}</span>
                    </span>
                  )}
                  {inQueue?.cnt && (
                    <span
                      className="audit-list-box"
                      style={{ color: "rgb(80, 80, 80)" }}
                    >
                      <span className="audit-txt">In Queue: </span>
                      <span className="audit-txt-bld">{inQueue?.cnt}</span>
                    </span>
                  )}
                  {totalCompleted?.cnt && (
                    <span
                      className="audit-list-box"
                      style={{ color: "#rgb(40, 167, 69)" }}
                    >
                      <span className="audit-txt">Completed: </span>
                      <span className="audit-txt-bld">
                        {totalCompleted.cnt}
                      </span>
                    </span>
                  )}
                  {totalFailed?.cnt && (
                    <span
                      className="audit-list-box"
                      style={{ color: "rgb(205, 0, 0)" }}
                    >
                      <span className="audit-txt">Failed: </span>
                      <span className="audit-txt-bld">{totalFailed.cnt}</span>
                    </span>
                  )}
                  {progress?.cnt && (
                    <span className="audit-list-box" style={{ color: "green" }}>
                      <span className="audit-txt">In Progress: </span>
                      {console.log(progress.cnt, "progress.cntprogress.cnt")}
                      <span className="audit-txt-bld">{progress.cnt}</span>
                    </span>
                  )}
                  {/*{fraudDetectionStatus === "Completed" && (
										<span className="fs-6 mx-2 text-success">
											<strong>Fraud Detection is Complete</strong>
										</span>
									)} */}
                </div>
              </div>
              {/* <div className="dataProcessImg " style={{ display: "none" }}>
								<img src={dataProcessImg} alt="data process"></img>
								<div className="dataProcessLabel">
									{totalComplete}/{totalCount} Completed
								</div>
							</div> */}
            </div>
            <div className="row row-search">
              <div className="col-lg-2 col-md-4 col-sm-6">
                <input
                  type="text"
                  id="fromData"
                  className="form-control"
                  placeholder="From Serial No."
                  onKeyPress={(e) => allowNumbersOnly(e)}
                />
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <input
                  type="text"
                  id="toData"
                  className="form-control"
                  placeholder="To Serial No."
                  onKeyPress={(e) => allowNumbersOnly(e)}
                />
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  id="searchAddress"
                />
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12 col-lg-6">
                <button
                  className="btn-search mr-2"
                  id="txtFile"
                  type="button"
                  onClick={(e) => handleSort(e)}
                >
                  Search
                </button>
                <button
                  id="txtFile"
                  type="button"
                  className="btn-clear-search"
                  onClick={() => clearFilters()}
                >
                  Clear Search
                </button>
              </div>
            </div>
            <div className="col-lg-8 col-md-10">
              {count > 25 && (
                <div className="pagination_main">
                  <Pagination
                    hideDisabled
                    activePage={activePage}
                    itemsCountPerPage={25}
                    totalItemsCount={count}
                    pageRangeDisplayed={10}
                    onChange={(e) => handlePageChange(e)}
                  />
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-8 tableResponsive">
                <div className="table_leftOuterWidth">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-1">S.No</th>
                        <th className="col-2">Name</th>
                        <th className="col-6">Address</th>
                        <th className="col-1">Reviews</th>
                        <th className="col-1">Status</th>
                        <th className="col-1"></th>
                        {/* <th className="col-2">Reprocess</th> */}
                      </tr>
                    </thead>
                    <div className="table_Outerscroll">
                      {excelData.length > 0 ? (
                        <>
                          {excelData.map((data, i) => (
                            <tbody
                              key={i + 1}
                              className="stickyDiv table_Outertbody"
                            >
                              <tr>
                                <td className="col-1">{data.id}</td>
                                <td className="col-2">
                                  {data.location_name}
                                  <br />
                                  {data.status == "Stray" ? (
                                    <span className="text-info">
                                      <b>({data.status})</b>
                                    </span>
                                  ) : (
                                    <span className="text-success">
                                      <b>({data.status})</b>
                                    </span>
                                  )}
                                </td>
                                {/* <td className="col-2">{data.status}</td> */}
                                <td className="hoverTexts col-6">
                                  {data.address}
                                </td>
                                <td className="col-1">{data.reviews}</td>
                                <td className="col-1" key={data.process_status}>
                                  {data.process_status === "" ? (
                                    ""
                                  ) : data.process_status === "Started" ? (
                                    <ScaleLoader
                                      color="#36d7b7"
                                      height={20}
                                      width={2}
                                    />
                                  ) : data.process_status === "Completed" ? (
                                    <span title="Completed">
                                      <Icon.CheckCircle
                                        color="green"
                                        name="completed"
                                      />
                                    </span>
                                  ) : (
                                    <span title="Failed">
                                      <Icon.XCircle
                                        color="red"
                                        name="completed"
                                      />
                                    </span>
                                  )}
                                </td>
                                <td className="col-1">
                                  <input
                                    type="radio"
                                    id={"radiotf" + i}
                                    name="process"
                                    onClick={() => handleProcessData(data.id)}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </>
                      ) : (
                        <tr>
                          {!loading && (
                            <td
                              colSpan={7}
                              className="col-12"
                              style={{ textAlign: "center" }}
                            >
                              <h4>No Data To Fetch</h4>
                            </td>
                          )}
                        </tr>
                      )}
                    </div>
                  </table>
                </div>
              </div>
              <div className="col-md-12 col-lg-4 tableResponsive">
                <div id="reviewsTable" className="table_rightOuterWidth" hidden>
                  {googleData.length !== 0 ? (
                    <table className="table">
                      <thead className="table_theadTop">
                        <tr>
                          <th className="col-6">Username</th>
                          <th className="col-6">Review</th>
                        </tr>
                      </thead>
                      {googleData.map((data, i) => (
                        <tbody className="table_righttbody" key={i + 1}>
                          <tr className="stickyDiv">
                            <td className="col-6">{data.user_name}</td>
                            <td className="col-6">{data.review}</td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  ) : (
                    ""
                  )}
                </div>
                <div id="postsTable" className="table_rightOuterWidth" hidden>
                  <DisplaySecondGoogleData
                    secondGoogleData={secondGoogleData}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-10">
              {count > 25 && (
                <div className="pagination_main">
                  <Pagination
                    hideDisabled
                    activePage={activePage}
                    itemsCountPerPage={25}
                    totalItemsCount={count}
                    pageRangeDisplayed={10}
                    onChange={(e) => handlePageChange(e)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
};
export default FraudDetails;
