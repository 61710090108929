import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import exportFromJSON from "export-from-json";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import { backendApi, pythonBackendApi } from "../apiService";
import Header from "../layout/header";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu";
import dataProcessImg from "../images/dataProcess.gif";
import Analysisimage from "../images/left_icons/analysis.png";
import $ from "jquery";
const CompetiterDetails = () => {
  const location = useLocation();
  let pathname = location.pathname;
  var path = pathname.split("/")[2];
  const [tableId, settableId] = useState(path);
  const [competiterData, setcompetiterData] = useState([]);
  const [totalCount, settotalCount] = useState("");

  const [visibility, setvisibility] = useState("");
  const [finalarray, setfinalarray] = useState([]);
  const [competitorStatusStarted, setcompetitorStatusStarted] = useState([]);
  const [googleData, setgoogleData] = useState([]);
  const [status, setstatus] = useState("");

  useEffect(() => {
    $("#nodata").hide();
    setInterval(() => {
      getStatusdata();
    }, 60000);
    getStatusdata();
    console.log(tableId);
    // comparecompetiter()
    getAuditExcelDataByMasterId();

    $(".visblity_text").show();
  }, []);

  const getStatusdata = () => {
    var apidata = {
      functionName: "auditCompetitorStatus",
      masterId: tableId,
    };
    backendApi(apidata).then((data) => {
      console.log("data=========", data);
      if (data.success === "1") {
        // const arr = data.data.filter((element) => {
        //   return element.process_status == "Completed";
        // });
        // const arr2 = data.data.filter((element) => {
        //   return element.process_status == "";
        // });

        setcompetitorStatusStarted(data.data);
        // setcompetitorStatusNotStarted(arr2);
      }
    });
  };
  const getAuditExcelDataByMasterId = () => {
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "getCompitetorDataByMasterId",
      masterId: tableId,
      search: $("#searchAddress").val(),
      startId: "",
      endId: "",
    };
    setfinalarray([]);
    backendApi(req).then((data) => {
      if (data.data) {
        $(".loder_div").attr("hidden", true);
        setcompetiterData(data.data);
        setgoogleData(data.googleData);

        settotalCount(data.totalCount);
        setvisibility(data.data[0].visibility);
        setstatus(data.status[0].status);
      } else {
        $(".loder_div").attr("hidden", true);
        setcompetiterData([]);
        setgoogleData([]);
        setvisibility("");
        settotalCount("");
      }
    });
  };
  const handleProcessData = (id) => {
    var apidata = {
      functionName: "getCompitetorGoogleData",
      dataId: id,
    };
    let array = finalarray;
    backendApi(apidata).then((data) => {
      console.log("data=========", data);
      if (data.success === "1") {
        array = [];
        for (let i = 0; i < data.data.length; i++) {
          array.push({
            Address: data.data[i].address,
            Reviews: data.data[i].reviews,
            status: data.data[i].status,
            accuracy: data.data[i].accuracy,
            statusValue:
              data.data[i].status === "Primary"
                ? "40"
                : data.data[i].status === "Stray"
                ? "10"
                : data.data[i].status === "Duplicate"
                ? "30"
                : "20",
          });
        }
        setfinalarray(array);
      }
    });
  };

  const migrateCompetitor = () => {
    $(".dataProcessImgProcessData").show();

    var req = {
      method: "POST",
      body: JSON.stringify({
        masterId: tableId,
        userId:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
      }),
    };

    pythonBackendApi("runCompetitor", req).then((data) => {
      if (data.success) {
        $(".dataProcessImgProcessData").hide();
        alert("Re-Process Started");
        // alert("competitor  Successfully");
        getAuditExcelDataByMasterId();
      } else {
        alert("Re-Process Failed");

        $(".dataProcessImgProcessData").hide();
      }

      //  window.location.reload()
    });
  };
  // const comparecompetiter = () => {
  //   if (!window.confirm("Do you want to Classify Data?")) {
  //     return false;
  //   }
  //   $(".dataProcessImgCompareData").show();

  //   var apidata = {
  //     method: "POST",
  //     body: JSON.stringify({
  //       masterId: tableId,
  //       userId:
  //         window.sessionStorage.getItem("switchUserId") === ""
  //           ? window.sessionStorage.getItem("userId")
  //           : window.sessionStorage.getItem("switchUserId"),
  //     }),
  //   };

  //   pythonBackendApi("compareCompitetorData", apidata).then((data) => {
  //     console.log("data=========", data);
  //     if (data.success === "1") {
  //       $(".dataProcessImgCompareData").hide();

  //       alert("Process Completed Successfully");
  //       getAuditExcelDataByMasterId();
  //     } else {
  //       alert("Failed");
  //       $(".dataProcessImgCompareData").hide();
  //     }
  //   });
  // };
  const comparecompetiter = () => {
    if (!window.confirm("Do you want to Classify Data?")) {
      return false;
    }

    var apidata = {
      method: "POST",
      body: JSON.stringify({
        masterId: tableId,
        userId:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
      }),
    };
    $(".loder_div").attr("hidden", false);
    pythonBackendApi("compareCompitetorData", apidata).then((data) => {
      console.log("data=========", data);
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        $(".dataProcessImgCompareData").hide();

        alert("Process Started Successfully");
        getAuditExcelDataByMasterId();
      } else {
        $(".loder_div").attr("hidden", true);
        alert("Failed");
        $(".dataProcessImgCompareData").hide();
      }
    });
  };
  const handleSort = (e) => {
    $("#nodata").hide();
    var flagvalue = true;
    let fromdata = $("#fromData").val();
    let todata = $("#toData").val();
    let fromdatavalue = "";
    let todatavalue = "";
    if (todata === "") {
      todata = 500000;
    }
    if (fromdata === "") {
      fromdata = 1;
    }

    if (todata < fromdata) {
      alert("Please Provide Valid ID");
      flagvalue = false;
    } else if (fromdata !== "" && todata !== "") {
      fromdatavalue = fromdata;
      todatavalue = todata;
      flagvalue = true;
    }
    if (flagvalue == true) {
      $(".loder_div").attr("hidden", false);

      var req = {
        functionName: "getCompitetorDataByMasterId",
        masterId: tableId,
        search:
          $("#searchAddress").val() === " " ? "" : $("#searchAddress").val(),
        startId: fromdatavalue,
        endId: todatavalue,
      };
      setfinalarray([]);
      console.log("req");
      backendApi(req).then((data) => {
        // for (let i = 0; i < data.data.length; i++) {
        //   $('#radiotf' + i).prop('checked', false);

        // }
        if (data.data || data.googleData) {
          $(".loder_div").attr("hidden", true);
          setcompetiterData(data.data);
          setgoogleData(data.googleData);
          setvisibility(data.data[0].visibility);
        } else {
          $(".loder_div").attr("hidden", true);

          setvisibility("");
        }
      });
    }
  };

  function allowNumbersOnly(e) {
    var code = e.which ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
      e.preventDefault();
    }
  }
  const clearFilters = () => {
    // $(".loder_div").attr("hidden", false);
    // setTimeout(() => {
    //   $(".loder_div").attr("hidden", true);
    // }, 500);
    $("#searchAddress").val("");

    $("#fromData").val("");
    $("#toData").val("");
    $("#statusValue").val("0");
    getAuditExcelDataByMasterId();
  };
  const singleRestart = (id) => {
    const apiParam = {
      functionName: "reprocessCompetitorDetails",
      Id: id,
    };
    backendApi(apiParam).then((data) => {
      if (data.success == "1") {
        alert(`Competitor process for listing-${id} restarted successfully`);
      } else {
        alert("Something went wrong");
      }
    });
  };
  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header heading="Competitor Details" headerImage={Analysisimage} />
        <div className="container-fluid">
          {/* <div className="back_tocatalog_main">
            <Link to="/competiterAnalysis" className="back-to-link">
              <Icon.ArrowLeft />
              Back to Competitor Analysis
            </Link>
          </div> */}

          <div className="user_listdiv_sub">
            <div className="back_tocatalog row">
              <div className="col-xl-4 col-lg-4 col-md-6 d-flex">
                <div className="back_tocatalog_main">
                  <Link to="/competiterAnalysis/audit" className="back-to-link">
                    <Icon.ArrowLeftCircle />
                  </Link>
                </div>

                <button
                  id="txtFile"
                  type="button"
                  disabled={status != "Audited" ? true : false}
                  className={
                    status != "Audited"
                      ? "btn btn-secondary"
                      : "btn btn-primary btn-right"
                  }
                  onClick={migrateCompetitor}
                >
                  Re-Process
                </button>

                <button
                  id="txtFile"
                  type="button"
                  disabled={status != "Audited" ? true : false}
                  className={
                    status != "Audited"
                      ? "btn btn-secondary ml-1"
                      : "btn btn-success btn-right"
                  }
                  onClick={() => comparecompetiter()}
                >
                  Classify Data
                </button>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="audit-list-box"
                  style={{ color: "rgb(73, 92, 131)" }}
                >
                  <span className="audit-txt">Total :</span>
                  <span className="audit-txt-bld">{totalCount}</span>
                </div>
                <div className="audit-list-box">
                  <span style={{ color: "rgb(128,128,128)" }}>
                    <span className="audit-txt">Queue :</span>
                    <span className="audit-txt-bld">
                      {competitorStatusStarted == ""
                        ? 0
                        : competitorStatusStarted.filter(
                            (data) => data.process_status == ""
                          ).length > 0
                        ? competitorStatusStarted
                            .filter((data) => data.process_status == "")
                            .map((data) => (data.cnt == "" ? 0 : data.cnt))
                        : 0}
                    </span>
                  </span>
                  {/* {AuditStatusInProgress != "" && (
                    <Link
                      className="link-show"
                      onClick={() => SearchFile("")}
                    >
                      show
                    </Link>
                  )} */}
                </div>
                <div className="audit-list-box">
                  <span style={{ color: "rgb(56, 74, 242)" }}>
                    <span className="audit-txt">In progress :</span>
                    <span className="audit-txt-bld">
                      {competitorStatusStarted == ""
                        ? 0
                        : competitorStatusStarted.filter(
                            (data) => data.process_status == "Started"
                          ).length > 0
                        ? competitorStatusStarted
                            .filter((data) => data.process_status == "Started")
                            .map((data) => (data.cnt == "" ? 0 : data.cnt))
                        : 0}
                    </span>
                  </span>
                  {/* {AuditStatusInProgress != "" && (
                    <Link
                      className="link-show"
                      onClick={() => SearchFile("")}
                    >
                      show
                    </Link>
                  )} */}
                </div>
                <div className="audit-list-box">
                  <span style={{ color: "rgb(40, 167, 69)" }}>
                    <span className="audit-txt">Completed :</span>
                    <span className="audit-txt-bld">
                      {competitorStatusStarted == ""
                        ? 0
                        : competitorStatusStarted.filter(
                            (data) => data.process_status == "Completed"
                          ).length > 0
                        ? competitorStatusStarted
                            .filter(
                              (data) => data.process_status == "Completed"
                            )
                            .map((data) => (data.cnt == "" ? 0 : data.cnt))
                        : 0}
                    </span>
                  </span>
                  {/* {AuditStatusInProgress != "" && (
                    <Link
                      className="link-show"
                      onClick={() => SearchFile("")}
                    >
                      show
                    </Link>
                  )} */}
                </div>
                <div className="audit-list-box">
                  <span style={{ color: "rgb(255,0,0)" }}>
                    <span className="audit-txt">Failed :</span>
                    <span className="audit-txt-bld">
                      {competitorStatusStarted == ""
                        ? 0
                        : competitorStatusStarted.filter(
                            (data) => data.process_status == "Failed"
                          ).length > 0
                        ? competitorStatusStarted
                            .filter((data) => data.process_status == "Failed")
                            .map((data) => (data.cnt == "" ? 0 : data.cnt))
                        : 0}
                    </span>
                  </span>
                  {/* {AuditStatusInProgress != "" && (
                    <Link
                      className="link-show"
                      onClick={() => SearchFile("")}
                    >
                      show
                    </Link>
                  )} */}
                </div>
                {/* <div className="audit-list-box">
                  <span
                    className="link-show"
                    style={{ color: "rgb(205, 0, 0)" }}
                  
                  >
                    <span className="audit-txt">Not Started:</span>
                    <span className="audit-txt-bld">
                      {competitorStatusNotStarted == ""
                        ? 0
                        : competitorStatusNotStarted.map((data) => data.cnt)}
                    </span>
                  </span>
             
                </div> */}
              </div>
              <div
                className="dataProcessImgProcessData"
                style={{ display: "none" }}
              >
                {/* <img src={dataProcessImg} alt="data process"></img> */}
                <div className="dataProcessLabel">
                  {" "}
                  Processing your Request Please Wait...
                </div>
              </div>
              <div
                className="dataProcessImgCompareData"
                style={{ display: "none" }}
              >
                {/* <img src={dataProcessImg} alt="data process"></img> */}
                <div className="dataProcessLabel">
                  {" "}
                  Comparing the Data Please Wait...
                </div>
              </div>
            </div>
            <hr></hr>

            <div className="row row-search">
              <div className="col-lg-2 col-md-4 col-sm-6">
                <input
                  type="text"
                  id="fromData"
                  className="form-control"
                  placeholder="From ID"
                  onKeyPress={(e) => allowNumbersOnly(e)}
                />
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <input
                  type="text"
                  id="toData"
                  className="form-control"
                  placeholder="To ID"
                  onKeyPress={(e) => allowNumbersOnly(e)}
                />
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  id="searchAddress"
                />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <button
                  id="txtFile"
                  type="button"
                  className="btn-search"
                  onClick={(e) => handleSort(e)}
                >
                  Search
                </button>
                <button
                  id="txtFile"
                  type="button"
                  className="btn-clear-search"
                  onClick={(e) => clearFilters(e)}
                >
                  Clear Search
                </button>
              </div>
              <div className="col-md-8 col-lg-3">
                <p className="text_visibility ml-auto">
                  Visibility :{visibility === null ? "0" : parseInt(visibility)}
                  %
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-12 tableResponsive">
                <div className="table_leftOuterWidth">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-1" style={{ maxWidth: "5%" }}>
                          ID
                        </th>
                        <th className="col-1">Name</th>
                        <th className="col-2">Address</th>
                        <th className="col-1">Address Match</th>
                        <th className="col-1">Ph.No Match</th>
                        <th className="col-1">Business Name Match</th>
                        {googleData.length != "0" && (
                          <>
                            <th className="col-2">Google Business Address</th>
                            <th className="col-1">Google Phone No</th>
                            <th className="col-2">Google Business Name</th>
                          </>
                        )}
                      </tr>
                    </thead>

                    <div class="table_Outerscroll">
                      {competiterData.length != 0 ? (
                        competiterData.map((data, i) => (
                          <tbody
                            key={i + 1}
                            className="stickyDiv table_Outertbody"
                          >
                            <tr>
                              <td className="col-1" style={{ maxWidth: "5%" }}>
                                {data.id}
                              </td>
                              <td className="col-1">{data.location_name} </td>
                              <td className="col-2">{data.address}</td>
                              <td className="col-1">
                                {status != "Audited" ? "-" : data.address_match}
                              </td>
                              <td className="col-1">
                                {status != "Audited"
                                  ? "-"
                                  : data.phone_number_match}
                              </td>
                              <td className="col-1">
                                {status != "Audited"
                                  ? "-"
                                  : data.business_name_match}
                              </td>{" "}
                              {console.log("googleDatagoogleData", googleData)}
                              {googleData.length != "0" && (
                                <>
                                  <td className="col-2">
                                    {googleData
                                      .filter(
                                        (obj) =>
                                          obj.competitor_audit_data_id ===
                                          data.id
                                      )
                                      .map((item) => item.address)}
                                  </td>
                                  <td className="col-1">
                                    {googleData
                                      .filter(
                                        (obj) =>
                                          obj.competitor_audit_data_id ===
                                          data.id
                                      )
                                      .map((item) => item.phone_no)}
                                  </td>
                                  <td className="col-2">
                                    {googleData
                                      .filter(
                                        (obj) =>
                                          obj.competitor_audit_data_id ===
                                          data.id
                                      )
                                      .map((item) => item.location_name)}
                                  </td>
                                </>
                              )}
                            </tr>
                            <tr>
                              <td className="col-6">
                                <span style={{ display: "block" }}>
                                  <button
                                    className="btn btn-info"
                                    onClick={() => singleRestart(data.id)}
                                  >
                                    Restart
                                  </button>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        ))
                      ) : (
                        <p id="nodata">No Data Found</p>
                      )}
                    </div>
                  </table>
                </div>
              </div>
              {/* <div className="col-md-10 col-lg-4 tableResponsive">
                <div className="table_rightOuterWidth">
                  {finalarray.length !== 0 && (
                    <table className="table">
                      <thead className="table_theadTop">
                        <tr>
                          <th className="col-6">Address</th>
                          <th className="col-3">Reviews</th>
                          <th className="col-3">Status</th>
                        </tr>
                      </thead>
                      <div className="table_rightOuterscroll">
                        {finalarray
                          .sort((a, b) => b.statusValue - a.statusValue)
                          .map((data, i) => (
                          <tbody className="table_righttbody">
                            <tr key={i + 1} className="stickyDiv">
                              <td  className="col-6">
       
                                {data.Address}

                                <p style={{ color: "GREEN" }}>
                                  Accuracy{" :  " + data.accuracy}
                                </p>
                              </td>
                              <td className="col-3">{data.Reviews}</td>
                              <td className="col-3">{data.status == null ? "" : data.status}</td>
                            </tr>
                          </tbody>
                          ))}
                      </div>
                    </table>
                  )}
                </div>
              </div> */}
            </div>
          </div>
          <div className="reprocessing_btndiv">
            <div className="repro_btn"></div>
            <div className="col-lg-8 col-md-10"></div>
            {/* <div className="repro_btn">
                  <button
                    id="pdfFile"
                    type="button"
                    className="btn-download"
                    onClick={(e) => this.downloadTxtFile(e)}
                  >
                    Download as PDF File
                  </button>
                </div> */}
          </div>
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
};
export default CompetiterDetails;
