import React from "react";
import { useState, useEffect } from "react";
import Dashboardimage from "../images/left_icons/dashboard.png";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import * as Icon from "react-feather";
import { BsBorderBottom } from "react-icons/bs";
import { apiCallingPost } from "../apiService";
import moment from "moment";
import Pagination from "react-js-pagination";
function BudgetAllocation() {
  const [budgetAlloctaionList, setBudgetAlloctaionList] = useState([])
  const [searchvalue, setSearchValue] = useState("")
  const [totalCount, setTotalCount] = useState("")
  const [activePage, setActivePage] = useState(1)


  // const tableData = [
  //   {
  //     dealerName: "Himalaya",
  //     address: "No.3, St.P.S Iv, Shop No.1, Popatlal Chawl, Ranade Rd, Dadar West, Mumbai, 400028",
  //     modifiedBy: "John",
  //     modifiedDate: "01 Jan 2024",
  //     history: [
  //       "PM meta PV fund changed to 1500",
  //       "SM google SCV fund changed to 3000"
  //     ]
  //   },
  //   {
  //     dealerName: "Himalaya",
  //     address: "Unit No G 76, Ground Floor, Phoenix Marketcity, L.B.S. Marg, Kurla West, Mumbai, 400070",
  //     modifiedBy: "John",
  //     modifiedDate: "01 Jan 2024",
  //     history: [
  //       "PM meta PV fund changed to 1500",
  //       "SM google SCV fund changed to 3000"
  //     ]
  //   },
  //   {
  //     dealerName: "Himalaya",
  //     address: "Unit No G 76, Ground Floor, Phoenix Marketcity, L.B.S. Marg, Kurla West, Mumbai, 400070",
  //     modifiedBy: "John",
  //     modifiedDate: "01 Jan 2024",
  //     history: [
  //       "PM meta PV fund changed to 1500",
  //       "SM google SCV fund changed to 3000"
  //     ]
  //   }
  // ];


  useEffect(() => {
    // setBudgetAlloctaionList(tableData);
    handleFundAllocationHistoryList()
  }, []);




  const handleFundAllocationHistoryList = () => {
    const postData = {
      "functionName": "dealerHistoryListing",
      "userId": window.sessionStorage.getItem("userId"),
      "dealerName": "",
      "date": "",
      "max": "10",
      "offset": "0"
    }
    apiCallingPost(postData).then((data) => {
      console.log("🚀 setBudgetAlloctaionList~ apiCallingPost ~ postData:", data.result.resultData)
      if (data.success === "1") {
        setBudgetAlloctaionList(data.result.resultData);
        setTotalCount(data.result.totalCount);
        setActivePage(1);
      } else {
        setBudgetAlloctaionList([])
      }
    });
  }

  const handleDealerSearch = () => {
    const postData = {
      "functionName": "dealerHistoryListing",
      "userId": window.sessionStorage.getItem("userId"),
      "dealerName": searchvalue,
      "date": "",
      "max": "",
      "offset": "0"
    }
    apiCallingPost(postData).then((data) => {
      console.log("🚀 setBudgetAlloctaionList~ apiCallingPost ~ postData:", data.result.resultData)
      if (data.success === "1") {
        setBudgetAlloctaionList(data.result.resultData);
        setTotalCount(data.result.totalCount);
        setActivePage(1)
      } else {
        setBudgetAlloctaionList([])
      }
    });
  }
  const handleClearSearch = () => {
    setSearchValue("");
    handleFundAllocationHistoryList();
    setActivePage(1)
  }
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = 10;
    } else {
      offset =
        parseInt(pageNumber - 1) * 10;
      max = 10;
    }
    setActivePage(pageNumber);
    const postData = {
      "functionName": "dealerHistoryListing",
      "userId": window.sessionStorage.getItem("userId"),
      "dealerName": searchvalue,
      "date": "",
      "max": max,
      "offset": offset
    }
    apiCallingPost(postData).then((data) => {
      console.log("🚀 setBudgetAlloctaionList~ apiCallingPost ~ postData:", data.result.resultData)
      if (data.success === "1") {
        setBudgetAlloctaionList(data.result.resultData)
        setTotalCount(data.result.totalCount)
      } else {
        setBudgetAlloctaionList([])
      }
    });
  };




  return (
    <div className="main_wrapper dsh-wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Fund Allocation History" headerImage={Dashboardimage} />
        <div className="container-fluid">
          {/* <div class="subhead">Budget Allocation</div> */}
          <div className="row mt-4 mx-0">
            <div class="col-lg-5 col-md-5 col-sm-4 col-9">
              <input type="text" class="form-control" placeholder="Search by Dealer" value={searchvalue} onChange={(e) => setSearchValue(e.target.value)} />
            </div>
            <div class="col-2 col-md-3">
              <button class="db-btn" type="button" onClick={() => handleDealerSearch()}>Search</button>
              <button class="db-btn ml-3" type="button" onClick={() => handleClearSearch()}>Clear</button>
            </div>
          </div>
          <div className="organisation-div organization-card user-creation">
            {/* <div class="subhead">Budget Allocation</div> */}
            {budgetAlloctaionList.length !== 0 ?
              <div className="col-md-12 tableResponsive">
                <div className="tableOuterWidth">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th className="col-3">Dealer Name / Address</th>
                        <th className="col-2">Modified by</th>
                        <th className="col-2">Modified Date</th>
                        <th className="col-5">History</th>
                      </tr>
                    </thead>
                    <div class="table_Outerscroll">
                      <tbody className="table_Outertbody">
                        {budgetAlloctaionList.map((item, index) => (
                          <tr key={index}>
                            <td className="col-3">
                              <p><b>{item.dealerName}</b></p>
                              <p className="text-muted">{item.address}</p>
                            </td>
                            <td className="col-2">
                              <p>{item.modifiedBy}</p>
                            </td>
                            <td className="col-2">
                              <p>{moment(item.modifiedDate).format("DD-MM-YYYY")}</p>
                            </td>
                            <td className="col-5">
                              <p>
                                <ul className="p-0">
                                  {item.history.map((historyItem, historyIndex) => (
                                    <li key={historyIndex}>{historyItem}</li>
                                  ))}
                                </ul>
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </div>
                  </table>
                </div>
              </div>
              : "No Data Found"}
          </div>

          {totalCount > 0 ?
            <Pagination
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={totalCount}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
            : ""}

        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
}
export default BudgetAllocation;
