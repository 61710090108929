import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import LeftMenu from "../layout/leftMenu";
import Lock from "../images/left_icons/lock2.png";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Select from "react-select";
import moment from "moment";
import Correct from "../images/correct.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { backendApi, pythonBackendApi } from "../apiService";
import Draggable from "react-draggable";
import ApproverPopup from "./approverPopup";
import html2canvas from "html2canvas";
import AWS from "aws-sdk";
import { GoPencil } from "react-icons/go";
let bucketParams;
let s3;
const s3ImageUrl =
  "http://tagtree.s3-website.ap-south-1.amazonaws.com/production/images";
const s3Region = "us-east-1";
const s3bucketAccessKey = process.env.REACT_APP_TAGTREE_S3_ACCESS_KEY;
const s3bucketSecretKey = process.env.REACT_APP_TAGTREE_S3_SECRET_KEY;
const s3Bucket = "tagtree";
const s3BucketMainFolder = "production";
const S3BucketImagesFolder = "images";

function ImagecontentMerge() {
  const [campaign, setCampaign] = useState("");
  const [editingIndex, setEditingIndex] = useState(-1);
  const [content, setContent] = useState("");
  const [approvedContent, setApprovedContent] = useState([]);
  const [mediaList, setMediaList] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [selectedContentIndex, setSelectedContentIndex] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedContent, setSelectedContent] = useState(null);
  const [fontSize, setFontSize] = useState(12);
  const [fontStyle, setFontStyle] = useState("Arial");
  const [textColor, setTextColor] = useState("#fffaf0");
  const [showApprovalPopup, setShowApprovalPopup] = useState(false);
  const [groupData, setGroupData] = useState([]);
  const [remark, setRemark] = useState("");
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [requestedData, setRequestedData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [waitingCount, setWaitingCount] = useState("");
  const [approvedCount, setApprovedCount] = useState("");
  const [rejectedCount, setRejectedCount] = useState("");

  useEffect(() => {
    getS3Credentials();
    groupDataInit();
    // getContentList();
    getMediaList();
    contentInit();
  }, []);

  const contentInit = () => {
    const apiReq = {
      functionName: "content_init",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apiReq).then((data) => {
      if (data.success == "1") {
        const transformedOptions = data.campaign_name.map(
          (campaign, index) => ({
            value: campaign.id,
            label: campaign.campaign_name,
          })
        );
        setCampaignOptions(transformedOptions);
      } else {
        console.log("Error in content init api");
      }
    });
  };

  const handleCancelClick = () => {
    setEditingIndex(-1);
  };

  const handleCampaignSelect = (selectedOption) => {
    setSelectedOption("");
    setSelectedContent("");
    setSelectedImage("");
    setSelectedImageIndex("");
    setSelectedContentIndex("");
    setCampaign(selectedOption);
    getContentList(selectedOption);
    getPlanningList(selectedOption);
  };

  const displayedImages =
    selectedOption === "Waiting"
      ? requestedData.waiting
      : selectedOption === "Approved"
      ? requestedData.approved
      : selectedOption === "Planning"
      ? requestedData.planning
      : [];

  const getPlanningList = (selectedCampaign) => {
    const postData = {
      functionName: "planningImages",
      campaign_id: selectedCampaign.value,
    };

    backendApi(postData)
      .then((data) => {
        if (data.success === "1") {
          console.log("data", data);
          setRequestedData({
            waiting: data.Waiting,
            approved: data.Approved,
            planning: data.Planning,
          });
          console.log("reqdata", requestedData);
        }
      })
      .catch((error) => {
        console.error("Error occurred while fetching requested data:", error);
      });
  };

  const getContentList = (campaign) => {
    const postData = {
      functionName: "content_request_Listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      category_type: "Text",
      req_status: "",
      campaign_id: campaign.value,
    };

    backendApi(postData)
      .then((data) => {
        if (data.success === "1") {
          setApprovedContent(data.dataList);
        }
      })
      .catch((error) => {
        console.error("Error occurred while fetching requested data:", error);
      });
  };

  const generateVariationOptions = () => {
    const options = [];
    for (let i = 12; i <= 32; i += 2) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  const handleUpdateClick = (i, data) => {
    let adminRemarks = remark.replace(/['"]/g, "\\$&");
    const postData = {
      functionName: "content_request_update_remarks",
      content_generation_request_id: data.id,
      remarks: adminRemarks,
      remarks_updated_by:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };
    backendApi(postData).then((response) => {
      if (response?.success === "1") {
        setEditingIndex(-1);
        getMediaList();
      } else {
        setEditingIndex(-1);
        console.log("Error");
      }
    });
  };

  const handleRemarkNameChange = (event) => {
    setRemark(event.target.value);
  };

  const getS3Credentials = () => {
    bucketParams = {
      Bucket: s3Bucket,
    };
    AWS.config.update({
      accessKeyId: s3bucketAccessKey,
      secretAccessKey: s3bucketSecretKey,
      region: s3Region,
    });
    s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: bucketParams,
    });
  };

  const handleEditClick = (index, remarks) => {
    setEditingIndex(index);
    setRemark(remarks);
  };

  const deleteRequest = (data) => {
    const postData = {
      functionName: "content_request_delete",
      content_generation_request_id: data.id,
      sec_user_id:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };
    backendApi(postData).then((response) => {
      if (response?.success === "1") {
        getMediaList();
      } else {
        console.log("Error");
      }
    });
  };

  const getMediaList = () => {
    const req = {
      functionName: "content_request_Listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      category_type: "Media",
      req_status: "",
    };
    backendApi(req).then((response) => {
      if (response.success == "1") {
        const newWaiting = [];
        const newApproved = [];
        const newRejected = [];
        response?.dataList?.forEach((item) => {
          if (item.req_status === "Waiting") {
            newWaiting.push(item);
          } else if (item.req_status === "Approved") {
            newApproved.push(item);
          } else if (item.req_status === "Rejected") {
            newRejected.push(item);
          }
        });
        setWaitingCount(newWaiting.length);
        setApprovedCount(newApproved.length);
        setRejectedCount(newRejected.length);
        setMediaList({
          waiting: newWaiting,
          approved: newApproved,
          rejected: newRejected,
        });
      } else {
        console.log(`Error in content request`);
      }
    });
  };

  const closePopup = () => {
    getMediaList();
    setShowApprovalPopup(false);
  };

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const fontStyles = [
    "Arial",
    "Roboto",
    "Courier",
    "Helvetica",
    "Times-Roman",
    "Courier New",
    "Verdana",
    "Garamond",
    "Georgia",
  ];

  const resendForApproval = (data) => {
    setContent(data.content_media);
    setShowApprovalPopup(true);
  };

  const sendForApproval = async () => {
    if (!selectedImage || !selectedContent) {
      alert("Please select an image and content before sending for approval.");
      return;
    }

    const imageContainer = document.querySelector(".image-container"); // Select the container

    html2canvas(imageContainer, { allowTaint: true, useCORS: true }) // Allow cross-origin resources
      .then((canvas) => {
        const dataURL = canvas.toDataURL("image/png"); // Get image data as PNG
        const imageData = atob(dataURL.split(",")[1]);
        const uint8Array = new Uint8Array(imageData.length);
        for (let i = 0; i < imageData.length; i++) {
          uint8Array[i] = imageData.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: "image/png" });
        AWS.config.update({
          accessKeyId: process.env.REACT_APP_TAGTREE_S3_ACCESS_KEY,
          secretAccessKey: process.env.REACT_APP_TAGTREE_S3_SECRET_KEY,
          region: s3Region,
        });
        var bucketParams = {
          Bucket: s3Bucket,
        };
        let s3 = new AWS.S3({
          apiVersion: "2006-03-01",
          params: bucketParams,
        });
        let fileName = uuidv4() + Date.parse(new Date()) + ".png";
        let fullFilePath = s3ImageUrl + "/" + fileName;
        s3.upload(
          {
            Key: fullFilePath,
            Body: blob,
            ACL: "public-read",
          },
          (err, data) => {
            if (err) {
              alert("There was an error uploading your image");
              console.log(err.message);
            } else if (data) {
              setContent(data.Location);
              setShowApprovalPopup(true);
            }
          }
        );
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      });
  };

  const groupDataInit = () => {
    const apiReq = {
      functionName: "content_approver_group_data",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    console.log("req", apiReq);
    backendApi(apiReq).then((data) => {
      if (data.success == "1") {
        setGroupData(data.dataSet);
      } else {
        console.log("Error in groupdata api");
      }
    });
  };

  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    var apidata = {
      method: "POST",
      body: JSON.stringify({
        image_url:
          selectedOption === "Planning"
            ? displayedImages[index].image_url
            : displayedImages[index].content_image,
      }),
    };
    pythonBackendApi("convertImageToBase", apidata).then((data) => {
      if (data.success == 1) {
        console.log("dataaa", data);
        setSelectedImage(data.data);
      } else {
        console.log("Error while converting to base64");
      }
    });
    // setSelectedImage(approvedImages[index]);
  };

  const handleContentClick = (index) => {
    setSelectedContentIndex(index);
    setSelectedContent(approvedContent[index]);
  };

  const downloadMedia = () => {
    const imageContainer = document.querySelector(".image-container");
    html2canvas(imageContainer, { allowTaint: true, useCORS: true }) // Allow cross-origin resources
      .then((canvas) => {
        const dataURL = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = dataURL;
        link.download = "mergedMedia.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      });
  };

  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Image Content Merge" headerImage={Lock} />
        <div className="col-md-12 tabs-main-wrapper">
          <Tabs>
            <TabList>
              <Tab>Image Content Merge</Tab>
              <Tab>
                Requested
                <span className="waitingTxt">{waitingCount}</span>
              </Tab>
              <Tab>
                Approved
                <span className="waitingTxt">{approvedCount}</span>
              </Tab>
              <Tab>
                Rejected
                <span className="rejectedTxt">{rejectedCount}</span>
              </Tab>
            </TabList>

            <TabPanel>
              <div className="row">
                <div className="col-md-12 mt-4">
                  <div className="d-flex align-items-center mb-3 select_wrap_div">
                    <label className="text-nowrap mb-0 mr-2">
                      Select Campaign
                    </label>
                    <Select
                      className="select_custom"
                      options={campaignOptions}
                      onChange={handleCampaignSelect}
                      value={campaign}
                    />
                    {selectedImage && selectedContent && (
                      <button
                        className="btn btn-primary ml-3"
                        style={{ height: "38px" }}
                        onClick={() => downloadMedia()}
                      >
                        Download Merged Image
                      </button>
                    )}
                  </div>
                </div>
                {campaign && (
                  <>
                    <div className="col-xl-6">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="merge_wrapper">
                            <div className="d-flex align-items-center mb-3 select_wrap_div">
                              <h3 className="image-heading mr-2">Images</h3>
                              <select
                                className="form-control"
                                value={selectedOption}
                                onChange={(e) =>
                                  setSelectedOption(e.target.value)
                                }
                              >
                                <option value="">Select</option>
                                <option value="Waiting">Waiting</option>
                                <option value="Approved">Approved</option>
                                <option value="Planning">Planning</option>
                              </select>
                            </div>
                            <div className="merge_wrapper_container">
                              {displayedImages.map((img, index) => (
                                <div
                                  className={`merge_image ${
                                    selectedImageIndex === index ? "active" : ""
                                  }`}
                                  onClick={() => handleImageClick(index)}
                                >
                                  <img
                                    src={
                                      selectedOption === "Planning"
                                        ? img.image_url
                                        : img.content_image
                                    }
                                    className="img-fluid marge_img"
                                    width="100%"
                                    height="100%"
                                    alt="merge-imgage"
                                  />
                                  {selectedImageIndex === index && (
                                    <img
                                      src={Correct}
                                      className="merge_tick"
                                      width="50"
                                      height="50"
                                      alt="correct"
                                    />
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="merge_wrapper">
                            <h3 className="image-heading">
                              Approved Text Content
                            </h3>
                            <div className="merge_wrapper_container approved_content">
                              {approvedContent.map((content, index) => (
                                <div
                                  className={`card mb-3 merge_card ${
                                    selectedContentIndex === index
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() => handleContentClick(index)}
                                >
                                  {selectedContentIndex === index && (
                                    <img
                                      src={Correct}
                                      className="merge_tick"
                                      width="50"
                                      height="50"
                                      alt="correct"
                                    />
                                  )}
                                  <div className="card-body h-100">
                                    <p className="card-text">
                                      {content.content_text}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6  mt-0 mt-lg-2">
                      <h3 className="image-heading invisible">invisible</h3>
                      <div className="preview-canvas">
                        <div class="card">
                          <div class="card-header">
                            <div className="row">
                              <div className="col-lg-3 col-md-6 mt-1">
                                Preview
                              </div>
                              {selectedImage && selectedContent && (
                                <>
                                  <div className="col-lg-1 col-md-4 pl-0">
                                    <div className="form-group mb-2 mb-md-0">
                                      {/* <label for="exampleInputEmail1">Font</label> */}
                                      <div className="d-md-flex">
                                        <input
                                          type="color"
                                          className="w-100"
                                          value={textColor}
                                          onChange={(e) =>
                                            setTextColor(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-4 pr-0 pl-0">
                                    <div className="form-group mb-2 mb-md-0">
                                      {/* <label for="exampleInputEmail1">Font</label> */}
                                      <div className="d-md-flex">
                                        <select
                                          className="form-control"
                                          onChange={(e) =>
                                            setFontStyle(e.target.value)
                                          }
                                          value={fontStyle}
                                        >
                                          <option>Select Font</option>
                                          {fontStyles.map((font) => (
                                            <option value={font} label={font}>
                                              {font}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-md-4 pr-0">
                                    <div className="form-group mb-2 mb-md-0">
                                      {/* <label for="exampleInputEmail1">Size</label> */}
                                      <div className="d-md-flex">
                                        <select
                                          className="form-control"
                                          value={fontSize}
                                          onChange={(e) =>
                                            setFontSize(e.target.value)
                                          }
                                        >
                                          <option>Select Font Size</option>
                                          {generateVariationOptions()}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-6 pr-0">
                                    <button
                                      className="btn btn-success"
                                      onClick={() => sendForApproval()}
                                    >
                                      Send for Approval
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div style={{ position: "relative" }}>
                            <div className="image-container">
                              {selectedImage && (
                                <img
                                  src={selectedImage}
                                  alt="approved_img"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                />
                              )}
                              {selectedContent && (
                                <Draggable onDrag={handleDrag}>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: position.y,
                                      left: position.x,
                                    }}
                                    className="image-container"
                                  >
                                    <div
                                      style={{
                                        fontSize: `${fontSize}px`,
                                        fontFamily: `${fontStyle}`,
                                        fontWeight: "bold",
                                        color: `${textColor}`,
                                        cursor: "move",
                                      }}
                                      contentEditable="true"
                                    >
                                      {selectedContent.content_text}
                                    </div>
                                  </div>
                                </Draggable>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </TabPanel>

            <TabPanel>
              <div className="col-md-12 tableResponsive tagImageEditrListTble mt-4">
                <div className="tableOuterWidth">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-3">Media </th>
                        <th className="col-3">Remarks</th>
                        <th className="col-2">Requested</th>
                        <th className="col-2">Approved</th>
                        <th className="col-2 ">Action</th>
                      </tr>
                    </thead>
                    <div className="table_Outerscroll">
                      {mediaList?.waiting?.map((data, i) => (
                        <tbody className="table_Outertbody pb-3">
                          <tr>
                            <td className="col-3">
                              <div className="popup-image">
                                <div className="popupGallerysection">
                                  <a
                                    href={data.content_media}
                                    title="img"
                                    target="_blank"
                                  >
                                    <img
                                      src={data.content_media}
                                      alt="media-waiting"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      className="img-fluid"
                                    />
                                  </a>
                                </div>
                              </div>
                            </td>

                            <td className="col-3">
                              {editingIndex === i ? (
                                <textarea
                                  type="text"
                                  value={remark}
                                  onChange={handleRemarkNameChange}
                                  className="w-100 form-control"
                                />
                              ) : (
                                <span>{data.remarks}</span>
                              )}
                              {editingIndex !== i ? (
                                <GoPencil
                                  className="ml-2"
                                  onClick={() =>
                                    handleEditClick(i, data.remarks)
                                  }
                                />
                              ) : (
                                ""
                              )}
                              {editingIndex === i && (
                                <div className="mt-2">
                                  <button
                                    onClick={() => handleUpdateClick(i, data)}
                                    className="btn btn-success"
                                  >
                                    Save
                                  </button>
                                  <button
                                    onClick={handleCancelClick}
                                    className="btn btn-secondary ml-1"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              )}
                            </td>
                            <td className="col-2">
                              <p>
                                {" "}
                                {moment(data.requested_date).format(
                                  "DD MMM YYYY"
                                )}
                              </p>
                              <p>{data.requested_by_name}</p>
                            </td>
                            <td className="col-2">
                              {data.approved_by_name ? (
                                <>
                                  <p>
                                    {moment(data.approved_date).format(
                                      "DD MMM YYYY"
                                    )}
                                  </p>
                                  <p>{data.approved_by_name}</p>
                                </>
                              ) : (
                                <p>Not approved</p>
                              )}
                            </td>
                            <td className="col-2">
                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={(e) => deleteRequest(data)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </div>
                  </table>
                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div className="col-md-12 tableResponsive tagImageEditrListTble mt-4">
                <div className="tableOuterWidth">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-3">Media </th>
                        <th className="col-3">Remarks</th>
                        <th className="col-2">Requested</th>
                        <th className="col-2">Approved</th>
                        <th className="col-2 ">Action</th>
                      </tr>
                    </thead>
                    <div className="table_Outerscroll">
                      {mediaList?.approved?.map((data, i) => (
                        <tbody className="table_Outertbody pb-3">
                          <tr>
                            <td className="col-3">
                              <div className="popup-image">
                                <div className="popupGallerysection">
                                  <a
                                    href={data.content_media}
                                    title="img"
                                    target="_blank"
                                  >
                                    <img
                                      src={data.content_media}
                                      alt="media-waiting"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      className="img-fluid"
                                    />
                                  </a>
                                </div>
                              </div>
                            </td>

                            <td className="col-3">
                              {editingIndex === i ? (
                                <textarea
                                  type="text"
                                  value={remark}
                                  onChange={handleRemarkNameChange}
                                  className="w-100 form-control"
                                />
                              ) : (
                                <span>{data.remarks}</span>
                              )}
                              {editingIndex !== i ? (
                                <GoPencil
                                  className="ml-2"
                                  onClick={() =>
                                    handleEditClick(i, data.remarks)
                                  }
                                />
                              ) : (
                                ""
                              )}
                              {editingIndex === i && (
                                <div className="mt-2">
                                  <button
                                    onClick={() => handleUpdateClick(i, data)}
                                    className="btn btn-success"
                                  >
                                    Save
                                  </button>
                                  <button
                                    onClick={handleCancelClick}
                                    className="btn btn-secondary ml-1"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              )}
                            </td>
                            <td className="col-2">
                              <p>
                                {" "}
                                {moment(data.requested_date).format(
                                  "DD MMM YYYY"
                                )}
                              </p>
                              <p>{data.requested_by_name}</p>
                            </td>
                            <td className="col-2">
                              <p>
                                {" "}
                                {moment(data.approved_date).format(
                                  "DD MMM YYYY"
                                )}
                              </p>
                              <p>{data.approved_by_name}</p>
                            </td>
                            <td className="col-2">
                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={(e) => deleteRequest(data)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </div>
                  </table>
                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div className="col-md-12 tableResponsive tagImageEditrListTble mt-4">
                <div className="tableOuterWidth">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-3">Media </th>
                        <th className="col-3">Remarks</th>
                        <th className="col-2">Requested</th>
                        <th className="col-2">Rejected</th>
                        <th className="col-2 ">Action</th>
                      </tr>
                    </thead>
                    <div className="table_Outerscroll">
                      {mediaList?.rejected?.map((data, i) => (
                        <tbody className="table_Outertbody pb-3">
                          <tr>
                            <td className="col-3">
                              <div className="popup-image">
                                <div className="popupGallerysection">
                                  <a
                                    href={data.content_media}
                                    title="img"
                                    target="_blank"
                                  >
                                    <img
                                      src={data.content_media}
                                      alt="media-waiting"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      className="img-fluid"
                                    />
                                  </a>
                                </div>
                              </div>
                            </td>

                            <td className="col-3">
                              {editingIndex === i ? (
                                <textarea
                                  type="text"
                                  value={remark}
                                  onChange={handleRemarkNameChange}
                                  className="w-100 form-control"
                                />
                              ) : (
                                <span>{data.remarks}</span>
                              )}
                              {editingIndex !== i ? (
                                <GoPencil
                                  className="ml-2"
                                  onClick={() =>
                                    handleEditClick(i, data.remarks)
                                  }
                                />
                              ) : (
                                ""
                              )}
                              {editingIndex === i && (
                                <div className="mt-2">
                                  <button
                                    onClick={() => handleUpdateClick(i, data)}
                                    className="btn btn-success"
                                  >
                                    Save
                                  </button>
                                  <button
                                    onClick={handleCancelClick}
                                    className="btn btn-secondary ml-1"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              )}
                            </td>
                            <td className="col-2">
                              <p>
                                {" "}
                                {moment(data.requested_date).format(
                                  "DD MMM YYYY"
                                )}
                              </p>
                              <p>{data.requested_by_name}</p>
                            </td>
                            <td className="col-2">
                              <p>
                                {" "}
                                {moment(data.approved_date).format(
                                  "DD MMM YYYY"
                                )}
                              </p>
                              <p>{data.approved_by_name}</p>
                            </td>
                            <td className="col-2">
                              <button
                                type="submit"
                                className="btn btn-success mr-1"
                                onClick={() => resendForApproval(data)}
                              >
                                Resend
                              </button>
                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={(e) => deleteRequest(data)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </div>
                  </table>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>

        {showApprovalPopup && (
          <ApproverPopup
            onClose={closePopup}
            groupData={groupData}
            textContent={content}
            typeOfContent={"Media Content"}
            campaignOptions={campaignOptions}
          />
        )}

        <div className="loder_div" hidden />
        <Footer />
      </div>
    </div>
  );
}

export default ImagecontentMerge;
