import React, { useState, useEffect } from "react";
import { backendApi, re } from "../../apiService";
import LeftMenu from "../../layout/leftMenu";
import Dashboardimage from "../../images/left_icons/settingsIcon.png";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import SettingYoutube from "./settingsYoutube";
import SettingsDataBaseRename from "./settingsDataBaseRename";
import LocalPageSettings from "./localePageSettings";
import SettingsTumblrCredentials from "./settingsTumblrCredentials";
import SettingsTwitterCredentials from "./settingsTwitterCredentials";
import SettingSocialMediaTags from "./settingsSocialmediatags";
import SettingsOrganization from "./settingsOrganization";
import SettingGoogleBusnessProfile from "./settingsGoogleBusnessProfile";
import SettingSeoDetails from "./settingsSeoDetails";
import SettingGoogleToken from "./settingsGoogleToken";
import SettingsChatGPT from "./settingsChatGPT";
import SettingsFacebookInstagram from "./settingsFacebookInstagram";
const Settings = () => {
  const [listingDataOfOrganization, setlistingDataOfOrganization] = useState(
    []
  );
  const [selectedOfOrganization, setselectedOfOrganization] = useState(
    !window.sessionStorage.getItem("switchingOrgId")
      ? window.sessionStorage.getItem("organization")
      : window.sessionStorage.getItem("switchingOrgId")
  );
  const [listingDataOfCatogaries, setlistingDataOfCatogaries] = useState([]);
  const [selectedCatogaries, setselectedCatogaries] = useState("");
  const [listingValuesForPassing, setlistingValuesForPassing] = useState([]);
  const [flad, setflag] = useState(0);
  const [organizationId, setorganizationId] = useState("");
  const [isLogin, setisLogin] = useState(
    window.sessionStorage.getItem("isLogin")
  );
  const [userRole, setuserRole] = useState(
    window.sessionStorage.getItem("role")
  );
  const [newOrganizationValue, setNewOrganizationValue] = useState(0);
  useEffect(() => {
    getUserData();
  }, []);
  var orgadmin = window.sessionStorage.getItem("role") == "ORG_ADMIN";
  const getUserData = () => {
    var req = {
      functionName: "organizationList",
      role:
        window.sessionStorage.getItem("switchUserRole") === ""
          ? window.sessionStorage.getItem("role")
          : window.sessionStorage.getItem("switchUserRole"),
      organization: !window.sessionStorage.getItem("switchingOrgId")
        ? window.sessionStorage.getItem("organization")
        : window.sessionStorage.getItem("switchingOrgId"),
      namesOnly: orgadmin ? "" : "false",
    };
    backendApi(req).then((response) => {
      if (response.success === "1") {
        console.log(response, "responseresponseresponse");
        setlistingDataOfOrganization(response.result);
        setlistingDataOfCatogaries(response.category);
        if (response.result?.length > 0) {
          let filterArray = response.result.filter((item) => {
            if (item.id == selectedOfOrganization) {
              window.sessionStorage.setItem(
                "local_page_isActive",
                item.local_is_active
              );
              console.log(item, "itemitemitem");
              setlistingValuesForPassing(item);
              setorganizationId(item.id);
            }
          });
        }
      }
    });
  };
  const organisationSelect = (value, type) => {
    if (value == "") {
      setlistingValuesForPassing("");
      setorganizationId("");
      setselectedCatogaries("");
      setselectedOfOrganization("");
    }
    if (type === "organisation") {
      setselectedOfOrganization(value);
      let filterArray = listingDataOfOrganization.filter((item) => {
        if (item.id == value) {
          setlistingValuesForPassing(item);
          setorganizationId(item.id);
        }
      });
    } else if (type === "Category") {
      setselectedCatogaries(value);
    } else if (type === "NewOrganization") {
      setNewOrganizationValue(1);
    }
  };
  return (
    <div className="main_wrapper">
      {isLogin === "true" &&
        (userRole === "ROLE_SUPER_ADMIN" ||
          userRole === "ROLE_ADMIN" ||
          userRole === "ORG_ADMIN") && (
          <>
            <LeftMenu></LeftMenu>
            <div className="userListWrapper">
              <Header
                heading="Organization Settings"
                headerImage={Dashboardimage}
              />
              <div className="container-fluid">
                <div className="row">
                  {!orgadmin && (
                    <div className="col-md-4">
                      <select
                        id="organizationSelection"
                        className="form-control mb-0"
                        value={selectedOfOrganization}
                        onChange={(e) => {
                          organisationSelect(e.target.value, "organisation");
                        }}
                      >
                        <option selected="selected" value={""}>
                          {" "}
                          Select Organization{" "}
                        </option>
                        {listingDataOfOrganization.map((item) => (
                          <option value={item.id}>
                            {item.organisation_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {userRole === "ROLE_SUPER_ADMIN" && (
                    <button
                      type="button"
                      className=" btn-save mb-2"
                      onClick={() => organisationSelect("", "NewOrganization")}
                    >
                      New Organization
                    </button>
                  )}
                  {newOrganizationValue !== 0 && (
                    <button
                      type="button"
                      className="  btn-save mb-2 ml-2"
                      onClick={() => {
                        setNewOrganizationValue(0);
                        setlistingValuesForPassing("");
                        setorganizationId("");
                        setselectedCatogaries("");
                        setselectedOfOrganization("");
                      }}
                    >
                      Back
                    </button>
                  )}
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <SettingsOrganization
                      getUserData={() => getUserData()}
                      organizationId={organizationId}
                      listingDataOfCatogaries={listingDataOfCatogaries}
                      listingValuesForPassing={listingValuesForPassing}
                    />
                    {newOrganizationValue === 0 && (
                      <>
                        <LocalPageSettings
                          getUserData={() => getUserData()}
                          organizationId={organizationId}
                          listingValuesForPassing={listingValuesForPassing}
                        />
                        <SettingSeoDetails
                          getUserData={() => getUserData()}
                          listingValuesForPassing={listingValuesForPassing}
                          organizationId={organizationId}
                        />

                        <SettingsTwitterCredentials
                          getUserData={() => getUserData()}
                          organizationId={organizationId}
                          listingValuesForPassing={listingValuesForPassing}
                        />

                        <SettingSocialMediaTags
                          getUserData={() => getUserData()}
                          organizationId={organizationId}
                          listingValuesForPassing={listingValuesForPassing}
                        />
                      </>
                    )}
                  </div>

                  <div className="col-lg-6">
                    {newOrganizationValue === 0 && (
                      <>
                        <SettingGoogleBusnessProfile
                          getUserData={() => getUserData()}
                          organizationId={organizationId}
                          listingValuesForPassing={listingValuesForPassing}
                        />

                        <SettingsDataBaseRename
                          getUserData={() => getUserData()}
                          listingValuesForPassing={listingValuesForPassing}
                          organizationId={organizationId}
                        />

                        <SettingYoutube
                          getUserData={() => getUserData()}
                          listingValuesForPassing={listingValuesForPassing}
                          organizationId={organizationId}
                        />

                        <SettingsTumblrCredentials
                          getUserData={() => getUserData()}
                          organizationId={organizationId}
                          listingValuesForPassing={listingValuesForPassing}
                        />

                        <SettingGoogleToken
                          getUserData={() => getUserData()}
                          organizationId={organizationId}
                          listingValuesForPassing={listingValuesForPassing}
                        />
                          <SettingsFacebookInstagram
                          getUserData={() => getUserData()}
                          organizationId={organizationId}
                          listingValuesForPassing={listingValuesForPassing}
                        />
                        <SettingsChatGPT
                          getUserData={() => getUserData()}
                          listingValuesForPassing={listingValuesForPassing}
                          organizationId={organizationId}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </>
        )}
    </div>
  );
};
export default Settings;
