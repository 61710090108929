import React, { Component } from "react";
import Chart from 'react-apexcharts'
class CustomerActionGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
            series: [{
              name: 'Visit your website',
              data: this.props.getWebsiteData.map(Number),
             }, {
              name: 'Request directions',
              data: this.props.getRequestDirectionData.map(Number),
              },{
              name: 'Call you',
              data: this.props.getPhoneData.map(Number),
             }],
       
            options: {
              chart: {
                type: 'area',
                toolbar: {
                    show: false
                  }
              },
              responsive: [{
                breakpoint: 480,
                options: {
                  legend: {
                    position: 'bottom'
                  }
                }
              }],
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'smooth',
                show: true,
                width: 2,
              },
              xaxis: {
                categories: this.props.customerActionXaxis
              }, 
              yaxis: {
                min:0
              },
              legend: {
                position: 'right'
               
              },   
            },        
          
          };

      }
    render() {
        return (
            <div className="graph_insight">
                <Chart  options={this.state.options} series={this.state.series} type="area"  height={300} />
            </div>
        )
    }
}
export default CustomerActionGraph;