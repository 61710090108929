import React, { useState } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import ReactDatePicker from "react-datepicker";
import Footer from "../layout/footer";
import Accessibiltyimage from "../images/left_icons/GBP.png";
import DatePicker from "react-datepicker";
import { useEffect } from "react";
import { apiCallingPost, backendApi } from "../apiService";
import $ from "jquery";
import NewInsightActionGraph from "./newInsightsGraph";
import moment from "moment";
import NewInsightSingleDataGraph from "./singleDataGraph";

function NewInsightsEditButtonGBP() {
  const [administrativeAreaList, setAdministrativeAreaList] = useState([]);
  const [localityList, setLocalityList] = useState([]);
  const [allLocationData, setAllLocationData] = useState([]);
  const [allAssertiveLocationData, setAllAssertiveLocationData] = useState([]);
  //   const [startDate, setStartDate] = useState(
  //     new Date(new Date().getFullYear(), new Date().getMonth() - 1).toISOString()
  //   );
  //   const [endDate, setEndDate] = useState(new Date().toISOString());
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedMetric, setSelectedMetric] = useState(
    "BUSINESS_IMPRESSIONS_DESKTOP_MAPS"
  );
  const [selectedTimeRange, setSelectedTimeRange] = useState("WEEK");
  const [administrativeArea, setAdministrativeArea] = useState("");
  const [locality, setLocality] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [metricDataXAxis, setMetricDataXAxis] = useState([]);
  const [metricDataYAxis, setMetricDataYAxis] = useState([]);
  const [metricDataXAxis2, setMetricDataXAxis2] = useState([]);
  const [metricDataYAxis2, setMetricDataYAxis2] = useState([]);
  const [metricDataXAxis3, setMetricDataXAxis3] = useState([]);
  const [metricDataYAxis3, setMetricDataYAxis3] = useState([]);
  const [metricDataXAxis4, setMetricDataXAxis4] = useState([]);
  const [metricDataYAxis4, setMetricDataYAxis4] = useState([]);
  const [overViewFlag, setOverViewFlag] = useState(true);
  const [callsFlag, setCallsFlag] = useState(false);
  const [messagesFlag, setMessagesFlag] = useState(false);
  const [bookingsFlag, setBookingsFlag] = useState(false);
  const [directionsFlag, setDirectionsFlag] = useState(false);
  const [websiteClicksFlag, setWebsiteClicksFlag] = useState(false);
  const [metricList, setMetricList] = useState([
    {
      metricDisplayName:
        "The number of times a direction request was requested to the business location ",
      metricValue: "BUSINESS_DIRECTION_REQUESTS",
    },
    {
      metricDisplayName:
        "The number of times the business profile website was clicked ",
      metricValue: "WEBSITE_CLICKS",
    },
    {
      metricDisplayName:
        "The number of food orders received from the business profile ",
      metricValue: "BUSINESS_FOOD_ORDERS",
    },
  ]);
  useEffect(() => {
    GetLocationsUnderAccount();
  }, []);

  // function to invoke the new Insights API
  const getInsightData = (locationId, metric, option, label) => {
    if (locationId == "0") {
      alert("Please select a location");
      return false;
    } else if (metric == "0") {
      alert("Please select a metric");
      return false;
    } else if (option == "0") {
      alert("Please select a Time Range");
      return false;
    }
    $(".loder_div").attr("hidden", false);
    setMetricDataXAxis([]);
    setMetricDataYAxis([]);
    let startDate = "";
    let endDate = "";
    const now = new Date();
    switch (option) {
      case "WEEK": {
        debugger;
        //*Code to get start and end of week starts here
        let variableStartDate = new Date();
        variableStartDate.setDate(variableStartDate.getDate() - 4);
        startDate = new Date(variableStartDate);
        startDate.setDate(
          variableStartDate.getDate() - variableStartDate.getDay()
        );

        endDate = new Date(variableStartDate);
        endDate.setDate(
          variableStartDate.getDate() + (6 - variableStartDate.getDay())
        );
        //*Code to get start and end of week ends here
        break;
      }
      case "MONTH": {
        const now = new Date();
        //* Code to get start and end of month starts here
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        //* Code to get start and end of month ends here
        break;
      }
      case "YEAR": {
        //*Code to get start and end of Year starts here
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 365);
        endDate = now;
        //*Code to get start and end of Year ends here
        break;
      }
      default: {
        alert("Invalid Time Range Selected");
        return false;
      }
    }
    if (now < endDate) {
      endDate = now;
    }
    const apiReq = {
      functionName: "newInsightLocationAnlytics",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      locationId: locationId,
      metric: metric,
      startYear: new Date(startDate).getFullYear(),
      startMonth: new Date(startDate).getMonth() + 1,
      startDay: new Date(startDate).getDate(),
      endYear: new Date(endDate).getFullYear(),
      endMonth: new Date(endDate).getMonth() + 1,
      endDay: new Date(endDate).getDate(),
    };
    backendApi(apiReq).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success == "1") {
        if (option == "YEAR") {
          let dates = data.data.timeSeries.datedValues;
          const groups = dates.reduce((groups, object) => {
            const parsed = new Date(
              object.date.year,
              object.date.month,
              object.date.day
            );
            const year = parsed.getFullYear();
            const month = parsed.getMonth();
            const groupKey = `${month},${year}`;
            if (!groups[groupKey]) {
              groups[groupKey] = [];
            }
            if (object.value) groups[groupKey].push(object.value);
            else groups[groupKey].push("0");
            return groups;
          }, {});
          const groupArrays = Object.keys(groups).map((object) => {
            var date = new Date(
              parseInt(object.split(",")[1]),
              parseInt(object.split(",")[0]) - 1,
              1
            );
            let formattedDate = moment(date).format("MMM-YY");
            return {
              formattedDate,
              values: groups[object],
            };
          });
          groupArrays.map((object) => {
            // Xarray.push(object.formattedDate);
            setMetricDataXAxis((data) => [...data, object.formattedDate]);
            // Yarray.push(object.values.reduce((sum, a) => sum + parseInt(a), 0));
            if (label == 1) {
              setMetricDataYAxis((data) => [
                ...data,
                object.values.reduce((sum, a) => sum + parseInt(a), 0),
              ]);
            } else if (label == 2) {
              setMetricDataYAxis2((data) => [
                ...data,
                object.values.reduce((sum, a) => sum + parseInt(a), 0),
              ]);
            } else if (label == 3) {
              setMetricDataYAxis3((data) => [
                ...data,
                object.values.reduce((sum, a) => sum + parseInt(a), 0),
              ]);
            } else if (label == 4) {
              setMetricDataYAxis4((data) => [
                ...data,
                object.values.reduce((sum, a) => sum + parseInt(a), 0),
              ]);
            }
          });
        } else {
          data.data.timeSeries.datedValues.map((result) => {
            if (result.value) {
              setMetricDataXAxis((object) => [
                ...object,
                moment(
                  new Date(
                    result.date.year,
                    result.date.month - 1,
                    result.date.day
                  )
                ).format("DD-MMM"),
              ]);
              if (label == 1) {
                setMetricDataYAxis((object) => [...object, result.value]);
              } else if (label == 2) {
                setMetricDataYAxis2((object) => [...object, result.value]);
              } else if (label == 3) {
                setMetricDataYAxis3((object) => [...object, result.value]);
              } else if (label == 4) {
                setMetricDataYAxis4((object) => [...object, result.value]);
              }
            } else {
              setMetricDataXAxis((object) => [
                ...object,
                moment(
                  new Date(
                    result.date.year,
                    result.date.month - 1,
                    result.date.day
                  )
                ).format("DD-MMM"),
              ]);
              if (label == 1) {
                setMetricDataYAxis((object) => [...object, "0"]);
              } else if (label == 2) {
                setMetricDataYAxis2((object) => [...object, "0"]);
              } else if (label == 3) {
                setMetricDataYAxis3((object) => [...object, "0"]);
              } else if (label == 4) {
                setMetricDataYAxis4((object) => [...object, "0"]);
              }
            }
          });
        }
      } else {
        // alert("No Insights available for this location");
      }
      //   setMetricDataYAxis([
      //     data.data.timeSeries.datedValues.map(
      //       (data) => new Date(data.date.year, data.date.month, data.date.day)
      //     ),
      //   ]);
      //   setMetricDataXAxis([
      //     data.data.timeSeries.datedValues.map((data) => data.value),
      //   ]);
    });
  };
  const GetLocationsUnderAccount = (val) => {
    $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "getMasterDataForFilter",

      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    console.log("reviewAPIData", reviewAPIData);
    apiCallingPost(reviewAPIData)
      .then(async (result) => {
        if (!result.data || result.success !== "1") {
          alert(result.errorMessage);
          $(".loder_div").attr("hidden", true);
          return;
        }
        const { data } = result;
        // TODO: May need location filter with country. currently only running for india.
        setAllLocationData(
          data.map((eachLocation) => ({
            locationId: eachLocation.location_id,
            location: `${eachLocation?.title} - ${eachLocation?.locality}`,
          }))
        );
        setLocationList(data);
        setAllAssertiveLocationData(data);
        setAdministrativeAreaList(
          new Array(
            ...new Set(
              data.map((eachLocation) => eachLocation.administrativeArea)
            )
          )
        );
        setLocalityList(
          new Array(
            ...new Set(data.map((eachLocation) => eachLocation?.locality))
          )
        );
        // $(".loder_div").attr("hidden", true);
        setSelectedLocation(data[0].location_id);
        setAdministrativeArea("0");
        setLocality("0");
        showOverView(
          data[0].location_id,
          "BUSINESS_IMPRESSIONS_MOBILE_SEARCH",
          "BUSINESS_IMPRESSIONS_MOBILE_MAPS",
          "BUSINESS_IMPRESSIONS_DESKTOP_SEARCH",
          "BUSINESS_IMPRESSIONS_DESKTOP_MAPS",
          selectedTimeRange
        );
      })
      .catch((error) => {
        console.trace(error);
        alert("Something went wrong in getting Location Data");
        $(".loder_div").attr("hidden", true);
      });
  };
  const onStateSelect = (administrativeArea) => {
    setAdministrativeArea(administrativeArea);
    setAllLocationData([]);
    setLocalityList(
      new Array(
        ...new Set(
          locationList
            .filter(
              (eachLocation) =>
                eachLocation?.administrativeArea === administrativeArea
            )
            .map((eachLocation) => eachLocation?.locality)
        )
      )
    );
    setAllAssertiveLocationData(
      locationList.filter(
        (eachLocation) => eachLocation?.administrativeArea == administrativeArea
      )
    );
    setLocality("0");
    setSelectedLocation("0");
    setSelectedMetric("0");
    // setSelectedTimeRange("0");
  };
  const onCitySelect = (locality) => {
    setLocality(locality);
    setAllLocationData(
      allAssertiveLocationData
        .filter((eachLocation) => eachLocation?.locality === locality)
        .map((eachLocation) => ({
          locationId: eachLocation.location_id,
          location: `${eachLocation?.title} - ${eachLocation?.locality}`,
        }))
    );
    setSelectedLocation("0");
    setSelectedMetric("0");
    // setSelectedTimeRange("0");
  };
  const showOverView = (
    locationId,
    mobileSearch,
    mobileMaps,
    desktopSearch,
    desktopMaps,
    timeRange
  ) => {
    setMetricDataXAxis([]);
    setMetricDataYAxis([]);
    setMetricDataYAxis2([]);
    setMetricDataYAxis3([]);
    setMetricDataYAxis4([]);
    setCallsFlag(false);
    setOverViewFlag(true);
    setMessagesFlag(false);
    setBookingsFlag(false);
    setDirectionsFlag(false);
    setWebsiteClicksFlag(false);
    getInsightData(locationId, mobileSearch, timeRange, 1);
    getInsightData(locationId, mobileMaps, timeRange, 2);
    getInsightData(locationId, desktopSearch, timeRange, 3);
    getInsightData(locationId, desktopMaps, timeRange, 4);
  };
  const showCalls = (locationId, callClicks, timeRange) => {
    setMetricDataXAxis([]);
    setMetricDataYAxis([]);
    setCallsFlag(true);
    setMessagesFlag(false);
    setOverViewFlag(false);
    setBookingsFlag(false);
    setDirectionsFlag(false);
    setWebsiteClicksFlag(false);
    getInsightData(locationId, callClicks, timeRange, 1);
  };
  const showMessages = (locationId, messages, timeRange) => {
    setMetricDataXAxis([]);
    setMetricDataYAxis([]);
    setCallsFlag(false);
    setOverViewFlag(false);
    setMessagesFlag(true);
    setBookingsFlag(false);
    setDirectionsFlag(false);
    setWebsiteClicksFlag(false);
    getInsightData(locationId, messages, timeRange, 1);
  };
  const showBookings = (locationId, bookings, timeRange) => {
    setMetricDataXAxis([]);
    setMetricDataYAxis([]);
    setCallsFlag(false);
    setOverViewFlag(false);
    setMessagesFlag(false);
    setBookingsFlag(true);
    setDirectionsFlag(false);
    setWebsiteClicksFlag(false);
    getInsightData(locationId, bookings, timeRange, 1);
  };
  const showDirectionRequests = (locationId, directions, timeRange) => {
    setMetricDataXAxis([]);
    setMetricDataYAxis([]);
    setCallsFlag(false);
    setOverViewFlag(false);
    setMessagesFlag(false);
    setBookingsFlag(false);
    setDirectionsFlag(true);
    setWebsiteClicksFlag(false);
    getInsightData(locationId, directions, timeRange, 1);
  };
  const showWebClicks = (locationId, webclicks, timeRange) => {
    setMetricDataXAxis([]);
    setMetricDataYAxis([]);
    setCallsFlag(false);
    setOverViewFlag(false);
    setMessagesFlag(false);
    setBookingsFlag(false);
    setDirectionsFlag(false);
    setWebsiteClicksFlag(true);
    getInsightData(locationId, webclicks, timeRange, 1);
  };
  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header heading="Insights" headerImage={Accessibiltyimage} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-6">
              <select
                className="form-control"
                required
                value={administrativeArea}
                onChange={(e) => onStateSelect(e.target.value)}
              >
                <option value="0">State</option>
                {administrativeAreaList?.length > 0 &&
                  administrativeAreaList.map((adminArea, index) => (
                    <option key={index} value={adminArea}>
                      {adminArea}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <select
                className="form-control"
                required
                value={locality}
                onChange={(e) => onCitySelect(e.target.value)}
              >
                <option value="0">City</option>
                {localityList?.length > 0 &&
                  localityList.map((eachLocality, index) => (
                    <option key={index} value={eachLocality}>
                      {eachLocality}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <button
                className="btn-search"
                onClick={() => GetLocationsUnderAccount()}
              >
                Clear
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-6">
              <span className="Date_fromToText">Location</span>
              <select
                className="form-control"
                required
                value={selectedLocation}
                onChange={(obj) => {
                  setSelectedLocation(obj.target.value);
                  overViewFlag == true &&
                    showOverView(
                      obj.target.value,
                      "BUSINESS_IMPRESSIONS_MOBILE_SEARCH",
                      "BUSINESS_IMPRESSIONS_MOBILE_MAPS",
                      "BUSINESS_IMPRESSIONS_DESKTOP_SEARCH",
                      "BUSINESS_IMPRESSIONS_DESKTOP_MAPS",
                      selectedTimeRange
                    );
                  callsFlag == true &&
                    showCalls(
                      obj.target.value,
                      "CALL_CLICKS",
                      selectedTimeRange
                    );
                  messagesFlag == true &&
                    showMessages(
                      obj.target.value,
                      "BUSINESS_CONVERSATIONS",
                      selectedTimeRange
                    );
                  bookingsFlag == true &&
                    showBookings(
                      obj.target.value,
                      "BUSINESS_BOOKINGS",
                      selectedTimeRange
                    );
                  directionsFlag == true &&
                    showDirectionRequests(
                      obj.target.value,
                      "BUSINESS_DIRECTION_REQUESTS",
                      selectedTimeRange
                    );
                  websiteClicksFlag == true &&
                    showWebClicks(
                      obj.target.value,
                      "WEBSITE_CLICKS",
                      selectedTimeRange
                    );
                }}
              >
                <option value="0" disabled>
                  Location
                </option>
                {allLocationData.map((object, i) => (
                  <option key={i} value={object.locationId}>
                    {object.location}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <span className="Date_fromToText">Time</span>
              <select
                className="form-control"
                value={selectedTimeRange}
                required
                onChange={(obj) => {
                  setSelectedTimeRange(obj.target.value);
                  overViewFlag == true &&
                    showOverView(
                      selectedLocation,
                      "BUSINESS_IMPRESSIONS_MOBILE_SEARCH",
                      "BUSINESS_IMPRESSIONS_MOBILE_MAPS",
                      "BUSINESS_IMPRESSIONS_DESKTOP_SEARCH",
                      "BUSINESS_IMPRESSIONS_DESKTOP_MAPS",
                      obj.target.value
                    );
                  callsFlag == true &&
                    showCalls(
                      selectedLocation,
                      "CALL_CLICKS",
                      obj.target.value
                    );
                  messagesFlag == true &&
                    showMessages(
                      selectedLocation,
                      "BUSINESS_CONVERSATIONS",
                      obj.target.value
                    );
                  bookingsFlag == true &&
                    showBookings(
                      selectedLocation,
                      "BUSINESS_BOOKINGS",
                      obj.target.value
                    );
                  directionsFlag == true &&
                    showDirectionRequests(
                      selectedLocation,
                      "BUSINESS_DIRECTION_REQUESTS",
                      obj.target.value
                    );
                  websiteClicksFlag == true &&
                    showWebClicks(
                      selectedLocation,
                      "WEBSITE_CLICKS",
                      obj.target.value
                    );
                }}
              >
                <option value="0">Time</option>
                <option key={0} value="WEEK">
                  Last Week
                </option>
                <option key={1} value="MONTH">
                  Monthly
                </option>
                <option key={2} value="YEAR">
                  Yearly
                </option>
              </select>
            </div>
          </div>

          <hr />
          <div className="customer_actionDiv insight_borderbtm">
            <div className="Date_fromToDiv mt-2">
              <div className="row" style={{ margin: "10px" }}>
                <button
                  className="btn btn-outline-primary insight_button"
                  onClick={() =>
                    showOverView(
                      selectedLocation,
                      "BUSINESS_IMPRESSIONS_MOBILE_SEARCH",
                      "BUSINESS_IMPRESSIONS_MOBILE_MAPS",
                      "BUSINESS_IMPRESSIONS_DESKTOP_SEARCH",
                      "BUSINESS_IMPRESSIONS_DESKTOP_MAPS",
                      selectedTimeRange
                    )
                  }
                >
                  Overview
                </button>
                <button
                  className="btn btn-outline-primary insight_button"
                  onClick={() =>
                    showCalls(
                      selectedLocation,
                      "CALL_CLICKS",
                      selectedTimeRange
                    )
                  }
                >
                  Calls
                </button>
                <button
                  className="btn btn-outline-primary insight_button"
                  onClick={() =>
                    showMessages(
                      selectedLocation,
                      "BUSINESS_CONVERSATIONS",
                      selectedTimeRange
                    )
                  }
                >
                  Messages
                </button>
                <button
                  className="btn btn-outline-primary insight_button"
                  onClick={() =>
                    showBookings(
                      selectedLocation,
                      "BUSINESS_BOOKINGS",
                      selectedTimeRange
                    )
                  }
                >
                  Bookings
                </button>
                <button
                  className="btn btn-outline-primary insight_button"
                  onClick={() =>
                    showDirectionRequests(
                      selectedLocation,
                      "BUSINESS_DIRECTION_REQUESTS",
                      selectedTimeRange
                    )
                  }
                >
                  Directions
                </button>
                <button
                  className="btn btn-outline-primary insight_button"
                  onClick={() =>
                    showWebClicks(
                      selectedLocation,
                      "WEBSITE_CLICKS",
                      selectedTimeRange
                    )
                  }
                >
                  Website Clicks
                </button>
              </div>
            </div>

            {/* <div className="insight_totalview">Total actions 81</div> */}

            <div className="customer_actionGraphDiv">
              {overViewFlag == true && (
                <>
                  <NewInsightActionGraph
                    key={metricDataXAxis}
                    XAxisData={metricDataXAxis}
                    YAxisData={metricDataYAxis}
                    YAxisData2={metricDataYAxis2}
                    YAxisData3={metricDataYAxis3}
                    YAxisData4={metricDataYAxis4}
                    YAxisName="Google Search - Mobile"
                    YAxisName2="Google Maps - Mobile"
                    YAxisName3="Google Search - Desktop"
                    YAxisName4="Google Maps - Desktop"
                  />
                </>
              )}
              {callsFlag == true && (
                <>
                  <NewInsightSingleDataGraph
                    key={metricDataXAxis}
                    Label={"Calls"}
                    XAxisData={metricDataXAxis}
                    YAxisData={metricDataYAxis}
                  />
                </>
              )}
              {messagesFlag == true && (
                <>
                  <NewInsightSingleDataGraph
                    key={metricDataXAxis}
                    Label={"Messages"}
                    XAxisData={metricDataXAxis}
                    YAxisData={metricDataYAxis}
                  />
                </>
              )}
              {bookingsFlag == true && (
                <>
                  <NewInsightSingleDataGraph
                    key={metricDataXAxis}
                    Label={"Bookings"}
                    XAxisData={metricDataXAxis}
                    YAxisData={metricDataYAxis}
                  />
                </>
              )}
              {directionsFlag == true && (
                <>
                  <NewInsightSingleDataGraph
                    key={metricDataXAxis}
                    Label={"Directions"}
                    XAxisData={metricDataXAxis}
                    YAxisData={metricDataYAxis}
                  />
                </>
              )}
              {websiteClicksFlag == true && (
                <>
                  <NewInsightSingleDataGraph
                    key={metricDataXAxis}
                    Label={"Website Clicks"}
                    XAxisData={metricDataXAxis}
                    YAxisData={metricDataYAxis}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
}

export default NewInsightsEditButtonGBP;
