import axios from "axios";
import React, { useEffect, useState } from "react";

function TwitterUsersLookup() {
  const [accessToken, setAccessToken] = useState("");
  const [nextPageToken, setNextPageToken] = useState("");
  const [prevPageToken, setPrevPageToken] = useState("");
  const [twittersUsersLookup, setTwitterUsersLookup] = useState([]);
  useEffect(() => {
    getTwitterToken();
  }, []);
  useEffect(() => {
    if (accessToken != "") getTwitterUsersLookup();
  }, [accessToken]);
  const getTwitterToken = () => {
    const tokenUrl =
      "https://jxrlgrrlpa.execute-api.ap-south-1.amazonaws.com/default/twitter-token";
    axios.get(tokenUrl).then((response) => {
      setAccessToken(response.data.access_token);
    });
  };
  const getTwitterUsersLookup = () => {
    const awsUrl =
      "https://rhg453avo6.execute-api.ap-south-1.amazonaws.com/default/twitter-users-lookup";
    var apiParams = {
      access_token: accessToken,
      userNames: "Kotak,Mahindra",
    };
    // setPrevPageToken(token);
    axios.post(awsUrl, apiParams).then((response) => {
      setTwitterUsersLookup(response.data.data);
      console.log("response.meta", response);
      //   setNextPageToken(response.data.meta.next_token);
    });
  };
  return (
    <div className="main_wrapper">
      <div className="userListWrapper">
        <div className="container-fluid">
          {/* <button onClick={() => getTwitterSearch(nextPageToken)}>
            Next Page
          </button> */}
          {/* <button onClick={() => getTwitterSearch(prevPageToken)}>
          Prev Page
        </button> */}
          <div className="col-md-12 tableResponsive">
            <div className="tableOuterWidth">
              <table className="table">
                <thead>
                  <tr>
                    <th className="col-4">Id</th>
                    <th className="col-4">Name</th>
                    <th className="col-4">Username</th>
                  </tr>
                  {/* <tr>Text</tr> */}
                </thead>
                {twittersUsersLookup.map((data) => {
                  return (
                    <tbody className="table_Outertbody">
                      <tr>
                        <td className="col-4">{data.id}</td>
                        <td className="col-4">{data.name}</td>
                        <td className="col-4">{data.username}</td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TwitterUsersLookup;
