import React, { useEffect, useState, useRef } from "react";
import "../css/bootstrap.css";
import LiveMonitoringimage from "../images/left_icons/Google-Chat.png";
import "../css/style.css";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import * as Icon from "react-feather";
import UserImg from "../images/3.png";
import UserImg5 from "../images/5.png";
import UserImg6 from "../images/6.png";
import UserImg7 from "../images/7.png";
import UserImg8 from "../images/8.png";
import UserImg9 from "../images/9.png";
import { backendApi } from "../apiService";
import $ from "jquery";

const ChatBox = () => {
  const [role, setRole] = useState(window.sessionStorage.getItem("role"));
  const[userid,setUserId]=useState(window.sessionStorage.getItem("userId"));
  const msgHistoryRef = useRef(null);
  //const role ="AGENT_MANAGER"
  const [chatMembers, setChatMembers] = useState([]);
  const [selectedChat, setSelectedChat] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [message, setMessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const [agentManagers, setAgentManagers] = useState([]);
  const [selectedManager, setSelectedManager] = useState([]);
  const [agentUnderManager, setAgentUnderManager] = useState([]);
  const [unreadCount, setUnreadCount] = useState([]);
  const[membersVisible,setMembersVisible]=useState(false);
  let filteredAgentManagers=[];
  let managerId="";
  const filteredChatMembers = chatMembers.filter((member) =>
    member.displayName.toLowerCase().includes(searchText.toLowerCase())
  );

  if(!membersVisible)
  {
    filteredAgentManagers=agentManagers.filter((manager)=>
    manager.first_name.toLowerCase().includes(searchText.toLowerCase())
    );
  }else{
    console.log("aMan");
    filteredAgentManagers=agentManagers;
  }


  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const scrollToBottom = () => {
    if (msgHistoryRef.current) {
      msgHistoryRef.current.scrollTop = msgHistoryRef.current.scrollHeight;
    }
  };

  console.log("rollee", role);
  console.log("filter", filteredChatMembers);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const getChatMembers = () => {
    var req = {
      functionName: "chatMembers",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(req)
      .then((data) => {
        console.log("members", data);
        if (data.success == "1") {
          // setChatMembers(data.result);
          setUnreadCount(data.resultCount);
          const updatedResult = data.result.map((item) => {
            const countObj = data.resultCount.find(
              (countItem) => countItem.conversationID === item.conversationID
            );

            if (countObj) {
              return {
                ...item,
                cnt: countObj.cnt,
              };
            }

            return item;
          });

          setChatMembers(updatedResult);
        } else {
          console.log("Error getting chat members");
        }
      })
      .catch((error) => {
        console.log("Error connecting to server");
      });
  };

  const formatTime = (timeString) => {
    const date = new Date(timeString);
    const day = date.toLocaleDateString("en-US", { weekday: "long" });
    const time = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return `${day} ${time}`;
  };

  const handleSend = () => {
    if (message.length > 0) {
      setMessage("");

      const now = new Date();
      const timeNow = now.toISOString();
      const msgs = [...chatHistory];
      msgs.push({
        status: "Send",
        message: message,
        sendTime: timeNow,
        displayName: selectedChat.displayName,
      });
      setChatHistory(msgs);

      let conversationid = selectedChat.conversationID;
      var req = {
        functionName: "chatMessageSave",
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        displayName: selectedChat.displayName,
        conversationID: conversationid,
        sendTime: timeNow,
        message: message,
      };
      console.log("reQQQ", req);
      backendApi(req)
        .then((data) => {
          console.log("sendmessages", data);
          if (data.success == "1") {
            var req = {
              functionName: "googleChatSendMessage",
              conversationId: conversationid,
              siteId:
                window.sessionStorage.getItem("switchingOrgId") === ""
                  ? window.sessionStorage.getItem("organizationId")
                  : window.sessionStorage.getItem("switchingOrgId"),
              message: message,
              representativeType: "HUMAN",
            };
            backendApi(req)
              .then((data) => {
                console.log("inside googlesend", data);
                if (data.success == "1") {
                  var req = {
                    functionName: "chatMembersHistory",
                    conversationId: selectedChat.conversationID,
                  };
                  console.log("updateReq", req);
                  backendApi(req)
                    .then((data) => {
                      console.log("inside updateReq", data);
                      if (data.success == "1") {
                        //setChatHistory(data.result);
                      } else {
                        console.log("Error getting chat history");
                      }
                    })
                    .catch((error) => {
                      console.log("Error connecting to server");
                    });
                  //setChatHistory(data.result);
                } else {
                  console.log("Error getting chat history");
                }
              })
              .catch((error) => {
                console.log("Error connecting to server");
              });
            //setChatMembers(data.result);
          } else {
            console.log("Error getting chat members");
          }
        })
        .catch((error) => {
          console.log("Error connecting to server");
        });
    }
  };

  const getChatHistory = () => {
    let conversationid = selectedChat.conversationID;
    var req = {
      functionName: "chatMembersHistory",
      conversationId: conversationid,
    };
    console.log("req", req);
    backendApi(req)
      .then((data) => {
        console.log("convsss", data);
        if (data.success == "1") {
          setChatHistory(data.result);
          const unreadIds = data.result
            .filter((item) => item.isRead === 0) 
            .map((item) => item.id); 
            if(unreadIds.length>0){
              var isReadReq = {
                functionName: "updateIsReadFlag",
                chatIds:unreadIds
              };
              console.log("reqRead",isReadReq)
              backendApi(isReadReq)
              .then((data) => {
                console.log("dataisRead",data)
                if (data.success === "1") {
                  getChatMembers();
                } else {
                  console.log("Error updating isRead status");
                }
              })
              .catch((error) => {
                console.log("Error connecting to server");
              });
            }
        } else {
          console.log("Error getting chat history");
        }
      })
      .catch((error) => {
        console.log("Error connecting to server");
      });
  };

  const getAgentManagers = () => {
    var agentReq = {
      functionName: "getAgentManager",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    console.log("agentReq", agentReq);
    backendApi(agentReq)
      .then((data) => {
        console.log("agentMags", data);
        if (data.success === "1") {
          setAgentManagers(data.result);
        } else {
          console.log("Error getting agent managers");
        }
      })
      .catch((error) => {
        console.log("Error connecting to server");
      });
  };

  const getAgentsUnderManager = () => {
    if(role=="ORG_ADMIN"){
      managerId=userid;
    }
    var req = {
      functionName: "getAgentUnderManager",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
        managerId: managerId
    };
    console.log("agentunderManager", req);
    backendApi(req)
      .then((data) => {
        console.log("agentunderMgs", data);
        if (data.success === "1") {
          setAgentUnderManager(data.result);
          console.log("set!!");
        } else {
          console.log("Error getting agent managers");
        }
      })
      .catch((error) => {
        console.log("Error connecting to server");
      });
  };

  useEffect(() => {
    if (role === "CHAT_AGENT") {
      console.log("role is not admin");
      $("#user_second").css("display", "none");
    } 
    else if(role=="ORG_ADMIN") {
      $("#user_third").css("display", "none");
      $(".headerChat").css("display", "none");

      $("#user_one ").on("click", function () {
        $("#user_one .chat_list").removeClass("activeUser");
        $("#user_one .chat_list").removeClass("active_chat");
        $(this).toggleClass("activeUser");
        $(this).toggleClass("active_chat");
        if ($("#user_second").is(":visible")) {
          $("#user_second").css("display", "none");
          // $("#user_one").slideDown(2000, function () {
          $("#user_one").css("width", "100%"); // Set width to 100%
          // });
        } else {
          $("#user_second").slideDown(2000, function () {});
          $(".headerChat").css("display", "block");
          $("#user_second").css("borderLeft", "3px solid rgb(195 217 223)");
        }
      });

      $("#user_second ").on("click", function () {
        $("#user_second .chat_list").removeClass("activeUser");
        $("#user_second .chat_list").removeClass("active_chat");
        $("#user_second").css("borderLeft", "1px solid rgb(195 217 223)");
        $(".user_one").css("width", "132px");
        $(this).toggleClass("activeUser");
        $(this).toggleClass("active_chat");
        if ($("#user_third").is(":visible")) {
          setMembersVisible(false)
          $("#user_third").css("display", "none");
          // $("#user_second").slideDown(2000, function () {
          $("#user_second").css("width", "100%"); // Set width to 100%
          // });
        } else {
          setMembersVisible(true)
          $("#user_third").slideDown(2000, function () {});
          $("#user_third").css("borderLeft", "3px solid rgb(195 217 223)");

          $("#user_one .chat_list").mouseenter(function () {
            $("#user_one").css("width", "100%");
          });
          $("#user_second .chat_list").mouseleave(function () {
            $("#user_one").css("width", "132px");
          });
        }
      });

      $("#user_third .chat_list").on("click", function () {
        $(".rightSectionMessages").css("display", "block");
        $("#user_third .chat_list").removeClass("active_chat");        
        $(this).toggleClass("active_chat");
      });
    }
    else{
      $("#user_second").css("display", "none");
      $("#user_third").css("display", "none");
      $(".headerChat").css("display", "none");

      $("#user_one ").on("click", function () {
        $("#user_one .chat_list").removeClass("activeUser");
        $("#user_one .chat_list").removeClass("active_chat");
        $(this).toggleClass("activeUser");
        $(this).toggleClass("active_chat");
        if ($("#user_second").is(":visible")) {
          $("#user_second").css("display", "none");
          // $("#user_one").slideDown(2000, function () {
          $("#user_one").css("width", "100%"); // Set width to 100%
          // });
        } else {
          $("#user_second").slideDown(2000, function () {});
          $(".headerChat").css("display", "block");
          $("#user_second").css("borderLeft", "3px solid rgb(195 217 223)");
        }
      });

      $("#user_second ").on("click", function () {
        $("#user_second .chat_list").removeClass("activeUser");
        $("#user_second .chat_list").removeClass("active_chat");
        $("#user_second").css("borderLeft", "1px solid rgb(195 217 223)");
        $(".user_one").css("width", "132px");
        $(this).toggleClass("activeUser");
        $(this).toggleClass("active_chat");
        if ($("#user_third").is(":visible")) {
          setMembersVisible(false)
          $("#user_third").css("display", "none");
          // $("#user_second").slideDown(2000, function () {
          $("#user_second").css("width", "100%"); // Set width to 100%
          // });
        } else {
          setMembersVisible(true)
          $("#user_third").slideDown(2000, function () {});
          $("#user_third").css("borderLeft", "3px solid rgb(195 217 223)");

          $("#user_one .chat_list").mouseenter(function () {
            $("#user_one").css("width", "100%");
          });
          $("#user_second .chat_list").mouseleave(function () {
            $("#user_one").css("width", "132px");
          });
        }
      });

      $("#user_third .chat_list").on("click", function () {
        $(".rightSectionMessages").css("display", "block");
        $("#user_third .chat_list").removeClass("active_chat");        
        $(this).toggleClass("active_chat");
      });
    }
  }, []);

  useEffect(() => {
    if (role == "ROLE_SUPER_ADMIN" || role == "ROLE_ADMIN") {
      getAgentManagers();
    }
    else if(role=="ORG_ADMIN"){
      getAgentsUnderManager();
    }
    getChatMembers();
  }, []);

  useEffect(() => {
    if (role == "ROLE_SUPER_ADMIN" || role == "ROLE_ADMIN" || role=="ORG_ADMIN" ) {
      console.log("useEffect");
      getAgentsUnderManager();
    }
  }, [selectedManager]);

  useEffect(() => {
    console.log("selected", selectedChat);
    getChatHistory();

    const interval = setInterval(() => {
      getChatHistory();
    }, 10000); // 60000 milliseconds = 1 minute

    return () => {
      clearInterval(interval);
    };
  }, [selectedChat]);
  

  const commonLeftMenu = <LeftMenu></LeftMenu>;

  const commonHeader = (
    <Header heading="Chat" headerImage={LiveMonitoringimage} />
  );

  const commonSearchBar = (
    <div className="headind_srch">
      <div className="srch_bar">
        <div className="stylish-input-group">
          <input
            type="text"
            className="search-bar"
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
          />
          <span className="input-group-addon">
            <button type="button">
              <Icon.Search></Icon.Search>
            </button>
          </span>
        </div>
      </div>
    </div>
  );

  const commonChatList = (
    <div className="inbox_chat user_third" id="user_third">
      {filteredChatMembers.map((member, index) => (
        <div className="chat_list" onClick={() => setSelectedChat(member)}>
          <div className="chat_people">
            <div className="chat_circle">
              <span className="chat_initial">
                {member.displayName.charAt(0)}
              </span>
            </div>
            <div className="chat_ib">
              <h5>
                {member.displayName}
                <span class="badge badge-danger">{member.cnt}</span>
              </h5>

              <span className="chat_date">
                {new Date(member.maxSendTime).toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "long",
                })}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const commonMsgHistory = (
    <div className="msg_history" ref={msgHistoryRef}>
      {chatHistory.map((message, index) => {
        const isReceived = message.status == "Send" || message.status == "Sent";
        return (
          <div
            key={index}
            className={isReceived ? "outgoing_msg" : "incoming_msg"}
          >
            {!isReceived && (
              <div className="chat_circle">
                <span className="chat_initial">
                  {message.displayName.charAt(0)}
                </span>
              </div>
            )}
            <div className={isReceived ? "sent_msg" : "received_msg"}>
              {!isReceived && (
                <small className="received_msger_name">
                  {message.displayName}
                </small>
              )}
              <div className="received_withd_msg">
                <p>{message.message}</p>
                <span className="time_date">
                  {formatTime(message.sendTime)}
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );

  const commonTypeMsg = (
    <div className="type_msg">
      <div className="input_msg_write">
        <input
          type="text"
          className="write_msg"
          placeholder="Type a message"
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSend();
            }
          }}
          value={message}
        />
        <button className="msg_send_btn" type="button" onClick={handleSend}>
          <Icon.Send />
        </button>
      </div>
    </div>
  );

  const AgentManagerView = (
    <div className="main_wrapper">
      {commonLeftMenu}
      <div className="userListWrapper">
        {commonHeader}
        <div className="messaging">
          <div className="inbox_msg">
            <div className="inbox_people">
              {commonSearchBar}
              <div className="inbox_user">
                <div className="inbox_chat user_one" id="user_one">
                  {filteredAgentManagers.map((manager, index) => (
                    <div
                      className="chat_list"
                      onClick={() => setSelectedManager(manager)}
                    >
                      <div className="chat_people">
                        <div className="chat_circle">
                          <span className="chat_initial">
                            {manager.first_name.charAt(0)}
                          </span>
                        </div>
                        <div className="chat_ib">
                          <h5>
                            {manager.first_name + " " + manager.last_name}
                          </h5>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="inbox_chat user_second" id="user_second">
                  {agentUnderManager.map((agent, index) => (
                    <div
                      className="chat_list"
                      // onClick={() => setSelectedManager(manager)}
                    >
                      <div className="chat_people">
                        <div className="chat_circle">
                          <span className="chat_initial">
                            {agent.first_name.charAt(0)}
                          </span>
                        </div>
                        <div className="chat_ib">
                          <h5>{agent.first_name + " " + agent.last_name}</h5>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {commonChatList}
              </div>
            </div>
            <div className="rightSectionMessages">
              {selectedChat && (
                <div className="selectedUserContainer">
                  <div className="selectedUser">{selectedChat.displayName}</div>
                </div>
              )}
              {commonMsgHistory}
              {commonTypeMsg}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );

  const OrgAdminView=(
    <div className="main_wrapper">
      {commonLeftMenu}
      <div className="userListWrapper">
        {commonHeader}
        <div className="messaging">
          <div className="inbox_msg">
            <div className="inbox_people">
              {commonSearchBar}
              <div className="inbox_user">
                <div className="inbox_chat user_second" id="user_second">
                  {agentUnderManager.map((agent, index) => (
                    <div
                      className="chat_list"
                      // onClick={() => setSelectedManager(manager)}
                    >
                      <div className="chat_people">
                        <div className="chat_circle">
                          <span className="chat_initial">
                            {agent.first_name.charAt(0)}
                          </span>
                        </div>
                        <div className="chat_ib">
                          <h5>{agent.first_name + " " + agent.last_name}</h5>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {commonChatList}
              </div>
            </div>
            <div className="rightSectionMessages">
              {selectedChat && (
                <div className="selectedUserContainer">
                  <div className="selectedUser">{selectedChat.displayName}</div>
                </div>
              )}
              {commonMsgHistory}
              {commonTypeMsg}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );

  const ChatAgentView = (
    <div className="main_wrapper">
      {commonLeftMenu}
      <div className="userListWrapper">
        {commonHeader}
        <div className="messaging">
          <div className="inbox_msg">
            <div className="inbox_people">
              {/* <div className="headerChat">Chat With Unni</div> */}
              {commonSearchBar}
              <div className="inbox_user">{commonChatList}</div>
            </div>
            <div className="rightSectionMessages">
              {selectedChat && (
                <div className="selectedUserContainer">
                  <div className="selectedUser">{selectedChat.displayName}</div>
                </div>
              )}
              {commonMsgHistory}
              {commonTypeMsg}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );

  const chatView =
    role == "ROLE_SUPER_ADMIN" || role == "ROLE_ADMIN"
      ? AgentManagerView
      : role === "CHAT_AGENT"
      ? ChatAgentView
      : role=="ORG_ADMIN"
      ? OrgAdminView
      : null;

  return chatView;
};

export default ChatBox;
