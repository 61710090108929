import React, { useEffect } from 'react';
import ApexCharts from 'apexcharts';

const DonutChart = () => {
  useEffect(() => {
    const chartContainer = document.getElementById('chart');
    chartContainer.style.width = '100%';
    var options = {
      series: [60.61, 30], // Only two series
      chart: {
        type: 'donut',
        width: '230px', 
      },
      labels: ['Google (60.61 %)', 'Meta (30 %)'], // Labels for the two series
      colors: ['#3ABBB5', '#F9BF00'],
      dataLabels: {
        enabled: false, // Disable data labels for the individual segments
      },
      plotOptions: {
        pie: {
          donut: {
            size: '75%', 
            labels: {
              show: true,
              name: {
                show: false, // Hide the name in the center
              },
              value: {
                show: true, // Hide the value in the center
              },
              total: {
                show: true,
                formatter: function (w) {
                  return '96.61%'; // Display 96.61% in the center
                },
                style: {
                  fontSize: '16px',
                  fontWeight: 'bold',
                },
              },
            },
             background: '#F5F3EF', 
             
          },
        },
      },
      legend: {
        position: 'bottom', // Set legend position to bottom
        show: false,
        markers: {
          shape: 'square', // Set the shape of the legend markers to square
        },
      },
      
      responsive: [{
        breakpoint: 1024,
        options: {
          chart: {
            width: 140,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: 991,
        options: {
          chart: {
            width: 240,
          },
          legend: {
            position: 'bottom',
          },
        },
      }],
    };

    var chart = new ApexCharts(document.querySelector("#chart"), options);
    chart.render();

    // Cleanup function to destroy chart on component unmount
    return () => {
      chart.destroy();
    };
  }, []);

  return (
    <div id="chart" style={{ width: '100%' }}></div>
  );
};

export default DonutChart;
