import React, { useState, useEffect, useRef } from 'react'
import { backendDashboardApi } from "../apiService";

const TableInvoice = ({ selectedStartDate, areaOffice,invoiceStatus,finalisedTableData, handleIncrementApiCount, onDataReceived  }) => {

    const [salesReportList, setSalesReportList] = useState([])
    const [salesGrantTotal, setSalesGrantTotal] = useState([])
    let formattedStartDate = new Date(selectedStartDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
    });
    let fromMonth = new Date(selectedStartDate).toLocaleString('en-US', { month: 'long' });
    useEffect(() => {
        if(finalisedTableData == null && invoiceStatus !== "Finalized"){
        handleSalesReportList();
        }
        else{
            if(finalisedTableData){
                onDataReceived(finalisedTableData) 
                setSalesReportList(finalisedTableData.invoiceSaleData)
                setSalesGrantTotal(finalisedTableData)
                handleIncrementApiCount();
            }
        }

    }, [finalisedTableData]);

    const handleSalesReportList = () => {

        const postData = {

            "functionName": "invoiceSalesReport",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "fromDate": formattedStartDate,
            "areaOffice": areaOffice,
            "userId": window.sessionStorage.getItem("userId")
        }
        backendDashboardApi(postData).then((data) => {
            console.log("🚀 ~ backendDashboardApi ~ postData:", data.result)
            if (data.success === "1") {
                onDataReceived(data.result[0]) 
                setSalesReportList(data.result[0].invoiceSaleData)
                setSalesGrantTotal(data.result[0])
                handleIncrementApiCount();
            } else {
                setSalesReportList([])
            }
        });
    }




    const container = { width: "711px", margin: "0px auto", padding: "5px" };
    const page = { width: "711px", padding: 0, margin: "0 auto", background: "white" };
    const colContent = { fontSize: "12px", margin: "2px 0px 0px", background: "#d9e7fd", padding: "5px" };
    const col1 = { width: "237px", float: "left" };
    const tableStr = { width: "711px", float: "left", display: "flex", justifyContent: "space-between", fontSize: "12px", fontWeight: "600", marginTop: "20px", borderBottom: "2px solid rgb(178 199 233)" };
    const tableHeadMain = { marginBottom: "0px", fontSize: "12px", width: "181px", textAlign: "left" };
    const tableHeadMain2 = { marginBottom: "0px", fontSize: "12px", width: "224px", textAlign: "right" };
    const tableHeadMain3 = { marginBottom: "0px", fontSize: "12px", width: "115px", textAlign: "right" };
    const tableHeadMain4 = { marginBottom: "0px", fontSize: "12px", width: "115px", textAlign: "right" };
    const tableHeadMain5 = { marginBottom: "0px", fontSize: "12px", width: "100px", textAlign: "right" };

    const tableHead1 = { marginBottom: "0px", fontSize: "12px", width: "181px", textAlign: "left", background: "#d9e7fd", padding: "2px" };
    const tableHead2 = { marginBottom: "0px", fontSize: "12px", width: "224px", textAlign: "right", background: "#d9e7fd", padding: "2px" };
    const tableHead3 = { marginBottom: "0px", fontSize: "12px", width: "115px", textAlign: "right", background: "#d9e7fd", padding: "2px" };
    const tableHead4 = { marginBottom: "0px", fontSize: "12px", width: "115px", textAlign: "right", background: "#d9e7fd", padding: "2px" };
    const tableHead5 = { marginBottom: "0px", fontSize: "12px", width: "100px", textAlign: "right", background: "#d9e7fd", padding: "2px" };


    const tableHead11 = { marginBottom: "0px", fontSize: "12px", width: "181px", textAlign: "left", background: "#fff", padding: "2px" };
    const tableHead21 = { marginBottom: "0px", fontSize: "12px", width: "224px", textAlign: "right", background: "#fff", padding: "2px" };
    const tableHead31 = { marginBottom: "0px", fontSize: "12px", width: "115px", textAlign: "right", background: "#fff", padding: "2px" };
    const tableHead41 = { marginBottom: "0px", fontSize: "12px", width: "115px", textAlign: "right", background: "#fff", padding: "2px" };
    const tableHead51 = { marginBottom: "0px", fontSize: "12px", width: "100px", textAlign: "right", background: "#fff", padding: "2px" };



    const tableHead = { marginBottom: "0px", fontSize: "12px" };
    const tableTd = { width: "711px", float: "left", display: "flex", justifyContent: "space-between", fontSize: "12px", borderBottom: "1px solid #ccc", padding: "8px 0px" };
    const tableTdNoBdr = { width: "711px", float: "left", display: "flex", justifyContent: "space-between", fontSize: "12px", padding: "8px 0px" };
const tableTdNoBdrWhite = {width: "711px", float: "left", display: "flex", justifyContent: "space-between", fontSize: "12px", padding: "8px 0px"}
    return (
        <div id="container" className="container" style={container}>
            {salesReportList.length > 0 ?
                <>
                <div className="page" style={page}>
                    <div className="subpage" style={{ clear: "both" }}>
                        <div style={col1}>
                            <p style={colContent} className='InvFontSize'>{formattedStartDate}</p>
                        </div>
                        <div style={col1}>
                            <p style={colContent} className='InvFontSize'>{areaOffice}</p>
                        </div>
                        <div className="table">
                            <div style={tableStr} className='InvFontSize'>
                                <p style={tableHead1} className='InvFontSize'>Row Labels</p>
                                <p style={tableHead2} className='InvFontSize'>Count of Customer Phone number</p>
                                <p style={tableHead3} className='InvFontSize'>Count of Enquiry</p>
                                <p style={tableHead4} className='InvFontSize'>Count of Booking </p>
                                <p style={tableHead5} className='InvFontSize'>Count of Retail</p>
                            </div>
                            {console.log("🚀 ~ TableInvoice ~ salesReportList:", salesReportList)}
                            {salesReportList && salesReportList.map((data) => (
                                <div style={tableTd}>
                                    <p style={tableHeadMain} className='InvFontSize'><b>{data.dealerName} </b></p>
                                    <p style={tableHeadMain2} className='InvFontSize'><b>{data.customerPhoneNoCount.toLocaleString('en-IN')}</b></p>
                                    <p style={tableHeadMain3} className='InvFontSize'><b>{data.enquiryNoCount.toLocaleString('en-IN')}</b></p>
                                    <p style={tableHeadMain4} className='InvFontSize'><b>{data.bookingCount.toLocaleString('en-IN')}</b></p>
                                    <p style={tableHeadMain5} className='InvFontSize'><b>{data.retailsCount.toLocaleString('en-IN')}</b></p>
                                </div>
                            ))}

                            {/* Rest of the rows */}
                            <div style={tableTdNoBdr}>
                                <p style={tableHead1} className='InvFontSize'><b>Grand Total </b></p>
                                <p style={tableHead2} className='InvFontSize'><b>{salesGrantTotal.totalcustomerPhoneNoCount.toLocaleString('en-IN')}</b></p>
                                <p style={tableHead3} className='InvFontSize'><b>{salesGrantTotal.totalenquiryNoCount.toLocaleString('en-IN')}</b></p>
                                <p style={tableHead4} className='InvFontSize'><b>{salesGrantTotal.totalbookingCount.toLocaleString('en-IN')}</b></p>
                                <p style={tableHead5} className='InvFontSize'><b>{salesGrantTotal.totalretailsCount.toLocaleString('en-IN')}</b></p>
                            </div>
                            <div style={tableTdNoBdrWhite}>
                                <p style={tableHead11} className='InvFontSize'>&nbsp;</p>
                                <p style={tableHead21} className='InvFontSize'>&nbsp;</p>
                                <p style={tableHead31} className='InvFontSize'>&nbsp;</p>
                                <p style={tableHead41} className='InvFontSize'>&nbsp;</p>
                                <p style={tableHead51} className='InvFontSize'>&nbsp;</p>
                            </div>
                            <br/>
                        </div>
                    </div>
                </div>
                <br/>
                 <br/>
                </>
                : ""}
                <br/>
        </div>
    );
};

export default TableInvoice;
