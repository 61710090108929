import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import * as Icon from "react-feather";
import $ from "jquery";
import AddGroup from "./addGroup";
export default function GroupsList(props) {
  const [editProps, setEditProps] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ props:", props);
  }, []);
  const editShowPopup = (data) => {
    // setPopupShow(true);
    setEditProps(data);
    setEditMode(true);
    $("#assignGroupEdit").attr("hidden", false);
    // props.contentInit();
  };
  const closePopup = (e) => {
    setEditMode(e);
    props.setEditMode(e);
  };
  const closeHere = () => {
    $("#assignGroup").attr("hidden", true);
  };
  return (
    <>
      <div className="module_close">
        <Icon.XCircle onClick={() => closeHere()} />
      </div>
      <div className="ml-2 mb-3">History</div>
      <div className="col-md-12 tableResponsive tagImageEditrListTble">
        <div className="tableOuterWidth" style={{minWidth: '500px',width:'100%'}}>
          {props.approverGroup && props.approverGroup.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th className="col-1">Id </th>
                  <th className="col-3">Group Name </th>
                  <th className="col-4">Locations</th>
                  <th className="col-4 ">Status/Edit</th>
                </tr>
              </thead>
              {props.approverGroup.map((data, i) => {
                return (
                  <div className="table_Outerscroll ">
                    <tbody className="table_Outertbody pb-3">
                      <tr>
                        <td className="col-1">{i + 1}</td>
                        <td className="col-3">{data.group_name}</td>
                        <td className="col-4">{data.locations}</td>
                        <td className="col-4 d-flex align-items-center">
                          <Switch
                            className="switch_case"
                            onChange={(e) => props.activeDeactive(e, data.id)}
                            checked={data.is_active === 1 ? true : false}
                          />
                          <Icon.Edit
                            onClick={() => editShowPopup(data)}
                            className="ml-3"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </div>
                );
              })}
            </table>
          ) : (
            <h1>No Groups Available</h1>
          )}
          <div id="assignGroupEdit" className="adv_modal" hidden>
            <div className="adv_modal_inner">
              <AddGroup
                key={editMode}
                contentInit={props.contentInit}
                editMode={editMode}
                editProps={editProps}
                setEditMode={closePopup}
                statesList={props.statesList}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
