import React, { useState, useEffect } from "react";
import { backendApi, backendDashboardApi } from "../apiService";
import Select from "react-select";

var divisionlistValue = []
var headerListNew = []
function UserCreationAdd(props) {
    const [instanceCount, setInstanceCount] = useState(1)
    const [saveflag, setSaveFlag] = useState(true)
    const [userName, setUserName] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [password, setPassword] = useState(3536)
    const [zoneList, setZoneList] = useState([])
    const [divisionList, setDivisionList] = useState({ [`divisionList0`]: [] })
    const arrayObject = [
        {
            "mastername": "Zone",
            "name": "zoneId",
        },
        {
            "mastername": "Region",
            "name": "stateId",
        },
        {
            "mastername": "State Zone",
            "name": "stateZoneId",
        },
        {
            "mastername": "District",
            "name": "districtId",
        },
        {
            "mastername": "City",
            "name": "cityId",
        },
        {
            "mastername": "Locality",
            "name": "localityId",
        },
        {
            "mastername": "Dealer",
            "name": "dealerMasterId",
        }
    ]
        
    const [userPermissionList, setUserPermissionList] = useState([
        {
            // "groupNumber": new Date().getTime(),
            "selectedOptions": {
                "masterName": "",
                "masterId": "",
                "selectAll": false,
                "specific": false,
                "hasParent": false
            },
            "result": [

            ]
        },])
    const [editFlag, setEditFlag] = useState(false)
    const [editUserId, setEditUserId] = useState(false)
    const [addMoreTime, setAddMoreTime] = useState(new Date().getTime())
    const [selectedParentIdNew, setSelectedParentIdNew] = useState({ [`selectedParentId0-0`]: null });
    const [divisionsArray, setDivisionsArray] = useState({ [`divisionsArray0-0`]: null });
    const [RoleArray, setRoleArray] = useState([])
    const [selectRole, setSelectRole] = useState("")
    useEffect(() => {
        divisionDetailApi();
        headerListingApi();
        roleDetailApi();
    }, [])
    useEffect(() => {
        const fetchData = async () => {
            try {
                await divisionDetailApi();
                await headerListingApi();
                if (props.edit === "edit") {
                    setEditFlag(true)

                    setEditUserId(props.dataValue.secUserId)
                    const userID = props.dataValue.secUserId;
                    const postData = {
                        "functionName": "getUserAccessPermissions",
                        "userId": userID
                    };

                    const data = await backendApi(postData);


                   let divisionBackup = []
                    if (data.success === "1") {
                        setFirstName(data.result.userPermissions[0].firstName)
                        setLastName(data.result.userPermissions[0].lastName)
                        setUserName(data.result.userPermissions[0].email)
                        setPassword("")
                        setSelectRole(data.result.userPermissions[0].roleId)
                        const updatedUserPermissions = data.result.userPermissions.map(permission => {
                            const groupNumber = permission.groupNumber;
                            const updatedResult = permission.result.map(result => ({
                                ...result,
                                groupNumber: groupNumber
                            }));
                            return {
                                ...permission,
                                result: updatedResult
                            };
                        });
                        setUserPermissionList(updatedUserPermissions);
                        console.log('updatedUserPermissions master....', updatedUserPermissions);
                        console.log('headerListNew....', headerListNew);
                        console.log('arrayObject....', arrayObject);
                        updatedUserPermissions.forEach((obj, index) => {
                            console.log('updatedUserPermissions obj....', obj);
                            if (obj.selectedOptions.hasParent === true) {
                                headerListNew.forEach((headerObj, headerIndex) => {
                                    console.log('headerObj.organisatonDivisionMasterName....', headerObj.organisatonDivisionMasterName);
                                    console.log('headerObj.organization_division_master_id....', headerObj.organization_division_master_id);
                                    console.log('obj.selectedOptions.masterId....', obj.selectedOptions.masterId);
                                   // if (headerObj.organization_division_master_id <= obj.selectedOptions.masterId) {
                                        console.log('iffffffffffffffff....');
                                        var keyObjectValue = arrayObject.filter((arrayValue => arrayValue.mastername === headerObj.organisatonDivisionMasterName))[0].name
                                        console.log('keyObjectValue....', keyObjectValue);
                                        let states = []
                                        if (headerIndex === 0) {
                                            states = divisionlistValue
                                        }
                                        else {
                                            states = divisionBackup[`divisionsArray${index}-${headerIndex}`]
                                        }
                                        console.log('headerIndex....', headerIndex);
                                        console.log('states....',states);
                                        setDivisionsArray((prevSavedValues) => ({
                                            ...prevSavedValues,
                                            [`divisionsArray${index}-${headerIndex}`]: states,
                                        }));
                                        divisionBackup[`divisionsArray${index}-${headerIndex}`] = states
                                        

                                        // if(typeof divisionBackup[`divisionsArray${index}-${headerIndex}`] !== "undefined"){

                                        // }
                                        if (obj.selectedOptions.selectAll === true) {
                                            console.log('obj.result[0][keyObjectValue]..........',obj.result[0][keyObjectValue]);
                                            setSelectedParentIdNew((prevselectedParentId) => ({
                                                ...prevselectedParentId,
                                                [`selectedParentId${index}-${headerIndex}`]: obj.result[0][keyObjectValue],
                                            }));
                                            setDivisionsArray((prevSavedValues) => ({
                                                ...prevSavedValues,
                                                [`divisionsArray${index}-${headerIndex + 1}`]: states.find(zone => zone.id == obj.result[0][keyObjectValue])?.result || []
                                            }));
                                            divisionBackup[`divisionsArray${index}-${headerIndex + 1}`] = states.find(zone => zone.id == obj.result[0][keyObjectValue])?.result || []
                                        }
                                        else {
                                            
                                            setSelectedParentIdNew((prevselectedParentId) => ({
                                                ...prevselectedParentId,
                                                [`selectedParentId${index}-${headerIndex}`]: obj.result[0][keyObjectValue],
                                            }));
                                            setDivisionsArray((prevSavedValues) => ({
                                                ...prevSavedValues,
                                                [`divisionsArray${index}-${headerIndex + 1}`]: states.find(zone => zone.id == obj.result[0][keyObjectValue])?.result || []
                                            }));
                                            divisionBackup[`divisionsArray${index}-${headerIndex + 1}`] = states.find(zone => zone.id == obj.result[0][keyObjectValue])?.result || []
                                        }
                                        console.log('divisionBackup.............',divisionBackup)


                                   // }
                                })
                            }
                            else {
                                setDivisionsArray((prevSavedValues) => ({
                                    ...prevSavedValues,
                                    [`divisionsArray${index}-0`]: divisionlistValue
                                }));
                                divisionBackup[`divisionsArray${index}-0`] = divisionlistValue
                            }
                        })
                    }

                    const postData1 = {
                        "functionName": "getAccountDivisionDetails",
                        "accountId": window.sessionStorage.getItem("switchingOrgId") === ""
                            ? window.sessionStorage.getItem("organizationId")
                            : window.sessionStorage.getItem("switchingOrgId"),
                    };

                    const data1 = await backendDashboardApi(postData1);

                    if (data1.success === "1") {
                        setZoneList(data1.result.result);
                    }
                }
            } catch (error) {
                console.error("Error in fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const handleOptionClick = (outerIndex, option) => {

        const updatedResult = [...userPermissionList];
        let groupNumber = addMoreTime
        if (updatedResult[outerIndex].result.length > 0) {
            groupNumber = updatedResult[outerIndex].result[0].groupNumber
        }
        updatedResult[outerIndex].selectedOptions.selectAll = (option === "all" ? true : false)
        updatedResult[outerIndex].selectedOptions.specific = (option === "specific" ? true : false)
        if (updatedResult[outerIndex].selectedOptions.hasParent === false && updatedResult[outerIndex].selectedOptions.selectAll === true) {

            updatedResult[outerIndex].result = []
            updatedResult[outerIndex].result.push({
                "allPermissions": true,
                "zoneId": "",
                "stateId": "",
                "stateZoneId": "",
                "districtId": "",
                "cityId": "",
                "localityId": "",
                "dealerId": "",
                "dealerMasterId":"",
                "groupNumber": groupNumber
            })
        }
        else if (updatedResult[outerIndex].selectedOptions.hasParent === true && updatedResult[outerIndex].selectedOptions.selectAll === true) {
            let o = {
                "allPermissions": false,
                "zoneId": "",
                "stateId": "",
                "stateZoneId": "",
                "districtId": "",
                "cityId": "",
                "localityId": "",
                "dealerId": "",
                "dealerMasterId":"",
                "groupNumber": groupNumber
            }
            headerListNew.forEach((headerObj, headerIndex) => {
                if (headerObj.organization_division_master_id < updatedResult[outerIndex].selectedOptions.masterId) {
                    var keyObjectValue = arrayObject.filter((arrayValue => arrayValue.mastername === headerObj.organisatonDivisionMasterName))[0].name
                    o[keyObjectValue] = updatedResult[outerIndex].result[0][keyObjectValue]
                }
            })
            updatedResult[outerIndex].result = []
            updatedResult[outerIndex].result.push(o)
        }
        else {
            updatedResult[outerIndex].result = updatedResult[outerIndex].result.map(permission => ({
                ...permission,
                allPermissions: false,
                groupNumber: groupNumber,
            }))
        }
        setUserPermissionList(updatedResult);
    };
    const roleDetailApi = () => {
        return new Promise((resolve, reject) => {
            const postData = {
                "functionName": "getActiveDashBoardUsersDetails",
            };

            try {
                backendDashboardApi(postData).then((data) => {
                    if (data.success === "1") {
                        setRoleArray(data.result);
                        resolve(true)
                    }
                })
            } catch (error) {
                throw error;
            }
        })
    };


    const divisionDetailApi = () => {
        return new Promise((resolve, reject) => {
            const postData = {
                "functionName": "getAccountDivisionDetails",
                "accountId": window.sessionStorage.getItem("switchingOrgId") === ""
                    ? window.sessionStorage.getItem("organizationId")
                    : window.sessionStorage.getItem("switchingOrgId"),
            };

            try {
                backendDashboardApi(postData).then((data) => {
                    if (data.success === "1") {
                        console.log('divisionlistValue..***********...',data.result.result);
                        divisionlistValue = data.result.result
                        setDivisionsArray((prevdivisionsArray) => ({
                            ...prevdivisionsArray,
                            [`divisionsArray0-0`]: data.result.result,
                        }));
                        resolve(true)
                    }
                })
            } catch (error) {
                console.error("Error in divisionDetailApi:", error);
                throw error;
            }
        })
    };
    const headerListingApi = () => {
        return new Promise((resolve, reject) => {
            const postData = {
                "functionName": "getOrganisationDivisionByAccount",
                "accountId": window.sessionStorage.getItem("switchingOrgId") === ""
                    ? window.sessionStorage.getItem("organizationId")
                    : window.sessionStorage.getItem("switchingOrgId"),
            };
            backendApi(postData).then((data) => {
                if (data.success == 1) {
                    headerListNew = data.result.filter((h) => h.is_active === true)
                    resolve(true)
                }
            }).catch(error => {
            });
        })
    }



    const divisionChange = (organisatonDivisionMasterName, index, organization_division_master_id, innerIndex) => {
        const updatedResult = [...userPermissionList]; // Create a copy of the userPermissionList array
        updatedResult[index].selectedOptions.masterId = organization_division_master_id;
        updatedResult[index].selectedOptions.masterName = organisatonDivisionMasterName;
        // if (updatedResult[index].result.length > 0) {
        updatedResult[index].result = []
        updatedResult[index].selectedOptions.selectAll = false
        updatedResult[index].selectedOptions.specific = false
        updatedResult[index].selectedOptions.hasParent = false
        // }
        setUserPermissionList(updatedResult);

        const updatedSelectedParentIdNew = { ...selectedParentIdNew };
        for (let i = 0; i < innerIndex; i++) {
            updatedSelectedParentIdNew[`selectedParentId${index}-${i}`] = [];
            if ((i + 1) === innerIndex) {
                divisionsArray[`divisionsArray${index}-${i + 1}`] = [];
            }
        }
        setDivisionsArray(divisionsArray)
        setSelectedParentIdNew(updatedSelectedParentIdNew);


    }






    const specificCheck = (e, outerIndex, headerName) => {
        const { value, checked } = e.target;
        const updatedResult = [...userPermissionList];
        let groupNumber = addMoreTime
        if (updatedResult[outerIndex].result.length > 0) {
            groupNumber = updatedResult[outerIndex].result[0].groupNumber
        }
        var keyObjectValue = arrayObject.filter((arrayValue => arrayValue.mastername === headerName))[0].name
        var checkDataExists = updatedResult[outerIndex].result.filter((arrayValue => arrayValue[keyObjectValue] === value))
        if (checkDataExists.length === 0) {
            console.log('ifffff');
            if (updatedResult[outerIndex].selectedOptions.hasParent === true) {
                let isSpecificDataExists = updatedResult[outerIndex].result.filter((obj) => (obj[keyObjectValue] !== ""))
                if (isSpecificDataExists.length === 0) {
                    updatedResult[outerIndex].result = updatedResult[outerIndex].result.map(permission => ({
                        ...permission,
                        allPermissions: false,
                        [keyObjectValue]: value,
                    }));
                }
                else {
                    const newItem = { ...updatedResult[outerIndex].result[0] }
                    newItem[keyObjectValue] = value
                    updatedResult[outerIndex].result.push(newItem)
                }
            }
            else {
                let hasValue = false;
                if (updatedResult[outerIndex].result.length === 1) {
                    let k = updatedResult[outerIndex].result[0]
                    headerListNew.forEach((headerObj, headerIndex) => {
                        var keyObjectValue1 = arrayObject.filter((arrayValue => arrayValue.mastername === headerObj.organisatonDivisionMasterName))[0].name
                        if (k[keyObjectValue1] !== "") {
                            hasValue = true;
                        }
                    })
                    if (hasValue === false) {
                        updatedResult[outerIndex].result[updatedResult[outerIndex].result.length - 1][keyObjectValue] = value
                    }
                    else {
                        updatedResult[outerIndex].result.push({
                            "allPermissions": false,
                            "zoneId": "",
                            "stateId": "",
                            "stateZoneId": "",
                            "districtId": "",
                            "cityId": "",
                            "localityId": "",
                            "dealerId": "",
                            "dealerMasterId":"",
                            "groupNumber": groupNumber
                        })
                        updatedResult[outerIndex].result[updatedResult[outerIndex].result.length - 1][keyObjectValue] = value
                    }
                }
                else {
                    updatedResult[outerIndex].result.push({
                        "allPermissions": false,
                        "zoneId": "",
                        "stateId": "",
                        "stateZoneId": "",
                        "districtId": "",
                        "cityId": "",
                        "localityId": "",
                        "dealerId": "",
                        "dealerMasterId":"",
                        "groupNumber": groupNumber
                    })
                    updatedResult[outerIndex].result[updatedResult[outerIndex].result.length - 1][keyObjectValue] = value
                }
            }
        }
        else {
            console.log('elseeeeeeeeeeeee');
            if (updatedResult[outerIndex].result.length === 1) {
                updatedResult[outerIndex].result = updatedResult[outerIndex].result.map(permission => ({
                    ...permission,
                    allPermissions: false,
                    [keyObjectValue]: "",
                }))
            }
            else {
                updatedResult[outerIndex].result = updatedResult[outerIndex].result.filter(permission => permission[keyObjectValue] !== value)
            }
            checkDataExists = updatedResult[outerIndex].result
        }
        setUserPermissionList(updatedResult);
    };

    const handleFirstSelectFieldChange = (event, i, outerIndex, indexname) => {
        const selectedValue = event.target.value;
        const states = divisionsArray[`divisionsArray${outerIndex}-${i}`].find(zone => zone.id == selectedValue)?.result || [];

        setDivisionsArray((prevSavedValues) => ({
            ...prevSavedValues,
            [`divisionsArray${outerIndex}-${i + 1}`]: states,
        }));
        setSelectedParentIdNew((prevSavedValues) => ({
            ...prevSavedValues,
            [`selectedParentId${outerIndex}-${i}`]: selectedValue,
        }));
        const updatedResult = [...userPermissionList];

        let groupNumber = addMoreTime
        if (updatedResult[outerIndex].result.length > 0) {
            groupNumber = updatedResult[outerIndex].result[0].groupNumber
        }

        updatedResult[outerIndex].selectedOptions.hasParent = true;
        setUserPermissionList(updatedResult);
        var keyObjectValue = arrayObject.filter((arrayValue => arrayValue.mastername === indexname))[0].name
        if (userPermissionList[outerIndex].result.length === 0) {
            updatedResult[outerIndex].result.push({
                "allPermissions": "",
                "zoneId": "",
                "stateId": "",
                "stateZoneId": "",
                "districtId": "",
                "cityId": "",
                "localityId": "",
                "dealerId": "",
                "dealerMasterId":"",
                "groupNumber": groupNumber
            })
            updatedResult[outerIndex].result[updatedResult[outerIndex].result.length - 1][keyObjectValue] = selectedValue
        }
        else {
            updatedResult[outerIndex].result = updatedResult[outerIndex].result.map(permission => ({
                ...permission,
                allPermissions: false,
                [keyObjectValue]: selectedValue,

            }));
            setUserPermissionList(updatedResult);
        }
    };


    const addMoreHandle = () => {

        setInstanceCount(prevInstances => prevInstances + 1);
        setDivisionsArray((prevDivisions) => ({
            ...prevDivisions,
            [`divisionsArray${userPermissionList.length}-0`]: divisionsArray[`divisionsArray0-0`],
        }));

        setAddMoreTime(new Date().getTime())
        const newObj = {
            "selectedOptions": {
                "masterName": "",
                "masterId": "",
                "selectAll": ""
            },
            result: [

            ]
        };

        setUserPermissionList(prevState => [...prevState, newObj]);

    }

    const saveHandle = () => {
        setSaveFlag(false)
        if(!firstName){
            alert("Please enter First Name")
            setSaveFlag(true)
            return 
        }
        else if(!userName){
            alert("Please enter User Name")
            setSaveFlag(true)
            return 
        }
        else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userName)) {
            alert("Please enter a valid email address");
            setSaveFlag(true);
            return;
        }
        else if(!selectRole){
            alert("Please select Role")
            setSaveFlag(true)
            return 
        }
        debugger
        const updatedUserPermissions = userPermissionList.map(permission => {
            const updatedResult = permission.result.map(resultItem => ({
                ...resultItem,
                selectedOptions: permission.selectedOptions // Assign the selectedOptions object from the parent permission
            }));
            const { selectedOptions, ...permissionWithoutSelectedOptions } = permission;
            return {
                ...permissionWithoutSelectedOptions,
                result: updatedResult
            };
        });
        const extractedObjects = updatedUserPermissions.flatMap(permission => permission.result);
        var postData = {
            "functionName": "saveUserAccessPermissions",
            "userId": editUserId,
            "firstName": firstName,
            "lastName": lastName,
            "email": userName,
            "password": password,
            "userRoleId": selectRole,
            "createdOn": editFlag === true ? "" : new Date().getTime(),
            "createdBy": editFlag === true ? "" : (window.sessionStorage.getItem("switchUserId") === ""
                ? window.sessionStorage.getItem("userId")
                : window.sessionStorage.getItem("switchUserId")),
            "modifiedOn": editFlag === true ? new Date().getTime() : "",
            "modifiedBy": editFlag === true ? (window.sessionStorage.getItem("switchUserId") === ""
                ? window.sessionStorage.getItem("userId")
                : window.sessionStorage.getItem("switchUserId")) : "",
            "accountId": window.sessionStorage.getItem("switchingOrgId") === ""
                ? window.sessionStorage.getItem("organizationId")
                : window.sessionStorage.getItem("switchingOrgId"),
            "organisation": window.sessionStorage.getItem('switchUserOrganization'),
            "userPermissions": extractedObjects
        }
        console.log("🚀handleSave ~ backendDashboardApi ~ data:", postData)
        backendDashboardApi(postData).then((data) => {
            if (data.success === "1") {
                alert("Saved Successfully");
                setSaveFlag(true)
                props.addHandle()
            } else {
                setSaveFlag(true)
            }
        }).catch(error => {
            console.error("Error while saving:", error);
        });

    }
    const handleRoleSelect = (event) => {
        setSelectRole(event.target.value)
    }

    return (
        <div>
            <div className="organisation-div organization-card user-creation">
                <div className="row">
                    <div className="col-md-6">
                        <div class="form-group row pr-0 pr-lg-5">
                            <label class="col-md-4 col-form-label">First Name</label>
                            <div class="col-md-8">
                                <input type="text" class="form-control-db mb-0" placeholder="First Name" autoComplete="off" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div class="form-group row pr-0 pr-lg-5">
                            <label class="col-md-4 col-form-label">Last Name</label>
                            <div class="col-md-8">
                                <input type="text" class="form-control-db mb-0" placeholder="Last Name" autoComplete="off" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div class="form-group row pr-0 pr-lg-5">
                            <label class="col-md-4 col-form-label">User Name</label>
                            <div class="col-md-8">
                                <input type="text" class="form-control-db mb-0" placeholder="User Name" autoComplete="off" value={userName} onChange={(e) => setUserName(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    {/* {editFlag===false &&
                <div className="col-md-6">
                    <div class="form-group row pr-0 pr-lg-5">
                        <label class="col-md-4 col-form-label">Password</label>
                        <div class="col-md-8">
                            <input type="password" class="form-control-db mb-0" placeholder="Password" autoComplete="off" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                    </div>
                </div>} */}
                    <div className="col-md-6">
                        <div class="form-group row pr-0 pr-lg-5">
                            <label class="col-md-4 col-form-label">Role</label>
                            <div class="col-md-8">
                                <select

                                    onChange={(e) => handleRoleSelect(e)}
                                    value={selectRole}
                                    className="form-control-db"
                                >
                                    <option value="">Select</option>

                                    {RoleArray.map((dataValue) => (
                                        <option key={dataValue.id} value={dataValue.id}>
                                            {dataValue.display_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div>
                <div class="subhead pt-4 pl-3">Add dealer</div>
                {userPermissionList.map((userPermissionListObj, outerIndex) => (

                    <div key={outerIndex} className="organisation-div organization-card user-creation">
                        <div className="row mt-4">
                            {headerListNew.filter((data) => data.is_active === true).map((item, innerIndex) =>
                                <div className="col-md-3 col-xl-2 col-sm-4" key={`${outerIndex}-${innerIndex}`}>
                                    {console.log("................................................................", userPermissionList)}
                                    <div className="organisation-subdiv d-flex">
                                        <span>{item.organisatonDivisionMasterName}</span>

                                        <div className="checkbox_div custom-radio">
                                            <input type="radio"
                                                id={`${item.organisatonDivisionMasterName}-${outerIndex}`}
                                                name={`customRadio-${outerIndex}`}
                                                class="custom-control-input"
                                                onChange={() => divisionChange(item.organisatonDivisionMasterName, outerIndex, item.organization_division_master_id, innerIndex)}
                                                checked={userPermissionList[outerIndex].selectedOptions.masterName === item.organisatonDivisionMasterName} />
                                            <label class="custom-control-label" for={`${item.organisatonDivisionMasterName}-${outerIndex}`}>
                                            </label>
                                        </div>

                                    </div>
                                </div>)}
                        </div>

                        {userPermissionList[outerIndex].selectedOptions.masterName != "" &&
                            <>
                                {headerListNew.some((h) => h.organisatonDivisionMasterName === userPermissionListObj.selectedOptions.masterName && h.is_active) ? (
                                    headerListNew.map((h, index) => {
                                        if (h.organisatonDivisionMasterName === userPermissionListObj.selectedOptions.masterName && h.is_active) {
                                            return (
                                                <div key={outerIndex}>
                                                    {Array.from({ length: index + 1 }).map((_, i) => (
                                                        <div className="form_row" key={i + outerIndex}  >
                                                            {i < index ? (
                                                                <>
                                                                    <label className="custom-dashboard-label col-md-3 col-lg-2">
                                                                        {headerListNew[i].organisatonDivisionMasterName}
                                                                    </label>
                                                                    <select

                                                                        onChange={(e) => handleFirstSelectFieldChange(e, i, outerIndex, headerListNew[i].organisatonDivisionMasterName)}
                                                                        value={selectedParentIdNew[`selectedParentId${outerIndex}-${i}`]}
                                                                        className="form-control-db col-md-4 py-2 mb-2"
                                                                    >
                                                                        <option value="">Select</option>

                                                                        {divisionsArray[`divisionsArray${outerIndex}-${i}`] && divisionsArray[`divisionsArray${outerIndex}-${i}`].map((dataValue) => (
                                                                            <option key={dataValue.id} value={dataValue.id}>
                                                                                {dataValue.accountDivisionName}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </>
                                                            ) : (
                                                                <div className="row">
                                                                    <div className="col-md-12 mt-4">
                                                                        <div className="card" style={{ borderRadius: "10px" }}>
                                                                            <div className="card-body p-3">
                                                                                <>
                                                                                    <div class="organisation-subdiv custom-control custom-radio border-0 mb-0">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id={`SelectallZoneState-${outerIndex}`}
                                                                                            name={`SelectallZoneState-${outerIndex}`}
                                                                                            className="custom-control-input"
                                                                                            checked={userPermissionList[outerIndex].selectedOptions.selectAll}
                                                                                            onChange={() => handleOptionClick(outerIndex, "all", i)}
                                                                                        />
                                                                                        <label class="custom-control-label" for={`SelectallZoneState-${outerIndex}`}>
                                                                                            <span className="ml-2">Select all  {headerListNew[i].organisatonDivisionMasterName}</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="d-flex px-5 py-2">Or</div>
                                                                                    <div class="organisation-subdiv custom-control custom-radio border-0 mb-0">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id={`specificState-${outerIndex}`}
                                                                                            name={`specificState-${outerIndex}`}
                                                                                            className="custom-control-input"
                                                                                            checked={userPermissionList[outerIndex].selectedOptions.specific}
                                                                                            onChange={() => handleOptionClick(outerIndex, "specific", i)}
                                                                                        />
                                                                                        <label class="custom-control-label" for={`specificState-${outerIndex}`}>
                                                                                            <span className="ml-2">Choose specific  {headerListNew[i].organisatonDivisionMasterName}</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {userPermissionList[outerIndex].selectedOptions.specific === true &&
                                                                                        <div className="ml-4 row">
                                                                                            {console.log(".........................................................!!!!", divisionsArray[`divisionsArray${outerIndex}-${i}`])}
                                                                                            {divisionsArray[`divisionsArray${outerIndex}-${i}`] && divisionsArray[`divisionsArray${outerIndex}-${i}`].filter(filterValue => filterValue.masterName === userPermissionListObj.selectedOptions.masterName).map((data, indexSub) => (
                                                                                                <div className="col-md-5 col-lg-4 col-xl-3">
                                                                                                    <div className="organisation-subdiv" key={index}>
                                                                                                        <span>{data.accountDivisionName}</span>
                                                                                                        <div className="checkbox_subdiv">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                id={`html${indexSub}-${outerIndex}`}
                                                                                                                className="checkbox_input"
                                                                                                                value={data.id}
                                                                                                                checked={(userPermissionListObj.result.filter((obj) => (obj[arrayObject.filter((arrayValue => arrayValue.mastername === userPermissionListObj.selectedOptions.masterName))[0].name].toString() == data.id.toString()))).length > 0 ? true : false}
                                                                                                                onChange={(e) => specificCheck(e, outerIndex, headerListNew[i].organisatonDivisionMasterName)}
                                                                                                            />
                                                                                                            <label htmlFor={`html${indexSub}-${outerIndex}`} className="checkbox_label mb-0"></label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>}
                                                                                </>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            );
                                        }
                                    })
                                ) : null}
                            </>}
                    </div>

                ))}
                <div className="col-md-12 m-0 mt-3 text-right">
                    <button className="db-btn mr-3" onClick={addMoreHandle}>
                        Add More
                    </button>
                    <button className="db-btn" onClick={saveflag ? () => saveHandle() : null}>
                        Save
                    </button>
                </div>
            </div>
        </div>

    );
}
export default UserCreationAdd;
