import React, { useState, useEffect } from "react";
import "../css/bootstrap.css";
import * as Icon from "react-feather";
import "../css/style.css";
import $ from "jquery";
import { useLocation } from "react-router-dom";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import { backendApi, pythonBackendApi } from "../apiService";
import LeftMenu from "../layout/leftMenu.jsx";
import Analysisimage from "../images/left_icons/analysis.png";
import Pagination from "react-js-pagination";

const CompetiterAnalysisAuditList = () => {
  const location = useLocation();
  let tablevalue  = location.state.tableId;
  let addressvalue = location.state.addressMatch
  let businessvalue = location.state.businessMatch
  let phnovalue = location.state.phnoMatch
  const [totalLocations, settotalLocations] = useState('');
  const [AddressMatch, setAddressMatch] = useState(addressvalue);
  const [PhoneNumberMatch, setPhoneNumberMatch] = useState(phnovalue);
  const [BusinessNameMatch, setBusinessNameMatch] = useState(businessvalue);

  const [competitorData, setcompetitorData] = useState([]);
  const [tableId, settableId] = useState(tablevalue);


  useEffect(() => {
    getAuditExcelDataByMasterId()

  }, []);
  const getAuditExcelDataByMasterId = () => {
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "getCompitetorDataByMasterId",
      masterId: tableId,
      search: $("#searchAddress").val(),
      startId:'',
      endId:''
    };

    backendApi(req).then((data) => {
      if(data.success =="1"){
        $(".loder_div").attr("hidden", true);
        setcompetitorData(data.data);
        settotalLocations(data.totalCount)
      }else{
        $(".loder_div").attr("hidden", true);
      }


  
    });
  };
  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header heading="Competitor Analysis" headerImage={Analysisimage} />

        <div className="container">

            <div className="visAccuracy_textDiv mb-1">
              <div className="visAccuracy_text">Total Locations  <Icon.ArrowRight /> <span className="visAccuracy_textBold">{totalLocations}</span></div>
              <div className="visAccuracy_text">Address Match <Icon.ArrowRight /> <span className="visAccuracy_textBold">{(Math.trunc(AddressMatch))}%</span></div>
              <div className="visAccuracy_text">Phone Number Match <Icon.ArrowRight /> <span className="visAccuracy_textBold">{(Math.trunc(PhoneNumberMatch))}%</span></div>
              <div className="visAccuracy_text">Business Name Match <Icon.ArrowRight /> <span className="visAccuracy_textBold">{(Math.trunc(BusinessNameMatch))}%</span></div>
            </div>
            <hr className="mb-0"></hr>
            <hr className="my-0"></hr>
              
            <div className="col-md-12 tableResponsive">
              <div className="tableOuterWidth">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="col-1">Ref Code</th>
                      <th className="col-3">Name</th>
                      <th className="col-5">Address</th>
                      <th className="col-1">Address Match</th>
                      <th className="col-1">Ph.No Match</th>
                      <th className="col-1">Business Name Match</th>
                    </tr>
                  </thead>
                  <div class="table_Outerscroll">
                    {competitorData.map((item)=>(
                    <tbody class="table_Outertbody">
                      <tr class="stickyDiv">
                        <td className="col-1">{item.id}</td>
                        <td className="col-3">{item.location_name}</td>
                        <td className="col-5">
                        {item.address}
                        </td>
                        <td className="col-1">
                          {item.address_match}
                        </td>
                        <td className="col-1">
                        {item.phone_number_match}
                        </td>
                        <td className="col-1">
                        {item.business_name_match}
                        </td>                      
                      </tr>
                    </tbody>
                    ))}
                  </div>
                </table>
          
              </div>
            </div>

        </div>
        <Footer/>
      </div>
      <div className="loder_div" hidden />
    </div>
  );

}

export default CompetiterAnalysisAuditList;
