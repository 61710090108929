import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { backendApi } from "../../apiService";
import "../../css/bootstrap.css";
import "../../css/style.css";
import $, { data } from "jquery";
import Header from "../../layout/header.jsx";
import Footer from "../../layout/footer";
import LeftMenu from "../../layout/leftMenu.jsx";
import Auditimage from "../../images/left_icons/processing.png";
import DragDropFile from "../../audit/dragDropFile";
import * as XLSX from "xlsx";
import DataInput from "../../audit/dataInput";
import * as Icon from "react-feather";
import EditListingComponent from "./components/editLisitng";
import VerifyListingComponent from "./components/verifyListing";
import OTPListingComponent from "./components/otpListing";
import stringreplacenpm from "stringreplacenpm";
import InviteAdminToListingComponent from "./components/inviteAdmin";

function UidPushDataList() {
  const location = useLocation();
  const [UID, setUID] = useState();
  const [failReason, setFailReason] = useState("");
  const [propsData, setPropsData] = useState("");
  const [verifyMethod, setVerifyMethod] = useState("");
  const [verificationPin, setVerificationMethod] = useState("");
  const [ownerEmailId, setOwnerEmailId] = useState("");
  const [skipVerificationPopup, setSkipVerificationPopup] = useState(false);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(true);
  const [visibility, setVisibility] = useState(false);
  const [verifyVisibility, setVerifyVisibility] = useState(false);
  const [inviteAdminVisibility, setInviteAdminVisibility] = useState(false);
  const [OTPVisibility, setOTPVisibility] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorListId, setErrorListId] = useState(0);
  const [pushDataList, setPushDataList] = useState([]);
  const [errorDetails, setErrorDetails] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  useEffect(() => {
    listWithUiId();
    getCategoriesListGoogle();
  }, []);

  useEffect(() => {
    setIsChecked2(!isChecked1);
  }, [isChecked1]);

  useEffect(() => {
    setIsChecked1(!isChecked2);
    setOwnerEmailId("");
  }, [isChecked2]);
  const listWithUiId = () => {
    // setUID(parseInt(location.pathname.split("/").pop()));
    // e.preventDefault();
    const apiReq = {
      functionName: "list_push_bulk_by_uid",
      uid: location.pathname.split("/").pop(),
    };
    $(".loder_div").attr("hidden", false);
    backendApi(apiReq).then((result) => {
      $(".loder_div").attr("hidden", true);
      if (result.success === "1") {
        setPushDataList(result.data);
      } else {
        // alert(result.data.error.message);
      }
    });
  };
  const getCategoriesListGoogle = () => {
    const apiReq = {
      functionName: "googleLocationCategoryList",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apiReq).then((data) => {
      setCategoriesList(data.data.categories);
    });
  };
  const createNewLocation = async (dataList) => {
    let validation = [];
    for (const [index, obj] of dataList.entries()) {
      if (obj.status === "Push Initiated") {
        var filteredCategory = [];
        if (obj?.category) {
          filteredCategory = categoriesList.filter(
            (data) =>
              data.displayName.toLowerCase() === obj.category.toLowerCase()
          );
        }
        let validation = await createValidationCheck(obj, index);
        let identifyId = obj.id;
        if (validation.valid === false) {
          var req = {
            functionName: "createNewLocation",
            siteId:
              window.sessionStorage.getItem("switchingOrgId") === ""
                ? window.sessionStorage.getItem("organizationId")
                : window.sessionStorage.getItem("switchingOrgId"),
            name: obj.full_name,
            title: obj.full_name,
            Description: obj.Description?.obj.Description,
            PhoneNo: "+91" + obj.mobile_no,
            categoryName:
              filteredCategory.length > 0
                ? filteredCategory[0]?.name
                : "categories/gcid:business_center",
            categoryDisplayName:
              filteredCategory.length > 0
                ? filteredCategory[0]?.displayName
                : "Business Centre",
            storeCode: obj.storeCode,
            AdministrativeArea: obj.administrativeArea,
            Locality: obj.locality,
            Address: obj.address,
            latitude: obj.latitude,
            longitude: obj.longitude,
            // WebsiteUrl: obj.WebsiteUrl?.obj.WebsiteUrl,
            postalCode: obj.postalCode,
            validate: false,
          };
          $(".loder_div").attr("hidden", false);
          backendApi(req).then((result) => {
            $(".loder_div").attr("hidden", true);
            if (result.success === "1") {
              const apiReq = {
                functionName: "updatePushStatus",
                location_id: result.data.name.split("/").pop(),
                g_pin: "",
                status:
                  window.sessionStorage.getItem("skipVerification") == 1
                    ? "Skipped Verification"
                    : "Location Created",
                id: identifyId,
                full_name: obj.full_name,
                address: obj.address,
                mobile_no: obj.mobile_no,
                email_id:
                  window.sessionStorage.getItem("skipVerification") == 1
                    ? window.sessionStorage.getItem("ownerEmailId")
                    : "",
                category:
                  filteredCategory.length > 0
                    ? filteredCategory[0]?.name
                    : "categories/gcid:business_center",
                locality: obj.locality,
                latitude: obj.latitude,
                storeCode: obj.storeCode,
                longitude: obj.longitude,
                administrativeArea: obj.administrativeArea,
                postalCode: obj.postalCode,
                verification_code: "",
                errorMessage: "",
              };
              if (window.sessionStorage.getItem("skipVerification") == 1) {
                let admins = window.sessionStorage
                  .getItem("ownerEmailId")
                  .split(",");
                for (const x of admins) {
                  const inviteAdmin = {
                    functionName: "InviteAdminToLocationPush",
                    siteId:
                      window.sessionStorage.getItem("switchingOrgId") === ""
                        ? window.sessionStorage.getItem("organizationId")
                        : window.sessionStorage.getItem("switchingOrgId"),
                    email: x,
                    role: "OWNER",
                    locationid: result.data.name.split("/").pop(),
                  };
                  backendApi(inviteAdmin);
                }
              }
              updatePushStatus(apiReq);
              //   this.recallCreateLocation(
              //     result.data.name != undefined ? result.data.name.split("/").pop() : ""
              //   );
            } else {
              // alert("");
              const apiReq = {
                functionName: "updatePushStatus",
                location_id: "",
                g_pin: "",
                status: "Location Creation Failed",
                id: identifyId,
                full_name: obj.full_name,
                address: obj.address,
                mobile_no: obj.mobile_no,
                email_id:
                  window.sessionStorage.getItem("skipVerification") == 1
                    ? window.sessionStorage.getItem("ownerEmailId")
                    : "",
                latitude: obj.latitude,
                longitude: obj.longitude,
                storeCode: obj.storeCode,
                locality: obj.locality,
                administrativeArea: obj.administrativeArea,
                postalCode: obj.postalCode,
                verification_code: "",
                category:
                  filteredCategory.length > 0
                    ? filteredCategory[0]?.name
                    : "categories/gcid:business_center",
                errorMessage: JSON.stringify(
                  result?.data?.error?.details?.map((data) => data)
                ).replaceAll(/'/g, "''"),
                // errorMessage: result?.data?.error?.details
                //   .map(
                //     (data) =>
                //       data.reason.replaceAll("_", " ") +
                //       " IN " +
                //       data.metadata.field_mask.replaceAll("_", " ").toUpperCase()
                //   )
                //   .join(),
              };
              updatePushStatus(apiReq);
              if (
                result.data.error.code == 400 &&
                result.data.error.details.length > 0
              ) {
                const errorArray = result.data.error.details.map((errorMap) => {
                  return errorMap.fieldViolations[0].description;
                });
                setErrorDetails((obj) => [
                  ...obj,
                  { id: identifyId, errors: errorArray },
                ]);
              }
              // alert(obj.full_name + "-" + result.data.error.details[0].reason);
              //   $("#failReason" + identifyId).text(
              //     result.data.error.details[0].reason
              //   );
            }
          });
        } else {
          const apiReq = {
            functionName: "updatePushStatus",
            location_id: "",
            g_pin: "",
            status: "Location Creation Failed",
            id: identifyId,
            full_name: obj.full_name,
            address: obj.address,
            mobile_no: obj.mobile_no,
            email_id:
              window.sessionStorage.getItem("skipVerification") == 1
                ? window.sessionStorage.getItem("ownerEmailId")
                : "",
            latitude: obj.latitude,
            longitude: obj.longitude,
            storeCode: obj.storeCode,
            locality: obj.locality,
            administrativeArea: obj.administrativeArea,
            postalCode: obj.postalCode,
            verification_code: "",
            category:
              filteredCategory.length > 0
                ? filteredCategory[0]?.name
                : "categories/gcid:business_center",
            errorMessage: validation.details.map((data) => data),
            // errorMessage: result?.data?.error?.details
            //   .map(
            //     (data) =>
            //       data.reason.replaceAll("_", " ") +
            //       " IN " +
            //       data.metadata.field_mask.replaceAll("_", " ").toUpperCase()
            //   )
            //   .join(),
          };
          updatePushStatus(apiReq);
          setErrorDetails((obj) => [
            ...obj,
            { id: identifyId, errors: validation.details },
          ]);
        }
      }
    }
  };
  const createValidationCheck = async (obj, index) => {
    let validation = { id: index, valid: false, details: [] };
    let validationAmt = 0;
    if (obj.address.split(",").length > 5) {
      validation["details"].push("Address has more than 5 entries");
      validation["valid"] = true;
    }
    if (obj.latitude === "") {
      validation["details"].push("Latitude field is empty");
      validation["valid"] = true;
    }
    if (obj.longitude === "") {
      validation["details"].push("Longitude field is empty");
      validation["valid"] = true;
    }
    if (
      obj.category == "undefined" ||
      obj.category == null ||
      obj.category == ""
    ) {
      validation["details"].push("Category field is empty");
      validation["valid"] = true;
    }
    return validation;
  };
  const updatePushStatus = (obj) => {
    backendApi(obj).then(async (result) => {
      if (result.success === "1") {
        listWithUiId();
      }
    });
  };
  const popupCloseHandler = (e) => {
    setVisibility(e);
    setInviteAdminVisibility(e);
  };
  const verifyPopupCloseHandler = (e, f) => {
    setVerifyVisibility(e);
    setOTPVisibility(f);
    setPropsData(propsData);
  };
  //   const retryVerification = (e,f) => {
  //     setVerifyVisibility(e);
  //     setOTPVisibility(f);
  //     setPropsData(propsData);
  //   };
  //   const found = pushDataList.find(el => el.status === "Push Initiated");
  //   const ShowEditPopup = (e, data) => {
  //     return (

  //     );
  //   };
  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header heading="Push Locations" headerImage={Auditimage} />

        <div className="container-fluid">
          <div className="row mb-3 ml-3">
            {/* <Link
              to="/claim_business/bulkUploadVerifyPage"
              className="back-to-link mr-3"
            > */}
            <Icon.ArrowLeftCircle onClick={() => window.history.back()} />
            {/* </Link> */}
          </div>
          <div className="col-md-12 tableResponsive">
            <div className="tableOuterWidth">
              {pushDataList.some((el) => el.status === "Push Initiated") && (
                <div className="module_popupsearchBtn">
                  <button
                    type="button"
                    className="btn btn-primary createGBP"
                    onClick={(e) => setSkipVerificationPopup(true)}
                  >
                    Push to GBP
                  </button>
                </div>
              )}
              <table className="table">
                <thead>
                  <tr>
                    <th className="col-2">Store Code</th>
                    <th className="col-2">Business Name</th>
                    <th className="col-3">Address</th>
                    <th className="col-2">Status</th>
                    {/* <th className="col-2"></th> */}
                    <th className="col-3"></th>
                  </tr>
                </thead>
                <div class="table_Outerscroll">
                  {pushDataList.map((data, i) => {
                    const error = errorDetails?.find(
                      (obj) => obj.id === data.id
                    );

                    return (
                      <tbody className="table_Outertbody">
                        <tr>
                          <td className="col-2">{data.storeCode}</td>
                          <td className="col-2">{data.full_name} </td>
                          <td className="col-3">{data.address}</td>
                          {/* {data.status === "Location Created" && (
                        <td className="col-4">
                          <p>
                            <Icon.CheckCircle
                              style={{ color: "GREEN" }}
                              className="icon_common"
                            />
                            Created Location
                          </p>
                          <button className="btn btn-success">
                            Start Verification Process
                          </button>
                        </td>
                      )} */}
                          {data.status === "Push Initiated" && (
                            <td className="col-2">
                              <p className="mb-0">{data.status}</p>
                            </td>
                          )}
                          {data.status === "Location Creation Failed" && (
                            <>
                              <td className="col-2">
                                <p className="mb-0">
                                  <Icon.Globe
                                    style={{ color: "RED" }}
                                    className="icon_common"
                                  />
                                  {data.status}
                                </p>
                                {error ? (
                                  <span
                                    className="errorClass"
                                    onClick={() => {
                                      setErrorPopup(true);
                                      setErrorListId(data.id);
                                    }}
                                  >
                                    Show Errors
                                  </span>
                                ) : null}
                              </td>
                              <td className="col-3">
                                <button
                                  className="btn btn-success"
                                  onClick={(e) => {
                                    setVisibility(!visibility);
                                    setPropsData(data);
                                  }}
                                >
                                  Edit Listing
                                </button>
                              </td>
                            </>
                          )}
                          {(data.status === "Location Created" ||
                            data.status === "Verification Failed" ||
                            data.status === "Skipped Verification") && (
                            <>
                              <td className="col-2">
                                <p className="mb-0">
                                  {data.status === "Location Created" && (
                                    <Icon.Globe
                                      style={{ color: "GREEN" }}
                                      className="icon_common"
                                    />
                                  )}
                                  {data.status === "Verification Failed" && (
                                    <Icon.User
                                      style={{ color: "RED" }}
                                      className="icon_common"
                                    />
                                  )}
                                  {data.status}
                                </p>
                                {error ? (
                                  <span
                                    className="errorClass"
                                    onClick={() => {
                                      setErrorPopup(true);
                                      setErrorListId(data.id);
                                    }}
                                  >
                                    Show Errors
                                  </span>
                                ) : null}
                              </td>
                              <td className="col-3">
                                <button
                                  className="btn btn-success"
                                  onClick={(e) => {
                                    setVerifyVisibility(true);
                                    setPropsData(data);
                                  }}
                                >
                                  Verify
                                </button>
                              </td>
                            </>
                          )}
                          {data.status === "Verification Started" && (
                            <>
                              <td className="col-2">
                                <p className="mb-0">
                                  <Icon.User
                                    style={{ color: "GREEN" }}
                                    className="icon_common"
                                  />
                                  {data.status}
                                </p>
                              </td>
                              <td className="col-3">
                                <button
                                  className="btn btn-success"
                                  onClick={(e) => {
                                    setOTPVisibility(!OTPVisibility);
                                    setPropsData(data);
                                  }}
                                >
                                  Enter Verification Code
                                </button>
                              </td>
                            </>
                          )}
                          {(data.status === "Verified" ||
                            data.status === "Admin Invitation Failed") && (
                            <>
                              <td className="col-2">
                                <p className="mb-0">
                                  {data.status === "Verified" && (
                                    <Icon.MapPin
                                      style={{ color: "Green" }}
                                      className="icon_common"
                                    />
                                  )}
                                  {data.status ===
                                    "Admin Invitation Failed" && (
                                    <Icon.CheckCircle
                                      style={{ color: "RED" }}
                                      className="icon_common"
                                    />
                                  )}

                                  {data.status}
                                </p>
                              </td>
                              <td className="col-3">
                                <button
                                  className="btn btn-success"
                                  onClick={(e) => {
                                    setInviteAdminVisibility(
                                      !inviteAdminVisibility
                                    );
                                    setPropsData(data);
                                  }}
                                >
                                  Invite Admin to Location
                                </button>
                              </td>
                            </>
                          )}
                          {data.status === "Admin Invited" && (
                            <>
                              <td className="col-2">
                                <p className="mb-0">
                                  <Icon.Compass
                                    style={{ color: "GREEN" }}
                                    className="icon_common"
                                  />
                                  {data.status}
                                </p>
                              </td>
                              <td className="col-3">
                                <button
                                  className="btn btn-success"
                                  onClick={(e) => {
                                    setInviteAdminVisibility(
                                      !inviteAdminVisibility
                                    );
                                    setPropsData(data);
                                  }}
                                >
                                  Invite Admin to Location
                                </button>
                              </td>
                            </>
                          )}
                        </tr>
                      </tbody>
                    );
                  })}
                </div>
              </table>
            </div>
          </div>
          {errorPopup == true && (
            <div className="module_popup">
              <div className="module_innerpopup">
                <div className="module_close">
                  <Icon.XCircle onClick={() => setErrorPopup(false)} />
                </div>
                <div className="module_header text-center">Error List</div>
                {/* <button
            type="button"
            className="btn btn-save mb-1 gobutton_position"
            onClick={() => closeHandler()}
          >
            X
          </button> */}
                <div className="module_body">
                  <div className="module_popupsearch_wrpr">
                    <div className="module_popupsearch">
                      <div className="container">
                        <form
                          className="user_formdiv col-md-10"
                          id="createLocationForm"
                        >
                          <div className="row">
                            {errorDetails
                              .filter((obj) => obj.id === errorListId)[0]
                              ?.errors.map((data, index) => {
                                return (
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      {" "}
                                      {index + 1}: {data}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {skipVerificationPopup == true && (
            <div className="module_popup">
              <div className="module_innerpopup">
                <div className="module_close">
                  <Icon.XCircle
                    onClick={() => setSkipVerificationPopup(false)}
                  />
                </div>
                <div className="module_header text-center">
                  <span className="mr-3">
                    Do you want to skip verification?
                  </span>

                  <input
                    type="checkbox"
                    checked={isChecked1}
                    onChange={() => setIsChecked1(!isChecked1)}
                  />
                  <label className="mr-2 ml-1">Yes</label>
                  <input
                    type="checkbox"
                    checked={isChecked2}
                    onChange={() => setIsChecked2(!isChecked2)}
                  />
                  <label className="ml-1">No</label>
                </div>
                {isChecked1 == true && (
                  <div className="module_body">
                    <div className="module_popupsearch_wrpr">
                      <div className="module_popupsearch">
                        <div className="container">
                          <div className="row">
                            {/* Do you want to skip verification? */}
                            <div className="col-9 m-auto">
                              <label>Owner Email Id:</label>
                              <input
                                type="text"
                                className="form-control"
                                onChange={(e) =>
                                  setOwnerEmailId(e.target.value)
                                }
                              />
                            </div>{" "}
                            <div className="col-3">
                              <label className="invisible">hide</label>
                              <button
                                className="btn btn-info w-100"
                                onClick={() => {
                                  if (isChecked1 == true) {
                                    window.sessionStorage.setItem(
                                      "skipVerification",
                                      1
                                    );
                                    if (ownerEmailId == "") {
                                      alert("Owner Email Id required");
                                    } else {
                                      createNewLocation(pushDataList);
                                      window.sessionStorage.setItem(
                                        "ownerEmailId",
                                        ownerEmailId
                                      );
                                      setSkipVerificationPopup(false);
                                    }
                                  } else {
                                    window.sessionStorage.setItem(
                                      "skipVerification",
                                      0
                                    );
                                  }
                                }}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isChecked2 == true && (
                  <div className="container">
                    <div className="row">
                      <div className="col-7">
                        <button
                          className="btn btn-info btn-lg float-right"
                          onClick={() => {
                            createNewLocation(pushDataList);
                            window.sessionStorage.setItem(
                              "skipVerification",
                              0
                            );
                            setSkipVerificationPopup(false);
                          }}
                        >
                          Proceed
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <EditListingComponent
          props={propsData}
          onClose={popupCloseHandler}
          show={visibility}
          categoriesList={categoriesList}
          updateData={updatePushStatus}
        />
        <VerifyListingComponent
          props={propsData}
          onClose={verifyPopupCloseHandler}
          show={verifyVisibility}
          updateData={updatePushStatus}
          retryVerification={verifyPopupCloseHandler}
          setVerificationMethod={(e) => setVerificationMethod(e)}
          setMethod={(obj) => setVerifyMethod(obj)}
          errorData={(obj) => setErrorDetails(obj)}
        />
        <OTPListingComponent
          props={propsData}
          verificationMethod={verifyMethod}
          onClose={(e) => setOTPVisibility(e)}
          retryVerification={verifyPopupCloseHandler}
          show={OTPVisibility}
          verification_code={verificationPin}
          updateData={updatePushStatus}
        />
        <InviteAdminToListingComponent
          props={propsData}
          onClose={popupCloseHandler}
          show={inviteAdminVisibility}
          updateData={updatePushStatus}
        />
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
}

export default UidPushDataList;
