import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import { useLocation } from "react-router-dom";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import Auditimage from "../images/left_icons/audit.png";
import DragDropFile from "./dragDropFile";
import DataInput from "./dataInput";
import cloudImg from "../images/cloud-computing.png";
import { Link } from "react-router-dom";
import deleteImg from "../images/Group122.png";
import { backendApi, pythonBackendApi } from "../apiService";
import * as XLSX from "xlsx";
import uploadImg from "../images/upload.png";
import $ from "jquery";
import closeImg1 from "../images/close1.png";
import * as Icon from "react-feather";
import exportFromJSON from "export-from-json";
import auditMatchSearchMasterData from "../docs/auditMatchSearchMasterTemplate.xlsx";
import auditMapping from "../docs/audit_Template.xlsx";
import moment from "moment";
const excelMime = [
  "application/vnd.ms-excel",
  "application/msexcel",
  "application/x-msexcel",
  "application/x-ms-excel",
  "application/x-excel",
  "application/x-dos_ms_excel",
  "application/xls",
  "application/x-xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
class Audit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      file: null,
      buffer: null,
      convertText: "",
      sourceText: "",
      status: "",
      src: "",
      imageLanguage: "",
      imageLanguageLabel: "",
      translateLanguage: "",
      languageListImage: [],
      languageListTTS: [],
      uploaded: false,
      dataList: [],
      excelData: [],
      googleData: [],
      fileName: "",
      setOrganizationId: "",
      userList: [],
      organizationList: [],
      orgId: "",
      tableId: "",
      popupData: "",
    };
    this.handleFile = this.handleFile.bind(this);
    this.onSave = this.onSave.bind(this);
    // this.handleProcessData = this.handleProcessData.bind(this);
    // this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
  }
  componentDidMount() {
    // console.log(props,"yyyyyyyyyyyyyyyyyyyy");
    // const location = useLocation();
    //   let pathname = location.pathname;
    //   var path = pathname.split("/")[2];
    //   this.setState({
    //     tableId: path,
    //   });
    // this.getExcelData();
    this.getMasterGBPAuditData();
    this.getOrganizationData();
  }
  getMasterGBPAuditData() {
    var req = {
      functionName: "auditMergeList",
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      role:
        window.sessionStorage.getItem("switchUserRole") === ""
          ? window.sessionStorage.getItem("role")
          : window.sessionStorage.getItem("switchUserRole"),
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        this.setState({
          uploaded: false,
          excelData: data.data,
        });
      } else {
        // alert("Something went wrong in getting Master Audit Data");
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  // getGoogleData(excelId) {
  //   this.setState({
  //     googleData: [],
  //   });
  //   var apidata = {
  //     excelId: excelId,
  //   };
  //   backendApi("getAuditGoogleData", apidata).then((data) => {
  //     console.log("data=========", data);
  //     if (data.success === "1") {
  //       this.setState({
  //         googleData: data.data,
  //       });
  //     }
  //   });
  // }
  // getExcelData() {
  //   var apidata = {
  //     functionName: "getAuditExcelData",
  //     userId:
  //       window.sessionStorage.getItem("switchUserId") === ""
  //         ? window.sessionStorage.getItem("userId")
  //         : window.sessionStorage.getItem("switchUserId"),
  //     siteId:
  //       window.sessionStorage.getItem("switchingOrgId") === ""
  //         ? window.sessionStorage.getItem("organizationId")
  //         : window.sessionStorage.getItem("switchingOrgId"),
  //   };
  //   backendApi(apidata).then((data) => {
  //     console.log("data=========", data);
  //     if (data.success === "1") {
  //       this.setState({
  //         uploaded: false,
  //         excelData: data.data,
  //       });
  //     }
  //   });
  // }
  downloadExcel() {
    $(".loder_div").attr("hidden", false);
    var apidata = {
      functionName: "downloadAuditExcel",
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apidata).then((data) => {
      console.log("data=========", data);
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        const successFileName = "final-excel";
        const exportType = "xls";
        this.exportXlsx(data.data, successFileName, exportType);
      }
    });
  }
  exportXlsx = (data, fileName, exportType) => {
    exportFromJSON({ data, fileName, exportType });
  };
  // migrateProcess = (id, count) => {
  //   if (!window.confirm("Are you sure you want to run this match")) {
  //     return false;
  //   }
  //   $(".loder_div").attr("hidden", false);
  //   $(".dataProcessImgCompareData").show();
  //   var apidata = {
  //     method: "POST",
  //     body: JSON.stringify({
  //       masterId: id.toString(),
  //       userId:
  //         window.sessionStorage.getItem("switchUserId") === ""
  //           ? window.sessionStorage.getItem("userId")
  //           : window.sessionStorage.getItem("switchUserId"),
  //       siteId:
  //         window.sessionStorage.getItem("switchingOrgId") === ""
  //           ? window.sessionStorage.getItem("organizationId")
  //           : window.sessionStorage.getItem("switchingOrgId"),
  //     }),
  //   };
  //   pythonBackendApi("runMergeAddress", apidata).then((data) => {
  //     if (data.success == 1) {
  //       $(".loder_div").attr("hidden", true);
  //       alert(
  //         "Your Request Sent Successfully and Result Will Be Shows Shortly"
  //       );
  //       window.location.href = "/auditDetailsMerge/" + id;
  //     } else {
  //       $(".loder_div").attr("hidden", true);
  //       alert("Failed");
  //     }
  //     $(".dataProcessImgCompareData").hide();
  //   });
  //   //   if(data.success == 1){
  //   //  console.log(data,"hhhhhhhhhhhhhhhhhhhhhhhhh");
  //   //     // alert("Your Request Sent Successfully and Result Will Be Shows Shortly")
  //   //     window.location.href = "/auditDetailsMerge/" + id;
  //   //   }
  //   // else{
  //   // alert("Failed")
  //   // }
  //   // $(".dataProcessImgCompareData").hide();
  //   // window.location.reload();
  //   // getAuditExcelDataByMasterId();
  // };
  migrateProcess = (id, count) => {
    if (!window.confirm("Are you sure you want to run this match")) {
      return false;
    }
    $(".loder_div").attr("hidden", false);
    var apidata = {
      functionName: "runSearchingAlgoritham",
      masterId: id.toString(),
    };
    backendApi(apidata).then((data) => {
      if (data.success == 1) {
        $(".loder_div").attr("hidden", true);
        alert(
          "Your Request Sent Successfully and Result Will Be Shows Shortly"
        );
        window.location.href = "/auditDetailsMerge/" + id;
      } else {
        $(".loder_div").attr("hidden", true);
        alert("Failed");
      }
    });
  };

  //   migrateProcess(masterId) {
  // $(".loder_div").attr("hidden", false);
  // var apidata = {
  //   method: "POST",
  //   body: JSON.stringify({
  //     masterId: masterId,
  //     userId:
  //       window.sessionStorage.getItem("switchUserId") === ""
  //         ? window.sessionStorage.getItem("userId")
  //         : window.sessionStorage.getItem("switchUserId"),
  //     siteId:
  //       window.sessionStorage.getItem("switchingOrgId") === ""
  //         ? window.sessionStorage.getItem("organizationId")
  //         : window.sessionStorage.getItem("switchingOrgId"),
  //   }),
  // };
  // pythonBackendApi("migrateAuditData", apidata).then((data) => {
  //   if (data) {
  //     window.sessionStorage.setItem("pageNumberDrDetails", 1);
  //     window.sessionStorage.setItem("offsetDrDetails", 0);
  //     window.location.href = "/auditDetails/" + masterId;
  //   }
  // });
  //   }
  processFile(masterId) {
    var apidata = {
      functionName: "auditMergeApprove",
      master_id: masterId.toString(),
    };
    backendApi(apidata).then((data) => {
      console.log("data=========", data);
      if (data.success === "1") {
        alert("Approved Successfully");
        window.location.reload();
      }
    });
  }
  clearData() {
    if (window.confirm("Are you sure to clear data?")) {
      var apidata = {
        functionName: "clearAuditData",
        userId:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
      };
      backendApi(apidata).then((data) => {
        console.log("data=========", data);
        if (data.success === "1") {
          alert(data.status);
          this.getExcelData();
        }
      });
    }
  }
  // handleUpdateStatus(e, excelId) {
  //   if (window.confirm("Are you sure to change status?")) {
  //     var apidata = {
  //       id: e.target.id,
  //       status: e.target.value,
  //     };
  //     backendApi("updateAuditStatus", apidata).then((data) => {
  //       console.log("data=========", data);
  //       if (data.success === "1") {
  //         alert(data.status);
  //         this.getGoogleData(excelId);
  //       }
  //     });
  //   }
  // }
  handleProcessData(e) {
    this.setState({
      googleData: [],
    });
    var apidata = {
      functionName: "getAuditGoogleData",
      excelId: e.target.value,
    };
    backendApi(apidata).then((data) => {
      console.log("data=========", data);
      if (data.success === "1") {
        this.setState({
          googleData: data.data,
        });
      }
    });
  }
  popupShow = (data) => {
    $("#moreInfoPopup").attr("hidden", false);
    this.setState(
      {
        popupData: data,
      },
      () => {
        console.log("hiiii", this.state.popupData);
      }
    );
  };
  OutTable = () => {
    return (
      <div>
        <div className="col-md-12 tableResponsive">
          <table className="table fileupload-table mb-0">
            <thead>
              <tr>
                <th className="col-3">ReferenceCode</th>
                <th className="col-2">Business name</th>
                <th className="col-6">Address</th>
                <th className="col-1">More Info</th>
              </tr>
            </thead>

            <div className="table_Outerscroll">
              {this.state.dataList.map((record, i) => (
                <tbody key={i} className="table_Outertbody">
                  <tr>
                    <td className="col-3">{record.ReferenceCode}</td>
                    <td className="col-2">{record.LocationName}</td>
                    <td className="col-6">
                      {`${record.Address_line1}` +
                        `${
                          record.Address_line2 && `, ${record.Address_line2}`
                        }` +
                        `${
                          record.Address_line3 && `, ${record.Address_line3}`
                        }` +
                        `${
                          record.Address_line4 && `, ${record.Address_line4}`
                        }` +
                        `${
                          record.Address_line5 && `, ${record.Address_line5}`
                        }` +
                        `${record.sub_locality && `, ${record.sub_locality}`}` +
                        `${record.Locality && `, ${record.Locality}`}` +
                        `${
                          record.Administrative_area &&
                          `, ${record.Administrative_area}`
                        }`}
                      ;
                    </td>
                    <td className="col-1">
                      <small
                        onClick={() => {
                          this.popupShow(record);
                        }}
                      >
                        <Icon.Info size="18px" />
                      </small>
                    </td>
                  </tr>
                </tbody>
              ))}
            </div>
          </table>
        </div>
        <div className="button-wrapper mt-0 mb-4">
          <button className="btn-submit ml-3" onClick={() => this.onSave()}>
            Upload
          </button>
          <button
            className="btn-cancel ml-3"
            onClick={() => {
              window.location.reload();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };
  onSave() {
    $("#editCommentPopup").attr("hidden", false);
  }
  saveAndStartUpload() {
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "saveAuditExcelMerge",
      dataList: this.state.dataList,
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      // siteId: this.state.orgId,
      comment: this.state.orgId,
      fileName: this.state.fileName,
    };
    console.log("jiii", req);
    // return false;
    $("#editCommentPopup").attr("hidden", true);
    backendApi(req).then((data) => {
      if (data.success === "1") {
        alert("Uploaded Successfully");
        window.location.reload();
      } else {
        alert("Something went wrong in uploading file");
      }
    });
  }
  async handleFile(files, e) {
    var buffer;
    if (files) {
      if (!excelMime.includes(files.type)) {
        alert("Please upload valid excel file");
        return false;
      }
      $(".loder_div").attr("hidden", false);
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          isLoader: 0,
        });
        /* Parse data */
        const ab = e.target.result;

        const wb = XLSX.read(ab, { type: "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { raw: false, header: 1 });
        let DataList = [];
        let DataListTemp = [];
        let keys = [];
        keys = data[0];
        console.log("keyskeys", keys);
        let record = {};
        for (let i = 1; i < data.length; i++) {
          let dd = data[i];
          if (dd.length > 0) {
            for (let j = 0; j < dd.length; j++) {
              if (keys[j].includes("is_owned_by_women")) {
                record["is_owned_by_women"] =
                  dd[j] === undefined ? "" : dd[j].toString().trim();
              } else if (keys[j].includes("american_express")) {
                record["Payments_american_express"] =
                  dd[j] === undefined ? "" : dd[j].toString().trim();
              } else if (keys[j].includes("mastercard")) {
                record["Payments_mastercard"] =
                  dd[j] === undefined ? "" : dd[j].toString().trim();
              } else if (keys[j].includes("visa")) {
                record["Payments_visa"] =
                  dd[j] === undefined ? "" : dd[j].toString().trim();
              } else if (keys[j].includes("url_menu")) {
                record["url_menu"] =
                  dd[j] === undefined ? "" : dd[j].toString().trim();
              } else if (keys[j].includes("url_order_ahead")) {
                record["url_order_ahead"] =
                  dd[j] === undefined ? "" : dd[j].toString().trim();
              } else if (keys[j].includes("wi_fi")) {
                record["Wi_Fi"] =
                  dd[j] === undefined ? "" : dd[j].toString().trim();
              } else if (keys[j].includes("Country/Region")) {
                record["Country"] =
                  dd[j] === undefined ? "" : dd[j].toString().trim();
              } else if (keys[j].includes("Sub-locality")) {
                record["sub_locality"] =
                  dd[j] === undefined ? "" : dd[j].toString().trim();
              } else if (keys[j].includes("Business name")) {
                record["LocationName"] =
                  dd[j] === undefined ? "" : dd[j].toString().trim();
              } else if (keys[j].includes("Primary phone")) {
                record["phone_no"] =
                  dd[j] === undefined ? "" : dd[j].toString().trim();
              } else if (keys[j].includes("Primary category")) {
                record["Category"] =
                  dd[j] === undefined ? "" : dd[j].toString().trim();
              } else {
                record[keys[j].trim().split(" ").join("_")] =
                  dd[j] === undefined ? "" : dd[j].toString().trim();
              }
            }
            let index = DataList.findIndex(
              (d) =>
                d.AdWords_location_extensions_phone ===
                  record.AdWords_location_extensions_phone &&
                d.Additional_categories === record.Additional_categories &&
                d.Additional_Phones === record.Additional_Phones &&
                d.Address_line1 === record.Address_line1 &&
                d.Address_line2 === record.Address_line2 &&
                d.Address_line3 === record.Address_line3 &&
                d.Address_line4 === record.Address_line4 &&
                d.Address_line5 === record.Address_line5 &&
                d.Administrative_area === record.Administrative_area &&
                d.Wi_Fi === record.Wi_Fi &&
                d.Business_name === record.Business_name &&
                d.Country === record.Country &&
                d.Cover_photo === record.Cover_photo &&
                d.Friday_hours === record.Friday_hours &&
                d.From_the_business === record.From_the_business &&
                d.Labels === record.Labels &&
                d.Latitude === record.Latitude &&
                d.Locality === record.Locality &&
                d.Logophoto === record.Logophoto &&
                d.Longitude === record.Longitude &&
                d.Monday_hours === record.Monday_hours &&
                d.Opening_date === record.Opening_date &&
                d.Other_photos === record.Other_photos &&
                d.Postcode === record.Postcode &&
                d.Primarycategory === record.Primarycategory &&
                d.phone_no === record.phone_no &&
                d.Saturday_hours === record.Saturday_hours &&
                d.Special_hours === record.Special_hours &&
                d.ReferenceCode === record.ReferenceCode &&
                d.sub_locality === record.sub_locality &&
                d.Sunday_hours === record.Sunday_hours &&
                d.Thursday_hours === record.Thursday_hours &&
                d.Tuesday_hours === record.Tuesday_hours &&
                d.Website === record.Website &&
                d.Wednesday_hours === record.Wednesday_hours &&
                d.is_owned_by_women === record.is_owned_by_women &&
                d.Payments_american_express ===
                  record.Payments_american_express &&
                d.Payments_mastercard === record.Payments_mastercard &&
                d.Payments_visa === record.Payments_visa &&
                d.url_menu === record.url_menu &&
                d.url_order_ahead === record.url_order_ahead
            );
            if (
              data[0][0] !== "ReferenceCode" &&
              data[0][1] !== "Business name" &&
              data[0][2] !== "Address line1" &&
              data[0][3] !== "Address line2" &&
              data[0][4] !== "Address line3" &&
              data[0][5] !== "Address line4" &&
              data[0][6] !== "Address line5" &&
              data[0][7] !== "Sub-locality" &&
              data[0][8] !== "Locality" &&
              data[0][9] !== "Administrative area" &&
              data[0][10] !== "Country/Region" &&
              data[0][11] !== "Postcode" &&
              data[0][12] !== "Latitude" &&
              data[0][13] !== "Longitude" &&
              data[0][14] !== "Primary phone" &&
              data[0][15] !== "Additional Phones" &&
              data[0][16] !== "Website" &&
              data[0][17] !== "Primary category" &&
              data[0][18] !== "Additional categories" &&
              data[0][19] !== "Sunday hours" &&
              data[0][20] !== "Monday hours" &&
              data[0][21] !== "Tuesday hours" &&
              data[0][22] !== "Wednesday hours" &&
              data[0][23] !== "Thursday hours" &&
              data[0][24] !== "Friday hours" &&
              data[0][25] !== "Saturday hours" &&
              data[0][26] !== "Special hours" &&
              data[0][26] !== "From the business" &&
              data[0][27] !== "Opening date" &&
              data[0][28] !== "Logo photo" &&
              data[0][29] !== "Cover photo" &&
              data[0][30] !== "Other photos" &&
              data[0][31] !== "Labels" &&
              data[0][32] !== "AdWords location extensions phone" &&
              data[0][33] !== "Amenities: Wi-Fi (wi_fi)" &&
              data[0][34] !==
                "From the business: Identifies as women-owned (is_owned_by_women)" &&
              data[0][35] !==
                "Payments: Credit cards (pay_credit_card_types_accepted): American Express (american_express)" &&
              data[0][36] !==
                "Payments: Credit cards (pay_credit_card_types_accepted): Mastercard (mastercard)" &&
              data[0][37] !==
                "Payments: Credit cards (pay_credit_card_types_accepted): VISA (visa)" &&
              data[0][38] !== "Place page URLs: Menu link (url_menu)" &&
              data[0][39] !==
                "Place page URLs: Order ahead links (url_order_ahead)"
            ) {
              alert("The Header Fields of Excel data is incorrect");
              $(".loder_div").attr("hidden", true);
              return false;
            }
            if (index === -1) {
              record["Address"] =
                `${record.Address_line1}` +
                `${record.Address_line2 && `, ${record.Address_line2}`}` +
                `${record.Address_line3 && `, ${record.Address_line3}`}` +
                `${record.Address_line4 && `, ${record.Address_line4}`}` +
                `${record.Address_line5 && `, ${record.Address_line5}`}` +
                `${record.sub_locality && `, ${record.sub_locality}`}` +
                `${record.Locality && `, ${record.Locality}`}` +
                `${
                  record.Administrative_area &&
                  `, ${record.Administrative_area}`
                }`;
              DataList.push(record);
            }
            console.log("Hellooooo", DataList);
            DataListTemp.push(record);
            record = {};
          }
        }
        $(".loder_div").attr("hidden", true);
        /* Update state */
        this.setState(
          {
            dataList: DataList,
            fileName: files.name,
            uploaded: true,
            isLoader: 1,
          },
          () => {
            if (DataListTemp.length !== DataList.length) {
              alert("File contains duplicate record(s)");
            }
          }
        );
      };
      reader.readAsArrayBuffer(files);
    }
  }
  downloadTemplate() {
    let date =
      "Audit_" +
      moment(new Date())
        .format("DD-MM-YYYY hh:mm:ss a")
        .toString()
        .replaceAll("-", "_")
        .replaceAll(" ", "_")
        .replaceAll(":", "_");
    let header1 = [
      "ReferenceCode",
      "Business name",
      "Address line1",
      "Address line2",
      "Address line3",
      "Address line4",
      "Address line5",
      "Sub-locality",
      "Locality",
      "Administrative area",
      "Country/Region",
      "Postcode",
      "Latitude",
      "Longitude",
      "Primary phone",
      "Additional Phones",
      "Website",
      "Primary category",
      "Additional categories",
      "Sunday hours",
      "Monday hours",
      "Tuesday hours",
      "Wednesday hours",
      "Thursday hours",
      "Friday hours",
      "Saturday hours",
      "Special hours",
      "From the business",
      "Opening date",
      "Logo photo",
      "Cover photo",
      "Other photos",
      "Labels",
      "AdWords location extensions phone",
      "Amenities: Wi-Fi (wi_fi)",
      "From the business: Identifies as women-owned (is_owned_by_women)",
      "Payments: Credit cards (pay_credit_card_types_accepted): American Express (american_express)",
      "Payments: Credit cards (pay_credit_card_types_accepted): Mastercard (mastercard)",
      "Payments: Credit cards (pay_credit_card_types_accepted): VISA (visa)",
      "Place page URLs: Menu link (url_menu)",
      "Place page URLs: Order ahead links (url_order_ahead)",
    ];
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([header1, []]);
    XLSX.utils.book_append_sheet(workbook, worksheet, `${date}`);
    XLSX.writeFile(workbook, `${date}.xlsx`);

    // XLSX.writeFile(workbook, date + ".xlsx", {
    //   compression: true,
    // });
  }

  getOrganizationData = () => {
    const req = {
      functionName: "getSiteByUser",
      userId: window.sessionStorage.getItem("userId"),
      role: window.sessionStorage.getItem("role"),
    };
    backendApi(req).then((data) => {
      console.log(data);
      if (data.success === "1") {
        this.setState({
          organizationList: data.data,
        });
      }
    });
  };
  handleselection(e) {
    alert(e.target.value);
  }
  drDetailsSection() {
    window.sessionStorage.setItem("pageNumberDrDetails", 1);
    window.sessionStorage.setItem("offsetDrDetails", 0);
  }
  deleteAuditMaster = (idForDelete) => {
    if (!window.confirm("Do you want to Delete Data?")) {
      return false;
    }
    var req = {
      functionName: "deleteAuditmergeData",
      masterId: idForDelete,
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        alert("Deleted Successfully");
        this.getMasterGBPAuditData();
      } else {
        $(".loder_div").attr("hidden", true);
        alert("Something went wrong in Deleting the Data");
        this.getMasterGBPAuditData();
      }
    });
  };
  render() {
    return (
      <div className="container-fluid">
        {/* <LeftMenu></LeftMenu>
         <div className="userListWrapper"> */}
        {/* <div className="dash_heading">
            <div className="leftdash_heading">
              <Icon.Home />
              <span>Audit</span>
            </div>
            <div className="rightdash_heading">
              <ul>
                <li>
                  <Icon.User />
                  <div className="dash_listdrop">
                    <ul>
                      <li>
                        <a href="">
                          <Icon.Settings />
                          Settings
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <Icon.LogOut />
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div> */}
        {/* <Header heading="Audit" headerImage={Auditimage} /> */}
        {/* <div className="container"> */}
        {/* <div
              className="col-md-12 downloadTemplateDiv"
              title="Download template"
              onClick={() => this.downloadTemplate()}
            >
              <Icon.Download /> Download template
            </div> */}
        <div className="file_upload_outer container">
          <DragDropFile handleFile={this.handleFile}>
            <div className="form-group formBottom" id="form-fileupload">
              {/* <img src={cloudImg} alt="cloud" width={70} /> */}
              {/* <Icon.UploadCloud className="uploadCloudIcon" /> */}
              <DataInput handleFile={this.handleFile} />
            </div>
          </DragDropFile>
          <div
            className="col-lg-3 col-md-12 TemplateDowloadDiv"
            title="Download template"
            onClick={() => this.downloadTemplate()}
          >
            {/* <a href={auditMatchSearchMasterData}> */}
            <Icon.Download /> Download template
            {/* </a> */}
          </div>
          {this.state.uploaded === true && this.OutTable()}
        </div>

        <div class="total_list">
          <span class="audit-txt">Total :</span>
          <span class="audit-txt-bld">
            {this.state.excelData.length != "0"
              ? this.state.excelData.length
              : "0"}
          </span>
        </div>
        {/* <div className="ocr_progress_bar">
              {this.state.excelData.length !== 0 ? (
                <div className="col-md-6 text-right">
                  <button
                    id="proceedBtn"
                    className="ocr_startbtn btn btn-info"
                    type="button"
                    onClick={() => this.clearData()}
                  >
                    Clear
                  </button>
                  <button
                    id="proceedBtn"
                    className="ocr_startbtn  btn btn-primary"
                    type="button"
                    onClick={() => this.migrateProcess()}
                  >
                    Migrate
                  </button>
                  <button
                    id="proceedBtn"
                    className="ocr_startbtn  btn btn-secondary"
                    type="button"
                    onClick={() => this.processFile()}
                  >
                    Compare
                  </button>
                </div>
              ) : (
                ""
              )}
            </div> */}
        {/* <div className="container reprocessing_wrapr fixed_body_table">
              <div className="row table_height">
                <div className="col-md-6">
                  <div className="table-responsive">
                    {this.state.excelData.length !== 0 ? (
                      <table className="table table-striped fileupload-table">
                        <thead>
                          <tr>
                            <th>Reference Code</th>
                            <th>Location Name</th>
                            <th>Address</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.excelData.map((r, i) => (
                            <tr key={i}>
                              <td>{r.reference_code}</td>
                              <td>{r.location_name}</td>
                              <td>{r.address}</td>
                              <td>
                                <input
                                  type="radio"
                                  name="process"
                                  value={r.id}
                                  onClick={(e) => this.handleProcessData(e)}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="table-responsive">
                    {this.state.googleData.length !== 0 ? (
                      <table className="table table-striped fileupload-table">
                        <thead>
                          <tr>
                            <th>Address</th>
                            <th>Reviews</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.googleData.map((r, i) => (
                            <tr key={i}>
                              <td>{r.address}</td>
                              <td>{r.reviews}</td>
                              <td>
                                <select
                                  className="form-control"
                                  id={r.id}
                                  onChange={(e) =>
                                    this.handleUpdateStatus(e, r.excel_id)
                                  }
                                  defaultValue={r.status ? r.status : ""}
                                >
                                  <option value="Primary">Primary</option>
                                  <option value="Stray">Stray</option>
                                  <option value="Duplicate">Duplicate</option>
                                  <option value="Ignore">Ignore</option>
                                </select>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="reprocessing_btndiv">
                <div className="repro_btn">
                  <button id="txtFile" type="button" className="btn_primary">
                    Re-Process
                  </button>
                  <button
                    id="txtFile"
                    type="button"
                    className="btn-download"
                    onClick={(e) => {
                      this.downloadExcel();
                    }}
                  >
                    Download Excel
                  </button>
                </div>
                <div className="repro_btn">
                  <button
                    id="pdfFile"
                    type="button"
                    className="btn-download"
                    onClick={(e) => this.downloadTxtFile(e)}
                  >
                    Download as PDF File
                  </button>
                </div>
              </div>
            </div> */}
        <div className="col-md-12 tableResponsive">
          <div className="tableOuterWidth">
            <table className="table">
              <thead>
                <tr>
                  <th className="col-1" style={{ width: "5%" }}>
                    ID
                  </th>
                  <th className="col-2">Batch</th>
                  <th className="col-2" style={{ width: "19.9999%" }}>
                    Organization
                  </th>
                  <th className="col-2">Date</th>
                  <th className="col-1">User </th>
                  <th className="col-2">Process</th>
                  <th className="col-1"></th>
                  <th className="col-1"></th>
                </tr>
              </thead>
              <div className="table_Outerscroll">
                {this.state.excelData.map((data, i) => {
                  let createdDate = moment(data.created_on).format(
                    "DD MMM YYYY hh:mm a"
                  );
                  return (
                    <tbody className="table_Outertbody">
                      <tr>
                        <td className="col-1" style={{ width: "5%" }}>
                          {data.id}
                        </td>
                        {/* <td>{r.}</td> */}
                        <td className="col-2">
                          {data.id}-&nbsp;{data.excel_name}
                        </td>
                        <td className="col-2" style={{ width: "19.9999%" }}>
                          {data.commentedSiteName}
                        </td>
                        <td className="col-2">{createdDate}</td>
                        <td className="col-1">{data.createdBy}</td>
                        <td className="col-2">
                          {/* <div class="create_popst_btn01"> */}
                          {/* <button type="button" class="btn btn-primary">
                          button
                        </button> */}
                          {data.status == "" && (
                            <div class="create_popst_btn01">
                              <span
                                type="button"
                                class="btn-start"
                                title="Match"
                                onClick={() =>
                                  this.migrateProcess(data.id, data.totalcount)
                                }
                              >
                                Match
                              </span>
                            </div>
                          )}
                          {data.status == "Search Completed" && (
                            <div class="create_popst_btn01">
                              <span
                                type="button"
                                class="btn-approve"
                                onClick={() => this.processFile(data.id)}
                              >
                                Approve
                              </span>
                            </div>
                          )}
                          {/* </div> */}
                        </td>
                        <td className="col-1">
                          <Link
                            onClick={() => this.drDetailsSection()}
                            to={"/auditDetailsMerge/" + data.id}
                            state={{
                              tableId: data.id,
                              status: data.status,
                            }}
                            id="linkId"
                            title=" Show more"
                          >
                            <Icon.Eye />
                          </Link>
                        </td>
                        <td
                          className="showEdit_icons col-1"
                          onClick={() => this.deleteAuditMaster(data.id)}
                        >
                          <Link title="Delete" style={{ color: "red" }}>
                            <Icon.Trash />
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </div>
            </table>
          </div>
        </div>
        {/* </div> */}
        {/* </div> */}
        <div id="editCommentPopup" className="module_popup" hidden>
          <div className="module_innerpopup">
            <div className="module_close">
              <Icon.XCircle
                onClick={() => {
                  $("#editCommentPopup").attr("hidden", true);
                }}
              />
            </div>
            <div id="editCommentPopupHeader" className="module_header"></div>
            <div className="module_body">
              <div className="module_popupsearch_wrpr">
                {/* <div className="module_popupsearch">
                  <input
                    id="editCommentPopupTextBox"
                    type="text"
                    placeholder="Name"
                  />
                </div> */}
                <div className="py-3">
                  <div class="did-floating-label-content">
                    <select
                      id="organisationName"
                      class="did-floating-select"
                      onChange={(e) =>
                        this.setState({
                          orgId: e.target.value,
                        })
                      }
                    >
                      <option value="">Select</option>
                      {this.state.organizationList.map((data, i) => (
                        <option
                          key={i + 1}
                          label={data.organisation_name}
                          value={data.id}
                        >
                          {data.organisation_name}
                        </option>
                      ))}
                    </select>
                    <label class="did-floating-label">Organization</label>
                  </div>
                </div>
                <div className="module_popupsearchBtn">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.saveAndStartUpload()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="moreInfoPopup" className="module_popup" hidden>
          <div className="module_innerpopup">
            <div className="module_close">
              <Icon.XCircle
                onClick={() => {
                  $("#moreInfoPopup").attr("hidden", true);
                }}
              />
            </div>
            <div id="editCommentPopupHeader" className="module_header"></div>
            <div className="module_body">
              <div className="module_popupsearch_wrpr">
                <div className="py-3">
                  <div class="did-floating-label-content">
                    <div className="moreDetails">
                      <span>
                        <b>Reference Code</b>:{" "}
                        {this.state.popupData.ReferenceCode
                          ? this.state.popupData.ReferenceCode
                          : "N/A"}
                      </span>
                      <span>
                        <b>Location Name</b>:{" "}
                        {this.state.popupData.LocationName
                          ? this.state.popupData.LocationName
                          : "N/A"}
                      </span>
                      <span>
                        <b>Address Line 1</b>:{" "}
                        {this.state.popupData.Address_line1
                          ? this.state.popupData.Address_line1
                          : "N/A"}
                      </span>
                      <span>
                        <b>Address Line 2</b>:{" "}
                        {this.state.popupData.Address_line2
                          ? this.state.popupData.Address_line2
                          : "N/A"}
                      </span>
                      <span>
                        <b>Address Line 3</b>:{" "}
                        {this.state.popupData.Address_line3
                          ? this.state.popupData.Address_line3
                          : "N/A"}
                      </span>
                      <span>
                        <b>Address Line 4</b>:{" "}
                        {this.state.popupData.Address_line4
                          ? this.state.popupData.Address_line4
                          : "N/A"}
                      </span>
                      <span>
                        <b>Address Line 5</b>:{" "}
                        {this.state.popupData.Address_line5
                          ? this.state.popupData.Address_line5
                          : "N/A"}
                      </span>
                      <span>
                        <b>Sub-locality</b>:{" "}
                        {this.state.popupData.sub_locality
                          ? this.state.popupData.sub_locality
                          : "N/A"}
                      </span>
                      <span>
                        <b>Locality</b>:{" "}
                        {this.state.popupData.Locality
                          ? this.state.popupData.Locality
                          : "N/A"}
                      </span>
                      <span>
                        <b>Administrative Area</b>:{" "}
                        {this.state.popupData.Administrative_area
                          ? this.state.popupData.Administrative_area
                          : "N/A"}
                      </span>
                      <span>
                        <b>Country/ Region</b>:{" "}
                        {this.state.popupData.Country
                          ? this.state.popupData.Country
                          : "N/A"}
                      </span>
                      <span>
                        <b>Postal Code</b>:{" "}
                        {this.state.popupData.Postcode
                          ? this.state.popupData.Postcode
                          : "N/A"}
                      </span>
                      <span>
                        <b>Latitude</b>:{" "}
                        {this.state.popupData.Latitude
                          ? this.state.popupData.Latitude
                          : "N/A"}
                      </span>
                      <span>
                        <b>Longitude</b>:{" "}
                        {this.state.popupData.Longitude
                          ? this.state.popupData.Longitude
                          : "N/A"}
                      </span>
                      <span>
                        <b>Primary Phone</b>:{" "}
                        {this.state.popupData.phone_no
                          ? this.state.popupData.phone_no
                          : "N/A"}
                      </span>
                      <span>
                        <b>Additional Phone Nos.</b>:{" "}
                        {this.state.popupData.Additional_Phones
                          ? this.state.popupData.Additional_Phones
                          : "N/A"}
                      </span>
                      <span>
                        <b>Category</b>:{" "}
                        {this.state.popupData.category
                          ? this.state.popupData.category
                          : "N/A"}
                      </span>
                      <span>
                        <b>Additional categories</b>:{" "}
                        {this.state.popupData.Additional_categories
                          ? this.state.popupData.Additional_categories
                          : "N/A"}
                      </span>
                      <span>
                        <b>Sunday Hours</b>:{" "}
                        {this.state.popupData.Sunday_hours
                          ? this.state.popupData.Sunday_hours
                          : "N/A"}
                      </span>
                      <span>
                        <b>Monday Hours</b>:{" "}
                        {this.state.popupData.Monday_hours
                          ? this.state.popupData.Monday_hours
                          : "N/A"}
                      </span>
                      <span>
                        <b>Tuesday Hours</b>:{" "}
                        {this.state.popupData.Tuesday_hours
                          ? this.state.popupData.Tuesday_hours
                          : "N/A"}
                      </span>
                      <span>
                        <b>Wednesday Hours</b>:{" "}
                        {this.state.popupData.Wednesday_hours
                          ? this.state.popupData.Wednesday_hours
                          : "N/A"}
                      </span>
                      <span>
                        <b>Thursday Hours</b>:{" "}
                        {this.state.popupData.Thursday_hours
                          ? this.state.popupData.Thursday_hours
                          : "N/A"}
                      </span>
                      <span>
                        <b>Friday Hours</b>:{" "}
                        {this.state.popupData.Friday_hours
                          ? this.state.popupData.Friday_hours
                          : "N/A"}
                      </span>
                      <span>
                        <b>Saturday Hours</b>:{" "}
                        {this.state.popupData.Saturday_hours
                          ? this.state.popupData.Saturday_hours
                          : "N/A"}
                      </span>
                      <span>
                        <b>Special Hours</b>:{" "}
                        {this.state.popupData.Special_hours
                          ? this.state.popupData.Special_hours
                          : "N/A"}
                      </span>
                      <span>
                        <b>From The Business</b>:{" "}
                        {this.state.popupData.From_the_business
                          ? this.state.popupData.From_the_business
                          : "N/A"}
                      </span>
                      <span>
                        <b>Opening Date</b>:{" "}
                        {this.state.popupData.Opening_date
                          ? this.state.popupData.Opening_date
                          : "N/A "}
                      </span>
                      <span>
                        <b>Logo Photo</b>:{" "}
                        {this.state.popupData.Logo_photo
                          ? this.state.popupData.Logo_photo
                          : "N/A"}
                      </span>
                      <span>
                        <b>Cover Photo</b>:{" "}
                        {this.state.popupData.Cover_photo
                          ? this.state.popupData.Cover_photo
                          : "N/A"}
                      </span>
                      <span>
                        <b>Other Photos</b>:{" "}
                        {this.state.popupData.Other_photos
                          ? this.state.popupData.Other_photos
                          : "N/A"}
                      </span>
                      <span>
                        <b>Labels</b>:{" "}
                        {this.state.popupData.Labels
                          ? this.state.popupData.Labels
                          : "N/A"}
                      </span>
                      <span>
                        <b>AdWords location extensions phone</b>:{" "}
                        {this.state.popupData.AdWords_location_extensions_phone
                          ? this.state.popupData
                              .AdWords_location_extensions_phone
                          : "N/A"}
                      </span>
                      <span>
                        <b>Amenities: Wi-Fi</b>:{" "}
                        {this.state.popupData.Wi_Fi
                          ? this.state.popupData.Wi_Fi
                          : "N/A"}
                      </span>
                      <span>
                        <b>From the business: Identifies as women-owned</b>:{" "}
                        {this.state.popupData.is_owned_by_women
                          ? this.state.popupData.is_owned_by_women
                          : "N/A"}
                      </span>
                      <span>
                        <b>Payments: Credit cards: American Express</b>:{" "}
                        {this.state.popupData.Payments_american_express
                          ? this.state.popupData.Payments_american_express
                          : "N/A"}
                      </span>
                      <span>
                        <b>Payments: Credit cards : Mastercard</b>:{" "}
                        {this.state.popupData.Payments_mastercard
                          ? this.state.popupData.Payments_mastercard
                          : "N/A"}
                      </span>
                      <span>
                        <b>Payments: Credit cards : Visa</b>:{" "}
                        {this.state.popupData.Payments_visa
                          ? this.state.popupData.Payments_visa
                          : "N/A"}
                      </span>
                      <span>
                        <b>Place page URLs: Menu link</b>:{" "}
                        {this.state.popupData.url_menu
                          ? this.state.popupData.url_menu
                          : "N/A"}
                      </span>
                      <span>
                        <b>Place page URLs: Order ahead links</b>:{" "}
                        {this.state.popupData.url_order_ahead
                          ? this.state.popupData.url_order_ahead
                          : "N/A"}
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div className="module_popupsearchBtn">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.saveAndStartUpload()}
                  >
                    Save
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="loder_div" hidden />
        {/* <Footer/> */}
      </div>
    );
  }
}
export default Audit;
