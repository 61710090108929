import React, { useEffect } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Dashboardimage from "../images/left_icons/business.png";
import LeftMenu from "../layout/leftMenu";
import { useState } from "react";
import $ from "jquery";
import * as Icon from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { apiCallingPost } from "../apiService";
import { Link } from "react-router-dom";

let stringComparison = require("string-comparison");
let cos = stringComparison.levenshtein;
let matchingLocationId;
// console.log("similarity",cos.similarity(Thanos, Rival));

export default function ClaimPrimary() {
  const [claimedData, setClaimedData] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [claimedTitle, setclaimedTitle] = useState("");
  const [claimedPrimaryData, setClaimedPrimaryData] = useState("");
  const [selectPlace, setSelectPlace] = useState("");
  const [selectPlaceData, setSelectPlaceData] = useState("");
  const [locationIdSelectedGbp, setlocationIdSelectedGbp] = useState("");
  const [selectPlaceId, setSelectPlaceId] = useState(0);
  const [nextData, setNextData] = useState("");
  const [prevData, setPrevData] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  let tempArrayList = [];
  useEffect(() => {
    console.log("locationssss", location);
    getauditGoogleData();
    // location.state.nextLocationsList.indexOf()
    let index = location.state.nextLocationsList.findIndex(
      (x) => x.id == location.state.auditClaimData.id
    );
    if (index - 1 != -1)
      setPrevData(location.state.nextLocationsList[index - 1]);
    else {
      setPrevData("");
    }
    if (index != location.state.nextLocationsList.length + 1) {
      setNextData(location.state.nextLocationsList[index + 1]);
      console.log("jsut some index", index);
    } else {
      console.log("its at the last index", index);
      setNextData("");
    }
  }, []);

  const getauditGoogleData = () => {
    $(".loder_div").attr("hidden", false);
    let newArray = [];
    var apiJson = {
      functionName: "getDataForClaimFromGoogle",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      account_id: window.sessionStorage.getItem("account_id"),
    };
    apiCallingPost(apiJson).then((data) => {
      if (data.success === "1") {
        data.data.forEach((item) => {
          let auditClaimAddress = location.state.auditClaimData.address;
          let auditCompare = item.addressLines;
          let percentage = cos.similarity(auditClaimAddress, auditCompare);
          item.percentage = percentage * 100;
          newArray.push(item);
        });
        newArray.sort(function (a, b) {
          return b.percentage - a.percentage;
        });
        tempArrayList = newArray;
        setDataList(newArray);
        claimAddress(location.state.auditClaimData);
      }
    });
  };
  const claimAddress = (data) => {
    setclaimedTitle(data.location_name);
    let claimedTempList = [];
    var apiJson = {
      functionName: "getDataFromAuditClaim",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      query: data.location_name + "," + data.address,
    };
    apiCallingPost(apiJson).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (Object.keys(data.data).length !== 0 && data.success === "1") {
        // setClaimedData(data.data.googleLocations);
        // tempArrayList.forEach((firstObject) => {
        //   data.data.googleLocations.forEach((secondObject) => {
        //     if (firstObject.title === secondObject.location.title) {
        //       claimedTempList.push(secondObject);
        //       console.log("inside if");
        //       alert(
        //         "tableListingGoogle" + secondObject.title.split(" ").join("_")
        //       );
        //       $(
        //         "#tableListingGoogle" + secondObject.title.split(" ").join("_")
        //       ).addClass("greenActive");
        //     } else {
        //       console.log("inside else");
        //     }
        //   });
        // });
        claimedTempList = data.data.googleLocations.filter((obj) =>
          tempArrayList.filter((data) =>
            data.title.includes(obj.location.title)
          )
        );
        // console.log("hellooooo", tempArrayList123);

        if (claimedTempList.length > 0) {
          setClaimedData(claimedTempList);
        } else {
          setClaimedData([]);
        }
      } else {
        setClaimedData([]);
      }
    });
  };
  const deleteDuplicationLocation = (id) => {
    if (!window.confirm("Do you want to delete this location?")) {
      return false;
    }
    $(".loder_div").attr("hidden", false);
    const req = {
      functionName: "deleteDuplicateLocation",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      location_id: id,
    };
    apiCallingPost(req).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.data.success === "1") {
        alert("Location deleted successfully!");
      } else {
        alert("Something went wrong in deleting location");
      }
    });
  };
  const mergeLocation = (id) => {
    if (!window.confirm("Do you want to merge this location?")) {
      return false;
    }
    if (selectPlace === "") {
      alert("Select location to be merged");
      return false;
    }
    $(".loder_div").attr("hidden", false);
    const req = {
      functionName: "mergeDuplicateLocation",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      location_id: id,
      placeId: selectPlaceId,
    };
    apiCallingPost(req).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.data.success === "1") {
        alert("Location merged successfully!");
      } else {
        alert("Something went wrong in merging location");
      }
    });
  };
  const selectPlaceLocation = (place, id, e) => {
    if (selectPlaceId !== 0) {
      $("#selectPlace" + selectPlaceId).removeClass("active");
      $("#flexCheckDefault" + selectPlaceId).prop("checked", false);
    }
    if (e.target.checked === true) {
      $("#selectPlace" + id).addClass("active");
      setSelectPlaceId(id);
      setSelectPlace(place.location.metadata.placeId);
    } else {
      setSelectPlace("");
      $("#selectPlace" + id).removeClass("active");
      setSelectPlaceId(0);
    }
  };
  const requesttoAccess = (path) => {
    if (!window.confirm("Do you want to redirect to  this page?")) {
      return false;
    }
    $(".loder_div").attr("hidden", false);
    const req = {
      functionName: "claiminitiatedUpdate",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      Id: location.state.auditClaimData.id,
    };
    apiCallingPost(req).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        window.open(path);
      } else {
        alert("Something went wrong ");
      }
    });
  };
  const selectGbp = (locationId, checked, item) => {
    $("input.example").on("change", function () {
      $("input.example").not(this).prop("checked", false);
    });
    // var $checks = $('input[type="checkbox"]');
    // $checks.click(function() {
    //    $checks.not(this).prop("checked", false);
    // });
    if (checked == true) {
      setlocationIdSelectedGbp(locationId);
      setSelectPlaceData(item);
    } else {
      setlocationIdSelectedGbp("");
      setSelectPlaceData(item);
    }
  };
  const confirmationAudit = () => {
    console.log("locationlocation", location);
    console.log("selectPlaceDataselectPlaceData", selectPlaceData);
    if (selectPlaceId != 0 && locationIdSelectedGbp != "") {
      $(".loder_div").attr("hidden", false);
      const req = {
        functionName: "claimSubmit",
        Id: location.state.auditClaimData.id,
        location_id: locationIdSelectedGbp,
        maps_url: selectPlaceData.maps_uri,
        site_id:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        storeCode: selectPlaceData.storeCode,
        created_by: window.sessionStorage.getItem("userId"),
      };
      console.log("reqreqreq", req);
      apiCallingPost(req).then((data) => {
        $(".loder_div").attr("hidden", true);
        if (data.success === "1") {
          window.history.back();
        } else {
          alert("Something went wrong ");
          window.history.back();
        }
      });
    } else {
      alert("Pleas select both the check boxes");
    }
  };
  const clickIgnore = () => {
    const apiReq = {
      functionName: "claimUpdateIgnore",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      Id: location.state.auditClaimData.id,
    };
    apiCallingPost(apiReq).then((data) => {
      // getauditGoogleData("");
      navigate(`/claim/claimBusiness/claimPrimary/${nextData.id}`, {
        state: {
          auditClaimData: nextData,
          nextLocationsList: location.state.nextLocationsList,
        },
      });
      window.location.reload();
    });
  };
  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Claim Primary" headerImage={Dashboardimage} />
        <div className="container">
          <div className="row">
            {/* <div className="col-md-12">
              <h3 className="popup-head">Google Locations</h3>
            </div> */}
            <div className="row mb-3 ml-1">
              <Link to="/claimBusiness" className="back-to-link mr-3">
                <Icon.ArrowLeftCircle />
              </Link>
            </div>
            <div className="w-100">
              <div className="sub_from_audit">From Audit</div>
            </div>
            <button
              className="btn btn-warning mr-2"
              onClick={() => clickIgnore()}
            >
              Ignore
            </button>
            <div className="col-md-12 tableResponsive">
              <div className="tableOuterWidth">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th className="col-1">ID</th>
                      <th className="col-2" style={{ maxWidth: "12%" }}>
                        Business Name
                      </th>
                      <th className="col-6" style={{ maxWidth: "63%" }}>
                        Address
                      </th>
                      <th className="col-1">Status</th>
                      <th className="col-2"></th>
                    </tr>
                  </thead>
                  <div class="table_Outerscroll">
                    <tbody className="table_Outertbody mb-0">
                      <tr>
                        <td className="col-1">
                          {location.state.auditClaimData.id}
                        </td>
                        <td className="col-2" style={{ maxWidth: "12%" }}>
                          {location.state.auditClaimData.location_name}
                        </td>
                        <td className="col-7" style={{ maxWidth: "63%" }}>
                          {location.state.auditClaimData.address}
                        </td>
                        <td className="col-1">
                          {location.state.auditClaimData.status}
                        </td>
                        <td className="col-2">
                          <button
                            className="btn btn-info mr-2"
                            disabled={prevData == "" ? true : false}
                            onClick={() => {
                              navigate(
                                `/claim/claimBusiness/claimPrimary/${prevData.id}`,
                                {
                                  state: {
                                    auditClaimData: prevData,
                                    nextLocationsList:
                                      location.state.nextLocationsList,
                                  },
                                }
                              );
                              window.location.reload();
                            }}
                          >
                            Prev.
                          </button>
                          <button
                            className="btn btn-info mr-2"
                            disabled={nextData == "" ? true : false}
                            onClick={() => {
                              navigate(
                                `/claim/claimBusiness/claimPrimary/${nextData.id}`,
                                {
                                  state: {
                                    auditClaimData: nextData,
                                    nextLocationsList:
                                      location.state.nextLocationsList,
                                  },
                                }
                              );
                              window.location.reload();
                            }}
                          >
                            Next
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </div>
                </table>
              </div>
            </div>
            {location.state.auditClaimData.GBP_status == "Claim Initiated" ? (
              <>
                <span
                  style={{
                    display: "block",
                    fontSize: "15px",
                  }}
                >
                  {location.state.auditClaimData?.created_on && (
                    <>
                      Claim initiated on:{" "}
                      {moment(location.state.auditClaimData.created_on).format(
                        "MMM DD, yy"
                      )}{" "}
                    </>
                  )}
                </span>
              </>
            ) : location.state.auditClaimData.GBP_status == "Ignore" ? (
              <>
                <span
                  style={{
                    display: "block",
                    fontSize: "15px",
                  }}
                >
                  {location.state.auditClaimData?.created_on && (
                    <>
                      Ignored on:{" "}
                      {moment(location.state.auditClaimData.created_on).format(
                        "MMM DD, yy"
                      )}{" "}
                    </>
                  )}
                </span>
              </>
            ) : (
              ""
            )}
            {/* {console.log(
              "lelelelelelelegoooo",
              claimedData.filter((el) => {
                return !dataList.find((element) => {
                  return element.title.includes(el.location.title);
                });
              })
            )} */}
            {/* {console.log("dataList", dataList)}
            {console.log("claimedData", claimedData)} */}
            {claimedData.filter((el) => {
              return dataList.find((element) => {
                return element.title == el.location.title;
              });
            }).length > 0 && (
              <div className="claim_boxprimary">
                <ul className="nav align-items-center">
                  <li>
                    If the address in the white section below is the same as
                    from "audit" on Google, please click on the confirmation
                    button to get it removed from the list!
                  </li>
                  {/* <li>label1</li>
                            <li>label1</li> */}
                  <li className="ml-auto" onClick={() => confirmationAudit()}>
                    <button type="button" className="btn btn-primary">
                      Confirm
                    </button>
                  </li>
                </ul>
              </div>
            )}
            {/* {claimedData ? (

            ) : (
              ""
            )} */}
            <>
              <div className="row col-lg-12 claimPrim">
                <div className="col-lg-4">
                  <div className="w-100">
                    <div className="sub_from_audit">From Google</div>
                  </div>
                  {claimedData.length !== 0 &&
                  claimedData.filter(
                    (obj) => obj.location.title === claimedTitle
                  ).length > 0 ? (
                    <>
                      {claimedData
                        .filter((obj) => obj.location.title === claimedTitle)
                        .map((data, i) => {
                          console.log("data", data);
                          return (
                            <div className="content-sub-wrapper">
                              <div
                                id={"selectPlace" + (i + 1)}
                                className="checkWrap"
                              >
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  onChange={(e) =>
                                    selectPlaceLocation(data, i + 1, e)
                                  }
                                  id={"flexCheckDefault" + (i + 1)}
                                />
                              </div>
                              <div
                                className="content-sub"
                                key={data.location.title}
                              >
                                {data.requestAdminRightsUri !== "" && (
                                  <div
                                    className="col-12 text-right mb-2"
                                    style={{ top: "-10px", height: "0" }}
                                  >
                                    <div className="verified">Verified</div>
                                  </div>
                                )}
                                <div className="row">
                                  <div className="col-4">
                                    <label className="col-form-label">
                                      Title
                                    </label>
                                  </div>
                                  <div className="col-1">
                                    <label className="col-form-label">:</label>
                                  </div>
                                  <div className="col-6">
                                    {" "}
                                    <label className="col-form-label">
                                      {" "}
                                      {data.location.title}
                                    </label>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-4">
                                    <label className="col-form-label">
                                      Phone No.
                                    </label>
                                  </div>
                                  <div className="col-1">
                                    <label className="col-form-label">:</label>
                                  </div>
                                  <div className="col-6">
                                    {" "}
                                    <label className="col-form-label">
                                      {" "}
                                      {data.location.phoneNumbers.primaryPhone}
                                    </label>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-4">
                                    <label className="col-form-label">
                                      Region Code
                                    </label>
                                  </div>
                                  <div className="col-1">
                                    <label className="col-form-label">:</label>
                                  </div>
                                  <div className="col-6">
                                    {" "}
                                    <label className="col-form-label">
                                      {" "}
                                      {
                                        data.location.storefrontAddress
                                          .regionCode
                                      }
                                    </label>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-4">
                                    <label className="col-form-label">
                                      Administration
                                    </label>
                                  </div>
                                  <div className="col-1">
                                    <label className="col-form-label">:</label>
                                  </div>
                                  <div className="col-6">
                                    {" "}
                                    <label className="col-form-label">
                                      {" "}
                                      {
                                        data.location.storefrontAddress
                                          .administrativeArea
                                      }
                                    </label>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-4">
                                    <label className="col-form-label">
                                      Locality
                                    </label>
                                  </div>
                                  <div className="col-1">
                                    <label className="col-form-label">:</label>
                                  </div>
                                  <div className="col-6">
                                    {" "}
                                    <label className="col-form-label">
                                      {" "}
                                      {data.location.storefrontAddress.locality}
                                    </label>
                                  </div>
                                </div>
                                <div className="address-section-bg row ml-0">
                                  <div className="col-4 pl-0">
                                    <label className="col-form-label">
                                      Address
                                    </label>
                                  </div>
                                  <div className="col-1 pl-0">
                                    <label className="col-form-label">:</label>
                                  </div>
                                  <div className="col-6 pl-2">
                                    {data.location.storefrontAddress.addressLines.map(
                                      (data) => (
                                        <div className="col-form-label py-0">
                                          {" "}
                                          {data}
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                                <div className="address-section-bg row ml-0">
                                  <div className="col-4 pl-0">
                                    <label className="col-form-label">
                                      Location Id
                                    </label>
                                  </div>
                                  <div className="col-1 pl-0">
                                    <label className="col-form-label">:</label>
                                  </div>
                                  <div className="col-6 pl-2">
                                    {/* {data.location.storefrontAddress.map(
                                      (data) => (
                                        <div className="col-form-label py-0">
                                          {" "}
                                          {data}
                                        </div>
                                      )
                                    )} */}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-4">
                                    <label className="col-form-label">
                                      Latitude
                                    </label>
                                  </div>
                                  <div className="col-1">
                                    <label className="col-form-label">:</label>
                                  </div>
                                  <div className="col-6">
                                    {" "}
                                    <label className="col-form-label">
                                      {" "}
                                      {data.location.latlng.latitude}
                                    </label>
                                  </div>

                                  <div className="col-4">
                                    <label className="col-form-label">
                                      Longitude
                                    </label>
                                  </div>
                                  <div className="col-1">
                                    <label className="col-form-label">:</label>
                                  </div>
                                  <div className="col-6">
                                    {" "}
                                    <label className="col-form-label">
                                      {" "}
                                      {data.location.latlng.longitude}
                                    </label>
                                  </div>
                                </div>
                                <hr></hr>
                                <div
                                  className="button-wrapper text-center"
                                  id="requestAccessUrl"
                                >
                                  <button
                                    class="btn-cancel"
                                    onClick={() => window.history.back()}
                                  >
                                    Cancel
                                  </button>

                                  {data.requestAdminRightsUri ? (
                                    // <a
                                    //   href={data.requestAdminRightsUri}
                                    //   target="_blank"
                                    // >
                                    <button
                                      class="btn-submit"
                                      onClick={() =>
                                        requesttoAccess(
                                          data.requestAdminRightsUri
                                        )
                                      }
                                    >
                                      Request to Access
                                    </button>
                                  ) : (
                                    // </a>
                                    // <a
                                    //   href={
                                    //     "https://business.google.com/create?fp=" +
                                    //     data.location.metadata.mapsUri.split(
                                    //       "cid="
                                    //     )[1] +
                                    //     "&getstarted&lis=1"
                                    //   }
                                    //   target="_blank"
                                    // >
                                    <button
                                      class="btn-submit"
                                      onClick={() =>
                                        requesttoAccess(
                                          "https://business.google.com/create?fp=" +
                                            data.location.metadata.mapsUri.split(
                                              "cid="
                                            )[1] +
                                            "&getstarted&lis=1"
                                        )
                                      }
                                    >
                                      Request Claim
                                    </button>
                                    // </a>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </>
                  ) : (
                    <div className="content-sub-wrapper">
                      <div className="content-sub">
                        <p id="nodata">No Data Found</p>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-lg-8">
                  <div className="w-100">
                    <div className="sub_from_audit">From GBP</div>
                  </div>
                  <div className="tableResponsive">
                    {dataList.length !== 0 &&
                    claimedData.filter((el) => {
                      return dataList.find((element) => {
                        return element.title == el.location.title;
                      });
                    }).length > 0 ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th
                              className="col-1"
                              style={{ maxWidth: "2%" }}
                            ></th>
                            <th className="col-4">Business</th>
                            <th className="col-3">Administrative Area</th>
                            <th
                              className="col-1"
                              style={{ maxWidth: "12%" }}
                            ></th>
                          </tr>
                        </thead>
                        <div class="table_Outerscroll">
                          <>
                            {dataList.map((item, i) => {
                              let active = false;
                              let colour = "yellow";
                              //! In the case of permanently deleted status, the class for redActive has been added in css.
                              //! Search for redActive
                              //? Condition for checking duplicate starts here
                              // item.has_voice_of_merchant === 0 &&
                              //   item.can_modify_service_list === 0 &&
                              //   item.place_id != "" &&
                              //   (colour = "yellow");
                              matchingLocationId = dataList[0].location_id;
                              //? Condition for checking duplicate ends here
                              // claimedData.length !== 0 &&
                              //   claimedData.forEach((firstObject, i) => {
                              //     if (
                              //       item.title.includes(
                              //         firstObject.location.title
                              //       )
                              //     ) {
                              //       console.log("kkkk");
                              //       active = true;
                              //       $("#requestAccessUrl").attr("hidden", true);
                              //     }
                              //   });
                              // console.log(
                              //   "hehehehehe",
                              //   claimedData.filter((el) => {
                              //     return dataList.find((element) => {
                              //       return element.title == el.location.title;
                              //     });
                              //   })
                              // );
                              if (
                                claimedData.length !== 0 &&
                                claimedData.filter(
                                  (firstObject, i) =>
                                    firstObject.location.storefrontAddress
                                      .administrativeArea ==
                                      item.administrativeArea &&
                                    firstObject.location.storefrontAddress
                                      .locality ==
                                      item.locality
                                ).length > 0
                              ) {
                                active = true;
                              }
                              // if (abc.length > 1) active = true;
                              return (
                                <>
                                  {claimedData.length !== 0 ? (
                                    <>
                                      {active === true && (
                                        <tbody className="table_Outertbody">
                                          <tr
                                            className={
                                              active === true
                                                ? i == 0
                                                  ? "table_Outertbody"
                                                  : i == 1
                                                  ? "table_Outertbody lightGreenActive"
                                                  : "table_Outertbody yellowActive"
                                                : "table_Outertbody"
                                            }
                                          >
                                            <td
                                              className="col-1"
                                              style={{ maxWidth: "2%" }}
                                            >
                                              <input
                                                type="checkbox"
                                                className="example"
                                                id={
                                                  "checkboxGbp" +
                                                  item.location_id
                                                }
                                                onChange={(e) =>
                                                  selectGbp(
                                                    item.location_id,
                                                    e.target.checked,
                                                    item
                                                  )
                                                }
                                              />
                                            </td>
                                            <td className="col-2">
                                              {item.title}
                                              <span
                                                style={{ display: "block" }}
                                              >
                                                {item.addressLines}
                                              </span>
                                            </td>
                                            <td className="col-2">
                                              {item.locality} ,{" "}
                                              {item.administrativeArea}
                                            </td>
                                            <td className="col-2">
                                              {item.has_voice_of_merchant ===
                                                1 && (
                                                <p>
                                                  <Icon.CheckCircle
                                                    style={{ color: "GREEN" }}
                                                    className="icon_common"
                                                  />
                                                  Verified
                                                </p>
                                              )}
                                              {item.has_voice_of_merchant ===
                                                0 &&
                                                item.can_modify_service_list ===
                                                  0 &&
                                                item.place_id != "" && (
                                                  <>
                                                    <p>
                                                      <Icon.AlertTriangle
                                                        style={{ color: "RED" }}
                                                        className="icon_common"
                                                      />
                                                      Duplicate
                                                    </p>
                                                    <Icon.Trash2
                                                      color="red"
                                                      className="mr-2"
                                                      onClick={() =>
                                                        deleteDuplicationLocation(
                                                          item.location_id
                                                        )
                                                      }
                                                    >
                                                      Delete
                                                    </Icon.Trash2>
                                                    <button
                                                      className="btn btn-info"
                                                      onClick={() =>
                                                        mergeLocation(
                                                          item.location_id
                                                        )
                                                      }
                                                    >
                                                      Merge
                                                    </button>
                                                  </>
                                                )}

                                              {item.has_voice_of_merchant ===
                                                0 &&
                                                item.place_id == "" && (
                                                  <p>
                                                    <Icon.CheckCircle
                                                      style={{
                                                        color: "#e5a924",
                                                      }}
                                                      className="icon_common"
                                                    />
                                                    Pending edits
                                                  </p>
                                                )}
                                            </td>
                                          </tr>
                                        </tbody>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <tbody className="table_Outertbody">
                                        <tr
                                          className={
                                            active === true
                                              ? "table_Outertbody greenActive"
                                              : "table_Outertbody"
                                          }
                                        >
                                          <td
                                            className="col-1"
                                            style={{ maxWidth: "2%" }}
                                          >
                                            <input type="checkbox" />
                                          </td>
                                          <td className="col-1">
                                            {item.title}
                                          </td>
                                          <td className="col-2">
                                            {item.addressLines}
                                          </td>
                                          <td className="col-2">
                                            {moment(item.created_on).format(
                                              "DD MMM YYYY hh:mm a"
                                            )}
                                          </td>
                                          <td className="col-2">
                                            {item.locality} ,{" "}
                                            {item.administrativeArea}
                                          </td>
                                          <td
                                            className="col-1"
                                            style={{ maxWidth: "12%" }}
                                          >
                                            {item.has_voice_of_merchant ===
                                              1 && (
                                              <p>
                                                <Icon.CheckCircle
                                                  style={{ color: "GREEN" }}
                                                  className="icon_common"
                                                />
                                                Verified
                                              </p>
                                            )}
                                            {item.has_voice_of_merchant === 0 &&
                                              item.can_modify_service_list ===
                                                0 &&
                                              item.place_id != "" && (
                                                <>
                                                  <p>
                                                    <Icon.AlertTriangle
                                                      style={{ color: "RED" }}
                                                      className="icon_common"
                                                    />
                                                    Duplicate
                                                  </p>
                                                </>
                                              )}

                                            {item.has_voice_of_merchant === 0 &&
                                              item.place_id == "" && (
                                                <p>
                                                  <Icon.CheckCircle
                                                    style={{ color: "#e5a924" }}
                                                    className="icon_common"
                                                  />
                                                  Pending edits
                                                </p>
                                              )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </>
                        </div>
                      </table>
                    ) : (
                      <table className="table">
                        No Data available in your GBP.
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </>
          </div>
          {/* <div className="content-sub-wrapper">
                {claimedData
                  .filter((obj) => obj.location.title === claimedTitle)
                  .map((data) => {
                    console.log("hiiiii", data);
                    return (
                      <div className="content-sub" key={data.location.title}>
                        <div className="row">
                          <div className="col-3">
                            <label className="col-form-label">Title</label>
                          </div>
                          <div className="col-1">
                            <label className="col-form-label">:</label>
                          </div>
                          <div className="col-7">
                            {" "}
                            <label className="col-form-label">
                              {" "}
                              {data.location.title}
                            </label>
                          </div>
                        </div>
                        <div className="address-section-bg row ml-0">
                          <div className="col-3 pl-0">
                            <label className="col-form-label">Address</label>
                          </div>
                          <div className="col-1 pl-0">
                            <label className="col-form-label">:</label>
                          </div>
                          <div className="col-7 pl-2">
                            {data.location.storefrontAddress.addressLines.map(
                              (data) => (
                                <div className="col-form-label py-0">
                                  {" "}
                                  {data},
                                </div>
                              )
                            )}
                            {data.location.storefrontAddress.locality},<br />
                            {data.location.storefrontAddress.administrativeArea}
                            ,<br />
                            {data.location.storefrontAddress.postalCode}
                          </div>
                        </div>
                        <hr></hr>
                        <div className="button-wrapper text-center">
                          <button
                            class="btn-cancel"
                            onClick={() => this.cancelPopUp()}
                          >
                            Cancel
                          </button>

                          {data.requestAdminRightsUri ? (
                            <a
                              href={data.requestAdminRightsUri}
                              target="_blank"
                            >
                              <button class="btn-submit">
                                Request to Access
                              </button>
                            </a>
                          ) : (
                            <a
                              href={
                                "https://business.google.com/create?fp=" +
                                data.location.metadata.mapsUri.split(
                                  "cid="
                                )[1] +
                                "&getstarted&lis=1"
                              }
                              target="_blank"
                            >
                              <button class="btn-submit">Request Claim</button>
                            </a>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div> */}
          {/* <div className="col-md-4">
              <div className="content-sub-wrapper">
                {claimedData
                  .filter((obj) => obj.location.title === claimedTitle)
                  .map((data) => (
                    <div className="content-sub" key={data.location.title}>
                      {data.requestAdminRightsUri !== "" && (
                        <div
                          className="col-12 text-right"
                          style={{ top: "-10px", height: "0" }}
                        >
                          <div className="verified">Verified</div>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-3">
                          <label className="col-form-label">Title</label>
                        </div>
                        <div className="col-1">
                          <label className="col-form-label">:</label>
                        </div>
                        <div className="col-7">
                          {" "}
                          <label className="col-form-label">
                            {" "}
                            {data.location.title}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <label className="col-form-label">Phone No.</label>
                        </div>
                        <div className="col-1">
                          <label className="col-form-label">:</label>
                        </div>
                        <div className="col-7">
                          {" "}
                          <label className="col-form-label">
                            {" "}
                            {data.location.phoneNumbers.primaryPhone}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <label className="col-form-label">Region Code</label>
                        </div>
                        <div className="col-1">
                          <label className="col-form-label">:</label>
                        </div>
                        <div className="col-7">
                          {" "}
                          <label className="col-form-label">
                            {" "}
                            {data.location.storefrontAddress.regionCode}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <label className="col-form-label">
                            Administartion
                          </label>
                        </div>
                        <div className="col-1">
                          <label className="col-form-label">:</label>
                        </div>
                        <div className="col-7">
                          {" "}
                          <label className="col-form-label">
                            {" "}
                            {data.location.storefrontAddress.administrativeArea}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <label className="col-form-label">Locality</label>
                        </div>
                        <div className="col-1">
                          <label className="col-form-label">:</label>
                        </div>
                        <div className="col-7">
                          {" "}
                          <label className="col-form-label">
                            {" "}
                            {data.location.storefrontAddress.locality}
                          </label>
                        </div>
                      </div>
                      <div className="address-section-bg row ml-0">
                        <div className="col-3 pl-0">
                          <label className="col-form-label">Address</label>
                        </div>
                        <div className="col-1 pl-0">
                          <label className="col-form-label">:</label>
                        </div>
                        <div className="col-7 pl-2">
                          {data.location.storefrontAddress.addressLines.map(
                            (data) => (
                              <div className="col-form-label py-0"> {data}</div>
                            )
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 row">
                          <div className="col-3">
                            <label className="col-form-label">Latitude</label>
                          </div>
                          <div className="col-1">
                            <label className="col-form-label">:</label>
                          </div>
                          <div className="col-7">
                            {" "}
                            <label className="col-form-label">
                              {" "}
                              {data.location.latlng.latitude}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div className="col-3">
                            <label className="col-form-label">Longitude</label>
                          </div>
                          <div className="col-1">
                            <label className="col-form-label">:</label>
                          </div>
                          <div className="col-7">
                            {" "}
                            <label className="col-form-label">
                              {" "}
                              {data.location.latlng.longitude}
                            </label>
                          </div>
                        </div>
                      </div>
                      <hr></hr>
                      <div className="button-wrapper text-center">
                        <button
                          class="btn-cancel"
                          onClick={() => this.cancelPopUp()}
                        >
                          Cancel
                        </button>

                        {data.requestAdminRightsUri ? (
                          <a href={data.requestAdminRightsUri} target="_blank">
                            <button class="btn-submit">
                              Request to Access
                            </button>
                          </a>
                        ) : (
                          <a
                            href={
                              "https://business.google.com/create?fp=" +
                              data.location.metadata.mapsUri.split("cid=")[1] +
                              "&getstarted&lis=1"
                            }
                            target="_blank"
                          >
                            <button class="btn-submit">Request Claim</button>
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div> */}
          {/* <div className="col-md-4">
              <div className="content-sub-wrapper">
                {claimedData
                  .filter((obj) => obj.location.title === claimedTitle)
                  .map((data) => (
                    <div className="content-sub" key={data.location.title}>
                      <div className="row">
                        <div className="col-3">
                          <label className="col-form-label">Title</label>
                        </div>
                        <div className="col-1">
                          <label className="col-form-label">:</label>
                        </div>
                        <div className="col-7">
                          {" "}
                          <label className="col-form-label">
                            {" "}
                            {data.location.title}
                          </label>
                        </div>
                      </div>

                      <div className="address-section-bg row ml-0">
                        <div className="col-3 pl-0">
                          <label className="col-form-label">Address</label>
                        </div>
                        <div className="col-1 pl-0">
                          <label className="col-form-label">:</label>
                        </div>
                        <div className="col-7 pl-2">
                          {data.location.storefrontAddress.addressLines.map(
                            (data) => (
                              <div className="col-form-label py-0">
                                {" "}
                                {data},
                              </div>
                            )
                          )}
                          {data.location.storefrontAddress.locality},
                          {data.location.storefrontAddress.administrativeArea},
                          {data.location.storefrontAddress.postalCode}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 row">
                          <div className="col-3">
                            <label className="col-form-label">Latitude</label>
                          </div>
                          <div className="col-1">
                            <label className="col-form-label">:</label>
                          </div>
                          <div className="col-7">
                            {" "}
                            <label className="col-form-label">
                              {" "}
                              {data.location.latlng.latitude}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6 row">
                          <div className="col-3">
                            <label className="col-form-label">Longitude</label>
                          </div>
                          <div className="col-1">
                            <label className="col-form-label">:</label>
                          </div>
                          <div className="col-7">
                            {" "}
                            <label className="col-form-label">
                              {" "}
                              {data.location.latlng.longitude}
                            </label>
                          </div>
                        </div>
                      </div>
                      <hr></hr>
                      <div className="button-wrapper text-center">
                        <button
                          class="btn-cancel"
                          onClick={() => this.cancelPopUp()}
                        >
                          Cancel
                        </button>

                        {data.requestAdminRightsUri ? (
                          <a href={data.requestAdminRightsUri} target="_blank">
                            <button class="btn-submit">
                              Request to Access
                            </button>
                          </a>
                        ) : (
                          <a
                            href={
                              "https://business.google.com/create?fp=" +
                              data.location.metadata.mapsUri.split("cid=")[1] +
                              "&getstarted&lis=1"
                            }
                            target="_blank"
                          >
                            <button class="btn-submit">Request Claim</button>
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div> */}
        </div>
        {/* <div className="popup-overlay" id="markedSubModal">
            <div className="popup-contentDivSm">
              <div className="modal-popup pb-3">
                <div className="module_close">
                  <Icon.XCircle
                    color="red"
                    onClick={() => {
                      setclaimedTitle("");
                      $("#markedSubModal").attr("hidden", true);
                    }}
                  />
                </div>
                <div className="content">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="popup-head">Google Locations</h3>
                    </div>
                  </div>
                  <hr className="mt-0"></hr>
                  <div className="content-sub-wrapper">
                    {claimedData
                      .filter((obj) => obj.location.title === claimedTitle)
                      .map((data) => (
                        <div className="content-sub" key={data.location.title}>
                          {data.requestAdminRightsUri !== "" && (
                            <div
                              className="col-12 text-right"
                              style={{ top: "-10px", height: "0" }}
                            >
                              <div className="verified">Verified</div>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-3">
                              <label className="col-form-label">Title</label>
                            </div>
                            <div className="col-1">
                              <label className="col-form-label">:</label>
                            </div>
                            <div className="col-7">
                              {" "}
                              <label className="col-form-label">
                                {" "}
                                {data.location.title}
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3">
                              <label className="col-form-label">
                                Phone No.
                              </label>
                            </div>
                            <div className="col-1">
                              <label className="col-form-label">:</label>
                            </div>
                            <div className="col-7">
                              {" "}
                              <label className="col-form-label">
                                {" "}
                                {data.location.phoneNumbers.primaryPhone}
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3">
                              <label className="col-form-label">
                                Region Code
                              </label>
                            </div>
                            <div className="col-1">
                              <label className="col-form-label">:</label>
                            </div>
                            <div className="col-7">
                              {" "}
                              <label className="col-form-label">
                                {" "}
                                {data.location.storefrontAddress.regionCode}
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3">
                              <label className="col-form-label">
                                Administartion
                              </label>
                            </div>
                            <div className="col-1">
                              <label className="col-form-label">:</label>
                            </div>
                            <div className="col-7">
                              {" "}
                              <label className="col-form-label">
                                {" "}
                                {
                                  data.location.storefrontAddress
                                    .administrativeArea
                                }
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3">
                              <label className="col-form-label">Locality</label>
                            </div>
                            <div className="col-1">
                              <label className="col-form-label">:</label>
                            </div>
                            <div className="col-7">
                              {" "}
                              <label className="col-form-label">
                                {" "}
                                {data.location.storefrontAddress.locality}
                              </label>
                            </div>
                          </div>
                          <div className="address-section-bg row ml-0">
                            <div className="col-3 pl-0">
                              <label className="col-form-label">Address</label>
                            </div>
                            <div className="col-1 pl-0">
                              <label className="col-form-label">:</label>
                            </div>
                            <div className="col-7 pl-2">
                              {data.location.storefrontAddress.addressLines.map(
                                (data) => (
                                  <div className="col-form-label py-0">
                                    {" "}
                                    {data}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 row">
                              <div className="col-3">
                                <label className="col-form-label">
                                  Latitude
                                </label>
                              </div>
                              <div className="col-1">
                                <label className="col-form-label">:</label>
                              </div>
                              <div className="col-7">
                                {" "}
                                <label className="col-form-label">
                                  {" "}
                                  {data.location.latlng.latitude}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6 row">
                              <div className="col-3">
                                <label className="col-form-label">
                                  Longitude
                                </label>
                              </div>
                              <div className="col-1">
                                <label className="col-form-label">:</label>
                              </div>
                              <div className="col-7">
                                {" "}
                                <label className="col-form-label">
                                  {" "}
                                  {data.location.latlng.longitude}
                                </label>
                              </div>
                            </div>
                          </div>
                          <hr></hr>
                          <div className="button-wrapper text-center">
                            <button
                              class="btn-cancel"
                              onClick={() => this.cancelPopUp()}
                            >
                              Cancel
                            </button>

                            {data.requestAdminRightsUri ? (
                              <a
                                href={data.requestAdminRightsUri}
                                target="_blank"
                              >
                                <button class="btn-submit">
                                  Request to Access
                                </button>
                              </a>
                            ) : (
                              <a
                                href={
                                  "https://business.google.com/create?fp=" +
                                  data.location.metadata.mapsUri.split(
                                    "cid="
                                  )[1] +
                                  "&getstarted&lis=1"
                                }
                                target="_blank"
                              >
                                <button class="btn-submit">
                                  Request Claim
                                </button>
                              </a>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
}
