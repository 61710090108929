import React from "react";
import DatePicker from "react-datepicker";
import * as Icon from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LeftMenu from "../layout/leftMenu";
import GooglePostimage from "../images/left_icons/Google-Post.png";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { useState } from "react";
import PostEvent from "./googlePostEvent";
import PostCovid19Updates from "./googlePostCovid19-updates";
import PostOffer from "./googlePostOffer";
import PostNew from "./googlePostWhatsnew";
import { useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
function CreateGooglePost() {
  const location = useLocation();
  const [topicType, setTopicType] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    console.log("locationlocationlocation111111", location);
    if (location.state.PostEditField) {
      setEventTab();
    } else {
      setTopicType("EVENT");
    }
    if (location.state?.eventType) {
      setTopicType(location.state.eventType);
    }
  }, []);
  const setEventTab = () => {
    location.state.PostEditField.length !== 0 &&
      setTopicType(location.state.PostEditField.topicType);
  };
  return (
    <>
      {" "}
      <div className=" main_wrapper">
        <LeftMenu />
        <div className=" userListWrapper">
          <Header heading="Add Post" headerImage={GooglePostimage} />

          <div className="container">
            <div className="row mb-3 ml-1 ">
              {location.state?.PostEditField.length === 0 ? (
                <Icon.ArrowLeftCircle
                  className="back-to-link mr-3"
                  onClick={() =>
                    navigate("/googlepost/index", {
                      state: {
                        completeLocationData:
                          location.state.completeLocationData,
                      },
                    })
                  }
                />
              ) : (
                // <Link to="/googlepost/index" className="back-to-link mr-3">
                //   <Icon.ArrowLeftCircle />
                // </Link>
                <Link to="/googlepost/postListing" className="back-to-link">
                  <Icon.ArrowLeftCircle />
                </Link>
              )}
              <div className="subhead">New Post</div>
            </div>

            <div className="row mx-0">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                  <Tabs
                    selectedIndex={
                      location.state.PostEditField.length !== 0
                        ? topicType === "EVENT"
                          ? 0
                          : topicType === "STANDARD"
                          ? 1
                          : topicType === "OFFER" && 2
                        : topicType === "EVENT"
                        ? 0
                        : topicType === "STANDARD"
                        ? 1
                        : topicType === "OFFER"
                        ? 2
                        : 0
                    }
                    className="tabs-main"
                    onSelect={(e) =>
                      setTopicType(
                        e === 0
                          ? "EVENT"
                          : e === 1
                          ? "STANDARD"
                          : e === 2 && "OFFER"
                      )
                    }
                  >
                    <TabList className={"pl-0"}>
                      <Tab>Events</Tab>
                      <Tab>Whats New</Tab>
                      <Tab>Offers</Tab>
                    </TabList>

                    <hr></hr>
                    <TabPanel>
                      <PostEvent />
                    </TabPanel>
                    <TabPanel>
                      <PostNew />
                    </TabPanel>
                    <TabPanel>
                      <PostOffer />
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default CreateGooglePost;
