import React, { Component } from "react";
import LeftMenu from "../layout/leftMenu";
import { Link } from "react-router-dom";
import QandA from "../images/left_icons/qa.png";
import AddImg from "../images/google_preview.PNG";
import * as Icon from "react-feather";
import Header from "../layout/header";
import AllSearches from "../GBP/all_searches";
import TotalView from "../GBP/total_view";
import Gbpimage from "../images/left_icons/GBP.png";
import GbpSlidrrimage from "../images/slider2.jpg";
import { backendApi, apiCallingPost } from "../apiService";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import moment from "moment";
import $, { data } from "jquery";
import AWS from "aws-sdk";
// import { Upload } from "@aws-sdk/lib-storage";
// import { S3Client, S3 } from "@aws-sdk/client-s3";
import Slider from "react-slick";
import "../css/slick.css";
import "../css/slick-theme.css";
import axios from "axios";
import Footer from "../layout/footer";

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1201,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

var settingsSlider = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1201,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
var s3;
var partSize = 1024 * 1024 * 5;
var partNum = 0;
var globalLocationId = 0;
const s3Region = "us-east-1",
  s3bucketAccessKey = process.env.REACT_APP_TAGTREE_S3_ACCESS_KEY,
  s3bucketSecretKey = process.env.REACT_APP_TAGTREE_S3_SECRET_KEY,
  s3Bucket = "tagtree",
  s3BucketMainFolder = "production",
  S3BucketImagesFolder = "images",
  bucketParams = {
    Bucket: s3Bucket,
  };
export default class AddPhotos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrlListStatus: 0,
      imageUrl: [],
      insightsStartDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ).toISOString(),
      insightsEndDate: new Date().toISOString(),
      temporaryLocationsList: [],
      localityList: [],
      localityListBackup: [],
      locationsList: [],
      administrativeAreaList: [],
      selectLocation: "",
      locationMedia: [],
      setCategory: "CATEGORY_UNSPECIFIED",
      selectedLocationsArray: [],
      selectedLocationsCompleteDataArray: [],
      setChangeStatus: 0,
      s3Credentials: [],
    };
    this.selectLocation = this.selectLocation.bind(this);
    this.selectAllLocations = this.selectAllLocations.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
  }
  componentDidMount() {
    // this.getLocationsList();
    $("#editMatchAddress").attr("hidden", true);
    this.newGetLocationsList();
    this.getS3Credentials();
  }
  getS3Credentials = () => {
    // setS3Credentials(data.data[0]);

    var bucketParams = {
      Bucket: s3Bucket,
    };
    AWS.config.update({
      accessKeyId: s3bucketAccessKey,
      secretAccessKey: s3bucketSecretKey,
      region: s3Region,
    });
    s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: bucketParams,
    });
    console.log("s3", s3);
  };
  newGetLocationsList = () => {
    $(".loder_div").attr("hidden", false);
    const apiJson = {
      functionName: "getMasterDataForFilter",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    apiCallingPost(apiJson).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        this.setState(
          {
            locationsList: data.data.map((data) => ({
              ...data,
              checked: false,
            })),
            temporaryLocationsList: data.data.map((data) => ({
              ...data,
              checked: false,
            })),
          },
          () => {
            if (window.sessionStorage.getItem("selectedLocation") !== "") {
              debugger;
              const {
                locationsList,
                temporaryLocationsList,
                selectedLocationsCompleteDataArray,
                selectedLocationsArray,
              } = this.state;
              let element = JSON.parse(
                window.sessionStorage.getItem("selectedLocation")
              );
              element.checked = true;
              let index = locationsList.findIndex((obj) => {
                return obj.location_id === element.location_id;
              });

              locationsList.splice(index, 1);
              temporaryLocationsList.splice(index, 1);
              selectedLocationsArray.push(element.location_id);
              this.setState((prevState) => ({
                selectedLocationsCompleteDataArray: [
                  ...prevState.selectedLocationsCompleteDataArray,
                  element,
                ],
                setChangeStatus: 5,
              }));
            }
          }
        );
        globalLocationId = data.data.length;
      }
    });
  };

  async handleImageUpload(image) {
    let s3BucketMainFolder1 = s3BucketMainFolder + "/" + S3BucketImagesFolder;
    var ttalCount = image.length;
    for (let i = 0; i < image.length; i++) {
      console.log(
        "🚀 ~ file: addPhotos.jsx:345 ~ AddPhotos ~ handleImageUpload ~ image[i]:",
        image[i]
      );
      if (image[i].size < 4900000 && image[i].size > 10240) {
        if (image[i].type !== "image/jpeg" && image[i].type !== "image/png") {
          alert("Please upload only JPEG or PNG files.");
          return false;
        }

        if (i === 11) {
          alert("Only 10 Images can be uploaded...");
          $(".loder_div").attr("hidden", true);
          return false;
        }
        $(".loder_div").attr("hidden", false);
        let fileName = this.uuidv4() + image[i].name;
        let fullFilePath = s3BucketMainFolder1 + "/" + fileName;
        s3.upload(
          {
            Key: fullFilePath,
            Body: image[i],
            ACL: "public-read",
          },
          (err, data) => {
            if (err) {
              alert("There was an error uploading your image");
              $(".loder_div").attr("hidden", true);
            } else if (data) {
              $("#uploadedImage").attr("hidden", false);
              $("#multipleImagesUploadingSlider").attr("hidden", false);
              (this.state.setCategory === "LOGO" ||
                this.state.setCategory === "COVER") &&
                this.state.imageUrl.splice(0, this.state.imageUrl.length);
              this.state.imageUrl.push(data.Location);
              this.setState({
                imageUrlListStatus: i + 1,
              });
              ttalCount = ttalCount - 1;
              console.log("voooooooooooo", ttalCount);
              if (ttalCount === 0) $(".loder_div").attr("hidden", true);
              // this.state.imageName.push(data.fileName);
              // this.setState({ imageUrl: data.Location, imageName: fileName });
            }
          }
        );
      } else {
        ttalCount = ttalCount - 1;
        console.log("voooooooooooo", ttalCount);
        alert("Files Size must be less than 5MB or greater than 10KB");
        $(".loder_div").attr("hidden", true);
        // this.multipartUpload(image[i]);
      }
    }
  }

  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  getLocationMedia(location) {
    $(".loder_div").attr("hidden", false);
    const apiRequest = {
      functionName: "GetLocationMedia",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      location_id: location.name.split("/")[1],
    };
    backendApi(apiRequest).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        this.setState({
          locationMedia: data.data.mediaItems,
        });
        $("#multipleImagesSlider").attr("hidden", false);
      }
    });
  }
  deleteLocationMedia = (location) => {
    $(".loder_div").attr("hidden", false);

    const apiReq = {
      functionName: "deleteLocationMedia",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      location_id: location.name.split("/")[3],
      media_id: location.name.split("/")[5],
    };
    backendApi(apiReq).then((data) => {
      if (data.success === "1") {
        alert("Deleted Successfully");
        window.location.reload();
      } else {
        alert("Something went wrong whil deleting Image");
      }
    });
  };
  selectAllLocations = (e) => {
    if (e.target.checked === true) {
      this.setState({ setChangeStatus: 2 });
      const {
        locationsList,
        temporaryLocationsList,
        selectedLocationsCompleteDataArray,
      } = this.state;
      // for (let i = 0; i < this.state.locationsList.length; i++) {
      //   $(
      //     "#selectLocationPost" + this.state.locationsList[i].name.split("/")[1]
      //   ).prop("checked", true);
      // }
      if (this.state.selectedLocationsArray.length > 0) {
        let filteredArray = this.state.locationsList.filter(
          (value) =>
            !this.state.selectedLocationsArray.includes(value.location_id)
        );
        filteredArray.map((obj) => {
          this.state.selectedLocationsArray.push(obj.location_id);
          // this.state.selectedLocationsCompleteDataArray.push(obj);
        });
      } else {
        this.state.locationsList.map((obj) => {
          this.state.selectedLocationsArray.push(obj.location_id);
          // this.state.selectedLocationsCompleteDataArray.push(obj);
        });
      }
      this.setState((prevState) => ({
        selectedLocationsCompleteDataArray: [
          ...prevState.selectedLocationsCompleteDataArray,
          ...this.state.locationsList.map((data) => ({
            ...data,
            checked: true,
          })),
        ],
        locationsList: [],
        temporaryLocationsList: [],
      }));
    } else {
      this.setState((prevState) => ({
        locationsList: [
          ...prevState.locationsList,
          ...this.state.selectedLocationsCompleteDataArray.map((data) => ({
            ...data,
            checked: false,
          })),
        ],
        temporaryLocationsList: [
          ...prevState.temporaryLocationsList,
          ...this.state.selectedLocationsCompleteDataArray.map((data) => ({
            ...data,
            checked: false,
          })),
        ],
        selectedLocationsCompleteDataArray: [],
      }));
      // for (let i = 0; i < this.state.locationsList.length; i++) {
      //   $(
      //     "#selectLocationPost" + this.state.locationsList[i].name.split("/")[1]
      //   ).prop("checked", false);
      // }
      this.setState({
        selectedLocationsArray: [],
        // selectedLocationsCompleteDataArray: [],
      });
    }
    var selectAllList = [];
  };
  selectLocation = (e, data, i) => {
    if (
      e.target.checked === true &&
      !this.state.selectedLocationsArray.includes(data.location_id)
    ) {
      this.setState({ setChangeStatus: 1 });
      this.state.selectedLocationsArray.push(data.location_id);
      // this.state.selectedLocationsCompleteDataArray.push(data);
      const { locationsList, temporaryLocationsList } = this.state;
      let index = locationsList.findIndex((obj) => {
        return obj.location_id === data.location_id;
      });
      let index3 = temporaryLocationsList.findIndex((obj) => {
        return obj.location_id === data.location_id;
      });
      let a = locationsList.map((object) => {
        if (
          object.location_id === data.location_id &&
          object.checked === false
        ) {
          return { ...object, checked: true };
        }
        return object;
      });
      let b = temporaryLocationsList.map((object) => {
        if (
          object.location_id === data.location_id &&
          object.checked === false
        ) {
          return { ...object, checked: true };
        }
        return object;
      });
      let deletedElA = a.splice(index, 1);
      locationsList.splice(index, 1);
      temporaryLocationsList.splice(index3, 1);
      this.setState((prevState) => ({
        selectedLocationsCompleteDataArray: [
          ...prevState.selectedLocationsCompleteDataArray,
          ...deletedElA,
        ],
      }));
      // a.unshift(...deletedElA);
      // b.unshift(...deletedElB);
    } else if (e.target.checked === false) {
      this.setState({ setChangeStatus: 3 });
      const {
        locationsList,
        temporaryLocationsList,
        selectedLocationsCompleteDataArray,
      } = this.state;
      let index2 = this.state.selectedLocationsCompleteDataArray.findIndex(
        (obj) => {
          return obj.location_id === data.location_id;
        }
      );
      let c = selectedLocationsCompleteDataArray.map((object) => {
        if (
          object.location_id === data.location_id &&
          object.checked === true
        ) {
          return { ...object, checked: false };
        }
        return object;
      });
      // let d = temporaryLocationsList.map((object) => {
      //   if (object.name === data.name && object.checked === true) {
      //     return { ...object, checked: false };
      //   }
      //   return object;
      // });
      let deletedElA = c.splice(index2, 1);
      selectedLocationsCompleteDataArray.splice(index2, 1);
      // let deletedElB = d.splice(index2, 1);
      // c.push(...deletedElA);
      // d.push(...deletedElB);
      this.setState((prevState) => ({
        locationsList: [...prevState.locationsList, ...deletedElA],
        temporaryLocationsList: [
          ...prevState.temporaryLocationsList,
          ...deletedElA,
        ],
      }));

      if (
        this.state.selectedLocationsArray.length !==
        this.state.locationsList.filter
      ) {
        $("#selectAllLocations").prop("checked", false);
      }

      let index = this.state.selectedLocationsArray.indexOf(data.location_id);
      this.state.selectedLocationsArray.splice(index, 1);
      // this.state.selectedLocationsCompleteDataArray.splice(index, 1);

      // if (this.state.selectedLocationsArray.length === 0) {
      //   setCreatePostButton(false);
      // } else {
      //   setCreatePostButton(true);
      // }
      // this.state.selectedLocationsArray.length === 0
    }
  };
  postPhotoToGBP = () => {
    // alert("ooooooo");
    $(".loder_div").attr("hidden", false);
    var totCount = this.state.selectedLocationsArray.length;
    var imageData = []; // create an array to store the image data
    for (const object of this.state.selectedLocationsArray) {
      for (const data of this.state.imageUrl) {
        console.log("🚀 ~ file: addPhotos.jsx:621 ~ AddPhotos ~ data:", data);
        var req = {
          functionName: "PostMediaGBP",
          account: window.sessionStorage.getItem("account_id"),
          locationId: object,
          category: this.state.setCategory,
          mediaFormat: $("#formatTypeDropdown").val(),
          source: data,
          siteId:
            window.sessionStorage.getItem("switchingOrgId") === ""
              ? window.sessionStorage.getItem("organizationId")
              : window.sessionStorage.getItem("switchingOrgId"),
        };
        backendApi(req).then((response) => {
          debugger;
          totCount = totCount - 1;
          if (response.success == 1) {
            console.log(response, "PostMediaGBPPostMediaGBPPostMediaGBP");
            var post = {
              functionName: "gbpImageUploadSave",
              site_id:
                window.sessionStorage.getItem("switchingOrgId") === ""
                  ? window.sessionStorage.getItem("organizationId")
                  : window.sessionStorage.getItem("switchingOrgId"),
              location_id: object,
              image_path: data,
              created_by: window.sessionStorage.getItem("userId"),
            };
            backendApi(post).then((response) => {
              console.log(response, "gbpImageUploadSave");
              console.log(totCount, "totCount");
              if (totCount === 0) {
                alert("Images Posted Successfully");
                window.location.href = "/GBPPhotos";
                $(".loder_div").attr("hidden", true);
              }
            });
            // store the image data in the array
            imageData.push({
              locationId: object,
              imageUrl: data,
            });
          } else {
            if (totCount === 0) {
              if (response.success === "0") {
                alert("Error in Uploading Image(s).");
              }
              console.error("Error in Image Upload", response.data.error);
              // window.location.href = "/GBPPhotos";
              $(".loder_div").attr("hidden", true);
            }
          }
        });
      }
    }
  };

  // postPhotoToGBP = () => {
  //   alert("ooooooo")
  //   debugger
  //   $(".loder_div").attr("hidden", false);
  //   var totCount = this.state.selectedLocationsArray.length;
  //   for (const object of this.state.selectedLocationsArray) {
  //     for (const data of this.state.imageUrl) {
  //       var req = {
  //         functionName: "PostMediaGBP",
  //         account: window.sessionStorage.getItem("account_id"),
  //         locationId: object,
  //         category: this.state.setCategory,
  //         mediaFormat: $("#formatTypeDropdown").val(),
  //         source: data,
  //         siteId:
  //           window.sessionStorage.getItem("switchingOrgId") === ""
  //             ? window.sessionStorage.getItem("organizationId")
  //             : window.sessionStorage.getItem("switchingOrgId"),
  //       };
  //       backendApi(req).then((response) => {
  //         console.log(response,"PostMediaGBPPostMediaGBPPostMediaGBP");
  //         debugger
  //         //call new API here
  //         var post = {
  //           "functionName": "gbpImageUploadSave",
  //           "site_id":window.sessionStorage.getItem("switchingOrgId") === ""
  //           ? window.sessionStorage.getItem("organizationId")
  //           : window.sessionStorage.getItem("switchingOrgId"),
  //           "location_id": object,
  //           "image_path":this.state.imageUrl,
  //           "created_by":window.sessionStorage.getItem("userId")
  //         };
  //         backendApi(post).then((response) => {

  //         });
  //       });
  //     }

  //     totCount = totCount - 1;
  //     if (totCount === 0) {
  //       alert("Images Posted Successfully");
  //       window.location.href = "/GBPPhotos";
  //       $(".loder_div").attr("hidden", true);
  //     }
  //   }
  // };
  filterLocations = (searchVal) => {
    if (searchVal.length === 0) {
      this.setState({ locationsList: this.state.temporaryLocationsList });
    } else {
      this.setState(
        { locationsList: this.state.temporaryLocationsList },
        () => {
          var filteredList = this.state.locationsList.filter((obj) => {
            return (
              obj.title
                .toLocaleLowerCase()
                .includes(searchVal.toLocaleLowerCase()) ||
              obj.locality
                .toLocaleLowerCase()
                .includes(searchVal.toLocaleLowerCase()) ||
              obj.administrativeArea
                .toLocaleLowerCase()
                .includes(searchVal.toLocaleLowerCase())
            );
          });
          this.setState({ locationsList: filteredList });
        }
      );
    }
  };
  render() {
    return (
      <div className="main_wrapper  ">
        <LeftMenu />
        <div className="userListWrapper ">
          <Header heading="Add Photos to GBP" headerImage={QandA} />
          <div className="container-fluid">
            <div className="row mb-3 ml-1">
              <Link to="/GBPPhotos" className="back-to-link mr-3">
                <Icon.ArrowLeftCircle />
              </Link>
              <div className="subhead">Add Photos</div>
            </div>

            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="addPhotosDiv_gbp">
                  <div className="addPhotoIconCam">
                    <Icon.Camera />
                  </div>
                  <div className="editInfo col-lg-12 ">
                    <div className="row ">
                      <div className="col-lg-6 col-md-6 col-12 ">
                        <select
                          id="categoryDropdown"
                          onChange={(e) => {
                            if (e.target.value !== "") {
                              this.setState({
                                setCategory: e.target.value,
                              });
                            }
                          }}
                          className="form-control"
                        >
                          <option value="CATEGORY_UNSPECIFIED">General</option>
                          <option
                            hidden={
                              this.state.imageUrl.length > 1 ? true : false
                            }
                            id="logoId"
                            value="LOGO"
                          >
                            Logo
                          </option>
                          <option
                            hidden={
                              this.state.imageUrl.length > 1 ? true : false
                            }
                            id="coverId"
                            value="COVER"
                          >
                            Cover
                          </option>
                          <option value="EXTERIOR">Exterior</option>
                          <option value="INTERIOR">Interior</option>
                          <option value="PRODUCT">Product</option>
                          <option value="AT_WORK">At Work</option>
                          <option value="FOOD_AND_DRINK">Food & Drink</option>
                          <option value="MENU">Menu</option>
                          <option value="COMMON_AREA">Common Area</option>
                          <option value="ROOMS">Rooms</option>
                          <option value="TEAMS">Teams</option>
                          <option value="ADDITIONAL">Additional</option>
                        </select>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <select
                          id="formatTypeDropdown"
                          className="form-control"
                        >
                          <option value="PHOTO">Photo</option>
                          <option value="VIDEO">Video</option>
                        </select>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-lg-3 col-md-12 tag_info_contentlocation">
                        <div className="right_google_popup">
                          <label>
                            <Icon.Camera />
                            Add photos
                            <input
                              type="file"
                              multiple />
                          </label>
                        </div>
                      </div>
                      <div className="tag_photoSave">
                        <button type="button"
                          className="btn btn-success" >
                          Save
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="subhead row" style={{ fontSize: "16px" }}>
                  <div className="col-md-8">Select Location(s) to post</div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      placeholder="Filter"
                      onChange={(e) => this.filterLocations(e.target.value)}
                      className="form-control mb-0"
                    />
                  </div>
                </div>
                <hr className="mt-0"></hr>
                <div className="col-md-12 tableResponsive mb-2">
                  <div className="table_leftOuterWidth">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="col-1">
                            <input
                              type="checkbox"
                              id="selectAllLocations"
                              onClick={(e) => this.selectAllLocations(e)}
                              name=""
                            />
                          </th>
                          <th className="col-2">Location(s)</th>
                          <th className="col-3">Address</th>
                          <th className="col-3">Locality</th>
                          <th className="col-3">Area</th>
                        </tr>
                      </thead>
                      <>
                        {this.state.selectedLocationsCompleteDataArray
                          .length !== 0 && (
                          <>
                            {this.state.selectedLocationsCompleteDataArray.map(
                              (data, i) => (
                                <tbody className="table_Outertbody">
                                  <tr>
                                    <td className="col-1">
                                      <input
                                        // id={
                                        //   "selectLocationPost" + data.name.split("/")[1]
                                        // }
                                        type="checkbox"
                                        name="location"
                                        checked={data.checked}
                                        onChange={(e) =>
                                          this.selectLocation(e, data, i)
                                        }
                                      />
                                    </td>
                                    <td className="col-2">{data.title}</td>
                                    <td className="col-3">
                                      {data.addressLines}
                                    </td>
                                    <td className="col-3">{data.locality}</td>
                                    <td className="col-3">
                                      {data.administrativeArea}
                                    </td>
                                  </tr>
                                </tbody>
                              )
                            )}
                          </>
                        )}
                        {this.state.locationsList.length !== 0 && (
                          <>
                            {this.state.locationsList.map((data, i) => (
                              <tbody className="table_Outertbody">
                                <tr>
                                  <td className="col-1">
                                    <input
                                      // id={
                                      //   "selectLocationPost" + data.name.split("/")[1]
                                      // }
                                      type="checkbox"
                                      name="location"
                                      checked={data.checked}
                                      onChange={(e) =>
                                        this.selectLocation(e, data, i)
                                      }
                                    />
                                  </td>
                                  <td className="col-2">{data.title}</td>
                                  <td className="col-3">{data.addressLines}</td>
                                  <td className="col-3">{data.locality}</td>
                                  <td className="col-3">
                                    {data.administrativeArea}
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </>
                        )}
                      </>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="col-md-12">
                  <div className="right_google_popup">
                    <label>
                      <Icon.Camera />
                      Add photos
                      <input
                        type="file"
                        multiple={
                          this.state.setCategory === "LOGO" ||
                          this.state.setCategory === "COVER"
                            ? false
                            : true
                        }
                        id="imageUpload"
                        onChange={(e) => {
                          this.handleImageUpload(e.target.files);
                          e.target.value = "";
                        }}
                      />
                    </label>
                  </div>

                  <div className="mt-2">
                    {this.state.imageUrl.length !== 0 ? (
                      <ul className="nav imgaddphoto_gbp">
                        {this.state.imageUrl.map((data, i) => (
                          <li className="nav-item">
                            {/* <span
                              onClick={() => {
                                $("#editMatchAddress").attr("hidden", false);
                                $("#postImage").attr("src", data);
                              }}
                            > */}
                            <div className="img_bgaddphoto">
                              <div
                                className="img_bgaddphoto"
                                style={{ backgroundImage: `url(${data})` }}
                                onClick={() => {
                                  $("#editMatchAddress").attr("hidden", false);
                                  $("#postImage").attr("src", data);
                                }}
                              ></div>
                              <Icon.XCircle
                                onClick={() => {
                                  let items = [...this.state.imageUrl];
                                  items.splice(i, 1);
                                  this.setState({ imageUrl: items });
                                }}
                                className="text-danger"
                              />
                            </div>
                            {/* </span> */}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      ""
                    )}
                  </div>
                  {this.state.setChangeStatus !== "" && (
                    <div className="text-center mt-2">
                      {this.state.imageUrl.length === 0 ||
                      this.state.selectedLocationsArray.length === 0 ? (
                        <button type="button" className="btn btn-secondary">
                          Choose&nbsp;
                          {this.state.imageUrl.length === 0 &&
                            this.state.selectedLocationsArray.length === 0 &&
                            "Image(s) and Location(s)"}
                          {this.state.imageUrl.length !== 0 &&
                            this.state.selectedLocationsArray.length === 0 &&
                            "Location(s)"}
                          {this.state.imageUrl.length === 0 &&
                            this.state.selectedLocationsArray.length !== 0 &&
                            "Image(s)"}
                          &nbsp;to upload
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => this.postPhotoToGBP()}
                        >
                          Upload
                        </button>
                      )}
                      {/* {this.buttonsList(
                    this.state.imageUrl.length,
                    this.state.selectedLocationsArray.length
                  )} */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="module_popup editMatchAddress"
            id="editMatchAddress"
            hidden
          >
            <div className="module_innerpopup">
              <div className="module_close">
                <Icon.XCircle
                  color="red"
                  onClick={() => {
                    $("#editMatchAddress").attr("hidden", true);
                  }}
                />
              </div>
              <div className="module_body">
                <div className="form-group row">
                  <img id="postImage" src={""} style={{ width: "100%" }} />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}

// multipartUpload = async (image) => {
//   // alert("hi");
//   console.log("imageimageimageimage", image);
//   var path = (window.URL || window.webkitURL).createObjectURL(image);
//   console.log("pathpathpathpathpath", path);
//   let idCardBase64 = "";
//   const instance = axios.create({
//     baseURL:
//       "https://mybusiness.googleapis.com/v4/accounts/113149385002384039024/locations/53904911361864879",
//     headers: { Authorization: `Bearer ${token}` },
//   });
//   let reader = new FileReader();
//   reader.readAsDataURL(image);
//   reader.onload = function () {
//     //   console.log(reader.result);
//     let imageBase64 = reader.result;
//     instance
//       .post("/media:startUpload")
//       .then(async (res) => {
//         console.log("First axios call is success");
//         console.log("============", res.data);
//         await axios
//           .post(
//             `https://mybusiness.googleapis.com/upload/v1/media/${res.data.resourceName}?upload_type=media`,
//             path,
//             {
//               headers: {
//                 Authorization: `Bearer ${token}`,
//               },
//             }
//           )
//           .then(async (response) => {
//             console.log("second axios call is success");
//             let result = {
//               success: "1",
//               errorMessage: "",
//               successMessage: "Success",
//               data: res.data,
//             };
//             let body = {
//               mediaFormat: "PHOTO",
//               locationAssociation: {
//                 category: "CATEGORY_UNSPECIFIED",
//               },
//               dataRef: {
//                 resourceName: res.data.resourceName,
//               },
//             };
//             console.log("resultresultresultresultresulte==========", result);
//             console.log("bodybodybodybody", body);
//             await instance
//               .post("/media", body)
//               .then((result) => {
//                 console.log("third axios call is success");
//                 console.log("third axios result================", result);
//               })
//               .catch((err) => {
//                 console.log("inside third axios error");
//                 let result = {
//                   success: "0",
//                   errorMessage: err.message,
//                   successMessage: "",
//                   data: err.response.data.error.details[0].errorDetails,
//                 };
//                 console.log(
//                   "resultresultresultresultresult+++++++++++++++++",
//                   result
//                 );
//                 // return callBack(result);
//               });
//           })
//           .catch((err) => {
//             console.log("inside second axios error");
//             let result = {
//               success: "0",
//               errorMessage: err.message,
//               successMessage: "",
//               data: err.response.data,
//             };
//             console.log("responseresponseresponse+++++++++++++++++", err);
//             console.log(
//               "resultresultresultresultresult+++++++++++++++++",
//               result
//             );
//           });
//       })
//       .catch((err) => {
//         console.log("Error inside First Axios post call........");
//         let response = {
//           success: "0",
//           errorMessage: err.message,
//           successMessage: "",
//           data: err.response.data,
//         };
//         // console.log("responseresponseresponse+++++++++++++++++", err);
//         console.log(
//           "This is the error result of First Axios Post call",
//           response
//         );
//       });
//   };
// };
//   getBase64(file, cb) {
//     let reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = function () {
//       cb(reader.result);
//     };
//     reader.onerror = function (error) {
//       console.log("Error: ", error);
//     };
//   }
//   multipartUpload = (image) => {
//     let s3BucketMainFolder1 = s3BucketMainFolder + "/" + S3BucketImagesFolder;
//     let fileName = this.uuidv4() + image.name;
//     let fullFilePath = s3BucketMainFolder1 + "/" + fileName;
//     s3.createMultipartUpload(function (mpErr, multipart) {
//       if (mpErr) {
//         console.log("Error!", mpErr);
//         return;
//       }
//       console.log("Got upload ID", multipart.UploadId);

//       // Grab each partSize chunk and upload it as a part
//       for (
//         var rangeStart = 0;
//         rangeStart < image.size;
//         rangeStart += partSize
//       ) {
//         partNum++;
//         var end = Math.min(rangeStart + partSize, image.size),
//           partParams = {
//             Body: image.slice(rangeStart, end),
//             Bucket: s3Bucket,
//             Key: fullFilePath,
//             PartNumber: String(partNum),
//             UploadId: multipart.UploadId,
//           };

//         // Send a single part
//         console.log(
//           "Uploading part: #",
//           partParams.PartNumber,
//           ", Range start:",
//           rangeStart
//         );
//         uploadPart(s3, multipart, partParams);
//       }
//     });
//   };
//   multipartUpload = async (image) => {
//     let bucket = "tagtree.s3.ap-south-1.amazonaws.com";
//     let s3BucketMainFolder1 = s3BucketMainFolder + "/" + S3BucketImagesFolder;
//     let fileName = this.uuidv4() + image.name;
//     let fullFilePath = s3BucketMainFolder1 + "/" + fileName;
//     const target = { Bucket: bucket, Key: fullFilePath, Body: image };
//     const creds = {
//       accessKeyId: s3bucketAccessKey,
//       secretAccessKey: s3bucketSecretKey,
//     };
//     console.log("hello.....");
//     try {
//       const parallelUploads3 = new Upload({
//         client: new S3({
//           apiVersion: "2006-03-01",
//           region: "ap-south-1",
//           credentials: creds,
//         }),
//         leavePartsOnError: false,
//         params: target,
//       });
//       console.log("depp star.....");

//       parallelUploads3.on("httpUploadProgress", (progress) => {
//         console.log("processsss", progress);
//       });
//       await parallelUploads3.done();
//     } catch (e) {
//       console.log(e);
//     }
//   };

// postPhotoToGBP() {
//   $(".loder_div").attr("hidden", false);
//   var totCount = this.state.imageUrl.length;
//   this.state.imageUrl.forEach(async (data, i) => {
//     var req = {
//       functionName: "PostMediaGBP",
//       account: window.sessionStorage.getItem("account_id"),
//       locationId: this.state.selectLocation.name.split("/")[1],
//       category: this.state.setCategory,
//       mediaFormat: $("#formatTypeDropdown").val(),
//       source: data,
//       siteId:
//         window.sessionStorage.getItem("switchingOrgId") === ""
//           ? window.sessionStorage.getItem("organizationId")
//           : window.sessionStorage.getItem("switchingOrgId"),
//     };
//     await backendApi(req).then((data) => {
//       totCount = totCount - 1;
//       if (totCount === 0) {
//         setTimeout(() => {
//           alert("Images Posted Successfully");
//           window.location.reload();
//         }, 3000);
//       }
//     });
//   });
//   $(".loder_div").attr("hidden", false);
// }
// selectLocation(object) {
//   var location = [];
//   location = this.state.locationsList.filter(
//     (obj) => obj.name === object.target.value
//   );
//   this.setState(
//     {
//       selectLocation: location[0],
//     },
//     () => {
//       console.log("gasdasidygasd", this.state.selectLocation);
//       this.getLocationMedia(this.state.selectLocation);
//     }
//   );
// }
//   imageListing = () => {
//     debugger;
//     return (
//       <div id="uploadedImage" className="imageUploaded">
//         {this.state.imageUrl.map((data, i) => (
//           <>
//             {console.log("datadata", data)}
//             <img src={data} alt="" className="imageUploaded_img" />;
//             <span className="close_popup_imageUpload">
//               <Icon.X
//                 onClick={() => {
//                   let items = [...this.state.imageUrl];
//                   items.splice(i, 1);
//                   this.setState({ imageUrl: items });

//                   // setImageName("");
//                   // setPhotos(true);
//                 }}
//               />
//             </span>
//             ;
//           </>
//         ))}
//       </div>
//     );
//   };
