import React, { useState, useEffect } from 'react';
import axios from "axios";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import {getAdset} from '../../apiService'
import { backendApi, apiCallingPost } from '../../apiService';

export default function Adsets(props){
  const[adsets,setAdsets] = useState([]);

  const columns = [
    {field: 'id', header: 'ID'},
    {field: 'bid_amount', header: 'Bid'},
    {field: 'campaign_id', header: 'Campaign'},
    {field: 'daily_budget', header: 'Budget'},
    {field: 'name', header: 'Name'},
    {field: 'optimization_goal', header: 'Goal'},
    {field: 'status', header: 'Status'}
];

    useEffect(() => {

      const credentialReq = {
        "functionName": "get_adsets",
        "site_id": "58",
      };
      backendApi(credentialReq).then((data) => {   
console.log("..............adsets",JSON.parse(data.body).data);
         setAdsets(JSON.parse(data.body).data)
        }).catch(()=>{})
        }, [props.data])

      const dynamicColumns = columns.map((col,i) => {
          return <Column key={col.field} field={col.field} header={col.header} />;
      });

    return(
      <div>
      <div className="card">
          {/* <DataTable value={adsets} responsiveLayout="scroll">
              {dynamicColumns}
          </DataTable> */}
          <div className="col-md-12 tableResponsive">
            <div className="tableOuterWidth">
              <table className="table">
                <thead>
                  {console.log("*********************",adsets)}
                  <tr>
                    <th className="col-2">ID</th> 
                    <th className="col-2">Bid</th>
                    <th className="col-1">Campaign</th>
                    <th className="col-1">Budget</th>
                    <th className="col-2">Name</th>
                    <th className="col-1">Goal</th>
                    <th className="col-1">Status</th> 
                  </tr>
                </thead>
                {adsets.length>0?
                <div className="table_Outerscroll">
                  {adsets.length>0?
                  <>
                {adsets.map((object, index) => (                        
                          <tbody className="table_Outertbody">
                            <tr>
                              <td className="col-2">{object.id}</td>
                              <td className="col-2">
                              {object.bid_amount}
                              </td>
                              <td className="col-1">
                              {object.campaign_id}
                              </td>
                              <td className="col-1">
                               {object.daily_budget}
                              </td>
                              <td className="col-2">
                              {object.name}
                              </td>
                              <td className="col-1">
                              {object.optimization_goal}
                              </td>
                              <td className="col-1">
                              {object.status}
                              </td>
                            </tr>
                          </tbody>))}
                          </>:"NO DATA FOUND"}
                       
                </div>:""}
              </table>
 
            </div>
            {/* <div className="pagination_main  tag_postListNext">
              <Pagination
                hideDisabled
                activePage={currentPage}
                itemsCountPerPage="25"
                totalItemsCount={totalCount}
                pageRangeDisplayed="10"
                onChange={(e) => handleActivePage(e)}
              />
            </div> */}
          </div>
      </div>
  </div>
  )
}