import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./css/bootstrap.css";
import "./css/style.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./home/home.jsx";
import NewUser from "./admin/new-user.jsx";
import UserList from "./admin/user-list.jsx";
import Login from "./admin/loginComponent/login";
import Dashboard from "./home/dashboard";
import ModuleList from "./admin/module_list";
import Audit from "./audit/audit";
import Review from "./review/review";
import Settings from "./admin/settings/settings";
import LiveDetails from "./home/live_details";
import AnalyticsGraph from "./analytics/analytics";
import AnalyticsGraphCopy from "./analytics/analyticsCopy";
import Fraud from "./review/fraud";
import SocialMedia from "./admin/social_media";
import NewChat from "./admin/new_chat";
import GooglePost from "./google-post/index";
import SocialStreams from "./admin/social_streams";
import SocialMeList from "./socialMe/SocialMeList";
import ImageEditer from "./ImageEditer/imageEditer";
import ImageEditerList from "./ImageEditer/imageEditerList";
import RoleList from "./admin/role_list";
import CompetiterAnalysis from "./competiterAnalysis/competiterAnalysis";
import Info from "./GBP/info_insight";
import PhotosGbp from "./GBP/photosGbp";
import PhotosUploadHistory from "./GBP/photosUploadHistory";
import SelectLocations from "./GBP/selectLocations";
// import InsightsGBP from "./GBP/insightsGbp";
import AuditDetails from "./audit/audit-details";
import FraudDetails from "./review/fraudDetails";
import CompetiterDetails from "./competiterAnalysis/competiterDetails";
import DataDashboard from "./admin/settings/dataDashboard";
import LiveMonitoring from "./admin/live-monitoring";
import VMProcessStatus from "./admin/vm_process_status";
import ChangePassword from "./admin/changePassword";
import HomeIndex from "./home/index";
import Visibility from "./visibility/vissibillity_AccuracyIndexPageGraph";
import VisibilityReport from "./visibility/visibility_accuracy_reportPageIndex";
import VisibilityAccuracyTable from "./visibility/visibility_accuracy_table";
import VisibilityDetails from "./visibility/visibilityDetails";
import ReviewIndex from "./review/reviewIndex";
import PushGBPData from "./pushGBP/pushGBPData";
import PostUnderLocation from "./google-post/postUnderLocation";
import PostListing from "./google-post/postListing";
import MergeIndex from "./audit/mergeIndex";
import MasterDataList from "./audit/masterdataList";
import AuditFileList from "./audit/auditFileList";
import AuditDetailsMerge from "./audit/audit-details-merge";
import ProcessAddress from "./processAddress/process_address";
import ProcessAddressDetail from "./processAddress/process_address_detail";
import BulkPublish from "./home/bulkPublish";
import LocateIt from "./locate/locateIt";
import Claim from "./claim_business/claim";
import Mark from "./claim_business/mark";
import CompetiterAnalysisGraph from "./competiterAnalysis/competiterAnalysisGraph";
import CompetiterAnalysisNewPageDesign from "./competiterAnalysis/competiterAnalysisNewPageDesign";
import CompetiterAnalysisAudit from "./competiterAnalysis/competiterAnalysisAudit";
import CompetiterAnalysisAuditList from "./competiterAnalysis/competiterAnalysisAuditList";
import Merge from "./claim_business/merge";
import PushNodata from "./claim_business/pushNodataFound";
import PushNodataFromGbp from "./claim_business/pushNodataFromGbp";
import PushNodataFromGoogle from "./claim_business/pushNodataFromGoogle";
import NoDataFound from "./claim_business/nodataFound";
import NodataFoundBulkUpload from "./claim_business/nodataFoundBulkUpload";
import BulkUploadVerifyPage from "./claim_business/bulkUploadVerifyPage";
import BusinessDetails from "./GBP/business-details";
import GoogleMap from "./GBP/GoogleMap";
import CreateGooglePost from "./google-post/createGooglePost";
import GooglePreview from "./google-post/googlePreview";
import ChatForm from "./chat/chat-form";
import AssignLocation from "./chat/assign-location";
import HistoryLocation from "./chat/historyLocation";
import ChatBox from "./chat/chat-box";

import CmsBackend from "./home/cmsBackend";

import GooglePreviewForCopy from "./google-post/googlePreviewForCopy";
import QuestionAnswer from "./QandA/QuestionAnswer";
import QandACreateQuestion from "./QandA/QandACreateQuestion";
import AnalyticsUser from "./analytics/analytics-users";
import ApproveReview from "./review/approveReview";
import NewTemplate from "./review/newtemplate";
// import LocalPage from "./localPage/localPageIndex";
import LocalUploadFile from "./home/localUpload";
import BusinessManagerIndex from "./businessManager/index";
import GoogleBusinessLocation from "./businessManager/googleBusinessLocation";
import CreateZone from "./home/create_zone";
import MapUnmap from "./home/mapunmap";
import AddPhotos from "./GBP/addPhotos";
import Newdashboard from "./home/dashboard2";
import ClaimPrimary from "./claim_business/claimPrimary";
import NewListing from "./home/image-editor-settings.jsx";
import TermsCondition from "./admin/terms_condition";
import PrivacyPolicy from "./admin/privacy_policy";
import BusinessInsights from "./home/businessInsights";
import Manual from "./ImageEditer/manual";
import Automatic from "./ImageEditer/automatic";
import Shuffle from "./ImageEditer/shuffle";
import SuggestedEdit from "./home/suggested_edit";
import Support from "./home/support";
import LiveMonitoringProcessingGpb from "./admin/settings/LiveMonitoringProcessingGpb";
import GbpMultipleLocations from "./GBP/gbpMultipleLocation";
import ShowMultipleLocations from "./GBP/showMultipleLocation";
import CreateNewLocation from "./claim_business/createNewLocation";
import CreateNewLocationSecondery from "./claim_business/creatNewLocationSecondery";
import PushDataListingPage from "./claim_business/singleClaim/pushDataListing";
import CreateNewLocationForm from "./claim_business/singleClaim/singleClaimForm";
import UidPushDataList from "./claim_business/singleClaim/uidPushDataList";
import NewInsightsGBP from "./GBP/newInsightsGBP";
import InsightsGbp from "./GBP/insightsGbp";
import NewInsightsEdit from "./GBP/newInsightEdit";
import InsightTabs from "./GBP/insightTabs";
import NewInsightsEditButtonGBP from "./GBP/newEditInsightsButton";
import InstagramAds from "./Ads/pages/Home";
import Adsets from "./Ads/components/adsets";
import Ads from "./Ads/components/ads";
import Campaigns from "./Ads/components/campaigns";
import AdImages from "./Ads/components/ad_images";
// import Feed from "./Ads/pages/Feed";
// import DisplayImages from "./Ads/components/displayImages";
import InstagramAdsReport from "./Ads/instagramAdsReport";
import FacebookAdsReport from "./Ads/facebookAdsReport.jsx";
import LocalIndex from "./localPage/index";
import Localpages from "./localPage/localpages";

import LandingPage from "./Project_Folder/landingPageCommon/landing_page_index";
import LocalPage from "./Project_Folder/landingPageCommon/local_Page_index";
import TwitterRecentSearch from "./twitterReports/twitterRecentSearch";
import TwitterUsersLookup from "./twitterReports/twitterUsersLookup";
import TwitterRulesList from "./twitterReports/twitterRulesList";
import PushDataHistory from "./pushGBP/pushDataHistory";
//import VideoChat from "./chatDemo/chatDemo";
import FraudDashboard from "./fraudulentDetection/Fraud-dashboard";
import FraudDetectedList from "./fraudulentDetection/Fraud-detected-list";
import FraudDashboardList from "./fraudulentDetection/fraud_dashboard_listing";
import ReviewAnalyticsTable from "./review/reviewAnalytics";
import CategoriesSettings from "./admin/settings/categoriesSettings";
import PostHistory from "./google-post/googlePostHistory";
import GoogleMapIndia from "./admin/googleMapForIndia";
import VisibilityAccuracyDetails from "./visibility/visibilityAccuracyViewDetailsPage";
import ExportExcel from "./localPage/exportExcel";
import LiveUpdate from "./localPage/liveUpdate";
import GeneralURl from "./localPage/generalUrl";
import CompetiterListing from "./competiterAnalysis/competiterListing";
import NewGBPInsights from "./GBP/newGBPInsights/newGBPInsights.jsx";
import InsightsScheduler from "./GBP/insightsScheduler.jsx";
import Dashboardnew from "./dashboard-new/dashboard";

import OrganisationDivision from "./custom-dashboard/organisation-division.jsx";
import UserCreation from "./custom-dashboard/user-creation";
import BudgetAllocation from "./custom-dashboard/budget-allocation";
import FundAllocationHistory from "./custom-dashboard/fund-allocation-history";
import FundAllocation from "./custom-dashboard/fund-allocation";
import UserManagement from "./custom-dashboard/user-management";
import Advgenerator from "./adv_generator/adv_generator";
import AddUser from "./adv_generator/add-user.jsx";
import ApprovedContent from "./adv_generator/approved-content.jsx";
import Addimages from "./adv_generator/add-images.jsx";
import Approval from "./adv_generator/approval.jsx";
import ImgcontentMerge from "./adv_generator/image-content-merge.jsx";
import Planning from "./planning/QuestionAnswer.jsx";
import RequirementGathering from "./planning/requirementGathering";
import RequirementGatheringView from "./planning/requirementGatheringView";
import Campaign from "./planning/campaign";
import CreateCampaign from "./planning/createCampaign";
import Invoice from "./planning/campaign-invoice";
import ViewCampaignList from "./adv_generator/viewCampaignList.jsx";
import PlanningMain from "./planning/planningMain.jsx";
import Requirement from "./planning/requirement.jsx";
import Qapdf from "./planning/question-answer-pdf.jsx";
import DashboardFour from "./dashboard-new/dashboard04";
import DashboardFive from "./dashboard-new/dashboard05";
import GoogleAdsReport from "./Ads/googleAdsReport";
import InvoiceNew from "./invoices/invoice.jsx";
import TableInvoice from "./invoices/tableInvoice.jsx";
import DownloadPdfButton from "./invoices/invoicedownloadPdf.jsx";
import ReportInvoice from "./invoices/reportInvoice.jsx";
import GenerateInvoice from "./invoices/generateInvoice.jsx";
import DealerShipInvoice from "./invoices/dealerShipInvoice.jsx";
import GenerateInvoiceTable from "./invoices/generateInvoiceTable.jsx";
import CreateInvoice from "./custom-dashboard/createInvoice.jsx";
import DashboardLatest from "./dashboard-new/dashboard/dashboard-latest.jsx";
import CompleteReportPage from "./home/completeReportPage.jsx";
import Popup from "./home/popup.jsx";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/qapdf" element={<Qapdf />} />
      <Route path="/image-content-merge" element={<ImgcontentMerge />} />
      <Route path="/approval" element={<Approval />} />
      <Route path="/add-user" element={<AddUser />} />
      <Route path="/approved-content" element={<ApprovedContent />} />
      <Route path="/add-images" element={<Addimages />} />
      <Route path="/twitterRecentSearch" element={<TwitterRecentSearch />} />
      <Route path="/exportExcel" element={<ExportExcel />} />
      <Route path="/liveUpdate" element={<LiveUpdate />} />
      <Route path="/generateUrl" element={<GeneralURl />} />
      <Route path="/twitterUsersLookup" element={<TwitterUsersLookup />} />
      <Route path="/twitterRulesList" element={<TwitterRulesList />} />
      <Route path="/home" element={<Home />} />
      <Route path="/newuser" element={<NewUser />} />
      <Route path="/newuser/:id" element={<NewUser />} />
      <Route path="/userlist" element={<UserList />} />
      <Route path="/" element={<Login />} />
      <Route path="/moduleList" element={<ModuleList />} />
      <Route path="/roleList" element={<RoleList />} />
      {/* <Route path="/dashboard" element={<Dashboard />} /> */}
      <Route path="/audit" element={<Audit />} />
      <Route path="/review/:locationId/:title" element={<Review />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/livedetails/:tableId" element={<LiveDetails />} />
      <Route path="/analyticsOld" element={<AnalyticsGraph />} />
      <Route path="/analytics" element={<AnalyticsGraphCopy />} />
      <Route path="/fraud" element={<Fraud />} />
      <Route path="/social" element={<SocialMedia />} />
      <Route path="/new-chat" element={<NewChat />} />
      <Route path="/googlepost/index" element={<GooglePost />} />
      <Route path="/googlepost/locationList" element={<PostUnderLocation />} />
      <Route path="/googlepost/postListing" element={<PostListing />} />
      <Route path="/googlepost/postHistory" element={<PostHistory />} />
      <Route path="/social_streams" element={<SocialStreams />} />
      <Route path="/social-me" element={<SocialMeList />} />
      <Route path="/imageEditer/imageEditer" element={<ImageEditer />} />
      <Route
        path="/imageEditer/imageEditerList"
        element={<ImageEditerList />}
      />
      <Route path="/multipleLocations" element={<GbpMultipleLocations />} />
      <Route path="/competiterAnalysis" element={<CompetiterAnalysis />} />
      <Route path="/info" element={<Info />} />
      <Route path="/createNewPost" element={<CreateGooglePost />} />
      <Route path="/GBPPhotos" element={<PhotosGbp />} />
      <Route path="/PhotosUploadHistory" element={<PhotosUploadHistory />} />
      <Route path="/SelectGBPLocations" element={<SelectLocations />} />
      <Route path="/GBPInsights" element={<NewInsightsGBP />} />
      <Route path="/insightsScheduler" element={<InsightsScheduler />} />
      <Route path="/auditDetails/:auditId" element={<AuditDetails />} />
      <Route path="/fraudDetails/:fraudId" element={<FraudDetails />} />
      <Route
        path="/showMultipleLocations"
        element={<ShowMultipleLocations />}
      />
      <Route
        path="/competiterDetails/:competiterId"
        element={<CompetiterDetails />}
      />
      <Route path="/dataDashboard" element={<DataDashboard />} />
      <Route path="/liveMonitoring" element={<LiveMonitoring />} />
      <Route path="/serverProcessStatus" element={<VMProcessStatus />} />
      <Route path="/changePassword" element={<ChangePassword />} />
      <Route path="/home/index" element={<HomeIndex />} />
      <Route path="/visibility" element={<Visibility />} />
      <Route path="/visibilityReport" element={<VisibilityReport />} />
      <Route
        path="/visibilityDetails/:mode/:filter/:id"
        element={<VisibilityAccuracyDetails />}
      />
      <Route
        path="/accuracyDetails/:mode/:filter/:id"
        element={<VisibilityAccuracyDetails />}
      />
      <Route
        path="/VisibilityAccuracyTable"
        element={<VisibilityAccuracyTable />}
      />
      <Route path="/visibilityDetails/:id" element={<VisibilityDetails />} />
      <Route path="/review/index/" element={<ReviewIndex />} />
      <Route path="/pushGBPData" element={<PushGBPData />} />
      <Route path="/mergeIndex" element={<MergeIndex />} />
      <Route path="/masterDataList" element={<MasterDataList />} />
      <Route path="/AuditFileList" element={<AuditFileList />} />
      <Route
        path="/auditDetailsMerge/:auditId"
        element={<AuditDetailsMerge />}
      />
      <Route path="/processAddress" element={<ProcessAddress />} />
      <Route
        path="/processAddressDetails/:id"
        element={<ProcessAddressDetail />}
      />
      <Route path="/questionAnswer" element={<QuestionAnswer />} />
      <Route path="/bulkpublish" element={<BulkPublish />} />
      <Route path="/locateIt" element={<LocateIt />} />
      <Route path="/claim" element={<Claim />} />
      <Route path="/claimBusiness" element={<Mark />} />
      <Route
        path="/claim/claimBusiness/claimPrimary/:id"
        element={<ClaimPrimary />}
      />
      <Route
        path="/competiterAnalysis/result"
        element={<CompetiterAnalysisGraph />}
      />
      <Route
        path="/competiterAnalysis/competitorListing"
        element={<CompetiterListing />}
      />
      <Route
        path="/competiterAnalysis/CompetiterAnalysis"
        element={<CompetiterAnalysisNewPageDesign />}
      />
      <Route
        path="/competiterAnalysis/audit"
        element={<CompetiterAnalysisAudit />}
      />
      <Route
        path="/competiterAnalysis/details/:id"
        element={<CompetiterAnalysisAuditList />}
      />
      <Route path="/merge" element={<Merge />} />
      <Route path="/business-details" element={<BusinessDetails />} />
      <Route exact path="/G/o/o/g/l/e/M/a/p" component={GoogleMap} />
      <Route exact path="/googlepreview" element={<GooglePreview />} />
      <Route
        exact
        path="/googlepost/copyPost"
        element={<GooglePreviewForCopy />}
      />
      <Route path="/chatForm" element={<ChatForm />} />
      <Route path="/assignLocation" element={<AssignLocation />} />
      <Route path="/historyLocation/:id" element={<HistoryLocation />} />
      <Route path="/chat" element={<ChatBox />} />
      <Route path="/cmsBackend" element={<CmsBackend />} />
      <Route
        path="/questionAnswer/createQuestion"
        element={<QandACreateQuestion />}
      />
      <Route path="/analytics-user" element={<AnalyticsUser />} />
      <Route path="/review/approve" element={<ApproveReview />} />
      <Route path="/review/analytics" element={<ReviewAnalyticsTable />} />
      <Route path="/review/newtemplate" element={<NewTemplate />} />
      <Route path="/uploadfile" element={<LocalUploadFile />} />
      <Route path="/businessAccount/index" element={<BusinessManagerIndex />} />
      <Route
        path="/googleBusinessLocation/:id"
        element={<GoogleBusinessLocation />}
      />
      <Route path="/createzone" element={<CreateZone />} />
      <Route path="/businessInsights" element={<BusinessInsights />} />
      <Route path="/mapunmap" element={<MapUnmap />} />
      <Route path="/addPhotos" element={<AddPhotos />} />
      <Route path="/dashboard2" element={<Newdashboard />} />
      <Route path="/dashboard" element={<Newdashboard />} />
      <Route path="/image-editor-settings" element={<NewListing />} />
      <Route path="/termsAndConditions" element={<TermsCondition />} />
      <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
      <Route path="/manual" element={<Manual />} />
      <Route path="/automatic" element={<Automatic />} />
      <Route path="/shuffle" element={<Shuffle />} />
      <Route path="/suggestededit" element={<SuggestedEdit />} />
      <Route path="/support" element={<Support />} />
      <Route path="/claim_business/PushNodata" element={<PushNodata />} />
      <Route
        path="/claim_business/PushNodataFromGBP"
        element={<PushNodataFromGbp />}
      />
      <Route
        path="/claim_business/PushNodataFromGoogle"
        element={<PushNodataFromGoogle />}
      />
      <Route path="/claim_business/NoDataFound" element={<NoDataFound />} />
      <Route
        path="/claim_business/pushDataListing"
        element={<PushDataListingPage />}
      />
      <Route
        path="/claim_business/createNewLocationForm"
        element={<CreateNewLocationForm />}
      />
      <Route
        path="/claim_business/listOfPushSaveLocations/:uid?"
        element={<UidPushDataList />}
      />
      <Route
        path="/claim_business/nodataFoundBulkUpload/:uid?"
        element={<NodataFoundBulkUpload />}
      />
      <Route
        path="/claim_business/bulkUploadVerifyPage/"
        element={<BulkUploadVerifyPage />}
      />
      <Route
        path="/liveMonitoringProcessing"
        element={<LiveMonitoringProcessingGpb />}
      />
      <Route path="/createNewLocation" element={<CreateNewLocation />} />
      <Route path="/pushDataHistory" element={<PushDataHistory />} />
      <Route path="/insightsGBP" element={<InsightsGbp />} />
      <Route path="/insightsEdit" element={<NewInsightsEditButtonGBP />} />
      <Route
        path="/createNewLocationSecondery"
        element={<CreateNewLocationSecondery />}
      />
      {/* <Route
        path="/localPageIndex"
        element={<LocalPage />}
      /> */}
      <Route path="/landing_page" element={<LandingPage />} />
      <Route path="/instagramAds" element={<InstagramAds />} />
      {/* <Route index element={ <Campaigns></Campaigns>}/> */}
      <Route path="/instagramAds" element={<Campaigns></Campaigns>} />
      <Route path="/instagramAds/adsets" element={<Adsets></Adsets>} />
      <Route path="/instagramAds/ads" element={<Ads></Ads>} />
      <Route path="/instagramAds/ad_images" element={<AdImages></AdImages>} />
      {/* <Route path="/instagramAds/pickorupload" element={<Feed></Feed>} /> */}
      {/* <Route path="/instagramAds/images" element={<DisplayImages />} /> */}
      <Route path="/Ads/instagramAdsReport" element={<InstagramAdsReport />} />
      <Route path="/Ads/facebookAdsReport" element={<FacebookAdsReport />} />
      <Route path="/:url/:displayName" element={<LocalPage />} />
      {/* //////////**If url is not given case */}
      <Route path="/:url" element={<LocalPage />} />
      <Route path="/local/index" element={<LocalIndex />} />
      <Route path="/local/Localpages" element={<Localpages />} />
      {/* <Route path="/chatDemo" element={<VideoChat />} /> */}
      <Route path="/fraud-dashboard" element={<FraudDashboard />} />
      <Route path="/fraud-detected-list" element={<FraudDetectedList />} />
      <Route path="/fraud-dashboard-list" element={<FraudDashboardList />} />
      <Route path="/categoriesSettings" element={<CategoriesSettings />} />
      <Route path="/trafficInsights" element={<NewGBPInsights />} />
      <Route path="/googleMapIndia/:id" element={<GoogleMapIndia />} />
      <Route path="/analytic-dashboard-old" element={<Dashboardnew />} />

      <Route path="/organisation-division" element={<OrganisationDivision />} />
      <Route path="/user-creation" element={<UserCreation />} />
      <Route path="/budget-allocation" element={<BudgetAllocation />} />
      <Route
        path="/fund-allocation-history"
        element={<FundAllocationHistory />}
      />
      <Route path="/fund-allocation" element={<FundAllocation />} />
      <Route path="/user-management" element={<UserManagement />} />
      <Route path="/adv-generator" element={<Advgenerator />} />
      <Route path="/planning" element={<PlanningMain />} />
      <Route path="/requirement-gathering" element={<RequirementGathering />} />
      <Route path="/requirement-view" element={<RequirementGatheringView />} />
      <Route path="/campaign" element={<Campaign />} />
      <Route path="/create-requirement" element={<CreateCampaign />} />
      <Route path="/invoice" element={<Invoice />} />
      <Route path="/viewCampaignList" element={<ViewCampaignList />} />
      <Route path="/requirement" element={<Requirement />} />
      <Route path="/analytic-dashboard04" element={<DashboardFour />} />
      <Route path="/analytic-dashboard05" element={<DashboardFive />} />
      <Route path="/googleAdsReport" element={<GoogleAdsReport />} />
      <Route path="/invoices/invoice" element={<InvoiceNew />} />
      <Route path="/invoices/tableInvoice" element={<TableInvoice />} />
      <Route path="/invoices/invoicedownload" element={<DownloadPdfButton />} />
      <Route path="/invoices/reportInvoice" element={<ReportInvoice />} />
      <Route path="/invoices/generateInvoice" element={<GenerateInvoice />} />
      <Route
        path="/invoices/dealerShipInvoice"
        element={<DealerShipInvoice />}
      />
      <Route
        path="/invoices/generateInvoiceTable"
        element={<GenerateInvoiceTable />}
      />
      <Route path="/createInvoice" element={<CreateInvoice />} />
      <Route path="/analytic-dashboard" element={<DashboardLatest />} />
      <Route
        path="/productCatCompleteReport"
        element={<CompleteReportPage />}
      />
      <Route path="/popup" element={<Popup />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
