import React, { useEffect, useState } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import QandA from "../images/left_icons/qa.png";
import { Link, useNavigate } from "react-router-dom";
import * as Icon from "react-feather";
import Modal from "react-modal";
import Pagination from "react-js-pagination";
import { backendApi } from "../apiService";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Switch from "react-switch";
import html2pdf from "html2pdf.js";
import moment from "moment";

function Requirement() {
  const navigate = useNavigate();
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [campaignData, setCampaignData] = useState([]);
  const [campaignData1, setCampaignData1] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [siteQuestion, setSiteQuestion] = useState([]);
  const [publicQuestion, setPublicQuestion] = useState([]);
  const [newQuestion, setNewQuestion] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const[waitingCount,setWaitingCount]=useState('');
  const[approvedCount,setApprovedCount]=useState('');
  const[rejectedCount,setRejectedCount]=useState('');

  const [requestedStatus, setRequestedStatus] = useState({
    approved: [],
    rejected: [],
  });

  const openEditModal = () => {
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    const itemsPerPage = 10;
    const indexOfLastPost = pageNumber * itemsPerPage;
    const indexOfFirstPost = indexOfLastPost - itemsPerPage;
    const slicedArrays = campaignData.slice(indexOfFirstPost, indexOfLastPost);
    setCampaignData1(slicedArrays);
  };
  useEffect(() => {
    getRequestedData();
    campaignInit();
    campaignHistory();
  }, []);

  function preparePdfContent(pdf) {
    console.log("cData", pdf);
    console.log("campaignData1", campaignData1);
    const pdfContent = `
        <div id="pdf-content" class="container px-5">
            <div class="row pt-3">
                <div class="col-3 col-md-3 col-sm-3 mb-2">
                    <p class="invoice_para">
                        <span class="invoice_para_bold">Campaign Name : </span>
                        ${campaignData1[0].campaign_name}
                    </p>
                </div>
                <div class="col-3 col-md-3 col-sm-3 mb-2">
                    <p class="invoice_para">
                        <span class="invoice_para_bold">Description : </span>
                        ${campaignData1[0].campaign_summary}
                    </p>
                </div>
            </div>
            <div class="row mt-4">
                <ul id="accordion" class="col-sm-12 col-md-12 col-12 qa-accordion">
                    ${pdf
                      .map(
                        (item, index) =>
                          `<li key=${item.id}>
                            <div data-toggle="collapse">
                                <span class="mr-2 qntext">Qn${index + 1}.</span>
                                ${item.question_text}
                            </div>
                            <div class="collapse show mt-2">
                                <div class="card-body">
                                <ul>
                                ${
                                  item.answer.length > 0
                                    ? item.answer
                                        .map(
                                          (ans, index) =>
                                            `<React.Fragment key=${index}>
                                            <li>
                                                <span class="mr-2 anstext">Ans :</span>
                                                ${
                                                  ans.answer_text
                                                    ? ans.answer_text
                                                    : "Not answered"
                                                }
                                            </li>
                                            <div class="my-2">
                                                <div
                                                    class="requirement_date ml-auto"
                                                    style="background: #c3e9cd; padding: 1px 10px; border-radius: 5px; width: fit-content;"
                                                >
                                                    <b>Approved By</b>
                                                    <span class="text-dark">${
                                                      ans.full_name
                                                    } ${formatDate(
                                              ans.approved_on
                                            )}</span>
                                                </div>
                                            </div>
                                        </React.Fragment>`
                                        )
                                        .join("")
                                    : '<li><span class="mr-2 anstext">Ans :</span>Not answered yet</li>'
                                }
                            </ul>
                                </div>
                            </div>
                        </li>`
                      )
                      .join("")}
                </ul>
            </div>
        </div>
    `;

    return pdfContent;
  }

  const downloadPdf = (campaignId) => {
    const postData = {
      functionName: "requirement_gathering_download",
      campaign_id: campaignId,
    };

    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          console.log("pdfdata", data);
          const sortedPdfData = data.dataList.sort(
            (a, b) => a.campaign_questions_master_id - b.campaign_questions_master_id
          );
          setPdfData(data.dataList);
          generatePdf(sortedPdfData);
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        // Handle errors that occur during API call
        console.error("Error occurred during API call:", error);
      });
  };

  const generatePdf = async (data) => {
    const pdfContent = preparePdfContent(data);
    html2pdf()
      .from(pdfContent)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        var pdfWindow = window.open("");
        pdfWindow.document.write(
          '<html><head><title>Print Preview</title></head><body style="margin: 0;">'
        );
        pdfWindow.document.write(
          '<iframe width="100%" height="100%" src="' +
            pdf.output("datauristring") +
            '"></iframe>'
        );
        pdfWindow.document.write("</body></html>");
      });
  };

  const getRequestedData = () => {
    const postData = {
      functionName: "content_request_Listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      category_type: "Question",
      req_status: "",
    };

    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          const newWaiting = [];
          const newApproved = [];
          const newRejected = [];

          // Loop through data and populate each status array
          data?.dataList?.forEach((item) => {
            if (item.req_status === "Waiting") {
              newWaiting.push(item);
            } else if (item.req_status === "Approved") {
              newApproved.push(item);
            } else if (item.req_status === "Rejected") {
              newRejected.push(item);
            }
          });
          setWaitingCount(newWaiting.length);
          setApprovedCount(newApproved.length);
          setRejectedCount(newRejected.length);
          // Update state with new arrays
          setRequestedStatus({
            waiting: newWaiting,
            approved: newApproved,
            rejected: newRejected,
          });
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        // Handle errors that occur during API call
        console.error("Error occurred during API call:", error);
      });
  };

  const campaignInit = () => {
    const postData = {
      functionName: "requirementGatheringList",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          const sortedDataList = data.dataList.sort(
            (a, b) => b.campaign_id - a.campaign_id
          );
          console.log("Sorted Data:", sortedDataList);
          setCampaignData(sortedDataList);
          setCampaignData1(sortedDataList.slice(0, 20));
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };
  const campaignHistory = () => {
    const postData = {
      functionName: "campaign_question_listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          console.log("SAdfafsf", data);
          setPublicQuestion(data.publicQuestion);
          setSiteQuestion(data.siteQuestion);
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };
  const campaignEdit = (id) => {
    navigate(`/requirement-gathering`, {
      state: {
        props: id,
      },
    });
  };
  function formatDate(timestamp) {
    return moment(timestamp).format("DD-MM-YYYY h:mm A");
  }

  const closePopup = () => {
    setShowModal(false);
    document.body.className = "";
  };

  const questionSave = () => {
    if (newQuestion === "") {
      alert("Please enter a question");
      return false;
    }
    var postData;
    let question=newQuestion.replace(/['"]/g, "\\$&");
    if (isPublic === true) {
      postData = {
        functionName: "campaign_add_question",
        site_id: "",
        question_text: question,
        is_public: "1",
      };
    } else {
      postData = {
        functionName: "campaign_add_question",
        site_id:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        created_by:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
        question_text: question,
        is_public: "0",
      };
    }
    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          alert("Question saved succesfully");
          closePopup();
          campaignInit();
          campaignHistory();
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };

  const questionStatus = () => {
    setIsPublic(!isPublic);
  };

  const statusChanging = (data, activeCase) => {
    let postData = {
      functionName: "campaign_question_activeDeactive",
      is_active: activeCase,
      id: data.id,
    };
    backendApi(postData)
      .then((response) => {
        if (response?.success === "1") {
          campaignHistory();
        }
      })
      .catch((error) => {
        console.error("Error fetching version list:", error);
      });
  };

  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Requirement Gathering" headerImage={QandA} />
        <div className="col-md-12 tabs-main-wrapper">
          <Tabs>
            <TabList onClick={(e) => setEditingIndex(-1)}>
              <Tab>Requirement Gathering</Tab>
              <Tab>Requested
              <span className="waitingTxt">{waitingCount}</span>
              </Tab>
              <Tab>Approved
              <span className="waitingTxt">{approvedCount}</span>
              </Tab>
              <Tab>Rejected
              <span className="rejectedTxt">{rejectedCount}</span>
              </Tab>
            </TabList>

            <TabPanel>
              <div className="user_listdiv">
                <Link
                  className="btn-addNew"
                  to="/create-requirement"
                  onClick={openEditModal}
                >
                  Add Requirement
                  <span>
                    <Icon.PlusCircle className="ml-1" />
                  </span>
                </Link>
                {/* <Link
                  className="btn-addNew mr-1"
                  onClick={() => showModalClick()}
                >
                  Add Question
                  <span>
                    <Icon.PlusCircle className="ml-1" />
                  </span>
                </Link> */}
                {campaignData?.length > 0 ? (
                  <div className="col-md-12 tableResponsive mt-4">
                    <div className="tableOuterWidth table_width_md">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th className="col-1">#</th>
                            <th className="col-2">Campaign Name</th>
                            <th className="col-3">Campaign Description</th>
                            <th className="col-2">Status</th>
                            <th className="col-2">Actions</th>
                            <th className="col-2"></th>
                          </tr>
                        </thead>
                        <div className="table_Outerscroll">
                          {campaignData1?.map((data, i) => {
                            // Check if any question has status "Approved"
                            const hasApprovedQuestion = data.questionCount.some(
                              (question) => question.STATUS === "Approved"
                            );

                            return (
                              <tbody className="table_Outertbody" key={i}>
                                <tr>
                                  <td className="col-1">{i + 1}</td>
                                  <td className="col-2">
                                    {data.campaign_name}
                                  </td>
                                  <td className="col-3">
                                    {data.campaign_summary}
                                  </td>
                                  <td className="col-2">
                                    {data.questionCount?.map((question, j) => (
                                      <p
                                        className="request-div request-approve"
                                        key={j}
                                      >
                                        {question.STATUS}-{question.cnt}
                                      </p>
                                    ))}
                                  </td>
                                  <td className="col-2">
                                    <span
                                      title="View More"
                                      onClick={() =>
                                        campaignEdit(data.campaign_id)
                                      }
                                    >
                                      <Icon.Eye color="blue" />
                                    </span>
                                  </td>
                                  <td className="col-2">
                                    {hasApprovedQuestion && (
                                      <span title="Download as Pdf">
                                        <button
                                          className="btn btn-submit"
                                          onClick={() =>
                                            downloadPdf(data.campaign_id)
                                          }
                                        >
                                          Download Pdf
                                        </button>
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })}
                        </div>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-12 tableResponsive mt-4">
                    <div className="summery-heading mb-2">
                      No Campaigns Added
                    </div>
                  </div>
                )}
                {campaignData?.length > 10 && (
                  <div className="d-flex align-items-center">
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={campaignData.length}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                    />
                  </div>
                )}
              </div>
            </TabPanel>

            <TabPanel>
              <div className="user_listdiv">
                {requestedStatus?.waiting?.length > 0 ? (
                  <div className="col-md-12 tableResponsive mt-4">
                    <div className="tableOuterWidth">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th className="col-1">Id </th>
                            <th className="col-3">Content</th>
                            <th className="col-2">Remarks</th>
                            <th className="col-2">Requested</th>
                            <th className="col-2">Approved</th>
                            <th className="col-2 "></th>
                          </tr>
                        </thead>
                        {requestedStatus?.waiting?.map((data, i) => (
                          <div className="table_Outerscroll">
                            <tbody className="table_Outertbody pb-3">
                              <tr key={i}>
                                <td className="col-1">{i + 1}</td>
                                <td className="col-3">
                                  <p>Qn:{data.content_image}</p>
                                  <p>Ans:{data.content_text}</p>
                                </td>
                                <td className="col-2">
                                  <div className="popup-image">
                                    <span id={`remarksValue${i}`}>
                                      {data.remarks === null ||
                                      data.remarks === ""
                                        ? "NA"
                                        : data.remarks}
                                    </span>
                                  </div>
                                </td>
                                <td className="col-2">
                                  <p>
                                    {" "}
                                    {moment(data.requested_date).format(
                                      "DD MMM YYYY"
                                    )}
                                  </p>
                                  <p>{data.requested_by_name}</p>
                                </td>
                                <td className="col-2">
                                  {data.approved_by_name ? (
                                    <>
                                      <p>
                                        {moment(data.approved_date).format(
                                          "DD MMM YYYY"
                                        )}
                                      </p>
                                      <p>{data.approved_by_name}</p>
                                    </>
                                  ) : (
                                    <p>Not approved</p>
                                  )}
                                </td>
                                <td className="col-2"></td>
                              </tr>
                            </tbody>
                          </div>
                        ))}
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-12 tableResponsive mt-4">
                    <div className="summery-heading mb-2">No Campaigns</div>
                  </div>
                )}
              </div>
            </TabPanel>

            <TabPanel>
              <div className="user_listdiv">
                {requestedStatus?.approved?.length > 0 ? (
                  <div className="col-md-12 tableResponsive mt-4">
                    <div className="tableOuterWidth">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th className="col-1">Id </th>
                            <th className="col-3">Content</th>
                            <th className="col-2">Remarks</th>
                            <th className="col-2">Requested</th>
                            <th className="col-2">Approved</th>
                            <th className="col-2 "></th>
                          </tr>
                        </thead>
                        {requestedStatus?.approved?.map((data, i) => (
                          <div className="table_Outerscroll">
                            <tbody className="table_Outertbody pb-3">
                              <tr key={i}>
                                <td className="col-1">{i + 1}</td>
                                <td className="col-3">
                                  <p>Qn:{data.content_image}</p>
                                  <p>Ans:{data.content_text}</p>
                                </td>
                                <td className="col-2">
                                  <div className="popup-image">
                                    <span id={`remarksValue${i}`}>
                                      {data.remarks === null ||
                                      data.remarks === ""
                                        ? "NA"
                                        : data.remarks}
                                    </span>
                                  </div>
                                </td>
                                <td className="col-2">
                                  <p>
                                    {" "}
                                    {moment(data.requested_date).format(
                                      "DD MMM YYYY"
                                    )}
                                  </p>
                                  <p>{data.requested_by_name}</p>
                                </td>
                                <td className="col-2">
                                  {data.approved_by_name ? (
                                    <>
                                      <p>
                                        {moment(data.approved_date).format(
                                          "DD MMM YYYY"
                                        )}
                                      </p>
                                      <p>{data.approved_by_name}</p>
                                    </>
                                  ) : (
                                    <p>Not approved</p>
                                  )}
                                </td>
                                <td className="col-2"></td>
                              </tr>
                            </tbody>
                          </div>
                        ))}
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-12 tableResponsive mt-4">
                    <div className="summery-heading mb-2">No Campaigns</div>
                  </div>
                )}
              </div>
            </TabPanel>

            <TabPanel>
              <div className="user_listdiv">
                {requestedStatus?.rejected?.length > 0 ? (
                  <div className="col-md-12 tableResponsive mt-4">
                    <div className="tableOuterWidth">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th className="col-1">Id </th>
                            <th className="col-3">Content</th>
                            <th className="col-2">Remarks</th>
                            <th className="col-2">Requested</th>
                            <th className="col-2">Rejected</th>
                            <th className="col-2 "></th>
                          </tr>
                        </thead>
                        {requestedStatus?.rejected?.map((data, i) => (
                          <div className="table_Outerscroll">
                            <tbody className="table_Outertbody pb-3">
                              <tr key={i}>
                                <td className="col-1">{i + 1}</td>
                                <td className="col-3">
                                  <p>Qn:{data.content_image}</p>
                                  <p>Ans:{data.content_text}</p>
                                </td>
                                <td className="col-2">
                                  <div className="popup-image">
                                    <span id={`remarksValue${i}`}>
                                      {data.remarks === null ||
                                      data.remarks === ""
                                        ? "NA"
                                        : data.remarks}
                                    </span>
                                  </div>
                                </td>
                                <td className="col-2">
                                  <p>
                                    {" "}
                                    {moment(data.requested_date).format(
                                      "DD MMM YYYY"
                                    )}
                                  </p>
                                  <p>{data.requested_by_name}</p>
                                </td>
                                <td className="col-2">
                                  {data.approved_by_name ? (
                                    <>
                                      <p>
                                        {moment(data.approved_date).format(
                                          "DD MMM YYYY"
                                        )}
                                      </p>
                                      <p>{data.approved_by_name}</p>
                                    </>
                                  ) : (
                                    <p>Not approved</p>
                                  )}
                                </td>
                                <td className="col-2"></td>
                              </tr>
                            </tbody>
                          </div>
                        ))}
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-12 tableResponsive mt-4">
                    <div className="summery-heading mb-2">No Campaigns</div>
                  </div>
                )}
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <div className="loder_div" hidden />
      {showModal && (
        <div className="adv_modal">
          <div className="adv_modal_inner ">
            <div className="adv_modal_header d-flex align-itesm-center">
              <h3 class="image-heading">Add Questions</h3>
              <button
                type="button"
                className="close ml-auto"
                onClick={() => closePopup()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <Tabs>
              <TabList onClick={() => setEditingIndex(-1)}>
                <Tab>Add Question</Tab>
                <Tab>History</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col-md-8">
                    <div className="form-group mb-2 mb-md-0">
                      <label for="exampleInputEmail1">Add Question</label>
                      <span className="red ml-1">*</span>{" "}
                      <div className="d-md-flex">
                        <input
                          type="text"
                          name="festivalName"
                          id="festivalName"
                          onChange={(e) => setNewQuestion(e.target.value)}
                          className="form-control"
                          value={newQuestion}
                          placeholder="Add Question"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group mb-2 mb-md-0">
                      <label for="exampleInputEmail1">Public</label>
                      <div className="d-md-flex">
                        <Switch
                          className="switch_case"
                          onChange={(e) => questionStatus(e)}
                          value={isPublic}
                          checked={isPublic}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-right">
                  <button
                    type="submit"
                    class="btn btn-success mr-2"
                    onClick={questionSave}
                  >
                    Save
                  </button>
                  <button
                    type="submit"
                    class="btn btn-secondary"
                    onClick={() => closePopup()}
                  >
                    Cancel
                  </button>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="col-md-12 tableResponsive tagImageEditrListTble mt-4">
                  <div className="tableOuterWidth table_width_md_sm">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="col-1">Sl </th>
                          <th className="col-7">Question</th>
                          {/* <th className="col-2 ">Description</th> */}
                          <th className="col-2">Status</th>
                          <th className="col-2">Created Date</th>
                        </tr>
                      </thead>
                      <div className="table_Outerscroll tableheight">
                        {siteQuestion?.length > 0 && (
                          <div>
                            <div>Site Questions</div>
                            {siteQuestion?.map((data, i) => (
                              <tbody className="table_Outertbody">
                                <tr>
                                  <td className="col-1">{i + 1}</td>
                                  <td className="col-7">
                                    <span>{data.question_text}</span>
                                  </td>
                                  <td className="col-2">
                                    <Switch
                                      className="switch_case"
                                      checked={data.is_active === 1}
                                      onChange={(checked) => {
                                        statusChanging(
                                          data,
                                          data.is_active === 1 ? 0 : 1
                                        );
                                      }}
                                    ></Switch>
                                  </td>
                                  <td className="col-2">
                                    <div className="d-flex align-items-center">
                                      {formatDate(data.created_on)}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </div>
                        )}
                        <div>Public Questions</div>
                        {publicQuestion?.map((data, i) => (
                          <tbody className="table_Outertbody">
                            <tr>
                              <td className="col-1">{i + 1}</td>
                              <td className="col-7">
                                <span>{data.question_text}</span>
                              </td>
                              <td className="col-2">
                                <Switch
                                  className="switch_case"
                                  checked={data.is_active === 1}
                                  onChange={(checked) => {
                                    statusChanging(
                                      data,
                                      data.is_active === 1 ? 0 : 1
                                    );
                                  }}
                                ></Switch>
                              </td>
                              <td className="col-2">
                                <div className="d-flex align-items-center">
                                  {formatDate(data.created_on)}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </div>
                    </table>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      )}

      {/* {pdfData && <QuestionAnswerPdf data={pdfData} campaignData={campaignData1} />} */}
      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={closeEditModal}
        className="modal__view"
        contentLabel="Editing Modal"
      >
        <div className="d-flex justify-content-end">
          <button onClick={closeEditModal} className="btn btn-transparent p-0">
            <Icon.XCircle style={{ width: "20px", color: "red" }} />
          </button>
        </div>
        <div>
          <form autoComplete="off">
            <div className="mb-2">
              <label>Campaign Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="mb-0">
              <label>Campaign Description</label>
              <textarea
                rows="3"
                className="form-control"
                style={{ minHeight: "120px !important" }}
              />
            </div>
            <div className="text-right">
              <button
                type="submit"
                className="btn-search"
                onClick={closeEditModal}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default Requirement;
