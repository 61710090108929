import React, { useEffect, useState } from 'react'
function TopPerformnce() {
    return (
        <div className="db-cards-30 db-card-margin-right">
                            <div class="card db-height">
                                <div class="card-body">
                                    <div className='db-cards-top  all-region-head'>
                                        <h5 class="card-title card-title-small mb-0">Top Performing Models</h5>
                                        <span className='db-cad-top-units-blue'>Sept - Nov</span>
                                    </div>
                                    <h6 class="card-subtitle mb-4">All Locations</h6>
                                        <div className="d-flex mb-4">
                                            <div className='db-sub-models-width db-names'>
                                            Thar 4X4
                                            </div>
                                            <div className='db-sub-models-width text-right'>
                                            95
                                            </div>
                                        </div>
                                        <div className="d-flex mb-4">
                                            <div className='db-sub-models-width db-names'>
                                            Thar 4X4
                                            </div>
                                            <div className='db-sub-models-width text-right'>
                                            95
                                            </div>
                                        </div>
                                        <div className="d-flex mb-4">
                                            <div className='db-sub-models-width db-names'>
                                            Thar 4X4
                                            </div>
                                            <div className='db-sub-models-width text-right'>
                                            95
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
    )
}

export default TopPerformnce;