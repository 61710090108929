import React, { useEffect } from "react";
import html2pdf from "html2pdf.js";

const QuestionAnswerPdf = ({ data, campaignData }) => {
  console.log("pdfdata", data);
  console.log("campaignData", campaignData);

  useEffect(() => {
    const generatePdf = () => {
      const element = document.getElementById("pdf-content");

      if (!element) return;

      html2pdf().from(element).toPdf().get('pdf').then(function (pdf) {
        var pdfWindow = window.open("");
        pdfWindow.document.write('<html><head><title>Print Preview</title></head><body style="margin: 0;">');
        pdfWindow.document.write('<iframe width="100%" height="100%" src="' + pdf.output('datauristring') + '"></iframe>');
        pdfWindow.document.write('</body></html>');
      });
    };

    generatePdf();
  }, [data]);

  return (
    <div id="pdf-content" className="container px-5">
      <div className="row pt-3">
        <div className="col-3 col-md-3 col-sm-3 mb-2">
          <p class="invoice_para">
            <span class="invoice_para_bold">Campaign Name : </span>
            {campaignData.campaign_name}
          </p>
        </div>
        <div className="col-3 col-md-3 col-sm-3 mb-2">
          <p class="invoice_para">
            <span class="invoice_para_bold">Description : </span>
            {campaignData.campaign_summary}
          </p>
        </div>{" "}

      </div>
      <div className="row mt-4">
        <ul id="accordion" className="col-sm-12 col-md-12 col-12 qa-accordion">
          {data.map((item) => (
            <li key={item.id}>
              <div data-toggle="collapse">
                <span className="mr-2 qntext">Qn.</span>
                {item.question_text}
              </div>
              <div className="collapse show mt-2">
                <div className="card-body">
                  <ul>
                    {item.answer.map((ans, index) => (
                      <React.Fragment key={index}>
                        <li>
                          <span className="mr-2 anstext">Ans{index + 1} :</span>
                          {ans.answer_text}
                        </li>
                        <div className="my-2">
                          <div
                            className="requirement_date ml-auto"
                            style={{
                              background: "#c3e9cd",
                              padding: "1px 10px",
                              borderRadius: "5px",
                              width: "fit-content",
                            }}
                          >
                            <b>Approved By</b>
                            <span className="text-dark">{ans.approved_by}</span>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default QuestionAnswerPdf;
