import React, { Component } from "react";
import { apiCallingPost2 } from "../apiService";

import DatePicker from "react-datepicker";
// import ChartBar  from "../google-analytics/barchart";
// import ChartPie  from "../google-analytics/piechart";
// import Linechart  from "../google-analytics/linecharts";
import GoogleAnalyticsimage from "../images/left_icons/google_analytics.png";
import Header from "../layout/header";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import search from "../images/searchicon.png";
import searchwhite from "../images/loupe.png";
import view from "../images/view-details.png";
import eye from "../images/view.png";
import down from "../images/down.png";
import handPhn from "../images/hand-phone.png";
import infoimg from "../images/info.png";
import rightArrow from "../images/right-arrow.png";
import Linechart from "./linecharts";
import $ from "jquery";
import AnalyticsUser from "./analytics-users";
const result = [
  {
    state: "Kerala",
    city: "Attingal",
    currentDatas: {
      week1: {
        actions: {
          websitevisits: 1,
          requestdirections: 1,
          phonecalls: 1,
          total: 3,
        },
        searches: { direct: 3, indirect: 6, chain: 1, total: 10 },
        views: { search: 2, maps: 6, total: 8 },
      },
      week2: {
        actions: {
          websitevisits: 3,
          requestdirections: 2,
          phonecalls: 2,
          total: 7,
        },
        searches: { direct: 6, indirect: 9, chain: 4, total: 19 },
        views: { search: 5, maps: 7, total: 12 },
      },
      month1: {
        actions: {
          websitevisits: 16,
          requestdirections: 10,
          phonecalls: 8,
          total: 34,
        },
        searches: { direct: 50, indirect: 80, chain: 70, total: 200 },
        views: { search: 85, maps: 50, total: 135 },
      },
      month2: {
        actions: {
          websitevisits: 26,
          requestdirections: 20,
          phonecalls: 18,
          total: 64,
        },
        searches: { direct: 60, indirect: 80, chain: 80, total: 220 },
        views: { search: 98, maps: 62, total: 160 },
      },
    },
    quarterDatas: {
      actions: {
        websitevisits: 60,
        requestdirections: 25,
        phonecalls: 22,
        total: 107,
      },
      searches: { direct: 105, indirect: 115, chain: 110, total: 330 },
      views: { search: 116, maps: 73, total: 189 },
    },
    yearlyDatas: {
      actions: {
        websitevisits: 170,
        requestdirections: 100,
        phonecalls: 70,
        total: 340,
      },
      searches: { direct: 160, indirect: 200, chain: 300, total: 660 },
      views: { search: 230, maps: 130, total: 360 },
    },
  },
  {
    state: "Kerala",
    city: "Trivandrum",
    currentDatas: {
      week1: {
        actions: {
          websitevisits: 2,
          requestdirections: 1,
          phonecalls: 0,
          total: 3,
        },
        searches: { direct: 4, indirect: 7, chain: 2, total: 13 },
        views: { search: 3, maps: 5, total: 8 },
      },
      week2: {
        actions: {
          websitevisits: 2,
          requestdirections: 1,
          phonecalls: 0,
          total: 3,
        },
        searches: { direct: 4, indirect: 7, chain: 2, total: 13 },
        views: { search: 3, maps: 5, total: 8 },
      },
      month1: {
        actions: {
          websitevisits: 15,
          requestdirections: 8,
          phonecalls: 6,
          total: 29,
        },
        searches: { direct: 48, indirect: 78, chain: 72, total: 198 },
        views: { search: 82, maps: 46, total: 128 },
      },
      month2: {
        actions: {
          websitevisits: 25,
          requestdirections: 18,
          phonecalls: 16,
          total: 59,
        },
        searches: { direct: 58, indirect: 88, chain: 82, total: 228 },
        views: { search: 92, maps: 56, total: 148 },
      },
    },
    quarterDatas: {
      actions: {
        websitevisits: 57,
        requestdirections: 19,
        phonecalls: 18,
        total: 94,
      },
      searches: { direct: 98, indirect: 181, chain: 117, total: 396 },
      views: { search: 156, maps: 73, total: 229 },
    },
    yearlyDatas: {
      actions: {
        websitevisits: 164,
        requestdirections: 136,
        phonecalls: 79,
        total: 379,
      },
      searches: { direct: 169, indirect: 202, chain: 354, total: 725 },
      views: { search: 226, maps: 132, total: 358 },
    },
  },
  {
    state: "Kerala",
    city: "Kochi",
    currentDatas: {
      week1: {
        actions: {
          websitevisits: 1,
          requestdirections: 0,
          phonecalls: 0,
          total: 1,
        },
        searches: { direct: 2, indirect: 4, chain: 1, total: 7 },
        views: { search: 1, maps: 1, total: 2 },
      },
      week2: {
        actions: {
          websitevisits: 3,
          requestdirections: 2,
          phonecalls: 0,
          total: 5,
        },
        searches: { direct: 5, indirect: 9, chain: 4, total: 18 },
        views: { search: 5, maps: 7, total: 12 },
      },
      month1: {
        actions: {
          websitevisits: 24,
          requestdirections: 2,
          phonecalls: 9,
          total: 35,
        },
        searches: { direct: 61, indirect: 71, chain: 48, total: 180 },
        views: { search: 75, maps: 28, total: 103 },
      },
      month2: {
        actions: {
          websitevisits: 50,
          requestdirections: 22,
          phonecalls: 21,
          total: 93,
        },
        searches: { direct: 78, indirect: 108, chain: 92, total: 278 },
        views: { search: 122, maps: 66, total: 188 },
      },
    },
    quarterDatas: {
      actions: {
        websitevisits: 66,
        requestdirections: 29,
        phonecalls: 28,
        total: 123,
      },
      searches: { direct: 106, indirect: 167, chain: 109, total: 382 },
      views: { search: 170, maps: 73, total: 243 },
    },
    yearlyDatas: {
      actions: {
        websitevisits: 139,
        requestdirections: 98,
        phonecalls: 88,
        total: 325,
      },
      searches: { direct: 269, indirect: 252, chain: 276, total: 797 },
      views: { search: 249, maps: 159, total: 408 },
    },
  },
  {
    state: "Karnataka",
    city: "Bengaluru",
    currentDatas: {
      week1: {
        actions: {
          websitevisits: 1,
          requestdirections: 2,
          phonecalls: 2,
          total: 5,
        },
        searches: { direct: 3, indirect: 5, chain: 2, total: 10 },
        views: { search: 2, maps: 2, total: 4 },
      },
      week2: {
        actions: {
          websitevisits: 3,
          requestdirections: 6,
          phonecalls: 5,
          total: 14,
        },
        searches: { direct: 10, indirect: 18, chain: 24, total: 52 },
        views: { search: 15, maps: 27, total: 42 },
      },
      month1: {
        actions: {
          websitevisits: 5,
          requestdirections: 15,
          phonecalls: 20,
          total: 40,
        },
        searches: { direct: 32, indirect: 56, chain: 64, total: 152 },
        views: { search: 54, maps: 82, total: 136 },
      },
      month2: {
        actions: {
          websitevisits: 10,
          requestdirections: 25,
          phonecalls: 36,
          total: 71,
        },
        searches: { direct: 48, indirect: 78, chain: 72, total: 198 },
        views: { search: 63, maps: 98, total: 161 },
      },
    },
    quarterDatas: {
      actions: {
        websitevisits: 22,
        requestdirections: 32,
        phonecalls: 67,
        total: 121,
      },
      searches: { direct: 57, indirect: 89, chain: 99, total: 245 },
      views: { search: 84, maps: 106, total: 190 },
    },
    yearlyDatas: {
      actions: {
        websitevisits: 67,
        requestdirections: 76,
        phonecalls: 90,
        total: 233,
      },
      searches: { direct: 123, indirect: 163, chain: 143, total: 429 },
      views: { search: 149, maps: 159, total: 308 },
    },
  },
  {
    state: "Tamil Nadu",
    city: "Chennai",
    currentDatas: {
      week1: {
        actions: {
          websitevisits: 0,
          requestdirections: 0,
          phonecalls: 1,
          total: 1,
        },
        searches: { direct: 2, indirect: 3, chain: 2, total: 7 },
        views: { search: 1, maps: 2, total: 3 },
      },
      week2: {
        actions: {
          websitevisits: 2,
          requestdirections: 2,
          phonecalls: 4,
          total: 7,
        },
        searches: { direct: 5, indirect: 7, chain: 10, total: 23 },
        views: { search: 10, maps: 15, total: 25 },
      },
      month1: {
        actions: {
          websitevisits: 4,
          requestdirections: 11,
          phonecalls: 5,
          total: 20,
        },
        searches: { direct: 25, indirect: 12, chain: 37, total: 74 },
        views: { search: 25, maps: 21, total: 46 },
      },
      month2: {
        actions: {
          websitevisits: 8,
          requestdirections: 15,
          phonecalls: 7,
          total: 30,
        },
        searches: { direct: 38, indirect: 32, chain: 46, total: 116 },
        views: { search: 43, maps: 33, total: 76 },
      },
    },
    quarterDatas: {
      actions: {
        websitevisits: 44,
        requestdirections: 19,
        phonecalls: 9,
        total: 72,
      },
      searches: { direct: 43, indirect: 39, chain: 67, total: 149 },
      views: { search: 54, maps: 43, total: 97 },
    },
    yearlyDatas: {
      actions: {
        websitevisits: 84,
        requestdirections: 56,
        phonecalls: 23,
        total: 163,
      },
      searches: { direct: 78, indirect: 68, chain: 78, total: 224 },
      views: { search: 96, maps: 78, total: 174 },
    },
  },
  {
    state: "Maharashtra",
    city: "Mumbai",
    currentDatas: {
      week1: {
        actions: {
          websitevisits: 2,
          requestdirections: 3,
          phonecalls: 1,
          total: 6,
        },
        searches: { direct: 5, indirect: 3, chain: 1, total: 9 },
        views: { search: 2, maps: 5, total: 7 },
      },
      week2: {
        actions: {
          websitevisits: 3,
          requestdirections: 3,
          phonecalls: 4,
          total: 10,
        },
        searches: { direct: 9, indirect: 9, chain: 10, total: 28 },
        views: { search: 12, maps: 15, total: 27 },
      },
      month1: {
        actions: {
          websitevisits: 4,
          requestdirections: 3,
          phonecalls: 6,
          total: 13,
        },
        searches: { direct: 16, indirect: 20, chain: 26, total: 62 },
        views: { search: 38, maps: 25, total: 63 },
      },
      month2: {
        actions: {
          websitevisits: 8,
          requestdirections: 13,
          phonecalls: 16,
          total: 37,
        },
        searches: { direct: 26, indirect: 30, chain: 29, total: 85 },
        views: { search: 48, maps: 31, total: 79 },
      },
    },
    quarterDatas: {
      actions: {
        websitevisits: 12,
        requestdirections: 29,
        phonecalls: 18,
        total: 59,
      },
      searches: { direct: 34, indirect: 46, chain: 34, total: 114 },
      views: { search: 64, maps: 38, total: 102 },
    },
    yearlyDatas: {
      actions: {
        websitevisits: 37,
        requestdirections: 54,
        phonecalls: 56,
        total: 147,
      },
      searches: { direct: 93, indirect: 103, chain: 91, total: 287 },
      views: { search: 104, maps: 93, total: 197 },
    },
  },
];
let totalResult = null;
class AnalyticsGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Sessions: "",
      NewUsers: "",
      ActiveUsers: "",
      BounceRate: "",
      Conversions: "",
      GoalConversionRate: "",
      UserEngagementDuration: "",
      AverageSessionDuration: "",
      TotalUsers: "",
      reportDatas: [],
      detailsData: [],
      rows: [],
      livePageDetails: [],
      googleReviewReportData: [],
      viewMap: "",
      viewSearch: "",
      actionsWebsite: "",
      actionsPhone: "",
      actionsDrivingDirections: "",
      photosViews: 0,
      photosCount: 0,
      localPostViewsSearch: "",
      duration: "1-month",
      gaReportDatas: [],
      isLoader: 0,
      gaReportDealers: [],
      gaReportGroupedDatas: {},
      selectOptions: [],
      selectOptions2: [],
      optionState: [],
      cityoptionResult: [],
      citywise: [],
      optionCountry: [],
      optionCity: [],
      CountriesData: [],
      citiesData: [],
      gaReportNewData: null,
      selCity: "",
      selState: "",
      selstatearray: null,
      graphData: null,
      diffVal: 0,
      citysearch: "",
      filterDealer: "",
      sortDealerWise: "BounceRate-desc",
    };
    this.analyticsData = this.analyticsData.bind(this);
    this.getAnalyticsQuery = this.getAnalyticsQuery.bind(this);
    this.googlereviewreport = this.googlereviewreport.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getGoogleAnalyticsReport = this.getGoogleAnalyticsReport.bind(this);
    this.stateHandleChange = this.stateHandleChange.bind(this);
    this.cityHandleChange = this.cityHandleChange.bind(this);
    this.citywiseHandleChange = this.citywiseHandleChange.bind(this);
    this.countryHandleChange = this.countryHandleChange.bind(this);
    this.cityAnalyticHandleChange = this.cityAnalyticHandleChange.bind(this);
    this.getSessionGraphData = this.getSessionGraphData.bind(this);
    this.citysearch = this.citysearch.bind(this);
    this.filterDealer = this.filterDealer.bind(this);
    this.cityWiseAnalyticSort = this.cityWiseAnalyticSort.bind(this);
    this.dealerWiseAnalyticSort = this.dealerWiseAnalyticSort.bind(this);
  }
  componentDidMount() {
    $(".analyticsData").click(function () {
      $(".analyticsData").removeClass("active");
      $(this).addClass("active");
    });
    // $("#navi").click(function(){
    //   $("#navi .fa-chevron-down").toggleClass("rtoate180");
    //   $("#navigation").stop().slideToggle(500);
    // });
    //   $(".newUser li").change(function () {
    //     var filename = $(this).val();
    //     console.log(filename);
    // });
    this.analyticsData();
    this.getAnalyticsQuery();
    this.googlereviewreport();
    this.getGoogleAnalyticsReport();
    this.getTotalResult(result);
    this.googlereviewreportnew();
    this.getSessionGraphData();
  }
  cityWiseAnalyticSort(e) {
    console.log(e.target.value);
    if (e.target.value === "BounceRate-asc") {
      let gaReportDatas = this.state.reportDatas.sort((a, b) => {
        return parseFloat(a.details[3].val) - parseFloat(b.details[3].val);
      });
      console.log("gaReportDatas=========", gaReportDatas);
      this.setState({
        reportDatas: gaReportDatas,
      });
    } else if (e.target.value === "BounceRate-desc") {
      let gaReportDatas = this.state.reportDatas.sort((a, b) => {
        return parseFloat(b.details[3].val) - parseFloat(a.details[3].val);
      });
      console.log("gaReportDatas=========", gaReportDatas);
      this.setState({
        reportDatas: gaReportDatas,
      });
    } else if (e.target.value === "NewUsers-asc") {
      let gaReportDatas = this.state.reportDatas.sort((a, b) => {
        return parseFloat(a.details[1].val) - parseFloat(b.details[1].val);
      });
      console.log("gaReportDatas=========", gaReportDatas);
      this.setState({
        reportDatas: gaReportDatas,
      });
    }
  }
  dealerWiseAnalyticSort(e) {
    this.setState({
      sortDealerWise: e.target.value,
    });
  }
  filterDealer() {
    this.setState({
      filterDealer: $("#txtdealerwisesearch").val(),
    });
  }
  startWithIgnoringCase(text, other) {
    return text.toLowerCase().startsWith(other.toLowerCase());
  }
  equalsIgnoringCase(text, other) {
    return text.localeCompare(other, undefined, { sensitivity: "base" }) === 0;
  }
  getSessionGraphData() {
    const analyticsData = {
      functionName: "gaSessionGraph",
    };
    apiCallingPost2(analyticsData).then((data) => {
      this.setState({
        graphData: data.data.graphData,
        diffVal: data.data.diffvalPer,
      });
    });
  }
  countryHandleChange(e) {
    if (e.target.value !== "") {
      console.log("country========", e.target.value);
      console.log("country========", this.state.optionCountry);
      console.log("state========", this.state.CountriesData[e.target.value]);
      let cityoptionResult = this.state.CountriesData[e.target.value].reduce(
        function (r, a) {
          r[a.city.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())] =
            r[a.city.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())] || [];
          r[a.city.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())].push(a);
          return r;
        },
        Object.create(null)
      );
      var totalSessions = 0;
      var totalNewUsers = 0;
      var totalActiveUsers = 0;
      var totalBounceRate = 0.0;
      var totalConversions = 0;
      var totalGoalConversionRate = 0;
      var totalUserEngagementDuration = 0;
      var totalAverageSessionDuration = 0.0;
      var totalTotalUsers = 0;
      var responseSize = 0;
      console.log("totalTotalUsers========", totalTotalUsers);
      this.state.CountriesData[e.target.value].forEach((data) => {
        responseSize++;
        data.details.forEach((data2) => {
          var sessionVal = 1;
          if (data2.name === "Sessions") {
            sessionVal = parseInt(data2.val);
          }
          switch (data2.name) {
            case "Sessions":
              totalSessions = totalSessions + parseInt(data2.val);
              break;
            case "NewUsers":
              totalNewUsers = totalNewUsers + parseInt(data2.val);
              break;
            case "ActiveUsers":
              totalActiveUsers = totalActiveUsers + parseInt(data2.val);
              break;
            case "BounceRate":
              totalBounceRate =
                totalBounceRate + parseFloat(data2.val.replace("%", ""));
              break;
            case "Conversions":
              totalConversions = totalConversions + parseInt(data2.val);
              totalGoalConversionRate =
                totalGoalConversionRate +
                (parseInt(data2.val) / parseInt(sessionVal)) * 100;
              break;
            //case "GoalConversionRate":totalSessions=totalSessions+`${row.metricValues[i].value}`;break;
            case "UserEngagementDuration":
              totalUserEngagementDuration =
                totalUserEngagementDuration + parseInt(data2.val);
              break;
            case "AverageSessionDuration":
              totalAverageSessionDuration =
                totalAverageSessionDuration + parseFloat(data2.val);
              break;
            case "TotalUsers":
              totalTotalUsers = totalTotalUsers + parseInt(data2.val);
              break;
            default:
              break;
          }
        });
      });
      var minutesAverageSessionDuration = Math.floor(
        totalAverageSessionDuration.toFixed(2) / 60000
      );
      var secondsAverageSessionDuration = (
        (totalAverageSessionDuration.toFixed(2) % 60000) /
        1000
      ).toFixed(0);
      var minutesUserEngagementDuration = Math.floor(
        totalUserEngagementDuration / 60000
      );
      var secondsUserEngagementDuration = (
        (totalUserEngagementDuration % 60000) /
        1000
      ).toFixed(0);
      console.log("totalTotalUsers========", totalTotalUsers);
      this.setState({
        optionCity: Object.keys(cityoptionResult),
        Sessions: totalSessions,
        NewUsers: totalNewUsers,
        BounceRate:
          (
            (totalBounceRate.toFixed(2) /
              (responseSize === 0 ? 1 : responseSize)) *
            100
          ).toFixed(2) + "%",
        Conversions: totalConversions,
        GoalConversionRate: totalGoalConversionRate,
        UserEngagementDuration:
          minutesUserEngagementDuration !== "0"
            ? minutesUserEngagementDuration + " min "
            : "" + secondsUserEngagementDuration + " sec",
        AverageSessionDuration:
          minutesAverageSessionDuration !== "0"
            ? minutesAverageSessionDuration + " min "
            : "" + secondsAverageSessionDuration + " sec",
        TotalUsers: totalTotalUsers,
        citiesData: cityoptionResult,
      });
    } else {
      this.analyticsData();
    }
    //console.log("totalTotalUsers========",this.state.totalTotalUsers)
  }
  cityAnalyticDurationChange(val) {
    let selCity = val;
    if (selCity === "Trivandrum") {
      selCity = "Thiruvananthapuram";
    }
    console.log("city========", this.state.citiesData[selCity]);
    if (selCity !== "") {
      var totalSessions = 0;
      var totalNewUsers = 0;
      var totalActiveUsers = 0;
      var totalBounceRate = 0.0;
      var totalConversions = 0;
      var totalGoalConversionRate = 0;
      var totalUserEngagementDuration = 0;
      var totalAverageSessionDuration = 0.0;
      var totalTotalUsers = 0;
      var responseSize = 0;
      console.log("totalTotalUsers========", totalTotalUsers);
      if (this.state.citiesData[selCity]) {
        this.state.citiesData[selCity].forEach((data) => {
          responseSize++;
          data.details.forEach((data2) => {
            var sessionVal = 1;
            if (data2.name === "Sessions") {
              sessionVal = parseInt(data2.val);
            }
            switch (data2.name) {
              case "Sessions":
                totalSessions = totalSessions + parseInt(data2.val);
                break;
              case "NewUsers":
                totalNewUsers = totalNewUsers + parseInt(data2.val);
                break;
              case "ActiveUsers":
                totalActiveUsers = totalActiveUsers + parseInt(data2.val);
                break;
              case "BounceRate":
                totalBounceRate =
                  totalBounceRate + parseFloat(data2.val.replace("%", ""));
                break;
              case "Conversions":
                totalConversions = totalConversions + parseInt(data2.val);
                totalGoalConversionRate =
                  totalGoalConversionRate +
                  (parseInt(data2.val) / parseInt(sessionVal)) * 100;
                break;
              //case "GoalConversionRate":totalSessions=totalSessions+`${row.metricValues[i].value}`;break;
              case "UserEngagementDuration":
                totalUserEngagementDuration =
                  totalUserEngagementDuration + parseInt(data2.val);
                break;
              case "AverageSessionDuration":
                totalAverageSessionDuration =
                  totalAverageSessionDuration + parseFloat(data2.val);
                break;
              case "TotalUsers":
                totalTotalUsers = totalTotalUsers + parseInt(data2.val);
                break;
              default:
                break;
            }
          });
        });
      }
      var minutesAverageSessionDuration = Math.floor(
        totalAverageSessionDuration.toFixed(2) / 60000
      );
      var secondsAverageSessionDuration = (
        (totalAverageSessionDuration.toFixed(2) % 60000) /
        1000
      ).toFixed(0);
      var minutesUserEngagementDuration = Math.floor(
        totalUserEngagementDuration / 60000
      );
      var secondsUserEngagementDuration = (
        (totalUserEngagementDuration % 60000) /
        1000
      ).toFixed(0);
      console.log("totalTotalUsers========", totalTotalUsers);
      this.setState({
        Sessions: totalSessions,
        NewUsers: totalNewUsers,
        BounceRate:
          (
            (totalBounceRate.toFixed(2) /
              (responseSize === 0 ? 1 : responseSize)) *
            100
          ).toFixed(2) + "%",
        Conversions: totalConversions,
        GoalConversionRate: totalGoalConversionRate,
        UserEngagementDuration:
          minutesUserEngagementDuration !== "0"
            ? minutesUserEngagementDuration + " min "
            : "" + secondsUserEngagementDuration + " sec",
        AverageSessionDuration:
          minutesAverageSessionDuration !== "0"
            ? minutesAverageSessionDuration + " min "
            : "" + secondsAverageSessionDuration + " sec",
        TotalUsers: totalTotalUsers,
      });
    } else {
      this.countryHandleChange();
    }
  }
  cityAnalyticHandleChange(e) {
    let selCity = e.target.value;
    if (selCity === "Trivandrum") {
      selCity = "Thiruvananthapuram";
    }
    console.log("city========", this.state.citiesData[selCity]);
    if (selCity !== "") {
      var totalSessions = 0;
      var totalNewUsers = 0;
      var totalActiveUsers = 0;
      var totalBounceRate = 0.0;
      var totalConversions = 0;
      var totalGoalConversionRate = 0;
      var totalUserEngagementDuration = 0;
      var totalAverageSessionDuration = 0.0;
      var totalTotalUsers = 0;
      var responseSize = 0;
      console.log("totalTotalUsers========", totalTotalUsers);
      if (this.state.citiesData[selCity]) {
        this.state.citiesData[selCity].forEach((data) => {
          responseSize++;
          data.details.forEach((data2) => {
            var sessionVal = 1;
            if (data2.name === "Sessions") {
              sessionVal = parseInt(data2.val);
            }
            switch (data2.name) {
              case "Sessions":
                totalSessions = totalSessions + parseInt(data2.val);
                break;
              case "NewUsers":
                totalNewUsers = totalNewUsers + parseInt(data2.val);
                break;
              case "ActiveUsers":
                totalActiveUsers = totalActiveUsers + parseInt(data2.val);
                break;
              case "BounceRate":
                totalBounceRate =
                  totalBounceRate + parseFloat(data2.val.replace("%", ""));
                break;
              case "Conversions":
                totalConversions = totalConversions + parseInt(data2.val);
                totalGoalConversionRate =
                  totalGoalConversionRate +
                  (parseInt(data2.val) / parseInt(sessionVal)) * 100;
                break;
              //case "GoalConversionRate":totalSessions=totalSessions+`${row.metricValues[i].value}`;break;
              case "UserEngagementDuration":
                totalUserEngagementDuration =
                  totalUserEngagementDuration + parseInt(data2.val);
                break;
              case "AverageSessionDuration":
                totalAverageSessionDuration =
                  totalAverageSessionDuration + parseFloat(data2.val);
                break;
              case "TotalUsers":
                totalTotalUsers = totalTotalUsers + parseInt(data2.val);
                break;
              default:
                break;
            }
          });
        });
      }
      var minutesAverageSessionDuration = Math.floor(
        totalAverageSessionDuration.toFixed(2) / 60000
      );
      var secondsAverageSessionDuration = (
        (totalAverageSessionDuration.toFixed(2) % 60000) /
        1000
      ).toFixed(0);
      var minutesUserEngagementDuration = Math.floor(
        totalUserEngagementDuration / 60000
      );
      var secondsUserEngagementDuration = (
        (totalUserEngagementDuration % 60000) /
        1000
      ).toFixed(0);
      console.log("totalTotalUsers========", totalTotalUsers);
      this.setState({
        Sessions: totalSessions,
        NewUsers: totalNewUsers,
        BounceRate:
          (
            (totalBounceRate.toFixed(2) /
              (responseSize === 0 ? 1 : responseSize)) *
            100
          ).toFixed(2) + "%",
        Conversions: totalConversions,
        GoalConversionRate: totalGoalConversionRate,
        UserEngagementDuration:
          minutesUserEngagementDuration !== "0"
            ? minutesUserEngagementDuration + " min "
            : "" + secondsUserEngagementDuration + " sec",
        AverageSessionDuration:
          minutesAverageSessionDuration !== "0"
            ? minutesAverageSessionDuration + " min "
            : "" + secondsAverageSessionDuration + " sec",
        TotalUsers: totalTotalUsers,
      });
    } else {
      this.countryHandleChange();
    }
  }
  getTotalResult(resultArray) {
    let totalCurrentAction = 0,
      totalQuarterAction = 0,
      totalYearAction = 0,
      totalCurrentWebsiteVisits = 0,
      totalQuarterWebsiteVisits = 0,
      totalYearWebsiteVisits = 0,
      totalCurrentRequestDirections = 0,
      totalQuarterRequestDirections = 0,
      totalYearRequestDirections = 0,
      totalCurrentPhoneCalls = 0,
      totalQuarterPhoneCalls = 0,
      totalYearPhoneCalls = 0;
    let totalCurrentSearches = 0,
      totalQuarterSearches = 0,
      totalYearSearches = 0,
      totalCurrentDiscovery = 0,
      totalQuarterDiscovery = 0,
      totalYearDiscovery = 0,
      totalCurrentDirect = 0,
      totalQuarterDirect = 0,
      totalYearDirect = 0,
      totalCurrentBranded = 0,
      totalQuarterBranded = 0,
      totalYearBranded = 0;
    let totalCurrentViews = 0,
      totalQuarterViews = 0,
      totalYearViews = 0,
      totalCurrentSearch = 0,
      totalQuarterSearch = 0,
      totalYearSearch = 0,
      totalCurrentMaps = 0,
      totalQuarterMaps = 0,
      totalYearMaps = 0;
    resultArray.forEach((data) => {
      if (this.state.duration === "7-day") {
        totalCurrentAction =
          totalCurrentAction + data.currentDatas.week1.actions.total;
        totalCurrentWebsiteVisits =
          totalCurrentWebsiteVisits +
          data.currentDatas.week1.actions.websitevisits;
        totalCurrentRequestDirections =
          totalCurrentRequestDirections +
          data.currentDatas.week1.actions.requestdirections;
        totalCurrentPhoneCalls =
          totalCurrentPhoneCalls + data.currentDatas.week1.actions.phonecalls;
        totalCurrentSearches =
          totalCurrentSearches + data.currentDatas.week1.searches.total;
        totalCurrentDiscovery =
          totalCurrentDiscovery + data.currentDatas.week1.searches.indirect;
        totalCurrentDirect =
          totalCurrentDirect + data.currentDatas.week1.searches.direct;
        totalCurrentBranded =
          totalCurrentBranded + data.currentDatas.week1.searches.chain;
        totalCurrentViews =
          totalCurrentViews + data.currentDatas.week1.views.total;
        totalCurrentSearch =
          totalCurrentSearch + data.currentDatas.week1.views.search;
        totalCurrentMaps =
          totalCurrentMaps + data.currentDatas.week1.views.maps;
      } else if (this.state.duration === "14-day") {
        totalCurrentAction =
          totalCurrentAction + data.currentDatas.week2.actions.total;
        totalCurrentWebsiteVisits =
          totalCurrentWebsiteVisits +
          data.currentDatas.week2.actions.websitevisits;
        totalCurrentRequestDirections =
          totalCurrentRequestDirections +
          data.currentDatas.week2.actions.requestdirections;
        totalCurrentPhoneCalls =
          totalCurrentPhoneCalls + data.currentDatas.week2.actions.phonecalls;
        totalCurrentSearches =
          totalCurrentSearches + data.currentDatas.week2.searches.total;
        totalCurrentDiscovery =
          totalCurrentDiscovery + data.currentDatas.week2.searches.indirect;
        totalCurrentDirect =
          totalCurrentDirect + data.currentDatas.week2.searches.direct;
        totalCurrentBranded =
          totalCurrentBranded + data.currentDatas.week2.searches.chain;
        totalCurrentViews =
          totalCurrentViews + data.currentDatas.week2.views.total;
        totalCurrentSearch =
          totalCurrentSearch + data.currentDatas.week2.views.search;
        totalCurrentMaps =
          totalCurrentMaps + data.currentDatas.week2.views.maps;
      } else if (this.state.duration === "1-month") {
        totalCurrentAction =
          totalCurrentAction + data.currentDatas.month1.actions.total;
        totalCurrentWebsiteVisits =
          totalCurrentWebsiteVisits +
          data.currentDatas.month1.actions.websitevisits;
        totalCurrentRequestDirections =
          totalCurrentRequestDirections +
          data.currentDatas.month1.actions.requestdirections;
        totalCurrentPhoneCalls =
          totalCurrentPhoneCalls + data.currentDatas.month1.actions.phonecalls;
        totalCurrentSearches =
          totalCurrentSearches + data.currentDatas.month1.searches.total;
        totalCurrentDiscovery =
          totalCurrentDiscovery + data.currentDatas.month1.searches.indirect;
        totalCurrentDirect =
          totalCurrentDirect + data.currentDatas.month1.searches.direct;
        totalCurrentBranded =
          totalCurrentBranded + data.currentDatas.month1.searches.chain;
        totalCurrentViews =
          totalCurrentViews + data.currentDatas.month1.views.total;
        totalCurrentSearch =
          totalCurrentSearch + data.currentDatas.month1.views.search;
        totalCurrentMaps =
          totalCurrentMaps + data.currentDatas.month1.views.maps;
      } else if (this.state.duration === "2-month") {
        totalCurrentAction =
          totalCurrentAction + data.currentDatas.month2.actions.total;
        totalCurrentWebsiteVisits =
          totalCurrentWebsiteVisits +
          data.currentDatas.month2.actions.websitevisits;
        totalCurrentRequestDirections =
          totalCurrentRequestDirections +
          data.currentDatas.month2.actions.requestdirections;
        totalCurrentPhoneCalls =
          totalCurrentPhoneCalls + data.currentDatas.month2.actions.phonecalls;
        totalCurrentSearches =
          totalCurrentSearches + data.currentDatas.month2.searches.total;
        totalCurrentDiscovery =
          totalCurrentDiscovery + data.currentDatas.month2.searches.indirect;
        totalCurrentDirect =
          totalCurrentDirect + data.currentDatas.month2.searches.direct;
        totalCurrentBranded =
          totalCurrentBranded + data.currentDatas.month2.searches.chain;
        totalCurrentViews =
          totalCurrentViews + data.currentDatas.month2.views.total;
        totalCurrentSearch =
          totalCurrentSearch + data.currentDatas.month2.views.search;
        totalCurrentMaps =
          totalCurrentMaps + data.currentDatas.month2.views.maps;
      } else if (this.state.duration === "3-month") {
        totalCurrentAction =
          totalCurrentAction + data.quarterDatas.actions.total;
        totalCurrentWebsiteVisits =
          totalCurrentWebsiteVisits + data.quarterDatas.actions.websitevisits;
        totalCurrentRequestDirections =
          totalCurrentRequestDirections +
          data.quarterDatas.actions.requestdirections;
        totalCurrentPhoneCalls =
          totalCurrentPhoneCalls + data.quarterDatas.actions.phonecalls;
        totalCurrentSearches =
          totalCurrentSearches + data.quarterDatas.searches.total;
        totalCurrentDiscovery =
          totalCurrentDiscovery + data.quarterDatas.searches.indirect;
        totalCurrentDirect =
          totalCurrentDirect + data.quarterDatas.searches.direct;
        totalCurrentBranded =
          totalCurrentBranded + data.quarterDatas.searches.chain;
        totalCurrentViews = totalCurrentViews + data.quarterDatas.views.total;
        totalCurrentSearch =
          totalCurrentSearch + data.quarterDatas.views.search;
        totalCurrentMaps = totalCurrentMaps + data.quarterDatas.views.maps;
      } else if (this.state.duration === "6-month") {
        totalCurrentAction =
          totalCurrentAction +
          parseInt(data.currentDatas.month1.actions.total) +
          parseInt(data.quarterDatas.actions.total);
        totalCurrentWebsiteVisits =
          totalCurrentWebsiteVisits +
          parseInt(data.currentDatas.month1.actions.websitevisits) +
          parseInt(data.quarterDatas.actions.websitevisits);
        totalCurrentRequestDirections =
          totalCurrentRequestDirections +
          parseInt(data.currentDatas.month1.actions.requestdirections) +
          parseInt(
            data.quarterDatas.actions.requestdirections +
              data.quarterDatas.actions.requestdirections
          );
        totalCurrentPhoneCalls =
          totalCurrentPhoneCalls +
          parseInt(data.currentDatas.month1.actions.phonecalls) +
          parseInt(data.quarterDatas.actions.phonecalls);
        totalCurrentSearches =
          totalCurrentSearches +
          parseInt(data.currentDatas.month1.searches.total) +
          parseInt(data.quarterDatas.searches.total);
        totalCurrentDiscovery =
          totalCurrentDiscovery +
          parseInt(data.currentDatas.month1.searches.indirect) +
          parseInt(data.quarterDatas.searches.indirect);
        totalCurrentDirect =
          totalCurrentDirect +
          parseInt(data.currentDatas.month1.searches.direct) +
          parseInt(data.quarterDatas.searches.direct);
        totalCurrentBranded =
          totalCurrentBranded +
          parseInt(data.currentDatas.month1.searches.chain) +
          parseInt(data.quarterDatas.searches.chain);
        totalCurrentViews =
          totalCurrentViews +
          parseInt(data.currentDatas.month1.views.total) +
          parseInt(data.quarterDatas.views.total);
        totalCurrentSearch =
          totalCurrentSearch +
          parseInt(data.currentDatas.month1.views.search) +
          parseInt(data.quarterDatas.views.search);
        totalCurrentMaps =
          totalCurrentMaps +
          parseInt(data.currentDatas.month1.views.maps) +
          parseInt(data.quarterDatas.views.maps);
      } else if (this.state.duration === "1-year") {
        totalCurrentAction =
          totalCurrentAction + data.yearlyDatas.actions.total;
        totalCurrentWebsiteVisits =
          totalCurrentWebsiteVisits + data.yearlyDatas.actions.websitevisits;
        totalCurrentRequestDirections =
          totalCurrentRequestDirections +
          data.yearlyDatas.actions.requestdirections;
        totalCurrentPhoneCalls =
          totalCurrentPhoneCalls + data.yearlyDatas.actions.phonecalls;
        totalCurrentSearches =
          totalCurrentSearches + data.yearlyDatas.searches.total;
        totalCurrentDiscovery =
          totalCurrentDiscovery + data.yearlyDatas.searches.indirect;
        totalCurrentDirect =
          totalCurrentDirect + data.yearlyDatas.searches.direct;
        totalCurrentBranded =
          totalCurrentBranded + data.yearlyDatas.searches.chain;
        totalCurrentViews = totalCurrentViews + data.yearlyDatas.views.total;
        totalCurrentSearch = totalCurrentSearch + data.yearlyDatas.views.search;
        totalCurrentMaps = totalCurrentMaps + data.yearlyDatas.views.maps;
      }
      totalQuarterAction = totalQuarterAction + data.quarterDatas.actions.total;
      totalYearAction = totalYearAction + data.yearlyDatas.actions.total;
      totalQuarterWebsiteVisits =
        totalQuarterWebsiteVisits + data.quarterDatas.actions.websitevisits;
      totalYearWebsiteVisits =
        totalYearWebsiteVisits + data.yearlyDatas.actions.websitevisits;
      totalQuarterRequestDirections =
        totalQuarterRequestDirections +
        data.quarterDatas.actions.requestdirections;
      totalYearRequestDirections =
        totalYearRequestDirections + data.yearlyDatas.actions.requestdirections;
      totalQuarterPhoneCalls =
        totalQuarterPhoneCalls + data.quarterDatas.actions.phonecalls;
      totalYearPhoneCalls =
        totalYearPhoneCalls + data.yearlyDatas.actions.phonecalls;
      totalQuarterSearches =
        totalQuarterSearches + data.quarterDatas.searches.total;
      totalYearSearches = totalYearSearches + data.yearlyDatas.searches.total;
      totalQuarterDiscovery =
        totalQuarterDiscovery + data.quarterDatas.searches.indirect;
      totalYearDiscovery =
        totalYearDiscovery + data.yearlyDatas.searches.indirect;
      totalQuarterDirect =
        totalQuarterDirect + data.quarterDatas.searches.direct;
      totalYearDirect = totalYearDirect + data.yearlyDatas.searches.direct;
      totalQuarterBranded =
        totalQuarterBranded + data.quarterDatas.searches.chain;
      totalYearBranded = totalYearBranded + data.yearlyDatas.searches.chain;
      totalQuarterViews = totalQuarterViews + data.quarterDatas.views.total;
      totalYearViews = totalYearViews + data.yearlyDatas.views.total;
      totalQuarterSearch = totalQuarterSearch + data.quarterDatas.views.search;
      totalYearSearch = totalYearSearch + data.yearlyDatas.views.search;
      totalQuarterMaps = totalQuarterMaps + data.quarterDatas.views.maps;
      totalYearMaps = totalYearMaps + data.yearlyDatas.views.maps;
    });
    totalResult = {
      currentDatas: {
        actions: {
          websitevisits: totalCurrentWebsiteVisits,
          requestdirections: totalCurrentRequestDirections,
          phonecalls: totalCurrentPhoneCalls,
          total: totalCurrentAction,
        },
        searches: {
          direct: totalCurrentDirect,
          indirect: totalCurrentDiscovery,
          chain: totalCurrentBranded,
          total: totalCurrentSearches,
        },
        views: {
          search: totalCurrentSearch,
          maps: totalCurrentMaps,
          total: totalCurrentViews,
        },
      },
      quarterDatas: {
        actions: {
          websitevisits: totalQuarterWebsiteVisits,
          requestdirections: totalQuarterRequestDirections,
          phonecalls: totalQuarterPhoneCalls,
          total: totalQuarterAction,
        },
        searches: {
          direct: totalQuarterDirect,
          indirect: totalQuarterDiscovery,
          chain: totalQuarterBranded,
          total: totalQuarterSearches,
        },
        views: {
          search: totalQuarterSearch,
          maps: totalQuarterMaps,
          total: totalQuarterViews,
        },
      },
      yearlyDatas: {
        actions: {
          websitevisits: totalYearWebsiteVisits,
          requestdirections: totalYearRequestDirections,
          phonecalls: totalYearPhoneCalls,
          total: totalYearAction,
        },
        searches: {
          direct: totalYearDirect,
          indirect: totalYearDiscovery,
          chain: totalYearBranded,
          total: totalYearSearches,
        },
        views: {
          search: totalYearSearch,
          maps: totalYearMaps,
          total: totalYearViews,
        },
      },
    };
    this.setState({
      gaReportNewData: totalResult,
    });
  }
  stateHandleChange(e) {
    //alert(this.state.selectOptions)
    //console.log("state========",this.state.selectOptions2[e.target.value])
    let uniqueoptionCity = [];
    let City = [];
    if (e.target.value === "") {
      this.setState(
        {
          selState: e.target.value,
          //gaReportNewData:null,
          selCity: "",
        },
        () => {
          this.getTotalResult(result);
        }
      );
    } else {
      let cityoptionResult = null;
      let cityoptionResult2 = null;
      if (this.state.selectOptions[e.target.value]) {
        cityoptionResult = this.state.selectOptions[e.target.value].reduce(
          function (r, a) {
            r[a.city.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())] =
              r[a.city.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())] || [];
            r[a.city.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())].push(a);
            return r;
          },
          Object.create(null)
        );
        City = Object.keys(cityoptionResult);
      }
      console.log("cityoptionResult=======", cityoptionResult);
      if (this.state.selectOptions2[e.target.value]) {
        cityoptionResult2 = this.state.selectOptions2[e.target.value].reduce(
          function (r, a) {
            r[a.city.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())] =
              r[a.city.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())] || [];
            r[a.city.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())].push(a);
            return r;
          },
          Object.create(null)
        );
      }
      console.log("City====", City);
      if (City.length > 0) {
        City.push.apply(City, Object.keys(cityoptionResult2));
        uniqueoptionCity = [...new Set(City)];
      } else {
        uniqueoptionCity = Object.keys(cityoptionResult2);
      }
    }
    let gaOriginalReportDatas = this.state.gaReportDatas;
    let gaOriginalReportDealers = this.state.gaReportDealers;
    let gaOriginalReportGroupedDatas = this.state.gaReportGroupedDatas;
    let filterStategaReportDatas = this.state.gaReportDatas.filter((x) => {
      return this.equalsIgnoringCase(
        x.fullpageurl.split("/")[2],
        e.target.value
      );
    });
    let dataresult = filterStategaReportDatas.reduce(function (r, a) {
      r[
        a.fullpageurl.split("/")[5].toUpperCase() +
          a.fullpageurl.split("/")[4].toUpperCase()
      ] =
        r[
          a.fullpageurl.split("/")[5].toUpperCase() +
            a.fullpageurl.split("/")[4].toUpperCase()
        ] || [];
      r[
        a.fullpageurl.split("/")[5].toUpperCase() +
          a.fullpageurl.split("/")[4].toUpperCase()
      ].push(a);
      return r;
    }, Object.create(null));
    let stateResult = this.state.selectOptions2[e.target.value];
    //this.getTotalResult(stateResult)
    console.log("uniqueoptionCity===", uniqueoptionCity);
    this.setState(
      {
        selState: e.target.value,
        cityoptionResult: uniqueoptionCity,
        gaOriginalReportDatas: gaOriginalReportDatas,
        gaOriginalReportDealers: gaOriginalReportDealers,
        gaOriginalReportGroupedDatas: gaOriginalReportGroupedDatas,
        gaReportDealers: Object.keys(dataresult),
        gaReportGroupedDatas: dataresult,
        selstatearray: stateResult,
      },
      () => {
        if (e.target.value === "") {
          this.getTotalResult(result);
        } else if (this.state.selState === "" && this.state.selCity === "") {
          this.getTotalResult(result);
        } else {
          this.getTotalResult(stateResult);
        }
      }
    );
  }
  cityHandleChange(e) {
    let filterCitygaReportDatas;
    let filterCityResultDatas;
    if (e.target.value === "") {
      filterCitygaReportDatas = this.state.gaReportDatas.filter((x) => {
        return this.equalsIgnoringCase(
          x.fullpageurl.split("/")[2],
          this.state.selState
        );
      });
      console.log("city empty=-===", this.state.selState);
    } else {
      filterCitygaReportDatas = this.state.gaReportDatas.filter((x) => {
        return (
          this.equalsIgnoringCase(
            x.fullpageurl.split("/")[2],
            this.state.selState
          ) &&
          this.equalsIgnoringCase(x.fullpageurl.split("/")[3], e.target.value)
        );
      });
      filterCityResultDatas = this.state.selstatearray.filter((x) => {
        return (
          this.equalsIgnoringCase(x.state, this.state.selState) &&
          this.equalsIgnoringCase(x.city, e.target.value)
        );
      });
    }
    console.log("filterCityResultDatas====", filterCityResultDatas);
    let result = filterCitygaReportDatas.reduce(function (r, a) {
      r[
        a.fullpageurl.split("/")[5].toUpperCase() +
          a.fullpageurl.split("/")[4].toUpperCase()
      ] =
        r[
          a.fullpageurl.split("/")[5].toUpperCase() +
            a.fullpageurl.split("/")[4].toUpperCase()
        ] || [];
      r[
        a.fullpageurl.split("/")[5].toUpperCase() +
          a.fullpageurl.split("/")[4].toUpperCase()
      ].push(a);
      return r;
    }, Object.create(null));
    this.setState(
      {
        gaReportDealers: Object.keys(result),
        gaReportGroupedDatas: result,
        selCity: e.target.value,
      },
      () => {
        if (e.target.value === "") {
          this.getTotalResult(this.state.selstatearray);
        } else {
          this.getTotalResult(filterCityResultDatas);
        }
      }
    );
  }
  citysearch(e) {
    this.setState({
      citysearch: e.target.value,
    });
  }
  citywiseHandleChange() {
    let filterCitygaReportDatas;
    if (this.state.citysearch === "") {
      filterCitygaReportDatas = this.state.originalreportDatas;
    } else {
      filterCitygaReportDatas = this.state.originalreportDatas.filter((x) => {
        return this.startWithIgnoringCase(x.city, this.state.citysearch);
      });
      this.setState({
        reportDatas: filterCitygaReportDatas,
      });
    }
  }
  handleChange(e) {
    this.setState(
      {
        duration: e.target.value,
      },
      () => {
        if (this.state.selState === "" && this.state.selCity === "") {
          this.getTotalResult(result);
        } else {
          this.getTotalResult(this.state.selstatearray);
        }
        this.callAllFunction();
      }
    );
  }
  getGoogleAnalyticsReport() {
    this.setState({ isLoader: 0 });
    const analyticsData = {
      functionName: "getAdminGAreport",
      duration: this.state.duration,
    };
    let options = [];
    apiCallingPost2(analyticsData).then((data) => {
      data.data.resultData.forEach((x) => {
        options.push({
          state: x.fullpageurl.split("/")[2],
          city: x.fullpageurl.split("/")[3],
        });
      });
      let optionResult = options.reduce(function (r, a) {
        r[a.state.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())] =
          r[a.state.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())] || [];
        r[a.state.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())].push(a);
        return r;
      }, Object.create(null));
      let result = data.data.resultData.reduce(function (r, a) {
        r[
          a.fullpageurl.split("/")[5].toUpperCase() +
            a.fullpageurl.split("/")[4].toUpperCase()
        ] =
          r[
            a.fullpageurl.split("/")[5].toUpperCase() +
              a.fullpageurl.split("/")[4].toUpperCase()
          ] || [];
        r[
          a.fullpageurl.split("/")[5].toUpperCase() +
            a.fullpageurl.split("/")[4].toUpperCase()
        ].push(a);
        return r;
      }, Object.create(null));
      var optionState = this.state.optionState;
      let uniqueoptionState = [];
      if (optionState.length > 0) {
        optionState.push.apply(optionState, Object.keys(optionResult));
        uniqueoptionState = [...new Set(optionState)];
      }
      this.setState({
        gaReportDatas: data.data.resultData,
        isLoader: 1,
        gaReportDealers: Object.keys(result),
        gaReportGroupedDatas: result,
        optionState: uniqueoptionState,
        selectOptions: optionResult,
      });
      //console.log("result===========",result);
      //console.log("keys=======",Object.keys(result));
      //console.log("result2===========", result[Object.keys(result)[0]]);
    });
  }
  callAllFunction = () => {
    this.analyticsData();
    this.getAnalyticsQuery();
    this.googlereviewreport();
    this.getGoogleAnalyticsReport();
  };
  googlereviewreport() {
    this.setState({ isLoader: 0 });
    const googleReviewReportData = {
      functionName: "googlereviewreport",
      duration: this.state.duration,
    };
    apiCallingPost2(googleReviewReportData).then((data) => {
      let photoview = 0;
      let photocount = 0;
      photoview =
        parseInt(photoview) +
        parseInt(
          data.data.filter(function (e) {
            return e.label === "PHOTOS_VIEWS_MERCHANT";
          })[0].value
        );
      photoview =
        parseInt(photoview) +
        parseInt(
          data.data.filter(function (e) {
            return e.label === "PHOTOS_VIEWS_CUSTOMERS";
          })[0].value
        );
      photocount =
        parseInt(photocount) +
        parseInt(
          data.data.filter(function (e) {
            return e.label === "PHOTOS_COUNT_MERCHANT";
          })[0].value
        );
      photocount =
        parseInt(photocount) +
        parseInt(
          data.data.filter(function (e) {
            return e.label === "PHOTOS_COUNT_CUSTOMERS";
          })[0].value
        );
      this.setState({
        googleReviewReportData: data.data,
        viewMap: data.data.filter(function (e) {
          return e.label === "VIEWS_MAPS";
        })[0].value,
        viewSearch: data.data.filter(function (e) {
          return e.label === "VIEWS_SEARCH";
        })[0].value,
        actionsWebsite: data.data.filter(function (e) {
          return e.label === "ACTIONS_WEBSITE";
        })[0].value,
        actionsPhone: data.data.filter(function (e) {
          return e.label === "ACTIONS_PHONE";
        })[0].value,
        actionsDrivingDirections: data.data.filter(function (e) {
          return e.label === "ACTIONS_DRIVING_DIRECTIONS";
        })[0].value,
        photosViews: photoview,
        photosCount: photocount,
        localPostViewsSearch: data.data.filter(function (e) {
          return e.label === "LOCAL_POST_VIEWS_SEARCH";
        })[0].value,
        isLoader: 1,
      });
    });
  }
  analyticsData() {
    this.setState({ isLoader: 0 });
    const analyticsData = {
      functionName: "googleAnalyticsReport",
      duration: this.state.duration,
    };
    apiCallingPost2(analyticsData).then((data) => {
      let cityoptionResult = data.data.datas
        .filter(function (e) {
          return e.city.trim() !== "(not set)";
        })
        .reduce(function (r, a) {
          r[a.city] = r[a.city] || [];
          r[a.city].push(a);
          return r;
        }, Object.create(null));
      let optionCountries = data.data.datas
        .filter(function (e) {
          return e.city.trim() !== "(not set)";
        })
        .reduce(function (r, a) {
          r[a.country.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())] =
            r[a.country.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())] || [];
          r[
            a.country.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()).toString()
          ].push(a);
          return r;
        }, Object.create(null));
      console.log("Country==========", optionCountries);
      console.log("cityoptionResult==========", cityoptionResult);
      let cityoptionResult2 = optionCountries["India"].reduce(function (r, a) {
        r[a.city.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())] =
          r[a.city.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())] || [];
        r[a.city.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())].push(a);
        return r;
      }, Object.create(null));
      let gaReportDatas = data.data.datas.sort((a, b) => {
        return parseFloat(a.details[1].val) - parseFloat(b.details[1].val);
      });
      this.setState(
        {
          Sessions: data.data.totalset.Sessions,
          citiesData: cityoptionResult2,
          NewUsers: data.data.totalset.NewUsers,
          //ActiveUsers:data.data.totalActiveUsers,
          BounceRate: data.data.totalset.BounceRate,
          Conversions: data.data.totalset.Conversions,
          GoalConversionRate: data.data.totalset.GoalConversionRate,
          UserEngagementDuration: data.data.totalset.UserEngagementDuration,
          AverageSessionDuration: data.data.totalset.AverageSessionDuration,
          TotalUsers: data.data.totalset.TotalUsers,
          reportDatas: gaReportDatas,
          originalreportDatas: gaReportDatas,
          isLoader: 1,
          citywise: Object.keys(cityoptionResult),
          optionCountry: Object.keys(optionCountries),
          CountriesData: optionCountries,
        },
        () => {
          if (this.state.selCity) {
            this.cityAnalyticDurationChange(this.state.selCity);
          }
        }
      );
    });
  }
  getAnalyticsQuery() {
    this.setState({ isLoader: 0 });
    const analyticsData = {
      functionName: "googleAnalyticsQuery",
      duration: this.state.duration,
    };
    apiCallingPost2(analyticsData).then((data) => {
      console.log("............................",data)
      if (data.data) {
        this.setState({
          rows: data.data.query.rows,
          ActiveUsers: data.data.totalActiveUsers,
          livePageDetails: data.data.resultData,
          isLoader: 1,
        });
        window.sessionStorage.setItem(
          "ActiveUsers",
          data.data.totalActiveUsers
        );
      }
    });
  }
  googlereviewreportnew() {
    let optionResult = result.reduce(function (r, a) {
      r[a.state.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())] =
        r[a.state.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())] || [];
      r[a.state.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())].push(a);
      return r;
    }, Object.create(null));
    console.log("optionResult=======", optionResult);
    this.setState({
      optionState: Object.keys(optionResult),
      selectOptions2: optionResult,
    });
  }
  render() {
    var bounceRate = 0;
    var newUser = 0;
    var totaluser = 0;
    var dealerName = "";
    var GoogleAnalyticsAdminDiv = [];
    var gaArray = [];
    this.state.gaReportDealers.forEach((gaReport) => {
      bounceRate = 0;
      newUser = 0;
      totaluser = 0;
      dealerName = "";
      this.state.gaReportGroupedDatas[gaReport].forEach((groupData) => {
        dealerName =
          groupData.fullpageurl.split("/")[5].replaceAll("-", " ") +
          " - " +
          groupData.fullpageurl.split("/")[4].replaceAll("-", " ") +
          " - " +
          groupData.fullpageurl.split("/")[3].replaceAll("-", " ") +
          " - " +
          groupData.fullpageurl.split("/")[2].replaceAll("-", " ");
        newUser = parseInt(newUser) + parseInt(groupData.newUsers);
        totaluser = parseInt(totaluser) + parseInt(groupData.totalUsers);
        bounceRate =
          parseFloat(bounceRate) +
          parseFloat(groupData.bounceRate.replaceAll("%", ""));
      });
      bounceRate =
        parseFloat(bounceRate) /
        (this.state.gaReportGroupedDatas[gaReport].length === 0
          ? 1
          : this.state.gaReportGroupedDatas[gaReport].length);
      bounceRate = parseFloat(bounceRate).toFixed(2);
      if (this.state.filterDealer === "") {
        gaArray.push({
          dealerName: dealerName,
          bounceRate: bounceRate,
          newUser: newUser,
          totaluser: totaluser,
        });
      } else {
        if (
          dealerName
            .toLowerCase()
            .includes(this.state.filterDealer.toLowerCase())
        ) {
          gaArray.push({
            dealerName: dealerName,
            bounceRate: bounceRate,
            newUser: newUser,
            totaluser: totaluser,
          });
        }
      }
    });
    if (this.state.sortDealerWise === "BounceRate-desc") {
      gaArray = gaArray.sort((a, b) => {
        return parseFloat(b.bounceRate) - parseFloat(a.bounceRate);
      });
    } else if (this.state.sortDealerWise === "NewUsers-asc") {
      gaArray = gaArray.sort((a, b) => {
        return parseFloat(b.newUser) - parseFloat(a.newUser);
      });
    } else if (this.state.sortDealerWise === "BounceRate-asc") {
      gaArray = gaArray.sort((a, b) => {
        return parseFloat(a.bounceRate) - parseFloat(b.bounceRate);
      });
    }
    gaArray.map((data) =>
      GoogleAnalyticsAdminDiv.push(
        <div className="col-lg-6 col-md-12">
          <div className="row mt-1 mb-1">
            <div className="col-md-12 mt-1 mb-1 cityName">
              {data.dealerName}
            </div>
            <div className="col-md-5 userListLabeltext">Bounce Rate</div>
            <div className="col-md-1 userListLabeltext colonDiv">:</div>
            <div className="col-md-5 ">{data.bounceRate}%</div>
            <div className="col-md-5 userListLabeltext">New Users</div>
            <div className="col-md-1 userListLabeltext colonDiv">:</div>
            <div className="col-md-5 ">{data.newUser}</div>
            <div className="col-md-5 userListLabeltext">Total Users</div>
            <div className="col-md-1 userListLabeltext colonDiv">:</div>
            <div className="col-md-5 ">{data.totaluser}</div>
          </div>
          <hr />
        </div>
      )
    );

    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          {/* <div className="dash_heading">
                        <div className="leftdash_heading">
                            <Icon.Home />
                            <span>Review</span>
                        </div>
                        <div className="rightdash_heading">
                            <ul>
                                <li>
                                    <Icon.User />
                                    <div className="dash_listdrop">
                                        <ul>
                                            <li>
                                                <a href="">
                                                    <Icon.Settings />
                                                    Settings
                                                </a>
                                            </li>
                                            <li>
                                                <a href="">
                                                    <Icon.LogOut />
                                                    Logout
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div> */}

          <Header
            heading="Google Analytics"
            headerImage={GoogleAnalyticsimage}
          />
          <div className="user_listdiv">
            <div className="mainContentDashboard analyticsDiv container-fluid">
              <div className="user_analytics_btn">
                <div className="topnav_activehbtn">
                  <div className="topnav_activenumbr">
                    <span>
                      {window.sessionStorage.getItem("ActiveUsers")
                        ? window.sessionStorage.getItem("ActiveUsers")
                        : "0"}
                    </span>
                  </div>
                  <div className="topnav_activetext">
                    <span>Active Users</span>
                  </div>
                </div>
              </div>
              <div className="row col-lg-12 pb-5">
                <div className="col-lg-3">
                  <select
                    value={this.state.selState}
                    className="ga-slect-field"
                    id="selstate"
                    onChange={this.stateHandleChange}
                  >
                    <option value="">State</option>
                    {this.state.optionState.map((data) => (
                      <>
                        <option value={data} className="valueSelected">
                          {data}
                        </option>
                      </>
                    ))}
                  </select>
                </div>
                <div className="col-lg-3">
                  <select
                    className="ga-slect-field"
                    id="selcity"
                    value={this.state.selCity}
                    onChange={(e) => {
                      this.cityHandleChange(e);
                      this.cityAnalyticHandleChange(e);
                    }}
                  >
                    {this.state.cityoptionResult.length !== 0 ? (
                      <option value="">All</option>
                    ) : (
                      <option value="0">City</option>
                    )}
                    {this.state.cityoptionResult.map((data) => (
                      <>
                        <option value={data}>{data}</option>
                      </>
                    ))}
                  </select>
                </div>
                <div className="col-lg-2">
                  <select className="ga-slect-field ">
                    <option value="">Business Name</option>
                  </select>
                </div>
                <div className="col-lg-2">
                  <DatePicker
                    selected={this.state.startDate}
                    name="startDate"
                    showMonthDropdown
                    dateFormat="dd/MM/yyyy"
                    className="form-control ga-slect-field "
                    placeholderText="From Date"
                  />
                </div>
                <div className="col-lg-2">
                  <DatePicker
                    selected={this.state.startDate}
                    name="startDate"
                    showMonthDropdown
                    dateFormat="dd/MM/yyyy"
                    className="form-control ga-slect-field "
                    placeholderText="To Date"
                  />
                </div>
              </div>
              {this.state.isLoader === 0 ? (
                <div className="loder-wrapper"></div>
              ) : (
                ""
              )}
              <div className="row col-md-12  ga-month-outer">
                <div className="col-md-6">
                  <span className="mainHeadGoogleAnalytics">
                    Google Analytics
                  </span>
                </div>
                <div className="col-md-2 paddLeft">
                  <select
                    value={this.state.selState}
                    className="ga-slect-field selectState"
                    id="selstate"
                    onChange={this.stateHandleChange}
                  >
                    <option value="">State</option>
                    {this.state.optionState.map((data) => (
                      <>
                        <option value={data} className="valueSelected">
                          {data}
                        </option>
                      </>
                    ))}
                  </select>
                </div>
                <div className="col-md-2 paddLeft">
                  <select
                    className="ga-slect-field selectState"
                    id="selcity"
                    value={this.state.selCity}
                    onChange={(e) => {
                      this.cityHandleChange(e);
                      this.cityAnalyticHandleChange(e);
                    }}
                  >
                    {this.state.cityoptionResult.length !== 0 ? (
                      <option value="">All</option>
                    ) : (
                      <option value="0">City</option>
                    )}
                    {this.state.cityoptionResult.map((data) => (
                      <>
                        <option value={data}>{data}</option>
                      </>
                    ))}
                  </select>
                </div>
                <div className="col-md-2 paddLeft">
                  <select
                    className="ga-slect-field"
                    id="duration"
                    onChange={this.handleChange}
                  >
                    <option value="7-day">Last One Week</option>
                    <option value="14-day">Last Two Week</option>
                    <option value="1-month">Last One Month</option>
                    <option value="2-month">Last Two Month</option>
                    <option value="3-month">Last Three Month</option>
                    <option value="6-month">Last Six Month</option>
                    <option value="1-year">Last One Year</option>
                  </select>
                </div>
              </div>
              <div className="row ml-1 mt-2">
                {/* <div className="row"> */}
                <div className="col-md-4 analyticsDiv">
                  <div className="row">
                    <div className="col-md-5 analyticsData">
                      <div className="analytics_home">Sessions</div>
                      <div className="analyticsHomeCount user-analytics">
                        {this.state.Sessions}
                      </div>
                    </div>
                    <div className="col-md-5 analyticsData">
                      <div className="analytics_home">New Users</div>
                      <div className="analyticsHomeCount user-analytics">
                        {this.state.NewUsers}
                      </div>
                    </div>
                    <div className="col-md-5 analyticsData">
                      <div className="analytics_home">
                        GCR
                        <a href="#open-modal">
                          {" "}
                          <img src={infoimg} alt="info" className="info_icon" />
                        </a>
                      </div>
                      <div className="analyticsHomeCount user-analytics">
                        {this.state.GoalConversionRate}
                      </div>
                    </div>
                    <div className="col-md-5 analyticsData">
                      <div className="analytics_home">
                        UED
                        <a href="#open-modal1">
                          {" "}
                          <img src={infoimg} alt="info" className="info_icon" />
                        </a>
                      </div>

                      <div className="analyticsHomeCount user-analytics">
                        {this.state.UserEngagementDuration}
                      </div>
                    </div>
                    <div className="col-md-5 analyticsData">
                      <div className="analytics_home">Total Users</div>
                      <div className="analyticsHomeCount user-analytics">
                        {this.state.TotalUsers}
                      </div>
                    </div>
                    <div className="col-md-5 analyticsData">
                      <div className="analytics_home">Bounce Rate</div>
                      <div className="analyticsHomeCount user-analytics user-sec2">
                        {this.state.BounceRate}
                      </div>
                    </div>
                    <div className="col-md-5 analyticsData">
                      <div className="analytics_home">
                        ASD
                        <a href="#open-modal2">
                          {" "}
                          <img src={infoimg} alt="info" className="info_icon" />
                        </a>
                      </div>
                      <div className="analyticsHomeCount user-analytics">
                        {this.state.AverageSessionDuration}
                      </div>
                    </div>
                    <div className="col-md-5 analyticsData">
                      <div className="analytics_home">Conversions</div>
                      <div className="analyticsHomeCount user-analytics user-sec2">
                        {this.state.Conversions}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 analyticsDatas">
                  {this.state.graphData ? (
                    <Linechart
                      label="Sessions"
                      xAxisLabel="Months"
                      yAxisLabel="No. of sessions"
                      data={[
                        {
                          key: "Sessions  " + this.state.diffVal + "%",
                          values: this.state.graphData,
                        },
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {/* </div> */}

                {/* {this.state.gaReportNewData ? (
              <div className="col-md-12">
                <div className="users-list-head">
                  Google Business Profile Insights
                </div>
                <div className="row gbpi">
                  <div className="col-md-3 gbpi-left">
                    
                    <div className="gbpi-item">
                      Last Quarter:{" "}
                      <span>
                        {this.state.gaReportNewData.quarterDatas.actions.total}
                      </span>
                      <span
                        className={
                          (
                            ((this.state.gaReportNewData.quarterDatas.actions
                              .total -
                              this.state.gaReportNewData.currentDatas.actions
                                .total) /
                              this.state.gaReportNewData.quarterDatas.actions
                                .total) *
                            100
                          ).toFixed(2) > 0
                            ? "badge-gdpi badge-green"
                            : "badge-gdpi badge-red"
                        }
                      >
                       ({(
                          ((this.state.gaReportNewData.quarterDatas.actions
                            .total -
                            this.state.gaReportNewData.currentDatas.actions
                              .total) /
                            this.state.gaReportNewData.quarterDatas.actions
                              .total) *
                          100
                        ).toFixed(2)}
                        %)
                      </span>
                    </div>
                    <div className="gbpi-item">
                      Last Year:{" "}
                      <span>
                        {this.state.gaReportNewData.yearlyDatas.actions.total}
                      </span>
                      <span
                        className={
                          (
                            ((this.state.gaReportNewData.yearlyDatas.actions
                              .total -
                              this.state.gaReportNewData.currentDatas.actions
                                .total) /
                              this.state.gaReportNewData.yearlyDatas.actions
                                .total) *
                            100
                          ).toFixed(2) > 0
                            ? "badge-gdpi badge-green"
                            : "badge-gdpi badge-red"
                        }
                      >
                        ({(
                          ((this.state.gaReportNewData.yearlyDatas.actions
                            .total -
                            this.state.gaReportNewData.currentDatas.actions
                              .total) /
                            this.state.gaReportNewData.yearlyDatas.actions
                              .total) *
                          100
                        ).toFixed(2)}
                        %)
                      </span>
                    </div>

                    <div className="gbpi-main">
                    <div className="row">
                        <div className="col-md-6">
                        <div className="gbpiData">
                        {this.state.gaReportNewData.currentDatas.actions.total}
                      </div>
                      <div className="actions"> Actions{" "}</div>
                     
                        </div>
                        <div className="col-md-6 marginAuto">
                            <img src={handPhn} alt='view' width="40px"/>
                        </div>
                        </div>  
                     
                    </div>
                  </div>
                  <div className="col-md-9 graph-sec">
                    <div className="row mt-1 gdpi-subitem">
                      <div className="col-md-3 gdpi-mbl"></div>
                      <div className="col-md-3 gdpi-header">Current</div>
                      <div className="col-md-3 gdpi-header">Last Quarter</div>
                      <div className="col-md-3 gdpi-header">Last Year</div>
                    </div>
                    <div className="row mt-1 gdpi-subitem">
                      <div className="col-md-3 gdpi-lists">
                        Website
                        Visits
                      </div>
                      <div className="col-md-3">
                        {
                          this.state.gaReportNewData.currentDatas.actions
                            .websitevisits
                        }
                      </div>
                      <div className="col-md-3">
                        <span className="span_all_gbt">
                          {
                            this.state.gaReportNewData.quarterDatas.actions
                              .websitevisits
                          }{" "}
                        </span>
                        <span
                          className={
                            (
                              ((this.state.gaReportNewData.quarterDatas.actions
                                .websitevisits -
                                this.state.gaReportNewData.currentDatas.actions
                                  .websitevisits) /
                                this.state.gaReportNewData.quarterDatas.actions
                                  .websitevisits) *
                              100
                            ).toFixed(2) > 0
                              ? "badge-gdpi badge-green"
                              : "badge-gdpi badge-red"
                          }
                        >
                          ({(
                            ((this.state.gaReportNewData.quarterDatas.actions
                              .websitevisits -
                              this.state.gaReportNewData.currentDatas.actions
                                .websitevisits) /
                              this.state.gaReportNewData.quarterDatas.actions
                                .websitevisits) *
                            100
                          ).toFixed(2)}
                          %)
                        </span>
                      </div>
                      <div className="col-md-3">
                        <span className="span_all_gbt">
                          {
                            this.state.gaReportNewData.yearlyDatas.actions
                              .websitevisits
                          }
                        </span>
                        <span
                          className={
                            (
                              ((this.state.gaReportNewData.yearlyDatas.actions
                                .websitevisits -
                                this.state.gaReportNewData.currentDatas.actions
                                  .websitevisits) /
                                this.state.gaReportNewData.yearlyDatas.actions
                                  .websitevisits) *
                              100
                            ).toFixed(2) > 0
                              ? "badge-gdpi badge-green"
                              : "badge-gdpi badge-red"
                          }
                        >
                         ({(
                            ((this.state.gaReportNewData.yearlyDatas.actions
                              .websitevisits -
                              this.state.gaReportNewData.currentDatas.actions
                                .websitevisits) /
                              this.state.gaReportNewData.yearlyDatas.actions
                                .websitevisits) *
                            100
                          ).toFixed(2)}
                          %)
                        </span>
                      </div>
                    </div>
                    <div className="row mt-1 gdpi-subitem">
                      <div className="col-md-3 gdpi-lists">
                        Request
                        Directions
                      </div>
                      <div className="col-md-3">
                        {
                          this.state.gaReportNewData.currentDatas.actions
                            .requestdirections
                        }
                      </div>
                      <div className="col-md-3">
                        <span className="span_all_gbt">
                          {
                            this.state.gaReportNewData.quarterDatas.actions
                              .requestdirections
                          }{" "}
                        </span>
                        <span
                          className={
                            (
                              ((this.state.gaReportNewData.quarterDatas.actions
                                .requestdirections -
                                this.state.gaReportNewData.currentDatas.actions
                                  .requestdirections) /
                                this.state.gaReportNewData.quarterDatas.actions
                                  .requestdirections) *
                              100
                            ).toFixed(2) > 0
                              ? "badge-gdpi badge-green"
                              : "badge-gdpi badge-red"
                          }
                        >
                          ({(
                            ((this.state.gaReportNewData.quarterDatas.actions
                              .requestdirections -
                              this.state.gaReportNewData.currentDatas.actions
                                .requestdirections) /
                              this.state.gaReportNewData.quarterDatas.actions
                                .requestdirections) *
                            100
                          ).toFixed(2)}
                          %)
                        </span>
                      </div>
                      <div className="col-md-3">
                        <span className="span_all_gbt">
                          {
                            this.state.gaReportNewData.yearlyDatas.actions
                              .requestdirections
                          }
                        </span>
                        <span
                          className={
                            (
                              ((this.state.gaReportNewData.yearlyDatas.actions
                                .requestdirections -
                                this.state.gaReportNewData.currentDatas.actions
                                  .requestdirections) /
                                this.state.gaReportNewData.yearlyDatas.actions
                                  .requestdirections) *
                              100
                            ).toFixed(2) > 0
                              ? "badge-gdpi badge-green"
                              : "badge-gdpi badge-red"
                          }
                        >
                          ({(
                            ((this.state.gaReportNewData.yearlyDatas.actions
                              .requestdirections -
                              this.state.gaReportNewData.currentDatas.actions
                                .requestdirections) /
                              this.state.gaReportNewData.yearlyDatas.actions
                                .requestdirections) *
                            100
                          ).toFixed(2)}
                          %)
                        </span>
                      </div>
                    </div>
                    <div className="row mt-1 gdpi-subitem">
                      <div className="col-md-3 gdpi-lists">
                       Phone
                        Calls
                      </div>
                      <div className="col-md-3">
                        {
                          this.state.gaReportNewData.currentDatas.actions
                            .phonecalls
                        }
                      </div>
                      <div className="col-md-3">
                        <span className="span_all_gbt">
                          {
                            this.state.gaReportNewData.quarterDatas.actions
                              .phonecalls
                          }{" "}
                        </span>
                        <span
                          className={
                            (
                              ((this.state.gaReportNewData.quarterDatas.actions
                                .phonecalls -
                                this.state.gaReportNewData.currentDatas.actions
                                  .phonecalls) /
                                this.state.gaReportNewData.quarterDatas.actions
                                  .phonecalls) *
                              100
                            ).toFixed(2) > 0
                              ? "badge-gdpi badge-green"
                              : "badge-gdpi badge-red"
                          }
                        >
                          ({(
                            ((this.state.gaReportNewData.quarterDatas.actions
                              .phonecalls -
                              this.state.gaReportNewData.currentDatas.actions
                                .phonecalls) /
                              this.state.gaReportNewData.quarterDatas.actions
                                .phonecalls) *
                            100
                          ).toFixed(2)}
                          %)
                        </span>
                      </div>
                      <div className="col-md-3">
                        <span className="span_all_gbt">
                          {
                            this.state.gaReportNewData.yearlyDatas.actions
                              .phonecalls
                          }
                        </span>
                        <span
                          className={
                            (
                              ((this.state.gaReportNewData.yearlyDatas.actions
                                .phonecalls -
                                this.state.gaReportNewData.currentDatas.actions
                                  .phonecalls) /
                                this.state.gaReportNewData.yearlyDatas.actions
                                  .phonecalls) *
                              100
                            ).toFixed(2) > 0
                              ? "badge-gdpi badge-green"
                              : "badge-gdpi badge-red"
                          }
                        >
                          ({(
                            ((this.state.gaReportNewData.yearlyDatas.actions
                              .phonecalls -
                              this.state.gaReportNewData.currentDatas.actions
                                .phonecalls) /
                              this.state.gaReportNewData.yearlyDatas.actions
                                .phonecalls) *
                            100
                          ).toFixed(2)}
                          %)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row gbpi">
                <div className="col-md-3 gbpi-left">
               
                    <div className="gbpi-item">
                      Last Quarter:{" "}
                      <span>
                        {this.state.gaReportNewData.quarterDatas.searches.total}
                      </span>
                      <span
                        className={
                          (
                            ((this.state.gaReportNewData.quarterDatas.searches
                              .total -
                              this.state.gaReportNewData.currentDatas.searches
                                .total) /
                              this.state.gaReportNewData.quarterDatas.searches
                                .total) *
                            100
                          ).toFixed(2) > 0
                            ? "badge-gdpi badge-green"
                            : "badge-gdpi badge-red"
                        }
                      >
                        ({(
                          ((this.state.gaReportNewData.quarterDatas.searches
                            .total -
                            this.state.gaReportNewData.currentDatas.searches
                              .total) /
                            this.state.gaReportNewData.quarterDatas.searches
                              .total) *
                          100
                        ).toFixed(2)}
                        %)
                      </span>
                    </div>
                                     
                    <div className="gbpi-item">
                      Last Year:{" "}
                      <span className="span_all_gbt">
                        {this.state.gaReportNewData.yearlyDatas.searches.total}
                      </span>
                      <span
                        className={
                          (
                            ((this.state.gaReportNewData.yearlyDatas.searches
                              .total -
                              this.state.gaReportNewData.currentDatas.searches
                                .total) /
                              this.state.gaReportNewData.yearlyDatas.searches
                                .total) *
                            100
                          ).toFixed(2) > 0
                            ? "badge-gdpi badge-green"
                            : "badge-gdpi badge-red"
                        }
                      >
                        ({(
                          ((this.state.gaReportNewData.yearlyDatas.searches
                            .total -
                            this.state.gaReportNewData.currentDatas.searches
                              .total) /
                            this.state.gaReportNewData.yearlyDatas.searches
                              .total) *
                          100
                        ).toFixed(2)}
                        %)
                      </span>
                    </div>
                    <div className="gbpi-main">
                    <div className="row">
                        <div className="col-md-6">
                        <div className="gbpiData">
                        {this.state.gaReportNewData.currentDatas.searches.total}
                      </div>
                      <div className="actions"> Searches:{" "}</div>
                        </div>
                        <div className="col-md-6 marginAuto">
                            <img src={view} alt='view' width="40px"/>
                        </div>
                        </div>  
                     
                    </div>  
                </div>
                  <div className="col-md-9  graph-sec">
                    
                    <div className="row mt-1 gdpi-subitem">
                      <div className="col-md-3 gdpi-mbl"></div>
                      <div className="col-md-3 gdpi-header">Current</div>
                      <div className="col-md-3 gdpi-header">Last Quarter</div>
                      <div className="col-md-3 gdpi-header">Last Year</div>
                    </div>
                    <div className="row mt-1 gdpi-subitem">
                      <div className="col-md-3 gdpi-lists">Discovery</div>
                      <div className="col-md-3">
                        {
                          this.state.gaReportNewData.currentDatas.searches
                            .indirect
                        }
                      </div>
                      <div className="col-md-3">
                        <span className="span_all_gbt">
                          {
                            this.state.gaReportNewData.quarterDatas.searches
                              .indirect
                          }{" "}
                        </span>
                        <span
                          className={
                            (
                              ((this.state.gaReportNewData.quarterDatas.searches
                                .indirect -
                                this.state.gaReportNewData.currentDatas.searches
                                  .indirect) /
                                this.state.gaReportNewData.quarterDatas.searches
                                  .indirect) *
                              100
                            ).toFixed(2) > 0
                              ? "badge-gdpi badge-green"
                              : "badge-gdpi badge-red"
                          }
                        >
                          ({(
                            ((this.state.gaReportNewData.quarterDatas.searches
                              .indirect -
                              this.state.gaReportNewData.currentDatas.searches
                                .indirect) /
                              this.state.gaReportNewData.quarterDatas.searches
                                .indirect) *
                            100
                          ).toFixed(2)}
                          %)
                        </span>
                      </div>
                      <div className="col-md-3">
                        <span className="span_all_gbt">
                          {
                            this.state.gaReportNewData.yearlyDatas.searches
                              .indirect
                          }
                        </span>
                        <span
                          className={
                            (
                              ((this.state.gaReportNewData.yearlyDatas.searches
                                .indirect -
                                this.state.gaReportNewData.currentDatas.searches
                                  .indirect) /
                                this.state.gaReportNewData.yearlyDatas.searches
                                  .indirect) *
                              100
                            ).toFixed(2) > 0
                              ? "badge-gdpi badge-green"
                              : "badge-gdpi badge-red"
                          }
                        >
                          ({(
                            ((this.state.gaReportNewData.yearlyDatas.searches
                              .indirect -
                              this.state.gaReportNewData.currentDatas.searches
                                .indirect) /
                              this.state.gaReportNewData.yearlyDatas.searches
                                .indirect) *
                            100
                          ).toFixed(2)}
                          %)
                        </span>
                      </div>
                    </div>
                    <div className="row mt-1 gdpi-subitem">
                      <div className="col-md-3 gdpi-lists">Direct</div>
                      <div className="col-md-3">
                        {
                          this.state.gaReportNewData.currentDatas.searches
                            .direct
                        }
                      </div>
                      <div className="col-md-3">
                        <span className="span_all_gbt">
                          {
                            this.state.gaReportNewData.quarterDatas.searches
                              .direct
                          }{" "}
                        </span>
                        <span
                          className={
                            (
                              ((this.state.gaReportNewData.quarterDatas.searches
                                .direct -
                                this.state.gaReportNewData.currentDatas.searches
                                  .direct) /
                                this.state.gaReportNewData.quarterDatas.searches
                                  .direct) *
                              100
                            ).toFixed(2) > 0
                              ? "badge-gdpi badge-green"
                              : "badge-gdpi badge-red"
                          }
                        >
                          ({(
                            ((this.state.gaReportNewData.quarterDatas.searches
                              .direct -
                              this.state.gaReportNewData.currentDatas.searches
                                .direct) /
                              this.state.gaReportNewData.quarterDatas.searches
                                .direct) *
                            100
                          ).toFixed(2)}
                          %)
                        </span>
                      </div>
                      <div className="col-md-3">
                        <span className="span_all_gbt">
                          {
                            this.state.gaReportNewData.yearlyDatas.searches
                              .direct
                          }
                        </span>
                        <span
                          className={
                            (
                              ((this.state.gaReportNewData.yearlyDatas.searches
                                .direct -
                                this.state.gaReportNewData.currentDatas.searches
                                  .direct) /
                                this.state.gaReportNewData.yearlyDatas.searches
                                  .direct) *
                              100
                            ).toFixed(2) > 0
                              ? "badge-gdpi badge-green"
                              : "badge-gdpi badge-red"
                          }
                        >
                          ({(
                            ((this.state.gaReportNewData.yearlyDatas.searches
                              .direct -
                              this.state.gaReportNewData.currentDatas.searches
                                .direct) /
                              this.state.gaReportNewData.yearlyDatas.searches
                                .direct) *
                            100
                          ).toFixed(2)}
                          %)
                        </span>
                      </div>
                    </div>
                    <div className="row mt-1 gdpi-subitem">
                      <div className="col-md-3 gdpi-lists">Branded</div>
                      <div className="col-md-3">
                        {this.state.gaReportNewData.currentDatas.searches.chain}
                      </div>
                      <div className="col-md-3">
                        <span className="span_all_gbt">
                          {
                            this.state.gaReportNewData.quarterDatas.searches
                              .chain
                          }{" "}
                        </span>
                        <span
                          className={
                            (
                              ((this.state.gaReportNewData.quarterDatas.searches
                                .chain -
                                this.state.gaReportNewData.currentDatas.searches
                                  .chain) /
                                this.state.gaReportNewData.quarterDatas.searches
                                  .chain) *
                              100
                            ).toFixed(2) > 0
                              ? "badge-gdpi badge-green"
                              : "badge-gdpi badge-red"
                          }
                        >
                          ({(
                            ((this.state.gaReportNewData.quarterDatas.searches
                              .chain -
                              this.state.gaReportNewData.currentDatas.searches
                                .chain) /
                              this.state.gaReportNewData.quarterDatas.searches
                                .chain) *
                            100
                          ).toFixed(2)}
                          %)
                        </span>
                      </div>
                      <div className="col-md-3">
                        <span className="span_all_gbt">
                          {
                            this.state.gaReportNewData.yearlyDatas.searches
                              .chain
                          }
                        </span>
                        <span
                          className={
                            (
                              ((this.state.gaReportNewData.yearlyDatas.searches
                                .chain -
                                this.state.gaReportNewData.currentDatas.searches
                                  .chain) /
                                this.state.gaReportNewData.yearlyDatas.searches
                                  .chain) *
                              100
                            ).toFixed(2) > 0
                              ? "badge-gdpi badge-green"
                              : "badge-gdpi badge-red"
                          }
                        >
                         ({(
                            ((this.state.gaReportNewData.yearlyDatas.searches
                              .chain -
                              this.state.gaReportNewData.currentDatas.searches
                                .chain) /
                              this.state.gaReportNewData.yearlyDatas.searches
                                .chain) *
                            100
                          ).toFixed(2)}
                          %)
                        </span>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div className="row gbpi border-bottom-none">
                  <div className="col-md-3 gbpi-left">
                   
                    <div className="gbpi-item">
                      Last Quarter:{" "}
                      <span>
                        {this.state.gaReportNewData.quarterDatas.views.total}
                      </span>
                      <span
                        className={
                          (
                            ((this.state.gaReportNewData.quarterDatas.views
                              .total -
                              this.state.gaReportNewData.currentDatas.views
                                .total) /
                              this.state.gaReportNewData.quarterDatas.views
                                .total) *
                            100
                          ).toFixed(2) > 0
                            ? "badge-gdpi badge-green"
                            : "badge-gdpi badge-red"
                        }
                      >
                       ( {(
                          ((this.state.gaReportNewData.quarterDatas.views
                            .total -
                            this.state.gaReportNewData.currentDatas.views
                              .total) /
                            this.state.gaReportNewData.quarterDatas.views
                              .total) *
                          100
                        ).toFixed(2)}
                        %)
                      </span>
                    </div>
                    <div className="gbpi-item">
                      Last Year:{" "}
                      <span className="span_all_gbt">
                        {this.state.gaReportNewData.yearlyDatas.views.total}
                      </span>
                      <span
                        className={
                          (
                            ((this.state.gaReportNewData.yearlyDatas.views
                              .total -
                              this.state.gaReportNewData.currentDatas.views
                                .total) /
                              this.state.gaReportNewData.yearlyDatas.views
                                .total) *
                            100
                          ).toFixed(2) > 0
                            ? "badge-gdpi badge-green"
                            : "badge-gdpi badge-red"
                        }
                      >
                        ({(
                          ((this.state.gaReportNewData.yearlyDatas.views.total -
                            this.state.gaReportNewData.currentDatas.views
                              .total) /
                            this.state.gaReportNewData.yearlyDatas.views
                              .total) *
                          100
                        ).toFixed(2)}
                        %)
                      </span>
                    </div>
                  
                    <div className="gbpi-main">
                    <div className="row">
                        <div className="col-md-6">
                        <div className="gbpiData">
                        {this.state.gaReportNewData.currentDatas.views.total}
                      </div>
                      <div className="actions">Views:{" "}</div>
                        </div>
                        <div className="col-md-6 marginAuto">
                            <img src={eye} alt='view' width="40px"/>
                        </div>
                        </div>  
                     
                    </div>  
                  </div>
                  <div className="col-md-9 graph-sec">
                  <div className="row mt-1 gdpi-subitem">
                      <div className="col-md-3 gdpi-mbl"></div>
                      <div className="col-md-3 gdpi-header">Current</div>
                      <div className="col-md-3 gdpi-header">Last Quarter</div>
                      <div className="col-md-3 gdpi-header">Last Year</div>
                    </div>
                    <div className="row mt-1 gdpi-subitem">
                      <div className="col-md-3 gdpi-lists">Search</div>
                      <div className="col-md-3">
                        {this.state.gaReportNewData.currentDatas.views.search}
                      </div>
                      <div className="col-md-3">
                        <span className="span_all_gbt">
                          {this.state.gaReportNewData.quarterDatas.views.search}{" "}
                        </span>
                        <span
                          className={
                            (
                              ((this.state.gaReportNewData.quarterDatas.views
                                .search -
                                this.state.gaReportNewData.currentDatas.views
                                  .search) /
                                this.state.gaReportNewData.quarterDatas.views
                                  .search) *
                              100
                            ).toFixed(2) > 0
                              ? "badge-gdpi badge-green"
                              : "badge-gdpi badge-red"
                          }
                        >
                          ({(
                            ((this.state.gaReportNewData.quarterDatas.views
                              .search -
                              this.state.gaReportNewData.currentDatas.views
                                .search) /
                              this.state.gaReportNewData.quarterDatas.views
                                .search) *
                            100
                          ).toFixed(2)}
                          %)
                        </span>
                      </div>
                      <div className="col-md-3">
                        <span className="span_all_gbt">
                          {this.state.gaReportNewData.yearlyDatas.views.search}
                        </span>
                        <span
                          className={
                            (
                              ((this.state.gaReportNewData.yearlyDatas.views
                                .search -
                                this.state.gaReportNewData.currentDatas.views
                                  .search) /
                                this.state.gaReportNewData.yearlyDatas.views
                                  .search) *
                              100
                            ).toFixed(2) > 0
                              ? "badge-gdpi badge-green"
                              : "badge-gdpi badge-red"
                          }
                        >
                          ({(
                            ((this.state.gaReportNewData.yearlyDatas.views
                              .search -
                              this.state.gaReportNewData.currentDatas.views
                                .search) /
                              this.state.gaReportNewData.yearlyDatas.views
                                .search) *
                            100
                          ).toFixed(2)}
                          %)
                        </span>
                      </div>
                    </div>
                    <div className="row mt-1 gdpi-subitem">
                      <div className="col-md-3 gdpi-lists">Maps</div>
                      <div className="col-md-3">
                        {this.state.gaReportNewData.currentDatas.views.maps}
                      </div>
                      <div className="col-md-3">
                        <span className="span_all_gbt">
                          {this.state.gaReportNewData.quarterDatas.views.maps}{" "}
                        </span>
                        <span
                          className={
                            (
                              ((this.state.gaReportNewData.quarterDatas.views
                                .maps -
                                this.state.gaReportNewData.currentDatas.views
                                  .maps) /
                                this.state.gaReportNewData.quarterDatas.views
                                  .maps) *
                              100
                            ).toFixed(2) > 0
                              ? "badge-gdpi badge-green"
                              : "badge-gdpi badge-red"
                          }
                        >
                          ({(
                            ((this.state.gaReportNewData.quarterDatas.views
                              .maps -
                              this.state.gaReportNewData.currentDatas.views
                                .maps) /
                              this.state.gaReportNewData.quarterDatas.views
                                .maps) *
                            100
                          ).toFixed(2)}
                          %)
                        </span>
                      </div>
                      <div className="col-md-3">
                        <span className="span_all_gbt">
                          {this.state.gaReportNewData.yearlyDatas.views.maps}
                        </span>
                        <span
                          className={
                            (
                              ((this.state.gaReportNewData.yearlyDatas.views
                                .maps -
                                this.state.gaReportNewData.currentDatas.views
                                  .maps) /
                                this.state.gaReportNewData.yearlyDatas.views
                                  .maps) *
                              100
                            ).toFixed(2) > 0
                              ? "badge-gdpi badge-green"
                              : "badge-gdpi badge-red"
                          }
                        >
                          ({(
                            ((this.state.gaReportNewData.yearlyDatas.views
                              .maps -
                              this.state.gaReportNewData.currentDatas.views
                                .maps) /
                              this.state.gaReportNewData.yearlyDatas.views
                                .maps) *
                            100
                          ).toFixed(2)}
                          %)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )} */}
                <AnalyticsUser />
                {/* <div className="row"> */}
                <div className="col-md-6 padd0">
                  <div className="users-list-head">City wise analytics</div>
                  <div className="user-list-label-sub">
                    <div className="m-3">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="topnav-searchs">
                            <span className="topnav-posrel">
                              <input
                                onChange={this.citysearch}
                                type="text"
                                placeholder="Search City wise,name wise"
                                className="topnav-searchinputss"
                              />
                            </span>
                            <span>
                              <button
                                onClick={() => this.citywiseHandleChange()}
                                type="button"
                                className="topnav-searchbtn-city"
                              >
                                <img
                                  src={searchwhite}
                                  alt="search"
                                  className="search_white"
                                />
                              </button>
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <select
                            onChange={this.cityWiseAnalyticSort}
                            className="newUserField"
                            id="citywiseanalyticsort"
                          >
                            <option value="NewUsers-asc">
                              New User &#8595;
                            </option>
                            <option value="BounceRate-desc">
                              Bounce Rate &#8593;
                            </option>
                            <option value="BounceRate-asc">
                              Bounce Rate &#8595;
                            </option>
                          </select>
                          {/* <div id="navi" className="newUserField"> New User &#8595;
              
  <img src={down} alt="down" className="fa-chevron-down"/>
</div>
 

<div id="navigation">
  <ul className="newUser">
    <li>New User &#8595;</li>
    <li>Bounce rate &#8593;</li>
    <li>Bounce rate &#8595;</li>
  </ul>
</div>
 
<div id="navigationin"></div> */}
                        </div>
                      </div>

                      {this.state.reportDatas.map((data) => (
                        <>
                          <div className="row mt-1 mb-1">
                            <div className="col-md-12 mt-1 mb-1 cityName">
                              {data.city}
                            </div>
                            {data.details.map((details) => (
                              <>
                                {details.name === "NewUsers" ||
                                details.name === "TotalUsers" ||
                                details.name === "BounceRate" ? (
                                  <>
                                    <div className="col-md-4 userListLabeltext">
                                      {details.name} :
                                    </div>
                                    {/* <div className="col-md-1 userListLabeltext colonDiv">
                                :
                              </div> */}
                                    <div className="col-md-5 userListLabeltextBolds">
                                      {details.name === "BounceRate"
                                        ? parseFloat(details.val).toFixed(2) *
                                            100 +
                                          "%"
                                        : details.val}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            ))}
                          </div>
                          <hr />
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 livePage">
                  <div className="users-list-head">Live Page Activitys</div>
                  <div className="user-list-label-sub">
                    <div className="m-3">
                      {this.state.livePageDetails.map((data, i) => (
                        <div className=" mt-1 mb-1">
                          <div className="col-md-12 mt-1 mb-1 cityName pl-0">
                            {data.country} - {data.city}
                          </div>
                          <div className="row">
                            {" "}
                            <div className="col-md-12 userListLabeltextBolds textGrey">
                              {data.page}
                            </div>
                            <div className="col-md-3  colonDiv liveAcitivitie">
                              Active User:
                            </div>
                            <div className="col-md-4 liveAcitivitie pl-0">
                              {data.activeUsers ? data.activeUsers : "0"}
                            </div>
                          </div>
                          <hr />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* </div> */}

                <div className="row ml-1 mt-4">
                  <div className="users-list-heads">Dealer wise analytics</div>
                  <div className="col-md-6 padd0">
                    <div className="topnav-searchs">
                      <input
                        type="text"
                        id="txtdealerwisesearch"
                        placeholder="Search City wise,name wise"
                        className="topnav-searchinputs"
                      />
                      <button
                        onClick={() => this.filterDealer()}
                        className="topnav-searchbtns"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  <div className="col-md-6 bounceRate">
                    <select
                      className="ga-slect-fields_dealer"
                      id="dealerwisesort"
                      onChange={this.dealerWiseAnalyticSort}
                    >
                      <option value="BounceRate-desc">
                        Bounce Rate &#8593;
                      </option>
                      <option value="NewUsers-asc">New User &#8595;</option>
                      <option value="BounceRate-asc">
                        Bounce Rate &#8595;
                      </option>
                    </select>
                  </div>
                  <div className="col-md-12 user-list-labels user-list-label-width googleAnalyticsAdmin">
                    <div className="row ">{GoogleAnalyticsAdminDiv}</div>
                  </div>
                </div>
                <div id="open-modal" className="modal-window">
                  <div>
                    <a href="#!" title="Close" className="modal-close">
                      &times;
                    </a>
                    <div className="reviewEdit">
                      <div className="row">
                        <div className="col-md-12  reviewTitle">
                          <p className="main-head-modal">
                            Grievance Redressal System
                          </p>
                          <div>
                            The receipt and processing of complaints from
                            citizens and consumers
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="open-modal1" className="modal-window">
                  <div>
                    <a href="#!" title="Close" className="modal-close">
                      &times;
                    </a>
                    <div className="reviewEdit">
                      <div className="row">
                        <div className="col-md-12 reviewTitle">
                          <p className="main-head-modal">
                            User Engagement Duration
                          </p>

                          <div>
                            measures how frequently and for how long users
                            interact with your website or application.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div id="open-modal2" className="modal-window">
                                                <div>
                                                    <a href="#!" title="Close" className="modal-close">&times;</a>
                                                    <div className="reviewEdit">
                                                        <div className="row">
                                                            <div className="col-md-12  col-3 reviewTitle">
                                                                <p>Grievance Redressal System</p>
                                                                <div>The receipt and processing of complaints from citizens and consumers</div>
                                                            </div>
                                                       
                                                          
                                                           
                                                           
                                                        </div>
                                                       

                                                    </div>
                                                </div>
                                            </div> */}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default AnalyticsGraph;
