import React, { Component } from "react";
import Chart from "react-apexcharts";
import { backendApi } from "../apiService";
class AllSearches extends Component {
  constructor(props) {
    super(props);
    console.log("pool",this.props.getGraphQueriesDirect)
    this.state = {
series: [this.props.getGraphQueriesDirect, this.props.getGraphQueriesInDirect, this.props.getBranded],
      
      options: {
        chart: {
          type: "donut",
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        labels: ["Direct", "Discovery", "Branded"],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true,
                  label: "All searches",
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "#000",
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
      },
     
    };
  }
  
  render() {
    return (
      <div className="graph_insight">
        <Chart
          key={this.state.series}
          options={this.state.options}
          series={this.state.series}
          type="donut"
        />
      </div>
    );
  }
}
export default AllSearches;
