import React, { useEffect, useState } from 'react'
import OveralBudget from "./overalbudget"
import Leads from "./leads"
import Retail from "./retailsection"
import TopPerforming from "./top-performing-vehicle"
import MapIndia from "../../images/map-india.png"
function PaidMedia() {

    return (
        <div className="container-fluid">
            <div className="row padd-top">
                <div className="col-lg-4 col-md-12">
                    <div className="secion-card">
                        <OveralBudget />
                    </div>
                </div>
                <div className="col-lg-8 col-md-12">
                    <div className="secion-card">
                        <Leads />
                    </div>
                </div>
            </div>
            <div className="row pt-2">
                <div className="col-lg-8 col-md-12">
                        <Retail />
                </div>
                <div className="col-lg-4 col-md-12">
                        <img src={MapIndia} alt="" className='img-fluid'/>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 col-md-12">
                        <TopPerforming />
                </div>
            </div>
        </div>

    )
}

export default PaidMedia;