import React from "react";
import { useFlip, FlipProvider } from "react-easy-flip";
export default class ReactTest extends React.Component {

    constructor(params) {
        super(params);
        this.state = {
            todoItems: _items,
            // todoItemsId : flip-todo-items
        }
    }

    render() {
        return (
            <>
                <ReactTestFunction />
            </>

        );
    }
}

const shuffle = function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

const _items = [


    {
        id: 1,
        nid: ['https://images.unsplash.com/photo-1585255318859-f5c15f4cffe9?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=500&ixlib=rb-1.2.1&q=80&w=500',

        ]
    },
    {
        id: 2,
        nid: ['https://images.unsplash.com/photo-1585179292338-45ba1f62f082?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=500&ixlib=rb-1.2.1&q=80&w=500',

        ]
    },
    {
        id: 3,
        nid: ['https://images.unsplash.com/photo-1584226761916-3fd67ab5ac3a?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=500&ixlib=rb-1.2.1&q=80&w=500',

        ]
    },
    {
        id: 4,
        nid: ['https://images.unsplash.com/photo-1583217874534-581393fd5325?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=500&ixlib=rb-1.2.1&q=80&w=500',

        ]
    },
    {
        id: 5,
        nid: ['https://images.unsplash.com/photo-1584753987666-ead137ec0614?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=500&ixlib=rb-1.2.1&q=80&w=500',

        ]
    },

    {
        id: 6,
        nid: ['https://images.unsplash.com/photo-1583217874534-581393fd5325?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=500&ixlib=rb-1.2.1&q=80&w=500',

        ]
    },
    {
        id: 7,
        nid: ['https://images.unsplash.com/photo-1585179292338-45ba1f62f082?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=500&ixlib=rb-1.2.1&q=80&w=500',

        ]
    },
    {
        id: 8,
        nid: ['https://images.unsplash.com/photo-1584226761916-3fd67ab5ac3a?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=500&ixlib=rb-1.2.1&q=80&w=500',

        ]
    },
    {
        id: 9,
        nid: ['https://images.unsplash.com/photo-1583217874534-581393fd5325?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=500&ixlib=rb-1.2.1&q=80&w=500',

        ]
    },
    {
        id: 10,
        nid: ['https://images.unsplash.com/photo-1584753987666-ead137ec0614?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=500&ixlib=rb-1.2.1&q=80&w=500',

        ]
    },


]
console.log("_items", _items)


export function ReactTestFunction() {
    var tag_tree = {
        listStyle: "none",
        width: "200px",
        // background: "pink",
        height: "200px",
        // display: "inline-block",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        float: "left",
        marginRight: "5px",
        objectFit:"contain"

    }

    const [todoItems, setTodoItems] = React.useState(_items);

    const todoItemsId = "flip-todo-items";

    useFlip(todoItemsId, {
        duration: 800,
    });



    return (
        <FlipProvider>

            <div className="flex w-full h-full justify-center items-center bg-gray-800">
                <div className="w-1/2 text-center">
                    <button
                        className="mt-2 p-2 bg-gray-200 font-bold rounded-lg hover:bg-gray-400"
                        onClick={() => setTodoItems(shuffle([...todoItems]))}
                    >
                        Shuffle Num
                    </button>
                    <ul data-flip-root-id={todoItemsId} className="p-4" >
                        {todoItems.map((item) => (
                            <li
                                style={tag_tree}
                                key={item.id}
                                data-flip-id={`flip-id-${item.id}`}
                                className="flex items-center justify-center font-bold text-lg bg-gray-400 mb-4 bg-yellow-500 rounded-lg"
                            >
                                <img src={item.nid} style={tag_tree} />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </FlipProvider>
    );
}





