import React from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer.jsx";
import LeftMenu from "../layout/leftMenu.jsx";
import { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Modal from "react-modal";
import SocialMedia from "../images/left_icons/social-media.png";
import GoogleCampaigns from "./components/google-campaign";
import GoogleAdsGroup from "./components/google-ads-group";
import GoogleCampaignBudget from "./components/google-campaign-budget";
import GoogleCreateAds from "./components/google-create-ads";
import GoogleConversionAction from "./components/google-conversion-action";
export default function GoogleAdsReport() {
    const [sectionName, setSectionName] = useState("");
    const [modalIsOpen, setIsOpen] = useState(false);
    const [tabName, setTabName] = useState("Campaign");
    const [tabState, setTabState] = useState("1")
    const openModal = () =>{
        setIsOpen(true)
    }
    let subtitle;
    function afterOpenModal() {
        subtitle.style.color = "#f00";
      }
      const closeModal = () => {
        setIsOpen(false)
      }
      const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
      };
      function tabCampaign(nameValue,stateValue) {
        setTabState(stateValue);
        setTabName(nameValue);
      }
  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header heading={sectionName} headerImage={SocialMedia} />

        <div className="container-fluid main_adswrapper">
          <Tabs className="tabs-main">
            <div className="tabs_headsec">
              <TabList>
                <Tab onClick={()=>tabCampaign("Campaign","1")}>Campaign</Tab>
                <Tab onClick={()=>tabCampaign("Ads Group","2")}>Ads Group</Tab>
                <Tab onClick={()=>tabCampaign("Campaign Budget","3")}>Campaign Budget</Tab>
                <Tab onClick={()=>tabCampaign("Ads","4")}>Ads</Tab>
                <Tab onClick={()=>tabCampaign("Conversion Action","5")}>Conversion action</Tab>
              </TabList>

              <div>
                <button
                  type="button"
                  class="btn btn-primary mr-3"
                  onClick={openModal}
                >
                  Create {tabName}
                </button>
              </div>
            </div>
            <TabPanel>
              <GoogleCampaigns/>
            </TabPanel>
            <TabPanel>
              <GoogleAdsGroup/>
            </TabPanel>
            <TabPanel>
              <GoogleCampaignBudget/>
            </TabPanel>
            <TabPanel>
              <GoogleCreateAds/>
            </TabPanel>
            <TabPanel>
              <GoogleConversionAction/>
            </TabPanel>
          </Tabs>
          {tabState === "1"?(
          <div>
              <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <div className="tag_campaignPopup">
                  <h2>Create {tabName}</h2>

                  <form>
                    <div
                      className="form-group tag_formLabel"
                      style={{ marginBottom: "5px" }}
                    >
                      <label>
                        Name<sup className="text-danger">*</sup>
                      </label>
                      <input
                        id={"inputName"}
                        type="text"
                        className="form-control"
                        style={{ marginBottom: "5px" }}
                      />
                    </div>
                    <div
                      className="form-group tag_formLabel"
                      style={{ marginBottom: "5px" }}
                    >
                      <label>
                        Objective <sup className="text-danger">*</sup>
                      </label>
                      <select
                        id={"Objective"}
                        className="form-control"
                        style={{ marginBottom: "5px" }}
                      >
                        <option value="">Please Select Objective</option>
                      </select>
                    </div>
                    <div
                      className="form-group tag_formLabel"
                      style={{ marginBottom: "5px" }}
                    >
                      <label>
                        Status <sup className="text-danger">*</sup>
                      </label>
                      <select
                        id={"Status"}
                        className="form-control"
                        style={{ marginBottom: "5px" }}
                      >
                        <option value="">Please Select Status</option>
                      </select>
                    </div>
                    <div
                      className="form-group tag_formLabel"
                      style={{ marginBottom: "5px" }}
                    >
                      <label>
                        Special Ad Categories
                        <sup className="text-danger">*</sup>
                      </label>
                      <select
                        id={"Categories"}
                        className="form-control"
                        style={{ marginBottom: "5px" }}
                      >
                        <option value="">
                          Please Select Special Ad Categories
                        </option>
                      </select>
                    </div>
                  </form>

                  <div class="button-wrapper">
                    <button
                      type="button"
                      class="btn-submit"
                    //   onClick={formSubmit}
                      style={{ cursor: "pointer" }}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      class="btn-cancel"
                      onClick={closeModal}
                      style={{ cursor: "pointer" }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Modal>

          </div>):(
           tabState === "2"?(
            <div>
                <Modal
                  isOpen={modalIsOpen}
                  onAfterOpen={afterOpenModal}
                  onRequestClose={closeModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <div className="tag_campaignPopup">
                    <h2>Create {tabName}</h2>
  
                    <form>
                      <div
                        className="form-group tag_formLabel"
                        style={{ marginBottom: "5px" }}
                      >
                        <label>
                          Name<sup className="text-danger">*</sup>
                        </label>
                        <input
                          id={"inputName"}
                          type="text"
                          className="form-control"
                          style={{ marginBottom: "5px" }}
                        />
                      </div>
                      <div
                        className="form-group tag_formLabel"
                        style={{ marginBottom: "5px" }}
                      >
                        <label>
                          Objective <sup className="text-danger">*</sup>
                        </label>
                        <select
                          id={"Objective"}
                          className="form-control"
                          style={{ marginBottom: "5px" }}
                        >
                          <option value="">Please Select Objective</option>
                        </select>
                      </div>
                      <div
                        className="form-group tag_formLabel"
                        style={{ marginBottom: "5px" }}
                      >
                        <label>
                          Status <sup className="text-danger">*</sup>
                        </label>
                        <select
                          id={"Status"}
                          className="form-control"
                          style={{ marginBottom: "5px" }}
                        >
                          <option value="">Please Select Status</option>
                        </select>
                      </div>
                      <div
                        className="form-group tag_formLabel"
                        style={{ marginBottom: "5px" }}
                      >
                        <label>
                          Special Ad Categories
                          <sup className="text-danger">*</sup>
                        </label>
                        <select
                          id={"Categories"}
                          className="form-control"
                          style={{ marginBottom: "5px" }}
                        >
                          <option value="">
                            Please Select Special Ad Categories
                          </option>
                        </select>
                      </div>
                    </form>
  
                    <div class="button-wrapper">
                      <button
                        type="button"
                        class="btn-submit"
                      //   onClick={formSubmit}
                        style={{ cursor: "pointer" }}
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        class="btn-cancel"
                        onClick={closeModal}
                        style={{ cursor: "pointer" }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Modal>
  
            </div>):(
              tabState === "3"?(
                <div>
                    <Modal
                      isOpen={modalIsOpen}
                      onAfterOpen={afterOpenModal}
                      onRequestClose={closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >
                      <div className="tag_campaignPopup">
                        <h2>Create {tabName}</h2>
      
                        <form>
                          <div
                            className="form-group tag_formLabel"
                            style={{ marginBottom: "5px" }}
                          >
                            <label>
                              Name<sup className="text-danger">*</sup>
                            </label>
                            <input
                              id={"inputName"}
                              type="text"
                              className="form-control"
                              style={{ marginBottom: "5px" }}
                            />
                          </div>
                          <div
                            className="form-group tag_formLabel"
                            style={{ marginBottom: "5px" }}
                          >
                            <label>
                              Objective <sup className="text-danger">*</sup>
                            </label>
                            <select
                              id={"Objective"}
                              className="form-control"
                              style={{ marginBottom: "5px" }}
                            >
                              <option value="">Please Select Objective</option>
                            </select>
                          </div>
                          <div
                            className="form-group tag_formLabel"
                            style={{ marginBottom: "5px" }}
                          >
                            <label>
                              Status <sup className="text-danger">*</sup>
                            </label>
                            <select
                              id={"Status"}
                              className="form-control"
                              style={{ marginBottom: "5px" }}
                            >
                              <option value="">Please Select Status</option>
                            </select>
                          </div>
                          <div
                            className="form-group tag_formLabel"
                            style={{ marginBottom: "5px" }}
                          >
                            <label>
                              Special Ad Categories
                              <sup className="text-danger">*</sup>
                            </label>
                            <select
                              id={"Categories"}
                              className="form-control"
                              style={{ marginBottom: "5px" }}
                            >
                              <option value="">
                                Please Select Special Ad Categories
                              </option>
                            </select>
                          </div>
                        </form>
      
                        <div class="button-wrapper">
                          <button
                            type="button"
                            class="btn-submit"
                          //   onClick={formSubmit}
                            style={{ cursor: "pointer" }}
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            class="btn-cancel"
                            onClick={closeModal}
                            style={{ cursor: "pointer" }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Modal>
      
                </div>):(
                 tabState === "4"?(
                  <div>
                      <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                      >
                        <div className="tag_campaignPopup">
                          <h2>Create {tabName}</h2>
        
                          <form>
                            <div
                              className="form-group tag_formLabel"
                              style={{ marginBottom: "5px" }}
                            >
                              <label>
                                Name<sup className="text-danger">*</sup>
                              </label>
                              <input
                                id={"inputName"}
                                type="text"
                                className="form-control"
                                style={{ marginBottom: "5px" }}
                              />
                            </div>
                            <div
                              className="form-group tag_formLabel"
                              style={{ marginBottom: "5px" }}
                            >
                              <label>
                                Objective <sup className="text-danger">*</sup>
                              </label>
                              <select
                                id={"Objective"}
                                className="form-control"
                                style={{ marginBottom: "5px" }}
                              >
                                <option value="">Please Select Objective</option>
                              </select>
                            </div>
                            <div
                              className="form-group tag_formLabel"
                              style={{ marginBottom: "5px" }}
                            >
                              <label>
                                Status <sup className="text-danger">*</sup>
                              </label>
                              <select
                                id={"Status"}
                                className="form-control"
                                style={{ marginBottom: "5px" }}
                              >
                                <option value="">Please Select Status</option>
                              </select>
                            </div>
                            <div
                              className="form-group tag_formLabel"
                              style={{ marginBottom: "5px" }}
                            >
                              <label>
                                Special Ad Categories
                                <sup className="text-danger">*</sup>
                              </label>
                              <select
                                id={"Categories"}
                                className="form-control"
                                style={{ marginBottom: "5px" }}
                              >
                                <option value="">
                                  Please Select Special Ad Categories
                                </option>
                              </select>
                            </div>
                          </form>
        
                          <div class="button-wrapper">
                            <button
                              type="button"
                              class="btn-submit"
                            //   onClick={formSubmit}
                              style={{ cursor: "pointer" }}
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              class="btn-cancel"
                              onClick={closeModal}
                              style={{ cursor: "pointer" }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </Modal>
        
                  </div>):(
                   tabState === "5"?(
                    <div>
                        <Modal
                          isOpen={modalIsOpen}
                          onAfterOpen={afterOpenModal}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <div className="tag_campaignPopup">
                            <h2>Create {tabName}</h2>
          
                            <form>
                              <div
                                className="form-group tag_formLabel"
                                style={{ marginBottom: "5px" }}
                              >
                                <label>
                                  Name<sup className="text-danger">*</sup>
                                </label>
                                <input
                                  id={"inputName"}
                                  type="text"
                                  className="form-control"
                                  style={{ marginBottom: "5px" }}
                                />
                              </div>
                              <div
                                className="form-group tag_formLabel"
                                style={{ marginBottom: "5px" }}
                              >
                                <label>
                                  Objective <sup className="text-danger">*</sup>
                                </label>
                                <select
                                  id={"Objective"}
                                  className="form-control"
                                  style={{ marginBottom: "5px" }}
                                >
                                  <option value="">Please Select Objective</option>
                                </select>
                              </div>
                              <div
                                className="form-group tag_formLabel"
                                style={{ marginBottom: "5px" }}
                              >
                                <label>
                                  Status <sup className="text-danger">*</sup>
                                </label>
                                <select
                                  id={"Status"}
                                  className="form-control"
                                  style={{ marginBottom: "5px" }}
                                >
                                  <option value="">Please Select Status</option>
                                </select>
                              </div>
                              <div
                                className="form-group tag_formLabel"
                                style={{ marginBottom: "5px" }}
                              >
                                <label>
                                  Special Ad Categories
                                  <sup className="text-danger">*</sup>
                                </label>
                                <select
                                  id={"Categories"}
                                  className="form-control"
                                  style={{ marginBottom: "5px" }}
                                >
                                  <option value="">
                                    Please Select Special Ad Categories
                                  </option>
                                </select>
                              </div>
                            </form>
          
                            <div class="button-wrapper">
                              <button
                                type="button"
                                class="btn-submit"
                              //   onClick={formSubmit}
                                style={{ cursor: "pointer" }}
                              >
                                Submit
                              </button>
                              <button
                                type="button"
                                class="btn-cancel"
                                onClick={closeModal}
                                style={{ cursor: "pointer" }}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </Modal>
          
                    </div>):(
                      ""
                    )
                  )
                )
            )
          )}
          {/* ================================ */}
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden></div>
    </div>
  );
}
