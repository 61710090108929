import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Link } from "react-router-dom";
import LeftMenu from "../layout/leftMenu";
import GooglePostimage from "../images/left_icons/Google-Post.png";
import DragDropFile from "../home/dragDropFile";
import DataInput from "../home/dataInput";
import cloudImg from "../images/cloud-computing.png";
import googlePostTemplate from "../docs/googlePostTemplate_excel.xlsx";
import * as XLSX from "xlsx";
import $ from "jquery";
import * as Icon from "react-feather";
import AWS from "aws-sdk";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { backendApi, apiCallingPost } from "../apiService";
import moment from "moment";
import PostEvents from "./googlePostEvent";
import PostOffers from "./googlePostOffer";
import GooglePostWhatsnew from "./googlePostWhatsnew";
import PostCovid19Updates from "./googlePostCovid19-updates";
import EventPostDetails from "./eventPost";
import OfferPostDetails from "./offerPost";
import WatsNewPost from "./watsnewPost";
import Covid19PostDetais from "./covid19Post";
import Offer from "../images/offer.png";
import CovidUpdtaes from "../images/covid-updates.png";
import Event from "../images/event.png";
import WhatsNew from "../images/whats-new.png";

const format = "h:mm a";
var s3Region;
var s3bucketAccessKey;
var s3bucketSecretKey;
var s3Bucket;
var s3BucketMainFolder;
var S3BucketImagesFolder;
var s3;
var hour = [];
var minute = [];
var globalLocationId;
function PostUnderLocation() {
  const s3Region = "us-east-1",
    s3bucketAccessKey = process.env.REACT_APP_TAGTREE_S3_ACCESS_KEY,
    s3bucketSecretKey = process.env.REACT_APP_TAGTREE_S3_SECRET_KEY,
    s3Bucket = "tagtree",
    s3BucketMainFolder = "production",
    S3BucketImagesFolder = "images",
    bucketParams = {
      Bucket: s3Bucket,
    };
  const [locationList, setLocationList] = useState([]);
  const [selectedLocationsArray, setSelectedLocationsArray] = useState([]);
  const [isLoader, setIsLoader] = useState(0);
  const [accountId, setAccountId] = useState(
    window.sessionStorage.getItem("account_id")
  );
  const [siteId, setSiteId] = useState(
    window.sessionStorage.getItem("switchingOrgId") === ""
      ? window.sessionStorage.getItem("organizationId")
      : window.sessionStorage.getItem("switchingOrgId")
  );
  const [s3Credentials, setS3Credentials] = useState([]);
  const [firstDescription, setFirstDescription] = useState(false);
  const [eventTitle, setEventTitle] = useState("");
  const [photos, setPhotos] = useState(false);
  const [addButton, setAddButton] = useState(false);
  const [linkForButton, setLinkForButton] = useState(false);
  const [title, setTitle] = useState(false);
  const [datePickers, setDatePickers] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [description, setDescription] = useState("");
  const [eventType, setEventType] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageName, setImageName] = useState("");
  const [localPostList, setLocalPostList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [fileName, setFileName] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [buttonValue, setButtonValue] = useState("");
  const [buttonURL, setButtonURL] = useState("");
  const [createPostButton, setCreatePostButton] = useState(false);
  const excelMime = [
    "application/vnd.ms-excel",
    "application/msexcel",
    "application/x-msexcel",
    "application/x-ms-excel",
    "application/x-excel",
    "application/x-dos_ms_excel",
    "application/xls",
    "application/x-xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const timeList = [
    "0:00",
    "0:30",
    "1:00",
    "1:30",
    "2:00",
    "2:30",
    "3:00",
    "3:30",
    "4:00",
    "4:30",
    "5:00",
    "5:30",
    "6:00",
    "6:30",
    "7:00",
    "7:30",
    "8:00",
    "8:30",
    "9:00",
    "9:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
  ];
  useEffect(() => {
    getLocationsByAccountID();
    getS3Credentials();
  }, []);

  const getS3Credentials = () => {
    AWS.config.update({
      accessKeyId: s3bucketAccessKey,
      secretAccessKey: s3bucketSecretKey,
      region: s3Region,
    });
    s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: bucketParams,
    });
    console.log("s3", s3);
  };
  const fileUpload = (file) => {
    $(".loder_div").attr("hidden", false);
    // $(".loader").show();
    console.log(file);
    // AWS.config.update({
    //   accessKeyId: s3bucketAccessKey,
    //   secretAccessKey: s3bucketSecretKey,
    //   region: s3Region,
    // });
    // console.log(AWS);
    // var bucketParams = {
    //   Bucket: s3Bucket,
    // };
    // s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    // let s3 = new AWS.S3({
    //   apiVersion: "2006-03-01",
    //   params: bucketParams,
    // });
    let s3BucketMainFolder1 = s3BucketMainFolder + "/" + S3BucketImagesFolder;
    let fileName = uuidv4() + file.name;
    let fullFilePath = s3BucketMainFolder1 + "/" + fileName;
    console.log("fullFilePath", fullFilePath);
    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          alert("There was an error uploading your image: ", err.message);
          $(".loder_div").attr("hidden", true);
        } else if (data) {
          alert("Image Uploaded Successfully");
          $(".loder_div").attr("hidden", true);
          setPhotos(false);
          $("#uploadedImage").attr("hidden", false);
          console.log(data.Location);
          setImageUrl(data.Location);
          setImageName(fileName);
          return;
        }
      }
    );
  };
  const getLocationsByAccountID = () => {
    var apiReq = {
      functionName: "GetLocationsByAccountId",
      accountId: accountId,
      siteId: siteId,
    };
    backendApi(apiReq).then((data) => {
      if (data.success === "1") {
        setLocationList(data.data.locations);
        globalLocationId = data.data.locations.length;
      } else {
        setLocationList([]);
        alert("Something went wrong");
      }
    });
  };
  const handleFile = (files) => {
    if (files) {
      if (!excelMime.includes(files.type)) {
        alert("Please upload valid excel file");
        return false;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        setIsLoader(0);
        // this.setState({
        //   isLoader: 0,
        // });
        /* Parse data */
        const ab = e.target.result;
        const wb = XLSX.read(ab, { type: "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { raw: false, header: 1 });
        let DataList = [];
        let DataListTemp = [];

        let keys = [];
        keys = data[0];
        console.log("keys======", keys);
        let record = {};
        for (let i = 1; i < data.length; i++) {
          let dd = data[i];
          console.log(dd);
          if (dd.length > 0) {
            for (let j = 0; j < dd.length; j++) {
              console.log(dd[j]);
              if (dd[j] === undefined || dd[j] === "") {
                alert(keys[j] + " field in row " + j + " cannot be empty");
                window.location.reload();
                return false;
              } else {
                record[keys[j].trim().split(" ")[0] + "data" + j] =
                  dd[j] === undefined ? "" : dd[j].toString().trim();
              }
            }
            DataList.push(record);

            DataListTemp.push(record);
            record = {};
          }
        }
        /* Update state */
        console.log("DataList====", DataList);
        // setDataList(DataList);
        // setFileName(files.name);
        // setUploaded(true);
        // setIsLoader(1);
        // this.setState(
        //   {
        //     dataList: DataList,
        //     fileName: files.name,
        //     uploaded: true,
        //     isLoader: 1,
        //   },
        //   () => {
        //     if (DataListTemp.length !== DataList.length) {
        //       alert("File contains duplicate record(s)");
        //     }
        //   }
        // );
      };
      reader.readAsArrayBuffer(files);
    }
  };
  const selectLocation = (e, data, id) => {
    if (
      e.target.checked === true &&
      !selectedLocationsArray.includes(data.name.split("/")[1])
    ) {
      setSelectedLocationsArray((object) => [
        ...object,
        data.name.split("/")[1],
      ]);
      setCreatePostButton(true);
    } else if (e.target.checked === false) {
      if (selectedLocationsArray.length !== locationList.filter) {
        $("#selectAllLocations").prop("checked", false);
      }

      let index = selectedLocationsArray.indexOf(data.name.split("/")[1]);
      selectedLocationsArray.splice(index, 1);
      if (selectedLocationsArray.length === 0) {
        setCreatePostButton(false);
      } else {
        setCreatePostButton(true);
      }
    }
  };
  const selectAllLocations = (e) => {
    if (e.target.checked === true) {
      for (let i = 0; i < globalLocationId; i++) {
        $("#selectLocationPost" + i).prop("checked", true);
      }
      if (selectedLocationsArray.length > 0) {
        let filteredArray = locationList.filter(
          (value) => !selectedLocationsArray.includes(value.name.split("/")[1])
        );
        filteredArray.map((obj) => {
          selectedLocationsArray.push(obj.name.split("/")[1]);
        });
      } else {
        locationList.map((obj) => {
          selectedLocationsArray.push(obj.name.split("/")[1]);
        });
      }
      if (selectedLocationsArray.length === 0) {
        setCreatePostButton(false);
      } else {
        setCreatePostButton(true);
      }
    } else {
      for (let i = 0; i < globalLocationId; i++) {
        $("#selectLocationPost" + i).prop("checked", false);
      }
      setSelectedLocationsArray([]);
      setCreatePostButton(false);
    }
    console.log(selectedLocationsArray, "gooooooooooooooooooo");
    var selectAllList = [];
    // let includes = locationList.some(item => selectedLocationsArray.includes(item.name.split("/")[1]))
    // console.log("foundfound", filteredArray);
  };
  const CreactPostPopup = () => {
    $("#postType").val("EVENT");
    $("#google_postpopup").css({
      display: "flex",
    });
    selectPostMode("", "EVENT");
  };
  const ClosePostPopup = () => {
    $("#google_postpopup").css({
      display: "none",
    });
  };
  const selectPostMode = (e, eventType) => {
    // let eventSelect = e.target.value;
    let eventSelect = eventType;
    setEventType(eventSelect);
    if (eventSelect === "1") {
      setFirstDescription(true);
      setAddButton(false);
      setTitle(true);
      setLinkForButton(false);
      setDatePickers(false);
      setPhotos(false);
    } else if (eventSelect === "2") {
      setFirstDescription(true);
      setAddButton(true);
      setTitle(true);
      setLinkForButton(true);
      setDatePickers(true);
      setPhotos(true);
    } else if (eventSelect === "3") {
      setFirstDescription(true);
      setAddButton(true);
      setTitle(true);
      setLinkForButton(true);
      setDatePickers(true);
      setPhotos(true);
    } else if (eventSelect === "EVENT") {
      setFirstDescription(true);
      setAddButton(true);
      setTitle(true);
      setLinkForButton(false);
      setDatePickers(true);
      setPhotos(true);
    } else {
      setFirstDescription(false);
      setAddButton(false);
      setTitle(false);
      setLinkForButton(false);
      setDatePickers(false);
      setPhotos(false);
    }
  };
  const postEvent = () => {
    if (eventTitle === "") {
      alert("Please enter Title");
      $("#EventTitle").focus();
      return false;
    } else if (imageUrl === "") {
      alert("Please Upload an Image");
      $("#imageUpload").focus();
      return false;
    } else if ($("#descriptionField").val() === "") {
      alert("Please enter Description");
      $("#descriptionField").focus();
      return false;
    } else if (startDate === "") {
      alert("Please enter Start Date");
      $("#startDate").focus();
      return false;
    } else if (startTime === "") {
      alert("Please enter Start Time");
      $("#startTime").focus();
      return false;
    } else if (endDate === "") {
      alert("Please enter End Date");
      $("#endDate").focus();
      return false;
    } else if (endTime === "") {
      alert("Please enter End Time");
      $("#endTime").focus();
      return false;
    } else if (endDate === "") {
      alert("Please enter End Date");
      $("#endDate").focus();
      return false;
    } else if (
      $("#callToActionButtons").val() !== "0" &&
      $("#callToActionURL").val() === " "
    ) {
      alert("Enter URL for " + $("#callToActionButtons").val() + " button");
      $("#callToActionURL").focus();
      return false;
    }
    console.log("eventTitle", eventTitle);
    console.log("description", $("#descriptionField").val());
    console.log("startDate", moment(startDate).get("date"));
    console.log("endDate", moment(endDate).get("year"));
    console.log("eventTitle", endTime);
    console.log("eventTitle", startTime.split(":")[0]);
    $(".loder_div").attr("hidden", false);
    var req = {
      title: eventTitle,
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      summary: $("#descriptionField").val(),
      startDate_year: parseInt(moment(startDate).get("year")),
      startDate_month: parseInt(moment(startDate).get("month") + 1),
      startDate_day: parseInt(moment(startDate).get("date")),
      startTime_hours: parseInt(startTime.split(":")[0]),
      startTime_minutes: parseInt(startTime.split(":")[1]),
      endDate_year: parseInt(moment(endDate).get("year")),
      endDate_month: parseInt(moment(endDate).get("month") + 1),
      endDate_day: parseInt(moment(endDate).get("date")),
      endTime_hours: parseInt(endTime.split(":")[0]),
      endTime_minutes: parseInt(endTime.split(":")[1]),
      bannerImages: imageUrl,
      eventType: eventType,
      functionName: "PostEvent",
    };
    console.log("request", req);
    apiCallingPost(req).then((data) => {
      if (data.success === "1") {
        alert("Posted Successfully!!!");
        ClosePostPopup();
        $(".loder_div").attr("hidden", true);
        window.location.reload();
      } else {
        data.errorMessage
          ? alert(data.errorMessage)
          : alert("Something went wrong");
      }
    });
  };
  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  const changeDescriptionField = (event) => {
    if (event.target.value.length < 1500) {
      setDescriptionLength(event.target.value.length);
      setDescription(
        event.target.value !== ""
          ? event.target.value
          : $("#descriptionField").val()
      );
    } else {
      alert("Description cannot have more than 1500 characters");
    }
  };
  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Create new Post" headerImage={GooglePostimage} />
        <div className="user_listdiv">
          <div className="file_upload_outer">
            <DragDropFile handleFile={handleFile}>
              <div className="form-group" id="form-fileupload">
                <img src={cloudImg} alt="cloud" width={70} />
                <div className="form-control border-0">
                  <DataInput handleFile={handleFile} />
                </div>
              </div>
            </DragDropFile>
            {/* {uploaded === true && OutTable()} */}
          </div>
          <div className="back_tocatalog_main">
            <Link to="/googlepost/index" className="back-to-link">
              <Icon.ArrowLeft />
              Back to Post(s) List
            </Link>

            {/* <div className="refresh-icon">
              <Icon.RefreshCcw
                onClick={() => {
                  window.location.reload();
                }}
                title="Refresh"
              />
            </div> */}
          </div>
          <div className="google_postdiv">
            <div className="google_postleft">
              <div className="google_post_table table-responsive">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          name=""
                          id="selectAllLocations"
                          onClick={(e) => selectAllLocations(e)}
                        />
                      </th>
                      <th>Si.no</th>
                      <th>Title</th>
                      <th>Address</th>
                    </tr>
                  </thead>
                  {locationList.length > 0 ? (
                    <tbody>
                      {locationList.map((obj, i) => {
                        return (
                          <tr key={i + 1}>
                            <td>
                              <input
                                type="checkbox"
                                id={"selectLocationPost" + i}
                                onClick={(e) => selectLocation(e, obj, i)}
                              />
                            </td>
                            <td>{i + 1}</td>
                            <td>{obj.title}</td>
                            <td>
                              <span>
                                {obj.storefrontAddress.addressLines.join(",")}
                                <br />
                                {obj.storefrontAddress.locality},
                                {obj.storefrontAddress.administrativeArea},
                                {obj.storefrontAddress.postalCode}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    ""
                  )}
                </table>
              </div>
            </div>
            {createPostButton === true && (
              <div className="google_postright">
                <div className="create_popst_btn">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      CreactPostPopup();
                    }}
                  >
                    Create Post
                  </button>
                </div>
              </div>
            )}

            <div className="google_postright">
              <div className="create_popst_btn">
                <a href={googlePostTemplate} className="btn btn-secondary">
                  Download Template
                </a>
              </div>
            </div>
          </div>
          <div className="googleEvents">
            <div className="cardEvents">
              <img src={Event} alt="Event" />
              Event
            </div>
            <div className="cardEvents">
              <img src={Offer} alt="Offer" />
              Offer
            </div>
            <div className="cardEvents">
              <img src={WhatsNew} alt="WhatsNew" />
              What's New
            </div>
            <div className="cardEvents">
              <img src={CovidUpdtaes} alt="CovidUpdtaes" />
              Covid 19 Updates
            </div>
            {/* <PostEvents /> */}
            {/* <PostOffers /> */}
            {/* <GooglePostWhatsnew /> */}
            {/* <PostCovid19Updates /> */}
          </div>
          {/* <div className="postDetails">
          <div className="">
          
          <div className="google_popupbody">
            <div className="left_google_popup">
              <div className="left_inner_google_pop">
                <div className="form_group_google"></div>
                <div className="form_group_google">
                  <label>Post Details</label>
                  <textarea className="form-control" cols="30" rows="5"></textarea>
                </div>
                {photos === true ? (
                  <div className="right_google_popup">
                    <label>
                      <Icon.Camera />
                      Add photos
                      <input
                        type="file"
                        id="imageUpload"
                        onChange={(e) => fileUpload(e.target.files[0])}
                      />
                    </label>
                  </div>
                ) : (
                  ""
                )}
                <div id="uploadedImage" className="imageUploaded" hidden>
                  <img src={imageUrl} alt="" className="imageUploaded_img" />
                  <span className="close_popup_imageUpload">
                    <Icon.X
                      onClick={() => {
                        $("#uploadedImage").attr("hidden", true);
                        setImageUrl("");
                        setImageName("");
                        setPhotos(true);
                      }}
                    />
                  </span>
                </div>
                <div className="form_group_google">
                  {title === true ? (
                    <div className="form_group_google linke_span_example">
                      <input
                        type="text"
                        id="EventTitle"
                        onChange={(e) => setEventTitle(e.target.value)}
                        className="form-control"
                        placeholder="Enter your Title"
                      />
                      <span>(Example: Halloween)</span>
                    </div>
                  ) : (
                    ""
                  )}
                  {firstDescription === true ? (
                    <>
                      <textarea
                        rows="4"
                        id="descriptionField"
                        className="form-control"
                        placeholder="Add a description"
                        value={description}
                        onChange={(obj) => changeDescriptionField(obj)}
                      ></textarea>
                      <div className="form_google_span">
                        <span> {descriptionLength}/ 1500</span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>

                {datePickers === true ? (
                  <>
                    <span className="info_locationspan postLocation">
                      <div className="did-floating-label-content">
                        <ReactDatePicker
                          id="startDate"
                          selected={startDate}
                          onChange={(startDate) => setStartDate(startDate)}
                          minDate={new Date()}
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          placeholderText="Enter Event Start Date"
                          disabled={false}
                          highlightToday
                          showYearDropdown
                        />
                        <label className="did-floating-label">
                          Event Start Date
                        </label>
                      </div>
                      <div className="did-floating-label-content">
                        <select
                          id="datepickerEndtime"
                          className="did-floating-select"
                          onChange={(e) => setEndTime(e.target.value)}
                        >
                          {timeList.map((data) => (
                            <option value={data}>{data}</option>
                          ))}
                        </select>
                        <label class="did-floating-label">Time</label>
                      </div>
                    </span>
                    <span className="info_locationspan postLocation">
                      <div className="did-floating-label-content">
                        <ReactDatePicker
                          id="endDate"
                          selected={endDate}
                          onChange={(endDate) => setEndDate(endDate)}
                          minDate={new Date()}
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          placeholderText="Enter Event End Date"
                          disabled={false}
                          highlightToday
                          showYearDropdown
                        />
                        <label className="did-floating-label">
                          Event End Date
                        </label>
                      </div>
                      <div className="did-floating-label-content">
                        <select
                          id="datepickerEndtime"
                          className="did-floating-select"
                          onChange={(e) => setStartTime(e.target.value)}
                        >
                          {timeList.map((data) => (
                            <option value={data}>{data}</option>
                          ))}
                        </select>
                        <label class="did-floating-label">Time</label>
                      </div>
                    </span>
                  </>
                ) : (
                  ""
                )}
                {addButton === true ? (
                  <div className="form_group_google">
                    <label>Add a button (optional)</label>
                    <select
                      id="callToActionButtons"
                      className="form-control"
                      onChange={(obj) => {
                        obj.target.value !== "0"
                          ? setLinkForButton(true)
                          : setLinkForButton(false);
                      }}
                    >
                      <option value="0">Select</option>
                      <option value="BOOK">Book</option>
                      <option value="ORDER">Order Online</option>
                      <option value="SHOP">Buy</option>
                      <option value="LEARN_MORE">Learn More</option>
                      <option value="SIGN_UP">Sign Up</option>
                      <option value="CALL">Call Now</option>
                    </select>
                  </div>
                ) : (
                  ""
                )}
                {linkForButton === true ? (
                  <div className="form_group_google linke_span_example">
                    <input
                      type="text"
                      className="form-control"
                      id="callToActionURL"
                      placeholder="Link for your button"
                    />
                    <span>(Example: google.co.uk)</span>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="right_google_popup">
                    <label>
                      <Icon.Camera />
                      Add photos
                      <input
                        type="file"
                        id="imageUpload"
                        onChange={(e) => fileUpload(e.target.files[0])}
                      />
                    </label>
                  </div>
                  <div id="uploadedImage" className="imageUploaded" hidden>
                  <img src={imageUrl} alt="" className="imageUploaded_img" />
                  <span className="close_popup_imageUpload">
                    <Icon.X
                      onClick={() => {
                        $("#uploadedImage").attr("hidden", true);
                        setImageUrl("");
                        setImageName("");
                        setPhotos(true);
                      }}
                    />
                  </span>
                </div>
            </div>
            <div className="google_post_footer">
              <button type="button" className="btn btn-light">
                Preview
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => postEvent()}
              >
                Post
              </button>
            </div>
          </div>
        </div>
            
          </div> */}
          <EventPostDetails />
          {/* <OfferPostDetails/>
          <WatsNewPost/>
          <Covid19PostDetais/> */}
        </div>
      </div>
      <div className="google_postpopup" id="google_postpopup">
        <div className="inner_google_postpopup">
          <div className="reply_popup_head d-flex align-items-center">
            <span className="popup_reply_head">
              <Icon.ArrowLeft className="mr-2" />
              Add Post
            </span>
            <span className="ml-auto close_popup_review">
              <Icon.X
                onClick={() => {
                  ClosePostPopup();
                }}
              />
            </span>
          </div>
          <div className="google_popupbody">
            <div className="left_google_popup">
              <div className="left_inner_google_pop">
                <div className="form_group_google"></div>
                <div className="form_group_google">
                  <label>Select Post Type</label>
                  <select
                    className="form-control"
                    id="postType"
                    onChange={(e) => selectPostMode(e)}
                  >
                    <option value="0">Select</option>
                    <option value="EVENT">Event</option>
                  </select>
                </div>
                {photos === true ? (
                  <div className="right_google_popup">
                    <label>
                      <Icon.Camera />
                      Add photos
                      <input
                        type="file"
                        id="imageUpload"
                        onChange={(e) => fileUpload(e.target.files[0])}
                      />
                    </label>
                  </div>
                ) : (
                  ""
                )}
                <div id="uploadedImage" className="imageUploaded" hidden>
                  <img src={imageUrl} alt="" className="imageUploaded_img" />
                  <span className="close_popup_imageUpload">
                    <Icon.X
                      onClick={() => {
                        $("#uploadedImage").attr("hidden", true);
                        setImageUrl("");
                        setImageName("");
                        setPhotos(true);
                      }}
                    />
                  </span>
                </div>
                <div className="form_group_google">
                  {title === true ? (
                    <div className="form_group_google linke_span_example">
                      <input
                        type="text"
                        id="EventTitle"
                        onChange={(e) => setEventTitle(e.target.value)}
                        className="form-control"
                        placeholder="Enter your Title"
                      />
                      <span>(Example: Halloween)</span>
                    </div>
                  ) : (
                    ""
                  )}
                  {firstDescription === true ? (
                    <>
                      <textarea
                        rows="4"
                        id="descriptionField"
                        className="form-control"
                        placeholder="Add a description"
                        value={description}
                        onChange={(obj) => changeDescriptionField(obj)}
                      ></textarea>
                      <div className="form_google_span">
                        <span> {descriptionLength}/ 1500</span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>

                {datePickers === true ? (
                  <>
                    <span className="info_locationspan postLocation">
                      <div className="did-floating-label-content">
                        <ReactDatePicker
                          id="startDate"
                          selected={startDate}
                          onChange={(startDate) => setStartDate(startDate)}
                          minDate={new Date()}
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          placeholderText="Enter Event Start Date"
                          disabled={false}
                          highlightToday
                          showYearDropdown
                        />
                        <label className="did-floating-label">
                          Event Start Date
                        </label>
                      </div>
                      <div className="did-floating-label-content">
                        <select
                          id="datepickerEndtime"
                          className="did-floating-select"
                          onChange={(e) => setEndTime(e.target.value)}
                        >
                          {timeList.map((data) => (
                            <option value={data}>{data}</option>
                          ))}
                        </select>
                        <label class="did-floating-label">Time</label>
                      </div>
                    </span>
                    <span className="info_locationspan postLocation">
                      <div className="did-floating-label-content">
                        <ReactDatePicker
                          id="endDate"
                          selected={endDate}
                          onChange={(endDate) => setEndDate(endDate)}
                          minDate={new Date()}
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          placeholderText="Enter Event End Date"
                          disabled={false}
                          highlightToday
                          showYearDropdown
                        />
                        <label className="did-floating-label">
                          Event End Date
                        </label>
                      </div>
                      <div className="did-floating-label-content">
                        <select
                          id="datepickerEndtime"
                          className="did-floating-select"
                          onChange={(e) => setStartTime(e.target.value)}
                        >
                          {timeList.map((data) => (
                            <option value={data}>{data}</option>
                          ))}
                        </select>
                        <label class="did-floating-label">Time</label>
                      </div>
                    </span>
                    <span className="info_locationspan postLocation">
                      <div className="did-floating-label-content">
                        <ReactDatePicker
                          id="startDate"
                          selected={startDate}
                          onChange={(startDate) => setStartDate(startDate)}
                          minDate={new Date()}
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          //   placeholderText="Enter Event Start Date"
                          disabled={false}
                          highlightToday
                          showYearDropdown
                        />
                        <label className="did-floating-label">
                          Event Start Date
                        </label>
                      </div>
                      <div className="did-floating-label-content">
                        <select
                          id="datepickerEndtime"
                          className="did-floating-select"
                          onChange={(e) => setEndTime(e.target.value)}
                        >
                          <option value="0">Select</option>
                          {timeList.map((data) => (
                            <option value={data}>{data}</option>
                          ))}
                        </select>
                        <label class="did-floating-label">Time</label>
                      </div>
                    </span>
                    <span className="info_locationspan postLocation">
                      <div className="did-floating-label-content">
                        <ReactDatePicker
                          id="endDate"
                          selected={endDate}
                          onChange={(endDate) => setEndDate(endDate)}
                          minDate={new Date()}
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          //   placeholderText="Enter Event End Date"
                          disabled={false}
                          highlightToday
                          showYearDropdown
                        />
                        <label className="did-floating-label">
                          Event End Date
                        </label>
                      </div>
                      <div className="did-floating-label-content">
                        <select
                          id="datepickerEndtime"
                          className="did-floating-select"
                          onChange={(e) => setStartTime(e.target.value)}
                        >
                          <option value="0">Select</option>
                          {timeList.map((data) => (
                            <option value={data}>{data}</option>
                          ))}
                        </select>
                        <label class="did-floating-label">Time</label>
                      </div>
                    </span>
                    {/* <input
                      type="time"
                      id="startTime"
                      className="form-control"
                      onChange={(e) => setStartTime(e.target.value)}
                    /> */}

                    {/* <input
                      type="time"
                      id="endTime"
                      className="form-control"
                      onChange={(e) => setEndTime(e.target.value)}
                    /> */}
                  </>
                ) : (
                  ""
                )}
                {/* {addButton === true ? (
                  <div className="form_group_google">
                    <label>Add a button (optional)</label>
                    <select
                      id="callToActionButtons"
                      className="form-control"
                      //   onChange={(obj) => {
                      //     obj.target.value !== "0"
                      //       ? () => {
                      //           setLinkForButton(true);
                      //           setButtonValue(obj.target.value);
                      //         }
                      //       : setLinkForButton(false);
                      //     setButtonValue(obj.target.value);
                      //   }}
                      onChange={(obj) => {
                        setLinkForButton(
                          obj.target.value !== "0" ? true : false
                        );
                        setButtonValue(
                          obj.target.value !== "0" ? obj.target.value : ""
                        );
                      }}
                    >
                      <option value="0">Select</option>
                      <option value="BOOK">Book</option>
                      <option value="ORDER">Order Online</option>
                      <option value="SHOP">Buy</option>
                      <option value="LEARN_MORE">Learn More</option>
                      <option value="SIGN_UP">Sign Up</option>
                      <option value="CALL">Call Now</option>
                    </select>
                  </div>
                ) : (
                  ""
                )}
                {linkForButton === true ? (
                  <div className="form_group_google linke_span_example">
                    <input
                      type="text"
                      className="form-control"
                      id="callToActionURL"
                      placeholder="Link for your button"
                      onChange={(obj) => {
                        setButtonURL(obj.target.value);
                      }}
                    />
                    <span>(Example: google.co.uk)</span>
                  </div>
                ) : (
                  ""
                )} */}
              </div>
            </div>
            <div className="google_post_footer">
              <button type="button" className="btn btn-light">
                Preview
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => postEvent()}
              >
                Post
              </button>
            </div>
          </div>
        </div>
        <div className="loder_div" hidden />
      </div>
      <div className="google_postpopup" id="google_postpopup">
        <div className="inner_google_postpopup">
          <div className="reply_popup_head d-flex align-items-center">
            <span className="popup_reply_head">
              <Icon.ArrowLeft className="mr-2" />
              Add Post
            </span>
            <span className="ml-auto close_popup_review">
              <Icon.X
                onClick={() => {
                  ClosePostPopup();
                }}
              />
            </span>
          </div>
        </div>
        <div className="loder_div" hidden />
        <Footer />
      </div>
    </div>
  );
}

export default PostUnderLocation;
