import React, { Component } from "react";
import * as Icon from "react-feather";
import LeftMenu from "../layout/leftMenu";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import "../css/bootstrap.css";
import "../css/style.css";
import LocalPagesimage from "../images/left_icons/Local-Page.png";
import ModuleList from "../admin/module_list";
import $ from "jquery";
import Samle01 from "../images/images1.jpg";
import { backendApi } from "../apiService";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localPageLink: "",
      localCMSLink: "",
    };
  }
  componentDidMount() {
    this.getPageLinks();
  }
  getPageLinks() {
    var apidata = {
      functionName:"getCMSLinks",
      orgName: window.sessionStorage.getItem("organization"),
    };
    backendApi( apidata).then((data) => {
      if (data.success == "1") {
        this.setState({
          localPageLink: data.result[0].local_page_cms_link,
          localCMSLink: data.result[0].local_page_link,
        });
      } else {
        alert("something went wrong");
      }
    });
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper">
          <Header heading="Local Pages" headerImage={LocalPagesimage} />
          <div className="user_listdiv user_listedit">
            <div className="tagtree_boxdesign local_index container-fluid">
              <div className="row">
                <div className="col-xl-6 col-md-12">
                  <div className="card border_info">
                    <div className="card-body">
                      <div className="tagtreecard_img">
                        <img src={Samle01} />
                      </div>
                      <div className="tagtreecard_right">
                        <h5>Local Pages (Webpage)</h5>
                        <div className="tagtree_right_go_main">
                          <button className="btn btn-primary tagtree_right_go">
                            <a href={this.state.localPageLink} >
                              Show <Icon.ArrowRight />
                            </a>
                          </button>
                        </div>
                        <ul className="tagtree_boxcircle">
                          <li>
                            Lorem Ipsum is simply dummy text of the printing and
                          </li>
                          <li>
                            typesetting industry. Lorem Ipsum has been the
                          </li>
                        </ul>
                        <Link 
                        
                        to={{ pathname: "/local/Localpages" }}>
                          Forward to local pages...
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-md-12">
                  <div className="card border_danger">
                    <div className="card-body">
                      <div className="tagtreecard_img">
                        <img src={Samle01} />
                      </div>
                      <div className="tagtreecard_right">
                        <h5>Local CMS Page</h5>
                        <div className="tagtree_right_go_main">
                          <button className="btn btn-primary tagtree_right_go">
                            <a href={this.state.localCMSLink }>
                              Go <Icon.ArrowRight />
                            </a>
                          </button>
                        </div>
                        <ul className="tagtree_boxcircle">
                          <li>
                            Lorem Ipsum is simply dummy text of the printing and
                          </li>
                          <li>
                            typesetting industry. Lorem Ipsum has been the
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default Index;
