import React from "react";
import Dashboardimage from "../images/left_icons/dashboard.png";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import * as Icon from "react-feather";
import { BsBorderBottom } from "react-icons/bs";
function FundAllocation() {
  return (
    <div className="main_wrapper dsh-wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Fund Allocation" headerImage={Dashboardimage} />
        <div className="container-fluid">
          <div className="organisation-div organization-card user-creation">
            {/* <div class="subhead">Budget Allocation</div> */}
            <div className="col-lg-5 col-md-8 col-12 tableResponsive m-0">
              <div className="tableOuterWidth table-width-change">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th className="col-3">Sl. No.</th>
                      <th className="col-3">Allocation Date</th>
                      <th className="col-3">Amount</th>
                      <th className="col-3">Action</th>
                    </tr>
                  </thead>
                  <div class="table_Outerscroll">
                    <tbody className="table_Outertbody">
                      <tr>
                        <td className="col-3">
                          <p>1</p>
                        </td>
                        <td className="col-3">
                          <p>01 Jan 2024</p>
                        </td>
                        <td className="col-3">
                          <p>1000 </p>
                        </td>
                        <td className="col-3">
                          <p>Edit </p>
                        </td>
                      </tr>
                    </tbody>
                    <tbody className="table_Outertbody">
                      <tr>
                        <td className="col-3">
                          <p>1</p>
                        </td>
                        <td className="col-3">
                          <p>01 Jan 2024</p>
                        </td>
                        <td className="col-3">
                          <p>1000 </p>
                        </td>
                        <td className="col-3">
                          <p>Edit </p>
                        </td>
                      </tr>
                    </tbody>
                    <tbody className="table_Outertbody">
                      <tr>
                        <td className="col-3">
                          <p>1</p>
                        </td>
                        <td className="col-3">
                          <p>01 Jan 2024</p>
                        </td>
                        <td className="col-3">
                          <p>1000 </p>
                        </td>
                        <td className="col-3">
                          <p>Edit </p>
                        </td>
                      </tr>
                    </tbody>
                    <tbody className="table_Outertbody">
                      <tr>
                        <td className="col-3">
                          <p>1</p>
                        </td>
                        <td className="col-3">
                          <p>01 Jan 2024</p>
                        </td>
                        <td className="col-3">
                          <p>1000 </p>
                        </td>
                        <td className="col-3">
                          <p>Edit </p>
                        </td>
                      </tr>
                    </tbody>
                  </div>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
}
export default FundAllocation;
