import React, { useEffect } from "react";

import { useState } from "react";
import { useLocation } from "react-router-dom";
import { backendApi, re } from "../../../apiService";
import * as Icon from "react-feather";

function VerifyListingComponent(props) {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [Title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [description, setdescription] = useState("");
  const [phone, setPhone] = useState("");
  const [adminstriveArea, setadminstriveArea] = useState("");
  const [locality, setlocality] = useState("");
  const [address, setaddress] = useState("");
  const [postalCode, setpostalCode] = useState("");
  const [method, setMethod] = useState("");
  const [inputDetails, setInputDetails] = useState("");
  const [errorDetailsList, setErrorDetailsList] = useState([]);
  //   const [websiteUrl, setwebsiteUrl] = useState("");
  //   const [Verificationmethod, setVerificationmethod] = useState("");
  //   const [VerificationCodeEmail, setVerificationCodeEmail] = useState("");
  //   const [verificationRole, setverificationRole] = useState("");
  //   const [VerificationmethodPhoneNumber, setVerificationmethodPhoneNumber] =
  //     useState("");
  //   const [VerificationCode, setVerificationCode] = useState("");
  //   const [currentPassword, setCurrentPassword] = useState("");
  //   const [newPassword, setNewPassword] = useState("");
  //   const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const closeHandler = (e) => {
    setShow(false);
    setMethod("");
    setInputDetails("");
    props.onClose(false, true);
  };

  useEffect(() => {
    setShow(props.show);
    setTitle(props.props.full_name);
    setFirstName(props.props.full_name);
    setdescription(
      window.sessionStorage.getItem("switchUserOrganization") !== ""
        ? window.sessionStorage.getItem("switchUserOrganization")
        : window.sessionStorage.getItem("organization")
    );
    setPhone(props.props.mobile_no);
    setadminstriveArea(props.props.administrativeArea);
    setlocality(props.props.locality);
    setaddress(props.props.address);
    setpostalCode(props.props.postalCode);
    console.log("loooog", props);
  }, [props.show]);
  const saveLocation = (e) => {
    e.preventDefault();
    const apiReq = {
      functionName: "updatePushStatus",
      location_id: "",
      g_pin: "",
      status: "Push Initiated",
      id: props.props.id,
      full_name: Title,
      address: address,
      mobile_no: phone.replace("+91", ""),
      email_id: "",
      locality: locality,
      administrativeArea: adminstriveArea,
      postalCode: postalCode,
      verification_code: "",
      errorMessage: "",
    };
    props.updateData(apiReq);
    closeHandler();
  };
  var phNum = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const startVerification = () => {
    if (method === "") {
      alert("Please Choose A Method First");
      return false;
    } else if (inputDetails === "") {
      if (method == "SMS" || method == "PHONE_CALL")
        alert("Please Enter a Phone Number to verify the location");
      else if (method == "EMAIL")
        alert("Please enter an Email Id to verify the location");
      return false;
    } else if (method == "EMAIL" && inputDetails == "") {
      alert("Enter valid Email-Id");
      return false;
    } else if (
      (method == "SMS" || method == "PHONE_CALL") &&
      !phNum.test(inputDetails)
    ) {
      alert("Enter valid Phone Number");
      return false;
    } else {
      props.setMethod(method);
      const apiReq = {
        functionName: "createNewLocationVerify",
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        method: method,
        ...(method === "EMAIL" && {
          emailAddress: inputDetails,
        }),
        ...(method === "PHONE_CALL" && {
          phoneNumber: "+91" + inputDetails,
        }),
        ...(method === "SMS" && {
          phoneNumber: "+91" + inputDetails,
        }),
        locationid: props.props.location_id,
      };
      backendApi(apiReq).then(async (result) => {
        if (result.success === "1") {
          const apiReq = {
            functionName: "updatePushStatus",
            location_id: props.props.location_id,
            g_pin: "",
            status: "Verification Started",
            id: props.props.id,
            full_name: props.props.full_name,
            address: props.props.address,
            mobile_no: inputDetails,
            email_id: method === "EMAIL" ? inputDetails : "",
            locality: props.props.locality,
            category: props.props.category,
            administrativeArea: props.props.administrativeArea,
            postalCode: props.props.postalCode,
            verification_code: result.data.verification.name.split("/").pop(),
            storeCode: props.props.storeCode,
            errorMessage: "",
          };
          // alert(result.data.verification.name.split("/").pop());
          //   this.updatePushStatus(apiReq);
          props.setVerificationMethod(
            result.data.verification.name.split("/").pop()
          );
          props.updateData(apiReq);
          closeHandler();
        } else {
          // alert(props.props.full_name + "-" + result.data.error.message);
          const apiReq = {
            functionName: "updatePushStatus",
            location_id: props.props.location_id,
            g_pin: "",
            status: "Verification Failed",
            id: props.props.id,
            full_name: props.props.full_name,
            address: props.props.address,
            mobile_no: props.props.mobile_no,
            email_id: props.props.email_id,
            locality: props.props.locality,
            category: props.props.category,
            administrativeArea: props.props.administrativeArea,
            postalCode: props.props.postalCode,
            storeCode: props.props.storeCode,
            verification_code: "",
            errorMessage: "",
          };
          if (
            result.data.error.code === 400 &&
            result.data.error.details.length > 0
          ) {
            const errorArray = result.data.error.details.map((errorMap) => {
              return errorMap.fieldViolations[0].description;
            });
            // setErrorDetailsList(errorArray);
            props.errorData((obj) => [
              ...obj,
              {
                id: props.props.id,
                errors: errorArray,
              },
            ]);
          }
          //   this.updatePushStatus(apiReq);
          props.updateData(apiReq);
          props.onClose(false, false);
        }
      });
    }
  };
  return (
    <div
      className="headerPopup"
      id="verificationCode"
      hidden={show ? false : true}
    >
      <div className="module_popup">
        <div className="module_innerpopup">
          <div className="module_close">
            <Icon.XCircle
              onClick={() => props.retryVerification(false, false)}
            />
          </div>
          <div className="module_header text-center">Verification</div>
          {/* <button
            type="button"
            className="btn btn-save mb-1 gobutton_position"
            onClick={() => props.retryVerification(false, false)}
          >
            X
          </button> */}
          <div className="module_body">
            <div className="module_popupsearch_wrpr">
              <div className="module_popupsearch">
                <div className="container">
                  <div className="row userrow_form" id="gbpEmail">
                    <div className="col-md-4 d-flex align-items-center ">
                      <label className="col-form-label">Choose Method</label>
                    </div>
                    <div className="col-md-8">
                      <select
                        id="organisation_name"
                        className="did-floating-select form-control"
                        onChange={(e) => {
                          setMethod(e.target.value);
                          if (
                            e.target.value == "SMS" ||
                            e.target.value == "PHONE_CALL"
                          ) {
                            setInputDetails(props.props.mobile_no);
                          } else {
                            setInputDetails("");
                          }
                        }}
                        value={method}
                      >
                        <option value="">Please Choose a Method</option>
                        <option value="SMS">Message</option>
                        <option value="PHONE_CALL">Phone</option>
                        <option value="EMAIL">Email</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-4 d-flex align-items-center ">
                      <label className="col-form-label" id="methodType">
                        {method === "SMS" || method === "PHONE_CALL" ? (
                          <>Phone Number</>
                        ) : method === "EMAIL" ? (
                          <>Email Id</>
                        ) : (
                          ""
                        )}{" "}
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        // onChange={(e) =>
                        //   this.setState({
                        //     inputDetails: e.target.value,
                        //   })
                        // }
                        // type="password"
                        id="inputDetails"
                        value={inputDetails}
                        onChange={(e) => setInputDetails(e.target.value)}
                        className="form-control"
                        // placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="w-100 text-right goButton" id="confirm">
                    <button
                      type="button"
                      className="btn btn-save mb-1 verificationBtn"
                      onClick={() => startVerification()}
                    >
                      Start Verification
                    </button>
                  </div>
                  {/* <div
                    className="col-md-3 col-lg-2 p-0 goButton"
                    id="goBackPopUp"
                    hidden
                  >
                    <button
                      type="button"
                      className="btn btn-save mb-1"
                      onClick={() => {
                        $("#gbpPassword").attr("hidden", true);
                        $("#gbpEmail").attr("hidden", false);
                        $("#Go").attr("hidden", false);
                        $("#confirm").attr("hidden", true);
                        $("#goBackPopUp").attr("hidden", true);
                      }}
                    >
                      Back
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyListingComponent;
