import React, { Component } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import * as Icon from "react-feather";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import $ from "jquery";
import {
  FaFacebookF,
  FaInstagram,
  FaTumblr,
  FaPinterestP,
  FaLinkedinIn,
  FaYoutube,
  FaSearch,
} from "react-icons/fa";
import Img01 from "../images/social01.PNG";
import Img02 from "../images/social02.PNG";
import Img03 from "../images/icon_bg.jpg";
class SocialMedia extends Component {
  // componentDidMount(){
  //   var $doc=$(document);
  // var $win=$(window);
  // var itemstoshow=5;

  // $('.inner_socialdiv').filter(function(index){
  // 	return (($(this).offset().top) > $win.height());
  // }).hide();

  // $(window).scroll(function(){
  //     if ($doc.height()-$win.height()-$(this).scrollTop() == 0) {
  // $('.inner_socialdiv:hidden:lt('+itemstoshow+')').show();
  //     }
  // });
  // }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper">
          <Header />

          <div className="social_headertab">
            <ul className="nav">
              <li className="nav-item">
                <span>All</span>
                <div className="social_datavalue">15</div>
              </li>
              <li className="nav-item">
                <span>
                  <FaFacebookF />
                </span>
                <div className="social_datavalue">15</div>
              </li>
              <li className="nav-item">
                <span>
                  <FaTumblr />
                </span>
                <div className="social_datavalue">15</div>
              </li>
              <li className="nav-item">
                <span>
                  <FaInstagram />
                </span>
                <div className="social_datavalue">15</div>
              </li>
              <li className="nav-item">
                <span>
                  <FaPinterestP />
                </span>
                <div className="social_datavalue">15</div>
              </li>
              <li className="nav-item">
                <span>
                  <FaLinkedinIn />
                </span>
                <div className="social_datavalue">15</div>
              </li>
              <li className="nav-item">
                <span>
                  <FaYoutube />
                </span>
                <div className="social_datavalue">15</div>
              </li>
              <li className="nav-item searech_social">
                <input type="text" placeholder="search" />
                <FaSearch />
              </li>
            </ul>
          </div>

          <ResponsiveMasonry
            className="user_listdiv"
            columnsCountBreakPoints={{
              400: 1,
              500: 2,
              768: 3,
              991: 3,
              1024: 4,
            }}
          >
            <Masonry className="inner_masonry_div" gutter={10}>
              <>
                <div className="inner_socialdiv">
                  <div className="social_mediacontnt">
                    .<a href="#">@DukeMedSchool</a>
                    scientists have identified a previously unknown protective
                    barrier for the smell-sensing cells of the nose. However,
                    this barrier may be hindering the effectiveness of vaccines
                    <a href="#">duke.is/bsmya</a>
                  </div>
                  <div className="social_mediamiddle">
                    <div className="social_imglink">
                      <span>
                        <img src={Img03} />
                        <FaInstagram />
                      </span>
                      <div className="img_mainwithlink">
                        <a rel="nofollow" href="" className="main_link_socila">
                          Duke University
                        </a>

                        <div className="img_withlink">
                          <a rel="nofollow" href="#" className="ff-nickname">
                            dukestudents
                          </a>
                          <a rel="nofollow" href="#" className="ff-timestamp">
                            Oct 30, 2018
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="social_footer">
                    <ul className="nav">
                      <li>
                        <a href="#">
                          <Icon.Heart />
                          <span>2k</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <Icon.MessageCircle />
                          <span>5</span>
                        </a>
                      </li>
                    </ul>
                    <ul className="nav social_rightdiv">
                      <li>
                        <a href="#">
                          <Icon.Share />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </>
              {(() => {
                const arr = [];
                for (let i = 0; i < 10; i++) {
                  arr.push(
                    <div className="inner_socialdiv" key={i}>
                      <div className="social_mediacontnt">
                        .<a href="#">@DukeMedSchool</a>
                        scientists have identified a previously unknown
                        protective barrier for the smell-sensing cells of the
                        nose. However, this barrier may be hindering the
                        effectiveness of vaccines
                        <a href="#">duke.is/bsmya</a>
                      </div>
                      <div className="social_mediamiddleimg">
                        <img src={Img02} />
                      </div>
                      <div className="social_mediamiddle">
                        <div className="social_imglink">
                          <span>
                            <img src={Img03} />
                            <FaInstagram />
                          </span>
                          <div className="img_mainwithlink">
                            <a
                              rel="nofollow"
                              href=""
                              className="main_link_socila"
                            >
                              Duke University
                            </a>

                            <div className="img_withlink">
                              <a
                                rel="nofollow"
                                href="#"
                                className="ff-nickname"
                              >
                                dukestudents
                              </a>
                              <a
                                rel="nofollow"
                                href="#"
                                className="ff-timestamp"
                              >
                                Oct 30, 2018
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="social_footer">
                        <ul className="nav">
                          <li>
                            <a href="#">
                              <Icon.Heart />
                              <span>2k</span>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <Icon.MessageCircle />
                              <span>5</span>
                            </a>
                          </li>
                        </ul>
                        <ul className="nav social_rightdiv">
                          <li>
                            <a href="#">
                              <Icon.Share />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                }
                return arr;
              })()}
            </Masonry>
          </ResponsiveMasonry>
          <div className="social_loadmorebtn">
            <button type="button">Show more</button>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default SocialMedia;
