import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as Icon from "react-feather";
import dataProcessImg from "../images/dataProcess.gif";
import Accessibiltyimage from "../images/left_icons/Accessibility-and-visibility.png";
import { Link } from "react-router-dom";
import { backendApi, pythonBackendApi } from "../apiService";
import Header from "../layout/header";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu";
import $ from "jquery";
const VisibilityDetails = () => {
  const location = useLocation();
  let pathname = location.pathname;
  var path = pathname.split("/")[2];
  const [tableId, settableId] = useState(path);
  const [VisibilityAccuracy, setVisibilityAccuracy] = useState([]);
  const [visibility, setvisibility] = useState([]);
  const [accuracy, setaccuracy] = useState([]);
  useEffect(() => {
    console.log(tableId);
    getAuditGoogleForVisibilityAccuracy();
    $(".visblity_text").hide();
  }, []);
  const getAuditGoogleForVisibilityAccuracy = () => {
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "getAuditGoogleForVisibilityAccuracy",
      masterId: tableId,
    };
    backendApi(req).then((data) => {
      $(".loder_div").attr("hidden", true);
      setVisibilityAccuracy(data.data);
    });
  };
  const visibilityaccuracychange = () => {
    $(".dataProcessImgCompareData").show();
    var req = {
      method: "POST",
      body: JSON.stringify({
        masterId: tableId,
        userId:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
      }),
    };
    pythonBackendApi("runAccuracyVisibilityAuditData", req).then((data) => {
      if (data.success === "1") {
        $(".dataProcessImgCompareData").hide();
        setvisibility(data.visibility);
        setaccuracy(data.accuracy);
        $(".visblity_text").show();
      } else {
        setvisibility("0");
        setaccuracy("0");
        alert("Failed");
      }
      //  window.location.reload()
    });
  };
  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header
          heading="Visibility & Accuracy"
          headerImage={Accessibiltyimage}
        />
        <div className="user_listdiv">
          <div className="back_tocatalog_main">
            <Link to="/visibility" className="back-to-link">
              <Icon.ArrowLeft />
              Back to Visibility & Accuracy
            </Link>
          </div>
          <div className="user_listdiv_sub">
            <div
              className="dataProcessImgCompareData"
              style={{ display: "none" }}
            >
              <img src={dataProcessImg} alt="data process"></img>
              <div className="dataProcessLabel">
                {" "}
                Checking Visiblity & Accuracy Please wait...
              </div>
            </div>
            <div className="back_tocatalog">
              <div className="col-md-12 row justify-content-between">
                <button
                  id="txtFile"
                  type="button"
                  className="btn btn-success"
                  onClick={visibilityaccuracychange}
                >
                  Show Accuracy & Visibility
                </button>
                <p className="visblity_text">
                  Visibility :{Math.round(visibility)} %
                </p>
                <p className="visblity_text">
                  Accuracy: {Math.round(accuracy)} %
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>Ref Code</th>
                        <th> Name</th>
                        <th className="addressDiv">Address</th>
                        <th>Accuracy</th>
                        <th>Visiblity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {VisibilityAccuracy.map((data, i) => (
                        <tr key={i + 1}>
                          <td>{data.reference_code}</td>
                          <td>
                            {data.location_name === null
                              ? "No Data Found"
                              : data.location_name}
                          </td>
                          <td>
                            {data.address === null
                              ? "No Data Found"
                              : data.address}
                          </td>
                          <td>
                            {data.accuracy == 1 ? (
                              <p style={{ color: "green" }}>Accurate</p>
                            ) : (
                              <p style={{ color: "red" }}>Not Accurate</p>
                            )}
                          </td>{" "}
                          <td>
                            {data.address === null ? (
                              <p style={{ color: "red" }}>Not Visible</p>
                            ) : (
                              <p style={{ color: "green" }}>Visible</p>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="reprocessing_btndiv">
            <div className="repro_btn"></div>
          </div>
        </div>
      </div>
      <div className="loder_div" hidden />
      <Footer />
    </div>
  );
};
export default VisibilityDetails;
