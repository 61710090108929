import React from "react";

const InsightsListing = ({ data }) => {
  function formatDate(dateString) {
    const dateObject = new Date(dateString);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();

    return `${day}/${month}/${year}`;
  }
  return (
    <div className="container-fluid">
      <div className="col-md-12">
        <div className="tableOuterWidth table_width_lg">
          <table className="table">
            <thead>
              <tr>
                <th className="col-1">#</th>
                <th className="col-2">Start Date</th>
                <th className="col-2">End Date</th>
                <th className="col-2">Created On</th>
                <th className="col-2">Action</th>
                <th className="col-1">Status</th>
                <th className="col-2">Created By</th>
              </tr>
            </thead>
            <div class="table_Outerscroll">
              {data ? (
                <>
                  {data.map((obj, i) => (
                    <tbody className="table_Outertbody">
                      <tr>
                        <td className="col-1">{i + 1}</td>
                        <td className="col-2">{obj.start_date}</td>
                        <td className="col-2">{obj.end_date}</td>
                        <td className="col-2">{formatDate(obj.created_on)}</td>
                        <td className="col-2">{obj.action}</td>
                        <td className="col-1">{obj.process}</td>
                        <td className="col-2">{obj.created_by_name}</td>
                      </tr>
                    </tbody>
                  ))}
                </>
              ) : (
                <>No Data Found</>
              )}
            </div>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InsightsListing;
