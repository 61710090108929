import React, { useState, useEffect } from "react";
import { backendApi, re } from "../../apiService";
import $ from "jquery";
import SaveAndValidation from "./settingsSaveAndValidation"
function SettingGoogleToken(props) {
  var orgadmin = window.sessionStorage.getItem("role") == "ORG_ADMIN";
   const [googleClientEmail, setgoogleClientEmail] = useState((props.listingValuesForPassing.client_email != "null") ? props.listingValuesForPassing.client_email : "");
   const [googleSubject, setgoogleSubject] = useState((props.listingValuesForPassing.subject != "null") ? props.listingValuesForPassing.subject : "");
   const [googlePrivateKey, setgooglePrivateKey] = useState((props.listingValuesForPassing.private_key != "null") ? props.listingValuesForPassing.private_key : "");
  const [googleAccountId, setGoogleAccountId] = useState((props.listingValuesForPassing.account_id != "null") ? (props.listingValuesForPassing.account_id) : "");
  useEffect(() => {
     if (props.listingValuesForPassing.client_email && (props.listingValuesForPassing.client_email != "null" || props.listingValuesForPassing.client_email != "undefined")) {
       setgoogleClientEmail((props.listingValuesForPassing.client_email))
     } else { setgoogleClientEmail("") }
    
     if (props.listingValuesForPassing.subject && (props.listingValuesForPassing.subject != "null" || props.listingValuesForPassing.subject != "undefined")) {
       setgoogleSubject((props.listingValuesForPassing.subject))
     } else { setgoogleSubject("") }
    
     if (props.listingValuesForPassing.private_key && (props.listingValuesForPassing.private_key != "null" || props.listingValuesForPassing.private_key != "undefined")) {
       setgooglePrivateKey((props.listingValuesForPassing.private_key))
     } else { setgooglePrivateKey("") }
    if (props.listingValuesForPassing.account_id && (props.listingValuesForPassing.account_id != "null" || props.listingValuesForPassing.account_id != "undefined")) {
      setGoogleAccountId((props.listingValuesForPassing.account_id))
    } else { setGoogleAccountId("") }
    
  }, [
     props.listingValuesForPassing.client_email, props.listingValuesForPassing.subject, props.listingValuesForPassing.private_key,
     props.listingValuesForPassing.account_id,

  ])
  return (
     
     <div className="row">
 <div className="col-md-12">
      <div className="user_formdiv">
        <div className="userform_head">Google Token</div>
        {window.sessionStorage.getItem("role") === "ROLE_SUPER_ADMIN"?
        <>
        <div className="row userrow_form">
          <div className="col-md-5">
            <label className="col-form-label">
              Google Client Email
            </label>
          </div>
        
          <div className="col-md-7">
            <input
              type="text"
              id="Clientmail"
              value={googleClientEmail}
              onChange={(e) => {
                setgoogleClientEmail(e.target.value);
              }}
              className="form-control"
              placeholder="Google Client Email"
            />
          </div>
        </div>
        <div className="row userrow_form">
          <div className="col-md-5">
            <label className="col-form-label">
              Google Subject
            </label>
          </div>
          <div className="col-md-7">
            <input
              type="text"
              id="GoogleSubject"
              value={googleSubject}
              onChange={(e) => {
                setgoogleSubject(e.target.value);
              }}
              className="form-control"
              placeholder="Google Subject"
            />
          </div>
        </div>
        <div className="row userrow_form">
          <div className="col-md-5">
            <label className="col-form-label">
              Google Private Key
            </label>
          </div>

          <div className="col-md-7">
            <input
              type="text"
              id="PrivateKey"
              value={googlePrivateKey}
              onChange={(e) => {
                setgooglePrivateKey(e.target.value);
              }}
              className="form-control"
              placeholder="Google Private Key"
            />
          </div>
        </div>
        </>
            :""}
        <div className="row userrow_form">
          <div className="col-md-4">
            <label className="col-form-label">
              Google Account Id
            </label>
            {/* <button className="btn-fetch">Fetch</button> */}
          </div>
          <div class="col-md-1"><span>:</span></div>
          <div className="col-md-7">
            <input
              type="text"
              id="AccountId"
              value={googleAccountId}
              onChange={(e) => {
                setGoogleAccountId(e.target.value);
              }}
              className="form-control"
              placeholder="Google Account Id"
            />
          </div>
        </div>
        <SaveAndValidation
            getUserData={() => props.getUserData()}
          valueForSaved={[googleClientEmail,googleSubject,googlePrivateKey,googleAccountId]}
          saveMode={"googleToken"}
          organizationId={props.organizationId} />
      </div>
    </div>
     </div>
    
  );
}
export default SettingGoogleToken;
