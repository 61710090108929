import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import exportFromJSON from "export-from-json";
import { backendApi, pythonBackendApi } from "../apiService";
import Header from "../layout/header";
import Footer from "../layout/footer";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import Auditimage from "../images/left_icons/audit.png";
import LeftMenu from "../layout/leftMenu";

import Pagination from "react-js-pagination";
// import "../css/bootstrap.css";
import ScaleLoader from "react-spinners/ScaleLoader";
import $ from "jquery";
import dataProcessImg from "../images/dataProcess.gif";
var rows = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
const AuditDetails = () => {
  const location = useLocation();
  let pathname = location.pathname;

  var path = pathname.split("/")[2];

  const [tableId, settableId] = useState(path);
  const [count, setcount] = useState("");
  const [excelData, setExcelData] = useState([]);
  const [excelDatasort, setExcelDatasort] = useState([]);
  const [googleData, setGoogleData] = useState([]);
  const [totalComplete, setTotalComplete] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [finalarray, setfinalarray] = useState([]);
  const [AuditStatusStarted, setAuditStatusStarted] = useState("");
  const [AuditStatusCompleted, setAuditStatusCompleted] = useState("");
  const [AuditStatusFailed, setAuditStatusFailed] = useState("");
  const [AuditStatusInProgress, setAuditStatusInProgress] = useState("");
  const [AuditStatusNotFound, setAuditStatusNotFound] = useState("");
  const [AuditStatusEmptySearch, setAuditStatusEmptySearch] = useState("");
  const [pageOffset, setPageOffset] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [blank, setblank] = useState("");
  const [countdata, setcountdata] = useState("");
  const [disabled, setdisabled] = useState("");
  const [statusForRestart, setstatusForRestart] = useState("");

  const [Primary, setPrimary] = useState("");
  const [Stray, setStray] = useState("");
  const [Blank, setBlank] = useState("");
  const [Duplicate, setDuplicate] = useState("");
  const [Position, setPosition] = useState("");
  const [Notfound, setNotfound] = useState([]);
  const [Notfoundcount, setNotfoundcount] = useState("");
  const [Notfoundflag, setNotfoundflag] = useState("");
  const [masterId, setmasterId] = useState("");
  const [popupData, setPopupData] = useState("");

  useEffect(() => {
    setActivePage(Number(window.sessionStorage.getItem("pageNumberDrDetails")));
    $("#reprocessModal").attr("hidden", true);
    auditNotFoundCommonAPI();

    getStatusdata();
    $("#statusValue").val("0");
    $("#nodata").hide();
    // setInterval(() => {

    //     $("#fromData").val("");
    //     $("#toData").val("");
    //     $("#statusValue").val("0");
    //   getAuditExcelDataByMasterId();
    // }, 60000);

    setInterval(() => {
      getStatusdata();
    }, 60000);
  }, []);
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    var offset;
    let fromdata = $("#fromData").val();
    let todata = $("#toData").val();
    let dropdowndata = $("#statusValue").find("option:selected").val();
    if (dropdowndata == "0") {
      dropdowndata = statusForRestart;
    }
    if (pageNumber === "1") {
      offset = "0";
    } else {
      offset = parseInt(pageNumber - 1) * 25;
    }
    setActivePage(pageNumber);
    setPageOffset(offset);

    let PostData = {
      functionName: "getAuditExcelDataByMasterId",
      masterId: tableId,
      processStatus:
        blank == "blank" ? "blank" : dropdowndata == "0" ? "" : dropdowndata,
      offset: offset.toString(),
      search:
        $("#searchAddress").val() === " " ? "" : $("#searchAddress").val(),
      category: "",
      startId: fromdata == "" ? "1" : fromdata,
      endId: todata == "" ? "500000" : todata,
    };
    window.sessionStorage.setItem("pageNumberDrDetails", pageNumber);
    window.sessionStorage.setItem("offsetDrDetailsAudit", offset);
    setfinalarray([]);

    backendApi(PostData).then((data) => {
      for (let i = 0; i < data.data.length; i++) {
        $("#radiotf" + i).prop("checked", false);
        $("#searchAddressvalue" + i).attr("hidden", true);
        $("#button" + i).attr("hidden", true);
        $("#titleCancelEdit" + i).attr("hidden", true);
        $("#titleEditIcon" + i).attr("hidden", false);
        $("#titleEditIcon" + i).removeAttr("hidden");
      }
      if (data.success === "1") {
        setcount(
          Notfoundflag == ""
            ? data.cntPaginationSearch[0].cntPaginationSearch
            : data.notFountTotalCnt[0].notFountTotalCnt
        );
        setExcelData(Notfoundflag == "" ? data.data : data.notFound);
        setNotfoundcount(data.notFountTotalCnt[0].notFountTotalCnt);
        setNotfound(data.notFound);
        setTotalComplete(data.paginationCount[0].cntPagination);
        setTotalCount(data.totalCount);
        setExcelDatasort(data.data);
        if (data.totalComplete !== data.totalCount) {
          $(".dataProcessImg").show();
        } else {
          $(".dataProcessImg").hide();
        }
      } else {
        setExcelData([]);
      }
    });
  };

  const getAuditExcelDataByMasterId = (
    val,
    fromdata,
    todata,
    type,
    dropdowndata,
    search,
    mode
  ) => {
    let status = "";
    if (dropdowndata != undefined && dropdowndata != 0) {
      status = dropdowndata;
    } else if (val != undefined) {
      status = val;
    } else if (val == "blank") {
      status = val;
    } else {
      status = "";
    }

    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "getAuditExcelDataByMasterId",
      masterId: tableId,
      processStatus: status,
      search: search != undefined && search != 0 ? search : "",
      offset: window.sessionStorage.getItem("offsetDrDetailsAudit"),
      category: "",
      startId: fromdata == "" ? "1" : fromdata,
      endId: todata == "" ? "500000" : todata,
    };
    // setfinalarray([]);

    backendApi(req).then((data) => {
      if (type != "clear") {
        for (let i = 0; i < data.data.length; i++) {
          $("#radiotf" + data.data.id).prop("checked", false);
          $("#searchAddressvalue" + data.data.id).attr("hidden", true);
          $("#button" + data.data.id).attr("hidden", true);
          $("#titleCancelEdit" + data.data.id).attr("hidden", true);
          $("#titleEditIcon" + data.data.id).attr("hidden", false);
          $("#titleEditIcon" + data.data.id).removeAttr("hidden");
        }
      } else {
        for (let i = 0; i < data.data.length; i++) {
          $("#radiotf" + i).prop("checked", false);
          $("#searchAddressvalue" + i).attr("hidden", true);
          $("#button" + i).attr("hidden", true);
          $("#titleCancelEdit" + i).attr("hidden", true);
          $("#titleEditIcon" + i).attr("hidden", false);
          $("#titleEditIcon" + i).removeAttr("hidden");
        }
      }

      if (data.success === "1") {
        setcount(
          mode == undefined || mode == ""
            ? data.cntPaginationSearch[0].cntPaginationSearch
            : data.notFountTotalCnt[0].notFountTotalCnt
        );
        setExcelData(
          mode == undefined || mode == "" ? data.data : data.notFound
        );
        setNotfoundcount(data.notFountTotalCnt[0].notFountTotalCnt);
        setNotfound(data.notFound);

        setdisabled(data.masterData[0].status);
        setmasterId(data.masterData[0].masterId);
        $("#prioritySelect").val(data.masterData[0].priority.toString());
        // setcount(data.cntPaginationSearch[0].cntPaginationSearch);
        setTotalComplete(data.paginationCount[0].cntPagination);
        setTotalCount(data.totalCount);
        setExcelDatasort(data.data);

        if (data.totalComplete !== data.totalCount) {
          $(".dataProcessImg").show();
        } else {
          $(".dataProcessImg").hide();
        }
        $(".loder_div").attr("hidden", true);

        const arr = data.auditGoogle.filter((element) => {
          return element.status == "Primary";
        });
        const arr2 = data.auditGoogle.filter((element) => {
          return element.status == "Stray";
        });

        const arr3 = data.auditGoogle.filter((element) => {
          return element.status == "Duplicate";
        });

        const arr4 = data.auditGoogle.filter((element) => {
          return element.status == "";
        });
        setPrimary(arr);
        setStray(arr2);
        setDuplicate(arr3);
        setBlank(arr4);
      } else {
        setExcelData([]);
      }
    });
  };
  const textAreachange = (i) => {
    $("#searchaddress" + i).hide();
    $("#searchAddressvalue" + i).attr("hidden", false);
    $("#button" + i).attr("hidden", false);
    $("#titleEditIcon" + i).attr("hidden", true);
    $("#titleCancelEdit" + i).removeAttr("hidden");
  };

  const getStatusdata = () => {
    var apidata = {
      functionName: "auditStatus",
      masterId: tableId,
    };
    backendApi(apidata).then((data) => {
      if (data.success === "1") {
        const arr = data.data.filter((element) => {
          return element.process_status == "Started";
        });
        const arr2 = data.data.filter((element) => {
          return element.process_status == "Completed";
        });

        const arr3 = data.data.filter((element) => {
          return element.process_status == "Failed";
        });

        const arr4 = data.data.filter((element) => {
          return element.process_status == "";
        });

        const arr5 = data.data.filter((element) => {
          return element.process_status == "Empty Search";
        });

        const arr6 = data.data.filter((element) => {
          return element.process_status == "NotFound";
        });
        setAuditStatusStarted(arr);
        setAuditStatusCompleted(arr2);
        setAuditStatusFailed(arr3);
        setAuditStatusInProgress(arr4);
        setAuditStatusEmptySearch(arr5);
        setAuditStatusNotFound(arr6);
      }
    });
  };
  const handleProcessData = (id) => {
    $("#table_loader").attr("hidden", false);
    var apidata = {
      functionName: "getAuditGoogleData",
      excelId: id,
    };
    let array = finalarray;
    backendApi(apidata).then((data) => {
      if (data.success === "1") {
        $("#table_loader").attr("hidden", true);
        array = [];

        setfinalarray(data.data);
      } else {
        $("#table_loader").attr("hidden", true);
        array = [];
      }
    });
  };
  const handleUpdateStatus = (e, googleid, excelId) => {
    let fromdata = $("#fromData").val();
    let todata = $("#toData").val();
    let dropdowndata = $("#statusValue").find("option:selected").val();
    let searchAddress = $("#searchAddress").val();
    if (e.target.value === "0") {
      return false;
    }
    if (window.confirm("Are you sure to change status?")) {
      var apidata = {
        functionName: "auditStatusChange",
        googleid: googleid,
        excelId: excelId,
        status: e.target.value,
      };
      $(".loder_div").attr("hidden", false);
      $("#googleid" + googleid).attr("hidden", false);
      backendApi(apidata).then((data) => {
        if (data.success === "1") {
          // alert("Status Changed Successfully");
          $(".loder_div").attr("hidden", true);
          setTimeout(() => {
            $(".save_success_msg").attr("hidden", true);
          }, 5000);
          // for (let i = 0; i < excelData.length; i++) {
          //   $("#radiotf" + i).prop("checked", false);

          // }
          // getAuditExcelDataByMasterId(
          //   blank == "" ? undefined : "blank",
          //   fromdata,
          //   todata,
          //   "",
          //   dropdowndata,
          //   searchAddress,
          //   undefined
          // );
        } else {
          setTimeout(() => {
            $(".save_success_msg").attr("hidden", true);
          }, 5000);
          $(".loder_div").attr("hidden", true);
          alert("Status Changed Failed");
        }
      });
    }
  };

  const auditNotFoundCommonAPI = () => {
    var apidata = {
      functionName: "auditNotFoundCommonAPI",
      masterId: tableId,
    };
    $(".loder_div").attr("hidden", false);

    backendApi(apidata).then((data) => {
      if (data.success === "1") {
        // alert("Status Changed Successfully");
        $(".loder_div").attr("hidden", true);

        getAuditExcelDataByMasterId();
      } else {
        $(".loder_div").attr("hidden", true);
        alert(" Failed");
      }
    });
  };
  const downloadExcel = () => {
    $(".loder_div").attr("hidden", false);
    var apidata = {
      functionName: "downloadAuditExcel",
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      masterId: tableId,
    };
    backendApi(apidata).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        const successFileName = "auditReport";
        const exportType = "xls";
        exportXlsx(data.data, successFileName, exportType);
      } else {
        alert("Failed to Download Audit Excel");
        $(".loder_div").attr("hidden", true);
      }
    });
  };
  const exportXlsx = (data, fileName, exportType) => {
    exportFromJSON({ data, fileName, exportType });
  };
  const reprocessSingleData = (masterId, id) => {
    $(".loder_div").attr("hidden", false);
    var req = {
      method: "POST",
      body: JSON.stringify({
        id: id,
        masterId: masterId,
        userId:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
      }),
    };
    pythonBackendApi("reProcessSingleAuditData", req).then((data) => {
      if (data.success) {
        alert("Re-Process Started");
        $("#fromData").val("");
        $("#toData").val("");
        $("#statusValue").val("0");
        $("#searchAddress").val("");
        getAuditExcelDataByMasterId(statusForRestart, "", "", "", "", "", "");
        $(".loder_div").attr("hidden", true);
      } else {
        $(".loder_div").attr("hidden", true);
      }
    });
  };

  const reprocessFullData = () => {
    if (!window.confirm("Your all data will be reversed to queue")) {
      return false;
    }
    $(".loder_div").attr("hidden", false);
    var req = {
      method: "POST",
      body: JSON.stringify({
        id: "",
        masterId: tableId,
        userId:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
      }),
    };
    pythonBackendApi("reProcessFullAuditData", req).then((data) => {
      if (data.success) {
        alert("Re-Process Started");
        $("#fromData").val("");
        $("#toData").val("");
        $("#statusValue").val("0");
        $("#searchAddress").val("");
        getAuditExcelDataByMasterId();
        $(".loder_div").attr("hidden", true);
      } else {
        $(".loder_div").attr("hidden", true);
      }
    });
  };
  const moveToFraud = () => {
    if (
      !window.confirm(
        "Ensure that your auditing file is properly classified; otherwise, the fraud detection process may not work effectively."
      )
    ) {
      return false;
    }
    $(".loder_div").attr("hidden", false);
    var apidata = {
      method: "POST",
      body: JSON.stringify({
        masterId: tableId,
      }),
    };
    pythonBackendApi("moveAuditToFraud", apidata).then((data) => {
      if (data.success) {
        alert("Successfully moved to fraud.");
        $("#fromData").val("");
        $("#toData").val("");
        $("#statusValue").val("0");
        $("#searchAddress").val("");
        getAuditExcelDataByMasterId();
        $(".loder_div").attr("hidden", true);
      } else {
        $(".loder_div").attr("hidden", true);
      }
    });
  };
  const reprocessFailedData = () => {
    $(".loder_div").attr("hidden", false);
    var req = {
      method: "POST",
      body: JSON.stringify({
        id: "",
        masterId: tableId,
        userId:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
      }),
    };
    pythonBackendApi("reProcessFullFailedAuditData", req).then((data) => {
      if (data.success) {
        alert("Re-Process Started");
        $("#fromData").val("");
        $("#toData").val("");
        $("#statusValue").val("0");
        $("#searchAddress").val("");
        getAuditExcelDataByMasterId();
        $(".loder_div").attr("hidden", true);
      } else {
        $(".loder_div").attr("hidden", true);
      }
    });
  };

  const Stopallprocess = () => {
    if (!window.confirm("Do you want to stop all Audit process?")) {
      return false;
    }
    $(".loder_div").attr("hidden", false);
    var req = {
      method: "POST",
      body: JSON.stringify({
        masterId: tableId,
      }),
    };
    pythonBackendApi("stopAuditProcess", req).then((data) => {
      if (data.success) {
        alert(
          "All audit process stopped successfully changes will be reflected soon"
        );
        $("#fromData").val("");
        $("#toData").val("");
        $("#statusValue").val("0");
        $("#searchAddress").val("");
        getAuditExcelDataByMasterId();
        $(".loder_div").attr("hidden", true);
        window.location.reload();
      } else {
        $(".loder_div").attr("hidden", true);
      }
    });
  };

  // const reprocessFullData = () => {
  //   $(".dataProcessImg").show();
  //   $(".loder_div").attr("hidden", false);
  //   var apidata = {
  //     functionName: "reProcessAuditData",
  //     masterId: tableId,
  //     userId:
  //       window.sessionStorage.getItem("switchUserId") === ""
  //         ? window.sessionStorage.getItem("userId")
  //         : window.sessionStorage.getItem("switchUserId"),
  //     siteId:
  //       window.sessionStorage.getItem("switchingOrgId") === ""
  //         ? window.sessionStorage.getItem("organizationId")
  //         : window.sessionStorage.getItem("switchingOrgId"),
  //   };
  //   backendApi(apidata).then((data) => {
  //     if (data.success == "1") {
  //       alert("Re-Process Started");
  //       $(".loder_div").attr("hidden", true);
  //       window.location.reload();
  //     } else {
  //       alert("Re-Process Failed");
  //       $(".loder_div").attr("hidden", true);
  //     }
  //   });

  //   // $(".loder_div").attr("hidden", true);
  // };
  const processFile = () => {
    if (!window.confirm("Do you want to Classify Data?")) {
      return false;
    }
    $(".dataProcessImgCompareData").show();
    $(".loder_div").attr("hidden", false);
    var apidata = {
      method: "POST",
      body: JSON.stringify({
        masterId: tableId,
        userId:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
      }),
    };

    pythonBackendApi("compareAuditData", apidata).then((data) => {
      if (data.success == "1") {
        alert(
          "Process Started Successfully ,The Result Will Be Reflected Soon"
        );
        window.location.reload();
        $(".loder_div").attr("hidden", true);
      } else {
        alert("Process Failed");
        $(".loder_div").attr("hidden", true);
      }
      $(".dataProcessImgCompareData").hide();
    });

    // window.location.reload();
    // getAuditExcelDataByMasterId();
  };
  const saveSearchCriteria = () => {
    if ($("#searchCriteriaInput").val() === "") {
      alert("Search Criteria cannot be empty");
      return false;
    }
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "updateSearchAuditExcelData",
      excelId: $("#dataId").text(),
      search: $("#searchCriteriaInput").val(),
      created_by: "",
    };
    backendApi(req).then((data) => {
      if (data.success === "1") {
        alert("Search Criteria Changed Successfully");
        $(".loder_div").attr("hidden", true);
        $("#reprocessModal").attr("hidden", true);
        // reprocessFullData();
        window.location.reload();
        getAuditExcelDataByMasterId();
      } else {
        alert("Something went wrong while updating Search Criteria");
        $(".loder_div").attr("hidden", true);
      }
    });
  };

  const saveforAddress = (i, search, id, createdBy) => {
    let fromdata = $("#fromData").val();
    let todata = $("#toData").val();
    let dropdowndata = $("#statusValue").find("option:selected").val();
    let searchAddress = $("#searchAddress").val();
    if ($("#searchAddressvalue" + i).val() === "") {
      alert("Search Criteria cannot be empty");
      return false;
    }
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "updateSearchAuditExcelData",
      excelId: id,
      search: search == "" ? search : $("#searchAddressvalue" + i).val(),
      created_by:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };

    backendApi(req).then((data) => {
      if (data.success === "1") {
        let excelId = id;
        $("#saveSuccess" + i).css("display", "block");
        setTimeout(() => {
          $("#saveSuccess" + i).css("display", "none");
        }, 3000);
        $(".loder_div").attr("hidden", true);
        $("#reprocessModal").attr("hidden", true);
        // reprocessFullData();
        //window.location.reload();

        getAuditExcelDataByMasterId(
          blank == "" ? undefined : "blank",
          fromdata,
          todata,
          "",
          dropdowndata,
          searchAddress,
          undefined
        );

        $("#radiotf" + i).prop("checked", false);
        $("#searchAddressvalue" + i).attr("hidden", true);
        $("#button" + i).attr("hidden", true);
        $("#titleCancelEdit" + i).attr("hidden", true);
        $("#titleEditIcon" + i).attr("hidden", false);
        $("#searchaddress" + i).show();
        $("#titleEditIcon" + i).removeAttr("hidden");
      } else {
        alert("Something went wrong while updating Search Criteria");
        $(".loder_div").attr("hidden", true);
      }
    });
  };

  const handleSort = (value) => {
    if (value == "blank") {
      setblank(value);
    } else {
      setblank("");
    }

    setActivePage(1);
    var flagvalue = true;
    let fromdata = $("#fromData").val();
    let todata = $("#toData").val();
    let dropdowndata = $("#statusValue").find("option:selected").val();
    let fromdatavalue = "";
    let todatavalue = "";

    if (todata === "") {
      todata = 500000;
    }
    if (fromdata === "") {
      fromdata = 1;
    }

    if (todata < fromdata) {
      alert("Please Provide Valid ID");
      flagvalue = false;
    } else if (fromdata !== "" && todata !== "") {
      fromdatavalue = fromdata;
      todatavalue = todata;
      flagvalue = true;
    }
    // else if (fromdata !== "" && todata === "") {
    //   fromdatavalue = fromdata;
    //   todatavalue = 500000;
    //   flagvalue = true;
    // } else if (fromdata === "" && todata !== "") {
    //   fromdatavalue = 1;
    //   todatavalue = todata;
    //   flagvalue = true;
    // }

    // if (fromdata > todata) {
    //   alert("Please Provide Valid Serial No.");
    //   flagvalue = false;
    // } else if (fromdata == "" && todata != "") {
    //   alert("Please Provide Serial No.");
    //   flagvalue = false;
    // } else if (fromdata != "" && todata == "") {
    //   alert("Please Provide Serial No.");
    //   flagvalue = false;
    // }

    if (flagvalue == true) {
      $("#nodata").show();
      $(".loder_div").attr("hidden", false);
      var req = {
        functionName: "getAuditExcelDataByMasterId",
        masterId: tableId,
        processStatus:
          value == "blank" ? "blank" : dropdowndata == "0" ? "" : dropdowndata,
        offset: window.sessionStorage.getItem("offsetDrDetailsAudit"),
        search:
          $("#searchAddress").val() === " " ? "" : $("#searchAddress").val(),
        category: "",
        startId: fromdatavalue,
        endId: todatavalue,
      };
      setfinalarray([]);

      backendApi(req).then((data) => {
        for (let i = 0; i < data.data.length; i++) {
          $("#radiotf" + i).prop("checked", false);
          $("#searchAddressvalue" + i).attr("hidden", true);
          $("#button" + i).attr("hidden", true);
          $("#titleCancelEdit" + i).attr("hidden", true);
          $("#titleEditIcon" + i).attr("hidden", false);
          $("#titleEditIcon" + i).removeAttr("hidden");
        }
        if (data.success === "1") {
          setcount(
            Notfoundflag == ""
              ? data.cntPaginationSearch[0].cntPaginationSearch
              : data.notFountTotalCnt[0].notFountTotalCnt
          );
          setExcelData(Notfoundflag == "" ? data.data : data.notFound);
          setNotfoundcount(data.notFountTotalCnt[0].notFountTotalCnt);
          setNotfound(data.notFound);

          $(".loder_div").attr("hidden", true);
        } else {
          setExcelData([]);
          setcount(0);
          $(".loder_div").attr("hidden", true);
        }
      });
    }
  };

  function allowNumbersOnly(e) {
    var code = e.which ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
      e.preventDefault();
    }
  }

  const clearFilters = () => {
    // $(".loder_div").attr("hidden", false);
    // setTimeout(() => {
    //   $(".loder_div").attr("hidden", true);
    // }, 500);

    $("#table_tableid").hide();
    $("#searchAddress").val("");
    setblank("");
    setstatusForRestart("");
    $("#fromData").val("");
    $("#toData").val("");
    $("#statusValue").val("0");
    window.sessionStorage.setItem("offsetDrDetailsAudit", 0);
    setActivePage(1);
    setNotfoundflag("");
    getAuditExcelDataByMasterId(undefined, "", "", "clear", "", "", undefined);
  };
  const SearchFile = (val, count) => {
    setstatusForRestart(val);
    if (val == "0") {
      $("#statusValue").val("0");
    } else {
      $("#statusValue").val(val);
    }
    for (let i = 0; i < excelData.length; i++) {
      $("#radiotf" + i).prop("checked", false);
      $("#searchAddressvalue" + i).attr("hidden", true);
      $("#button" + i).attr("hidden", true);
      $("#titleCancelEdit" + i).attr("hidden", true);
      $("#titleEditIcon" + i).attr("hidden", false);
      $("#titleEditIcon" + i).removeAttr("hidden");
    }
    window.sessionStorage.setItem("offsetDrDetailsAudit", 0);
    setActivePage(1);
    getAuditExcelDataByMasterId(val, "", "", "", "", "", undefined);
  };
  const priorityChange = (e) => {
    if (e.target.value == 0) return false;
    if (!window.confirm("Do you want to Change Priority?")) {
      return false;
    }
    $(".loder_div").attr("hidden", false);
    var apidata = {
      functionName: "changePriority",
      masterId: tableId,
      priority: e.target.value,
    };
    backendApi(apidata).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        getAuditExcelDataByMasterId("", "", "", "", "", "", "");
      } else {
        $(".loder_div").attr("hidden", true);
      }
    });
  };
  const revertFunction = () => {
    $("#fromValue").val("0");
    $("#toValue").val("0");
    $("#editCommentPopup").attr("hidden", false);
  };

  const saveRevert = () => {
    let fromValue = $("#fromValue").find("option:selected").val();
    let toValue = $("#toValue").find("option:selected").val();
    var flag = true;

    if (fromValue == "0") {
      alert("From Value required");
      flag = false;
    } else if (toValue == "0") {
      alert("To Value required");
      flag = false;
    }
    if (flag == true) {
      $(".loder_div").attr("hidden", false);

      var apidata = {
        method: "POST",
        body: JSON.stringify({
          auditStatusFrom: fromValue,
          auditStatusTo: toValue,
          masterId: tableId,
        }),
      };
      pythonBackendApi("changeAuditStatusRevert", apidata).then((data) => {
        if (data.success === "1") {
          $(".loder_div").attr("hidden", true);
          alert("Reverted Successfully");
          $("#editCommentPopup").attr("hidden", true);
          $("#fromValue").val("0");

          $("#toValue").val("0");
          window.location.reload();
        } else {
          $(".loder_div").attr("hidden", true);
          alert("Reverted Failed");
        }
      });
    }
  };
  const notfound = () => {
    setNotfoundflag("mode");
    window.sessionStorage.setItem("offsetDrDetailsAudit", 0);
    setActivePage(1);
    getAuditExcelDataByMasterId(undefined, "", "", "clear", "", "", "mode");

    setcount(Notfoundcount);
    setExcelData(Notfound);
    setblank("");
  };
  const removeDuplicate = () => {
    if (!window.confirm("Do you want to Remove Duplicate?")) {
      return false;
    }
    $(".loder_div").attr("hidden", false);
    var apidata = {
      functionName: "removeDuplicateData",
      audit_excel_id: tableId,
    };
    backendApi(apidata).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        alert("Successfully Removed");
        getAuditExcelDataByMasterId();
      } else {
        $(".loder_div").attr("hidden", true);
      }
    });
  };

  const migrateAuditDataRestart = () => {
    $(".loder_div").attr("hidden", false);
    var apidata = {
      method: "POST",
      body: JSON.stringify({
        masterId: tableId,
        userId:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
      }),
    };
    pythonBackendApi("migrateAuditDataRestart", apidata).then((data) => {
      $(".loder_div").attr("hidden", true);
      alert("Process Re-Started Successfully");
    });
  };
  const popupShow = (data) => {
    $("#moreInfoPopup").attr("hidden", false);
    setPopupData(data);
  };
  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper auditDeatilsDiv">
        <Header heading="Audit Details" headerImage={Auditimage} />
        <div className="container-fluid">
          <div className="user_listdiv_sub">
            {/* <div className="back_tocatalog_main">
            <Link to="/audit" className="back-to-link">
              <Icon.ArrowLeftCircle/>
            </Link>

            <div className="refresh-icon">
              <Icon.RefreshCcw
                onClick={() => {
                  window.location.reload();
                }}
                title="Refresh"
              />
            </div>
          </div> */}
            <div className="back_tocatalog row align-items-center">
              <div className="col-xl-12 d-flex flex-wrap">
                <div className="back_tocatalog_main">
                  <Link to="/audit" className="back-to-link">
                    <Icon.ArrowLeftCircle />
                  </Link>
                </div>
                <button
                  id="txtFile"
                  type="button"
                  disabled={
                    (disabled == "" || disabled == "Audited") &&
                    (masterId == "Started" ||
                      masterId !== "Classify" ||
                      masterId === "Classify")
                      ? false
                      : true
                  }
                  className={
                    (disabled == "" || disabled == "Audited") &&
                    (masterId == "Started" ||
                      masterId !== "Classify" ||
                      masterId === "Classify")
                      ? "btn btn-info mr-2"
                      : "btn btn-secondary mr-2"
                  }
                  onClick={() => reprocessFullData()}
                >
                  Re-Process All
                </button>
                <button
                  id="txtFile"
                  type="button"
                  // disabled={
                  //   (disabled == "" || disabled == "Audited") &&
                  //     (masterId == "Started" ||
                  //       masterId !== "Classify" ||
                  //       masterId === "Classify")
                  //     ? false
                  //     : true
                  // }
                  className="btn btn-info mr-2"
                  // {
                  //   (disabled == "" || disabled == "Audited") &&
                  //     (masterId == "Started" ||
                  //       masterId !== "Classify" ||
                  //       masterId === "Classify")
                  //     ? "btn btn-info mr-2"
                  //     : "btn btn-secondary mr-2"
                  // }
                  onClick={() => moveToFraud()}
                >
                  Move To Fraud
                </button>
                <button
                  className="btn btn-success mr-2"
                  id="txtFile"
                  type="button"
                  onClick={() => migrateAuditDataRestart()}
                >
                  Re-Start
                </button>
                <button
                  id="txtFile"
                  type="button"
                  disabled={
                    disabled == "Audited" &&
                    (masterId !== "Classify" || masterId === "Classify")
                      ? false
                      : true
                  }
                  className={
                    disabled == "Audited" &&
                    (masterId !== "Classify" || masterId === "Classify")
                      ? "btn btn-success mr-2"
                      : "btn btn-secondary mr-2"
                  }
                  onClick={() => processFile()}
                >
                  {disabled == "Compared"
                    ? "Data Sent for Classification"
                    : disabled == "Audited" && masterId === "Classify"
                    ? "Re-Classify Data"
                    : disabled == "Audited" && masterId !== "Classify"
                    ? "Classify Data"
                    : "Classify Data"}
                </button>
                <button
                  id="txtFile"
                  type="button"
                  disabled={disabled != "Audited" ? true : false}
                  className={
                    disabled != "Audited"
                      ? "btn btn-secondary mr-2"
                      : "btn-download mr-1 ml-0"
                  }
                  onClick={(e) => {
                    downloadExcel();
                  }}
                >
                  Download Excel
                </button>
                <button
                  id="txtFile"
                  type="button"
                  className="btn btn-danger mr-2"
                  onClick={() => reprocessFailedData()}
                >
                  Re-Process Failed
                </button>
                <button
                  id="txtFile"
                  type="button"
                  className={"btn btn-danger mr-2"}
                  onClick={() => Stopallprocess()}
                >
                  Stop All Process
                </button>
                <button
                  id="txtFile"
                  type="button"
                  className={"btn btn-danger mr-2"}
                  onClick={() => removeDuplicate()}
                >
                  Remove Duplicate
                </button>
                <div
                  className="audit-list-box audit-priority"
                  style={{ color: "rgb(73, 92, 131)" }}
                >
                  <span className="audit-txt-bld ml-0">Priority :</span>
                  <span className="priorityList">
                    {(window.sessionStorage.getItem("role") ===
                      "ROLE_SUPER_ADMIN" ||
                      window.sessionStorage.getItem("role") ===
                        "ROLE_ADMIN") && (
                      <select
                        className="form-control mb-0 prioritySelect"
                        id="prioritySelect"
                        onChange={(e) => priorityChange(e)}
                      >
                        <option value="0">Select Priority</option>
                        {rows.map((data) => (
                          <option key={data} value={data}>
                            {data}
                          </option>
                        ))}
                      </select>
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-xl-4">
                <div className="classify_data_sublist row pl-3">
                  <div className="classify_databox">
                    <div className="classify_sub">
                      <span className="classify_subtxt">Primary : </span>
                      <span className="classify_subtxt">
                        {" "}
                        {Primary == "" ? 0 : Primary.map((data) => data.STATUS)}
                      </span>
                    </div>
                    <div className="classify_sub">
                      <span className="classify_subtxt">Stray : </span>
                      <span className="classify_subtxt">
                        {" "}
                        {Stray == "" ? 0 : Stray.map((data) => data.STATUS)}
                      </span>
                    </div>
                    <div className="classify_sub">
                      <span className="classify_subtxt">Duplicate : </span>
                      <span className="classify_subtxt">
                        {" "}
                        {Duplicate == ""
                          ? 0
                          : Duplicate.map((data) => data.STATUS)}
                      </span>
                    </div>
                    <div className="classify_sub">
                      <span className="classify_subtxt">Blank : </span>
                      <span className="classify_subtxt">
                        {" "}
                        {blank == "" ? 0 : Blank.map((data) => data.STATUS)}
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-xl-5">
                <div className="d-flex">
                  <div className="classify_databox">
                    <div className="classify_sub color_prim">
                      <span className="classify_subtxt">
                        <Icon.Compass />
                        Primary :{" "}
                      </span>
                      <span className="classify_subtxt">
                        {" "}
                        {Primary == "" ? 0 : Primary.map((data) => data.STATUS)}
                      </span>
                    </div>
                    <div className="classify_sub color_stray">
                      <span className="classify_subtxt">
                        <Icon.GitMerge />
                        Stray :{" "}
                      </span>
                      <span className="classify_subtxt">
                        {" "}
                        {Stray == "" ? 0 : Stray.map((data) => data.STATUS)}
                      </span>
                    </div>
                    <div className="classify_sub color_duplic">
                      <span className="classify_subtxt">
                        <Icon.Copy />
                        Duplicate :{" "}
                      </span>
                      <span className="classify_subtxt">
                        {" "}
                        {Duplicate == ""
                          ? 0
                          : Duplicate.map((data) => data.STATUS)}
                      </span>
                    </div>

                    <div className="classify_sub">
                      <span className="classify_subtxt">
                        <Icon.File />
                        Blank :{" "}
                      </span>
                      <span className="classify_subtxt">
                        {" "}
                        {Blank == "" ? 0 : Blank.map((data) => data.STATUS)}
                      </span>
                    </div>
                    <Link className="" onClick={notfound}>
                      <div className="classify_sub link-show text_warning_color">
                        <span className="classify_subtxt">
                          <Icon.XSquare />
                          Primary not found :{" "}
                        </span>
                        <span className="classify_subtxt">
                          {" "}
                          {Notfoundcount}
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 d-flex align-items-center mb-2 flex-wrap">
                <div
                  className="audit-list-box audit_list_auto"
                  style={{ color: "rgb(73, 92, 131)" }}
                >
                  <span className="audit-txt">
                    {/* <Icon.DollarSign /> */}
                    Total :
                  </span>
                  <span className="audit-txt-bld">{totalComplete}</span>
                </div>
                <div className="audit-list-box">
                  <Link
                    className="link-show"
                    onClick={() =>
                      SearchFile(
                        " ",
                        AuditStatusInProgress.map((data) => data.cnt)
                      )
                    }
                    style={{ color: "rgb(80, 80, 80)" }}
                  >
                    <span className="audit-txt">
                      <Icon.Link />
                      In Queue :
                    </span>
                    <span className="audit-txt-bld">
                      {AuditStatusInProgress == ""
                        ? 0
                        : AuditStatusInProgress.map((data) => data.cnt)}
                    </span>
                  </Link>
                  {/* {AuditStatusInProgress != "" && (
                    <Link
                      className="link-show"
                      onClick={() => SearchFile("")}
                    >
                      show
                    </Link>
                  )} */}
                </div>
                <div className="audit-list-box">
                  <Link
                    className="link-show"
                    onClick={() =>
                      SearchFile(
                        "Completed",
                        AuditStatusCompleted.map((data) => data.cnt)
                      )
                    }
                    style={{ color: "#rgb(40, 167, 69)" }}
                  >
                    <span className="audit-txt">
                      <Icon.Check />
                      Completed:
                    </span>
                    <span className="audit-txt-bld">
                      {AuditStatusCompleted == ""
                        ? 0
                        : AuditStatusCompleted.map((data) => data.cnt)}
                    </span>
                  </Link>
                  {/* {AuditStatusCompleted != "" && (
                    <Link
                      className="link-show"
                      onClick={() => SearchFile("Completed")}
                    >
                      show
                    </Link>
                  )} */}
                </div>
                <div className="audit-list-box">
                  <Link
                    className="link-show"
                    onClick={() =>
                      SearchFile(
                        "Failed",
                        AuditStatusNotFound.map((data) => data.cnt)
                      )
                    }
                    style={{ color: "rgb(205, 0, 0)" }}
                  >
                    <span className="audit-txt">Not Found:</span>
                    <span className="audit-txt-bld">
                      {AuditStatusNotFound == ""
                        ? 0
                        : AuditStatusNotFound.map((data) => data.cnt)}
                    </span>
                  </Link>
                </div>
                <div className="audit-list-box">
                  <Link
                    className="link-show"
                    onClick={() =>
                      SearchFile(
                        "blank",
                        AuditStatusEmptySearch.map((data) => data.cnt)
                      )
                    }
                    style={{ color: "rgb(205, 0, 0)" }}
                  >
                    <span className="audit-txt">
                      <Icon.Search />
                      Empty Search:
                    </span>
                    <span className="audit-txt-bld">
                      {AuditStatusEmptySearch == ""
                        ? 0
                        : AuditStatusEmptySearch.map((data) => data.cnt)}
                    </span>
                  </Link>
                </div>

                <div className="audit-list-box">
                  <Link
                    className="link-show"
                    onClick={() =>
                      SearchFile(
                        "Started",
                        AuditStatusStarted.map((data) => data.cnt)
                      )
                    }
                    style={{ color: "rgb(23, 162, 184)" }}
                  >
                    <span className="audit-txt">
                      <Icon.Loader />
                      In Progress :
                    </span>
                    <span className="audit-txt-bld">
                      {AuditStatusStarted == ""
                        ? 0
                        : AuditStatusStarted.map((data) => data.cnt)}
                    </span>
                  </Link>
                </div>

                <div
                  className="audit-list-box"
                  onClick={() => revertFunction()}
                >
                  <Link>
                    <span className="audit-txt revert-btn">
                      Revert
                      <Icon.GitPullRequest className="ml-1" />
                    </span>
                  </Link>
                </div>
                {/* <div className="audit-list-box">
                  <Link>
                    <span className="audit-txt">
                      <Icon.RotateCcw />
                    </span>
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="row row-search">
              <div className="col-lg-2 col-md-4 col-sm-6">
                <select className="form-control" id="statusValue">
                  {" "}
                  <option value="0">Select Status</option>
                  <option value="Completed">Completed</option>
                  <option value="Started">In Progress</option>
                  <option value=" ">In Queue</option>
                  <option value="Failed">Not Found</option>
                  <option value="blank">Empty Search</option>
                </select>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <input
                  type="text"
                  id="fromData"
                  className="form-control"
                  placeholder="From ID"
                  onKeyPress={(e) => allowNumbersOnly(e)}
                />
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <input
                  type="text"
                  id="toData"
                  className="form-control"
                  placeholder="To ID"
                  onKeyPress={(e) => allowNumbersOnly(e)}
                />
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  id="searchAddress"
                />
              </div>
              <div className="col-xl-4 col-md-6 col-sm-12 col-lg-6">
                <button
                  className="btn-search"
                  id="txtFile"
                  type="button"
                  onClick={() => handleSort("val")}
                >
                  Search
                </button>
                <button
                  id="txtFile"
                  type="button"
                  className="btn btn-dark fetchDataBtn"
                  onClick={() => handleSort("blank")}
                >
                  Blank Search
                </button>

                <button
                  id="txtFile"
                  type="button"
                  className="btn btn-clear-search fetchDataBtn"
                  onClick={(e) => clearFilters(e)}
                >
                  Clear Search
                </button>
              </div>
            </div>
            {/* <div className="slNosection col-lg-8 col-md-10">
              <select
                className="form-control slNoselection_field"
                id="statusValue"
              >
                {" "}
                <option value="0">Select Status</option>
                <option value="Completed">Completed</option>
                <option value="Started">In Progress</option>
                <option value="">In Queue</option>
                <option value="Failed">Failed</option>
              </select>
              <span className="slNosectionSpan">
                <input
                  type="text"
                  id="fromData"
                  className="form-control"
                  placeholder="From Serial No."
                  onKeyPress={(e) => allowNumbersOnly(e)}
                />
              </span>
              &nbsp;&nbsp; To
              <span className="slNosectionSpan">
                <input
                  type="text"
                  id="toData"
                  className="form-control"
                  placeholder="To Serial No."
                  onKeyPress={(e) => allowNumbersOnly(e)}
                />
              </span>
              <span className="slNosectionSpan">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  id="searchAddress"
                />
              </span>
             
              <button
                id="txtFile"
                type="button"
                className="btn btn-info fetchDataBtn"
                onClick={(e) => handleSort(e)}
              >
                Search
              </button>
              <button
                id="txtFile"
                type="button"
                className="btn btn-dark fetchDataBtn"
                onClick={(e) => handleSort("null")}
              >
                Blank Search
              </button>
              <button
                id="txtFile"
                type="button"
                className="btn btn-info fetchDataBtn"
                onClick={(e) => clearFilters(e)}
              >
                Clear Search
              </button>
            </div> */}
            <div className="row">
              <div className="module_popup" id="reprocessModal" hidden>
                <div className="module_innerpopup">
                  <div className="module_close">
                    <Icon.XSquare
                      color="red"
                      onClick={() => {
                        $("#reprocessModal").attr("hidden", true);
                      }}
                    />
                  </div>

                  <div className="module_body">
                    <h5>Search Criteria</h5>
                    <span id="dataId" hidden></span>
                    <textarea
                      id="searchCriteriaInput"
                      className="reprocessArea"
                    ></textarea>
                  </div>
                  <div className="module_popupsearch">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => saveSearchCriteria()}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        $("#reprocessModal").attr("hidden", true);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-10">
                {count > 25 && (
                  <div className="pagination_main">
                    <Pagination
                      hideDisabled
                      activePage={activePage}
                      itemsCountPerPage={25}
                      totalItemsCount={count}
                      pageRangeDisplayed={10}
                      onChange={(e) => handlePageChange(e)}
                    />
                  </div>
                )}
              </div>
              <div className="col-lg-4 col-md-2"></div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-md-10 tableResponsive">
                <div className="table_leftOuterWidth">
                  <table className="table">
                    <thead className="table_theadTop">
                      <tr>
                        <th className="col-2">ID</th>
                        <th className="col-2">Name</th>
                        <th className="col-3">Address</th>
                        {/* <th>Edit</th> */}
                        <th className="col-1">Status</th>
                        {/* <th>Reprocess</th> */}
                        <th className="col-1"></th>
                        <th className="col-1"></th>
                      </tr>
                    </thead>

                    <div className="table_Outerscroll">
                      {excelData.length > 0 ? (
                        <>
                          {excelData.map((data, i) => (
                            <tbody
                              key={i + 1}
                              className="stickyDiv table_Outertbody"
                            >
                              <tr>
                                <td className="col-2">
                                  {data.id}
                                  <span
                                    style={{ display: "block", color: "blue" }}
                                  >
                                    {data.reference_code}
                                  </span>
                                </td>

                                <td className="col-2">
                                  <span className="text-secondary">
                                    {data.location_name}
                                  </span>
                                  {data.category && (
                                    <span
                                      className="text-secondary"
                                      style={{ display: "block" }}
                                    >
                                      ({data.category})
                                    </span>
                                  )}
                                  <br />
                                  {data.google_address_status && (
                                    <span className="text-success">
                                      ({data.google_address_status})
                                    </span>
                                  )}
                                </td>

                                <td className="col-3">
                                  {data.address}

                                  <div className="searchbuttonDiv">
                                    <Icon.Search
                                      id={"titleEditIcon" + i}
                                      className="audit-search-icon"
                                      onClick={() => {
                                        textAreachange(i, data.search);
                                        $("#searchAddressvalue" + i).val(
                                          data.search
                                        );
                                        $("#dataId").text(data.id);
                                      }}
                                    />
                                    <p
                                      id={"searchaddress" + i}
                                      className="mb-0 succes_textdiv"
                                    >
                                      {data.search}
                                    </p>
                                    <textarea
                                      type="text"
                                      className="form-control searchEditInput"
                                      placeholder="Search"
                                      id={"searchAddressvalue" + i}
                                      hidden
                                    />
                                    <span id={"button" + i} hidden>
                                      <Icon.Save
                                        className="audit-save-icon"
                                        onClick={() =>
                                          saveforAddress(
                                            i,
                                            data.search,
                                            data.id,
                                            data.createdBy
                                          )
                                        }
                                      />
                                    </span>
                                  </div>

                                  <span
                                    className="success-msg succes_save_msg"
                                    id={"saveSuccess" + i}
                                  >
                                    Saved successfully
                                  </span>
                                </td>

                                <td
                                  className="col-1"
                                  style={{ textAlign: "center" }}
                                  key={data.process_status}
                                >
                                  {/* {data.process_status} */}

                                  {data.process_status === "" ? (
                                    ""
                                  ) : data.process_status === "Started" ? (
                                    <ScaleLoader
                                      color="#36d7b7"
                                      height={20}
                                      width={2}
                                    />
                                  ) : data.process_status === "Completed" ? (
                                    <span title="Completed">
                                      <Icon.CheckCircle
                                        color="green"
                                        name="completed"
                                      />
                                    </span>
                                  ) : (
                                    <span title="Failed">
                                      <Icon.XCircle
                                        color="red"
                                        name="completed"
                                      />
                                    </span>
                                  )}
                                </td>

                                <td className="text-center col-2">
                                  {data.search != null && (
                                    <a
                                      role="button"
                                      className="btn-link"
                                      onClick={() =>
                                        reprocessSingleData(
                                          data.audit_excel_id,
                                          data.id
                                        )
                                      }
                                    >
                                      Restart
                                    </a>
                                  )}
                                </td>
                                <td className="col-1 text-center">
                                  <input
                                    type="radio"
                                    id={"radiotf" + i}
                                    name="process"
                                    onClick={(e) => {
                                      handleProcessData(data.id);
                                      var elem = e.target;
                                      var rect = elem.getBoundingClientRect();
                                      var scrollTop =
                                        document.documentElement.scrollTop;
                                      const absoluteY = scrollTop + rect.top;
                                      setPosition(absoluteY);
                                    }}
                                  />
                                </td>
                                <td className="col-1">
                                  <Icon.Info
                                    size="18px"
                                    onClick={() => {
                                      popupShow(data);
                                    }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </>
                      ) : (
                        <tr id="nodata">No Data Found</tr>
                      )}
                    </div>
                  </table>
                </div>
                {count > 25 && (
                  <div className="pagination_main">
                    <Pagination
                      hideDisabled
                      activePage={activePage}
                      itemsCountPerPage={25}
                      totalItemsCount={count}
                      pageRangeDisplayed={10}
                      onChange={(e) => handlePageChange(e)}
                    />
                  </div>
                )}
              </div>

              <div className="col-md-10 col-lg-4 tableResponsive">
                {finalarray.length !== 0 && (
                  <div
                    className="table_rightOuterWidth custom_position"
                    style={{
                      top: Position + "px",
                      position: "absolute",
                      right: "0",
                      "margin-top": "-303px",
                      overflow: "auto",
                    }}
                  >
                    <div
                      className="table_loader"
                      id="table_loader"
                      hidden
                    ></div>
                    <table className="table" id="table_tableid">
                      <thead className="table_theadTop">
                        <tr>
                          <th className="col-2">Name</th>
                          <th className="col-5">Address</th>
                          <th className="col-2">Reviews</th>
                          <th className="col-3">Status</th>
                        </tr>
                      </thead>

                      <div className="table_rightOuterscroll">
                        {finalarray.map((data, i) => (
                          <>
                            <tbody
                              key={data.id}
                              className="table_righttbody"
                              id={data.id}
                            >
                              <span
                                className="save_success_msg"
                                hidden
                                id={"googleid" + data.id}
                              >
                                {" "}
                                Saved successfully{" "}
                              </span>

                              <tr>
                                <td className="col-2">{data.location_name}</td>
                                <td className="col-5">
                                  {data.address}
                                  <div>
                                    (
                                    <span className="span_div_style">
                                      {data.category}
                                    </span>
                                    )
                                  </div>
                                </td>
                                <td className="col-2">{data.reviews}</td>
                                <td className="col-3">
                                  <select
                                    className="form-control status_field"
                                    id={data.id}
                                    defaultValue={
                                      data.status ? data.status : ""
                                    }
                                    onChange={(e) =>
                                      handleUpdateStatus(
                                        e,
                                        data.id,
                                        data.excel_id
                                      )
                                    }
                                  >
                                    {" "}
                                    <option value="0">Select</option>
                                    <option value="Primary">Primary</option>
                                    <option value="Stray">Stray</option>
                                    <option value="Duplicate">Duplicate</option>
                                    <option value="Not Found">Not Found</option>
                                    <option value="Ignore">Ignore</option>
                                  </select>
                                </td>
                              </tr>
                            </tbody>
                          </>
                        ))}
                      </div>
                    </table>
                  </div>
                )}
                <div id="editCommentPopup" className="module_popup" hidden>
                  <div className="module_innerpopup">
                    <div className="module_close">
                      <Icon.XCircle
                        onClick={() => {
                          $("#editCommentPopup").attr("hidden", true);
                        }}
                      />
                    </div>
                    <div className="module_header">Revert</div>
                    <div
                      id="editCommentPopupHeader"
                      className="module_header"
                    ></div>
                    <div className="module_body">
                      <div className="module_popupsearch_wrpr">
                        <div className="row">
                          <div className="col-md-5">
                            <select id="fromValue" className="form-control">
                              <option value="0">From </option>
                              <option value="Completed">
                                Completed (
                                {AuditStatusCompleted == ""
                                  ? "0"
                                  : AuditStatusCompleted.map(
                                      (data) => data.cnt
                                    )}
                                )
                              </option>
                              {/* <option value="Failed">
                                Failed (
                                {AuditStatusFailed == ""
                                  ? "0"
                                  : AuditStatusFailed.map((data) => data.cnt)}
                                )
                              </option> */}
                              <option value="Started">
                                In Progress (
                                {AuditStatusStarted == ""
                                  ? "0"
                                  : AuditStatusStarted.map((data) => data.cnt)}
                                )
                              </option>
                              <option value="NotFound">
                                Not Found/Failed
                                {/* (
                                {AuditStatusNotFound == ""
                                  ? "0"
                                  : AuditStatusNotFound.map((data) => data.cnt)}
                                ) */}
                              </option>
                            </select>
                          </div>
                          <div className="col-md-5">
                            <select id="toValue" className="form-control">
                              <option value="0">To </option>
                              <option value="">
                                In Queue (
                                {AuditStatusInProgress == ""
                                  ? "0"
                                  : AuditStatusInProgress.map(
                                      (data) => data.cnt
                                    )}
                                )
                              </option>
                              {/* <option value="Failed">
                                Failed (
                                {AuditStatusFailed == ""
                                  ? "0"
                                  : AuditStatusFailed.map((data) => data.cnt)}
                                )
                              </option> */}
                              <option value="NotFound">
                                Not Found/Failed
                                {/* ({" "}
                                {AuditStatusNotFound == ""
                                  ? 0
                                  : AuditStatusNotFound.map((data) => data.cnt)}
                                ) */}
                              </option>
                            </select>
                          </div>
                          <div className="col-md-2">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => saveRevert()}
                            >
                              Revert
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="moreInfoPopup" className="module_popup" hidden>
                  <div className="module_innerpopup">
                    <div className="module_close">
                      {console.log("popupDatapopupData", popupData)}
                      <Icon.XCircle
                        onClick={() => {
                          $("#moreInfoPopup").attr("hidden", true);
                        }}
                      />
                    </div>
                    <div
                      id="moreInfoPopupHeader"
                      className="module_header"
                    ></div>
                    <div className="module_body">
                      <div className="module_popupsearch_wrpr">
                        <div className="py-3">
                          <div class="did-floating-label-content">
                            <div className="moreDetails">
                              <span>
                                <b>Reference Code</b>:{" "}
                                {popupData.reference_code
                                  ? popupData.reference_code
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Location Name</b>:{" "}
                                {popupData.location_name
                                  ? popupData.location_name
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Address Line 1</b>:{" "}
                                {popupData.address_line1
                                  ? popupData.address_line1
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Address Line 2</b>:{" "}
                                {popupData.address_line2
                                  ? popupData.address_line2
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Address Line 3</b>:{" "}
                                {popupData.address_line3
                                  ? popupData.address_line3
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Address Line 4</b>:{" "}
                                {popupData.address_line4
                                  ? popupData.address_line4
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Address Line 5</b>:{" "}
                                {popupData.address_line5
                                  ? popupData.address_line5
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Sub-locality</b>:{" "}
                                {popupData.sub_locality
                                  ? popupData.sub_locality
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Locality</b>:{" "}
                                {popupData.locality
                                  ? popupData.locality
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Administrative Area</b>:{" "}
                                {popupData.administrativeArea
                                  ? popupData.administrativeArea
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Country/ Region</b>:{" "}
                                {popupData.country_Region
                                  ? popupData.country_Region
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Postal Code</b>:{" "}
                                {popupData.Postcode
                                  ? popupData.Postcode
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Latitude</b>:{" "}
                                {popupData.Latitude
                                  ? popupData.Latitude
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Longitude</b>:{" "}
                                {popupData.Longitude
                                  ? popupData.Longitude
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Primary Phone</b>:{" "}
                                {popupData.phone_no
                                  ? popupData.phone_no
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Additional Phone Nos.</b>:{" "}
                                {popupData.Additional_Phones
                                  ? popupData.Additional_Phones
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Category</b>:{" "}
                                {popupData.category
                                  ? popupData.category
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Additional categories</b>:{" "}
                                {popupData.Additional_categories
                                  ? popupData.Additional_categories
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Sunday Hours</b>:{" "}
                                {popupData.Sunday_hours
                                  ? popupData.Sunday_hours
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Monday Hours</b>:{" "}
                                {popupData.Monday_hours
                                  ? popupData.Monday_hours
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Tuesday Hours</b>:{" "}
                                {popupData.Tuesday_hours
                                  ? popupData.Tuesday_hours
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Wednesday Hours</b>:{" "}
                                {popupData.Wednesday_hours
                                  ? popupData.Wednesday_hours
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Thursday Hours</b>:{" "}
                                {popupData.Thursday_hours
                                  ? popupData.Thursday_hours
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Friday Hours</b>:{" "}
                                {popupData.Friday_hours
                                  ? popupData.Friday_hours
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Saturday Hours</b>:{" "}
                                {popupData.Saturday_hours
                                  ? popupData.Saturday_hours
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Special Hours</b>:{" "}
                                {popupData.Special_hours
                                  ? popupData.Special_hours
                                  : "N/A"}
                              </span>
                              <span>
                                <b>From The Business</b>:{" "}
                                {popupData.From_the_business
                                  ? popupData.From_the_business
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Opening Date</b>:{" "}
                                {popupData.Opening_date
                                  ? popupData.Opening_date
                                  : "N/A "}
                              </span>
                              <span>
                                <b>Logo Photo</b>:{" "}
                                {popupData.Logo_photo
                                  ? popupData.Logo_photo
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Cover Photo</b>:{" "}
                                {popupData.Cover_photo
                                  ? popupData.Cover_photo
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Other Photos</b>:{" "}
                                {popupData.Other_photos
                                  ? popupData.Other_photos
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Labels</b>:{" "}
                                {popupData.Labels ? popupData.Labels : "N/A"}
                              </span>
                              <span>
                                <b>AdWords location extensions phone</b>:{" "}
                                {popupData.AdWords_location_extensions_phone
                                  ? popupData.AdWords_location_extensions_phone
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Amenities: Wi-Fi</b>:{" "}
                                {popupData.Wi_Fi ? popupData.Wi_Fi : "N/A"}
                              </span>
                              <span>
                                <b>
                                  From the business: Identifies as women-owned
                                </b>
                                :{" "}
                                {popupData.is_owned_by_women
                                  ? popupData.is_owned_by_women
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Payments: Credit cards: American Express</b>:{" "}
                                {popupData.Payments_american_express
                                  ? popupData.Payments_american_express
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Payments: Credit cards : Mastercard</b>:{" "}
                                {popupData.Payments_mastercard
                                  ? popupData.Payments_mastercard
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Payments: Credit cards : Visa</b>:{" "}
                                {popupData.Payments_visa
                                  ? popupData.Payments_visa
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Place page URLs: Menu link</b>:{" "}
                                {popupData.url_menu
                                  ? popupData.url_menu
                                  : "N/A"}
                              </span>
                              <span>
                                <b>Place page URLs: Order ahead links</b>:{" "}
                                {popupData.url_order_ahead
                                  ? popupData.url_order_ahead
                                  : "N/A"}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* <div className="module_popupsearchBtn">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.saveAndStartUpload()}
                  >
                    Save
                  </button>
                </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden />
      {/* <Footer/> */}
    </div>
  );
};
export default AuditDetails;
