import React, { useState, useEffect } from "react";
import SaveAndValidation from "./settingsSaveAndValidation"
function SettingSeoDetails(props) {
  var orgadmin = window.sessionStorage.getItem("role") == "ORG_ADMIN";
  const [seo_title, setseo_title] = useState((props.listingValuesForPassing.seo_title != "null") ? props.listingValuesForPassing.seo_title : "");
  const [seo_description, setseo_description] = useState((props.listingValuesForPassing.seo_description != "null") ? props.listingValuesForPassing.seo_description : "");
  const [seo_slug, setseo_slug] = useState((props.listingValuesForPassing.seo_slug != "null") ? props.listingValuesForPassing.seo_slug : "");
  useEffect(() => {
    if (props.listingValuesForPassing.seo_title && (props.listingValuesForPassing.seo_title != "null" || props.listingValuesForPassing.seo_title != "undefined")) {
      setseo_title((props.listingValuesForPassing.seo_title))
    } else { setseo_title("") }
    if (props.listingValuesForPassing.seo_slug && (props.listingValuesForPassing.seo_slug != "null" || props.listingValuesForPassing.seo_slug != "undefined")) {
      setseo_slug((props.listingValuesForPassing.seo_slug))
    } else { setseo_slug("") }
    if (props.listingValuesForPassing.seo_description && (props.listingValuesForPassing.seo_description != "null" || props.listingValuesForPassing.seo_description != "undefined")) {
      setseo_description((props.listingValuesForPassing.seo_description))
    } else { setseo_description("") }
    ////////////////
  }, [props.listingValuesForPassing.seo_title, props.listingValuesForPassing.seo_slug, props.listingValuesForPassing.seo_description,
  ])
  return (
    
     <div className="row">
 <div className="col-md-12">
      <div className="user_formdiv">
        <div className="userform_head">SEO</div>
        <div className="row userrow_form">
          <div className="col-md-4">
            <label className="col-form-label">SEO Title</label>
          </div>
          <div className="col-md-1">
              <span>:</span>
          </div>
          <div className="col-md-7">
            <input
              type="text"
              className="form-control"
              id="seoTitle"
              value={seo_title}
              onChange={(e) => {
                setseo_title(e.target.value);
              }}
              placeholder="SEO Title"
            />
          </div>
        </div>
        <div className="row userrow_form">
          <div className="col-md-4">
            <label className="col-form-label">
              SEO Description
            </label>
          </div>
          <div className="col-md-1">
              <span>:</span>
          </div>
          <div className="col-md-7">
            <input
              type="text"
              className="form-control"
              id="seodescription"
              value={seo_description}
              onChange={(e) => {
                setseo_description(e.target.value);
              }}
              placeholder="SEO Description"
            />
          </div>
        </div>
        <div className="row userrow_form">
          <div className="col-md-4">
            <label className="col-form-label">SEO Slug</label>
          </div>
          <div className="col-md-1">
              <span>:</span>
          </div>
          <div className="col-md-7">
            <input
              type="text"
              id="seoslug"
              value={seo_slug}
              onChange={(e) => {
                setseo_slug(e.target.value);
              }}
              className="form-control"
              placeholder="SEO Slug"
            />
          </div>
        </div>
        {/* ////////////////////////////////////////////////////////////////////////// */}
        <SaveAndValidation
           getUserData={() => props.getUserData()}
          valueForSaved={[seo_title, seo_description, seo_slug]}
          saveMode={"seoDetails"}
          organizationId={props.organizationId} />
      </div>
    </div>
     </div>
   
  );

}
export default SettingSeoDetails;
