import React, { useState, useEffect } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import $ from "jquery";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import Auditimage from "../images/left_icons/processing.png";
import DragDropFile from "../audit/dragDropFile";
import exportFromJSON from "export-from-json";
import { useLocation } from "react-router-dom";
import DataInput from "../audit/dataInput";
import { Link } from "react-router-dom";
import { backendApi, pythonBackendApi } from "../apiService";
import * as Icon from "react-feather";
const ProcessAddressDetail = () => {
  const location = useLocation();
  let pathname = location.pathname;

  var path = pathname.split("/")[2];
  const [tableId, settableId] = useState(path);
  const [addressCleaningById, setaddressCleaningById] = useState([]);
  const csvDownload = "Address Processing ";
  useEffect(() => {
    getaddressCleaningByIdData();
    
  }, []);

//   $(".button").click(function(e){
//     $(".form-control").show();
//      e.stopPropagation();
// });

const areaClick = (i,val)=>{
  $(".form-control").removeClass("addTransform");
if(val==="address1"){
  $("#address2"+i).removeClass("addTransform");
  $("#address3"+i).removeClass("addTransform");
  $("#strike_out"+i).removeClass("addTransform");
  $("#city"+i).removeClass("addTransform");
  $("#address1"+i).addClass("addTransform");
}
else if(val==="address2"){
  $("#address1"+i).removeClass("addTransform");
  $("#address3"+i).removeClass("addTransform");
  $("#strike_out"+i).removeClass("addTransform");
  $("#city"+i).removeClass("addTransform");
  $("#address2"+i).addClass("addTransform");
}
else if(val==="address3"){
  $("#address1"+i).removeClass("addTransform");
  $("#address2"+i).removeClass("addTransform");
  $("#strike_out"+i).removeClass("addTransform");
  $("#city"+i).removeClass("addTransform");
  $("#address3"+i).addClass("addTransform");
}

else if(val==="city"){
  $("#address1"+i).removeClass("addTransform");
  $("#address2"+i).removeClass("addTransform");  
  $("#strike_out"+i).removeClass("addTransform");  
  $("#address3"+i).removeClass("addTransform");
  $("#city"+i).addClass("addTransform");
}
else if(val==="strike_out"){
  $("#address1"+i).removeClass("addTransform");
  $("#address2"+i).removeClass("addTransform");  
  $("#strike_out"+i).addClass("addTransform");  
  $("#address3"+i).removeClass("addTransform");
  $("#city"+i).removeClass("addTransform");
}
}

  const migrateProcess = () => {
    $(".loder_div").attr("hidden", false);
    var apidata = {
      method: "POST",
      body: JSON.stringify({
        masterId: tableId,
      }),
    };

    pythonBackendApi("runAddressCleaning", apidata).then((data) => {
      if (data.success == "1") {
        alert("Process Started Successfully");
        getaddressCleaningByIdData();
        $(".loder_div").attr("hidden", true);
      } else {
        alert("Process Failed");
        $(".loder_div").attr("hidden", true);
      }
    });
  };
  const getaddressCleaningByIdData = (i) => {
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "getAddressCleaningDataByID",
      masterId: tableId,
    };

    backendApi(req).then((data) => {
      if (data.success === "1") {
        $("#address1" + i).attr("hidden", true);
        $("#address2" + i).attr("hidden", true);
        $("#address3" + i).attr("hidden", true);
        $("#city" + i).attr("hidden", true);
        $("#strike_out" + i).attr("hidden", true);
        $("#address1Text" + i).show();
        $("#address2Text" + i).show();
        $("#address3Text" + i).show();
        $("#strike_outText" + i).show();
        $("#cityText" + i).show();
        $("#processSaveIcon" + i).attr("hidden", true);
        $("#processEditIcon" + i).show();

        setaddressCleaningById(data.data);
        $(".loder_div").attr("hidden", true);
      } else {
        alert("Process Is Failed");
        $(".loder_div").attr("hidden", true);
      }
    });
  };
  
  const editProcess = (i) => {
    $("#address1Text" + i).hide();
    $("#address2Text" + i).hide();
    $("#address3Text" + i).hide();
    $("#strike_outText" + i).hide();
    $("#strike_out" + i).attr("hidden", false);
    $("#cityText" + i).hide();
    $("#address1" + i).attr("hidden", false);
    $("#address2" + i).attr("hidden", false);
    $("#address3" + i).attr("hidden", false);
    $("#city" + i).attr("hidden", false);
    $("#processEditIcon" + i).hide();
    $("#processSaveIcon" + i).attr("hidden", false);
    $("#processTable .form-control" + i).attr("hidden", false);
    $("#processTable .processSpan" + i).attr("hidden", true);
  };
  const updateAddressCleaning = (
    i,
    addres1,
    address2,
    address3,
    city,
    strike_out,
    id
  ) => {
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "updateAddressCleaning",
      excel_city: city === "" ? city : $("#city" + i).val(),
      address_1: addres1 === "" ? addres1 : $("#address1" + i).val(),
      address_2: address2 === "" ? address2 : $("#address2" + i).val(),
      address_3: address3 === "" ? address3 : $("#address3" + i).val(),
      strike_out: strike_out === "" ? strike_out : $("#strike_out" + i).val(),
      id: id,
    };
    backendApi(req).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        $("#saveSuccess" + id).css("display", "block");
        setTimeout(() => {
          $("#saveSuccess" + id).css("display", "none");
        }, 3000);
        getaddressCleaningByIdData(i);
      } else {
        alert("Failed");
        $(".loder_div").attr("hidden", true);
      }
    });
  };
  const downloadExcel = (id) => {
    $(".loder_div").attr("hidden", false);
    const excelRow = [];
    var addressProcessing = [
      [
        "Serial%20No.",
        "Excel%20Address",
        "Address%201",
        "Address%202",
        "Address%203",
        "City",
        "Strike%20Out"
      ],
    ];

    var req = {
      functionName: "getAddressCleaningDataByID",
      masterId: tableId,
    };
    backendApi(req).then((data) => {
      $(".loder_div").attr("hidden", true);
      for (var j = 0; j < data.data.length; j++) {
        addressProcessing.push([
          j+1,
          data.data[j].excel_address === ("" || null)
            ? data.data[j].excel_address
            : data.data[j].excel_address.replace(/,/g, '%20').replace(/ /g, '%20'),
             

          data.data[j].address_1 === ("" || null)
            ? data.data[j].address_1
            : data.data[j].address_1.replace(/,/g, '%20').replace(/ /g, '%20'),

          data.data[j].address_2 === ("" || null)
            ? data.data[j].address_2
            : data.data[j].address_2.replace(/,/g, '%20').replace(/ /g, '%20'),

          data.data[j].address_3 === ("" || null)
            ? data.data[j].address_3
            : data.data[j].address_3.replace(/,/g, '%20').replace(/ /g, '%20'),

          data.data[j].excel_city === ("" || null)
            ? data.data[j].excel_city
            : data.data[j].excel_city.replace(/,/g, '%20').replace(/ /g, '%20'),

              data.data[j].strike_out === ("" || null)
              ? data.data[j].strike_out
              : data.data[j].strike_out.replace(/,/g, '%20').replace(/ /g, '%20'),
        ]);
      }

      for (var i = 0; i < addressProcessing.length; ++i) {
        excelRow.push(addressProcessing[i].join(","));
      }
      console.log("excelRow", excelRow);
      var csvString = excelRow.join("%0A");

      var create = document.createElement("a");
      create.href = "data:attachment/csv," + csvString;
      create.target = "_Blank";
      create.download = csvDownload + ".csv";
      document.body.appendChild(create);
      create.click();
    });
  };

  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header heading="Address Processing" headerImage={Auditimage} />
        <div className="container-fluid">
          <div className="back_tocatalog row">
            <div className="col-lg-12 d-flex">
              <div className="back_tocatalog_main">
                <Link to="/processAddress" className="back-to-link">
                  <Icon.ArrowLeftCircle />
                </Link>
              </div>
              <button
                id="txtFile"
                type="button"
                className="btn btn-info mr-2"
                onClick={() => migrateProcess()}
              >
                Start Process
              </button>

              <button
                  id="txtFile"
                  type="button"
              
                  className="btn-download" 
            
                  onClick={(e) => {
                    downloadExcel();
                  }}
                >
                  Download Excel
                </button>
            </div>
          </div>
          <span id="dataId" hidden></span>
          <div className="col-md-12 tableResponsive">
            <div className="tableOuterWidth">
              <table className="table">
                <thead>
                  <tr>
                    <th className="col-1">ID</th>
                    <th className="col-2">Excel Address</th>
                    <th className="col-2">Address 1</th>
                    <th className="col-2">Address 2</th>
                    <th className="col-2">Address 3</th>
                    <th className="col-1">City</th>
                    <th className="col-1">Strike Out</th>
                    <th className="col-1 tag_tbleLastChild"></th>
                  </tr>
                </thead>
                <div className="table_Outerscroll">
                  {addressCleaningById.map((item, i) => (
                    <tbody
                      className="table_Outertbody"
                      id={"processTable" + i}
                      key={item.id}
                    >
                      <tr>
                        <td className="col-1">{item.id}</td>
                        <td className="col-2">{item.excel_address}</td>
                        <td className="col-2">
                          <span className="processSpan" id={"address1Text" + i}>
                            {item.address_1}
                          </span>
                          <textarea
                            type="text"
                            id={"address1" + i}
                            className="form-control address1"
                            hidden onClick={()=>areaClick(i,"address1")}
                          />
                        </td>
                        <td className="col-2">
                          <span className="processSpan" id={"address2Text" + i}>
                            {item.address_2}
                          </span>
                          <textarea
                            type="text"
                            id={"address2" + i}
                            className="form-control address2"
                            hidden onClick={()=>areaClick(i,"address2")}
                          />
                        </td>
                        <td className="col-2">
                          <span className="processSpan" id={"address3Text" + i}>
                            {item.address_3}
                          </span>
                          <textarea
                            type="text"
                            id={"address3" + i}
                            className="form-control"
                            hidden onClick={()=>areaClick(i,"address3")}
                          />
                        </td>
                        <td className="col-1">
                          <span className="processSpan" id={"cityText" + i}>
                            {item.excel_city}
                          </span>
                          <textarea
                            type="text"
                            id={"city" + i}
                            className="form-control"
                            hidden  onClick={()=>areaClick(i,"city")}
                          />
                        </td>
                        <td className="col-1">
                          <span
                            className="processSpan"
                            id={"strike_outText" + i}
                          >
                            {item.strike_out}
                          </span>
                          <textarea
                            type="text"
                            id={"strike_out" + i}
                            className="form-control"
                            hidden onClick={()=>areaClick(i,"strike_out")}
                          />
                        </td>
                        <td className="col-1 tag_tbleLastChild">
                          <span id={"processEditIcon" + i}>
                            <Icon.Edit
                              className="audit-search-icon"
                              onClick={() => {
                                editProcess(i);
                                $("#address1" + i).val(item.address_1);
                                $("#address2" + i).val(item.address_2);
                                $("#address3" + i).val(item.address_3);
                                $("#city" + i).val(item.excel_city);
                                $("#strike_out" + i).val(item.strike_out);
                                $("#dataId").text(item.id);
                                $(".form-control").removeClass("addTransform");
                              }}
                            />
                          </span>
                          <span id={"processSaveIcon" + i} hidden>
                            <Icon.Save
                              className="audit-save-icon"
                              onClick={() => {
                                updateAddressCleaning(
                                  i,
                                  item.address_1,
                                  item.address_2,
                                  item.address_3,
                                  item.excel_city,
                                  item.strike_out,
                                  item.id
                                );
                              }}
                            />
                          </span>
                        </td>
                      </tr>
                      <div
                        className="success-msg text-right mr-3 mb-1"
                        id={"saveSuccess" + item.id}
                      >
                        Saved successfully
                      </div>
                    </tbody>
                  ))}
                </div>
              </table>
            </div>
          </div>
        </div>
         <Footer/>
      </div>
      <div className="loder_div" hidden />
    </div>
  );
};
export default ProcessAddressDetail;
