import React, { useState, useEffect } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Link } from "react-router-dom";
import $ from "jquery";

import * as Icon from "react-feather";
import QandA from "../images/left_icons/qa.png";
import { backendApi } from "../apiService";

import BulkAddQuestions from "./BulkAddQuestions";
import AddSingleQuestion from "./AddSingleQuestion";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

let globalLocationId = 0;

function QandACreateQuestion() {
  const [locationsList, setLocationsList] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    window.sessionStorage.getItem("pageIndexMergeAudit") !== ""
      ? setTabIndex(
          parseInt(window.sessionStorage.getItem("pageIndexMergeAudit"))
        )
      : setTabIndex(0);
  });

  const excelMime = [
    "application/vnd.ms-excel",
    "application/msexcel",
    "application/x-msexcel",
    "application/x-ms-excel",
    "application/x-excel",
    "application/x-dos_ms_excel",
    "application/xls",
    "application/x-xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  useEffect(() => getLocationsList(), []);

  const getLocationsList = React.useCallback(() => {
    $(".loder_div").attr("hidden", false);
    backendApi({
      functionName: "getMasterDataForFilter",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    })
      .then(({ success, data }) => {
        console.log(data);
        $(".loder_div").attr("hidden", true);
        if (success != "1") {
          setLocationsList([]);
          return;
        }
        setLocationsList(data);
        globalLocationId = data.length;
      })
      .catch((error) => {
        console.log(error);
        alert("Something went wrong");
      });
    $(".loder_div").attr("hidden", false);
  }, []);

  return (
    <div className="main_wrapper  ">
      <LeftMenu />
      <div className="userListWrapper ">
        <Header heading="Q & A" headerImage={QandA} />
        <div className="container">
          <div className="row mb-3 ml-1">
            <Link to="/questionAnswer" className="back-to-link mr-3">
              <Icon.ArrowLeftCircle />
            </Link>
            <div className="subhead">Add Question(s)</div>
          </div>
          <div className="container-fluid">
            <Tabs
              selectedIndex={tabIndex}
              className="tabs-main"
              onSelect={(index) => {
                setTabIndex(index);
                window.sessionStorage.setItem("pageIndexMergeAudit", index);
              }}
            >
              <TabList>
                <Tab>Single Upload</Tab>
                <Tab>Bulk Upload</Tab>
              </TabList>
              <TabPanel>
                <AddSingleQuestion />
              </TabPanel>
              <TabPanel>
                <BulkAddQuestions
                  locationsList={locationsList}
                  excelMime={excelMime}
                />
              </TabPanel>
            </Tabs>
          </div>
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
}

export default QandACreateQuestion;
