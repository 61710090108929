import React, { Component } from "react";
import Chart from 'react-apexcharts'
class TotalView extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
            series: [{
              name: 'Listing on Searh',
              data: [31, 40, 28, 51]
            }, {
              name: 'Listing on Maps',
              data: [11, 32, 45, 31]
            }],
           
            options: {
              chart: {
                type: 'area',
                toolbar: {
                    show: false
                  }
              },
              responsive: [{
                breakpoint: 480,
                options: {
                  legend: {
                    position: 'bottom'
                  }
                }
              }],
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'smooth',
                show: true,
                width: 2,
              },
              xaxis: {
                categories: ["Aug 21", "Aug 22", "Aug 23", "Aug 24"]
              }, 
              legend: {
                position: 'right'
              },   
            },        
          
          };

      }
    render() {
        return (
            <div className="graph_insight">
                <Chart  options={this.state.options} series={this.state.series} type="area"  height={300} />
            </div>
        )
    }
}
export default TotalView;