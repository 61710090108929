import React from "react";
import * as Icon from "react-feather";
import $ from "jquery";
import { backendApi } from "../apiService";
import { useNavigate } from "react-router-dom";

function SavedQandAPreview({
  setSavedQandAData,
  savedQandAData,
  uniqueIdForDataset,
  locationsList,
}) {
  const navigate = useNavigate;
  const addQuestion = async () => {
    $(".loder_div").attr("hidden", false);
    for (const data of savedQandAData) {
      try {
        const result = await backendApi({
          functionName: "CreateQuestion",
          siteId:
            window.sessionStorage.getItem("switchingOrgId") === ""
              ? window.sessionStorage.getItem("organizationId")
              : window.sessionStorage.getItem("switchingOrgId"),
          question: { text: data.question },
          locationid: data.location_id,
        });
        console.log(
          "🚀 ~ file: SavedQandAPreview.jsx:27 ~ addQuestion ~ result:",
          result
        );

        if (result.success != "1") {
          alert("Something went wrong while adding the question.");
          $(".loder_div").attr("hidden", true);
          return;
        }

        const postId = result?.data?.name?.split("/");

        if (postId[0] !== "locations" && postId[2] !== "questions") {
          alert("Something went wrong while adding the question.");
          $(".loder_div").attr("hidden", true);
          return;
        }

        const addPostId = await backendApi({
          functionName: "updatePostIdAferQuestionSend",
          id: data.id,
          post_id: postId.at(-1),
        });
        console.log(
          "🚀 ~ file: SavedQandAPreview.jsx:48 ~ addQuestion ~ addPostId:",
          addPostId
        );
        if (addPostId.success != "1") {
          alert("Something went wrong while adding the question.");
          $(".loder_div").attr("hidden", true);
          return;
        }
        setTimeout(async () => {
          const addAnswer = await backendApi({
            functionName: "CreateModifyAnswer",
            location_id: data.location_id,
            postId: postId.at(-1),
            answer: { answer: { text: data.answer } },
            siteId:
              window.sessionStorage.getItem("switchingOrgId") === ""
                ? window.sessionStorage.getItem("organizationId")
                : window.sessionStorage.getItem("switchingOrgId"),
          });
          console.log(
            "🚀 ~ file: SavedQandAPreview.jsx:64 ~ addQuestion ~ addAnswer:",
            addAnswer
          );
          console.log(result.data.name, addAnswer);

          console.log(data);

          backendApi({
            functionName: "listQueAndAnsByUniqueId",
            uniqueId: uniqueIdForDataset,
          }).then((result) => {
            console.log(
              "🚀 ~ file: SavedQandAPreview.jsx:73 ~ addQuestion ~ result:",
              result
            );
            // console.log("savedQandAData", result.data);
            setSavedQandAData(result.data);
            $(".loder_div").attr("hidden", true);
          });
        }, 10000);
      } catch (error) {
        console.trace(error);
        alert("Something went wrong while adding the question!");
        $(".loder_div").attr("hidden", true);
        return;
      }
    }

    // navigate("/questionAnswer");
  };
  return savedQandAData.length ? (
    <>
      <div className="col-md-12 order-1 d-flex justify-content-end mb-4">
        <button className="btn btn-primary" onClick={addQuestion}>
          Add Questions and Answers
        </button>
      </div>
      <div className="col-md-12 tableResponsive order-2">
        <table
          className="table fileupload-table mb-0"
          style={{ minWidth: "100%" }}
        >
          <thead>
            <tr>
              <th className="col-1">Ref Code</th>
              <th className="col-2">Location Number</th>
              <th className="col-4">Question</th>
              <th className="col-3">Answer</th>
              <th className="col-2">Add</th>
            </tr>
          </thead>
          <tbody className="table_Outertbody">
            {savedQandAData.map((data, index) => (
              <tr key={index}>
                <td className="col-1">{data.id}</td>
                <td className="col-2">{data.location_id}</td>
                <td className="col-4">{data.question}</td>
                <td className="col-3">{data.answer}</td>
                <td className="col-2">
                  {/* <button className="btn btn-info p-1 m-0"> */}
                  {data.post_id ? (
                    <Icon.CheckCircle color="#34ad3e" />
                  ) : (
                    <Icon.Circle color="#3ec898" />
                  )}
                  {/* </button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  ) : (
    <></>
  );
}

export default SavedQandAPreview;
