import React, { Component } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Dashboardimage from "../images/left_icons/dashboard.png";
import * as Icon from "react-feather";

class Merge extends Component {  
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper">
          <Header heading="Merge" headerImage={Dashboardimage}/>

          <Tabs  className="tabs-main">
          <div className="container">
                <div className="row">
                        <TabList>
                            <Tab >Merged</Tab>
                            <Tab >Merge</Tab>
                        </TabList>
              </div>
              </div>
                        <TabPanel>
                        <div className="container">
                <div className="row">
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <select className="form-control">
                    <option value="">India</option>
                    <option value="">Sri Lanka</option>
                  </select>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <select className="form-control">
                    <option value="0">State</option>
                    <option value="">Kerala</option>
                  </select>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <select className="form-control">
                    <option  value="0">City</option>
                    <option  value="">Tvm</option>
                  </select>
                </div>               
                <div className="col-lg-3 col-md-4 col-sm-6 location_search">
                    <input className="form-control" type="text" placeholder="Location"/>
                    <div className="location_search_drop">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <span>Location1</span>
                        </li>
                        <li class="nav-item">
                            <span>Location2</span>
                        </li>
                        <li class="nav-item">
                            <span>Location3</span>
                        </li>
                        <li class="nav-item">
                            <span>Location4</span>
                        </li>
                        <li class="nav-item">
                            <span>Location5</span>
                        </li>
                        <li class="nav-item">
                            <span>Location6</span>
                        </li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                 <input type="text" className="form-control" placeholder="Location Id" />
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                 <input type="text" className="form-control" placeholder="Pincode" />
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <button
                    className="btn-search">
                   Search
                  </button>
                </div>
          </div>
          <div className="col-md-12 tableResponsive">
            <div className="tableOuterWidth">
          <table class="table">
                <thead>
                    <tr>
                        <th className="col-1">Location ID</th>
                        <th className="col-1">Ref Code</th>
                        <th className="col-2">Business Name</th>
                        <th className="col-2">Address</th>
                        <th className="col-1">Map Link</th>
                        <th className="col-3">Name/Email ID,Phone Number</th>                        
                        <th className="col-2">Status</th>
                    </tr>
                </thead>
                <div className="table_Outerscroll">
                <tbody className="table_Outertbody">
                    <tr>
                        <td className="col-1"></td>
                        <td className="col-1"></td>
                        <td className="col-2"></td>
                        <td className="col-2"></td>
                        <td className="col-1"></td>
                        <td className="col-3"> 
                        <form class="form-inlines">
                            <div class="form-groups">
                                <label for="nameId">Name<sup>*</sup></label>
                                <input type="text" class="form-control" id="nameId" placeholder=""/>
                            </div>
                            <div class="form-groups">
                                <label for="nameId">Email<sup>*</sup></label>
                                <input type="text" class="form-control" id="nameId" placeholder=""/>
                            </div>
                            <div class="form-groups">
                                <label for="nameId">Phone<sup>*</sup></label>
                                <input type="text" class="form-control" id="nameId" placeholder=""/>
                            </div>
                        </form>
                            </td>
                       
                        <td className="col-2"><button type="button" class="btn btn-primary">Merge</button></td>
                    </tr>
                    </tbody>
                    <tbody className="table_Outertbody">
                    <tr>
                        <td className="col-1"></td>
                        <td className="col-1"></td>
                        <td className="col-2"></td>
                        <td className="col-2"></td>
                        <td className="col-1"></td>
                        <td className="col-3"></td>
                       
                        <td className="col-2"><button type="button" class="btn btn-primary">Merge</button></td>

                    </tr>
                </tbody>
                </div>
            </table>
            </div>
          </div>
          </div>
                        </TabPanel>
                        <TabPanel>
                        <div className="container">
                
          <div className="col-md-12 tableResponsive">
            <div className="tableOuterWidth">
              <table class="table">
                <thead>
                    <tr>
                        <th className="col-2">Location ID</th>
                        <th className="col-2">Ref Code</th>
                        <th className="col-2">Business Name</th>
                        <th className="col-3">Address</th>                                            
                        <th className="col-3">Status</th>
                    </tr>
                </thead>
                <div className="table_Outerscroll">
                <tbody className="table_Outertbody">
                    <tr>
                        <td className="col-2"></td>
                        <td className="col-2"></td>
                        <td className="col-2"></td>
                        <td className="col-3"></td>                       
                        <td className="col-3">Deleted By Name, Email, Phone</td>
                    </tr>
                    </tbody>
                    <tbody className="table_Outertbody">
                    <tr>
                        <td className="col-2"></td>
                        <td className="col-2"></td>
                        <td className="col-2"></td>                       
                        <td className="col-3"></td>                       
                        <td className="col-3">Deleted By Name, Email, Phone</td>
                    </tr>
                </tbody>
                </div>
            </table>
            </div>
          </div>
          </div>
                        </TabPanel>
                    </Tabs>
          
        </div>
        <Footer/>
      </div>
    );
  }
}

export default Merge;
