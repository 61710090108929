import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
// import CampaignForm from "./campaign_form";
import AdsetForm from "./adset_form";
import Campaigns from "./campaigns";
import Adsets from "./adsets"
export default function Navbar(props) {
  let activeClassName = 'active_tab';
  const [businesses, setBusinesses] = useState([]);
  const [businessid, setBusinessId] = useState('');
  const [tab, setActiveTab] = useState('campaigns');
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    axios.post('https://w9xp1zy5ui.execute-api.ap-south-1.amazonaws.com/default/get_businesses', { userid: props.data.userid, access_token: props.data.access_token })
      .then((res) => {
        setBusinesses(res.data.data);
        setBusinessId(res.data.data.length > 0 ? res.data.data[0].id : null);
        console.log(res.data.data)
      }).catch((err) => {
        console.log(err)
      })
  }, props.data);
  return (
    <div className="tab-bar">
      <div className="tabs-left">
        <NavLink onClick={() => {
          setActiveTab('campaigns')
        }} to="" className={({ isActive }) =>
          isActive ? activeClassName : 'tab'
        }>Campaigns</NavLink>
        <NavLink onClick={() => {
          setActiveTab('adsets')
        }} to="adsets" className={({ isActive }) =>
          isActive ? activeClassName : 'tab'
        }>Adsets</NavLink>
        <NavLink onClick={() => {
          setActiveTab('ads')
        }} to="ads" className={({ isActive }) =>
          isActive ? activeClassName : 'tab'
        }>Ads</NavLink>
        <NavLink onClick={() => {
          setActiveTab('ad_images')
        }} to="ad_images" className={({ isActive }) =>
          isActive ? activeClassName : 'tab'
        }>Images</NavLink>
      </div>
      <div className="tabs-center">
        <select value={businessid} onChange={(e) => { setBusinessId(e.target.value) }} className="tab">
          <option value={null}>{'No Business'}</option>
          {businesses.map((x) => (<option value={x.id}>{x.name}</option>))}

        </select>
      </div>
      <div className="tabs-right">
        <div className="tab">Reports</div>
        <div className={visible ? activeClassName : 'tab'} onClick={() => { setVisible(true) }}>Create</div>
      </div>
      <div className="card flex justify-content-center">
        {tab == 'campaigns' ?
          <Campaigns />
          // <CampaignForm func={(x)=>{setVisible(x)}} data={{visible}}></CampaignForm>
          : tab == 'adsets' ?
            <AdsetForm func={(x) => { setVisible(x) }} data={{ visible }}></AdsetForm>
            // <Adsets/>
            : ''}
      </div>
    </div>
  )
}