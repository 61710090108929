import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import $ from "jquery";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import Auditimage from "../images/left_icons/processing.png";
import exportFromJSON from "export-from-json";
import DragDropFile from "../audit/dragDropFile";
import * as XLSX from "xlsx";
import moment from "moment";
import DataInput from "../audit/dataInput";
import { backendApi, pythonBackendApi } from "../apiService";
import { Link } from "react-router-dom";

import * as Icon from "react-feather";

const excelMime = [
  "application/vnd.ms-excel",
  "application/msexcel",
  "application/x-msexcel",
  "application/x-ms-excel",
  "application/x-excel",
  "application/x-dos_ms_excel",
  "application/xls",
  "application/x-xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
const csvDownload = "Address Processing ";

class ProcessAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AddressCleaningData: [],
      file: null,
      buffer: null,
      uploaded: false,
      dataList: [],
      fileName: "",
      getAddressCleaningDataByID: [],
    };
    this.handleFile = this.handleFile.bind(this);
    this.saveAndStartUpload = this.saveAndStartUpload.bind(this);
  }
  componentDidMount() {
    this.getAddressCleaningData();
  }
  excelFileDownload(id) {
    $(".loder_div").attr("hidden", false);
    const excelRow = [];
    var addressProcessing = [
      [
        "Serial%20No.",
        "Excel%20Address",
        "Address%201",
        "Address%202",
        "Address%203",
        "City",
        "Strike%20Out"
      ],
    ];

    var req = {
      functionName: "getAddressCleaningDataByID",
      masterId: id,
    };
    backendApi(req).then((data) => {
      $(".loder_div").attr("hidden", true);
      for (var j = 0; j < data.data.length; j++) {
        addressProcessing.push([
          j+1,
          data.data[j].excel_address === ("" || null)
            ? data.data[j].excel_address
            : data.data[j].excel_address.replace(/,/g, '%20').replace(/ /g, '%20'),
             

          data.data[j].address_1 === ("" || null)
            ? data.data[j].address_1
            : data.data[j].address_1.replace(/,/g, '%20').replace(/ /g, '%20'),

          data.data[j].address_2 === ("" || null)
            ? data.data[j].address_2
            : data.data[j].address_2.replace(/,/g, '%20').replace(/ /g, '%20'),

          data.data[j].address_3 === ("" || null)
            ? data.data[j].address_3
            : data.data[j].address_3.replace(/,/g, '%20').replace(/ /g, '%20'),

          data.data[j].excel_city === ("" || null)
            ? data.data[j].excel_city
            : data.data[j].excel_city.replace(/,/g, '%20').replace(/ /g, '%20'),

              data.data[j].strike_out === ("" || null)
              ? data.data[j].strike_out
              : data.data[j].strike_out.replace(/,/g, '%20').replace(/ /g, '%20'),
        ]);
      }

      for (var i = 0; i < addressProcessing.length; ++i) {
        excelRow.push(addressProcessing[i].join(","));
      }
      console.log("excelRow", excelRow);
      var csvString = excelRow.join("%0A");

      var create = document.createElement("a");
      create.href = "data:attachment/csv," + csvString;
      create.target = "_Blank";
      create.download = csvDownload + ".csv";
      document.body.appendChild(create);
      create.click();
    });
  }
  getAddressCleaningData() {
    var req = {
      functionName: "getAddressCleaningList",
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      role:
        window.sessionStorage.getItem("switchUserRole") === ""
          ? window.sessionStorage.getItem("role")
          : window.sessionStorage.getItem("switchUserRole"),
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        this.setState({
          AddressCleaningData: data.data,
        });
      } else {
        // alert("Failed");
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  saveAndStartUpload() {
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "saveAddressCleaningData",
      dataList: this.state.dataList,
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      fileName: this.state.fileName,
      createdBy:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };

    $("#editCommentPopup").attr("hidden", true);
    backendApi(req).then((data) => {
      if (data.success === "1") {
        alert("File Uploaded Successfully");
        window.location.reload();
      } else {
        alert("Something went wrong in uploading file");
      }
    });
  }
  OutTable = () => {
    return (
      <div className="col-md-12 tableResponsive">        
          <table className="table fileupload-table mb-0">
            <thead>
              <tr>
                <th className="col-9">Address</th>
                <th className="col-3">City</th>
              </tr>
            </thead>
            <div className="table_Outerscroll">
              {this.state.dataList.map((r, i) => (
                <tbody key={i} className="table_Outertbody">
                  <tr>
                    <td className="col-9">{r.Address}</td>
                    <td className="col-3">{r.City}</td>
                  </tr>
                </tbody>
              ))}
            </div>
          </table>
        
        <div className="button-wrapper mt-0 mb-4">
          <button
            className=" btn-submit ml-3"
            onClick={() => this.saveAndStartUpload()}
          >
            Upload
          </button>
          <button
            className="btn-cancel ml-3"
            onClick={() => {
              window.location.reload();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };
  async handleFile(files, e) {
    var buffer;
    if (files) {
      if (!excelMime.includes(files.type)) {
        alert("Please upload valid excel file");
        $(".loder_div").attr("hidden", true);
        return false;
      }
      $(".loder_div").attr("hidden", false);
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          isLoader: 0,
        });
        /* Parse data */
        const ab = e.target.result;
        
        const wb = XLSX.read(ab, { type: "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
       
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        
        const data = XLSX.utils.sheet_to_json(ws, { raw: false, header: 1 });
       if(data[0][0] !=="Address" &&data[0][1] !=="City"){
        alert("The Header Fields of Excel data is incorrect");
        $(".loder_div").attr("hidden", true);
        return false;
       }
        let DataList = [];
        let DataListTemp = [];

        let keys = [];
        keys = data[0];
        let record = {};
        for (let i = 1; i < data.length; i++) {
          let dd = data[i];
          if (dd.length > 0) {
            for (let j = 0; j < dd.length; j++) {
              record[keys[j].trim().split(" ").join("")] =
                dd[j] === undefined ? "" : dd[j].toString().trim();
            }

            let index = DataList.findIndex(
              (d) => d.Address === record.Address && d.City === record.City
            );
            
            if (index === -1) {
              DataList.push(record);
            }
            DataListTemp.push(record);
            record = {};
          }
        }
        $(".loder_div").attr("hidden", true);
        /* Update state */
        this.setState(
          {
            dataList: DataList,
            fileName: files.name,
            uploaded: true,
            isLoader: 1,
          },
          () => {
            if (DataListTemp.length !== DataList.length) {
              alert("File contains duplicate record(s)");
            }
          }
        );
      };
      reader.readAsArrayBuffer(files);
    }
  }
  migrateProcess(masterId) {
    $(".loder_div").attr("hidden", false);
    var apidata = {
      method: "POST",
      body: JSON.stringify({
        masterId: masterId,
      }),
    };

    pythonBackendApi("runAddressCleaning", apidata).then((data) => {
      if (data.success == "1") {
        $(".loder_div").attr("hidden", true);
        alert("Process Started Successfully");
        window.location.href = "/processAddressDetails/" + masterId;
      } else {
        alert("Process Failed");
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  downloadTemplate() {
    let header = ["Address", "City"];
    let data = [];
    let date =
      "Address Processig" 

    const workbook = XLSX.utils.book_new();
    console.log(workbook, "workbookworkbookworkbook");
    XLSX.utils.book_append_sheet(workbook, data, date);
    XLSX.utils.sheet_add_aoa(data, [header], { origin: "A1" });
    XLSX.writeFile(workbook, date + ".xlsx", {
      compression: true,
    });
  }
  deleteAddressCleaningDataByID(val) {
    if (!window.confirm("Do you want to Delete?")) {
      return false;
    }
    var apidata = {
      functionName: "deleteAddressCleaningDataByID",
      masterId: val,
    };
    $(".loder_div").attr("hidden", false);
    backendApi(apidata).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        this.getAddressCleaningData();
      } else {
        alert("Something went wrong in Deleting the file");
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          <Header heading="Address Processing" headerImage={Auditimage} />
          <div className="container">
            <div className="file_upload_outer mt-3 container">
              <DragDropFile handleFile={this.handleFile}>
                <div className="form-group formBottom" id="form-fileupload">
                  <DataInput handleFile={this.handleFile} />
                </div>
              </DragDropFile>
              <div
                className="col-md-12 TemplateDowloadDiv"
                title="Download template"
                onClick={() => this.downloadTemplate()}
              >
                <Icon.Download /> Download template
              </div>
              {this.state.uploaded === true && this.OutTable()}
            </div>        
           
            <div class="total_list">
              <span class="audit-txt">Total :</span>
              <span class="audit-txt-bld">{this.state.AddressCleaningData.length !="0"?this.state.AddressCleaningData.length:"0"}</span>
            </div>
            <div className="col-md-12 tableResponsive">
              <div className="tableOuterWidth table_width_lg">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="col-1">ID</th>
                      <th className="col-3">Batch</th>
                      <th className="col-3">Date</th>
                      <th className="col-2">User</th>
                      <th className="col-1">Process</th>
                      <th className="col-1"></th>
                      <th className="col-1"></th>
                    </tr>
                  </thead>
                  <div className="table_Outerscroll">
                    {this.state.AddressCleaningData.length>0?
                    this.state.AddressCleaningData.map((data, i) => (
                      <tbody className="table_Outertbody">
                        <tr>
                          <td className="col-1">{data.id}</td>
                          <td className="col-3">{data.file_name}</td>
                          <td className="col-3">{moment(data.created_on).format("DD-MM-YYYY hh:mm a")}</td>
                          <td className="col-2">{data.createdBy}</td>
                          <td className="col-1">
                            {" "}
                            <div class="create_popst_btn01">
                              {data.status === ("" || null) ? (
                                <div class="create_popst_btn01">
                                  <span 
                                    class="btn-start"
                                    onClick={() =>
                                      this.migrateProcess(
                                        data.id,
                                        data.totalcount
                                      )
                                    }
                                  >
                                    Start
                                  </span>
                                </div>
                              ) : data.status === "Completed" ? (
                                <div class="create_popst_btn01">
                                  <div
                                    title="Download excel"
                                    onClick={() =>
                                      this.excelFileDownload(data.id)
                                    }
                                  >
                                    <Icon.Download />
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                          <td
                            style={{ color: "red" }}
                            className="col-1"
                            onClick={() =>
                              this.deleteAddressCleaningDataByID(data.id)
                            }
                          >
                            <Icon.Trash />
                          </td>
                          <td className="col-1" title="Edit">
                            <Link
                              to={"/processAddressDetails/" + data.id}
                              title=" Show more"
                              state={{
                                tableId: data.id,
                              }}
                            >
                              <Icon.Eye />
                            </Link>
                          </td>

                       
                        </tr>
                      </tbody>
                    )):<p className="pl-2">No Data Found</p>}
                  </div>
                </table>
              </div>
            </div>           
          </div>
          <Footer/>
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}
export default ProcessAddress;
