import React, { useState, useEffect } from "react";
import Header from "../layout/header.jsx";
import Fraudimage from "../images/left_icons/fraud.png";
import "./style.css";
import FraudIcon from "./images/fraud.png";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { backendApi, pythonBackendApi, twitterRecentSearch } from "../apiService";
import moment from "moment";
import { TagsInput } from "react-tag-input-component";
import $ from "jquery";
import * as Icon from "react-feather";
import Pagination from "react-js-pagination";
import { useLocation } from "react-router-dom";
var XLSX = require("xlsx");
var twitterBackUpSearchVariable,
  UserBackUplistVariable = [];
export default function FraudDashboardList() {
  let currentDate = new Date();
  const [endDate, setEndDate] = useState(
    new Date(currentDate.getTime() - 240 * 1000)
  );
  const [endDateFilter, setEndDateFilter] = useState("");
  const location = useLocation();
  const [startDateFilter, setStartDateFilter] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [insertId, setInsertId] = useState("");
  const [twitterKeywords, setTwitterKeywords] = useState("");
  const [nextPageToken, setNextPageToken] = useState("");
  const [twitterSearchList, setTwitterSearchList] = useState([]);
  const [twitterSearchListBackup, setTwitterSearchListBackup] = useState([]);
  const [keyword, setkeyword] = useState([]);
  const [keywordLength, setkeywordLength] = useState(0);
  const [twitterUserList, setTwitterUserList] = useState([]);
  const [twitterUserListBackup, setTwitterUserListBackup] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [UniqueID, setUniqueID] = useState([]);
  const [RaiseFlag, setraiseFlag] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [backupdata, setbackupdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataForShowFromBackEnd, setDataForShowFromBackEnd] = useState([]);
  const [mappingFinalArray, setmappingFinalArray] = useState([]);
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6);

  useEffect(() => {
    getKeyWordFunction();
    // directFromTwitter();
  }, []);
  useEffect(() => {
    handleActivePage(1);
  }, [twitterUserList, twitterSearchList]);

  const handleActivePage = (e) => {
    console.log(twitterSearchList, "twitterUserListtwitterUserList");
    // setmappingFinalArray([])
    setCurrentPage(e);

    // let filteredArray = mappingFinalArray.filter((obj) =>
    //   keyword.some((obj2) =>
    //     obj.text.toLowerCase().includes(obj2.toLowerCase())
    //   )
    // );
    const indexOfLastPost = e * 25;
    const indexOfFirstPost = indexOfLastPost - 25;
    const currentPosts = twitterSearchList.slice(
      indexOfFirstPost,
      indexOfLastPost
    );
    console.log("currentPosts", currentPosts);
    setTotalCount(twitterSearchList.length);
    // setTwitterSearchList(currentPosts);
    setmappingFinalArray(currentPosts);
    // setTwitterSearchListBackup(currentPosts);
  };
  const dateSetting = (keyWordPassing, brand_name) => {
    let startingTimeOfDate = new Date();
    let nextDayOfdateEnd;
    startingTimeOfDate.setDate(startingTimeOfDate.getDate() - 6);
    // nextDayOfdateEnd=(new Date().getDate() - 7);
    nextDayOfdateEnd = new Date().getTime() - 240 * 1000;
    // getTwitterStatussReport(
    //   keyWordPassing,
    //   brand_name,
    //   new Date(startingTimeOfDate).toISOString(),
    //   new Date(nextDayOfdateEnd).toISOString()
    // );
    directFromTwitter(
      keyWordPassing,
      brand_name,
      new Date(startingTimeOfDate).toISOString(),
      new Date(nextDayOfdateEnd).toISOString()
    );
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const getTwitterStatussReport = (
    keyWordPassing,
    brand_name,
    startingTimeOfDate,
    nextDayOfdateEnd
  ) => {
    $(".loder_div").attr("hidden", false);
    var apidata = {
      method: "POST",
      body: JSON.stringify({
        query: brand_name.toString(),
        next_token: nextPageToken,
        endTime: endDateFilter == "" ? nextDayOfdateEnd : endDateFilter,
        startTime: startDateFilter == "" ? startingTimeOfDate : startDateFilter,
      }),
    };
    pythonBackendApi("getTwitterFraudAPIFirst100", apidata).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success == 1) {
        // let filteredArray = TwiterChatDataDetails.filter((obj) =>
        //         keyWordPassing.some((obj2) =>
        //           obj.text.toLowerCase().includes(obj2.toLowerCase())

        //         )
        //       );
        UserBackUplistVariable = data.userList;
        twitterBackUpSearchVariable = data.data;
        setTwitterSearchListBackup(data.data);
        setTwitterUserListBackup(data.userList);

        let a = keyWordPassing.filter((obj) => {
          if (obj != "") return true;
          return false;
        });
        if (a.length > 0) {
          let filteredArray = data.data.filter((obj) =>
            keyWordPassing.some((obj2) =>
              obj.text.toLowerCase().includes(obj2.toLowerCase())
            )
          );
          // setTotalCount(filteredArray.length);
          console.log(filteredArray, "filteredArrayfilteredArray");
          setTwitterSearchList(filteredArray);
          let userFilteredArray = data.userList.filter((obj) =>
            keyWordPassing.some(
              (obj2) =>
                obj.username.toLowerCase().includes(obj2.toLowerCase()) ||
                obj.name.toLowerCase().includes(obj2.toLowerCase())
            )
          );
          console.log("sdygsedfr", userFilteredArray);
          setTwitterUserList(userFilteredArray);
          // getTwitterStatussReportSecondeCall(keyWordPassing, brand_name, startingTimeOfDate, nextDayOfdateEnd, filteredArray, userFilteredArray, data.data, data.userList)

          //  setmappingFinalArray(filteredArray)
        } else {
          // console.log(filteredArray,"filteredArrayfilteredArray");
          //  setmappingFinalArray(filteredArray)
          setTotalCount(data.data.length);
          setTwitterSearchList(data.data);
          setTwitterUserList(data.userList);
          // getTwitterStatussReportSecondeCall(keyWordPassing, brand_name, startingTimeOfDate, nextDayOfdateEnd, data.data, data.userList, data.data, data.userList)
        }
        // handleActivePage(1)
      }
    });
  };

  const getTwitterStatussReportSecondeCall = (
    keyWordPassing,
    brand_name,
    startingTimeOfDate,
    nextDayOfdateEnd,
    searchList,
    userList,
    searchBackup,
    userBackup
  ) => {
    var apidata = {
      method: "POST",
      body: JSON.stringify({
        query: brand_name.toString(),
        next_token: nextPageToken,
        endTime: endDateFilter == "" ? nextDayOfdateEnd : endDateFilter,
        startTime: startDateFilter == "" ? startingTimeOfDate : startDateFilter,
      }),
    };

    pythonBackendApi("getTwitterFraudAPI", apidata).then((data) => {
      if (data.success == "1") {
        console.log("searchListsearchList", searchList);
        let filteredArraySearchBackUp = data.data.filter((obj) => {
          if (searchList.some((obj2) => obj.id != obj2.id)) return true;
          return false;
        });
        let filterArraayUserBAckUp = data.userList.filter((obj) => {
          if (userList.some((obj2) => obj.id != obj2.id)) return true;
          return false;
        });
        UserBackUplistVariable = data.userList;
        twitterBackUpSearchVariable = data.data;
        // UserBackUplistVariable = [...userBackup, ...filterArraayUserBAckUp]
        // twitterBackUpSearchVariable = [...searchBackup, ...filteredArraySearchBackUp]
        setTwitterSearchListBackup([
          ...searchBackup,
          ...twitterBackUpSearchVariable,
        ]);
        setTwitterUserListBackup([...userBackup, ...UserBackUplistVariable]);
        let a = keyWordPassing.filter((obj) => {
          if (obj != "") return true;
          return false;
        });
        if (a.length > 0) {
          let filteredArray = data.data.filter((obj) =>
            keyWordPassing.some((obj2) =>
              obj.text.toLowerCase().includes(obj2.toLowerCase())
            )
          );
          setTwitterSearchList(filteredArray);
          // setTwitterSearchList(filteredArray);

          setTotalCount(filteredArray.length + totalCount);

          console.log(filteredArray, "filteredArray1235678788");
          console.log(userList, "userList123333333");

          let userFilteredArray = data.userList
            .filter((obj) =>
              keyWordPassing.some(
                (obj2) =>
                  obj.username.toLowerCase().includes(obj2.toLowerCase()) ||
                  obj.name.toLowerCase().includes(obj2.toLowerCase())
              )
            )
            .filter((secObj) =>
              UserBackUplistVariable.some((someObj) => someObj.id != secObj.id)
            );
          setTwitterUserList(userFilteredArray);
          //  setmappingFinalArray(filteredArray)
        } else {
          let filteredArraySearch = data.data.filter((obj) => {
            if (twitterBackUpSearchVariable.some((obj2) => obj.id != obj2.id))
              return true;
            return false;
          });
          let filterArraayUser = data.userList.filter((obj) => {
            if (UserBackUplistVariable.some((obj2) => obj.id != obj2.id))
              return true;
            return false;
          });
          console.log(
            filterArraayUser,
            "filterArraayUserfilterArraayUserfilterArraayUser"
          );
          //  setmappingFinalArray(filteredArray)
          setTotalCount(filteredArraySearch.length + totalCount);
          setTwitterSearchList(data.data);
          setTwitterUserList(data.userList);
        }
      }
    });

    // }
  };

  const directFromTwitter = async (
    keyWordPassing,
    brand_name,
    startingTimeOfDate,
    nextDayOfdateEnd
  ) => {
    var apiReq = {
      query: brand_name.toString(),
      next_token: nextPageToken,
      endTime: endDateFilter == "" ? nextDayOfdateEnd : endDateFilter,
      startTime: startDateFilter == "" ? startingTimeOfDate : startDateFilter,
    };
    twitterRecentSearch(apiReq).then((response) => {
      UserBackUplistVariable = response.data.includes.users;
      twitterBackUpSearchVariable = response.data.data;
      setTwitterSearchListBackup(response.data.data);
      setTwitterUserListBackup(response.data.includes.users);

      let a = keyWordPassing.filter((obj) => {
        if (obj != "") return true;
        return false;
      });
      if (a.length > 0) {
        let filteredArray = response.data.data.filter((obj) =>
          keyWordPassing.some((obj2) =>
            obj.text.toLowerCase().includes(obj2.toLowerCase())
          )
        );
        // setTotalCount(filteredArray.length);
        console.log(filteredArray, "filteredArrayfilteredArray");
        setTwitterSearchList(filteredArray);
        let userFilteredArray = response.data.includes.users.filter((obj) =>
          keyWordPassing.some(
            (obj2) =>
              obj.username.toLowerCase().includes(obj2.toLowerCase()) ||
              obj.name.toLowerCase().includes(obj2.toLowerCase())
          )
        );
        console.log("sdygsedfr", userFilteredArray);
        setTwitterUserList(userFilteredArray);
        // getTwitterStatussReportSecondeCall(keyWordPassing, brand_name, startingTimeOfDate, nextDayOfdateEnd, filteredArray, userFilteredArray, response.data.data, response.data.includes.users)

        //  setmappingFinalArray(filteredArray)
      } else {
        // console.log(filteredArray,"filteredArrayfilteredArray");
        //  setmappingFinalArray(filteredArray)
        setTotalCount(response.data.data.length);
        setTwitterSearchList(response.data.data);
        setTwitterUserList(response.data.includes.users);
        // getTwitterStatussReportSecondeCall(keyWordPassing, brand_name, startingTimeOfDate, nextDayOfdateEnd, response.data.data, response.data.includes.users, response.data.data, response.includes.users)
      }
    });
  };

  const getKeyWordFunction = () => {
    const apiReq = {
      functionName: "getfraudulentDetectionDetailsById",
      id: location.state.insertId,
    };
    backendApi(apiReq).then((obj) => {
      setTwitterKeywords(obj.result[0].brand_name);
      obj.result[0].keywords !== "" &&
        setkeyword(obj.result[0].keywords.split(","));
      dateSetting(
        obj.result[0].keywords.split(","),
        obj.result[0].brand_name.split(",")
      );
    });
  };

  const searchAmongRecentList = (query) => {
    if (query == "") {
      setTwitterSearchList(twitterBackUpSearchVariable);
    } else {
      let filteredList = twitterBackUpSearchVariable.filter((obj) =>
        obj.id.includes(query)
      );
      setTwitterSearchList(filteredList);
    }
  };
  const searchByDate = (date) => {
    $(".loder_div").attr("hidden", false);
    setEndDate(date);
    let a = keyword.filter((obj) => {
      if (obj != "") return true;
      return false;
    });
    if (a.length > 0) {
      const formattedDate = moment(date).format("YYYY-MM-DD").toString();
      console.log(formattedDate, "formattedDate");
      const filteredArray = twitterSearchListBackup.filter(
        (obj) => moment(obj.created_at).format("YYYY-MM-DD") == formattedDate
      );
      filteredArray.filter((obj) =>
        keyword.some((obj2) =>
          obj.text.toLowerCase().includes(obj2.toLowerCase())
        )
      );
      console.log(
        filteredArray,
        "filteredArrayfilteredArray................./////////////"
      );
      $(".loder_div").attr("hidden", true);
      setTotalCount(filteredArray.length);
      setTwitterSearchList(filteredArray);
      // setmappingFinalArray(currentPosts)
    } else {
      //  if (a.length > 0) {
      const formattedDate = moment(date).format("YYYY-MM-DD").toString();
      const filteredArray = twitterSearchListBackup.filter(
        (obj) => moment(obj.created_at).format("YYYY-MM-DD") == formattedDate
      );
      setTwitterSearchList(filteredArray);
      setTotalCount(filteredArray.length);
      // console.log(filteredArray,"filteredArrayfilteredArrayrrrrrrrrrrrrrr");
      // setmappingFinalArray(currentPosts)
      // } else {
      //   setTotalCount(TwiterChatDataDetails.length);
      //   setTwitterSearchList(TwiterChatDataDetails);
      //  setTwitterUserList(TwiterUserDateadata);

      // const formattedDate = moment(date).format("YYYY-MM-DD").toString();

      // const filteredArray = twitterSearchListBackup.filter((obj) =>
      //   obj.created_at.includes(formattedDate)
      // );

      // const indexOfLastPost = currentPage * 25;
      // const indexOfFirstPost = indexOfLastPost - 25;
      // const currentPosts = filteredArray.slice(
      //   indexOfFirstPost,
      //   indexOfLastPost
      // );
      // $(".loder_div").attr("hidden", true);
      // setTotalCount(filteredArray.length)
      // setTwitterSearchList(currentPosts);
      // setmappingFinalArray(currentPosts)

      $(".loder_div").attr("hidden", true);

      // }
    }
  };
  // const searchByDate = (date) => {
  //   $(".loder_div").attr("hidden", false);
  //   let endingDate = new Date(date);
  //   let startingDate = new Date(date);
  //   startingDate.setHours(0, 0, 0, 0);
  //   // setEndDate(date);
  //   setNextPageToken("");
  //   setStartDateFilter(startingDate);
  //   setEndDateFilter(new Date(endingDate.getTime() - 240 * 1000).toISOString());
  //   $(".loder_div").attr("hidden", true);
  // };
  const exportData = () => {
    const data = twitterSearchList.map((obj) => ({
      Platform: "Twitter",
      "Unique Id": obj.id,
      "Created Date": moment(obj.created_at).format("MMM DD, YYYY hh:mm a"),
      Text: obj.text,
    }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    worksheet["!cols"] = [
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 50 },
      { width: 50 },
      { width: 50 },
    ];
    worksheet["!cols"].splice(3, 0, { width: 20 }); // Add a column with width 20 at index 3 (after the 'Rating' column)
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Reviews");
    XLSX.writeFile(workbook, "twitterRecent.xlsx");
  };
  const getSearchWithKey = () => {
    $(".loder_div").attr("hidden", false);
    let updatedSearchList = [];
    let UniqueID = [];
    let filteredArray = [];
    let userFilteredArray = [];
    if (keyword.length > 0) {
      filteredArray = twitterBackUpSearchVariable.filter((obj) =>
        keyword.some((obj2) =>
          obj.text.toLowerCase().includes(obj2.toLowerCase())
        )
      );
      console.log(
        "twitterUserListBackuptwitterUserListBackup",
        twitterUserListBackup
      );
      userFilteredArray = UserBackUplistVariable.filter((obj) =>
        keyword.some((obj2) => {
          if (
            obj?.name?.toLowerCase().includes(obj2.toLowerCase()) ||
            obj?.user_name?.toLowerCase().includes(obj2.toLowerCase())
          )
            return true;
        })
      );
      $(".loder_div").attr("hidden", true);
      setTotalCount(filteredArray.length);
      setTwitterSearchList(filteredArray);
      setTwitterUserList(userFilteredArray);
    } else {
      $(".loder_div").attr("hidden", true);
      setTwitterSearchList(twitterBackUpSearchVariable);
      setTotalCount(twitterBackUpSearchVariable.length);
      setTwitterUserList(UserBackUplistVariable);
    }
  };
  const setSelected = (value) => {
    setkeyword(value);
  };
  const raiseFullFlag = () => {
    var FullvalueSend = [];
    twitterSearchListBackup.forEach((updatedObj, i) => {
      var emailBody = {
        uniqueId: updatedObj.id,
        tweetTime: moment(updatedObj.created_at).format("DD/MM/yyy").toString(),
        text: updatedObj.text,
      };
      FullvalueSend.push(emailBody);
    });
    SendMail(FullvalueSend);
  };
  const raiseFlag = (value) => {
    var emailBody = [];
    var valuesToset = {
      uniqueId: value.id,
      tweetTime: moment(value.created_at).format("DD/MM/yyy").toString(),
      text: value.text,
    };
    emailBody.push(valuesToset);
    SendMail(emailBody);
  };
  const SendMail = (emailBody) => {
    var req = {
      functionName: "sentEmailFraudtwitter",
      brand: "Kotak",
      dataSet: emailBody,
    };
    backendApi(req).then((response) => {
      $(".loder_div").attr("hidden", true);
      if (response.success === "1") {
        alert("Email sent successfully");
      }
    });
  };
  const userFilterTweet = (userData) => {
    let filteredArray = twitterSearchListBackup.filter(
      (obj) => obj.author_id == userData.id
    );
    setTotalCount(filteredArray.length);
    setTwitterSearchList(filteredArray);
  };
  useEffect(() => {}, [twitterUserListBackup, twitterSearchListBackup]);

  return (
    <div className="main_wrapper">
      <div className="userListWrapper fraudulent_detection_div">
        <Header
          heading="Convergensee - Fraud Dashboard"
          headerImage={Fraudimage}
        />
        <div className="container-fluid bg-white pt-4">
          <div className="row row-search search_fraud_row">
            <div className="col-lg-4 col-md-4 col-sm-6 search_fraud">
              <input
                type="text"
                className="form-control"
                placeholder="Search By Unique ID"
                onChange={(e) => searchAmongRecentList(e.target.value)}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <ReactDatePicker
                id="endDate"
                selected={endDate}
                onChange={(endDate) => searchByDate(endDate)}
                //  minDate={new Date()}
                minDate={sevenDaysAgo}
                dateFormat="dd-MM-yyyy"
                className="form-control fraud_datepicker"
                maxDate={new Date()}
                // placeholderText="By Date"
                // disabled={false}
                // highlightToday
                // showYearDropdown
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <select className="form-control">
                <option>By Platform</option>
                <option selected>Twitter</option>
              </select>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 div_tags_input">
              <TagsInput
                key={keywordLength}
                value={keyword}
                onChange={setSelected}
                name="keywords"
                placeHolder="Enter Fraud Keywords"
                style={{ width: "100%" }}
                className="fraud_search"
              />
              {/* <select disabled className="form-control" required>
                <option>By Fraud Status</option>
                <option>1</option>
              </select> */}
            </div>
            {/* <div className="col-lg-1 col-md-4 col-sm-6 ">
              <div className="form-control">
                {" "}
                <span onClick={() => getTwitterSearch()}>
                  Next
                </span>
              </div>
            </div> */}
            <div className="col-lg-1 col-md-4 col-sm-6 ">
              <div className="form-control">
                {" "}
                <span onClick={() => getSearchWithKey()}>
                  Search
                  {/* <Icon.ArrowRight /> */}
                </span>
                {/* <img
                  src={FraudIcon}
                  className="img-fluid"
                  onClick={() => getTwitterSearch()}
                /> */}
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 ">
              <div className="form-control">
                {" "}
                <span onClick={() => raiseFullFlag()}>
                  Send Email (All Tweets)
                  {/* <Icon.ArrowRight /> */}
                </span>
                {/* <img
                  src={FraudIcon}
                  className="img-fluid"
                  onClick={() => getTwitterSearch()}
                /> */}
              </div>
            </div>
          </div>
          {totalCount > 25 ? (
            <div className="pagination_main  tag_postListNext">
              <Pagination
                hideDisabled
                activePage={currentPage}
                itemsCountPerPage="25"
                totalItemsCount={totalCount}
                pageRangeDisplayed="10"
                onChange={(e) => handleActivePage(e)}
              />
            </div>
          ) : (
            ""
          )}
          <div className="row">
            <div className="col-md-2 fraud_left_main_head">
              <div className="text-center twitter_name">Twitter Handles</div>
              <div className="fraud-left-wrapper w-100">
                {/* {console.log(mappingFinalArray, "mappingFinalArraymappingFinalArray")} */}
                <div className="fraud-left-top fraud-left-top_height">
                  {twitterUserList.map((obj, index) => {
                    let name = obj.name;
                    let userName = obj.username;
                    let userId = obj.id;
                    return (
                      <span
                        onClick={() => userFilterTweet(obj)}
                        className="fraud-left_list"
                      >
                        <div className="fraud-left-top-mainhead">{name}</div>
                        <div className="fraud-left-top-subhead">{userName}</div>
                      </span>
                    );
                  })}
                </div>
                {/* <div className="fraud-left-top-subhead">Frauds</div> */}
              </div>
            </div>

            {/* {console.log(twitterUserListBackup, "twitterUserListBackuplllllllllllllllllllllllllll")} */}
            <div className="col-md-10">
              <div className="row fraud_dash_main_list">
                {mappingFinalArray.map((obj, i) => {
                  let createdAt = moment(obj.created_at).format(
                    "MMM DD, YYYY hh:mm a"
                  );

                  let user = UserBackUplistVariable.filter(
                    (_) => _.id == obj.author_id
                  );
                  return (
                    <div className="col-md-6">
                      <div className="fradu_dash_inner_list">
                        <div className="fraud_dash_main_inner">
                          <div className="fraud_unique_div">
                            <div className="fraud_unique_id">Unique ID :</div>
                            <div className="fraud_unique_value">{obj.id}</div>
                          </div>
                          <div className="fraud_unique_div">
                            <div className="fraud_unique_id">Author :</div>
                            <div className="fraud_unique_value">
                              <a
                                href={`https://twitter.com/${user[0].username}`}
                                target="_blank"
                              >
                                {user[0].name}
                                <span style={{ display: "block" }}>
                                  @{user[0].username}
                                </span>
                              </a>
                            </div>
                          </div>
                          <div className="fraud_unique_address_div">
                            <div className="fraud_div_review">Review : </div>
                            <a
                              href={`https://twitter.com/twitter/statuses/${obj.id}`}
                              target="_blank"
                            >
                              <div
                                className="fraud_div_review_address"
                                dangerouslySetInnerHTML={{ __html: obj.text }}
                              ></div>
                            </a>
                          </div>
                          <div className="row platform_row_div">
                            <div className="col-md-6 platform_row_divsix">
                              <div className="fraud_unique_id">Platform :</div>
                              <div className="fraud_twitter_inner">Twitter</div>
                            </div>
                            <div className="col-md-6 platform_row_divsix">
                              <div className="fraud_unique_id">Date :</div>
                              <div className="fraud_twitter_inner">
                                {createdAt}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="btn-group fraud_btn_group" role="group">
                          {/* <button
                            type="button"
                            className="btn fraud_btn_group_info"
                          >
                            Cancel
                          </button> */}
                          <button
                            onClick={(e) => raiseFlag(obj)}
                            type="button"
                            className="btn fraud_btn_group_primary"
                          >
                            Raise Flag
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="loder_div" hidden />
    </div>
  );
}
