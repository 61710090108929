import React, { useState, useEffect } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import DragDropFile from "../home/dragDropFile";
import axios from "axios";
import DataInputGpost from "../google-post/dataInputGpost";
import { useLocation } from "react-router-dom";
import cloudImg from "../images/cloud-computing.png";
import GooglePostimage from "../images/left_icons/Google-Post.png";
import * as Icon from "react-feather";

import $ from "jquery";
import moment from "moment";
import { backendApi, apiCallingPost } from "../apiService";
import AWS from "aws-sdk";
import * as XLSX from "xlsx";
import googlePostTemplate from "../docs/googlePostTemplate_excel.xlsx";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
// import TimePicker from "rc-time-picker";
// import "rc-time-picker/assets/index.css";
// import TimePicker from "react-time-picker";
const format = "h:mm a";
const s3ImageUrl =
  "http://tagtree.s3-website.ap-south-1.amazonaws.com/production/images";
const s3Region = "us-east-1";
const s3Bucket = "tagtree";
const s3BucketMainFolder = "production";
const S3BucketImagesFolder = "images";

var hour = [];
var minute = [];
var globalLocationId = 0;
function HistoryLocation() {
  // const [firstDescription, setFirstDescription] = useState(false);
  // const [eventTitle, setEventTitle] = useState("");
  // const [photos, setPhotos] = useState(false);
  // const [addButton, setAddButton] = useState(false);
  // const [linkForButton, setLinkForButton] = useState(false);
  // const [title, setTitle] = useState(false);
  // const [datePickers, setDatePickers] = useState(false);
  // const [startTime, setStartTime] = useState("");
  // const [endTime, setEndTime] = useState("");
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  // const [description, setDescription] = useState("");
  // const [eventType, setEventType] = useState("");
  // const [imageUrl, setImageUrl] = useState("");
  // const [imageName, setImageName] = useState("");
  // const [localPostList, setLocalPostList] = useState([]);
  // const [isLoader, setIsLoader] = useState(0);
  // const [dataList, setDataList] = useState([]);
  const [dropdownList, setdropdownList] = useState([]);
  const [managerId, setmanagerId] = useState("");
  const [locationshistoryList, setlocationshistoryList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const [temporaryLocationsList, setTemporaryLocationsList] = useState([]);
  const [selectedLocationsArray, setSelectedLocationsArray] = useState([]);
  const [administrativeAreaList, setAdministrativeAreaList] = useState([]);
  const [localityList, setLocalityList] = useState([]);
  const [localityListBackup, setLocalityListBackup] = useState([]);
  const [administrativeAreaFilterEnable, setAdministrativeAreaFilterEnable] =
    useState(0);
  const [
    administrativeAreaFilterEnableArray,
    setAdministrativeAreaFilterEnableArray,
  ] = useState(0);
  const [
    selectedLocationsCompleteDataArray,
    setSelectedLocationsCompleteDataArray,
  ] = useState([]);
  const [createPostButton, setCreatePostButton] = useState(false);

  const excelMime = [
    "application/vnd.ms-excel",
    "application/msexcel",
    "application/x-msexcel",
    "application/x-ms-excel",
    "application/x-excel",
    "application/x-dos_ms_excel",
    "application/xls",
    "application/x-xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const location = useLocation();
  let pathname = location.pathname;

  var path = pathname.split("/")[2];

  useEffect(() => {
    getLocationsList();
getlocationHistorylist()
    // Hours();
    // Minutes();
    // getPostList();
    if (window.sessionStorage.getItem("role") === "ROLE_USER") {
      $("#roleSelector").attr("hidden", true);
    } else if (window.sessionStorage.getItem("role") !== "ROLE_SUPER_ADMIN") {
      $("#roleSelector").attr("hidden", false);
      getUserList("");
    }
    // else {
    //   $("#roleSelector").attr("hidden", true);
    // }
    if (window.sessionStorage.getItem("switchingOrgId") !== "") {
      getUserList(window.sessionStorage.getItem("switchingOrgId"));
    }
    if (window.sessionStorage.getItem("switchUserId") !== "") {
      getUserList(window.sessionStorage.getItem("switchingOrgId"));
      $("#roleSelector").attr("hidden", false);
      $("#goBackSelector").attr("hidden", false);
    }
  }, []);

  const getlocationHistorylist =()=> {
    function localhost(postData) {
      return axios
        .post("http://localhost:8080/listHistoryLocation", postData)
        .then((data) => data.data)
        .then((res) => res);
    }

    const reviewAPIData = {
      functionName: "listHistoryLocation",

      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
          id:path
    };

    localhost(reviewAPIData).then((data) => {
      if (data.response.success === "1") {
        $(".loder_div").attr("hidden", true);

        setlocationshistoryList(data.response.data)

      } else {
        $(".loder_div").attr("hidden", true);
        alert("Failed");
      }
    });
  }

  const getUserList = (e) => {
    var req = {
      functionName: "listUserByOrganization",
      siteId: e === "" ? window.sessionStorage.getItem("organizationId") : e,
    };
    // $(".loder_div").attr("hidden", false);
    apiCallingPost(req).then((data) => {
      if (data.success === "1") {
        // $(".loder_div").attr("hidden", true);

        let array = data.result.filter(
          (item) =>
            item.role == "ROLE_ADMIN" ||
            item.role == "ORG_ADMIN" ||
            item.role == "ROLE_SUPER_ADMIN" ||
            item.role == "CHAT_MANAGER"
        );

        setdropdownList(data.result);
      } else {
        // $(".loder_div").attr("hidden", true);
      }
    });
  };
  const setFilter = async (e) => {
    if (e.target.id === "administrativeList") {
      if (e.target.value !== "0") {
        setAdministrativeAreaFilterEnable(1);
        var filteredLocations = temporaryLocationsList.filter((obj) => {
          return obj.storefrontAddress.administrativeArea === e.target.value;
        });
        var localityArray = [];
        filteredLocations.forEach((obj) => {
          return (
            !localityArray.includes(obj.storefrontAddress.locality) &&
            localityArray.push(obj.storefrontAddress.locality)
          );
        });
        setLocationsList(filteredLocations);
        setAdministrativeAreaFilterEnableArray(filteredLocations);
        setLocalityList(localityArray);
      } else {
        setLocationsList(temporaryLocationsList);
        setLocalityList(localityListBackup);
        setAdministrativeAreaFilterEnable(0);
      }
    } else if (e.target.id === "localityFilter") {
      if (e.target.value !== "0") {
        setAdministrativeAreaFilterEnable(1);
        var filteredLocations = temporaryLocationsList.filter((obj) => {
          return obj.storefrontAddress.locality === e.target.value;
        });
        // var localityArray = [];
        // filteredLocations.forEach((obj) => {
        //   return (
        //     localityArray.includes(obj.storefrontAddress.locality) &&
        //     localityArray.push(obj.storefrontAddress.locality)
        //   );
        // });
        setLocationsList(filteredLocations);
        // setLocalityList(localityArray);
      } else {
        setAdministrativeAreaFilterEnable(0);
        setLocationsList(temporaryLocationsList);
      }
    }
  };
  const getLocationsList = () => {
    $(".loder_div").attr("hidden", false);
    const apiJson = {
      functionName: "GetLocationsUnderAccount",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    apiCallingPost(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        setLocationsList(data.data.locations);
        setTemporaryLocationsList(data.data.locations);
        globalLocationId = data.data.locations.length;
        for (let i = 0; i < globalLocationId; i++) {
          $("#selectLocationPost" + i).prop("checked", false);
        }
        data.data.locations.forEach((obj) => {
          !administrativeAreaList.includes(
            obj.storefrontAddress.administrativeArea
          ) &&
            administrativeAreaList.push(
              obj.storefrontAddress.administrativeArea
            );
          !localityList.includes(obj.storefrontAddress.locality) &&
            localityList.push(obj.storefrontAddress.locality);
        });
        setLocalityListBackup(localityList);
      } else {
        setLocationsList([]);
      }
    });
  };
  const selectLocation = (e, data, id) => {
    if (
      e.target.checked === true &&
      !selectedLocationsArray.includes(data.name.split("/")[1])
    ) {
      setSelectedLocationsCompleteDataArray((object) => [...object, data]);
      setSelectedLocationsArray((object) => [
        ...object,
        data.name.split("/")[1],
      ]);
      setCreatePostButton(true);
    } else if (e.target.checked === false) {
      if (selectedLocationsArray.length !== locationsList.filter) {
        $("#selectAllLocations").prop("checked", false);
      }

      let index = selectedLocationsArray.indexOf(data.name.split("/")[1]);
      selectedLocationsArray.splice(index, 1);
      selectedLocationsCompleteDataArray.splice(index, 1);

      if (selectedLocationsArray.length === 0) {
        setCreatePostButton(false);
      } else {
        setCreatePostButton(true);
      }
    }
    console.log(selectedLocationsArray, "gooooooooooooooooooo");
  };
  const selectAllLocations = (e) => {
    if (e.target.checked === true) {
      for (let i = 0; i < globalLocationId; i++) {
        $("#selectLocationPost" + i).prop("checked", true);
      }
      if (selectedLocationsArray.length > 0) {
        let filteredArray = locationsList.filter(
          (value) => !selectedLocationsArray.includes(value.name.split("/")[1])
        );
        filteredArray.map((obj) => {
          selectedLocationsArray.push(obj.name.split("/")[1]);
          selectedLocationsCompleteDataArray.push(obj);
        });
      } else {
        locationsList.map((obj) => {
          selectedLocationsArray.push(obj.name.split("/")[1]);
          selectedLocationsCompleteDataArray.push(obj);
        });
      }
      if (selectedLocationsArray.length === 0) {
        setCreatePostButton(false);
      } else {
        setCreatePostButton(true);
      }
    } else {
      for (let i = 0; i < globalLocationId; i++) {
        $("#selectLocationPost" + i).prop("checked", false);
      }
      setSelectedLocationsArray([]);
      setSelectedLocationsCompleteDataArray([]);
      setCreatePostButton(false);
    }

    var selectAllList = [];
    // let includes = locationsList.some(item => selectedLocationsArray.includes(item.name.split("/")[1]))
    // console.log("foundfound", filteredArray);
  };
  const changestatus = (id) => {


    function localhost(postData) {
      return axios
        .post("http://localhost:8080/statusChangelocation", postData)
        .then((data) => data.data)
        .then((res) => res);
    }
    var statusTrue =
      $('input[name="status' + id + '"]:checked').val() === "on" ? 0 : 1;
    const reviewAPIData = {
      functionName: "statusChangelocation",
      is_active:statusTrue,
      id:id

    };
    $(".loder_div").attr("hidden", false);
    localhost(reviewAPIData).then((data) => {
      if (data.response.success === "1") {
        $(".loder_div").attr("hidden", true);

        getLocationsList();
        getlocationHistorylist()
      } else {
        $(".loder_div").attr("hidden", true);
        alert("Failed");
      }
    });
  };
  return (
    <div className="main_wrapper  ">
      <LeftMenu />
      <div className="userListWrapper ">
        <Header heading="History Location" headerImage={GooglePostimage} />
        <div className="container-fluid">
          <div className="row mb-3 ml-1">
            <Link to="/chatForm" className="back-to-link mr-3">
              <Icon.ArrowLeftCircle />
            </Link>
            <div className="subhead"> History Location</div>
          </div>
          {/* <div className="row row-search ">
            <div className="col-lg-2 col-md-4 col-sm-6">
              <select
                id="administrativeList"
                onChange={(e) => {
                  setFilter(e);
                }}
                className="form-control"
              >
                <option value="0">State</option>
                {administrativeAreaList.map((data) => (
                  <option value={data}>{data}</option>
                ))}
              </select>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 ">
              <select
                id="localityFilter"
                onChange={(e) => setFilter(e)}
                className="form-control"
              >
                <option value="0">City</option>
                {localityList.map((data) => (
                  <option value={data}>{data}</option>
                ))}
              </select>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 location_search">
              <input
                className="form-control"
                type="text"
                placeholder="Search Business "
                onChange={(e) => {
                  if (e.target.value.length > 3) {
                    var local = locationsList.filter((obj) => {
                      let title = obj.title.toLocaleLowerCase();
                      return title.includes(e.target.value.toLocaleLowerCase());
                    });
                    setLocationsList(local);
                  } else {
                    administrativeAreaFilterEnable === 1
                      ? setLocationsList(administrativeAreaFilterEnableArray)
                      : setLocationsList(temporaryLocationsList);
                  }
                }}
              />

          
            </div>
          </div> */}
          <br />

          <hr className="mt-0"></hr>
          <div className="col-md-12 tableResponsive">
            <div className="tableOuterWidth">
              <table className="table">
                <thead>
                  <tr>
                  <th className="col-1">Id</th>
                   <th className="col-1">Agent name</th>
                    <th className="col-2">Location(s)</th>
                    <th className="col-1">Created on</th>
                    <th className="col-2">Address</th>
                    <th className="col-2">Locality</th>
                    <th className="col-2">Area</th>
                    <th className="col-1">Status</th>
                  </tr>
                </thead>
                <div className="table_Outerscroll">
                  {locationshistoryList.length>0 ?
                  locationshistoryList.map((data, i) => (
                    <tbody className="table_Outertbody">
                      <tr>
                      <td className="col-1">
                            {data.id}
                        </td>
                        <td className="col-1">
                            {data.agent_name}
                        </td>
                        <td className="col-2">{data.title}</td>
                        <td className="col-1"> {moment(data.created_on).format("DD-MM-YYYY")}</td>
                        <td className="col-2">
                          {data.addressLines}
                        </td>
                        <td className="col-2">
                          {data.locality}
                        </td>
                        <td className="col-2">
                          {data.administrativeArea}
                        </td>
                   
                        <td className="col-1">
                                <span className="switch round"></span>
                                <span>
                                  <label className="switch_items">
                                    <input
                                      id={"status" + data.id}
                                      name={"status" + data.id}
                                      type="checkbox"
                                      defaultChecked={
                                        data.is_active == 0 ? true : false
                                      }
                                      onClick={() =>
                                        changestatus(
                                          data.id,
                        
                                        )
                                      }
                                    />
                                    <span className="switch round"></span>
                                  </label>
                                </span>
                              </td>
       
                      </tr>
                    </tbody>
                  )):<>No Data Found</>}
                </div>
              </table>
            </div>
          </div>
          <br />
        </div>


        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
}

export default HistoryLocation;
