import React, { Component } from "react";
import Chart from "react-apexcharts";
export default class NewInsightSingleDataGraph extends Component {
  constructor(props) {
    console.log("props", props);
    super(props);
    this.state = {
      series: [
        {
          name: this.props.Label,
          data: this.props.YAxisData.map(Number),
        }   
      ],
      options: {
        chart: {
          type: "area",
          toolbar: {
            show: false,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          show: true,
          width: 2,
        },
        xaxis: {
          categories: this.props.XAxisData,
          type: "dateTime",
        },
        yaxis: {
          min: 0,
        },
        legend: {
          position: "right",
        },
      },
    };
  }
  render() {
    return (
      <div className="graph_insight">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={300}
        />
      </div>
    );
  }
}
