import React, { useState } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import ReactDatePicker from "react-datepicker";
import Footer from "../layout/footer";
import Accessibiltyimage from "../images/left_icons/GBP.png";
import DatePicker from "react-datepicker";
import { useEffect } from "react";
import { apiCallingPost, backendApi } from "../apiService";
import $ from "jquery";
import NewInsightActionGraph from "./newInsightsGraph";
import moment from "moment";

function NewInsightsGBP() {
  const [administrativeAreaList, setAdministrativeAreaList] = useState([]);
  const [localityList, setLocalityList] = useState([]);
  const [allLocationData, setAllLocationData] = useState([]);
  const [allAssertiveLocationData, setAllAssertiveLocationData] = useState([]);
  //   const [startDate, setStartDate] = useState(
  //     new Date(new Date().getFullYear(), new Date().getMonth() - 1).toISOString()
  //   );
  //   const [endDate, setEndDate] = useState(new Date().toISOString());
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedMetric, setSelectedMetric] = useState(
    "BUSINESS_IMPRESSIONS_DESKTOP_MAPS"
  );
  const [selectedTimeRange, setSelectedTimeRange] = useState("WEEK");
  const [administrativeArea, setAdministrativeArea] = useState("");
  const [locality, setLocality] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [metricDataXAxis, setMetricDataXAxis] = useState([]);
  const [metricDataYAxis, setMetricDataYAxis] = useState([]);
  const [metricList, setMetricList] = useState([
    {
      metricDisplayName:
        "Business Impressions on Google Maps (Desktop Devices) ",
      metricValue: "BUSINESS_IMPRESSIONS_DESKTOP_MAPS",
    },
    {
      metricDisplayName:
        "Business impressions on Google Search (Desktop devices) ",
      metricValue: "BUSINESS_IMPRESSIONS_DESKTOP_SEARCH",
    },
    {
      metricDisplayName:
        "Business impressions on Google Maps (Mobile devices) ",
      metricValue: "BUSINESS_IMPRESSIONS_MOBILE_MAPS",
    },
    {
      metricDisplayName:
        "Business impressions on Google Search (Mobile devices) ",
      metricValue: "BUSINESS_IMPRESSIONS_MOBILE_SEARCH",
    },
    {
      metricDisplayName:
        "The number of message conversations received on the business profile ",
      metricValue: "BUSINESS_CONVERSATIONS",
    },
    {
      metricDisplayName:
        "The number of times a direction request was requested to the business location ",
      metricValue: "BUSINESS_DIRECTION_REQUESTS",
    },
    {
      metricDisplayName:
        "The number of times the business profile call button was clicked ",
      metricValue: "CALL_CLICKS",
    },
    {
      metricDisplayName:
        "The number of times the business profile website was clicked ",
      metricValue: "WEBSITE_CLICKS",
    },
    {
      metricDisplayName:
        "The number of bookings received from the business profile ",
      metricValue: "BUSINESS_BOOKINGS",
    },
    {
      metricDisplayName:
        "The number of food orders received from the business profile ",
      metricValue: "BUSINESS_FOOD_ORDERS",
    },
  ]);
  useEffect(() => {
    GetLocationsUnderAccount();
  }, []);

  // function to invoke the new Insights API
  const getInsightData = (locationId, metric, option) => {
    if (locationId == "0") {
      alert("Please select a location");
      return false;
    } else if (metric == "0") {
      alert("Please select a metric");
      return false;
    } else if (option == "0") {
      alert("Please select a Time Range");
      return false;
    }
    $(".loder_div").attr("hidden", false);
    setMetricDataXAxis([]);
    setMetricDataYAxis([]);
    let startDate = "";
    let endDate = "";
    const now = new Date();
    switch (option) {
      case "WEEK": {
        //*Code to get start and end of week starts here
        startDate = new Date(now);
        startDate.setDate(now.getDate() - now.getDay());

        endDate = new Date(now);
        endDate.setDate(now.getDate() + (6 - now.getDay()));
        //*Code to get start and end of week ends here
        break;
      }
      case "MONTH": {
        const now = new Date();
        //* Code to get start and end of month starts here
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        //* Code to get start and end of month ends here
        break;
      }
      case "YEAR": {
        //*Code to get start and end of Year starts here
        startDate = new Date(now.getFullYear(), 0, 1);
        endDate = new Date(now.getFullYear(), 11, 31);
        //*Code to get start and end of Year ends here
        break;
      }
      default: {
        alert("Invalid Time Range Selected");
        return false;
      }
    }
    if (now < endDate) {
      endDate = now;
    }
    const apiReq = {
      functionName: "newInsightLocationAnlytics",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      locationId: locationId,
      metric: metric,
      startYear: new Date(startDate).getFullYear(),
      startMonth: new Date(startDate).getMonth() + 1,
      startDay: new Date(startDate).getDate(),
      endYear: new Date(endDate).getFullYear(),
      endMonth: new Date(endDate).getMonth() + 1,
      endDay: new Date(endDate).getDate(),
    };
    backendApi(apiReq).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success == "1") {
        console.log(data);
        data.data.timeSeries.datedValues.map((result) => {
          if (result.value) {
            setMetricDataYAxis((object) => [...object, result.value]);
            setMetricDataXAxis((object) => [
              ...object,
              moment(
                new Date(
                  result.date.year,
                  result.date.month - 1,
                  result.date.day
                )
              ).format("DD/MM/YY"),
            ]);
          } else {
            setMetricDataYAxis((object) => [...object, "0"]);
            setMetricDataXAxis((object) => [
              ...object,
              moment(
                new Date(
                  result.date.year,
                  result.date.month - 1,
                  result.date.day
                )
              ).format("DD/MM/YY"),
            ]);
          }
        });
      } else {
        alert("No Insights available for this location");
      }
      //   setMetricDataYAxis([
      //     data.data.timeSeries.datedValues.map(
      //       (data) => new Date(data.date.year, data.date.month, data.date.day)
      //     ),
      //   ]);
      //   setMetricDataXAxis([
      //     data.data.timeSeries.datedValues.map((data) => data.value),
      //   ]);
    });
  };
  const GetLocationsUnderAccount = (val) => {
    $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "getMasterDataForFilter",

      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    console.log("reviewAPIData", reviewAPIData);
    apiCallingPost(reviewAPIData)
      .then(async (result) => {
        if (!result.data || result.success !== "1") {
          alert(result.errorMessage);
          $(".loder_div").attr("hidden", true);
          return;
        }
        const { data } = result;
        // TODO: May need location filter with country. currently only running for india.
        setAllLocationData(
          data.map((eachLocation) => ({
            locationId: eachLocation.location_id,
            location: `${eachLocation?.title} - ${eachLocation?.locality}`,
          }))
        );
        setLocationList(data);
        setAllAssertiveLocationData(data);
        setAdministrativeAreaList(
          new Array(
            ...new Set(
              data.map((eachLocation) => eachLocation.administrativeArea)
            )
          )
        );
        setLocalityList(
          new Array(
            ...new Set(data.map((eachLocation) => eachLocation?.locality))
          )
        );
        // $(".loder_div").attr("hidden", true);
        setSelectedLocation(data[0].location_id);
        setAdministrativeArea("0");
        setLocality("0");
        getInsightData(
          data[0].location_id,
          "BUSINESS_IMPRESSIONS_DESKTOP_MAPS",
          "WEEK"
        );
      })
      .catch((error) => {
        console.trace(error);
        alert("Something went wrong in getting Location Data");
        $(".loder_div").attr("hidden", true);
      });
  };
  const onStateSelect = (administrativeArea) => {
    setAdministrativeArea(administrativeArea);
    setLocalityList(
      new Array(
        ...new Set(
          locationList
            .filter(
              (eachLocation) =>
                eachLocation?.administrativeArea === administrativeArea
            )
            .map((eachLocation) => eachLocation?.locality)
        )
      )
    );
    setAllAssertiveLocationData(
      locationList.filter(
        (eachLocation) => eachLocation?.administrativeArea == administrativeArea
      )
    );
    setLocality("0");
    setSelectedLocation("0");
    setSelectedMetric("0");
    // setSelectedTimeRange("0");
  };
  const onCitySelect = (locality) => {
    setLocality(locality);
    setAllLocationData(
      allAssertiveLocationData
        .filter((eachLocation) => eachLocation?.locality === locality)
        .map((eachLocation) => ({
          locationId: eachLocation.location_id,
          location: `${eachLocation?.title} - ${eachLocation?.locality}`,
        }))
    );
    setSelectedLocation("0");
    setSelectedMetric("0");
    // setSelectedTimeRange("0");
  };

  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header heading="Insights" headerImage={Accessibiltyimage} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-6">
              <select
                className="form-control"
                required
                value={administrativeArea}
                onChange={(e) => onStateSelect(e.target.value)}
              >
                <option value="0">State</option>
                {administrativeAreaList?.length > 0 &&
                  administrativeAreaList.map((adminArea, index) => (
                    <option key={index} value={adminArea}>
                      {adminArea}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <select
                className="form-control"
                required
                value={locality}
                onChange={(e) => onCitySelect(e.target.value)}
              >
                <option value="0">City</option>
                {localityList?.length > 0 &&
                  localityList.map((eachLocality, index) => (
                    <option key={index} value={eachLocality}>
                      {eachLocality}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <button
                className="btn-search"
                onClick={() => GetLocationsUnderAccount()}
              >
                Clear
              </button>
            </div>
            {/* 
            <div className="col-lg-2 col-md-4 col-sm-6">
              <DatePicker
                selected={startDate}
                // onChange={(date) => this.handleChangeStart(date)}
                name="startDate"
                id="startDate"
                showMonthDropdown
                showYearDropdown
                dateFormat="dd/MM/yyyy"
                className="form-control"
                placeholderText="From Date"
              />
            </div>

            <div className="col-lg-2 col-md-4 col-sm-6">
              <DatePicker
                selected={endDate}
                // onChange={(date) => this.handleChangeEnd(date)}
                name="endDate"
                id="endDate"
                showMonthDropdown
                showYearDropdown
                dateFormat="dd/MM/yyyy"
                className="form-control"
                placeholderText="To Date"
              />
            </div> */}
          </div>
          <hr />
          <div className="customer_actionDiv insight_borderbtm">
            {/* <div className="insight_head">
              Business impressions on Google Maps (Desktop devices)
            </div>
            <div className="insight_text">
              Business impressions of the selected location on Google Maps on
              Desktop devices.
            </div> */}
            <div className="Date_fromToDiv mt-2">
              {/* <span className="Date_fromToText">From</span>
              <div className="Date_fromTo">
                <ReactDatePicker
                  selected={new Date(startDate)}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="dd-MM-yyyy"
                  dateFormatCalendar="MMMM"
                  id="fromdate"
                  //   onChange={(obj) => {
                  //     this.setfromtoDate(obj, "fromDateCustomerAction");
                  //     let startDateGraph = new Date(obj);
                  //     this.setState({
                  //       insightsStartDate: startDateGraph.toISOString(),
                  //       insightsDataKey: startDateGraph.toISOString(),
                  //     });
                  //   }}
                />
              </div>
              <span className="Date_fromToText">To</span>
              <div className="Date_fromTo">
                <ReactDatePicker
                  selected={new Date(endDate)}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="dd-MM-yyyy"
                  dateFormatCalendar="MMMM"
                  //   onChange={(obj) => {
                  //     this.setfromtoDate(obj, "toDateCustomerAction");
                  //     let endDateGraph = new Date(obj);
                  //     this.setState({
                  //       insightsEndDate: endDateGraph.toISOString(),
                  //       insightsDataKey: endDateGraph.toISOString(),
                  //     });
                  //   }}
                />
              </div> */}
              <span className="Date_fromToText">Location</span>
              <div className="Date_fromTo">
                <select
                  className="form-control"
                  required
                  value={selectedLocation}
                  onChange={(obj) => setSelectedLocation(obj.target.value)}
                  //   value={this.state.locality}
                >
                  <option value="0" disabled>
                    Location
                  </option>
                  {allLocationData.map((object, i) => (
                    <option key={i} value={object.locationId}>
                      {object.location}
                    </option>
                  ))}
                </select>
              </div>
              <span className="Date_fromToText">Metric</span>
              <div className="Date_fromTo">
                <select
                  className="form-control"
                  required
                  onChange={(obj) => setSelectedMetric(obj.target.value)}
                  value={selectedMetric}
                >
                  <option value="0" disabled>
                    Metric
                  </option>
                  {metricList.map((metric, i) => (
                    <option key={i} value={metric.metricValue}>
                      {metric.metricDisplayName}
                    </option>
                  ))}
                </select>
              </div>
              <span className="Date_fromToText">Time</span>
              <div className="Date_fromTo">
                <select
                  className="form-control"
                  value={selectedTimeRange}
                  required
                  onChange={(obj) => setSelectedTimeRange(obj.target.value)}
                >
                  <option value="0">Time</option>
                  <option key={0} value="WEEK">
                    1 Week
                  </option>
                  <option key={1} value="MONTH">
                    1 Month
                  </option>
                  <option key={2} value="YEAR">
                    1 Year
                  </option>
                </select>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <button
                  className="btn-search"
                  onClick={() =>
                    getInsightData(
                      selectedLocation,
                      selectedMetric,
                      selectedTimeRange
                    )
                  }
                >
                  Get Insight Data
                </button>
              </div>
            </div>

            {/* <div className="insight_totalview">Total actions 81</div> */}
            <div className="customer_actionGraphDiv">
              <NewInsightActionGraph
                key={metricDataXAxis}
                XAxisData={metricDataXAxis}
                YAxisData={metricDataYAxis}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
}

export default NewInsightsGBP;
