import React, { Component } from "react";
import Chart from "react-apexcharts";
// import moment from "moment";
class ReviewAnalyticsGraph extends Component {
  constructor(props) {
    console.log("helelelelel", props);
    super(props);
    this.state = {
      series: [
        {
          name: "Mixed Sentiment",
          data: this.props.completeDataArray.map((i) =>
            i?.mixed ? parseInt(i.mixed) : 0
          ),
        },
        {
          name: "Positive Sentiment",
          data: this.props.completeDataArray.map((i) =>
            i?.positive ? parseInt(i.positive) : 0
          ),
        },
        {
          name: "Neutral Sentiment",
          data: this.props.completeDataArray.map((i) =>
            i?.neutral ? parseInt(i.neutral) : 0
          ),
        },
        {
          name: "Negative Sentiment",
          data: this.props.completeDataArray.map((i) =>
            i?.negative ? parseInt(i.negative) : 0
          ),
        },
      ],
      options: {
        chart: {
          type: "line",
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          show: true,
          width: 2,
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: this.props.completeDataArray.map((obj) => obj.date),
          type: "datetime",
        },
        title: {
          text: "Review Sentiment Analytics",
          align: "left",
        },
        yaxis: {
          min: 0,
        },
        legend: {
          position: "right",
        },
      },
    };
  }
  render() {
    return (
      <div className="graph_insight">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={300}
        />
      </div>
    );
  }
}
export default ReviewAnalyticsGraph;
