import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import { backendApi, pythonBackendApi } from "../apiService";
import Img1 from "../images/img1.png";
import DragDropFile from "./dragDropFile";
import DataInput from "./dataInput";
import ImageList from "./imageList";
import cloudImg from "../images/upload1.png";
import $ from "jquery";
import * as Icon from "react-feather";
class popupImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageData: [],
      tempData: {},
      uploadedImage: [],
    };
    this.handleFile = this.handleFile.bind(this);
  }
  handleFile(files) {
    console.log(files);
    var reader = new FileReader();
    var url = reader.readAsDataURL(files);

    reader.onloadend = function (e) {
      console.log("reader.result=====", reader.result);
      var tempData = {
        name: files.name,
        image: reader.result,
      };
      this.setState({
        tempData: tempData,
      });
    }.bind(this);
  }
  addImage() {
    if (!this.state.tempData.name) {
      alert("Please select image file");
      return false;
    }
    var imageData = this.state.imageData;
    imageData.push(this.state.tempData);
    this.setState({
      imageData: imageData,
      tempData: {},
    });
  }
  deleteTempImage(index) {
    var imageData = this.state.imageData;
    imageData.splice(index, 1);
    this.setState({
      imageData: imageData,
    });
  }
  uploadImage() {
    if (this.state.imageData.length === 0) {
      alert("Please add images");
      return false;
    }
    var apidata = {
      method: "POST",
      body: JSON.stringify({
        imageData: this.state.imageData,
        createdBy:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
      }),
    };
    pythonBackendApi("uploadImage", apidata).then((data) => {
      console.log("data=========", data);
      if (data.success === "1") {
        alert("Image Upload Successfully");
        $("#analisePopup").hide();
        window.location.reload();
      }
      this.props.setStatus(data.success);
    });
  }
  removeImage(id) {
    if (window.confirm("Are you sure want to delete image?")) {
      var apidata = {
        method: "POST",
        body: JSON.stringify({
          id: id,
          createdBy:
            window.sessionStorage.getItem("switchUserId") === ""
              ? window.sessionStorage.getItem("userId")
              : window.sessionStorage.getItem("switchUserId"),
          siteId:
            window.sessionStorage.getItem("switchingOrgId") === ""
              ? window.sessionStorage.getItem("organizationId")
              : window.sessionStorage.getItem("switchingOrgId"),
        }),
      };
      pythonBackendApi("removeImage", apidata).then((data) => {
        console.log("data=========", data);
        if (data.success === "1") {
          alert("Image Deleted Successfully");
          $("#analisePopup").hide();
          window.location.reload();
        }
        this.props.setStatus(data.success);
      });
    }
  }
  updateImage(id) {
    var apidata = {
      method: "POST",
      body: JSON.stringify({
        id: id,
        name: $("#txtimagename" + id).val(),
        createdBy:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
      }),
    };
    pythonBackendApi("updateImage", apidata).then((data) => {
      console.log("data=========", data);
      if (data.success === "1") {
        alert("Image Updated Successfully");
        $("#analisePopup").hide();
      }
      this.props.setStatus(data.success);
    });
  }
  render() {
    return (
      <div className="popupImageWrapper table_overflow_div">
        <div className="row">
          <div className="file_upload_outer file_upload_img mb-2">
            <DragDropFile handleFile={this.handleFile}>
              <div className="form-group formBottom" id="form-fileupload">
                {/* <img src={cloudImg} alt="cloud" width={40} height={40}/> */}
                {/* <div className="form-control border-0"> */}
                <DataInput handleFile={this.handleFile} />
                {/* </div> */}
              </div>
            </DragDropFile>
          </div>
          <div className="col-lg-12 text-right">
            <button
              type="button"
              className="btn-addfile"
              onClick={() => this.addImage()}
            >
              Add Files
            </button>
          </div>
        </div>
        <div className="popup_filetable container-fluid">
          {this.state.imageData.map((data, i) => (
            <>
              {/* <div className="popupfile_tablehead row">
            <div className="col-4">Image</div>
            <div className="col-4">Image name</div>
            <div className="col-4 text-right">Action</div>
        </div> */}
              <div className="popupfile_tablbody row">
                <div className="col-4">
                  <img className="popupfile_img" src={data.image} alt="img1" />
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    class="form-control mb-0"
                    defaultValue={data.name}
                  />
                </div>
                <div className="col-4 text-right">
                  <button
                    type="button"
                    className="border-0 text-danger"
                    onClick={() => this.deleteTempImage(i)}
                  >
                    <Icon.Trash width="18" height="18" />
                  </button>
                </div>
              </div>
            </>
          ))}
          {this.props.imgdata.map((data) => (
            <>
              {/* <div className="popupfile_tablehead row">
            <div className="col-4">Image</div>
            <div className="col-4">Image name</div>
            <div className="col-4 text-right">Action</div>
        </div> */}

              <div className="popupfile_tablbody row">
                <div className="col-4">
                  <img className="popupfile_img" src={data.image} alt="img1" />
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    class="form-control mb-0"
                    id={"txtimagename" + data.id}
                    defaultValue={data.image_name}
                  />
                </div>
                <div className="col-4 text-right">
                  <button
                    type="button"
                    onClick={() => this.updateImage(data.id)}
                    className="btn editBtn"
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    className="btn deleteBtn"
                    onClick={() => this.removeImage(data.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </>
          ))}
        </div>
        {/* <div className="table-responsive">
          <table className="table file_uplad_div">
            <thead>
              <tr>
                <th  scope="col" className="ImageWrapp">Image</th>
                <th scope="col">Image name</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.imageData.map((data,i)=>(
                <tr>
                <td scope="col">
                  <img src={data.image} alt="img1" className="imgpopTable"/>
                </td>
                <td scope="col">
                  <input type="text" defaultValue={data.name} className="form-control popupInput" />
                </td>
                <td scope="col">
                  
                  <button type="button" className="btn deleteBtn" onClick={()=>this.deleteTempImage(i)}>
                    Delete
                  </button>
                </td>
              </tr>

              ))}
              {this.props.imgdata.map((data)=>(
                <tr>
                <td scope="col">
                  <img src={data.image} alt="img1" className="imgpopTable"/>
                </td>
                <td scope="col">
                  <input type="text" id={"txtimagename"+data.id} defaultValue={data.image_name} className="form-control popupInput" />
                </td>
                <td scope="col">
                  <button type="button" onClick={()=>this.updateImage(data.id)} className="btn editBtn">
                    Update
                  </button>
                  <button type="button" className="btn deleteBtn" onClick={()=>this.removeImage(data.id)}>
                    Delete
                  </button>
                </td>
              </tr>

              ))}
              
              
            </tbody>
          </table>
          </div> */}
        <div className="row">
          <div className="col-lg-12">
            <div className="float-right rightMrgn">
              <button onClick={() => this.uploadImage()} className="uploadBtn">
                Upload these files
              </button>
              <span className="orseperator">or</span>
              <a
                href="#"
                onClick={this.props.closeBtnClick}
                className="cancelBtnPopup"
              >
                Cancel
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default popupImage;
