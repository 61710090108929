import React, { useState, useEffect } from 'react';
import axios from "axios";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {getAdImageByContact} from '../../apiService'
import { backendApi, apiCallingPost } from '../../apiService';



export default function Ads(props) {
  const [ads, setAds] = useState([]);

  const columns = [
    { field: 'name', header: 'Name' },
    { field: 'status', header: 'Status' }
  ];

  useEffect(() => {

    const credentialReq = {
      "functionName": "get_ads_by_account",
      "site_id": "58",
    };
    backendApi(credentialReq).then((data) => {  
      console.log(".................",data);
        setAds(JSON.parse(data.body).data)
    }).catch(() => { })
  }, [props.data])

  const dynamicColumns = columns.map((col, i) => {
    return <Column key={col.field} field={col.field} header={col.header} />;
  });

  return (
    <div>
      <div className="card">
        {/* <DataTable value={ads} responsiveLayout="scroll">
              {dynamicColumns}
          </DataTable> */}
        <div className="col-md-12 tableResponsive">
          <div className="tableOuterWidth">
            <table className="table">
              <thead>
                <tr>
                  <th className="col-2">ID</th>
                  <th className="col-2">Account</th>
                  <th className="col-4">Name</th>
                </tr>
              </thead>
              <div className="table_Outerscroll">
                <>
                  <div class="table_Outerscroll">
                    <tr id="nodata"  >
                      <div class="content-sub-wrapper col-md-12">
                        <div className='row'>
                          <div class="content-sub">
                            <p style={{ margin: "0px", textAlign: "center", color: "red" }}>No Data Found</p>
                          </div>
                        </div>
                      </div>
                    </tr>
                  </div>
                </>
              </div>
            </table>
          </div>
        </div>

      </div>
    </div>
  )
}