import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import Img01 from '../images/fastival.jpg'
import moment from 'moment';

export default function Popup({holidayList,onClose}) {
    useEffect(() => {
        console.log("SFSDGFDs",holidayList);
    }, []);

    const formattedDate = (date) => {
        return moment(date).format('ddd, DD MMM, YYYY');
      };

    return (
        <div className="popup-overlay" id="markedSubModal">
        <div className="popup-contentDivSm">
        <div className="module_close" style={{position:'unset'}}>
              <Icon.XCircle
                style={{top:'0px', right:'0px'}}
                color="red"
                onClick={onClose}
              />
            </div>
          <div className="modal-popup pt-0 position-relative" style={{overflow:'auto'}}>
            <div className="content content-div">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="popup-head">Upcoming Holidays</h3>
                </div>
              </div>
              <hr className="mt-0"></hr>
              <div className="content-sub-wrapper">
                <ul className="content-ul">
                {holidayList?.map((holiday, index) => (<li className="content-li">
                        <div className="content-left">
                            {/* <img src={Img01} width={100} height="auto" alt="img" className="img-fluid content-left-img"/> */}
                        </div>
                        <div className="content-right">
                            <div className="user_datahead">{holiday.holiday}</div>
                            <div className="content-date">{formattedDate(holiday.DATE)}</div>
                            <div className="used_datafree mb-0">
                                 {holiday.shortsummary}
                            </div>
                            <div className="used_datafree mb-0">
                                 States:{" "}{holiday.states}
                            </div>
                        </div>
                    </li>))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}
