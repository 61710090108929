import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { backendDashboardApi } from "../apiService";

const LineChart = ({ fromPage, formattedDates }) => {
  // Sample data for demonstration
  const [leadsData, setLeadsData] = useState([]);
  const [leadsSeries, setLeadsSeries] = useState([]);

  let sampleData = {
    series: []
  };

  let chartOptions = {
    chart: {
      type: 'line',
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [], // Empty array for X-axis
      labels: {
        text: 'Leads',
        show: fromPage === "leadsGenerated" ? false : true
      }
    },
    yaxis: {
      labels: {
        text: 'Time'
      }
    },
    stroke: {
      width: 3
    }
  };


  const [chartData, setChartData] = useState(sampleData);

  useEffect(() => {
    const fetchData = () => {
      console.log("deeeeeeeeeeeeeeeeeeeeeeeeeeeee", fromPage)
      setTimeout(() => {
        setChartData(sampleData);
      }, 3000);
    };

    fetchData();
  }, []);

  useEffect(() => {
    LeadsGeneratedChart()
  }, []);


  const LeadsGeneratedChart = () => {
    const postData = {
      "functionName": "leadsGeneratedAnalytics",
      "accountId": window.sessionStorage.getItem("switchingOrgId"),
      "startDate": formattedDates[0],
      "endDate": formattedDates[1],
      "userId": window.sessionStorage.getItem("userId")
    };
    backendDashboardApi(postData).then((data) => {
      console.log("🚀 ~ backendDashboardApi ~ data1:", data)
      console.log("🚀 ~ backendDashboardApi ~ data2:", data.result.date)
      console.log("🚀 ~ backendDashboardApi ~ data3:", data.result.series)
      if (data.success === "1") {
        sampleData = {
          series: data.result.series
        };
        chartOptions = {
          chart: {
            type: 'line',
            toolbar: {
              show: false,
            },
          },
          xaxis: {
            categories: data.result.date, // Empty array for X-axis
            labels: {
              text: 'Leads',
              show: fromPage === "leadsGenerated" ? false : true
            }
          },
          yaxis: {
            labels: {
              text: 'Time'
            }
          },
          stroke: {
            width: 3
          }
        };
        // setLeadsData(data.result.date)
        // setLeadsSeries(data.result.series)
        // const transformedData = transformData(data.result);
        // setData(transformedData)
      } else {

      }
    });
  };




  // Options for ApexCharts


  return (
    <div className={fromPage == "leadsGenerated" ? 'custom-line-chart' : 'custom-line-chart custom-line-chart-full'}>
      <Chart
        options={chartOptions}
        series={chartData.series}
        type="line"
        width="100%"
        height={fromPage == "leadsGenerated" ? "150px" : "250px"}
      />
    </div>
  );
};

export default LineChart;
