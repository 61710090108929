import "../../css/bootstrap.css";
import "../../css/style.css";
import "../../css/responsive.css";
import { useState,useCallback } from "react";
import React from "react";
import { User, Lock, Navigation, PhoneOutgoing } from "react-feather";
import SignupNew from "./signup_new";
import SigninOld from "./signin_old";
import Loader from "../../images/loader.gif";
function Login() {

  const [ showform, setShowform ] = useState(true);
  const [ newMember, setNewMember ] = useState(false);
  const getRegisFlag = (param) => {
    setShowform(!param);
  };
  
  return (
    <>
    <div className="login_outer">
      <div className="login_inner">
        {/* <div className="inner_loginleft"></div> */}

        {
          showform ? (
            <SigninOld newMember={getRegisFlag} />
          ) : (
            <SignupNew newMember={getRegisFlag} />
          )
          // <form>
          //     <div className="login_head">Registration</div>
          //     <div className="form_group">
          //     <User />
          //         <input type='text' placeholder="Email ID"/>
          //     </div>
          //     <div className="form_group">
          //         <Lock/>
          //         <input type='password' placeholder="Password"/>
          //     </div>
          //     <div className="form_group">
          //         <Navigation/>
          //         <input type='text' placeholder="Address"/>
          //     </div>
          //     <div className="form_group">
          //         <PhoneOutgoing/>
          //         <input type='text' placeholder="Phone No"/>
          //     </div>
          //     <div>
          //       <button type="button" onClick={() => logIn()}>Already a User?</button>
          //     </div>
          //     <div className="login_submit">
          //         <button type="button" onClick={() => signIn(true)}>Sign Up</button>
          //     </div>
          // </form>
        }
      </div>
      <div className="loder_div" hidden></div>
    </div>
    </>
  );
}

export default Login;
