import React, { useEffect, useState, useRef } from "react";
import LeftMenu from "../layout/leftMenu";
import LocalPagesimage from "../images/left_icons/Local-Page.png";
import Header from "../layout/header";
import { backendApi } from "../apiService";
import "../css/bootstrap.css";
import "../css/style.css";
function GeneralUrl() {
    const [textBoxes, setTextBoxes] = useState({
        textBox1: '',
        textBox2: '',
        textBox3: '',
        textBox4: '',
        textBox5: ''
    })
    const [dropdowns, setDropdowns] = useState({
        dropdown1: '',
        dropdown2: '',
        dropdown3: '',
        dropdown4: '',
        dropdown5: ''
    });
    const checkBoxRef1 = useRef(null);
    const checkBoxRef2 = useRef(null);
    const checkBoxRef3 = useRef(null);
    const checkBoxRef4 = useRef(null);
    const checkBoxRef5 = useRef(null);

    function handleChange(event) {
        const { name, value } = event.target;
        setTextBoxes(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    function handleDropdownChange(event) {
        const { name, value } = event.target;
        setDropdowns(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    const validationOfTextAndCheckBox = () => {
        if ((dropdowns.dropdown1) && (!textBoxes.textBox1)
        ) {
            alert("Please Fill Text Field")
            // checkBoxRef1.current.focus();
            return false
        } else if ((dropdowns.dropdown2) && (!textBoxes.textBox2)) {
            alert("Please Fill Text Field")
            // checkBoxRef2.current.focus();
            return false
        } else if ((dropdowns.dropdown3) && (!textBoxes.textBox3)) {
            alert("Please Fill Text Field")
            // checkBoxRef3.current.focus();
            return false
        }
        else if ((dropdowns.dropdown4) && (!textBoxes.textBox4)) {
            alert("Please Fill Text Field")
            // checkBoxRef4.current.focus();
            return false
        }
        else if ((dropdowns.dropdown5) && (!textBoxes.textBox5)) {
            alert("Please Fill Text Field")
            // checkBoxRef5.current.focus();
            return false
        }
        else if ((textBoxes.textBox1 && textBoxes.textBox2) && !dropdowns.dropdown1
        ) {
            alert("Please Choose a value From Drop Down")
            checkBoxRef1.current.focus();
            return false
        } else if ((textBoxes.textBox2 && textBoxes.textBox3) && !dropdowns.dropdown2) {
            alert("Please Choose a value From Drop Down")
            checkBoxRef2.current.focus();
            return false
        } else if ((textBoxes.textBox3 && textBoxes.textBox4) && !dropdowns.dropdown3) {
            alert("Please Choose a value From Drop Down")
            checkBoxRef3.current.focus();
            return false
        }
        else if ((textBoxes.textBox4 && textBoxes.textBox5) && !dropdowns.dropdown4) {
            alert("Please Choose a value From Drop Down")
            checkBoxRef4.current.focus();
            return false
        }
        else if ((textBoxes.textBox5) && !dropdowns.dropdown5) {
            alert("Please Choose a value From Drop Down")
            checkBoxRef5.current.focus();
            return false
        } else { return true }
    }

    const saveFunction = () => {
        if (validationOfTextAndCheckBox()) {
            const postData = {
                "functionName": "localPageUrlCreate",
                "site_id": window.sessionStorage.getItem("switchingOrgId") === ""
                    ? window.sessionStorage.getItem("organizationId")
                    : window.sessionStorage.getItem("switchingOrgId"),
                "local_page_url_format": "abc/123"
            }
            backendApi(postData).then((result) => {
                if (result.success === "1") {

                }
            })
        }
    }
    return (
        <div className="main_wrapper">
            <LeftMenu />
            <div className="userListWrapper">
                <Header heading="Generate Url" headerImage={LocalPagesimage} />
                <div className="container">
                    <div className="user_formdiv mt-5">
                        <div className="row userrow_form">
                            <div className="col-md-2">
                                {/* <select className="form-control">
                    <option value="">Select</option>
                 </select> */}
                                <input type="text"
                                    name="textBox1"
                                    value={textBoxes.textBox1}
                                    onChange={handleChange} className="form-control"></input>
                            </div>
                            <div className="col-md-2">
                                <select name="dropdown1" ref={checkBoxRef1}
                                    id="dropdown1"
                                    value={dropdowns.dropdown1}
                                    onChange={handleDropdownChange} className="form-control">
                                    <option value="">Select</option>
                                    <option value="/">/</option>
                                    <option value="_">_</option>
                                </select>
                            </div>
                            <div className="col-md-2">
                                <input type="text"
                                    name="textBox2"
                                    value={textBoxes.textBox2}
                                    onChange={handleChange} className="form-control"></input>
                                {/* <select className="form-control">
                        <option value="">Select</option>
                    </select> */}
                            </div>
                            <div className="col-md-2">
                                <select name="dropdown2"
                                    value={dropdowns.dropdown2} ref={checkBoxRef2}
                                    onChange={handleDropdownChange} className="form-control">
                                    <option value="">Select</option>
                                    <option value="/">/</option>
                                    <option value="_">_</option>
                                </select>
                            </div>
                            <div className="col-md-2">
                                {/* <select className="form-control">
                        <option value="">Select</option>
                    </select> */}
                                <input type="text"
                                    name="textBox3"
                                    value={textBoxes.textBox3}
                                    onChange={handleChange} className="form-control"></input>
                            </div>
                            <div className="col-md-2">
                                <select name="dropdown3"
                                    value={dropdowns.dropdown3} ref={checkBoxRef3}
                                    onChange={handleDropdownChange} className="form-control">
                                    <option value="">Select</option>
                                    <option value="/">/</option>
                                    <option value="_">_</option>
                                </select>
                            </div>
                            <div className="col-md-2">
                                {/* <select className="form-control">
                        <option value="">Select</option>
                    </select> */}
                                <input type="text"
                                    name="textBox4"
                                    value={textBoxes.textBox4}
                                    onChange={handleChange} className="form-control"></input>
                            </div>
                            <div className="col-md-2">
                                <select name="dropdown4"
                                    value={dropdowns.dropdown4} ref={checkBoxRef4}
                                    onChange={handleDropdownChange} className="form-control">
                                    <option value="">Select</option>
                                    <option value="/">/</option>
                                    <option value="_">_</option>
                                </select>
                            </div>
                            <div className="col-md-2">
                                <input type="text"
                                    name="textBox5"
                                    value={textBoxes.textBox5}
                                    onChange={handleChange} className="form-control"></input>
                                {/* <select  className="form-control">
                        <option value="">Select</option>
                    </select> */}
                            </div>
                            <div className="col-md-2">
                                <select name="dropdown5"
                                    value={dropdowns.dropdown5} ref={checkBoxRef5}
                                    onChange={handleDropdownChange} className="form-control">
                                    <option value="">Select</option>
                                    <option value="/">/</option>
                                    <option value="_">_</option>
                                </select>
                            </div>
                            <div className="col-md-2">
                                <button className="btn btn-success w-100" onClick={saveFunction}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GeneralUrl;
