import React, { useEffect, useState, useRef } from "react";
import * as Icon from "react-feather";
import { apiCallingPost, pythonBackendApi } from "../../apiService";
import { Link } from "react-router-dom";
import $ from "jquery";
import Dashboardimage from '../../images/left_icons//dashboard.png'
import LeftMenu from "../../layout/leftMenu";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
export default function LiveMonitoringProcessingGpb() {
  const [dataList, setDataList] = useState(false);
  const [dataExcel, setdataExcel] = useState([]);
  const messagesEndRef = useRef(null);
  useEffect(() => {
    // $('#mainDiv').animate({scrollTop: $('#mainDiv').prop("scrollHeight")}, 300);
  }, []);
  const RebootVM = (id) => {
    if (!window.confirm("Do You Want To Stop Running Audit Files?")) {
      return false;
    }
    setDataList(true)
    stopProcessStep1();
  }
  const stopProcessStep1 = (id) => {
    $(".loder_div").attr("hidden", false);
    const req = {
      "functionName": "stopProcessStep1"
    };
    apiCallingPost(req).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        if (data.data.length >= 0) {
          const timer = ms => new Promise(res => setTimeout(res, ms))
          async function load() { // We need to wrap the loop into an async function for this to work
            for (var i = 0; i <= data.data.length; i++) {
              $("#mainDiv").append('<p class="rebooting_pro_text" >VM - ' + data.data[i].vm_ip + ' - ' + data.data[i].status + '</p>');
              // var scrollPos =  $("#branch1").offset().top;
              // $(window).scrollTop(scrollPos);  
              //   $('input').focus(function () {
              //     $appender.insertAfter($(this).parent());
              // });
              $('#mainDiv').animate({ scrollTop: $('#mainDiv').prop("scrollHeight") }, 600);
              await timer(1000); // then the created Promise can be awaited
              if (i == data.data.length - 1) {
                $('#testID1').addClass('active_progressbar')
                restartAllAudit_step3()
              }
            }
          }
          load();
        } else { restartAllAudit_step3() }
      }
      else {
        restartAllAudit_step3() 
        alert("Something went wrong ");
      }
    });
  };
  const restart_process_step2 = (id) => {
    var apidata = {
      method: "POST",
      body: JSON.stringify({}),
    };
    pythonBackendApi("restart_process_step2", apidata).then((data) => {
      if (data.success === "1") {
        // stopProcessStep5()
        stopProcessStep4()
      } else {
        stopProcessStep4()
        // alert("Something went wrong ");
      }
    });
  };
  const restartAllAudit_step3 = () => {
    $(".loder_div").attr("hidden", false);
    var apidata = {
      method: "POST",
      body: JSON.stringify({}),
    };
    pythonBackendApi("restartAllAudit_step3", apidata).then((data) => {
      if (data.success == 1) {
        restart_process_step2()
      } else {
        restart_process_step2()
      }
    });
  }
  const stopProcessStep4 = (id) => {
    const req = {
      "functionName": "audit_restart_listing"
    }
    apiCallingPost(req).then((data) => {
      if (data.success === "1") {
        if (data.data.length > 0) {
          setdataExcel(data.data2)
          const timer = ms => new Promise(res => setTimeout(res, ms))
          async function load() { // We need to wrap the loop into an async function for this to work
            for (var i = 0; i <= data.data.length; i++) {
              // $("#mainDiv").append('<p class="rebooting_pro_text">VM - ' + data.data[i].excel_name  + ' - ' + data.data[i].status + '</p>').find('input:last').focus();
              await timer(3000); // then the created Promise can be awaited
              if (i == data.data.length - 1) {
                stopProcessStep4SeconArray()
                $('#testID2').addClass('active_progressbar')
                // $("#mainDiv").append('<p class="rebooting_pro_text">VM - Your Process Completed Successfully</p>')
                $('#testID3').addClass('active_progressbar')
              }
            }
          }
          load();
        } else { stopProcessStep4SeconArray() }
      } else {
        stopProcessStep4SeconArray()
        alert("Something went wrong ");
      }
    });
  };
  const stopProcessStep4SeconArray = (id) => {
    if (dataExcel.length > 0) {
      const timer = ms => new Promise(res => setTimeout(res, ms))
      async function loadding() { // We need to wrap the loop into an async function for this to work
        for (var i = 0; i <= dataExcel.length; i++) {
          $("#mainDiv").append('<p class="rebooting_pro_text">VM - ' + dataExcel[i].excel_name + ' - ' + dataExcel[i].status + '</p>');
          await timer(3000); // then the created Promise can be awaited
          if (i == dataExcel.length - 1) {
            $('#testID2').addClass('active_progressbar')
            stopProcessStep5()
            // $("#mainDiv").append('<p class="rebooting_pro_text">VM - Your Process Completed Successfully</p>')
            $('#testID3').addClass('active_progressbar')
          }
        }
      }
      loadding();
    } else { stopProcessStep5() }
  };
  const stopProcessStep5 = (id) => {
    const req = {
      "functionName": "stopProcessStep5"
    };
    apiCallingPost(req).then((data) => {
      if (data.success === "1") {
        const timer = ms => new Promise(res => setTimeout(res, ms))
        async function load() { // We need to wrap the loop into an async function for this to work
          for (var i = 0; i <= data.data.length; i++) {
            $("#mainDiv").append('<p class="rebooting_pro_text">VM - ' + data.data[i].vm_ip + ' - ' + data.data[i].status + '</p>').find('input:last').focus();
            await timer(3000); // then the created Promise can be awaited
            if (i == data.data.length - 1) {
              $('#testID2').addClass('active_progressbar')
              $("#mainDiv").append('<p class="rebooting_pro_text">VM - Your Process Completed Successfully</p>')
              $('#testID3').addClass('active_progressbar')
            }
          }
        }
        load();
      } else {
        alert("Something went wrong ");
      }
    });
  };
  const RebootVmbackto = () => {
   window.history.back()

  }
  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper mapunmap_div">
        <Header heading="Stop All AuditFiles And Reboot VM" headerImage={Dashboardimage} />
        <div className="container-fluid">
          <div className="livemonitering_group ">
            <div className="row">
            <div className="col-md-3">
                {/* <button type="type" className="btn btn-primary mr-1">Button One</button>
                <button type="type" className="btn btn-info mr-1">Button Two</button> */}
                <div className="back_tocatalog_main">
                  <Link to="/liveMonitoring" className="back-to-link" title="Back">
                    <Icon.ArrowLeftCircle />
                  </Link>
                </div>
              </div>
              <div className="col-md-9">
                {/* <button type="type" className="btn btn-primary mr-1">Button One</button>
                <button type="type" className="btn btn-info mr-1">Button Two</button> */}
                <button type="type" title="Reboot" className="btn btn-danger mr-1 rebootBtn" onClick={RebootVM} >Reboot VM</button>
              </div>
              {/* <div className="col-md-3">
                <select className="form-control mb-0">
                  <option>1</option>
                  <option>1</option>
                  <option>1</option>
                </select>
              </div> */}
            </div>
          </div>
          {dataList == true &&
            <>
              <div className="row">
                <div className="col">
                  <ul className="progressbar_monitering" >
                    <li className="step_one" id="testID1"  >Rebooting VM</li>
                    <li className=" step_two" id="testID2" style={{ textAlign: "center" }}>PROGRESSING</li>
                    <li className=" step_three" id="testID3" style={{ textAlign: "right" }}> ENDED</li>
                  </ul>
                </div>
              </div>
              <div className="rebooting_wrapr">
                <div className="rebooting_process" id="mainDiv" ref={messagesEndRef}>
                  <p className="rebooting_pro_text" >VM 10.20.2220 - Rebooting Started</p>
                  <p className="rebooting_pro_text" id="mainDiv2">VM 10.20.2220 - Rebooting Progressing</p>
                </div>
              </div></>}
        </div>
        <Footer />
      </div>
    </div>
  );
}
