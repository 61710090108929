import React, { useEffect } from "react";

import { useState } from "react";
import { useLocation } from "react-router-dom";
import { backendApi } from "../../../apiService";
import * as Icon from "react-feather";

function OTPListingComponent(props) {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [Title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [description, setdescription] = useState("");
  const [phone, setPhone] = useState("");
  const [adminstriveArea, setadminstriveArea] = useState("");
  const [locality, setlocality] = useState("");
  const [address, setaddress] = useState("");
  const [postalCode, setpostalCode] = useState("");
  const [VerificationCode, setVerificationCode] = useState("");
  //   const [Verificationmethod, setVerificationmethod] = useState("");
  //   const [VerificationCodeEmail, setVerificationCodeEmail] = useState("");
  //   const [verificationRole, setverificationRole] = useState("");
  //   const [VerificationmethodPhoneNumber, setVerificationmethodPhoneNumber] =
  //     useState("");
  //   const [currentPassword, setCurrentPassword] = useState("");
  //   const [newPassword, setNewPassword] = useState("");
  //   const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const closeHandler = (e) => {
    setShow(false);
    props.retryVerification(false, true);
    setVerificationCode("");
    // props.onClose(false);
  };
  const retryVerificationHandler = (e) => {
    setShow(false);
    props.retryVerification(true, false);
    setVerificationCode("");
  };
  useEffect(() => {
    setShow(props.show);
    setTitle(props.props.full_name);
    setFirstName(props.props.full_name);
    setdescription(
      window.sessionStorage.getItem("switchUserOrganization") !== ""
        ? window.sessionStorage.getItem("switchUserOrganization")
        : window.sessionStorage.getItem("organization")
    );
    setPhone(props.props.mobile_no);
    setadminstriveArea(props.props.administrativeArea);
    setlocality(props.props.locality);
    setaddress(props.props.address);
    setpostalCode(props.props.postalCode);
    console.log("loooog", props);
  }, [props.show]);

  const GBPVerifyPin = (e) => {
    e.preventDefault();
    const apiReq = {
      functionName: "GBPVerifyPin",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      locationid: props.props.location_id,
      verificationID: props.verification_code,
      pin: VerificationCode,
    };

    backendApi(apiReq).then((result) => {
      if (result.success === "1") {
        const apiReq = {
          functionName: "updatePushStatus",
          location_id: props.props.location_id,
          g_pin: VerificationCode,
          status: "Verified",
          id: props.props.id,
          full_name: props.props.full_name,
          address: props.props.address,
          category: props.props.category,
          mobile_no: props.props.mobile_no,
          email_id: props.props.email_id,
          locality: props.props.locality,
          administrativeArea: props.props.administrativeArea,
          postalCode: props.props.postalCode,
          storeCode: props.props.storeCode,
          verification_code: props.props.verification_code,
          errorMessage: "",
        };
        props.updateData(apiReq);
        closeHandler();
      } else {
        // alert(result.data.error.message);
        const apiReq = {
          functionName: "updatePushStatus",
          location_id: props.props.location_id,
          g_pin: "",
          status: "Verification Failed",
          id: props.props.id,
          full_name: props.props.full_name,
          address: props.props.address,
          mobile_no: props.props.mobile_no,
          email_id: props.props.email_id,
          category: props.props.category,
          locality: props.props.locality,
          administrativeArea: props.props.administrativeArea,
          postalCode: props.props.postalCode,
          storeCode: props.props.storeCode,
          verification_code: props.props.verification_code,
          errorMessage: JSON.stringify(
            result?.data?.error?.details?.map((data) => data)
          ),
        };
        props.updateData(apiReq);
        retryVerificationHandler();
      }
    });
  };
  const RetryVerification = (e) => {
    e.preventDefault();
    const apiReq = {
      functionName: "updatePushStatus",
      location_id: props.props.location_id,
      g_pin: "",
      status: "Verification Failed",
      id: props.props.id,
      full_name: props.props.full_name,
      address: props.props.address,
      mobile_no: props.props.mobile_no,
      email_id: "",
      category: props.props.category,
      locality: props.props.locality,
      administrativeArea: props.props.administrativeArea,
      postalCode: props.props.postalCode,
      storeCode: props.props.storeCode,
      verification_code: "",
      errorMessage: "",
    };
    props.updateData(apiReq);
    retryVerificationHandler();
  };
  return (
    <div
      className="headerPopup"
      id="verificationCode"
      hidden={show ? false : true}
    >
      <div className="module_popup">
        <div className="module_innerpopup">
          <div className="module_close">
            <Icon.XCircle
              onClick={() => props.retryVerification(false, false)}
            />
          </div>
          <div className="module_header text-center">Business Verification</div>
          {/* <button
            type="button"
            className="btn btn-save mb-1 gobutton_position"
            onClick={() => props.retryVerification(false, false)}
          >
            X
          </button> */}
          <div className="module_body">
            <div className="module_popupsearch_wrpr">
              <div className="module_popupsearch">
                <div className="container">
                  <form
                    className="user_formdiv col-md-10"
                    id="createLocationForm"
                  >
                    <div className="row userrow_form" id="gbpPassword">
                      <div className="col-md-4 d-flex align-items-center ">
                        <label className="col-form-label pt-0">
                          {" "}
                          Verify Location with OTP
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          value={
                            props.verificationMethod === "EMAIL"
                              ? props.props.email_id
                              : props.props.mobile_no
                          }
                          id="gppEmail"
                          className="form-control"
                          placeholder="OTP"
                        />
                        <input
                          onChange={(e) => setVerificationCode(e.target.value)}
                          id="Gbppassword"
                          className="form-control"
                          placeholder="Please Fill OTP Here"
                        />
                      </div>
                    </div>
                    <div className="form_bttn">
                      <button
                        type="submit"
                        className="btn btn-primary submitButton"
                        onClick={(e) => GBPVerifyPin(e)}
                      >
                        <span>Verify Pin</span>
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary submitButton retryVerificatn"
                        onClick={(e) => RetryVerification(e)}
                      >
                        <span>Retry Verification</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OTPListingComponent;
