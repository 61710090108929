import React from "react";
import * as Icon from "react-feather";

export default function dataInputGpost(props) {
  const handleChange = (e) => {
   // console.log(e.target.files,"hhhhhhhhhhhhhhhhhhhhhhh");
    const files = e.target.files;
    if (files && files[0]) props.handleFile(files[0], e);
  };

  return (
    <>
      <Icon.UploadCloud className="uploadCloudIcon" />
      <div className="form-label-fileuploaddiv">
        <label className="form-label-fileupload" htmlFor="file">
          Drag & Drop your Image here&nbsp;
        </label>
        <input
          type="file"
          className=""
          id={props.id}
          //name="file"
          accept={AcceptFileFormats}
          onChange={handleChange}
        />

        <span className="form-fileupload-span">Or</span>
      </div>
      {/* <div className="form-fileupload-btn-padd">
                <button className="form-fileupload-btn">Browse File</button>
                <input type="file" name="myfile"/>
            </div> */}
      <div className="upload-btn-wrapper">
        <button className="form-fileupload-btn">Upload Image</button>
        <input type="file" name="file" onChange={handleChange} id={props.id} />
      </div>
    </>
  );
}

const AcceptFileFormats = ["xlsx", "xlsb", "xlsm", "xls", "jpg", "jpeg", "png"];
