import React, { useState, useEffect } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import DragDropFile from "../home/dragDropFile";
import axios from "axios";
import DataInputGpost from "../google-post/dataInputGpost";
import Pagination from "react-js-pagination";
import cloudImg from "../images/cloud-computing.png";
import GooglePostimage from "../images/left_icons/Google-Post.png";
import * as Icon from "react-feather";

import $ from "jquery";

import { backendApi, apiCallingPost } from "../apiService";




var globalLocationId = 0;
function AssignLocation() {
  // const [firstDescription, setFirstDescription] = useState(false);
  // const [eventTitle, setEventTitle] = useState("");
  // const [photos, setPhotos] = useState(false);
  // const [addButton, setAddButton] = useState(false);
  // const [linkForButton, setLinkForButton] = useState(false);
  // const [title, setTitle] = useState(false);
  // const [datePickers, setDatePickers] = useState(false);
  // const [startTime, setStartTime] = useState("");
  // const [endTime, setEndTime] = useState("");
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  // const [description, setDescription] = useState("");
  // const [eventType, setEventType] = useState("");
  // const [imageUrl, setImageUrl] = useState("");
  // const [imageName, setImageName] = useState("");
  // const [localPostList, setLocalPostList] = useState([]);
  // const [isLoader, setIsLoader] = useState(0);
  // const [dataList, setDataList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [locationlistdataforpagination, setlocationlistdataforpagination] = useState([]);
  const [dropdownList, setdropdownList] = useState([]);
  const [managerId, setmanagerId] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [previousValue, setPreviousValue] = useState("");
  const [locationsList, setLocationsList] = useState([]);
  const [temporaryLocationsList, setTemporaryLocationsList] = useState([]);
  const [selectedLocationsArray, setSelectedLocationsArray] = useState([]);
  const [administrativeAreaList, setAdministrativeAreaList] = useState([]);
  const [localityList, setLocalityList] = useState([]);
  const [localityListBackup, setLocalityListBackup] = useState([]);
  const [dropdown, setdropdown] = useState([]);
  const [administrativeAreaFilterEnable, setAdministrativeAreaFilterEnable] =
    useState(0);
  const [
    administrativeAreaFilterEnableArray,
    setAdministrativeAreaFilterEnableArray,
  ] = useState(0);
  const [
    selectedLocationsCompleteDataArray,
    setSelectedLocationsCompleteDataArray,
  ] = useState([]);
  const [backuplist, setbackuplist] = useState([]);
  const [createPostButton, setCreatePostButton] = useState(false);



  useEffect(() => {
    getLocationsList();
    listAgents();

    // Hours();
    // Minutes();
    // getPostList();
  }, []);

  const listAgents = () => {
    function localhost(postData) {
      return axios
        .post("http://localhost:8080/listAgents", postData)
        .then((data) => data.data)
        .then((res) => res);
    }

    const reviewAPIData = {
      functionName: "listAgents",

      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };

    localhost(reviewAPIData).then((data) => {
      if (data.response.success === "1") {
        $(".loder_div").attr("hidden", true);

        const uniqueObjects = {};
        const newArray = [];

        for (const obj of data.response.data) {
          const key = JSON.stringify(obj);
          if (!uniqueObjects[key]) {
            newArray.push(obj);
            uniqueObjects[key] = true;
          }
        }
        const result = Object.values(
          newArray.reduce((acc, curr) => {
            if (acc[curr.chat_agent_id]) {
              if (
                !acc[curr.chat_agent_id].first_name.includes(curr.first_name)
              ) {
                acc[curr.chat_agent_id].first_name.push(curr.first_name);
              }
              if (!acc[curr.chat_agent_id].user_id.includes(curr.user_id)) {
                acc[curr.chat_agent_id].user_id.push(curr.user_id);
              }
            } else {
              acc[curr.chat_agent_id] = {
                ...curr,
                first_name: [curr.first_name],
                user_id: [curr.user_id],
              };
            }
            return acc;
          }, {})
        );
        console.log("asdasd", result);
        setdropdownList(result);
      } else {
        $(".loder_div").attr("hidden", true);
        alert("Failed");
      }
    });
  };

  const setFilter = async (e) => {
    if (e.target.id === "administrativeList") {
      if (e.target.value !== "0") {
        setAdministrativeAreaFilterEnable(1);
        var filteredLocations = temporaryLocationsList.filter((obj) => {
          return obj.storefrontAddress.administrativeArea === e.target.value;
        });
        var localityArray = [];
        filteredLocations.forEach((obj) => {
          return (
            !localityArray.includes(obj.storefrontAddress.locality) &&
            localityArray.push(obj.storefrontAddress.locality)
          );
        });
 
        setAdministrativeAreaFilterEnableArray(filteredLocations);
        setLocalityList(localityArray);

        setTotalCount(filteredLocations.length);
        const indexOfLastPost = currentPage * 25;
        const indexOfFirstPost = indexOfLastPost - 25;
        // let tempList = locationlistdataforpagination;
        const currentPosts = filteredLocations.slice(indexOfFirstPost, indexOfLastPost);
        setLocationsList(currentPosts);
        // setDataList(data.data);
        setlocationlistdataforpagination(filteredLocations);
        $('#localityFilter').val('0');
      } else {

        setLocationsList(temporaryLocationsList);
        setLocalityList(localityListBackup);
        setAdministrativeAreaFilterEnable(0);
      }
    } else if (e.target.id === "localityFilter") {
      if (e.target.value !== "0") {
        setAdministrativeAreaFilterEnable(1);
        var filteredLocations = temporaryLocationsList.filter((obj) => {
          return obj.storefrontAddress.locality === e.target.value;
        });
        // var localityArray = [];
        // filteredLocations.forEach((obj) => {
        //   return (
        //     localityArray.includes(obj.storefrontAddress.locality) &&
        //     localityArray.push(obj.storefrontAddress.locality)
        //   );
        // });
        // setLocationsList(filteredLocations);
        
        setTotalCount(filteredLocations.length);
        const indexOfLastPost = currentPage * 25;
        const indexOfFirstPost = indexOfLastPost - 25;
        // let tempList = locationlistdataforpagination;
        const currentPosts = filteredLocations.slice(indexOfFirstPost, indexOfLastPost);
        setLocationsList(currentPosts);
        // setDataList(data.data);
        setlocationlistdataforpagination(filteredLocations);
        // setLocalityList(localityArray);
      } else {
        setAdministrativeAreaFilterEnable(0);
        setLocationsList(temporaryLocationsList);
      }
    }
  };
  const getLocationsList = (flag) => {
    $(".loder_div").attr("hidden", false);
    const apiJson = {
      functionName: "GetLocationsUnderAccount",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    apiCallingPost(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);


        if (flag == "data") {

          setLocationsList(
            locationsList.map((data) => ({
              ...data,
         
            }))
          );

        } else {
          setLocationsList([]);
          setlocationlistdataforpagination([]);
        }

        setTemporaryLocationsList(data.data.locations);
        setbackuplist(
          data.data.locations.map((data) => ({
            ...data,

          }))
        );
        globalLocationId = data.data.locations.length;
        for (let i = 0; i < globalLocationId; i++) {
          $("#selectLocationPost" + i).prop("checked", false);
        }
        $("#selectAllLocations").prop("checked", false);
        data.data.locations.forEach((obj) => {
          !administrativeAreaList.includes(
            obj.storefrontAddress.administrativeArea
          ) &&
            administrativeAreaList.push(
              obj.storefrontAddress.administrativeArea
            );
          !localityList.includes(obj.storefrontAddress.locality) &&
            localityList.push(obj.storefrontAddress.locality);
        });
        setLocalityListBackup(localityList);
      } else {
     
        setLocationsList([]);
        setlocationlistdataforpagination([]);
      }
    });
  };
  const handleActivePage = (e) => {
    setCurrentPage(e);
    const indexOfLastPost = e * 25;
    const indexOfFirstPost = indexOfLastPost - 25;
    let tempList = locationlistdataforpagination;
    const currentPosts = locationlistdataforpagination.slice(indexOfFirstPost, indexOfLastPost);
    setLocationsList(currentPosts);
    setlocationlistdataforpagination(tempList);
  };
  const selectLocation = (e, data, id) => {
      
    if (
      e.target.checked === true &&
      !selectedLocationsArray.includes(data.name.split("/")[1])
    ) {
      setSelectedLocationsCompleteDataArray((object) => [...object, data]);
      setSelectedLocationsArray((object) => [
        ...object,
        data.name.split("/")[1],
      ]);

      setCreatePostButton(true);
    } else if (e.target.checked === false) {
      if (selectedLocationsArray.length !== locationsList.filter) {
        $("#selectAllLocations").prop("checked", false);
      }

      let index = selectedLocationsArray.indexOf(data.name.split("/")[1]);
      selectedLocationsArray.splice(index, 1);
      selectedLocationsCompleteDataArray.splice(index, 1);

      if (selectedLocationsArray.length === 0) {
        setCreatePostButton(false);
      } else {
        setCreatePostButton(true);
      }
    }
  };
  const selectAllLocations = (e) => {
    if (e.target.checked === true) {
      for (let i = 0; i < globalLocationId; i++) {
        $("#selectLocationPost" + i).prop("checked", true);
      }
      if (selectedLocationsArray.length > 0) {
        let filteredArray = locationsList.filter(
          (value) => !selectedLocationsArray.includes(value.name.split("/")[1])
        );
        filteredArray.map((obj) => {
          selectedLocationsArray.push(obj.name.split("/")[1]);
          selectedLocationsCompleteDataArray.push(obj);
        });
      } else {
        locationsList.map((obj) => {
          selectedLocationsArray.push(obj.name.split("/")[1]);
          selectedLocationsCompleteDataArray.push(obj);
        });
      }
      if (selectedLocationsArray.length === 0) {
        setCreatePostButton(false);
      } else {
        setCreatePostButton(true);
      }
    } else {
      for (let i = 0; i < globalLocationId; i++) {
        $("#selectLocationPost" + i).prop("checked", false);
      }
      setSelectedLocationsArray([]);
      setSelectedLocationsCompleteDataArray([]);
      setCreatePostButton(false);
    }

    var selectAllList = [];
    // let includes = locationsList.some(item => selectedLocationsArray.includes(item.name.split("/")[1]))
    // console.log("foundfound", filteredArray);
  };
  const savelocation = () => {
    var flag = true;

    if (selectedLocationsArray.length == 0) {
      alert("Please select atleast one location");
      flag = false;
    } else if (managerId == "") {
      alert("Please select an agent");
      flag = false;
    }

    function localhost(postData) {
      return axios
        .post("http://localhost:8080/assignLocationAdd", postData)
        .then((data) => data.data)
        .then((res) => res);
    }
    if (flag == true) {
      const reviewAPIData = {
        functionName: "assignLocationAdd",
        chat_agent_id: managerId,
        accountid: selectedLocationsArray,
        is_active: 0,
        created_by: window.sessionStorage.getItem("userId"),
        modified_by: window.sessionStorage.getItem("userId"),
        site_id:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
      };
      console.log("reviewAPIData", reviewAPIData);
      $(".loder_div").attr("hidden", false);
      localhost(reviewAPIData).then((data) => {
        if (data.response.success === "1") {
          $(".loder_div").attr("hidden", true);
          $("#selectAllLocations").prop("checked", false);
          getLocationsList("data");
          setSelectedLocationsArray([]);
        } else {
          $(".loder_div").attr("hidden", true);
          alert("Failed");
        }
      });
    }
  };
  function dropdownchange(e) {
    setmanagerId(e.target.value);

    for (let i = 0; i < globalLocationId; i++) {
      $("#selectLocationPost" + i).prop("checked", false);
    }
    $("#selectAllLocations").prop("checked", false);
    setSelectedLocationsArray([]);
    if (e.target.value != "") {


      setTotalCount(backuplist.length);
      const indexOfLastPost = currentPage * 25;
      const indexOfFirstPost = indexOfLastPost - 25;
      let tempList = backuplist;
      const currentPosts = backuplist.slice(indexOfFirstPost, indexOfLastPost);
      setLocationsList(
        currentPosts.map((data) => ({
          ...data,

        }))
      );
      setlocationlistdataforpagination(tempList);
    } else {
      setLocationsList([]);
    }

 
  }
  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper ">
        <Header heading="Assign Location" headerImage={GooglePostimage} />
        <div className="container-fluid">
          <div className="row mb-3 ml-1">
            <div className="subhead"> Assign Location</div>
          </div>
          {totalCount > 25 && (
        <div className="pagination_main">
          <Pagination
            hideDisabled
            activePage={currentPage}
            itemsCountPerPage={25}
            totalItemsCount={totalCount}
            pageRangeDisplayed={10}
            onChange={(e) => handleActivePage(e)}
          />
        </div>
      )}
          <div className="row row-search ">
            {managerId != "" && (
              <div className="col-lg-2 col-md-4 col-sm-6">
                <select
                  id="administrativeList"
                  onChange={(e) => {
                    setFilter(e);
                  }}
                  className="form-control"
                >
                  <option value="0">State</option>
                  {administrativeAreaList.map((data) => (
                    <option value={data}>{data}</option>
                  ))}
                </select>
              </div>
            )}
            {managerId != "" && (
              <div className="col-lg-2 col-md-4 col-sm-6 ">
                <select
                  id="localityFilter"
                  onChange={(e) => setFilter(e)}
                  className="form-control"
                >
                  <option value="0">City</option>
                  {localityList.map((data) => (
                    <option value={data}>{data}</option>
                  ))}
                </select>
              </div>
            )}
            {managerId != "" && (
              <div className="col-lg-2 col-md-4 col-sm-6 location_search">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search Business "
                  onChange={(e) => {
                    if (e.target.value.length > 3) {
                      var local = locationsList.filter((obj) => {
                        let title = obj.title.toLocaleLowerCase();
                        return title.includes(
                          e.target.value.toLocaleLowerCase()
                        );
                      });
                      setLocationsList(local);

                      setTotalCount(local.length);
                      const indexOfLastPost = currentPage * 25;
                      const indexOfFirstPost = indexOfLastPost - 25;
                      // let tempList = locationlistdataforpagination;
                      const currentPosts = local.slice(indexOfFirstPost, indexOfLastPost);
                      setLocationsList(currentPosts);
                      // setDataList(data.data);
                      setlocationlistdataforpagination(local);
                    } else {
                      administrativeAreaFilterEnable === 1
                        ? setLocationsList(administrativeAreaFilterEnableArray)
                        : setLocationsList(temporaryLocationsList);
                    }
                  }}
                />

                {/* <div className="location_search_drop">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <span>Location1</span>
                  </li>
                  <li className="nav-item">
                    <span>Location2</span>
                  </li>
                  <li className="nav-item">
                    <span>Location3</span>
                  </li>
                  <li className="nav-item">
                    <span>Location4</span>
                  </li>
                  <li className="nav-item">
                    <span>Location5</span>
                  </li>
                  <li className="nav-item">
                    <span>Location6</span>
                  </li>
                </ul>
              </div> */}
              </div>
            )}
          </div>
          <div className="row align-items-center my-2">
            <div className="subhead px-3" style={{ fontSize: "16px" }}>
              {" "}
              Select Agents to add Location(s)
            </div>{" "}
            <div className="col-lg-3 col-md-6 col-8">
              <select
                id="organizationSelection"
                className="form-control mb-0"
                onChange={(e) => dropdownchange(e)}
              >
                <option value="" > Select </option>
                {dropdownList.map((data) => (
                  <option value={data.chat_agent_id}>{data.agent_name}</option>
                ))}
              </select>
            </div>
            <div className="col">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => savelocation()}
              >
                Save
              </button>
            </div>
          </div>
          <hr className="mt-0"></hr>
          <div className="col-md-12 tableResponsive">
            <div className="tableOuterWidth">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th className="col-1">
                      {" "}
                      {managerId != "" && (
                        <input
                          type="checkbox"
                          name=""
                          id="selectAllLocations"
                          onClick={(e) => selectAllLocations(e)}
                        />
                      )}
                    </th>
                    <th className="col-2">Location(s)</th>
                    <th className="col-4">Address</th>
                    <th className="col-3">Locality</th>
                    <th className="col-2">Area</th>
                  </tr>
                </thead>
                <div className="table_Outerscroll">
                  {console.log("map", managerId)}
                  {locationsList.map((data, i) => (
                    <tbody className="table_Outertbody">
                      <tr>
                        <td className="col-1">
                          {" "}
                          <input
                            id={"selectLocationPost" + i}
                            type="checkbox"
                            name=""
                            onClick={(e) => selectLocation(e, data, i)}
                          />
                        </td>
                        <td className="col-2">{data.title}</td>
                        <td className="col-4">
                          {data.storefrontAddress.addressLines.join()}
                        </td>
                        <td className="col-3">
                          {data.storefrontAddress.locality}
                        </td>
                        <td className="col-2">
                          {data.storefrontAddress.administrativeArea}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </div>
              </table>
            </div>
          </div>
        </div>

        {/* <div className="col-md-2">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => savelocation()}
          >
            Save
          </button>
        </div> */}
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
}

export default AssignLocation;
