import React, { useEffect, useState } from "react";
import { apiCallingPost, backendDashboardApi } from "../apiService";
import moment from "moment";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import BudgetSettingDetails from "./budget-settingsDetails"
import AWS from "aws-sdk";
var bucketParams;
var s3;
const s3ImageUrl =
    "https://tagtree.s3.ap-south-1.amazonaws.com/production/budgetupload";
const s3Region = "us-east-1";
const s3bucketAccessKey = process.env.REACT_APP_TAGTREE_S3_ACCESS_KEY;
const s3bucketSecretKey = process.env.REACT_APP_TAGTREE_S3_SECRET_KEY;
const s3Bucket = "tagtree";
const s3BucketMainFolder = "production";
const S3BucketImagesFolder = "budgetupload";

const CustomDashboardTab3 = () => {
    const [budgetMasterList, setBudgetMasterList] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [budgetAmount, setBudgetAmount] = useState("");
    const [budgetDetails, setBudgetDetails] = useState("");
    const [showBudgetForm, setShowBudgetForm] = useState(false);
    const [showBudgetDetail, setShowBudgetDetail] = useState(false);
    const [totalCount, setTotalCount] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [fileUploadFlag, setFileUploadFlag] = useState({});

    useEffect(() => {
        handleBudgetMasterList();
        getS3Credentials();
    }, []);

    useEffect(() => {
        const initialFlags = {};
        budgetMasterList.forEach((_, index) => {
            initialFlags[index] = true;
        });
        setFileUploadFlag(initialFlags);
    }, [budgetMasterList]);

    const updateFileUploadFlag = (index, value) => {
        setFileUploadFlag(prevState => ({
            ...prevState,
            [index]: value
        }));
    };

    const getS3Credentials = () => {
        bucketParams = {
            Bucket: s3Bucket,
        };
        AWS.config.update({
            accessKeyId: s3bucketAccessKey,
            secretAccessKey: s3bucketSecretKey,
            region: s3Region,
        });
        s3 = new AWS.S3({
            apiVersion: "2006-03-01",
            params: bucketParams,
        });
    };

    const handleAddBudget = () => {
        setShowBudgetForm(true);
    };
    const handleViewBudgetDetail = (data) => {
        setShowBudgetDetail(true);
        setBudgetDetails(data);
    };

    const handleFileUpload = async (image, value, index) => {
        updateFileUploadFlag(index, false);
        try {
            let fileName = new Date().getTime() + image.name;
            let fullFilePath = `${s3BucketMainFolder}/${S3BucketImagesFolder}/${fileName}`;
            let fullFilePathApi = `${s3ImageUrl}/${fileName}`;

            // Upload file to S3
            const dataS3 = await new Promise((resolve, reject) => {
                s3.upload({
                    Key: fullFilePath,
                    Body: image,
                    ACL: "public-read",
                }, (err, data) => {
                    if (err) {
                        console.error(err.message);
                        reject(err);
                    } else {
                        console.log("Uploaded data:", data);
                        resolve(data);
                    }
                });
            });

            // Make API call to save file details
            const postData = {
                "functionName": "budgetMasterFileSave",
                "accountId": window.sessionStorage.getItem("switchingOrgId") === "" ? window.sessionStorage.getItem("organizationId") : window.sessionStorage.getItem("switchingOrgId"),
                "createdOn": new Date().getTime(),
                "createdBy": (window.sessionStorage.getItem("switchUserId") === "" ? window.sessionStorage.getItem("userId") : window.sessionStorage.getItem("switchUserId")),
                "budgetMasterId": value.accountBudgetMasterId,
                "fileName": fullFilePathApi
            };

            const dataValue = await backendDashboardApi(postData);
            if (dataValue.success === "1") {
                const postData2 = {
                    "functionName": "budgetAllocationFileUpload",
                    "budgetMasterFileId": dataValue.result[0].budgetMasterFileId
                };
                const data2 = await backendDashboardApi(postData2);
                if (data2.success === "1") {
                    updateFileUploadFlag(index, true);
                    alert("File uploaded successfully")
                }
            } else {
                alert("Failed to save file details");
            }
            return true; // Indicate success
        } catch (error) {
            console.error("Error during file upload:", error);
            throw error; // Propagate error
        }
    };


    const handleViewBudgetDetailfalse = () => {
        setShowBudgetDetail(false);
    };

    const handleBudgetMasterList = () => {
        const postData = {
            "functionName": "accountBudgetMasterList",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "fromDate": "",
            "toDate": "",
            "offset": "0",
            "max": "10"
        }
        apiCallingPost(postData).then((data) => {
            console.log("🚀setBudgetMasterList ~ apiCallingPost ~ data:", data.result)
            if (data.success === "1") {
                setBudgetMasterList(data.result)
                setTotalCount(data.totalCount);
                setActivePage(1);
            } else {
                setBudgetMasterList([])
            }

        });
    }
    const handleEndDateChange = (date) => {
        setEndDate(date);
        if (startDate && date && startDate >= date) {
            alert('End date must be greater than start date');
            setEndDate("");
            document.getElementById("endDate").focus();;
        }
    };

    const handleCancel = () => {
        setBudgetAmount("");
        setStartDate("");
        setEndDate("");
        setShowBudgetForm(false);
    }



    const handleSave = () => {
        if (!budgetAmount) {
            alert("Budget Amount Required")
            return false;
        }
        else if (!startDate) {
            alert("Start Date Required")
            return false;
        }
        else if (!endDate) {
            alert("End Date Required")
            return false;
        }
        else {
            const postData = {
                "functionName": "accountBudgetMasterSave",
                "accountId": window.sessionStorage.getItem("switchingOrgId"),
                "totalBudget": budgetAmount,
                "date": Date.parse(startDate),
                "expiryDate": Date.parse(endDate),
                "isActive": true,
                "createdOn": "",
                "createdBy": "",
                "modifiedOn": "",
                "modifiedBy": "",
                "divisionDetails": []
            }
            apiCallingPost(postData).then((data) => {
                if (data.success === "1") {
                    alert("Saved Successfully");
                    handleBudgetMasterList();
                    handleCancel();
                } else {
                    alert("Something Went Wrong")
                }

            });
        }
    }



    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        var offset;
        var max;
        if (pageNumber === "1") {
            offset = "0";
            max = 10;
        } else {
            offset =
                parseInt(pageNumber - 1) * 10;
            max = 10;
        }
        setActivePage(pageNumber);
        const postData = {
            "functionName": "accountBudgetMasterList",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "fromDate": "",
            "toDate": "",
            "offset": offset,
            "max": max
        }
        apiCallingPost(postData).then((data) => {
            if (data.success === "1") {
                setBudgetMasterList(data.result);
                setTotalCount(data.totalCount);
            } else {
                setBudgetMasterList([])
            }

        });
    };


    return (
        <>
            {showBudgetDetail === false ? (
                <div className="col-md-12 py-2">

                    {/* {showBudgetForm && ( */}
                    <div className="row mt-4 mx-0">
                        <div class="col-lg-3 col-md-3 col-sm-4 col-9 pl-0">
                            <label className="m-0">
                                Budget Amount<sup style={{ color: "red" }}>*</sup>{" "}
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Budget Amount"
                                value={budgetAmount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                                onChange={(e) => setBudgetAmount(e.target.value.replace(/,/g, '').replace(/\D/g, ''))}
                                onKeyPress={(e) => {
                                    const charCode = e.which ? e.which : e.keyCode;
                                    if (charCode < 48 || charCode > 57) {
                                        e.preventDefault();
                                    }
                                }}
                            />

                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-4 col-9 datefield">
                            <label className="m-0">
                                Start Date<sup style={{ color: "red" }}>*</sup>{" "}
                            </label>
                            <DatePicker
                                onChange={(date) => setStartDate(date)}
                                selected={startDate}
                                name="startDate"
                                showMonthDropdown
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Start Date"
                                className="form-control"
                            />
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-4 col-9 datefield">
                            <label className="m-0">
                                End Date<sup style={{ color: "red" }}>*</sup>{" "}
                            </label>
                            <DatePicker
                                onChange={handleEndDateChange}
                                selected={endDate}
                                name="entDate"
                                id="endDate"
                                showMonthDropdown
                                dateFormat="dd/MM/yyyy"
                                placeholderText="End Date"
                                className="form-control"
                            />
                        </div>
                        <div class="col-2 col-md-3 mt-3">
                            <button class="db-btn" type="button" onClick={() => handleSave()}>Save</button>
                            <button class="db-btn ml-3" type="button" onClick={() => handleCancel()}>Cancel</button>
                        </div>
                    </div>
                    {/* )}
                    {!showBudgetForm && (
                        <div class="col-2 col-md-3">
                            <button class="db-btn" type="button" onClick={() => handleAddBudget()}>Add</button>
                        </div>
                    )} */}

                    <div className="organisation-div organization-card user-creation">
                        <div className="col-md-12 tableResponsive">
                            <div className="tableOuterWidth">
                                <table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th className="col-3">Budget Amount</th>
                                            <th className="col-2">Start Date</th>
                                            <th className="col-2">End Date</th>
                                            <th className="col-2"></th>
                                            <th className="col-2"></th>
                                        </tr>
                                    </thead>
                                    <div class="table_Outerscroll">
                                        <tbody className="table_Outertbody">
                                            {budgetMasterList && budgetMasterList.map((data, index) => (
                                                <tr>
                                                    <td className="col-3">
                                                        <p id={index}> <b>{data.totalBudget.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</b></p>
                                                    </td>
                                                    <td className="col-2">
                                                        <p id={index}>{moment(data.date).format("DD-MM-YYYY")}</p>
                                                    </td>
                                                    <td className="col-2">
                                                        <p id={index}>{moment(data.expiryDate).format("DD-MM-YYYY")}</p>
                                                    </td>
                                                    <td>
                                                        <button class="db-btn" type="button" id={index} onClick={() => handleViewBudgetDetail(data)}>View Detail</button>
                                                    </td>
                                                    <td className="position-relative">
                                                        <button className="db-btn" id={index}>{fileUploadFlag[index] ? "File Upload" : "File Processing..."}</button>
                                                        {/* <button className="db-btn" id={index}> File Upload</button> */}
                                                        <input id={index} type="file" onChange={fileUploadFlag[index] ? (e) => handleFileUpload(e.target.files[0], data, index) : null} />
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </div>
                                </table>
                            </div>



                        </div>
                    </div>


                    {totalCount > 0 ?
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={totalCount}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                        />
                        : ""}
                </div>

            ) : (

                <BudgetSettingDetails budgetDetails={budgetDetails} handleBudgetMasterList={handleBudgetMasterList} handleViewBudgetDetailfalse={handleViewBudgetDetailfalse} />
            )}

        </>

    );

}
export default CustomDashboardTab3;
