import React, { Component } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Dashboardimage from "../images/left_icons/business.png";
import * as Icon from "react-feather";
import FromAudit from "./fromAudit";
import FromGoogle from "./fromGoogle";
import AuditStray from "./auditStray";
import NoDataFound from "./nodataFound";
import AuditDuplicate from "./auditDuplicate";
import ClaimCompleted from "./claimCompleted";
class Mark extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };
  }
  changeTab(obj) {
    this.setState({
      activeTab: obj,
    });
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper">
          <Header heading="Claim a Business" headerImage={Dashboardimage} />

          <Tabs
            className="tabs-main"
            selectedIndex={this.state.activeTab}
            onSelect={(obj) => this.changeTab(obj)}
          >
            <div className="container">
              <div className="row">
                <TabList>
                  <Tab>Audit Result (From Google)</Tab>
                  {/* <Tab>From GBP</Tab> */}
                  {/* <Tab>From Audit Duplicate</Tab>
                  <Tab>From Audit Stray</Tab> */}
                  <Tab>No Data Found (From Audit Excel)</Tab>
                  {/* <Tab>Claim Requested</Tab> */}
                </TabList>
              </div>
            </div>
            <TabPanel>
              <FromAudit changeTab={(obj) => this.changeTab(obj)} />
            </TabPanel>
            {/* <TabPanel>
              <FromGoogle />
            </TabPanel> */}
            {/* <TabPanel>
              <AuditDuplicate />
            </TabPanel>
            <TabPanel>
              <AuditStray />
            </TabPanel> */}
            <TabPanel>
              <NoDataFound />
            </TabPanel>
            {/* <TabPanel>
              <ClaimCompleted />
            </TabPanel> */}
          </Tabs>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Mark;
