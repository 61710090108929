import React, { useEffect, useState } from 'react'
import LeadsGraph from "./leadsGraph"
import LaedsImg from "../../images/leads.png"
import Line2 from "../../images/Line 2.png"
import Menu from "../../images/menulead.png"
import Dropdown from "../../images/dropdown-black.png"
function Leads() {

    return (
        <div className='section-wrapper'>
            <div className="leadGraph-top">
                <div className="leadGraph-tophead">
                    <img src={LaedsImg} alt="" className='img-fluid'/>
                    <span className="leadGraph-topheadtext">Leads - 1,00,000</span>
                </div>
                <ul className="leadgraph-list">
                        <li><span className='leadgraphlist-text'>CPL - 1.8k</span></li>
                        <li className='line'><img src={Line2} alt="" className='img-fluid' /></li>
                        <li><span className='leadgraphlist-text'>Enquiry - 1900</span></li>
                        <li className='line'><img src={Line2} alt="" className='img-fluid' /></li>
                        <li><span className='leadgraphlist-text'>Lead : Enquiry - 4.75 %</span></li>
                </ul>
                <div className="leadgraph-right">
                    <img src={Menu} alt=""  className='img-fluid'/>
                    <span className="leadgraph-righttext">Last 30 Days</span>
                    <img src={Dropdown} alt=""  className='img-fluid'/>
                </div>
            </div>
            <div className="">
                <LeadsGraph />
            </div>
        </div>
    )
}

export default Leads;