import React, { Component } from "react";
import * as XLSX from "xlsx";
import { backendApi, pythonBackendApi } from "../apiService";
import "../css/bootstrap.css";
import "../css/style.css";
import Img1 from "../images/img1.png";
import DragDropFile from "./dragDropFile";
import DataInput from "./dataInput";
import ImageList from "./imageList";
import cloudImg from "../images/upload1.png";
import mappingTemplate from "../docs/mappings-template.xlsx";
import productDetailTemplate from "../docs/product-details-template.xlsx";
import $ from "jquery";
import * as Icon from "react-feather";
const excelMime = [
  "application/vnd.ms-excel",
  "application/msexcel",
  "application/x-msexcel",
  "application/x-ms-excel",
  "application/x-excel",
  "application/x-dos_ms_excel",
  "application/xls",
  "application/x-xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
class ExcelUploadPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataType: this.props.dataType,
      excelData: [],
      uploadtype: "",
    };
    this.handleFile = this.handleFile.bind(this);
    this.uploadData = this.uploadData.bind(this);
    this.uploadHandler = this.uploadHandler.bind(this);
  }
  componentDidMount() {}
  uploadHandler(e) {
    console.log("e.target.value===", e.target.value);
    this.setState({
      uploadtype: e.target.value,
    });
  }
  async handleFile(files) {
    if (files) {
      if (!excelMime.includes(files.type)) {
        alert("Please upload valid excel file");
        return false;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          isLoader: 0,
        });
        /* Parse data */
        const ab = e.target.result;
        const wb = XLSX.read(ab, { type: "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { raw: false, header: 1 });
        console.log("data===", data);
        let ExcelDataList = [];

        let keys = [];
        keys = data[0];
        console.log("keys===", keys);
        let record = {};
        for (let i = 1; i < data.length; i++) {
          let dd = data[i];
          if (dd.length > 0) {
            for (let j = 0; j < dd.length; j++) {
              record[keys[j].trim().split(" ").join("")] =
                dd[j] === undefined ? "" : dd[j].toString().trim();
            }

            ExcelDataList.push(record);

            record = {};
          }
        }
        console.log("ExcelDataList==", ExcelDataList);
        /* Update state */
        this.setState({
          excelData: ExcelDataList,
        });
      };
      reader.readAsArrayBuffer(files);
    }
  }

  uploadData() {
    if (this.state.uploadtype == "") {
      alert("Please select the radio button");
      return false;
    }
    var apidata = {
      method: "POST",
      body: JSON.stringify({
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        dataList: this.state.excelData,
        uploadtype: this.state.uploadtype,
        excelType: this.props.dataType,
      }),
    };
    pythonBackendApi("updateExcelData", apidata).then((data) => {
      console.log("data=========", data);
      if (data.success === "1") {
        alert("Successfully uploaded");
        $("#excelUpdatePopup").hide();
      } else {
        alert("Upload failed");
      }
      this.props.setStatus(data.success);
      this.setState({
        excelData: [],
        uploadtype: "",
      });
    });
  }

  render() {
    return (
      <div className=" popupImageWrapper">
        <div className="row">
          <div className="file_upload_outer  file_upload_img mb-2">
            <DragDropFile handleFile={this.handleFile}>
              <div className="form-group formBottom justify-content-start" id="form-fileupload">
                {/* <img src={cloudImg} alt="cloud" width={40} height={40} /> */}
                {/* <div className="form-group formBottom"> */}
                  <DataInput handleFile={this.handleFile} />
                {/* </div> */}
              </div>
            </DragDropFile>
            {this.props.dataType === "mapping" ? (
              <a className="col-lg-3 col-md-12 TemplateDowloadDiv" href={mappingTemplate}> <Icon.Download className="mr-2"/>Download template</a>
            ) : (
              <a className="col-lg-3 col-md-12 TemplateDowloadDiv" href={productDetailTemplate}> <Icon.Download className="mr-2" />Download template</a>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <label className="input-label excel_label" htmlFor="radreplace">
              <input
                className="input-form"
                type="radio"
                onClick={this.uploadHandler}
                id="radreplace"
                name="uploadType"
                value="Replace"
              />
              Replace
            </label>

            <label className="input-label excel_label" htmlFor="radmerge">
              <input
                className="input-form"
                type="radio"
                onClick={this.uploadHandler}
                id="radmerge"
                name="uploadType"
                value="Merge"
              />
              Merge
            </label>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-lg-4">
            {this.props.dataType === "mapping" ? (
              <a href={mappingTemplate}>Download template</a>
            ) : (
              <a href={productDetailTemplate}>Download template</a>
            )}
          </div> */}
          <div className="col-lg-12">
            <div className="text-center">
              <button onClick={this.uploadData} className="uploadBtn">
                Upload these files
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExcelUploadPopup;
