import React, { Component } from "react";
import "../css/bootstrap.css";
import { Link } from "react-router-dom";
import "../css/style.css";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import { backendApi, apiCallingPost, pythonBackendApi } from "../apiService";
import $ from "jquery";
import { TagsInput } from "react-tag-input-component";
import * as Icon from "react-feather";
import Reviewimage from "../images/left_icons/review.png";
import moment from "moment";
import ReactStars from "react-rating-stars-component";
import DatePicker from "react-datepicker";
import { FaReply } from "react-icons/fa";
import ScaleLoader from "react-spinners/ScaleLoader";
import Delete from "../images/delete.png";
import Wordcloud from "wordcloud";
import "react-datepicker/dist/react-datepicker.css";
import WordCloud from "./wordCloud";
import "../css/social_default.css";
import "../css/social_streams.css";
import Pagination from "react-js-pagination";
var XLSX = require("xlsx");
var removeWords = require("remove-words");
const CountWordsArray = require("count-words-array");
let location_id = window.location.pathname.split("/")[2];
let titleName = window.location.pathname.split("/")[3];
let finalfilter = [];
let finalfilter1 = [];
let finalarray = [];
let outputArray = [];
let addressarray = [];
let dateFilter = [];
let reviewFilter = [];
let arrfilter = [];
let type = null;
let options = null;
class ReviewIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      file: null,
      buffer: null,
      convertText: "",
      sourceText: "",
      status: "",
      src: "",
      imageLanguage: "",
      imageLanguageLabel: "",
      translateLanguage: "",
      languageListImage: [],
      languageListTTS: [],
      uploaded: false,
      dataList: [],
      excelData: [],
      googleData: [],
      keyword: [],
      keywordLength: 0,
      reviewDatas: [],
      getCompanyData: [],
      companyName: "",
      displayName: "",
      createTime: "",
      comment: "",
      starRating: "",
      reviewList: [],
      rateChange: "",
      messageChange: "",
      reviewId: "",
      replyComments: "",
      radioComment: "",
      profilePhotoUrl: "",
      sentimentalList: [],
      revId: "",
      startDate: "",
      endDate: "",
      newRating: "",
      resultProductData: [],
      resultReviewarray: [],
      getlocationData: [],
      sentimentalDataList: [],
      allLocationdata: [],
      flagdata: [],
      getLocationAccount: [],
      getLocationTitle: [],
      getTitleName: "",
      getTitleNameCopy: "",
      getLocation: "",
      getCityName: "",
      getCityList: [],
      businessName: [],
      getbusinessList: "",
      getaccountByLocationList: [],
      starReview: "",
      reply: "",
      sentimental: "",
      templateNewState: [],
      reviewArray: [],
      globalFilterLocationsList: [],
      businessNameFilter: "",
      AdministrativeArea: "",
      businessAddress: "",
      LocalityList: "",
      tempSearchLocationsList: [],
      newReviewFilterList: [],
      offset: "",
      totalCount: "",
      currentPage: 1,
      locationIdsList: [],
    };
    this.setSelected = this.setSelected.bind(this);
    this.handleCompanyDetails = this.handleCompanyDetails.bind(this);
  }
  componentDidMount() {
    $("#abcd").css("display", "none");
    $("#nodata").hide();
    // this.pythonCodeReviewUpdateFunction();
    this.getreviewlist();
    this.getLocationSearch();
    this.newReviewList();
    this.getAdministrativeArea();
    this.pythonCodeReviewUpdateFunction();
    // this.scheduleConditionPythonFunction();
    // setInterval(() => {
    //   this.scheduledPythonFunction();
    // }, 60000);
    $("#templateView" + this.state.revId).hide();
    $("#worldcloud").hide();
  }
  reviewMigrateByLocation() {
    if (this.state.locationIdsList.length > 0) {
      // let apiReq = {
      //   method: "POST",
      //   body: JSON.stringify({
      //     siteId:
      //       window.sessionStorage.getItem("switchingOrgId") === ""
      //         ? window.sessionStorage.getItem("organizationId")
      //         : window.sessionStorage.getItem("switchingOrgId"),
      //     locationarray: `[{${this.state.locationIdsList.join(",")}}]`,
      //   }),
      // };
      // console.log("apiReqapiReq", apiReq);
      let apiParameters = {
        functionName: "dataMigrationReviewAndQA",
        function_name: "every_time_review_update_byLocations",
        module_name: "Review Update By Location",
        params: {
          siteId:
            window.sessionStorage.getItem("switchingOrgId") === ""
              ? window.sessionStorage.getItem("organizationId")
              : window.sessionStorage.getItem("switchingOrgId"),
          locationarray: `[{${this.state.locationIdsList.join(",")}}]`,
        },
      };
      backendApi(apiParameters).then((data) => {
        console.log(data);
      });
      // pythonBackendApi("every_time_review_update_byLocations", apiReq);
    }
  }
  pythonCodeReviewUpdateFunction() {
    let date = new Date();
    let siteId =
      window.sessionStorage.getItem("switchingOrgId") === ""
        ? window.sessionStorage.getItem("organizationId")
        : window.sessionStorage.getItem("switchingOrgId");
    // const apiReq = {
    //   method: "POST",
    //   body: JSON.stringify({
    //     site_id:
    //       window.sessionStorage.getItem("switchingOrgId") === ""
    //         ? window.sessionStorage.getItem("organizationId")
    //         : window.sessionStorage.getItem("switchingOrgId"),
    //   }),
    // };
    var ApiParameters = {
      functionName: "dataMigrationReviewAndQA",
      function_name: "every_time_review_update_by_site",
      module_name: "Review Update By Site",
      params: { siteId: siteId, locationarray: "[{}]" },
    };
    if (window.sessionStorage?.getItem("ReviewUpdateTime" + siteId)) {
      let lastDate = new Date(
        window.sessionStorage?.getItem("ReviewUpdateTime" + siteId)
      );
      const differenceInMinutes = Math.floor((date - lastDate) / 60000);
      if (differenceInMinutes >= 30) {
        window.sessionStorage.setItem("ReviewUpdateTime" + siteId, date);

        backendApi(ApiParameters).then((data) => {
          console.log("data", data);
        });
        // pythonBackendApi("QaaMigrationBySiteID", apiReq).then((data) => {
        //   console.log("data", data);
        // });
      }
    } else {
      window.sessionStorage.setItem("ReviewUpdateTime" + siteId, date);
      backendApi(ApiParameters).then((data) => {
        console.log("data", data);
      });
      // pythonBackendApi("QaaMigrationBySiteID", apiReq).then((data) => {
      //   console.log("data", data);
      // });
    }
  }
  scheduleConditionPythonFunction() {
    if (this.state.getTitleName != "") {
      const apiReq = {
        method: "POST",
        body: JSON.stringify({
          location_id: this.state.getTitleName,
        }),
      };
      setInterval(() => {
        pythonBackendApi("updateReviewByLocation", apiReq);
      }, 60000);
    }
  }
  // scheduledPythonFunction() {
  //   if (this.state.getTitleName != "") {
  //     const apiReq = {
  //       method: "POST",
  //       body: JSON.stringify({
  //         location_id: this.state.getTitleName,
  //       }),
  //     };
  //     pythonBackendApi("updateReviewByLocation", apiReq);
  //   } else {
  //     this.pythonCodeReviewUpdateFunction();
  //   }
  // }
  // pythonCodeReviewUpdateFunction() {
  //   const apiReq = {
  //     method: "POST",
  //     body: JSON.stringify({
  //       site_id:
  //         window.sessionStorage.getItem("switchingOrgId") === ""
  //           ? window.sessionStorage.getItem("organizationId")
  //           : window.sessionStorage.getItem("switchingOrgId"),
  //     }),
  //   };
  //   //pythonBackendApi("every_time_review_update_by_site", apiReq);
  // }
  getAdministrativeArea() {
    const businessAccountData = {
      functionName: "getAdministrativeArea",
      account_id: window.sessionStorage.getItem("account_id"),
    };
    apiCallingPost(businessAccountData).then((data) => {
      if (data.success === "1") {
        this.setState({
          getLocationTitle: data.data.map((obj) => obj.AdministrativeArea),
        });
      } else {
        // $(".loder_div").attr("hidden", true);
      }
    });
  }
  getLocationSearch() {
    const businessAccountData = {
      functionName: "getLocationSearch",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea: "",
      locality: "",
    };
    // $(".loder_div").attr("hidden", false);
    apiCallingPost(businessAccountData).then((data) => {
      // $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        this.setState(
          {
            allLocationdata: data.data,
            businessName: data.data,
            globalFilterLocationsList: data.data,
          },
          () => {
            console.log("alllLocationData", this.state.allLocationdata);
          }
        );
      } else {
        // $(".loder_div").attr("hidden", true);
      }
    });
  }
  NewSearchAPIFunction() {
    // const apiReq = {
    //   method: "POST",
    //   body: JSON.stringify({
    //     location_id: this.state.getTitleName,
    //   }),
    // };
    this.reviewMigrateByLocation();
    // if (this.state.getTitleName != "") {
    //   this.scheduleConditionPythonFunction();
    //   // pythonBackendApi("updateReviewByLocation", apiReq);
    // }
    this.newReviewList();
  }
  newReviewList() {
    $(".loder_div").attr("hidden", false);
    const apiReq = {
      functionName: "newReviewList",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      location_id: this.state.getTitleName,
      fromDate:
        this.state.endDate != ""
          ? moment(this.state.endDate).format("yyyy-MM-DD")
          : "",
      toDate:
        this.state.startDate != ""
          ? moment(this.state.startDate).format("yyyy-MM-DD")
          : "",
      starValue: this.state.starReview,
      sentiment: "",
      administrativeArea: this.state.AdministrativeArea,
      locality: this.state.LocalityList,
      searchKeyword: this.state.keyword,
      offset: this.state.offset,
    };
    backendApi(apiReq).then((data) => {
      if (data.success == "1") {
        let reviewArrayList = [];
        data.data.forEach((obj) => {
          if (obj.reviewReplyComment != "")
            reviewArrayList.push({
              comment: obj.reviewReplyComment,
              updateTime: obj.reviewReplyUpdateTime,
            });
        });
        $(".loder_div").attr("hidden", true);
        this.setState(
          {
            newReviewFilterList: data.data,
            resultReviewarray: reviewArrayList,
            totalCount: data.totalCount[0].totalCount,
          },
          () => {
            window.animation(document.getElementById("grid_review"));
          }
        );
      }
    });
  }

  handleChangeCity(value, AdministrativeArea) {
    console.log("hiiii", value);
    this.setState({
      LocalityList: value,
      getTitleName: "",
    });
    this.setState({
      businessNameFilter: "",
      businessAddress: "",
    });

    const businessAccountData = {
      functionName: "getLocationSearch",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea: AdministrativeArea,
      locality: value,
    };
    // $(".loder_div").attr("hidden", false);
    apiCallingPost(businessAccountData).then((data) => {
      // $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        this.setState({
          globalFilterLocationsList: data.data,
          tempSearchLocationsList: data.data,
          locationIdsList: data.data.map((obj) => obj.location_id),
        });
        finalarray = [];
      } else {
        this.setState({
          globalFilterLocationsList: [],
          tempSearchLocationsList: [],
          locationIdsList: [],
        });
        // $(".loder_div").attr("hidden", true);
      }
    });
  }

  handleChangeState = (value) => {
    $("#cityId").val("");
    if (value !== "") {
      this.setState({
        businessNameFilter: "",
        businessAddress: "",
        LocalityList: "",
        getTitleName: "",
      });
      const businessAccountData = {
        functionName: "getLocalityUnderAdministrativeArea",
        account_id: window.sessionStorage.getItem("account_id"),
        AdministrativeArea: value,
      };
      // $(".loder_div").attr("hidden", false);
      apiCallingPost(businessAccountData).then((data) => {
        // $(".loder_div").attr("hidden", true);
        if (data.success === "1") {
          const businessAccountData = {
            functionName: "getLocationSearch",
            account_id: window.sessionStorage.getItem("account_id"),
            AdministrativeArea: value,
            locality: "",
          };
          backendApi(businessAccountData).then((response) => {
            this.setState({
              globalFilterLocationsList: response.data,
              tempSearchLocationsList: response.data,
              locationIdsList: response.data.map((obj) => obj.location_id),
            });
            finalarray = [];
            // response.data.forEach((data, i) => {
            //   if (i <= 7)
            //     finalarray.push(
            //       `accounts/${window.sessionStorage.getItem(
            //         "account_id"
            //       )}/locations/${data.location_id}`
            //     );
            // });
            // this.newGetLocationReviews();
          });
          this.setState({
            getCityList: data.data.map((obj) => obj.locality),
            AdministrativeArea: value,
          });
        } else {
          this.setState({
            getCityList: [],
          });
          // $(".loder_div").attr("hidden", true);
        }
      });
    } else {
      this.setState({
        AdministrativeArea: "",
        LocalityList: "",
        getCityList: [],
        globalFilterLocationsList: this.state.tempSearchLocationsList,
      });
    }
  };

  getlocationData() {
    const reviewAPIData = {
      functionName: "GetBatchReview",
      accountId: window.sessionStorage.getItem("account_id"),
      reqData: {
        locationNames: finalarray,
        pageSize: 50,
        ignoreRatingOnlyReviews: true,
      },
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    console.log(reviewAPIData);
    apiCallingPost(reviewAPIData).then((data) => {
      // setTimeout(() => {
      //   $(".loder_div").attr("hidden", true);
      // }, 500);
      // if (data.success == 1) {
      //   $("#nodata").show();
      //   if (Object.keys(data.data).length == 0) {
      //     this.setState({
      //       getlocationData: [],
      //       resultReviewarray: [],
      //       getlocationDataopy: [],
      //     });
      //     return false;
      //   } else {
      //     $("#nodata").hide();
      //     var final1 = data.data.locationReviews.filter((obj) => {
      //       console.log(
      //         "kkkkkkkkkkkkkkkkkk",
      //         Object.keys(obj.review).toString().indexOf("reviewReply")
      //       );
      //       if (
      //         Object.keys(obj.review).toString().indexOf("reviewReply") != "-1"
      //       ) {
      //         return obj.review.reviewReply;
      //       } else if (
      //         Object.keys(obj.review).toString().indexOf("reviewReply") == "-1"
      //       ) {
      //         $("#nodata").show();
      //       }
      //     });
      //     console.log("zzzzzzzz", final1);
      //     this.setState(
      //       {
      //         getlocationData: final1,
      //         resultReviewarray: final1,
      //         getlocationDataopy: final1,
      //       },
      //       () => {
      //         window.animation(document.getElementById("grid_review"));
      //       }
      //     );
      //     this.recallUpdate();
      //   }
      // } else if (data.errorMessage != "") {
      //   $("#nodata").show();
      //   $(".loder_div").attr("hidden", true);
      // }
    });
  }
  // getReviewsData() {
  //   const reviewAPIData = {
  //     functionName: "GetReviewsFromLocation",
  //     location_id: "53904911361864879",
  //     account_id: window.sessionStorage.getItem("account_id"),
  //     userId:
  //       window.sessionStorage.getItem("switchUserId") === ""
  //         ? window.sessionStorage.getItem("userId")
  //         : window.sessionStorage.getItem("switchUserId"),
  //     siteId:
  //       window.sessionStorage.getItem("switchingOrgId") === ""
  //         ? window.sessionStorage.getItem("organizationId")
  //         : window.sessionStorage.getItem("switchingOrgId"),
  //   };
  //   apiCallingPost(reviewAPIData).then((data) => {
  //     console.log(data.data.reviews);
  //     if (data.data.reviews.length > 0) {
  //       this.setState(
  //         {
  //           reviewDatas: data.data.reviews,
  //           // resultReviewarray: data.data.reviews,
  //         },
  //         () => {
  //           this.recallUpdate();
  //         }
  //       );
  //     }
  //   });
  // }
  getClearData() {
    // this.getAllLocationdata();
    $("#stateId").val("");
    $("#cityId").val("");
    $("#buisnessId").val("");
    let businessName = this.state.allLocationdata.map((obj) => {
      return obj.title;
    });
    this.setState(
      {
        LocalityList: "",
        AdministrativeArea: "",
        startDate: "",
        endDate: "",
        starReview: "",
        sentimental: "",
        reply: "",
        getTitleName: "",
        getCityList: [],
        keyword: [],
        businessName: businessName,
        businessNameFilter: "",
        locationIdsList: [],
      },
      () => {
        this.newReviewList();
        // this.getfilterData();
      }
    );
  }
  getBusinessChange(item, data) {
    // let filterData = this.state.allLocationdata.filter((obj, i) => {
    //   return obj.location_id === item;
    // });
    // console.log("dataaaa",data)
    this.setState(
      {
        getTitleName: item,
        getTitleNameCopy: data.title,
        locationIdsList: [data.location_id],
        businessNameFilter: `${data.storeCode}- ${data.title}-${data.addressLines},
      ${data.locality},${data.AdministrativeArea}`,
        businessAddress: `${data.title}, ${data.addressLines}, ${data.locality}, ${data.AdministrativeArea}`,
      },
      () => {
        console.log(
          "this.state.businessNameFilter",
          this.state.businessNameFilter
        );
      }
    );
  }
  // getBusinessChange(item) {
  //  ;
  //   let filterData = this.state.businessName.filter((obj, i) => {
  //     return obj.location_id === item;
  //   });
  //   this.setState({
  //     getTitleName: filterData[0].location_id,
  //     getTitleNameCopy: filterData[0].title,
  //   });
  // }
  Locationchange() {
    window.sessionStorage.setItem("reviewrouter", "/review/index");
    window.location.href = "/review/newtemplate";
  }
  recallUpdate() {
    const reviewAPIData = {
      functionName: "processSentiMental",
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    apiCallingPost(reviewAPIData).then((data) => {
      if (data.success === "1") {
        this.setState({
          sentimentalList: data.data,
          sentimentalDataList: data.data,
        });
      }
    });
  }
  setSelected(val) {
    this.setState(
      {
        keyword: val,
      },
      () => console.log("valuuuuu", this.state.keyword)
    );
  }
  handleCompanyDetails(e) {
    this.setState({ companyName: e.target.value });
  }
  // newReviewPopup(
  //   displayName,
  //   createTime,
  //   comment,
  //   reviewId,
  //   reviewercomment,
  //   profilePhotoUrl,
  //   starRating
  // ) {
  //   $("body").addClass("newreview_visible");
  //   this.setState({
  //     displayName: displayName,
  //     createTime: createTime,
  //     comment: comment,
  //     reviewId: reviewId,
  //     starRating: starRating,
  //     profilePhotoUrl: profilePhotoUrl,
  //   });
  // }
  // CloseReviewPopup() {
  //   $("body").removeClass("newreview_visible");
  // }
  templateShow(revId, starRating) {
    let tempStarRating =
      starRating === "ONE"
        ? 1
        : starRating === "TWO"
        ? 2
        : starRating === "THREE"
        ? 3
        : starRating === "FOUR"
        ? 4
        : starRating === "FIVE"
        ? 5
        : 0;
    this.setState({ revId: revId, starRating: tempStarRating }, () => {
      $("#templateView" + this.state.revId).show();
      $("#templateListAll" + this.state.revId).show();
      $("#newTemplate").hide();
    });
  }
  newTemplate() {
    $("#newTemplate").show();
    $("#templateListAll" + this.state.revId).hide();
  }
  editReview(message, templateId, starRate) {
    $('#ratedropdown [value="' + starRate + '"]').attr("selected", "true");
    $("#newTemplate").show();
    $("#templateListAll" + this.state.revId).hide();
    this.setState({
      messageChange: message,
      templateId: templateId,
      rateChange: starRate,
    });
  }
  templateViewClose() {
    $("#templateView" + this.state.revId).hide();
  }
  getreviewlist() {
    var apidata = {
      functionName: "reviewTemplateListing",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apidata).then((data) => {
      this.setState({
        reviewList: data.result,
      });
    });
  }
  backReview() {
    // $("#templateView" + this.state.revId).hide();
    $("#templateView" + this.state.revId).show();
    $("#templateListAll" + this.state.revId).show();
    $("#newTemplate").hide();
  }
  saveTemplate() {
    console.log("this.state.templateId", this.state.templateId);
    var apidata = {
      functionName: "reviewTemplateSave",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      template: this.state.messageChange,
      starRate: this.state.rateChange,
      templateId:
        this.state.templateId === undefined ? "" : this.state.templateId,
    };
    backendApi(apidata).then((data) => {
      if (data.success === "1") {
        if (this.state.templateId == undefined) {
          alert("Templated added Successfully");
          $("#templateView" + this.state.revId).hide();
          this.getreviewlist();
        } else {
          alert("Templated Updated Successfully");
          $("#templateView" + this.state.revId).hide();
          this.getreviewlist();
        }
      } else {
        return false;
      }
    });
  }
  deleteReview(Id) {
    if (Id != "") {
      var apidata = {
        templateId: Id,
        functionName: "reviewTemplateDelete",
      };
      backendApi(apidata).then((data) => {
        if (data.success === "1") {
          alert("Templated deleted Successfully");
          this.getreviewlist();
        } else {
          return false;
        }
      });
    }
  }
  // replaySave(reviewId, location_id, comment) {
  //   if (!window.confirm("Do you want to Post the Review?")) {
  //     return false;
  //   }
  //   const reviewSaveData = {
  //     functionName: "newreviewReplyCommentUpdate",
  //     reviewId: reviewId,
  //     reviewReplyComment: comment,
  //   };
  //   console.log("zzzzzzzzzzzzzz", reviewSaveData);
  //   apiCallingPost(reviewSaveData).then((data) => {
  //     if (data.success === "1") {
  //       alert("Response Posted Successfully");
  //       $("body").removeClass("newreview_visible");
  //       // this.getlocationData();
  //       this.newReviewList();
  //       $("#deleteid" + reviewId).show();
  //       // this.getCompanyData();
  //     } else {
  //       alert("Your Reply was not Reviewed");
  //     }
  //   });
  // }

  replaySave(i, reviewId, name, comment) {
    $("#approve_popupmodal" + i).hide();
    const reviewAPIData = {
      functionName: "ReplyToReview",
      comment: comment,
      reviewID: reviewId,
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      location_id: name,
    };
    const reviewSaveData = {
      functionName: "newreviewReplyCommentUpdate",
      reviewId: reviewId,
      reviewReplyComment: comment,
    };
    apiCallingPost(reviewAPIData).then((data) => {
      if (data.success === "1") {
        apiCallingPost(reviewSaveData).then((obj) => {
          if (data.success == 1) {
            alert("Response Posted Successfully");
            $("body").removeClass("newreview_visible");
            this.getreviewlist();
            this.newReviewList();
            $("#deleteid" + reviewId).show();
          } else {
            alert("Something went wrong while saving reply");
          }
        });
      } else {
        alert("Your Reply was not Reviewed");
      }
    });
  }
  replayDelete(reviewId, comment, location_id) {
    if (!window.confirm("Do you want to Delete this reply?")) {
      return false;
    }
    // const reviewDelete = {
    //   functionName: "newDeleteReview",
    //   reviewId: reviewId,
    // };
    const reviewAPIData = {
      functionName: "DeleteReply",
      reviewID: reviewId,
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      location_id: location_id,
      // "account_id":window.sessionStorage.getItem("account_id"),
      // "message": comment,
      // "location_title": "11",
      // "location_address": "11",
      // "created_by": "111",
      // "created_on": "111",
      // "account_id": "111"
    };
    apiCallingPost(reviewAPIData).then((data) => {
      if (data.success === "1") {
        alert("Successfully Deleted this reply");
        $("body").removeClass("newreview_visible");
        $("#deleteid" + reviewId).hide();
        this.newReviewList();
        // this.getReviewsData();
        $("#replymessage" + reviewId).val("");
      } else {
        alert("Error on Deleting the Review");
      }
    });
  }
  // $("#replymessage").val(reviewercomment);
  selectTemplate(template) {
    this.setState({
      radioComment: template,
    });
    $("#replymessage" + this.state.revId).val(template);
    $("#templateView" + this.state.revId).hide();
  }
  ratingChanged(newRating) {
    this.setState({
      newRating: newRating,
    });
  }
  fetchdata() {
    // this.state.reviewDatas.filter((a) => {
    //   var createTimeva = new Date(
    //     a.createTime.replace("T", " ").replace("Z", "").split(" ")[0]
    //   );
    //   return this.state.endDate !== "" && this.state.endDate !== null
    //     ? new Date(createTimeva) >= this.state.startDate &&
    //         new Date(a.createTime) <= this.state.endDate
    //     : new Date(createTimeva) >= this.state.startDate;
    // });
    // var resultProductData = this.state.reviewDatas.filter(a => {
    //   let flag =""
    //  if(a.starRating =="ONE"){
    //    flag = "1"
    //  }else if (a.starRating =="TWO") {
    //    flag = "2"
    //  }
    //  else if (a.starRating =="THREE") {
    //    flag = "3"
    //  }
    //  else if (a.starRating =="FOUR") {
    //    flag = "4"
    //  }
    //  else if (a.starRating =="FIVE") {
    //    flag = "5"
    //  }
    //    return (flag == this.state.newRating);
    //  });
    // var text = $("#sentiment").find("option:selected").text();
    // var resultProductData = this.state.sentimentalList.filter((a) => {
    //   return text == a.status;
    // });
    // console.log("resultProductData", resultProductData);
  }
  handleChangeStart(value) {
    this.setState({
      startDate: value,
    });
  }
  handleChangeEnd(values) {
    this.setState({
      endDate: values,
    });
  }
  star(values) {
    // alert(values)
    this.setState({
      starReview: values,
    });
  }
  replyDrop(values) {
    // alert(values)
    this.setState({
      reply: values,
    });
  }
  sentimentalDrop(values) {
    // alert(values)
    this.setState({
      sentimental: values,
    });
  }
  reviewChange(locationId) {
    window.location.href =
      "/review/" + "53904911361864879" + "/" + "Xilligence";
    // const reviewAPIData = {
    //   functionName: "GetLocationsByAccountId",
    //   accountId: window.sessionStorage.getItem("account_id"),
    //   siteId:
    //     window.sessionStorage.getItem("switchingOrgId") === ""
    //       ? window.sessionStorage.getItem("organizationId")
    //       : window.sessionStorage.getItem("switchingOrgId"),
    // };
    // apiCallingPost(reviewAPIData).then((data) => {
    //   if (data.data.locations.length > 0) {
    //     for (let i = 0; i < data.data.locations.length; i++) {
    //       //  console.log("data.data.locations",data.data.locations[i].name.split("/")[1])
    //       if (locationId == data.data.locations[i].name.split("/")[1]) {
    //         window.location.href =
    //           "/review/" + locationId + "/" + data.data.locations[i].title;
    //       }
    //     }
    //     // this.setState({ getReviewsLocation: data.data.locations });
    //   }
    // });
  }
  worldCloudClose() {
    $("#worldcloud").hide();
  }
  worldCloudOpen() {
    $("#worldcloud").show();
    $(".loder_div").attr("hidden", false);
    $(".loder_div").attr("hidden", true);
    let reviews = this.state.resultReviewarray;
    if (reviews.length > 0) {
      let array = [];
      for (let i = 0; i < reviews.length; i++) {
        if (reviews[i].comment != "") {
          array.push(reviews[i].comment);
        }
      }
      console.log(">>>>>>>>>>>>>>>>>", array);
      let dataSet = removeWords(array.join());
      let finalData = "";
      dataSet.map((i) => {
        finalData = finalData + "," + i;
      });
      let countWordsArray = CountWordsArray(finalData);
      countWordsArray = countWordsArray.sort(function (a, b) {
        return b.count - a.count;
      });
      console.log("countWordsArray", countWordsArray);
      let final = countWordsArray;
      if (countWordsArray.length > 50) {
        final = countWordsArray.slice(1, 50);
      } else {
        final = countWordsArray;
      }
      console.log("final.....", final.map(Object.values));
      let finalDa = final.map(Object.values);
      let bbb = [
        ["foo12", 12],
        ["bar222", 2],
        ["bar555", 5],
        ["barwwww99", 9],
        ["bar44", 4],
      ];
      Wordcloud(document.getElementById("my_canvas"), {
        list: finalDa,
        gridSize: Math.round((16 * $("#my_canvas").width()) / 1024),
        weightFactor: 16.1,
        fontFamily: "Trebuchet MS sans-serif",
      });
    }
  }
  textfield(e, index) {
    // $(".replyBtns").css("display", "block");
    $("#abcd" + index).css("display", "block");
    console.log("eeeeeeeee", e);
    let data = [...this.state.newReviewFilterList];
    console.log("eeeeeeeee", data[index]);
    // data[index].reviewReplyDefault = e
    data[index].reviewReplyComment = e;
    this.setState(
      {
        newReviewFilterList: data,
      },
      () => {
        window.animation(document.getElementById("grid_review"));
      }
    );
  }
  filterLocations(e) {
    this.setState({
      businessNameFilter: e.target.value,
    });
    var array = [];
    if (this.state.AdministrativeArea != "" && this.state.LocalityList != "") {
      array = this.state.tempSearchLocationsList;
    } else {
      array = this.state.allLocationdata;
    }
    var filterArray = array.filter(
      (data) =>
        data.storeCode.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.addressLines
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        (
          data.storeCode +
          "," +
          data.addressLines +
          "," +
          data.locality +
          "," +
          data.AdministrativeArea
        )
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        (data.locality + "," + data.AdministrativeArea)
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
    );
    this.setState({
      globalFilterLocationsList: filterArray,
    });
  }
  // getStoreCode() {}

  exportReveiw() {
    // Get the review data
    const reviews = this.state.newReviewFilterList;
    console.log("hiiii", reviews);
    // Map the reviews to an array of objects with the required columns
    const data = reviews.map((review) => {
      let totalAddress = this.getAddress(review.location_id);
      return {
        "Store Code": this.getStoreCode(review.location_id),
        "Store Name": `${totalAddress[0].title}`,
        Address: `${totalAddress[0].addressLines}`,
        Locality: `${totalAddress[0].locality}`,
        State: `${totalAddress[0].AdministrativeArea}`,
        "Postal Code": `${totalAddress[0].postalCode}`,
        "Date and Time": moment(review.createTime).format("DD/MM/YYYY hh:mm A"),
        Name: review.displayName,
        Rating: this.getRating(review.starRating),
        Sentiment: this.getSentiment(review.starRating),
        Review: this.getReviewText(review),
        Reply:
          this.state.radioComment || review.reviewReplyComment === ""
            ? ""
            : review.reviewReplyComment,
        "Replied to review date":
          review.reviewReplyComment !== ""
            ? moment(review.reviewReplyUpdateTime).format("DD/MM/YYYY hh:mm A")
            : "",
      };
    });

    // Define the worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Add a custom number format for the rating column
    worksheet["!cols"] = [
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 50 },
      { width: 50 },
      { width: 50 },
    ];
    // worksheet['!numfmt'] = [{}, {}, { numFmt: '[>=1][<2]★;General;General;@' }];
    worksheet["!cols"].splice(3, 0, { width: 20 }); // Add a column with width 20 at index 3 (after the 'Rating' column)

    // Add the worksheet to a new workbook and save it
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Reviews");
    XLSX.writeFile(workbook, "reviews.xlsx");
  }

  getRating(rating) {
    if (rating === "") return 0;
    let ratingText = rating?.toUpperCase();
    switch (ratingText) {
      case "FIVE":
        return 5;
      case "FOUR":
        return 4;
      case "THREE":
        return 3;
      case "TWO":
        return 2;
      case "ONE":
        return 1;
    }
  }
  getAddress(locationId) {
    let address2 = this.state.allLocationdata.filter((data) => {
      return data.location_id == locationId;
    });
    return address2;
    let address = this.state.allLocationdata
      .filter((data) => {
        return data.location_id == locationId;
      })
      .map((obj) => {
        console.log(
          "🚀 ~ file: reviewIndex.jsx:1112 ~ ReviewIndex ~ .map ~ obj:",
          obj
        );
        return `${obj.title},${obj.addressLines}, ${obj.locality}, ${obj.AdministrativeArea},${obj.postalCode}`;
      });
    console.log("locationId", address[0]);
    return address[0];
  }
  getStoreCode(locationId) {
    let address = this.state.allLocationdata
      .filter((data) => {
        return data.location_id == locationId;
      })
      .map((obj) => {
        return `${obj.storeCode}`;
      });
    // console.log("locationId", address[0]);
    return address[0];
  }
  getSentiment(review) {
    if (review.toString() == "FIVE" || review.toString() == "FOUR") {
      return "POSITIVE";
    } else if (review.toString() == "TWO" || review.toString() == "ONE") {
      return "NEGATIVE";
    } else if (review.toString() == "THREE") {
      return "NEUTRAL";
    } else {
      return ""; // Sentiment will be determined by another process
    }
  }

  getReviewText(review) {
    // Return the review text or an empty string if there is none
    return review.comment || "";
  }

  //  getBusinessMatch(review) {
  //   // Return the review text or an empty string if there is none
  //   return review.review.comment || '';
  // }

  handleActivePage(pageNumber) {
    // this.setState({})
    let offset = "";
    if (pageNumber == "1") {
      offset = "";
    } else {
      offset = parseInt(pageNumber - 1) * 50;
    }
    this.setState(
      {
        offset: offset,
        currentPage: pageNumber,
      },
      () => {
        this.newReviewList();
      }
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentPage !== prevState.currentPage) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          <Header heading="Google Reviews" headerImage={Reviewimage} />
          <div className="container-fluid">
            <div className="row">
              <div className="col text-right">
                <a onClick={() => this.exportReveiw()}>
                  <button class="btn_worldclouds ml-2"> Export</button>
                </a>
                <a onClick={() => this.Locationchange()}>
                  <button class="replay_tembtn ml-2">Template</button>
                </a>
                <button
                  type="button"
                  className="btn_worldcloud ml-2"
                  onClick={() => this.worldCloudOpen()}
                >
                  Word Cloud
                </button>
              </div>
            </div>
            <div className="row row-search px-2 mt-2">
              <div className="col-lg-2 col-md-4 col-sm-6">
                <select
                  className="form-control"
                  id="stateId"
                  value={this.state.AdministrativeArea}
                  onChange={(e) => this.handleChangeState(e.target.value)}
                >
                  <option value="">State</option>
                  {this.state.getLocationTitle.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <select
                  className="form-control"
                  id="cityId"
                  value={this.state.LocalityList}
                  onChange={(e) =>
                    this.handleChangeCity(
                      e.target.value,
                      this.state.AdministrativeArea
                    )
                  }
                >
                  <option value="">City</option>
                  {this.state.getCityList.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="col-lg-5 col-md-4 location_search">
                <input
                  className="form-control"
                  value={this.state.businessNameFilter}
                  onChange={(e) => this.filterLocations(e)}
                  placeholder="Search by location"
                />
                <div className="autocomplete__list location_search_drop">
                  <ul class="nav flex-column">
                    {this.state.globalFilterLocationsList.map((data) => (
                      <li
                        key={data.location_id}
                        onMouseDown={() =>
                          this.getBusinessChange(data.location_id, data)
                        }
                      >
                        {data.storeCode}- {data.title}-{data.addressLines},
                        {data.locality},{data.AdministrativeArea}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <select
                  className="form-control review_select_option"
                  id="starId"
                  value={this.state.starReview}
                  onChange={(e) => this.star(e.target.value)}
                >
                  <option value="">Review</option>
                  <option value="ONE">
                    <span>&#9733;</span>
                  </option>
                  <option value="TWO">
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                  </option>
                  <option value="THREE">
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                  </option>
                  <option value="FOUR">
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                  </option>
                  <option value="FIVE">
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                    <span>&#9733;</span>
                  </option>
                </select>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <DatePicker
                  selected={this.state.startDate}
                  onChange={(date) => this.handleChangeStart(date)}
                  name="startDate"
                  showMonthDropdown
                  showYearDropdown
                  autoComplete="off"
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  placeholderText="From Date"
                />
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <DatePicker
                  selected={this.state.endDate}
                  onChange={(date) => this.handleChangeEnd(date)}
                  name="startDate"
                  id="endDate"
                  showMonthDropdown
                  autoComplete="off"
                  showYearDropdown
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  placeholderText="To Date"
                />
              </div>
              <div className="col-lg-5 tagsInput">
                <TagsInput
                  // key={this.state.keywordLength}
                  classNames="new-react-tag-classes"
                  value={this.state.keyword}
                  onChange={this.setSelected}
                  name="keywords"
                  isEditOnRemove={true}
                  placeHolder="Write and press ENTER after each word"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="col-lg-1 col-md-4 col-sm-6 pr-0">
                <button
                  className="btn-search w-100"
                  onClick={() => this.NewSearchAPIFunction()}
                >
                  Search
                </button>
              </div>
              <div className="col-lg-1 col-md-4 col-sm-6 pr-0">
                <button
                  className="btn-clear-search w-100"
                  onClick={() => this.getClearData()}
                >
                  Clear
                </button>
              </div>
            </div>
            <div className="user_main_review">
              <div className="pagination_main">
                <Pagination
                  hideDisabled
                  activePage={this.state.currentPage}
                  itemsCountPerPage={50}
                  totalItemsCount={this.state.totalCount}
                  onChange={(e) => this.handleActivePage(e)}
                />
              </div>
              <div className="row d-flex align-items-center mx-0"></div>
            </div>
            <ul className="row review_container grid effect-1" id="grid_review">
              {this.state.newReviewFilterList.length > 0 ? (
                this.state.newReviewFilterList.map((item, i) => {
                  return (
                    <li className="col-lg-6" key={item}>
                      <div
                        className={
                          item.isDeleted == 1
                            ? "row row_bodyview reviewIndexDeleted"
                            : "row row_bodyview"
                        }
                      >
                        <div className="col-md-6">
                          <div className="review_rowdiv">
                            <div class="review_rowname">Name</div>
                            <div class="review_rowfield">
                              {" "}
                              {item.displayName}{" "}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="review_rowdiv">
                            <div class="review_rowname">Date and Time</div>
                            <div class="review_rowfield">
                              {" "}
                              {moment(item.createTime).format(
                                "DD/MM/YYYY hh:mm A"
                              )}{" "}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="review_rowdiv mb-0">
                            <div class="review_rowreview">Review</div>
                            <div class="review_rowfield">
                              <ReactStars
                                key={item.starRating}
                                count={
                                  item.starRating === "ONE"
                                    ? 1
                                    : item.starRating === "TWO"
                                    ? 2
                                    : item.starRating === "THREE"
                                    ? 3
                                    : item.starRating === "FOUR"
                                    ? 4
                                    : item.starRating === "FIVE"
                                    ? 5
                                    : 0
                                }
                                size={24}
                                edit={false}
                                color2={"#ffd700"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          {/* {console.log(
                          "cccc",
                          this.state.sentimentalList.filter(
                            (obj) => obj.review_id
                          )
                        )} */}
                          {/* {console.log("ccddw", item.review.reviewId)} */}
                          <div className="review_rowdiv mb-0">
                            <div class="review_rowname">Sentiment</div>
                            <div class="review_rowfield">
                              {(item.starRating == "FIVE" &&
                                item.comment == "") ||
                              (item.starRating == "FOUR" &&
                                item.comment == "") ? (
                                <span
                                  className={
                                    (item.starRating === "FIVE" &&
                                      item.comment == "") ||
                                    (item.starRating == "FOUR" &&
                                      item.comment == "")
                                      ? "review_mixedvalue review_green"
                                      : ""
                                  }
                                >
                                  POSITIVE
                                </span>
                              ) : (item.starRating == "ONE" &&
                                  item.comment == "") ||
                                (item.starRating == "TWO" &&
                                  item.comment == "") ? (
                                <span
                                  className={
                                    (item.starRating === "ONE" &&
                                      item.comment == "") ||
                                    (item.starRating == "TWO" &&
                                      item.comment == "")
                                      ? "review_mixedvalue review_red"
                                      : ""
                                  }
                                >
                                  NEGATIVE
                                </span>
                              ) : item.starRating == "THREE" &&
                                item.comment == "" ? (
                                <span
                                  className={
                                    item.starRating === "THREE" &&
                                    item.comment == ""
                                      ? "review_mixedvalue review_yellow"
                                      : ""
                                  }
                                >
                                  NEUTRAL
                                </span>
                              ) : (
                                <span>
                                  {this.state.sentimentalList.length > 0
                                    ? this.state.sentimentalList
                                        .filter(
                                          (obj) =>
                                            obj.review_id == item.reviewId
                                        )
                                        .map((data) => (
                                          <span key={data.status} className="">
                                            {/* {console.log("data rew", data.status)} */}
                                            <span
                                              className={
                                                data.status === "POSITIVE"
                                                  ? "review_mixedvalue review_green"
                                                  : data.status == "NEUTRAL"
                                                  ? "review_mixedvalue review_yellow"
                                                  : data.status == "NEGATIVE"
                                                  ? "review_mixedvalue review_red"
                                                  : ""
                                              }
                                            >
                                              {data.status}
                                            </span>
                                            {(item.starRating === "FIVE" ||
                                              item.starRating === "FOUR" ||
                                              item.starRating === "ONE") &&
                                            item.comment != "" &&
                                            data.status === "" ? (
                                              <ScaleLoader
                                                color="#36d7b7"
                                                height={20}
                                                width={2}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                        ))
                                    : ""}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="review_rowaddress">
                            <div class="review_rowadddiv">Address</div>
                            <div class="review_rowcommd">
                              <p>
                                {/* {this.state.globalFilterLocationsList
                                .filter((data) => {
                                  return (
                                    data.location_id === item.name.split("/")[3]
                                  );
                                })
                                .map((obj) => obj.storeCode)} */}
                                {this.state.allLocationdata
                                  .filter((data) => {
                                    return (
                                      data.location_id === item.location_id
                                    );
                                  })
                                  .map((obj) => {
                                    return (
                                      <>
                                        {obj.storeCode}-{obj.title}
                                      </>
                                    );
                                  })}
                              </p>
                              <p>
                                {this.state.allLocationdata
                                  .filter((data) => {
                                    return (
                                      data.location_id === item.location_id
                                    );
                                  })
                                  .map((obj) => {
                                    return (
                                      <>
                                        {obj.addressLines},{obj.locality},
                                        {obj.AdministrativeArea}
                                      </>
                                    );
                                  })}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          {item.comment == "" ? (
                            ""
                          ) : (
                            <>
                              <div class="review_rowadddiv">Review</div>
                              <div className="review_replaydiv">
                                {item.comment}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="col-md-12">
                          <div class="review_rowadddiv w-100">
                            Reply{" "}
                            {/* {item.isDeleted == 1 && (
                              <span style={{ color: "red" }}>
                                (Reply deleted on:{" "}
                                {moment(item.deletedDate).format(
                                  "DD/MM/YYYY hh:mm A"
                                )}
                                )
                              </span>
                            )} */}
                          </div>
                          <div className="all_review_divtag text_araa_div">
                            <div className="textarea_replydiv">
                              <textarea
                                placeholder="Reply publicly"
                                value={
                                  this.state.radioComment ||
                                  item.reviewReplyComment === ""
                                    ? ""
                                    : item.reviewReplyComment
                                }
                                rows="3"
                                id={"replymessage" + item.reviewId}
                                onChange={(e) =>
                                  this.textfield(e.target.value, i)
                                }
                              ></textarea>
                              <div className="round_divreply">
                                <Icon.Send
                                  className="text-primary replyBtns"
                                  id={"abcd" + i}
                                  onClick={() =>
                                    this.replaySave(
                                      i,
                                      item.reviewId,
                                      item.location_id,
                                      item.reviewReplyComment
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="review_allreplybtn">
                              {/* <button class="replay_tembtn">Template</button> */}
                              <Icon.Trash
                                className="text-danger"
                                onClick={() =>
                                  this.replayDelete(
                                    item.reviewId,
                                    item.comment,
                                    item.location_id
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <h3 id="nodata" className="px-2 w-100 text-center noDataFound">
                  No Data Found
                </h3>
              )}
            </ul>
            <div className="user_main_review">
              <div className="pagination_main">
                <Pagination
                  hideDisabled
                  activePage={this.state.currentPage}
                  itemsCountPerPage={50}
                  totalItemsCount={this.state.totalCount}
                  onChange={(e) => this.handleActivePage(e)}
                />
              </div>
              <div className="row d-flex align-items-center mx-0"></div>
            </div>
          </div>
          <Footer />
        </div>
        <div id={"templateView" + this.state.revId} className="template_popup">
          <div className="review_owner_dropdown" id="templateList">
            <div className="review_owner_top">
              {/* <div className="template_head">
                <button
                  className="btn bt-transparent p-0 border-0"
                  onClick={() => this.templateViewClose()}
                >
                  <Icon.X />
                </button>
              </div> */}
              <div class="reply_popup_head d-flex align-items-center popup_review_common">
                <span class="popup_reply_head">
                  <Icon.FileText
                    onClick={() => {
                      $("#templateView" + this.state.revId).show();
                      $("#templateListAll" + this.state.revId).show();
                      $("#newTemplate").hide();
                    }}
                  />
                  Template
                </span>
                <span class="ml-auto close_popup_review">
                  <Icon.X onClick={() => this.templateViewClose()} />
                </span>
              </div>
              <ul
                className="nav flex-column"
                id={"templateListAll" + this.state.revId}
              >
                <div className="template_head review_temphead">
                  <button
                    className="btn bg-transparent p-0 border-0"
                    onClick={() => this.newTemplate()}
                  >
                    Create Template
                  </button>
                </div>
                {this.state.reviewList
                  .filter((obj) => obj.star_rate === this.state.starRating)
                  .map((item) => (
                    <li>
                      <p>
                        {" "}
                        {item.template}
                        <div
                          className="review_edit_popup"
                          onClick={() =>
                            this.editReview(
                              item.template,
                              item.id,
                              item.star_rate
                            )
                          }
                        >
                          Do you want to edit?
                        </div>
                      </p>
                      <span>
                        {/* <Icon.Edit
                        className="text-success"
                        onClick={() =>
                          this.editReview(
                            item.template,
                            item.id,
                            item.star_rate
                          )
                        }
                      /> */}
                        {/* <Icon.Trash
                        className="text-danger"
                        onClick={() => this.deleteReview(item.id)}
                      /> */}
                        <span
                          className="text-danger"
                          onClick={() => this.deleteReview(item.id)}
                        >
                          Delete
                        </span>
                        <input
                          type="radio"
                          name="templateradio"
                          id="templateradio"
                          onClick={() => this.selectTemplate(item.template)}
                        />
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="review_Popupdiv" id="newTemplate">
              <div className="review_popup_div text-right"></div>
              <form>
                <div className="popup_formgroup">
                  <label>Rate</label>
                  <select
                    className="form-control"
                    id="ratedropdown"
                    onChange={(e) =>
                      this.setState({
                        rateChange: e.target.value,
                      })
                    }
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
                <div className="popup_formgroup">
                  <label>Message</label>
                  <textarea
                    className="form-control"
                    value={this.state.messageChange}
                    onChange={(e) =>
                      this.setState({
                        messageChange: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="popup_formgroup text-right mt-1">
                  <div
                    className="btn btn-secondary mr-2"
                    type="button"
                    onClick={() => this.backReview()}
                  >
                    Back
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.saveTemplate()}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div id="worldcloud" className="template_popup">
          <div className="review_owner_dropdown" id="templateList">
            <div className="review_owner_top">
              <div class="reply_popup_head d-flex align-items-center popup_review_common">
                <span class="popup_reply_head">
                  <Icon.FileText
                    onClick={() => {
                      $("#templateView" + this.state.revId).show();
                      $("#templateListAll" + this.state.revId).show();
                      $("#newTemplate").hide();
                    }}
                  />
                  Word Cloud
                </span>
                <span class="ml-auto close_popup_review">
                  <Icon.X onClick={() => this.worldCloudClose()} />
                </span>
              </div>
              <WordCloud />
            </div>
          </div>
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}
export default ReviewIndex;
