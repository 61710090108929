import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import DatePicker from "react-datepicker";
import CovidUpdtaes from "../images/covid-updates.png";

function PostCovid19Updates() {
  return (
    <div className="row tag_filter tag_marginBtm ">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="row">
          <label>Enter Details </label>
          <textarea type="text" className="form-control" />
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-6">
        <label> </label>
        <select className="form-control" placeholder="Select">
          <option value="0" disabled selected>
            Select
          </option>
          <option value="1">Book</option>
          <option value="">Buy </option>
          <option value="">Order</option>
          <option value="">Sign Up</option>
        </select>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <label> Enter URL</label>
        <input type="text" className="form-control" placeholder="Enter URL" />
      </div>
      <div className="col-lg-3 col-md-6 col-sm-6 ">
        <label> </label>

        <DatePicker
          // selected={this.state.startDate}
          // onChange={(date) => this.handleChangeStart(date)}
          name="startDate"
          showMonthDropdown
          dateFormat="dd/MM/yyyy"
          className="form-control"
          placeholderText="From Date"
        />
      </div>
      <div className="col-lg-3 col-md-6 col-sm-6">
        <label> </label>

        <DatePicker
          // selected={this.state.endDate}
          // onChange={(date) => this.handleChangeEnd(date)}
          name="startDate"
          id="endDate"
          showMonthDropdown
          dateFormat="dd/MM/yyyy"
          className="form-control"
          placeholderText="To Date"
        />
      </div>
      <div>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <button type="button" className=" tag_submitBtn btn btn-primary">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default PostCovid19Updates;
