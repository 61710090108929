import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import { TagsInput } from "react-tag-input-component";
import cloudImg from "../images/cloud-computing.png";
import deleteImg from "../images/Group122.png";
import { backendApi, pythonBackendApi } from "../apiService";
import Pagination from "react-js-pagination";
import Fraudimage from "../images/left_icons/fraud.png";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import uploadImg from "../images/upload.png";
import $ from "jquery";
import closeImg1 from "../images/close1.png";
import * as Icon from "react-feather";
import exportFromJSON from "export-from-json";
import moment from "moment";
const excelMime = [
  "application/vnd.ms-excel",
  "application/msexcel",
  "application/x-msexcel",
  "application/x-ms-excel",
  "application/x-excel",
  "application/x-dos_ms_excel",
  "application/xls",
  "application/x-xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
class Fraud extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      file: null,
      buffer: null,
      convertText: "",
      sourceText: "",
      status: "",
      src: "",
      imageLanguage: "",
      imageLanguageLabel: "",
      translateLanguage: "",
      languageListImage: [],
      languageListTTS: [],
      uploaded: false,
      dataList: [],
      excelData: [],
      googleData: [],
      keyword: [],
      keywordLength: 0,
      count: "",
      setPageOffset: 0,
      setActivePage: 1,
      schedule: "",
      flag: false,
      dataFlag: false,
      masterId: "",
    };
    this.setSelected = this.setSelected.bind(this);
  }
  componentDidMount() {
    this.getDatas();
    this.getMasterGBPAuditData();
    this.setState({
      setActivePage: Number(
        window.sessionStorage.getItem("activePageNumberfraudmain")
      ),
    });
  }
  handlePageChange(pageNumber) {
    this.setState({ setActivePage: pageNumber });
    var offset;
    if (pageNumber === "1") {
      offset = "0";
    } else {
      offset = parseInt(pageNumber - 1) * 25;
    }
    this.setState({ setActivePage: pageNumber, setPageOffset: offset });
    let PostData = {
      functionName: "getAuditMasterDataFraud",
      offset: offset.toString(),
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      role:
        window.sessionStorage.getItem("switchUserRole") === ""
          ? window.sessionStorage.getItem("role")
          : window.sessionStorage.getItem("switchUserRole"),
    };
    window.sessionStorage.setItem("activePageNumberfraudmain", pageNumber);
    window.sessionStorage.setItem("offsetfraudmain", offset);
    backendApi(PostData).then((data) => {
      if (data.success === "1") {
        this.setState({
          excelData: data.data,
          setcount: data.totalCnt[0].totalCnt,
        });
      } else {
        this.setState({
          excelData: [],
          setcount: 0,
        });
      }
    });
  }
  getMasterGBPAuditData() {
    const req = {
      functionName: "getAuditMasterDataFraud",
      offset:
        window.sessionStorage.getItem("offsetfraudmain") == null
          ? 0
          : window.sessionStorage.getItem("offsetfraudmain"),
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      role:
        window.sessionStorage.getItem("switchUserRole") === ""
          ? window.sessionStorage.getItem("role")
          : window.sessionStorage.getItem("switchUserRole"),
    };
    backendApi(req).then((data) => {
      if (data.success !== "1") {
        return;
      }
      this.setState({
        uploaded: false,
        excelData: data.data,
        setcount: data.totalCnt[0].totalCnt,
      });
    });
  }
  downloadExcel(masterId) {
    var apidata = {
      functionName: "downloadFraudExcel",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      masterId: masterId,
    };
    backendApi(apidata)
      .then((data) => {
        if (data.success !== "1") {
          return;
        }
        const successFileName = "fraud-audit-excel";
        const exportType = "xls";
        this.exportXlsx(data.data, successFileName, exportType);
      })
      .catch((error) => {
        alert(error ? error : "Something went wrong");
      });
  }
  exportXlsx = (data, fileName, exportType) => {
    exportFromJSON({ data, fileName, exportType });
  };
  getDatas() {
    var apidata = {
      functionName: "getKeywords",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };
    backendApi(apidata).then((data) => {
      if (data.success === "1") {
        var dataArray = data.data;
        var result = [];
        dataArray.forEach((keyword) => {
          result.push(keyword.keyword.toString());
        });
        this.setState({
          keywordLength: result.length,
          keyword: result,
        });
      } else {
        return false;
      }
    });
  }
  migrateReview(type, masterId, shedule) {
    let value;
    let valueForpassSheduleType = "";
    if (type == "Start") {
      this.setState({
        masterId: masterId,
      });
      $("#vmModal").attr("hidden", false);
      $("#schedulePopUp").val(shedule);
      value = $("#schedule").val();
    } else if (type == "finalStart") {
      value = $("#schedulePopUp").val();
      let reminder = "";
      let answer = "";
      let message = value;
      if (value == "Daily") {
        valueForpassSheduleType = "Day";
        reminder = "Midnight";
        message = "Daily";
      }
      if (value == "Weekly") {
        reminder = "Every sunday";
        message = "Weekly";
        valueForpassSheduleType = moment(new Date()).format("dddd");
      } else if (value == "Monthly") {
        reminder = "End of the month";
        valueForpassSheduleType = moment(new Date()).format("DD/MM/YYYY");
      } else if (value == "") {
        reminder = "None";
        message = "None";
        valueForpassSheduleType = "";
      }
      // this.setState({
      // 	flag: true
      // });
      if (value != "") {
        answer = window.confirm(
          "Do you want to Resheudule into " + message + " (" + reminder + ")"
        );
      } else answer = window.confirm("Do you want to remove Shedule ?");
      if (answer == true) {
        this.setState({
          schedule: $("#schedule").val(),
          reminder: reminder,
          dataFlag: true,
          flag: true,
        });
        var req = {
          functionName: "migrateReview",
          id: this.state.masterId,
          schedule: value,
          scheduleType: valueForpassSheduleType,
        };
        backendApi(req).then((data) => {
          if (data.success == "1") {
            setTimeout(
              () => (
                $("#vmModal").attr("hidden", true),
                this.getMasterGBPAuditData(),
                this.setState({
                  dataFlag: false,
                  flag: false,
                })
              ),
              5000
            );
          } else {
            this.getMasterGBPAuditData();
            $("#vmModal").attr("hidden", true);
            alert("Something went wrong. Couldn't save the keyword(s).");
          }
        });
      } else {
        $("#vmModal").attr("hidden", true);
      }
    }
  }
  keywordSave() {
    if (this.state.keyword.length === 0) {
      alert("Please enter some keywords");
      return false;
    }
    $(".loder_div").attr("hidden", false);
    const apidata = {
      functionName: "fraudKeywordSave",
      keyword: this.state.keyword,
      userId: window.sessionStorage.getItem("userId"),
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apidata)
      .then((data) => {
        $(".loder_div").attr("hidden", true);
        if (data.success !== "1") {
          return;
        }
        alert("Keywords Saved Successfully");
        this.getDatas();
      })
      .catch((error) => {
        alert("Something went wrong. Couldn't save the keyword(s).");
      });
  }
  runFraud(masterId) {
    const apidata = {
      method: "POST",
      body: JSON.stringify({
        userId:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        masterId: masterId,
      }),
    };
    pythonBackendApi("runFraud", apidata);
    alert("Running Fraud");
    this.getDatas();
  }
  setSelected(val) {
    this.setState({
      keyword: val,
    });
  }
  Fraudsession() {
    window.sessionStorage.setItem("pageNumberfraud", 1);
    window.sessionStorage.setItem("offsetfraud", 0);
  }
  selectSchedule(value, index, id) {
    let valueForpassSheduleType = "";
    let reminder = "";
    let answer = "";
    let message = value;
    if (value == "Daily") {
      reminder = "Midnigt";
      message = "Daily";
      valueForpassSheduleType = "Day";
    }
    if (value == "Weekly") {
      reminder = "Every sunday";
      valueForpassSheduleType = moment(new Date()).format("dddd");
    } else if (value == "Monthly") {
      reminder = "End of the month";
      valueForpassSheduleType = moment(new Date()).format("DD/MM/YYYY");
    } else if (value == "") {
      // reminder="None"
      // message="None"
      valueForpassSheduleType = "";
    }
    if (value != "") {
      answer = window.confirm(
        "Do you want to Resheudule into " + message + " (" + reminder + ")"
      );
    } else answer = window.confirm("Do you want to remove Shedule ?");
    if (answer == true) {
      //  $("#schedule"+ index).val(message)
      var req = {
        functionName: "migrateReview",
        id: id,
        schedule: $("#schedule" + index).val(),
        scheduleType: valueForpassSheduleType,
      };
      backendApi(req).then((data) => {
        if (data.success == "1") {
          this.getMasterGBPAuditData();
          alert("Schedule Changed Successfully");
        } else {
          alert("Something went wrong. Couldn't save the keyword(s).");
        }
      });
    }
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          {/* <div className="dash_heading">
            <div className="leftdash_heading">
              <Icon.Home />
              <span>Review</span>
            </div>
            <div className="rightdash_heading">
              <ul>
                <li>
                  <Icon.User />
                  <div className="dash_listdrop">
                    <ul>
                      <li>
                        <a href="">
                          <Icon.Settings />
                          Settings
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <Icon.LogOut />
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div> */}
          <Header heading="Fraud" headerImage={Fraudimage} />
          <div className="container">
            <div className="file_upload_outer">
              <TagsInput
                key={this.state.keywordLength}
                value={this.state.keyword}
                onChange={this.setSelected}
                name="keywords"
                placeHolder="Enter Fraud Keywords"
                style={{ width: "100%" }}
              />
              <em>Press Enter to Add New Keywords</em>
              {/* </div> */}
              {/* <div className="row"> */}
              <div className="col-md-2">
                <button
                  id="proceedBtn"
                  className="btn btn-primary"
                  type="button"
                  onClick={() => this.keywordSave()}
                >
                  Save
                </button>
              </div>
            </div>
            <div className="ocr_progress_bar"></div>
            {this.state.setcount > 25 && (
              <div className="pagination_main">
                <Pagination
                  hideDisabled
                  activePage={this.state.setActivePage}
                  itemsCountPerPage={25}
                  totalItemsCount={this.state.setcount}
                  pageRangeDisplayed={10}
                  onChange={(e) => this.handlePageChange(e)}
                />
              </div>
            )}
            <div className="col-md-12 tableResponsive ">
              <div className="tableOuterWidth table_width_lg">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="col-1">S.No</th>
                      <th className="col-2">Batch</th>
                      <th className="col-2">Comment</th>
                      <th className="col-2">User</th>
                      <th className="col-1">Actions</th>
                      <th className="col-1">Download</th>
                      <th className="col-1">View</th>
                      <th className="col-2">Schedule</th>
                    </tr>
                  </thead>
                  <div className="table_Outerscroll">
                    {this.state.excelData.map((data, i) => {
                      return (
                        <tbody key={i} className="table_Outertbody">
                          <tr>
                            <td className="col-1">{data.id}</td>
                            {/* <td>{r.}</td> */}
                            <td className="col-2">
                              <p className="mb-1">{`${data.excel_name}`}</p>
                              <p className="m-0">{`${moment(
                                data.created_on
                              ).format("DD-MMM-YYYY hh:mm a")}`}</p>
                            </td>
                            <td className="col-2">
                              <p className="mb-1">{data.commentedSiteName}</p>
                              <p className="m-0">{data.category}</p>
                            </td>
                            <td className="col-2">{data.createdBy}</td>
                            <td className="col-1">
                              {/* TODO" Need Checking... */}
                              {data.status === "Fraud Scheduled" ? (
                                <span
                                  id="txtFile"
                                  className="btn btn-start"
                                  onClick={(e) =>
                                    this.migrateReview(
                                      "Start",
                                      data.id,
                                      data.schedule
                                    )
                                  }
                                >
                                  Start
                                </span>
                              ) : data.status === "ReviewStarted" ? (
                                <span
                                  id="txtFile"
                                  className="btn-progress"
                                  onClick={(e) => this.runFraud(data.id)}
                                >
                                  Restart
                                </span>
                              ) : (
                                ""
                              )}
                            </td>
                            {/*data.status === "RunFraud" ? (<span id="txtFile" className="btn-start">In Review...</span>) : ("")*/}
                            <td className="col-1">
                              <div
                                className=""
                                id="txtFile"
                                onClick={(e) => this.downloadExcel(data.id)}
                                title="Download excel"
                              >
                                <Icon.Download />
                              </div>
                            </td>
                            <td className="col-1">
                              <Link
                                onClick={() => this.Fraudsession()}
                                to={"/fraudDetails/" + data.id}
                                state={{ tableId: data.id }}
                                title="Show more"
                              >
                                <Icon.Eye />
                              </Link>
                            </td>
                            {data.status !== "Compared" ? (
                              <td className="col-2 scheduleField">
                                <select
                                  className="form-control"
                                  id={"schedule" + i}
                                  value={data.schedule}
                                  onChange={(e) =>
                                    this.selectSchedule(
                                      e.target.value,
                                      i,
                                      data.id
                                    )
                                  }
                                >
                                  <option value="">None</option>
                                  <option value="Daily">Daily</option>
                                  <option value="Weekly">Weekly</option>
                                  <option value="Monthly">Monthly</option>
                                </select>
                              </td>
                            ) : (
                              <td className="col-2">{data.schedule}</td>
                            )}
                          </tr>
                        </tbody>
                      );
                    })}
                  </div>
                </table>
              </div>
            </div>
            {/* </div> */}
            {/* <div className="reprocessing_btndiv">
                <div className="repro_btn">
                  <button
                    id="txtFile"
                    type="button"
                    className="btn-download"
                    onClick={(e) => {
                      this.migrateReview();
                    }}
                  >
                    Migrate Review
                  </button>
                  <button
                    id="txtFile"
                    type="button"
                    className="btn-download"
                    onClick={(e) => {
                      this.runFraud();
                    }}
                  >
                    Run Fraud
                  </button>
                  <button
                    id="txtFile"
                    type="button"
                    className="btn-download"
                    onClick={(e) => {
                      this.downloadExcel();
                    }}
                  >
                    Download Excel
                  </button>
                </div>
              </div> */}
            {/* </div> */}
            {this.state.setcount > 25 && (
              <div className="pagination_main">
                <Pagination
                  hideDisabled
                  activePage={this.state.setActivePage}
                  itemsCountPerPage={25}
                  totalItemsCount={this.state.setcount}
                  pageRangeDisplayed={10}
                  onChange={(e) => this.handlePageChange(e)}
                />
              </div>
            )}
          </div>
          <Footer />
        </div>
        <div className="loder_div" hidden />
        <div className="module_popup vmModal" id="vmModal" hidden>
          <div className="module_innerpopup module_bodypop">
            <div className="module_headdiv">
              Schedule Fraud
              <div className="module_close">
                <Icon.XCircle
                  color="red"
                  onClick={() => {
                    $("#vmModal").attr("hidden", true);
                  }}
                />
              </div>
            </div>
            <div className="module_body">
              {/* <h5 className="mainHead_popup">
                  {this.state.dataId ? "Update New VM" : "Add New VM"}
                </h5> */}
              <div className="row tag_formLabel mb-2">
                <div className="col-lg-3 col-md-3"></div>
                <div className="m-0 col-sm-3 col-lg-4 col-md-4">
                  <label htmlFor="" className="scheduleLabel mb-2">
                    Schedule
                  </label>
                  <select
                    className="form-control"
                    id="schedulePopUp"
                    //  onChange={(e) => this.selectSchedule(e.target.value,i,data.id)}
                  >
                    <option value="">None</option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                  </select>
                </div>
                <div
                  className="col-sm-2 startDivs"
                  //  value={data.schedule}
                  onClick={(e) => this.migrateReview("finalStart")}
                >
                  <button class="btn-save startBtnMrg">Start</button>
                </div>
              </div>
              {/* {this.state.flag === true &&
								<div>Your Schedule will reflect {this.state.reminder}</div>} */}
              {this.state.dataFlag === true && (
                <div className="mb-5 mt-2 scheduleText">
                  Your Schedule Being Processed {this.state.schedule} and will
                  Reflect {this.state.reminder}
                </div>
              )}
              {/* <input
                      type="checkbox"
                      className="form-control w-auto mt-2"
                      id="checkboxid"
                      disabled={this.state.dataId ? false : true}
                    /> */}
            </div>
            {/* <div class="button-wrapper"> */}
            {/* <button
								type="button"
								class="btn-save"
								// onClick={(e) => this.migrateReview("","save")}
								// onClick={() => this.saveVm()}
							>
								Save
							</button> */}
            {/* <button
								type="button"
								class="btn-cancel"
								onClick={() => {
									$("#vmModal").attr("hidden", true);
									$("#vmName").val("");
									$("#ipAddress").val("");
									$("#username").val("");
									$("#password").val("");
									$("#statusValue").val("");
								}}
							>
								Cancel
							</button> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  }
}
export default Fraud;
