import React, { useState, useEffect } from "react";
import SaveAndValidation from "./settingsSaveAndValidation"
function SettingsDataBaseRename(props) {
  var orgadmin = window.sessionStorage.getItem("role") == "ORG_ADMIN";
  const [db_name, setdb_name] = useState((props.listingValuesForPassing.db_name != "null") ? props.listingValuesForPassing.db_name : "");
  const [is_active_db_name, setis_active_db_name] = useState((props.listingValuesForPassing.is_active_db_name != "null ") ? (props.listingValuesForPassing.is_active_db_name == 0 ? false : true) : "");
  useEffect(() => {
    if (props.listingValuesForPassing.db_name && (props.listingValuesForPassing.db_name != "null" || props.listingValuesForPassing.db_name != "undefined")) {
      setdb_name((props.listingValuesForPassing.db_name))
    } else { setdb_name("") }

    if (props.listingValuesForPassing.is_active_db_name && (props.listingValuesForPassing.is_active_db_name != "null" || props.listingValuesForPassing.is_active_db_name != "undefined")) { setis_active_db_name((props.listingValuesForPassing.is_active_db_name == 0 ? false : true)) }
    else { setis_active_db_name("") }
  }, [props.listingValuesForPassing.db_name, props.listingValuesForPassing.is_active_db_name])
  return (
 
     <div className="row"> 
 <div className="col-md-12">
        <div className="user_formdiv">
          <div className="userform_head">Database Settings</div>
          <div className="row userrow_form">
            <div className="col-md-4">
              <label className="col-form-label">
                Local DB Name
              </label>
            </div>
            <div class="col-md-1"><span>:</span></div>
            <div className="col-md-7">
              <input
                type="text"
                id="databasename"
                value={db_name}
                onChange={(e) => {
                  setdb_name(e.target.value);
                }}
                className="form-control"
                placeholder="Local DB Name"
              />
            </div>
          </div>
          <div className="row userrow_form">
            <div className="col-md-4">
              <label className="col-form-label">Is Active</label>
            </div>
            <div class="col-md-1"><span>:</span></div>
            <div className="col-md-7">
              <span>
                <>
                  <label className="switch_items">
                    <input
                      id={"status"}
                      name={"status"}
                      type="checkbox"
                      checked={is_active_db_name}
                      onClick={(event) => setis_active_db_name(event.target.checked)}
                    />
                    <span className="switch round"></span>
                  </label>
                </>
              </span>
            </div>
          </div>
          <SaveAndValidation
          getUserData={() => props.getUserData()}
            valueForSaved={[db_name, is_active_db_name]}
            saveMode={"DataBaseRename"}
            organizationId={props.organizationId} />
        </div>
      </div>
     </div>
   
  );

}
export default SettingsDataBaseRename;
