import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import deleteImg from "../images/close.png";
import rolesimage from "../images/left_icons/roles.png";
import Checked from "../images/checked.png";
import Edit from "../images/edit.png";
import LeftMenu from "../layout/leftMenu";
import { backendApi } from "../apiService";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import * as Icon from "react-feather";
import $ from "jquery";
import Select from "react-select";

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      roleList: [],
      approved: false,
      userID: window.sessionStorage.getItem("userId"),
      isLogin: window.sessionStorage.getItem("isLogin"),
      userRole: window.sessionStorage.getItem("role"),
      userOrganization: window.sessionStorage.getItem("organization"),
      organizationAllotment:
        window.sessionStorage.getItem("organizationAllotment") === "undefined"
          ? []
          : JSON.parse(window.sessionStorage.getItem("organizationAllotment")),
      modules: JSON.parse(window.sessionStorage.getItem("modules")),
      moduleActive: false,
      organizationListActive: false,
      moduleId: [],
      organizationListId: [],
      userRoleid: "",
      userListModules: [],
      searchValue: "",
      statesList: [],
      cityList: [],
      groupName: "",
      selectedCity: [],
      emailFilter:
        window.sessionStorage.getItem("role") == "ORG_ADMIN"
          ? window.sessionStorage.getItem("emailsession") != ""
            ? window.sessionStorage
                .getItem("emailsession")
                .split("@")[1]
                .split(".")[0]
            : ""
          : this.props.organizationemail,
      selectedState: "",
      globalFilterLocationsList: "",
      selectedLocations: [],
      backupStates: [],
    };
  }

  changeStatus(e, moduleId) {
    $("#moduleIds" + moduleId).toggleClass("active");

    let newArray = [...this.state.moduleId, moduleId];

    if (this.state.moduleId.includes(moduleId)) {
      newArray = newArray.filter((checked) => checked !== moduleId);
    }
    if (this.state.modules.length == newArray.length) {
      $("#modulesSelectAll").prop("checked", true);
    }
    this.setState({
      moduleId: newArray,
    });
  }
  changeOrgStatus(e, orgId) {
    $("#organizationId" + orgId).toggleClass("active");

    let newArray = [...this.state.organizationListId, orgId];
    if (this.state.organizationListId.includes(orgId)) {
      newArray = newArray.filter((checked) => checked !== orgId);
    }

    this.setState({
      organizationListId: newArray,
    });
  }
  saveModules() {
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "addModulesForUser",
      userId: window.sessionStorage.getItem("userId"),
      moduleUserId: this.state.userRoleid,
      moduleIdsList: this.state.moduleId,
    };

    backendApi(req).then((response) => {
      $(".loder_div").attr("hidden", true);
      if (response.success === "1") {
        alert("Modules added successfully");
        window.location.reload();
      }
    });
  }

  saveOrgAllotment() {
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "addorgForUser",
      user_id: this.state.userRoleid,
      organisation_id: this.state.organizationListId,
    };

    backendApi(req).then((response) => {
      $(".loder_div").attr("hidden", true);
      if (response.success === "1") {
        alert("Organization(s) added successfully");
        window.location.reload();
      }
    });
  }
  componentDidMount() {
    this.getRoles();
    this.getUserList();
    this.getAdministrativeArea();
    $("#editCommentPopup").attr("hidden", true);
  }
  toggleModal(id) {
    $(".loder_div").attr("hidden", false);
    var apiReq = {
      id: id,
      functionName: "getUserModuleList",
    };
    var array = [];
    backendApi(apiReq).then((response) => {
      $(".loder_div").attr("hidden", true);
      console.log("datatat", response.data[1]);
      if (this.state.modules.length == response.data[1].length) {
        $("#modulesSelectAll").prop("checked", true);
      } else {
        $("#modulesSelectAll").prop("checked", false);
      }
      if (response.data[1].length > 0) {
        response.data[1].forEach((module) => {
          $("body").addClass("isActive");
          array.push(module.moduleId);
          $("#moduleIds" + module.moduleId).addClass("active");
        });
      } else {
        $("body").addClass("isActive");
      }
      this.setState({
        userRoleid: id,
        moduleId: array,
      });
    });
  }
  toggleModal2(id) {
    $(".loder_div").attr("hidden", false);
    var apiReq = {
      id: id,
      functionName: "getUserModuleList",
    };
    var array = [];
    backendApi(apiReq).then((response) => {
      $("#editCommentPopup").attr("hidden", false);
      $(".loder_div").attr("hidden", true);
      if (response.success == "1") {
        response.data[0].forEach((obj) => {
          array.push(obj.id);
          $("#organizationId" + obj.id).addClass("active");
        });
        this.setState({
          userRoleid: id,
          organizationListId: array,
        });
      }
    });
  }

  removeModal() {
    $("body").removeClass("isActive");
    $(".modules_div").removeClass("active");
    $("#modulesSelectAll").prop("checked", false);
  }
  getRoles() {
    var req = {
      functionName: "roleList",
    };
    backendApi(req).then((response) => {
      if (response.success === "1") {
        this.setState({ roleList: response.result }, () => {});
      }
    });
  }

  getAdministrativeArea = () => {
    const businessAccountData = {
      functionName: "getAdministrativeArea",
      account_id: window.sessionStorage.getItem("account_id"),
    };
    backendApi(businessAccountData).then((data) => {
      if (data.success === "1") {
        this.setState({
          statesList: data.data.map((obj) => obj.AdministrativeArea),
        });
      } else {
        this.setState({
          statesList: [],
        });
      }
    });
  };

  getUserList() {
    $(".loder_div").attr("hidden", false);
    var req = {
      userId: this.state.userID,
      role: this.state.userRole,
      organization: this.state.userOrganization,
      functionName: "userlist",
    };
    const apiReq = {
      userId: this.state.userID,
      role: this.state.userRole,
      organization: this.state.userOrganization,
      functionName: "userlistNew",
      searchParam: this.state.searchValue,
    };
    backendApi(apiReq).then((response) => {
      $(".loder_div").attr("hidden", true);
      if (response.success === "1") {
        console.log("loooooog", response);
        this.setState({ userList: response.data });
      } else {
        this.setState({ userList: [] });
      }
    });
  }
  approveUser(id, status, email, name, mobile) {
    $(".loder_div").attr("hidden", false);
    var statusTrue =
      $('input[name="status' + id + '"]:checked').val() === "on" ? true : false;
    var req = {
      functionName: "approveUser",
      approveStatus: statusTrue,
      userId: id,
      emailId: email,
      userName: name,
      mobileNo: mobile,
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((response) => {
      $(".loder_div").attr("hidden", true);
      if (response.success === "1") {
        $(".loder_div").attr("hidden", true);
        alert("Status Changed");
        this.getUserList();
      }
    });
  }
  changeRole(roleid, userole) {
    if (roleid !== "Select") {
      var req = {
        functionName: "updateRole",
        listUserId: userole,
        roleId: roleid,
      };
      $(".loder_div").attr("hidden", false);
      backendApi(req).then((response) => {
        if (response.success === "1") {
          alert("Role changed successfully");
          $(".loder_div").attr("hidden", true);
          this.getUserList();
        }
      });
    }
  }
  selectAll(checked) {
    var array = [];
    if (checked) {
      this.state.modules.map((obj) => array.push(obj.moduleId));

      this.setState({
        moduleActive: true,
        moduleId: array,
      });
    } else {
      this.setState({
        moduleActive: false,
        moduleId: [],
      });
    }
  }
  selectAllOrgs(checked) {
    var array = [];
    if (checked) {
      this.state.organizationAllotment.map((obj) => array.push(obj.id));
      this.setState({
        organizationListActive: true,
        organizationListId: array,
      });
    } else {
      this.setState({
        organizationListActive: false,
        organizationListId: [],
      });
    }
  }

  render() {
    return (
      <div className="main_wrapper">
        {this.state.isLogin === "true" &&
        (this.state.userRole === "ROLE_SUPER_ADMIN" ||
          this.state.userRole === "ROLE_ADMIN" ||
          this.state.userRole === "ORG_ADMIN") ? (
          <>
            <LeftMenu></LeftMenu>

            <div className="userListWrapper">
              {this.props.emailflag == undefined && (
                <Header heading="User Management" headerImage={rolesimage} />
              )}
              <div className="container-fluid">
                <div className="row justify-content-between mb-3 mx-0">
                  <div className="searchSection">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search by keyword"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(e) =>
                          this.setState({ searchValue: e.target.value })
                        }
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-search"
                          type="button"
                          onClick={() => this.getUserList()}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                  <Link
                    className="btn-addNew"
                    to={{
                      pathname: "/newUser",
                    }}
                  >
                    Add New User{" "}
                    <span>
                      <Icon.PlusCircle />{" "}
                    </span>
                  </Link>
                </div>

                <div className="col-md-12 tableResponsive">
                  <div className="tableOuterWidth table_width_lg">
                    {this.state.userList.length > 0 ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="col-1">Sl. No.</th>
                            <th className="col-3">
                              ID/Name/Mobile Number/Email-Id
                            </th>
                            <th className="col-1">Organization</th>
                            <th className="col-1">Edit</th>
                            <th className="col-1">Approve User</th>
                            <th className="col-2">Set Role</th>
                            <th className="col-1">Access Modules</th>
                            <th className="col-1">Org. Allotment</th>
                            {/* <th className="col-1">Group</th> */}
                          </tr>
                        </thead>
                        <div class="table_Outerscroll">
                          {this.state.userList.map((data, i) => (
                            <tbody className="table_Outertbody">
                              <tr>
                                <td className="col-1">{i + 1}</td>
                                <td className="col-3">
                                  {data.id}-&nbsp;{data.first_name}&nbsp;
                                  {data.last_name}
                                  <br />
                                  {data.mobile_no}
                                  <br />
                                  {data.email_id}
                                </td>
                                <td className="col-1">{data.organization}</td>
                                <td className="col-1">
                                  <span>
                                    {data.is_privileged !== 1 &&
                                      data.role !== "ROLE_SUPER_ADMIN" && (
                                        <Link
                                          to={"/newUser"}
                                          state={{
                                            id: data.id,
                                            oldUser: true,
                                          }}
                                        >
                                          <img
                                            src={Edit}
                                            alt="delete"
                                            width="20"
                                            className="iconAction"
                                          />
                                        </Link>
                                      )}
                                  </span>
                                </td>
                                <td className="col-1">
                                  <span className="switch round"></span>
                                  <span>
                                    <label className="switch_items">
                                      <input
                                        id={"status" + data.id}
                                        name={"status" + data.id}
                                        type="checkbox"
                                        defaultChecked={
                                          data.is_approved === 1 ? true : false
                                        }
                                        onClick={() =>
                                          this.approveUser(
                                            data.id,
                                            true,
                                            data.email_id,
                                            data.first_name,
                                            data.mobile_no
                                          )
                                        }
                                      />
                                      <span className="switch round"></span>
                                    </label>
                                  </span>
                                </td>
                                <td className="col-2">
                                  <select
                                    className="form-control"
                                    id={"roleSelect" + data.id}
                                    onChange={(e) =>
                                      this.changeRole(e.target.value, data.id)
                                    }
                                    defaultValue={data.roleId}
                                  >
                                    <option>Select</option>
                                    {this.state.roleList.map((options) => (
                                      <option
                                        value={options.id}
                                        label={options.display_name}
                                      >
                                        {options.display_name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td className="text-center col-1">
                                  <span
                                    className="p-0 ml-2 add_usser_listplus"
                                    onClick={() => this.toggleModal(data.id)}
                                  >
                                    <Icon.PlusCircle />
                                  </span>
                                </td>
                                <td className="text-center col-1">
                                  <span
                                    className="p-0 ml-2 add_usser_listplus"
                                    onClick={() => {
                                      this.toggleModal2(data.id);
                                    }}
                                  >
                                    <Icon.PlusCircle />
                                  </span>
                                </td>
                                {/* <td className="text-center col-1">
                                  <span
                                    className="p-0 ml-2 add_usser_listplus"
                                    onClick={() => {
                                      this.toggleModal3(data.id);
                                    }}
                                  >
                                    <Icon.PlusCircle />
                                  </span>
                                </td> */}
                              </tr>
                            </tbody>
                          ))}
                        </div>
                      </table>
                    ) : (
                      <h3>No Data Found</h3>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </>
        ) : (
          ""
        )}
        <div className="loder_div" hidden />

        <div className="userList_modal">
          <div className="userListmodal_inner">
            <div className="userListmodal_head">
              <span className="subhead">Modules</span>
              <label className="mb-0">
                <input
                  id="modulesSelectAll"
                  type="checkbox"
                  onChange={(e) => this.selectAll(e.target.checked)}
                />
                <span>Select All</span>
              </label>
            </div>
            <div className="userlistmodal_body">
              {this.state.modules.map((item) => {
                if (item.moduleId !== 3 && item.moduleId !== 1)
                  return (
                    <div
                      className={
                        this.state.moduleActive === true
                          ? "modules_div active"
                          : "modules_div "
                      }
                      id={"moduleIds" + item.moduleId}
                      onClick={() =>
                        this.changeStatus(
                          this,
                          item.moduleId,
                          this.state.moduleId
                        )
                      }
                    >
                      {item.moduleName}
                    </div>
                  );
              })}
            </div>

            <div className="userlistModal_footer">
              <button
                className="btn btn-success"
                onClick={() => this.saveModules()}
              >
                Save
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => this.removeModal()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div id="editCommentPopup" className="module_popup" hidden>
          <div className="module_innerpopup">
            <div className="module_close">
              <Icon.XCircle
                onClick={() => {
                  $("#editCommentPopup").attr("hidden", true);
                  $(".modules_div_org").removeClass("active");
                  $("#orgainzationSelectAll").prop("checked", false);
                }}
              />
            </div>
            <div className="userListmodal_head">
              <span className="subhead">Organizations</span>
              <label className="mb-0">
                <input
                  type="checkbox"
                  id="orgainzationSelectAll"
                  onChange={(e) => this.selectAllOrgs(e.target.checked)}
                />
                <span>Select All</span>
              </label>
            </div>
            <div id="editCommentPopupHeader" className="module_header"></div>
            <div className="module_body">
              <div className="module_popupsearch_wrpr">
                <div className="userlistmodal_body">
                  {this.state.organizationAllotment.map((item) => (
                    <div
                      className={
                        this.state.organizationListActive === true
                          ? "modules_div_org active"
                          : "modules_div_org "
                      }
                      id={"organizationId" + item.id}
                      onClick={() =>
                        this.changeOrgStatus(
                          this,
                          item.id,
                          this.state.organizationListId
                        )
                      }
                    >
                      {item.organisation_name}
                    </div>
                  ))}
                </div>
                <div className="module_popupsearchBtn">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.saveOrgAllotment()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserList;
