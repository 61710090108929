import React, { Component } from "react";
import Header from "../layout/header";
import LeftMenu from "../layout/leftMenu";
import Footer from "../layout/footer";
import LiveMonitoringimage from "../images/left_icons/live.png";
import { backendApi, pythonBackendApi } from "../apiService";
import moment from "moment";
import $ from "jquery";
import * as Icon from "react-feather";

export default class AddPhotos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataForListing: [],
      locationsList: [],
    };
  }
  componentDidMount() {
    this.getListOfProcess();
    this.newGetLocationsList();
  }
  getListOfProcess = () => {
    $(".loder_div").attr("hidden", false);
    var apidata = {
      functionName: "gbpImageUploadListing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      location_id: "",
    };
    backendApi(apidata).then((data) => {
      this.setState({
        dataForListing: data.data,
      });
      $(".loder_div").attr("hidden", true);
    });
  };
  newGetLocationsList = () => {
    $(".loder_div").attr("hidden", false);
    const apiJson = {
      functionName: "getMasterDataForFilter",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apiJson).then((data) => {
      $(".loder_div").attr("hidden", true);
      this.setState({
        locationsList: data.data,
      });
    });
  };
  render() {
    let locationFilterList = this.state.dataForListing.filter((data) =>
      this.state.locationsList.some(
        (obj) => data.location_id == obj.location_id
      )
    );
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          <Header
            heading="Photos Upload History List"
            headerImage={LiveMonitoringimage}
          />
          <div className="container-fluid">
            {locationFilterList.length ? (
              <div className="col-md-12 tableResponsive">
                <div className="tableOuterWidth">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-1">Id</th>
                        <th className="col-3">Location_id</th>
                        <th className="col-2">Created on</th>
                        <th className="col-2">Deleted Date</th>
                        <th className="col-2">Status</th>
                        <th className="col-2">Image </th>
                      </tr>
                    </thead>
                    <div class="table_Outerscroll">
                      {locationFilterList.length > 0 && (
                        <>
                          {locationFilterList.map((data, i) => {
                            let DateTime = data.created_on;
                            DateTime =
                              moment(DateTime).format("DD-MM-YY hh:mm a");
                            let location = this.state.locationsList.filter(
                              (obj) => obj.location_id === data.location_id
                            );
                            console.log("location" + i, location);
                            return (
                              <tbody className="table_Outertbody ">
                                <tr>
                                  <td className="col-1">{data.id}</td>
                                  <td className="col-3">
                                    <span
                                      style={{
                                        textDecoration: "underline",
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {location[0].title}
                                    </span>
                                    <span style={{ display: "block" }}>
                                      {location[0].addressLines},{" "}
                                      {location[0].locality},{" "}
                                      {location[0].AdministrativeArea}
                                    </span>
                                    ({location[0].location_id})
                                  </td>

                                  <td className="col-2">{DateTime}</td>
                                  <td className="col-2">
                                    {data.deleted_date
                                      ? `Deleted On: ${moment(
                                          data.deleted_date
                                        ).format("DD-MM-YY hh:mm a")}`
                                      : "Not Available"}
                                  </td>
                                  <td className="col-2">
                                    {/* {data.function_name}{" "} */}
                                    {data.is_active == 0 ? (
                                      <span title="In-active">
                                        <Icon.AlertTriangle
                                          style={{ color: "red" }}
                                          className="icon_common"
                                        />
                                      </span>
                                    ) : (
                                      <span title="Running">
                                        <Icon.CheckCircle
                                          style={{ color: "green" }}
                                          className="icon_common"
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td className="col-2">
                                    {" "}
                                    <img
                                      src={data.image_path}
                                      alt="Gbpimage"
                                      className="img-fluid"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </table>
                </div>
              </div>
            ) : (
              "No Data Found"
            )}
          </div>
          <Footer />
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}

// export default VMProcessStatus;
