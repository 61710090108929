import React, { useEffect, useState } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Footer from "../layout/footer";
import Accessibiltyimage from "../images/left_icons/GBP.png";
import EnableInsights from "./enableInsights";
import { backendApi } from "../apiService";
import RequestInsights from "./requestInsights";
import InsightsListing from "./insightsListing";
import RemoveInsights from "./removeInsights";

const InsightsScheduler = () => {
    const[insightData,setInsightData]=useState([]);
    const [topicType, setTopicType] = useState("");
  useEffect(() => {
    getInsightsData();
  }, []);

  const getInsightsData = () => {
    const apiReq = {
      functionName: "requestInsightDataListing",
      siteId:  window.sessionStorage.getItem("switchingOrgId") === ""
      ? window.sessionStorage.getItem("organizationId")
      : window.sessionStorage.getItem("switchingOrgId")
    };
    console.log("req", apiReq);
    backendApi(apiReq).then((data) => {
      if (data.success == "1") {
        setInsightData(data.data);
      } else {
        console.log("Something went wrong");
      }
    });
  };

  const updateInsightsData = () => {
    getInsightsData();
  };

  return (
    <>
      {" "}
      <div className=" main_wrapper">
        <LeftMenu />
        <div className=" userListWrapper">
          <Header
            heading="Insights Scheduler"
            headerImage={Accessibiltyimage}
          />

          <div className="container">
            <div className="row mx-0">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                  <Tabs
                    selectedIndex={
                     topicType === "ENABLE"
                        ? 0
                        : topicType === "REQUEST"
                        ? 1
                        : topicType === "REMOVE"
                        ? 2
                        : topicType === "LIST"
                        ? 3
                        : 0
                    }
                    className="tabs-main"
                    onSelect={(e) =>
                      setTopicType(
                        e === 0
                          ? "ENABLE"
                          : e === 1
                          ? "REQUEST"
                          : e === 2 ? "REMOVE"
                          : e === 3 && "LIST"
                      )
                    }
                  >
                    <TabList className={"pl-0"}>
                      <Tab>Enable Scheduler</ Tab>
                      <Tab>Request Insights Data</Tab>
                      {/* <Tab>Remove Insights Data</Tab> */}
                      <Tab>Insights Request History</Tab>
                    </TabList>

                    <hr></hr>
                    <TabPanel>
                      <EnableInsights />
                    </TabPanel>
                    <TabPanel><RequestInsights onUpdateInsightsData={updateInsightsData} /></TabPanel>
                    {/* <TabPanel><RemoveInsights onUpdateInsightsData={updateInsightsData}/></TabPanel> */}
                    <TabPanel><InsightsListing data={insightData} /></TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default InsightsScheduler;
