import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { backendApi } from "../apiService";
import LeftMenu from "../layout/leftMenu";
import Lock from "../images/left_icons/lock2.png";
import Header from "../layout/header";
import Footer from "../layout/footer";
import $ from "jquery"

function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newConfirmPassword, setNewConfirmPassword] = useState("");
  const changePassword = (e) => {
    e.preventDefault()
    if (currentPassword === "") {
      alert("Enter current password");
      return false;
    } else if (newPassword === "") {
      alert("Enter current password");
      return false;
    } else if (newConfirmPassword === "") {
      alert("Enter current password");
      return false;
    } else if (newPassword !== newConfirmPassword) {
      alert("New password and confirm password must be same");
      return false;
    }
    var req = {
      functionName: "changePassword",
      userId: window.sessionStorage.getItem("userId"),
      currentPassword: currentPassword,
      newPassword: newConfirmPassword,
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((data) => {
      alert("Password Changed Successfully");
      $(".loder_div").attr("hidden", true);
    });
  };
  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Change Password" headerImage={Lock}/>
        <div className="user_listdiv">
          <form className="user_formdiv col-md-10">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Current Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="firstName"
                    aria-describedby="emailHelp"
                    placeholder="Enter Current Password"
                    onChange={(event) => setCurrentPassword(event.target.value)}
                    value={currentPassword}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">New Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="lastName"
                    aria-describedby="emailHelp"
                    placeholder="Enter New Password"
                    onChange={(event) => setNewPassword(event.target.value)}
                    value={newPassword}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    Confirm New Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="emailId"
                    aria-describedby="emailHelp"
                    placeholder="Confirm New Password"
                    onChange={(event) =>
                      setNewConfirmPassword(event.target.value)
                    }
                    value={newConfirmPassword}
                  />
                </div>
              </div>
            </div>
            {/* <div className="form_bttn">
              <button
                type="submit"
                className="btn btn-primary submitButton cancel_bttn"
              >
                <Link to="/userList">Cancel</Link>
              </button>
            <button
              type="submit"
              className="btn btn-primary submitButton"
              onClick={changePassword}
            >
              Update
            </button>
            </div>  */}

            <div class="button-wrapper tag_changePswdHover">
              <button type="submit" class="btn-cancel "> <Link to="/userList">Cancel</Link></button>
              <button type="submit" class="btn-submit" onClick={changePassword}>Update</button>
            </div>
          </form>
        </div>
      </div>
      <div className="loder_div" hidden />
      <Footer/>
    </div>
  );
}

export default ChangePassword;
