import React, { useEffect, useState } from 'react'
import LineChart from './line-graphLeads';
import { backendDashboardApi } from "../apiService";
function LeadsGenerated({formattedDates}) {
    const [leadsData, setLeadsData] = useState([]);
    useEffect(() => {
        Listing()
    }, [formattedDates])
    const Listing = () => {
        const postData = {
            "functionName": "leadsGenerated",
            "userId":window.sessionStorage.getItem("userId"),
            "accountId":window.sessionStorage.getItem("switchingOrgId"),
            "startDate":formattedDates[0],
            "endDate": formattedDates[1]
        }
        backendDashboardApi(postData).then((data) => {
            console.log("leadsgenerated", data)
            if (data.success === "1") {
                setLeadsData(data.result)
            } else {
                setLeadsData([])
            }
        });
    };
    return (
        <div className= {window.sessionStorage.getItem("roleId") === "12" ? "db-cards-left db-leads-generated" : "db-cards-left"}>
            <div class="card db-height"  style={{ marginBottom: window.sessionStorage.getItem("roleId") === "12" ? 0 : "15px" }}>
                <div class="card-body mb-0 p-0 pt-2 pb-2">
                    <div className='db_leads_main_div'>
                        <div class="card bg-white shadow-none">
                            <div className='db_generator_box'>Leads Generated</div>
                            <div className='db_generator_chat'>
                                <LineChart fromPage={"leadsGenerated"} formattedDates={formattedDates}/>
                            </div>
                        </div>
                    </div>
                    {leadsData && 
                    <div className='leads-generated active_div'>
                        <div className='leads-left '>
                            <div class="card bg-white shadow-none">
                                <div className='db-cards-top'>
                                    <h5 class="db-card-title w-100">Lead Feedback %</h5>
                                    <span className='db-right-number db-leads-amout'> {leadsData.leadFeedbackpercent}
                                        <span class="db-percentage">(%)</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='leads-left'>
                            <div class="card bg-white shadow-none">
                                <div className='db-cards-top'>
                                    <h5 class="db-card-title w-100">Customer interested</h5>
                                    <span className='db-right-number db-leads-amout'> {leadsData.customerInterested}
                                    <span className='db-percentage'>{(leadsData.customerInterestedSymbol) && `(${leadsData.customerInterestedSymbol})`}</span>
                                        {/* <span class="db-percentage">{(leadsData.customerInterestedSymbol)}</span> */}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='leads-left'>
                            <div class="card bg-white shadow-none">
                                <div className='db-cards-top'>
                                    <h5 class="db-card-title w-100">Bookings</h5>
                                    <span className='db-right-number db-leads-amout'>{leadsData.bookings}
                                        <span class="db-percentage">{(leadsData.bookingsSymbol)}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='leads-left'>
                            <div class="card bg-white shadow-none">
                                <div className='db-cards-top'>
                                    <h5 class="db-card-title w-100">Enquries</h5>
                                    <span className='db-right-number db-leads-amout'>{leadsData.enquires}
                                    <span className='db-percentage'>{(leadsData.enquiresSymbol) && `(${leadsData.enquiresSymbol})`}</span>
                                        {/* <span class="db-percentage">{(leadsData.enquiresSymbol)}</span> */}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default LeadsGenerated;