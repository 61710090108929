import React, { useState } from "react";
import "../css/bootstrap.css";
import * as Icon from "react-feather";
import "../css/style.css";
import WhatsNew from "../images/whats-new.png";
import $ from "jquery";
import DatePicker from "react-datepicker";
import { apiCallingPost } from "../apiService";
import AWS from "aws-sdk";
import { backendApi } from "../apiService";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
const s3ImageUrl =
  "http://tagtree.s3-website.ap-south-1.amazonaws.com/production/images";
var s3Region;
var s3bucketAccessKey;
var s3bucketSecretKey;
var s3Bucket;
var s3BucketMainFolder;
var S3BucketImagesFolder = "images";
var bucketParams;
var s3;
function PostNew() {
  const s3Region = "us-east-1",
    s3bucketAccessKey = process.env.REACT_APP_TAGTREE_S3_ACCESS_KEY,
    s3bucketSecretKey = process.env.REACT_APP_TAGTREE_S3_SECRET_KEY,
    s3Bucket = "tagtree",
    s3BucketMainFolder = "production",
    S3BucketImagesFolder = "images",
    bucketParams = {
      Bucket: s3Bucket,
    };
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [description, setDescription] = useState("");
  const [eventType, setEventType] = useState("STANDARD");
  const [imageUrl, setImageUrl] = useState([]);
  const [displayImages, setDisplayImages] = useState([]);
  const [tempImagesList, setTempImagesList] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [callToActionButton, setCallToActionButton] = useState("0");
  const [callToActionUrl, setCallToActionUrl] = useState("");
  const [s3Credentials, setS3Credentials] = useState([]);
  const [eventTitle, setEventTitle] = useState("");
  var location = useLocation();
  const navigate = useNavigate();
  // mediaEventType: {
  //   mediaFormat: "PHOTO",
  //   sourceUrl: jsonObj.bannerImages,
  // }
  useEffect(() => {
    // console.log(
    //   "location.state?.callToAction?.actionType",
    //   location.state?.callToAction?.actionType
    // );
    setCallToActionButton(
      location.state?.callToAction?.actionType
        ? location.state?.callToAction?.actionType
        : "0"
    );
    setDescription(location.state?.summary ? location.state?.summary : "");
    setCallToActionUrl(
      location.state?.callToAction?.url != ""
        ? location.state?.callToAction?.url
        : ""
    );
    if (location.state?.callToAction?.actionType) {
      $("#button_URL").attr("hidden", false);
    }
    if (location?.state?.bannerImages?.length > 0) {
      setShowPreview(true);
      setDisplayImages([location?.state?.bannerImages[0].url]);
      setTempImagesList([location?.state?.bannerImages[0].url]);
      setImageUrl([
        {
          url: location.state?.bannerImages[0].url,
          type: location.state?.bannerImages[0].type,
        },
      ]);
    }
    getS3Credentials();
    location.state.PostEditField.length !== 0 &&
      getEventEditDetails(location.state.PostEditField);
  }, []);
  const getS3Credentials = () => {
    AWS.config.update({
      accessKeyId: s3bucketAccessKey,
      secretAccessKey: s3bucketSecretKey,
      region: s3Region,
    });
    s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: bucketParams,
    });
    // console.log("s3", s3);
    // });
  };
  const getEventEditDetails = (params) => {
    console.log("paraaaaams", params);
    // $("#descriptionField").val(params.summary);
    setDescription(params.summary);
    // $("#callToActionButtons").val(params.callToAction.actionType);
    params?.callToAction &&
      setCallToActionButton(params.callToAction.actionType);

    params.callToAction && setCallToActionUrl(params.callToAction.url);
    if (params.callToAction) {
      $("#button_URL").attr("hidden", false);
    }
    if (params.media) {
      setShowPreview(true);
      // params.media.forEach((obj) => {
      //   displayImages.push(obj.googleUrl);
      //   tempImagesList.push(obj.googleUrl);
      // });
      $("#deleteImageIcon0").attr("hidden", true);
      setDisplayImages([params.media[0].googleUrl]);
      setTempImagesList([params.media[0].googleUrl]);
      setImageUrl([
        {
          url: params.media[0].googleUrl,
          type: "PHOTO",
        },
      ]);
    }
  };
  const changeDescriptionField = (event) => {
    if (event.target.value.length < 1500) {
      setDescriptionLength(event.target.value.length);
      setDescription(
        event.target.value !== ""
          ? event.target.value
          : $("#descriptionField").val()
      );
    } else {
      alert("Description cannot have more than 1500 characters");
    }
  };
  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  const handleImageUpload = (image) => {
    // var totalCount = image.length;
    $(".loder_div").attr("hidden", false);
    let s3BucketMainFolder1 = s3BucketMainFolder + "/" + S3BucketImagesFolder;
    if (location?.state?.PostEditField?.media?.length > 0) {
      navigateToNextPage();
      return false;
    }
    // for (let i = 0; i < image.length; i++) {
    // let fileName = uuidv4() + image[i].name;
    let fileName = uuidv4() + image.name;
    let fullFilePath = s3BucketMainFolder1 + "/" + fileName;
    s3.upload(
      {
        Key: fullFilePath,
        // Body: image[i],
        Body: image,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          alert("There was an error uploading your image");
          // console.log(err.message);
        } else if (data) {
          // totalCount = totalCount - 1;
          // console.log(data.Location);
          // $("#uploadedImage").attr("hidden", false);
          // $("#multipleImagesSlider").attr("hidden", false);
          // imageUrl.push({ url: data.Location, type: image[i].type });
          imageUrl.push({ url: data.Location, type: image.type });
          // if (totalCount === 0) {
          $(".loder_div").attr("hidden", true);
          navigateToNextPage();
          // }
          // console.log("imageUrlimageUrlimageUrl", this.state.imageUrl);
          // this.setState({
          //   imageUrlListStatus: i + 1,
          // });
          // this.state.imageName.push(data.fileName);
          // this.setState({ imageUrl: data.Location, imageName: fileName });

          return;
        }
      }
    );
    // }
  };
  const fileUploadHandler = (e) => {
    let image = e.target.files;
    for (let i = 0; i < image.length; i++) {
      if (image[i].size < 10240) {
        alert("Files must be greater than 10KB");
        return;
      } else if (image[i].size > 4900000) {
        alert("Files must be less than 5MB");
        return;
      } else {
        setDisplayImages([window.webkitURL.createObjectURL(e.target.files[0])]);
        setTempImagesList([e.target.files[0]]);
        e.target.value = [];
        setImageUrl([]);
      }
    }
    setShowPreview(true);
  };
  const navigateToNextPage = () => {
    navigate("/googlepreview", {
      state: {
        ...(location.state.PostEditField.length !== 0
          ? { bannerImages: [tempImagesList[0]] }
          : {
              bannerImages: imageUrl.length > 0 ? [imageUrl[0]] : [],
            }),
        title: description.slice(0, description.indexOf(" ")),
        summary: description,
        PostEditField:
          location.state.PostEditField.length !== 0
            ? location.state.PostEditField
            : [],
        fromClaimData: location.state?.fromClaimData,
        eventType: "STANDARD",
        completeLocationData: location.state.completeLocationData,
        selectedLocations: location.state.selectedLocations,
        callToAction: {
          actionType: callToActionButton,
          url: callToActionUrl,
        },
      },
    });
  };
  const validatePreview = () => {
    if (
      tempImagesList.length === 0 ||
      description === "" ||
      $("#descriptionField").val() === ""
    ) {
      alert("Both Photo(s) and Summary are required to Post");
    } else {
      handleImageUpload(tempImagesList[0]);
    }
  };
  const deleteImages = (i) => {
    displayImages.splice(i, 1);
    setDisplayImages(displayImages);
    displayImages.length === 0 && setShowPreview(false);
    displayImages.length === 0 && setDisplayImages([]);
  };
  return (
    <div className="row">
      <div className="tag_info_contentlocation ">
        <div className="right_google_popup" style={{ margin: "0px" }}>
          <label>
            <Icon.Camera />
            Add photos
            <input
              type="file"
              id="imageUpload"
              onChange={(e) => fileUploadHandler(e)}
            />
          </label>
        </div>
        {/* <div className="tag_googlePhotoSave">
          <button
            type="submit"
            className="btn btn-success"
            onClick={() => handleImageUpload(tempImagesList)}
          >
            Upload
          </button>
        </div> */}
      </div>

      {showPreview === true && (
        <div id="displayImagesList" className="tag_googledisplayImagesList">
          <div className="tag_google">
            <div className="row">
              <div className=" tag_imgWrap">
                <div className="multipleImagesSlider">
                  {displayImages.map((data, i) => (
                    <>
                      <img src={data} />
                      <span className="close_popup_imageUpload">
                        <Icon.X
                          id={"deleteImageIcon" + i}
                          onClick={() => {
                            deleteImages(i);
                          }}
                        />
                      </span>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* ===================== OLD CODE BEGINS HERE ========================= */}
      {/* <div className="col-lg-12 mb-5"> */}
      {/* <div
          className="right_google_popup text-center"
          style={{ width: "300px" }}
        >
          <label>
            <Icon.Camera />
            Add photos
            <input
              type="file"
              id="imageUpload"
              multiple
              onChange={(e) => fileUploadHandler(e.target.files)}
            />
          </label>
          <button
            type="submit"
            className="btn btn-info mt-2"
            onClick={() => handleImageUpload(imageUrl)}
          >
            Upload
          </button>
        </div> */}
      {/* </div> */}

      {/* ===================== OLD CODE ENDS HERE ========================= */}
      <br />
      <div className="tag_whatsNew">
        <div className="row mt-3   ">
          {/* <div className="col-lg-3 col-md-6 tag_formCntrl">
            <label className="m-0">
              Enter Title<sup style={{ color: "red" }}>*</sup>{" "}
            </label>
            <input
              id="EventTitle"
              type="text"
              value={eventTitle}
              className="form-control"
              onChange={(e) => setEventTitle(e.target.value)}
            />
          </div> */}
          <div className="col-lg-12 col-md-12 tag_formCntrl  ">
            <label className="m-0">
              Enter Details<sup style={{ color: "red" }}>*</sup>{" "}
            </label>
            <textarea
              id="descriptionField"
              type="text"
              value={description}
              className=" form-control mb-0"
              cols="10"
              onChange={(obj) => changeDescriptionField(obj)}
            />
            <div className="form_google_span">
              <span> {descriptionLength}/ 1500</span>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 tag_formCntrl">
            <label className="m-0">Add a button (optional)</label>
            <select
              id="callToActionButtons"
              className="form-control"
              value={callToActionButton}
              placeholder="Select"
              onChange={(e) => {
                if (e.target.value == "0" || e.target.value === "CALL") {
                  $("#button_URL").attr("hidden", true);
                } else if (e.target.value !== "0") {
                  $("#button_URL").attr("hidden", false);
                }
                setCallToActionButton(e.target.value);
              }}
            >
              <option value="0">None</option>
              <option value="BOOK">Book</option>
              <option value="ORDER">Order Online</option>
              <option value="SIGN_UP">Sign Up</option>
              <option value="LEARN_MORE">Learn More</option>
              <option value="SHOP">Buy</option>
              <option value="CALL">Call</option>
            </select>
          </div>

          <div
            id="button_URL"
            hidden
            className="col-lg-4 col-md-12 tag_formCntrl"
          >
            <label className="m-0"> Enter URL</label>
            <input
              type="text"
              className="form-control"
              value={callToActionUrl}
              placeholder="Enter URL"
              onChange={(e) => setCallToActionUrl(e.target.value)}
            />
          </div>
          {/* ================ OLD CODE BEGINS HERE ======================== */}
          {/* <div className="col-lg-12 col-md-12 col-sm-12">
        <Link
          to="/googlepreview"
          state={{
            ...$(
              imageUrl.length > 0
                ? { bannerImages: imageUrl }
                : { bannerImages: [] }
            ),
            summary: $("#descriptionField").val(),
            callToAction: {
              actionType: $("#callToActionButtons").val(),
              url: $("#callToActionURL").val(),
            },
          }}
        >
          <button
            type="button"
            className="btn btn-save mr-3 tag_submitBtn"
            disabled={
              imageUrl.length > 0 && $("#descriptionField").val() !== ""
                ? false
                : true
            }
          >
            Preview
          </button>
        </Link>

        // {/* <button type="button" className="btn btn-save tag_submitBtn">
        //     Submit
        //   </button> */}
          {/* </div> */}
          {/* ================ OLD CODE ENDS HERE ======================== */}
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="button-wrapper pb-2 mb-3">
              {/* <Link
                to="/googlepreview"
                state={{
                  ...(location.state.PostEditField.length !== 0
                    ? { bannerImages: tempImagesList }
                    : { bannerImages: imageUrl.length > 0 ? imageUrl : [] }),
                  title: description,
                  PostEditField: [],
                  eventType: "STANDARD",
                  completeLocationData: location.state.completeLocationData,
                  selectedLocations: location.state.selectedLocations,
                  callToAction: {
                    actionType: callToActionButton,
                    url: callToActionUrl,
                  },
                }}
              >

                <button
                  type="button"
                  className="btn-save ml-3 "
                  disabled={
                    (imageUrl.length !== 0 && description !== "") ||
                    $("#descriptionField").val() !== ""
                      ? false
                      : true
                  }
                >
                  Preview
                </button>
              </Link> */}
              <button
                type="button"
                className="btn-save ml-3 "
                onClick={() => validatePreview()}
              >
                {" "}
                Upload & Preview
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="loder_div" hidden></div>
    </div>
  );
}

export default PostNew;
