import React, { useEffect } from "react";
// import './Home.css';
import axios from "axios";
import Campaigns from "../components/campaigns";
import Adsets from "../components/adsets";
import Navbar from "../components/navbar";
import { Outlet } from "react-router-dom";
export default class Home extends React.Component {
  constructor(props) {
    super(props)
    //replace with FB SDK or server side in production insted of long lived user access token in frontend
    this.state = {
      userid: '3332668366947745',
      access_token: 'EAALIuyKeks0BABwbwznj4dQXJYlwIhBHGoFTCtZAZBvaZCMUyCWe1ZBGZCKFSZB2ZCvl55toCnNZBlK6CkSUKxDRWSIgdqzkDM1TquC9jnVGjJZCCz8ViEz9hKvUNQt1KEtHQugeNq16yrN51pYh4omZBBFb1201mVEwVODLrAMbaYnQZDZD',

      tab: 'campaigns',
      businesses: [],
      businessid: null
    }
    this.tabChange = this.tabChange.bind(this);
  }
  tabChange(value) {
    this.setState({ ...this.state, tab: value });
  }
  render() {
    return (<div style={{ width: window.innerWidth, height: window.innerHeight }} class="container">
      <div className="tab-container">
        <Navbar tab={this.state.tab} tabChange={this.tabChange} data={{ userid: this.state.userid, access_token: this.state.access_token }}></Navbar>
        <div className="tab-body">
          {/* <Outlet></Outlet> */}
          {/* {this.state.tab == 'campaigns'?
              <Campaigns data={{userid: this.state.userid, access_token: this.state.access_token}}/>:
              this.state.tab
              <Adsets data={{userid: this.state.userid, access_token: this.state.access_token}}></Adsets>
              } */}
        </div>
      </div>
    </div>)
  }
}