import React, { useState, useEffect } from 'react';
import axios from "axios";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import {getAdImage} from '../../apiService'
import { backendApi } from "../../apiService"

export default function AdImages(props) {
  const [images, setImages] = useState([]);

  const columns = [
    { field: 'id', header: 'ID' },
    { field: 'url', header: 'URL' },
    { field: 'width', header: 'Width' },
    { field: 'height', header: 'Height' },
    { field: 'hash', header: 'Hash' },
    { field: 'name', header: 'Name' }
  ];

  useEffect(() => {

    const credentialReq = {
      "functionName": "get_ad_images",
      "site_id": "58",
    };
    backendApi(credentialReq).then((data) => {   
console.log("..............adImages",JSON.parse(data.body).data);
setImages(JSON.parse(data.body).data)     
    }).catch(() => { })
  }, [props.data])

  const dynamicColumns = columns.map((col, i) => {
    return col.field == 'url' ? <Column key={col.field} body={(data) => { return <img style={{ maxWidth: 50 }} src={data.url}></img> }} field={col.field} header={col.header} /> : <Column key={col.field} field={col.field} header={col.header} />;
  });

  return (
    <div>
      <div className="card">
        {/* <DataTable value={images} responsiveLayout="scroll">
              {dynamicColumns}
          </DataTable> */}
        {images.length > 0 ?
          <div className="col-md-12 tableResponsive">
            <div className="tableOuterWidth">
              <table className="table">
                <thead>
                  <tr>
                    <th className="col-2">ID</th>
                    <th className="col-2">URL</th>
                    <th className="col-1">Width</th>
                    <th className="col-1">Height</th>
                    <th className="col-2">Hash</th>
                    <th className="col-1">Name</th>
                  </tr>
                </thead>
                <div className="table_Outerscroll">
                  {images.length > 0 ?
                    <>
                      {images.map((object, index) => (
                        <tbody className="table_Outertbody">
                          <tr>
                            <td className="col-2">{object.id}</td>
                            <td className="col-2">
                              <img src={object.url} className="img-fluid" width={100} height={100} />
                            </td>
                            <td className="col-1">
                              {object.width}
                            </td>
                            <td className="col-1">
                              {object.height}
                            </td>
                            <td className="col-2">
                              {object.hash}
                            </td>
                            <td className="col-1">
                              {object.name}
                            </td>

                          </tr>
                        </tbody>))}
                    </> : "NO DATA FOUND"}

                </div>
              </table>

            </div>
            {/* <div className="pagination_main  tag_postListNext">
              <Pagination
                hideDisabled
                activePage={currentPage}
                itemsCountPerPage="25"
                totalItemsCount={totalCount}
                pageRangeDisplayed="10"
                onChange={(e) => handleActivePage(e)}
              />
            </div> */}
          </div> :
          ""
        }
      </div>
    </div>
  )
}