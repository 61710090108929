import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { backendApi } from "../apiService";
import "../css/bootstrap.css";
import "../css/style.css";
import $, { data } from "jquery";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import Pagination from "react-js-pagination";
import Auditimage from "../images/left_icons/processing.png";
import DragDropFile from "../audit/dragDropFile";
import * as XLSX from "xlsx";
import DataInput from "../audit/dataInput";
import * as Icon from "react-feather";
import EditListingComponent from "../claim_business/singleClaim/components/editLisitng";
import VerifyListingComponent from "../claim_business/singleClaim/components/verifyListing";
import OTPListingComponent from "../claim_business/singleClaim/components/otpListing";
import InviteAdminToListingComponent from "../claim_business/singleClaim/components/inviteAdmin";
import moment from "moment";
var tempPostList = [];
var count;
function UidPushDataList() {
  const location = useLocation();
  const [UID, setUID] = useState();
  const [failReason, setFailReason] = useState("");
  const [propsData, setPropsData] = useState("");
  const [verifyMethod, setVerifyMethod] = useState("");
  const [verificationPin, setVerificationMethod] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [verifyVisibility, setVerifyVisibility] = useState(false);
  const [inviteAdminVisibility, setInviteAdminVisibility] = useState(false);
  const [OTPVisibility, setOTPVisibility] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [pushDataList, setPushDataList] = useState([]);
  const [errorDetails, setErrorDetails] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [backupdata, setbackupdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesList, setCategoriesList] = useState([]);
  console.log("uid", location);
  useEffect(() => {
    listWithUiId();
    getCategoriesListGoogle();
  }, []);
  const listWithUiId = () => {
    tempPostList = [];

    // setUID(parseInt(location.pathname.split("/").pop()));
    // e.preventDefault();
    const apiReq = {
      functionName: "listPushFullData",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    $(".loder_div").attr("hidden", false);
    backendApi(apiReq).then((result) => {
      $(".loder_div").attr("hidden", true);
      if (result.success === "1") {
        tempPostList.push(...result.data);
        // setLocalPostList((object) => [...object, ...data.data.localPosts]);
        // const res = result.data
        //   .slice(0)
        //   .sort((a, b) => a.createTime.localeCompare(b.createTime));
        setTotalCount(result.data.length);
        // var reverseDealerDatas = res.reverse();
        const indexOfLastPost = currentPage * 25;
        const indexOfFirstPost = indexOfLastPost - 25;
        const currentPosts = result.data.slice(
          indexOfFirstPost,
          indexOfLastPost
        );
        // setDataList(currentPosts);
        setbackupdata(result.data);
        // setclaimList(tempList);
        setPushDataList(currentPosts);
        $(".loder_div").attr("hidden", true);
      } else {
        // alert(result.data.error.message);
      }
    });
  };
  const getCategoriesListGoogle = () => {
    const apiReq = {
      functionName: "googleLocationCategoryList",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apiReq).then((data) => {
      console.log("googleLocationCategoryList", data);
      setCategoriesList(data.data.categories);
    });
  };
  const createNewLocation = (dataList) => {
    for (const obj of dataList) {
      if (obj.status === "Push Initiated") {
        var filteredCategory = [];
        if (obj?.category) {
          filteredCategory = categoriesList.filter((data) =>
            data.name.includes(obj.category)
          );
        }
        var req = {
          functionName: "createNewLocation",
          siteId:
            window.sessionStorage.getItem("switchingOrgId") === ""
              ? window.sessionStorage.getItem("organizationId")
              : window.sessionStorage.getItem("switchingOrgId"),
          name: obj.full_name,
          title: obj.full_name,
          Description: obj.Description?.obj.Description,
          PhoneNo: "+91" + obj.mobile_no,
          storeCode: obj.storeCode,
          categoryName:
            filteredCategory.length > 0
              ? filteredCategory[0]?.name
              : "categories/gcid:business_center",
          categoryDisplayName:
            filteredCategory.length > 0
              ? filteredCategory[0]?.displayName
              : "Business Centre",
          AdministrativeArea: obj.administrativeArea,
          Locality: obj.locality,
          Address: obj.address,
          latitude: obj.latitude,
          longitude: obj.longitude,
          // WebsiteUrl: obj.WebsiteUrl?.obj.WebsiteUrl,
          postalCode: obj.postalCode,
          validate: false,
        };
        let identifyId = obj.id;
        console.log("api request ", req);
        $(".loder_div").attr("hidden", false);
        backendApi(req).then((result) => {
          $(".loder_div").attr("hidden", true);
          if (result.success === "1") {
            const apiReq = {
              functionName: "updatePushStatus",
              location_id: result.data.name.split("/").pop(),
              g_pin: "",
              status: "Location Created",
              id: identifyId,
              full_name: obj.full_name,
              address: obj.address,
              mobile_no: obj.mobile_no,
              email_id: "",
              locality: obj.locality,
              category:
                filteredCategory.length > 0
                  ? filteredCategory[0]?.name
                  : "categories/gcid:business_center",
              latitude: obj.latitude,
              storeCode: obj.storeCode,
              longitude: obj.longitude,
              administrativeArea: obj.administrativeArea,
              postalCode: obj.postalCode,
              verification_code: "",
              errorMessage: "",
            };
            updatePushStatus(apiReq);
            //   this.recallCreateLocation(
            //     result.data.name != undefined ? result.data.name.split("/").pop() : ""
            //   );
          } else {
            // alert("");
            const apiReq = {
              functionName: "updatePushStatus",
              location_id: "",
              g_pin: "",
              status: "Location Creation Failed",
              id: identifyId,
              full_name: obj.full_name,
              address: obj.address,
              mobile_no: obj.mobile_no,
              email_id: "",
              latitude: obj.latitude,
              longitude: obj.longitude,
              storeCode: obj.storeCode,
              category:
                filteredCategory.length > 0
                  ? filteredCategory[0]?.name
                  : "categories/gcid:business_center",
              locality: obj.locality,
              administrativeArea: obj.administrativeArea,
              postalCode: obj.postalCode,
              verification_code: "",
              errorMessage: JSON.stringify(
                result?.data?.error?.details?.map((data) => data)
              ),
              // errorMessage: result?.data?.error?.details
              //   .map(
              //     (data) =>
              //       data.reason.replaceAll("_", " ") +
              //       " IN " +
              //       data.metadata.field_mask.replaceAll("_", " ").toUpperCase()
              //   )
              //   .join(),
            };
            console.log("apireqqqqqq", apiReq);
            updatePushStatus(apiReq);
            // alert(obj.full_name + "-" + result.data.error.details[0].reason);
            //   $("#failReason" + identifyId).text(
            //     result.data.error.details[0].reason
            //   );
          }
        });
      }
    }
  };

  const updatePushStatus = (obj) => {
    console.log("apireqqqqqq", obj);

    backendApi(obj).then(async (result) => {
      if (result.success === "1") {
        listWithUiId();
      }
    });
  };
  const popupCloseHandler = (e) => {
    setVisibility(e);
    setInviteAdminVisibility(e);
  };
  const verifyPopupCloseHandler = (e, f) => {
    setVerifyVisibility(e);
    setOTPVisibility(f);
    console.log(propsData, "propsDatapropsDatapropsDatapropsData");
    setPropsData(propsData);
  };
  //   const retryVerification = (e,f) => {
  //     setVerifyVisibility(e);
  //     setOTPVisibility(f);
  //     setPropsData(propsData);
  //   };
  //   const found = pushDataList.find(el => el.status === "Push Initiated");
  //   const ShowEditPopup = (e, data) => {
  //     return (

  //     );
  //   };
  const BulkUpload = () => {
    window.sessionStorage.setItem("pushFromNodata", 0);
    window.location.href = "/claim_business/bulkUploadVerifyPage";
  };
  const handleActivePage = (e) => {
    setCurrentPage(e);
    const indexOfLastPost = e * 25;
    const indexOfFirstPost = indexOfLastPost - 25;
    let tempList = backupdata;
    const currentPosts = tempList.slice(indexOfFirstPost, indexOfLastPost);
    setPushDataList(currentPosts);
  };
  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header heading="Push Locations" headerImage={Auditimage} />

        <div className="container-fluid">
          <div className="row mb-3 ml-3">
            <div className="col-md-12 Gbp_headtopAuto">
              <div className="row">
                <div className="col-md-3">
                  {/* <Link
              to="/claim_business/bulkUploadVerifyPage"
              className="back-to-link mr-3"
            > */}
                  <Icon.ArrowLeftCircle onClick={() => window.history.back()} />
                  {/* </Link> */}
                </div>
                <div className="col-md-7 text-right">
                  <button
                    className="btn btn-primary"
                    onClick={() => BulkUpload()}
                    style={{ padding: "4px 9px", minWidth: "160px" }}
                  >
                    Bulk Push To GBP
                    <Icon.ArrowRight className="mr-1" width={18} />
                  </button>
                </div>
                {pushDataList.some((el) => el.status === "Push Initiated") && (
                  <div className="module_popupsearchBtn">
                    <button
                      type="button"
                      className="btn btn-primary createGBP"
                      onClick={(e) => {
                        createNewLocation(pushDataList);
                      }}
                    >
                      Push to GBP
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-12 tableResponsive">
            <div className="tableOuterWidth">
              <table className="table">
                <thead>
                  <tr>
                    <th className="col-3" style={{ maxWidth: "150px" }}>
                      Store Code
                    </th>
                    <th className="col-3">Business Name</th>
                    {/* <th className="col-3">Address</th> */}
                    <th className="col-2">Created On</th>
                    <th className="col-2">Status</th>
                    {/* <th className="col-2"></th> */}
                    <th className="col-2"></th>
                  </tr>
                </thead>
                <div class="table_Outerscroll">
                  {pushDataList.map((data, i) => (
                    <tbody className="table_Outertbody">
                      <tr>
                        <td className="col-3" style={{ maxWidth: "150px" }}>
                          {data.id}
                          <br></br>
                          <span>
                            {data.storeCode != "undefined" &&
                            data.storeCode != "null" &&
                            data?.storeCode
                              ? data.storeCode
                              : ""}
                          </span>
                        </td>

                        <td className="col-3">
                          {data.full_name}{" "}
                          <span style={{ display: "block" }}>
                            {data.address}
                          </span>
                        </td>

                        {/* <td className="col-3">{data.address}</td> */}
                        <td className="col-2">
                          {moment(data.created_on).format(
                            "DD-MMM-yyyy hh:mm:ss a"
                          )}{" "}
                        </td>
                        {/* {data.status === "Location Created" && (
                        <td className="col-4">
                          <p>
                            <Icon.CheckCircle
                              style={{ color: "GREEN" }}
                              className="icon_common"
                            />
                            Created Location
                          </p>
                          <button className="btn btn-success">
                            Start Verification Process
                          </button>
                        </td>
                      )} */}
                        {data.status === "Push Initiated" && (
                          <td className="col-2">
                            <p className="mb-0">{data.status}</p>
                          </td>
                        )}
                        {data.status === "Location Creation Failed" && (
                          <>
                            <td className="col-2">
                              <p className="mb-0">
                                <Icon.Globe
                                  style={{ color: "RED" }}
                                  className="icon_common"
                                />
                                {data.status}
                              </p>
                            </td>
                            <td className="col-2">
                              <button
                                className="btn btn-success btn-success-width"
                                onClick={(e) => {
                                  setVisibility(!visibility);
                                  setPropsData(data);
                                }}
                              >
                                Edit Listing
                              </button>
                            </td>
                          </>
                        )}
                        {(data.status === "Location Created" ||
                          data.status === "Verification Failed") && (
                          <>
                            <td className="col-2">
                              <p className="mb-0">
                                {data.status === "Location Created" && (
                                  <Icon.Globe
                                    style={{ color: "GREEN" }}
                                    className="icon_common"
                                  />
                                )}
                                {data.status === "Verification Failed" && (
                                  <Icon.User
                                    style={{ color: "RED" }}
                                    className="icon_common"
                                  />
                                )}
                                {data.status}
                              </p>
                            </td>
                            <td className="col-2">
                              <button
                                className="btn btn-success btn-success-width"
                                onClick={(e) => {
                                  setVerifyVisibility(true);
                                  setPropsData(data);
                                }}
                              >
                                Verify
                              </button>
                            </td>
                          </>
                        )}
                        {data.status === "Verification Started" && (
                          <>
                            <td className="col-2">
                              <p className="mb-0">
                                <Icon.User
                                  style={{ color: "GREEN" }}
                                  className="icon_common"
                                />
                                {data.status}
                              </p>
                            </td>
                            <td className="col-2">
                              <button
                                className="btn btn-success"
                                onClick={(e) => {
                                  setOTPVisibility(!OTPVisibility);
                                  setPropsData(data);
                                }}
                              >
                                Enter Verification Code
                              </button>
                            </td>
                          </>
                        )}
                        {(data.status === "Verified" ||
                          data.status === "Admin Invitation Failed") && (
                          <>
                            <td className="col-2">
                              <p className="mb-0">
                                {data.status === "Verified" && (
                                  <Icon.MapPin
                                    style={{ color: "Green" }}
                                    className="icon_common"
                                  />
                                )}
                                {data.status === "Admin Invitation Failed" && (
                                  <Icon.CheckCircle
                                    style={{ color: "RED" }}
                                    className="icon_common"
                                  />
                                )}

                                {data.status}
                              </p>
                            </td>
                            <td className="col-3">
                              <button
                                className="btn btn-success"
                                onClick={(e) => {
                                  setInviteAdminVisibility(
                                    !inviteAdminVisibility
                                  );
                                  setPropsData(data);
                                }}
                              >
                                Invite Admin to Location
                              </button>
                            </td>
                          </>
                        )}
                        {data.status === "Admin Invited" && (
                          <td className="col-2">
                            <p className="mb-0">
                              <Icon.Compass
                                style={{ color: "GREEN" }}
                                className="icon_common"
                              />
                              {data.status}
                            </p>
                          </td>
                        )}
                        {/* {data.error != "" && (
                        <td className="col-2">
                          <button className="btn btn-danger"
                            onClick={() => {
                              setErrorPopup(true);
                              setErrorDetails(data.errorMessage.split(","));
                            }}
                          >
                            Show Errors
                          </button>
                        </td>
                      )} */}
                      </tr>
                    </tbody>
                  ))}
                </div>
              </table>
            </div>
          </div>
          {errorPopup == true && (
            <div className="module_popup">
              <div className="module_innerpopup">
                <div className="module_close">
                  <Icon.XCircle onClick={() => setErrorPopup(false)} />
                </div>
                <div className="module_header text-center">Error List</div>
                {/* <button
            type="button"
            className="btn btn-save mb-1 gobutton_position"
            onClick={() => closeHandler()}
          >
            X
          </button> */}
                <div className="module_body">
                  <div className="module_popupsearch_wrpr">
                    <div className="module_popupsearch">
                      <div className="container">
                        <form
                          className="user_formdiv col-md-10"
                          id="createLocationForm"
                        >
                          <div className="row">
                            {errorDetails.map((data) => (
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail1">
                                    {" "}
                                    Error
                                  </label>
                                  <label htmlFor="exampleInputEmail1">
                                    {" "}
                                    {data}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="pagination_main  tag_postListNext">
          <Pagination
            hideDisabled
            activePage={currentPage}
            itemsCountPerPage="25"
            totalItemsCount={totalCount}
            pageRangeDisplayed="10"
            onChange={(e) => handleActivePage(e)}
          />
        </div>
        <EditListingComponent
          props={propsData}
          onClose={popupCloseHandler}
          show={visibility}
          updateData={updatePushStatus}
        />
        <VerifyListingComponent
          props={propsData}
          onClose={verifyPopupCloseHandler}
          show={verifyVisibility}
          updateData={updatePushStatus}
          retryVerification={verifyPopupCloseHandler}
          setVerificationMethod={(e) => setVerificationMethod(e)}
          setMethod={(obj) => setVerifyMethod(obj)}
        />
        <OTPListingComponent
          props={propsData}
          verificationMethod={verifyMethod}
          onClose={(e) => setOTPVisibility(e)}
          retryVerification={verifyPopupCloseHandler}
          show={OTPVisibility}
          verification_code={verificationPin}
          updateData={updatePushStatus}
        />
        <InviteAdminToListingComponent
          props={propsData}
          onClose={popupCloseHandler}
          show={inviteAdminVisibility}
          updateData={updatePushStatus}
        />
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
}

export default UidPushDataList;
