import React, { Component, isValidElement } from "react";
import { Buffer } from "buffer";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Style } from "react-style-tag";
import SocialMediaimage from "../images/left_icons/social-media.png";
import img1 from "../images/img1.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import $ from "jquery";
import Slider from "react-slick";
import { backendApi } from "../apiService";
import AWS from "aws-sdk";
import Shuffle from "./shuffle";
// import React from "react";
import { useFlip, FlipProvider } from "react-easy-flip";
import { useEffect } from "react";
const s3ImageUrl =
  "http://tagtree.s3-website.ap-south-1.amazonaws.com/production/images";
const s3Region = "us-east-1";
// const s3bucketAccessKey = "AKIAIO4K2KIE4OR7QACQ";
// const s3bucketSecretKey = "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";
// const s3bucketAccessKey = "AKIAS3OQS5BMA346O26E";
// const s3bucketSecretKey = "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";
// const s3Bucket = "tagtree";
// const s3BucketMainFolder = "production";
const S3BucketImagesFolder = "images";
// AWS.config.update({
//   accessKeyId: s3bucketAccessKey,
//   secretAccessKey: s3bucketSecretKey,
//   region: s3Region,
// });
// var bucketParams = {
//   Bucket: s3Bucket,
// };
// let s3 = new AWS.S3({
//   apiVersion: "2006-03-01",
//   params: bucketParams,
// });
<Style>{`
    body {
      background: #222;
      color: #fff;
      position: relative;
      text-align: center;
      font-size: 1rem;
      font-family: sans-serif;
      padding-bottom: 3em;
    }
    .page-wrap {
      display: inline-block;
      margin: 2em auto;
    }
    .controls {
      &__input {
        display: block;
        margin: 0 auto;
        background: none;
        border: none;
        font-size: 1em;
        padding-bottom: .5em;
        border-bottom: 2px solid #ccc;
        text-align: center;
        outline: none;
        color: #fff;
      }
      &__btn {
        background: dodgerblue;
        color: #fff;
        border: none;
        font-size: 1em;
      }
      &__label {
        display: block;
        font-size: .8em;
        padding-top: .3em;
        margin-bottom: 2em;
      }
    }
    canvas {
      background-color: #eee;
      // opacity: 0;
      transition: opacity .3s;
      &.show {
        opacity: 1;
      }
    }
    #canvas-wrap {
      margin-top: 50px;
      position: relative;
    }
    .canvasID {
      z-index: 9999;
    }
`}</Style>;
// var tag_tree = {
//   listStyle: "none",
//   width: "200px",
//   // background: "pink",
//   height: "200px",
//   // display: "inline-block",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   float: "left",
//   marginRight: "5px",
//   objectFit: "contain"

// }
const todoItemsId = "flip-todo-items";
var canvas;
var ctx;
var img = "";
var src = "";
var text_title = "";
class Automatic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ["canvas"]: null,
      ["ctx"]: null,
      ["img"]: null,
      ["text"]: null,
      fontsizeData: "20px",
      fontstyleData: "",
      fontfamilyData: "OpenSans Regular",
      list: [],
      eventList: [],
      listText: [],
      listImage: [],
      imgId: "",
      imageArray: [],
      selectImage: "",
      selectImage1: "",
      imageUrl: "",
      imageUrl1: "",
      imagePopUp: "",
      popUpList: [],
      fullList: [],
      flag: true,
      showImage: true,
      s3bucketAccessKey: "",
      s3bucketSecretKey: "",
      s3Bucket: "",
      s3BucketMainFolder: "",
      todoItems: [],
      showFunctionalComponent: false,
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    this.selectAllWithRandomText = this.selectAllWithRandomText.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.selectSingle = this.selectSingle.bind(this);
  }
  componentDidMount() {
    console.log("this.props", this.props);
    //   if(this.props.length > 0){
    //   this.setState({
    //     // fullList: data.data,
    //     todoItems: this.props.abcd,
    //   });
    // }
    $("#ImageModal").attr("hidden", true);
    // this.imageBrandDropList()
    this.list();
    this.awsCalling();
    this.imageEventDropList();

    canvas = document.getElementById("imageCanvas");
    img = new Image();
    img.crossOrigin = "anonymous";
  }
  awsCalling() {
    var postData = {
      functionName: "s3Credentials",
    };
    backendApi(postData).then((data) => {
      if (data.success === "1") {
        console.log(
          "dataaaaaaaaaaaaaaaaaaaaaa",
          data.data[0].s3bucketAccessKey
        );
        this.setState({
          // fullList: data.data,
          s3bucketAccessKey: data.data[0].s3bucketAccessKey,
          s3bucketSecretKey: data.data[0].s3bucketSecretKey,
          s3Bucket: data.data[0].s3bucket,
          s3BucketMainFolder: data.data[0].s3Folder,
        });
      }
    });
  }
  imageBrandDropList() {
    var postData = {
      functionName: "getBrandAndEvent",
      // "brand_name": "",
      site_id: "",
      event_name: "",
      id: "",
    };
    backendApi(postData).then((data) => {
      if (data.success === "1") {
        this.setState(
          {
            list: data.data,
          },
          () => {
            // this.imageEventDropList()
          }
        );
      }
    });
  }
  list() {
    var postData = {
      functionName: "listAdvGenerator",
      site_id: this.state.siteId,
      created_by: "1",
    };
    backendApi(postData).then((data) => {
      if (data.success === "1") {
        // console.log("dataaaaaaaaaaaaaaaaaaaaaa", data.data);
        this.setState({
          fullList: data.data,
        });
      }
    });
  }
  imageEventDropList() {
    // this.imageBrandDropList()
    this.setState({
      eventList: [],
    });
    var postData = {
      functionName: "getBrandAndEvent",
      // "brand_name": $("#txtBrand").val(),
      site_id: this.state.siteId,
      event_name: "",
      id: "",
    };
    backendApi(postData).then((data) => {
      if (data.success === "1") {
        this.setState(
          {
            eventList: data.data,
            imgId: data.data[0].id,
          },
          () => {
            // this.textImageList()
            console.log("KKKKKKKKKKKKKKKKKKK");
          }
        );
      }
    });
  }
  textImageList(e) {
    this.setState({
      showFunctionalComponent: false,
    });
    if (e === "0") {
      $(".imageListwrap").attr("hidden", true);
    } else {
      $(".imageListwrap").attr("hidden", false);
    }
    var postData = {
      functionName: "getBrandAndEvent",
      brand_name: "",
      // site_id
      event_name: "",
      id: e,
    };
    backendApi(postData).then((data) => {
      if (data.success === "1") {
        // $(".imageListwrap").attr("hidden", false);
        this.setState((prevState) => ({
          showFunctionalComponent: !prevState.showFunctionalComponent,
        }));
        document.getElementById("flexCheckChecked").checked = false;
        document.getElementById("flexCheckDefault").checked = false;

        this.setState(
          {
            listText: data.data[0],
            listImage: data.data[1],
            todoItems: data.data[1],
          },
          () => {
            // this.textImageList()
            this.shuffle([...data.data[1]]);
          }
        );
      }
    });
  }
  shuffle = (array) => {
    // debugger;
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    console.log("Ppppppppppppppppppp", array);
    // return array;
    this.setState({
      listImage: array,
    });
  };

  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  // textChange = () => {
  //   ctx.clearRect(0, 0, canvas.width, canvas.height);
  //   this.DrawOverlay(img);
  //   text_title = document.getElementById("overlayText").value;
  //   ctx.fillText(text_title, 50, 50);
  //   this.DrawText();
  // };
  imageDownload = () => {
    debugger;
    let listImage = this.state.listImage;
    for (let i = 0; i < listImage.length; i++) {
      if(Object.keys(listImage[i]).toString().indexOf("checkedBox") !== -1 )
      {
          if (Object.keys(listImage[i]).toString().indexOf("edited_image_url") !== -1 && listImage[0].checkedBox===true ) {
        ///////////////////
        
               var el = document.createElement("a");
               el.setAttribute("href", this.state["canvas" + i].toDataURL("png"));
               el.setAttribute("download", "demo");
               document.body.appendChild(el);
               el.click();
               el.remove();
            } else {
        // debugger;
        this.imagedownload()
          // const el = document.createElement("a");
          // el.href = listImage[i].image_url;
          // el.setAttribute("download", "demo");
          // el.style.display = "none"; // Hide the element
          // document.body.appendChild(el);
          // el.click();
          // document.body.removeChild(el);
        
          }
          
    }
  }
  };
  imagedownload(){
      let listImage = this.state.listImage;
let i = 0;

function processNextIteration() {
  if (i < listImage.length) {
    if (Object.keys(listImage[i]).toString().indexOf("checkedBox") !== -1 && Object.keys(listImage[i]).toString().indexOf("edited_image_url") === -1) {
      
        const el = document.createElement("a");
        el.href = listImage[i].image_url;
        el.setAttribute("download", "demo");
        el.style.display = "none";
        document.body.appendChild(el);
        el.click();
        document.body.removeChild(el);
      
    }
    
    i++;
    setTimeout(processNextIteration, 1000); // Delay of 1 second (1000 milliseconds)
  }
}

processNextIteration();
  }
  // fontSizeDetails(eventFontize) {
  //   this.setState(
  //     {
  //       fontsizeData: eventFontize,
  //     },
  //     () => {
  //       this.textChange();
  //     }
  //   );
  // }
  // fontStyleDetails(eventFontstyle) {
  //   this.setState(
  //     {
  //       fontstyleData: eventFontstyle,
  //     },
  //     () => {
  //       this.textChange();
  //     }
  //   );
  // }
  // fontFamilyDetails(eventFontfamily) {
  //   this.setState(
  //     {
  //       fontfamilyData: eventFontfamily,
  //     },
  //     () => {
  //       this.textChange();
  //     }
  //   );
  // }

  handleImageUpload = () => {
    console.log("2222222");

    AWS.config.update({
      accessKeyId: this.state.s3bucketAccessKey,
      secretAccessKey: this.state.s3bucketSecretKey,
      region: s3Region,
    });
    var bucketParams = {
      Bucket: this.state.s3Bucket,
    };
    let s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: bucketParams,
    });
    let listImage = this.state.listImage;
    return new Promise((resolve, reject) => {
      for (let i = 0; i < listImage.length; i++) {
        console.log("3333333");
        if (
          Object.keys(listImage[i]).toString().indexOf("edited_image_url") ===
          -1
        ) {
          ///////////////////
        } else {
          console.log("33333111111");
          const base64 = listImage[i].edited_image_url;
          let fileName = "tagTree" + Date.parse(new Date()) + ".png";
          let fullFilePath =
            this.state.s3BucketMainFolder +
            "/" +
            S3BucketImagesFolder +
            "/" +
            this.uuidv4() +
            fileName;
          let buf = new Buffer.from(
            base64.replace(/^data:image\/\w+;base64,/, ""),
            "base64"
          );
          s3.upload(
            {
              Key: fullFilePath,
              Body: buf,
              ContentEncoding: "base64",
              ContentType: "image/png",
            },
            (err, data) => {
              if (data) {
                listImage[i].imageUrl1 = data.Location;
                console.log("44444", this.state.listImage);
                this.setState(
                  {
                    listImage: listImage,
                  },
                  () => {
                    if (
                      this.state.listImage.filter((obj) => {
                        return obj.checkedText === true;
                      }).length ===
                      this.state.listImage.filter((obj) => {
                        return obj.imageUrl1;
                      }).length
                    ) {
                      resolve(true);
                      console.log("444441111");
                    }
                  }
                );
                return;
              } else if (err) {
                alert("There was an error uploading your image");
              }
            }
          );
        }
      }
      console.log(
        "555555",
        this.state.listImage.filter((obj) => {
          return obj.checkedText;
        }).length
      );
      this.setState({
        flag: false,
      });
      if (
        this.state.listImage.filter((obj) => {
          return obj.checkedText;
        }).length <= 0
      ) {
        resolve(true);
      }
    });
  };

  async saveImageEditor() {
    console.log("111111111");
    await this.handleImageUpload();
    if (this.state.flag === false) {
      console.log("66666");
      var mediaListing = [];
      this.state.listImage
        .filter((obj) => {
          return obj.checkedBox === true;
        })
        .map((val, idx) => {
          var listArray = {
            image_url: val.image_url,
            image_text:
              val.image_text === "" || val.image_text === undefined
                ? ""
                : val.image_text,
            edited_image_url:
              val.imageUrl1 === "" || val.imageUrl1 === undefined
                ? ""
                : val.imageUrl1,
          };
          mediaListing.push(listArray);
        });
      $(".loder_div").attr("hidden", false);
      console.log("777777");
      var postData = {
        functionName: "saveAdvGenerator",
        site_id:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        created_by: "1",
        ad_event_master_id: $("#txtEvent").val(),
        mediaList: mediaListing,
      };
      console.log("postData", postData);
      backendApi(postData).then((data) => {
        if (data.success === "1") {
          $(".loder_div").attr("hidden", true);
          // alert(data.successMessage);
          alert("Added Successfully");
          this.list();
          window.location.href = "imageEditer/imageEditerList";
        } else {
          alert(data.errorMessage);
        }
      });
    }
  }
  imageClick(e, ids) {
    // debugger;
    $("#ImageModal").attr("hidden", false);
    var popUpListArray = this.state.listText.filter((obj) => {
      return obj.ad_event_master_id === e.ad_event_master_id;
    });
    this.setState({
      imagePopUp: ids,
      popUpList: popUpListArray,
    });
  }
  // New code starts here ***************************************************************
  urlToObject = async (image) => {
    console.log("2...");
    const response = await fetch(image);
    console.log("3...");
    const blob = await response.blob();
    const file = new File([blob], "image.jpg", { type: blob.type });
    return file;
  };
  setStateData = (i, canvasObject, isCheckboxClicked, imageText, checkTrue) => {
    // debugger
    this.setState(
      {
        ["canvas" + i]: canvasObject,
      },
      () => {
        let listImage = this.state.listImage;
        listImage[i].checkedText = checkTrue;
        listImage[i].image_text = imageText;
        listImage[i].edited_image_url =
          isCheckboxClicked === true || typeof isCheckboxClicked === "undefined"
            ? canvasObject.toDataURL("png")
            : null;
        this.setState({
          listImage: listImage,
        });
      }
    );
  };
  // addon/////////////////////////////////////////////////////////////
  selectSingle = (e, i) => {
    // debugger;
    var listImageObject = this.state.listImage;
    if (e.target.checked === true) {
      document.getElementById("save").style.display = "block";
      document.getElementById("cancel").style.display = "block";
      document.getElementById("download").style.display = "block";
    } else if (e.target.checked === false) {
      document.getElementById("save").style.display = "none";
      document.getElementById("cancel").style.display = "none";
      document.getElementById("download").style.display = "none";
    }
    if (e.target.checked === false) {
      const fn = this;
      listImageObject[i].checkedBox = e.target.checked;
      this.setState(
        {
          ["canvas" + i]: document.getElementById("imageCanvas" + i),
          ["ctx" + i]: document
            .getElementById("imageCanvas" + i)
            .getContext("2d"),
          ["img" + i]: new Image(),
          ["text" + i]: "",
          listImage: listImageObject,
        },
        async () => {
          // debugger
          let listImage = this.state.listImage[i];
          this.state["img" + i].crossOrigin = "anonymous";
          let imgObject = this.state["img" + i];
          let ctxObject = this.state["ctx" + i];
          let canvasObject = this.state["canvas" + i];
          let textObject = this.state["text" + i];
          let selectImageUrl = listImage.image_url;
          ctxObject.clearRect(0, 0, canvasObject.width, canvasObject.height);
          console.log("1...");
          let image = await this.urlToObject(listImage.image_url);
          console.log("4...");
          var reader = new FileReader();
          reader.onload = function (event) {
            imgObject.src = selectImageUrl;
            canvasObject.classList.add("show");
            imgObject.onload = function () {
              canvasObject.width = imgObject.width;
              canvasObject.height = imgObject.height;
              ctxObject.drawImage(imgObject, 0, 0);
              ctxObject.fillStyle = "rgba(30, 144, 255, 0)";
              ctxObject.fillRect(0, 0, canvasObject.width, canvasObject.height);
              var gradient = ctxObject.createLinearGradient(
                0,
                0,
                canvasObject.width,
                0
              );
              gradient.addColorStop("0", " magenta");
              gradient.addColorStop("0.5", "blue");
              gradient.addColorStop("1.0", "red");
              ctxObject.fillStyle = gradient;
              ctxObject.textBaseline = "middle";
              ctxObject.font = "bold 50px Arial";
              ctxObject.fillText(textObject, 100, 100);
              fn.setStateData(
                i,
                canvasObject,
                e.target.checked,
                textObject,
                false
              );
            };
          };

          reader.readAsDataURL(image);
        }
      );
      $("#ImageModal").attr("hidden", true);
    } else {
      let listImage = this.state.listImage;
      listImage[i].checkedBox = e.target.checked;
      this.setState(
        {
          listImage: listImage,
        },
        () => {
          if (typeof this.state["canvas" + i] !== "undefined") {
            this.setStateData(
              i,
              this.state["canvas" + i],
              e.target.checked,
              "",
              false
            );
          }
        }
      );
    }
  };
  selectAll = (e) => {
    if (e.target.checked === true) {
      document.getElementById("save").style.display = "block";
      document.getElementById("cancel").style.display = "block";
      document.getElementById("download").style.display = "block";
    } else if (e.target.checked === false) {
      document.getElementById("save").style.display = "none";
      document.getElementById("cancel").style.display = "none";
      document.getElementById("download").style.display = "none";
    }
    var checkedValue = e.target.checked;
    var result = this.state.listImage.map((val, idx) => {
      val.checkedBox = checkedValue;
      if (typeof this.state["canvas" + idx] !== "undefined") {
        this.setStateData(idx, this.state["canvas" + idx], checkedValue);
      }
      return val;
    });
    this.setState({
      listImage: result,
    });
  };

  selectAllWithRandomText = (e) => {
    // debugger
    if (e.target.checked === true) {
      document.getElementById("save").style.display = "block";
      document.getElementById("cancel").style.display = "block";
      document.getElementById("download").style.display = "block";
    } else if (e.target.checked === false) {
      document.getElementById("save").style.display = "none";
      document.getElementById("cancel").style.display = "none";
      document.getElementById("download").style.display = "none";
    }
    var checkedValue = e.target.checked;
    const fn = this;
    let listImage = this.state.listImage;
    var result = listImage.map((val, idx) => {
      val.checkedBox = checkedValue;
      val.checkedText = checkedValue;
      return val;
    });
    this.setState({
      listImage: result,
    });
    //addon end////////////////////////////////////////////////////////////////
    listImage.map((obj, i) =>
      this.setState(
        {
          ["canvas" + i]: document.getElementById("imageCanvas" + i),
          ["ctx" + i]: document
            .getElementById("imageCanvas" + i)
            .getContext("2d"),
          ["img" + i]: new Image(),
          ["text" + i]:
            checkedValue === true
              ? typeof this.state.listText[i] !== "undefined"
                ? this.state.listText[i].text_name
                : this.state.listText[0].text_name
              : "",
        },
        async () => {
          this.state["img" + i].crossOrigin = "anonymous";
          let imgObject = this.state["img" + i];
          let ctxObject = this.state["ctx" + i];
          let canvasObject = this.state["canvas" + i];
          let textObject = this.state["text" + i];
          ctxObject.clearRect(0, 0, canvasObject.width, canvasObject.height);
          console.log("1...");
          let image = await this.urlToObject(listImage[i].image_url);
          console.log("4...");
          var reader = new FileReader();
          reader.onload = function (event) {
            imgObject.src = obj.image_url;
            canvasObject.classList.add("show");
            imgObject.onload = function () {
              canvasObject.width = imgObject.width;
              canvasObject.height = imgObject.height;
              ctxObject.drawImage(imgObject, 0, 0);
              ctxObject.fillStyle = "rgba(30, 144, 255, 0)";
              ctxObject.fillRect(0, 0, canvasObject.width, canvasObject.height);
              var gradient = ctxObject.createLinearGradient(
                0,
                0,
                canvasObject.width,
                0
              );
              // debugger
              gradient.addColorStop("0", " magenta");
              gradient.addColorStop("0.5", "blue");
              gradient.addColorStop("1.0", "red");
              ctxObject.fillStyle = gradient;
              ctxObject.textBaseline = "middle";
              ctxObject.font = "bold 50px Arial";
              ctxObject.fillText(textObject, 100, 100);
              fn.setStateData(
                i,
                canvasObject,
                checkedValue,
                textObject,
                checkedValue
              );
            };
          };
          reader.readAsDataURL(image);
        }
      )
    );
  };
  // New code ends here ***************************************************************
  imagePopUpEdit(method, i) {
    // debugger;
    var listImageObject = this.state.listImage;
    listImageObject[i].checkedBox = true;
    document.getElementById("save").style.display = "block";
    document.getElementById("cancel").style.display = "block";
    document.getElementById("download").style.display = "block";
    const fn = this;
    this.setState(
      {
        ["canvas" + i]: document.getElementById("imageCanvas" + i),
        ["ctx" + i]: document
          .getElementById("imageCanvas" + i)
          .getContext("2d"),
        ["img" + i]: new Image(),
        ["text" + i]: method,
        listImage: listImageObject,
      },
      async () => {
        let listImage = this.state.listImage[i];
        this.state["img" + i].crossOrigin = "anonymous";
        let imgObject = this.state["img" + i];
        let ctxObject = this.state["ctx" + i];
        let canvasObject = this.state["canvas" + i];
        let textObject = this.state["text" + i];
        let selectImageUrl = listImage.image_url;
        ctxObject.clearRect(0, 0, canvasObject.width, canvasObject.height);
        console.log("1...");
        let image = await this.urlToObject(listImage.image_url);
        console.log("4...");
        var reader = new FileReader();
        reader.onload = function (event) {
          imgObject.src = selectImageUrl;
          canvasObject.classList.add("show");
          imgObject.onload = function () {
            canvasObject.width = imgObject.width;
            canvasObject.height = imgObject.height;
            ctxObject.drawImage(imgObject, 0, 0);
            ctxObject.fillStyle = "rgba(30, 144, 255, 0)";
            ctxObject.fillRect(0, 0, canvasObject.width, canvasObject.height);
            var gradient = ctxObject.createLinearGradient(
              0,
              0,
              canvasObject.width,
              0
            );
            gradient.addColorStop("0", " magenta");
            gradient.addColorStop("0.5", "blue");
            gradient.addColorStop("1.0", "red");
            ctxObject.fillStyle = gradient;
            ctxObject.textBaseline = "middle";
            ctxObject.font = "bold 50px Arial";
            ctxObject.fillText(textObject, 100, 100);
            fn.setStateData(
              i,
              canvasObject,
              listImage.checkedBox,
              textObject,
              listImageObject[i].checkedBox
            );
          };
        };
        reader.readAsDataURL(image);
      }
    );
    $("#ImageModal").attr("hidden", true);
  }
  render() {
    var settings = {
      dots: false,
      arrows: true,
      infinite: false,
      draggable: true,
      touchMove: true,
      speed: 500,
      autoplay: false,
      slidesToShow: 3,
      slidesToScroll: 1,
    };
    window.$(document).ready(function () {
      window.$(".popup-image").magnificPopup({
        delegate: "a",
        type: "image",
        tLoading: "Loading image #%curr%...",
        mainClass: "mfp-img-mobile",
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
        },
        image: {
          tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
          titleSrc: function (item) {
            return item.el.attr("title") + "<small></small>";
          },
        },
      });
      window.$(".popup-video").magnificPopup({
        delegate: "a",
        type: "iframe",
        tLoading: "Loading...",
      });
    });
    return (
      <div className="main_wrapper">
        {/* {this.state.showFunctionalComponent && <FunctionalComponent />} */}
        {console.log(
          this.state.showFunctionalComponent,
          "this.state.todoItems",
          this.state.listImage
        )}
        {this.state.listImage.length > 0 &&
          this.state.showFunctionalComponent && <ReactTestFunction />}
        <LeftMenu />
        <div className="userListWrapper">
          <Header heading="Image Editor" headerImage={SocialMediaimage} />
          <div className="social_userdiv">
            <div className="container-fluid ad-generator_div tagAutomatic">
              {/* <div className="back_tocatalog row">


              <div className="col-lg-12 d-flex">
                <div className="back_tocatalog_main">
                  <Link to="/imageEditer/imageEditerList" className="back-to-link">
                    <Icon.ArrowLeftCircle />
                  </Link>
                </div> 
              </div>
              <br/> */}
              <div className="back_tocatalog row">
                <div className="col-lg-12 d-flex">
                  <div className="back_tocatalog_main">
                    <Link
                      to="/imageEditer/imageEditerList"
                      className="back-to-link"
                    >
                      <Icon.ArrowLeftCircle />
                    </Link>
                  </div>
                </div>
              </div>
              {/* =============== */}
              <div className="col-lg-12 d-flex align-items-center mb-4">
                <div className="row">
                  <div className="col-lg-12 col-md-5 pl-0">
                    <label htmlFor="">
                      Choose your event type<sup className="functionSup">*</sup>
                    </label>
                    <select
                      className="form-control mb-0"
                      onChange={(e) => this.textImageList(e.target.value)}
                      name="cmbEvent"
                      id="txtEvent"
                    >
                      <option value="0">Event Type</option>
                      {/* <option value="1">Others</option> */}
                      {this.state.eventList.map((data, i) => {
                        return (
                          <option value={data.id}>{data.event_name} </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              {/* </div> */}
              <div className="row tag_controls">
                {/* <div className="col-lg-2 col-md-5">
                      <select className="form-control"
                        onChange={() => this.imageEventDropList()}
                        name="cmbBrand"
                        id="txtBrand">
                        <option value="0" >Brand</option>
                        {this.state.list.map((data, i) => {
                          return (
                            <option value={data.brand_name}>{data.brand_name} </option>
                          )
                        })}
                      </select>
                    </div> */}
                {/* <div className="col-lg-2 col-md-5">
                  <select
                    className="form-control"
                    onChange={(e) => this.textImageList(e.target.value)}
                    name="cmbEvent"
                    id="txtEvent"
                  >
                    <option value="0">Function</option>
                    {this.state.eventList.map((data, i) => {
                      return (
                        <option value={data.id}>{data.event_name} </option>
                      );
                    })}
                  </select>
                </div> */}
              </div>
              {/* {document.getElementById("txtEvent").value==="0"?"":( */}
              <div className="row imageListwrap" hidden>
                <div className="chekedImage">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      // value={checked?true:false}
                      id="flexCheckDefault"
                      onClick={(e) => this.selectAllWithRandomText(e)}
                      // textChange
                    />
                    <label className="form-check-label" for="flexCheckDefault">
                      Select all with random text
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      // value=""
                      id="flexCheckChecked"
                      onClick={(e) => this.selectAll(e)}
                    />
                    <label className="form-check-label" for="flexCheckChecked">
                      Select All
                    </label>
                  </div>
                </div>
                {this.state.listImage.length > 0 ? (
                  <FlipProvider>
                    <div className="imageBoxes row TagImgFlip">
                      {this.state.listImage.map((object, i) => {
                        return (
                          <div className="col-lg-3 ImageMainWrap col-md-6">
                            {object.edited_image_url === null ||
                            typeof object.edited_image_url === "undefined" ? (
                              <>
                                <ul data-flip-root-id={todoItemsId}>
                                  {/* {this.state.todoItems.map((item,i) => ( */}
                                  <span
                                    // style={tag_tree}
                                    key={object.id}
                                    data-flip-id={`flip-id-${object.id}`}
                                    className="imageBoxesWrap"
                                    // className="flex items-center justify-center font-bold text-lg bg-gray-400 mb-4 bg-yellow-500 rounded-lg"
                                  >
                                    <>
                                      {console.log(
                                        "llllllllllllll",
                                        this.state.listImage
                                      )}
                                      <img
                                        src={object.image_url}
                                        // style={tag_tree}
                                        className="test"
                                        id="txtImageUrl"
                                        onClick={() =>
                                          this.imageClick(object, i)
                                        }
                                      />
                                    </>
                                  </span>
                                  {/* ))} */}
                                </ul>
                                {/* <img
                                  src={object.image_url}
                                  className="test"
                                  // id="txtImageUrl"  onClick={() => {$("#ImageModal").attr("hidden", false);}}
                                  id="txtImageUrl"
                                  onClick={() => this.imageClick(object, i)}
                                /> */}
                              </>
                            ) : (
                              ""
                            )}
                            <div
                              id="canvas-wrap"
                              style={{
                                display:
                                  object.edited_image_url === null ||
                                  typeof object.edited_image_url === "undefined"
                                    ? "none"
                                    : "block",
                              }}
                            >
                              <canvas
                                className="imageBoxesWrap"
                                id={"imageCanvas" + i}
                                onClick={() => this.imageClick(object, i)}
                                // style={{
                                //   display: "block",
                                //   width: "100%",
                                //   height: "auto",
                                // }}
                              >
                                <canvas
                                  class="canvasID"
                                  id={"canvasID" + i}
                                ></canvas>
                              </canvas>
                            </div>
                            {/* } */}
                            <div className="imageChecked">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                checked={object.checkedBox}
                                id={"flexCheckChecked" + i}
                                onClick={(e) => this.selectSingle(e, i)}
                              />
                            </div>
                            {console.log(
                              "..................",
                              object.checkedText
                            )}
                            {object.checkedText === true ? (
                              <div className="TChecked">
                                <span id={"TChecked" + object.id}>T</span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </FlipProvider>
                ) : (
                  ""
                )}
              </div>
              {/* )}  */}
              <div className="button-wrapper btn-wrapper">
                {/* <button
                  id="download"
                  style={{ display: "none" }}
                  type="button"
                  className="btn-save mr-1"
                  onClick={() => {
                    this.imageDownload();
                  }}
                >
                  Download
                </button> */}
                <div
                  id="download"
                  style={{ display: "none", margin: "inherit" }}
                  onClick={() => {
                    this.imageDownload();
                  }}
                  className="  tag_TemplateDowloadDiv mr-3"
                  title="Download"
                >
                  <Icon.Download /> Download
                </div>
                <button
                  id="save"
                  style={{ display: "none" }}
                  type="button"
                  className="btn-save"
                  onClick={() => {
                    this.saveImageEditor();
                  }}
                >
                  Save
                </button>

                <div
                  id="cancel"
                  style={{ display: "none" }}
                  className="back_tocatalog_main"
                >
                  <Link to="/imageEditer/imageEditerList">
                    <button className="btn-cancel"> Cancel</button>
                  </Link>
                </div>
              </div>
            </div>
            {/*  */}
            {/* <Shuffle listImage={this.state.listImage} /> */}
            {/*  */}
          </div>
          <Footer />
        </div>
        <div className="module_popup ImageModal" id="ImageModal" hidden>
          <div
            className="module_innerpopup module_bodypop tagmodule_bodypop {
    padding: 0;
    width: 400px;
}"
          >
            <div className="module_headdiv">
              Choose Text
              <div className="module_close">
                <Icon.XCircle
                  color="red"
                  onClick={() => {
                    $("#ImageModal").attr("hidden", true);
                  }}
                />
              </div>
            </div>
            <div
              className="module_body textEditorScroll"
              style={{ paddingBottom: "15px" }}
            >
              {/* <h5 className="mainHead_popup"></h5> */}
              <div className="col-md-12">
                <div className="row">
                  {/* <div className="col-lg-6"> */}
                  {/* <div className="imagepop">
                    <img src={this.state.imagePopUp}></img>
                  </div> */}
                  {/* {console.log("ths",this.state.listText)}
                  {this.state.listText.map((object, i) => {
                    return(
                      <div>
                  <span  onClick={(e) => this.imagePopUpEdit(object.text_name)}>
                   {object.text_name}
                  </span>
                  </div>)})} */}
                  {/* <div className="button-wrapper">
                    <button type="button" className="btn-save">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn-cancel"
                      onClick={() => {
                        $("#ImageModal").attr("hidden", true);
                      }}
                    >
                      Cancel
                    </button>
                  </div> */}
                  {/* </div> */}
                  {/* {console.log("popup", this.state.popUpList)} */}
                  <div className="col-lg-12   tagimageTextscroll p-0">
                    <p module_body textEditorScroll>
                      <ul style={{ paddingLeft: "20px" }}>
                        {this.state.popUpList.map((object, i) => {
                          return (
                            <li
                              className="tagChooseTxt"
                              onClick={(e) =>
                                this.imagePopUpEdit(
                                  object.text_name,
                                  this.state.imagePopUp
                                )
                              }
                              key={0}
                            >
                              {object.text_name}
                            </li>
                          );
                        })}
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}
export default Automatic;
export function ReactTestFunction(props) {
  useFlip(todoItemsId, {
    duration: 800,
  });

  return <></>;
}
