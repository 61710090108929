import React, { Component } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import QandA from "../images/left_icons/qa.png";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import Modal from "react-modal";
import $ from "jquery";

class QuestionAnswer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editModalIsOpen: false,
      activeEditQuestionFlag: false,
    }
  }
  componentDidMount() {
    const items = document.querySelectorAll(".accordion button");

    function toggleAccordion() {
      const itemToggle = this.getAttribute('aria-expanded');

      for (var i = 0; i < items.length; i++) {
        items[i].setAttribute('aria-expanded', 'false');
      }

      if (itemToggle == 'false') {
        this.setAttribute('aria-expanded', 'true');
      }
    }

    items.forEach(item => item.addEventListener('click', toggleAccordion));
  }
  
  openEditModal = () => {
      this.setState({
        editModalIsOpen: true,
      });
  };
  closeEditModal = () => {
    this.setState({ editModalIsOpen: false });
  };
  activeEditQuestion = () => {
      this.setState({
        activeEditQuestionFlag: true,
      });
  };
  saveQuestion = () => {
    this.setState({
      activeEditQuestionFlag: false,
    });
  }

  render() {
    return (
      <div className="main_wrapper">
        {console.log("activeEditQuestionFlag---", this.state.activeEditQuestionFlag)}
        <LeftMenu />
        <div className="userListWrapper">
          <Header heading="Planning" headerImage={QandA} />
          <div className="container-fluid">
            <div class="container">
              <div className="text-right mb-2 btn_popupdiv ml-auto">
                <Link
                  className="btn-addNew"
                  to="#" onClick={() => this.openEditModal( )}
                >
                  Add Question
                  <span>
                    <Icon.PlusCircle className="ml-1"/>
                  </span>
                </Link>
              </div>
              <div class="accordion">
                <div class="accordion-item">
                  <button className="accordion-head" id="accordion-button-1" aria-expanded="false">
                    <div class="accordion-title">Why is the moon sometimes out during the day?</div>
                    <span class="icon" aria-hidden="true"></span>
                  </button>
                  <div class="accordion-content">
                    <div className="accordion-content-ul">
                      <div className="row my-4">
                        <div className="col-md-1">
                          <p>Reply :- </p>
                        </div>
                        <div className="col-md-11 position-relative">
                          <p className="text-added-det text-muted text-right">
                            <small>Added By Unni</small>
                            <small className="ml-5">07-03-2024</small>
                          </p>
                          <textarea
                            value={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti."}
                            rows="3"
                            className={`${this.state.activeEditQuestionFlag === true ? "form-control-text-brdr" : ""} form-control-text`}
                          />
                          {this.state.activeEditQuestionFlag === true ?
                            <div className="col-md-12 text-right px-5">
                              <button type="submit" className="btn-search"
                                onClick={() => this.saveQuestion( )}>
                                  Save & Continue
                              </button>
                              <button type="submit" className="btn-search"
                                onClick={() => this.saveQuestion( )}>
                                  Approve
                              </button>
                              <button type="submit" className="btn-search"
                                onClick={() => this.saveQuestion( )}>
                                  Draft
                              </button>
                            </div>
                            :
                            <Icon.Edit size={18} className="acc-edit" 
                            onClick={() => this.activeEditQuestion( )}/>
                          }
                        </div>
                      </div>
                      <div className="row my-4">
                        <div className="col-md-1">
                          <p>Reply :- </p>
                        </div>
                        <div className="col-md-11 position-relative">
                          <p className="text-added-det text-muted text-right">
                            <small>Added By Unni</small>
                            <small className="ml-5">07-03-2024</small>
                          </p>
                          <textarea
                            value={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti."}
                            rows="3"
                            className={`${this.state.activeEditQuestionFlag === true ? "form-control-text-brdr" : ""} form-control-text`}
                          />
                          {this.state.activeEditQuestionFlag === true ?
                            <div className="col-md-12 text-right px-5">
                              <button type="submit" className="btn-search"
                                onClick={() => this.saveQuestion( )}>
                                  Save & Continue
                              </button>
                              <button type="submit" className="btn-search"
                                onClick={() => this.saveQuestion( )}>
                                  Approve
                              </button>
                              <button type="submit" className="btn-search"
                                onClick={() => this.saveQuestion( )}>
                                  Draft
                              </button>
                            </div>
                            :
                            <Icon.Edit size={18} className="acc-edit" 
                            onClick={() => this.activeEditQuestion( )}/>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <button className="accordion-head" id="accordion-button-2" aria-expanded="false">
                    <div class="accordion-title">Why is the moon sometimes out during the day? </div>
                    {/* <span >
                      <Icon.DownloadCloud className="ml-1"/>
                    </span> */}
                    <span class="icon" aria-hidden="true"></span>
                  </button>
                  <div class="accordion-content">
                    <div className="accordion-content-ul">
                      <div className="row my-4">
                        <div className="col-md-1">
                          <p>Reply :- </p>
                        </div>
                        <div className="col-md-11 position-relative">
                          <p className="text-added-det text-muted text-right">
                            <small>Added By Unni</small>
                            <small className="ml-5">07-03-2024</small>
                          </p>
                          <textarea
                            value={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti."}
                            rows="3"
                            className={`${this.state.activeEditQuestionFlag === true ? "form-control-text-brdr" : ""} form-control-text`}
                          />
                          {this.state.activeEditQuestionFlag === true ?
                            <div className="col-md-12 text-right px-5">
                              <button type="submit" className="btn-search"
                                onClick={() => this.saveQuestion( )}>
                                  Save & Continue
                              </button>
                              <button type="submit" className="btn-search"
                                onClick={() => this.saveQuestion( )}>
                                  Approve
                              </button>
                              <button type="submit" className="btn-search"
                                onClick={() => this.saveQuestion( )}>
                                  Draft
                              </button>
                            </div>
                            :
                            <Icon.Edit size={18} className="acc-edit" 
                            onClick={() => this.activeEditQuestion( )}/>
                          }
                        </div>
                      </div>
                      <div className="row my-4">
                        <div className="col-md-1">
                          <p>Reply :- </p>
                        </div>
                        <div className="col-md-11 position-relative">
                          <p className="text-added-det text-muted text-right">
                            <small>Added By Unni</small>
                            <small className="ml-5">07-03-2024</small>
                          </p>
                          <textarea
                            value={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti."}
                            rows="3"
                            className={`${this.state.activeEditQuestionFlag === true ? "form-control-text-brdr" : ""} form-control-text`}
                          />
                          {this.state.activeEditQuestionFlag === true ?
                            <div className="col-md-12 text-right px-5">
                              <button type="submit" className="btn-search"
                                onClick={() => this.saveQuestion( )}>
                                  Save & Continue
                              </button>
                              <button type="submit" className="btn-search"
                                onClick={() => this.saveQuestion( )}>
                                  Approve
                              </button>
                              <button type="submit" className="btn-search"
                                onClick={() => this.saveQuestion( )}>
                                  Draft
                              </button>
                            </div>
                            :
                            <Icon.Edit size={18} className="acc-edit" 
                            onClick={() => this.activeEditQuestion( )}/>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <button className="accordion-head" id="accordion-button-3" aria-expanded="false">
                    <div class="accordion-title">Why is the moon sometimes out during the day?</div>
                    <span class="icon" aria-hidden="true"></span>
                  </button>
                  <div class="accordion-content">
                    <div className="accordion-content-ul">
                      <div className="row my-4">
                        <div className="col-md-1">
                          <p>Reply :- </p>
                        </div>
                        <div className="col-md-11 position-relative">
                          <p className="text-added-det text-muted text-right">
                            <small>Added By Unni</small>
                            <small className="ml-5">07-03-2024</small>
                          </p>
                          <textarea
                            value={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti."}
                            rows="3"
                            className={`${this.state.activeEditQuestionFlag === true ? "form-control-text-brdr" : ""} form-control-text`}
                          />
                          {this.state.activeEditQuestionFlag === true ?
                            <div className="col-md-12 text-right px-5">
                              <button type="submit" className="btn-search"
                                onClick={() => this.saveQuestion( )}>
                                  Save & Continue
                              </button>
                              <button type="submit" className="btn-search"
                                onClick={() => this.saveQuestion( )}>
                                  Approve
                              </button>
                              <button type="submit" className="btn-search"
                                onClick={() => this.saveQuestion( )}>
                                  Draft
                              </button>
                            </div>
                            :
                            <Icon.Edit size={18} className="acc-edit" 
                            onClick={() => this.activeEditQuestion( )}/>
                          }
                        </div>
                      </div>
                      <div className="row my-4">
                        <div className="col-md-1">
                          <p>Reply :- </p>
                        </div>
                        <div className="col-md-11 position-relative">
                          <p className="text-added-det text-muted text-right">
                            <small>Added By Unni</small>
                            <small className="ml-5">07-03-2024</small>
                          </p>
                          <textarea
                            value={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti."}
                            rows="3"
                            className={`${this.state.activeEditQuestionFlag === true ? "form-control-text-brdr" : ""} form-control-text`}
                          />
                          {this.state.activeEditQuestionFlag === true ?
                            <div className="col-md-12 text-right px-5">
                              <button type="submit" className="btn-search"
                                onClick={() => this.saveQuestion( )}>
                                  Save & Continue
                              </button>
                              <button type="submit" className="btn-search"
                                onClick={() => this.saveQuestion( )}>
                                  Approve
                              </button>
                              <button type="submit" className="btn-search"
                                onClick={() => this.saveQuestion( )}>
                                  Draft
                              </button>
                            </div>
                            :
                            <Icon.Edit size={18} className="acc-edit" 
                            onClick={() => this.activeEditQuestion( )}/>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="loder_div" hidden />
              <Modal
                isOpen={this.state.editModalIsOpen}
                onRequestClose={this.closeEditModal}
                className="modal__view"
                contentLabel="Editing Modal"
              >
                <div className="d-flex justify-content-end">
                  <button
                    onClick={this.closeEditModal}
                    className="btn btn-transparent p-0"
                  >
                    <Icon.XCircle style={{ width: "20px", color: "red" }} />
                  </button>
                </div>
                <div>
                  <form
                    autoComplete="off"
                  >
                    <label>Questions</label>
                    <textarea
                      rows="4"
                      className="form-control"
                      style={{ minHeight: "120px !important" }}
                    />
                    <div className="text-right">
                      <button type="submit" className="btn-search"
                    onClick={this.closeEditModal}>
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </Modal>
      </div>
    );
  }
}

export default QuestionAnswer;
