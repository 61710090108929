import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import GooglePostimage from "../images/left_icons/Google-Post.png";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import $, { data } from "jquery";
import XillImg from "../images/xill_sm.png";
// import GooglePreviewImg from "../images/google_preview.PNG";
import GooglePreviewImg from "../images/default-image.jpg";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import moment from "moment";
import { apiCallingPost } from "../apiService";
function GooglePreview() {
  const location = useLocation();
  const [fromClaimData, setFromClaimData] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    console.log(
      "selectedLocationsselectedLocationsselectedLocations",
      location
    );
    setFromClaimData(location.state?.fromClaimData);
    setLocationList(location.state.completeLocationData);
  }, []);
  const postEvent = (topic) => {
    $(".loder_div").attr("hidden", false);
    let imagesList = [];
    if (location.state.bannerImages.length > 0) {
      debugger;
      imagesList.push({
        mediaFormat: "PHOTO",
        sourceUrl: location.state.bannerImages[0].url,
      });
      // location.state.bannerImages.forEach((obj, i) => {
      //   imagesList.push({
      //
      //   });
      // });
    }
    if (location.state?.fromClaimData) {
      alert("ji");
      var req = {
        ...(location.state.title && { title: location.state.title }),
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        ...(location.state.summary !== "" && {
          summary: location.state.summary,
        }),
        startDate_year: parseInt(moment(location.state.startDate).get("year")),
        startDate_month: parseInt(
          moment(location.state.startDate).get("month") + 1
        ),
        startDate_day: parseInt(moment(location.state.startDate).get("date")),
        ...(location.state.startTime && {
          startTime_hours: parseInt(location.state.startTime.split(":")[0]),
          startTime_minutes: parseInt(location.state.startTime.split(":")[1]),
        }),
        endDate_year: parseInt(moment(location.state.endDate).get("year")),
        endDate_month: parseInt(
          moment(location.state.endDate).get("month") + 1
        ),
        endDate_day: parseInt(moment(location.state.endDate).get("date")),
        ...(location.state.endTime && {
          endTime_hours: parseInt(location.state.endTime.split(":")[0]),
          endTime_minutes: parseInt(location.state.endTime.split(":")[1]),
        }),

        ...(location.state.callToAction.actionType !== "0" && {
          callToAction: {
            actionType: location.state.callToAction.actionType
              .toUpperCase()
              .replaceAll(" ", "_"),
            url: location.state.callToAction.url,
          },
        }),
        ...(location.state.offer !== undefined && {
          offer: location.state.offer,
        }),
        bannerImages: [imagesList[0]],
        eventType: location.state.eventType,
        location_id: fromClaimData[0].google_location_id,
        functionName: topic,
        ...(topic === "EditPost" && {
          localPostId: location.state.PostEditField.name.split("/")[5],
        }),
      };
      console.log("request", req);
      apiCallingPost(req).then((data) => {
        // console.log(totalCount);
        totalCount = totalCount - 1;
        if (data.success === "1") {
          // alert("Posted Successfully!!!");
          $("#PendingPosting").attr("hidden", true);
          $("#SuccessPosting").removeAttr("hidden");
          $("#FailedPosting").attr("hidden", true);
          $(".loder_div").attr("hidden", true);
          if (totalCount === 0) {
            window.location.href = "/googlepost/postListing";
          }
        } else {
          $("#PendingPosting").attr("hidden", true);
          $("#SuccessPosting").attr("hidden", true);
          $("#FailedPosting").removeAttr("hidden");
          // data.errorMessage
          //   ? alert(data.errorMessage)
          //   : alert("Something went wrong");
        }
        // if (i == location.state.selectedLocations.length) {
        //   window.location.href = "/googlepost/postListing";
        // }
      });
    } else {
      var totalCount = location.state.selectedLocations.length;
      for (const [i, obj] of location.state.selectedLocations?.entries()) {
        var req = {
          ...(location.state.title && { title: location.state.title }),
          siteId:
            window.sessionStorage.getItem("switchingOrgId") === ""
              ? window.sessionStorage.getItem("organizationId")
              : window.sessionStorage.getItem("switchingOrgId"),
          ...(location.state.summary !== "" && {
            summary: location.state.summary,
          }),
          startDate_year: parseInt(
            moment(location.state.startDate).get("year")
          ),
          startDate_month: parseInt(
            moment(location.state.startDate).get("month") + 1
          ),
          startDate_day: parseInt(moment(location.state.startDate).get("date")),
          ...(location.state.startTime && {
            startTime_hours: parseInt(location.state.startTime.split(":")[0]),
            startTime_minutes: parseInt(location.state.startTime.split(":")[1]),
          }),
          endDate_year: parseInt(moment(location.state.endDate).get("year")),
          endDate_month: parseInt(
            moment(location.state.endDate).get("month") + 1
          ),
          endDate_day: parseInt(moment(location.state.endDate).get("date")),
          ...(location.state.endTime && {
            endTime_hours: parseInt(location.state.endTime.split(":")[0]),
            endTime_minutes: parseInt(location.state.endTime.split(":")[1]),
          }),

          ...(location.state.callToAction.actionType !== "0" && {
            callToAction: {
              actionType: location.state.callToAction.actionType,
              url: location.state.callToAction.url,
            },
          }),
          ...(location.state.offer !== undefined && {
            offer: location.state.offer,
          }),
          bannerImages:
            Object.keys(location?.state?.PostEditField).length !== 0
              ? {
                  mediaFormat: "PHOTO",
                  sourceUrl:
                    location?.state?.PostEditField?.media[0]?.googleUrl,
                }
              : imagesList,
          eventType: location.state.eventType,
          location_id: obj,
          functionName: topic,
          ...(topic === "EditPost" && {
            localPostId: location.state.PostEditField.name.split("/")[5],
          }),
        };
        console.log("request", req);
        apiCallingPost(req).then((data) => {
          console.log(totalCount);
          totalCount = totalCount - 1;
          if (data.success === "1") {
            console.log("datadata", data);
            // alert("Posted Successfully!!!");
            $("#PendingPosting" + i).attr("hidden", true);
            $("#SuccessPosting" + i).removeAttr("hidden");
            $("#FailedPosting" + i).attr("hidden", true);
            $(".loder_div").attr("hidden", true);
            const historyApiReq = {
              functionName: "postHistory",
              posted_by:
                window.sessionStorage.getItem("switchUserId") === ""
                  ? window.sessionStorage.getItem("userId")
                  : window.sessionStorage.getItem("switchUserId"),
              status: "Posted",
              title: location?.state?.title ? location.state.title : "",
              site_id:
                window.sessionStorage.getItem("switchingOrgId") === ""
                  ? window.sessionStorage.getItem("organizationId")
                  : window.sessionStorage.getItem("switchingOrgId"),
              startDate_year: parseInt(
                moment(location.state.startDate).get("year")
              ),
              startDate_month: parseInt(
                moment(location.state.startDate).get("month") + 1
              ),
              startDate_day: parseInt(
                moment(location.state.startDate).get("date")
              ),
              startTime_hours: location?.state?.startTime
                ? parseInt(location.state.startTime.split(":")[0])
                : "00",
              startTime_minutes: location?.state?.startTime
                ? parseInt(location.state.startTime.split(":")[1])
                : "00",
              endDate_year: parseInt(
                moment(location.state.endDate).get("year")
              ),
              endDate_month: parseInt(
                moment(location.state.endDate).get("month") + 1
              ),
              endDate_day: parseInt(moment(location.state.endDate).get("date")),
              endTime_hours: location?.state?.startTime
                ? parseInt(location.state.endTime.split(":")[0])
                : "00",
              endTime_minutes: location?.state?.startTime
                ? parseInt(location.state.endTime.split(":")[1])
                : "00",
              actionType:
                location?.state?.callToAction?.actionType !== "0"
                  ? location.state.callToAction.actionType
                  : "",
              action_url: location?.state?.callToAction?.url
                ? location.state.callToAction.url
                : "",
              bannerImages:
                Object.keys(location?.state?.PostEditField).length !== 0
                  ? {
                      mediaFormat: "PHOTO",
                      sourceUrl:
                        location?.state?.PostEditField?.media[0]?.googleUrl,
                    }
                  : imagesList[0]?.sourceUrl
                  ? imagesList[0]?.sourceUrl
                  : "",
              eventType: location.state.eventType,
              location_id: obj,
              post_id: data.data.name.split("/").pop(),
            };
            console.log("sdaiusd", historyApiReq);
            apiCallingPost(historyApiReq).then((obj) => {
              if (totalCount === 0) {
                window.location.href = "/googlepost/postListing";
              }
            });
          } else {
            $("#PendingPosting" + i).attr("hidden", true);
            $("#SuccessPosting" + i).attr("hidden", true);
            $("#FailedPosting" + i).removeAttr("hidden");
            $(".loder_div").attr("hidden", true);
            const historyApiReq = {
              functionName: "postHistory",
              posted_by:
                window.sessionStorage.getItem("switchUserId") === ""
                  ? window.sessionStorage.getItem("userId")
                  : window.sessionStorage.getItem("switchUserId"),
              status: "Failed",
              title: location?.state?.title ? location.state.title : "",
              site_id:
                window.sessionStorage.getItem("switchingOrgId") === ""
                  ? window.sessionStorage.getItem("organizationId")
                  : window.sessionStorage.getItem("switchingOrgId"),
              startDate_year: parseInt(
                moment(location.state.startDate).get("year")
              ),
              startDate_month: parseInt(
                moment(location.state.startDate).get("month") + 1
              ),
              startDate_day: parseInt(
                moment(location.state.startDate).get("date")
              ),
              startTime_hours: location?.state?.startTime
                ? parseInt(location.state.startTime.split(":")[0])
                : "00",
              startTime_minutes: location?.state?.startTime
                ? parseInt(location.state.startTime.split(":")[1])
                : "00",
              endDate_year: parseInt(
                moment(location.state.endDate).get("year")
              ),
              endDate_month: parseInt(
                moment(location.state.endDate).get("month") + 1
              ),
              endDate_day: parseInt(moment(location.state.endDate).get("date")),
              endTime_hours: location?.state?.startTime
                ? parseInt(location.state.endTime.split(":")[0])
                : "00",
              endTime_minutes: location?.state?.startTime
                ? parseInt(location.state.endTime.split(":")[1])
                : "00",
              actionType:
                location?.state?.callToAction?.actionType !== "0"
                  ? location.state.callToAction.actionType
                  : "",
              action_url: location?.state?.callToAction?.url
                ? location.state.callToAction.url
                : "",
              bannerImages:
                Object.keys(location?.state?.PostEditField).length !== 0
                  ? {
                      mediaFormat: "PHOTO",
                      sourceUrl:
                        location?.state?.PostEditField?.media[0]?.googleUrl,
                    }
                  : imagesList[0]?.sourceUrl
                  ? imagesList[0]?.sourceUrl
                  : "",
              eventType: location.state.eventType,
              location_id: obj,
              post_id: "",
            };
            apiCallingPost(historyApiReq).then((obj) => {
              if (totalCount === 0) {
                window.location.href = "/googlepost/postListing";
              }
            });
            // data.errorMessage
            //   ? alert(data.errorMessage)
            //   : alert("Something went wrong");
          }
          // if (i == location.state.selectedLocations.length) {
          //   window.location.href = "/googlepost/postListing";
          // }
        });
      }
    }
  };
  // console.log("selectedLocationsselectedLocationsselectedLocations", location);
  const removeLocation = (locationData) => {
    if (window.confirm("Do you want to remove this location?") !== true)
      return false;
    console.log("datatatatatat", locationData);
    // locationList.filter((obj) => obj.location_id !== locationData.location_id);
    setLocationList(
      locationList.filter((obj) => obj.location_id !== locationData.location_id)
    );
  };
  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Preview Post" headerImage={GooglePostimage} />
        <div className="container mt-3">
          <Icon.ArrowLeftCircle
            className="back-to-link mr-3"
            onClick={() =>
              navigate("/createNewPost", {
                state: location.state,
              })
            }
          />
          {/* <Link to="/createNewPost" className="back-to-link mr-3">
                  <Icon.ArrowLeftCircle />
                </Link> */}
          <div className="row google_review_row">
            <div className="left_review_row">
              <div className="left_googlePreview">
                <div className="leftside_shieldcontt">
                  {location.state.bannerImages.length > 0 ? (
                    <img
                      src={
                        location.state.PostEditField.length !== 0
                          ? location.state.bannerImages[0]
                          : location.state.bannerImages[0].url
                      }
                    />
                  ) : (
                    <img src={GooglePreviewImg} />
                  )}
                </div>
                <div className="leftside_shidfoot">
                  <h3 className="subhead">{location.state.title}</h3>
                  {/* <p>
                    Happy International Men's Day to all our dear important men
                    of Xilligence.
                  </p> */}
                </div>
                <div className="tabs-wrapper">
                  <Tabs>
                    <TabList>
                      <Tab>{location.state.eventType}</Tab>
                    </TabList>

                    <TabPanel>
                      <p className="mb-0">{location.state.summary}</p>
                      {location.state.offer && (
                        <>
                          {location.state.offer.couponCode && (
                            <p className="mb-0">
                              {location.state.offer.couponCode}
                            </p>
                          )}
                          {location.state.offer.redeemOnlineUrl && (
                            <p className="mb-0">
                              {location.state.offer.redeemOnlineUrl}
                            </p>
                          )}
                          {location.state.offer.termsConditions && (
                            <p className="mb-0">
                              {location.state.offer.termsConditions}
                            </p>
                          )}
                        </>
                      )}
                      {/* <p className="mb-0">Maharashtra 400706</p>
                      <p className="mb-0">Building, Girgaon, Mumbai</p> */}
                    </TabPanel>
                  </Tabs>
                </div>
                <div className="preview_timer">
                  {location.state.startDate !== "" &&
                    location.state.endDate !== "" && (
                      <div className="preview_innertimer">
                        <Icon.Clock className="timmer_icon" />
                        <p>
                          {/* <b className="text-success">Open</b>{" "} */}
                          <span className="ml-2 timer_openclose">
                            {moment(location.state.startDate).format(
                              "DD-MMM-yyyy"
                            )}
                            , {location.state.startTime}
                          </span>{" "}
                        </p>
                      </div>
                    )}
                  <div className="preview_innertimer">
                    <Icon.Clock className="timmer_icon" />
                    <p>
                      {/* <b className="text-success">Open</b>{" "} */}
                      <span className="ml-2 timer_openclose">
                        {moment(location.state.endDate).format("DD-MMM-yyyy")},{" "}
                        {location.state.endTime}
                      </span>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-10 tableResponsive ">
              {/* row right_review_row */}
              <div className="table_leftOuterWidth">
                {/* <div className="tableOuterWidth"> */}
                <table className="table">
                  <thead className="table_theadTop">
                    <tr>
                      <th className="col-6">Address</th>
                      <th className="col-6">Action</th>
                    </tr>
                  </thead>
                  <div className="table_Outerscroll">
                    {locationList.map((data, i) => (
                      <tbody className="stickyDiv table_Outertbody">
                        <tr>
                          {/* <td className="col-3">{data.name.split("/")[1]}</td> */}
                          <td className="col-6">
                            <p className="mb-0">
                              <b>{data.title}</b>
                            </p>
                            <p className="mb-0">
                              {data.addressLines}, {data.locality},{" "}
                              {data.administrativeArea}, {data.postalCode}
                            </p>
                          </td>
                          <td className="col-3">
                            <div className="d-flex align-items-center">
                              <span id={"PendingPosting" + i}>
                                <Icon.CheckCircle className="pending_svg" />
                                <span className="text-nowrap">
                                  Ready for Post
                                </span>
                              </span>
                              <span hidden id={"SuccessPosting" + i}>
                                <Icon.CheckCircle className="pending_svg" />
                                <span className="text-nowrap">
                                  Posted Successfully
                                </span>
                              </span>
                              <span hidden id={"FailedPosting" + i}>
                                <Icon.CheckCircle className="pending_svg" />
                                <span className="text-nowrap">
                                  Failed to Post
                                </span>
                              </span>
                            </div>
                            {/* <div
                              hidden
                              id={"SuccessfullPosted" + i}
                              className="d-flex align-items-center"
                            >
                              <Icon.CheckCircle className="pending_svg" />
                              <span className="text-nowrap">
                                Posted Successfully
                              </span>
                            </div> */}
                          </td>
                          <td className="col-3">
                            <button
                              className="btn btn-danger"
                              onClick={() => removeLocation(data)}
                            >
                              Remove Location
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                    {fromClaimData && (
                      <>
                        {fromClaimData.map((data) => (
                          <tbody className="stickyDiv table_Outertbody">
                            <tr>
                              <td className="col-3">
                                {data.google_location_id}
                              </td>
                              <td className="col-3">
                                <p className="mb-0">
                                  <b>{data.location_name}</b>
                                </p>
                                <p className="mb-0">{data.address}</p>
                              </td>
                              <td className="col-3">
                                <div className="d-flex align-items-center">
                                  <span id={"PendingPosting"}>
                                    <Icon.CheckCircle className="pending_svg" />
                                    <span className="text-nowrap">
                                      Ready for Post
                                    </span>
                                  </span>
                                  <span hidden id={"SuccessPosting"}>
                                    <Icon.CheckCircle className="pending_svg" />
                                    <span className="text-nowrap">
                                      Posted Successfully
                                    </span>
                                  </span>
                                  <span hidden id={"FailedPosting"}>
                                    <Icon.CheckCircle className="pending_svg" />
                                    <span className="text-nowrap">
                                      Failed to Post
                                    </span>
                                  </span>
                                </div>
                                {/* <div
                              hidden
                              id={"SuccessfullPosted" + i}
                              className="d-flex align-items-center"
                            >
                              <Icon.CheckCircle className="pending_svg" />
                              <span className="text-nowrap">
                                Posted Successfully
                              </span>
                            </div> */}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </>
                    )}
                  </div>
                </table>
                {/* </div> */}
              </div>
              <div className="button-wrapper">
                <button
                  class="btn-cancel"
                  onClick={() => window.history.back()}
                >
                  Cancel
                </button>
                {location.state.PostEditField.length !== 0 ? (
                  <button
                    class="btn-submit"
                    onClick={() => {
                      postEvent("EditPost");
                    }}
                  >
                    Update Post
                  </button>
                ) : (
                  <button
                    class="btn-submit"
                    id="postEventButton"
                    onClick={() => {
                      postEvent("PostEvent");
                    }}
                  >
                    Post
                  </button>
                )}
              </div>
            </div>
          </div>
          {/* <div className="row">
                <div className="col-md-3 left_bg_preview">
                    <div className="left_googlePreview">
                        <div className="left_google_prvhead">
                            <div className="leftside_googlehead">
                                <img src={XillImg}/>
                                <div className="xill_shield">
                                <Icon.Shield className="xill_shield_svg"/>
                                <Icon.Check className="xill_shield_tick"/>
                                </div>
                            </div>
                            <div className="leftside_xill">
                                <h6 className="mb-0">Xilligence</h6>
                                <p className="mb-0">2 days ago</p>
                            </div>
                            <div className="ml-auto">
                                <Icon.MoreVertical/>
                            </div>
                        </div>
                        <div className="leftside_shieldcontt">
                            <img src={GooglePreviewImg}/>
                        </div>
                        <div className="leftside_shidfoot">
                            <h3>Internationak Men's Day</h3>
                            <h4>19 Nov, 12:00 am - 11:59 pm </h4>
                            <p>Happy International Men's Day to all our dear important men of Xilligence.</p>
                            <a href="#">Learn more</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-9 col-md-6">
                <div className="table-responsive review_tableList tag_table table_row_nowwrap">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th><input type="checkbox" name="" /> </th>
                        <th>Description</th>
                        <th>Address</th>
                        <th>Action</th>
                    </tr>
                </thead>
              <tbody>
                <tr>
                <td> 
                    <input type="checkbox" name="" /> 
                  </td>
                    <td>MSAB002</td>
                    <td>
                        <p className="mb-0"><b>Abbas Broilers</b></p>
                        <p className="mb-0">417, A 33rd Road Khar, Ram Krishna Nager, Bandra West, Mumbai, Maharashtra 4000052
                            </p>
                    </td>
                    <td>
                    <div className="d-flex align-items-center">
                        <Icon.CheckCircle className="pending_svg"/>
                        <span className="text-nowrap">Pending edits</span>
                        </div>
                    </td>
                </tr>
                <tr>
                <td> 
                    <input type="checkbox" name="" /> 
                  </td>
                    <td>MSAB002</td>
                    <td>
                        <p className="mb-0"><b>Abbas Broilers</b></p>
                        <p className="mb-0">417, A 33rd Road Khar, Ram Krishna Nager, Bandra West, Mumbai, Maharashtra 4000052
                            </p>
                    </td>
                    <td>
                        <div className="d-flex align-items-center">
                        <Icon.CheckCircle className="pending_svg"/>
                        <span className="text-nowrap">Pending edits</span>
                        </div>
                        </td>                        
                </tr>
                <tr>
                <td> 
                    <input type="checkbox" name="" /> 
                  </td>
                    <td>MSAB002</td>
                    <td>
                        <p className="mb-0"><b>Abbas Broilers</b></p>
                        <p className="mb-0">417, A 33rd Road Khar, Ram Krishna Nager, Bandra West, Mumbai, Maharashtra 4000052
                            </p>
                    </td>
                    <td>
                    <div className="d-flex align-items-center">
                        <Icon.CheckCircle className="pending_svg"/>
                        <span className="text-nowrap">Pending edits</span>
                        </div>
                    </td>
                </tr>
                <tr>
                <td> 
                    <input type="checkbox" name="" /> 
                  </td>
                    <td>MSAB002</td>
                    <td>
                        <p className="mb-0"><b>Abbas Broilers</b></p>
                        <p className="mb-0">417, A 33rd Road Khar, Ram Krishna Nager, Bandra West, Mumbai, Maharashtra 4000052
                            </p>
                    </td>
                    <td>
                    <div className="d-flex align-items-center">
                        <Icon.CheckCircle className="pending_svg"/>
                        <span className="text-nowrap">Pending edits</span>
                        </div>
                    </td>
                </tr>
              </tbody>
            </table>
          </div> 
          <div className="preview_div">
          <button class="btn btn-danger">Cancel</button>
          <button class="btn btn-primary">Post</button>
          </div>
                </div>
            </div> */}
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
}

export default GooglePreview;
