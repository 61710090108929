import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import $, { data } from "jquery";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";

import LeftMenu from "../layout/leftMenu.jsx";
import Auditimage from "../images/left_icons/processing.png";
import DragDropFile from "../audit/dragDropFile";
import * as XLSX from "xlsx";
import excelTemplate from "../docs/BulkLocationUpdate.xlsx";

import DataInput from "../audit/dataInput";
import { backendApi, pythonBackendApi } from "../apiService";

import * as Icon from "react-feather";

const excelMime = [
  "application/vnd.ms-excel",
  "application/msexcel",
  "application/x-msexcel",
  "application/x-ms-excel",
  "application/x-excel",
  "application/x-dos_ms_excel",
  "application/xls",
  "application/x-xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

class GbpMultipleLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AddressCleaningData: [],
      file: null,
      buffer: null,
      uploaded: false,
      dataList: [],
      fileName: "",
      getAddressCleaningDataByID: [],
      gbp: [],
      status: false,
      selectAll: false,
      categoriesList: [],
      selectedLocationsCompleteDataArray: [],
      updateChecks: [
        {
          title: "Location Name",
          key: "name",
          checked: false,
        },
        {
          title: "Address",
          key: "addressLines",
          checked: false,
        },
        {
          title: "Description",
          key: "description",
          checked: false,
        },
        {
          title: "Sub-locality",
          key: "sublocality",
          checked: false,
        },
        {
          title: "Locality",
          key: "locality",
          checked: false,
        },
        {
          title: "Administrative Area",
          key: "administrativeArea",
          checked: false,
        },
        {
          title: "Country/Region",
          key: "regionCode",
          checked: false,
        },
        {
          title: "Postal Code",
          key: "postalCode",
          checked: false,
        },
        {
          title: "Latitude",
          key: "latitude",
          checked: false,
        },
        {
          title: "Longitude",
          key: "longitude",
          checked: false,
        },
        {
          title: "Primary phone",
          key: "primaryPhone",
          checked: false,
        },
        {
          title: "Additional Phones",
          key: "additionalPhones",
          checked: false,
        },
        {
          title: "Website",
          key: "websiteUri",
          checked: false,
        },
        {
          title: "Primary category",
          key: "primaryCategory",
          checked: false,
        },
        {
          title: "Additional categories",
          key: "additionalCategories",
          checked: false,
        },
        {
          title: "Sunday hours",
          key: "Sunday_Hours",
          checked: false,
        },
        {
          title: "Monday hours",
          key: "Monday_Hours",
          checked: false,
        },
        {
          title: "Tuesday hours",
          key: "Tuesday_Hours",
          checked: false,
        },
        {
          title: "Wednesday hours",
          key: "Wednesday_Hours",
          checked: false,
        },
        {
          title: "Thursday hours",
          key: "Thursday_Hours",
          checked: false,
        },
        {
          title: "Friday hours",
          key: "Friday_Hours",
          checked: false,
        },
        {
          title: "Saturday hours",
          key: "Saturday_Hours",
          checked: false,
        },
        // {
        //   title: "Special hours",
        //   key: "SpecialHours",
        //   checked: false,
        // },
        {
          title: "Opening date",
          key: "openingDate",
          checked: false,
        },
        {
          title: "Labels",
          key: "labels",
          checked: false,
        },
        {
          title: "AdWords location extensions phone",
          key: "adPhone",
          checked: false,
        },
        // ,
        // {
        //   title: "Amenities: Wi-Fi (wi_fi)",
        //   key: "Wifi",
        //   checked: false,
        // },
        // {
        //   title:
        //     "From the business: Identifies as women-owned (is_owned_by_women)",
        //   key: "is_owned_by_women",
        //   checked: false,
        // },
        // {
        //   title:
        //     "Payments: Credit cards (pay_credit_card_types_accepted): American Express (american_express)",
        //   key: "american_express",
        //   checked: false,
        // },
        // {
        //   title:
        //     "Payments: Credit cards (pay_credit_card_types_accepted): Mastercard (mastercard)",
        //   key: "mastercard",
        //   checked: false,
        // },
        // {
        //   title:
        //     "Payments: Credit cards (pay_credit_card_types_accepted): VISA (visa)",
        //   key: "visa",
        //   checked: false,
        // },
        // {
        //   title: "Place page URLs: Menu link (url_menu)",
        //   key: "url_menu",
        //   checked: false,
        // },
        // {
        //   title: "Place page URLs: Order ahead links (url_order_ahead)",
        //   key: "url_order_ahead",
        //   checked: false,
        // },
      ],
    };
    this.handleFile = this.handleFile.bind(this);
  }
  componentDidMount() {
    this.getGBPLocationsData();
    this.getCategoriesListGoogle();
  }
  getCategoriesListGoogle = () => {
    const apiReq = {
      functionName: "googleLocationCategoryList",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apiReq).then((data) => {
      console.log("googleLocationCategoryList", data);
      this.setState({
        categoriesList: data.data.categories,
      });
      // setCategoriesList(data.data.categories);
    });
  };
  getGBPLocationsData() {
    var req = {
      functionName: "getAccountLocations",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      accounts_id: window.sessionStorage.getItem("account_id"),
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        this.setState({
          gbp: data.data,
        });
      } else {
        alert("Failed");
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  onSaveGbp() {
    try {
      // window.location.href = "/showMultipleLocations?data=" + encodeURIComponent(JSON.stringify(this.state.dataList));
      let checkedTotal = 0;
      // this.state.updateChecks.every((item) => {
      //   if (item.checked) {
      //     checkedTotal += 1;
      //   }
      // });
      // if (checkedTotal === 0) {
      //   alert("Please check atleast one field");
      //   return false;
      // }

      var datarray = [];
      var dataarray1 = [];

      // const checkboxess = document.querySelectorAll('input[type="checkbox"]');
      // const checkedValues = [...checkboxess]
      //   .filter((checkbox) => checkbox.checked)
      //   .map((checkbox) => checkbox.value);

      // checkedValues.map((value, k) => {
      //   this.state.dataList.map((r, i) => {
      //     if (r[value]) {
      //       datarray.push({
      //         LocationId: this.state.dataList[i].LocationId,
      //         AdditionalPhone: this.state.dataList[i].AdditionalPhone,
      //         Description: this.state.dataList[i].profile,
      //         LocationName: this.state.dataList[i].LocationName,
      //         Storecode: this.state.dataList[i].StoreCode,
      //         phoneNumbers: this.state.dataList[i].phoneNumbers,
      //         Regularhours: this.state.dataList[i].regularHours,
      //         websiteUri: this.state.dataList[i].websiteUri,
      //         updateMask: value,
      //       });
      //     }
      //   });
      // });

      let locationIds = [];
      let newData = [];

      // datarray.forEach(function (item) {
      //   let index = locationIds.indexOf(item.LocationId);
      //   if (index === -1) {
      //     locationIds.push(item.LocationId);
      //     newData.push(item);
      //   } else {
      //     newData[index].updateMask =
      //       newData[index].updateMask + "," + item.updateMask;
      //   }
      // });
      // updateChecks.forEach((obj, index) => {
      //   this.state.gbp.forEach((data, secondIndex) => {

      //   });
      // });
      let fullArray = [];
      for (const [index, obj] of this.state.dataList.entries()) {
        let tempData = {};
        let eachPeriod = {};
        let hoursArray = [];
        tempData["StoreCode"] = obj?.StoreCode;
        tempData["BusinessName"] = obj?.BusinessName;
        tempData["LocationId"] = obj?.LocationId;
        if (obj?.Address1 || obj?.Address2) {
          tempData[
            "address"
          ] = `${obj?.Address1},${obj?.Address2},${obj?.Address3},${obj?.Address4},${obj?.Address5}`;
        }
        if (obj?.SubLocality) {
          tempData["sublocality"] = `${obj?.SubLocality}`;
        }
        if (obj?.Locality) {
          tempData["locality"] = `${obj?.Locality}`;
        }
        if (obj?.AdministrativeArea) {
          tempData["administrativeArea"] = `${obj?.AdministrativeArea}`;
        }
        if (obj?.Country) {
          tempData["regionCode"] = `${obj?.Country}`;
        }
        if (obj?.PostCode) {
          tempData["postalCode"] = `${obj?.PostCode}`;
        }
        if (obj?.Latitude) {
          tempData["latitude"] = `${obj?.Latitude}`;
        }
        if (obj?.Longitude) {
          tempData["longitude"] = `${obj?.Longitude}`;
        }
        if (obj?.Primaryphone) {
          tempData["primaryPhone"] = `${obj?.Primaryphone}`;
        }
        if (obj?.AdditionalPhone) {
          tempData["additionalPhones"] = `${obj?.AdditionalPhone}`;
        }
        if (
          obj?.SundayHours !== undefined &&
          obj?.SundayHours.toLowerCase() !== "closed"
        ) {
          // if (obj?.SundayHours.toLowerCase() == "closed") {
          //   eachPeriod = {
          //     openDay: "SUNDAY",
          //     openTime: {
          //       hours: 0,
          //       minutes: 0,
          //     },
          //     closeDay: "SUNDAY",
          //     closeTime: {
          //       hours: 0,
          //       minutes: 0,
          //     },
          //   };
          //   hoursArray.push(eachPeriod);
          // } else {
          let openHours = obj?.SundayHours.split("-")[0];
          let closeHours = obj?.SundayHours.split("-")[1];
          let openHour = parseInt(openHours.split(":")[0]);
          let openMinute = parseInt(openHours.split(":")[1]);
          let closeHour = parseInt(closeHours.split(":")[0]);
          let closeMinute = parseInt(closeHours.split(":")[1]);
          eachPeriod = {
            openDay: "SUNDAY",
            openTime: {
              hours: openHour,
              minutes: openMinute,
            },
            closeDay: "SUNDAY",
            closeTime: {
              hours: closeHour,
              minutes: closeMinute,
            },
          };
          hoursArray.push(eachPeriod);
          // }
          // tempData["sublocality"] = `${obj?.SubLocality}`;
        }
        if (
          obj?.MondayHours !== undefined &&
          obj?.MondayHours.toLowerCase() !== "closed"
        ) {
          // if (obj?.MondayHours.toLowerCase() == "closed") {
          //   eachPeriod = {
          //     openDay: "MONDAY",
          //     openTime: {
          //       hours: 0,
          //       minutes: 0,
          //     },
          //     closeDay: "MONDAY",
          //     closeTime: {
          //       hours: 0,
          //       minutes: 0,
          //     },
          //   };
          //   hoursArray.push(eachPeriod);
          // } else {
          let openHours = obj?.MondayHours.split("-")[0];
          let closeHours = obj?.MondayHours.split("-")[1];
          let openHour = parseInt(openHours.split(":")[0]);
          let openMinute = parseInt(openHours.split(":")[1]);
          let closeHour = parseInt(closeHours.split(":")[0]);
          let closeMinute = parseInt(closeHours.split(":")[1]);
          eachPeriod = {
            openDay: "MONDAY",
            openTime: {
              hours: openHour,
              minutes: openMinute,
            },
            closeDay: "MONDAY",
            closeTime: {
              hours: closeHour,
              minutes: closeMinute,
            },
          };
          hoursArray.push(eachPeriod);
          // tempData["sublocality"] = `${obj?.SubLocality}`;
          // }
        }
        if (
          obj?.TuesdayHours !== undefined &&
          obj?.TuesdayHours.toLowerCase() !== "closed"
        ) {
          // if (obj?.TuesdayHours.toLowerCase() == "closed") {
          //   eachPeriod = {
          //     openDay: "TUESDAY",
          //     openTime: {
          //       hours: 0,
          //       minutes: 0,
          //     },
          //     closeDay: "TUESDAY",
          //     closeTime: {
          //       hours: 0,
          //       minutes: 0,
          //     },
          //   };
          //   hoursArray.push(eachPeriod);
          // } else {
          let openHours = obj?.TuesdayHours.split("-")[0];
          let closeHours = obj?.TuesdayHours.split("-")[1];
          let openHour = parseInt(openHours.split(":")[0]);
          let openMinute = parseInt(openHours.split(":")[1]);
          let closeHour = parseInt(closeHours.split(":")[0]);
          let closeMinute = parseInt(closeHours.split(":")[1]);
          eachPeriod = {
            openDay: "TUESDAY",
            openTime: {
              hours: openHour,
              minutes: openMinute,
            },
            closeDay: "TUESDAY",
            closeTime: {
              hours: closeHour,
              minutes: closeMinute,
            },
          };
          hoursArray.push(eachPeriod);
          // }
          // tempData["sublocality"] = `${obj?.SubLocality}`;
        }
        if (
          obj?.WednesdayHours !== undefined &&
          obj?.WednesdayHours.toLowerCase() !== "closed"
        ) {
          // if (obj?.WednesdayHours.toLowerCase() == "closed") {
          //   eachPeriod = {
          //     openDay: "WEDNESDAY",
          //     openTime: {
          //       hours: 0,
          //       minutes: 0,
          //     },
          //     closeDay: "WEDNESDAY",
          //     closeTime: {
          //       hours: 0,
          //       minutes: 0,
          //     },
          //   };
          //   hoursArray.push(eachPeriod);
          // } else {
          let openHours = obj?.WednesdayHours.split("-")[0];
          let closeHours = obj?.WednesdayHours.split("-")[1];
          let openHour = parseInt(openHours.split(":")[0]);
          let openMinute = parseInt(openHours.split(":")[1]);
          let closeHour = parseInt(closeHours.split(":")[0]);
          let closeMinute = parseInt(closeHours.split(":")[1]);
          eachPeriod = {
            openDay: "WEDNESDAY",
            openTime: {
              hours: openHour,
              minutes: openMinute,
            },
            closeDay: "WEDNESDAY",
            closeTime: {
              hours: closeHour,
              minutes: closeMinute,
            },
          };
          hoursArray.push(eachPeriod);
          // }
          // tempData["sublocality"] = `${obj?.SubLocality}`;
        }
        if (
          obj?.ThursdayHours !== undefined &&
          obj?.ThursdayHours.toLowerCase() !== "closed"
        ) {
          // if (obj?.ThursdayHours.toLowerCase() == "closed") {
          //   eachPeriod = {
          //     openDay: "THURSDAY",
          //     openTime: {
          //       hours: 0,
          //       minutes: 0,
          //     },
          //     closeDay: "THURSDAY",
          //     closeTime: {
          //       hours: 0,
          //       minutes: 0,
          //     },
          //   };
          //   hoursArray.push(eachPeriod);
          // } else {
          let openHours = obj?.ThursdayHours.split("-")[0];
          let closeHours = obj?.ThursdayHours.split("-")[1];
          let openHour = parseInt(openHours.split(":")[0]);
          let openMinute = parseInt(openHours.split(":")[1]);
          let closeHour = parseInt(closeHours.split(":")[0]);
          let closeMinute = parseInt(closeHours.split(":")[1]);
          eachPeriod = {
            openDay: "THURSDAY",
            openTime: {
              hours: openHour,
              minutes: openMinute,
            },
            closeDay: "THURSDAY",
            closeTime: {
              hours: closeHour,
              minutes: closeMinute,
            },
          };
          hoursArray.push(eachPeriod);
          // }
          // tempData["sublocality"] = `${obj?.SubLocality}`;
        }
        if (
          obj?.FridayHours !== undefined &&
          obj?.FridayHours.toLowerCase() !== "closed"
        ) {
          // if (obj?.FridayHours.toLowerCase() == "closed") {
          //   eachPeriod = {
          //     openDay: "FRIDAY",
          //     openTime: {
          //       hours: 0,
          //       minutes: 0,
          //     },
          //     closeDay: "FRIDAY",
          //     closeTime: {
          //       hours: 0,
          //       minutes: 0,
          //     },
          //   };
          //   hoursArray.push(eachPeriod);
          // } else {
          let openHours = obj?.FridayHours.split("-")[0];
          let closeHours = obj?.FridayHours.split("-")[1];
          let openHour = parseInt(openHours.split(":")[0]);
          let openMinute = parseInt(openHours.split(":")[1]);
          let closeHour = parseInt(closeHours.split(":")[0]);
          let closeMinute = parseInt(closeHours.split(":")[1]);
          eachPeriod = {
            openDay: "FRIDAY",
            openTime: {
              hours: openHour,
              minutes: openMinute,
            },
            closeDay: "FRIDAY",
            closeTime: {
              hours: closeHour,
              minutes: closeMinute,
            },
          };
          hoursArray.push(eachPeriod);
          // tempData["sublocality"] = `${obj?.SubLocality}`;
          // }
        }
        if (
          obj?.SaturdayHours !== undefined &&
          obj?.SaturdayHours.toLowerCase() !== "closed"
        ) {
          // if (obj?.SaturdayHours.toLowerCase() == "closed") {
          //   eachPeriod = {
          //     openDay: "SATURDAY",
          //     openTime: {
          //       hours: 0,
          //       minutes: 0,
          //     },
          //     closeDay: "SATURDAY",
          //     closeTime: {
          //       hours: 0,
          //       minutes: 0,
          //     },
          //   };
          //   hoursArray.push(eachPeriod);
          // } else {
          let openHours = obj?.SaturdayHours.split("-")[0];
          let closeHours = obj?.SaturdayHours.split("-")[1];
          let openHour = parseInt(openHours.split(":")[0]);
          let openMinute = parseInt(openHours.split(":")[1]);
          let closeHour = parseInt(closeHours.split(":")[0]);
          let closeMinute = parseInt(closeHours.split(":")[1]);
          eachPeriod = {
            openDay: "SATURDAY",
            openTime: {
              hours: openHour,
              minutes: openMinute,
            },
            closeDay: "SATURDAY",
            closeTime: {
              hours: closeHour,
              minutes: closeMinute,
            },
          };
          hoursArray.push(eachPeriod);
          // tempData["sublocality"] = `${obj?.SubLocality}`;
          // }
        }
        if (hoursArray.length !== 0) {
          tempData["periods"] = hoursArray;
        }
        if (obj?.FromBusiness) {
          tempData["description"] = `${obj?.FromBusiness}`;
        }
        if (obj?.Labels) {
          tempData["labels"] = `${obj?.Labels}`;
        }
        if (obj?.ExtensionsPhone) {
          tempData["adWordsLocationExtensions"] = `${obj?.ExtensionsPhone}`;
        }
        if (obj?.Website) {
          tempData["websiteUri"] = `${obj?.Website}`;
        }
        if (obj?.PrimaryCategory) {
          let primCategory = this.state.categoriesList.filter(
            (data) =>
              data?.displayName?.toLowerCase().trim() ===
              obj?.PrimaryCategory?.toLowerCase().trim()
          );
          tempData["primaryCategory"] = {
            displayName: primCategory[0].displayName,
            categoryId: primCategory[0].name,
          };
        }
        if (obj?.AdditionalCategory) {
          let additionalCategoriesList = obj?.AdditionalCategory.split(",");
          let additionCategories = this.state.categoriesList.filter((data) =>
            additionalCategoriesList.some(
              (add) =>
                add.toLowerCase().trim() ===
                data?.displayName?.toLowerCase().trim()
            )
          );
          tempData["additionalCategories"] = additionCategories.map((data) => ({
            displayName: data.displayName,
            name: data.name,
          }));
          // tempData["primaryCategory"] = {
          //   displayName: primCategory[0].displayName,
          //   categoryId: primCategory[0].name,
          // };
        }

        fullArray.push(tempData);
      }
      window.sessionStorage.setItem("dataList", JSON.stringify(fullArray));
      // window.sessionStorage.setItem(
      //   "checkedValues",
      //   JSON.stringify(checkedValues)
      // );
      // return false;
      window.location.href = "/showMultipleLocations";
    } catch (e) {
      alert("Something went wrong");
      console.error("Error in GBP Bulk Update", e);
    }
  }

  OutTable = () => {
    return (
      <div className="col-md-12 tableResponsive">
        <table className="table fileupload-table mb-0">
          <thead>
            <tr>
              <th className="col-1">Location Name</th>
              <th className="col-1">Store code</th>
              <th className="col-1">Address</th>
              <th className="col-1">Description</th>
              <th className="col-1">Primary Phone</th>
              <th className="col-1">Additional Phone</th>
              <th className="col-2">Website</th>
              <th className="col-3">Regular hours</th>
            </tr>
          </thead>

          <div className="table_Outerscroll">
            {this.state.dataList.map((r, i) => {
              const RegularHours = [
                r.SundayHours ? `Sunday- ${r.SundayHours}` : "",
                r.MondayHours ? `Monday- ${r.MondayHours}` : "",
                r.TuesdayHours ? `Tuesday- ${r.TuesdayHours}` : "",
                r.WednesdayHours ? `Wednesday- ${r.WednesdayHours}` : "",
                r.ThursdayHours ? `Thursday- ${r.ThursdayHours}` : "",
                r.FridayHours ? `Friday- ${r.FridayHours}` : "",
                r.SaturdayHours ? `Saturday- ${r.SaturdayHours}` : "",
              ]
                .filter((day) => day !== "")
                .join(", ");
              const daySpans = RegularHours.split(", ").map((day, index) => (
                <span key={index}>
                  {day}
                  {index !== RegularHours.split(", ").length - 1 && <br />}
                </span>
              ));
              console.log("this.state.dataList", r);
              return (
                <tbody key={i} className="table_Outertbody tagEllipsis">
                  <tr>
                    <td className="col-1" title={r.BusinessName}>
                      {r.BusinessName}
                    </td>
                    <td className="col-1" title={r.BusinessName}>
                      {r.StoreCode}
                    </td>
                    <td
                      className="col-1"
                      title={
                        r?.Address1 ||
                        r?.Address2 ||
                        r?.Address3 ||
                        r?.Address4 ||
                        r?.Address5
                          ? `${r?.Address1}${
                              r?.Address2 ? `, ${r?.Address2}` : ""
                            }${r?.Address3 ? `, ${r?.Address3}` : ""}${
                              r?.Address4 ? `, ${r?.Address4}` : ""
                            }${r?.Address5 ? `, ${r?.Address5}` : ""}`
                          : null
                      }
                    >
                      {r?.Address1 ||
                      r?.Address2 ||
                      r?.Address3 ||
                      r?.Address4 ||
                      r?.Address5
                        ? `${r?.Address1}${
                            r?.Address2 && `{, ${r?.Address2}}`
                          },${r?.Address3 && `, ${r?.Address3}`}${
                            r?.Address4 && `{, ${r?.Address4}}`
                          }${r?.Address5 && `, ${r?.Address5}`}`
                        : null}
                    </td>
                    <td className="col-1" title={r.FromBusiness}>
                      {r.FromBusiness}
                    </td>
                    <td className="col-1" title={r.Primaryphone} F>
                      {r.Primaryphone}
                    </td>
                    <td className="col-1" title={r.AdditionalPhone}>
                      {r.AdditionalPhone}
                    </td>
                    <td className="col-2" title={r.Website}>
                      {r.Website}
                    </td>
                    <td className="col-3">
                      {RegularHours.split(", ").map((day, index) => {
                        return (
                          <span key={index}>
                            {day}
                            {index !== RegularHours.split(", ").length - 1 && (
                              <br />
                            )}
                          </span>
                        );
                      })}
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </div>
        </table>

        <div className="button-wrapper mt-0 mb-4">
          <button
            className="btn-cancel ml-3"
            onClick={() => {
              window.location.reload();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  ShowTable = () => {
    return (
      <div className="col-md-12 tableResponsive">
        <table className="table fileupload-table mb-0">
          <thead>
            <tr>
              <th className="col-1">Location Name</th>
              <th className="col-1">Location Id</th>
              <th className="col-1">Address</th>
              <th className="col-1">Description</th>

              {/* <th className="col-3">Category</th> */}
              {/* <th className="col-3">Opening date</th> */}
              {/* <th className="col-3">Services</th> */}
              <th className="col-1">Primary Phone</th>
              <th className="col-1">Additional Phone</th>
              <th className="col-1">Website</th>
              <th className="col-2">Regular hours</th>
              {/* <th className="col-3">More hours</th> */}
              {/* <th className="col-3">Appointment link</th> */}
              {/* <th className="col-3">Products</th> */}
              <th className="col-1">Store code</th>
              <th className="col-1">Business Opening Date</th>
              <th className="col-1">Status</th>
            </tr>
          </thead>

          <div className="table_Outerscroll">
            {this.state.dataList.map((r, i) => {
              console.log(".state.dataList", this.state.dataList);
              if (r[$("#statusValue").find("option:selected").val()] != "") {
                return (
                  <tbody key={i} className="table_Outertbody">
                    <tr>
                      <td className="col-1">{r.LocationName}</td>
                      <td className="col-1">{r.LocationId}</td>
                      <td className="col-1">{r.Address}</td>
                      <td className="col-1">{r.Description}</td>
                      <td className="col-1">{r.Phone}</td>
                      <td className="col-1">
                        {!r.AdditionalPhone
                          ? ""
                          : r.AdditionalPhone.replace(/&/g, " ")}
                      </td>
                      <td className="col-1">{r.Website}</td>
                      <td className="col-2">{r.Regularhours}</td>
                      <td className="col-1">{r.StoreCode}</td>
                      <td className="col-1">{r.OpeningDate}</td>
                      <td className="col-1">
                        {" "}
                        <span id={"success" + r.LocationId} hidden>
                          <Icon.CheckCircle
                            style={{ color: "#0a8908" }}
                            className="icon_common"
                          />
                        </span>
                        <span id={"failed" + r.LocationId} hidden>
                          {" "}
                          <Icon.XCircle
                            style={{ color: "red" }}
                            className="icon_common"
                          />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                );
              }
            })}
          </div>
        </table>

        <div className="button-wrapper mt-0 mb-4">
          <button
            className="btn-cancel ml-3"
            onClick={() => {
              window.location.reload();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };
  async handleFile(files, e) {
    var buffer;
    if (files) {
      if (!excelMime.includes(files.type)) {
        alert("Please upload valid excel file");
        $(".loder_div").attr("hidden", true);
        return false;
      }
      $(".loder_div").attr("hidden", false);
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          isLoader: 0,
        });
        /* Parse data */
        const ab = e.target.result;

        const wb = XLSX.read(ab, { type: "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */

        const data = XLSX.utils.sheet_to_json(ws, { raw: false, header: 1 });

        let DataList = [];
        let DataListTemp = [];

        let keys = [];

        const newHeader = [
          "LocationId",
          "StoreCode",
          "BusinessName",
          "Address1",
          "Address2",
          "Address3",
          "Address4",
          "Address5",
          "SubLocality",
          "Locality",
          "AdministrativeArea",
          "Country",
          "PostCode",
          "Latitude",
          "Longitude",
          "Primaryphone",
          "AdditionalPhone",
          "Website",
          "PrimaryCategory",
          "AdditionalCategory",
          "SundayHours",
          "MondayHours",
          "TuesdayHours",
          "WednesdayHours",
          "ThursdayHours",
          "FridayHours",
          "SaturdayHours",
          "FromBusiness",
          "OpeningDate",
          "Labels",
          "ExtensionsPhone",
        ];
        // data[0] = newHeader;

        // const newReplacedKeys = data[0].map((key) => {

        // })
        data.shift();
        // Map the data array into an array of objects with newHeader as keys
        const arrayOfObjects = data.map((row) => {
          const obj = {};
          newHeader.forEach((key, index) => {
            obj[key] = row[index];
          });
          return obj;
        });

        // Replace the 0th element of each element in arrayOfObjects with values from gbp
        const updatedArrayOfObjects = arrayOfObjects.map((obj) => {
          const storeCode = obj.StoreCode; // Assuming 'StoreCode' is the key for the store code
          const gbpData = this.state.gbp.find(
            (item) => item.storeCode.toLowerCase() === storeCode.toLowerCase()
          );
          if (gbpData) {
            obj.LocationId = gbpData.location_id;
          }
          return obj;
        });
        function findDuplicateFields(dataArray) {
          const duplicateFields = {};

          // Iterate through the keys in the first object to check for duplicates
          const keys = Object.keys(dataArray[0]);

          for (const key of keys) {
            const values = new Set(); // Use a Set to track unique values

            for (const obj of dataArray) {
              const value = obj[key];

              if (values.has(value)) {
                if (!duplicateFields[key]) {
                  duplicateFields[key] = [value];
                } else {
                  duplicateFields[key].push(value);
                }
              } else {
                values.add(value);
              }
            }
          }

          return duplicateFields;
        }

        // Call the function with your 'updatedArrayOfObjects' data
        const duplicates = findDuplicateFields(updatedArrayOfObjects);
        $(".loder_div").attr("hidden", true);
        /* Update state */

        this.setState({
          dataList: updatedArrayOfObjects,
          fileName: files.name,
          uploaded: true,
          isLoader: 1,
        });
      };
      reader.readAsArrayBuffer(files);
    }
  }
  migrateProcess(masterId) {
    $(".loder_div").attr("hidden", false);
    var apidata = {
      method: "POST",
      body: JSON.stringify({
        masterId: masterId,
      }),
    };

    pythonBackendApi("runAddressCleaning", apidata).then((data) => {
      if (data.success == "1") {
        $(".loder_div").attr("hidden", true);
        alert("Process Started Successfully");
        window.location.href = "/processAddressDetails/" + masterId;
      } else {
        alert("Process Failed");
        $(".loder_div").attr("hidden", true);
      }
    });
  }

  deleteAddressCleaningDataByID(val) {
    if (!window.confirm("Do you want to Delete?")) {
      return false;
    }
    var apidata = {
      functionName: "deleteAddressCleaningDataByID",
      masterId: val,
    };
    $(".loder_div").attr("hidden", false);
    backendApi(apidata).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        this.getAddressCleaningData();
      } else {
        alert("Something went wrong in Deleting the file");
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  handleCheckCall(data) {
    const updatedUpdateChecks = this.state.updateChecks.map((item) => {
      if (item.key === data.key) {
        // Create a new object with checked set to true
        return { ...item, checked: !data.checked };
      }
      return item;
    });
    const allChecked = updatedUpdateChecks.every((item) => item.checked);
    this.setState({ updateChecks: updatedUpdateChecks, selectAll: allChecked });
  }

  handleSelectAll(checked) {
    this.setState(
      {
        selectAll: checked,
        updateChecks: this.state.updateChecks.map((item) => {
          return { ...item, checked: checked };
        }),
      },
      () => {
        console.log("updateChecks", this.state.updateChecks);
      }
    );
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          <Header heading="GBP Multiple Locations" headerImage={Auditimage} />
          <div className="container">
            <div className="file_upload_outer mt-3 container">
              <DragDropFile handleFile={this.handleFile}>
                <div className="form-group formBottom" id="form-fileupload">
                  <DataInput handleFile={this.handleFile} />
                </div>
              </DragDropFile>
              <div
                className="col-lg-3 col-md-12 TemplateDowloadDiv"
                title="Download template"
                // onClick={() => this.downloadTemplate()}
              >
                <a href={excelTemplate}>
                  <Icon.Download /> Download template
                </a>
              </div>

              {this.state.uploaded === true && this.OutTable()}
            </div>

            <div className="locationsMultiple">
              {/* <div className="checkboxMultiple paddLeft">
                <input
                  type="checkbox"
                  id="allSelect"
                  checked={this.state.selectAll}
                  onChange={() => this.handleSelectAll(!this.state.selectAll)}
                />
                <label>Select All</label>
              </div> */}
              {/* {this.state.updateChecks.map((data) => {
                return (
                  <div className="checkboxMultiple paddLeft">
                    <input
                      type="checkbox"
                      id={data.key}
                      value={data.key}
                      checked={data.checked}
                      onChange={() => this.handleCheckCall(data)}
                    />
                    <label>{data.title}</label>
                  </div>
                );
              })} */}
              {/* <div className="checkboxMultiple paddLeft">
                <input type="checkbox" id="title" value="title" />
                <label>Location Name</label>
              </div>
              <div className="checkboxMultiple">
                <input type="checkbox" id="profile" value="profile" />
                <label>Description</label>
              </div>
              <div className="checkboxMultiple">
                <input type="checkbox" id="phone" value="phoneNumbers" />
                <label>Phone</label>
              </div>
              <div className="checkboxMultiple">
                <input type="checkbox" id="websiteUri" value="websiteUri" />
                <label>Website</label>
              </div>
              <div className="checkboxMultiple">
                <input type="checkbox" id="regularHours" value="regularHours" />
                <label>Regular hours</label>
              </div>
              <div className="checkboxMultiple">
                <input type="checkbox" id="StoreCode" value="StoreCode" />
                <label>Store code</label>
              </div> */}

              <button
                onClick={() => this.onSaveGbp()}
                id="txtFile"
                type="button"
                className={"btn btn-info mr-1 ml-3"}
              >
                Next
              </button>
            </div>
          </div>

          <Footer />
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}
export default GbpMultipleLocation;
