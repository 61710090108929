import React, { Component } from "react";
import { useLocation } from "react-router-dom";
import "../css/bootstrap.css";
import LiveMonitoringimage from "../images/left_icons/live.png";
import * as XLSX from "xlsx";
import { backendApi, apiCallingPost, pythonBackendApi } from "../apiService";
import "../css/style.css";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import close from "../images/close.png";
import checked from "../images/checked.png";
import $ from "jquery";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import moment from "moment";
import GoogleDetailsIcon from "../images/icon04.png";
import GooglePost from "../images/icon01.png";
import GooglePhotos from "../images/icon02.png";
import GoogleIcon from "../images/google.png";
// import { useNavigate } from "react-router-dom";
var Id = window.location.pathname.split("/")[2];
class GoogleBusinessLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountList: [],
      backupAccountList: [],
      stateLocationList: [],
      administrativeArea: "",
      cityLocationList: [],
      locality: "",
      allLocationdata: [],
      globalFilterLocationsList: [],
      tempSearchLocationsList: [],
    };
    this.setSession = this.setSession.bind(this);
  }
  // const navigate = useNavigate();
  componentDidMount() {
    window.sessionStorage.setItem("editBusinessAcountLocationId", "");
    this.getAdministrativeArea();
    this.getAccountLocations();
    this.initialData();
  }
  getAccountLocations() {
    $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "getAccountLocations",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      accounts_id: Id,
    };
    apiCallingPost(reviewAPIData).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        this.setState({
          accountList: data.data,
          backupAccountList: data.data,
        });
      } else {
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  fetchLocationFromAccount() {
    var apidata = {
      method: "POST",
      body: JSON.stringify({
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        userId:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
        accountId: Id,
      }),
    };
    $(".loder_div").attr("hidden", false);
    pythonBackendApi("fetchLocationFromAccount", apidata).then((data) => {
      if (data.success === "1") {
        alert("Google Business Locations Successfully Started");
        $(".loder_div").attr("hidden", true);
        this.getAccountLocations();
      } else {
        $(".loder_div").attr("hidden", true);
        alert("Google Business Locations Failed");
      }
    });
  }
  setSession(e) {
    window.sessionStorage.setItem("editBusinessAcountLocationId", e);
    window.location.href = "/business-details";
  }
  getAdministrativeArea() {
    const businessAccountData = {
      functionName: "getAdministrativeArea",
      account_id: window.sessionStorage.getItem("account_id"),
    };
    apiCallingPost(businessAccountData).then((data) => {
      if (data.success === "1") {
        this.setState({
          stateLocationList: data.data.map((obj) => obj.AdministrativeArea),
        });
      } else {
      }
    });
  }
  initialData() {
    const businessAccountData = {
      functionName: "getLocalityUnderAdministrativeArea",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea: "",
    };
    // $(".loder_div").attr("hidden", false);
    apiCallingPost(businessAccountData).then(async (data) => {
      this.setState({
        cityLocationList: data.data.map((obj) => obj.locality),
      });
      // this.handleChangeCity(data.data[0].locality, value);
      const businessAccountData2 = {
        functionName: "getLocationSearch",
        account_id: window.sessionStorage.getItem("account_id"),
        AdministrativeArea: "",
        locality: "",
      };
      apiCallingPost(businessAccountData2).then((newObj) => {
        this.setState({
          globalFilterLocationsList: newObj.data,
          tempSearchLocationsList: newObj.data,
        });
      });
    });
  }

  handleChangeState = (value) => {
    if (!value) {
      this.setState({
        administrativeArea: "",
        localityList: "",
        cityLocationList: [],
        globalFilterLocationsList: this.state.tempSearchLocationsList,
      });
      return;
    }
    const accountList = this.state.backupAccountList.filter(
      (eachAcc) => eachAcc.administrativeArea === value
    );
    this.setState({
      accountList,
    });
    const businessAccountData = {
      functionName: "getLocalityUnderAdministrativeArea",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea: value,
    };
    apiCallingPost(businessAccountData).then((data) => {
      if (data.success === "1") {
        const businessAccountData = {
          functionName: "getLocationSearch",
          account_id: window.sessionStorage.getItem("account_id"),
          AdministrativeArea: value,
          locality: "",
        };
        backendApi(businessAccountData).then((response) => {
          this.setState({
            globalFilterLocationsList: response.data,
            tempSearchLocationsList: response.data,
          });
        });
        this.setState({
          cityLocationList: data.data.map((obj) => obj.locality),
          administrativeArea: value,
        });
      } else {
        this.setState({
          cityLocationList: [],
        });
      }
    });
  };
  handleChangeCity(value, AdministrativeArea) {
    if (!value) {
      return;
    }
    const accountList = this.state.backupAccountList.filter(
      (eachAcc) => eachAcc.locality === value
    );
    this.setState({
      accountList,
    });
    this.setState({
      locality: value,
    });

    const businessAccountData = {
      functionName: "getLocationSearch",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea,
      locality: value,
    };
    apiCallingPost(businessAccountData).then((data) => {
      if (data.success === "1") {
        this.setState({
          globalFilterLocationsList: data.data,
          tempSearchLocationsList: data.data,
        });
      } else {
        this.setState({
          globalFilterLocationsList: [],
          tempSearchLocationsList: [],
        });
      }
    });
  }
  filterLocations(e) {
    if (e.target.value.length === 0) {
      this.setState({ locationsList: this.state.tempSearchLocationsList });
    } else {
      this.setState({
        businessNameFilter: e.target.value,
      });

      var array = [];
      if (this.state.administrativeArea != "" && this.state.locality != "") {
        array = this.state.tempSearchLocationsList;
      } else {
        array = this.state.backupAccountList;
      }
      var filterArray = array.filter(
        (data) =>
          data.storeCode.toLowerCase().includes(e.target.value.toLowerCase()) ||
          data.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
          data.addressLines
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          (
            data.storeCode +
            "," +
            data.addressLines +
            "," +
            data.locality +
            "," +
            data.AdministrativeArea
          )
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          (data.locality + "," + data.AdministrativeArea)
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
      );
      this.setState({
        globalFilterLocationsList: filterArray,
      });
    }
  }
  getBusinessChange(locationId, data) {
    const accountList = this.state.backupAccountList.filter(
      (eachAcc) => eachAcc.location_id === locationId
    );
    console.log(
      "🚀 ~ GoogleBusinessLocation ~ getBusinessChange ~ accountList:",
      accountList
    );
    const businessNameFilter = `${accountList[0].storeCode}- ${accountList[0].title}-${accountList[0].addressLines},
    ${accountList[0].locality},${accountList[0].AdministrativeArea}`;
    // console.log("🚀 ~ file: googleBusinessLocation.jsx:239 ~ GoogleBusinessLocation ~ getBusinessChange ~ this.state.backupAccountList:", this.state.backupAccountList);
    this.setState({
      accountList,
      businessNameFilter,
    });
  }
  getClearData() {
    this.setState({
      accountList: this.state.backupAccountList,
      administrativeArea: "",
      locality: "",
      businessNameFilter: "",
    });
  }
  getConfirmation() {
    let confrimMessage =
      'Clicking "OK" will clear the current list of locations. The new list will be updated within a few minutes.';
    if (window.confirm(confrimMessage) === true) {
      this.fetchLocationFromAccount();
    }
  }
  exportExcelData() {
    console.log(
      "🚀 ~ file: googleBusinessLocation.jsx:330 ~ exportExcelData ~ this.state.accountList:",
      this.state.accountList
    );
    let accountList = this.state.accountList.map((data) => {
      // let json = JSON.parse(data?.dataJson);
      let json = ""
      return {
        "Location Id": `'${data.location_id}`,
        "GBP Reference Code": `${data.storeCode}`,
        Name: `${data.title}`,
        Address: data.addressLines,
        City: data.locality,
        "State/Province/Region": data.administrativeArea,
        "Postal Code": data.postalCode,
        Latitude: `'${data.latitude}`,
        Longitude: `'${data.longitude}`,
        Phone: `'${json?.phoneNumbers.primaryPhone}${
          json?.phoneNumbers?.additionalPhones
            ? `, ${json?.phoneNumbers?.additionalPhones.join(",")}`
            : ""
        }`,
        Website: json?.websiteUri,
        "Operational Hours": `SUNDAY- ${data.periods_sunday} |MONDAY- ${data.periods_monday} |TUESDAY- ${data.periods_tuesday} |WEDNESDAY- ${data.periods_wednesday} |THURSDAY- ${data.periods_thursday} |FRIDAY- ${data.periods_friday} |SATURDAY- ${data.periods_saturday} `,
        URL: data?.maps_uri,
      };
    });
    const now = new Date();
    let pageDate = moment(now).format("DD_MM_YY_HH:mm");
    // alert(pageDate);
    const worksheet = XLSX.utils.json_to_sheet(accountList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `Sheet1-${pageDate}`);
    XLSX.writeFile(workbook, "GoogleBusinessLocation" + pageDate + ".xlsx");
    console.log(
      "🚀 ~ file: googleBusinessLocation.jsx:317 ~ GoogleBusinessLocation ~ exportExcelData ~ accountList:",
      accountList
    );
    // this.exportXlsx(accountList, fileName, fileType);
  }

  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          <Header
            heading="Business Locations"
            headerImage={LiveMonitoringimage}
          />
          <div className="container-fluid">
            <div className="back_tocatalog flex-wrap">
              <div className="back_tocatalog_main">
                <Link to="/businessAccount/index" className="back-to-link">
                  <Icon.ArrowLeftCircle />
                </Link>
              </div>
              <div class="total_list  mr-3">
                <span class="audit-txt">Total :</span>
                <span class="audit-txt-bld">
                  {this.state.accountList.length != "0"
                    ? this.state.accountList.length
                    : "0"}
                </span>
              </div>
              <div className="text-right mb-2 btn_popupdiv ml-auto">
                <Link
                  className="btn-addNew"
                  to="#"
                  onClick={() => this.getConfirmation()}
                >
                  Fetch Google Business Locations{" "}
                  <span>
                    <Icon.PlusCircle />{" "}
                  </span>
                </Link>
              </div>
            </div>
            <div className="row row-search px-2 mt-2">
              <div className="col-lg-2 col-md-4 col-sm-6">
                <select
                  className="form-control"
                  id="stateId"
                  value={this.state.administrativeArea}
                  onChange={(e) => this.handleChangeState(e.target.value)}
                >
                  <option value="">State</option>
                  {this.state.stateLocationList.map((item) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <select
                  className="form-control"
                  id="cityId"
                  value={this.state.locality}
                  onChange={(e) =>
                    this.handleChangeCity(
                      e.target.value,
                      this.state.administrativeArea
                    )
                  }
                >
                  <option value="">City</option>
                  {this.state.cityLocationList.map((item) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-5 col-md-4 location_search">
                <input
                  className="form-control"
                  value={this.state.businessNameFilter}
                  onChange={(e) => this.filterLocations(e)}
                  placeholder="Search by location"
                />
                <div className="autocomplete__list location_search_drop">
                  <ul class="nav flex-column">
                    {this.state.globalFilterLocationsList.map((data) => (
                      <li
                        key={data.location_id}
                        onMouseDown={() =>
                          this.getBusinessChange(data.location_id, data)
                        }
                      >
                        {data.storeCode}- {data.title}-{data.addressLines},
                        {data.locality},{data.AdministrativeArea}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* <div className="col-lg-1 col-md-4 col-sm-6 pr-0">
								<button className="btn-search w-100" onClick={() => this.getGbpData(this.state.locationIdsList)}>
									Search
								</button>
							</div>*/}
              <div className="col-lg-1 col-md-4 col-sm-6 pr-0">
                <button
                  className="btn-clear-search w-100"
                  onClick={() => this.getClearData()}
                >
                  Clear
                </button>
              </div>
              <div className="col-lg-1 col-md-4 col-sm-6 pr-0">
                <button
                  className="btn btn-info w-100"
                  onClick={() => this.exportExcelData()}
                >
                  Export
                </button>
              </div>
            </div>
            {/* <div className="container"> */}
            <div className="col-md-12 tableResponsive">
              <div className="tableOuterWidth">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="col-1">
                        Location Id/
                        <br />
                        Store Code
                      </th>
                      <th className="col-2">Title</th>

                      {/* <th className="col-3">Address Lines</th> */}
                      <th className="col-2">Location</th>
                      <th className="col-3">More Info</th>
                      {/* <th className="col-2">Location id</th> */}
                      {/* <th className="col-1">Options</th> */}
                      <th className="col-4">Status</th>
                    </tr>
                  </thead>
                  <div className="table_Outerscroll">
                    {this.state.accountList.map((item) => {
                      // let json = JSON.parse(item?.dataJson);
                      let json = "";
                      return (
                        <tbody className="table_Outertbody" key={item.id}>
                          <tr>
                            <td className="col-1">
                              {item.location_id}
                              <span style={{ display: "block", color: "blue" }}>
                                {item.storeCode}
                              </span>
                            </td>
                            <td className="col-2">
                              <b>Name:</b> {item.title}
                              <br></br>
                              <span>
                                <b>Address:</b> {item.addressLines}
                              </span>{" "}
                              <br></br>
                              {item.location_id ? (
                                <span
                                  style={{ display: "block", color: "green" }}
                                >
                                  (<b>Location Id:</b> {item.location_id})
                                </span>
                              ) : (
                                ""
                              )}
                            </td>
                            <td className="col-2">
                              <b>City:</b> {item.locality} ,<br />
                              <b>State:</b> {item.administrativeArea} ,<br />
                              <b>Postal Code:</b> {item.postalCode} ,<br />
                              <b>Latitude:</b> {item.latitude} ,<br />
                              <b>Longitude:</b> {item.longitude} ,<br />
                            </td>
                            <td className="col-3">
                              {/* <b>Phone:</b>{" "}
                              {`${json?.phoneNumbers?.primaryPhone} ${
                                json?.phoneNumbers?.additionalPhones
                                  ? `, ${json?.phoneNumbers?.additionalPhones.join(
                                      ","
                                    )}`
                                  : ""
                              }`}{" "}
                              ,<br />
                              <b>Website:</b> {json?.websiteUri} ,<br /> */}
                              <span className="tagOpenHrs">
                                <b>Operational Hours:</b> <br />
                                <span>
                                  Sunday- {item.periods_sunday}
                                  <br />
                                  Monday- {item.periods_monday}
                                  <br />
                                  Tuesday- {item.periods_tuesday}
                                  <br />
                                  Wednesday- {item.periods_wednesday}
                                  <br />
                                  Thursday- {item.periods_thursday}
                                  <br />
                                  Friday- {item.periods_friday}
                                  <br />
                                  Saturday- {item.periods_saturday}
                                  <br />
                                </span>
                              </span>
                            </td>
                            {/* <td className="col-2">{item.location_id}</td> */}
                            {/* {(item.maps_uri&&item.new_review_uri&&item.has_voice_of_merchant)?
                          <td className="col-2">
                          <>
                            <img
                              src={GoogleDetailsIcon}
                              title="Edit Business Details"
                              className="mr-3"
                            onClick={() => navigateToBusinessEdit(data)}
                            />
                            <img
                              src={GooglePost}
                              title="Create post"
                              className="mr-3"
                               onClick={() => navigateToNextPage(data)}
                            />
                            <img
                              className="mr-3"
                              src={GooglePhotos}
                              title="Add Photos"
                               onClick={() => navigateToGooglePhotos(data)}
                            />
                            <select
                              className="custom_mini_select"
                             onChange={(e) => moreOptionsSelect(e, data)}
                            >
                              <option value="0"></option>
                              <option value="1">Merge Location </option>
                              <option value="2">Delete Location </option>
                            </select>
                          </>

                          </td>:""} */}
                            <td className="col-4 tagStatusClm">
                              <span>
                                {item.is_active == "1" ? (
                                  <Icon.CheckCircle
                                    style={{ color: "GREEN" }}
                                    className="icon_common"
                                  />
                                ) : (
                                  <Icon.XCircle
                                    style={{ color: "red" }}
                                    className="icon_common"
                                  />
                                )}
                              </span>
                              <span
                                title="Edit business details"
                                onClick={() => {
                                  this.setSession(item.location_id);
                                }}
                              >
                                <Icon.Edit2
                                  style={{ color: "BLUE" }}
                                  className="icon_common"
                                />
                              </span>
                              {item.maps_uri &&
                              item.new_review_uri &&
                              item.has_voice_of_merchant ? (
                                <span>
                                  <a
                                    href={
                                      "https://business.google.com/n/" +
                                      item.location_id +
                                      "/profile?hl=en-GB&fid=" +
                                      item.maps_uri?.split("=")[1]
                                    }
                                    target="_blank"
                                  >
                                    <button className="btn btn-outline-secondary">
                                      <img src={GoogleIcon} /> See your location
                                    </button>
                                  </a>
                                </span>
                              ) : (
                                <span></span>
                              )}
                              <span className="btn btn-outline-secondary">
                                <img /> Request to access
                              </span>
                            </td>
                            {/* <td className="col-2"> */}
                            {/* <Link
                              to={{
                                pathname: "/business-details",
                                // search: { location_id: item.location_id },
                              }}
                              state={{ location_id: item.location_id }}
                              // state={{ location_id: item.location_id }}
                            > */}

                            {/* </Link> */}
                            {/* </td> */}

                            {/* <td className="col-2">
                              
                            </td> */}
                          </tr>
                        </tbody>
                      );
                    })}
                  </div>
                </table>
              </div>
            </div>
            {/* </div> */}
          </div>
          <Footer />
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}

export default GoogleBusinessLocation;
