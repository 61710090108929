import React, { Component } from "react";
import Chart from "react-apexcharts";
import { backendApi } from "../apiService";
import $ from "jquery";
import * as Icon from "react-feather";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import { Link } from "react-router-dom";
import LeftMenu from "../layout/leftMenu.jsx";
import Accessibiltyimage from "../images/left_icons/analysis.png";

class CompetiterAnalysisGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [],
      finalarray: [],
      options: {
        chart: {
          type: "radialBar",
          events: {
            dataPointSelection: (event, chartContext, config) => {
              console.log(
                config.w.config.labels[config.dataPointIndex] == "Address Match"
                  ? "/competiterAnalysis/details"
                  : "/competiterAnalysis/audit"
              );
            },
          },
        },
        legend: {
          show: true,
        },
        stroke: {
          width: [10],
        },
        labels: ["Address Match", "Business Match", "Phone Match"],
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: "14px",
                show: true,
                color: "#000",
              },
              value: {
                show: true,
                color: "#333",
                fontSize: "14px",
              },
              // total: {
              //   show: true,
              //   label: 'Total',
              //   formatter: function (w) {
              //     return 249
              //   }
              // },
              offsetY: 0,
              startAngle: 0,
              endAngle: 360,
              hollow: {
                margin: 5,
                size: "30%",
                background: "transparent",
                image: undefined,
              },
            },
          },
        },

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
      },
      insightsDataKey: "",
      todayDate: new Date(),
      insightsStartDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ).toISOString(),
      insightsEndDate: new Date().toISOString(),
      seriesFlag: 0,
      getMasterdata: [],
    };
  }

  getCompitetorMasterData() {
    var req = {
      functionName: "getCompitetorMasterData",
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      role:
        window.sessionStorage.getItem("switchUserRole") === ""
          ? window.sessionStorage.getItem("role")
          : window.sessionStorage.getItem("switchUserRole"),
    };

    $(".loder_div").attr("hidden", false);
    backendApi(req).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        let array = [];
        for (let i = 0; i < data.data.length; i++) {
          array.push({
            addressMatch: data.data[i].address_match_percentage,
            businessMatch: data.data[i].business_name_match_percentage,
            phnoMatch: data.data[i].phoneno_match_percentage,
            name: data.data[i].comment,
            id: data.data[i].id,
          });
        }
        this.setState({
          uploaded: false,
          getMasterdata: data.data,
          finalarray: array,
        });
      }
    });
  }
  componentDidMount() {
    this.getCompitetorMasterData();

    // console.log("graph propsss data", this.props.endDate);
    if (this.state.startDate !== "" && this.state.endDate !== "") {
      console.log("graph propsss data", this.state.insightsStartDate);
      this.setState(
        {
          insightsStartDate: this.state.startDate,
          insightsEndDate: this.state.endDate,
        },
        () => {
          this.getInsights();
        }
      );
    } else {
      this.getInsights();
    }
  }
  getInsights() {
    var array = [];
    var req = {
      functionName: "GetInsights",
      account: window.sessionStorage.getItem("account_id"),
      location: "53904911361864879",
      startDate: this.state.insightsStartDate,
      endTime: this.state.insightsEndDate,
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    console.log("reqreq", req);
    backendApi(req).then((data) => {
      if (data.success === "1") {
        array.push(
          parseInt(
            data.data.locationMetrics[0].metricValues[0].totalValue.value
          )
        );
        array.push(
          parseInt(
            data.data.locationMetrics[0].metricValues[4].totalValue.value
          )
        );
        array.push(
          parseInt(
            data.data.locationMetrics[0].metricValues[3].totalValue.value
          )
        );
        array.push(
          parseInt(
            data.data.locationMetrics[0].metricValues[1].totalValue.value
          )
        );
        this.setState({ seriesFlag: 1, series: array });
      }
    });
  }
  deleteCompetitorDataByID(val){

    if (!window.confirm("Do you want to Delete Competitor Data?")) {
      return false;
    }
    var apidata = {
      functionName: "deleteCompetitorDataByID",
      masterId: val
    };
    $(".loder_div").attr("hidden", false);
    backendApi(apidata).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        this.getCompitetorMasterData()
      } else {
        alert("Something went wrong in Deleting the file");
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper bg-white">
          <Header
            heading="Competitor Analysis"
            headerImage={Accessibiltyimage}
          />
           
          {this.state.finalarray.length != "0" ? (
            <div className="container mt-3">
                {/* <span className="audit-txt">Total :{this.state.getMasterdata.length !="0"?this.state.getMasterdata.length:"0"}</span>  */}
              <div className="row graph_insight">
                {this.state.finalarray.map(
                  (item, i) =>
                    item.addressMatch != null &&
                    item.businessMatch != null &&
                    item.phnoMatch != null && (
                      <div className="col-md-6 col-lg-6 p-1">
                        <div className="chart-outer">
                          <h4 className="graph_insight_head">
                            {item.name.toUpperCase()}
                          </h4>
                          {/* <a href="/competiterAnalysis/details"> */}
                          <Link
                            to={"/competiterAnalysis/details/" + item.id}
                            state={{
                              tableId: item.id,
                              addressMatch: item.addressMatch,
                              businessMatch: item.businessMatch,
                              phnoMatch: item.phnoMatch,
                            }}
                          >
                            <Chart
                              key={item.id}
                              options={this.state.options}
                              series={[
                                parseInt(item.addressMatch),
                                parseInt(item.businessMatch),
                                parseInt(item.phnoMatch),
                              ]}
                              type="radialBar"
                            />
           
                          </Link>
                          <span 
                          className="trashGraph" onClick={()=>this.deleteCompetitorDataByID(item.id)}>
    
                              <Icon.Trash />
                           
                          </span>
                          {/* </a> */}
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
          ) : (
            <p className="m-3">No Data Found</p>
          )}
                  <Footer/>
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}
export default CompetiterAnalysisGraph;
