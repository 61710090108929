import moment from "moment";
import React, { Component } from "react";
import Chart from "react-apexcharts";
export default class NewInsightActionGraph extends Component {
  constructor(props) {
    console.log("props", props);
    super(props);
    this.state = {
      series: [
        // {
        //   name: this.props.YAxisName1,
        //   data: this.props.YAxisData.map(Number),
        // },
        // {
        //   name: this.props.YAxisName2,
        //   data: this.props.YAxisData2.map(Number),
        // },
        // {
        //   name: this.props.YAxisName3,
        //   data: this.props.YAxisData3.map(Number),
        // },
        // {
        //   name: this.props.YAxisName4,
        //   data: this.props.YAxisData4.map(Number),
        // },
      ],

      options: {
        chart: {
          type: "area",
          toolbar: {
            show: false,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          show: true,
          width: 2,
        },
        xaxis: {
          categories: this.props.XAxisData.map((data) =>
            moment(new Date(data)).add(1, "days").format("DD-MMM-YYYY")
          ),
          type: "datetime",
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return Math.round(value); // Use Math.round() to convert to integers
            },
          },
        },
        legend: {
          position: "right",
        },
      },
    };
  }
  render() {
    const seriesData = [];
    if (this.props.YAxisData?.length > 0) {
      seriesData.push({
        name: this.props.YAxisName,
        data: this.props.YAxisData.map(Number),
      });
    }
    if (this.props?.YAxisData2?.length > 0) {
      seriesData.push({
        name: this.props.YAxisName2,
        data: this.props.YAxisData2.map(Number),
      });
    }
    if (this.props?.YAxisData3?.length > 0) {
      seriesData.push({
        name: this.props.YAxisName3,
        data: this.props.YAxisData3.map(Number),
      });
    }
    if (this.props?.YAxisData4?.length > 0) {
      seriesData.push({
        name: this.props.YAxisName4,
        data: this.props.YAxisData4.map(Number),
      });
    }
    return (
      <div className="graph_insight">
        <Chart
          options={this.state.options}
          series={seriesData}
          type="area"
          height={300}
        />
      </div>
    );
  }
}
