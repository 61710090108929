import React, { Component } from "react";
import Dashboardimage from "../images/left_icons/dashboard.png";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import axios from "axios";
import Footer from "../layout/footer";
import { apiCallingPost, backendApi } from "../apiService";

import $ from "jquery";
import moment from "moment";
import stringreplacenpm from "stringreplacenpm";
import * as Icon from "react-feather";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Multiselect from "multiselect-react-dropdown";
var textBoxId;
class supportReply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listSupport: [],
      username: "",
      resolvedOn: "",
      issues: "",
      status: "",
      Id: "",
      reply: "",
      activeLink: null,
      convertedText: "",
      commentList: [],
      dropdownList: [],
      selectedValue: [],
      IdforEdit: "",
      userList: [],
      statusList: [],
    };
  }
  getUserList = (e) => {
    var req = {
      functionName: "listUserByOrganization",
      siteId: e === "" ? window.sessionStorage.getItem("organizationId") : e,
    };
    // $(".loder_div").attr("hidden", false);
    apiCallingPost(req).then((data) => {
      if (data.success === "1") {
        // $(".loder_div").attr("hidden", true);

        let finalarray = data.result.map((item) => ({
          name: item.first_name,
          id: item.id,
        }));
        this.setState({
          dropdownList: finalarray,
        });
      } else {
        // $(".loder_div").attr("hidden", true);
      }
    });
  };
  componentDidMount() {
    this.listSupport();
    if (window.sessionStorage.getItem("role") === "ROLE_USER") {
      $("#roleSelector").attr("hidden", true);
    } else if (window.sessionStorage.getItem("role") !== "ROLE_SUPER_ADMIN") {
      $("#roleSelector").attr("hidden", false);
      this.getUserList("");
    }
    // else {
    //   $("#roleSelector").attr("hidden", true);
    // }
    if (window.sessionStorage.getItem("switchingOrgId") !== "") {
      this.getUserList(window.sessionStorage.getItem("switchingOrgId"));
    }
    if (window.sessionStorage.getItem("switchUserId") !== "") {
      this.getUserList(window.sessionStorage.getItem("switchingOrgId"));
      $("#roleSelector").attr("hidden", false);
      $("#goBackSelector").attr("hidden", false);
    }
    $("#nodata").hide();
    $("#textArea").hide();
    $(document).ready(function () {
      let tabs_height = $(".tabs-main .react-tabs__tab-list").outerHeight(true);
      let header_height = $(".dash_heading").outerHeight(true);
      let totalheights = tabs_height + header_height;
      let sub = $(window).height() - totalheights;
      $(".left_support_history,.right_support_history").css("height", sub);
      $(window).resize(function () {
        let tabs_height = $(".tabs-main .react-tabs__tab-list").outerHeight();
        let header_height = $(".dash_heading").outerHeight();
        let totalheights = tabs_height + header_height;
        let sub = $(window).height() - totalheights;
        $(".left_support_history,.right_support_history").css("height", sub);
      });
    });
  }
  userStatuschange(e, id) {
    var iddropdownChange = { id: id }; //? when status Change stateUpdate
    if (!window.confirm("Do You Want To Change Status?")) {
      return false;
    }
    $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "userStatusChange",
      id: id,
      status: e.target.value,
    };
    apiCallingPost(reviewAPIData).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        alert("Status Changed Successfully");
        this.listSupport();
        this.textareaBox(iddropdownChange);
      } else {
        $(".loder_div").attr("hidden", true);
        alert("Failed");
      }
    });
  }
  sendIssue() {
    $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "supportCommentAdd",
      created_by: window.sessionStorage.getItem("userId"),
      id: this.state.IdforEdit != "" ? this.state.IdforEdit : "",
      support_id: this.state.Id,
      supportcomment: this.state.convertedText,
    };

    apiCallingPost(reviewAPIData).then((data) => {
      console.log("data=========", reviewAPIData);

      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        // alert("Send successfully");

        this.setState({
          convertedText: "",
          IdforEdit: "",
        });
        this.listcommenteddata(this.state.Id);
      } else {
        $(".loder_div").attr("hidden", true);
        alert("failed");
      }
    });
  }
  listcommenteddata(Id) {
    $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "listComment",
      support_id: Id,
    };

    apiCallingPost(reviewAPIData).then((data) => {
      console.log("data=========", data);

      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        // alert("Send successfully");
        this.setState({
          commentList: data.data,
        });
      } else {
        this.setState({
          commentList: [],
        });
        $(".loder_div").attr("hidden", true);
      }
    });
  }

  listAssignee(id) {
    $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "listAssignee",
      support_id: id,
    };
    apiCallingPost(reviewAPIData).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);

        let finalarray = data.data.map((item) => ({
          name: item.first_name,
          id: item.id,
        }));

        this.setState({
          selectedValue: finalarray,
        });
      } else {
        this.setState({
          selectedValue: [],
        });
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  deletComment(item) {
    $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "deleteComment",
      id: item.id,
    };
    apiCallingPost(reviewAPIData).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        this.listcommenteddata(item.support_id);
        $(".user_div_connent_inputs").show();
        $(".user_div_reactquill").hide();
      } else {
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  listSupport() {
    $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "listSupport",
      created_by: window.sessionStorage.getItem("userId"),
      role:
        window.sessionStorage.getItem("switchUserRole") === ""
          ? window.sessionStorage.getItem("role")
          : window.sessionStorage.getItem("switchUserRole"),
    };
    apiCallingPost(reviewAPIData).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        this.setState({
          listSupport: data.data[0],
          statusList: data.data[1],
        });
      } else {
        $(".loder_div").attr("hidden", true);
      }
    });
  }

  replyIssue(id) {
    if (!stringreplacenpm($("#issueInputadmin" + id).val())) {
      alert("Reply Field Cannot Be Empty");
      return false;
    }
    $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "updateSupport",
      resolved_by: window.sessionStorage.getItem("userId"),
      status: $("#statusValue" + id)
        .find("option:selected")
        .val(),
      replay: stringreplacenpm($("#issueInputadmin" + id).val()),

      id: id,
    };
    apiCallingPost(reviewAPIData).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        alert("Replied Successfully");

        this.listSupport();
      } else {
        $(".loder_div").attr("hidden", true);
        alert("Failed");
      }
    });
  }

  textareaBox(item) {
    $(".loder_div").attr("hidden", false);
    const apiReq = {
      functionName: "listSupportById",
      created_by: window.sessionStorage.getItem("userId"),
      role: window.sessionStorage.getItem("role"),
      id: item.id,
    };
    backendApi(apiReq).then((data) => {
      $(".loder_div").attr("hidden", true);
      $("#textArea" + item.id).show();
      this.setState({
        username: data.data[0].userName,
        resolvedOn: data.data[0].resolved_on,
        issues: data.data[0].issues,
        status: data.data[0].status,
        Id: data.data[0].id,
        reply: data.data[0].replay,
        activeLink: data.data[0].id,
        convertedText: "",
        IdforEdit: "",
      });
      this.listcommenteddata(data.data[0].id);
      this.listAssignee(data.data[0].id);
      // $(document).ready(function () {
      //   let tabs_height = $(".tabs-main .react-tabs__tab-list").outerHeight();
      //   let header_height = $(".dash_heading").outerHeight();
      //   let totalheights = tabs_height + header_height;
      //   let sub = $(window).height() - totalheights;
      //   $(".left_support_history,.right_support_history").css("height", sub);
      //   $(window).resize(function () {
      //     let tabs_height = $(".tabs-main .react-tabs__tab-list").outerHeight();
      //     let header_height = $(".dash_heading").outerHeight();
      //     let totalheights = tabs_height + header_height;
      //     let sub = $(window).height() - totalheights;
      //     $(".left_support_history,.right_support_history").css("height", sub);
      //   });
      //   $(".user_div_connent_inputs").click(function () {
      //     $(".user_div_connent_inputs").hide();
      //     $(".user_div_reactquill").show();
      //     this.setState({
      //       IdforEdit: "",
      //     });
      //   });
      // });
    });
  }

  convertedText(e) {
    console.log(e);
    this.setState({
      convertedText: e,
    });
  }
  userSaveText() {
    this.sendIssue();
    $(".user_div_connent_inputs").show();
    $(".user_div_reactquill").hide();
  }
  opentextEdit(item) {
    // "user_div_comment"+item.id
    // $("#user_div_comment"+item.id).hide();
    $(".user_div_reactquill").show();
    $(".user_div_connent_inputs").hide();
    this.setState({
      convertedText: item.supportcomment,
      IdforEdit: item.id,
    });
  }

  onSelect(selectedList, selectedItem) {
    // $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "supportAssignAdd",
      assignee_id: selectedItem.id,
      created_by: window.sessionStorage.getItem("userId"),
      support_id: $("#supportId").text(),
    };
    console.log("selectedList", selectedItem);
    apiCallingPost(reviewAPIData).then((data) => {
      console.log("data=========", reviewAPIData);

      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        // alert("Send successfully");
      } else {
        $(".loder_div").attr("hidden", true);
        alert("failed");
      }
    });
  }

  onRemove(selectedList, selectedItem) {
    let lastObject = selectedItem;
    let lastId = lastObject.id;
    // $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "deleteAssign",
      id: lastId,
    };

    console.log("reviewAPIData", reviewAPIData);

    apiCallingPost(reviewAPIData).then((data) => {
      console.log("data=========", reviewAPIData);

      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        // alert("Send successfully");
      } else {
        $(".loder_div").attr("hidden", true);
        alert("failed");
      }
    });
  }
  listSupportByStatus(status) {
    $(".loder_div").attr("hidden", false);
    const api = {
      functionName: "listSupportByStatus",
      created_by: window.sessionStorage.getItem("userId"),
      role: window.sessionStorage.getItem("role"),
      status: status,
    };
    backendApi(api).then((data) => {
      $(".loder_div").attr("hidden", true);
      // console.log("statusstatusstatusstatusstatus", data);
      this.setState({
        listSupport: data.data,
      });
    });
  }
  commentEditorShow() {
    $(".user_div_connent_inputs").hide();
    $(".user_div_reactquill").show();
    this.setState({
      IdforEdit: "",
      convertedText: "",
    });
  }
  render() {
    return (
      <div className="">
        <div className="row">
          <div className="col-xl-5">
            <div className="d-flex">
              <div className="classify_databox">
                <div
                  className="classify_sub color_prim"
                  onClick={() => this.listSupportByStatus("closed")}
                >
                  <span className="classify_subtxt">
                    <Icon.Compass />
                    Closed :{" "}
                  </span>
                  <span className="classify_subtxt">
                    {" "}
                    {this.state.statusList
                      .filter((data) => data.STATUS === "Closed")
                      .map((data) => data.cnt)}
                    {/* {Primary == "" ? 0 : Primary.map((data) => data.STATUS)} */}
                  </span>
                </div>
                <div
                  className="classify_sub color_stray"
                  onClick={() => this.listSupportByStatus("on hold")}
                >
                  <span className="classify_subtxt">
                    <Icon.GitMerge />
                    On Hold :{" "}
                  </span>
                  <span className="classify_subtxt">
                    {" "}
                    {this.state.statusList
                      .filter((data) => data.STATUS === "On Hold")
                      .map((data) => data.cnt)}
                    {/* {Stray == "" ? 0 : Stray.map((data) => data.STATUS)} */}
                  </span>
                </div>
                <div
                  className="classify_sub color_duplic"
                  onClick={() => this.listSupportByStatus("open")}
                >
                  <span className="classify_subtxt">
                    <Icon.Copy />
                    Open :{" "}
                  </span>
                  <span className="classify_subtxt">
                    {" "}
                    {/* {Duplicate == ""
                          ? 0
                          : Duplicate.map((data) => data.STATUS)} */}
                    {this.state.statusList
                      .filter((data) => data.STATUS === "Open")
                      .map((data) => data.cnt)}
                  </span>
                </div>

                <div
                  className="classify_sub"
                  onClick={() => this.listSupportByStatus("pending")}
                >
                  <span className="classify_subtxt">
                    <Icon.File />
                    Pending :{" "}
                  </span>
                  <span className="classify_subtxt">
                    {" "}
                    {/* {Blank == "" ? 0 : Blank.map((data) => data.STATUS)} */}
                    {this.state.statusList
                      .filter((data) => data.STATUS === "Pending")
                      .map((data) => data.cnt)}
                  </span>
                </div>
                <div
                  className="classify_sub link-show text_warning_color"
                  onClick={() => this.listSupportByStatus("resolved")}
                >
                  <span className="classify_subtxt">
                    <Icon.XSquare />
                    Resolved :{" "}
                  </span>
                  <span className="classify_subtxt">
                    {this.state.statusList
                      .filter((data) => data.STATUS === "Resolved")
                      .map((data) => data.cnt)}{" "}
                    {/* {Notfoundcount} */}
                  </span>
                </div>
                <div
                  className="classify_sub link-show text_red_color"
                  onClick={() => this.listSupportByStatus("Re-Open")}
                >
                  <span className="classify_subtxt">
                    <Icon.RefreshCw />
                    Re-Open :{" "}
                  </span>
                  <span className="classify_subtxt">
                    {this.state.statusList
                      .filter((data) => data.STATUS === "Re-Open")
                      .map((data) => data.cnt)}{" "}
                    {/* {Notfoundcount} */}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-5 mb-2">
          <div class="form-group row mb-0 d-flex">search</div>
        </div>
        <div className="support_history">
          <div className="left_support_history">
            {this.state.listSupport.length != "0" ? (
              this.state.listSupport.map((item) => {
                let issues = item.issues;
                return (
                  <div
                    key={item.id}
                    className={
                      "inner_left_support_histroy " +
                      (item.id === this.state.activeLink ? "active_item" : "")
                    }
                    onClick={() => this.textareaBox(item)}
                  >
                    <div className="support_history_id">
                      {" "}
                      {moment(item.created_on).format("DD MMM")}
                    </div>
                    <div className="support_history_div">
                      <div className="support_name_token">
                        <div className="support_history_name">
                          {item.userName}
                        </div>
                        {item.status == "Open" ? (
                          <div className="support_history_open_re open__red">
                            Open
                          </div>
                        ) : item.status == "Re-Open" ? (
                          <div className="support_history_open_re reopen__red">
                            Re-Open
                          </div>
                        ) : item.status == "Resolved" ? (
                          <div className="support_history_open_re resolved_green">
                            Resolved
                          </div>
                        ) : item.status == "Re-Open" ? (
                          <div className="support_history_open_re resolved_green">
                            Re-Open
                          </div>
                        ) : item.status == "Pending" ? (
                          <div className="support_history_open_re pending_yellow">
                            Pending
                          </div>
                        ) : item.status == "Closed" ? (
                          <div className="support_history_open_re close_gray">
                            Closed
                          </div>
                        ) : (
                          <div className="support_history_open_re on_hold">
                            On Hold
                          </div>
                        )}
                        <div className="support_history_token">
                          Token : <span>{item.id}</span>
                        </div>
                      </div>
                      <div className="support_history_date">
                        {moment(item.created_on).format("DD MMM YYYY hh:mm a")}
                      </div>
                      {/* <div
                        className="support_history_contt"
                        dangerouslySetInnerHTML={{ __html: $('<p>').html(issues).find('img').remove().end().html() }}
                      ></div> */}
                    </div>
                  </div>
                );
              })
            ) : (
              <p id="nodata">No Data Found</p>
            )}
          </div>

          <div className="right_support_history" key={this.state.Id}>
            <div
              className="inner_right_support_history"
              id={"textArea" + this.state.Id}
            >
              <div className="inner_support_msg common_support">
                <div class="support_name_token">
                  <div class="support_history_name">{this.state.username}</div>
                  <div class="support_history_token">
                    {this.state.resolvedOn != null && (
                      <>
                        Resolved On :{" "}
                        <span>
                          {moment(this.state.resolvedOn).format(
                            "DD MMM YYYY hh:mm a"
                          )}
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="rply_suppor_div"
                  dangerouslySetInnerHTML={{ __html: this.state.issues }}
                ></div>
                <div className="replay_support_textarea">
                  {window.sessionStorage.getItem("role") ===
                  "ROLE_SUPER_ADMIN" ? (
                    <textarea
                      placeholder="Reply Here..."
                      id={"issueInputadmin" + this.state.Id}
                      rows="4"
                      className="form-control"
                      defaultValue={
                        this.state.reply != null ? this.state.reply : ""
                      }
                    ></textarea>
                  ) : (
                    <span>{this.state.reply}</span>
                  )}
                </div>
                <div className="row">
                  <div className="col-lg-5 mb-2">
                    <div class="form-group row mb-0 d-flex">
                      <label class="col-sm-4 col-md-3 mt-2">Status</label>
                      <div id="supportId" hidden>
                        {this.state.Id}
                      </div>
                      <div class="col-sm-8 col-md-9">
                        <select
                          className="form-control mb-0"
                          name=""
                          id={"statusValue" + this.state.Id}
                          onChange={(e) =>
                            this.userStatuschange(e, this.state.Id)
                          }
                          value={this.state.status}
                        >
                          <option value="Open">Open</option>
                          <option value="Resolved">Resolved </option>
                          <option value="Pending">Pending</option>
                          {window.sessionStorage.getItem("role") ===
                            "ROLE_SUPER_ADMIN" && (
                            <>
                              <option value="Re-Open">Re-Open</option>
                              <option value="Closed">Closed</option>
                              <option value="On Hold">On Hold</option>
                            </>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 mb-2">
                    <div class="form-group row mb-0 d-flex">
                      <label class="col-sm-4 col-md-3 mt-2">Assignee</label>
                      <div class="col-sm-8 col-md-9">
                        <Multiselect
                          options={this.state.dropdownList}
                          selectedValues={
                            this.state.selectedValue.length > 0
                              ? this.state.selectedValue
                              : undefined
                          }
                          onSelect={this.onSelect}
                          onRemove={this.onRemove}
                          displayValue="name"
                          customCloseIcon={<Icon.X />}
                          // options={this.state.dropdownList} // Options to display in the dropdown
                          // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                          // onSelect={this.onSelect} // Function will trigger on select event
                          // onRemove={this.onRemove} // Function will trigger on remove event
                          // displayValue="name" // Property name to display in the dropdown options
                          // customCloseIcon={<Icon.X />}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 text-right">
                    {window.sessionStorage.getItem("role") ===
                      "ROLE_SUPER_ADMIN" && (
                      <button
                        className="btn-search"
                        onClick={() => this.replyIssue(this.state.Id)}
                      >
                        Reply
                      </button>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-8 col-md-12 col-lg-8">
                    <div className="user_div_commend">
                      <div className="user_div_comment">
                        <div className="user_div_comment_avathar">
                          <span className="user_div_comment_span">
                            {window.sessionStorage.getItem("userName").length >
                            0
                              ? window.sessionStorage
                                  .getItem("userName")
                                  .slice(0, 2)
                                  .toUpperCase()
                              : ""}
                          </span>
                        </div>
                        <div className="user_div_comment_textfield">
                          <input
                            className="user_div_connent_inputs form-control"
                            type="text"
                            onClick={() => this.commentEditorShow()}
                            placeholder="Add a comment..."
                          />
                          <div className="user_div_reactquill">
                            <div className="position-relative">
                              <ReactQuill
                                theme="snow"
                                value={this.state.convertedText}
                                onChange={(e) => this.convertedText(e)}
                                placeholder="Type @ to mention and notify someone."
                              />
                              {/* <span className="user_div_reactquill_span">
                                {" "}
                                <Icon.RotateCcw />
                              </span> */}
                            </div>
                            <div className="user_div_btn_gap">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() =>
                                  this.userSaveText(
                                    $("#user_div_comment" + textBoxId).show()
                                  )
                                }
                              >
                                Save
                              </button>
                              <button
                                type="button"
                                className="btn btn-light"
                                onClick={() =>
                                  this.cancelText(
                                    $("#user_div_comment" + textBoxId).show(),
                                    $(".user_div_connent_inputs").show(),
                                    $(".user_div_reactquill").hide()
                                  )
                                }
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.commentList.map((item) => (
                        <div
                          className="user_div_comment"
                          id={"user_div_comment" + item.id}
                        >
                          <div className="user_div_comment_avathar">
                            <span className="user_div_comment_span">
                              {/* {window.sessionStorage.getItem("userName")
                                .length > 0
                                ? window.sessionStorage
                                  .getItem("userName")
                                  .slice(0, 2)
                                  .toUpperCase()
                                : ""} */}
                              {item.first_name.slice(0, 1).toUpperCase()}
                              {item.last_name.slice(0, 1).toUpperCase()}
                              {/* {item.first_name} + {item.last_name} */}
                            </span>
                          </div>
                          <div className="user_div_comment_textfield">
                            <div className="user_div_comment_username_wrapper">
                              <div className="user_div_comment_username">
                                {item.first_name} {item.last_name}
                              </div>
                              {item.is_active == 0 ? (
                                <div className="user_dvi_minutes">
                                  {moment(item.created_on).format(
                                    "DD MMM YYYY"
                                  )}
                                </div>
                              ) : (
                                <div className="user_dvi_minutes">
                                  {moment(item.modified_on).format(
                                    "DD MMM YYYY"
                                  )}
                                </div>
                              )}

                              <div className="user_dvi_edited">
                                {item.is_active == 1 ? "Edited" : ""}
                              </div>
                            </div>

                            <div
                              className="user_div_testing_cmmd"
                              dangerouslySetInnerHTML={{
                                __html: item.supportcomment,
                              }}
                            ></div>
                            <div className="user_div_btn_gap mt-0 btn_edit_div">
                              <button
                                type="button"
                                className="btn btn-light"
                                onClick={() =>
                                  this.opentextEdit(
                                    item,
                                    (textBoxId = item.id),
                                    $("#user_div_comment" + item.id).hide()
                                  )
                                }
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                className="btn btn-light"
                                onClick={() => this.deletComment(item)}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="tableResponsive">
              <div className="tableOuterWidth">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th className="col-1">ID</th>
                      <th
                        className="col-2"
                        style={{ maxWidth: "12.666667%", flex: "0 0 12.6667%" }}
                      >
                        Created On
                      </th>
                      <th
                        className="col-2"
                        style={{ maxWidth: "24.666667%", flex: "0 0 24.6667%" }}
                      >
                        Issues
                      </th>

                      <th className="col-3">Reply</th>
                      <th
                        className="col-2"
                        style={{ maxWidth: "12.666667%", flex: "0 0 12.6667%" }}
                      >
                        Replied On
                      </th>
                      <th className="col-1">Status</th>
                      <th className="col-1"></th>
                    </tr>
                  </thead>
                  <div class="table_Outerscroll">
                    {this.state.listSupport.length != "0" ? (
                      this.state.listSupport.map((item) => (
                        <tbody className="table_Outertbody">
                          <tr>
                            <td className="col-1">{item.id}</td>
                            <td
                              className="col-2"
                              style={{
                                maxWidth: "12.666667%",
                                flex: "0 0 12.6667%",
                              }}
                            >
                              {moment(item.created_on).format(
                                "DD MMM YYYY hh:mm a"
                              )}
                              <br />({item.userName})
                            </td>
                            <td
                              className="col-2"
                              style={{
                                maxWidth: "24.666667%",
                                flex: "0 0 24.6667%",
                              }}
                            >
                              {item.issues}
                            </td>

                            <td className="col-3">
                              {window.sessionStorage.getItem("role") ===
                              "ROLE_SUPER_ADMIN" ? (
                                <textarea
                                  id={"issueInputadmin" + item.id}
                                  className="reprocessAreaInpt"
                                  defaultValue={item.replay}
                                ></textarea>
                              ) : (
                                <span>{item.replay}</span>
                              )}
                            </td>
                            <td
                              className="col-2"
                              style={{
                                maxWidth: "12.666667%",
                                flex: "0 0 12.6667%",
                              }}
                            >
                              {item.resolved_on == null
                                ? "-"
                                : moment(item.resolved_on).format(
                                    "DD MMM YYYY hh:mm a"
                                  )}
                            </td>

                            <td className="col-1">
                              <select
                                name=""
                                id={"statusValue" + item.id}
                                onChange={(e) =>
                                  this.userStatuschange(e, item.id)
                                }
                                defaultValue={item.status}
                              >
                                <option value="Open">Open</option>
                                <option value="Resolved">Resolved </option>
                                <option value="Pending">Pending</option>
                                {window.sessionStorage.getItem("role") ===
                                  "ROLE_SUPER_ADMIN" && (
                                  <>
                                    <option value="Re-Open">Re-Open</option>
                                    <option value="Closed">Closed</option>
                                    <option value="On Hold">On Hold</option>
                                  </>
                                )}
                              </select>
                            </td>

                            <td className="col-2">
                              {" "}
                              {window.sessionStorage.getItem("role") ===
                                "ROLE_SUPER_ADMIN" && (
                                <button
                                  className="btn-search"
                                  onClick={() => this.replyIssue(item.id)}
                                >
                                  Reply
                                </button>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <p id="nodata">No Data Found</p>
                    )}
                  </div>
                </table>
              </div>
            </div> */}
        <div className="loder_div" hidden />
      </div>
    );
  }
}

export default supportReply;
