import React, { useState, useEffect } from "react";
import SaveAndValidation from "./settingsSaveAndValidation";
function SettingsChatGPT(props) {
  var orgadmin = window.sessionStorage.getItem("role") == "ORG_ADMIN";
  const [is_active_chat_gpt, setIs_active_chat_gpt] = useState(
    props.listingValuesForPassing.is_active_chat_gpt != "null "
      ? props.listingValuesForPassing.is_active_chat_gpt == 0
        ? false
        : true
      : ""
  );
  useEffect(() => {
    if (
      props.listingValuesForPassing.is_active_chat_gpt &&
      (props.listingValuesForPassing.is_active_chat_gpt != "null" ||
        props.listingValuesForPassing.is_active_chat_gpt != "undefined")
    ) {
      setIs_active_chat_gpt(
        props.listingValuesForPassing.is_active_chat_gpt == 0 ? false : true
      );
    } else {
      setIs_active_chat_gpt("");
    }
  }, [props.listingValuesForPassing.is_active_chat_gpt]);
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="user_formdiv">
          <div className="userform_head">ChatGPT Review Settings</div>
          <div className="row userrow_form">
            <div className="col-md-4">
              <label className="col-form-label">Use ChatGPT for Reviews</label>
            </div>
            <div class="col-md-1">
              <span>:</span>
            </div>
            <div className="col-md-7">
              <span>
                <>
                  <label className="switch_items">
                    <input
                      id={"status"}
                      name={"status"}
                      type="checkbox"
                      checked={is_active_chat_gpt}
                      onClick={(event) =>
                        setIs_active_chat_gpt(event.target.checked)
                      }
                    />
                    <span className="switch round"></span>
                  </label>
                </>
              </span>
            </div>
          </div>
          <SaveAndValidation
            getUserData={() => props.getUserData()}
            valueForSaved={[is_active_chat_gpt]}
            saveMode={"ChatGPTSetting"}
            organizationId={props.organizationId}
          />
        </div>
      </div>
    </div>
  );
}
export default SettingsChatGPT;
