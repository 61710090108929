import React, { useEffect, useState } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Lock from "../images/left_icons/lock2.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Footer from "../layout/footer";
import { backendApi } from "../apiService";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GoPencil } from "react-icons/go";
import $ from "jquery";
import moment from "moment";
import Switch from "react-switch";

const ViewCampaignList = () => {
  const navigate = useNavigate();
  const [textList, setTextList] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [mediaList, setMediaList] = useState([]);
  const [planning, setPlanning] = useState([]);
  const [reqList, setReqList] = useState([]);
  const location = useLocation();
  const [waitingMessage, setWaitingMessage] = useState("No Data Found");
  const [hideSpan, setHideSpan] = useState(false);
  const [editModes, setEditModes] = useState();
  const [reqListCount, setReqListCount] = useState("");
  const [planningCount, setPlanningCount] = useState("");
  const [textListCount, setTextListCount] = useState("");
  const [imageListCount, setImageListCount] = useState("");
  const [mediaListCount, setMediaListCount] = useState("");
  const [campaign, setCampaign] = useState("");

  useEffect(() => {
    let camp = location.state.campaignName;
    setCampaign(camp);
    getTextList();
    getImageList();
    getMediaList();
    getPlanning();
    getRequirements();
  }, []);

  const textAreachange = (i) => {
    $("#searchaddress" + i).hide();
    $("#searchAddressvalue" + i).attr("hidden", false);
    $("#button" + i).attr("hidden", false);
    $("#titleEditIcon" + i).attr("hidden", true);
    $("#titleCancelEdit" + i).removeAttr("hidden");
  };

  const deleteContent = (data) => {
    const api = {
      functionName: "content_request_delete",
      content_generation_request_id: data.id,
      sec_user_id:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };
    backendApi(api).then((data) => {
      if (data.success === "1") {
        alert("Deleted Successfully!!");
        reset();
      } else {
        alert("Something went wrong");
      }
    });
  };

 
  // const approveContent = (dataArray) => {
  //   console.log("DA", dataArray);
  //   const promises = dataArray.map((data) => {
  //     const api = {
  //       functionName: "content_request_update_status",
  //       content_generation_request_id: data.id,
  //       req_status: "Approved",
  //       sec_user_id:
  //         window.sessionStorage.getItem("switchUserId") === ""
  //           ? window.sessionStorage.getItem("userId")
  //           : window.sessionStorage.getItem("switchUserId"),
  //     };
  //     return backendApi(api);
  //   });

  //   Promise.all(promises).then((results) => {
  //     const allApproved = results.every((result) => result.success === "1");
  //     if (allApproved) {
  //       alert("All items approved successfully!");
  //       getTextList();
  //       getImageList();
  //       getMediaList();
  //       getPlanning();
  //       getRequirements();
  //       reset(); // Reset any necessary state after approval
  //     } else {
  //       alert("Something went wrong while approving items.");
  //     }
  //   });
  // };

  const approveContent = (dataArray) => {
    if (Array.isArray(dataArray)) {
      const promises = dataArray.map((data) => {
        return approveItem(data);
      });
  
      Promise.all(promises).then((results) => {
        const allApproved = results.every((result) => result.success == "1");
        if (allApproved) {
          alert("All items approved successfully!");
          resetAfterApproval();
        } else {
          alert("Something went wrong while approving items.");
        }
      });
    } else {
      approveItem(dataArray).then((result) => {
        if (result.success ==="1") {
          alert("Item approved successfully!");
          resetAfterApproval();
        } else {
          alert("Failed to approve item.");
        }
      });
    }
  };
  
  const approveItem = (data) => {
    const api = {
      functionName: "content_request_update_status",
      content_generation_request_id: data.id,
      req_status: "Approved",
      sec_user_id:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };
  
    return backendApi(api);
  };

  const resetAfterApproval = () => {
    // Perform necessary state resets or data refetching after approval
    getTextList();
    getImageList();
    getMediaList();
    getPlanning();
    getRequirements();
    reset(); // Reset any necessary state after approval
  };

  const reset = () => {
    getTextList();
    getImageList();
    getMediaList();
    getPlanning();
    getRequirements();
  };
  const rejectContent = (data) => {
    const api = {
      functionName: "content_request_update_status",
      content_generation_request_id: data.id,
      req_status: "Rejected",
      sec_user_id:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };
    backendApi(api).then((data) => {
      if (data.success === "1") {
        alert("Rejected Successfully!!");
        reset();
      } else {
        alert("Something went wrong");
      }
    });
  };

  const cancelEdit = (i) => {
    $("#searchAddressvalue" + i).attr("hidden", true);
    $("#button" + i).attr("hidden", true);
    $("#titleCancelEdit" + i).attr("hidden", true);
    $("#titleEditIcon" + i).attr("hidden", false);
    $("#titleEditIcon" + i).removeAttr("hidden");
  };

  const getTextList = () => {
    const postData = {
      functionName: "content_request_Listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      category_type: "Text",
      req_status: "Waiting",
      campaign_id: location.state.props,
    };

    backendApi(postData)
      .then((data) => {
        if (data.success === "1") {
          setTextList(data.dataList);
          setTextListCount(data.dataList.length);
        }
      })
      .catch((error) => {
        console.error("Error occurred while fetching requested data:", error);
      });
  };
  const getImageList = () => {
    const postData = {
      functionName: "content_request_Listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      category_type: "Image",
      req_status: "Waiting",
      campaign_id: location.state.props,
    };

    backendApi(postData)
      .then((data) => {
        if (data.success === "1") {
          setImageList(data.dataList);
          setImageListCount(data.dataList.length);
        }
      })
      .catch((error) => {
        console.error("Error occurred while fetching requested data:", error);
      });
  };
  const getMediaList = () => {
    const postData = {
      functionName: "content_request_Listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      category_type: "Media",
      req_status: "Waiting",
      campaign_id: location.state.props,
    };

    backendApi(postData)
      .then((data) => {
        if (data.success === "1") {
          setMediaList(data.dataList);
          setMediaListCount(data.dataList.length);
        }
      })
      .catch((error) => {
        console.error("Error occurred while fetching requested data:", error);
      });
  };

  const getPlanning = () => {
    const postData = {
      functionName: "content_request_Listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      category_type: "Planning",
      req_status: "Waiting",
      campaign_id: location.state.props,
    };

    backendApi(postData)
      .then((data) => {
        if (data.success === "1") {
          setPlanning(data.dataList);
          setPlanningCount(data.dataList.length);
        }
      })
      .catch((error) => {
        console.error("Error occurred while fetching requested data:", error);
      });
  };

  const getRequirements = () => {
    const postData = {
      functionName: "content_request_Listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      category_type: "Question",
      req_status: "Waiting",
      campaign_id: location.state.props,
    };

    backendApi(postData)
      .then((data) => {
        if (data.success === "1") {
          setReqList(data.dataList);
          setReqListCount(data.dataList.length);
          setEditModes(new Array(data.dataList.length).fill(false));
        }
      })
      .catch((error) => {
        console.error("Error occurred while fetching requested data:", error);
      });
  };

  const saveRemarks = (data, i) => {
    let adminRemark = $("#searchAddressvalue" + i)
      .val()
      .replace(/['"]/g, "\\$&");
    const api = {
      functionName: "content_request_update_remarks",
      content_generation_request_id: data.id,
      remarks: adminRemark,
      remarks_updated_by:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };
    backendApi(api).then((data) => {
      alert("Remarks updated successfully!!");
      $("#searchAddressvalue" + i).attr("hidden", true);
      $("#button" + i).attr("hidden", true);
      $("#titleCancelEdit" + i).attr("hidden", true);
      $("#titleEditIcon" + i).attr("hidden", false);
      $("#titleEditIcon" + i).removeAttr("hidden");
      setHideSpan(false);
      reset();
    });
  };

  const back = () => {
    navigate(-1);
  };

  const referenceChanging = (data, checked, activeCase) => {
    let postData = {
      functionName: "referenceEnableDisable",
      is_reference: activeCase,
      content_generation_request_id: data.id,
    };
    backendApi(postData)
      .then((response) => {
        if (response?.success === "1") {
          getRequirements();
        }
      })
      .catch((error) => {
        console.error("Error fetching version list:", error);
      });
  };

  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Screening" headerImage={Lock} />
        <div className="col-md-12">
          <div className="d-md-flex justify-content-between my-2">
            {campaign && <h3 className="mb-0">{campaign}</h3>}
            <button
              type="submit"
              className="btn btn-secondary mr-2"
              onClick={() => back()}
            >
              Back
            </button>
          </div>

          <div className="tabs-main-wrapper">
            <Tabs>
              <TabList>
                <Tab>
                  Requirement Gathering
                  <span className="waitingTxt">{reqListCount}</span>
                </Tab>
                <Tab>
                  Planning
                  <span className="waitingTxt">{planningCount}</span>
                </Tab>
                <Tab>
                  Text
                  <span className="waitingTxt">{textListCount}</span>
                </Tab>
                <Tab>
                  Image
                  <span className="waitingTxt">{imageListCount}</span>
                </Tab>
                <Tab>
                  Media
                  <span className="waitingTxt">{mediaListCount}</span>
                </Tab>
              </TabList>

              <TabPanel>
                <div className="col-md-12 tableResponsive tagImageEditrListTble">
                  <div className="tableOuterWidth ">
                    {reqList && reqList.length > 0 ? (
                      <>
                        <button
                          className="btn btn-submit pull-right my-3"
                          onClick={() => approveContent(reqList)}
                        >
                          Approve all
                        </button>
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="col-1">Id </th>
                              <th className="col-3">Content</th>
                              <th className="col-2">Remarks</th>
                              <th className="col-2">Requested</th>
                              <th className="col-2">Reference</th>
                              <th className="col-2 ">Actions</th>
                            </tr>
                          </thead>
                          {reqList.map((data, i) => {
                            return (
                              <div className="table_Outerscroll">
                                <tbody className="table_Outertbody pb-3">
                                  <tr key={i}>
                                    <td className="col-1">{i + 1}</td>
                                    <td className="col-3">
                                      <p>Qn:{data.content_image}</p>
                                      <p>Ans:{data.content_text}</p>
                                    </td>
                                    <td className="col-2">
                                      <div className="popup-image">
                                        {!hideSpan && (
                                          <span id={`remarksValue${i}`}>
                                            {data.remarks === null ||
                                            data.remarks === ""
                                              ? "NA"
                                              : data.remarks}
                                          </span>
                                        )}
                                        <GoPencil
                                          id={"titleEditIcon" + i}
                                          className="audit-search-icon ml-2"
                                          onClick={() => {
                                            textAreachange(i, data.search);
                                            setHideSpan(true);
                                            $("#searchAddressvalue" + i).val(
                                              data.remarks
                                            );
                                            $("#dataId").text(data.id);
                                          }}
                                        />
                                        <textarea
                                          type="text"
                                          className="form-control searchEditInput"
                                          placeholder="Remarks.."
                                          id={"searchAddressvalue" + i}
                                          hidden
                                        />
                                        <div className="mt-2">
                                          <span id={"button" + i} hidden>
                                            <button
                                              onClick={() =>
                                                saveRemarks(data, i)
                                              }
                                              className="btn btn-success"
                                            >
                                              Save
                                            </button>
                                          </span>
                                          <span
                                            id={"titleCancelEdit" + i}
                                            hidden
                                          >
                                            <button
                                              onClick={() => cancelEdit(i)}
                                              className="btn btn-secondary ml-1"
                                            >
                                              Cancel
                                            </button>
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="col-2">
                                      <p>
                                        {" "}
                                        {moment(data.requested_date).format(
                                          "DD MMM YYYY"
                                        )}
                                      </p>
                                      <p>{data.requested_by_name}</p>
                                    </td>
                                    <td className="col-2">
                                      <Switch
                                        className="switch_case"
                                        checked={data.is_reference === 1}
                                        onChange={(checked) => {
                                          referenceChanging(
                                            data,
                                            checked,
                                            data.is_reference === 1 ? 0 : 1
                                          );
                                        }}
                                      ></Switch>
                                    </td>
                                    <td className="col-2">
                                      {data.req_status === "Waiting" && (
                                        <>
                                          <button
                                            type="submit"
                                            className="btn btn-success mr-2"
                                            onClick={() => approveContent(data)}
                                          >
                                            Approve
                                          </button>
                                          <button
                                            type="submit"
                                            className="btn btn-danger"
                                            onClick={() => rejectContent(data)}
                                          >
                                            Reject
                                          </button>
                                        </>
                                      )}
                                      {(data.req_status === "Approved" ||
                                        data.req_status === "Rejected") && (
                                        <button
                                          type="submit"
                                          className="btn btn-danger"
                                          title="Delete Content"
                                          onClick={() => deleteContent(data)}
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </div>
                            );
                          })}
                        </table>
                      </>
                    ) : (
                      <div className="message-common">{waitingMessage}</div>
                    )}
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="col-md-12 tableResponsive tagImageEditrListTble">
                  <div className="tableOuterWidth">
                    {planning && planning.length > 0 ? (
                      <>
                        <button
                          className="btn btn-submit pull-right my-3"
                          onClick={() => approveContent(planning)}
                        >
                          Approve all
                        </button>
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="col-1">Id </th>
                              <th className="col-3">Image</th>
                              <th className="col-2">Remarks</th>
                              <th className="col-2">Requested</th>
                              <th className="col-2">Approved</th>
                              <th className="col-2 ">Actions</th>
                            </tr>
                          </thead>
                          {planning.map((data, i) => {
                            return (
                              <div className="table_Outerscroll">
                                <tbody className="table_Outertbody pb-3">
                                  <tr key={i}>
                                    <td className="col-1">{i + 1}</td>
                                    <td className="col-3">
                                      <div className="popup-image">
                                        <div className="popupGallerysection">
                                          <a
                                            href={data.content_image}
                                            title="img"
                                            target="_blank"
                                          >
                                            <img
                                              src={data.content_image}
                                              className="img-fluid"
                                            />
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="col-2">
                                      <div className="popup-image">
                                        {!hideSpan && (
                                          <span id={`remarksValue${i}`}>
                                            {data.remarks === null ||
                                            data.remarks === ""
                                              ? "NA"
                                              : data.remarks}
                                          </span>
                                        )}
                                        <GoPencil
                                          id={"titleEditIcon" + i}
                                          className="audit-search-icon ml-2"
                                          onClick={() => {
                                            textAreachange(i, data.search);
                                            setHideSpan(true);
                                            $("#searchAddressvalue" + i).val(
                                              data.search
                                            );
                                            $("#dataId").text(data.id);
                                          }}
                                        />
                                        <textarea
                                          type="text"
                                          className="form-control searchEditInput"
                                          placeholder="Remarks.."
                                          id={"searchAddressvalue" + i}
                                          hidden
                                        />
                                        <div className="mt-2">
                                          <span id={"button" + i} hidden>
                                            <button
                                              onClick={() =>
                                                saveRemarks(data, i)
                                              }
                                              className="btn btn-success"
                                            >
                                              Save
                                            </button>
                                          </span>
                                          <span
                                            id={"titleCancelEdit" + i}
                                            hidden
                                          >
                                            <button
                                              onClick={() => cancelEdit(i)}
                                              className="btn btn-secondary ml-1"
                                            >
                                              Cancel
                                            </button>
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="col-2">
                                      <p>
                                        {" "}
                                        {moment(data.requested_date).format(
                                          "DD MMM YYYY"
                                        )}
                                      </p>
                                      <p>{data.requested_by_name}</p>
                                    </td>
                                    <td className="col-2">
                                      {data.approved_by_name ? (
                                        <>
                                          <p>
                                            {moment(data.approved_date).format(
                                              "DD MMM YYYY"
                                            )}
                                          </p>
                                          <p>{data.approved_by_name}</p>
                                        </>
                                      ) : (
                                        <p>Not approved</p>
                                      )}
                                    </td>
                                    <td className="col-2">
                                      {data.req_status === "Waiting" && (
                                        <>
                                          <button
                                            type="submit"
                                            className="btn btn-success mr-2"
                                            onClick={() => approveContent(data)}
                                          >
                                            Approve
                                          </button>
                                          <button
                                            type="submit"
                                            className="btn btn-danger"
                                            onClick={() => rejectContent(data)}
                                          >
                                            Reject
                                          </button>
                                        </>
                                      )}
                                      {(data.req_status === "Approved" ||
                                        data.req_status === "Rejected") && (
                                        <button
                                          type="submit"
                                          className="btn btn-danger"
                                          title="Delete Content"
                                          onClick={() => deleteContent(data)}
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </div>
                            );
                          })}
                        </table>
                      </>
                    ) : (
                      <div className="message-common">{waitingMessage}</div>
                    )}
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="col-md-12 tableResponsive tagImageEditrListTble mt-4">
                  <div className="tableOuterWidth ">
                    {textList && textList.length > 0 ? (
                      <>
                        <button
                          className="btn btn-submit pull-right my-3"
                          onClick={() => approveContent(textList)}
                        >
                          Approve all
                        </button>

                        <table className="table">
                          <thead>
                            <tr>
                              <th className="col-1">Id </th>
                              <th className="col-3">Content</th>
                              <th className="col-2">Remarks</th>
                              <th className="col-2">Requested</th>
                              <th className="col-2">Approved</th>
                              <th className="col-2 ">Actions</th>
                            </tr>
                          </thead>
                          {textList.map((data, i) => {
                            return (
                              <div className="table_Outerscroll">
                                <tbody className="table_Outertbody pb-3">
                                  <tr key={i}>
                                    <td className="col-1">{i + 1}</td>
                                    <td className="col-3">
                                      <span>{data.content_text}</span>
                                    </td>
                                    <td className="col-2">
                                      <div className="popup-image">
                                        {!hideSpan && (
                                          <span id={`remarksValue${i}`}>
                                            {data.remarks === null ||
                                            data.remarks === ""
                                              ? "NA"
                                              : data.remarks}
                                          </span>
                                        )}
                                        <GoPencil
                                          id={"titleEditIcon" + i}
                                          className="audit-search-icon ml-2"
                                          onClick={() => {
                                            textAreachange(i, data.search);
                                            setHideSpan(true);
                                            $("#searchAddressvalue" + i).val(
                                              data.search
                                            );
                                            $("#dataId").text(data.id);
                                          }}
                                        />
                                        <textarea
                                          type="text"
                                          className="form-control searchEditInput"
                                          placeholder="Remarks.."
                                          id={"searchAddressvalue" + i}
                                          hidden
                                        />
                                        <div className="mt-2">
                                          <span id={"button" + i} hidden>
                                            <button
                                              onClick={() =>
                                                saveRemarks(data, i)
                                              }
                                              className="btn btn-success"
                                            >
                                              Save
                                            </button>
                                          </span>
                                          <span
                                            id={"titleCancelEdit" + i}
                                            hidden
                                          >
                                            <button
                                              onClick={() => cancelEdit(i)}
                                              className="btn btn-secondary ml-1"
                                            >
                                              Cancel
                                            </button>
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="col-2">
                                      <p>
                                        {" "}
                                        {moment(data.requested_date).format(
                                          "DD MMM YYYY"
                                        )}
                                      </p>
                                      <p>{data.requested_by_name}</p>
                                    </td>
                                    <td className="col-2">
                                      {data.approved_by_name ? (
                                        <>
                                          <p>
                                            {moment(data.approved_date).format(
                                              "DD MMM YYYY"
                                            )}
                                          </p>
                                          <p>{data.approved_by_name}</p>
                                        </>
                                      ) : (
                                        <p>Not approved</p>
                                      )}
                                    </td>
                                    <td className="col-2">
                                      {data.req_status === "Waiting" && (
                                        <>
                                          <button
                                            type="submit"
                                            className="btn btn-success mr-2"
                                            onClick={() => approveContent(data)}
                                          >
                                            Approve
                                          </button>
                                          <button
                                            type="submit"
                                            className="btn btn-danger"
                                            onClick={() => rejectContent(data)}
                                          >
                                            Reject
                                          </button>
                                        </>
                                      )}
                                      {(data.req_status === "Approved" ||
                                        data.req_status === "Rejected") && (
                                        <button
                                          type="submit"
                                          className="btn btn-danger"
                                          title="Delete Content"
                                          onClick={() => deleteContent(data)}
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </div>
                            );
                          })}
                        </table>
                      </>
                    ) : (
                      <div className="message-common">{waitingMessage}</div>
                    )}
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="col-md-12 tableResponsive tagImageEditrListTble">
                  <div className="tableOuterWidth">
                    {imageList && imageList.length > 0 ? (
                      <>
                        <button
                          className="btn btn-submit pull-right my-3"
                          onClick={() => approveContent(imageList)}
                        >
                          Approve all
                        </button>

                        <table className="table">
                          <thead>
                            <tr>
                              <th className="col-1">Id </th>
                              <th className="col-3">Image</th>
                              <th className="col-2">Remarks</th>
                              <th className="col-2">Requested</th>
                              <th className="col-2">Approved</th>
                              <th className="col-2 ">Actions</th>
                            </tr>
                          </thead>
                          {imageList.map((data, i) => {
                            let contentType = data.category_type;
                            return (
                              <div className="table_Outerscroll">
                                <tbody className="table_Outertbody pb-3">
                                  <tr key={i}>
                                    <td className="col-1">{i + 1}</td>
                                    <td className="col-3">
                                      {contentType === "Image" && (
                                        <div className="popup-image">
                                          <div className="popupGallerysection">
                                            <a
                                              href={data.content_image}
                                              title="img"
                                              target="_blank"
                                            >
                                              <img
                                                src={data.content_image}
                                                className="img-fluid"
                                              />
                                            </a>
                                          </div>
                                        </div>
                                      )}
                                    </td>
                                    <td className="col-2">
                                      <div className="popup-image">
                                        {!hideSpan && (
                                          <span id={`remarksValue${i}`}>
                                            {data.remarks === null ||
                                            data.remarks === ""
                                              ? "NA"
                                              : data.remarks}
                                          </span>
                                        )}
                                        <GoPencil
                                          id={"titleEditIcon" + i}
                                          className="audit-search-icon ml-2"
                                          onClick={() => {
                                            textAreachange(i, data.search);
                                            setHideSpan(true);
                                            $("#searchAddressvalue" + i).val(
                                              data.search
                                            );
                                            $("#dataId").text(data.id);
                                          }}
                                        />
                                        <textarea
                                          type="text"
                                          className="form-control searchEditInput"
                                          placeholder="Remarks.."
                                          id={"searchAddressvalue" + i}
                                          hidden
                                        />
                                        <div className="mt-2">
                                          <span id={"button" + i} hidden>
                                            <button
                                              onClick={() =>
                                                saveRemarks(data, i)
                                              }
                                              className="btn btn-success"
                                            >
                                              Save
                                            </button>
                                          </span>
                                          <span
                                            id={"titleCancelEdit" + i}
                                            hidden
                                          >
                                            <button
                                              onClick={() => cancelEdit(i)}
                                              className="btn btn-secondary ml-1"
                                            >
                                              Cancel
                                            </button>
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="col-2">
                                      <p>
                                        {" "}
                                        {moment(data.requested_date).format(
                                          "DD MMM YYYY"
                                        )}
                                      </p>
                                      <p>{data.requested_by_name}</p>
                                    </td>
                                    <td className="col-2">
                                      {data.approved_by_name ? (
                                        <>
                                          <p>
                                            {moment(data.approved_date).format(
                                              "DD MMM YYYY"
                                            )}
                                          </p>
                                          <p>{data.approved_by_name}</p>
                                        </>
                                      ) : (
                                        <p>Not approved</p>
                                      )}
                                    </td>
                                    <td className="col-2">
                                      {data.req_status === "Waiting" && (
                                        <>
                                          <button
                                            type="submit"
                                            className="btn btn-success mr-2"
                                            onClick={() => approveContent(data)}
                                          >
                                            Approve
                                          </button>
                                          <button
                                            type="submit"
                                            className="btn btn-danger"
                                            onClick={() => rejectContent(data)}
                                          >
                                            Reject
                                          </button>
                                        </>
                                      )}
                                      {(data.req_status === "Approved" ||
                                        data.req_status === "Rejected") && (
                                        <button
                                          type="submit"
                                          className="btn btn-danger"
                                          title="Delete Content"
                                          onClick={() => deleteContent(data)}
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </div>
                            );
                          })}
                        </table>
                      </>
                    ) : (
                      <div className="message-common">{waitingMessage}</div>
                    )}
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="col-md-12 tableResponsive tagImageEditrListTble">
                  <div className="tableOuterWidth">
                    {mediaList && mediaList.length > 0 ? (
                      <>
                        <button
                          className="btn btn-submit pull-right my-3"
                          onClick={() => approveContent(mediaList)}
                        >
                          Approve all
                        </button>

                        <table className="table">
                          <thead>
                            <tr>
                              <th className="col-1">Id </th>
                              <th className="col-3">Media</th>
                              <th className="col-2">Remarks</th>
                              <th className="col-2">Requested</th>
                              <th className="col-2">Approved</th>
                              <th className="col-2 ">Actions</th>
                            </tr>
                          </thead>
                          {mediaList.map((data, i) => {
                            let contentType = data.category_type;
                            return (
                              <div className="table_Outerscroll">
                                <tbody className="table_Outertbody pb-3">
                                  <tr key={i}>
                                    <td className="col-1">{i + 1}</td>
                                    <td className="col-3">
                                      {contentType === "Media" && (
                                        <div className="popup-image">
                                          <div className="popupGallerysection">
                                            <a
                                              href={data.content_media}
                                              title="img"
                                              target="_blank"
                                            >
                                              <img
                                                src={data.content_media}
                                                className="img-fluid"
                                              />
                                            </a>
                                          </div>
                                        </div>
                                      )}
                                    </td>
                                    <td className="col-2">
                                      <div className="popup-image">
                                        {!hideSpan && (
                                          <span id={`remarksValue${i}`}>
                                            {data.remarks === null ||
                                            data.remarks === ""
                                              ? "NA"
                                              : data.remarks}
                                          </span>
                                        )}
                                        <GoPencil
                                          id={"titleEditIcon" + i}
                                          className="audit-search-icon ml-2"
                                          onClick={() => {
                                            textAreachange(i, data.search);
                                            setHideSpan(true);
                                            $("#searchAddressvalue" + i).val(
                                              data.search
                                            );
                                            $("#dataId").text(data.id);
                                          }}
                                        />
                                        <textarea
                                          type="text"
                                          className="form-control searchEditInput"
                                          placeholder="Remarks.."
                                          id={"searchAddressvalue" + i}
                                          hidden
                                        />
                                        <div className="mt-2">
                                          <span id={"button" + i} hidden>
                                            <button
                                              onClick={() =>
                                                saveRemarks(data, i)
                                              }
                                              className="btn btn-success"
                                            >
                                              Save
                                            </button>
                                          </span>
                                          <span
                                            id={"titleCancelEdit" + i}
                                            hidden
                                          >
                                            <button
                                              onClick={() => cancelEdit(i)}
                                              className="btn btn-secondary ml-1"
                                            >
                                              Cancel
                                            </button>
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="col-2">
                                      <p>
                                        {" "}
                                        {moment(data.requested_date).format(
                                          "DD MMM YYYY"
                                        )}
                                      </p>
                                      <p>{data.requested_by_name}</p>
                                    </td>
                                    <td className="col-2">
                                      {data.approved_by_name ? (
                                        <>
                                          <p>
                                            {moment(data.approved_date).format(
                                              "DD MMM YYYY"
                                            )}
                                          </p>
                                          <p>{data.approved_by_name}</p>
                                        </>
                                      ) : (
                                        <p>Not approved</p>
                                      )}
                                    </td>
                                    <td className="col-2">
                                      {data.req_status === "Waiting" && (
                                        <>
                                          <button
                                            type="submit"
                                            className="btn btn-success mr-2"
                                            onClick={() => approveContent(data)}
                                          >
                                            Approve
                                          </button>
                                          <button
                                            type="submit"
                                            className="btn btn-danger"
                                            onClick={() => rejectContent(data)}
                                          >
                                            Reject
                                          </button>
                                        </>
                                      )}
                                      {(data.req_status === "Approved" ||
                                        data.req_status === "Rejected") && (
                                        <button
                                          type="submit"
                                          className="btn btn-danger"
                                          title="Delete Content"
                                          onClick={() => deleteContent(data)}
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </div>
                            );
                          })}
                        </table>
                      </>
                    ) : (
                      <div className="message-common">{waitingMessage}</div>
                    )}
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>

        <div className="loder_div" hidden />
        <Footer />
      </div>
    </div>
  );
};

export default ViewCampaignList;
