import React, { useEffect, useState } from 'react'
import Img from "../images/arrow-up.png"
import { backendApi } from "../apiService";
import { backendDashboardApi } from "../apiService";
function DashboardSales({formattedDates}) {
    const [list, setList] = useState([])
    const [salesList, setSalesList] = useState([]);
    useEffect(() => {
        // listCallHandle()
        salesListing()
    }, [formattedDates])
    // const listCallHandle = () => {
    //     const postData = {
    //         "functionName": "dashboard_GBP_Data",
    //         "siteId": window.sessionStorage.getItem("switchingOrgId") === ""
    //         ? window.sessionStorage.getItem("organizationId")
    //         : window.sessionStorage.getItem("switchingOrgId"),
    //     }
    //     backendDashboardApi(postData).then((data) => {
    //         setList(data.result.gbpTotalAction[0].grand_total_sum)
    //     })
    // }
    const convertToK = (num) => {
        if (num >= 1000) {
            return (num / 1000).toFixed(1);
        } else if (num >= 10) {
            return (num / 1000).toFixed(3);
        } else {
            return (num / 1000).toFixed(3);
        }
    };
    const salesListing = () => {
        const postData = {
            "functionName": "getSalesDetailsByAccountId",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "userId": window.sessionStorage.getItem("userId"),
            "startDate": formattedDates[0],
            "endDate":formattedDates[1]
        };
        backendDashboardApi(postData).then((data) => {
            console.log("sales data", data)
            if (data.success === "1") {
                setSalesList(data.result)
            } else {

            }
        });
    };
    return (
        <div className= {window.sessionStorage.getItem("roleId") === "12" ? "db-cards-left db-total-sales" : "db-cards-left"}>
            <div class="card db-height mb-2 ">
            {salesList && salesList.map((data, index) =>
                <div class="card-body mb-0 p-0 py-2 ">
                    <div className='db-cards-top '>
                        <h5 class="card-title active_div">{data.total_sales.toLocaleString('en-IN')} <span className='db-cad-top-units'>Units</span>
                            <span>
                                <img src={Img} alt="Img" className='img-fluid ms-2' width="17" height="17" />
                            </span>
                        </h5>
                        <span className='db-right-btn'>{formattedDates[0] === formattedDates[1] ? formattedDates[0] :formattedDates[0]+"-"+formattedDates[1]}</span>
                    </div>
                    <h6 class="card-subtitle mb-3">Total Sales</h6>
                    <div class="card bg-transparent border-0 shadow-none cards-width px-0 justify-content-center text-center w-100 pb-2 mb-0 pt-0">
                                        {/* <div className='db-head invisible'> */}
                                        <div className= {window.sessionStorage.getItem("roleId") === "11" ? "db-head db-head-11 invisible mb-0" : "db-head invisible"}>
                                            Budget Allocation :
                                        </div>
                                        </div>
                    <div className= {window.sessionStorage.getItem("roleId") === "11" ? "card bg-white shadow-none db-sales-cards " : "card bg-white shadow-none"}>
                        <div className='db-cards-top px-2 active_div'>
                            <h5 class="db-card-title">Cost/Retail</h5>
                            <span className='db-right-number'>&#8377;{data.cost_retail_amount}
                            <span className='db-percentage'>{(data.cost_retail_symbol) && `(${data.cost_retail_symbol})`}</span>
                                {/* <span className='db-percentage'>({data.cost_retail_symbol})</span> */}
                            </span>
                        </div>
                    </div>
                    <div className= {window.sessionStorage.getItem("roleId") === "11" ? "card bg-white shadow-none db-sales-cards" : "card bg-white shadow-none"}>
                        <div className='db-cards-top px-2 active_div'>
                            <h5 class="db-card-title">Lead 2 Retail (%)</h5>
                            <span className='db-right-number'>{data.lead_2_retail?.toFixed(2)}
                                <span className='db-percentage'>%</span>
                            </span>
                        </div>

                    </div>
                    <div className= {window.sessionStorage.getItem("roleId") === "11" ? "card bg-white shadow-none db-sales-cards" : "card bg-white shadow-none"}>
                        <div className='db-cards-top px-2'>
                            <h5 class="db-card-title">GBP Actions</h5>
                            <span className='db-right-number'>
                                {data.GBP_action_amount}
                                <span className='db-percentage'>{(data.GBP_action_symbol) && `(${data.GBP_action_symbol})`}</span>
                            </span>
                        </div>
                    </div>
                </div>
                )}
            </div>
        </div>
    )
}

export default DashboardSales;