import React, { useEffect, useState } from 'react'
import Export from '../../images/files.png'
import Calender from '../../images/date_filters.png'
import Dropdown from '../../images/dropdown.png'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PaidMedia from './paidMedia'
function Dashboardmain({ formattedDates }) {

    return (
        <div className='container-fluid dashb-main pt-3'>
            <div className="dashb-main-tabs">
                <Tabs>
                    <TabList>
                        <Tab>Paid Media</Tab>
                        <Tab>Social Media</Tab>
                        <Tab>Lisitings & Repetition</Tab>
                        <Tab>Location Pages</Tab>
                        <Tab>Content Creation</Tab>
                    </TabList>

                    <TabPanel>
                        <PaidMedia />
                    </TabPanel>
                    <TabPanel>
                        <PaidMedia />
                    </TabPanel>
                    <TabPanel>
                        <PaidMedia />
                    </TabPanel>
                    <TabPanel>
                        <PaidMedia />
                    </TabPanel>
                    <TabPanel>
                        <PaidMedia />
                    </TabPanel>
                </Tabs>
            </div>
            <div className="dashb-main-right">
                <span className="export-btn"><img src={Export} alt="" className='img-fluid' /><span className='export-btn-text'>Exports</span></span>
                <button className='dashb-download-btn'>Last 30 Days

                    <img src={Calender} alt="Calender" className='img-fluid calenderImg' width="20" heigt="20" />
                    <img src={Dropdown} alt="Calender" className='img-fluid dropdownImg' width="20" heigt="20" />

                </button>
                <div></div>
            </div>
        </div>
    )
}

export default Dashboardmain;