import React from "react";
import { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { apiCallingPost } from "../apiService";

function ProductType() {
    const [productValue, setProductValue] = useState('');
    const [productValueResult, setProductValueResult] = useState("");

    useEffect(() => {
        getProductTypeValue();
    }, []);

    const handleProductTypeSave = () => {
        const postData = {
            "functionName": "accountSettings",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "isHighValue": productValue
        }
        apiCallingPost(postData).then((data) => {
            if (data.success === "1") {
                alert("Saved Successfully");
            } else {
                alert("SomethingWent Wrong");
            }
        });
    }
    const handleRadioChange = (value) => {
        setProductValue(value);
        setProductValueResult(value)
    };

    const getProductTypeValue = () => {
        const postData = {
            "functionName": "getOrganizationSettings",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
        }
        apiCallingPost(postData).then((data) => {
            console.log("🚀setProductValueResult ~ apiCallingPost ~ data:", data)
            if (data.success === "1") {
                setProductValueResult(data.result.isHighValue)
            } else {
            }
        });
    }

    return (
        <div className="pl-0 pl-md-4 py-5">
            <div className="row mx-0">
                <div className="col-lg-12 subhead mt-3">Product Type</div>
                <div className="col-md-8 col-lg-6 col-xl-4">
                    <div class="card my-2 border-0">
                        <div class="card-body p-0">
                            <div className="organisation-subdiv d-flex">
                                <span>High Value</span>
                                <div className="checkbox_div custom-radio">
                                    {console.log("sssssssssssssssssssssss", productValueResult)}
                                    <input type="radio" id="highvalue" name="productName" className="custom-control-input" onChange={() => handleRadioChange(true)}
                                        checked={productValueResult === true}
                                    />
                                    <label className="custom-control-label" for="highvalue"></label>
                                </div>
                            </div>
                            <div className="organisation-subdiv d-flex">
                                <span>Low Value</span>
                                <div className="checkbox_div custom-radio">
                                    <input type="radio" id="lowvalue" name="productName" className="custom-control-input" onChange={() => handleRadioChange(false)}
                                        checked={productValueResult === false}
                                    />
                                    <label className="custom-control-label" for="lowvalue"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="ml-auto my-3">
                            <button className="db-btn" onClick={() => handleProductTypeSave()}>Save</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ProductType;
