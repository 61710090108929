import React, { useEffect, useState } from 'react'
import { backendDashboardApi } from "../apiService";
function GbpSearches({ formattedDates }) {
    const [gbpData, setGbpData] = useState([]);
    const [gbpDataList, setGbpDataList] = useState([]);
    const [gbpFullData, setGbpFullData] = useState();
    const [selectedOption, setSelectedOption] = useState("all");
    useEffect(() => {
        Listing();
        console.log("llllllllll", formattedDates)
    }, [formattedDates])
    const Listing = () => {
        const postData = {
            "functionName": "gbpSearches",
            "userId": window.sessionStorage.getItem("userId"),
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "startDate": formattedDates[0],
            "endDate": formattedDates[1]
        }
        backendDashboardApi(postData).then((data) => {
            console.log("gbp", data)
            if (data.success === "1") {
                setGbpFullData(data.result)
                setGbpData(data.result.all)
                setGbpDataList(data.result.gbpData)
            } else {
                setGbpData([])
                setGbpDataList([])
            }
        });
    };
    const radioClick = (filterKey) => {
        setSelectedOption(filterKey);
        if (gbpFullData && gbpFullData[filterKey]) {
            setGbpData(gbpFullData[filterKey]);
        }
    }



    return (
        <div className="db-cards-40 gbp-searches" style={{ marginRight: window.sessionStorage.getItem("roleId") === "12" ? 0 : "15px" }}>
            <div className="card" style={{ marginTop: window.sessionStorage.getItem("roleId") === "12" ? 0 : "5px", padding: '8px 15px' }}>
                <div className="card-body p-0 pb-2">
                    <div class="db-cards-top  all-region-head" style={{ marginBottom: window.sessionStorage.getItem("roleId") === "12" ? 0 : "20px" }}>
                        <h5 class="card-title card-title-small mb-0">GBP Searches</h5>
                        <span class="db-cad-top-units-blue ">
                            <div className="form-check mb-0 pl-0">
                                <div className="custom-control custom-radio" onClick={() => radioClick("all")}>
                                    <input type="radio" id="flexRadioDefault11" name="flexRadioDefaultSearches" className="custom-control-input" checked={selectedOption === "all"}
                                    />
                                    <label className="custom-control-label" for="flexRadioDefault11">All</label>
                                </div>
                            </div>
                            <div className="form-check mb-0 pl-0">
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="flexRadioDefault12" name="flexRadioDefaultSearches" className="custom-control-input" onClick={() => radioClick("maps")}
                                    />
                                    <label className="custom-control-label" for="flexRadioDefault12">Maps</label>
                                </div>
                            </div>
                            <div className="form-check mb-0 pl-0">
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="flexRadioDefault13" name="flexRadioDefaultSearches" className="custom-control-input" onClick={() => radioClick("searches")}
                                    />
                                    <label className="custom-control-label" for="flexRadioDefault13">Searches</label>
                                </div>
                            </div>
                        </span>
                    </div>

                    
                    <div className='db-cards-top mb-0 db-cards-check align-items-start'>
                        <div className="db-height w-100">
                            <div className="progress mt-4">
                                <div class="progress-bar" role="progressbar" aria-valuenow={gbpData && gbpData.desktopPercentage} aria-valuemin="0" aria-valuemax="100" style={{ width: (gbpData && gbpData.desktopPercentage) + '%' }}>
                                    <div className='progress-view'><span>Desktop</span><span>{gbpData && gbpData.desktopPercentage + '%'}</span></div>
                                </div>
                            </div>
                            <div className="progress mt-3">
                                <div class="progress-bar" role="progressbar" aria-valuenow={gbpData && gbpData.desktopPercentage} aria-valuemin="0" aria-valuemax="100" style={{ width: (gbpData && gbpData.mobilePercentage) + '%' }}>
                                    <div className='progress-view'><span>Mobile</span><span>{gbpData && gbpData.mobilePercentage + '%'}</span></div>
                                </div>
                            </div>
                            <div className='row mt-4 db-row-col-common '>
                                <div className='col-md-6 db-row-col-first mb-0'>
                                    <div class="card bg-white shadow-none my-0">
                                        <div className='db-cards-top an-selectBy'>
                                            <h5 class="db-card-title">GBP Visibility</h5>
                                            <span className='db-right-number'>{gbpDataList && gbpDataList.gbpVisibility}<span className='db-percentage'>(%)</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 db-row-col-second mb-0'>
                                    <div class="card bg-white shadow-none my-0">
                                        <div className='db-cards-top an-selectBy'>
                                            <h5 class="db-card-title">GBP Accuracy</h5>
                                            <span className='db-right-number'>{gbpDataList && gbpDataList.gbpAccuracy}<span className='db-percentage'>(%)</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-white rounded db-row-col-common'>
                                <div className='row'>
                                    <div className='col-md-6  db-row-col-first mb-0'>
                                        <div class="card bg-white shadow-none my-0">
                                            <div className='db-cards-top an-selectBy'>
                                                <h5 class="db-card-title">Reviews </h5>
                                                <span className='db-right-number'>{gbpDataList && gbpDataList.reviews}{gbpDataList && gbpDataList.reviewsSymbol}
                                                <span className='db-percentage db-percenteage-text'>(<span className='text-orange'>{gbpDataList && gbpDataList.averageRating}</span>)</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 db-row-col-second mb-0'>
                                        <div class="card bg-white shadow-none my-0">
                                            <div className='db-cards-top an-selectBy'>
                                                <h5 class="db-card-title">Response Rate</h5>
                                                <span className='db-right-number'>{gbpDataList && gbpDataList.responseRate}<span className='db-percentage'>(%)</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-white rounded db-row-col-common'>
                                <div className='row'>
                                    <div className='col-md-6  db-row-col-first mb-0'>
                                        <div class="card bg-white shadow-none my-0">
                                            <div className='db-cards-top an-selectBy pr-0'>
                                                <h5 class="db-card-title">Total Questions </h5>
                                                <span className='db-right-number'>{gbpDataList && gbpDataList.totalQuestions}{gbpDataList && gbpDataList.totalQuestionsSymbol}
                                                {/* <span className='db-percentage'>()</span> */}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 db-row-col-second mb-0'>
                                        <div class="card bg-white shadow-none my-0">
                                            <div className='db-cards-top an-selectBy'>
                                                <h5 class="db-card-title">Answer Rate</h5>
                                                <span className='db-right-number'>{gbpDataList && gbpDataList.answerRate}<span className='db-percentage'>(%)</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GbpSearches;