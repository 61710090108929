import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { backendApi, apiCallingPost } from "../apiService";
const BudgetSettingDetails = (props) => {
    console.log("pppppppppppppppppppppppppppp", props);
    const [activeIndex, setActiveIndex] = useState(0);
    const [labelText, setLabelText] = useState("Zone");
    const [budgetData, setBudgetData] = useState("");
    const [budgetAmount, setBudgetAmount] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [maxAmount, setMaxAmount] = useState("");
    const [organizationDivisionMasteId, setOrganizationDivisionMasteId] = useState("");
    const [accountBudgetMasterId, setAccountBudgetMasterId] = useState("");
    const [headerList, setHeaderList] = useState([])
    const [divisionDetailsData, setdivisionDetailsData] = useState(props.budgetDetails.divisionDetails)
    // const [maxAmounts, setMaxAmounts] = useState([]);

    const [maxAmounts, setMaxAmounts] = useState({ [`maxAmounts0`]: null });

    const handleClick = (index, data) => {
        setActiveIndex(index);
        setLabelText(data);
    };
    useEffect(() => {
        console.log("............................", props);
        // setBudgetData(props.budgetDetails)
        setBudgetAmount(props.budgetDetails.totalBudget);
        setStartDate(props.budgetDetails.date);
        setEndDate(props.budgetDetails.expiryDate);
        setAccountBudgetMasterId(props.budgetDetails.accountBudgetMasterId);
        // setMaxAmounts(props.budgetDetails.divisionDetails.maxAmount);
        console.log("............................", props.budgetDetails.divisionDetails);
        console.log(".......typeof.....................", typeof props.budgetDetails.divisionDetails);
        if (props.budgetDetails.divisionDetails) {
            console.log("..............ifffffffff..............");
            props.budgetDetails.divisionDetails.map((obj) => {
                console.log('setMaxAmounts...********.....', obj);
                setMaxAmounts((prevSelectedZone) => ({
                    ...prevSelectedZone,
                    [`maxAmounts${obj.accountBudgetMasterId}`]: obj.maxAmount,
                }));
            })
        }
        headerListingApi();
    }, []);

    const handleBudgetAmount = (e) => {
        setBudgetAmount(e.target.value);
    }
    const handleStartDate = (date) => {
        setStartDate(Date.parse(date));
    }
    const handleEndDate = (date) => {
        setEndDate(Date.parse(date));
    }

    const headerListingApi = () => {
        return new Promise((resolve, reject) => {
            const postData = {
                "functionName": "getOrganisationDivisionByAccount",
                "accountId": window.sessionStorage.getItem("switchingOrgId") === ""
                    ? window.sessionStorage.getItem("organizationId")
                    : window.sessionStorage.getItem("switchingOrgId"),
            };
            backendApi(postData).then((data) => {
                console.log("🚀yyyyyyyyyyyyyyyyy ~ backendApi ~ data:", data)
                if (data.success == 1) {
                    setHeaderList(data.result);
                    setOrganizationDivisionMasteId(data.organization_division_master_id)
                    setOrganizationDivisionMasteId(data.setOrganisationDivisionId)
                } else {
                    reject(new Error("Failed to fetch header listing")); // Reject the promise if fetching fails
                }
            }).catch(error => {
                reject(error); // Catch any errors and reject the promise
            });
        });
    };

    useEffect(() => {
        const activeHeaders = headerList.filter(data => data.is_active === true);
        const initialMaxAmounts = activeHeaders.map(() => "");
        //  setMaxAmounts(initialMaxAmounts);
    }, [headerList]);

    const handleMaxAmountChange = (value, index, organisationDivisionId) => {
        // const updatedMaxAmounts = [...maxAmounts];
        // updatedMaxAmounts[index] = value;
        //  setMaxAmounts(updatedMaxAmounts);

        setMaxAmounts({
            ...maxAmounts,
            [`maxAmounts${organisationDivisionId}`]: value,
        });

    };

    const handleSave = () => {
        debugger
        const activeHeaders = headerList.filter(data => data.is_active === true);
        console.log("🚀 ~ handleSave ~ activeHeaders:", activeHeaders)
        console.log("🚀 ~ handleSave ~ divisionDetailsData:", divisionDetailsData)
        let arrayData = []
        //item.accountBudgetMasterDivisionId = divisionDetailsData.filter((obj)=>(obj.accountDivisionDetailsId == item.organisationDivisionId))[0].accountBudgetMasterDivisionId
        const divisionDetails = activeHeaders.map((item, index) => {
            if (maxAmounts[`maxAmounts${item.organisationDivisionId}`]) {
                let o = divisionDetailsData.filter((obj) => (obj.accountDivisionDetailsId == item.organisationDivisionId))
                let a = {
                    "accountBudgetMasterDivisionId": o.length > 0 ? o[0].accountBudgetMasterDivisionId : "",
                    "accountDivisionDetailsId": item.organisationDivisionId,
                    "maxAmount": parseFloat(maxAmounts[`maxAmounts${item.organisationDivisionId}`]) || 0
                }
                arrayData.push(a)
            }
        });

        // const divisionDetails = divisionDetailsData.map((item, index) => ({
        //     "accountBudgetMasterDivisionId": item.accountBudgetMasterDivisionId,
        //     "accountDivisionDetailsId": item.accountDivisionDetailsId,
        //     "maxAmount": parseFloat(maxAmounts[`maxAmounts${item.accountDivisionDetailsId}`]) || 0
        // }));


        const postData = {
            "functionName": "accountBudgetMasterSave",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "accountBudgetMasterId": accountBudgetMasterId,
            "totalBudget": budgetAmount,
            "date": startDate,
            "expiryDate": endDate,
            "isActive": true,
            "createdOn": new Date().getTime(),
            "createdBy": window.sessionStorage.getItem("userId"),
            "modifiedOn": "",
            "modifiedBy": "",
            "divisionDetails": arrayData
        }
        console.log("99999999999999999999", postData);
        apiCallingPost(postData).then((data) => {
            debugger
            if (data.success === "1") {
                alert("Saved Successfully");
                props.handleBudgetMasterList();
                props.handleViewBudgetDetailfalse();
            } else {
                alert("Something Went Wrong")
            }

        });
    }


    const handleCancel = () => {
        props.handleViewBudgetDetailfalse();
    }


    return (
        <div className="col-md-12 py-2">
            <div className="row mt-4">
                <div class="col-md-12 col-lg-12  mb-4">
                    <div className="col-md-12 col-lg-11 col-xl-7 d-flex align-items-center flex-wrap px-0 border-bottom-col">
                        {/* <p className=" lead-text div-fund-text mb-0 px-0">Total Fund Allocated :  1,00,00,000</p> */}


                        <div class="col-lg-3 col-md-3 col-sm-4 col-9 pl-0">
                            <label className="m-0">
                                Budget Amount<sup style={{ color: "red" }}>*</sup>{" "}
                            </label>
                            <input type="text" class="form-control" placeholder="Budget Amount" value={budgetAmount} onChange={handleBudgetAmount} />
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-4 col-9 datefield">
                            <label className="m-0">
                                Start Date<sup style={{ color: "red" }}>*</sup>{" "}
                            </label>
                            <DatePicker
                                onChange={handleStartDate}
                                selected={startDate}
                                name="startDate"
                                showMonthDropdown
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Start Date"
                                className="form-control"
                            />
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-4 col-9 datefield">
                            <label className="m-0">
                                End Date<sup style={{ color: "red" }}>*</sup>{" "}
                            </label>
                            <DatePicker
                                onChange={handleEndDate}
                                selected={endDate}
                                name="entDate"
                                id="endDate"
                                showMonthDropdown
                                dateFormat="dd/MM/yyyy"
                                placeholderText="End Date"
                                className="form-control"
                            />
                        </div>

                    </div>
                </div>


                <div className="left-zone-div col-md-12 col-lg-6">
                    {/* <div className="row">
                        <div className="col-lg-5 col-md-5">

                        </div>
                        <div className="col-lg-1 col-md-1 d-none d-lg-block"></div>
                        <div className="col-lg-6 col-md-6">
                            <div className="row mb-2">
                                <div className="col-md-6 text-left">
                                    Label 1
                                </div>
                                <div className="col-md-6 text-left">
                                    Label 2
                                </div>
                            </div>
                        </div>
                    </div>
                    {headerList.filter((data) => data.is_active === true).map((item, headerIndex) =>
                        <>

                            <div className="row">
                                <div className="col-md-5">
                                    <label
                                        key={headerIndex}
                                        className="left-zone-subdiv"
                                    >
                                        {item.organisatonDivisionMasterName}
                                    </label>
                                </div>
                                <div className="col-lg-1 d-none d-lg-block"></div>
                                <div className="col-md-6">

                                    <div className="row">

                                        <div className="col-md-6">
        
                                            <input type="text" placeholder="Max" className="form-control-2" value={maxAmounts[`maxAmounts${item.organisationDivisionId}`]}
                                                onChange={(e) => handleMaxAmountChange(e.target.value, headerIndex, item.organisationDivisionId)} /></div>
                                        <div className="col-md-6">

                                            <input type="text" className="form-control-2" placeholder="Allocated" />
                                        </div>
                                    </div>
                                </div>


                            </div>


                        </>
                    )} */}
                    <div className="mt-2 text-right">
                        <button className="db-btn ml-auto" onClick={() => handleSave()}>Save</button>
                        <button className="db-btn ml-2" onClick={() => handleCancel()}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default BudgetSettingDetails;