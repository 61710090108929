import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
function MultiGraph(props) {
  useEffect(() => {
    console.log("qweqeqezzzzzzzzzzz", props)
  }, [])
 
  const seriesMulti = props.xArray
  const optionsMulti = {
    chart: {
      height: 350,
      type: 'line',
      // dropShadow: {
      //   enabled: true,
      //   color: '#000',
      //   top: 20,
      //   left: 7,
      //   blur: 5,
      //   opacity: 0.2
      // },
      toolbar: {
        show: false
      }
    },
    colors: ['blue', 'black', "red", "green"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: 'Analytics',
      align: 'left'
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      },
    },
    markers: {
      size: 1
    },
    xaxis: {
      categories: props.yArray,
      // title: {
      //   text: 'Month'
      // }
    },
    yaxis: {
      title: {
        text: 'Data Mode'
      },
      min: props.minValue,
      max: props.maxValue
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    }
  }

  return (
    <div className="treatmentProfileCover">
      <div className="treatmentPlanContent">
        {console.log(props.xArray, "prropp", props.yArray)}
        <Chart
          options={optionsMulti}
          series={seriesMulti}
          type="line"
          height={500}
        />
      </div>
    </div>
  );
}
export default MultiGraph;