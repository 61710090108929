import React, { Component } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../css/bootstrap.css";
import "../css/style.css";
import { Link } from "react-router-dom";
import Event from "../images/event.png";
import * as Icon from "react-feather";
import DatePicker from "react-datepicker";
import { useState } from "react";
import moment from "moment";
import { apiCallingPost, backendApi } from "../apiService";
import $ from "jquery";
import GooglePreview from "./googlePreview";
import AWS from "aws-sdk";
import { useEffect } from "react";
const s3ImageUrl =
  "http://tagtree.s3-website.ap-south-1.amazonaws.com/production/images";
var s3Region;
var s3bucketAccessKey;
var s3bucketSecretKey;
var s3Bucket;
var s3BucketMainFolder;
var S3BucketImagesFolder = "images";
var bucketParams;
var s3;
var timeList = [
  "0:00",
  "0:30",
  "1:00",
  "1:30",
  "2:00",
  "2:30",
  "3:00",
  "3:30",
  "4:00",
  "4:30",
  "5:00",
  "5:30",
  "6:00",
  "6:30",
  "7:00",
  "7:30",
  "8:00",
  "8:30",
  "9:00",
  "9:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];
var regex = new RegExp(":", "g");
function PostEvent() {
  const s3Region = "us-east-1",
    s3bucketAccessKey = process.env.REACT_APP_TAGTREE_S3_ACCESS_KEY,
    s3bucketSecretKey = process.env.REACT_APP_TAGTREE_S3_SECRET_KEY,
    s3Bucket = "tagtree",
    s3BucketMainFolder = "production",
    S3BucketImagesFolder = "images",
    bucketParams = {
      Bucket: s3Bucket,
    };
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState(new Date());
  const [endTime, setEndTime] = useState("");
  const [eventType, setEventType] = useState("EVENT");
  const [imageUrl, setImageUrl] = useState([]);
  const [eventTitle, setEventTitle] = useState();
  const [apiRequest, setApiRequest] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [displayImages, setDisplayImages] = useState([]);
  const [tempImagesList, setTempImagesList] = useState([]);
  const [callToActionButton, setCallToActionButton] = useState("0");
  const [callToActionUrl, setCallToActionUrl] = useState("");
  const [s3Credentials, setS3Credentials] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  // const [eventType, setEventType] = useState("EVENT")
  const changeDescriptionField = (event) => {
    if (event.target.value.length < 1500) {
      setDescriptionLength(event.target.value.length);
      setDescription(
        event.target.value !== ""
          ? event.target.value
          : $("#descriptionField").val()
      );
    } else {
      alert("Description cannot have more than 1500 characters");
    }
  };
  const getS3Credentials = () => {
    AWS.config.update({
      accessKeyId: s3bucketAccessKey,
      secretAccessKey: s3bucketSecretKey,
      region: s3Region,
    });
    s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: bucketParams,
    });
  };
  const navigateToNextPage = () => {
    console.log();
    navigate("/googlepreview", {
      state: {
        title: eventTitle,
        ...(location.state.PostEditField.length !== 0
          ? { bannerImages: [tempImagesList[0]] }
          : {
              bannerImages: imageUrl.length > 0 ? [imageUrl[0]] : [],
            }),
        summary: $("#descriptionField").val(),
        startDate: startDate,
        startTime: startTime === "" ? "00:00" : startTime,
        endDate: endDate,
        endTime: endTime === "" ? "23:59" : endTime,
        eventType: "EVENT",
        callToAction: {
          actionType: callToActionButton,
          url: callToActionUrl,
        },
        completeLocationData: location.state.completeLocationData,
        selectedLocations: location.state.selectedLocations,
        fromClaimData: location.state?.fromClaimData,
        PostEditField:
          location.state.PostEditField.length !== 0
            ? location.state.PostEditField
            : [],
        // startDate_year: parseInt(moment(startDate).get("year")),
        // startDate_month: parseInt(moment(startDate).get("month") + 1),
        // startDate_day: parseInt(moment(startDate).get("date")),
        // startTime_hours: parseInt(startTime.split(":")[0]),
        // startTime_minutes: parseInt(startTime.split(":")[1]),
        // endDate_year: parseInt(moment(endDate).get("year")),
        // endDate_month: parseInt(moment(endDate).get("month") + 1),
        // endDate_day: parseInt(moment(endDate).get("date")),
        // endTime_hours: parseInt(endTime.split(":")[0]),
        // endTime_minutes: parseInt(endTime.split(":")[1]),
      },
    });
  };
  const handleImageUpload = (image) => {
    $(".loder_div").attr("hidden", false);
    let s3BucketMainFolder1 = s3BucketMainFolder + "/" + S3BucketImagesFolder;
    // var totalCount = image.length;
    // for (let i = 0; i < image.length; i++) {
    // console.log("sdk size", image[i].size);
    // console.log("image[i]image[i]image[i]", image[i]);
    if (location?.state?.PostEditField?.media?.length > 0) {
      navigateToNextPage();
      return false;
    }
    // let fileName = uuidv4() + image[i].name;
    //?uncomment above line for multiple images
    let fileName = uuidv4() + image.name;
    let fullFilePath = s3BucketMainFolder1 + "/" + fileName;
    // console.log("fullFilePath", fullFilePath);
    s3.upload(
      {
        Key: fullFilePath,
        Body: image,
        // Body: image[i],
        //?uncomment above line for multiple images
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          alert("There was an error uploading your image");
          $(".loder_div").attr("hidden", true);
          // console.log(err.message);
        } else if (data) {
          // totalCount = totalCount - 1;
          //?uncomment above line for multiple images
          // imageUrl.push({ url: data.Location, type: image[i].type });
          //?uncomment above line for multiple images
          imageUrl.push({ url: data.Location, type: image.type });
          // if (totalCount === 0) {
          //?uncomment above line for multiple images
          $(".loder_div").attr("hidden", true);
          navigateToNextPage();
          // }
          //?uncomment above line for multiple images
          // console.log("imageUrlimageUrlimageUrl", this.state.imageUrl);
          // this.setState({
          //   imageUrlListStatus: i + 1,
          // });
          // this.state.imageName.push(data.fileName);
          // this.setState({ imageUrl: data.Location, imageName: fileName });
          // console.log(data.Location);
          // $("#uploadedImage").attr("hidden", false);
          // $("#multipleImagesSlider").attr("hidden", false);

          return;
        }
      }
    );
    // }
  };
  const fileUploadHandler = (e) => {
    let image = e.target.files;
    for (let i = 0; i < image.length; i++) {
      if (image[i].size < 10240) {
        alert("Files must be greater than 10KB");
        return;
      } else if (image[i].size > 4900000) {
        alert("Files must be less than 5MB");
        return;
      } else {
        // displayImages.push(window.webkitURL.createObjectURL(image[i]));
        // tempImagesList.push(image[i]);
        setDisplayImages([window.webkitURL.createObjectURL(e.target.files[0])]);
        setTempImagesList([e.target.files[0]]);
        setImageUrl([]);
      }
    }
    setShowPreview(true);
    // if (tempImagesList.length > 0) setShowPreview(true);
  };
  // const showPreviewPopop = () => {
  //   return (
  //     <div className="module_popup" id="previewPost">
  //       <div className="module_innerpopup">
  //         <div className="module_close">
  //           <Icon.XCircle />
  //         </div>
  //         <GooglePreview previewProperties={apiRequest} />
  //       </div>
  //     </div>
  //   );
  // };
  useEffect(() => {
    setEventTitle(location.state?.title ? location.state?.title : "");
    setStartDate(
      location.state?.startDate ? location.state?.startDate : new Date()
    );
    setDescription(location.state?.summary ? location.state?.summary : "");
    setStartTime(location.state?.startTime ? location.state?.startTime : "");
    setEndTime(location.state?.endTime ? location.state.endTime : "");
    setEndDate(location.state?.endDate ? location.state?.endDate : new Date());
    setCallToActionButton(
      location.state?.callToAction?.actionType
        ? location.state?.callToAction.actionType
        : "0"
    );
    // $("#descriptionField").val(
    //   location.state?.summary ? location.state?.summary : ""
    // );
    setCallToActionUrl(
      location.state?.callToAction?.url ? location.state?.callToAction?.url : ""
    );
    if (location.state?.callToAction?.actionType) {
      $("#button_URL").attr("hidden", false);
    }
    // if (location.state.bannerImages?.length) {
    //   displayImages.push(location.state.bannerImages?.url);
    //   tempImagesList.push(location.state.bannerImages?.url);
    //   imageUrl.push({ url: location.state.bannerImages?.url, type: "PHOTO" });
    if (location.state?.bannerImages?.length > 0) {
      setShowPreview(true);
      setDisplayImages([location.state.bannerImages[0].url]);
      setTempImagesList([location.state.bannerImages[0].url]);
      setImageUrl([
        {
          url: location.state.bannerImages[0].url,
          type: location.state.bannerImages[0].type,
        },
      ]);
      // }
      //  setDisplayImages(location.state.bannerImages?.url?location.state.bannerImages?.url:"");
      // setTempImagesList([e.target.files[0]]);
      // setImageUrl([]);
    }

    getS3Credentials();
    // console.log("locationlocationfffffffffffffffffff", location);
    location.state.PostEditField.length !== 0 &&
      getEventEditDetails(location.state.PostEditField);
  }, []);
  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  const getEventEditDetails = (params) => {
    let startDate = params.event.schedule.startDate;
    let startTime;
    if (Object.keys(params.event.schedule.startTime).length !== 0) {
      startTime = params.event.schedule.startTime;
      setStartTime(startTime.hours + ":" + startTime.minutes);
    } else {
      setStartTime("00:00");
    }

    let endDate = params.event.schedule.endDate;
    let endTime = params.event.schedule.endTime;
    setEventTitle(params.event.title);
    setEndTime(endTime.hours + ":" + endTime.minutes);
    setStartDate(new Date(startDate.year, startDate.month - 1, startDate.day));
    setEndDate(new Date(endDate.year, endDate.month - 1, endDate.day));
    if (params?.callToAction) {
      setCallToActionButton(params?.callToAction.actionType);
      setCallToActionUrl(params?.callToAction.url);
      $("#button_URL").attr("hidden", false);
    }

    // $("#descriptionField").val(params.summary);
    setDescription(params?.summary);
    // $("#callToActionButtons").val(params.callToAction.actionType);
    if (params.media) {
      setShowPreview(true);
      // params.media.forEach((obj) => {
      //   displayImages.push(obj.googleUrl);
      //   tempImagesList.push(obj.googleUrl);
      //   imageUrl.push({ url: obj.googleUrl, type: "PHOTO" });
      // });
      $("#deleteImageIconEvent0").attr("hidden", true);
      setDisplayImages([params.media[0].googleUrl]);
      setTempImagesList([params.media[0].googleUrl]);
      setImageUrl([
        {
          url: params.media[0].googleUrl,
          type: "PHOTO",
        },
      ]);
    }
  };
  const validatePreviewParams = () => {
    if (eventTitle === "") {
      alert("Event title Cannot be empty");
      return false;
    } else if (tempImagesList.length !== 0 && displayImages.length !== 0) {
      handleImageUpload(tempImagesList[0]);
    } else {
      navigateToNextPage();
    }
    // if (eventTitle === "" || $("#eventTitle").val() === "") {
    //   alert("Event Title is required to post.");
    // } else if (tempImagesList.length !== 0 && imageUrl.length === 0) {
    //   alert("Upload Images First!!");
    // } else {
    //   handleImageUpload(tempImagesList);
    // }
  };
  const deleteImages = (i) => {
    displayImages.splice(i, 1);
    setDisplayImages(displayImages);
    displayImages.length === 0 && setShowPreview(false);
    displayImages.length === 0 && setDisplayImages([]);
  };
  return (
    <div className="row">
      {/* <div className="tag_info_contentlocation ">
        <div className="right_google_popup" style={{ margin: "0px" }}>
          <label>
            <Icon.Camera />
            Add photos
            <input
              type="file"
              id="imageUpload"
              onChange={(e) => {
                fileUploadHandler(e);
                e.target.value = "";
              }}
            />
          </label>
        </div>
      </div>

      <div className="tag_photoSave">
        <button
          type="submit"
          className="btn btn-success "
          onClick={() => handleImageUpload(tempImagesList)}
        >
          Upload
        </button>
      </div> */}

      <div className="tag_info_contentlocation ">
        <div className="right_google_popup" style={{ margin: "0px" }}>
          <label>
            <Icon.Camera />
            Add photos
            <input
              type="file"
              id="imageUpload"
              onChange={(e) => {
                fileUploadHandler(e);
                e.target.value = "";
              }}
            />
          </label>
        </div>
      </div>

      {/* {showPreview === true && (
          <div id="displayImagesList">
            {displayImages.map((data) => (
              <img src={data} />
            ))}
          </div>
        )} */}

      {/*  ==================== */}
      {showPreview === true && (
        <div id="displayImagesList" className="tag_googledisplayImagesList">
          <div className="tag_google">
            <div className="row">
              {displayImages.map((data, i) => (
                <>
                  <div className=" tag_imgWrap">
                    <div className="multipleImagesSlider">
                      <img src={data} />
                      <span className="close_popup_imageUpload">
                        <Icon.X
                          id={"deleteImageIconEvent" + i}
                          onClick={() => {
                            deleteImages();
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      )}
      {/* ===================== */}
      <br />
      <div className="row mt-3 ">
        <div className="col-lg-3 col-md-6 tag_formCntrl">
          <label className="m-0">
            Enter Event Title<sup style={{ color: "red" }}>*</sup>{" "}
          </label>
          <input
            id="EventTitle"
            type="text"
            value={eventTitle}
            className="form-control"
            onChange={(e) => setEventTitle(e.target.value)}
          />
        </div>
        <div className="col-lg-2 col-md-6 tag_formCntrl ">
          <label className="m-0">
            From Date<sup style={{ color: "red" }}>*</sup>
          </label>
          {/* <div className="row m-0"> */}
          {/* <div style={{ width: "70%", marginRight: "2%" }}> */}
          <DatePicker
            onChange={(date) => setStartDate(date)}
            selected={startDate}
            name="startDate"
            minDate={new Date()}
            showMonthDropdown
            dateFormat="dd/MM/yyyy"
            placeholderText="From Date"
            className="form-control"
          />
          {/* </div> */}
          {/* <select
            name="startTime"
            id="startTime"
            className="form-control"
            onChange={(e) => setStartTime(e.target.value)}
            style={{ width: "27%" }}
          >
            <option value="">Select</option>
            {timeList.map((data) => (
              <option value={data}>{data}</option>
            ))}
          </select> */}
          {/* </div> */}
        </div>
        <div className="col-lg-2 col-md-6  tag_formCntrl">
          <label className="m-0">Start Time </label>
          <select
            name="startTime"
            id="startTime"
            value={startTime}
            className="form-control"
            onChange={(e) => setStartTime(e.target.value)}
          >
            <option value="">Select</option>
            {timeList.map((data) => (
              <option value={data}>{data}</option>
            ))}
          </select>
        </div>
        <div className="col-lg-2 col-md-6 tag_formCntrl">
          <label className="m-0">
            To Date<sup style={{ color: "red" }}>*</sup>
          </label>
          {/* <div className="row m-0"> */}
          {/* <div style={{ width: "70%", marginRight: "2%" }}> */}
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            name="startDate"
            id="endDate"
            minDate={new Date()}
            showMonthDropdown
            dateFormat="dd/MM/yyyy"
            className="form-control"
            placeholderText="To Date"
          />
          {/* </div> */}
          {/* <select
            name="endTime"
            id="endTime"
            style={{ width: "27%" }}
            className="form-control"
            onChange={(e) => setEndTime(e.target.value)}
          >
            <option value="">Select</option>
            {timeList.map((data) => (
              <option value={data}>{data}</option>
            ))}
          </select> */}
          {/* </div> */}
        </div>
        <div className="col-lg-2 col-md-6   tag_formCntrl">
          <label className="m-0">End Time </label>
          <select
            name="endTime"
            id="endTime"
            value={endTime}
            className="form-control"
            onChange={(e) => setEndTime(e.target.value)}
          >
            <option value="">Select</option>
            {timeList.map((data) => {
              let timeStr1 = moment().format("HH:mm:ss"),
                timeStr2 = data + ":00",
                finalTime = "";

              if (
                parseInt(timeStr1.replace(regex, ""), 10) <
                parseInt(timeStr2.replace(regex, ""), 10)
              ) {
                let tempTime = timeStr2.split(":");
                finalTime = tempTime[0] + ":" + tempTime[1];
              }
              return (
                <>
                  {finalTime !== "" && (
                    <option value={finalTime}>{finalTime}</option>
                  )}
                </>
              );
            })}
          </select>
        </div>
        <div className="col-lg-11 col-md-11 col-sm-12 tag_formCntrl  ">
          <label className="m-0">Enter Details (Optional)</label>
          <textarea
            id="descriptionField"
            type="text"
            className="form-control  mb-0"
            defaultValue={description}
            cols="15"
            onChange={(obj) => changeDescriptionField(obj)}
          />
          <div className="form_google_span">
            <span> {descriptionLength}/ 1500</span>
          </div>
        </div>
        <div className="col-lg-3 col-md-6  tag_formCntrl">
          <label className="m-0">Add a button (Optional)</label>
          <select
            id="callToActionButtons"
            className="form-control"
            placeholder="Select"
            value={callToActionButton}
            onChange={(e) => {
              if (e.target.value == "0" || e.target.value === "CALL") {
                $("#button_URL").attr("hidden", true);
              } else if (e.target.value !== "0") {
                $("#button_URL").attr("hidden", false);
              }
              setCallToActionButton(e.target.value);
            }}
          >
            <option value="0">None</option>
            <option value="BOOK">Book</option>
            <option value="ORDER">Order Online</option>
            <option value="SIGN_UP">Sign Up</option>
            <option value="LEARN_MORE">Learn More</option>
            <option value="SHOP">Buy</option>
            <option value="CALL">Call</option>
            {/* <option value="Call">Call</option> */}
          </select>
        </div>

        <div
          id="button_URL"
          hidden
          className="col-lg-4 col-md-12   tag_formCntrl"
        >
          <label className="m-0"> Enter URL</label>
          <input
            type="text"
            className="form-control"
            value={callToActionUrl}
            placeholder="Enter URL"
            onChange={(e) => setCallToActionUrl(e.target.value)}
          />
        </div>
        <div className="col-lg-11 col-md-11  ">
          <div className="button-wrapper pb-2 mb-3">
            <button
              type="button"
              className="btn-save ml-3 "
              onClick={() => validatePreviewParams()}
            >
              Upload & Preview
            </button>
            {/* <div className="tag_googlePhotoSave">
              <button
                type="submit"
                className="btn btn-success"
                onClick={() => handleImageUpload(tempImagesList)}
              >
                Upload
              </button>
            </div> */}
            {/* <Link
              to="/googlepreview"
              state={{
                title: eventTitle,
                ...(location.state.PostEditField.length !== 0
                  ? { bannerImages: tempImagesList }
                  : { bannerImages: imageUrl.length > 0 ? imageUrl : [] }),
                summary: $("#descriptionField").val(),
                startDate: startDate,
                startTime: startTime === "" ? "00:00" : startTime,
                endDate: endDate,
                endTime: endTime === "" ? "23:59" : endTime,
                eventType: "EVENT",
                callToAction: {
                  actionType: callToActionButton,
                  url: callToActionUrl,
                },
                completeLocationData: location.state.completeLocationData,
                selectedLocations: location.state.selectedLocations,
                PostEditField:
                  location.state.PostEditField.length !== 0
                    ? location.state.PostEditField
                    : [],
                // startDate_year: parseInt(moment(startDate).get("year")),
                // startDate_month: parseInt(moment(startDate).get("month") + 1),
                // startDate_day: parseInt(moment(startDate).get("date")),
                // startTime_hours: parseInt(startTime.split(":")[0]),
                // startTime_minutes: parseInt(startTime.split(":")[1]),
                // endDate_year: parseInt(moment(endDate).get("year")),
                // endDate_month: parseInt(moment(endDate).get("month") + 1),
                // endDate_day: parseInt(moment(endDate).get("date")),
                // endTime_hours: parseInt(endTime.split(":")[0]),
                // endTime_minutes: parseInt(endTime.split(":")[1]),
              }}
              id="googlePreviewLink"
            >
              <button
                type="button"
                disabled={$("#eventTitle").val() === "" ? true : false}
                className="btn-save ml-3 "
              >
                Preview
              </button>
            </Link> */}
          </div>

          {/* {showPreview === true && showPreviewPopop()} */}
          {/* <button type="button" className="btn btn-save tag_submitBtn">
            Submit
          </button> */}
        </div>
      </div>
      <div className="loder_div" hidden></div>
    </div>
  );
}

export default PostEvent;
