import React, { useEffect, useState } from 'react'
import Leads from "../../images/leads.png"
import RetailImg from "../../images/retail.png"
import RightArrow from "../../images/arrow-orange.png"
import ArrowLeft from "../../images/arrow_L&R.png"
import Line from "../../images/Line 3.png"
function Retail() {
    const [currentTable, setCurrentTable] = useState(1); // 1: first table, 2: second table, 3: third table

    const handleRowClick = (ao) => {
        if (ao === 'Mumbai AO') {
            setCurrentTable(2);
        }
    };

    const handleLocationClick = () => {
        setCurrentTable(3);
    };

    const handleBackClick = () => {
        if (currentTable === 3) {
            setCurrentTable(2);
        } else if (currentTable === 2) {
            setCurrentTable(1);
        }
    };

    return (
        <div className='section-wrapper'>
            <div className="retail-section">
                <div className="retail-section-left">
                    <img src={Leads} alt="" className='img-fluid' />
                    <span className='retails-headText'>Retail(s) - 800 Units </span>
                </div>
                <div className="retail-section-right">
                    <span className="retail-section-li">CPR (INR) - 7 k</span>
                    <span className="retail-section-li">Booking : Retail - 4 %</span>
                    <span className="retail-section-li">Lead : Retail - 2 %</span>
                </div>
            </div>

            {currentTable === 1 && (
                <>

                    <div className="top-performing-section">
                        <div className="top-performing-head">
                            <img src={RetailImg} alt="" className='img-fluid' />
                            <span className='top-performing-headText'>Top Performing AOs </span><span className="top-performing-greyext">(Top 5)</span>
                        </div>
                        <div className="top-performing-tbl">

                            <div className="divTable">
                                <div className="divTableHeading">
                                    <div class="divTableRow">
                                        <div className="divTableHead">AO</div>
                                        <div className="divTableHead">Leads (Unit)</div>
                                        <div className="divTableHead">Enquiry (%)</div>
                                        <div className="divTableHead">Booking (%)</div>
                                        <div className="divTableHead">Retail (%)</div>
                                        <div className="divTableHead">Sold (Unit)</div>
                                    </div>
                                </div>
                                <div className="divTableBody">
                                    <div className="divTableRow">
                                        <div className="divTableCell position-relative" data-label="AO">Mumbai AO <img src={RightArrow} className="img-fluid img-arrow-Ao cursor-pointer" alt="" onClick={() => handleRowClick('Mumbai AO')} /></div>
                                        <div className="divTableCell bg-white" data-label="Leads (Unit)">13,424</div>
                                        <div className="divTableCell bg-white" data-label="Enquiry (%)">20</div>
                                        <div className="divTableCell bg-white" data-label="Booking (%)">14</div>
                                        <div className="divTableCell bg-white" data-label="Retail (%)">2</div>
                                        <div className="divTableCell bg-white" data-label="Sold (Unit)">321</div>
                                    </div>
                                    <div className="divTableRow">
                                        <div className="divTableCell position-relative" data-label="AO">Pune AO <img src={RightArrow} className="img-fluid img-arrow-Ao cursor-pointer" alt="" /></div>
                                        <div className="divTableCell bg-white" data-label="Leads (Unit)">11,823</div>
                                        <div className="divTableCell bg-white" data-label="Enquiry (%)">20</div>
                                        <div className="divTableCell bg-white" data-label="Booking (%)">14</div>
                                        <div className="divTableCell bg-white" data-label="Retail (%)">2</div>
                                        <div className="divTableCell bg-white" data-label="Sold (Unit)">221</div>
                                    </div>
                                    <div className="divTableRow">
                                        <div className="divTableCell position-relative" data-label="AO">Jaipur AO <img src={RightArrow} className="img-fluid img-arrow-Ao cursor-pointer" alt="" /></div>
                                        <div className="divTableCell bg-white" data-label="Leads (Unit)">9,876</div>
                                        <div className="divTableCell bg-white" data-label="Enquiry (%)">20</div>
                                        <div className="divTableCell bg-white" data-label="Booking (%)">14</div>
                                        <div className="divTableCell bg-white" data-label="Retail (%)">2</div>
                                        <div className="divTableCell bg-white" data-label="Sold (Unit)">102</div>
                                    </div>
                                    <div className="divTableRow">
                                        <div className="divTableCell position-relative" data-label="AO">Cochin AO <img src={RightArrow} className="img-fluid img-arrow-Ao cursor-pointer" alt="" /></div>
                                        <div className="divTableCell bg-white" data-label="Leads (Unit)">7,676</div>
                                        <div className="divTableCell bg-white" data-label="Enquiry (%)">20</div>
                                        <div className="divTableCell bg-white" data-label="Booking (%)">14</div>
                                        <div className="divTableCell bg-white" data-label="Retail (%)">2</div>
                                        <div className="divTableCell bg-white" data-label="Sold (Unit)">97</div>
                                    </div>
                                    <div className="divTableRow">
                                        <div className="divTableCell position-relative" data-label="AO">Chennai AO <img src={RightArrow} className="img-fluid img-arrow-Ao cursor-pointer" alt="" /></div>
                                        <div className="divTableCell bg-white" data-label="Leads (Unit)">7,123</div>
                                        <div className="divTableCell bg-white" data-label="Enquiry (%)">20</div>
                                        <div className="divTableCell bg-white" data-label="Booking (%)">14</div>
                                        <div className="divTableCell bg-white" data-label="Retail (%)">2</div>
                                        <div className="divTableCell bg-white" data-label="Sold (Unit)">76</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {currentTable === 2 && (
                <>

                    <div className="top-performing-section">
                        <div className="top-performing-head">
                            <img src={ArrowLeft} alt="" className='img-fluid cursor-pointer' onClick={handleBackClick} />
                            <img src={Line} alt="" className='img-fluid' />
                            <img src={RetailImg} alt="" className='img-fluid' />
                            <span className='top-performing-headText'>Top Performing AOs Dealers </span><span className="top-performing-greyext">(Top 5)</span>
                        </div>
                        <div className="top-performing-tbl">
                            <div className="divTable">
                                <div className="divTableHeading">
                                    <div class="divTableRow">
                                        <div className="divTableHead">AO</div>
                                        <div className="divTableHead">Leads (Unit)</div>
                                        <div className="divTableHead">Enquiry (%)</div>
                                        <div className="divTableHead">Booking (%)</div>
                                        <div className="divTableHead">Retail (%)</div>
                                        <div className="divTableHead">Sold (Unit)</div>
                                    </div>
                                </div>
                                <div className="divTableBody">
                                    <div className="divTableRow">
                                        <div className="divTableCell position-relative" data-label="AO">Mahindra Naik Motors <img src={RightArrow} className="img-fluid img-arrow-Ao cursor-pointer" alt="" onClick={() => handleLocationClick()} /></div>
                                        <div className="divTableCell bg-white" data-label="Leads (Unit)">3952</div>
                                        <div className="divTableCell bg-white" data-label="Enquiry (%)">20</div>
                                        <div className="divTableCell bg-white" data-label="Booking (%)">14</div>
                                        <div className="divTableCell bg-white" data-label="Retail (%)">2</div>
                                        <div className="divTableCell bg-white" data-label="Sold (Unit)">321</div>
                                    </div>
                                    <div className="divTableRow">
                                        <div className="divTableCell position-relative" data-label="AO">Mahindra NBS International <img src={RightArrow} className="img-fluid img-arrow-Ao cursor-pointer" alt="" /></div>
                                        <div className="divTableCell bg-white" data-label="Leads (Unit)">2569</div>
                                        <div className="divTableCell bg-white" data-label="Enquiry (%)">20</div>
                                        <div className="divTableCell bg-white" data-label="Booking (%)">14</div>
                                        <div className="divTableCell bg-white" data-label="Retail (%)">2</div>
                                        <div className="divTableCell bg-white" data-label="Sold (Unit)">221</div>
                                    </div>
                                    <div className="divTableRow">
                                        <div className="divTableCell position-relative" data-label="AO">Mahindra Satpuda Automobiles <img src={RightArrow} className="img-fluid img-arrow-Ao cursor-pointer" alt="" /></div>
                                        <div className="divTableCell bg-white" data-label="Leads (Unit)">2435</div>
                                        <div className="divTableCell bg-white" data-label="Enquiry (%)">20</div>
                                        <div className="divTableCell bg-white" data-label="Booking (%)">14</div>
                                        <div className="divTableCell bg-white" data-label="Retail (%)">2</div>
                                        <div className="divTableCell bg-white" data-label="Sold (Unit)">102</div>
                                    </div>
                                    <div className="divTableRow">
                                        <div className="divTableCell position-relative" data-label="AO">Mahindra Bhavna Motors<img src={RightArrow} className="img-fluid img-arrow-Ao cursor-pointer" alt="" /></div>
                                        <div className="divTableCell bg-white" data-label="Leads (Unit)">2169</div>
                                        <div className="divTableCell bg-white" data-label="Enquiry (%)">20</div>
                                        <div className="divTableCell bg-white" data-label="Booking (%)">14</div>
                                        <div className="divTableCell bg-white" data-label="Retail (%)">2</div>
                                        <div className="divTableCell bg-white" data-label="Sold (Unit)">97</div>
                                    </div>
                                    <div className="divTableRow">
                                        <div className="divTableCell position-relative" data-label="AO">Mahindra Randhawa Motors<img src={RightArrow} className="img-fluid img-arrow-Ao cursor-pointer" alt="" /></div>
                                        <div className="divTableCell bg-white" data-label="Leads (Unit)">2299</div>
                                        <div className="divTableCell bg-white" data-label="Enquiry (%)">20</div>
                                        <div className="divTableCell bg-white" data-label="Booking (%)">14</div>
                                        <div className="divTableCell bg-white" data-label="Retail (%)">2</div>
                                        <div className="divTableCell bg-white" data-label="Sold (Unit)">76</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {currentTable === 3 && (
                <>

                    <div className="top-performing-section">
                        <div className="top-performing-head">
                            <img src={ArrowLeft} alt="" className='img-fluid cursor-pointer' onClick={handleBackClick} />
                            <img src={Line} alt="" className='img-fluid' />
                            <img src={RetailImg} alt="" className='img-fluid' />
                            <span className='top-performing-headText'>Top Performing AOs Dealer Locations </span><span className="top-performing-greyext">(Top 5)</span>
                        </div>
                        <div className="top-performing-tbl">
                            <div className="divTable">
                                <div className="divTableHeading">
                                    <div class="divTableRow">
                                        <div className="divTableHead">AO</div>
                                        <div className="divTableHead">Leads (Unit)</div>
                                        <div className="divTableHead">Enquiry (%)</div>
                                        <div className="divTableHead">Booking (%)</div>
                                        <div className="divTableHead">Retail (%)</div>
                                        <div className="divTableHead">Sold (Unit)</div>
                                    </div>
                                </div>
                                <div className="divTableBody">
                                    <div className="divTableRow">
                                        <div className="divTableCell position-relative" data-label="AO">Mahindra Naik Motors Kankavli </div>
                                        <div className="divTableCell bg-white" data-label="Leads (Unit)">1124</div>
                                        <div className="divTableCell bg-white" data-label="Enquiry (%)">20</div>
                                        <div className="divTableCell bg-white" data-label="Booking (%)">14</div>
                                        <div className="divTableCell bg-white" data-label="Retail (%)">2</div>
                                        <div className="divTableCell bg-white" data-label="Sold (Unit)">321</div>
                                    </div>
                                    <div className="divTableRow">
                                        <div className="divTableCell position-relative" data-label="AO">Mahindra Naik Motors Ratnagiri</div>
                                        <div className="divTableCell bg-white" data-label="Leads (Unit)">973</div>
                                        <div className="divTableCell bg-white" data-label="Enquiry (%)">20</div>
                                        <div className="divTableCell bg-white" data-label="Booking (%)">14</div>
                                        <div className="divTableCell bg-white" data-label="Retail (%)">2</div>
                                        <div className="divTableCell bg-white" data-label="Sold (Unit)">221</div>
                                    </div>
                                    <div className="divTableRow">
                                        <div className="divTableCell position-relative" data-label="AO">Mahindra Naik Motors Sangolda</div>
                                        <div className="divTableCell bg-white" data-label="Leads (Unit)">855</div>
                                        <div className="divTableCell bg-white" data-label="Enquiry (%)">20</div>
                                        <div className="divTableCell bg-white" data-label="Booking (%)">14</div>
                                        <div className="divTableCell bg-white" data-label="Retail (%)">2</div>
                                        <div className="divTableCell bg-white" data-label="Sold (Unit)">102</div>
                                    </div>
                                    <div className="divTableRow">
                                        <div className="divTableCell position-relative" data-label="AO">Mahindra Naik Motors Verna</div>
                                        <div className="divTableCell bg-white" data-label="Leads (Unit)">632</div>
                                        <div className="divTableCell bg-white" data-label="Enquiry (%)">20</div>
                                        <div className="divTableCell bg-white" data-label="Booking (%)">14</div>
                                        <div className="divTableCell bg-white" data-label="Retail (%)">2</div>
                                        <div className="divTableCell bg-white" data-label="Sold (Unit)">97</div>
                                    </div>
                                    <div className="divTableRow">
                                        <div className="divTableCell position-relative" data-label="AO">Mahindra Naik Motors Mapusa</div>
                                        <div className="divTableCell bg-white" data-label="Leads (Unit)">223</div>
                                        <div className="divTableCell bg-white" data-label="Enquiry (%)">20</div>
                                        <div className="divTableCell bg-white" data-label="Booking (%)">14</div>
                                        <div className="divTableCell bg-white" data-label="Retail (%)">2</div>
                                        <div className="divTableCell bg-white" data-label="Sold (Unit)">76</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}


        </div>
    )
}

export default Retail;