import React, { useEffect, useState } from "react";
import LeftMenu from "../layout/leftMenu";
import { backendApi } from "../apiService";
import axios from "axios";
import Header from "../layout/header";
import Footer from "../layout/footer";
import GooglePostimage from "../images/left_icons/Local-Page.png";
import * as Icon from "react-feather";
import $ from 'jquery'
import { getSiteDetails} from '../Project_Folder/service';
import CmsImg from '../images/tagtree_cms.webp'
// var awsUrl = "https://vxrf65nsk3.execute-api.ap-south-1.amazonaws.com/unicorn_test/pagewisecms";
function CmsBackend() {
    const [dataFromApi, setDataFromApi] = useState([])
    const [awswUrl, setAwsUrl] = useState("")
    const [selectedOfOrganization, setselectedOfOrganization] = useState(
        !window.sessionStorage.getItem("switchingOrgId")
            ? window.sessionStorage.getItem("organization")
            : window.sessionStorage.getItem("switchingOrgId")
    );
    useEffect(() => {
        getData();
    }, []);
    const getData = () => {
         $(".loader_div").attr("hidden", false)
        var req = {
            "functionName": "viewListing",
            "userId": "2",
            "offset": "0",
            "max": "10"
        };
        backendApi(req).then((response) => {
                    if (response.success === "1") {
                        setDataFromApi(response.result.siteList)
        }
    })
        // urlSetting(function (response) {
        //     console.log(response, "responseresponseresponse");
        //     if (response != false)
        //         apiCallindCms(response, req).then((data) => {
        //             setDataFromApi(data.result.siteList)
        //             console.log(data, "datadatadatadatadata");

        //         })
        //     $(".loder_div").attr("hidden", true);

        // })
        // urlSetting(req).then((data) => {
        //     // .then((data) =>
        //     // if (response.success === "1") {
        //         console.log(data, "responseresponseresponse");
        //         // setDataFromApi(response.data.result.siteList)


        //     // }
        // });
    };
    // var orgadmin = window.sessionStorage.getItem("role") == "ORG_ADMIN";
    // const getUserData = () => {
    //     var req = {
    //         functionName: "organizationList",
    //         role:
    //             window.sessionStorage.getItem("switchUserRole") === ""
    //                 ? window.sessionStorage.getItem("role")
    //                 : window.sessionStorage.getItem("switchUserRole"),
    //         organization: !window.sessionStorage.getItem("switchingOrgId")
    //             ? window.sessionStorage.getItem("organization")
    //             : window.sessionStorage.getItem("switchingOrgId"),
    //         namesOnly: orgadmin ? "" : "false",
    //     };
    //     backendApi(req).then((response) => {
    //         if (response.success === "1") {
    //             if (response.result?.length > 0) {
    //                 let filterArray = response.result.filter((item) => {
    //                     if (item.id == selectedOfOrganization) {
    //                         console.log(item, "itemitemitem");
    //                         getData(item.local_page_cms_link)
    //                     }
    //                 });
    //             }
    //         }
    //     });
    // };
    return (
        <div className="main_wrapper">
            <LeftMenu />
            <div className="userListWrapper">
                <Header heading="CMS Backend" headerImage={GooglePostimage} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2 col-md-4 col-sm-6">
                            <select className="form-control">
                                <option value="">India</option>
                                <option value="">Sri Lanka</option>
                            </select>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6">
                            <select className="form-control">
                                <option value="0">State</option>
                                <option value="">Kerala</option>
                            </select>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6">
                            <select className="form-control">
                                <option value="0">City</option>
                                <option value="">Tvm</option>
                            </select>
                        </div>

                        <div className="col-lg-2 col-md-4 col-sm-6 location_search">
                            <input className="form-control" type="text" placeholder="Location" />
                            <div className="location_search_drop">
                                <ul class="nav flex-column">
                                    <li class="nav-item">
                                        <span>Location1</span>
                                    </li>
                                    <li class="nav-item">
                                        <span>Location2</span>
                                    </li>
                                    <li class="nav-item">
                                        <span>Location3</span>
                                    </li>
                                    <li class="nav-item">
                                        <span>Location4</span>
                                    </li>
                                    <li class="nav-item">
                                        <span>Location5</span>
                                    </li>
                                    <li class="nav-item">
                                        <span>Location6</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6">
                            <button
                                className="btn-search">
                                Search
                            </button>
                        </div>
                    </div>
                    {dataFromApi.length > 0 &&
                        <div className="row mt-1">
                            {dataFromApi.map((item) => (
                                <div className="col-md-6 col-lg-4 col_flex">
                                    <div className="card_div">
                                        <div className="card_head">
                                            {item.image ?
                                                <img src={item.image} className="img-fluid" /> :
                                                <img src={CmsImg} className="img-fluid" />}
                                        </div>
                                        <div className="card_body">
                                            <div className="cms_leftcontt">
                                                <div className="cms_lefthead">
                                                    {item.centerName}
                                                </div>
                                                <div className="cms_leftconnt">
                                                    {item.stateName}-{item.cityName}
                                                </div>
                                            </div>
                                            <div className="cms_righticons">
                                                <span className="cms_icons" title="Edit"><Icon.Edit /></span>
                                                <span className="cms_icons" title="Preview"><Icon.Eye /></span>
                                                <span className="cms_icons" title="Save"><Icon.Save /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>))}
                        </div>
                    }
                </div>
                <Footer />
            </div>
            <div className="loder_div" />
        </div>
    );
}

export default CmsBackend;
