import React, { useEffect,useState } from "react";
import LeftMenu from "../layout/leftMenu";
import { backendApi } from "../apiService";
import LocalPagesimage from "../images/left_icons/Local-Page.png";
import Header from "../layout/header";
import "../css/bootstrap.css";
import exportFromJSON from "export-from-json";
import "../css/style.css";
function ExportExcel() {
const [dataFromApi,setDataFromApi]=useState("")
const [checkboxChecked,SetcheckboxChecked]=useState("csv")
const [localPageUrlSet,SetlocalPageUrlSet]=useState(window.sessionStorage.getItem("localpage_url_link"))
const [urlCheckBox,SeturlCheckBox]=useState(false)
  useEffect(()=>{
    listingFunction()
  },[]);
  const listingFunction=()=>{
const postParaMeter ={
  "functionName": "localPageExporttoExcel",
  "site_id": window.sessionStorage.getItem("switchingOrgId") === ""
  ? window.sessionStorage.getItem("organizationId")
  : window.sessionStorage.getItem("switchingOrgId"),
}
    backendApi(postParaMeter).then((result)=>{
      if (result.success === "1") {
        setDataFromApi(result.data)
      }


    })
  }
  const  checkBoxControlFunction=(whichIsChecked)=>{
    SetcheckboxChecked(whichIsChecked)
  }
const  exportExcel=()=>{
  
    var re = dataFromApi;
    const data = [];
    for (var item = 0; item < re.length; item++) {
      data.push({
        "Id":re[item].id,
        "Reference Code":re[item].reference_code,
        "Location Name": re[item].location_name,
        "Address":re[item].address,
        "Migration Status": re[item].migration_status,
        "Error Count": re[item].error_count,
        "Search":re[item].search,
        "Process  Status": re[item].process_status,
        "Google Address Status":re[item].google_address_status,
        "Created By": re[item].created_by,
        "Audit Excel Id": re[item].audit_excel_id,
        "Site Id": re[item].site_id,
        "Phone No": re[item].phone_no,
        "Vm Id": re[item].vm_id,
        "Sub Master Id": re[item].sub_master_id,
        "Vm Date": re[item].vm_date,
        "Category":re[item].category,
        "Address Line1":re[item].address_line1,
        "Address Line2":re[item].address_line2,
        "Address Line3":re[item].address_line3,
        "Address Line4":re[item].address_line4,
        "Address Line5": re[item].address_line5,
        "Sub Locality": re[item].sub_locality,
        "Locality":re[item].locality,
        "Administrative Area":re[item].administrativeArea,
        "Country Region":re[item].country_Region,
        "Postcode":re[item].Postcode,
        "Latitude":re[item].Latitude,
        "Longitude":re[item].Longitude,
        "Additional Phones": re[item].Additional_Phones,
        "Website": re[item].Website,
        "Additional Categories": re[item].Additional_categories,
        "Sunday Hours": re[item].Sunday_hours,
        "Monday Hours": re[item].Monday_hours,
        "Tuesday Hours": re[item].Tuesday_hours,
        "Wednesday Hours": re[item].Wednesday_hours,
        "Thursday Hours": re[item].Thursday_hours,
        "Friday Hours": re[item].Friday_hours,
        "Saturday Hours": re[item].Saturday_hours,
        "Special Hours": re[item].Special_hours,
        "From The Business": re[item].From_the_business,
        "Opening Date": re[item].Opening_date,
        "Logo Photo": re[item].Logo_photo,
        "Cover Photo": re[item].Cover_photo,
        "Other Photos": re[item].Other_photos,
        "Labels": re[item].Labels,
        "AdWords Location Extensions Phone": re[item].AdWords_location_extensions_phone,
        "WiFi": re[item].Wi_Fi,
        "Is Owned By Women": re[item].is_owned_by_women,
        "Payments American Express": re[item].Payments_american_express,
        "Payments Mastercard": re[item].Payments_mastercard,
        "Payments Visa": re[item].Payments_visa,
        "Url Menu": re[item].url_menu,
        "Url Order Ahead": re[item].url_order_ahead
    });
    }
    const fileName = "download";
    const exportType = checkboxChecked;
    exportFromJSON({ data, fileName, exportType });
  }
    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper">
          <Header heading="Export Excel" headerImage={LocalPagesimage} />
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="user_formdiv mt-5 p-3">
                  <div className="export_data_header">
                  Export  &#123; {window.sessionStorage.getItem("switchUserOrganization")} &#125;
                  </div>
                  <p className="export_data_p">
                    Lorem Ipsum is simply dummy text of the <b>435</b> &#123; object Name here &#125; printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,{" "}
                  </p>
                  <div className="user_formdiv mb-3 mt-3 exportData">
                    <div className="row">
                      <div className="col-md-6">
                        <label class="radio-inline">
                          <input
                       onChange={(e) => {
                        checkBoxControlFunction("csv")
                      }}
                            // value={checkboxChecked}
                            type="radio"
                            checked
                            name="filenames"
                            className="mr-1"
                          />
                          CSV
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label class="radio-inline">
                          <input
                            type="radio"
                            name="filenames"
                            // value={checkboxChecked}
                            className="mr-1"
                            onChange={(e) => {
                              checkBoxControlFunction("xls")
                            }}
                          />
                         Excel
                        </label>
                      </div>
                     
                    </div>
                  </div>
                  <div className="text-right">
                    <button className="btn btn-danger mr-2">Cancel</button>
                    <button className="btn btn-primary" onClick={(e) => {
                                  exportExcel()
                                }}>Export</button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="user_formdiv mt-5 p-3">
                  <div className="export_data_header">
                    Export  &#123; {window.sessionStorage.getItem("switchUserOrganization")} &#125;
                  </div>
                  <p className="export_data_p">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,{" "}
                  </p>
                  <div className="user_formdiv mb-3 mt-3 exportData">
                    <div className="row">
                      <div className="col-md-12">
                        <label class="radio-inline">
                          <input
                           type="checkbox"
                            // name="options"
                            checked={urlCheckBox}
                            onChange={(e) => SeturlCheckBox(e.target.checked)}
                            
                            className="mr-1"
                          />
                         Url
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* <div className="text-right">
                    <button className="btn btn-danger mr-2">Cancel</button>
                    <button className="btn btn-primary"  onClick={(e) => {
                                  // exportExcel()
                                }}>Export</button>
                  </div> */}
                </div>
              </div>
              {urlCheckBox==true?(
              <div className="col-md-12">
                        <div className="export_black mt-5 pl-5">
                            {/* &#123;<br/>
                          <div className="ml-5">Test</div>
                            &#125; */}
                           {localPageUrlSet}
                        </div>
              </div>):""}
            </div>
          </div>
        </div>
      </div>
    );
  
}

export default ExportExcel;
