import React, { Component } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import * as Icon from "react-feather";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import { backendApi } from "../apiService";
import "../css/social_default.css";
import "../css/social_streams.css";
import facebook from "../images/facebook.png";
import instagram from "../images/instagram.png";
import tumblr from "../images/tumblr.png";
import SocialMediaimage from "../images/left_icons/social-media.png";
import pinterest from "../images/pinterest.png";
import linkedin from "../images/linkedin.png";
import youtube from "../images/youtube.png";
import twitter from "../images/twitter.png";
import socialTemp from "../images/socialTemp.png";
import {
  FaFacebookF,
  FaInstagram,
  FaTumblr,
  FaPinterestP,
  FaLinkedinIn,
  FaYoutube,
  FaSearch,
  FaTwitter,
} from "react-icons/fa";

import Img03 from "../images/icon_bg.jpg";
import { stringify } from "uuid";
var array = [];
let testArray = [];
var social_tags = window.sessionStorage.getItem("social_tags");
class SocialStreams extends Component {
  state = {
    items: Array.from({ length: 20 }),
    tumblarList: [],
    twitterList: [],
    mainArray: [],
    finalArray: [],
    socialdata:"",
    inputHandle:
      social_tags == ""
        ? window.sessionStorage.getItem("organization")
        : social_tags,
    // isActivetumblr: false,
    // isActiveyoutube: false,
    // isActivetwitter: false,
    // isAll: false,
  };
  componentDidMount() {
    console.log("window",window.sessionStorage.getItem("social_tags"))
    this.processFunction("");
  }

  async processFunction(type) {
    array = [];
    this.setState({ mainArray: [] }, () => {
      $(".loder_div").attr("hidden", false);
      let serachParams = window.sessionStorage.getItem("social_tags");
      console.log("serachParamsserachParams", serachParams);
      let serachParamsArray = serachParams.split(",");
      let searchFlg = $("#searchData").val();
      let newData = "";
      let flag = "";
      if (serachParamsArray.length > 0) {
        serachParamsArray.forEach(async (data, i) => {
          if (searchFlg != "") {
            searchFlg = " " + searchFlg;
          }
          newData = data + searchFlg;
          if (searchFlg === "") {
            flag = "";
          } else {
            flag = true;
          }
          if (type === "tumblar") {
            console.log("tumblar OK");
            await this.gettumblarList(flag, newData);
          } else if (type === "Twitter") {
            console.log("Twitter OK");
            await this.getTwitterList(flag, newData);
          } else if (type === "Youtube") {
            console.log("Youtube OK");
            await this.getYoutubeList(flag, newData);
          } else {
            console.log("ALL OK");

            await this.getTwitterList(flag, newData);
            await this.gettumblarList(flag, newData);
            await this.getYoutubeList(flag, newData);
          }

          newData = "";
        });
        $(".loder_div").attr("hidden", true);
        // window.animation(document.getElementById("grid"));
      } else {
        $(".loder_div").attr("hidden", true);
        alert(
          "No searching hash tags available now, Please add social me tags"
        );
      }
    });
  }

  async gettumblarList(flag, data) {
    var req = {
      functionName: "gettumblrpost",
      tag: data,
    };
    this.setState({
      socialdata:data
    })
    console.log(">>>>>>>>>", req);
    let tumblerArray = this.state.mainArray;

    testArray = this.state.mainArray;
    $(".loder_div").attr("hidden", false);
    await backendApi(req).then((response) => {
      if (response.success === "1") {
        $(".loder_div").attr("hidden", true);
        this.setState({ tumblarList: response.data });
      }
      let arraytumblr = response.data;

      // array.push(response.data)
      for (let i = 0; i < arraytumblr.length; i++) {
        array.length = 0;

        array.push({ blog_name: arraytumblr[i].blog_name });

        tumblerArray.push({
          text: arraytumblr[i].blog_name,
          description: arraytumblr[i].summary,
          trail: arraytumblr[i].trail === undefined ? "" : arraytumblr[i].trail,
          summary: arraytumblr[i].summary,
          publishTime: arraytumblr[i].date,
          type: "tumblr",
          count: i,
        });
      }
      console.log("tumblerArray...........................", tumblerArray);
      this.setState({ mainArray: tumblerArray }, () => {
        window.animation(document.getElementById("grid"));
      });
    });
    // window.animation(document.getElementById("grid"));
  }

  async getTwitterList(flag, data) {
    var req = {
      functionName: "twitterrescenttweets",
      query: data,
      token: null,
    };
    let twitterArray = this.state.mainArray;

    $(".loder_div").attr("hidden", false);
    await backendApi(req).then((response) => {
      if (response.success === "1") {
        $(".loder_div").attr("hidden", true);
        this.setState({
          twitterList: response.data.data,
        });
      }

      let arraytwitter = response.data.data;
      for (let j = 0; j < arraytwitter.length; j++) {
        array.push({ text: arraytwitter[j].text });
        console.log("ssssssssssssss", arraytwitter[j].entities.mentions);
        twitterArray.push({
          text: arraytwitter[j].text,
          publishTime: arraytwitter[j].created_at,
          mentions:
            arraytwitter[j].entities.mentions === undefined
              ? []
              : arraytwitter[j].entities.mentions,
          type: "twitter",
          count: j,
        });
      }

      // array.push(response.data.data )

      this.setState({ mainArray: twitterArray }, () => {
        window.animation(document.getElementById("grid"));
      });
    });
    // window.animation(document.getElementById("grid"));
  }
  async getYoutubeList(flag, data) {
    var req = {
      functionName: "youtube",
      query: data,
    };
    let youtubeArray = this.state.mainArray;

    // $(".loder_div").attr("hidden", false);
    await backendApi(req).then((response) => {
      if (response.success === "1") {
        // $(".loder_div").attr("hidden", true);
        this.setState({
          twitterList: response.data.data,
        });
      }

      let arrayyoutube = response.data;

      for (let k = 0; k < arrayyoutube.length; k++) {
        array.length = 0;
        array.push({ text: arrayyoutube[k].text });
        youtubeArray.push({
          text: arrayyoutube[k].snippet.title,
          youtubedescription: arrayyoutube[k].snippet.description,
          thumbnails:
            arrayyoutube[k].snippet.thumbnails.default.url === undefined
              ? ""
              : arrayyoutube[k].snippet.thumbnails.default.url, //arrayyoutube[k].snippet.thumbnails.default.url,
          publishTime: arrayyoutube[k].snippet.publishTime,
          channelTitle: arrayyoutube[k].snippet.channelTitle,
          type: "youtube",
          count: k,
        });
      }

      // array.push(response.data.data )

      this.setState({ mainArray: youtubeArray }, () => {
        window.animation(document.getElementById("grid"));
      });
    });
    // window.animation(document.getElementById("grid"));
  }
  enterKeyPressed(event) {
    if (event.keyCode == 13) {
      this.processFunction("");
      return true;
    } else {
      return false;
    }
  }
  getdata() {
    this.processFunction("");
  }
  getAll() {
    this.processFunction("");
  }
  closePopup(){

   $("#warning").hide()
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu />

        <div className="userListWrapper">
          <Header heading="Social Me" headerImage={SocialMediaimage}
           />
          {/* {this.state.socialdata == "" &&
<div className="user_listdiv" id="warning">
<div class="alert alert-warning" role="alert">
  <p>
    <Link to="/settings">Please click here to add Social tags in Organization Settings</Link></p>
 
  <button type="button" class="closes" data-dismiss="alert" aria-label="Close" onClick={()=>this.closePopup()}>
    <span aria-hidden="true">&times;</span>
  </button>
</div>
</div>} */}
          <div className="social_headertab">
            <ul className="nav">
              <li className="nav-item">
                <span
                  onClick={() => {
                    this.processFunction("");
                  }}
                >
                  All
                </span>
                <div className="social_datavalue">15</div>
              </li>
              {/* <li className="nav-item">
                <span>
                <img src={facebook}/>
                </span>
                <div className="social_datavalue">15</div>
              </li> */}
              <li className="nav-item">
                <span
                  onClick={() => this.processFunction("tumblar")}
                  // style={{
                  //   backgroundColor: this.state.isActivetumblr ? "#cdcdcd" : "",
                  // }}
                >
                  <img src={tumblr} />
                </span>
                <div className="social_datavalue">15</div>
              </li>
              {/* <li className="nav-item">
                <span>
                <img src={instagram}/>
                </span>
                <div className="social_datavalue">15</div>
              </li>
              <li className="nav-item">
                <span>
                <img src={pinterest}/>
                </span>
                <div className="social_datavalue">15</div>
              </li>
              <li className="nav-item">
                <span>
                <img src={linkedin}/>
                </span>
                <div className="social_datavalue">15</div>
              </li> */}
              <li className="nav-item">
                <span onClick={() => this.processFunction("Youtube")}>
                  <img src={youtube} />
                </span>
                <div className="social_datavalue">15</div>
              </li>
              <li className="nav-item">
                <span onClick={() => this.processFunction("Twitter")}>
                  <img src={twitter} />
                </span>
                <div className="social_datavalue">15</div>
              </li>
              <li className="nav-item searech_social">
                <input
                  type="text"
                  placeholder="search"
                  id="searchData"
                  onKeyDown={(e) => this.enterKeyPressed(e)}
                />

                <FaSearch
                  onClick={() => {
                    this.getdata();
                  }}
                />
              </li>
            </ul>
          </div>
          <div className="user_listdiv social_userdiv">
            <ul
              className="grid effect-2"
              id="grid"
              key={this.state.mainArray.length}
            >
              {(() => {
                const arr = [];
                //for (let i = 0; i < this.state.mainArray.length; i++) {
                arr.push(
                  <>
                    {this.state.mainArray
                      // .sort(
                      //   (a, b) =>
                      //     new Date(b.publishTime) - new Date(a.publishTime)
                      // )
                      .sort((a, b) => 0.5 - Math.random())
                      .map((data, j) => (
                        <>
                          <li key={j}>
                            <div className="innter_griddiv">
                              <div className="social_mediacontnt">
                                {data.text}
                                {/* {data.text} ----{data.type} */}
                                {data.type == "tumblr" ? (
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: data.description,
                                    }}
                                  />
                                ) : data.type == "youtube" ? (
                                  <p>{data.youtubedescription}</p>
                                ) : (
                                  <p></p>
                                )}

                                {data.type == "twitter" ? data.text : ""}
                              </div>

                              <div className="social_mediamiddle">
                                {data.type == "tumblr" ? (
                                  data.trail != "" ?
                                  <img
                                  src={
                                    data.trail[0] != undefined
                                      ? data.trail[0].blog.theme
                                          .header_image
                                      : ""
                                  }
                                  />:""
                                ) : (
                                  ""
                                )}

                                {data.type == "youtube" ? (
                                  <img src={data.thumbnails} />
                                ) : (
                                  ""
                                )}

                                <div className="social_imglink">
                                  <div className="img_mainwithlink">
                                    <a
                                      rel="nofollow"
                                      href=""
                                      className="main_link_socila"
                                    >
                                      {data.type == "twitter" ? "" : data.text}
                                    </a>

                                    <div className="img_withlink img_withlink_btm">
                                      <span>
                                        {data.type == "twitter" ? (
                                          <FaTwitter />
                                        ) : data.type == "tumblr" ? (
                                          <FaTumblr />
                                        ) : (
                                          <FaYoutube />
                                        )}
                                      </span>

                                      <span>
                                        {/* <p
                                          rel="nofollow"
                                          href="#"
                                          className="ff-nickname"
                                        >
                                          {data.type == "twitter"
                                            ? data.mentions.map(
                                                (item) => item.username
                                              )
                                            : data.title}
                                        </p> */}
                                        <p
                                          rel="nofollow"
                                          href="#"
                                          className="ff-timestamp"
                                        >
                                          {data.publishTime != ("" || null)
                                            ? data.publishTime
                                                .replace("T", " ")
                                                .replace("Z", "")
                                                .split(" ")[0]
                                            : ""}
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </>
                      ))}
                  </>
                );
                //}
                return arr;
              })()}
            </ul>
          </div>
          {/* <div className="social_loadmorebtn">
            <button type="button">Show more</button>
          </div> */}
        </div>
        <div className="loder_div" hidden />
        <Footer/>
      </div>
    );
  }
}

export default SocialStreams;
