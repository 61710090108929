import React, { useEffect, useState } from "react";
import Img1 from "../../images/img1.png";
import * as Icon from "react-feather";
import $ from "jquery";
import { backendApi } from "../../apiService";
import { GoPencil } from "react-icons/go";
import moment from "moment";
export default function WaitingApproval({
  arrayList,
  callData,
  waitingMessage,
  approveContent,
  rejectContent,
  type,
  deleteContent,
}) {
  const [waitingList, setWaitingList] = useState([]);
  useEffect(() => {
    if (arrayList && arrayList.length > 0) {
      setWaitingList(arrayList);
    }
  }, [arrayList]);
  const textAreachange = (i) => {
    $("#searchaddress" + i).hide();
    $("#searchAddressvalue" + i).attr("hidden", false);
    $("#button" + i).attr("hidden", false);
    $("#titleEditIcon" + i).attr("hidden", true);
    $("#titleCancelEdit" + i).removeAttr("hidden");
  };
  const cancelEdit = (i) => {
    $("#searchAddressvalue" + i).attr("hidden", true);
    $("#button" + i).attr("hidden", true);
    $("#titleCancelEdit" + i).attr("hidden", true);
    $("#titleEditIcon" + i).attr("hidden", false);
    $("#titleEditIcon" + i).removeAttr("hidden");
  };
  const saveRemarks = (data, i) => {
    const api = {
      functionName: "content_request_update_remarks",
      content_generation_request_id: data.id,
      remarks: $("#searchAddressvalue" + i).val(),
      remarks_updated_by:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };
    backendApi(api).then((data) => {
      alert("Remarks updated successfully!!");
      //   $(`remarksValue${i}`).text($("#searchAddressvalue" + i).val());
      $("#searchAddressvalue" + i).attr("hidden", true);
      $("#button" + i).attr("hidden", true);
      $("#titleCancelEdit" + i).attr("hidden", true);
      $("#titleEditIcon" + i).attr("hidden", false);
      $("#titleEditIcon" + i).removeAttr("hidden");
      //   setWaitingList([]);
      callData();
      //   window.location.reload();
    });
  };
  return (
    <div className="col-md-12 tableResponsive tagImageEditrListTble mt-4">
      <div className="tableOuterWidth table_width_md">
        {waitingList && waitingList.length > 0 ? (
          <table className="table">
            <thead>
              <tr>
                <th className="col-1">Id </th>
                <th className="col-3">Content </th>
                <th className="col-2">Remarks</th>
                <th className="col-2">Requested</th>
                <th className="col-2">{type==='Rejected'?'Rejected' :'Approved'}</th>
                <th className="col-2 ">Action</th>
              </tr>
            </thead>
            {waitingList.map((data, i) => {
              let contentType = data.category_type;
              return (
                <div className="table_Outerscroll table_width_md">
                  <tbody className="table_Outertbody pb-3">
                    <tr>
                      <td className="col-1">{i + 1}</td>
                      <td className="col-3">
                        {(contentType === "Image" || contentType=="Planning")&& (
                          <div className="popup-image">
                            <div className="popupGallerysection">
                              <a
                                href={data.content_image}
                                title="img"
                                target="_blank"
                              >
                                <img
                                  src={data.content_image}
                                  className="img-fluid"
                                />
                              </a>
                            </div>
                          </div>
                        )}
                        {contentType === "Media" && (
                          <div className="popup-image">
                            <div className="popupGallerysection">
                              <a
                                href={data.content_media}
                                title="img"
                                target="_blank"
                              >
                                <img
                                  src={data.content_media}
                                  className="img-fluid"
                                />
                              </a>
                            </div>
                          </div>
                        )}
                        {contentType === "Text" && (
                          <span>{data.content_text}</span>
                        )}
                          {contentType === "Question" && (
                            <>
                          <p>Qn:{data.content_image}</p>
                          <p>Ans:{data.content_text}</p>
                          </>
                        )}
                      </td>
                      <td className="col-2">
                        <div className="popup-image">
                          <span id={`remarksValue${i}`}>
                            {data.remarks === null || data.remarks === ""
                              ? "NA"
                              : data.remarks}
                          </span>
                          {/* <div className="searchbuttonDiv"> */}
                          <GoPencil
                            id={"titleEditIcon" + i}
                            className="audit-search-icon ml-2"
                            onClick={() => {
                              textAreachange(i, data.search);
                              $("#searchAddressvalue" + i).val(data.search);
                              $("#dataId").text(data.id);
                            }}
                          />
                          <p
                            id={"searchaddress" + i}
                            className="mb-0 succes_textdiv"
                          >
                            {data.search}
                          </p>
                          <textarea
                            type="text"
                            className="form-control searchEditInput"
                            placeholder="Search"
                            id={"searchAddressvalue" + i}
                            hidden
                          />
                          <div className="mt-2">
                            <span id={"button" + i} hidden>
                              <button
                                onClick={() => saveRemarks(data, i)}
                                className="btn btn-success"
                              >
                                Save
                              </button>
                            </span>
                            <span id={"titleCancelEdit" + i} hidden>
                              <button
                                onClick={() => cancelEdit(i)}
                                className="btn btn-secondary ml-1"
                              >
                                Cancel
                              </button>
                            </span>
                          </div>
                        </div>
                        {/* </div> */}
                      </td>
                      <td className="col-2">
                        <p>
                          {" "}
                          {moment(data.requested_date).format("DD MMM YYYY")}
                        </p>
                        <p>{data.requested_by_name}</p>
                      </td>
                      <td className="col-2">
                        <p>
                          {" "}
                          {moment(data.approved_date).format("DD MMM YYYY")}
                        </p>
                        <p>{data.approved_by_name}</p>
                      </td>
                      <td className="col-2">
                        {(data.req_status === "Approved" ||
                          data.req_status === "Rejected") && (
                          <button
                            type="submit"
                            className="btn btn-danger"
                            title="Delete Content"
                            onClick={() => deleteContent(data)}
                          >
                            Delete
                          </button>
                        )}
                        {data.req_status === "Waiting" && (
                          <>
                            <button
                              type="submit"
                              className="btn btn-success mr-2"
                              onClick={() => approveContent(data)}
                            >
                              Approve
                            </button>
                            <button
                              type="submit"
                              className="btn btn-danger"
                              onClick={() => rejectContent(data)}
                            >
                              Reject
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </div>
              );
            })}
          </table>
        ) : (
          <h1>{waitingMessage}</h1>
        )}

        <div id="editPopup" className="module_popup" hidden>
          <div className="module_innerpopup">
            <div className="module_close">
              <Icon.XCircle
                onClick={() => {
                  $("#editPopup").attr("hidden", true);
                  $(".modules_div_org").removeClass("active");
                  $("#orgainzationSelectAll").prop("checked", false);
                }}
              />
            </div>
            <div className="userListmodal_head">
              <span className="subhead">Share To</span>
            </div>
            <div className="module_body">
              <div className="module_popupsearch_wrpr">
                <div className="userlistmodal_body">
                  <input
                    type="text"
                    className="form-control m-0"
                    placeholder="user's name"
                  />
                </div>
                <div className="module_popupsearchBtn">
                  <button
                    type="button"
                    className="btn btn-primary"
                    // onClick={() => this.saveOrgAllotment()}
                  >
                    Share
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
