import React, { Component } from "react";
import { Buffer } from "buffer";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Style } from "react-style-tag";
import SocialMediaimage from "../images/left_icons/social-media.png";
import img1 from "../images/img1.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import * as Icon from "react-feather";
import $ from "jquery";
import Slider from "react-slick";
import { backendApi } from "../apiService";
import AWS from "aws-sdk";
const s3ImageUrl =
  "http://tagtree.s3-website.ap-south-1.amazonaws.com/production/images";
// var s3Region ;
// var s3bucketAccessKey;
// var s3bucketSecretKey;
// var s3Bucket;
// var s3BucketMainFolder;
// var S3BucketImagesFolder;
// var bucketParams;
var s3;

const s3Region = "us-east-1",
  s3bucketAccessKey = process.env.REACT_APP_TAGTREE_S3_ACCESS_KEY,
  s3bucketSecretKey = process.env.REACT_APP_TAGTREE_S3_SECRET_KEY,
  s3Bucket = "tagtree",
  s3BucketMainFolder = "production",
  S3BucketImagesFolder = "images",
  bucketParams = {
    Bucket: s3Bucket,
  };
// AWS.config.update({
//   accessKeyId: s3bucketAccessKey,
//   secretAccessKey: s3bucketSecretKey,
//   region: s3Region,
// });
// var bucketParams = {
//   Bucket: s3Bucket,
// };
// let s3 = new AWS.S3({
//   apiVersion: "2006-03-01",
//   params: bucketParams,
// });
<Style>{`
    body {
      background: #222;
      color: #fff;
      position: relative;
      text-align: center;
      font-size: 1rem;
      font-family: sans-serif;
      padding-bottom: 3em;
    }
    .page-wrap {
      display: inline-block;
      margin: 2em auto;
    }
    .controls {
      &__input {
        display: block;
        margin: 0 auto;
        background: none;
        border: none;
        font-size: 1em;
        padding-bottom: .5em;
        border-bottom: 2px solid #ccc;
        text-align: center;
        outline: none;
        color: #fff;
      }
      &__btn {
        background: dodgerblue;
        color: #fff;
        border: none;
        font-size: 1em;
      }
      &__label {
        display: block;
        font-size: .8em;
        padding-top: .3em;
        margin-bottom: 2em;
      }
    }
    canvas {
      background-color: #eee;
      // opacity: 0;
      transition: opacity .3s;
      &.show {
        opacity: 1;
      }
    }
    .canvas-wrap {
      margin-top: 50px;
      position: relative;
    }
    #canvasID {
      z-index: 9999;
    }
`}</Style>;
var canvas;
var ctx;
var img = "";
var src = "";
var text_title = "";
class ImageEditer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fontsizeData: "20px",
      fontstyleData: "",
      fontfamilyData: "OpenSans Regular",
      list: [],
      eventList: [],
      listText: [],
      listImage: [],
      imgId: "",
      imageArray: [],
      selectImage: "",
      selectImage1: "",
      imageUrl: "",
      imageUrl1: "",
      imagePopUp: "",
      popUpList: [],
      fullList: [],
      flag: true,
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
  }
  getS3Credentials() {
    AWS.config.update({
      accessKeyId: s3bucketAccessKey,
      secretAccessKey: s3bucketSecretKey,
      region: s3Region,
    });
    s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: bucketParams,
    });
  }
  componentDidMount() {
    $("#ImageModal").attr("hidden", true);
    // this.imageBrandDropList()
    this.list();
    this.imageEventDropList();
    this.getS3Credentials();

    canvas = document.getElementById("imageCanvas");
    ctx = canvas.getContext("2d");
    img = new Image();
    img.crossOrigin = "anonymous";
  }
  imageBrandDropList() {
    var postData = {
      functionName: "getBrandAndEvent",
      // "brand_name": "",
      site_id: "",
      event_name: "",
      id: "",
    };
    backendApi(postData).then((data) => {
      if (data.success === "1") {
        console.log("imageBrandDropList1111", postData);
        console.log("imageBrandDropList11111", data);
        // this.setState({
        //   list: data.data,
        // });
        this.setState(
          {
            list: data.data,
          },
          () => {
            // this.imageEventDropList()
          }
        );
      }
    });
  }
  list() {
    var postData = {
      functionName: "listAdvGenerator",
      site_id: this.state.siteId,
      created_by: "1",
    };
    backendApi(postData).then((data) => {
      if (data.success === "1") {
        console.log("dataaaaaaaaaaaaaaaaaaaaaa", data.data);
        this.setState({
          fullList: data.data,
        });
      }
    });
  }
  imageEventDropList() {
    // this.imageBrandDropList()
    this.setState({
      eventList: [],
    });
    var postData = {
      functionName: "getBrandAndEvent",
      // "brand_name": $("#txtBrand").val(),
      site_id: this.state.siteId,
      event_name: "",
      id: "",
    };
    backendApi(postData).then((data) => {
      if (data.success === "1") {
        console.log("imageEventDropList1111", postData);
        console.log("imageEventDropList11111", data);
        this.setState(
          {
            eventList: data.data,
            imgId: data.data[0].id,
          },
          () => {
            // this.textImageList()
            console.log("KKKKKKKKKKKKKKKKKKK");
          }
        );
      }
    });
  }
  textImageList(e) {
    var postData = {
      functionName: "getBrandAndEvent",
      brand_name: "",
      // site_id
      event_name: "",
      id: e,
    };
    backendApi(postData).then((data) => {
      if (data.success === "1") {
        console.log("textImageList1111", postData);
        console.log("textImageList11111", data);
        // this.setState({
        //   list: data.data,
        //   listText: data.data[0],
        //   listImage: data.data[1],
        // });
        this.setState(
          {
            listText: data.data[0],
            listImage: data.data[1],
          },
          () => {
            // this.textImageList()
            console.log("KKKKKKKKKKKKKKKKKKK");
          }
        );
      }
    });
  }
  DrawOverlay(img) {
    ctx.drawImage(img, 0, 0);
    ctx.fillStyle = "rgba(30, 144, 255, 0)";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  }
  DrawText() {
    var gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
    gradient.addColorStop("0", " magenta");
    gradient.addColorStop("0.5", "blue");
    gradient.addColorStop("1.0", "red");
    ctx.fillStyle = gradient;
    ctx.textBaseline = "middle";
    let setFontStyle =
      this.state.fontstyleData +
      " " +
      this.state.fontsizeData +
      " " +
      this.state.fontfamilyData;
    ctx.font = setFontStyle;
    ctx.fillText(text_title, 50, 50);
  }
  DynamicText(img) {
    this.DrawOverlay(img);
    this.DrawText();
  }
  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  handleImage = (e) => {
    var reader = new FileReader();
    reader.onload = function (event) {
      img = new Image();
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
      };
      img.src = event.target.result;
      src = event.target.result;
      document.getElementById("tagDownload").style.display = "block";
      canvas.classList.add("show");
      this.DrawOverlay(img);
      this.DrawText();
      this.DynamicText(img);
    };
    reader.readAsDataURL(e.target.files[0]);
    this.setState({
      selectImage1: e.target.files[0],
      selectImage:
        s3BucketMainFolder +
        "/" +
        S3BucketImagesFolder +
        "/" +
        this.uuidv4() +
        e.target.files[0].name,
    });
  };
  textChange = () => {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    this.DrawOverlay(img);
    text_title = document.getElementById("overlayText").value;
    ctx.fillText(text_title, 50, 50);
    this.DrawText();
  };
  imageDownload = () => {
    // debugger;
    var el = document.createElement("a");
    el.setAttribute("href", canvas.toDataURL("png"));
    el.setAttribute("download", "demo");
    document.body.appendChild(el);
    el.click();
    el.remove();
  };
  fontSizeDetails(eventFontize) {
    this.setState(
      {
        fontsizeData: eventFontize,
      },
      () => {
        this.textChange();
      }
    );
  }
  fontStyleDetails(eventFontstyle) {
    this.setState(
      {
        fontstyleData: eventFontstyle,
      },
      () => {
        this.textChange();
      }
    );
  }
  fontFamilyDetails(eventFontfamily) {
    this.setState(
      {
        fontfamilyData: eventFontfamily,
      },
      () => {
        this.textChange();
      }
    );
  }

  handleImageUpload = () => {
    var el = document.createElement("a");
    el.setAttribute("href", canvas.toDataURL("png"));
    const base64 = el.href;
    let fileName = "tagTree" + Date.parse(new Date()) + ".png";
    let fullFilePath =
      s3BucketMainFolder +
      "/" +
      S3BucketImagesFolder +
      "/" +
      this.uuidv4() +
      fileName;
    let buf = new Buffer.from(
      base64.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    let fullFilePath1 = this.state.selectImage;
    let body = this.state.selectImage1;
    s3.upload(
      {
        Key: fullFilePath1,
        Body: body,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          alert("There was an error uploading your image");
          console.log(err.message);
        } else if (data) {
          // this.setState({ imageUrl: data.Location});
          this.setState(
            {
              imageUrl: data.Location,
            },
            () => {
              console.log("imageUrl", this.state.imageUrl);
            }
          );
          return;
        }
      }
    );
    s3.upload(
      {
        Key: fullFilePath,
        Body: buf,
        ContentEncoding: "base64",
        // ACL: "public-read",
        ContentType: "image/png",
      },
      (err, data) => {
        if (err) {
          alert("There was an error uploading your image");
          console.log(err.message);
        } else if (data) {
          console.log("dataaaaaaaaaaaaaaaaa", data);
          // this.setState({ imageUrl1: data.Location});
          this.setState(
            {
              imageUrl1: data.Location,
            },
            () => {
              console.log("imageUrl1", this.state.imageUrl1);
            }
          );
          // this.state.imageUrl1.push({ edited_image_url: data.Location});
          return;
        }
      }
    );
    // }
    this.setState({
      flag: false,
    });
  };

  saveImageEditor() {
    this.handleImageUpload();
    if (this.state.flag === false) {
      var postData = {
        functionName: "saveAdvGenerator",
        site_id:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        created_by: "1",
        ad_event_master_id: $("#txtManualEvent").val(),
        mediaList: [
          {
            image_url: this.state.imageUrl,
            image_text: $("#overlayText").val(),
            edited_image_url: this.state.imageUrl1,
          },
        ],
      };
      console.log("ffffffff", postData);
      backendApi(postData).then((data) => {
        if (data.success === "1") {
          console.log("savepost", postData);
          console.log("saveposssst", data);
          alert(data.successMessage);
          this.list();
          // window.location.href = "/image-editor-settings";
        } else {
          alert(data.errorMessage);
        }
      });
    }
    // }
  }
  imageClick(e) {
    $("#ImageModal").attr("hidden", false);
    console.log("ddddd", e);
    console.log("dddddaaaaa", this.state.listText);
    var a = this.state.listText.filter((obj) => {
      return obj.ad_event_master_id === e.ad_event_master_id;
    });
    console.log("dddddaaaaakkkdddd", a);
    this.setState({
      imagePopUp: e.image_url,
      popUpList: a,
    });
    console.log("dddddaaaaakkk", this.state.popUpList);
  }
  render() {
    var settings = {
      dots: false,
      arrows: true,
      infinite: false,
      draggable: true,
      touchMove: true,
      speed: 500,
      autoplay: false,
      slidesToShow: 3,
      slidesToScroll: 1,
    };
    window.$(document).ready(function () {
      window.$(".popup-image").magnificPopup({
        delegate: "a",
        type: "image",
        tLoading: "Loading image #%curr%...",
        mainClass: "mfp-img-mobile",
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
        },
        image: {
          tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
          titleSrc: function (item) {
            return item.el.attr("title") + "<small></small>";
          },
        },
      });
      window.$(".popup-video").magnificPopup({
        delegate: "a",
        type: "iframe",
        tLoading: "Loading...",
      });
    });
    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper">
          <Header heading="Image Editor" headerImage={SocialMediaimage} />
          <div className="social_userdiv">
            <div className="container-fluid ad-generator_div">
              <Tabs>
                <TabList>
                  <Tab>Manual</Tab>
                  <Tab>Automatic</Tab>
                </TabList>
                <TabPanel>
                  <div className="page-wrap">
                    <div className="controls tag_controls">
                      <div className="col-lg-2 col-md-5">
                        <select
                          className="form-control"
                          onChange={(e) => this.textImageList(e.target.value)}
                          name="cmbManualEvent"
                          id="txtManualEvent"
                        >
                          <option value="0">Function</option>
                          {this.state.eventList.map((data, i) => {
                            return (
                              <option value={data.id}>
                                {data.event_name}{" "}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="tag_upload-btn-wrapper">
                        <button className="btn">Choose Image</button>
                        <input
                          type="file"
                          name="imageLoader"
                          id="imageLoader"
                          className="controls__input"
                          onChange={(e) => this.handleImage(e)}
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <label className="controls__label" for="name">
                            Overlay Text
                          </label>
                          <input
                            className="controls__input form-control"
                            id="overlayText"
                            type="text"
                            onChange={() => this.textChange()}
                          />
                          <br />
                        </div>
                        <div className="col-md-3">
                          <label className="controls__label" for="name">
                            Font Size
                          </label>
                          <input
                            className="form-control"
                            onChange={(e) =>
                              this.fontSizeDetails(e.target.value + "px")
                            }
                            placeholder="size in px"
                            id="txtFont"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          ></input>
                        </div>
                        <div className="col-md-3">
                          <label className="controls__label" for="name">
                            Font Style
                          </label>
                          <select
                            className="form-control"
                            onChange={(e) =>
                              this.fontStyleDetails(e.target.value)
                            }
                          >
                            <option value="Normal">Normal</option>
                            <option value="Italic">Italic</option>
                            <option value="Bold">Bold</option>
                          </select>
                        </div>
                        <div className="col-md-3">
                          <label className="controls__label" for="name">
                            Font Family
                          </label>
                          <select
                            className="form-control"
                            onChange={(e) =>
                              this.fontFamilyDetails(e.target.value)
                            }
                          >
                            <option value="OpenSans Regular">
                              OpenSans Regular
                            </option>
                            <option value="Poppins sans-serif">
                              Poppins sans-serif
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div id="canvas-wrap">
                      <canvas
                        id="imageCanvas"
                        style={{
                          display: "block",
                          width: "500px",
                          height: "400px",
                        }}
                      >
                        <canvas id="canvasID"></canvas>
                      </canvas>
                    </div>
                    <div className="tag_downloadImage">
                      <div
                        id="tagDownload"
                        style={{ display: "none" }}
                        onClick={() => this.imageDownload()}
                        className="col-md-12 tag_TemplateDowloadDiv "
                        title="Download template"
                      >
                        <Icon.Download /> Download Image
                      </div>
                    </div>
                    <div className="button-wrapper">
                      <button
                        type="button"
                        className="btn-save"
                        onClick={() => {
                          this.saveImageEditor();
                        }}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn-cancel"
                        onClick={() => {
                          // $("#ImageModal").attr("hidden", true);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="col-md-12 tableResponsive">
                      <div className="tableOuterWidth">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="col-1">Sl no</th>
                              {/* <th className="col-2">Brand</th> */}
                              <th className="col-2">Function</th>
                              <th className="col-2">Image</th>
                              <th className="col-3">Texts</th>
                              {/* <th className="col-2"></th> */}
                            </tr>
                          </thead>
                          <div className="table_Outerscroll">
                            {console.log(
                              "ssssssssssssssssss",
                              this.state.fullList
                            )}

                            <tbody className="table_Outertbody">
                              {this.state.fullList.map((object, i) => {
                                return (
                                  <tr>
                                    <td className="col-1"> {i + 1} </td>
                                    <td className="col-2"></td>
                                    <td className="col-2 imageText">
                                      <div
                                        className=""
                                        style={{ width: "300px" }}
                                      >
                                        {Object.keys(object)
                                          .toString()
                                          .indexOf("mediaArray") == "-1" ? (
                                          ""
                                        ) : (
                                          <>
                                            {object.mediaArray.length > 0 ? (
                                              <>
                                                <Slider {...settings} key={i}>
                                                  {console.log(
                                                    "hhhhh",
                                                    object.mediaArray
                                                  )}
                                                  {object.mediaArray.map(
                                                    (item, j) => (
                                                      <div className="popup-image">
                                                        <div className="popupGallerysection">
                                                          {console.log(
                                                            "hhhhhhhhhhh",
                                                            item.edited_image_url
                                                          )}
                                                          <a
                                                            href={
                                                              item.image_url
                                                            }
                                                            title="img"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              title=""
                                                              src={
                                                                item.edited_image_url
                                                              }
                                                            />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                </Slider>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </td>
                                    <td className="col-3">
                                      {" "}
                                      <>
                                        {Object.keys(object)
                                          .toString()
                                          .indexOf("mediaArray") == "-1" ? (
                                          ""
                                        ) : (
                                          <>
                                            {object.mediaArray.length > 0 ? (
                                              <>
                                                {object.mediaArray.map(
                                                  (item, j) => (
                                                    <>
                                                      <p
                                                        className="description_ellipse"
                                                        title={item.image_text}
                                                      >
                                                        {item.image_text}
                                                      </p>
                                                    </>
                                                  )
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        )}
                                      </>
                                    </td>
                                    {/* <td className="col-2 ">
                                <Icon.Edit className="icon_common mr-3" />
                                <Icon.Trash className="icon_common" />
                              </td> */}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </div>
                        </table>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="row tag_controls">
                    {/* <div className="col-lg-2 col-md-5">
                      <select className="form-control"
                        onChange={() => this.imageEventDropList()}
                        name="cmbBrand"
                        id="txtBrand">
                        <option value="0" >Brand</option>
                        {this.state.list.map((data, i) => {
                          return (
                            <option value={data.brand_name}>{data.brand_name} </option>
                          )
                        })}
                      </select>
                    </div> */}
                    {console.log("event", this.state.eventList)}
                    <div className="col-lg-2 col-md-5">
                      <select
                        className="form-control"
                        onChange={(e) => this.textImageList(e.target.value)}
                        name="cmbEvent"
                        id="txtEvent"
                      >
                        <option value="0">Function</option>
                        {this.state.eventList.map((data, i) => {
                          return (
                            <option value={data.id}>{data.event_name} </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="row imageListwrap">
                    <div className="chekedImage">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          onClick={() => this.imageClick()}
                          // textChange
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          Select all with random text
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckChecked"
                        >
                          Select All
                        </label>
                      </div>
                    </div>
                    {console.log("aaaaaaaaaaaaaaaa", this.state.listImage)}
                    {this.state.listImage.length > 0 ? (
                      <div className="imageBoxes row">
                        {this.state.listImage.map((object, i) => {
                          return (
                            <div className="col-lg-3 ImageMainWrap col-md-6">
                              <div className="imageBoxesWrap">
                                <img
                                  src={object.image_url}
                                  className="test"
                                  // id="txtImageUrl"  onClick={() => {$("#ImageModal").attr("hidden", false);}}
                                  id="txtImageUrl"
                                  onClick={() => this.imageClick(object)}
                                  // onClick={() => this.textImageList()}
                                  // onClick={() => {
                                  //   $("#ImageModal").attr("hidden", false);
                                  // }}
                                />
                              </div>
                              <div className="imageChecked">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckChecked"
                                />
                              </div>
                              <div className="TChecked">
                                <span>T</span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-12 tableResponsive">
                    <div className="tableOuterWidth">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="col-1">Sl no</th>
                            <th className="col-2">Brand</th>
                            <th className="col-2">Function</th>
                            <th className="col-2">Image</th>
                            <th className="col-3">Texts</th>
                            <th className="col-2"></th>
                          </tr>
                        </thead>
                        <div className="table_Outerscroll">
                          <tbody className="table_Outertbody">
                            <tr>
                              <td className="col-1">1</td>
                              <td className="col-2">Xilligence</td>
                              <td className="col-2">Diwali</td>
                              <td className="col-2">
                                <div className="imageDisplay">
                                  <img src={img1} />
                                </div>
                              </td>
                              <td className="col-3">
                                <p className="description_ellipse">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to
                                </p>
                                <p className="description_ellipse">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to
                                </p>
                              </td>
                              <td className="col-2 ">
                                <Icon.Edit className="icon_common mr-3" />
                                <Icon.Trash className="icon_common" />
                              </td>
                            </tr>
                          </tbody>
                        </div>
                      </table>
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
              {/*  */}
            </div>
          </div>
          <Footer />
        </div>
        <div className="module_popup ImageModal" id="ImageModal" hidden>
          <div className="module_innerpopup module_bodypop">
            <div className="module_headdiv">
              choose text
              <div className="module_close">
                <Icon.XCircle
                  color="red"
                  onClick={() => {
                    $("#ImageModal").attr("hidden", true);
                  }}
                />
              </div>
            </div>
            <div className="module_body textEditorScroll">
              <h5 className="mainHead_popup"></h5>
              <div className="row">
                <div className="col-lg-6">
                  <div className="imagepop">
                    <img src={this.state.imagePopUp}></img>
                  </div>
                  <div className="button-wrapper">
                    <button type="button" className="btn-save">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn-cancel"
                      onClick={() => {
                        $("#ImageModal").attr("hidden", true);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div className="col-lg-6 imageTextscroll p-0">
                  <p>
                    {this.state.popUpList.map((object, i) => {
                      return <div key={0}>{object.text_name}</div>;
                    })}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}
export default ImageEditer;
