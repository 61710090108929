//note: code formatted for ES6 here
import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
// import { apiCalling } from "../../apiService";
var mapKey = "";
export class MapContainer extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  };
  componentDidMount() {
    let prop = this.props;
  }
  // onMapClicked = (props, marker, e) => {
  //   // let center = marker.center;
  // };

  // onMarkerClick = (props, marker, e) => {
  //   let position = marker.getPosition();
  //   this.setState({ lat: position.lat(), lng: position.lng() });
  // };

  onMarkerDragEnd = (props, marker, e) => {
    let position = marker.getPosition();
    this.props.onMarkerDragEnd(position.lat(), position.lng());
    //this.setState({ lat: position.lat(), lng: position.lng() });
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.props.selectGoogleAddressPin(address, latLng.lat, latLng.lng);
      })
      .catch((error) => console.error("Error", error));
  };

  render() {
    const searchOptions = {
      componentRestrictions: { country: ["ind"] },
    };
    return (
      <div class="gmap-div">
        <div id="googleMap">
          <div className="adrsmap-hspadmin">
            <Map
              google={this.props.google}
              // onClick={this.onMapClicked}
              initialCenter={{
                lat:
                  this.props.latitude === "" ||
                  this.props.latitude === undefined
                    ? 8.555164446
                    : this.props.latitude,
                lng:
                  this.props.longitude === "" ||
                  this.props.longitude === undefined
                    ? 76.875496498
                    : this.props.longitude,
              }}
              center={{
                lat:
                  this.props.latitude === "" ||
                  this.props.latitude === undefined
                    ? 8.555164446
                    : this.props.latitude,
                lng:
                  this.props.longitude === "" ||
                  this.props.longitude === undefined
                    ? 76.875496498
                    : this.props.longitude,
              }}
            >
              <Marker
                // onClick={this.onMarkerClick}
                name={"Current location"}
                position={{
                  lat:
                    this.props.latitude === "" ||
                    this.props.latitude === undefined
                      ? 8.555164446
                      : this.props.latitude,
                  lng:
                    this.props.longitude === "" ||
                    this.props.longitude === undefined
                      ? 76.875496498
                      : this.props.longitude,
                }}
                draggable={true}
                onDragend={this.onMarkerDragEnd}
              />
              {/*    
          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}>
              <div>
                <h1>{this.state.selectedPlace.name}</h1>
              </div>
          </InfoWindow> */}
            </Map>
          </div>

          <div className="lanlongdiv0">
            <PlacesAutocomplete
              value={this.props.googleAddressPin}
              onChange={this.props.setGoogleAddressPin}
              onSelect={this.handleSelect}
              searchOptions={searchOptions}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="hspinfo-contentdiv hspinfo-contentdiv-langlog">
                  <label> Address for pin</label>
                  <input
                    {...getInputProps({
                      placeholder: "Search Places ...",
                      className:
                        "location-search-input hspInfofieldinput mapinput-marginhsp",
                    })}
                    id="googleAddressPin"
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion, i) => {
                      const style = suggestion.active
                        ? { backgroundColor: "#42a5f5", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          key={i}
                          className="input-suggestion"
                          {...getSuggestionItemProps(suggestion, {
                            style,
                          })}
                        >
                          <i class="material-icons"> </i>{" "}
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>

            <div
              className="hspinfo-contentdiv hspinfo-contentdiv-langlog"
              hidden
            >
              <label>
                Latitude<span className="hspifoMantory">*</span>
              </label>
              <input
                type="text"
                id="latitude"
                name="latitude"
                value={
                  this.props.latitude === "" ||
                  this.props.latitude === undefined
                    ? 8.555164446
                    : this.props.latitude
                }
                className="hspInfofieldinput hspInfofieldinputMobmap mapinput-marginhsp"
                placeholder="Latitude"
                // onInput={this.props.changeHandler}
              />
            </div>

            <div
              className="hspinfo-contentdiv hspinfo-contentdiv-langlog"
              hidden
            >
              <label>
                Longitude<span className="hspifoMantory">*</span>
              </label>
              <input
                type="text"
                id="longitude"
                name="longitude"
                value={
                  this.props.longitude === "" ||
                  this.props.longitude === undefined
                    ? 76.875496498
                    : this.props.longitude
                }
                className="hspInfofieldinput hspInfofieldinputMobmap mapinput-marginhsp"
                placeholder="Longitude"
                // onInput={this.props.changeHandler}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCTMVAmLQi08X6YSVelIERIkm8z9GlWM60",
})(MapContainer);
