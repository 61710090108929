import React, { useState, useEffect } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import DragDropFile from "../home/dragDropFile";

import DataInputGpost from "../google-post/dataInputGpost";

import cloudImg from "../images/cloud-computing.png";
import GooglePostimage from "../images/left_icons/Google-Post.png";
import * as Icon from "react-feather";
import $ from "jquery";
import moment from "moment";
import { backendApi, apiCallingPost } from "../apiService";
import AWS from "aws-sdk";
import * as XLSX from "xlsx";
import googlePostTemplate from "../docs/googlePostTemplate_excel.xlsx";
import { Link, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
// import TimePicker from "rc-time-picker";
// import "rc-time-picker/assets/index.css";
// import TimePicker from "react-time-picker";
const format = "h:mm a";

var hour = [];
var minute = [];
var globalLocationId = 0;
function SelectLocations() {
  // const [firstDescription, setFirstDescription] = useState(false);
  // const [eventTitle, setEventTitle] = useState("");
  // const [photos, setPhotos] = useState(false);
  // const [addButton, setAddButton] = useState(false);
  // const [linkForButton, setLinkForButton] = useState(false);
  // const [title, setTitle] = useState(false);
  // const [datePickers, setDatePickers] = useState(false);
  // const [startTime, setStartTime] = useState("");
  // const [endTime, setEndTime] = useState("");
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  // const [description, setDescription] = useState("");
  // const [eventType, setEventType] = useState("");
  // const [imageUrl, setImageUrl] = useState("");
  // const [imageName, setImageName] = useState("");
  // const [localPostList, setLocalPostList] = useState([]);
  // const [isLoader, setIsLoader] = useState(0);
  // const [dataList, setDataList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const [temporaryLocationsList, setTemporaryLocationsList] = useState([]);
  const [selectedLocationsArray, setSelectedLocationsArray] = useState([]);
  const [administrativeAreaList, setAdministrativeAreaList] = useState([]);
  const [localityList, setLocalityList] = useState([]);
  const [localityListBackup, setLocalityListBackup] = useState([]);
  const [administrativeAreaFilterEnable, setAdministrativeAreaFilterEnable] =
    useState(0);
  const [
    administrativeAreaFilterEnableArray,
    setAdministrativeAreaFilterEnableArray,
  ] = useState(0);
  const [
    selectedLocationsCompleteDataArray,
    setSelectedLocationsCompleteDataArray,
  ] = useState([]);
  const [createPostButton, setCreatePostButton] = useState(false);
  const location = useLocation();
  const excelMime = [
    "application/vnd.ms-excel",
    "application/msexcel",
    "application/x-msexcel",
    "application/x-ms-excel",
    "application/x-excel",
    "application/x-dos_ms_excel",
    "application/xls",
    "application/x-xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  useEffect(() => {
    getLocationsList();
    console.log(location, "locationlocationlocation");
    // Hours();
    // Minutes();
    // getPostList();
  }, []);
  const setFilter = async (e) => {
    if (e.target.id === "administrativeList") {
      if (e.target.value !== "0") {
        setAdministrativeAreaFilterEnable(1);
        var filteredLocations = temporaryLocationsList.filter((obj) => {
          return obj.storefrontAddress.administrativeArea === e.target.value;
        });
        var localityArray = [];
        filteredLocations.forEach((obj) => {
          return (
            !localityArray.includes(obj.storefrontAddress.locality) &&
            localityArray.push(obj.storefrontAddress.locality)
          );
        });
        setLocationsList(filteredLocations);
        setAdministrativeAreaFilterEnableArray(filteredLocations);
        setLocalityList(localityArray);
      } else {
        setLocationsList(temporaryLocationsList);
        setLocalityList(localityListBackup);
        setAdministrativeAreaFilterEnable(0);
      }
    } else if (e.target.id === "localityFilter") {
      if (e.target.value !== "0") {
        setAdministrativeAreaFilterEnable(1);
        var filteredLocations = temporaryLocationsList.filter((obj) => {
          return obj.storefrontAddress.locality === e.target.value;
        });
        // var localityArray = [];
        // filteredLocations.forEach((obj) => {
        //   return (
        //     localityArray.includes(obj.storefrontAddress.locality) &&
        //     localityArray.push(obj.storefrontAddress.locality)
        //   );
        // });
        setLocationsList(filteredLocations);
        // setLocalityList(localityArray);
      } else {
        setAdministrativeAreaFilterEnable(0);
        setLocationsList(temporaryLocationsList);
      }
    }
  };
  const getLocationsList = () => {
    $(".loder_div").attr("hidden", false);
    const apiJson = {
      functionName: "GetLocationsUnderAccount",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    apiCallingPost(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        setLocationsList(data.data.locations);
        setTemporaryLocationsList(data.data.locations);
        globalLocationId = data.data.locations.length;
        data.data.locations.forEach((obj) => {
          !administrativeAreaList.includes(
            obj.storefrontAddress.administrativeArea
          ) &&
            administrativeAreaList.push(
              obj.storefrontAddress.administrativeArea
            );
          !localityList.includes(obj.storefrontAddress.locality) &&
            localityList.push(obj.storefrontAddress.locality);
        });
        setLocalityListBackup(localityList);
      } else {
        setLocationsList([]);
      }
    });
  };
  const selectLocation = (e, data, id) => {
    if (
      e.target.checked === true &&
      !selectedLocationsArray.includes(data.name.split("/")[1])
    ) {
      setSelectedLocationsCompleteDataArray((object) => [...object, data]);
      setSelectedLocationsArray((object) => [
        ...object,
        data.name.split("/")[1],
      ]);
      setCreatePostButton(true);
    } else if (e.target.checked === false) {
      if (selectedLocationsArray.length !== locationsList.filter) {
        $("#selectAllLocations").prop("checked", false);
      }

      let index = selectedLocationsArray.indexOf(data.name.split("/")[1]);
      selectedLocationsArray.splice(index, 1);
      selectedLocationsCompleteDataArray.splice(index, 1);

      if (selectedLocationsArray.length === 0) {
        setCreatePostButton(false);
      } else {
        setCreatePostButton(true);
      }
    }
  };
  const selectAllLocations = (e) => {
    if (e.target.checked === true) {
      for (let i = 0; i < globalLocationId; i++) {
        $("#selectLocationPost" + i).prop("checked", true);
      }
      if (selectedLocationsArray.length > 0) {
        let filteredArray = locationsList.filter(
          (value) => !selectedLocationsArray.includes(value.name.split("/")[1])
        );
        filteredArray.map((obj) => {
          selectedLocationsArray.push(obj.name.split("/")[1]);
          selectedLocationsCompleteDataArray.push(obj);
        });
      } else {
        locationsList.map((obj) => {
          selectedLocationsArray.push(obj.name.split("/")[1]);
          selectedLocationsCompleteDataArray.push(obj);
        });
      }
      if (selectedLocationsArray.length === 0) {
        setCreatePostButton(false);
      } else {
        setCreatePostButton(true);
      }
    } else {
      for (let i = 0; i < globalLocationId; i++) {
        $("#selectLocationPost" + i).prop("checked", false);
      }
      setSelectedLocationsArray([]);
      setSelectedLocationsCompleteDataArray([]);
      setCreatePostButton(false);
    }
    console.log(selectedLocationsArray, "gooooooooooooooooooo");
    var selectAllList = [];
    // let includes = locationsList.some(item => selectedLocationsArray.includes(item.name.split("/")[1]))
    // console.log("foundfound", filteredArray);
  };
  const postPhotoToGBP = () => {
    $(".loder_div").attr("hidden", false);
    var totCount = selectedLocationsArray.length;
    selectedLocationsArray.forEach((object, j) => {
      location.state.images.forEach(async (data, i) => {
        var req = {
          functionName: "PostMediaGBP",
          account: window.sessionStorage.getItem("account_id"),
          locationId: object,
          category: location.state.setCategory,
          mediaFormat: location.state.mediaFormat,
          source: data,
          siteId:
            window.sessionStorage.getItem("switchingOrgId") === ""
              ? window.sessionStorage.getItem("organizationId")
              : window.sessionStorage.getItem("switchingOrgId"),
        };
        console.log(req, "reqreqreq" + j);
        await backendApi(req).then((data) => {});
      });
      totCount = totCount - 1;
      if (totCount === 0) {
        setTimeout(() => {
          alert("Images Posted Successfully");
          window.location.reload();
        }, 3000);
      }
    });

    $(".loder_div").attr("hidden", false);
  };
  return (
    <div className="main_wrapper  ">
      <LeftMenu />
      <div className="userListWrapper ">
        <Header heading="Google Post" headerImage={GooglePostimage} />
        <div className="container">
          <div className="row mb-3 ml-1">
            <Link to="/GBPPhotos" className="back-to-link mr-3">
              <Icon.ArrowLeftCircle />
            </Link>
            <div className="subhead"> Create New Post</div>
          </div>
          <div className="row row-search ">
            <div className="col-lg-2 col-md-4 col-sm-6">
              <select
                id="administrativeList"
                onChange={(e) => {
                  setFilter(e);
                }}
                className="form-control"
              >
                <option value="0">State</option>
                {administrativeAreaList.map((data) => (
                  <option value={data}>{data}</option>
                ))}
              </select>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 ">
              <select
                id="localityFilter"
                onChange={(e) => setFilter(e)}
                className="form-control"
              >
                <option value="0">City</option>
                {localityList.map((data) => (
                  <option value={data}>{data}</option>
                ))}
              </select>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 location_search">
              <input
                className="form-control"
                type="text"
                placeholder="Search Business "
                onChange={(e) => {
                  if (e.target.value.length > 3) {
                    var local = locationsList.filter((obj) => {
                      let title = obj.title.toLocaleLowerCase();
                      return title.includes(e.target.value.toLocaleLowerCase());
                    });
                    setLocationsList(local);
                  } else {
                    administrativeAreaFilterEnable === 1
                      ? setLocationsList(administrativeAreaFilterEnableArray)
                      : setLocationsList(temporaryLocationsList);
                  }
                }}
              />
              {/* <div className="location_search_drop">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <span>Location1</span>
                  </li>
                  <li className="nav-item">
                    <span>Location2</span>
                  </li>
                  <li className="nav-item">
                    <span>Location3</span>
                  </li>
                  <li className="nav-item">
                    <span>Location4</span>
                  </li>
                  <li className="nav-item">
                    <span>Location5</span>
                  </li>
                  <li className="nav-item">
                    <span>Location6</span>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
          <br />
          <div className="subhead" style={{ fontSize: "16px" }}>
            {" "}
            Select Location(s) to post
          </div>{" "}
          <hr className="mt-0"></hr>
          <div className="col-md-12 tableResponsive">
            <div className="tableOuterWidth">
              <table className="table">
                <thead>
                  <tr>
                    <th className="col-1">
                      {" "}
                      <input
                        type="checkbox"
                        name=""
                        id="selectAllLocations"
                        onClick={(e) => selectAllLocations(e)}
                      />{" "}
                    </th>
                    <th className="col-2">Location(s)</th>
                    <th className="col-4">Address</th>
                    <th className="col-3">Locality</th>
                    <th className="col-2">Area</th>
                  </tr>
                </thead>
                <div className="table_Outerscroll">
                  {locationsList.map((data, i) => (
                    <tbody className="table_Outertbody">
                      <tr>
                        <td className="col-1">
                          {" "}
                          <input
                            id={"selectLocationPost" + i}
                            type="checkbox"
                            name=""
                            onClick={(e) => selectLocation(e, data, i)}
                          />
                        </td>
                        <td className="col-2">{data.title}</td>
                        <td className="col-4">
                          {data.storefrontAddress.addressLines.join()}
                        </td>
                        <td className="col-3">
                          {data.storefrontAddress.locality}
                        </td>
                        <td className="col-2">
                          {data.storefrontAddress.administrativeArea}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </div>
              </table>
            </div>
          </div>
          {createPostButton === true && (
            <div className="button-wrapper pb-2 mb-3">
              <button
                type="button"
                className="btn-save ml-3 tag_Next btn-primary "
                onClick={() => postPhotoToGBP()}
              >
                Next
              </button>
            </div>
          )}
          <br />
        </div>
      </div>
      <div className="loder_div" hidden />
      <Footer />
    </div>
  );
}

export default SelectLocations;
