import React, { Component } from "react";
import Header from "../layout/header";
import rolesimage from "../images/left_icons/roles.png";
import LeftMenu from "../layout/leftMenu";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { pythonBackendApi, apiCallingPost } from "../apiService";
import $ from "jquery";
export default class BusinessInsights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataList: [],
      dataFlag: window.sessionStorage.getItem("insightsDataSave") == "" ? 0 : 1,
      time:
        window.sessionStorage.getItem("insightsDataSave") == ""
          ? moment().unix()
          : window.sessionStorage.getItem("insightsDataSave"),
      insightsStartDate: moment(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 10
        )
      ).format("yyyy/MM/DD"),
      insightsEndDate: moment(new Date()).format("yyyy/MM/DD"),
    };
  }
  componentDidMount() {
    const interval = setInterval(() => {
      this.apiCallEveryOneminute();
    }, 10000);
  }
  insightsDataGettingByDate() {
    let newTime = moment().unix();
    if (!window.confirm("Do You Want To Fetching insights ?")) {
      return false;
    }
    window.sessionStorage.setItem("insightsDataSave", "");
    this.setState({
      dataList: [],
      time: newTime,
      dataFlag: "1",
    });
    // $(".loder_div").attr("hidden", false);
    var req = {
      method: "POST",
      body: JSON.stringify({
        startDate: moment(this.state.insightsStartDate).format("DD/MM/yyyy"),
        endDate: moment(this.state.insightsEndDate).format("DD/MM/yyyy"),
        masterId: newTime,
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
      }),
    };
    pythonBackendApi("insightsDataGettingByDate", req).then((data) => {
      if (data.success == "1") {
        // $(".loder_div").attr("hidden", true);
        alert("Fetching completed.");
      } else {
        alert("Something went wrong to fetch business insights data");
      }
    });
  }
  setfromtDate(date, type) {
    // this.insightsDataGettingByDate();
    // console.log("date", date);
    // console.log("type", type);
    // if (type === "fromDate") {
    //   this.setState(
    //     {
    //       insightsStartDate: moment(new Date(date)).format("yyyy/MM/DD"),
    //     },
    //     () => {
    //       console.log("ahahahahaha", this.state.insightsStartDate);
    //     }
    //   );
    // }
  }
  // $(".loder_div").attr("hidden", true);
  apiCallEveryOneminute() {
    var req = {
      functionName: "GetInsightsDataListing",
      masterId: this.state.time,
    };
    apiCallingPost(req).then((data) => {
      if (data.success == "1") {
        let array = [];
        $('#mainDiv').animate({ scrollTop: $('#mainDiv').prop("scrollHeight") }, 600);
        this.setState({
          dataList: data.data,
          dataFlag: "0",
        });
      } else {
        alert("Something went wrong to fetch business insights data");
      }
    });
  }
  insightsDataSave() {
    {
      window.sessionStorage.setItem("insightsDataSave", this.state.time);
    }
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper">
          <Header
            heading="Fetch Business Insight Data"
            headerImage={rolesimage}
          />
          <div className="container-fluid">
            <div className="row justify-content-between mb-3 mx-0">
              <div className="col-md-12 mt-3">
                {/* <div className="visAccuracy_head">Latest Update({moment(latestUpdate).format('YYYY-MM-DD')})</div> */}
                <hr className="mt-1" />
                <div className="Date_fromToDiv mt-2">
                  <span className="Date_fromToText">From</span>
                  <div className="Date_fromTo">
                    <ReactDatePicker
                      selected={new Date(this.state.insightsStartDate)}
                      // selected={new Date(new Date().getFullYear() - 1, 0, 1)}
                      dateFormat="dd/MM/yyyy"
                      dateFormatCalendar="MMMM"
                      maxDate={new Date()}
                      id="fromdate"
                      onChange={(obj) => {
                        // this.setfromtDate();
                        let startDate = new Date(obj);
                        this.setState({
                          insightsStartDate: startDate,
                          // insightsDataKey: startDate.toISOString(),
                        });
                      }}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                  <span className="Date_fromToText">To</span>
                  <div className="Date_fromTo">
                    <ReactDatePicker
                      selected={new Date(this.state.insightsEndDate)}
                      dateFormat="dd/MM/yyyy"
                      dateFormatCalendar="MMMM"
                      maxDate={new Date()}
                      onChange={(obj) => {
                        // this.setfromtDate();
                        let endDate = new Date(obj);
                        this.setState({
                          insightsEndDate: endDate,
                          // insightsDataKey: endDate.toISOString(),
                        });
                      }}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                  <button
                    className="btn btn-success"
                    onClick={() => this.insightsDataGettingByDate()}
                  >
                    Run
                  </button>
                </div>
                Clicking on "Run" will fetch the Business Insights for {" => "}
                {window.sessionStorage.getItem("switchUserOrganization")}.
              </div>
            </div>
            <div className="rebooting_wrapr">
              <div className="rebooting_process" id="mainDiv">
                {this.state.dataFlag == "1" ? (
                  <p className="rebooting_pro_text">
                    Please Wait Data Fetching..........
                  </p>
                ) : (
                  <>
                    {this.state.dataList.map((item, i) => (
                      <p className="rebooting_pro_text"id="mainDiv" >
                        {item.location_id} - {item.city} - {item.state} -{" "}
                        {item.created_on} - DONE
                      </p>
                    ))}
                  </>
                )}
              </div>
              <div className="text-right">
                <button
                  className="btn btn-success my-1"
                  onClick={() => this.insightsDataSave()}
                >
                  Save session
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="rebooting_wrapr">
          <div className="col">
            {this.state.dataList.map((item, i) => (
              <ul className="progressbar_monitering" >
                {console.log(item,"vvvvvvvvvvvvvvvvv")}
                <li className="step_one" id="testID1"  >{item.id}</li>
              </ul>
            ))}
          </div>
        </div> */}
        <div className="loder_div" hidden />
      </div>
    );
  }
}
