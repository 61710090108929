import React, { useState, useEffect } from "react";
import SaveAndValidation from "./settingsSaveAndValidation"
function SettingYoutube(props) {
    var orgadmin = window.sessionStorage.getItem("role") == "ORG_ADMIN";
    const [ytKey, setytKey] = useState((props.listingValuesForPassing.youtube_key != "null" || props.listingValuesForPassing.youtube_key != "undefined" || !props.listingValuesForPassing.youtube_key) ? props.listingValuesForPassing.youtube_key : "");
    useEffect(() => {
        if (props.listingValuesForPassing.youtube_key && (props.listingValuesForPassing.youtube_key != "null" || props.listingValuesForPassing.youtube_key != "undefined")) {

            setytKey((props.listingValuesForPassing.youtube_key))
        } else { setytKey("") }
    }, [props.listingValuesForPassing.youtube_key

    ])
    return (
        
        <div className="row">
                <div className="col-md-12">
            <div className="user_formdiv">
                <div className="userform_head">Youtube Credentials</div>
                <div className="row userrow_form">
                    <div className="col-md-4">
                        <label className="col-form-label">YouTube Key</label>
                    </div>
                    <div class="col-md-1"><span>:</span></div>
                    <div className="col-md-7">
                        <input
                            type="text"
                            id="youTubeKey"
                            value={ytKey}
                            onChange={(e) => {
                                setytKey(e.target.value);
                            }}
                            className="form-control"
                            placeholder="YouTube Key"
                        />
                    </div>
                </div>
                <SaveAndValidation
                     getUserData={() => props.getUserData()}
                    valueForSaved={ytKey}
                    saveMode={"Youtube"}
                    organizationIdFromYoutube={props.organizationId} />
            </div>
        </div>
        </div>
        
    );

}
export default SettingYoutube;
