import React, { useEffect } from "react";
import LeftMenu from "../layout/leftMenu";
import ProductCatalogimage from "../images/left_icons/Product-Catalog.png";
import Header from "../layout/header";
import * as Icon from "react-feather";
import * as XLSX from "xlsx";
import DragDropFile from "./dragDropFile";
import DataInput from "./dataInput";
import { Link } from "react-router-dom";
import { backendApi } from "../apiService";
import Footer from "../layout/footer";
import Pagination from "react-js-pagination";
import $ from "jquery";
import moment from "moment";

const excelMime = [
  "application/vnd.ms-excel",
  "application/msexcel",
  "application/x-msexcel",
  "application/x-ms-excel",
  "application/x-excel",
  "application/x-dos_ms_excel",
  "application/xls",
  "application/x-xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
export default function CompleteReportPage() {
  const [uploaded, setUploaded] = React.useState(false);
  const [dataList, setDataList] = React.useState([]);
  const [reportList, setReportList] = React.useState([]);
  const [fileName, setFileName] = React.useState("");
  const [isLoader, setIsLoader] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [offset, setOffset] = React.useState(0);
  const [completedCount, setCompletedCount] = React.useState(0);
  const [totalLocationCount, setTotalLocationCount] = React.useState(0);
  const [qaDone, setQaDone] = React.useState(0);
  const [qaPending, setQaPending] = React.useState(0);
  const [qaFailed, setQaFailed] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [filter, setFilter] = React.useState(0);
  const [downloadList, setDownloadList] = React.useState([]);

  React.useEffect(() => {
    getProductCatalogueReport(0);
    // getAccountLocations();
  }, []);
  const handleFile = async (files) => {
    if (files) {
      if (!excelMime.includes(files.type)) {
        alert("Please upload valid excel file");
        return false;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        setIsLoader(0);
        const ab = e.target.result;
        const wb = XLSX.read(ab, { type: "array" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { raw: false, header: 1 });

        // const desiredStringColumns = [
        //   "Store Code",
        //   // "Location Id",
        //   "Account Id",
        // ]; // Replace with actual column names
        // const data = XLSX.utils.sheet_to_json(ws, {
        //   raw: false,
        //   header: 1,
        //   defval: "", // Set default value for empty cells
        //   decodeCell: (cell, worksheet) => {
        //     const col = worksheet["!ref"].split("!")[1].charAt(cell.c); // Get column letter
        //     debugger;
        //     if (desiredStringColumns.includes(col)) {
        //       return (`'hello${XLSX.utils.decode_cell(cell).toString()}`); // Convert to string
        //     }
        //     return XLSX.utils.decode_cell(cell); // Use default parsing for other columns
        //   },
        // });
        // console.log("🚀 ~ handleFile ~ data:", data);
        let DataList = [];
        let DataListTemp = [];
        let keys = [];
        keys = data[0];

        let record = {};
        for (let i = 1; i < data.length; i++) {
          let dd = data[i];
          if (dd.length > 0) {
            for (let j = 0; j < dd.length; j++) {
              record[keys[j].trim().split(" ").join("")] =
                dd[j] === undefined ? "" : dd[j].toString().trim();
            }
            DataList.push(record);
            DataListTemp.push(record);
            record = {};
          }
        }
        setDataList(DataList);
        setFileName(files.name);
        setUploaded(true);
        setIsLoader(1);
      };
      reader.readAsArrayBuffer(files);
    }
  };
  const OutTable = () => {
    return (
      <div>
        <div className="col-md-12 tableResponsive">
          <table className="table fileupload-table mb-0">
            <thead>
              <tr>
                <th className="col-1">Id</th>
                <th className="col-3">Store Code</th>
                <th className="col-3">Location</th>
                {/* <th className="col-3">Location Id</th> */}
                <th className="col-2">Status</th>
              </tr>
            </thead>
            <div className="table_Outerscroll">
              {dataList.length > 0 &&
                dataList.map((r, i) => {
                  return (
                    <tbody key={`Out${i}`} className="table_Outertbody">
                      <tr>
                        <td className="col-1">{i + 1}</td>
                        <td className="col-3">{r.StoreCode}</td>
                        <td className="col-3">
                          {r.Title},{r.AdministrativeArea},{r.AddressLines}
                        </td>
                        {/* <td className="col-3">{r.LocationId}</td> */}
                        <td className="col-2">{r.Status}</td>
                      </tr>
                    </tbody>
                  );
                })}
            </div>
          </table>
        </div>
        <div className="button-wrapper mb-4 mt-3">
          <button className="btn-submit ml-3" onClick={() => onSave()}>
            Upload File
          </button>
          <button className=" btn-cancel ml-3" onClick={() => onCancel()}>
            Cancel
          </button>
        </div>
      </div>
    );
  };
  const onSave = () => {
    $(".loder_div").attr("hidden", false);
    let key = dataList.some((obj) => !obj["LocationId"])
      ? "storeCode"
      : "locationId";
    debugger;
    let dataSet =
      key == "storeCode"
        ? dataList.map((o) => o.StoreCode)
        : dataList.map((o) => o.LocationId.replace(/^'/, ""));

    const api = {
      functionName: "uploadCompleteReport",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      key,
      dataList: dataSet,
    };
    backendApi(api).then((response) => {
      $("loder_div").attr("hidden", true);
      if (response.success === "1") {
        alert("Uploaded successfully");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        alert("Something went wrong uploading file");
        console.error("Error Response", response);
      }
    });
  };
  const onCancel = () => {
    window.location.reload();
  };
  const getProductCatalogueReport = (offset = 0, filter = 0) => {
    const api = {
      functionName: "getCompleteProductCatalogueReport",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      offset: offset,
      search: search,
      filter,
    };
    backendApi(api).then((response) => {
      if (response.success === "1") {
        setOffset(offset);
        if (offset === 0) setCurrentPage(1);
        setFilter(filter);
        setReportList(response.data);
        setTotalCount(response.totalCount);
        setTotalLocationCount(response.statusCounts.total_count);
        setCompletedCount(response.statusCounts.completed_count);
        setQaDone(response.statusCounts.qa_done);
        setQaPending(response.statusCounts.qa_pending);
        setQaFailed(response.statusCounts.qa_failed);
        // setFailedCount(response.statusCounts.failed_count);
      } else {
        alert("Error fetching Complete Report");
      }
    });
  };
  const changeStatus = (id, qa) => {
    const api = {
      functionName: "changeCatalogueQAStatus",
      dataId: id,
      qa: qa,
    };
    backendApi(api).then((data) => {
      if (data.success === "1") {
        // alert("");
        getProductCatalogueReport(offset, filter);
      } else {
        alert("Something went wrong");
      }
    });
  };
  const handleActivePage = (pageNumber) => {
    setCurrentPage(pageNumber);
    let offset = (Number(pageNumber) - 1) * 100;
    setOffset(offset);
    getProductCatalogueReport(offset, filter);
  };
  // useEffect(() => {}, [offset]);

  // const getAccountLocations = () => {
  //   const api = {
  //     functionName: "getAccountLocations",
  //     siteId:
  //       window.sessionStorage.getItem("switchingOrgId") === ""
  //         ? window.sessionStorage.getItem("organizationId")
  //         : window.sessionStorage.getItem("switchingOrgId"),
  //     accounts_id: window.sessionStorage.getItem("account_id"),
  //   };
  //   backendApi(api).then((response) => {
  //     if (response.success === "1") {
  //       setTotalLocationCount(response.totalCount);
  //     }
  //   });
  // };
  const downloadExcel = (
    filter = 0,
    offset = 0,
    repeat = false,
    array = []
  ) => {
    if (repeat) {
      const api = {
        functionName: "downloadCompleteProductCatalogueReport",
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        offset: offset,
        search: search,
        filter: filter,
      };
      backendApi(api).then((response) => {
        if (response.success === "1") {
          let tempArray = [...array, ...response.data];
          // setDownloadList(tempArray);
          let totalCount = response.totalCount;
          let nextOffset = offset + 500;
          let remainingCount = totalCount - nextOffset;
          if (remainingCount <= 0) {
            const api = {
              functionName: "downloadCompleteProductCatalogueReport",
              siteId:
                window.sessionStorage.getItem("switchingOrgId") === ""
                  ? window.sessionStorage.getItem("organizationId")
                  : window.sessionStorage.getItem("switchingOrgId"),
              offset: nextOffset,
              search: search,
              filter: filter,
            };
            backendApi(api).then((response) => {
              if (response.success === "1") {
                let tempArray2 = [...tempArray, ...response.data];
                console.log("🚀 ~ backendApi ~ tempArray:", tempArray2);
                // setDownloadList((props) => [...props, ...response.data]);
                exportExcelData(tempArray2, filter);
              }
            });
          } else {
            downloadExcel(filter, nextOffset, true, tempArray);
          }
        }
      });
    } else {
    }
  };

  const exportExcelData = (excelData, filter) => {
    console.log("🚀 ~ exportExcelData ~ excelData:", excelData);
    let accountList = excelData.map((data, index) => {
      let json = "";
      return {
        ID: index + 1,
        "Store Code": data.store_code,
        "Location Name": data.title,
        Address: data.address_lines,
        "Administrative Area": data.administrative_area,
        Link: data.link,
      };
    });
    console.log("🚀 ~ accountList ~ accountList:", accountList);

    let excelName = "";
    let sheetName = "";
    if (filter === 0) {
      excelName = "ProductCatalogueTotalList_";
      sheetName = "Total";
    } else if (filter === 1) {
      excelName = "ProductCatalogueCompletedList_";
      sheetName = "Completed";
    } else if (filter === 2) {
      excelName = "ProductCatalogueQADoneList_";
      sheetName = "QA Done";
    } else if (filter === 3) {
      excelName = "ProductCatalogueQAFailedList_";
      sheetName = "QA Failed";
    } else if (filter === 4) {
      excelName = "ProductCatalogueTotalList_";
      sheetName = "QA Pending";
    }
    const now = new Date();
    let pageDate = moment(now).format("DD_MM_YY_HH:mm");
    const worksheet = XLSX.utils.json_to_sheet(accountList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    XLSX.writeFile(workbook, excelName + pageDate + ".xlsx");
    console.log(
      "🚀 ~ file: googleBusinessLocation.jsx:317 ~ GoogleBusinessLocation ~ exportExcelData ~ accountList:",
      accountList
    );
    // this.exportXlsx(accountList, fileName, fileType);
  };
  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header
          heading="Product Catalog Report"
          headerImage={ProductCatalogimage}
        />
        <div className="container">
          <div className="back_tocatalog row">
            <div className="col-lg-1 d-flex">
              <div className="back_tocatalog">
                <div className="back_tocatalog_main">
                  <Link to="/home">
                    <Icon.ArrowLeftCircle className="back-to-link" />
                    {/* Back to Product Catalog */}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {Number(window.sessionStorage.getItem("roleId")) === 1 && (
            <div className="col-lg-11 settings_form">
              <div className="file_upload_outer">
                <DragDropFile handleFile={handleFile}>
                  <div
                    className="form-group formBottom pl-4 justify-content-start"
                    id="form-fileupload"
                  >
                    <DataInput handleFile={handleFile} />
                  </div>
                </DragDropFile>
                {uploaded === true && OutTable()}
              </div>
            </div>
          )}
          <div>
            <div className="report-wrapper">
              <p className="report-inner-wrapper">
                <button
                  type="button"
                  className="btn btn-secondary mr-2"
                  onClick={() => getProductCatalogueReport(0, 0)}
                >
                  Total Locations: {totalLocationCount}
                </button>
                <Icon.Download
                  size={"15px"}
                  color="#0067b9"
                  onClick={() => downloadExcel(0, 0, true)}
                />
              </p>
              <p className="report-inner-wrapper">
                <button
                  type="button"
                  className="btn btn-secondary mr-2"
                  onClick={() => getProductCatalogueReport(0, 1)}
                >
                  Completed Locations: {completedCount}
                </button>
                <Icon.Download
                  size={"15px"}
                  color="#0067b9"
                  onClick={() => downloadExcel(1, 0, true)}
                />
              </p>

              <p className="report-inner-wrapper">
                <button
                  type="button"
                  className="btn btn-success mr-2"
                  onClick={() => getProductCatalogueReport(0, 2)}
                >
                  QA Done: {qaDone}
                </button>
                <Icon.Download
                  size={"15px"}
                  color="#0067b9"
                  onClick={() => downloadExcel(2, 0, true)}
                />
              </p>
              <p className="report-inner-wrapper">
                <button
                  type="button"
                  className="btn btn-failed mr-2"
                  onClick={() => getProductCatalogueReport(0, 3)}
                >
                  QA Failed: {qaFailed}
                </button>
                <Icon.Download
                  size={"15px"}
                  color="#0067b9"
                  onClick={() => downloadExcel(3, 0, true)}
                />
              </p>
              <p className="report-inner-wrapper">
                <button
                  type="button"
                  className="btn btn-secondary mr-2"
                  onClick={() => getProductCatalogueReport(0, 4)}
                >
                  QA Pending: {qaPending}
                </button>
                <Icon.Download
                  size={"15px"}
                  color="#0067b9"
                  onClick={() => downloadExcel(4, 0, true)}
                />
              </p>
            </div>
          </div>
          <div className="row justify-content-between mb-3 mx-0">
            <div className="searchSection">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by keyword"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-search"
                    type="button"
                    onClick={() => getProductCatalogueReport(0)}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 tableResponsive">
            <div className="tableOuterWidth">
              <table className="table">
                <thead>
                  <tr>
                    <th className="col-1" style={{ maxWidth: "5%" }}>
                      Sl.no
                    </th>
                    <th className="col-3">Store Code</th>
                    <th className="col-5">Location Link</th>
                    <th className="col-2">Status</th>
                    <th className="col-1">QA Done</th>
                  </tr>
                </thead>
                <div
                  className="table_Outerscroll"
                  style={{ overflow: "overlay" }}
                >
                  {reportList.length !== 0 ? (
                    <>
                      {reportList.map((data, i) => (
                        <tbody className="table_Outertbody" key={data.id}>
                          <tr>
                            <td className="col-1">{offset + (i + 1)}</td>
                            <td className="col-3">{data.store_code}</td>
                            <td className="col-5">
                              <a href={data.link} target="_blank">
                                <span
                                  id={"location" + i}
                                  style={{
                                    fontFamily: "OpenSans SemiBold",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {data.title}
                                </span>
                                <span style={{ display: "block" }}>
                                  {data.administrative_area},
                                  {data.address_lines}
                                </span>
                              </a>
                            </td>
                            <td className="col-2">
                              <span id={"status" + i}>{data.status}</span>
                            </td>
                            <td
                              className="col-1"
                              onClick={() => changeStatus(data.id, 1)}
                              onDoubleClick={() => changeStatus(data.id, 2)}
                            >
                              {data.qa_done === 0 && (
                                <Icon.Circle color="grey" />
                              )}
                              {data.qa_done === 1 && (
                                <Icon.CheckCircle color="green" />
                              )}
                              {data.qa_done === 2 && (
                                <Icon.XCircle color="red" />
                              )}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </>
                  ) : (
                    <tr>No Data Available</tr>
                  )}
                </div>
              </table>
            </div>
            {totalCount > 100 && (
              <Pagination
                hideDisabled
                activePage={currentPage}
                itemsCountPerPage={100}
                totalItemsCount={totalCount}
                onChange={(e) => handleActivePage(e)}
              />
            )}
          </div>
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
}
