import React, { useEffect, useRef, useState } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import QandA from "../images/left_icons/qa.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-feather";
import Modal from "react-modal";
import { FiArrowRightCircle } from "react-icons/fi";
import { backendApi } from "../apiService";
import ApproverPopup from "../adv_generator/approverPopup";
import SendApprovalPopup from "./sendApprovalPopupRequirement";
import $ from "jquery";
import moment from "moment";
import Switch from "react-switch";

function RequirementGathering() {
  const location = useLocation();
  const navigate = useNavigate();
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false);
  const [campaignData, setCampaignData] = useState([]);
  const [answerData, setAnswerData] = useState([]);
  const [campaign, setCampaign] = useState();
  const [textValue, setTextValue] = useState("");
  const [textEditValues, setTextEditValues] = useState();
  const [questionId, setQuestionId] = useState("");
  const [editModes, setEditModes] = useState();
  const [showApprovalPopup, setShowApprovalPopup] = useState(false);
  const [groupData, setGroupData] = useState([]);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [question, setQuestion] = useState("");
  const [questionStatus, setQuestionStatus] = useState("");
  const [content, setContent] = useState("");
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [questionAnswerIds, setQuestionAnswerIds] = useState([]);
  const [flag, setFlag] = useState();
  const [flag1, setFlag1] = useState(false);
  const [Position, setPosition] = useState("");

  const scrollToAnswers = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling behavior
    });
  };

  useEffect(() => {
    $(document).ready(function () {
      // Hide all submenus initially
      $(".subMenubar.Creatify").show();
      $(".req-gather").addClass("active");
      $(".req-gather")[0].scrollIntoView();
    });
    groupDataInit();
    contentInit();
    campaignInit();
  }, []);

  const contentInit = () => {
    const apiReq = {
      functionName: "content_init",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apiReq).then((data) => {
      if (data.success == "1") {
        const transformedOptions = data.campaign_name.map(
          (campaign, index) => ({
            value: campaign.id,
            label: campaign.campaign_name,
          })
        );
        const campaignSelected = transformedOptions.filter(
          (campaign) => campaign.value == location.state.props
        );
        console.log("adfffffffda", transformedOptions);
        console.log("adfffffffda", location.state.props);
        setCampaign(campaignSelected[0]);
        setCampaignOptions(transformedOptions);
      } else {
        console.log("Error in content init api");
      }
    });
  };

  const updateReference = (id, newStatus) => {
    console.log("newStatus",newStatus);
    var req = {
      functionName: "requirementStatusChange",
      is_reference: newStatus ? 1 : 0,
      requirementId: id,
    };
    backendApi(req).then((data) => {
      if (data.success ==="1") {
        campaignInit();
        answerAdded();
      } else {
        alert("Something went wrong while updating status");
      }
    });
  };

  const groupDataInit = () => {
    const apiReq = {
      functionName: "content_approver_group_data",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apiReq).then((data) => {
      if (data.success == "1") {
        setGroupData(data.dataSet);
        console.log("adfffffffda", data);
      } else {
        console.log("Error in groupdata api");
      }
    });
  };

  const campaignInit = () => {
    const postData = {
      functionName: "campaign_listing_details",
      campaign_id: location.state.props,
    };
    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          const sortedCampaignData = data.campaign_question.sort(
            (a, b) => a.campaign_questions_master_id - b.campaign_questions_master_id
          );
          console.log("Sorted Campaign Data:", sortedCampaignData);
          setCampaignData(sortedCampaignData);
          setFlag(data.showSendApproveButton);
          setFlag1(
            data.campaign_question.every(
              (data) =>
                data.status === "Requested" || data.status === "Approved"
            )
          );
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };

  const campaignEdit = (data, id, question, status, index) => {
    setSelectedImageIndex(index);
    setShowAnswers(true);
    setAnswerData(data);
    setEditModes(new Array(answerData.length).fill(false));
    setTextEditValues(new Array(answerData.length).fill(""));
    setQuestionId(id);
    setQuestion(question);
    setQuestionStatus(status);
  };

  const toggleEditMode = (index, data) => {
    const newEditModes = [...editModes];
    newEditModes[index] = !newEditModes[index];
    setEditModes(newEditModes);
    const newEditModesValue = [...textEditValues];
    newEditModesValue[index] = data;
    setTextEditValues(newEditModesValue);
  };

  const toggleEditMod = (index) => {
    const newEditModes = [...editModes];
    newEditModes[index] = !newEditModes[index];
    setEditModes(newEditModes);
  };

  const back = () => {
    navigate(-1);
  };

  const campaignSaveDirectEdit = (value, data, id) => {
    if (data.trim() === "") {
      alert("Please enter a question");
      return false;
    }
    let answer=data.replace(/['"]/g, "\\$&");
    const postData = {
      functionName: "campaign_question_answer_update",
      sec_user_id:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      id: id,
      answer_text: answer,
      status: value,
    };
    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          campaignInit();
          answerAdded();
          setEditModes(new Array(answerData.length).fill(false));
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };

  const campaignSave = (value) => {
    if (textValue.trim() === "") {
      alert("Please enter a question");
      return false;
    }
    let answer=textValue.replace(/['"]/g, "\\$&");
    const postData = {
      functionName: "campaign_question_answer_save",
      created_by:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      campaign_question_id: questionId,
      answer_text: answer,
      status: value,
    };

    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          campaignInit();
          answerAdded();
          setTextValue("");
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };

  const handleTextEditChange = (index, value) => {
    const newTextEditValues = [...textEditValues];
    newTextEditValues[index] = value;
    setTextEditValues(newTextEditValues);
  };

  const answerAdded = () => {
    const postData = {
      functionName: "campaign_listing_details",
      campaign_id: location.state.props,
    };
    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          console.log("new", data);
          console.log("new", questionId);
          const answerData = data.campaign_question.filter(
            (campaign) => campaign.id === questionId
          );
          console.log("answerData", answerData);
          setAnswerData(answerData[0].answer);
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };

  function formatDate(timestamp) {
    return moment(timestamp).format("DD-MM-YYYY h:mm A");
  }

  const sendForApproval = (text, data) => {
    setContent(text);
    const newArray = campaignData
      .filter((data) => data.status !== "Approved")
      .map((data) => ({
        content_text:
          data.answer.length > 0
            ? data.answer[data.answer.length - 1]?.answer_text
            : "",
        content_image: data.question_text,
        content_media: "",
        campaign_question_answer_id:
          data.answer.length > 0 ? data.answer[data.answer.length - 1]?.id : "",
        campaign_question_id: data.id,
      }));
    setQuestionAnswerIds(newArray);
    setShowApprovalPopup(true);
  };

  const closePopup = () => {
    // getRequestedData();
    setShowApprovalPopup(false);
    // setShowAddCampaignPopup(false);
  };

  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Requirement Gathering" headerImage={QandA} />
        <div className="container-fluid">
          <div className="container">
            <div className=" text-right">
              <button
                type="submit"
                className="btn btn-secondary mr-2"
                onClick={() => back()}
              >
                Back
              </button>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="d-md-flex">
                  {campaign && <h3 className="mb-0">{campaign.label}</h3>}
                  {flag !== "DontShow" && flag1 === false && (
                    <button
                      className="btn btn-primary ml-auto"
                      onClick={() => sendForApproval()}
                    >
                      Send for approval
                    </button>
                  )}
                </div>

                <div className="requirement">
                  {campaignData?.length > 0 ? (
                    <ul className="requirement_ul">
                      {campaignData?.map((data, i) => (
                        <li
                          className={`requirement_li ${
                            selectedImageIndex === i ? "active" : ""
                          }`}
                          key={i}
                        >
                          <div className="requriement_summary">
                            <div className="summery_header">
                              <div className="summery-heading">Question</div>
                              <div className="summery-para">
                                {data.question_text}
                              </div>
                            </div>
                            <div className="summery_links">
                              {/* <button className="alert alert-primary mr-2">Save</button> */}
                              <button className="alert alert-warning mr-3">
                                {data.status}
                              </button>
                              {/* <button className="alert alert-success mr-2">Approve</button> */}
                              <FiArrowRightCircle
                                className="text-primary"
                                style={{ width: "23px", height: "23px" }}
                                onClick={(e) => {
                                  campaignEdit(
                                    data.answer,
                                    data.id,
                                    data.question_text,
                                    data.status,
                                    i
                                  );
                                  var elem = e.target;
                                  var rect = elem.getBoundingClientRect();
                                  var scrollTop =
                                    document.documentElement.scrollTop;
                                  const absoluteY = scrollTop + rect.top;
                                  setPosition(absoluteY);
                                  // scrollToAnswers();
                                }}
                              />
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="col-md-12 tableResponsive mt-4">
                      <div className="summery-heading mb-2">
                        No Requirements Added
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {showAnswers && (
                <div
                  className="col-md-6 card mb-3"
                  
                  style={{
                    top: Position + "px",
                    position: "absolute",
                    right: "0",
                    "margin-top": "-90px",
                    overflow: "auto",
                    borderLeft: "1px solid #eee"
                  }}
                >
                  <div className="requirement">
                    <div>
                      <ul className="requirement_ul">
                        <div className="summery-heading mb-2"> Answer</div>
                        {answerData?.map((data, i) => (
                          <li className="requirement_li" key={i}>
                            <div className="requriement_summary">
                              <div className="summery_header w-100">
                                {!editModes[i] && (
                                  <div className="summery-para">
                                    {data.answer_text}
                                  </div>
                                )}
                              </div>
                             
                              {editModes[i] && (
                                <div className="summery_links w-100">
                                  <textarea
                                    rows="4"
                                    className="form-control-text-brdr form-control-text w-100 mt-1"
                                    value={textEditValues[i]}
                                    onChange={(e) =>
                                      handleTextEditChange(i, e.target.value)
                                    }
                                  ></textarea>

                                  <button
                                    className="alert alert-primary mr-2"
                                    onClick={() =>
                                      campaignSaveDirectEdit(
                                        "Save",
                                        textEditValues[i],
                                        data.id
                                      )
                                    }
                                  >
                                    Save
                                  </button>

                                  <button
                                    className="alert alert-success ml-2"
                                    onClick={() => toggleEditMod(i)}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              )}
                            </div>
                            
                            {(data.status === "Approved" ||
                              data.status === "Save") && (
                              <Icon.Edit
                                className="requirement_edit_icon mb-1"
                                onClick={() =>
                                  toggleEditMode(i, data.answer_text)
                                }
                              />
                            )}
                            <div className="d-md-flex">
                            <div className="d-md-flex">
                            <input
                                type="checkbox"
                                className=""
                                name={"status" + data.id}
                                onClick={() => updateReference(data.id,!data.is_reference)}
                                checked={data.is_reference}                                
                              />
                              <label
                                className="form-check-label ml-2"
                              >
                                Is Reference
                              </label>
                            </div>
                            {data.status != "Replied" ? (
                              <div
                                className="requirement_date ml-auto"
                                style={{
                                  background: "#c3e9cd",
                                  padding: "1px 10px",
                                  borderRadius: "5px",
                                  width: "fit-content",
                                }}
                              >
                                Added by {data.created_by_name}
                                <span className="text-dark">
                                  {formatDate(data.created_on)}
                                </span>
                              </div>
                            ) : (
                              <div
                                className="requirement_date ml-auto"
                                style={{
                                  background: "#5992cf",
                                  padding: "1px 10px",
                                  borderRadius: "5px",
                                  width: "fit-content",
                                }}
                              >
                                Replied by {data.created_by_name}
                                <span className="text-dark">
                                  {formatDate(data.created_on)}
                                </span>
                              </div>
                            )}
                            </div>
                           
                            
                           
                          </li>
                        ))}
                      </ul>
                      {answerData[answerData.length - 1]?.status != "Save" &&
                        answerData[answerData.length - 1]?.status !=
                          "Approved" && (
                          <div className="requriement_summary">
                            <div className="summery_header w-100">
                              <div className="summery-heading"></div>
                              <textarea
                                rows="4"
                                className="form-control-text-brdr form-control-text w-100"
                                value={textValue}
                                onChange={(e) => setTextValue(e.target.value)}
                              ></textarea>
                            </div>
                            <div className="summery_links w-100">
                              <button
                                className="alert alert-primary mr-2"
                                onClick={() => campaignSave("Save")}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="loder_div" hidden />
      {showApprovalPopup && (
        <SendApprovalPopup
          onClose={closePopup}
          groupData={groupData}
          textContent={content}
          questionContent={question}
          typeOfContent={"Text Content"}
          campaignOptions={campaignOptions}
          selectedCampaign={campaign}
          questionAnswerId={questionAnswerIds}
          campaignCall={campaignInit}
        />
      )}
    </div>
  );
}

export default RequirementGathering;
