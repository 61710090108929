import React, { Component } from "react";
// import FileSaver from 'file-saver';
import "../css/bootstrap.css";
import "../css/style.css";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import Accessibiltyimage from "../images/left_icons/Accessibility-and-visibility.png";
import Auditimage from "../images/left_icons/audit.png";
import { TagsInput } from "react-tag-input-component";
import cloudImg from "../images/cloud-computing.png";
import deleteImg from "../images/Group122.png";
import DragDropFile from "../audit/dragDropFile";
import DataInput from "../audit/dataInput";
import { backendApi, pythonBackendApi } from "../apiService";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import uploadImg from "../images/upload.png";
import $ from "jquery";
import closeImg1 from "../images/close1.png";
import * as Icon from "react-feather";
import exportFromJSON from "export-from-json";
import GoogleIcon from "../images/google.png";
import moment from "moment";
import Pagination from "react-js-pagination";
import Edit from "../images/edit.png";
import Address from "../images/address-match.png";
import VisibilityAccuracyGraph from "./visibility_accuracyGraph";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// var tempPostList = [];
var total_location = 0;
var address_match = 0;
var address_match_percentage = 0;
var phone_no_match = 0;
var phoneno_match_percentage = 0;
var business_name_match_percentage = 0;
var business_name_match = 0;
var totalNumber = 0;
var latestUpdate = 0;
export default class VisibilityAccuracyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      totalCount: 0,
      backupdata: [],
      tempLocationsList: [],
      languageListTTS: [],
      apiDataList: [],
      csvPushData: [],
      submode: "",
      filter: "",
      mode: "",
    };
  }
  componentDidMount() {
    this.setState({
      filter: window.location.pathname.split("/")[3],
      mode: window.location.pathname.split("/")[2],
    });
    // this.getVisibilityAndAccuracyDataByDate();
    this.getPropsData();
  }
  getPropsData() {
    let mode = window.location.pathname.split("/")[2];
    console.log("moooode", mode);
    let propsData =
      mode === "visibility"
        ? this.props.apiDataVisList
        : this.props.apiDataList;
    if (propsData.length > 0) {
      this.setState({
        totalCount: this.props.apiDataList.length,
        submode: this.props.subModeFlag,
      });
      console.log("props", this.props);
      const indexOfLastPost = this.state.currentPage * 25;
      const indexOfFirstPost = indexOfLastPost - 25;
      const currentPosts = propsData.slice(indexOfFirstPost, indexOfLastPost);
      this.setState({
        apiDataList: currentPosts,
        backupdata: propsData,
      });
    }
  }
  getVisibilityAndAccuracyDataByDate() {
    console.log("this.propsssss", window.location.pathname.split("/"));
    const apiReq = {
      functionName: "getVisibilityAndAccuracyDataByDate",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      visibility_and_accuracy_id: window.location.pathname.split("/")[4],
    };
    backendApi(apiReq).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        this.setState({
          totalCount: data.result.length,
        });
        const indexOfLastPost = this.state.currentPage * 25;
        const indexOfFirstPost = indexOfLastPost - 25;
        const currentPosts = data.result.slice(
          indexOfFirstPost,
          indexOfLastPost
        );
        this.setState({
          apiDataList: currentPosts,
          backupdata: data.result,
        });
      }
    });
  }
  //**Pagination
  handleActivePage = (e) => {
    this.setState({
      currentPage: e,
    });
    const indexOfLastPost = e * 25;
    const indexOfFirstPost = indexOfLastPost - 25;
    let tempList = this.state.backupdata;
    const currentPosts = tempList.slice(indexOfFirstPost, indexOfLastPost);
    this.setState({
      apiDataList: currentPosts,
    });
  };
  //**Export Function
  patientListDownload() {
    var re = this.state.apiDataList;
    const data = [];
    for (var item = 0; item < re.length; item++) {
      data.push({
        "Excel Address": re[item].excel_address,
        "Address Match": `<span style="color:blue">Address Match Audit:</span>${re[item].google_address}<br/><span style="color:red">Address Match Visibility:</span>${re[item].address}`,
        "Phone Number": `${
          re[item].googlePhoneNumber
            ? `<span style="color:blue">${re[item].googlePhoneNumber}</span><br/>`
            : `<span style="color:blue">No Phone Number</span><br/>`
        }${
          re[item].phone_no
            ? `<span style="color:red">${re[item].phone_no}</span>`
            : `<span style="color:red">No number</span>`
        }`,
        // 'Phone Number Audit': re[item].googlePhoneNumber,
        // 'Phone Number Visibility': re[item].phone_no,
        "Address Match ":
          re[item].address_match == 0 || re[item].address_match == "No"
            ? "No"
            : "Yes",
        "Phone Number Match":
          re[item].phoneno_match == 0 || re[item].phoneno_match == "No"
            ? "No"
            : "Yes",
        "Business Match":
          re[item].business_name_match == 0 ||
          re[item].business_name_match == "No"
            ? "No"
            : "Yes",
      });
    }
    const fileName = "download";
    const exportType = "xls";
    exportFromJSON({ data, fileName, exportType });
  }
  setSession(e) {
    let filterList = this.state.apiDataList.filter(
      (obj) => obj.location_id == e
    );
    window.sessionStorage.setItem("editBusinessAcountLocationId", e);
    window.sessionStorage.setItem(
      "businessClipboardData",
      JSON.stringify(filterList)
    );
    window.location.href = "/business-details";
  }
  handleGoogleLocationRedirect = (url) => {
    window.open(url, "_blank");
  };
  render() {
    return (
      <div className="container-fluid">
        {/* <div className="back_tocatalog row align-items-center">
              <div className="col-xl-12 d-flex flex-wrap">
                
              </div>
            </div> */}

        {this.state.apiDataList.length ? (
          <div className="userListWrapper w-100 ml-0">
            {/* {this.state.apiDataList.length ? (
              <div className="float-right ">
                <button
                  class="btn btn-primary mr-3 mt-1 float-right export_btn"
                  type="button"
                  onClick={(e) => this.patientListDownload(e)}
                  id="export"
                >
                  Export
                </button>
              </div>
            ) : (
              ""
            )} */}

            <div className="container-fluid">
              <div className="col-md-12 visibiltyGpagh">
                {/* <img src={Accessibiltyimage} alt="Dashboard" className="imgHead"/> */}
                <h3 className="mb-0 ml-1">
                  <Icon.ArrowLeftCircle onClick={() => window.history.back()} />{" "}
                  {/* {this.state.mode == "visibility" ? (
                        <>Visibility Latest Report</>
                      ) : (
                        <>Accuracy Latest Report</>
                      )} */}
                </h3>
              </div>
              {/* <div className="visibilityReport mt-2">
              <span className="visibilityReportText"><b>Total Location
              </b>  :{total_location}<div className="">(gfgf)</div></span>
              <span className="visibilityReportText"><b>Address Match
              </b>  : {address_match}<div className="">(gfgf)</div></span>
              <span className="visibilityReportText"><b>Phone Number Match
              </b>  :{phone_no_match}<div className="">(gfgf)</div></span>
              <span className="visibilityReportText"><b>Business Match
              </b>  : {business_name_match}<div className="">(gfgf)</div></span>
            </div> */}
              {/* pagination */}
              <div className="col-md-12 tableResponsive mt-5">
                <div className="tableOuterWidth tableOuterWidthBig">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-1">Date</th>

                        <th className="col-1">Address</th>
                        <th className="col-1">Business Name</th>
                        <th className="col-1">Phone Number</th>
                        <th className="col-1">Category</th>
                        {this.state.mode === "accuracy" && (
                          <>
                            <th className="col-1">Website</th>
                            <th className="col-1">Website Match</th>
                            <th className="col-1">Working Hours</th>
                            <th className="col-1">Working Hours Match</th>
                          </>
                        )}
                        <th className="col-1">GBP Status</th>
                        <th className="col-1">GBP</th>
                      </tr>
                    </thead>
                    <div class="table_Outerscroll">
                      {this.state.apiDataList.map((item, i) => (
                        <tbody className="table_Outertbody">
                          <tr className="py-1">
                            <td className="col-1" rowspan="2">
                              <div className="d-flex flex-column">
                                <span>
                                  {moment(item.created_on).format("DD/MM/YYYY")}
                                </span>
                                <span style={{ display: "block" }}>
                                  {moment(item.created_on).format("hh:mm A")}
                                </span>

                                <br></br>
                              </div>
                            </td>
                            <td
                              className={
                                this.state.filter == "address"
                                  ? this.state.submode == "Yes"
                                    ? "col-1 greenActive"
                                    : "col-1 redActive"
                                  : "col-1"
                              }
                            >
                              <div className="mb-1">
                                <label htmlFor="" className="fromAuditText">
                                  From Audit Excel
                                </label>
                                <div>
                                  {item?.excel_storeCode ? (
                                    <span>
                                      Store Code: {item.excel_storeCode}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  <br></br>
                                  {item.excelAddress}
                                </div>
                                <hr />
                              </div>
                              <label htmlFor="" className="fromVisibitiText">
                                From Google
                              </label>
                              {/* <hr className="m-0"/> */}
                              <div>
                                {/* {item?.GBP_storeCode ? <span>Store Code: {item.GBP_storeCode}</span> : ""}<br></br> */}
                                {item.googleAddress !== ""
                                  ? item.googleAddress
                                  : "NA"}
                                <br></br>
                                {item.from_googleShareLink && (
                                  <span>
                                    Location:
                                    <img
                                      width="15"
                                      title="Go to Google address"
                                      onClick={() =>
                                        this.handleGoogleLocationRedirect(
                                          item.from_googleShareLink
                                        )
                                      }
                                      src={Address}
                                      alt="Address"
                                      className="address-icon ml-1"
                                    />
                                  </span>
                                )}
                              </div>
                            </td>
                            <td
                              className={
                                this.state.filter == "business"
                                  ? this.state.submode == "Yes"
                                    ? "col-1 greenActive"
                                    : "col-1 redActive"
                                  : "col-1"
                              }
                            >
                              <div className="mb-1">
                                <label htmlFor="" className="fromAuditText">
                                  From Audit Excel
                                </label>
                                <div>{item.excelName}</div>
                                <hr />
                              </div>
                              <label htmlFor="" className="fromVisibitiText">
                                From Google
                              </label>
                              {/* <hr className="m-0"/> */}
                              <div>
                                {item.googleName !== ""
                                  ? item.googleName
                                  : "NA"}
                              </div>
                            </td>
                            <td
                              className={
                                this.state.filter == "phone"
                                  ? this.state.submode == "Yes"
                                    ? "col-1 greenActive"
                                    : "col-1 redActive"
                                  : "col-1"
                              }
                            >
                              <div className="mb-1">
                                <label htmlFor="" className="fromAuditText">
                                  From Audit Excel
                                </label>
                                <div>{item.excelPhoneNo}</div>
                                <hr />
                              </div>
                              <label htmlFor="" className="fromVisibitiText">
                                From Google
                              </label>
                              {/* <hr className="m-0"/> */}
                              <div>
                                {item.googlePhoneNO !== ""
                                  ? item.googlePhoneNO
                                  : "NA"}
                              </div>
                            </td>
                            <td
                              className={
                                this.state.filter == "category"
                                  ? this.state.submode == "Yes"
                                    ? "col-1 greenActive"
                                    : "col-1 redActive"
                                  : "col-1"
                              }
                            >
                              <div className="mb-1">
                                <label htmlFor="" className="fromAuditText">
                                  From Audit Excel
                                </label>
                                <div>{item.excelCategory}</div>
                                <hr />
                              </div>
                              <label htmlFor="" className="fromVisibitiText">
                                From Google
                              </label>
                              {/* <hr className="m-0"/> */}
                              <div>
                                {item.googleCategory !== ""
                                  ? item.googleCategory
                                  : "NA"}
                              </div>
                            </td>
                            {this.state.mode === "accuracy" && (
                              <>
                                <td
                                  className={
                                    this.state.filter == "website"
                                      ? this.state.submode == "Yes"
                                        ? "col-1 greenActive"
                                        : "col-1 redActive"
                                      : "col-1"
                                  }
                                >
                                  <div className="mb-1">
                                    <div className="mb-1">
                                      <label
                                        htmlFor=""
                                        className="fromAuditText"
                                      >
                                        From Audit Excel
                                      </label>
                                      <div>{item.Website}</div>
                                      <hr />
                                    </div>
                                    <label
                                      htmlFor=""
                                      className="fromVisibitiText"
                                    >
                                      From Google
                                    </label>
                                    {/* <hr className="m-0"/> */}
                                    <div>
                                      {item.excel_website !== ""
                                        ? item.excel_website
                                        : "NA"}
                                    </div>
                                  </div>
                                </td>{" "}
                                <td
                                  className={
                                    this.state.filter == "website"
                                      ? this.state.submode == "Yes"
                                        ? "col-1 greenActive"
                                        : "col-1 redActive"
                                      : "col-1"
                                  }
                                >
                                  <div className="mb-1">
                                    <div>
                                      {item?.Web_match !== null &&
                                      item?.Web_match !== ""
                                        ? item?.Web_match
                                        : "Not Match"}
                                    </div>
                                  </div>
                                </td>
                                <td
                                  className={
                                    this.state.filter == "hours"
                                      ? this.state.submode == "Yes"
                                        ? "col-1 greenActive"
                                        : "col-1 redActive"
                                      : "col-1"
                                  }
                                >
                                  <div className="mb-1">
                                    <div className="mb-1">
                                      <label
                                        htmlFor=""
                                        className="fromAuditText"
                                      >
                                        From Audit Excel
                                      </label>
                                      <div>{item.hrs}</div>
                                      <hr />
                                    </div>
                                    <label
                                      htmlFor=""
                                      className="fromVisibitiText"
                                    >
                                      From Google
                                    </label>
                                    {/* <hr className="m-0"/> */}
                                    <div>
                                      {item.Additional_Hrs !== "" &&
                                      item.Additional_Hrs !== null
                                        ? item.Additional_Hrs
                                        : "NA"}
                                    </div>
                                  </div>
                                </td>
                                <td className="col-1">
                                  <div
                                    className={
                                      this.state.filter == "hours"
                                        ? this.state.submode == "Yes"
                                          ? "mb-1 greenActive"
                                          : "mb-1 redActive"
                                        : "mb-1"
                                    }
                                  >
                                    <div>{item?.Hrs_match}/7</div>
                                  </div>
                                </td>
                              </>
                            )}

                            <td className="col-1">
                              <div
                                className={
                                  this.state.filter == "gbp"
                                    ? this.state.submode == "Yes"
                                      ? "mb-1 greenActive"
                                      : "mb-1 redActive"
                                    : "mb-1"
                                }
                              >
                                <div>
                                  {item?.location_id !== null ? "Yes" : "No"}
                                </div>
                              </div>
                            </td>
                            <td className="col-1">
                              {item.maps_uri && (
                                <span style={{ display: "block" }}>
                                  <a
                                    href={item.maps_uri}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {" "}
                                    <button className="btn btn-outline-secondary">
                                      <img src={GoogleIcon} /> See your location
                                    </button>
                                  </a>
                                </span>
                              )}

                              {item.location_id && (
                                <span style={{ display: "block" }}>
                                  {" "}
                                  <button
                                    className="btn btn-outline-secondary mt-2"
                                    onClick={() =>
                                      this.setSession(item.location_id)
                                    }
                                  >
                                    <img
                                      className="mr-1"
                                      src={Edit}
                                      alt="edit"
                                      width="15px"
                                    />
                                    Edit your location
                                  </button>
                                </span>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </div>
                  </table>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="pagination_main  tag_postListNext">
                  <Pagination
                    hideDisabled
                    activePage={this.state.currentPage}
                    itemsCountPerPage={25}
                    totalItemsCount={this.state.totalCount}
                    pageRangeDisplayed={10}
                    onChange={(e) => this.handleActivePage(e)}
                  />
                </div>
              </div>
            </div>
            {/* <Footer /> */}
          </div>
        ) : (
          <h3>No Data Found</h3>
        )}
        <div className="loder_div" hidden />
      </div>
    );
  }
}
// export default VisibilityReports;
