import React, { useState, useEffect } from "react";
import { backendApi, re } from "../../apiService";
import $ from "jquery";
import SaveAndValidation from "./settingsSaveAndValidation";
function SettingsFacebookinstagram(props) {
  var orgadmin = window.sessionStorage.getItem("role") == "ORG_ADMIN";
  const [accountID, setaccountID] = useState(
    props.listingValuesForPassing?.facebook_ad_account_id != "null"
      ? props.listingValuesForPassing?.facebook_ad_account_id
      : ""
  );
  const [accessToken, setaccessToken] = useState(
    props.listingValuesForPassing?.facebook_access_token != "null"
      ? props.listingValuesForPassing?.facebook_access_token
      : ""
  );
  const [baseURL, setbaseURL] = useState(
    props.listingValuesForPassing?.facebook_baseUrl != "null"
      ? props.listingValuesForPassing?.facebook_baseUrl
      : ""
  );
  const [, set] = useState(
    props.listingValuesForPassing.account_id != "null"
      ? props.listingValuesForPassing.account_id
      : ""
  );
  useEffect(() => {
    if (
      props.listingValuesForPassing?.facebook_ad_account_id &&
      (props.listingValuesForPassing?.facebook_ad_account_id != "null" ||
        props.listingValuesForPassing?.facebook_ad_account_id != "undefined")
    ) {
      setaccountID(props.listingValuesForPassing?.facebook_ad_account_id);
    } else {
      setaccountID("");
    }

    if (
      props.listingValuesForPassing?.facebook_access_token &&
      (props.listingValuesForPassing?.facebook_access_token != "null" ||
        props.listingValuesForPassing?.facebook_access_token != "undefined")
    ) {
      setaccessToken(props.listingValuesForPassing?.facebook_access_token);
    } else {
      setaccessToken("");
    }

    if (
      props.listingValuesForPassing?.facebook_baseUrl &&
      (props.listingValuesForPassing?.facebook_baseUrl != "null" ||
        props.listingValuesForPassing?.facebook_baseUrl != "undefined")
    ) {
      setbaseURL(props.listingValuesForPassing?.facebook_baseUrl);
    } else {
      setbaseURL("");
    }
    if (
      props.listingValuesForPassing.account_id &&
      (props.listingValuesForPassing.account_id != "null" ||
        props.listingValuesForPassing.account_id != "undefined")
    ) {
      set(props.listingValuesForPassing.account_id);
    } else {
      set("");
    }
  }, [
    props.listingValuesForPassing.client_email,
    props.listingValuesForPassing?.facebook_access_token,
    props.listingValuesForPassing?.facebook_baseUrl,
    props.listingValuesForPassing.account_id,
  ]);
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="user_formdiv">
          <div className="userform_head">Facebook/Instagram</div>
          {window.sessionStorage.getItem("role") === "ROLE_SUPER_ADMIN" ? (
            <>
              <div className="row userrow_form">
                <div className="col-md-5">
                  <label className="col-form-label">Account Id</label>
                </div>

                <div className="col-md-7">
                  <input
                    type="text"
                    id="facebookAccountId"
                    value={accountID}
                    onChange={(e) => {
                      setaccountID(e.target.value);
                    }}
                    className="form-control"
                    placeholder="Account I.D."
                  />
                </div>
              </div>
              <div className="row userrow_form">
                <div className="col-md-5">
                  <label className="col-form-label">Access Token</label>
                </div>
                <div className="col-md-7">
                  <input
                    type="text"
                    id="facebookAccessToken"
                    value={accessToken}
                    onChange={(e) => {
                      setaccessToken(e.target.value);
                    }}
                    className="form-control"
                    placeholder="Access Token"
                  />
                </div>
              </div>
              <div className="row userrow_form">
                <div className="col-md-5">
                  <label className="col-form-label">Base URL</label>
                </div>

                <div className="col-md-7">
                  <input
                    type="text"
                    id="facebookBaseUrl"
                    value={baseURL}
                    onChange={(e) => {
                      setbaseURL(e.target.value);
                    }}
                    className="form-control"
                    placeholder="Base URL"
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {/* <div className="row userrow_form">
          <div className="col-md-4">
            <label className="col-form-label">
              Google Account Id
            </label>
          </div>
          <div class="col-md-1"><span>:</span></div>
          <div className="col-md-7">
            <input
              type="text"
              id="AccountId"
              value={}
              onChange={(e) => {
                set(e.target.value);
              }}
              className="form-control"
              placeholder="Google Account Id"
            />
          </div>
        </div> */}
          <SaveAndValidation
            getUserData={() => props.getUserData()}
            valueForSaved={[accountID, accessToken, baseURL]}
            saveMode={"facebookInstagram"}
            organizationId={props.organizationId}
          />
        </div>
      </div>
    </div>
  );
}
export default SettingsFacebookinstagram;
