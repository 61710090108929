import React, { Component } from "react";
import Dashboardimage from "../images/left_icons/dashboard.png";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { apiCallingPost } from "../apiService";
import $ from "jquery";
import moment from "moment";
import * as Icon from "react-feather";
class SuggestedEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businessName: [],
      getaccountByLocationList: [],
      graphDataViewSearch: [],
      graphDataViewMap: [],
      graphDataPhone: [],
      graphDataWebsite: [],
      getMasterdataXaxis: [],
      graphRequestDirection: [],
      graphQueriesDirect: 0,
      graphQueriesInDirect: 0,
      graphQueriesChain: 0,
      garphphotoViewMerchant: [],
      garphphotoViewCustomer: [],
      garphphotoCountMerchant: [],
      garphphotoCountCustomer: [],
      getAllGraphData: [],
      locationValues: [],
      stateValues: [],
      cityValues: [],
      startDate: "",
      endDate: "",

      locationLatLongArray: [],

      allLocationData: [],
      allAssertiveLocationData: [],
      administrativeAreaList: [],
      administrativeArea: "0",
      localityList: [],
      locality: "0",
      locationList: [],
      location: "0",
      getRemovedData: [],
      getRemovedDataAll: [],
    };
  }
  componentDidMount() {
    this.GetLocationsUnderAccount();
    this.getRemovedData();
    $("#nodata").hide();
  }

  GetLocationsUnderAccount(val) {
    const reviewAPIData = {
      functionName: "getMasterDataForFilter",

      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };

    apiCallingPost(reviewAPIData).then((result) => {
      if (result.success === "1") {
        $(".loder_div").attr("hidden", true);

        // const {
        //   data: { locations },
        // } = result.data;
        console.log("reviewAPIData", result.data);
        // TODO: May need location filter with country. currently only running for india.
        this.setState({
          allLocationData: result.data,
          allAssertiveLocationData: result.data,
          administrativeAreaList: new Array(
            ...new Set(
              result.data.map((eachLocation) => eachLocation.administrativeArea)
            )
          ),

          localityList: new Array(
            ...new Set(
              result.data.map((eachLocation) => eachLocation?.locality)
            )
          ),
          locationList: result.data.map((eachLocation) => ({
            locationId: eachLocation.location_id,
            location: `${eachLocation?.title} - ${eachLocation?.locality}`,
          })),

          administrativeArea: "0",
        });

        $(".loder_div").attr("hidden", true);
      }
    });
    // .catch((error) => {
    //   console.trace(error);
    //   alert("Something went wrong in getting Location Data");
    //   $(".loder_div").attr("hidden", true);
    // });
  }
  getRemovedData() {
    $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "getRemovedData",

      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };

    apiCallingPost(reviewAPIData).then((data) => {
      console.log("data=========", data);

      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        this.setState({
          getRemovedData: data.data,
          getRemovedDataAll: data.data,
        });
      } else {
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  onStateSelect(administrativeArea) {
    this.setState({
      administrativeArea,
      localityList: new Array(
        ...new Set(
          this.state.allLocationData
            .filter(
              (eachLocation) =>
                eachLocation?.administrativeArea === administrativeArea
            )
            .map((eachLocation) => eachLocation?.locality)
        )
      ),
      allAssertiveLocationData: this.state.allLocationData.filter(
        (eachLocation) =>
          eachLocation?.administrativeArea === administrativeArea
      ),
      locality: "0",
      location: "0",
    });
  }

  onCitySelect(locality) {
    this.setState({
      locality,
      locationList: this.state.allAssertiveLocationData
        .filter((eachLocation) => eachLocation?.locality === locality)
        .map((eachLocation) => ({
          locationId: eachLocation.location_id,
          location: `${eachLocation?.title} - ${eachLocation?.locality}`,
        })),
      location: "0",
    });
  }

  onLocationSelect(location) {
    this.setState({ location });
  }

  handleFilter() {
    $(".loder_div").attr("hidden", false);

    setTimeout(() => {
      $(".loder_div").attr("hidden", true);
    }, 500);
    $("#nodata").show();
    var locationId = this.state.location;
    var state = this.state.administrativeArea;
    var city = this.state.locality;

    let finalfilter = this.state.getRemovedDataAll;
    console.log(">>>>>>>>>>>>>", state, city);

    if (state != "0" && city != "0" && locationId != "0") {
      finalfilter = finalfilter.filter(
        (x) =>
          x.administrativeArea == state &&
          x.locality == city &&
          x.location_id == locationId
      );
    } else if (state != "0" && city != "0") {
      finalfilter = finalfilter.filter(
        (x) => x.administrativeArea == state && x.locality == city
      );
    } else if (city != "0" && locationId != "0") {
      finalfilter = finalfilter.filter(
        (x) => x.location_id == locationId && x.locality == city
      );
    } else if (state != "0" && locationId != "0") {
      finalfilter = finalfilter.filter(
        (x) => x.administrativeArea == state && x.location_id == locationId
      );
    } else if (state != "0") {
      finalfilter = finalfilter.filter((x) => x.administrativeArea == state);
    } else if (city != "0") {
      finalfilter = finalfilter.filter((x) => x.locality == city);
    } else if (locationId != "0") {
      finalfilter = finalfilter.filter((x) => x.location_id == locationId);
    }

    console.log("finalfilter", finalfilter);
    this.setState({
      getRemovedData: finalfilter,
    });
  }

  clearData() {
    this.getRemovedData();
    this.setState({
      locality: "0",
      location: "0",
      administrativeArea: "0",
    });
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper mapunmap_div">
          <Header heading="Suggested Edits" headerImage={Dashboardimage} />
          <div className="container-fluid">
            <div className="row row-search px-2">
              <div className="col-lg-2 col-md-4 col-sm-6">
                <select className="form-control" disabled>
                  <option>India</option>
                </select>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <select
                  className="form-control"
                  id="statevalue"
                  required
                  value={this.state.administrativeArea}
                  onChange={(e) => this.onStateSelect(e.target.value)}
                >
                  <option value="0" disabled>
                    State
                  </option>
                  {this.state.administrativeAreaList?.length > 0 &&
                    this.state.administrativeAreaList.map(
                      (adminArea, index) => (
                        <option key={index} value={adminArea}>
                          {adminArea}
                        </option>
                      )
                    )}
                </select>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <select
                  className="form-control"
                  required
                  value={this.state.locality}
                  onChange={(e) => this.onCitySelect(e.target.value)}
                >
                  <option value="0" disabled>
                    City
                  </option>
                  {this.state.localityList?.length > 0 &&
                    this.state.localityList.map((eachLocality, index) => (
                      <option key={index} value={eachLocality}>
                        {eachLocality}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 location_search">
                <select
                  className="form-control"
                  required
                  value={this.state.location}
                  onChange={(e) => this.onLocationSelect(e.target.value)}
                >
                  <option value="0" disabled>
                    Location
                  </option>
                  {this.state.locationList?.length > 0 &&
                    this.state.locationList.map((eachLocation) => (
                      <option
                        key={eachLocation.locationId}
                        value={eachLocation.locationId}
                      >
                        {eachLocation.location}
                      </option>
                    ))}
                </select>
              </div>

              <div className="col-lg-2 col-md-4 col-sm-6">
                <button
                  className="btn-search"
                  onClick={() => this.handleFilter()}
                >
                  Search
                </button>
                <button className="btn-search" onClick={() => this.clearData()}>
                  Clear
                </button>
              </div>

              {/* <div className="col-lg-2 col-md-4 col-sm-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Location ID"
                />
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pin Code"
                />
              </div> */}
            </div>
            <div className="tableResponsive">
              <div className="tableOuterWidth">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th className="col-2">Title</th>
                      <th className="col-2">Address</th>
                      <th className="col-2">State</th>
                      <th className="col-2">City</th>
                      <th className="col-2">Date</th>
                      <th className="col-2"></th>
                    </tr>
                  </thead>
                  <div class="table_Outerscroll">
                    {this.state.getRemovedData.length != "0" ? (
                      this.state.getRemovedData.map((item, j) => (
                        <tbody className="table_Outertbody">
                          <tr>
                            <td className="col-2">
                              {item.title}
                            </td>
                            <td className="col-2">{item.addressLines}</td>
                            <td className="col-2">{item.administrativeArea}</td>
                            <td className="col-2">{item.locality}</td>
                            <td className="col-2">
                              {moment(item.created_on).format(
                                "DD MMM YYYY hh:mm a"
                              )}
                            </td>
                            {/* <td className="col-2">
                              <ul>
                                <li>{item.title}</li>
                                <li>{item.addressLines}</li>
                                <li>
                                  {moment(item.created_on).format(
                                    "DD MMM YYYY hh:mm a"
                                  )}
                                </li>
                              </ul>


                              
                            </td> */}

                            <td className="col-2">
                              <span>Title:{item.title}</span>
                              <br />
                              <span>{item.addressLines}</span>
                              <br />
                              <span>
                                Date:
                                {moment(item.created_on).format(
                                  "DD MMM YYYY hh:mm a"
                                )}
                              </span>
                              <br />
                              {JSON.parse(item.updateString).map((obj, i) => {
                                if (obj) {
                                  var suggetedData = JSON.parse(obj);
                                }
                                console.log(
                                  "suggetedData",
                                  JSON.stringify(suggetedData)
                                );
                                if (i == 0) {
                                  return (
                                    <>
                                      {typeof suggetedData === "object" &&
                                        Object.keys(suggetedData) != "" && (
                                          <>
                                            <br />
                                            {JSON.stringify(
                                              suggetedData.primaryCategory
                                            ) ? (
                                              <span>
                                                Change in Primary Category :Yes
                                              </span>
                                            ) : (
                                              <span>
                                                Change in Primary Category :No
                                              </span>
                                            )}
                                            <br />
                                            {JSON.stringify(
                                              suggetedData.canDelete == true
                                            ) ? (
                                              <span>Delete :Yes</span>
                                            ) : (
                                              <span>Delete:No</span>
                                            )}

                                            <br />
                                            {JSON.stringify(
                                              suggetedData.canModifyServiceList ==
                                                true
                                            ) ? (
                                              <span>
                                                Modify Service List :Yes
                                              </span>
                                            ) : (
                                              <span>
                                                Modify Service List :No
                                              </span>
                                            )}
                                            <br />
                                            {JSON.stringify(
                                              suggetedData.hasVoiceOfMerchant ==
                                                true
                                            ) ? (
                                              <span>
                                                Voice of Merchant :Yes
                                              </span>
                                            ) : (
                                              <span>Voice of Merchant :No</span>
                                            )}
                                            <br />
                                            {JSON.stringify(
                                              suggetedData.periods
                                            ) != undefined ? (
                                              <span>Office Hours :Yes</span>
                                            ) : (
                                              <span>Office Hours :No</span>
                                            )}
                                            <br />
                                            {JSON.stringify(
                                              suggetedData.latitude
                                            ) != undefined ? (
                                              <span>
                                                Latitude :
                                                {JSON.stringify(
                                                  suggetedData.latitude
                                                )}
                                              </span>
                                            ) : (
                                              <span>Latitude :No</span>
                                            )}<br/>
                                            {JSON.stringify(
                                              suggetedData.longitude
                                            ) != undefined ? (
                                              <span>
                                                Longitude :
                                                {JSON.stringify(
                                                  suggetedData.longitude
                                                )}
                                              </span>
                                            ) : (
                                              <span>Longitude :No</span>
                                            )}
                                          </>
                                        )}
                                    </>
                                  );
                                }
                              })}

                              {/* {JSON.parse(
                                item.updateString
                                  .replace(/[{}]/g, "")
                                  .split(",")
                              )} */}
                            </td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <p id="nodata">No Data Found</p>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="loder_div" hidden />
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default SuggestedEdit;
