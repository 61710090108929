import React, { Component } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import { backendApi } from "../apiService";
import SocialMediaimage from "../images/left_icons/social-media.png";
import $ from "jquery";
import Footer from "../layout/footer";
import {
    FaFacebookF,
    FaInstagram,
    FaTumblr,
    FaYoutube,
    FaSearch,
    FaTwitter
} from "react-icons/fa";

const iconMap = {
    twitter: <FaTwitter />,
    tumblr: <FaTumblr />,
    youtube: <FaYoutube />,
    facebook: <FaFacebookF />,
    instagram: <FaInstagram />,
};

class SocialMeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            socialData: [],
            isData: new Date(),
            socialType: '',
            nodataTumblr: "",
            nodataFacebook: "",
            nodataInstagram: "",
            nodataYoutube: "",
            nodataTwitter: ""
        }
    }
    componentDidMount() {
        $(".loder_div").attr("hidden", false);
        this.getDetails('')
        // $('.tag_social ul li span').on('click', function () {
        //     $('.tag_social ul li span.currentDiv').removeClass('currentDiv');
        //     $(this).addClass('currentDiv');
        // });
    }
    getDetails(mode) {
        $(".loder_div").attr("hidden", false);
        let serachParams = window.sessionStorage.getItem("social_tags");
        let serachParamsArray = serachParams.split(",");
        if (serachParamsArray.length > 0) {
            serachParamsArray = serachParamsArray.join(' ') + " " + document.getElementById("socialSearch").value;
            switch (mode) {
                case 'Facebook':
                    this.getFacebookList()
                    break;
                case 'Instagram':
                    this.getInstagramList()
                    break;
                case 'Tumblr':
                    this.gettumblarList(serachParamsArray)
                    break;
                case 'Youtube':
                    this.getYoutubeList(serachParamsArray)
                    break;
                case 'Twitter':
                    this.getTwitterList(serachParamsArray)
                    break;
                default:
                    this.gettumblarList(serachParamsArray)
                    this.getYoutubeList(serachParamsArray)
                    this.getTwitterList(serachParamsArray)
                    this.getFacebookList()
                    this.getInstagramList()
                    break;
            }
        }
    }
    getInstagramList() {
        let socialDataOld = this.state.socialData
        var postData = {
            functionName: "instegram"
        };
        backendApi(postData).then((response) => {
            console.log('getInstagramList response......', response);
            if (response.success === "1") {
                response.data.data.map((obj) => {
                    if (typeof obj.caption !== "undefined") {
                        socialDataOld.push({
                            title: "",
                            shortTitle: "",
                            url: obj.permalink,
                            shortDescription: obj.caption,
                            description: "",
                            publishTime: "",
                            type: "instagram",
                            thumbImage: ""
                        });
                    }
                })
                this.setState({
                    socialData: socialDataOld
                }, () => {
                    window.animation(document.getElementById("grid"));
                    $(".loder_div").attr("hidden", true);
                })
            }
            else {
                $(".loder_div").attr("hidden", true);
                this.setState({
                    nodataInstagram: "Token Expired"
                })
            }
        })
    }
    getFacebookList() {
        let socialDataOld = this.state.socialData
        var postData = {
            functionName: "facebook"
        };
        backendApi(postData).then((response) => {
            console.log('response...facebook.....', response)
            if (response.success === "1") {
                response.data.data.map((obj) => {
                    if (typeof obj.message !== "undefined") {
                        socialDataOld.push({
                            title: "",
                            shortTitle: "",
                            url: "",
                            shortDescription: obj.message,
                            description: "",
                            publishTime: new Date(obj.created_time).toDateString(),
                            type: "facebook",
                            thumbImage: obj.picture
                        });
                    }
                })
                this.setState({
                    socialData: socialDataOld
                }, () => {
                    window.animation(document.getElementById("grid"));
                    $(".loder_div").attr("hidden", true);
                })
            }
            else {
                $(".loder_div").attr("hidden", true);
                this.setState({
                    nodataFacebook: "Token Expired"
                })
            }
        })
    }
    getTwitterList(data) {
        let socialDataOld = this.state.socialData
        var postData = {
            functionName: "twitterrescenttweets",
            query: data,
            token: null,
        };
        backendApi(postData).then((response) => {
            if (response.success === "1") {
                if (response.data.meta.result_count > 0) {
                    response.data.data.map((obj) => {
                        let title = obj.text, description = "", thumbImage = "", url = "";
                        if (typeof obj.entities.urls !== "undefined") {
                            if (typeof obj.entities.urls[0].title !== "undefined") {
                                title = obj.entities.urls[0].title
                            }
                            if (typeof obj.entities.urls[0].description !== "undefined") {
                                description = obj.entities.urls[0].description
                            }
                            if (typeof obj.entities.urls[0].images !== "undefined") {
                                thumbImage = obj.entities.urls[0].images[0].url
                            }
                            if (typeof obj.entities.urls[0].expanded_url !== "undefined") {
                                url = obj.entities.urls[0].expanded_url
                            }
                        }
                        socialDataOld.push({
                            title: title,
                            shortTitle: "",
                            url: url,
                            shortDescription: "",
                            description: description,
                            publishTime: new Date(obj.created_at).toDateString(),
                            type: "twitter",
                            thumbImage: thumbImage
                        });
                    })
                }
                else{
                    this.setState({
                        nodataTwitter: 'No data available for search query: '+data
                    })
                }
                this.setState({
                    socialData: socialDataOld
                }, () => {
                    $(".loder_div").attr("hidden", true);
                    window.animation(document.getElementById("grid"));
                })
            }
            else {
                $(".loder_div").attr("hidden", true);
                this.setState({
                    nodataTwitter: response.data.detail
                })
            }
        })
    }
    getYoutubeList(data) {
        let socialDataOld = this.state.socialData
        var postData = {
            functionName: "youtube",
            query: data,
        };
        backendApi(postData).then((response) => {
            if (response.success === "1") {
                if (response.data.length > 0) {
                    response.data.map((obj) => {
                        socialDataOld.push({
                            title: obj.snippet.title,
                            shortTitle: obj.snippet.channelTitle,
                            url: obj.id.url,
                            shortDescription: obj.snippet.description,
                            description: "",
                            publishTime: new Date(obj.snippet.publishTime).toDateString(),
                            type: "youtube",
                            thumbImage: obj.snippet.thumbnails.medium.url
                        });
                    })
                    this.setState({
                        socialData: socialDataOld
                    }, () => {
                        window.animation(document.getElementById("grid"));
                        $(".loder_div").attr("hidden", true);
                    })
                }
                else {
                    $(".loder_div").attr("hidden", true);
                }
            }
            else {
                $(".loder_div").attr("hidden", true);
                this.setState({
                    nodataYoutube: "Limit Exceeded"
                })
            }
        })
    }
    gettumblarList(data) {
        let socialDataOld = this.state.socialData
        var postData = {
            functionName: "gettumblrpost",
            tag: data.trim().split(" "),
        };
        backendApi(postData).then((response) => {
            if (response.success === "1") {
                let title = "", shortDescription = "", thumbImage = "";
                response.data.map((obj) => {
                    if (obj.type === 'text') {
                        title = obj.blog.title
                        shortDescription = obj.blog.description
                        thumbImage = (obj.trail.length > 0 ? obj.trail[0].blog.theme.header_image : "")
                    }
                    else if (obj.type === 'link') {
                        title = obj.title
                        shortDescription = obj.excerpt
                        thumbImage = obj.link_image
                    }
                    else if (obj.type === 'photo') {
                        title = obj.blog.title
                        shortDescription = ""
                        thumbImage = obj.photos[0].original_size.url
                    }
                    socialDataOld.push({
                        title: title,
                        shortTitle: obj.blog_name,
                        url: obj.short_url,
                        shortDescription: shortDescription,
                        description: (typeof obj.body_abstract !== "undefined" ? obj.body_abstract : obj.summary),
                        publishTime: new Date(obj.date).toDateString(),
                        type: "tumblr",
                        thumbImage: thumbImage
                    });
                })
                this.setState({
                    socialData: socialDataOld
                }, () => {
                    window.animation(document.getElementById("grid"));
                    $(".loder_div").attr("hidden", true);
                })
            }
            else {
                $(".loder_div").attr("hidden", true);
                this.setState({
                    nodataTumblr: "Limit Exceeded"
                })
            }
        })
    }
    selectMedia(mode) {
        $(".loder_div").attr("hidden", false);
        $('.tag_social ul li span.currentDiv').removeClass('currentDiv');
        $("#div" + mode).addClass('currentDiv');
        this.setState({
            socialData: [],
            socialType: mode
        }, () => {
            this.getDetails(mode)
        })
    }
    socialSearch(event) {
        if (event.target.value.toString().trim() !== "") {
            if (event.keyCode == 13) {
                $("#clearSearchBtn").show()
                this.getDetails();
                return true;
            } else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    socialSearchIcon() {
        let socialSearch = document.getElementById("socialSearch").value;
        if (socialSearch.trim() !== "") {
            $("#clearSearchBtn").show()
            this.getDetails();
        }
    }
    clearData() {
        $("#clearSearchBtn").hide()
        $("#socialSearch").val('');
        this.getDetails(this.state.socialType);
    }
    render() {
        return (
            <div className="main_wrapper">
                <LeftMenu />
                <div className=" tag_socialMeWraper">
                    <Header heading="Social Me" headerImage={SocialMediaimage} />
                    <div className="tag_social_userdiv">
                        <div className="  tag_social">
                            <ul className="nav">
                                <li className="nav-item tag_li ">
                                    <span className="currentDiv" id="divAll" onClick={() => this.selectMedia('All')}>All</span>
                                </li>

                                {this.state.nodataTumblr !== "" ?
                                    <li className={"nav-item tag_li disabled_li"} title={this.state.nodataTumblr}>
                                        <span className="">
                                            <FaTumblr />
                                        </span>
                                    </li>
                                    :
                                    <li className={"nav-item tag_li"}>
                                        <span className="" id="divTumblr" onClick={() => this.selectMedia('Tumblr')}>
                                            <FaTumblr />
                                        </span>
                                    </li>
                                }


                                {this.state.nodataYoutube !== "" ?
                                    <li className={"nav-item tag_li disabled_li"} title={this.state.nodataYoutube}>
                                        <span className="">
                                            <FaYoutube />
                                        </span>
                                    </li>
                                    :
                                    <li className={"nav-item tag_li"}>
                                        <span className="" id="divYoutube" onClick={() => this.selectMedia('Youtube')}>
                                            <FaYoutube />
                                        </span>
                                    </li>
                                }


                                {this.state.nodataTwitter !== "" ?
                                    <li className={"nav-item tag_li disabled_li"} title={this.state.nodataTwitter}>
                                        <span className="">
                                            <FaTwitter />
                                        </span>
                                    </li>
                                    :
                                    <li className={"nav-item tag_li"}>
                                        <span className="" id="divTwitter" onClick={() => this.selectMedia('Twitter')}>
                                            <FaTwitter />
                                        </span>
                                    </li>
                                }

                                {this.state.nodataFacebook !== "" ?
                                    <li className={"nav-item tag_li disabled_li"} title={this.state.nodataFacebook}>
                                        <span className="">
                                            <FaFacebookF />
                                        </span>
                                    </li>
                                    :
                                    <li className={"nav-item tag_li"}>
                                        <span className="" id="divFacebook" onClick={() => this.selectMedia('Facebook')}>
                                            <FaFacebookF />
                                        </span>
                                    </li>
                                }

                                {this.state.nodataInstagram !== "" ?
                                    <li className={"nav-item tag_li disabled_li"} title={this.state.nodataInstagram}>
                                        <span className="">
                                            <FaInstagram />
                                        </span>
                                    </li>
                                    :
                                    <li className={"nav-item tag_li"}>
                                        <span className="" id="divInstagram" onClick={() => this.selectMedia('Instagram')}>
                                            <FaInstagram />
                                        </span>
                                    </li>
                                }


                                <li className="nav-item searech_social">
                                    <input type="text" placeholder="search" id="socialSearch" onKeyDown={(e) => this.socialSearch(e)} />
                                    <FaSearch onClick={() => this.socialSearchIcon()} />
                                </li>
                                <li>
                                    <button className="btn-save tag_searchBtn" id="clearSearchBtn" style={{ display: 'none' }} onClick={() => this.clearData()}>Clear</button>
                                </li>
                            </ul>
                        </div>
                        <div className="user_listdiv social_userdiv ">
                            <ul className="grid effect-2" id="grid">
                                {this.state.socialData.sort((a, b) => 0.5 - Math.random()).map((obj, index) => {
                                    return (
                                        <li key={index}>
                                            <div className="innter_griddiv tag_innter_griddiv">
                                                <div className="social_mediacontnt">
                                                    {obj.url !== "" ?
                                                        <a href={obj.url} target="_blank">{obj.title}</a>
                                                        :
                                                        obj.title
                                                    }
                                                    <br />
                                                    <p dangerouslySetInnerHTML={{
                                                        __html: obj.shortDescription,
                                                    }}
                                                    />
                                                    <p dangerouslySetInnerHTML={{
                                                        __html: obj.description,
                                                    }}
                                                    />
                                                    {obj.thumbImage !== "" ?
                                                        <div className="social_mediamiddleimg">
                                                            {obj.url !== "" ?
                                                                <a href={obj.url} target="_blank"><img src={obj.thumbImage} /></a>
                                                                :
                                                                <img src={obj.thumbImage} />
                                                            }
                                                        </div>
                                                        : ""}
                                                </div>
                                                <div className="social_mediamiddle">
                                                    <div className="social_imglink tag_social_imglink">
                                                        <div className="img_mainwithlink">
                                                            {obj.url !== "" ?
                                                                <a rel="nofollow" href={obj.url} target="_blank" className="main_link_socila">
                                                                    {obj.shortTitle}
                                                                </a>
                                                                :
                                                                obj.shortTitle
                                                            }
                                                            <div className="img_withlink img_withlink_btm">
                                                                <span>
                                                                    {iconMap[obj.type]}
                                                                </span>
                                                                <p rel="nofollow" className="ff-timestamp">
                                                                    {obj.publishTime}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <Footer />
                </div>
                <div className="loder_div" hidden />
            </div>
        );
    }
}
export default SocialMeList;
