import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import $, { data } from "jquery";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import Auditimage from "../images/left_icons/processing.png";
import DragDropFile from "../audit/dragDropFile";
import * as XLSX from "xlsx";
import DataInput from "../audit/dataInput";
import { backendApi, pythonBackendApi } from "../apiService";
import * as Icon from "react-feather";
var UID = 0;
let objid = 0;
class GbpMultipleLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataListFromApi: [],
      inputDetails: "",
      verificationPassword: "",
      verifyValue: "",
      VerificationCode: "",
      NewLocationId: "",
      setMethod: "",
      managerEmail: "",
      NewLocationPin: "",
    };
    this.editAdressPush = this.editAdressPush.bind(this);
  }
  componentDidMount() {
    UID = parseInt(window.location.pathname.split("/").pop());
    this.listWithUiId(UID);
  }
  listWithUiId = (uiid) => {
    // e.preventDefault();
    const apiReq = {
      functionName: "list_push_bulk_by_uid",
      uid: uiid,
    };
    backendApi(apiReq).then((result) => {
      if (result.success === "1") {
        this.setState({
          dataListFromApi: result.data,
        });
      } else {
        alert(result.data.error.message);
      }
    });
  };
  async onSaveGbp(data) {
    for (const obj of this.state.dataListFromApi) {
      {
        // objid = obj.id
        // locationsLength = i + 1;
        var api = {
          functionName: "claim_initiated_Save",
          site_id:
            window.sessionStorage.getItem("switchingOrgId") === ""
              ? window.sessionStorage.getItem("organizationId")
              : window.sessionStorage.getItem("switchingOrgId"),
          audit_data_from_excel_id: obj.id,
          created_by: obj.created_by,
          comment: "",
        };
        await backendApi(api).then((data) => {
          this.createNewLocation(obj);
        });
      }
    }
  }
  createNewLocation = (obj) => {
    console.log(obj, "objobjobjobjobjobj");
    var req = {
      functionName: "createNewLocation",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      name: obj.full_name,
      title: obj.full_name,
      Description: obj.Description?.obj.Description,
      PhoneNo: "+91" + obj.mobile_no,
      AdministrativeArea: obj.administrativeArea.replace(" ", ""),
      Locality: obj.locality,
      Address: obj.address.replace(" ", ""),
      WebsiteUrl: obj.WebsiteUrl?.obj.WebsiteUrl,
      postalCode: obj.postalCode,
      validate: false,
    };
    let identifyId = obj.id;
    backendApi(req).then((result) => {
      if (result.success === "1") {
        const apiReq = {
          functionName: "updatePushStatus",
          location_id: result.data.name.split("/").pop(),
          g_pin: "",
          status: "Push Initiated",
          id: identifyId,
          full_name: obj.full_name,
          address: obj.address,
          mobile_no: "+91" + obj.mobile_no,
          email_id: obj.email_id,
          locality: obj.locality,
          administrativeArea: obj.administrativeArea,
          postalCode: obj.postalCode,
          verification_code: "",
          errorMessage: "",
        };
        this.updatePushStatus(apiReq);
        this.recallCreateLocation(
          result.data.name != undefined ? result.data.name.split("/").pop() : ""
        );
      } else {
        const apiReq = {
          functionName: "updatePushStatus",
          location_id: "",
          g_pin: "",
          status: "Failed",
          id: identifyId,
          full_name: obj.full_name,
          address: obj.address,
          mobile_no: "+91" + obj.mobile_no,
          email_id: obj.email_id,
          locality: obj.locality,
          administrativeArea: obj.administrativeArea,
          postalCode: obj.postalCode,
          verification_code: "",
          errorMessage: JSON.stringify(
            result?.data?.error?.details?.map((data) => data)
          ),
        };
        this.updatePushStatus(apiReq);
        alert(result.data.error.details);
      }
    });
  };
  recallCreateLocation = (NewLocationId, obj) => {
    console.log(obj, "kkkkkkkkkkkkkkkkkkkk");
    let self = this;
    var req = {
      functionName: "createNewLocation",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      name: obj.Name,
      title: obj.Name,
      Description: obj.Description,
      PhoneNo: "+91" + obj.PhoneNo,
      AdministrativeArea: obj.administrativeArea,
      Locality: obj.Locality,
      Address: obj.Adress,
      WebsiteUrl: obj.WebsiteUrl,
      postalCode: obj.postalCode,
      validate: true,
    };
    backendApi(req).then((result) => {
      if (result.success === "1") {
        // this.setState({
        //     NewLocationId:
        // })
        //    this.goClick(NewLocationId,obj)
        // this.verificationCode(NewLocationId,obj)
        // this.recallCreateLocation(result.data.name.split("/").pop(), obj);
      } else {
        alert(result.data.error.details[0].reason);
      }
    });
  };
  AdminClick = (obj) => {
    $("#adminPopUp").attr("hidden", false);
    objid = obj.id;
    this.setState({ verifyValue: obj });
  };
  popUporganizationBulkUploadVerify = (obj) => {
    $("#popUporganizationBulkUpload").attr("hidden", false);
    objid = obj.id;
    this.setState({ verifyValue: obj });
  };
  goClick = () => {
    if (this.state.setMethod === "") {
      alert("Please Choose A Methode First");
      return false;
    } else if (this.state.inputDetails === "") {
      alert("Please Fill The Field");
      return false;
    } else {
      $("#Go").attr("hidden", true);
      $("#gbpPassword").attr("hidden", false);
      $("#gbpEmail").attr("hidden", true);
      $("#confirm").attr("hidden", false);
      $("#goBackPopUp").attr("hidden", false);
      // const apiReq =
      // {
      //     "locationid": this.state.verifyValue.location_id,
      //     "functionName": "GBPVerify",
      //     "siteId": window.sessionStorage.getItem("switchingOrgId") === ""
      //         ? window.sessionStorage.getItem("organizationId")
      //         : window.sessionStorage.getItem("switchingOrgId"),
      //     "mainId": this.state.verifyValue.id,
      //     "dataSet": {
      //         method: this.state.setMethod,
      //         ...(this.state.setMethod === "EMAIL" && { emailId: this.state.inputDetails, }),
      //         ...(this.state.setMethod === "PHONE_CALL" && { phoneNumber: "+91" + this.state.inputDetails, }),
      //         ...(this.state.setMethod === "SMS" && { mailerContact: this.state.inputDetails, }),
      //         // phoneNumber: "+91"+8137828248,
      //         locationid: this.state.verifyValue.location_id,
      //     }
      // }
      const apiReq = {
        functionName: "createNewLocationVerify",
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        method: this.state.setMethod,
        ...(this.state.setMethod === "EMAIL" && {
          emailId: this.state.inputDetails,
        }),
        ...(this.state.setMethod === "PHONE_CALL" && {
          phoneNumber: "+91" + this.state.inputDetails,
        }),
        ...(this.state.setMethod === "SMS" && {
          phoneNumber: "+91" + this.state.inputDetails,
        }),
        locationid: this.state.verifyValue.location_id,
      };
      backendApi(apiReq).then(async (result) => {
        if (result.success === "1") {
          const apiReq = {
            functionName: "updatePushStatus",
            location_id: this.state.verifyValue.location_id,
            g_pin: "",
            status: "Verification Started",
            id: objid,
            full_name: this.state.verifyValue.full_name,
            address: this.state.verifyValue.address,
            mobile_no: "+91" + this.state.verifyValue.mobile_no,
            email_id: this.state.verifyValue.email_id,
            locality: this.state.verifyValue.locality,
            administrativeArea: this.state.verifyValue.administrativeArea,
            postalCode: this.state.verifyValue.postalCode,
            verification_code: result.data.verification.name.split("/").pop(),
            errorMessage: "",
          };
          this.updatePushStatus(apiReq);
          this.setState({
            NewLocationPin: result.data.verification.name.split("/").pop(),
          });
        } else {
          alert(result.data.error.details[0].fieldViolations[0].description);
          const apiReq = {
            functionName: "updatePushStatus",
            location_id: this.state.verifyValue.location_id,
            g_pin: "",
            status: "Verification Failed",
            id: objid,
            full_name: this.state.verifyValue.full_name,
            address: this.state.verifyValue.address,
            mobile_no: "+91" + this.state.verifyValue.mobile_no,
            email_id: this.state.verifyValue.email_id,
            locality: this.state.verifyValue.locality,
            administrativeArea: this.state.verifyValue.administrativeArea,
            postalCode: this.state.verifyValue.postalCode,
            verification_code: "",
            errorMessage: JSON.stringify(
              result?.data?.error?.details?.map((data) => data)
            ),
          };
          this.updatePushStatus(apiReq);
        }
      });
    }
  };
  Confirm = (NewLocationId) => {
    if (this.state.verificationPassword) {
      const apiReq = {
        functionName: "GBPVerifyPin",
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        locationid: this.state.verifyValue.location_id,
        verificationID: this.state.NewLocationPin,
        pin: this.state.verificationPassword, //need to do
      };
      backendApi(apiReq).then((result) => {
        if (result.success === "1") {
          const apiReq = {
            functionName: "updatePushStatus",
            location_id: this.state.verifyValue.location_id,
            g_pin: this.state.verificationPassword,
            status: "COMPLETED",
            id: objid,
            full_name: this.state.verifyValue.full_name,
            address: this.state.verifyValue.address,
            mobile_no: "+91" + this.state.verifyValue.mobile_no,
            email_id: this.state.verifyValue.email_id,
            locality: this.state.verifyValue.locality,
            administrativeArea: this.state.verifyValue.administrativeArea,
            postalCode: this.state.verifyValue.postalCode,
            verification_code: this.state.NewLocationPin,
            errorMessage: "",
          };
          this.updatePushStatus(apiReq);
          $("#verifybutton" + NewLocationId.id).hide();
          $("#popUporganizationBulkUpload").attr("hidden", true);
        } else {
          alert(result.data.error.message);
          const apiReq = {
            functionName: "updatePushStatus",
            location_id: this.state.verifyValue.location_id,
            g_pin: this.state.verificationPassword,
            status: "Verification Failed",
            id: objid,
            full_name: this.state.verifyValue.full_name,
            address: this.state.verifyValue.address,
            mobile_no: "+91" + this.state.verifyValue.mobile_no,
            email_id: this.state.verifyValue.email_id,
            locality: this.state.verifyValue.locality,
            administrativeArea: this.state.verifyValue.administrativeArea,
            postalCode: this.state.verifyValue.postalCode,
            verification_code: this.state.NewLocationPin,
            errorMessage: JSON.stringify(
              result?.data?.error?.details?.map((data) => data)
            ),
          };
          this.updatePushStatus(apiReq);
        }
      });
    } else {
      alert("verification Field Cannot Be empty");
    }
  };
  resend = (obj) => {
    this.Confirm(this.state.verifyValue);
    // $("#gbpPassword").attr("hidden", true);
    // $("#gbpEmail").attr("hidden", false);
  };
  updatePushStatus = (obj) => {
    backendApi(obj).then(async (result) => {
      if (result.success === "1") {
        this.listWithUiId(UID);
      }
    });
  };
  closeButton = (obj) => {
    $("#popUporganizationBulkUpload").attr("hidden", true);
    $("#adminPopUp").attr("hidden", true);
  };
  editAdressPush = (obj) => {
    alert(obj + "hhhhhhhhhhhhh");
    window.sessionStorage.setItem("dataPushData", JSON.stringify(obj));
    //   window.location.href="/createNewLocationSecondery"
  };
  goClickmaqnagerClick = (e) => {
    e.preventDefault();
    const apiReq = {
      functionName: "InviteAdminToLocationPush",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      email: this.state.managerEmail,
      role: "MANAGER",
      locationid: this.state.verifyValue.location_id,
    };
    backendApi(apiReq).then((result) => {
      if (result.success === "1") {
        const apiReq = {
          functionName: "updatePushStatus",
          location_id: this.state.verifyValue.location_id,
          g_pin: this.state.verificationPassword,
          status: "Admin Invited",
          id: objid,
          full_name: this.state.verifyValue.full_name,
          address: this.state.verifyValue.address,
          mobile_no: "+91" + this.state.verifyValue.mobile_no,
          email_id: this.state.verifyValue.email_id,
          locality: this.state.verifyValue.locality,
          administrativeArea: this.state.verifyValue.administrativeArea,
          postalCode: this.state.verifyValue.postalCode,
          verification_code: this.state.NewLocationPin,
          errorMessage: "",
        };
        this.updatePushStatus(apiReq);
        $("#verificationSuccsess").attr("hidden", false);
        $("#verificationCodeEmailId").attr("hidden", true);
        $("#adminPopUp").attr("hidden", true);
      } else {
        const apiReq = {
          functionName: "updatePushStatus",
          location_id: this.state.verifyValue.location_id,
          g_pin: this.state.verificationPassword,
          status: "Admin Invitation Failed",
          id: objid,
          full_name: this.state.verifyValue.full_name,
          address: this.state.verifyValue.address,
          mobile_no: "+91" + this.state.verifyValue.mobile_no,
          email_id: this.state.verifyValue.email_id,
          locality: this.state.verifyValue.locality,
          administrativeArea: this.state.verifyValue.administrativeArea,
          postalCode: this.state.verifyValue.postalCode,
          verification_code: this.state.NewLocationPin,
          errorMessage: JSON.stringify(
            result?.data?.error?.details?.map((data) => data)
          ),
        };
        this.updatePushStatus(apiReq);
      }
    });
  };

  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          <Header
            heading="No Data Found Bulk Upload"
            headerImage={Auditimage}
          />
          <div className="container-fluid">
            <div className="tableOuterWidth table_width_md">
              <div className="module_popupsearchBtn">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => {
                    this.onSaveGbp();
                  }}
                >
                  Push Location to GBP
                </button>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th className="col-1">ID</th>
                    <th className="col-2">Business Name</th>
                    <th className="col-4">Address</th>
                    <th className="col-1">Status</th>
                    {/* <th className="col-2"></th> */}
                    <th className="col-2"></th>
                    <th className="col-2"></th>
                  </tr>
                </thead>
                <div class="table_Outerscroll">
                  {this.state.dataListFromApi.map((data, i) => (
                    <tbody className="table_Outertbody">
                      <tr>
                        <td className="col-1">{data.id}</td>
                        <td className="col-2">{data.full_name} </td>
                        <td className="col-4">{data.address}</td>
                        {/* <td className="col-1">{data.status}</td> */}
                        {data.status == "Push Initiated" ? (
                          <td className="col-4">
                            <p>
                              <Icon.CheckCircle
                                style={{ color: "GREEN" }}
                                className="icon_common"
                              />
                              Pushed
                            </p>
                          </td>
                        ) : data.status == "Failed" ? (
                          <td className="col-2">
                            {" "}
                            <p>
                              <Icon.CheckCircle
                                style={{ color: "RED" }}
                                className="icon_common"
                              />
                              Failed
                            </p>
                            <button className="btn btn-primary">
                              <a
                                href="/createNewLocationSecondery"
                                onClick={() => this.editAdressPush(data)}
                              >
                                Edit Listing
                              </a>
                            </button>
                          </td>
                        ) : (
                          ""
                        )}
                        {data.status == "COMPLETED" && (
                          <td className="col-2">
                            {" "}
                            <button
                              className="btn btn-primary"
                              onClick={(e) => {
                                this.AdminClick(data);
                              }}
                            >
                              Invite Admin Role
                            </button>
                          </td>
                        )}
                        {data.status == "Push Initiated" ||
                        data.status == "Verification Failed" ||
                        data.status == "Verification Started" ? (
                          <td
                            className="col-2"
                            onClick={(e) => {
                              this.popUporganizationBulkUploadVerify(data);
                            }}
                          >
                            {" "}
                            <button
                              className="btn btn-danger mr-2 verifyyshow"
                              id={"verifybutton" + data.id}
                            >
                              Verify
                            </button>
                          </td>
                        ) : (
                          ""
                        )}
                        {data.status == "Admin Invited" && (
                          <td className="col-2">
                            {" "}
                            <button className="btn btn-danger mr-2 verifyyshow">
                              {data.status}
                            </button>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  ))}
                  {/* <tr id="nodata">
                                        <div className="content-sub-wrapper col-md-12">
                                            <div className="content-sub">
                                                <p>No Data Found</p>
                                            </div>
                                        </div>
                                    </tr> */}
                </div>
              </table>
            </div>
            <div
              className="headerPopup"
              id="popUporganizationBulkUpload"
              hidden
            >
              <div className="module_popup">
                <div className="module_innerpopup">
                  <div className="module_close"></div>
                  <div className="module_header text-center">
                    Email Verification
                  </div>
                  <button
                    type="button"
                    className="btn btn-save mb-1 gobutton_position"
                    onClick={() => this.closeButton()}
                  >
                    X
                  </button>
                  <div className="module_body">
                    <div className="module_popupsearch_wrpr">
                      <div className="module_popupsearch">
                        <div className="container">
                          <div className="row userrow_form" id="gbpEmail">
                            <div className="col-md-4 d-flex align-items-center ">
                              <label className="col-form-label">
                                Choose Method
                              </label>
                            </div>
                            <div className="col-md-8">
                              <select
                                value={this.state.setMethod}
                                id="organisation_name"
                                className="did-floating-select form-control"
                                onChange={(e) =>
                                  this.setState({ setMethod: e.target.value })
                                }
                              >
                                <option value="">Please Choose a Method</option>
                                <option value="SMS">Message</option>
                                <option value="PHONE_CALL">Phone</option>
                                <option value="EMAIL">Email</option>
                              </select>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-4 d-flex align-items-center ">
                              <label className="col-form-label" id="methodType">
                                {this.state.setMethod}{" "}
                              </label>
                            </div>
                            <div className="col-md-8">
                              <input
                                onChange={(e) =>
                                  this.setState({
                                    inputDetails: e.target.value,
                                  })
                                }
                                // type="password"
                                id="OTP"
                                className="form-control"
                                // placeholder="Email"
                              />
                            </div>
                          </div>
                          <div
                            className="row userrow_form"
                            id="gbpPassword"
                            hidden
                          >
                            <div className="col-md-4 d-flex align-items-center ">
                              <label className="col-form-label pt-0">
                                {" "}
                                Verification OTP
                              </label>
                            </div>
                            <div className="col-md-8">
                              <input
                                onChange={(e) => {
                                  this.setState({
                                    verificationPassword: e.target.value,
                                  });
                                }}
                                // type="password"
                                // value={this.state.inputDetails}
                                id="gppEmail"
                                className="form-control"
                                placeholder="OTP"
                              />
                              <input
                                onChange={(e) =>
                                  this.setState({
                                    VerificationCode: e.target.value,
                                  })
                                }
                                hidden
                                // type="password"
                                id="Gbppassword"
                                className="form-control"
                                placeholder="GB Password"
                              />
                            </div>
                          </div>
                          <div className="goButton text-right w-100" id="Go">
                            <button
                              type="button"
                              className="btn btn-save mb-1"
                              onClick={() => this.goClick()}
                            >
                              Go
                            </button>
                          </div>
                          <div
                            className="w-100 text-right goButton"
                            id="confirm"
                            hidden
                          >
                            <button
                              type="button"
                              className="btn btn-save mb-1"
                              onClick={() =>
                                this.Confirm(
                                  this.state.verifyValue
                                  //   "MainPage"
                                )
                              }
                            >
                              Confirm
                            </button>
                          </div>
                          <div
                            className="col-md-3 col-lg-2 p-0 goButton"
                            id="goBackPopUp"
                            hidden
                          >
                            <button
                              type="button"
                              className="btn btn-save mb-1"
                              onClick={() => {
                                $("#gbpPassword").attr("hidden", true);
                                $("#gbpEmail").attr("hidden", false);
                                $("#Go").attr("hidden", false);
                                $("#confirm").attr("hidden", true);
                                $("#goBackPopUp").attr("hidden", true);
                              }}
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="loder_div" hidden /> */}
            </div>
            <div className="headerPopup" id="adminPopUp" hidden>
              <div className="module_popup">
                <div className="module_innerpopup">
                  <div className="module_close"></div>
                  <div className="module_header text-center">Verification</div>
                  <button
                    type="button"
                    className="btn btn-save mb-1"
                    onClick={() => this.closeButton()}
                  >
                    X
                  </button>
                  <div className="module_body">
                    <div className="module_popupsearch_wrpr">
                      <div className="module_popupsearch">
                        <div className="row col-lg-9 col-md-12 organizationPop">
                          <div className="row userrow_form" id="ManagerEmailId">
                            <div className="col-md-5">
                              <label className="col-form-label pt-0">
                                {" "}
                                Manager Email Id
                              </label>
                            </div>
                            <div className="col-md-1">
                              <span>:</span>
                            </div>
                            <div className="col-md-5">
                              <input
                                onChange={(e) =>
                                  this.setState({
                                    managerEmail: e.target.value,
                                  })
                                }
                                id="gppEmail"
                                className="form-control"
                                placeholder="Email Id"
                              />
                            </div>
                          </div>
                          <div
                            className="col-md-3 col-lg-2 p-0 goButton"
                            id="GoaDMIN"
                          >
                            <button
                              type="button"
                              className="btn btn-save mb-1"
                              onClick={(e) => this.goClickmaqnagerClick(e)}
                            >
                              Go
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="loder_div" hidden /> */}
            </div>
          </div>
          <Footer />
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}
export default GbpMultipleLocation;
