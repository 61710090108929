import React, { Component } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Style } from "react-style-tag";
import SocialMediaimage from "../images/left_icons/social-media.png";
import img1 from "../images/img1.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import * as Icon from "react-feather";
import $ from "jquery";
import Slider from "react-slick";
import { backendApi } from "../apiService";
import NoDataFound from "../noDataFound";
import Moment from 'react-moment';
class ImageEditerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    this.shareAssign = this.shareAssign.bind(this);
  }
  componentDidMount() {
    this.list();
  }
  list() {
    var postData = {
      functionName: "listAdvGenerator",
      site_id: this.state.siteId,
      created_by: "1",
    };
    backendApi(postData).then((data) => {
      if (data.success === "1") {
        this.setState({
          list: data.data,
        });
      }
    });
  }
  handleImage() {
    window.location.href = "/imageEditer/imageEditer";
  }
  shareAssign = (e, i, object) => {
    var postData = {
      functionName: "approveEventData",
      masterId: object,
      approveFlag: e.target.checked,
    };
    if (window.confirm("Do you confirm to Assign?")) {
      backendApi(postData).then((data) => {
        if (data.success === "1") {
          // alert("Assigned Successfully");
          this.list()
        }
      });
    } else {
      document.getElementById("shareCheck" + i).checked = false;
    }
  };
  render() {
    var settings = {
      dots: false,
      arrows: true,
      infinite: false,
      draggable: true,
      touchMove: true,
      speed: 500,
      autoplay: false,
      slidesToShow: 3,
      slidesToScroll: 1,
    };
    window.$(document).ready(function () {
      window.$(".popup-image").magnificPopup({
        delegate: "a",
        type: "image",
        tLoading: "Loading image #%curr%...",
        mainClass: "mfp-img-mobile",
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
        },
        image: {
          tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
          titleSrc: function (item) {
            return item.el.attr("title") + "<small></small>";
          },
        },
      });
      window.$(".popup-video").magnificPopup({
        delegate: "a",
        type: "iframe",
        tLoading: "Loading...",
      });
    });

    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper">
          <Header heading="Image Editor" headerImage={SocialMediaimage} />
          <div className="social_userdiv">
            <div className="container-fluid ad-generator_div">
              <Tabs>
                {/* <TabList>
                      <Tab>Manual</Tab>
                      <Tab>Automatic</Tab>
                    </TabList> */}
                <div className="tag_upload-btn-wrapper">
                  {/* <button className="btn" onClick={(e) => this.handleImage(e)}>
                    Go For Manual/Automatic
                  </button> */}
                  {/* <input type="file" name="imageLoader" id="imageLoader"
                              className="controls__input" onChange={(e) => this.handleImage(e)}
                            /> */}
                  <a href="/manual" className="image-editor-btn">
                    Manual
                  </a>
                  <a href="/automatic" className="image-editor-btn ml-2">
                    Automatic
                  </a>
                </div>
                <TabPanel>
                  <div className="page-wrap">
                    <div className="controls tag_controls">
                      {/* <div className="col-lg-2 col-md-5">
                          <select className="form-control"
                            onChange={(e) => this.textImageList(e.target.value)} 
                            name="cmbManualEvent"
                            id="txtManualEvent">
                            <option value="0">Function</option>
                            {this.state.eventList.map((data, i) => {
                              return (
                                <option value={data.id} >{data.event_name} </option>
                              )
                            })}
                          </select>
                        </div> */}
                      {/* <div className="tag_upload-btn-wrapper">
                            <button className="btn">Choose Image</button>
                            <input type="file" name="imageLoader" id="imageLoader"
                              className="controls__input" onChange={(e) => this.handleImage(e)}
                            />
                          </div> */}
                      {/* <div className="row">
                            <div className="col-md-3">
                              <label className="controls__label" for="name">
                                Overlay Text
                              </label>
                              <input className="controls__input form-control"
                                id="overlayText" type="text" onChange={() => this.textChange()} />
                              <br />
                            </div>
                            <div className="col-md-3">
                              <label className="controls__label" for="name">
                                Font Size
                              </label>
                              <input
                                className="form-control" onChange={(e) =>
                                  this.fontSizeDetails(e.target.value + 'px')
                                }
                                placeholder="size in px"
                                id="txtFont"
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              >
                              </input>
                            </div>
                            <div className="col-md-3">
                              <label className="controls__label" for="name">
                                Font Style
                              </label>
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  this.fontStyleDetails(e.target.value)
                                }
                              >
                                <option value="Normal">Normal</option>
                                <option value="Italic">Italic</option>
                                <option value="Bold">Bold</option>
                              </select>
                            </div>
                            <div className="col-md-3">
                              <label className="controls__label" for="name">
                                Font Family
                              </label>
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  this.fontFamilyDetails(e.target.value)
                                }
                              >
                                <option value="OpenSans Regular">
                                  OpenSans Regular
                                </option>
                                <option value="Poppins sans-serif">
                                  Poppins sans-serif
                                </option>
                              </select>
                            </div>
                          </div> */}
                    </div>
                    {/* <div id="canvas-wrap">
                          <canvas
                            id="imageCanvas"
                            style={{
                              display: "block",
                              width: "500px",
                              height: "400px",
                            }}
                          >
                            <canvas id="canvasID"></canvas>
                          </canvas>
                        </div> */}
                    {/* <div className="tag_downloadImage">
                          <div
                            id="tagDownload"
                            style={{ display: "none" }}
                            onClick={() => this.imageDownload()}
                            className="col-md-12 tag_TemplateDowloadDiv "
                            title="Download template"
                          >
                            <Icon.Download /> Download Image
                          </div>
                        </div> */}
                    {/* <div className="button-wrapper">
                        <button type="button" className="btn-save" onClick={() => { this.saveImageEditor() }}>
                          Save
                        </button>
                        <button
                          type="button"
                          className="btn-cancel"
                          onClick={() => {
                            // $("#ImageModal").attr("hidden", true);
                          }}
                        >
                          Cancel
                        </button>
                      </div> */}
                    <div className="col-md-12 tableResponsive tagImageEditrListTble">
                      <div className="tableOuterWidth">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="col-1">Sl no</th>
                              {/* <th className="col-2">Brand</th> */}
                              <th className="col-1">Function</th>
                              <th className="col-6 text-center">Image</th>
                              <th className="col-2 pl-1  ">Texts</th>
                              <th className="col-1 pl-1">Time</th>
                              <th className="col-1">Assigned to copyright</th>
                            </tr>
                          </thead>
                          {this.state.list.length > 0 ? (
                            <div className="table_Outerscroll">
                              <tbody className="table_Outertbody">
                                {this.state.list.map((object, i) => {
                                  return (
                                    <tr>
                                      <td className="col-1"> {i + 1} </td>
                                      <td className="col-1">
                                        {object.event_name}
                                      </td>
                                      <td
                                        className="col-5 imageText"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <div
                                          className=""
                                          style={{ width: "400px" }}
                                        >
                                          {Object.keys(object)
                                            .toString()
                                            .indexOf("mediaArray") == "-1" ? (
                                            ""
                                          ) : (
                                            <>
                                              {object.mediaArray.length > 0 ? (
                                                <>
                                                  <Slider {...settings} key={i}>
                                                    {object.mediaArray.map(
                                                      (item, j) => (
                                                        <div className="popup-image">
                                                          <div className="popupGallerysection">
                                                            <a
                                                              href={
                                                                item.edited_image_url ===
                                                                ""
                                                                  ? item.image_url
                                                                  : item.edited_image_url
                                                              }
                                                              title="img"
                                                              target="_blank"
                                                            >
                                                              <img
                                                                title=""
                                                                src={
                                                                  item.edited_image_url ===
                                                                  ""
                                                                    ? item.image_url
                                                                    : item.edited_image_url
                                                                }
                                                              />
                                                            </a>
                                                          </div>
                                                        </div>
                                                      )
                                                    )}
                                                  </Slider>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </td>
                                      <td className="col-2 description_edit">
                                        {" "}
                                        <>
                                          {Object.keys(object)
                                            .toString()
                                            .indexOf("mediaArray") == "-1" ? (
                                            ""
                                          ) : (
                                            <>
                                              {object.mediaArray.length > 0 ? (
                                                <>
                                                  {object.mediaArray.map(
                                                    (item, j) => (
                                                      <>
                                                        <p
                                                          className="description_ellipse"
                                                          title={
                                                            item.image_text
                                                          }
                                                        >
                                                          {item.image_text}
                                                        </p>
                                                      </>
                                                    )
                                                  )}
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          )}
                                        </>
                                      </td>
                                      <td className="col-2 ">
                                        {/* <Icon.Share2 className="icon_common "  onClick={() => {
                  $("#editPopup").attr("hidden", false);
                }}/> */}
                                        <div>
                                        <Moment>{object.created_on}</Moment>
                                        </div>

                                       
                                      </td>
                                      <td className="col-1 ">
                                      <div
                                          className="imageChecked"
                                          style={{ right: "auto" }}
                                        >
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            checked={object.is_approved===1?true:false}
                                            id={"shareCheck" + i}
                                            onClick={(e) =>
                                              this.shareAssign(e, i, object.id)
                                            }
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </div>
                          ) : (
                            <NoDataFound />
                          )}
                        </table>
                        <div id="editPopup" className="module_popup" hidden>
                          <div className="module_innerpopup">
                            <div className="module_close">
                              <Icon.XCircle
                                onClick={() => {
                                  $("#editPopup").attr("hidden", true);
                                  $(".modules_div_org").removeClass("active");
                                  $("#orgainzationSelectAll").prop(
                                    "checked",
                                    false
                                  );
                                }}
                              />
                            </div>
                            <div className="userListmodal_head">
                              <span className="subhead">Share To</span>
                            </div>
                            <div className="module_body">
                              <div className="module_popupsearch_wrpr">
                                <div className="userlistmodal_body">
                                  <input
                                    type="text"
                                    className="form-control m-0"
                                    placeholder="user's name"
                                  />
                                </div>
                                <div className="module_popupsearchBtn">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => this.saveOrgAllotment()}
                                  >
                                    Share
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                {/* <TabPanel>
                      <div className="row tag_controls"> */}
                {/* <div className="col-lg-2 col-md-5">
                          <select className="form-control"
                            onChange={() => this.imageEventDropList()}
                            name="cmbBrand"
                            id="txtBrand">
                            <option value="0" >Brand</option>
                            {this.state.list.map((data, i) => {
                              return (
                                <option value={data.brand_name}>{data.brand_name} </option>
                              )
                            })}
                          </select>
                        </div> */}
                {/* {console.log("event", this.state.eventList)}
                        <div className="col-lg-2 col-md-5">
                          <select className="form-control"
                            onChange={(e) => this.textImageList(e.target.value)} 
                            name="cmbEvent"
                            id="txtEvent">
                            <option value="0">Function</option>
                            {this.state.eventList.map((data, i) => {
                              return (
                                <option value={data.id} >{data.event_name} </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="row imageListwrap">
                        <div className="chekedImage">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              onClick={() =>this.imageClick() }
                              // textChange
                            />
                            <label className="form-check-label" for="flexCheckDefault">
                              Select all with random text
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked"
                            />
                            <label className="form-check-label" for="flexCheckChecked">
                              Select All
                            </label>
                          </div>
                        </div>
                        {console.log("aaaaaaaaaaaaaaaa", this.state.listImage)}
                        {this.state.listImage.length > 0 ?
                          <div className="imageBoxes row">
                            {this.state.listImage.map((object, i) => {
                              return (
                                <div className="col-lg-3 ImageMainWrap col-md-6">
                                  <div className="imageBoxesWrap">
                                    <img src={object.image_url} className="test" */}
                {/* // id="txtImageUrl"  onClick={() => {$("#ImageModal").attr("hidden", false);}}
                                      id="txtImageUrl"  onClick={() =>this.imageClick(object) }
                                    // onClick={() => this.textImageList()}
                                    // onClick={() => { */}
                {/* //   $("#ImageModal").attr("hidden", false);
                                    // }} 
                                    /> */}
                {/* </div>
                                  <div className="imageChecked">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                  </div>
                                  <div className="TChecked">
                                    <span>T</span>
                                  </div>
                                </div>                          
                              )})}
                          </div>
                          : ""}
                      </div> */}
                {/* <div className="col-md-12 tableResponsive">
                        <div className="tableOuterWidth">
                          <table className="table">
                            <thead>
                              <tr>
                                <th className="col-1">Sl no</th>
                                <th className="col-2">Brand</th>
                                <th className="col-2">Function</th>
                                <th className="col-2">Image</th>
                                <th className="col-3">Texts</th>
                                <th className="col-2"></th>
                              </tr>
                            </thead>
                            <div className="table_Outerscroll">
                              <tbody className="table_Outertbody">
                                <tr>
                                  <td className="col-1">1</td>
                                  <td className="col-2">Xilligence</td>
                                  <td className="col-2">Diwali</td>
                                  <td className="col-2">
                                    <div className="imageDisplay">
                                      <img src={img1} />
                                    </div>
                                  </td>
                                  <td className="col-3">
                                    <p className="description_ellipse">
                                      Lorem Ipsum is simply dummy text of the
                                      printing and typesetting industry. Lorem Ipsum
                                      has been the industry's standard dummy text
                                      ever since the 1500s, when an unknown printer
                                      took a galley of type and scrambled it to
                                    </p>
                                    <p className="description_ellipse">
                                      Lorem Ipsum is simply dummy text of the
                                      printing and typesetting industry. Lorem Ipsum
                                      has been the industry's standard dummy text
                                      ever since the 1500s, when an unknown printer
                                      took a galley of type and scrambled it to
                                    </p>
                                  </td>
                                  <td className="col-2 ">
                                    <Icon.Edit className="icon_common mr-3" />
                                    <Icon.Trash className="icon_common" />
                                  </td>
                                </tr>
                              </tbody>
                            </div>
                          </table>
                        </div>
                      </div> */}
                {/* </TabPanel> */}
              </Tabs>
              {/*  */}
            </div>
          </div>
          <Footer />
        </div>
        {/* <div className="module_popup ImageModal" id="ImageModal" hidden>
              <div className="module_innerpopup module_bodypop">
                <div className="module_headdiv">
                  choose text
                  <div className="module_close">
                    <Icon.XCircle
                      color="red"
                      onClick={() => {
                        $("#ImageModal").attr("hidden", true);
                      }}
                    />
                  </div>
                </div>
                <div className="module_body textEditorScroll">
                  <h5 className="mainHead_popup"></h5>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="imagepop">
                        <img src={this.state.imagePopUp}></img>
                      </div>
                      <div className="button-wrapper">
                        <button type="button" className="btn-save">
                          Save
                        </button>
                        <button
                          type="button"
                          className="btn-cancel"
                          onClick={() => {
                            $("#ImageModal").attr("hidden", true);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-6 imageTextscroll p-0">
                      
                      <p>
                      {this.state.popUpList.map((object, i) => {
                        return(<div key={0}>
    {
    object.text_name
    }
                        </div>)
                      })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
        <div className="loder_div" hidden />
      </div>
    );
  }
}
export default ImageEditerList;
