import React, { useEffect } from "react";

import { useState } from "react";
import { useLocation } from "react-router-dom";
import * as Icon from "react-feather";
import GoogleMap from "./GoogleMap";
import $ from "jquery";
function EditListingComponent(props) {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [Title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [storeCode, setStoreCode] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [googleAddressPin, setGoogleAddressPin] = useState("");
  const [description, setdescription] = useState("");
  const [phone, setPhone] = useState("");
  const [adminstriveArea, setadminstriveArea] = useState("");
  const [locality, setlocality] = useState("");
  const [address, setaddress] = useState("");
  const [postalCode, setpostalCode] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  //   const [websiteUrl, setwebsiteUrl] = useState("");
  //   const [Verificationmethod, setVerificationmethod] = useState("");
  //   const [VerificationCodeEmail, setVerificationCodeEmail] = useState("");
  //   const [verificationRole, setverificationRole] = useState("");
  //   const [VerificationmethodPhoneNumber, setVerificationmethodPhoneNumber] =
  //     useState("");
  //   const [VerificationCode, setVerificationCode] = useState("");
  //   const [currentPassword, setCurrentPassword] = useState("");
  //   const [newPassword, setNewPassword] = useState("");
  //   const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const closeHandler = (e) => {
    setShow(false);
    props.onClose(false);
  };
  useEffect(() => {
    console.log(props);
    if (props.props) {
      debugger;
      setCategoriesList(props.categoriesList);
      let filteredCategory = props.categoriesList.filter((obj) =>
        obj.name?.toLowerCase().includes(props.props.category?.toLowerCase())
      );
      // console.log("filteredCategoryfilteredCategory", filteredCategory);
      setSelectedCategory(filteredCategory[0]);
      $("#LocationSelectedCategory").val(filteredCategory[0]?.displayName);
      setShow(props.show);
      // setTitle(props.props.full_name);
      setFirstName(props.props.full_name);
      // setCategory(props.props.category);
      setdescription(
        window.sessionStorage.getItem("switchUserOrganization") !== ""
          ? window.sessionStorage.getItem("switchUserOrganization")
          : window.sessionStorage.getItem("organization")
      );
      setPhone(
        props.props.mobile_no.includes("null") ? "" : props.props.mobile_no
      );
      setStoreCode(props.props.storeCode);
      setadminstriveArea(props.props.administrativeArea);
      setlocality(props.props.locality);
      setaddress(props.props.address);
      setpostalCode(props.props.postalCode);
      setGoogleAddressPin(
        props.props.address +
          ", " +
          props.props.locality +
          ", " +
          props.props.administrativeArea
      );
      setLongitude(
        props.props.longitude != "null" || props.props.longitude != ""
          ? props.props.longitude
          : ""
      );
      setLatitude(
        props.props.latitude != "null" || props.props.latitude != ""
          ? props.props.latitude
          : ""
      );
    }
  }, [props.show]);
  const saveLocation = (e) => {
    e.preventDefault();
    var validationFlag = validationCheck();
    if (validationFlag == true) {
      const apiReq = {
        functionName: "updatePushStatus",
        location_id: "",
        g_pin: "",
        status: "Push Initiated",
        id: props.props.id,
        full_name: firstName,
        address: address,
        storeCode: storeCode,
        mobile_no: phone.replace("+91", ""),
        email_id: "",
        locality: locality,
        category: selectedCategory?.name,
        latitude: latitude,
        longitude: longitude,
        administrativeArea: adminstriveArea,
        postalCode: postalCode,
        verification_code: "",
      };
      props.updateData(apiReq);
      closeHandler();
    }
  };
  const validationCheck = () => {
    if (!storeCode) {
      alert("Store Code is Required");
      return false;
    } else if (!firstName) {
      alert("Location Name is Required");
      return false;
    } else if (selectedCategory == "") {
      alert("Category is Required");
      return false;
    } else if (!address) {
      alert("Address is Required");
      return false;
    } else if (address.split(",").length > 5) {
      alert("Only max 5 entries allowed for Address");
      return false;
    } else if (!phone) {
      alert("Phone Number is Required");
      return false;
    } else if (!locality) {
      alert("Locality is Required");
      return false;
    } else if (!adminstriveArea) {
      alert("Administrative Area is Required");
      return false;
    } else if (!postalCode) {
      alert("Postal Code is Required");
      return false;
    } else if (
      longitude == "null" ||
      longitude == "" ||
      latitude == "null" ||
      latitude == ""
    ) {
      alert("Place pin of Business on map");
      return false;
    } else {
      return true;
    }
  };
  const changeHandler = (e) => {
    console.log("eeeee", e);
  };
  const splitAddressFunction = (address) => {
    console.log("eeeee", address);
    let pinData = [];
    pinData = address.split(", ");
    let re = /^[0-9]{6}$/;
    if (re.test(pinData[pinData.length - 1])) pinData.pop();

    if (pinData.some((obj) => obj.toLowerCase() == "india")) {
      let array = pinData.filter((obj) => obj.toLowerCase() != "india");
      pinData = array;
    }
    let adminArea = pinData.pop();
    let localArea = pinData.pop();
    setadminstriveArea(adminArea.toString());
    setlocality(localArea.toString());
    setaddress(pinData.join(", "));
    console.log("its there", pinData);
    // if (
    //   pinData.some((obj) => obj.toLowerCase() == AdminstriveArea.toLowerCase())
    // ) {
    //   let array = pinData.filter(
    //     (obj) => obj.toLowerCase() != AdminstriveArea.toLowerCase()
    //   );
    //   pinData = array;
    //   console.log("its there", pinData);
    // }
    // pinData
    //   .filter((obj) => obj.toLowerCase() == AdminstriveArea.toLowerCase())
    //   .map((adminArea) => {
    //     // console.log("adminArea", adminArea);
    //     setAdminstriveArea();
    //   });
    // if (pinData.toLowerCase().includes(AdminstriveArea.toLowerCase())) {
    //   pinData.toLowerCase().replace(AdminstriveArea.toLowerCase() + ", ");
    //   console.log("its there", pinData);
    // }
  };
  // const getCategoriesListGoogle = () => {
  //   const apiReq = {
  //     functionName: "googleLocationCategoryList",
  //     siteId: "59",
  //   };
  //   backendApi(apiReq).then((data) => {
  //     console.log("googleLocationCategoryList", data);
  //     setCategoriesList(data.data.categories);
  //   });
  // };
  const filterCategory = (e) => {
    if (e.target.value.length > 2) {
      var filterArray = categoriesList.filter((data) =>
        data.displayName.toLowerCase().includes(e.target.value.toLowerCase())
      );
      console.log("filterArrayfilterArray", filterArray);
      setFilteredCategories(filterArray);
    } else {
      setFilteredCategories([]);
    }
  };
  const selectCategory = (data) => {
    console.log(data, "datatatatata");
    setSelectedCategory(data);
    $("#LocationSelectedCategory").val(data?.displayName);
  };
  return (
    <div
      className="headerPopup"
      id="verificationCode"
      hidden={show ? false : true}
    >
      <div className="module_popup inner_module_popup_div">
        <div className="module_innerpopup">
          <div className="module_close">
            <Icon.XCircle onClick={() => closeHandler()} />
          </div>
          <div className="module_header text-center">
            Edit Business Information
          </div>
          <div className="module_body">
            <div className="module_popupsearch_wrpr">
              <div className="module_popupsearch">
                <div className="container-fluid">
                  <form className="user_formdiv" id="createLocationForm">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            {" "}
                            Store Code
                          </label>
                          <input
                            type="text"
                            onChange={(e) => setStoreCode(e.target.value)}
                            value={storeCode}
                            className="form-control"
                            id="firstName"
                            aria-describedby="emailHelp"
                            placeholder="Name"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1"> Name</label>
                          <input
                            type="text"
                            onChange={(e) => setFirstName(e.target.value)}
                            value={firstName}
                            className="form-control"
                            id="firstName"
                            aria-describedby="emailHelp"
                            placeholder="Name"
                          />
                        </div>
                        {/* <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Title</label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            onChange={(e) => setTitle(e.target.value)}
                            value={Title}
                            aria-describedby="emailHelp"
                            placeholder="Title"
                          />
                        </div> */}
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Description
                          </label>
                          <input
                            value={description}
                            onChange={(e) => setdescription(e.target.value)}
                            className="form-control"
                            id="emailId"
                            aria-describedby="emailHelp"
                            placeholder="Description"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Business Category
                          </label>
                          <span className="location_search">
                            <input
                              className="form-control"
                              type="text"
                              id="LocationSelectedCategory"
                              onChange={(e) => filterCategory(e)}
                              placeholder="Category"
                            />
                            {filteredCategories.length > 0 && (
                              <div className="autocomplete__list location_search_drop">
                                <ul
                                  id="categoryListDropdown"
                                  class="nav flex-column"
                                >
                                  {filteredCategories.map((data) => (
                                    <li
                                      key={data.name}
                                      onMouseDown={() => selectCategory(data)}
                                    >
                                      {data.displayName}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </span>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Phone No</label>
                          <input
                            type="text"
                            className="form-control"
                            id="phoneNo"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            maxLength={10}
                            aria-describedby="emailHelp"
                            placeholder="Phone No"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Administrative Area
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="phoneNo"
                            // maxLength={10}
                            value={adminstriveArea}
                            onChange={(e) => setadminstriveArea(e.target.value)}
                            aria-describedby="emailHelp"
                            placeholder=" Administrative Area"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Locality</label>
                          <input
                            type="text"
                            className="form-control"
                            id="phoneNo"
                            // maxLength={10}
                            value={locality}
                            onChange={(e) => setlocality(e.target.value)}
                            aria-describedby="emailHelp"
                            placeholder="Locality"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Address</label>
                          <textarea
                            type="text"
                            className="form-control"
                            id="phoneNo"
                            // maxLength={10}
                            onChange={(e) => setaddress(e.target.value)}
                            value={address}
                            aria-describedby="emailHelp"
                            placeholder="Address"
                            rows="3"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            {" "}
                            Postal Code
                          </label>
                          <input
                            type="text"
                            onChange={(e) => setpostalCode(e.target.value)}
                            value={postalCode}
                            className="form-control"
                            id="firstName"
                            aria-describedby="emailHelp"
                            placeholder="Postal Code"
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <GoogleMap
                          longitude={longitude}
                          latitude={latitude}
                          googleAddressPin={googleAddressPin}
                          changeHandler={changeHandler}
                          setGoogleAddressPin={(googleAddressPin) => {
                            setGoogleAddressPin(googleAddressPin);
                            // splitAddressFunction(googleAddressPin);
                          }}
                          selectGoogleAddressPin={(
                            googleAddressPin,
                            lat,
                            lng
                          ) => {
                            setGoogleAddressPin(googleAddressPin);
                            // splitAddressFunction(googleAddressPin);
                            setLatitude(lat);
                            setLongitude(lng);
                          }}
                          onMarkerDragEnd={(lat, lng) => {
                            setLatitude(lat);
                            setLongitude(lng);
                          }}
                        />
                        <div className="row mt-2">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                {" "}
                                Longitude
                              </label>
                              <input
                                type="text"
                                onChange={(e) => setLongitude(e.target.value)}
                                value={longitude == "null" ? "" : longitude}
                                className="form-control"
                                id="firstName"
                                aria-describedby="emailHelp"
                                placeholder="Longitude"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                {" "}
                                Latitude
                              </label>
                              <input
                                type="text"
                                onChange={(e) => setLatitude(e.target.value)}
                                value={latitude == "null" ? "" : latitude}
                                className="form-control"
                                id="firstName"
                                aria-describedby="emailHelp"
                                placeholder="Latitude"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form_bttn">
                          <button
                            type="submit"
                            className="btn btn-primary submitButton saveListng"
                            onClick={(e) => saveLocation(e)}
                          >
                            <span>Save and Proceed</span>
                          </button>
                          {/* <Link
                        to="/claimBusiness"
                        className="btn btn-primary submitButton cancel_bttn"
                      >
                        <span>Cancel</span>
                      </Link> */}
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Website Url
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="phoneNo"
                            // maxLength={10}
                            onChange={(e) => setwebsiteUrl(e.target.value)}
                            value={websiteUrl}
                            aria-describedby="emailHelp"
                            placeholder="Website Url"
                          />
                        </div>
                      </div> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditListingComponent;
