import React from "react";
import { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { apiCallingPost } from "../apiService";

// const allOptions = [
//     { value: "option 1", label: "Himalaya" },
//     { value: "option 2", label: "Mahindra" },
// ];



function OrganisationLeading() {
    // const [selectedOptions, setSelectedOptions] = useState([]);
    const [organisationList, setOrganisationList] = useState([]);
    const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);


    useEffect(() => {
        handleOrganisationList();
    }, []);

    const handleOrganisationList = () => {
        const postData = {
            "functionName": "getOrganisationDivisionByAccount",
            "accountId": window.sessionStorage.getItem("switchingOrgId")
        }
        apiCallingPost(postData).then((data) => {
            console.log("🚀organisationList ~ apiCallingPost ~ data:", data)
            if (data.success === "1") {
                // setOrganisationList(data.result)
                const updatedResult = data.result.map((item, index) => ({
                    ...item,
                    inputValue: item.order_number
                }));
                setOrganisationList(updatedResult);
                const checked = data.result.map(item => item.is_active || false);
                setCheckedCheckboxes(checked);
            } else {
            }
        });
    }
    const handleSave = () => {
        const isEmptyInput = organisationList.some(data => data.inputValue === undefined || data.inputValue === '');

        if (isEmptyInput) {
            alert("Please fill in all sort order before saving.");
            return; // Stop further processing if any input value is empty
        }
        organisationList.sort((a, b) => {
            const inputA = parseInt(a.inputValue || 0);
            const inputB = parseInt(b.inputValue || 0);
            return inputA - inputB;
        });
        const postData = {
            "functionName": "saveOrUpdateorganisationDivisionByAccounts",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "data": organisationList.map((data, index) => ({
                "is_active": data.is_active,
                "organization_division_master_id": data.organization_division_master_id,
                "organisationDivisioId": data.organisationDivisionId,
                "order_number" : data.inputValue
            }))
        }
        console.log("🚀 ~ handleSave ~ postData:", postData)
        apiCallingPost(postData).then((data) => {
            console.log("🚀handleSave ~ apiCallingPost ~ data:", data)
            if (data.success === "1") {
                alert("Saved Successfully");
                handleOrganisationList()
            } else {
                // Handle error case if needed
            }
        }).catch(error => {
            console.error("Error while saving:", error);
            // Handle error case if needed
        });
    }
    const handleCheckboxChange = (index, isChecked) => {
        const updatedCheckboxes = [...organisationList];
        updatedCheckboxes[index].is_active = isChecked;
        setOrganisationList(updatedCheckboxes);
    };

    const handleInputChange = (index, value) => {
        const updatedOrganisationList = [...organisationList];
        updatedOrganisationList[index].inputValue = value;
        setOrganisationList(updatedOrganisationList);
    };
    return (
        <div className="organisation-div pl-0 pl-md-4 border-0">
            <div className="row mx-0">
                <div className="col-lg-12 subhead mt-3">Organisation Division</div>
                <div className="col-md-8 col-lg-6 col-xl-4">
                    {/* <Select
                        defaultValue={[]}
                        // isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        onChange={(options) => {
                            if (Array.isArray(options)) {
                                setSelectedOptions(options.map((opt) => opt.value));
                            }
                        }}
                        options={allOptions}
                    /> */}
                    <div class="card my-2 border-0">
                        <div class="card-body p-0">
                            {organisationList && organisationList.map((data, index) =>
                                <div key={index} className="organisation-leading-div">
                                    {console.log("organisationList......................................",organisationList)}
                                    <input className="organisation-leading-input"
                                        type="text"
                                        // placeholder="Sort order"
                                        value={data.inputValue }
                                        onChange={(e) => handleInputChange(index, e.target.value)}
                                        onKeyDown={(e) => {
                                            // Allow only numeric keys (0-9)
                                            if (!(e.key >= '1' && e.key <= '9') && e.key !== 'Backspace') {
                                                e.preventDefault();
                                                return;
                                            }
                                            const enteredValue = e.target.value + e.key;
                                            const parsedValue = parseInt(enteredValue);
                                            if (organisationList.some(item => item.inputValue === parsedValue)) {
                                                if (enteredValue.length > 1) {
                                                  if (e.key !== 'Backspace') {
                                                    e.preventDefault();
                                                  }
                                                } else {
                                                  e.preventDefault();
                                                  alert("Value is already assigned");
                                                }
                                                return;
                                              }

                                            if (isNaN(parsedValue) || parsedValue > organisationList.length) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    <div className="organisation-subdiv  orgainsationn-submain">
                                        <span>{data.organisatonDivisionMasterName}</span>
                                        <div className="checkbox_subdiv">
                                            <input type="checkbox" id={"html01" + index} className="checkbox_input"
                                                checked={data.is_active}
                                                onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                                            />
                                            <label htmlFor={"html01" + index} className="checkbox_label mb-0"></label>
                                        </div>

                                    </div>

                                </div>




                            )}
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="ml-auto my-3">
                            <button className="db-btn" onClick={() => handleSave()}>Save</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default OrganisationLeading;
