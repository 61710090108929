import React from "react";
import { useState, useEffect } from "react";
import { backendDashboardApi } from "../apiService";
import Dashboardimage from "../images/left_icons/dashboard.png";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import * as Icon from "react-feather";
import { FiChevronDown } from "react-icons/fi";
import { BsBorderBottom } from "react-icons/bs";
import moment from "moment";
import Pagination from "react-js-pagination";
function BudgetAllocation() {
  const [currentIndex, setcurrentIndex] = useState(0);
  const [isEditing, setIsEditing] = useState([]);
  const [fundAllocated, setFundAllocated] = useState('');
  const [balanceFund, setbalanceFund] = useState({ [`balanceFund0`]: 0 });
  const [fundAllocatedNew, setFundAllocatedNew] = useState({ [`fundAllocatedNew0`]: 0 });

  const [isBudgetVisible, setIsBudgetVisible] = useState(false);
  const [budgetMasterList, setBudgetMasterList] = useState([]);
  const [budgetAllocationList, setBudgetAllocationList] = useState([]);
  const [accountBudgetMasterId, setAccountBudgetMasterId] = useState("");
  const [selectedBudget, setSelectedBudget] = useState(null);
  const [editMode, setEditMode] = useState({ index: null, state: false });
  const [searchValue, setSearchValue] = useState("");
  const [activePage, setActivePage] = useState(1)
  const [totalCount, setTotalCount] = useState("")
  const [dataChanged, setdataChanged] = useState(new Date().getTime())


  const [inputValues, setInputValues] = useState({
    [`pmMetaPv0`]: "",
    [`pmMetaCv0`]: "",
    [`pmMetaScv0`]: "",
    [`pmGooglePv0`]: "",
    [`pmGoogleCv0`]: "",
    [`pmGoogleScv0`]: "",
    [`smMetaPv0`]: "",
    [`smMetaCv0`]: "",
    [`smMetaScv0`]: "",
    [`smGooglePv0`]: "",
    [`smGoogleCv0`]: "",
    [`smGoogleScv0`]: "",
    [`contentPv0`]: "",
    [`contentCv0`]: "",
    [`contentScv0`]: "",
    [`lpPv0`]: "",
    [`lpCv0`]: "",
    [`lpScv0`]: "",
    [`lpmPv0`]: "",
    [`lpmCv0`]: "",
    [`lpmScv0`]: "",
    [`pmMetaPvSpent0`]: "",
    [`pmMetaCvSpent0`]: "",
    [`pmMetaScvSpent0`]: "",
    [`pmGooglePvSpent0`]: "",
    [`pmGoogleCvSpent0`]: "",
    [`pmGoogleScvSpent0`]: "",
    [`smMetaPvSpent0`]: "",
    [`smMetaCvSpent0`]: "",
    [`smMetaScvSpent0`]: "",
    [`smGooglePvSpent0`]: "",
    [`smGoogleCvSpent0`]: "",
    [`smGoogleScvSpent0`]: "",
    [`contentPvSpent0`]: "",
    [`contentCvSpent0`]: "",
    [`contentScvSpent0`]: "",
    [`lpPvSpent0`]: "",
    [`lpCvSpent0`]: "",
    [`lpScvSpent0`]: "",
    [`lpmPvSpent0`]: "",
    [`lpmCvSpent0`]: "",
    [`lpmScvSpent0`]: "",
    [`paidMediaDealerShare0`]: "",
    [`paidMediahoShare0`]: "",
    [`smBudgetShare0`]: "",
    [`contentBudgetShare0`]: "",
    [`lpBudgetShare0`]: "",
    [`lpmBudgetShare0`]: "",
    [`lpmBudget0`]: "",
    [`lpmSpent0`]: "",
    [`lpBudget0`]: "",
    [`lpSpent0`]: "",
    [`contentBudget0`]: "",
    [`contentSpent0`]: "",
    [`metaBudget0`]: "",
    [`metaSpent0`]: "",
  });

  const handleInputChange = (e, field, index) => {
    const { value } = e.target;
    setInputValues(prevState => ({
      ...prevState,
      [field]: parseInt(value) || 0 // Parse input value as integer or default to 0
    }));

  };

  useEffect(() => {
    calculateBalanceOnchange(fundAllocatedNew[`fundAllocatedNew${currentIndex}`], currentIndex)
  }, [inputValues]);

  // Calculate the total sum of all input values
  const totalSum = inputValues.pmMetaPv + inputValues.pmMetaCv + inputValues.pmMetaScv + inputValues.pmGooglePv + inputValues.pmGoogleCv + inputValues.pmGoogleScv + inputValues.smMetaPv + inputValues.smMetaCv + inputValues.smMetaScv + inputValues.smGooglePv + inputValues.smGoogleCv + inputValues.smGoogleScv + inputValues.contentPv + inputValues.contentCv + inputValues.contentScv + inputValues.lpPv + inputValues.lpCv + inputValues.lpScv + inputValues.lpmPv + inputValues.lpmCv + inputValues.lpmScv;

  const calculateBalance = (fundValue, index) => {
    console.log('remainingFund.....22222......', fundValue);
    return new Promise((resolve, reject) => {
      console.log('fundValue:', fundValue);
      console.log('fundValue...index..:', index);
      console.log('fund_allocated:', fundValue.fund_allocated);
      console.log('pmMetaPv:', fundValue.pmMetaPv);
      const remainingFund = fundValue.fund_allocated !== 0 ?
        fundValue.fund_allocated - (
          fundValue.pm_meta_pv + fundValue.pm_meta_cv + fundValue.pm_meta_scv +
          fundValue.pm_google_pv + fundValue.pm_google_cv + fundValue.pm_google_scv +
          fundValue.sm_meta_pv + fundValue.sm_meta_cv + fundValue.sm_meta_scv +
          fundValue.sm_google_pv + fundValue.sm_google_cv + fundValue.sm_google_scv +
          fundValue.content_pv + fundValue.content_cv + fundValue.content_scv +
          fundValue.lp_pv + fundValue.lp_cv + fundValue.lp_scv +
          fundValue.lpm_pv + fundValue.lpm_cv + fundValue.lpm_scv
        ) :
        0;
      console.log('remainingFund...........', remainingFund);
      resolve(remainingFund);
      return remainingFund;
      //return remainingFund;
    })


    // setbalanceFund({[`balanceFund${index}`]:remainingFund})
    // return true;
  };


  const calculateBalanceOnchange = (fundValue, index) => {
    // inputValues[`contentCv${index}`]
    console.log('fundValue...........', fundValue);
    console.log('inputValues[`pmMetaPv${index}`]...........', inputValues[`pmMetaPv${index}`]);
    const remainingFund = fundValue !== 0 ?
      fundValue - (
        inputValues[`pmMetaPv${index}`] + inputValues[`pmMetaCv${index}`] + inputValues[`pmMetaScv${index}`] +
        inputValues[`pmGooglePv${index}`] + inputValues[`pmGoogleCv${index}`] + inputValues[`pmGoogleScv${index}`] +
        inputValues[`smMetaPv${index}`] + inputValues[`smMetaCv${index}`] + inputValues[`smMetaScv${index}`] +
        inputValues[`smGooglePv${index}`] + inputValues[`smGoogleCv${index}`] + inputValues[`smGoogleScv${index}`] +
        inputValues[`contentPv${index}`] + inputValues[`contentCv${index}`] + inputValues[`contentScv${index}`] +
        inputValues[`lpPv${index}`] + inputValues[`lpCv${index}`] + inputValues[`lpScv${index}`] +
        inputValues[`lpmPv${index}`] + inputValues[`lpmCv${index}`] + inputValues[`lpmScv${index}`]
      ) :
      0;
    console.log('remainingFund...........', remainingFund);
    setbalanceFund({ [`balanceFund${index}`]: remainingFund })
    return remainingFund;





  };

  const handleFundAllocated = (fundValue, index, item) => {
    // calculateBalance(item,index)
    // let data = [...budgetAllocationList]
    // console.log('a...handleFundAllocated...fundValue......',fundValue);
    // let a = data.map((obj)=>{
    //   console.log('a...handleFundAllocated...obj.dealerId......',obj.dealerId);
    //   console.log('a...handleFundAllocated...obj.fund_allocated......',item.dealerId);
    //     if(obj.dealerId == item.dealerId){
    //         obj.fund_allocated = fundValue
    //     }
    //     return obj
    // })

    // console.log('a...handleFundAllocated.........',a);

    // setBudgetAllocationList(a)
    // setdataChanged(new Date().getTime())
    calculateBalanceOnchange(fundValue, index)
    setFundAllocatedNew({ [`fundAllocatedNew${index}`]: fundValue })
    // setFundAllocated(fundValue);

  }

  const handleEditClick = (index, data) => {
    setcurrentIndex(index)
    console.log("......................", data)
    const {
      content_cv,
      content_pv,
      content_scv,
      pm_meta_pv,
      pm_meta_cv,
      pm_meta_scv,
      pm_google_pv,
      pm_google_cv,
      pm_google_scv,
      sm_meta_pv,
      sm_meta_cv,
      sm_meta_scv,
      sm_google_pv,
      sm_google_cv,
      sm_google_scv,
      lp_pv,
      lp_cv,
      lp_scv,
      lpm_pv,
      lpm_cv,
      lpm_scv,
      pm_meta_pv_spent,
      pm_meta_cv_spent,
      pm_meta_scv_spent,
      pm_google_pv_spent,
      pm_google_cv_spent,
      pm_google_scv_spent,
      sm_meta_pv_spent,
      sm_meta_cv_spent,
      sm_meta_scv_spent,
      sm_google_pv_spent,
      sm_google_cv_spent,
      sm_google_scv_spent,
      content_pv_spent,
      content_cv_spent,
      content_scv_spent,
      lp_pv_spent,
      lp_cv_spent,
      lp_scv_spent,
      lpm_pv_spent,
      lpm_cv_spent,
      lpm_scv_spent,
      paid_media_dealer_share,
      paid_media_ho_share,
      sm_budget_share,
      content_budget_share,
      lp_budget_share,
      lpm_budget_share,
      lpm_budget,
      lpm_spent,
      lp_budget,
      lp_spent,
      content_budget,
      content_spent,
      sm_budget,
sm_spent,

      fund_allocated,
      balanceFund
    } = data



    // setIsEditing(prevState => ({
    //   ...prevState,
    //   [index]: true // Parse input value as integer or default to 0
    // }));

    setIsEditing(prevState => ({
      ...Object.keys(prevState).reduce((acc, key) => ({ ...acc, [key]: false }), {}), // Close all other sections
      [index]: true // Open the clicked section
    }));

    console.log("pm_meta_pv**********......................", pm_meta_pv)

    setInputValues({
      [`contentCv${index}`]: content_cv,
      [`contentPv${index}`]: content_pv,
      [`contentScv${index}`]: content_scv,
      [`pmMetaPv${index}`]: pm_meta_pv,
      [`pmMetaCv${index}`]: pm_meta_cv,
      [`pmMetaScv${index}`]: pm_meta_scv,
      [`pmGooglePv${index}`]: pm_google_pv,
      [`pmGoogleCv${index}`]: pm_google_cv,
      [`pmGoogleScv${index}`]: pm_google_scv,
      [`smMetaPv${index}`]: sm_meta_pv,
      [`smMetaCv${index}`]: sm_meta_cv,
      [`smMetaScv${index}`]: sm_meta_scv,
      [`smGooglePv${index}`]: sm_google_pv,
      [`smGoogleCv${index}`]: sm_google_cv,
      [`smGoogleScv${index}`]: sm_google_scv,
      [`lpPv${index}`]: lp_pv,
      [`lpCv${index}`]: lp_cv,
      [`lpScv${index}`]: lp_scv,
      [`lpmPv${index}`]: lpm_pv,
      [`lpmCv${index}`]: lpm_cv,
      [`lpmScv${index}`]: lpm_scv,
      [`pmMetaPvSpent${index}`]: pm_meta_pv_spent,
      [`pmMetaCvSpent${index}`]: pm_meta_cv_spent,
      [`pmMetaScvSpent${index}`]: pm_meta_scv_spent,
      [`pmGooglePvSpent${index}`]: pm_google_pv_spent,
      [`pmGoogleCvSpent${index}`]: pm_google_cv_spent,
      [`pmGoogleScvSpent${index}`]: pm_google_scv_spent,
      [`smMetaPvSpent${index}`]: sm_meta_pv_spent,
      [`smMetaCvSpent${index}`]: sm_meta_cv_spent,
      [`smMetaScvSpent${index}`]: sm_meta_scv_spent,
      [`smGooglePvSpent${index}`]: sm_google_pv_spent,
      [`smGoogleCvSpent${index}`]: sm_google_cv_spent,
      [`smGoogleScvSpent${index}`]: sm_google_scv_spent,
      [`contentPvSpent${index}`]: content_pv_spent,
      [`contentCvSpent${index}`]: content_cv_spent,
      [`contentScvSpent${index}`]: content_scv_spent,
      [`lpPvSpent${index}`]: lp_pv_spent,
      [`lpCvSpent${index}`]: lp_cv_spent,
      [`lpScvSpent${index}`]: lp_scv_spent,
      [`lpmPvSpent${index}`]: lpm_pv_spent,
      [`lpmCvSpent${index}`]: lpm_cv_spent,
      [`lpmScvSpent${index}`]: lpm_scv_spent,
      [`paidMediaDealerShare${index}`]: paid_media_dealer_share,
      [`paidMediahoShare${index}`]: paid_media_ho_share,
      [`smBudgetShare${index}`]: sm_budget_share,
      [`contentBudgetShare${index}`]: content_budget_share,
      [`lpBudgetShare${index}`]: lp_budget_share,
      [`lpmBudgetShare${index}`]: lpm_budget_share,
      [`lpmBudget${index}`]: lpm_budget,
      [`lpmSpent${index}`]: lpm_spent,
      [`lpBudget${index}`]: lp_budget,
      [`lpSpent${index}`]: lp_spent,
      [`contentBudget${index}`]: content_budget,
      [`contentSpent${index}`]: content_spent,
      [`metaBudget${index}`]: sm_budget,
      [`metaSpent${index}`]: sm_spent,

    })
    setFundAllocatedNew({ [`fundAllocatedNew${index}`]: fund_allocated })
    setbalanceFund({ [`balanceFund${index}`]: balanceFund })

    // setFundAllocated(fund_allocated);
    setEditMode({ state: true, index })

  };

  const handleCancelClick = (index) => {
    setIsEditing(prevState => ({
      ...prevState,
      [index]: false // Parse input value as integer or default to 0
    }));
    setEditMode({ state: false, index })
  };

  const remainingFund = fundAllocated - totalSum;

  const showHistoryHandle = () => {
    window.location.href = '/fund-allocation-history'
  }


  const handleSaveBudgetAllocation = (index, dealerId, accountBudgetMasterDetailsId) => {
    console.log("🚀 ~ handleSaveBudgetAllocation ~ dealerId:", dealerId)

    console.log("accountBudgetMasterId=====", accountBudgetMasterId);

    const fund_spent = inputValues[`pmMetaPvSpent${index}`] +
      inputValues[`pmMetaCvSpent${index}`] +
      inputValues[`pmMetaScvSpent${index}`] +
      inputValues[`pmGooglePvSpent${index}`] +
      inputValues[`pmGoogleCvSpent${index}`] +
      inputValues[`pmGoogleScvSpent${index}`] +
      inputValues[`smMetaPvSpent${index}`] +
      inputValues[`smMetaCvSpent${index}`] +
      inputValues[`smMetaScvSpent${index}`] +
      inputValues[`smGooglePvSpent${index}`] +
      inputValues[`smGoogleCvSpent${index}`] +
      inputValues[`smGoogleScvSpent${index}`] +
      inputValues[`contentPvSpent${index}`] +
      inputValues[`contentCvSpent${index}`] +
      inputValues[`contentScvSpent${index}`] +
      inputValues[`lpPvSpent${index}`] +
      inputValues[`lpCvSpent${index}`] +
      inputValues[`lpScvSpent${index}`] +
      inputValues[`lpmPvSpent${index}`] +
      inputValues[`lpmCvSpent${index}`] +
      inputValues[`lpmScvSpent${index}`]

    const postData = {
      "functionName": "budgetAllocationSaveOrUpdate",
      "accountBudgetMasterDetailsId": accountBudgetMasterDetailsId ? accountBudgetMasterDetailsId : "",
      "createdBy": window.sessionStorage.getItem("userId"),
      "createdOn": new Date().getTime(),
      "accountBudgetMasterId": accountBudgetMasterId,
      "dealer_id": dealerId,
      "pmMetaPv": inputValues[`pmMetaPv${index}`],
      "pmMetaCv": inputValues[`pmMetaCv${index}`],
      "pmMetaScv": inputValues[`pmMetaScv${index}`],
      "pmGooglePv": inputValues[`pmGooglePv${index}`],
      "pmGoogleCv": inputValues[`pmGoogleCv${index}`],
      "pmGoogleScv": inputValues[`pmGoogleScv${index}`],
      "smMetaPv": inputValues[`smMetaPv${index}`],
      "smMetaCv": inputValues[`smMetaCv${index}`],
      "smMetaScv": inputValues[`smMetaScv${index}`],
      "smGooglePv": inputValues[`smGooglePv${index}`],
      "smGoogleCv": inputValues[`smGoogleCv${index}`],
      "smGoogleScv": inputValues[`smGoogleScv${index}`],
      "contentPv": inputValues[`contentPv${index}`],
      "contentCv": inputValues[`contentCv${index}`],
      "contentScv": inputValues[`contentScv${index}`],
      "lpPv": inputValues[`lpPv${index}`],
      "lpCv": inputValues[`lpCv${index}`],
      "lpScv": inputValues[`lpScv${index}`],
      "lpmPv": inputValues[`lpmPv${index}`],
      "lpmCv": inputValues[`lpmCv${index}`],
      "lpmScv": inputValues[`lpmScv${index}`],
      "pmMetaPvSpent": inputValues[`pmMetaPvSpent${index}`],
      "pmMetaCvSpent": inputValues[`pmMetaCvSpent${index}`],
      "pmMetaScvSpent": inputValues[`pmMetaScvSpent${index}`],
      "pmGooglePvSpent": inputValues[`pmGooglePvSpent${index}`],
      "pmGoogleCvSpent": inputValues[`pmGoogleCvSpent${index}`],
      "pmGoogleScvSpent": inputValues[`pmGoogleScvSpent${index}`],
      "smMetaPvSpent": inputValues[`smMetaPvSpent${index}`],
      "smMetaCvSpent": inputValues[`smMetaCvSpent${index}`],
      "smMetaScvSpent": inputValues[`smMetaScvSpent${index}`],
      "smGooglePvSpent": inputValues[`smGooglePvSpent${index}`],
      "smGoogleCvSpent": inputValues[`smGoogleCvSpent${index}`],
      "smGoogleScvSpent": inputValues[`smGoogleScvSpent${index}`],
      "contentPvSpent": inputValues[`contentPvSpent${index}`],
      "contentCvSpent": inputValues[`contentCvSpent${index}`],
      "contentScvSpent": inputValues[`contentScvSpent${index}`],
      "lpPvSpent": inputValues[`lpPvSpent${index}`],
      "lpCvSpent": inputValues[`lpCvSpent${index}`],
      "lpScvSpent": inputValues[`lpScvSpent${index}`],
      "lpmPvSpent": inputValues[`lpmPvSpent${index}`],
      "lpmCvSpent": inputValues[`lpmCvSpent${index}`],
      "lpmScvSpent": inputValues[`lpmScvSpent${index}`],
      "fund_allocated": fundAllocatedNew[`fundAllocatedNew${index}`],
      "fund_spent": fund_spent,
      "lpm_budget_share": inputValues[`lpmBudgetShare${index}`],
      "sm_budget_share": inputValues[`smBudgetShare${index}`],
      "lp_budget_share": inputValues[`lpBudgetShare${index}`],
      "content_budget_share": inputValues[`contentBudgetShare${index}`],
      "paid_media_dealer_share": inputValues[`paidMediaDealerShare${index}`],
      "paid_media_ho_share": inputValues[`paidMediahoShare${index}`],
      "contentBudget": inputValues[`contentBudget${index}`],
      "contentSpent": inputValues[`contentSpent${index}`],
      "lpBudget": inputValues[`lpBudget${index}`],
      "lpSpent": inputValues[`lpSpent${index}`],
      "lpmBudget": inputValues[`lpmBudget${index}`],
      "lpmSpent": inputValues[`lpmSpent${index}`],
      "smBudget": inputValues[`metaBudget${index}`],
      "smSpent": inputValues[`metaSpent${index}`]
    }
    console.log("🚀 ~ handleSaveBudgetAllocation ~ postData:", postData)
    backendDashboardApi(postData).then((data) => {
      if (data.success === "1") {
        alert("Saved Successfully");
        handleCancelClick(index);
        setEditMode({ state: false, index })
      } else {
        // Handle error case if needed
      }
    }).catch(error => {
      console.error("Error while saving:", error);
      // Handle error case if needed
    }).finally(() => {
      handleBudgetAlloctaionList();
    })
  }


  const handleBudgetClick = () => {
    console.log("________________", !isBudgetVisible);
    setIsBudgetVisible(!isBudgetVisible);
  };

  const handleBudgetMasterList = () => {
    const postData = {
      "functionName": "accountBudgetMasterList",
      "accountId": window.sessionStorage.getItem("switchingOrgId"),
      "fromDate": "",
      "toDate": "",
      "offset": "",
      "max": ""
    }
    backendDashboardApi(postData).then((data) => {
      console.log("🚀setBudgetMasterList ~ backendDashboardApi ~ data:", data)
      if (data.success === "1") {
        setBudgetMasterList(data.result)
      } else {
      }
    });
  }

  useEffect(() => {
    handleBudgetMasterList();
  }, []);

  // useEffect(() => {
  //   setdataChanged(new Date().getTime())
  // }, [budgetAllocationList]);

  useEffect(() => {
    handleBudgetAlloctaionList();
  }, [accountBudgetMasterId]);

  const handlebudgetlist = (accountBudgetMasterId) => {
    console.log("masterBudgetValue", accountBudgetMasterId);
    setAccountBudgetMasterId(accountBudgetMasterId)
    const selected = budgetMasterList.find(data => data.accountBudgetMasterId === accountBudgetMasterId);
    setSelectedBudget(selected);
    setIsBudgetVisible(false);
  }

  const handleSelectedBudgetClick = () => {
    setIsBudgetVisible(!isBudgetVisible);
  };



  const handleBudgetAlloctaionList = () => {
    //alert("111")
    console.log("accountBudgetMasterId =============================", accountBudgetMasterId);
    const postData = {
      "functionName": "getDealersByUserId",
      "userId": window.sessionStorage.getItem("userId"),
      "budgetMasterId": accountBudgetMasterId,
      "searchText": "",
      "max": 10,
      "offset": 0
    }
    backendDashboardApi(postData).then(async (data) => {
      console.log("setBudgetAllocationList ~ backendDashboardApi ~ data:", data.result)
      if (data.success === "1") {
        console.log('remainingFund.....111......');
        let a = data.result.map(async (item, index) => {
          let v = await calculateBalance(item, index)
          console.log('remainingFund....333333...vvvv....', v);
          item.balanceFund = v
          return item;
        });
        console.log('remainingFund....333333.......', a);
        const results = await Promise.all(a);
        console.log('remainingFund....333333.......', results);
        setBudgetAllocationList(results)
        setTotalCount(data.totalCount)
        setActivePage(1)
      } else {
        setBudgetAllocationList([])
      }
    });
  }

  const handleSearch = () => {
    // alert("222")

    const postData = {
      "functionName": "getDealersByUserId",
      "userId": window.sessionStorage.getItem("userId"),
      "budgetMasterId": accountBudgetMasterId,
      "searchText": searchValue,
      "max": 10,
      "offset": 0
    }
    backendDashboardApi(postData).then(async(data) => {
      console.log("setBudgetAllocationList ~ backendDashboardApi ~ data:", data.result)
      if (data.success === "1") {
        let a = data.result.map(async (item, index) => {
          let v = await calculateBalance(item, index)
          console.log('remainingFund....333333...vvvv....', v);
          item.balanceFund = v
          return item;
        });
        console.log('remainingFund....333333.......', a);
        const results = await Promise.all(a);
        console.log('remainingFund....333333.......', results);
        setBudgetAllocationList(results)
        // setBudgetAllocationList(data.result)
        setTotalCount(data.totalCount)
        setActivePage(1)
      } else {
        setBudgetAllocationList([])
      }
    });
  }

  const handleClearSearch = () => {
    setSearchValue("");
    handleBudgetAlloctaionList();
  }



  const handlePageChange = (pageNumber) => {
    //  alert("333")
    setActivePage(pageNumber);
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = 10;
    } else {
      offset =
        parseInt(pageNumber - 1) * 10;
      max = 10;
    }
    setActivePage(pageNumber);
    const postData = {
      "functionName": "getDealersByUserId",
      "userId": window.sessionStorage.getItem("userId"),
      "budgetMasterId": accountBudgetMasterId,
      "searchText": "",
      "max": max,
      "offset": offset
    }
    backendDashboardApi(postData).then(async(data) => {
      console.log("setBudgetAllocationList ~ backendDashboardApi ~ data:", data.result)
      if (data.success === "1") {
        let a = data.result.map(async (item, index) => {
          let v = await calculateBalance(item, index)
          console.log('remainingFund....333333...vvvv....', v);
          item.balanceFund = v
          return item;
        });
        console.log('remainingFund....333333.......', a);
        const results = await Promise.all(a);
        console.log('remainingFund....333333.......', results);
        setBudgetAllocationList(results)
        setTotalCount(data.totalCount)
      } else {
        setBudgetAllocationList([])
      }
    });
  };

  // const handleKeyPress = (e) =>{
  //   debugger
  //   const keyPressed = parseInt(e.key); // Access the value of the key pressed
  //   var c = parseInt(e.target.value)
  //   var sum = keyPressed + c
  //   // Check if remainingFund is less than or equal to the key pressed
  //   if (remainingFund <= sum) {
  //     e.preventDefault();
  //   }
  // }


  return (
    <div className="main_wrapper dsh-wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Budget Allocation" headerImage={Dashboardimage} />
        <div className="container-fluid">
          {/* <div class="subhead">Budget Allocation</div> */}
          <div className="row mt-4 align-items-center">
            <div class="col-xl-3 col-md-5 col-sm-8 col-12">
              <div className="position-relative">
                <div className="select_budget" onClick={handleBudgetClick} style={{ cursor: "pointer" }}>
                  {selectedBudget ? (
                    <div className="d-flex flex-wrap" onClick={handleSelectedBudgetClick}>
                      <div className="w-100">Budget Amount : {selectedBudget.totalBudget.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</div>
                      <div className="mr-2">Start :  {moment(selectedBudget.date).format("DD-MM-YYYY")}</div>
                      <div>End :  {moment(selectedBudget.expiryDate).format("DD-MM-YYYY")}</div>
                    </div>
                  ) : (
                    <>Select Budget<FiChevronDown /></>
                  )}
                </div>
                {console.log(".........................", isBudgetVisible)}
                {isBudgetVisible && (
                  <ul className="select_budget_ul">
                    {budgetMasterList && budgetMasterList.map((data, index) =>
                      <li className="select_budget_li" onClick={(e) => handlebudgetlist(data.accountBudgetMasterId)}>
                        <div className="select_budget_div">Budget Amount : {data.totalBudget.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</div>
                        <div className="mr-2">Start : {moment(data.date).format("DD-MM-YYYY")}</div>
                        <div>End : {moment(data.expiryDate).format("DD-MM-YYYY")}</div>
                      </li>
                    )}
                  </ul>
                )}
              </div>
            </div>
            <div class="col-xl-9 col-md-7 col-sm-4 col-12 text-right">
              <button class="db-btn" type="button" onClick={() => showHistoryHandle()}>Show History</button>
            </div>

            {/* <div class="col-12 col-md-4 col-xl-4 text-right pr-0 pr-md-4">
              <p><b>Total Fund Allocated : </b> 1,00,00,000</p>
            </div> */}
          </div>
          <hr />
          <div className="row my-4">
            <div class="col-xl-6 d-flex align-items-center mx-auto">
              <input type="text" class="form-control mb-0 mr-2" placeholder="Dealer Name" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
              <button class="db-btn mr-2" type="button" onClick={() => handleSearch()}>Search</button>
              <button class="db-btn mr-2" type="button" onClick={() => handleClearSearch()}>Clear</button>
            </div>
          </div>
          {selectedBudget ? (
            <div className="organisation-div organization-card user-creation budge_allocation">
              {/* <div class="subhead">Budget Allocation</div> */}
              {budgetAllocationList.length !== 0 ?
                <div className="col-md-12 tableResponsive" style={{ overflow: "visible" }}>
                  <div className="tableOuterWidth">
                    <table className="table mb-0">
                      <thead style={{ top: "0", backgroundColor: "#eff5ff" }}>
                        <tr>
                          <th className="col-2 pr-0">Dealer Name / Address</th>
                          <th className={`${editMode.state === true ? "col-9" : "col-9"} `}></th>
                          <th className="col-1">Action</th>
                        </tr>
                      </thead>
                      {/* <div class="table_Outerscroll"> */}
                      {budgetAllocationList && budgetAllocationList.map((data, index) =>
                        <tbody className="d-grid" key={dataChanged}>
                          <tr>
                            <td className="col-2">
                              <p><b>{data.title}</b></p>
                              <p className="text-muted">{data.address}</p>
                              <p className="mt-3 my-0"><b>Fund Allocated</b></p>
                              <p>
                                {isEditing[index] ? (
                                  <input type="text" value={fundAllocatedNew[`fundAllocatedNew${index}`]} onChange={(e) => handleFundAllocated(e.target.value, index, data)} onKeyPress={(e) => {
                                    const charCode = e.which ? e.which : e.keyCode;
                                    if (charCode < 48 || charCode > 57) {
                                      e.preventDefault();
                                    }
                                  }} />
                                ) : (
                                  <span><b>&#8377;</b>{data.fund_allocated.toLocaleString('en-IN')}</span>
                                )}
                              </p>
                              <p className="mt-3 my-0"><b>Balance Fund Allocated</b></p>

                              {isEditing[index] ? (
                                <p style={{ color: "red" }}>
                                  {balanceFund[`balanceFund${index}`].toLocaleString('en-IN')}
                                </p>
                              ) : (
                                <p style={{ color: "red" }}>
                                  <b>&#8377;</b>{data.balanceFund.toLocaleString('en-IN')}
                                </p>
                              )}
                              <p className="mt-3 my-0"><b>Fund Spent</b></p>
                              <p>
                                <b>&#8377;</b>{data.fund_spent.toLocaleString('en-IN')}
                              </p>
                            </td>
                            <td className={`${editMode.state === true ? "col-9" : "col-9"} `} style={{ overflow: "auto" }}>
                              {/* <div className="col-md-12 tableResponsive">
                                  <div className="tableOuterWidth table_width_col"> */}
                              <table className="table mb-0" style={
                                      isEditing[index] ? { width: "1420px" } : { width: "1200px" }
                                    }>
                                <thead className="pos-unset" style={{ borderBottom: "1px solid #e5e5e5" }}>
                                  <tr>
                                    <th style={{ width: "320px", fontSize: "16px" }}><b>PM</b></th>
                                    <th style={{ width: "320px", fontSize: "16px" }}><b>SM</b></th>
                                    <th style={{ width: "160px", fontSize: "16px" }}><b>Content</b></th>
                                    <th style={{ width: "160px", fontSize: "16px" }}><b>LP</b></th>
                                    <th style={{ width: "160px", fontSize: "16px" }}><b>LPM</b></th>
                                  </tr>
                                </thead>
                                <tbody className="d-grid mb-0" style={{ boxShadow: "unset" }}>
                                  <tr>
                                  <td className={`row justify-content-between pb-0 mb-1 ${isEditing[index] ? 'db-t-head-active' : ''}`} style={
                                      isEditing[index] ? { width: "380px" } : { width: "320px" }
                                    }>
                                      <table className="mb-0" style={{ width: "50%" }}>
                                        <thead className="pos-unset">
                                          <tr>
                                            <th className="col-6 px-0 th-font-sm">Meta</th>
                                          </tr>
                                        </thead>
                                        <tbody className="d-grid mt-3 mb-0">
                                          <tr>
                                            <td className="col-12 px-0 pb-0 pt-2">
                                              <div className="db-t-head"><b>Allocated</b></div>
                                              <div className="pt-2">
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">PV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`pmMetaPv${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'pmMetaPv' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.pm_meta_pv.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">CV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`pmMetaCv${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'pmMetaCv' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.pm_meta_cv.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">SCV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`pmMetaScv${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'pmMetaScv' + index, index)}
                                                      />
                                                    ) : (

                                                      <span>{data.pm_meta_scv.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="db-t-head"><b>Spent</b></div>
                                              <div className="pt-2">
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">PV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`pmMetaPvSpent${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'pmMetaPvSpent' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.pm_meta_pv_spent.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">CV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`pmMetaCvSpent${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'pmMetaCvSpent' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.pm_meta_cv_spent.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">SCV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`pmMetaScvSpent${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'pmMetaScvSpent' + index, index)}
                                                      />
                                                    ) : (

                                                      <span>{data.pm_meta_scv_spent.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table className="mb-0" style={{ width: "45%" }}>
                                        <thead className="pos-unset">
                                          <tr>
                                            <th className="col-6 px-0 th-font-sm">Google</th>
                                          </tr>
                                        </thead>
                                        <tbody className="d-grid mt-3 mb-0">
                                          <tr>
                                            <td className="col-12 px-0 pb-0 pt-2">
                                              <div className="db-t-head"><b>Allocated</b></div>
                                              <div className="pt-2">
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">PV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`pmGooglePv${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'pmGooglePv' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.pm_google_pv.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">CV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        id="pmGoogleCv"
                                                        value={inputValues[`pmGoogleCv${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'pmGoogleCv' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.pm_google_cv.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">SCV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`pmGoogleScv${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'pmGoogleScv' + index, index)}
                                                      />
                                                    ) : (

                                                      <span>{data.pm_google_scv.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="db-t-head"><b>Spent</b></div>
                                              <div className="pt-2">
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">PV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`pmGooglePvSpent${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'pmGooglePvSpent' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.pm_google_pv_spent.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">CV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        id="pmGoogleCv"
                                                        value={inputValues[`pmGoogleCvSpent${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'pmGoogleCvSpent' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.pm_google_cv_spent.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">SCV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`pmGoogleScvSpent${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'pmGoogleScvSpent' + index, index)}
                                                      />
                                                    ) : (

                                                      <span>{data.pm_google_scv_spent.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td className="row justify-content-between pb-0 mb-1" 
                                    style={
                                      isEditing[index] ? { width: "560px" } : { width: "320px" }
                                    }>
                                      <table className="mb-0" style={{ width: "50%" }}>
                                        <thead className="pos-unset">
                                          <tr>
                                            <th className="col-6 px-0 th-font-sm">Meta</th>
                                          </tr>
                                        </thead>
                                        <tbody className="d-grid mt-3 mb-0">
                                          <tr>
                                            {console.log("@@@@@@@@@@@@@@@@@@@@@@@@@",data)}
                                            <td className="col-12 px-0 pb-0 pt-2">
                                            <div className="db-t-head db-t-head-amount"> <span><b className="no-wrap-span">Allocated</b> </span> <span>:</span>
                                                {isEditing[index] ? (
                                                  <input className="pl-1" type="text" style={{ width: "128px" }}
                                                    value={inputValues[`metaBudget${index}`]}
                                                    onChange={(e) => handleInputChange(e, 'metaBudget' + index, index)}
                                                  />

                                                ) : (
                                                  <span>{data.sm_budget.toLocaleString('en-IN')}</span>
                                                )}</div>
                                              <div className="pt-2">
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">PV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`smMetaPv${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'smMetaPv' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.sm_meta_pv.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">CV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`smMetaCv${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'smMetaCv' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.sm_meta_cv.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">SCV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`smMetaScv${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'smMetaScv' + index, index)}
                                                      />
                                                    ) : (

                                                      <span>{data.sm_meta_scv.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="db-t-head db-t-head-amount"><b>Spent</b><span>:</span>
                                                {isEditing[index] ? (
                                                  <input type="text" style={{ width: "128px" }}
                                                    value={inputValues[`metaSpent${index}`]}
                                                    onChange={(e) => handleInputChange(e, 'metaSpent' + index, index)}
                                                  />

                                                ) : (
                                                  <span>{data.sm_spent.toLocaleString('en-IN')}</span>
                                                )}</div>
                                              <div className="pt-2">
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">PV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`smMetaPvSpent${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'smMetaPvSpent' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.sm_meta_pv_spent.toLocaleString('en-IN')}</span>
                                                    )}

                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">CV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`smMetaCvSpent${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'smMetaCvSpent' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.sm_meta_cv_spent.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">SCV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`smMetaScvSpent${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'smMetaScvSpent' + index, index)}
                                                      />
                                                    ) : (

                                                      <span>{data.sm_meta_scv_spent.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table className="mb-0" style={{ width: "45%" }}>
                                        <thead className="pos-unset">
                                          <tr>
                                            <th className="col-6 px-0 th-font-sm">Google</th>
                                          </tr>
                                        </thead>
                                        <tbody className="d-grid mt-3 mb-0">
                                          <tr>
                                            <td className="col-12 px-0 pb-0 pt-2">
                                              <div className="db-t-head"><b>Allocated</b></div>
                                              <div className="pt-2">
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">PV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`smGooglePv${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'smGooglePv' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.sm_google_pv.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">CV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`smGoogleCv${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'smGoogleCv' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.sm_google_cv.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">SCV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`smGoogleScv${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'smGoogleScv' + index, index)}
                                                      />
                                                    ) : (

                                                      <span>{data.sm_google_scv.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="db-t-head"><b>Spent</b></div>
                                              <div className="pt-2">
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">PV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`smGooglePvSpent${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'smGooglePvSpent' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.sm_google_pv_spent.toLocaleString('en-IN')}</span>
                                                    )}

                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">CV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`smGoogleCvSpent${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'smGoogleCvSpent' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.sm_google_cv_spent.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">SCV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`smGoogleScvSpent${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'smGoogleScvSpent' + index, index)}
                                                      />
                                                    ) : (

                                                      <span>{data.sm_google_scv_spent.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td className=" pb-0 mb-1" style={
                                      isEditing[index] ? { width: "292px" } : { width: "160px" }
                                    }>
                                      <table className="col-12 px-0 mb-0">
                                        <thead className="pos-unset">
                                          <tr>
                                            <th className="col-12 px-0"></th>
                                          </tr>
                                        </thead>
                                        <tbody className="d-grid mt-3 mb-0">
                                          <tr>
                                            <td className="col-12 px-0 pb-0 pt-2">
                                              {/* <div className="d-flex aling-items-center px-2 mb-2">
                                              <div class="th-font-sm w-25"><b>Allocated</b> </div>
                                              <div class="w-75 pl-3"><input type="text" value="0" /></div>
                                              </div> */}

                                              <div className="db-t-head db-t-head-amount"> <span><b className="no-wrap-span">Allocated</b> </span> <span>:</span>
                                                {isEditing[index] ? (
                                                  <input className="pl-1" type="text" style={{ width: "128px" }}
                                                    value={inputValues[`contentBudget${index}`]}
                                                    onChange={(e) => handleInputChange(e, 'contentBudget' + index, index)}
                                                  />

                                                ) : (
                                                  <span>{data.content_budget.toLocaleString('en-IN')}</span>
                                                )}
                                              </div>
                                              <div className="pt-2">
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">PV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`contentPv${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'contentPv' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.content_pv.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">CV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`contentCv${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'contentCv' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.content_cv.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">SCV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`contentScv${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'contentScv' + index, index)}
                                                      />
                                                    ) : (

                                                      <span>{data.content_scv.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="db-t-head db-t-head-amount"> <span><b>Spent</b></span> <span>:</span>
                                                {isEditing[index] ? (
                                                  <input type="text" style={{ width: "128px" }}
                                                    value={inputValues[`contentSpent${index}`]}
                                                    onChange={(e) => handleInputChange(e, 'contentSpent' + index, index)}
                                                  />

                                                ) : (
                                                  <span>{data.content_spent.toLocaleString('en-IN')}</span>
                                                )}
                                              </div>
                                              <div className="pt-2">
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">PV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`contentPvSpent${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'contentPvSpent' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.content_pv_spent.toLocaleString('en-IN')}</span>
                                                    )}

                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">CV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`contentCvSpent${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'contentCvSpent' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.content_cv_spent.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">SCV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`contentScvSpent${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'contentScvSpent' + index, index)}
                                                      />
                                                    ) : (

                                                      <span>{data.content_scv_spent.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td className=" pb-0 mb-1" style={
                                      isEditing[index] ? { width: "292px" } : { width: "160px" }
                                    }>
                                      <table className="col-12 px-0 mb-0">
                                        <thead className="pos-unset">
                                          <tr>
                                            <th className="col-12 px-0"></th>
                                          </tr>
                                        </thead>
                                        <tbody className="d-grid mt-3 mb-0">
                                          <tr>
                                            <td className="col-12 px-0 pb-0 pt-2">
                                              <div className="db-t-head db-t-head-amount"> <span><b className="no-wrap-span">Allocated</b> </span> <span>:</span>
                                                {isEditing[index] ? (
                                                  <input type="text" style={{ width: "128px" }}
                                                    value={inputValues[`lpBudget${index}`]}
                                                    onChange={(e) => handleInputChange(e, 'lpBudget' + index, index)}
                                                  />

                                                ) : (
                                                  <span>{data.lp_budget.toLocaleString('en-IN')}</span>
                                                )}
                                              </div>
                                              <div className="pt-2">
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">PV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`lpPv${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'lpPv' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.lp_pv.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">CV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`lpCv${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'lpCv' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.lp_cv.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">SCV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`lpScv${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'lpScv' + index, index)}
                                                      />
                                                    ) : (

                                                      <span>{data.lp_scv.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="db-t-head db-t-head-amount"> <span><b>Spent</b></span> <span>:</span>
                                                {isEditing[index] ? (
                                                  <input type="text" style={{ width: "128px" }}
                                                    value={inputValues[`lpSpent${index}`]}
                                                    onChange={(e) => handleInputChange(e, 'lpSpent' + index, index)}
                                                  />

                                                ) : (
                                                  <span>{data.lp_spent.toLocaleString('en-IN')}</span>
                                                )}
                                              </div>
                                              <div className="pt-2">
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">PV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`lpPvSpent${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'lpPvSpent' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.lp_pv_spent.toLocaleString('en-IN')}</span>
                                                    )}

                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">CV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`lpCvSpent${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'lpCvSpent' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.lp_cv_spent.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">SCV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`lpScvSpent${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'lpScvSpent' + index, index)}
                                                      />
                                                    ) : (

                                                      <span>{data.lp_scv_spent.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td className=" pb-0 mb-1" style={
                                      isEditing[index] ? { width: "292px" } : { width: "160px" }
                                    }>
                                      <table className="col-12 px-0 mb-0">
                                        <thead className="pos-unset">
                                          <tr>
                                            <th className="col-12 px-0"></th>
                                          </tr>
                                        </thead>
                                        <tbody className="d-grid mt-3 mb-0">
                                          <tr>
                                            <td className="col-12 px-0 pb-0 pt-2">
                                              <div className="db-t-head db-t-head-amount"> <span><b className="no-wrap-span">Allocated</b> </span> <span>:</span>
                                                {isEditing[index] ? (
                                                  <input type="text" style={{ width: "128px" }}
                                                    value={inputValues[`lpmBudget${index}`]}
                                                    onChange={(e) => handleInputChange(e, 'lpmBudget' + index, index)}
                                                  />

                                                ) : (
                                                  <span>{data.lpm_budget.toLocaleString('en-IN')}</span>
                                                )}
                                              </div>
                                              <div className="pt-2">
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">PV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`lpmPv${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'lpmPv' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.lpm_pv.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">CV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`lpmCv${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'lpmCv' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.lpm_cv.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">SCV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`lpmScv${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'lpmScv' + index, index)}
                                                      />
                                                    ) : (

                                                      <span>{data.lpm_scv.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="db-t-head db-t-head-amount"> <span><b>Spent</b></span> <span>:</span>
                                                {isEditing[index] ? (
                                                  <input type="text" style={{ width: "128px" }}
                                                    value={inputValues[`lpmSpent${index}`]}
                                                    onChange={(e) => handleInputChange(e, 'lpmSpent' + index, index)}
                                                  />

                                                ) : (
                                                  <span>{data.lpm_spent.toLocaleString('en-IN')}</span>
                                                )}</div>
                                              <div className="pt-2">
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">PV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`lpmPvSpent${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'lpmPvSpent' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.lpm_pv_spent.toLocaleString('en-IN')}</span>
                                                    )}

                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">CV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`lpmCvSpent${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'lpmCvSpent' + index, index)}
                                                      />

                                                    ) : (
                                                      <span>{data.lpm_cv_spent.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex aling-items-center px-2 mb-2">
                                                  <div className="th-font-sm w-25">SCV</div>
                                                  <div className="w-75 pl-3">
                                                    {isEditing[index] ? (
                                                      <input type="text" style={{ width: "100%" }}
                                                        value={inputValues[`lpmScvSpent${index}`]}
                                                        onChange={(e) => handleInputChange(e, 'lpmScvSpent' + index, index)}
                                                      />
                                                    ) : (

                                                      <span>{data.lpm_scv_spent.toLocaleString('en-IN')}</span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr className="tr-budgetShare-sec">
                                    <td className="col-md-2">
                                      <div className="db-t-head db-t-head-padd">Paid Media Dealer Share (%)</div>
                                      {isEditing[index] ? (
                                        <input type="text" style={{ width: "100%" }}
                                          value={inputValues[`paidMediaDealerShare${index}`]}
                                          onChange={(e) => handleInputChange(e, 'paidMediaDealerShare' + index, index)}
                                        />
                                      ) : (
                                        <div className="tr-budgetShare">{data.paid_media_dealer_share}</div>
                                      )}

                                    </td>
                                    <td className="col-md-2">
                                      <div className="db-t-head db-t-head-padd">Paid Media HO Share (%)</div>
                                      {isEditing[index] ? (
                                        <input type="text" style={{ width: "100%" }}
                                          value={inputValues[`paidMediahoShare${index}`]}
                                          onChange={(e) => handleInputChange(e, 'paidMediahoShare' + index, index)}
                                        />
                                      ) : (
                                        <div className="tr-budgetShare">{data.paid_media_ho_share}</div>
                                      )}
                                    </td>
                                    <td className="col-md-2">
                                      <div className="db-t-head db-t-head-padd">SM Budget Share Amount</div>
                                      {isEditing[index] ? (
                                        <input type="text" style={{ width: "100%" }}
                                          value={inputValues[`smBudgetShare${index}`]}
                                          onChange={(e) => handleInputChange(e, 'smBudgetShare' + index, index)}
                                        />
                                      ) : (
                                        <div className="tr-budgetShare">{data.sm_budget_share}</div>
                                      )}
                                    </td>
                                    <td className="col-md-2">
                                      <div className="db-t-head db-t-head-padd">Content Budget Share Amount</div>
                                      {isEditing[index] ? (
                                        <input type="text" style={{ width: "100%" }}
                                          value={inputValues[`contentBudgetShare${index}`]}
                                          onChange={(e) => handleInputChange(e, 'contentBudgetShare' + index, index)}
                                        />
                                      ) : (
                                        <div className="tr-budgetShare">{data.content_budget_share}</div>
                                      )}
                                    </td>
                                    <td className="col-md-2">
                                      <div className="db-t-head db-t-head-padd">LP Budget Share Amount</div>
                                      {isEditing[index] ? (
                                        <input type="text" style={{ width: "100%" }}
                                          value={inputValues[`lpBudgetShare${index}`]}
                                          onChange={(e) => handleInputChange(e, 'lpBudgetShare' + index, index)}
                                        />
                                      ) : (
                                        <div className="tr-budgetShare">{data.lp_budget_share}</div>
                                      )}
                                    </td>
                                    <td className="col-md-2">
                                      <div className="db-t-head db-t-head-padd">LPM Budget Share Amount</div>
                                      {isEditing[index] ? (
                                        <input type="text" style={{ width: "100%" }}
                                          value={inputValues[`lpmBudgetShare${index}`]}
                                          onChange={(e) => handleInputChange(e, 'lpmBudgetShare' + index, index)}
                                        />
                                      ) : (
                                        <div className="tr-budgetShare">{data.lpm_budget_share}</div>
                                      )}
                                    </td>

                                  </tr>
                                </tfoot>
                              </table>
                              {/* </div>
                                </div> */}
                            </td>
                            <td className="col-1 text-center">
                              {isEditing[index] ? (
                                <>
                                  <button className="db-btn mb-2" style={{ minWidth: "75px" }} type="button" onClick={() => handleSaveBudgetAllocation(index, data.dealerId, data.accountBudgetMasterDetailsId)}>Save</button>
                                  <button className="db-btn" style={{ minWidth: "75px" }} type="button" onClick={() => handleCancelClick(index)}>Cancel</button>
                                </>

                              ) : (
                                <Icon.Edit size={14} className="mt-5" onClick={() => handleEditClick(index, data)} />
                              )}
                            </td>
                          </tr>
                        </tbody>
                      )}
                      {/* </div> */}
                    </table>




                    {totalCount > 0 ?
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                      />
                      : ""}







                  </div>
                </div>
                : "No Data Found"}
            </div>

          ) : ""}


        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
}
export default BudgetAllocation;
