import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import moment from "moment";
import { TagsInput } from "react-tag-input-component";
import cloudImg from "../images/cloud-computing.png";
import deleteImg from "../images/Group122.png";
import DragDropFile from "../audit/dragDropFile";
import Analysisimage from "../images/left_icons/analysis.png";
import DataInput from "../audit/dataInput";
import { backendApi, pythonBackendApi } from "../apiService";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import uploadImg from "../images/upload.png";
import $ from "jquery";
import closeImg1 from "../images/close1.png";
import * as Icon from "react-feather";
import exportFromJSON from "export-from-json";
import CompetiterAnalysisAuditList from "./competiterAnalysisAuditList";
const excelMime = [
  "application/vnd.ms-excel",
  "application/msexcel",
  "application/x-msexcel",
  "application/x-ms-excel",
  "application/x-excel",
  "application/x-dos_ms_excel",
  "application/xls",
  "application/x-xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
const csvDownload = "Competitor Analysis ";
class CompetiterAnalysisAudit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      file: null,
      buffer: null,
      convertText: "",
      sourceText: "",
      status: "",
      src: "",
      imageLanguage: "",
      imageLanguageLabel: "",
      translateLanguage: "",
      languageListImage: [],
      languageListTTS: [],
      uploaded: false,
      dataList: [],
      excelData: [],
      googleData: [],
      keyword: [],
      keywordLength: 0,
      getMasterdata: [],
      orgId: "",
      organizationList: [],
      organizationListName: [],
      categoryName: "",
      mergeId: [],
      mergeComment: [],
      idSet: [],
      categoryEditName:""
     
    };

    this.setSelected = this.setSelected.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.onSave = this.onSave.bind(this);
  }
  getOrganizationData = () => {
    const req = {
      functionName: "organizationList",
      role: window.sessionStorage.getItem("role"),
      organization: window.sessionStorage.getItem("organization"),
    };
    backendApi(req).then((data) => {
      console.log(data);
      if (data.success === "1") {
        this.setState({
          categoryEditName:"",
          organizationList: data.category,
          organizationListName:data.result
        });
      }
    });
  };
  componentDidMount() {
  //  this.getCategoreList()
    // this.getDatas();

    this.getCompitetorMasterData();
    this.getOrganizationData();
    $("#moveButton").hide();
  }
  getCompitetorMasterData() {
    var req = {
      functionName: "getCompitetorMasterData",
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      role:
        window.sessionStorage.getItem("switchUserRole") === ""
          ? window.sessionStorage.getItem("role")
          : window.sessionStorage.getItem("switchUserRole"),
    };

    $(".loder_div").attr("hidden", false);
    backendApi(req).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        this.setState({
          uploaded: false,
          getMasterdata: data.data,
        });
      }
    });
  }
  excelFileDownload(id) {
    const excelRow = [];
    var addressProcessing = [
      [
        "ID",
        "Excel%20Address",
        "Address%201",
        "Address%202",
        "Address%203",
        "City",
      ],
    ];
let reg = (/[^a-zA-Z0-9/g]/g, " ").split(/\s{1,}/g).join(" %20")
    var req = {
      functionName: "getAddressCleaningDataByID",
      masterId: id,
    };
    backendApi(req).then((data) => {
      for (var j = 0; j < data.data.length; j++) {
        addressProcessing.push([
          data.data[j].id.toString(),
          data.data[j].excel_address ===(""||null)? data.data[j].excel_address:
          data.data[j].excel_address.replace(reg),

          data.data[j].address_1 ===(""||null)? data.data[j].address_1:
          data.data[j].address_1.replace(reg),

          data.data[j].address_2 ===(""||null)? data.data[j].address_2:
          data.data[j].address_2.replace(reg),

          data.data[j].address_3 ===(""||null)? data.data[j].address_3:
          data.data[j].address_3.replace(reg),

          data.data[j].excel_city ===(""||null)? data.data[j].excel_city:
          data.data[j].excel_city.replace(reg),
        ]);
      }

      for (var i = 0; i < addressProcessing.length; ++i) {
        excelRow.push(addressProcessing[i].join(","));
      }
      console.log("excelRow", excelRow);
      var csvString = excelRow.join("%0A");

      var create = document.createElement("a");
      create.href = "data:attachment/csv," + csvString;
      create.target = "_Blank";
      create.download = csvDownload + ".csv";
      document.body.appendChild(create);
      create.click();
    });
  }
  OutTable = () => {
    return (
      <div>
        <div className="col-md-12 tableResponsive">
          <table className="table fileupload-table mb-0">
            <thead>
              <tr>
                <th className="col-2">Ref Code</th>
                <th className="col-2">Name</th>
                <th className="col-4">Address</th>
                <th className="col-2">Search</th>
                <th className="col-2">PhoneNo</th>
              </tr>
            </thead>

            <div class="table_Outerscroll">
              {this.state.dataList.map((r, i) => (
                <tbody class="table_Outertbody">
                  <tr key={i}>
                    <td className="col-2">{r.ReferenceCode}</td>
                    <td className="col-2">{r.LocationName}</td>
                    <td className="col-4">{r.Address}</td>
                    <td className="col-2">{r.Search}</td>
                    <td className="col-2">{r.PhoneNo}</td>
                  </tr>
                </tbody>
              ))}
            </div>
          </table>
        </div>
        <div className="button-wrapper pb-2">
          <button className=" btn-save ml-3" onClick={this.onSave}>
            Upload
          </button>
          <button
            className=" btn-cancel ml-3"
            onClick={() => window.location.reload()}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  migrateCompetitor(masterId) {
    $(".loder_div").attr("hidden", false);
    var req = {
      method: "POST",
      body: JSON.stringify({
        masterId: masterId,
        userId:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
      }),
    };

    pythonBackendApi("runCompetitor", req).then((data) => {
      $(".loder_div").attr("hidden", true);
      alert("Competitor Started Successfully");
      window.location.href = "/competiterDetails/" + masterId;


    });
  }

  setSelected(val) {
    this.setState({
      keyword: val,
    });
  }
  comparecompetiter(masterId) {
    if (!window.confirm("Do you want to Classify Data?")) {
      return false;
    }
    var apidata = {
      method: "POST",
      body: JSON.stringify({
        masterId: masterId,
        userId:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
      }),
    };

    $(".loder_div").attr("hidden", false);
    pythonBackendApi("compareCompitetorData", apidata).then((data) => {
  
      $(".loder_div").attr("hidden", true);
      alert("Process Started Successfully");
      window.location.href = "/competiterDetails/" + masterId;
    });
  }
  onSave() {
    $("#enterCategoryName").attr("hidden", true);
    $("#editCommentPopup").attr("hidden", false);
    $("#editCommentPopupHeader").text("Insert Comment"); 
    $("#commentAdd").attr("hidden", false);
    $("#removeModal").attr("hidden", true);
  }
  saveAndStartUpload() {
    if ($("#editCommentPopupTextBox").val() === "") {
      alert("Enter comment please");
      return false;
    }
    if (this.state.categoryName == 0) {
      alert("Select Category");
      return false;
    }
    var apidata = {
      functionName: "saveCompetitorSave",
      competitor: this.state.dataList,
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      comment: $("#editCommentPopupTextBox").val(),
      fileName: this.state.fileName,
      category_id: this.state.categoryName,
    };
    $(".loder_div").attr("hidden", false);
    backendApi(apidata).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        alert("Uploaded Successfully");
        console.log("...................", data);
        window.location.reload();

        // window.sessionStorage.setItem("offsetcompe", 0)
        //  window.location.href = "/competiterDetails/" + data.masterId
      } else {
        alert("Something went wrong in uploading file");
        $(".loder_div").attr("hidden", true);
      }
    });
    $("#editCommentPopup").attr("hidden", true);
  }
  async handleFile(files, e) {
    var buffer;
    if (files) {
      if (!excelMime.includes(files.type)) {
        alert("Please upload valid excel file");
        $(".loder_div").attr("hidden", true);
        return false;
      }
      $(".loder_div").attr("hidden", false);
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          isLoader: 0,
        });
        /* Parse data */
        const ab = e.target.result;
        const wb = XLSX.read(ab, { type: "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { raw: false, header: 1 });

        if(data[0][0] !=="Reference Code" &&data[0][1] !=="Location Name"
        &&data[0][2] !=="Address"
        &&data[0][3] !=="Search"
        &&data[0][4] !=="Phone No"){
          $(".loder_div").attr("hidden", true);
          alert("The Header Fields of Excel data is incorrect");
          return false;
         }
        let DataList = [];
        let DataListTemp = [];

        let keys = [];
        keys = data[0];
        let record = {};
        for (let i = 1; i < data.length; i++) {
          let dd = data[i];
          if (dd.length > 0) {
            for (let j = 0; j < dd.length; j++) {
              record[keys[j].trim().split(" ").join("")] =
                dd[j] === undefined ? "" : dd[j].toString().trim();
            }

            let index = DataList.findIndex(
              (d) =>
                d.ReferenceCode === record.ReferenceCode &&
                d.LocationName === record.LocationName &&
                d.Address === record.Address &&
                d.Search === record.Search &&
                d.PhoneNo === record.PhoneNo
            );
            if (index === -1) {
              DataList.push(record);
            }
            DataListTemp.push(record);
            record = {};
          }
        }
        $(".loder_div").attr("hidden", true);
        /* Update state */
        this.setState(
          {
            dataList: DataList,
            fileName: files.name,
            uploaded: true,
            isLoader: 1,
          },
          () => {
            if (DataListTemp.length !== DataList.length) {
              alert("File contains duplicate record(s)");
            }
          }
        );
      };
      reader.readAsArrayBuffer(files);
    }
  }

  downloadTemplate() {
    let header = [
      "Reference Code",
      "Location Name",
      "Address",
      "Search",
      "Phone No",
    ];
    let data = [];
    let date =
      "Competitor Analysis" 

    const workbook = XLSX.utils.book_new();
    console.log(workbook, "workbookworkbookworkbook");
    XLSX.utils.book_append_sheet(workbook, data, date);
    XLSX.utils.sheet_add_aoa(data, [header], { origin: "A1" });
    XLSX.writeFile(workbook, date + ".xlsx", {
      compression: true,
    });
  }

  categoryhandleChange(val) {
    this.setState({
      categoryName: val,
    });
  }
  deleteCompetitorDataByID(val){
    if (!window.confirm("Do you want to Delete Competitor Data?")) {
      return false;
    }
    var apidata = {
      functionName: "deleteCompetitorDataByID",
      masterId: val
    };
    $(".loder_div").attr("hidden", false);
    backendApi(apidata).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        this.getCompitetorMasterData()
      } else {
        alert("Something went wrong in Deleting the file");
        $(".loder_div").attr("hidden", true);
      }
    });
  }

  checkboxValidation(e, ids) {
    let idArray = this.state.idSet;
    if ($("#selectLocationPost" + ids).is(":checked")) {
      $("#selectLocationPost" + ids).prop("checked", true);
      idArray.push(ids);
    } else {
      $("#selectLocationPost" + ids).prop("checked", false);

      var index = idArray.indexOf(ids);
      if (index > -1) {
        idArray.splice(index, 1);
      }
    }
    this.setState({ idSet: idArray });

    
    if (idArray.length > 0) {
      $("#moveButton").show();
    } else {
      $("#moveButton").hide();
    }
  }

  moveData() {
    $("#moveCommentPopup").attr("hidden", false);
  }

  moveupload (){

    if (this.state.idSet.length > 0) {
      $(".loder_div").attr("hidden", false);
      const req = {
        functionName: "moveToOrganisationCompetitor",
        master_id: this.state.idSet,
        site_id:this.state.orgId,
      };
      backendApi(req).then((data) => {
        $("#moveCommentPopup").attr("hidden", true);
        if (data.success === "1") {
          $(".loder_div").attr("hidden", true);
          alert("File(s) Moved Successfully");
          window.location.reload();
        } else {
          alert("File(s) Moved Failed");
          $(".loder_div").attr("hidden", true);
        }
      });
    } else {
      alert("Please select at least one file(s) to move");
      $(".loder_div").attr("hidden", true);
    }
  }
  toggleModal(data,type) {
    $(".loder_div").attr("hidden", true);
        $("body").addClass("isActive");
    this.setState({
      categoryEditName:type=="Save"?"":data.category,
      saveOrUpdate:type,
      categoryId:type=="Save"?"":data.id,
    });
 
}
// getCategoreList() {
//   $(".loder_div").attr("hidden", false);
//   var req = { 
//       "functionName": "CategoryFullListing"
//     }
//     ;
//   backendApi(req).then((response) => {
//     $(".loder_div").attr("hidden", true);
//     if (response.success === "1") {
//       this.setState({ categoriesList: response.data });
//     } else {
//       this.setState({ categoriesList: [] });
//     }
//   });
// }
removeModal() {
  $("body").removeClass("isActive");
  $(".modules_div").removeClass("active");
  $("#modulesSelectAll").prop("checked", false);
}
saveModules() {
  $(".loder_div").attr("hidden", false);
  var req = { 
    "functionName": "createCategory",
    "category": this.state.categoryEditName
  };
  backendApi(req).then((response) => {
    $(".loder_div").attr("hidden", true);
    if (response.success === "1") {
      alert("Saved successfully");
      this.getOrganizationData();
    }
  });
}
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          <Header heading="Competitor Analysis" headerImage={Analysisimage} />

          <div className="container-fluid">
            <div className="file_upload_outer container">
              <DragDropFile handleFile={this.handleFile}>
                <div className="form-group formBottom" id="form-fileupload">
                  <DataInput handleFile={this.handleFile} />
                </div>
              </DragDropFile>
              <div
                className="col-lg-3 col-md-12 TemplateDowloadDiv"
                onClick={() => this.downloadTemplate()}
              >
                <Icon.Download /> Download template
              </div>
              {this.state.uploaded === true && this.OutTable()}
            </div>     
            <div className="d-flex align-items-center">
            <div class="total_list">
              <span class="audit-txt">Total :</span>
              <span class="audit-txt-bld">{this.state.getMasterdata.length !="0"?this.state.getMasterdata.length:"0"}</span>
            </div>  
            {/* <div className="ocr_progress_bar"></div> */}
            <button
              id="moveButton"
              type="button"
              className="btn btn-primary ml-3"
              onClick={() => this.moveData()}
            >
              Move
            </button>
            </div>     
            <div className="col-md-12 tableResponsive">
              <div className="tableOuterWidth">
                <table className="table">
                  <thead>
                    <tr>
                     <th className="col-1" style={{ maxWidth: "4%" }}></th>
                      <th className="col-1">ID</th>
                      <th className="col-2">Competitor Name</th>
                      <th className="col-2">Comment</th>
                      <th className="col-2">Created On</th>
                      <th className="col-1">Created By </th>
                      <th className="col-1">Actions</th>
                      <th className="col-1"></th>
                      <th className="col-1"></th>
                    </tr>
                  </thead>
                  <div class="table_Outerscroll">
                    {this.state.getMasterdata.map((data, i) => (
                      <tbody className="table_Outertbody" key={i}>
                        <tr>
                        <td className="col-1" style={{ maxWidth: "4%" }}>
                              <input
                                type="checkbox"
                                id={"selectLocationPost" + data.id}
                                onClick={(e) =>
                                  this.checkboxValidation(
                                    e,
                                    data.id,
                                    data.comment
                                  )
                                }
                              />
                            </td>
                          <td className="col-1">{data.id}</td>

                          <td className="col-2">
                            {data.competitor
                              .toString()
                              .replace(/[\[\]']+/g, "")}
                          </td>
                          <td className="col-2">{data.comment}</td>
                          <td className="col-2">{moment(data.created_on).format("DD-MM-YYYY hh:mm:ss a")}</td>
                          <td className="col-1">{data.createdBy}</td>
            
                          <td className="col-1">
           
                            {data.status === "" ? (
                              <span
                                class="btn-start" role="button"
                                onClick={(e) => {
                                  this.migrateCompetitor(data.id);
                                }}
                              >
                                Start
                              </span>
                            ) : data.status === "Started" ? (
                              <div class="create_popst_btn01" disabled>
                                <span
                                  class="btn-progress"
                                  
                                >
                                  In Progress
                                </span>
                              </div>
                            ) : data.status === "Audited" ? (
                              <div class="create_popst_btn01">
                                <span
                                  class="btn-start"
                                  onClick={(e) => {
                                    this.comparecompetiter(data.id);
                                  }}
                                >
                                    Classify Data
                                </span>
                                
                              </div>
                            ) : (
                              ""
                            )}
                          </td>
                          <td className="col-1">
                            <Link
                              to={"/competiterDetails/" + data.id}
                              state={{
                                tableId: data.id,
                                visibility: data.visibility,
                              }}
                            >
                              <Icon.Eye />
                            </Link>
                          </td>
                          <td style={{color:"red"}} 
                          className="col-1" onClick={()=>this.deleteCompetitorDataByID(data.id)}>
    
                              <Icon.Trash />
                           
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </div>
                </table>
              </div>
            </div>
            {/* <div className="reprocessing_btndiv">
                <div className="repro_btn">
                  <button
                    id="txtFile"
                    type="button"
                    className="btn-download"
                    onClick={(e) => {
                      this.migrateReview();
                    }}
                  >
                    Migrate Review
                  </button>
                  <button
                    id="txtFile"
                    type="button"
                    className="btn-download"
                    onClick={(e) => {
                      this.runFraud();
                    }}
                  >
                    Run Fraud
                  </button>
                  <button
                    id="txtFile"
                    type="button"
                    className="btn-download"
                    onClick={(e) => {
                      this.downloadExcel();
                    }}
                  >
                    Download Excel
                  </button>
                </div>
              </div> */}
          </div>
          <Footer/>
        </div>
        <div id="editCommentPopup" className="module_popup" hidden>
          <div className="module_innerpopup">
            <div className="module_close">
              <Icon.XCircle
                onClick={() => {
                  $("#editCommentPopup").attr("hidden", true);
                }}
              />
            </div>
            <div id="editCommentPopupHeader" className="module_header"></div>
            <div className="module_body">
              <div className="module_popupsearch_wrpr">
                <div className="module_popupsearch">
                  <input
                    id="editCommentPopupTextBox"
                    type="text"
                    placeholder="Name"
                  />
                </div>
                <div className="row">
                  <div className="col-md-9 md-pr-0">
                  <select
                    id="organizationSelection"
                    className="form-control mb-2"
                    onChange={(e) => {
                      this.categoryhandleChange(e.target.value);
                    }}
                  >
                    <option value="0"> Select Category</option>

                    {this.state.organizationList.map((item, i) => (
                      <option value={item.id}>{item.category}</option>
                    ))}
                  </select>
                  </div>
                  <div className="col-md-3 pl-0">
                      <button className="btn btn-primary float-right mb-1" onClick={() => {
                      $("#enterCategoryName").attr("hidden", false);
                      $("#categoryAdd").attr("hidden", false);
                      $("#commentAdd").attr("hidden", true);
                      $("#removeModal").attr("hidden", false);
                    }}>Add New Category</button>
                  </div>
                
                    {/* <Icon.PlusCircle title="Click to Add New Category"  onClick={() => {
                  $("#enterCategoryName").attr("hidden", false);
                  $("#categoryAdd").attr("hidden", false);
                  $("#commentAdd").attr("hidden", true);
                  $("#removeModal").attr("hidden", false);
                }}/> */}
                <div className="col-md-12">
                <input
                  id="enterCategoryName"
                      type="text"
                      hidden
                      className="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Enter Category Name"
                      onChange={(event) => this.setState({ categoryEditName: event.target.value })}
                      value={this.state.categoryEditName}
                    />
                </div>
                
                </div>
               
                <div className="module_popupsearchBtn">
                <button
                id="categoryAdd"
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.saveModules()}
                    hidden
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    id="commentAdd"
                    className="btn btn-primary"
                    hidden
                    onClick={() => this.saveAndStartUpload()}
                  >
                    Save
                  </button>
                  <button
                  hidden
                  id="removeModal"
                className="btn btn-secondary"
                onClick={() =>{this.removeModal()
                  $("#enterCategoryName").attr("hidden", true);
                  $("#removeModal").attr("hidden", true);
                }}
              >
                Cancel
              </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="moveCommentPopup" className="module_popup" hidden>
          <div className="module_innerpopup">
            <div className="module_close">
              <Icon.XCircle
                onClick={() => {
                  $("#moveCommentPopup").attr("hidden", true);
                }}
              />
            </div>
            <div id="editCommentPopupHeader" className="module_header"></div>
            <div className="module_body">
              <div className="module_popupsearch_wrpr">
                {/* <div className="module_popupsearch">
                  <input
                    id="editCommentPopupTextBox"
                    type="text"
                    placeholder="Name"
                  />
                </div> */}

                <div className="py-3">
                  <div class="did-floating-label-content flex-div">
                    <select
                      id="organisationName"
                      class="did-floating-select"
                      onChange={(e) =>
                        this.setState({
                          orgId: e.target.value,
                        })
                      }
                    >
                      <option value="">Organization Category</option>
                      {this.state.organizationListName.map((data, i) => (
                        <option
                          key={i + 1}
                          label={data.organisation_name}
                          value={data.id}
                        >
                          {data.organisation_name}
                        </option>
                      ))}
                    </select>
                    <label class="did-floating-label">
                      Organization Category
                    </label>
                  </div>
                </div>

                <div className="module_popupsearchBtn">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.moveupload()}
                  >
                    Move
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="loder_div" hidden />
        {/* <div className="userList_modal ">
          <div className="userListmodal_inner p-2 modal-sm">
          <div className="row">
                <div className="col-md-12 m-auto">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Add Category</label>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Enter Category Name"
                      onChange={(event) => this.setState({ categoryEditName: event.target.value })}
                      value={this.state.categoryEditName}
                    />
                  </div>
                </div>
              </div>
            <div className="userlistModal_footer">
              <button
                className="btn btn-success"
                onClick={() => this.saveModules()}
              >
                Save
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => this.removeModal()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default CompetiterAnalysisAudit;
