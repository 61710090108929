export const objectives = [
    {
      name: "OUTCOME_APP_PROMOTION",
      code: "OUTCOME_APP_PROMOTION",
      category: [
        { name: "NONE", code: "NONE" },
        { name: "EMPLOYMENT", code: "EMPLOYMENT" }
      ]
    },
    { name: "OUTCOME_AWARENESS", code: "OUTCOME_AWARENESS",category: [
        { name: "NONE", code: "NONE" },
        { name: "HOUSING", code: "HOUSING" },
        { name: "ISSUES_ELECTIONS_POLITICS", code: "ISSUES_ELECTIONS_POLITICS" }
      ] },
    { name: "OUTCOME_LEADS", code: "OUTCOME_LEADS",category: [
        { name: "NONE", code: "NONE" },
        { name: "ONLINE_GAMBLING_AND_GAMING", code: "ONLINE_GAMBLING_AND_GAMING" },
        { name: "CREDIT", code: "CREDIT" },
        { name: "HOUSING", code: "HOUSING" },
      ] },
    { name: "OUTCOME_SALES", code: "OUTCOME_SALES",category: [
        { name: "NONE", code: "NONE" },
        { name: "CREDIT", code: "CREDIT" }
      ] },
    { name: "OUTCOME_TRAFFIC", code: "OUTCOME_TRAFFIC",category: [
        { name: "NONE", code: "NONE" },
        { name: "EMPLOYMENT", code: "EMPLOYMENT" },
        { name: "HOUSING", code: "HOUSING" }
      ] },
    { name: "OUTCOME_ENGAGEMENT", code: "OUTCOME_ENGAGEMENT",category: [
        { name: "NONE", code: "NONE" },
        { name: "ONLINE_GAMBLING_AND_GAMING", code: "ONLINE_GAMBLING_AND_GAMING" }
      ] }
  ];