
import React, { useEffect, useState, useTransition } from "react";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import axios from "axios";
import countries from '../pages/countryCodes.json';
import { Dialog } from 'primereact/dialog';


export default function AdsetForm(props) {
    const [name, setName] = useState('');
    const [campaigns, setCampaigns] = useState([]);
    const [optimization_goal, setOptimization_goal] = useState({ name: 'LINK_CLICKS', code: 'LINK_CLICKS' });
    const [bid_amount, setBidAmount] = useState(0);
    const [billing_event, setBilling_event] = useState({ name: 'NONE', code: 'NONE' });
    const [country, setCountry] = useState({ name: 'India', code: 'IN' })
    const [daily_budget, setDaily_budget] = useState(0);
    const [lifetime_budget, setlifetime_budget] = useState(0);
    const [end_time, setend_time] = useState(null);
    const [campaign_id, setcampaign_id] = useState({ name: 'NONE', code: 'NONE' });
    const [targeting, settargeting] = useState(null);
    const [status, setStatus] = useState({ name: 'PAUSED', code: 'PAUSED' });
    const [type, setStype] = useState('daily');
    const [visible, setVisible] = useState(props.data.visible);

    const optimization_goals = [
        { name: 'NONE', code: 'NONE' },
        { name: 'APP_INSTALLS', code: 'APP_INSTALLS' },
        { name: 'AD_RECALL_LIFT', code: 'AD_RECALL_LIFT' },
        { name: 'ENGAGED_USERS', code: 'ENGAGED_USERS' },
        { name: 'EVENT_RESPONSES', code: 'EVENT_RESPONSES' },
        { name: 'IMPRESSIONS', code: 'IMPRESSIONS' },
        { name: 'LEAD_GENERATION', code: 'LEAD_GENERATION' },
        { name: 'QUALITY_LEAD', code: 'QUALITY_LEAD' },
        { name: 'LINK_CLICKS', code: 'LINK_CLICKS' },
        { name: 'OFFSITE_CONVERSIONS', code: 'OFFSITE_CONVERSIONS' },
        { name: 'PAGE_LIKES', code: 'PAGE_LIKES' },
        { name: 'POST_ENGAGEMENT', code: 'POST_ENGAGEMENT' },
        { name: 'QUALITY_CALL', code: 'QUALITY_CALL' },
        { name: 'REACH', code: 'REACH' },
        { name: 'LANDING_PAGE_VIEWS', code: 'LANDING_PAGE_VIEWS' },
        { name: 'VISIT_INSTAGRAM_PROFILE', code: 'VISIT_INSTAGRAM_PROFILE' },
        { name: 'VALUE', code: 'VALUE' },
        { name: 'THRUPLAY', code: 'THRUPLAY' },
        { name: 'DERIVED_EVENTS', code: 'DERIVED_EVENTS' },
        { name: 'APP_INSTALLS_AND_OFFSITE_CONVERSIONS', code: 'APP_INSTALLS_AND_OFFSITE_CONVERSIONS' },
        { name: 'CONVERSATIONS', code: 'CONVERSATIONS' },
        { name: 'IN_APP_VALUE', code: 'IN_APP_VALUE' },
        { name: 'MESSAGING_PURCHASE_CONVERSION', code: 'MESSAGING_PURCHASE_CONVERSION' },
        { name: 'MESSAGING_APPOINTMENT_CONVERSION', code: 'MESSAGING_APPOINTMENT_CONVERSION' }
    ];
    const billing_events = [
        { name: 'APP_INSTALLS', code: 'APP_INSTALLS' },
        { name: 'CLICKS', code: 'CLICKS' },
        { name: 'IMPRESSIONS', code: 'IMPRESSIONS' },
        { name: 'LINK_CLICKS', code: 'LINK_CLICKS' },
        { name: 'NONE', code: 'NONE' },
        { name: 'OFFER_CLAIMS', code: 'OFFER_CLAIMS' },
        { name: 'PAGE_LIKES', code: 'PAGE_LIKES' },
        { name: 'POST_ENGAGEMENT', code: 'POST_ENGAGEMENT' },
        { name: 'THRUPLAY', code: 'THRUPLAY' },
        { name: 'LISTING_INTERACTION', code: 'LISTING_INTERACTION' }
    ]
    const statuses = [
        { name: 'ACTIVE', code: 'ACTIVE' },
        { name: 'PAUSED', code: 'PAUSED' },
        { name: 'DELETED', code: 'DELETED' },
        { name: 'ARCHIVED', code: 'ARCHIVED' }
    ]
    const footerContent = (
        <div>
            <Button label="No" icon="pi pi-times" onClick={() => { props.func(false) }} className="p-button-text" />
            <Button label="Yes" icon="pi pi-check" onClick={() => { create(); props.func(false) }} autoFocus />
        </div>
    );
    const create = () => {
        let obj = null;
        if (type == 'daily') {
            obj = {
                name,
                optimization_goal,
                billing_event: billing_event.code,
                bid_amount,
                daily_budget,
                campaign_id: campaign_id.code,
                targeting: {
                    "geo_locations": { "countries": [country.code] }
                },
                status: status.code
            }
        } else {
            obj = {
                name,
                lifetime_budget,
                optimization_goal,
                billing_event: billing_event.code,
                bid_amount,
                targeting: {
                    "geo_locations": { "countries": [country.code] }
                },
                campaign_id: campaign_id.code,
                end_time,
                status: status.code
            }
        }
        axios.post('https://9tidgy66eg.execute-api.ap-south-1.amazonaws.com/default/create-adsets', obj).then((res) => {
            console.log(res.data)
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
        console.log(countries.data);
        setVisible(props.data.visible)
    }, [props.data])
    useEffect(() => {
        axios.get('https://1t9imlf7lh.execute-api.ap-south-1.amazonaws.com/default/get-campaigns').then((res) => {
            let tempCampaigns = [];
            tempCampaigns.push({ name: 'NONE', code: 'NONE' });
            res?.data?.data.forEach(element => {
                let obj = { code: element.id, name: element.name }
                tempCampaigns.push(obj);
            });

            setCampaigns(tempCampaigns);
        }).catch(() => {

        })
    }, [])
    return (
        <Dialog header="Create Adset" visible={visible} style={{ width: '50vw' }} onHide={() => props.func(false)} footer={footerContent}>
            <div className="dual_form">
                <div class="form-vertical">
                    <label style={{ margin: '0 10px 0 10px' }}>Name</label><InputText value={name} onChange={(e) => { setName(e.target.value) }} />
                    <label style={{ margin: '0 10px 0 10px' }}>Optimisation Goal</label> <Dropdown value={optimization_goal} onChange={(e) => setOptimization_goal(e.value)} options={optimization_goals} optionLabel="name"
                        placeholder="Select an Objective" className="w-full md:w-14rem" />
                    <label style={{ margin: '0 10px 0 10px' }}>Billing Event</label> <Dropdown value={billing_event} onChange={(e) => setBilling_event(e.value)} options={billing_events} optionLabel="name"
                        placeholder="Select a Status" className="w-full md:w-14rem" />
                    <label style={{ margin: '0 10px 0 10px' }}>Bid Amount</label><InputText value={bid_amount} onChange={(e) => setBidAmount(e.target.value)} />
                    {type == 'daily' ?
                        <><label style={{ margin: '0 10px 0 10px' }}>Daily Budget</label><InputText value={daily_budget} onChange={(e) => setDaily_budget(e.target.value)} /></> :
                        <><label style={{ margin: '0 10px 0 10px' }}>Lifetime Budget</label><InputText value={lifetime_budget} onChange={(e) => setlifetime_budget(e.target.value)} /></>
                    }
                </div>
                <div class="form-vertical">
                    <label style={{ margin: '0 10px 0 10px' }}>Country</label><Dropdown value={country} onChange={(e) => setCountry(e.value)} options={countries.data} optionLabel="name"
                        placeholder="Select an Objective" className="w-full md:w-14rem" />
                    <label style={{ margin: '0 10px 0 10px' }}>Campaign</label><Dropdown value={campaign_id} onChange={(e) => setcampaign_id(e.value)} options={campaigns} optionLabel="name"
                        placeholder="Select a Campaign" className="w-full md:w-14rem" />
                    <label style={{ margin: '0 10px 0 10px' }}>Status</label><Dropdown value={status} onChange={(e) => setStatus(e.value)} options={statuses} optionLabel="name"
                        placeholder="Select a Status" className="w-full md:w-14rem" />

                </div>
            </div>
        </Dialog>
    )
}