import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LeftMenu from "../layout/leftMenu";
import Lock from "../images/left_icons/lock2.png";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { MdHistory } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";
// import Loader from "../images/ideas.jpg";
import { GoPencil } from "react-icons/go";
import { backendApi } from "../apiService";
// import Loader from "../images/loader.gif";
import ApproverPopup from "./approverPopup";
import Img1 from "../images/img1.png";
import Img2 from "../images/images1.jpg";
import Img3 from "../images/ideas.jpg";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import $ from "jquery";
import * as Icon from "react-feather";
import DragDropFile from "../audit/dragDropFile";
import DataInput from "../audit/dataInput";
import AWS from "aws-sdk";
import moment from "moment";

function AddImages() {
  const[waitingCount,setWaitingCount]=useState('');
  const[approvedCount,setApprovedCount]=useState('');
  const[rejectedCount,setRejectedCount]=useState('');
  const [profilePic, setProfilePic] = useState("");
  const [remark, setRemark] = useState("");
  const [editingIndex, setEditingIndex] = useState(-1);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [content_approver_group_id, setContent_approver_group_Data] = useState(
    []
  );
  const [dataFlag, setDataFlag] = useState(false);
  const [showApprovalPopup, setShowApprovalPopup] = useState(false);
  const [requestedStatus, setRequestedStatus] = useState({
    waiting: [],
    approved: [],
    rejected: [],
  });
  var bucketParams;
  var s3;
  const s3ImageUrl =
    "http://tagtree.s3-website.ap-south-1.amazonaws.com/production/images";
  const s3Region = "us-east-1";
  const s3bucketAccessKey = process.env.REACT_APP_TAGTREE_S3_ACCESS_KEY;
  const s3bucketSecretKey = process.env.REACT_APP_TAGTREE_S3_SECRET_KEY;
  const s3Bucket = "tagtree";
  const s3BucketMainFolder = "production";
  const S3BucketImagesFolder = "images";

  useEffect(() => {
    getS3Credentials();
    content_approver_group_data();
    getRequestedData();
    contentInit();
  }, []);

  const contentInit = () => {
    const apiReq = {
      functionName: "content_init",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apiReq).then((data) => {
      if (data.success == "1") {
        const transformedOptions = data.campaign_name.map(
          (campaign, index) => ({
            value: campaign.id,
            label: campaign.campaign_name,
          })
        );
        setCampaignOptions(transformedOptions);
      } else {
        console.log("Error in content init api");
      }
    });
  };

  const getS3Credentials = () => {
    bucketParams = {
      Bucket: s3Bucket,
    };
    AWS.config.update({
      accessKeyId: s3bucketAccessKey,
      secretAccessKey: s3bucketSecretKey,
      region: s3Region,
    });
    s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: bucketParams,
    });
  };
  window.$(document).ready(function () {
    window.$(".popup-image").magnificPopup({
      delegate: "a",
      type: "image",
      tLoading: "Loading image #%curr%...",
      mainClass: "mfp-img-mobile",
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
      },
      image: {
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        titleSrc: function (item) {
          return item.el.attr("title") + "<small></small>";
        },
      },
    });
    window.$(".popup-video").magnificPopup({
      delegate: "a",
      type: "iframe",
      tLoading: "Loading...",
    });
  });

  const sendForApproval = (data) => {
    setProfilePic(data.content_image);
  };

  const handleImageUpload = (image) => {
    setDataFlag(true);
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_TAGTREE_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_TAGTREE_S3_SECRET_KEY,
      region: s3Region,
    });
    var bucketParams = {
      Bucket: s3Bucket,
    };
    // s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: bucketParams,
    });
    let s3BucketMainFolder1 = s3BucketMainFolder + "/" + S3BucketImagesFolder;
    let fileName = uuidv4() + image.name;
    let fullFilePath = s3ImageUrl + "/" + fileName;
    s3.upload(
      {
        Key: fullFilePath,
        Body: image,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          setDataFlag(false);
          alert("There was an error uploading your image");
          console.log(err.message);
        } else if (data) {
          setDataFlag(false);
          // alert("Image Uploaded Successfully");
          setProfilePic(data.Location);
        }
      }
    );
  };
  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  const content_approver_group_data = () => {
    const postData = {
      functionName: "content_approver_group_data",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(postData).then((response) => {
      if (response?.success === "1") {
        setContent_approver_group_Data(response.dataSet);
      } else {
        console.log("Error");
      }
    });
  };
  const requestApprove = () => {
    setShowApprovalPopup(true)
  }
  const onImageCancel = () => {
    const result = window.confirm("Are you sure you want to cancel?");
    if (result) {
      setProfilePic("")
    }

  }
  const getRequestedData = () => {
    const postData = {
      functionName: "content_request_Listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      category_type: "Image",
      req_status: "",
    };

    backendApi(postData)
      .then((data) => {
        if (data.success === "1") {
          const newWaiting = [];
          const newApproved = [];
          const newRejected = [];
          data?.dataList?.forEach((item) => {
            if (item.req_status === "Waiting") {
              newWaiting.push(item);
            } else if (item.req_status === "Approved") {
              newApproved.push(item);
            } else if (item.req_status === "Rejected") {
              newRejected.push(item);
            }
          });
          setWaitingCount(newWaiting.length);
          setApprovedCount(newApproved.length);
          setRejectedCount(newRejected.length);
          setRequestedStatus({
            waiting: newWaiting,
            approved: newApproved,
            rejected: newRejected,
          });
        }
      })
      .catch((error) => {
        console.error("Error occurred while fetching requested data:", error);
      });
  };

  const deleteRequest = (data) => {
    const postData = {
      functionName: "content_request_delete",
      content_generation_request_id: data.id,
      sec_user_id:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };
    backendApi(postData).then((response) => {
      if (response?.success === "1") {
        getRequestedData();
      } else {
        console.log("Error");
      }
    });
  };
  const handleRemarkNameChange = (event) => {
    setRemark(event.target.value);
  };
  const handleEditClick = (index, remarks) => {
    setEditingIndex(index);
    setRemark(remarks);
  };
  const handleCancelClick = () => {
    setEditingIndex(-1);
  };

  const handleUpdateClick = (i, data) => {
    if (!remark) {
      alert("Please Fill Remarks First");
      return;
    }
    const postData = {
      functionName: "content_request_update_remarks",
      content_generation_request_id: data.id,
      remarks: remark,
      remarks_updated_by:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };
    backendApi(postData).then((response) => {
      if (response?.success === "1") {
        setEditingIndex(-1);
        getRequestedData();
      } else {
        setEditingIndex(-1);
        console.log("Error");
      }
    });
  };
  const closePopup = () => {
    getRequestedData();
    setProfilePic("");
    setShowApprovalPopup(false)
  };
  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Add Images" headerImage={Lock} />
        <div className="user_listdiv">
          <div className="col-md-12 tabs-main-wrapper">
            <Tabs>
              <TabList onClick={(e) => setEditingIndex(-1)}>
                <Tab>Upload Image</Tab>
                <Tab>Requested
              <span className="waitingTxt">{waitingCount}</span>
              </Tab>
              <Tab>Approved
              <span className="waitingTxt">{approvedCount}</span>
              </Tab>
              <Tab>Rejected
              <span className="rejectedTxt">{rejectedCount}</span>
              </Tab>
              </TabList>

              <TabPanel>
                {!dataFlag ? (
                  <>
                    <div className="file_upload_outer mt-3 container">
                      <DragDropFile>
                        <div
                          className="form-group formBottom"
                          id="form-fileupload"
                        >
                          <DataInput handleFile={handleImageUpload} />
                        </div>
                      </DragDropFile>
                    </div>
                 {profilePic &&
                      <div className="imageWrap">
                        <img src={profilePic} alt={Img1} className="img-fluid" width="200" height={200} />
                        <button className="btn btn-success ml-3" onClick={requestApprove}>Send for Approval</button>
                        <span
                          className="btn-cancel"
                          style={{ cursor: "pointer" }}
                          onClick={() => onImageCancel()}
                        >
                          Cancel
                        </span>
                      </div>} 
                  </>
                ) : (
                  <>
                    <div className="loder-wrapper">Uploading.......</div>
                  </>
                )}
              </TabPanel>

              <TabPanel>
                <div className="col-md-12 tableResponsive tagImageEditrListTble mt-4">
                  <div className="tableOuterWidth">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="col-3">Image </th>
                          <th className="col-3">Remarks</th>
                          <th className="col-2">Requested</th>
                          <th className="col-2">Approved</th>
                          <th className="col-2">Action</th>
                        </tr>
                      </thead>
                      {requestedStatus?.waiting?.map((data, i) => (
                        <tbody className="table_Outertbody pb-3">
                          <tr>
                            <td className="col-3">
                              <div className="popup-image">
                                <div className="popupGallerysection">
                                  <a
                                    href={data.content_image}
                                    title="img"
                                    target="_blank"
                                  >
                                    <img
                                      src={data.content_image}
                                      className="img-fluid"
                                    />
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td className="col-3">
                              {editingIndex === i ? (
                                <textarea
                                  type="text"
                                  value={remark}
                                  onChange={handleRemarkNameChange}
                                  className="w-100 form-control"
                                />
                              ) : (
                                <span>{data.remarks}</span>
                              )}
                              {editingIndex !== i ? (
                                <GoPencil
                                  className="ml-2"
                                  onClick={() =>
                                    handleEditClick(i, data.remarks)
                                  }
                                />
                              ) : (
                                ""
                              )}
                              {editingIndex === i && (
                                <div className="mt-2">
                                  <button
                                    onClick={() => handleUpdateClick(i, data)}
                                    className="btn btn-success"
                                  >
                                    Save
                                  </button>
                                  <button
                                    onClick={handleCancelClick}
                                    className="btn btn-secondary ml-1"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              )}
                            </td>
                            <td className="col-2">
                              <p>
                                {" "}
                                {moment(data.requested_date).format(
                                  "DD MMM YYYY"
                                )}
                              </p>
                              <p>{data.requested_by_name}</p>
                            </td>
                            <td className="col-2">
                              {data.approved_by_name ? (
                                <>
                                  <p>
                                    {moment(data.approved_date).format(
                                      "DD MMM YYYY"
                                    )}
                                  </p>
                                  <p>{data.approved_by_name}</p>
                                </>
                              ) : (
                                <p>Not approved</p>
                              )}
                            </td>
                            <td className="col-2">
                              {/* <button type="submit" className="btn btn-success mr-2">Send for Approval</button> */}
                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={(e) => deleteRequest(data)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                      {/* </div> */}
                    </table>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="col-md-12 tableResponsive tagImageEditrListTble mt-4">
                  <div className="tableOuterWidth">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="col-3">Image </th>
                          <th className="col-3">Remarks</th>
                          <th className="col-2">Requested</th>
                          <th className="col-2">Approved</th>
                          <th className="col-2">Action</th>
                        </tr>
                      </thead>
                      <div className="table_Outerscroll">
                        {requestedStatus?.approved?.map((data, i) => (
                          <tbody className="table_Outertbody pb-3">
                            <tr>
                              <td className="col-3">
                                <div className="popup-image">
                                  <div className="popupGallerysection">
                                    <a
                                      href={data.content_image}
                                      title="img"
                                      target="_blank"
                                    >
                                      <img
                                        src={data.content_image}
                                        className="img-fluid"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td className="col-3">
                                {editingIndex === i ? (
                                  <textarea
                                    type="text"
                                    value={remark}
                                    onChange={handleRemarkNameChange}
                                    className="w-100 form-control"
                                  />
                                ) : (
                                  <span>{data.remarks}</span>
                                )}
                                {editingIndex !== i ? (
                                  <GoPencil
                                    className="ml-2"
                                    onClick={() =>
                                      handleEditClick(i, data.remarks)
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                                {editingIndex === i && (
                                  <div className="mt-2">
                                    <button
                                      onClick={() => handleUpdateClick(i, data)}
                                      className="btn btn-success"
                                    >
                                      Save
                                    </button>
                                    <button
                                      onClick={handleCancelClick}
                                      className="btn btn-secondary ml-1"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                )}
                              </td>
                              <td className="col-2">
                                <p>
                                  {" "}
                                  {moment(data.requested_date).format(
                                    "DD MMM YYYY"
                                  )}
                                </p>
                                <p>{data.requested_by_name}</p>
                              </td>
                              <td className="col-2">
                                <p>
                                  {" "}
                                  {moment(data.approved_date).format(
                                    "DD MMM YYYY"
                                  )}
                                </p>
                                <p>{data.approved_by_name}</p>
                              </td>
                              <td className="col-2">
                                {/* <button type="submit" className="btn btn-success mr-2">Send for Approval</button> */}
                                <button
                                  type="submit"
                                  className="btn btn-danger"
                                  onClick={(e) => deleteRequest(data)}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </div>
                    </table>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="col-md-12 tableResponsive tagImageEditrListTble mt-4">
                  <div className="tableOuterWidth">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="col-3">Image </th>
                          <th className="col-3">Remarks</th>
                          <th className="col-2">Requested</th>
                          <th className="col-2">Rejected</th>
                          <th className="col-2">Action</th>
                        </tr>
                      </thead>
                      <div className="table_Outerscroll">
                        {requestedStatus?.rejected?.map((data, i) => (
                          <tbody className="table_Outertbody pb-3">
                            <tr>
                              <td className="col-3">
                                <div className="popup-image">
                                  <div className="popupGallerysection">
                                    <a
                                      href={data.content_image}
                                      title="img"
                                      target="_blank"
                                    >
                                      <img
                                        src={data.content_image}
                                        className="img-fluid"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td className="col-3">
                                {editingIndex === i ? (
                                  <textarea
                                    type="text"
                                    value={remark}
                                    onChange={handleRemarkNameChange}
                                    className="w-100 form-control"
                                  />
                                ) : (
                                  <span>{data.remarks}</span>
                                )}
                                {editingIndex !== i ? (
                                  <GoPencil
                                    className="ml-2"
                                    onClick={() =>
                                      handleEditClick(i, data.remarks)
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                                {editingIndex === i && (
                                  <div className="mt-2">
                                    <button
                                      onClick={() => handleUpdateClick(i, data)}
                                      className="btn btn-success"
                                    >
                                      Save
                                    </button>
                                    <button
                                      onClick={handleCancelClick}
                                      className="btn btn-secondary ml-1"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                )}
                              </td>
                              <td className="col-2">
                                <p>
                                  {" "}
                                  {moment(data.requested_date).format(
                                    "DD MMM YYYY"
                                  )}
                                </p>
                                <p>{data.requested_by_name}</p>
                              </td>
                              <td className="col-2">
                                <p>
                                  {" "}
                                  {moment(data.approved_date).format(
                                    "DD MMM YYYY"
                                  )}
                                </p>
                                <p>{data.approved_by_name}</p>
                              </td>
                              <td className="col-2">
                                <button
                                  type="submit"
                                  className="btn btn-success mr-1"
                                  onClick={() => sendForApproval(data)}
                                >
                                  Resend
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-danger"
                                  onClick={(e) => deleteRequest(data)}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </div>
                    </table>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>

        <div className="loder_div" hidden />
        <Footer />
        {profilePic && showApprovalPopup&&(
          <ApproverPopup
            onClose={closePopup}
            groupData={content_approver_group_id}
            textContent={profilePic}
            typeOfContent={"Image Content"}
            campaignOptions={campaignOptions}
          />
        )}
      </div>
    </div>
  );
}

export default AddImages;
