import React, { useState, useEffect } from "react";
import SaveAndValidation from "./settingsSaveAndValidation";
import * as Icon from "react-feather";
import { backendApi } from "../../apiService";
import $ from "jquery";
const SettingsOrganization = (props) => {
  const [listingDataOfCatogaries, setlistingDataOfCatogaries] = useState([]);
  const [selectedCatogaries, setselectedCatogaries] = useState();
  const [organizationId, setorganizationId] = useState("");
  const [organizationName, setorganizationName] = useState("");
  const [organizationAddress, setorganizationAddress] = useState("");
  const [organizationAddress2, setorganizationAddress2] = useState("");
  const [organizationAddress3, setorganizationAddress3] = useState("");
  const [organizationAddress4, setorganizationAddress4] = useState("");
  const [administrativeArea, setAdministrativeArea] = useState("");
  const [locality, setLocality] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [organizationMobileNo, setorganizationMobileNo] = useState("");
  const [organizationEmailId, setorganizationEmailId] = useState("");
  const [organizationPinCode, setorganizationPinCode] = useState("");
  const [organizationDomain, setorganizationDomain] = useState("");
  const [organizationIsActive, setOrganizationIsActive] = useState(false);
  const [categoryEditName, setcategoryEditName] = useState("");
  const [isLogin, setisLogin] = useState(
    window.sessionStorage.getItem("isLogin")
  );
  const [userRole, setuserRole] = useState(
    window.sessionStorage.getItem("role")
  );
  useEffect(() => {
    if (
      props.listingDataOfCatogaries &&
      (props.listingDataOfCatogaries != "null" ||
        props.listingDataOfCatogaries != "undefined")
    ) {
      setlistingDataOfCatogaries(props.listingDataOfCatogaries);
    } else {
      setlistingDataOfCatogaries([]);
    }
    //     ////////////////
    if (
      props.listingValuesForPassing.category_id &&
      (props.listingValuesForPassing.category_id != "null" ||
        props.listingValuesForPassing.category_id != "undefined")
    ) {
      setselectedCatogaries(props.listingValuesForPassing.category_id);
    } else {
      setselectedCatogaries("");
    }
    //////////////////////
    if (
      props.listingValuesForPassing.organisation_name &&
      (props.listingValuesForPassing.organisation_name != "null" ||
        props.listingValuesForPassing.organisation_name != "undefined")
    ) {
      setorganizationName(props.listingValuesForPassing.organisation_name);
    } else {
      setorganizationName("");
    }
    if (
      props.listingValuesForPassing.id &&
      (props.listingValuesForPassing.id != "null" ||
        props.listingValuesForPassing.id != "undefined")
    ) {
      setorganizationId(props.listingValuesForPassing.id);
    } else {
      setorganizationId("");
    }
    ///////////////////////////////////////////////////
    if (
      props.listingValuesForPassing.address &&
      (props.listingValuesForPassing.address != "null" ||
        props.listingValuesForPassing.address != "undefined")
    ) {
      setorganizationAddress(props.listingValuesForPassing.address);
    } else {
      setorganizationAddress("");
    }
    /////////////////////////////////////////
    if (
      props.listingValuesForPassing.mobile_no &&
      (props.listingValuesForPassing.mobile_no != "null" ||
        props.listingValuesForPassing.mobile_no != "undefined")
    ) {
      setorganizationMobileNo(props.listingValuesForPassing.mobile_no);
    } else {
      setorganizationMobileNo("");
    }
    ///////////////////////////////////////////////////////
    if (
      props.listingValuesForPassing.email_id &&
      (props.listingValuesForPassing.email_id != "null" ||
        props.listingValuesForPassing.email_id != "undefined")
    ) {
      setorganizationEmailId(props.listingValuesForPassing.email_id);
    } else {
      setorganizationEmailId("");
    }
    //////////////////////////////////////////////
    if (
      props.listingValuesForPassing.pin_code &&
      (props.listingValuesForPassing.pin_code != "null" ||
        props.listingValuesForPassing.pin_code != "undefined")
    ) {
      setorganizationPinCode(props.listingValuesForPassing.pin_code);
    } else {
      setorganizationPinCode("");
    }
    /////////////////////////////////////////////////
    if (
      props.listingValuesForPassing.domain &&
      (props.listingValuesForPassing.domain != "null" ||
        props.listingValuesForPassing.domain != "undefined")
    ) {
      setorganizationDomain(props.listingValuesForPassing.domain);
    } else {
      setorganizationDomain("");
    }
    if (
      props.listingValuesForPassing.is_active &&
      (props.listingValuesForPassing.is_active != "null" ||
        props.listingValuesForPassing.is_active != "undefined")
    ) {
      setOrganizationIsActive(
        props.listingValuesForPassing.is_active == 0 ? false : true
      );
    } else {
      setOrganizationIsActive(0);
    }
  }, [
    props.listingValuesForPassing.category_id,
    props.listingDataOfCatogaries,
    props.listingValuesForPassing.organisation_name,
    props.listingValuesForPassing.id,
    props.listingValuesForPassing.address,
    props.listingValuesForPassing.mobile_no,
    props.listingValuesForPassing.email_id,
    props.listingValuesForPassing.pin_code,
    props.listingValuesForPassing.domain,
    props.listingValuesForPassing.is_active,
  ]);
  var orgadmin = window.sessionStorage.getItem("role") == "ORG_ADMIN";
  const organisationSelect = (value, type) => {
    if (value == "") {
      // setlistingValuesForPassing("")
      setorganizationId("");
      setorganizationName("");
      setorganizationAddress("");
      setorganizationMobileNo("");
      setorganizationEmailId("");
      setorganizationPinCode("");
      setorganizationDomain("");
      setselectedCatogaries("");
      setOrganizationIsActive(false);
    } else if (type === "Category") {
      setselectedCatogaries(value);
    }
  };
  const saveModules = () => {
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "createCategory",
      category: categoryEditName,
    };
    backendApi(req).then((response) => {
      $(".loder_div").attr("hidden", true);
      if (response.success === "1") {
        setcategoryEditName("");
        removeModal();
        alert("Saved successfully");
        props.getUserData();
      }
    });
  };
  const removeModal = () => {
    $("body").removeClass("isActive");
    $(".modules_div").removeClass("active");
    // $("#modulesSelectAll").prop("checked", false);
  };
  const addCategories = () => {
    $("body").addClass("isActive");
  };
  return (
    <>
      {isLogin === "true" &&
        (userRole === "ROLE_SUPER_ADMIN" ||
          userRole === "ROLE_ADMIN" ||
          userRole === "ORG_ADMIN") && (
          <>
            {/* <div className=" ">
              <div className="container"> */}

            <div className="row">
              <div className="col-md-12">
                <div className="user_formdiv">
                  <div className="userform_head">Organization Details</div>
                  <div className="row userrow_form">
                    <div className="col-md-4">
                      <label className="col-form-label">Organization Id</label>
                    </div>
                    <div className="col-md-1">
                      <span>:</span>
                    </div>
                    <div className="col-md-7">
                      <input
                        disabled
                        // onChange={(e) => setorganizationId(e.target.value)}
                        type="text"
                        className="form-control"
                        value={organizationId}
                        placeholder="Organization Id"
                      />
                    </div>
                  </div>
                  <div className="row userrow_form">
                    <div className="col-md-4">
                      <label className="col-form-label">Name</label>
                    </div>
                    <div className="col-md-1">
                      <span>:</span>
                    </div>
                    <div className="col-md-7">
                      <input
                        onChange={(e) => setorganizationName(e.target.value)}
                        type="text"
                        id="organizationName"
                        className="form-control"
                        value={organizationName}
                        placeholder="Organization Name"
                      />
                    </div>
                  </div>
                  <div className="row userrow_form">
                    <div className="col-md-4">
                      <label className="col-form-label">Address 1</label>
                    </div>
                    <div className="col-md-1">
                      <span>:</span>
                    </div>
                    <div className="col-md-7">
                      <input
                        type="text"
                        id="organizationAddress"
                        className="form-control"
                        onChange={(e) => setorganizationAddress(e.target.value)}
                        value={organizationAddress}
                        placeholder="Address Line 1"
                      />
                    </div>
                  </div>
                  <div className="row userrow_form">
                    <div className="col-md-4">
                      <label className="col-form-label">Address 2</label>
                    </div>
                    <div className="col-md-1">
                      <span>:</span>
                    </div>
                    <div className="col-md-7">
                      <input
                        type="text"
                        id="organizationAddress"
                        className="form-control"
                        onChange={(e) =>
                          setorganizationAddress2(e.target.value)
                        }
                        value={organizationAddress2}
                        placeholder="Address Line 2"
                      />
                    </div>
                  </div>
                  <div className="row userrow_form">
                    <div className="col-md-4">
                      <label className="col-form-label">Address 3</label>
                    </div>
                    <div className="col-md-1">
                      <span>:</span>
                    </div>
                    <div className="col-md-7">
                      <input
                        type="text"
                        id="organizationAddress"
                        className="form-control"
                        onChange={(e) =>
                          setorganizationAddress3(e.target.value)
                        }
                        value={organizationAddress3}
                        placeholder="Address Line 3"
                      />
                    </div>
                  </div>
                  <div className="row userrow_form">
                    <div className="col-md-4">
                      <label className="col-form-label">Address 4</label>
                    </div>
                    <div className="col-md-1">
                      <span>:</span>
                    </div>
                    <div className="col-md-7">
                      <input
                        type="text"
                        id="organizationAddress"
                        className="form-control"
                        onChange={(e) =>
                          setorganizationAddress4(e.target.value)
                        }
                        value={organizationAddress4}
                        placeholder="Address Line 4"
                      />
                    </div>
                  </div>
                  <div className="row userrow_form">
                    <div className="col-md-4">
                      <label className="col-form-label">Locality</label>
                    </div>
                    <div className="col-md-1">
                      <span>:</span>
                    </div>
                    <div className="col-md-7">
                      <input
                        type="text"
                        id="organizationAddress"
                        className="form-control"
                        onChange={(e) => setLocality(e.target.value)}
                        value={locality}
                        placeholder="Organization Address"
                      />
                    </div>
                  </div>
                  <div className="row userrow_form">
                    <div className="col-md-4">
                      <label className="col-form-label">
                        Administrative Area
                      </label>
                    </div>
                    <div className="col-md-1">
                      <span>:</span>
                    </div>
                    <div className="col-md-7">
                      <input
                        type="text"
                        id="organizationAddress"
                        className="form-control"
                        onChange={(e) => setAdministrativeArea(e.target.value)}
                        value={administrativeArea}
                        placeholder="Organization Address"
                      />
                    </div>
                  </div>
                  <div className="row userrow_form">
                    <div className="col-md-4">
                      <label className="col-form-label">Mobile No.</label>
                    </div>
                    <div className="col-md-1">
                      <span>:</span>
                    </div>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        id="organizationmobno"
                        onChange={(e) =>
                          setorganizationMobileNo(e.target.value)
                        }
                        maxlength="10"
                        value={organizationMobileNo}
                        placeholder="Organization Mobile No."
                      />
                    </div>
                  </div>
                  <div className="row userrow_form">
                    <div className="col-md-4">
                      <label className="col-form-label">Email Id.</label>
                    </div>
                    <div className="col-md-1">
                      <span>:</span>
                    </div>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        id="organizationEmail"
                        value={organizationEmailId}
                        onChange={(e) => setorganizationEmailId(e.target.value)}
                        placeholder="Organization Email Id."
                      />
                    </div>
                  </div>
                  <div className="row userrow_form">
                    <div className="col-md-4">
                      <label className="col-form-label">Pin Code</label>
                    </div>
                    <div className="col-md-1">
                      <span>:</span>
                    </div>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        id="organizationPinCode"
                        value={organizationPinCode}
                        onChange={(e) => setorganizationPinCode(e.target.value)}
                        placeholder="Organization Pin Code"
                      />
                    </div>
                  </div>

                  <div className="row userrow_form">
                    <div className="col-md-4">
                      <label className="col-form-label">Domain</label>
                    </div>
                    <div className="col-md-1">
                      <span>:</span>
                    </div>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        id="organizationDomain"
                        placeholder="Domain"
                        onChange={(e) => setorganizationDomain(e.target.value)}
                        value={organizationDomain}
                      />
                    </div>
                  </div>
                  <div className="row userrow_form">
                    <div className="col-md-4">
                      <label className="col-form-label">Category</label>
                    </div>
                    <div className="col-md-1">
                      <span>:</span>
                    </div>
                    <div className="col-md-7 flex-div">
                      <select
                        id="organizationSelection"
                        className="form-control"
                        value={selectedCatogaries}
                        onChange={(e) => {
                          organisationSelect(e.target.value, "Category");
                        }}
                      >
                        <option selected="selected" value={""}>
                          {" "}
                          Select Category{" "}
                        </option>
                        {listingDataOfCatogaries.map((item) => (
                          <option value={item.id}>{item.category}</option>
                        ))}
                      </select>
                      <Icon.PlusCircle
                        onClick={(event) => addCategories(event.target.checked)}
                      />
                    </div>
                  </div>
                  <div className="row userrow_form">
                    <div className="col-md-4">
                      <label className="col-form-label">Is Active</label>
                    </div>
                    <div className="col-md-1">
                      <span>:</span>
                    </div>
                    <div className="col-md-7">
                      <span>
                        <>
                          <label className="switch_items">
                            <input
                              id={"organizationStatus"}
                              name={"status"}
                              type="checkbox"
                              checked={organizationIsActive}
                              onClick={(event) =>
                                setOrganizationIsActive(event.target.checked)
                              }
                            />
                            <span className="switch round"></span>
                          </label>
                        </>
                      </span>
                    </div>
                  </div>
                  <div className="row userrow_form">
                    <div className="col-md-4">
                      <label className="col-form-label">GSTIN</label>
                    </div>
                    <div className="col-md-1">
                      <span>:</span>
                    </div>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        id="organizationDomain"
                        placeholder="GSTIN No."
                        onChange={(e) => setGstNo(e.target.value)}
                        value={gstNo}
                      />
                    </div>
                  </div>
                  <SaveAndValidation
                    getUserData={() => props.getUserData()}
                    valueForSaved={[
                      selectedCatogaries,
                      organizationName,
                      organizationAddress,
                      organizationMobileNo,
                      organizationEmailId,
                      organizationPinCode,
                      organizationDomain,
                      organizationIsActive,
                      organizationAddress2,
                      organizationAddress3,
                      organizationAddress4,
                      administrativeArea,
                      locality,
                      gstNo,
                    ]}
                    saveMode={"organizationSetting"}
                    organizationId={organizationId}
                  />
                </div>
              </div>
            </div>

            <div className="loder_div" hidden />
            <div className="userList_modal ">
              <div className="userListmodal_inner p-2 modal-sm">
                {/* <div class="userListmodal_head">
            <span class="subhead">Categories</span>
          </div> */}
                <div className="row">
                  <div className="col-md-12 m-auto">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Add Category</label>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Enter Category Name"
                        onChange={(event) => {
                          setcategoryEditName(event.target.value);
                        }}
                        value={categoryEditName}
                      />
                    </div>
                  </div>
                </div>
                <div className="userlistModal_footer">
                  <button
                    className="btn btn-success"
                    onClick={() => saveModules()}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => removeModal()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            {/* </div> */}
            {/* </div>
            </div> */}
          </>
        )}
    </>
  );
};
export default SettingsOrganization;
