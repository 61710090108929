import React, { useEffect, useState } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import rolesimage from "../images/left_icons/roles.png";
import LeftMenu from "../layout/leftMenu";
import { backendApi } from "../apiService";
import Header from "../layout/header";
import Footer from "../layout/footer";
import $ from "jquery";
import bank1Image from "../images/bank1.png";
import Analysisimage from "../images/left_icons/analysis.png";
import * as Icon from "react-feather";
import Slider from "react-slick";
import "../css/slick.css";
import "../css/slick-theme.css";

const CompetitorList = () => {
  const [competitors, setCompetitors] = useState([]);
  const [startIndex, setStartIndex] = useState(0);

  // const initialData = competitors.slice(startIndex, startIndex + 4);
  const getCompetitors = () => {
    var req = {
      functionName: "getCompetitors",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };

    backendApi(req)
      .then((data) => {
        console.log("compData", data);
        if (data.success === "1") {
          setCompetitors(data.data);
        } else {
          console.log("Error getting competitors");
        }
      })
      .catch((error) => {
        console.log("Error connecting to server");
      });
  };

  useEffect(() => {
    getCompetitors();
  }, []);

  // const handleNextClick = () => {
  //   if (initialData.length < 4) {
  //     setStartIndex(startIndex + 1);
  //   } else {
  //     setStartIndex(startIndex + 4);
  //   }
  // };
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows:true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };
  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header
          heading="Competitors and Similar Companies"
          headerImage={Analysisimage}
        />
        <div className="container mt-5 mb-2">
          <div className="listing_divs">
            <Slider {...settings}>
              {/* <div className="listing_divs_arrow">
              <Icon.ArrowRight  onClick={handleNextClick}/>
            </div> */}
              {competitors.map((competitor,index) => (
                <div className="listing_col" key={index}>
                  <div className="card border-0 h-100">
                    <div className="card-body card__body">
                      <div className="card__img">
                        <img
                          src={competitor.brand_logo}
                          alt="bank1Image"
                          className="img-fluid"
                        />
                      </div>
                      <div className="card__body_contt">
                        <p className="mb-0">{competitor.category}</p>
                        <div className="card_divider"></div>
                        <div className="card_body_icon">
                          <button type="button" className="btn btn-success">
                          <label className="mb-0 position-relative cursor-pointer">
                            <input type="file"/>
                            <Icon.Upload />
                          </label>
                          </button>
                          <button type="button" className="btn btn-warning">
                          <a href="" download className=" text-white">
                            <Icon.Download />
                          </a>
                          </button>
                          <button type="button" className="btn btn-info">
                          <Icon.Eye />
                          </button>
                        </div>
                        <div className="card_body_submit">
                          <button type="button" className="btn btn-primary">
                            Classify
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
};

export default CompetitorList;
