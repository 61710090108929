import React, { Component } from "react";
import LeftMenu from "../layout/leftMenu";
import LocalPagesimage from "../images/left_icons/Local-Page.png";
import Header from "../layout/header";
import "../css/bootstrap.css";
import "../css/style.css";
class liveUpdate extends Component {
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper">
          <Header heading="Export Excel" headerImage={LocalPagesimage} />
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="user_formdiv mt-5 p-3">
                  <div className="export_data_header">
                  Export  &#123; object Name here &#125;
                  </div>
                  <p className="export_data_p">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,{" "}
                  </p>
                  <div className="user_formdiv mb-3 mt-3 exportData">
                    <div className="row">
                      <div className="col-md-6">
                        <label class="radio-inline">
                          <input
                            type="radio"
                            name="filenames"
                            checked
                            className="mr-1"
                          />
                          CSV
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label class="radio-inline">
                          <input
                            type="radio"
                            name="filenames"
                            className="mr-1"
                          />
                          JSON
                        </label>
                      </div>
                     
                    </div>
                  </div>
                  <div className="text-right">
                    <button className="btn btn-danger mr-2">Cancel</button>
                    <button className="btn btn-primary">Export</button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="user_formdiv mt-5 p-3">
                  <div className="export_data_header">
                    Export  &#123; object Name here &#125;
                  </div>
                  <p className="export_data_p">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,{" "}
                  </p>
                  <div className="user_formdiv mb-3 mt-3 exportData">
                    <div className="row">
                      <div className="col-md-12">
                        <label class="radio-inline">
                          <input
                            type="radio"
                            name="options"
                            checked
                            className="mr-1"
                          />
                         Url
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <button className="btn btn-danger mr-2">Cancel</button>
                    <button className="btn btn-primary">Export</button>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                        <div className="export_black mt-5 pl-5">
                            {/* &#123;<br/>
                          <div className="ml-5">Test</div>
                            &#125; */}
                            Test
                        </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default liveUpdate;
