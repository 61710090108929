import React, { Component } from "react";
import * as Icon from "react-feather";
import Dashboardimage from "../images/left_icons/dashboard.png";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
class CreateZone extends Component {
  constructor() {
    super();
    this.state = {
      rowList: [true]
    };
  }
  addItemRow() {
    let rowList = [...this.state.rowList];
    rowList.push(true);
    this.setState({ rowList });
  }
  render() {
    let { rowList } = this.state;
    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper mapunmap_div">
          <Header heading="Create Zone" headerImage={Dashboardimage} />
          <div className="container-fluid">
          <Tabs>
    <TabList>
      <Tab>Create Zone Level Access</Tab>
      <Tab>Page Level Access</Tab>
    </TabList>

    <TabPanel>
    <div className="create_zonediv">
                <label>Create Zone Level Access</label>
                <div className="innercreate_zonediv">
                  <div className="row">
                    <div className="col-md-4">
                      <input type="text" className="form-control" placeholder="Zone Name"/>
                    </div>
                    <div className="col-md-2">
                      <button className="btn btn-primary p-0 append_btnclick" onClick={() => this.addItemRow()}><Icon.Plus/></button>
                    </div>
                  </div>
                  <div className="append_divzone">
                  {rowList.map((x, i) => {
                  return (
                    <div  key={i}>
                  <div className="row">
                    <div className="col-md-4">
                      <select className="form-control">
                        <option>Select</option>
                        <option>Kerala</option>
                      </select>
                    </div>
                    <div className="col-md-4">
                    <label className="">Select City</label>
                    <ul class="nav flex_lizone">
                        <li class="nav-item">
                            <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" value=""/> Tvm
                            </label>
                        </li>
                        <li class="nav-item">
                            <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" value=""/> Tvm
                            </label>
                        </li>
                        <li class="nav-item">
                            <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" value=""/> Cochin
                            </label>
                        </li>
                        <li class="nav-item">
                            <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" value=""/> kannur
                            </label>
                        </li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                      <label className="d-flex align-items-center">Assign to</label>
                      <ul class="nav flex_lizone">
                        <li class="nav-item">
                            <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" value=""/> Name
                            </label>
                        </li>
                        <li class="nav-item">
                            <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" value=""/> Name
                            </label>
                        </li>
                        </ul>
                    </div>
                  </div>
                  <hr/>
                  </div>
                  )
                })}
                  </div>
                </div>
              </div>
    </TabPanel>
    <TabPanel>
    <div className="create_zonediv">
                <label>By Store / Branch</label>
                <div className="innercreate_zonediv">
                  <div className="row">
                    <div className="col-md-6">
                    <ul class="list-group">
                        <li class="list-group-item">Location 1</li>
                        <li class="list-group-item">Location 2</li>
                        <li class="list-group-item">Location 3</li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                      <label className="d-flex align-items-center">Assign to</label>
                      <ul class="nav flex_lizone">
                        <li class="nav-item">
                            <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" value=""/> Name
                            </label>
                        </li>
                        <li class="nav-item">
                            <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" value=""/> Name
                            </label>
                        </li>
                        </ul>
                    </div>
                  </div>
                  <hr/>
                  </div>
                </div>
    </TabPanel>
  </Tabs>
        
          </div>
          <Footer/>
        </div>
      </div>
    );
  }
}

export default CreateZone;
