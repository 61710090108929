import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { backendApi } from "../apiService";

let globalLocationId = 0;

function AddSingleQuestion() {
	const navigate = useNavigate();
	const [locationsList, setLocationsList] = useState([]);
	const [temporaryLocationsList, setTemporaryLocationsList] = useState([]);
	const [selectedLocationsArray, setSelectedLocationsArray] = useState([]);
	const [administrativeAreaList, setAdministrativeAreaList] = useState([]);

	const [localityListBackup, setLocalityListBackup] = useState([]);
	const [administrativeAreaFilterEnable, setAdministrativeAreaFilterEnable] = useState(0);
	const [administrativeAreaFilterEnableArray, setAdministrativeAreaFilterEnableArray] = useState(0);
	const [selectedLocationsCompleteDataArray, setSelectedLocationsCompleteDataArray] = useState([]);
	const [questionAdded, setQuestionAdded] = useState("");
	const [answerAdded, setAnswerAdded] = useState([]);
	const [localityList, setLocalityList] = useState([]);

	useEffect(() => getLocationsList(), []);

	const getLocationsList = React.useCallback(() => {
		$(".loder_div").attr("hidden", false);
		backendApi({
			functionName: "getMasterDataForFilter",
			siteId: window.sessionStorage.getItem("switchingOrgId") === "" ? window.sessionStorage.getItem("organizationId") : window.sessionStorage.getItem("switchingOrgId"),
		})
			.then(({ success, data }) => {
				console.log(data);
				$(".loder_div").attr("hidden", true);
				if (success != "1") {
					setLocationsList([]);
					return;
				}
				setLocationsList(data);
				setTemporaryLocationsList(data);
				globalLocationId = data.length;
				const adminAreaList = new Array(...new Set(data.map((obj) => obj.administrativeArea)));
				setAdministrativeAreaList(adminAreaList);
				const currentLocalityList = new Array(...new Set(data.map((obj) => obj.locality)));
				setLocalityList(currentLocalityList);
				setLocalityListBackup(currentLocalityList);
			})
			.catch((error) => {
				console.trace(error);
				alert("Something went wrong");
			});
		$(".loder_div").attr("hidden", false);
	}, []);

	const selectLocation = (e, data, id) => {
		if (e.target.checked === true && !selectedLocationsArray.includes(data.location_id)) {
			setSelectedLocationsCompleteDataArray((object) => [...object, data]);
			setSelectedLocationsArray((object) => [...object, data.location_id]);
		} else if (e.target.checked === false) {
			if (selectedLocationsArray.length != locationsList.filter) {
				$("#selectAllLocations").prop("checked", false);
			}

			let index = selectedLocationsArray.indexOf(data.location_id);
			selectedLocationsArray.splice(index, 1);
			selectedLocationsCompleteDataArray.splice(index, 1);
		}
		$("#selectAllLocations").prop("checked", false);
	};

	const setFilter = async (e) => {
		if (e.target.id === "administrativeList") {
			if (e.target.value != "0") {
				setAdministrativeAreaFilterEnable(1);
				const filteredLocations = temporaryLocationsList.filter((obj) => {
					return obj.administrativeArea === e.target.value;
				});
				const localityArray = [];
				filteredLocations.forEach((obj) => {
					return !localityArray.includes(obj.locality) && localityArray.push(obj.locality);
				});
				setLocationsList(filteredLocations);
				setAdministrativeAreaFilterEnableArray(filteredLocations);
				setLocalityList(localityArray);
			} else {
				setLocationsList(temporaryLocationsList);
				setLocalityList(localityListBackup);
				setAdministrativeAreaFilterEnable(0);
			}
		} else if (e.target.id === "localityFilter") {
			if (e.target.value != "0") {
				setAdministrativeAreaFilterEnable(1);
				const filteredLocations = temporaryLocationsList.filter((obj) => {
					return obj.locality === e.target.value;
				});
				setLocationsList(filteredLocations);
			} else {
				setAdministrativeAreaFilterEnable(0);
				setLocationsList(temporaryLocationsList);
			}
		}
	};
	const selectAllLocations = (e) => {
		if (e.target.checked === true) {
			for (let i = 0; i < globalLocationId; i++) {
				$("#selectLocationPost" + i).prop("checked", true);
			}
			if (selectedLocationsArray.length > 0) {
				let filteredArray = locationsList.filter((value) => !selectedLocationsArray.includes(value.location_id));
				filteredArray.map((obj) => {
					selectedLocationsArray.push(obj.location_id);
					selectedLocationsCompleteDataArray.push(obj);
				});
			} else {
				locationsList.map((obj) => {
					selectedLocationsArray.push(obj.location_id);
					selectedLocationsCompleteDataArray.push(obj);
				});
			}
		} else {
			for (let i = 0; i < globalLocationId; i++) {
				$("#selectLocationPost" + i).prop("checked", false);
			}
			setSelectedLocationsArray([]);
			setSelectedLocationsCompleteDataArray([]);
		}
	};

	const handleCreateQuestion = async (e) => {
		e.preventDefault();
		$(".loder_div").attr("hidden", false);
		if (!questionAdded) {
			alert("You haven't added any question.");
			$(".loder_div").attr("hidden", true);
			return;
		}
		if (selectedLocationsArray.length <= 0) {
			alert("You haven't selected any Locations.");
			$(".loder_div").attr("hidden", true);
			return;
		}

		for (const locationid of selectedLocationsArray) {
			try {
				const result = await backendApi({ functionName: "CreateQuestion", siteId: window.sessionStorage.getItem("switchingOrgId") === "" ? window.sessionStorage.getItem("organizationId") : window.sessionStorage.getItem("switchingOrgId"), question: { text: questionAdded }, locationid });
				console.log(result);
				if (result.success != "1") {
					alert("Something went wrong in adding the question");
					$(".loder_div").attr("hidden", true);
					return;
				}

				const postId = result?.data?.name?.split("/");
				if (postId[0] !== "locations" && postId[2] !== "questions") {
					alert("Something went wrong while adding the question.");
					$(".loder_div").attr("hidden", true);
					return;
				}
				const addAnswer = await backendApi({
					functionName: "CreateModifyAnswer",
					location_id: locationid,
					postId: postId.at(-1),
					answer: { answer: { text: answerAdded } },
					siteId: window.sessionStorage.getItem("switchingOrgId") === "" ? window.sessionStorage.getItem("organizationId") : window.sessionStorage.getItem("switchingOrgId"),
				});
				console.log(addAnswer);
			} catch (error) {
				$(".loder_div").attr("hidden", true);
				console.trace(error);
			}
		}

		alert("Question and Answer added Successfully");
		$(".loder_div").attr("hidden", true);
	};
	return (
		<>
			<div className="col-md-12 order-3">
				<div className="row row-search ">
					<div className="col-lg-4 col-md-4 col-sm-6">
						<select id="administrativeList" onChange={(e) => setFilter(e)} className="form-control">
							<option value="0">State</option>
							{administrativeAreaList.map((data, index) => (
								<option key={index} value={data}>
									{data}
								</option>
							))}
						</select>
					</div>

					<div className="col-lg-4 col-md-4 col-sm-6 ">
						<select id="localityFilter" onChange={(e) => setFilter(e)} className="form-control">
							<option value="0">City</option>
							{localityList.map((data, index) => (
								<option key={index} value={data}>
									{data}
								</option>
							))}
						</select>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-6 location_search">
						<input
							className="form-control"
							type="text"
							placeholder="Search Business "
							onChange={(e) => {
								if (e.target.value.length > 3) {
									const local = locationsList.filter((obj) => {
										let title = obj.title.toLocaleLowerCase();
										return title.includes(e.target.value.toLocaleLowerCase());
									});
									setLocationsList(local);
								} else {
									administrativeAreaFilterEnable === 1 ? setLocationsList(administrativeAreaFilterEnableArray) : setLocationsList(temporaryLocationsList);
								}
							}}
						/>
					</div>
				</div>
				<div className="subhead" style={{ fontSize: "16px" }}>
					Select Location(s) to post
				</div>
				<hr className="mt-0"></hr>
				<div className="row">
					<div className="col-md-12 col-lg-8 tableResponsive">
						<div className="table_leftOuterWidth">
							<table className="table">
								<thead>
									<tr>
										<th className="col-1">
											<input type="checkbox" name="" id="selectAllLocations" onClick={(e) => selectAllLocations(e)} />{" "}
										</th>
										<th className="col-2">Location(s)</th>
										<th className="col-4">Address</th>
										<th className="col-3">Locality</th>
										<th className="col-2">Area</th>
									</tr>
								</thead>

								<div className="table_Outerscroll">
									{locationsList.length > 0 &&
										locationsList.map((data, i) => (
											<tbody key={i} className="table_Outertbody">
												<tr>
													<td className="col-1">
														<input id={"selectLocationPost" + i} type="checkbox" name="" onClick={(e) => selectLocation(e, data, i)} />
													</td>
													<td className="col-2">{data.title}</td>
													<td className="col-4">{data.addressLines}</td>
													<td className="col-3">{data.locality}</td>
													<td className="col-2">{data.administrativeArea}</td>
												</tr>
											</tbody>
										))}
								</div>
							</table>
						</div>
					</div>
					<div className="col-md-12 col-lg-4 order-4 mt-lg-5">
						<form autoComplete="off" onSubmit={handleCreateQuestion}>
							<textarea className="form-control" required value={questionAdded} onChange={(e) => setQuestionAdded(e.target.value)} placeholder="Add the question here" style={{ height: "100px !important" }} />
							<textarea className="form-control" required value={answerAdded} onChange={(e) => setAnswerAdded(e.target.value)} placeholder="Add the answer here" style={{ height: "100px !important" }} />
							<div className="button-wrapper mt-0 mb-4">
								<button className="btn-submit ml-3" type="submit">
									Add Q and A
								</button>
								<button
									className="btn-cancel ml-3"
									onClick={() => {
										setQuestionAdded("");
										navigate("/questionAnswer");
									}}>
									Cancel
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}

export default AddSingleQuestion;
