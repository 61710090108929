import React, { useEffect, useState } from "react";
import LeftMenu from "../layout/leftMenu";
import Lock from "../images/left_icons/lock2.png";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { GoPencil } from "react-icons/go";
import { backendApi } from "../apiService";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
function ApprovedContent() {
  const [requestedStatus, setRequestedStatus] = useState({
    approved: [],
    rejected: []
  });
  const [remark, setRemark] = useState("")
  const [editingIndex, setEditingIndex] = useState(-1);
  useEffect(() => {
    getRequestedData()
  }, [])
  window.$(document).ready(function () {
    window.$(".popup-image").magnificPopup({
      delegate: "a",
      type: "image",
      tLoading: "Loading image #%curr%...",
      mainClass: "mfp-img-mobile",
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
      },
      image: {
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        titleSrc: function (item) {
          return item.el.attr("title") + "<small></small>";
        },
      },
    });
    window.$(".popup-video").magnificPopup({
      delegate: "a",
      type: "iframe",
      tLoading: "Loading...",
    });
  });
  const getRequestedData = () => {
    const postData =
    {
      "functionName": "content_request_Listing",
      "site_id":
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      // "sec_user_id":
      //   window.sessionStorage.getItem("switchUserId") === ""
      //     ? window.sessionStorage.getItem("userId")
      //     : window.sessionStorage.getItem("switchUserId"),
      "category_type": "Text",
      "req_status": ""
    };
    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          const newWaiting = [];
          const newApproved = [];
          const newRejected = [];
          data?.dataList?.forEach((item) => {
            if (item.req_status === "Waiting") {
              newWaiting.push(item);
            } else if (item.req_status === "Approved") {
              newApproved.push(item);
            } else if (item.req_status === "Rejected") {
              newRejected.push(item);
            }
          });
          setRequestedStatus({
            waiting: newWaiting,
            approved: newApproved,
            rejected: newRejected
          });
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };
  const deleteRequest = (data) => {
    const postData = {
      "functionName": "content_request_delete",
      "content_generation_request_id": data.id,
      "sec_user_id": window.sessionStorage.getItem("switchUserId") === ""
        ? window.sessionStorage.getItem("userId")
        : window.sessionStorage.getItem("switchUserId"),
    }
    backendApi(postData).then((response) => {
      if (response?.success === "1") {
        getRequestedData()
      } else {
        console.log("Error");
      }
    });
  };
  const handleRemarkNameChange = (event) => {
    setRemark(event.target.value);
  };
  const handleEditClick = (index, remarks) => {
    setEditingIndex(index);
    setRemark(remarks)
  };
  const handleCancelClick = () => {
    setEditingIndex(-1);
  };
  const handleUpdateClick = (i, data) => {
    if(!remark){alert("Please Fill Remarks First")
  return
  }
    const postData = {
      "functionName": "content_request_update_remarks",
      "content_generation_request_id": data.id,
      "remarks": remark,
      "remarks_updated_by": window.sessionStorage.getItem("switchUserId") === ""
        ? window.sessionStorage.getItem("userId")
        : window.sessionStorage.getItem("switchUserId"),
    }
    backendApi(postData).then((response) => {
      if (response?.success === "1") {
        setEditingIndex(-1)
        getRequestedData()
      } else {
        setEditingIndex(-1)
        console.log("Error");
      }
    });
  };
  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Approved Content" headerImage={Lock} />
        <div className="col-md-12">
          <Tabs>
          <TabList  onClick={(e)=>setEditingIndex(-1)}>
              <Tab>Approved</Tab>
              <Tab>Requested</Tab>
              <Tab>Rejected</Tab>
            </TabList>
            <TabPanel>
              <div className="col-md-12 tableResponsive tagImageEditrListTble">
                <div className="tableOuterWidth table_width_md">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-4">Image </th>
                        <th className="col-4">Remark</th>
                        <th className="col-4 ">Action</th>
                      </tr>
                    </thead>
                    <div className="table_Outerscroll">
                      {requestedStatus?.approved?.map((data, i) => (
                        <tbody className="table_Outertbody pb-3">

                          <tr>
                            {data.category_type === "Image" ? (
                              <td className="col-4">
                                <div className="popup-image">
                                  <div className="popupGallerysection">
                                    <a
                                      // href={/* Specify the correct href if necessary */}
                                      title="img"
                                      target="_blank"
                                    >
                                      <img src={data.content_image} className="img-fluid" />
                                    </a>
                                  </div>
                                </div>
                              </td>
                            ) : (
                              <td className="col-4">{data.content_text}</td>
                            )}
                            <td className="col-2">
                              {editingIndex === i ? (
                                <input
                                  type="text"
                                  value={
                                    remark
                                  }
                                  onChange={handleRemarkNameChange}
                                  className="w-100"
                                />
                              ) : (
                                <span>{data.remarks}</span>
                              )}
                              {editingIndex !== i ? (
                                <GoPencil
                                  onClick={() =>
                                    handleEditClick(
                                      i,
                                      data.remarks,
                                    )
                                  }
                                />
                              ) : (
                                ""
                              )}
                              {editingIndex === i && (
                                <div className="ml-4">
                                  <button
                                    onClick={() =>
                                      handleUpdateClick(i, data)
                                    }
                                    className="btn btn-success"
                                  >
                                    Save
                                  </button>
                                  <button
                                    onClick={handleCancelClick}
                                    className="btn btn-secondary ml-1"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              )}
                            </td>
                            {/* <td className="col-4">
                              {data.remarks}
                            </td> */}
                            <td className="col-4">

                              <button type="submit" className="btn btn-danger" onClick={(e) => deleteRequest(data)}>Delete</button>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </div>
                  </table>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="col-md-12 tableResponsive tagImageEditrListTble">
                <div className="tableOuterWidth table_width_md">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-4">Image </th>
                        <th className="col-4">Remark</th>
                        <th className="col-4 ">Action</th>
                      </tr>
                    </thead>
                    {/* <div className="table_Outerscroll"> */}
                    {requestedStatus?.waiting?.map((data, i) => (
                      <tbody className="table_Outertbody pb-3">
                        <tr>
                          {data.category_type === "Image" ? (
                            <td className="col-4">
                              <div className="popup-image">
                                <div className="popupGallerysection">
                                  <a
                                    // href={/* Specify the correct href if necessary */}
                                    title="img"
                                    target="_blank"
                                  >
                                    <img src={data.content_image} className="img-fluid" />
                                  </a>
                                </div>
                              </div>
                            </td>
                          ) : (
                            <td className="col-4">{data.content_text}</td>
                          )}

                          <td className="col-2">
                            {editingIndex === i ? (
                              <input
                                type="text"
                                value={
                                  remark
                                }
                                onChange={handleRemarkNameChange}
                                className="w-100"
                              />
                            ) : (
                              <span>{data.remarks}</span>
                            )}
                            {editingIndex !== i ? (
                              <GoPencil
                                onClick={() =>
                                  handleEditClick(
                                    i,
                                    data.remarks,
                                  )
                                }
                              />
                            ) : (
                              ""
                            )}
                            {editingIndex === i && (
                              <div className="ml-4">
                                <button
                                  onClick={() =>
                                    handleUpdateClick(i, data)
                                  }
                                  className="btn btn-success"
                                >
                                  Save
                                </button>
                                <button
                                  onClick={handleCancelClick}
                                  className="btn btn-secondary ml-1"
                                >
                                  Cancel
                                </button>
                              </div>
                            )}
                          </td>
                          <td className="col-4">
                            {/* <button type="submit" className="btn btn-success mr-2">Send for Approval</button> */}
                            <button type="submit" className="btn btn-danger" onClick={(e) => deleteRequest(data)}>Delete</button>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                    {/* </div> */}
                  </table>

                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="col-md-12 tableResponsive tagImageEditrListTble">
                <div className="tableOuterWidth table_width_md">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-4">Image </th>
                        <th className="col-4">Remark</th>
                        <th className="col-4 ">Action</th>
                      </tr>
                    </thead>
                    <div className="table_Outerscroll">
                      {requestedStatus?.rejected?.map((data, i) => (
                        <tbody className="table_Outertbody pb-3">

                          <tr>
                            {data.category_type === "Image" ? (
                              <td className="col-4">
                                <div className="popup-image">
                                  <div className="popupGallerysection">
                                    <a
                                      // href={/* Specify the correct href if necessary */}
                                      title="img"
                                      target="_blank"
                                    >
                                      <img src={data.content_image} className="img-fluid" />
                                    </a>
                                  </div>
                                </div>
                              </td>
                            ) : (
                              <td className="col-4">{data.content_text}</td>
                            )}
                            <td className="col-2">
                              {editingIndex === i ? (
                                <input
                                  type="text"
                                  value={
                                    remark
                                  }
                                  onChange={handleRemarkNameChange}
                                  className="w-100"
                                />
                              ) : (
                                <span>{data.remarks}</span>
                              )}
                              {editingIndex !== i ? (
                                <GoPencil
                                  onClick={() =>
                                    handleEditClick(
                                      i,
                                      data.remarks,
                                    )
                                  }
                                />
                              ) : (
                                ""
                              )}
                              {editingIndex === i && (
                                <div className="ml-4">
                                  <button
                                    onClick={() =>
                                      handleUpdateClick(i, data)
                                    }
                                    className="btn btn-success"
                                  >
                                    Save
                                  </button>
                                  <button
                                    onClick={handleCancelClick}
                                    className="btn btn-secondary ml-1"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              )}
                            </td>
                            {/* <td className="col-4">
                              {data.remarks}
                            </td> */}
                            <td className="col-4">
                              <button type="submit" className="btn btn-danger" onClick={(e) => deleteRequest(data)}>Delete</button>
                            </td>
                          </tr>
                          <tr>
                            <td className="col-4">
                              <div className="popup-image">
                                <div className="popupGallerysection">
                                  <a
                                    // href={Img2}
                                    title="img"
                                    target="_blank"
                                  >
                                    {/* <img src={Img2} className="img-fluid" /> */}
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td className="col-4">
                              Rejected
                            </td>
                            <td className="col-4">
                              <button type="submit" className="btn btn-danger" onClick={(e) => deleteRequest(data)}>Delete</button>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </div>
                  </table>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
        <div className="loder_div" hidden />
        <Footer />
      </div>
    </div>
  );
}

export default ApprovedContent;
