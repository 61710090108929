import React, { useState, useEffect } from "react";
import SaveAndValidation from "./settingsSaveAndValidation"
function SettingsTumblrCredentials(props) {
  var orgadmin = window.sessionStorage.getItem("role") == "ORG_ADMIN";
  const [tumblr_consumer_key, settumblr_consumer_key] = useState((props.listingValuesForPassing.tumblr_consumer_key != "null") ? props.listingValuesForPassing.tumblr_consumer_key : "");
  const [tumblr_consumer_secret, settumblr_consumer_secret] = useState((props.listingValuesForPassing.tumblr_consumer_secret != "null") ? props.listingValuesForPassing.tumblr_consumer_secret : "");
  const [tumblr_tokenKey, settumblr_tokenKey] = useState((props.listingValuesForPassing.tumblr_tokenKey != "null") ? props.listingValuesForPassing.tumblr_tokenKey : "");
  const [tumblr_tokenSecret, settumblr_tokenSecret] = useState((props.listingValuesForPassing.tumblr_tokenSecret != "null") ? props.listingValuesForPassing.tumblr_tokenSecret : "");

  useEffect(() => {
    if (props.listingValuesForPassing.tumblr_tokenSecret && (props.listingValuesForPassing.tumblr_tokenSecret != "null" || props.listingValuesForPassing.tumblr_tokenSecret != "undefined")) {
      settumblr_tokenSecret((props.listingValuesForPassing.tumblr_tokenSecret))
    } else { settumblr_tokenSecret("") }
    if (props.listingValuesForPassing.tumblr_consumer_secret && (props.listingValuesForPassing.tumblr_consumer_secret != "null" || props.listingValuesForPassing.tumblr_consumer_secret != "undefined")) {
      settumblr_consumer_secret((props.listingValuesForPassing.tumblr_consumer_secret))
    } else { settumblr_consumer_secret("") }
    if (props.listingValuesForPassing.tumblr_tokenKey && (props.listingValuesForPassing.tumblr_tokenKey != "null" || props.listingValuesForPassing.tumblr_tokenKey != "undefined")) {
      settumblr_tokenKey((props.listingValuesForPassing.tumblr_tokenKey))
    } else { settumblr_tokenKey("") }
    if (props.listingValuesForPassing.tumblr_consumer_key && (props.listingValuesForPassing.tumblr_consumer_key != "null" || props.listingValuesForPassing.tumblr_consumer_key != "undefined")) {
      settumblr_consumer_key((props.listingValuesForPassing.tumblr_consumer_key))
    } else { settumblr_consumer_key("") }
  }, [props.listingValuesForPassing.tumblr_tokenSecret, props.listingValuesForPassing.tumblr_tokenKey, props.listingValuesForPassing.tumblr_consumer_secret, props.listingValuesForPassing.tumblr_consumer_key,
  ])


  return (
     
      <div className="row">
        <div className="col-md-12">
      <div className="user_formdiv">
        <div className="userform_head">Tumblr Credentials</div>
        <div className="row userrow_form">
          <div className="col-md-4">
            <label className="col-form-label">
              Tumblr ConsumerKey
            </label>
          </div>
          <div class="col-md-1"><span>:</span></div>
          <div className="col-md-7">
            <input
              type="text"
              id="TumblrConsumerKey"
              onChange={(e) => {
                settumblr_consumer_key(e.target.value);
              }}
              className="form-control"
              value={tumblr_consumer_key}
              placeholder="Tumblr ConsumerKey"
            />
          </div>
        </div>
        <div className="row userrow_form">
          <div className="col-md-4">
            <label className="col-form-label">
              Tumblr ConsumerSecret
            </label>
          </div>
          <div class="col-md-1"><span>:</span></div>
          <div className="col-md-7">
            <input
              type="text"
              id="TumblrConsumerSecret"
              onChange={(e) => {
                settumblr_consumer_secret(e.target.value);
              }}
              value={tumblr_consumer_secret}
              className="form-control"
              placeholder="Tumblr ConsumerSecret"
            />
          </div>
        </div>{" "}
        <div className="row userrow_form">
          <div className="col-md-4">
            <label className="col-form-label">
              Tumblr TokenKey
            </label>
          </div>
          <div class="col-md-1"><span>:</span></div>
          <div className="col-md-7">
            <input
              type="text"
              id="TumblrTokenKey"
              value={tumblr_tokenKey}
              onChange={(e) => {
                settumblr_tokenKey(e.target.value);
              }}
              className="form-control"
              placeholder="Tumblr TokenKey"
            />
          </div>
        </div>{" "}
        <div className="row userrow_form">
          <div className="col-md-4">
            <label className="col-form-label">
              Tumblr TokenSecret
            </label>
          </div>
          <div class="col-md-1"><span>:</span></div>
          <div className="col-md-7">
            <input
              type="text"
              id="TumblrTokenSecret"
              value={tumblr_tokenSecret}
              onChange={(e) => {
                settumblr_tokenSecret(e.target.value);
              }}
              className="form-control"
              placeholder="Tumblr TokenSecret"
            />
          </div>
        </div>
        <SaveAndValidation
           getUserData={() => props.getUserData()}
          valueForSaved={[tumblr_consumer_key, tumblr_consumer_secret, tumblr_tokenKey, tumblr_tokenSecret]}
          saveMode={"trumblerCredential"}
          organizationId={props.organizationId} />
      </div>
    </div>
      </div>
     
  );

}
export default SettingsTumblrCredentials;
