import React, { Component } from "react";
import DatePicker from "react-datepicker";
import $ from "jquery";
import moment from "moment";
import * as Icon from "react-feather";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import LeftMenu from "../layout/leftMenu";
import { TagsInput } from "react-tag-input-component";
import Header from "../layout/header";
import QandA from "../images/left_icons/qa.png";
import { backendApi, pythonBackendApi } from "../apiService";
var XLSX = require("xlsx");

let constFlag = 0;
class QuestionAnswer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      allLocationData: [],
      allAssertiveLocationData: [],
      administrativeAreaList: [],
      administrativeArea: "0",
      localityList: [],
      locality: "0",
      locationList: [],
      filteredLocationList: [],
      location: "0",
      startDate: "",
      endDate: "",
      questionAndAnswers: [],
      unfilteredQuestionAndAnswers: [],
      repliedByOwner: "",
      modalInputPlaceholder: "",
      modalButtonPlaceholder: "",
      modalOnClickFunction: "",
      deleteModalOnClickFunction: "",
      editModalIsOpen: false,
      deleteModalIsOpen: false,
      deletedQuestion: { locationid: "", questionId: "" },
      updatedAnswer: { locationid: "", questionId: "", text: "" },
      updatedQuestion: { locationid: "", questionId: "", text: "" },
      googleAccountList: [],
      currentQandAList: [],
      numberOfpages: 0,
      statesList: [],
      businessNameFilter: "",
      cityList: [],
      searchAddress: "",
      globalFilterLocationsList: [],
      tempSearchLocationsList: [],
      locationIdsList: [],
      currentPage: 1,
      offset: "",
      totalCount: 0,
      keyword: [],
      keywordLength: 0,
      response: "Waiting for Data...",
      allLocationData: [],
    };
    this.setSelected = this.setSelected.bind(this);
  }
  componentDidMount() {
    this.getAdministrativeArea();
    this.getAllLocations();
    // this.firstInitialFunction();
    // this.getNewLocationData();
    this.getQuestionAndAnswerList();
    this.pythonUpdateNewDate();
  }
  getAllLocations() {
    const params = {
      functionName: "getLocationSearch",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea: "",
      locality: "",
    };
    backendApi(params).then((data) => {
      this.setState(
        {
          allLocationData: data.data,
        },
        () => {
          console.log(
            "🚀 ~ file: QuestionAnswer.jsx:86 ~ QuestionAnswer ~ backendApi ~ allLocationData:",
            this.state.allLocationData
          );
        }
      );
    });
  }
  getQuestionAndAnswerList() {
    $(".loder_div").attr("hidden", false);
    var apiReq = {
      functionName: "questionAndAnswerList",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      adminArea: "",
      locality: "",
      addressLine: "",
      fromDate:
        this.state.startDate != ""
          ? moment(this.state.startDate).format("yyyy-MM-DD")
          : "",
      toDate:
        this.state.endDate != ""
          ? moment(this.state.endDate).format("yyyy-MM-DD")
          : "",
      keyword:
        this.state.keyword.length > 0 ? this.state.keyword.join(",") : "",
      offset: this.state.offset,
      location_id:
        this.state.locationIdsList.length > 0
          ? this.state.locationIdsList.join(",")
          : "",
    };
    this.qAndAMigrateByLocation();
    backendApi(apiReq).then((data) => {
      $(".loder_div").attr("hidden", true);
      let filteredArray = [];
      if (this.state.repliedByOwner === "yes") {
        console.log(`repliedByOwner ${this.state.repliedByOwner}`);
        filteredArray = data.data.filter((questions) =>
          questions?.answers?.some(
            (eachAnswer) => eachAnswer.author_type == "MERCHANT"
          )
        );
        this.setState({
          questionAndAnswers: filteredArray,
          response: filteredArray.length > 0 ? "" : "No Data available",
          totalCount: filteredArray.length,
        });
      } else if (this.state.repliedByOwner === "no") {
        console.log(`repliedByOwner ${this.state.repliedByOwner}`);
        filteredArray = data.data.filter((questions) =>
          questions?.answers?.some(
            (eachAnswer) => eachAnswer?.author_type !== "MERCHANT"
          )
        );
        this.setState({
          questionAndAnswers: filteredArray,
          response: filteredArray.length > 0 ? "" : "No Data available",
          totalCount: filteredArray.length,
        });
      } else {
        console.log(`repliedByOwner ${this.state.repliedByOwner}`);
        this.setState({
          questionAndAnswers: data.data,
          response: data.data.length > 0 ? "" : "No Data available",
          totalCount: data.totalCnt.count,
        });
      }
    });
  }
  getNewLocationData() {
    var apiJson = {
      functionName: "getMasterDataForFilter",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apiJson).then((data) => {
      this.setState({
        allLocationData: data.data,
        allAssertiveLocationData: data.data,
        globalFilterLocationsList: data.data,
        tempSearchLocationsList: data.data,
        // businessNameFilter: `${data.data[0].storeCode}- ${data.data[0].title}, ${data.data[0].addressLines}, ${data.data[0].locality}, ${data.data[0].AdministrativeArea}`,
        administrativeArea: "0",
        // administrativeAreaList: new Array(
        //   ...new Set(
        //     data.map((eachLocation) => eachLocation.administrativeArea)
        //   )
        // ),
        // localityList: new Array(
        //   ...new Set(data.map((eachLocation) => eachLocation?.locality))
        // ),
        // locationList: data.map((eachLocation) => ({
        //   locationId: eachLocation.location_id,
        //   location: `${eachLocation?.title}, ${eachLocation?.addressLines}, ${eachLocation.locality}`,
        // })),
        // filteredLocationList: data.map((eachLocation) => ({
        //   locationId: eachLocation.location_id,
        //   location: `${eachLocation?.title}(${eachLocation?.addressLines})`,
        // })),
      });
      this.getQuestionsData(data.data);
      // this.handleChangeState(
      //   data.data[0].AdministrativeArea,
      //   data.data[0].locality,
      //   1
      // );
    });
  }
  clearData(e) {
    e.preventDefault();
    console.log("startDAte", this.state.startDate);
    this.setState(
      {
        locationIdsList: [],
        administrativeArea: "0",
        locality: "0",
        businessNameFilter: "",
        startDate: "",
        endDate: "",
        repliedByOwner: "",
        keyword: [],
      },
      () => this.getQuestionAndAnswerList()
    );
  }
  pythonUpdateNewDate() {
    let date = new Date();
    let siteId =
      window.sessionStorage.getItem("switchingOrgId") === ""
        ? window.sessionStorage.getItem("organizationId")
        : window.sessionStorage.getItem("switchingOrgId");

    console.log(
      "lastDate",
      window.sessionStorage?.getItem("Q&AUpdateTime" + siteId)
    );
    console.log("date", date);
    const apiReq = {
      method: "POST",
      body: JSON.stringify({
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
      }),
    };
    let apiParameters = {
      functionName: "dataMigrationReviewAndQA",
      function_name: "QaaMigrationBySiteID",
      module_name: "QandA Migration by Site",
      params: { siteId: "58", locationarray: "[{53904911361864879}]" },
    };
    if (window.sessionStorage?.getItem("Q&AUpdateTime" + siteId)) {
      let lastDate = new Date(
        window.sessionStorage?.getItem("Q&AUpdateTime" + siteId)
      );
      const differenceInMinutes = Math.floor((date - lastDate) / 60000);
      if (differenceInMinutes >= 30) {
        window.sessionStorage.setItem("Q&AUpdateTime" + siteId, date);
        backendApi(apiParameters).then((data) => {
          console.log("data", data);
        });
        // pythonBackendApi("QaaMigrationBySiteID", apiReq).then((data) => {
        //   console.log("data", data);
        // });
      }
    } else {
      window.sessionStorage.setItem("Q&AUpdateTime" + siteId, date);
      backendApi(apiParameters).then((data) => {
        console.log("data", data);
      });
      // pythonBackendApi("QaaMigrationBySiteID", apiReq).then((data) => {
      //   console.log("data", data);
      // });
    }
  }
  getQuestionsData(locationData) {
    const questionList = [];

    let indexVal = locationData.length;
    for (const [index, location] of locationData.entries()) {
      if (index < 10 && constFlag == 0) {
        indexVal = 10;
      }

      //alert(index+'-'+indexVal)
      if (index < indexVal) {
        // alert('ok')
        const apiReq = {
          functionName: "GetQuestions",
          siteId: this.state.siteId,
          locationid: location.location_id,
        };
        backendApi(apiReq).then((questionsResult) => {
          let locality = location.locality;
          let title = location.title;
          let addressLines = location.addressLines;

          if (questionsResult.success === "1") {
            //   const data  = result;
            if (questionsResult.data.questions) {
              // const { questions } = data;
              const queslist = questionsResult.data.questions.map((data) => ({
                ...data,
                locality,
                title: `${title}, ${addressLines}`,
              }));
              questionList.push(...queslist);
            }
          }
          console.log(questionList);

          this.setState(
            {
              questionAndAnswers: questionList,
              unfilteredQuestionAndAnswers: questionList,
            },
            () => {
              let questions = this.state.unfilteredQuestionAndAnswers;
              if (this.state.startDate) {
                questions = questions.filter(
                  (data) =>
                    new Date(data.createTime).getTime() >=
                    new Date(this.state.startDate).getTime()
                );
              }
              if (this.state.endDate) {
                questions = questions.filter(
                  (data) =>
                    new Date(data.createTime).getTime() <=
                    new Date(this.state.endDate).getTime()
                );
              }
              if (this.state.repliedByOwner === "yes") {
                console.log(questions);
                questions = questions.filter((eachQuestion) => {
                  if (eachQuestion.topAnswers) {
                    for (const eachAnswer of eachQuestion.topAnswers) {
                      if (
                        eachAnswer?.author?.type === "MERCHANT" &&
                        this.state.googleAccountList.includes(
                          eachAnswer?.author?.displayName
                        )
                      ) {
                        return true;
                      }
                    }
                  }
                });
              }
              if (this.state.repliedByOwner === "no") {
                questions = questions.filter((eachQuestion) => {
                  if (eachQuestion.topAnswers) {
                    for (const eachAnswer of eachQuestion.topAnswers) {
                      if (
                        eachAnswer?.author?.type !== "MERCHANT" ||
                        !this.state.googleAccountList.includes(
                          eachAnswer?.author?.displayName
                        )
                      ) {
                        return true;
                      }
                    }
                  }
                });
              }
              this.setState({
                questionAndAnswers: questions,
              });
            }
          );
        });
      } else {
        return false;
        break;
      }
    }
  }
  getSingleQuestionsData(location) {
    const questionList = [];
    const apiReq = {
      functionName: "GetQuestions",
      siteId: this.state.siteId,
      locationid: location.location_id,
    };
    backendApi(apiReq).then((questionsResult) => {
      let locality = location.locality;
      let title = location.title;
      let addressLines = location.addressLines;

      if (questionsResult.success === "1") {
        //   const data  = result;
        if (questionsResult.data.questions) {
          // const { questions } = data;
          const queslist = questionsResult.data.questions.map((data) => ({
            ...data,
            locality,
            title: `${title}, ${addressLines}`,
          }));
          questionList.push(...queslist);
        }
      }
      console.log(questionList);

      this.setState(
        {
          questionAndAnswers: questionList,
          unfilteredQuestionAndAnswers: questionList,
        },
        () => {
          let questions = this.state.unfilteredQuestionAndAnswers;
          if (this.state.startDate) {
            questions = questions.filter(
              (data) =>
                new Date(data.createTime).getTime() >=
                new Date(this.state.startDate).getTime()
            );
          }
          if (this.state.endDate) {
            questions = questions.filter(
              (data) =>
                new Date(data.createTime).getTime() <=
                new Date(this.state.endDate).getTime()
            );
          }
          if (this.state.repliedByOwner === "yes") {
            console.log(questions);
            questions = questions.filter((eachQuestion) => {
              if (eachQuestion.topAnswers) {
                for (const eachAnswer of eachQuestion.topAnswers) {
                  if (
                    eachAnswer?.author?.type === "MERCHANT" &&
                    this.state.googleAccountList.includes(
                      eachAnswer?.author?.displayName
                    )
                  ) {
                    return true;
                  }
                }
              }
            });
          }
          if (this.state.repliedByOwner === "no") {
            questions = questions.filter((eachQuestion) => {
              if (eachQuestion.topAnswers) {
                for (const eachAnswer of eachQuestion.topAnswers) {
                  if (
                    eachAnswer?.author?.type !== "MERCHANT" ||
                    !this.state.googleAccountList.includes(
                      eachAnswer?.author?.displayName
                    )
                  ) {
                    return true;
                  }
                }
              }
            });
          }
          this.setState({
            questionAndAnswers: questions,
          });
        }
      );
    });
  }
  firstInitialFunction() {
    $(".loder_div").attr("hidden", false);
    $("#headerName").text("Q & A");

    Modal.setAppElement("body");
    backendApi({
      functionName: "getAccountList",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      role: window.sessionStorage.getItem("role"),
    })
      .then(({ data, success }) => {
        if (success != "1") {
          return;
        }
        if (data.length > 0) {
          data = data.map((_) => _.accountName);
        }
        this.setState({ googleAccountList: data });
        return backendApi({
          functionName: "getMasterDataForFilter",
          siteId:
            window.sessionStorage.getItem("switchingOrgId") === ""
              ? window.sessionStorage.getItem("organizationId")
              : window.sessionStorage.getItem("switchingOrgId"),
        });
      })
      .then(async (result) => {
        if (!result.data || result.success != "1") {
          alert("Location are not available under this organization.");
          $(".loder_div").attr("hidden", true);
          return;
        }
        $(".loder_div").attr("hidden", false);
        const { data } = result;
        console.log(
          "🚀 ~ file: QuestionAnswer.jsx:73 ~ QuestionAnswer ~ .then ~ result",
          data
        );

        // TODO: May need location filter with country. currently only running for India.
        this.setState({
          allLocationData: data,
          allAssertiveLocationData: data,
          administrativeAreaList: new Array(
            ...new Set(
              data.map((eachLocation) => eachLocation.administrativeArea)
            )
          ),
          localityList: new Array(
            ...new Set(data.map((eachLocation) => eachLocation?.locality))
          ),
          locationList: data.map((eachLocation) => ({
            locationId: eachLocation.location_id,
            location: `${eachLocation?.title}, ${eachLocation?.addressLines}, ${eachLocation.locality}`,
          })),
          filteredLocationList: data.map((eachLocation) => ({
            locationId: eachLocation.location_id,
            location: `${eachLocation?.title}(${eachLocation?.addressLines})`,
          })),
          administrativeArea: "0",
        });
        const questionList = [];
        if (data.length <= 0) {
          $(".loder_div").attr("hidden", true);
          return;
        }
        const interval = 5;
        if (data.length > interval) {
          const locationData = data.reduce(function (r, a, i) {
            const slot = Math.floor((i - 1) / interval);
            (r[slot] = r[slot] || []).push(a);
            return r;
          }, []);
          console.log(locationData);
          this.setState({ currentQandAList: locationData });
        }
        // for (const { location_id, addressLines, locality, title } of data) {
        // 	const result = await backendApi({ functionName: "GetQuestions", siteId: this.state.siteId, locationid: location_id });
        // 	if (result.success === "1") {
        // 		const { data } = result;
        // 		if (data.questions) {
        // 			const { questions } = data;
        // 			const queslist = questions.map((data) => ({ ...data, locality, title: `${title}, ${addressLines}` }));
        // 			questionList.push(...queslist);
        // 		}
        // 	}
        // }
        const questionsResult = await backendApi({
          functionName: "GetQuestions",
          siteId: this.state.siteId,
          locationid: data[0].location_id,
        });
        let locality = data[0].locality;
        let title = data[0].title;
        let addressLines = data[0].addressLines;

        if (questionsResult.success === "1") {
          //   const data  = result;
          if (questionsResult.data.questions) {
            // const { questions } = data;
            const queslist = questionsResult.data.questions.map((data) => ({
              ...data,
              locality,
              title: `${title}, ${addressLines}`,
            }));
            questionList.push(...queslist);
          }
        }
        console.log(questionList);
        this.setState({
          questionAndAnswers: questionList,
          unfilteredQuestionAndAnswers: questionList,
        });
        $(".loder_div").attr("hidden", true);
      })
      .catch((error) => {
        console.trace(error);
        alert("Something went wrong in getting Location Data");
        $(".loder_div").attr("hidden", true);
      });
  }

  handleChangeStart(value) {
    this.setState({ startDate: value });
  }

  handleChangeEnd(values) {
    this.setState({ endDate: values });
  }
  onStateSelect(administrativeArea) {
    this.setState({
      administrativeArea,
      localityList: new Array(
        ...new Set(
          this.state.allLocationData
            .filter(
              (eachLocation) =>
                eachLocation?.administrativeArea === administrativeArea
            )
            .map((eachLocation) => eachLocation?.locality)
        )
      ),
      filteredLocationList: this.state.allLocationData
        .filter(
          (eachLocation) =>
            eachLocation?.administrativeArea === administrativeArea
        )
        .map((eachLocation) => ({
          locationId: eachLocation.location_id,
          location: eachLocation?.title,
        })),
      locality: "0",
      location: "0",
    });
  }
  onCitySelect(locality) {
    console.log(
      "🚀 ~ file: QuestionAnswer.jsx:134 ~ QuestionAnswer ~ onCitySelect ~ onCitySelect",
      this.state.allAssertiveLocationData
        .filter((eachLocation) => eachLocation?.locality === locality)
        .map((eachLocation) => ({
          locationId: eachLocation.name,
          location: eachLocation?.title,
        }))
    );
    this.setState({
      locality,
      filteredLocationList: this.state.allAssertiveLocationData
        .filter((eachLocation) => eachLocation?.locality === locality)
        .map((eachLocation) => ({
          locationId: eachLocation.name,
          location: eachLocation?.title,
        })),
      location: "0",
    });
  }
  onLocationSelect(location) {
    console.log(
      "🚀 ~ file: QuestionAnswer.jsx:134 ~ QuestionAnswer ~ onLocationSelect ~ location",
      location
    );

    this.setState(
      {
        location: location,
        searchAddress: location.addressLines,
        locationIdsList: [location.location_id],
        businessNameFilter: `${location.storeCode}- ${location.title}, ${location.addressLines}, ${location.locality}, ${location.AdministrativeArea}`,
      },
      () => {
        // this.getQuestionAndAnswerList();
      }
    );
  }

  onSubmitFilter(e) {
    e && e.preventDefault();
    console.log(
      "🚀 ~ file: QuestionAnswer.jsx:142 ~ QuestionAnswer ~ onSubmitFilter ~ SUBMIT_FILTER",
      this.state.questionAndAnswers
    );
    console.log(
      "🚀 ~ file: QuestionAnswer.jsx:142 ~ QuestionAnswer ~ onSubmitFilter ~ allLocationData",
      this.state.allLocationData
    );

    $(".loder_div").attr("hidden", false);

    if (this.state.location && this.state.location === "0") {
      let questions = this.state.unfilteredQuestionAndAnswers;
      // TODO: Need to make the questions into a function.
      if (this.state.startDate) {
        questions = questions.filter(
          (data) =>
            new Date(data.createTime).getTime() >=
            new Date(this.state.startDate).getTime()
        );
      }
      if (this.state.endDate) {
        questions = questions.filter(
          (data) =>
            new Date(data.createTime).getTime() <=
            new Date(this.state.endDate).getTime()
        );
      }
      if (this.state.administrativeArea != "0") {
        if (this.state.locality === "0") {
          const localityInAdministrativeArea =
            this.state.allAssertiveLocationData
              .filter(
                (eachLocation) =>
                  eachLocation?.administrativeArea ===
                  this.state.administrativeArea
              )
              .map((eachlocation) => eachlocation?.locality);
          questions = questions.filter((data) =>
            localityInAdministrativeArea.includes(data.locality)
          );
          console.log(questions);
        } else {
          questions = questions.filter(
            (data) => data.locality === this.state.locality
          );
        }
      }
      if (this.state.repliedByOwner === "yes") {
        console.log(questions);
        questions = questions.filter((eachQuestion) => {
          if (eachQuestion.topAnswers) {
            for (const eachAnswer of eachQuestion.topAnswers) {
              if (
                eachAnswer?.author?.type === "MERCHANT" &&
                this.state.googleAccountList.includes(
                  eachAnswer?.author?.displayName
                )
              ) {
                return true;
              }
            }
          }
        });
      }
      if (this.state.repliedByOwner === "no") {
        questions = questions.filter((eachQuestion) => {
          if (eachQuestion.topAnswers) {
            for (const eachAnswer of eachQuestion.topAnswers) {
              if (
                eachAnswer?.author?.type !== "MERCHANT" ||
                !this.state.googleAccountList.includes(
                  eachAnswer?.author?.displayName
                )
              ) {
                return true;
              }
            }
          }
        });
      }
      this.setState({ questionAndAnswers: questions });
      $(".loder_div").attr("hidden", true);
      return;
    }
    const { locationId } = this.state.location;

    console.log(locationId, this.state.siteId);

    backendApi({
      functionName: "GetQuestions",
      siteId: this.state.siteId,
      locationid: locationId,
    })
      .then((result) => {
        if (!result.data || result.success != "1" || !result.data.questions) {
          alert("Data not available.");
          this.setState({ questionAndAnswers: [] });
          $(".loder_div").attr("hidden", true);
          return;
        }
        let {
          data: { questions },
        } = result;

        let locationArray = this.state.filteredLocationList.filter((data) =>
          data.locationId.includes(locationId)
        );
        let location;
        if (locationArray?.length) {
          [location] = locationArray;
        }
        questions = questions.map((data) => ({
          ...data,
          title: location.location,
        }));
        console.log(questions);
        if (this.state.startDate) {
          questions = questions.filter(
            (data) =>
              new Date(data.createTime).getTime() >=
              new Date(this.state.startDate).getTime()
          );
        }
        if (this.state.endDate) {
          questions = questions.filter(
            (data) =>
              new Date(data.createTime).getTime() <=
              new Date(this.state.endDate).getTime()
          );
        }

        if (this.state.repliedByOwner === "yes") {
          questions = questions.filter((eachQuestion) => {
            if (eachQuestion.topAnswers) {
              for (const eachAnswer of eachQuestion.topAnswers) {
                if (
                  eachAnswer?.author?.type === "MERCHANT" &&
                  this.state.googleAccountList.includes(
                    eachAnswer?.author?.displayName
                  )
                ) {
                  return true;
                }
              }
            }
          });
        }
        if (this.state.repliedByOwner === "no") {
          questions = questions.filter((eachQuestion) => {
            if (eachQuestion.topAnswers) {
              for (const eachAnswer of eachQuestion.topAnswers) {
                if (
                  eachAnswer?.author?.type !== "MERCHANT" ||
                  !this.state.googleAccountList.includes(
                    eachAnswer?.author?.displayName
                  )
                ) {
                  return true;
                }
              }
            }
          });
        }
        $(".loder_div").attr("hidden", true);
        if (questions.length <= 0) {
          alert("No data to show.");
          return;
        }
        this.setState({ questionAndAnswers: questions });
      })
      .catch((error) => {
        console.trace(error);
        $(".loder_div").attr("hidden", true);
        alert("Something went wrong in getting Location Data");
      });
  }

  openEditModal = (pickedQuestion, questionOrAnswer) => {
    console.log("🚀 ~ file: QuestionAnswer.jsx:839 ~ QuestionAnswer ~ pickedQuestion:", pickedQuestion)
    $(".sticky__table__header").css("position", "static");
    const locationid = pickedQuestion?.location_id,
      questionId = pickedQuestion?.questionId;
    if (questionOrAnswer === "update_question") {
      this.setState({
        editModalIsOpen: true,
        modalButtonPlaceholder: "Submit",
        modalOnClickFunction: "update_question",
        modalInputPlaceholder: "Edit Question",
        updatedQuestion: { locationid, questionId, text: pickedQuestion.text },
      });
    } else if (questionOrAnswer === "add_answer") {
      this.setState({
        editModalIsOpen: true,
        modalButtonPlaceholder: "Add Answer",
        modalOnClickFunction: "add_answer",
        modalInputPlaceholder: "Add Answer",
        updatedAnswer: { locationid, questionId },
      });
    } else if (questionOrAnswer === "edit_answer") {
      console.log(
        "🚀 ~ file: QuestionAnswer.jsx:209 ~ QuestionAnswer ~ pickedQuestion",
        pickedQuestion
      );
      this.setState({
        editModalIsOpen: true,
        modalButtonPlaceholder: "Edit Answer",
        modalOnClickFunction: "edit_answer",
        modalInputPlaceholder: "Edit Answer",
        updatedAnswer: { locationid, questionId, text: pickedQuestion.text },
      });
    }
  };
  closeEditModal = () => {
    $(".sticky__table__header").css("position", "sticky");
    this.setState({ editModalIsOpen: false });
  };
  openDeleteModal = (pickedQuestion, questionOrAnswer) => {
    $(".sticky__table__header").css("position", "static");
    const [, locationid, , questionId] = pickedQuestion?.name.split("/");
    this.setState({
      deleteModalIsOpen: true,
      deletedQuestion: { locationid, questionId },
      deleteModalOnClickFunction: questionOrAnswer,
    });
  };
  closeDeleteModal = () => {
    $(".sticky__table__header").css("position", "sticky");
    this.setState({ deleteModalIsOpen: false });
  };

  updateQuestion = () => {
    $(".loder_div").attr("hidden", false);
    console.log(
      "🚀 ~ file: QuestionAnswer.jsx:238 ~ QuestionAnswer ~ this.state.updatedQuestion, this.state.location",
      this.state.updatedQuestion,
      this.state.location
    );
    const {
      updatedQuestion: { locationid, questionId, text },
      siteId,
    } = this.state;

    backendApi({
      functionName: "UpdateQuestion",
      siteId,
      locationid,
      questionId,
      updatedQuestion: { text },
    }).then((result) => {
      $(".loder_div").attr("hidden", true);
      if (result.success != "1") {
        return;
      }
      alert("Question Updated Successfully");
      this.closeEditModal();
      setTimeout(() => {
        $(".loder_div").attr("hidden", false);
        // this.onSubmitFilter();
      }, 3000);
    });
  };

  updateAnswer = () => {
    $(".loder_div").attr("hidden", false);
    const {
      updatedAnswer: { locationid, questionId, text },
      siteId,
    } = this.state;
    console.log({
      functionName: "CreateModifyAnswer",
      siteId,
      location_id: locationid,
      postId: questionId,
      answer: { answer: { text } },
    });
    backendApi({
      functionName: "CreateModifyAnswer",
      siteId,
      location_id: locationid,
      postId: questionId,
      answer: { answer: { text } },
    }).then((result) => {
      $(".loder_div").attr("hidden", true);
      if (result.success != "1") {
        return;
      }
      alert("Answer Updated Successfully");
      this.closeEditModal();
      setTimeout(() => {
        $(".loder_div").attr("hidden", false);
        this.onSubmitFilter();
      }, 3000);
    });
  };

  updateQuestionOrAnswer = (e, questionAndAnswer) => {
    e.preventDefault();
    if (questionAndAnswer === "update_question") {
      this.updateQuestion();
    } else {
      this.updateAnswer();
    }
  };
  deleteAnswer = () => {
    $(".loder_div").attr("hidden", false);
    const {
      deletedQuestion: { locationid, questionId },
      siteId,
    } = this.state;
    backendApi({
      functionName: "DeleteAnswer",
      siteId,
      location_id: locationid,
      postId: questionId,
    }).then((result) => {
      $(".loder_div").attr("hidden", true);
      if (result.success != "1") {
        return;
      }
      alert("Answer Deleted Successfully");
      this.closeDeleteModal();
      setTimeout(() => {
        $(".loder_div").attr("hidden", false);
        // this.onSubmitFilter();
      }, 3000);
    });
    $(".loder_div").attr("hidden", true);
  };
  deleteQuestion = () => {
    $(".loder_div").attr("hidden", false);
    const {
      siteId,
      deletedQuestion: { locationid, questionId },
    } = this.state;

    backendApi({
      functionName: "DeleteQuestion",
      siteId,
      locationid,
      questionId,
    }).then((result) => {
      if (result.success != "1") {
        $(".loder_div").attr("hidden", true);
        return;
      }
      alert("Question deleted successfully");
      this.closeDeleteModal();
      setTimeout(() => {
        $(".loder_div").attr("hidden", false);
        this.onSubmitFilter();
      }, 3000);
      $(".loder_div").attr("hidden", true);
    });
  };

  deleteQuestionOrAnswer = () => {
    if (this.state.deleteModalOnClickFunction === "delete_question") {
      this.deleteQuestion();
    } else {
      this.deleteAnswer();
    }
  };

  onLocationFilter = (filterKey) => {
    constFlag = 1;
    var demoArray = [];
    if (this.state.administrativeArea !== "" || this.state.locality != "") {
      demoArray = this.state.tempSearchLocationsList;
    } else {
      demoArray = this.state.locationList;
    }
    // this.setState({
    let filteredArray = demoArray.filter(
      (data) =>
        data.storeCode
          .toLowerCase()
          .includes(filterKey.target.value.toLowerCase()) ||
        data.title
          .toLowerCase()
          .includes(filterKey.target.value.toLowerCase()) ||
        data.addressLines
          .toLowerCase()
          .includes(filterKey.target.value.toLowerCase()) ||
        (
          data.storeCode +
          "," +
          data.addressLines +
          "," +
          data.locality +
          "," +
          data.AdministrativeArea
        )
          .toLowerCase()
          .includes(filterKey.target.value.toLowerCase()) ||
        (data.locality + "," + data.AdministrativeArea)
          .toLowerCase()
          .includes(filterKey.target.value.toLowerCase()) ||
        data.locality
          .toLowerCase()
          .includes(filterKey.target.value.toLowerCase()) ||
        data.AdministrativeArea.toLowerCase().includes(
          filterKey.target.value.toLowerCase()
        )
    );
    if (filterKey.target.value == "") {
      this.setState({
        globalFilterLocationsList: this.state.tempSearchLocationsList,
        businessNameFilter: "",
      });
    } else {
      this.setState({
        businessNameFilter: filterKey.target.value,
        globalFilterLocationsList: filteredArray,
      });
    }
    // });
    // if (filterKey == "") {
    //   this.setState({ filteredLocationList: this.state.locationList });
    // }
  };

  getAdministrativeArea() {
    const businessAccountData = {
      functionName: "getAdministrativeArea",
      account_id: window.sessionStorage.getItem("account_id"),
    };
    backendApi(businessAccountData).then((data) => {
      if (data.success === "1") {
        this.setState({
          administrativeAreaList: data.data.map(
            (obj) => obj.AdministrativeArea
          ),
        });
      } else {
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  handleChangeState = (value, locality, load) => {
    $("#cityId").val("");
    constFlag = 1;
    if (value !== "") {
      if (load == 0) {
        this.setState({
          businessNameFilter: "",
          businessAddress: "",
          locality: "",
          searchAddress: "",
          administrativeArea: value,
        });
      }
      const businessAccountData = {
        functionName: "getLocalityUnderAdministrativeArea",
        account_id: window.sessionStorage.getItem("account_id"),
        AdministrativeArea: value,
      };
      backendApi(businessAccountData).then((data) => {
        if (data.success === "1") {
          const businessAccountData = {
            functionName: "getLocationSearch",
            account_id: window.sessionStorage.getItem("account_id"),
            AdministrativeArea: value,
            locality: "",
          };
          backendApi(businessAccountData).then((response) => {
            this.setState(
              {
                globalFilterLocationsList: response.data,
                tempSearchLocationsList: response.data,
                locationIdsList: response.data.map((data) => data.location_id),
              },
              () => console.log(this.state.locationIdsList)
            );
            // this.getQuestionsData(response.data);
            // this.getQuestionAndAnswerList();
          });
          this.setState({
            cityList: data.data.map((obj) => obj.locality),
          });
          // if (load !== 0) this.handleChangeCity(locality, value, 1);
        } else {
          this.setState({
            cityList: [],
          });
          $(".loder_div").attr("hidden", true);
        }
      });
    }
  };
  handleChangeCity(value, administrativeArea, load) {
    constFlag = 1;
    this.setState({
      locality: value,
      searchAddress: "",
      administrativeArea: administrativeArea,
    });
    if (load == 0) {
      this.setState({
        businessNameFilter: "",
        businessAddress: "",
      });
    }
    const businessAccountData = {
      functionName: "getLocationSearch",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea: administrativeArea,
      locality: value,
    };
    backendApi(businessAccountData).then((data) => {
      if (data.success === "1") {
        this.setState({
          globalFilterLocationsList: data.data,
          tempSearchLocationsList: data.data,
          locationIdsList: data.data.map((obj) => obj.location_id),
        });
        // this.getQuestionsData(data.data);
        // this.getQuestionAndAnswerList();
      } else {
        this.setState({
          globalFilterLocationsList: [],
          tempSearchLocationsList: [],
          locationIdsList: [],
        });
        $(".loder_div").attr("hidden", true);
      }
    });
    // this.setState({
    //   getLocation: value,
    // });

    // this.setState({
    //   businessName: filterbusiness,
    // });
  }
  searchLocation(e) {
    e.preventDefault();
    // console.log("loooooooooooooo", this.state.location.location_id);
    // this.getSingleQuestionsData(this.state.location);
    this.getQuestionAndAnswerList();
  }
  qAndAMigrateByLocation() {
    if (this.state.locationIdsList.length > 0) {
      // let apiReq = {
      //   method: "POST",
      //   body: JSON.stringify({
      //     siteId:
      //       window.sessionStorage.getItem("switchingOrgId") === ""
      //         ? window.sessionStorage.getItem("organizationId")
      //         : window.sessionStorage.getItem("switchingOrgId"),
      //     locationArray: `[{${this.state.locationIdsList.join(",")}}]`,
      //   }),
      // };
      let apiParameters = {
        functionName: "dataMigrationReviewAndQA",
        function_name: "migrateLocationData_ByLocations",
        module_name: "QandA Migrate By Location",
        params: {
          siteId:
            window.sessionStorage.getItem("switchingOrgId") === ""
              ? window.sessionStorage.getItem("organizationId")
              : window.sessionStorage.getItem("switchingOrgId"),
          locationarray: `[{${this.state.locationIdsList.join(",")}}]`,
        },
      };
      // console.log("apiReqapiReq", apiReq);
      backendApi(apiParameters).then((data) => {
        console.log(data);
      });
      // pythonBackendApi("migrateLocationData_ByLocations", apiReq);
    }
  }
  handleActivePage(e) {
    var offset;
    if (e === "1") {
      offset = "0";
    } else {
      offset = parseInt(e - 1) * 25;
    }
    this.setState({
      currentPage: e,
      offset: offset,
    });
    this.getQuestionAndAnswerList();
    // const indexOfLastPost = e * 25;
    // const indexOfFirstPost = indexOfLastPost - 25;
    // let tempList = backupdata;
    // const currentPosts = tempList.slice(indexOfFirstPost, indexOfLastPost);
    // setLocalPostList(currentPosts);
  }
  setSelected(val) {
    this.setState({
      keyword: val,
    });
  }
  checkReplyOwner(val) {
    console.log("questionAndAnswers", this.state.questionAndAnswers);
    let location_ids = [];
    if (val === "yes") {
      location_ids = this.state.questionAndAnswers
        .filter((questions) =>
          questions.answers.some(
            (eachAnswer) => eachAnswer.author_type == "MERCHANT"
          )
        )
        .map((eachQuestion) => eachQuestion.location_id);
      console.log(
        "🚀 ~ file: QuestionAnswer.jsx:1214 ~ QuestionAnswer ~ checkReplyOwner ~ location_ids:",
        location_ids
      );
    }
    this.setState({ repliedByOwner: val, locationIdsList: location_ids });
  }
  getStoreCode(locationId) {
    let address = this.state.allLocationData
      .filter((data) => {
        return data.location_id == locationId;
      })
      .map((obj) => {
        return `${obj.storeCode}`;
      });
    // console.log("locationId", address[0]);
    return address[0];
  }
  exportReveiw(e) {
    e.preventDefault();
    // Get the review data
    const questions = this.state.questionAndAnswers;
    console.log("hiiii", questions);
    const transformedObjects = [];
    let ownerCount = 0;
    let regularCount = 0;
    // Map the questions to an array of objects with the required columns
    const data = questions.map((question) => {
      ownerCount = 0;
      regularCount = 0;
      // let totalAddress = this.getAddress(review.location_id);
      const transformedObject = {
        "Store Code": this.getStoreCode(question.location_id),
        "Store Name": question.title,
        Address: question.addressLines,
        Locality: question.locality,
        State: question.administrativeArea,
        "Postal Code": question.postalCode,
        "Question created on": moment(question.questionCreateTime).format(
          "DD/MM/YYYY hh:mm A"
        ),
        "Author Question": question.questionAuthor,
        Question: question.questionText,
      };

      // question.answers?.forEach((answer, index) => {
      //   if (answer.author_type == "MERCHANT") {
      //     debugger;
      //     console.log(
      //       "🚀 ~ file: QuestionAnswer.jsx:1309 ~ QuestionAnswer ~ question.answers.forEach ~ answer:",
      //       answer
      //     );
      //     transformedObject[`Owner Answer Name ${ownerCount + 1}`] =
      //       answer.author_displayName;
      //     transformedObject[`Owner Answer ${ownerCount + 1}`] =
      //       answer.answer_text;

      //     ownerCount += 1;
      //   }
      // });
      // transformedObjects.push(transformedObject);

      question.answers?.forEach((answer, index) => {
        // if (answer.author_type !== "MERCHANT") {
          transformedObject[`Author ${regularCount + 1}`] =
            answer.author_displayName;
          transformedObject[`Author Answer ${regularCount + 1}`] =
            answer.answer_text;

          regularCount += 1;
        // }
      });
      transformedObjects.push(transformedObject);
      // if (ownerCount === 0) {
      //   transformedObject["Owner Answer Name"] = "";
      //   transformedObject["Owner Answer"] = "";
      // }

      // if (regularCount === 0) {
      //   transformedObject["Author"] = "";
      //   transformedObject["Author Answer"] = "";
      // }
      // return {
      //   "Store Code": this.getStoreCode(review.location_id),
      //   "Store Name": `${totalAddress[0].title}`,
      //   Address: `${totalAddress[0].addressLines}`,
      //   Locality: `${totalAddress[0].locality}`,
      //   State: `${totalAddress[0].AdministrativeArea}`,
      //   "Postal Code": `${totalAddress[0].postalCode}`,
      //   "Date and Time": moment(review.createTime).format("DD/MM/YYYY hh:mm A"),
      //   Name: review.displayName,
      //   Rating: this.getRating(review.starRating),
      //   Sentiment: this.getSentiment(review.starRating),
      //   Review: this.getReviewText(review),
      //   Reply:
      //     this.state.radioComment || review.reviewReplyComment === ""
      //       ? ""
      //       : review.reviewReplyComment,
      //   "Replied to review date":
      //     review.reviewReplyComment !== ""
      //       ? moment(review.reviewReplyUpdateTime).format("DD/MM/YYYY hh:mm A")
      //       : "",
      // };
    });
    console.log(
      "🚀 ~ file: QuestionAnswer.jsx:1328 ~ QuestionAnswer ~ data ~ transformedObjects:",
      transformedObjects
    );
    // Define the worksheet
    const worksheet = XLSX.utils.json_to_sheet(transformedObjects);

    // Add a custom number format for the rating column
    worksheet["!cols"] = [
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 50 },
      { width: 50 },
      { width: 50 },
    ];
    // worksheet['!numfmt'] = [{}, {}, { numFmt: '[>=1][<2]★;General;General;@' }];
    worksheet["!cols"].splice(3, 0, { width: 20 }); // Add a column with width 20 at index 3 (after the 'Rating' column)

    // Add the worksheet to a new workbook and save it
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Reviews");
    XLSX.writeFile(workbook, "QandA.xlsx");
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper">
          <Header heading="Question and Answers" headerImage={QandA} />
          <div className="container-fluid">
            <form
              autoComplete="off"
              // onSubmit={(e) => this.onSubmitFilter(e)}
            >
              <div className="row row-search">
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <select className="form-control" disabled defaultValue={"IN"}>
                    <option value="IN">India</option>
                  </select>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <select
                    className="form-control"
                    value={this.state.administrativeArea}
                    onChange={(e) =>
                      this.handleChangeState(e.target.value, "", 0)
                    }
                  >
                    <option value="">State</option>
                    {this.state.administrativeAreaList?.length > 0 &&
                      this.state.administrativeAreaList.map(
                        (adminArea, index) => (
                          <option key={index} value={adminArea}>
                            {adminArea}
                          </option>
                        )
                      )}
                  </select>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <select
                    className="form-control"
                    value={this.state.locality}
                    onChange={(e) =>
                      this.handleChangeCity(
                        e.target.value,
                        this.state.administrativeArea,
                        0
                      )
                    }
                  >
                    <option value="">City</option>
                    {this.state.cityList?.length > 0 &&
                      this.state.cityList.map((eachLocality, index) => (
                        <option key={index} value={eachLocality}>
                          {eachLocality}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 location_search">
                  <input
                    className="form-control"
                    type="text"
                    value={this.state.businessNameFilter}
                    placeholder="Location"
                    onChange={(e) => this.onLocationFilter(e)}
                  />
                  <div className="autocomplete__list location_search_drop">
                    <ul className="nav flex-column">
                      {this.state.globalFilterLocationsList?.length > 0 &&
                        this.state.globalFilterLocationsList.map(
                          (eachLocation) => (
                            <li
                              onMouseDown={() =>
                                this.onLocationSelect(eachLocation)
                              }
                              key={eachLocation.locationId}
                            >
                              {eachLocation.storeCode}-{eachLocation.title},{" "}
                              {eachLocation.addressLines},{" "}
                              {eachLocation.locality},{" "}
                              {eachLocation.AdministrativeArea}
                            </li>
                          )
                        )}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={(date) => this.handleChangeStart(date)}
                    name="startDate"
                    showMonthDropdown
                    showYearDropdown
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    placeholderText="From Date"
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <DatePicker
                    selected={this.state.endDate}
                    onChange={(date) => this.handleChangeEnd(date)}
                    name="startDate"
                    id="endDate"
                    showYearDropdown
                    showMonthDropdown
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    placeholderText="To Date"
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <select
                    className="form-control"
                    value={this.state.repliedByOwner}
                    onChange={(e) => this.checkReplyOwner(e.target.value)}
                  >
                    <option value="">Replied by Owner</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div className="col-lg-5 tagsInput">
                  <TagsInput
                    // key={this.state.keywordLength}
                    classNames="new-react-tag-classes"
                    value={this.state.keyword}
                    onChange={this.setSelected}
                    name="keywords"
                    isEditOnRemove={true}
                    placeHolder="Write and press ENTER after each word"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-lg-3 col-md-5 col-sm-7">
                  <button
                    className="btn-search"
                    onClick={(e) => this.searchLocation(e)}
                  >
                    Search
                  </button>
                  <button
                    className="btn-clear-search"
                    onClick={(e) => this.clearData(e)}
                  >
                    Clear
                  </button>
                  <button
                    className="btn-search"
                    onClick={(e) => this.exportReveiw(e)}
                  >
                    Export
                  </button>
                </div>
              </div>
            </form>
            {this.state.totalCount.length > 100 && (
              <Pagination
                hideDisabled
                activePage={this.state.currentPage}
                itemsCountPerPage={100}
                totalItemsCount={this.state.totalCount}
                pageRangeDisplayed={10}
                onChange={(e) => this.handleActivePage(e)}
              />
            )}

            <div className="col-md-12 tableResponsive">
              {this.state.questionAndAnswers?.length > 0 ? (
                <div className="tableOuterWidth">
                  <table className="table">
                    <thead className="sticky__table__header">
                      <tr>
                        <th className="col-2">Date</th>
                        <th className="col-2">Location</th>
                        <th className="col-3">Question</th>
                        <th className="col-5">
                          <div className="row">
                            <div className="col-7">Answer</div>
                            <div className="col-5 pl-0">Answered by Owner</div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <div
                      className="table_Outerscroll"
                      style={{ overflow: "overlay" }}
                    >
                      {this.state.questionAndAnswers.map(
                        (eachQuestion, index) => {
                          return (
                            <tbody key={index} className="table_Outertbody">
                              <tr>
                                <td className="col-2">
                                  Created On:{" "}
                                  {moment(
                                    eachQuestion?.questionCreateTime
                                  ).format("DD/MM/YYYY hh:mm A")}
                                  {/* {eachQuestion?.questionUpdateTime && (
                                      <span style={{ display: "block" }}>
                                        Updated On:{" "}
                                        {moment(
                                          eachQuestion?.questionUpdateTime
                                        ).format("DD/MM/YYYY")}
                                      </span>
                                    )} */}
                                </td>
                                <td className="col-2">
                                  {eachQuestion?.storeCode && (
                                    <span style={{ color: "firebrick" }}>
                                      {eachQuestion?.storeCode}-&nbsp;
                                    </span>
                                  )}
                                  <span
                                    style={{
                                      textDecoration: "underline",
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {eachQuestion.title}
                                  </span>
                                  <span style={{ display: "block" }}>
                                    {eachQuestion.addressLines},
                                    {eachQuestion.locality},
                                    {eachQuestion.administrativeArea}
                                  </span>
                                </td>
                                <td className="col-3">
                                  {/* {console.log("Ques", eachQuestion)} */}
                                  <div className="question_textdiv">
                                    <div className="question_textdivhead">
                                      {eachQuestion.questionText}
                                      <div>
                                        <small>
                                          ( {eachQuestion.questionAuthor} )
                                        </small>
                                      </div>
                                    </div>
                                    {/* <div className="question_textaction">
                                        {eachQuestion?.questionAuthorType ===
                                          "MERCHANT" && (
                                          <>
                                            <button
                                              onClick={() =>
                                                this.openEditModal(
                                                  eachQuestion,
                                                  "update_question"
                                                )
                                              }
                                              className="btn btn-light p-0"
                                            >
                                              <Icon.Edit
                                                style={{
                                                  marginRight: "5px",
                                                  width: "17px",
                                                }}
                                              />
                                            </button>
                                            <button
                                              onClick={() =>
                                                this.openDeleteModal(
                                                  eachQuestion,
                                                  "delete_question"
                                                )
                                              }
                                              className="btn btn-light p-0"
                                            >
                                              <Icon.Trash
                                                style={{
                                                  width: "17px",
                                                  color: "red",
                                                }}
                                              />
                                            </button>
                                          </>
                                        )}
                                      </div> */}
                                  </div>
                                </td>
                                <td className="col-5">
                                  <div className="row">
                                    <div className="col-10">
                                      {eachQuestion.answers?.length > 0
                                        ? eachQuestion.answers.map(
                                            (eachAnswer, index) => {
                                              return (
                                                <div
                                                  className="row answer_yesorno"
                                                  key={index}
                                                >
                                                  {index + 1}.{" "}
                                                  <div className="col-5">
                                                    <span
                                                      style={{
                                                        textDecoration:
                                                          "underline",
                                                        fontWeight: "bold",
                                                        fontSize: "14px",
                                                      }}
                                                    >
                                                      {
                                                        eachAnswer.author_displayName
                                                      }
                                                    </span>
                                                    <span
                                                      style={{
                                                        display: "block",
                                                      }}
                                                    >
                                                      {eachAnswer.answer_text}
                                                    </span>
                                                  </div>
                                                  <div className="col-3">
                                                    {eachAnswer?.author_type ===
                                                    "MERCHANT" ? (
                                                      <>
                                                        <button className="btn btn-light p-0">
                                                          <Icon.Edit
                                                            onClick={() =>
                                                              this.openEditModal(
                                                                eachAnswer,
                                                                "edit_answer"
                                                              )
                                                            }
                                                            style={{
                                                              marginRight:
                                                                "5px",
                                                              width: "17px",
                                                            }}
                                                          />
                                                        </button>
                                                        <button className="btn btn-light p-0">
                                                          <Icon.Trash
                                                            style={{
                                                              width: "17px",
                                                              color: "red",
                                                            }}
                                                            onClick={() =>
                                                              this.openDeleteModal(
                                                                eachQuestion,
                                                                "delete_answer"
                                                              )
                                                            }
                                                          />
                                                        </button>
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </div>
                                                  <div className="col-2">
                                                    {eachAnswer?.author_type ===
                                                    "MERCHANT"
                                                      ? //   &&
                                                        // this.state.googleAccountList.includes(
                                                        //   eachAnswer?.author
                                                        //     ?.displayName
                                                        // )
                                                        "Yes"
                                                      : "No"}
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )
                                        : "No answers available"}
                                    </div>
                                    <div className="col-2 d-flex align-items-center justify-content-center">
                                      <button className="btn-search p-0 m-0">
                                          <Icon.Plus
                                            onClick={() =>
                                              this.openEditModal(
                                                eachQuestion,
                                                "add_answer"
                                              )
                                            }
                                          />
                                        </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          );
                        }
                      )}
                    </div>
                  </table>
                </div>
              ) : (
                <div className="content-sub-wrapper col-md-12">
                  <div className="content-sub">
                    <p style={{ textAlign: "center" }}>{this.state.response}</p>
                  </div>
                </div>
              )}

              <Modal
                isOpen={this.state.editModalIsOpen}
                onRequestClose={this.closeEditModal}
                className="modal__view"
                contentLabel="Editing Modal"
              >
                <div className="d-flex justify-content-end">
                  <button
                    onClick={this.closeEditModal}
                    className="btn btn-transparent p-0"
                  >
                    <Icon.XCircle style={{ width: "20px", color: "red" }} />
                  </button>
                </div>
                <div>
                  <form
                    autoComplete="off"
                    onSubmit={(e) =>
                      this.updateQuestionOrAnswer(
                        e,
                        this.state.modalOnClickFunction
                      )
                    }
                  >
                    <textarea
                      rows="4"
                      className="form-control"
                      required
                      value={
                        this.state.modalOnClickFunction === "update_question"
                          ? this.state.updatedQuestion.text
                          : this.state.updatedAnswer.text
                      }
                      placeholder={this.state.modalInputPlaceholder}
                      style={{ minHeight: "120px !important" }}
                      onChange={(e) =>
                        this.setState((prevVal) =>
                          this.state.modalOnClickFunction === "update_question"
                            ? {
                                updatedQuestion: {
                                  ...prevVal.updatedQuestion,
                                  text: e.target.value,
                                },
                              }
                            : {
                                updatedAnswer: {
                                  ...prevVal.updatedAnswer,
                                  text: e.target.value,
                                },
                              }
                        )
                      }
                    />
                    <div className="text-right">
                      <button type="submit" className="btn-search">
                        {this.state.modalButtonPlaceholder}
                      </button>
                    </div>
                  </form>
                </div>
              </Modal>

              <Modal
                isOpen={this.state.deleteModalIsOpen}
                onRequestClose={this.closeDeleteModal}
                className="delete__modal__view"
                contentLabel="Deleteing Modal"
              >
                <div className="d-flex justify-content-end mb-3">
                  <button
                    onClick={this.closeDeleteModal}
                    className="btn btn-transparent border-0 p-0"
                  >
                    <Icon.XCircle style={{ width: "17px", color: "red" }} />
                  </button>
                </div>
                <div>
                  <h4 style={{ textAlign: "center" }}>
                    Are you sure you want to delete this entry?
                  </h4>
                  <div>
                    <button
                      onClick={() => this.deleteQuestionOrAnswer()}
                      className="btn-save mt-2 mb-1 mx-2 tag_Next btn-primary nohover__nofocus"
                    >
                      Yes
                    </button>
                    <button
                      onClick={() => this.closeDeleteModal()}
                      className="btn-save mt-2 mb-1 mx-2 tag_Next btn-primary nohover__nofocus"
                    >
                      No
                    </button>
                  </div>
                </div>
              </Modal>

              <Link
                to="/questionAnswer/createQuestion"
                className="tag_float"
                title="Add Post"
              >
                <Icon.Plus color="#fff" className="tag_my_float" />
              </Link>
            </div>
            {this.state.totalCount.length > 100 && (
              <Pagination
                hideDisabled
                activePage={this.state.currentPage}
                itemsCountPerPage={100}
                totalItemsCount={this.state.totalCount}
                pageRangeDisplayed={10}
                onChange={(e) => this.handleActivePage(e)}
              />
            )}
          </div>
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}

export default QuestionAnswer;
