import React, { useState, useEffect } from 'react';
import html2pdf from 'html2pdf.js';
import TableInvoice from './tableInvoice';
import ReportInvoice from './reportInvoice';
import InvoiceNew from './invoice';
import DealerShipInvoice from './dealerShipInvoice';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { backendDashboardApi } from "../apiService";
const DownloadPdfButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedStartDate, areaOffice, receivedAmount, id, invoiceStatus } = location.state;
  const [loader, setLoader] = useState(true);
  const [apiCount, setApiCount] = useState(0);
  const [invoiceData, setInvoiceData] = useState(null);
  const [tableInvoiceDetails, setTableInvoiceDeatails] = useState(null);
  const [reportInvoiceDetailsGoogleCv, setReportInvoiceDeatailsGoogleCv] = useState(null);
  const [reportInvoiceDetailsGooglePv, setReportInvoiceDeatailsGooglePv] = useState(null);
  const [reportInvoiceDetailsGoogleScv, setReportInvoiceDeatailsGoogleScv] = useState(null);
  const [reportInvoiceDetailsMetaCv, setReportInvoiceDeatailsMetaCv] = useState(null);
  const [reportInvoiceDetailsMetaPv, setReportInvoiceDeatailsMetaPv] = useState(null);
  const [reportInvoiceDetailsMetaScv, setReportInvoiceDeatailsMetaScv] = useState(null);
  const [dealershipInvoiceDetailsCv, setDealershipInvoiceDeatailsCv] = useState(null);
  const [dealershipInvoiceDetailsPv, setDealershipInvoiceDeatailsPv] = useState(null);
  const [dealershipInvoiceDetailsScv, setDealershipInvoiceDeatailsScv] = useState(null);
  const [finalisedData, setFinalisedData] = useState(null);
  const [finalisedTableData, setFinalisedTableData] = useState(null);
  const [dealershipData, setDealershipData] = useState(null);
  const [reportInvoiceData, setReportInvoiceData] = useState(null);

  // const handleDownloadPdf = () => {
  //   const tableInvoiceElement = document.getElementById('tableInvoice');
  //   const reportInvoiceElement = document.getElementById('reportTnvoice');
  //   const invoiceElement = document.getElementById('invoice');
  //   const dealerShipInvoice = document.getElementById('dealerShipInvoice');
  //   const combinedHtml = `
  //     <div>
  //       ${invoiceElement.innerHTML}
  //       ${tableInvoiceElement.innerHTML}
  //       ${reportInvoiceElement.innerHTML}
  //       ${dealerShipInvoice.innerHTML}
  //     </div>
  //   `;
  //   html2pdf()
  //     .from(combinedHtml)
  //     .save();
  // };

  useEffect(() => {
    if (invoiceStatus === "Finalized") {
      const postData = {
        "functionName": "getInvoiceDetailsByInvoiceId",
        "invoiceId": id
      }
      backendDashboardApi(postData).then((data) => {
        console.log("Finalized Invoice Data:", data.result);
        if (data.success === "1" && data.result[0].invoiceJsonFinalized) {
          try {
            const invoiceJsonFinalized = JSON.parse(data.result[0].invoiceJsonFinalized);
            console.log("Finalized Invoice Data:", invoiceJsonFinalized);
            setFinalisedData(invoiceJsonFinalized.invoiceData)
            setFinalisedTableData(invoiceJsonFinalized.tableInvoiceDetails)
            setDealershipData({
              dealershipCv: invoiceJsonFinalized.dealershipCv,
              dealershipPv: invoiceJsonFinalized.dealershipPv,
              dealershipScv: invoiceJsonFinalized.dealershipScv
          });
          setReportInvoiceData({
            googleCv: invoiceJsonFinalized.googleCv,
            googlePv: invoiceJsonFinalized.googlePv,
            googleScv: invoiceJsonFinalized.googleScv,
            metaCv: invoiceJsonFinalized.metaCv,
            metaPv: invoiceJsonFinalized.metaPv,
            metaScv: invoiceJsonFinalized.metaScv
          });
          } catch (error) {
            console.error("Error parsing invoice JSON:", error);
          }
        } else {

        }
      })
    }
    else {

    }

  }, [invoiceStatus]);
  const handleDownloadPdf = () => {
    const invoiceContainer = document.getElementById('invoiceContainer');

    const pdfOptions = {
      margin: 10,
      filename: 'invoices.pdf',
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    html2pdf()
      .from(invoiceContainer)
      .set(pdfOptions)
      .save();
  };

  const handlePrintPdf = () => {
    window.print();
  };

  const handleBacktoList = () => {
    navigate('/createInvoice')
  }


  const handleIncrementApiCount = () => {
    setApiCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 3) {
        setLoader(false);
      }
      return newCount;
    });
  }
  const handleDataReceived = (data) => {
    console.log("dataaaaaaaaaaaaaaaa111111111111111", data)
    setInvoiceData(data);
  };
  const handleDataReceivedTable = (data) => {
    console.log("dataaaaaaaaaaaaaaaa2222222222222222", data)
    setTableInvoiceDeatails(data);
  };
  const handleDataReceivedReport = (source, data) => {
    console.log(source, "dataaaaaaaaaaaaaaaa333333333333333333333", data)
    switch (source) {
      case 'googleCv':
        setReportInvoiceDeatailsGoogleCv(data);
        break;
      case 'googlePv':
        setReportInvoiceDeatailsGooglePv(data);
        break;
      case 'googleScv':
        setReportInvoiceDeatailsGoogleScv(data);
        break;
      case 'metaCv':
        setReportInvoiceDeatailsMetaCv(data);
        break;
      case 'metaPv':
        setReportInvoiceDeatailsMetaPv(data);
        break;
      case 'metaScv':
        setReportInvoiceDeatailsMetaScv(data);
        break;
      default:
        break;
    }
  };

  const handleDataReceivedDealership = (source, data) => {
    console.log(source, "dataaaaaaaaaaaaaaaa444444444444444444444444", data)
    switch (source) {
      case 'dealershipCv':
        setDealershipInvoiceDeatailsCv(data);
        break;
      case 'dealershipPv':
        setDealershipInvoiceDeatailsPv(data);
        break;
      case 'dealershipScv':
        setDealershipInvoiceDeatailsScv(data);
        break;
      default:
        break;
    }
  };
  const finaliseClick = () => {
    if (window.confirm("Are you sure to Finalize?")) {
    const postData = {
      "functionName": "finalizeInvoice",
      "invoiceID": id,
      "modifiedOn": Date.now(),
      "modifiedBy": window.sessionStorage.getItem("userId"),
      "invoiceJson": {
        invoiceData: invoiceData,
        tableInvoiceDetails: tableInvoiceDetails,
        googleCv: reportInvoiceDetailsGoogleCv,
        googlePv: reportInvoiceDetailsGooglePv,
        googleScv: reportInvoiceDetailsGoogleScv,
        metaCv: reportInvoiceDetailsMetaCv,
        metaPv: reportInvoiceDetailsMetaPv,
        metaScv: reportInvoiceDetailsMetaScv,
        dealershipCv: dealershipInvoiceDetailsCv,
        dealershipPv: dealershipInvoiceDetailsPv,
        dealershipScv: dealershipInvoiceDetailsScv
      }
    }
    console.log("xxxxxxxxxxxxxxxxxxpostdata", postData)
    backendDashboardApi(postData).then((data) => {
      console.log("finaliseClick", data)
      if (data.success === "1") {
        // alert("Sucessfully Finalized")
        navigate('/createInvoice')
      } else {

      }
    });}
  }
  const voidClick = () => {
    if (window.confirm("Are you sure ?")) {
    const postData = {
      "functionName": "voidInvoice",
      "invoiceID": id,
      "modifiedOn": Date.now(),
      "modifiedBy": window.sessionStorage.getItem("userId"),
    }
    backendDashboardApi(postData).then((data) => {
      console.log("voidClick", data)
      if (data.success === "1") {
        // alert("Void")
        navigate('/createInvoice')
      } else {

      }
    });
  }
  }
  return (
    <div className="container">
      {loader &&
        <div className="">Please Wait</div>
      }
      <>
        <div className="text-right">
          {invoiceStatus === "Finalized" ?
          <>
          <span>Current Status : </span><span className='status-btn-draft'>Finalized</span>
            <button className='mt-4 btn-void-invoice' onClick={voidClick}>Void</button>
            </>
            :
          <>
            <span>Current Status : </span><span className='status-btn-draft'>Draft</span>
            <button className='mt-4 btn-finalize-invoice' onClick={finaliseClick}>Finalize</button></>}
          <button className='mt-4 btn-invoive-dwnlod' onClick={handleBacktoList}>Back To List</button>
          <button className='mt-4 btn-invoive-dwnlod' onClick={handleDownloadPdf}>Download Invoice</button>
          <button className='mt-4 btn-invoive-dwnlod' onClick={handlePrintPdf}>Print Invoice</button>
        </div>
        <div id="invoiceContainer">
          <div className="invoice-page">
            <InvoiceNew selectedStartDate={selectedStartDate} areaOffice={areaOffice} invoiceStatus={invoiceStatus} finalisedData={finalisedData} onDataReceived={handleDataReceived} receivedAmount={receivedAmount} handleIncrementApiCount={handleIncrementApiCount} />
          </div>
          <div className="invoice-page">
            <TableInvoice selectedStartDate={selectedStartDate} areaOffice={areaOffice} invoiceStatus={invoiceStatus} finalisedTableData={finalisedTableData} onDataReceived={handleDataReceivedTable} handleIncrementApiCount={handleIncrementApiCount} />
          </div>
          <div >
            <ReportInvoice selectedStartDate={selectedStartDate} areaOffice={areaOffice} invoiceStatus={invoiceStatus} finalisedData={reportInvoiceData} onDataReceived={handleDataReceivedReport} handleIncrementApiCount={handleIncrementApiCount} />
          </div>
          <div>
            <DealerShipInvoice selectedStartDate={selectedStartDate} areaOffice={areaOffice} invoiceStatus={invoiceStatus} finalisedData={dealershipData} onDataReceived={handleDataReceivedDealership} handleIncrementApiCount={handleIncrementApiCount} />
          </div>
        </div>
      </>

    </div>

  );
};

export default DownloadPdfButton;
