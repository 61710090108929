import React, { useState } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import Offer from "../images/offer.png";
import DatePicker from "react-datepicker";
import $ from "jquery";
import * as Icon from "react-feather";
import { apiCallingPost } from "../apiService";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import { useEffect } from "react";
import { backendApi } from "../apiService";
const s3ImageUrl =
  "http://tagtree.s3-website.ap-south-1.amazonaws.com/production/images";
var s3Region;
var s3bucketAccessKey;
var s3bucketSecretKey;
var s3Bucket;
var s3BucketMainFolder;
var S3BucketImagesFolder = "images";
var bucketParams;
var s3;
var timeList = [
  "0:00",
  "0:30",
  "1:00",
  "1:30",
  "2:00",
  "2:30",
  "3:00",
  "3:30",
  "4:00",
  "4:30",
  "5:00",
  "5:30",
  "6:00",
  "6:30",
  "7:00",
  "7:30",
  "8:00",
  "8:30",
  "9:00",
  "9:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];
var regex = new RegExp(":", "g");

function PostOffer() {
  const s3Region = "us-east-1",
    s3bucketAccessKey = process.env.REACT_APP_TAGTREE_S3_ACCESS_KEY,
    s3bucketSecretKey = process.env.REACT_APP_TAGTREE_S3_SECRET_KEY,
    s3Bucket = "tagtree",
    s3BucketMainFolder = "production",
    S3BucketImagesFolder = "images",
    bucketParams = {
      Bucket: s3Bucket,
    };
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState(new Date());
  const [endTime, setEndTime] = useState("");
  const [eventType, setEventType] = useState("EVENT");
  const [imageUrl, setImageUrl] = useState([]);
  const [eventTitle, setEventTitle] = useState("");
  const [displayImages, setDisplayImages] = useState([]);
  const [tempImagesList, setTempImagesList] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [couponCodeObject, setCouponCodeObject] = useState("");
  const [redeemOnlineUrlObject, setRedeemOnlineUrlObject] = useState("");
  const [termsAndConditionsObject, setTermsAndConditionsObject] = useState("");
  const [offerDetails, setOfferDetails] = useState("");
  const [callToActionButton, setCallToActionButton] = useState("0");
  const [callToActionUrl, setCallToActionUrl] = useState("");
  const [s3Credentials, setS3Credentials] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    setEventTitle(location.state?.title ? location.state?.title : "");
    setStartDate(
      location.state?.startDate ? location.state?.startDate : new Date()
    );
    setDescription(location.state?.summary ? location.state?.summary : "");
    setStartTime(location.state?.startTime ? location.state?.startTime : "");
    setEndTime(location.state?.endTime ? location.state.endTime : "");
    setEndDate(location.state?.endDate ? location.state?.endDate : new Date());
    setCallToActionButton(
      location.state?.callToAction?.actionType
        ? location.state?.callToAction.actionType
        : "0"
    );
    setCallToActionUrl(
      location.state?.callToAction?.url != ""
        ? location.state?.callToAction?.url
        : ""
    );
    if (location.state?.callToAction?.actionType) {
      $("#button_URL").attr("hidden", false);
    }
    if (location.state?.bannerImages?.length > 0) {
      setShowPreview(true);
      setDisplayImages([location.state.bannerImages[0].url]);
      setTempImagesList([location.state.bannerImages[0].url]);
      setImageUrl([
        {
          url: location.state.bannerImages[0].url,
          type: location.state.bannerImages[0].type,
        },
      ]);
    }
    console.log(
      "location.state?.offerlocation.state?.offer",
      location.state?.offer
    );
    if (location.state?.offer != undefined) {
      setCouponCodeObject(
        location.state?.offer?.couponCode
          ? location.state?.offer?.couponCode
          : ""
      );
      setRedeemOnlineUrlObject(
        location.state?.offer?.redeemOnlineUrl
          ? location.state?.offer?.redeemOnlineUrl
          : ""
      );
      setTermsAndConditionsObject(
        location.state?.offer?.termsConditions
          ? location.state?.offer?.termsConditions
          : ""
      );
    }
    getS3Credentials();
    location.state.PostEditField.length !== 0 &&
      getEventEditDetails(location.state.PostEditField);
  }, []);
  const getS3Credentials = () => {
    // const credentialReq = {
    //   functionName: "s3Credentials",
    // };
    // backendApi(credentialReq).then((data) => {
    // setS3Credentials(data.data[0]);
    AWS.config.update({
      accessKeyId: s3bucketAccessKey,
      secretAccessKey: s3bucketSecretKey,
      region: s3Region,
    });
    s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: bucketParams,
    });
    console.log("s3", s3);
    // });
  };
  const getEventEditDetails = (params) => {
    let startDate = params.event.schedule.startDate;
    let startTime = params.event.schedule.startTime;
    let endDate = params.event.schedule.endDate;
    let endTime = params.event.schedule.endTime;
    setEventTitle(params.event.title);
    setStartDate(new Date(startDate.year, startDate.month - 1, startDate.day));
    setEndDate(new Date(endDate.year, endDate.month - 1, endDate.day));
    if (params.callToAction) {
      setCallToActionButton(params.callToAction.actionType);
      setCallToActionUrl(params.callToAction.url);
      $("#button_URL").attr("hidden", false);
    }
    setStartTime(startTime.hours + ":" + startTime.minutes);
    setEndTime(endTime.hours + ":" + endTime.minutes);
    setOfferDetails(params.summary);
    // $("#descriptionField").val(params.summary);
    // $("#callToActionButtons").val(params.callToAction.actionType);
    if (params.media) {
      setShowPreview(true);
      // params.media.forEach((obj) => {
      //   displayImages.push(obj.googleUrl);
      //   tempImagesList.push(obj.googleUrl);
      //   imageUrl.push({ url: obj.googleUrl, type: "PHOTO" });
      // });
      $("#deleteImageIconOffer0").attr("hidden", true);
      setDisplayImages([params.media[0].googleUrl]);
      setTempImagesList([params.media[0].googleUrl]);
      setImageUrl([
        {
          url: params.media[0].googleUrl,
          type: "PHOTO",
        },
      ]);
    }
  };
  const postEvent = () => {
    if ($("#descriptionField").val() === "") {
      alert("Please enter Description");
      $("#descriptionField").focus();
      return false;
    } else if (
      $("#callToActionButtons").val() !== "0" &&
      $("#callToActionURL").val() === " "
    ) {
      alert("Enter URL for " + $("#callToActionButtons").val() + " button");
      $("#callToActionURL").focus();
      return false;
    }
    $(".loder_div").attr("hidden", false);
    var req = {
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      summary: $("#descriptionField").val(),
      startDate_year: parseInt(moment(startDate).get("year")),
      startDate_month: parseInt(moment(startDate).get("month") + 1),
      startDate_day: parseInt(moment(startDate).get("date")),
      startTime_hours: parseInt(startTime.split(":")[0]),
      startTime_minutes: parseInt(startTime.split(":")[1]),
      endDate_year: parseInt(moment(endDate).get("year")),
      endDate_month: parseInt(moment(endDate).get("month") + 1),
      endDate_day: parseInt(moment(endDate).get("date")),
      endTime_hours: parseInt(endTime.split(":")[0]),
      endTime_minutes: parseInt(endTime.split(":")[1]),
      ...($("#callToActionButtons").val() !== "0" && {
        callToAction: {
          actionType: $("#callToActionButtons")
            .val()
            .toUpperCase()
            .replaceAll(" ", "_"),
          url: $("#callToActionURL").val(),
        },
      }),
      offer: {
        ...($("#offerDetails").val() !== "" && {
          couponCode: $("#offerDetails").val(),
        }),
        ...($("#redeemOnlineUrlObject").val() !== "" && {
          redeemOnlineUrl: $("#redeemOnlineUrlObject").val(),
        }),
        ...($("#termsAndConditionsObject").val() !== "" && {
          termsConditions: $("#termsAndConditionsObject").val(),
        }),
      },
      bannerImages: imageUrl,
      eventType: "OFFER",
      functionName: "PostEvent",
    };
    console.log("request", req);
    apiCallingPost(req).then((data) => {
      if (data.success === "1") {
        alert("Posted Successfully!!!");
        // ClosePostPopup();
        $(".loder_div").attr("hidden", true);
        window.location.reload();
      } else {
        data.errorMessage
          ? alert(data.errorMessage)
          : alert("Something went wrong");
      }
    });
  };
  const changeDescriptionField = (event) => {
    if (event.target.value.length < 1500) {
      setDescriptionLength(event.target.value.length);
      setDescription(
        event.target.value !== ""
          ? event.target.value
          : $("#descriptionField").val()
      );
    } else {
      alert("Description cannot have more than 1500 characters");
    }
  };
  const handleImageUpload = (image) => {
    var totalCount = image.length;
    $(".loder_div").attr("hidden", false);
    let s3BucketMainFolder1 = s3BucketMainFolder + "/" + S3BucketImagesFolder;
    if (location?.state?.PostEditField?.media?.length > 0) {
      navigateToNextPage();
      return false;
    }
    for (let i = 0; i < image.length; i++) {
      let fileName = uuidv4() + image[i].name;
      let fullFilePath = s3BucketMainFolder1 + "/" + fileName;
      console.log("fullFilePath", fullFilePath);
      s3.upload(
        {
          Key: fullFilePath,
          Body: image[i],
          ACL: "public-read",
        },
        (err, data) => {
          $(".loder_div").attr("hidden", true);

          if (err) {
            alert("There was an error uploading your image");
            console.log(err.message);
          } else if (data) {
            console.log(i);
            totalCount = totalCount - 1;
            // console.log(data.Location);
            // $("#uploadedImage").attr("hidden", false);
            // $("#multipleImagesSlider").attr("hidden", false);
            imageUrl.push({ url: data.Location, type: image[i].type });
            if (totalCount === 0) {
              $(".loder_div").attr("hidden", true);
              navigateToNextPage();
            }
            // console.log("imageUrlimageUrlimageUrl", this.state.imageUrl);
            // this.setState({
            //   imageUrlListStatus: i + 1,
            // });
            // this.state.imageName.push(data.fileName);
            // this.setState({ imageUrl: data.Location, imageName: fileName });

            return;
          }
        }
      );
    }
  };
  const fileUploadHandler = (e) => {
    let image = e.target.files;
    for (let i = 0; i < image.length; i++) {
      if (image[i].size < 10240) {
        alert("Files must be greater than 10KB");
        return;
      } else if (image[i].size > 4900000) {
        alert("Files must be less than 5MB");
        return;
      } else {
        setDisplayImages([window.webkitURL.createObjectURL(e.target.files[0])]);
        setTempImagesList([e.target.files[0]]);
        setImageUrl([]);
      }
    }
    setShowPreview(true);
  };
  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  const navigateToNextPage = () => {
    navigate("/googlepreview", {
      state: {
        title: eventTitle,
        ...(location.state.PostEditField.length !== 0
          ? { bannerImages: [tempImagesList[0]] }
          : {
              bannerImages: imageUrl.length > 0 ? [imageUrl[0]] : [],
            }),
        summary: offerDetails,
        startDate: startDate,
        startTime: startTime === "" ? "00:00" : startTime,
        endDate: endDate,
        endTime: endTime === "" ? "23:59" : endTime,
        completeLocationData: location.state.completeLocationData,
        selectedLocations: location.state.selectedLocations,
        fromClaimData: location.state?.fromClaimData,
        callToAction: {
          actionType: $("#callToActionButtons").val(),
          url: $("#callToActionURL").val(),
        },
        offer: {
          ...(couponCodeObject !== "" && {
            couponCode: couponCodeObject,
          }),
          ...(redeemOnlineUrlObject !== "" && {
            redeemOnlineUrl: redeemOnlineUrlObject,
          }),
          ...(termsAndConditionsObject !== "" && {
            termsConditions: termsAndConditionsObject,
          }),
        },
        callToAction: {
          actionType: "0",
          url: "",
        },
        PostEditField:
          location.state.PostEditField.length !== 0
            ? location.state.PostEditField
            : [],
        eventType: "OFFER",
      },
    });
  };
  const validatePreview = () => {
    if (eventTitle === "" || $("#EventTitle").val() === "") {
      alert("Please enter Offer Title");
      return false;
    } else if (tempImagesList.length !== 0 && displayImages.length !== 0) {
      handleImageUpload(tempImagesList);
    } else {
      navigateToNextPage();
    }
  };
  const deleteImages = (i) => {
    displayImages.splice(i, 1);
    setDisplayImages(displayImages);
    displayImages.length === 0 && setShowPreview(false);
    displayImages.length === 0 && setDisplayImages([]);
  };
  return (
    <div className="row">
      {/* +============ OLD CODE BEGINS HERE =================== */}
      {/* <div className="col-lg-12 mb-5">
        <div
          className="right_google_popup text-center"
          style={{ width: "300px" }}
        >
          <label>
            <Icon.Camera />
            Add photos
            <input
              type="file"
              id="imageUpload"
              multiple
              onChange={(e) => fileUploadHandler(e.target.files)}
            />
          </label>
          <button
            type="submit"
            className="btn btn-info mt-2"
            onClick={() => handleImageUpload(tempImagesList)}
          >
            Upload
          </button>
        </div>
        {showPreview === true && (
          <div id="displayImagesList">
            {displayImages.map((data) => (
              <img src={data} />
            ))}
          </div>
        )}
      </div> */}

      <div className="tag_info_contentlocation ">
        <div className="right_google_popup" style={{ margin: "0px" }}>
          <label>
            <Icon.Camera />
            Add photos
            <input
              type="file"
              id="imageUpload"
              onChange={(e) => fileUploadHandler(e)}
            />
          </label>
        </div>
        {/* <div className="tag_googlePhotoSave">
          <button
            type="submit"
            className="btn btn-success"
            onClick={() => handleImageUpload(tempImagesList)}
          >
            Upload
          </button>
        </div> */}
      </div>

      {showPreview === true && (
        <div id="displayImagesList" className="tag_googledisplayImagesList">
          <div className="tag_google">
            <div className="row">
              <div className=" tag_imgWrap">
                <div className="multipleImagesSlider">
                  {displayImages.map((data, i) => (
                    <>
                      <img src={data} />
                      <span className="close_popup_imageUpload">
                        <Icon.X
                          id={"deleteImageIconOffer" + i}
                          onClick={() => {
                            deleteImages(i);
                          }}
                        />
                      </span>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* ============================= */}

      <div className="row mt-3">
        <div className="col-lg-3 col-md-6 tag_formCntrl">
          <label className="m-0">
            Offer Title <sup style={{ color: "red" }}>*</sup>
          </label>
          <input
            id="EventTitle"
            type="text"
            value={eventTitle}
            className="form-control"
            onChange={(e) => setEventTitle(e.target.value)}
          />
        </div>
        <div className="col-lg-2 col-md-6 tag_formCntrl ">
          <label className="m-0">
            From Date<sup style={{ color: "red" }}>*</sup>
          </label>
          <div className="row m-0">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              name="startDate"
              showMonthDropdown
              dateFormat="dd/MM/yyyy"
              placeholderText="From Date"
              className="form-control"
            />
          </div>
        </div>
        <div className="col-lg-2 col-md-6 tag_formCntrl">
          <label className="m-0">Start Time</label>
          <select
            name="startTime"
            id="startTime"
            className="form-control"
            onChange={(e) => setStartTime(e.target.value)}
          >
            <option value="">Select</option>
            {timeList.map((data) => (
              <option value={data}>{data}</option>
            ))}
          </select>
        </div>

        <div className="col-lg-2 col-md-6 tag_formCntrl">
          <label className="m-0">
            To Date<sup style={{ color: "red" }}>*</sup>
          </label>
          <div className="row m-0">
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              name="startDate"
              id="endDate"
              showMonthDropdown
              dateFormat="dd/MM/yyyy"
              className="form-control"
              placeholderText="To Date"
            />
          </div>
        </div>

        <div className="col-lg-2 col-md-6 tag_formCntrl">
          <label className="m-0">End Time</label>
          <select
            name="endTime"
            id="endTime"
            className="form-control"
            onChange={(e) => setEndTime(e.target.value)}
          >
            <option value="">Select</option>
            {timeList.map((data) => {
              let timeStr1 = moment().format("HH:mm:ss"),
                timeStr2 = data + ":00",
                finalTime = "";
              if (
                parseInt(timeStr1.replace(regex, ""), 10) <
                parseInt(timeStr2.replace(regex, ""), 10)
              ) {
                let tempTime = timeStr2.split(":");
                finalTime = tempTime[0] + ":" + tempTime[1];
              }
              return (
                <>
                  {finalTime !== "" && (
                    <option value={finalTime}>{finalTime}</option>
                  )}
                </>
              );
            })}
          </select>
        </div>
        <div className="col-lg-11 col-md-11  tag_formCntrl ">
          <label className="m-0">Offer Details (optional)</label>
          <textarea
            id="descriptionField"
            cols="10"
            type="text"
            value={offerDetails}
            className="form-control "
            onChange={(e) => setOfferDetails(e.target.value)}
          />
        </div>
        <div className="col-lg-3 col-md-6 tag_formCntrl">
          <label className="m-0">Voucher Code (optional) </label>
          <input
            id="couponCodeObject"
            type="text"
            value={couponCodeObject}
            onChange={(obj) => setCouponCodeObject(obj.target.value)}
            className="form-control"
          />
        </div>
        <div className="col-lg-3 col-md-6 tag_formCntrl">
          <label className="m-0">Link to redeem offer (optional)</label>
          <input
            id="redeemOnlineUrlObject"
            type="text"
            value={redeemOnlineUrlObject}
            onChange={(obj) => setRedeemOnlineUrlObject(obj.target.value)}
            className="form-control"
          />
        </div>
        <div className="col-lg-3 col-md-6 tag_formCntrl">
          <label className="m-0">Terms and Conditions (optional)</label>
          <input
            id="termsAndConditionsObject"
            type="text"
            value={termsAndConditionsObject}
            className="form-control"
            onChange={(obj) => setTermsAndConditionsObject(obj.target.value)}
          />
        </div>
        {/* <div className="col-lg-12 col-md-12 col-sm-12">
        <Link
          to="/googlepreview"
          state={{
            title: eventTitle,
            bannerImages: imageUrl.length > 0 ? imageUrl : [],
            summary: offerDetails,
            startDate: startDate,
            startTime: startTime,
            endDate: endDate,
            endTime: endTime,
            completeLocationData: location.state.completeLocationData,
            selectedLocations: location.state.selectedLocations,
            callToAction: {
              actionType: $("#callToActionButtons").val(),
              url: $("#callToActionURL").val(),
            },
            offer: {
              ...($("#couponCodeObject").val() !== "" && {
                couponCode: $("#couponCodeObject").val(),
              }),
              ...($("#redeemOnlineUrlObject").val() !== "" && {
                redeemOnlineUrl: $("#redeemOnlineUrlObject").val(),
              }),
              ...($("#termsAndConditionsObject").val() !== "" && {
                termsConditions: $("#termsAndConditionsObject").val(),
              }),
            },
            eventType: "OFFER",
          }}
        >
          <button type="button" className="btn btn-save mr-3 tag_submitBtn">
            Preview
          </button>
        </Link>
        <button type="button" className="btn btn-save tag_submitBtn">
            Submit
          </button>
      </div> */}

        <div className="col-lg-11 col-md-11 tag_formCntrl">
          <div className="button-wrapper pb-2 mb-3">
            {/* <Link
              to="/googlepreview"
              state={{
                title: eventTitle,
                bannerImages: imageUrl.length > 0 ? imageUrl : [],
                summary: offerDetails,
                startDate: startDate,
                startTime: startTime === "" ? "00:00" : startTime,
                endDate: endDate,
                endTime: endTime === "" ? "23:59" : endTime,
                completeLocationData: location.state.completeLocationData,
                selectedLocations: location.state.selectedLocations,
                callToAction: {
                  actionType: $("#callToActionButtons").val(),
                  url: $("#callToActionURL").val(),
                },
                offer: {
                  ...(couponCodeObject !== "" && {
                    couponCode: couponCodeObject,
                  }),
                  ...(redeemOnlineUrlObject !== "" && {
                    redeemOnlineUrl: redeemOnlineUrlObject,
                  }),
                  ...(termsAndConditionsObject !== "" && {
                    termsConditions: termsAndConditionsObject,
                  }),
                },
                eventType: "OFFER",
              }}
            > */}
            <button
              type="button"
              className="btn-save ml-3 "
              onClick={() => validatePreview()}
            >
              Upload & Preview
            </button>
            {/* </Link> */}
          </div>{" "}
        </div>
      </div>

      <div className="loder_div" hidden></div>
    </div>
  );
}

export default PostOffer;
