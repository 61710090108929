import React, { useState, useEffect } from 'react';
import axios from "axios";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import { getCampaign } from '../../apiService'
import { backendApi, apiCallingPost } from '../../apiService';


export default function Campaigns(props) {
  const [userid, setUserID] = useState('3332668366947745');
  const [campaigns, setCampaigns] = useState([]);

  const columns = [
    { field: 'id', header: 'ID' },
    { field: 'account_id', header: 'Account' },
    { field: 'created_time', header: 'Created' },
    { field: 'budget_remaining', header: 'Budget' },
    { field: 'name', header: 'Name' },
    { field: 'action', header: 'Action' }
  ];

  useEffect(() => {
    const credentialReq =
    {
      "functionName": "get_campaigns",
      "site_id":"58"
    }
    backendApi(credentialReq).then((data) => {
      console.log("campaignn.....w...",data);

      setCampaigns(JSON.parse(data.body).data);
      console.log("campaignn........",JSON.parse(data.body).data);
    }).catch(() => {

    })
  }, [props.data])

  const dynamicColumns = columns.map((col, i) => {
    return col.field == 'action' ? <Column key={col.field} body={<a href="google.com">*</a>} field={col.field} header={col.header} /> : <Column key={col.field} field={col.field} header={col.header} />;
  });

  return (<div>
    <div className="card">
      {/* <DataTable value={campaigns} responsiveLayout="scroll">
              {dynamicColumns}
          </DataTable> */}
      {/* ============ */}
      <div className="col-md-12 tableResponsive">
        <div className="tableOuterWidth">
          <table className="table">
            <thead>
              {console.log("*********************", campaigns)}
              <tr>
                <th className="col-2">ID</th>
                <th className="col-2">Account</th>
                <th className="col-1">Created</th>
                <th className="col-1">Budget</th>
                <th className="col-2">Name</th>
                <th className="col-1">objective</th>
              </tr>
            </thead>
            <div className="table_Outerscroll">
              {campaigns.map((object, index) => (
                <tbody className="table_Outertbody">
                  <tr>
                    <td className="col-2">{object.id}</td>
                    <td className="col-2">
                      {object.account_id}
                    </td>
                    <td className="col-1">
                      {object.created_time}
                    </td>
                    <td className="col-1">
                      {object.budget_remaining}
                    </td>
                    <td className="col-2">
                      {object.name}
                    </td>
                    <td className="col-1">
                      {object.objective}
                    </td>

                  </tr>
                </tbody>))}

            </div>
          </table>

        </div>
        {/* <div className="pagination_main  tag_postListNext">
              <Pagination
                hideDisabled
                activePage={currentPage}
                itemsCountPerPage="25"
                totalItemsCount={totalCount}
                pageRangeDisplayed="10"
                onChange={(e) => handleActivePage(e)}
              />
            </div> */}
      </div>
      {/* ========== */}

    </div>
  </div>)
}