import React, { useState, useEffect } from "react";
import LeftMenu from "../../layout/leftMenu";
import $ from "jquery";
import Header from "../../layout/header";
import Accessibiltyimage from "../../images/left_icons/GBP.png";
import { apiCallingPost } from "../../apiService";
import NewInsightActionGraph from "../newInsightsGraph";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
export default function NewGBPInsights() {
  const [globalFilterLocationsList, setGlobalFilterLocationsList] = useState(
    []
  );
  const [tempFilterLocationsList, setTempFilterLocationsList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [businessNameFilter, setBusinessNameFilter] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [metricDataXAxis, setMetricDataXAxis] = useState([]);
  const [metricDataYAxis, setMetricDataYAxis] = useState([]);
  const [metricDataYAxis2, setMetricDataYAxis2] = useState([]);
  const [metricDataYAxis3, setMetricDataYAxis3] = useState([]);
  const [metricDataYAxis4, setMetricDataYAxis4] = useState([]);
  const [metricDataYAxis5, setMetricDataYAxis5] = useState([]);
  const [metricDataYAxis6, setMetricDataYAxis6] = useState([]);
  const [metricDataYAxis7, setMetricDataYAxis7] = useState([]);
  const [metricDataYAxis8, setMetricDataYAxis8] = useState([]);
  const [metricDataYAxis9, setMetricDataYAxis9] = useState([]);
  const [selectedLocationsArray, setSelectedLocationsArray] = useState([]);
  useEffect(() => {
    getAdministrativeArea();
    initialData();
    getGraphData();
    // getAccountLocations();
  }, []);

  //?Function to get list of all Locations
  // const getAccountLocations = () => {
  //   $(".loder_div").attr("hidden", false);
  //   const reviewAPIData = {
  //     functionName: "getAccountLocations",
  //     siteId:
  //       window.sessionStorage.getItem("switchingOrgId") === ""
  //         ? window.sessionStorage.getItem("organizationId")
  //         : window.sessionStorage.getItem("switchingOrgId"),
  //     accounts_id: window.sessionStorage.getItem("account_id"),
  //   };
  //   apiCallingPost(reviewAPIData).then((data) => {
  //     if (data.success === "1") {
  //       $(".loder_div").attr("hidden", true);
  //       this.setState({
  //         accountList: data.data,
  //         backupAccountList: data.data,
  //       });
  //     } else {
  //       $(".loder_div").attr("hidden", true);
  //     }
  //   });
  // };

  //?Function to get initial data
  const goToInsights = () => {
    window.location.href = "/insightsScheduler";
  };

  const initialData = () => {
    const businessAccountData = {
      functionName: "getLocalityUnderAdministrativeArea",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea: "",
    };
    // $(".loder_div").attr("hidden", false);
    apiCallingPost(businessAccountData).then(async (data) => {
      setCityList(data.data.map((obj) => obj.locality));
      const businessAccountData2 = {
        functionName: "getLocationSearch",
        account_id: window.sessionStorage.getItem("account_id"),
        AdministrativeArea: "",
        locality: "",
      };
      apiCallingPost(businessAccountData2).then((newObj) => {
        setGlobalFilterLocationsList(
          newObj.data.map((data) => ({
            ...data,
            value: data.location_id,
            label: `${data.storeCode}- ${data.title}-${data.addressLines},
            ${data.locality},${data.AdministrativeArea}`,
          }))
        );
        setTempFilterLocationsList(
          newObj.data.map((data) => ({
            ...data,
            value: data.location_id,
            label: data.storeCode,
          }))
        );
      });
    });
  };

  //?Function to get list of states
  const getAdministrativeArea = () => {
    const businessAccountData = {
      functionName: "getAdministrativeArea",
      account_id: window.sessionStorage.getItem("account_id"),
    };
    apiCallingPost(businessAccountData).then((data) => {
      if (data.success === "1") {
        setStateList(data.data.map((obj) => obj.AdministrativeArea));
      } else {
        setStateList([]);
        // $(".loder_div").attr("hidden", true);
      }
    });
  };

  //?Function to handle state change
  const handleChangeState = (value) => {
    if (!value) {
      setSelectedState("");

      //   this.setState({
      //     administrativeArea: "",
      //     localityList: "",
      //     cityLocationList: [],
      //     globalFilterLocationsList: this.state.tempSearchLocationsList,
      //   });
      return;
    }
    setBusinessNameFilter("");
    const businessAccountData = {
      functionName: "getLocalityUnderAdministrativeArea",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea: value,
    };
    apiCallingPost(businessAccountData).then(async (data) => {
      if (data.success === "1") {
        await getLocationSearch(value, "");
        // const businessAccountData = {
        //   functionName: "getLocationSearch",
        //   account_id: window.sessionStorage.getItem("account_id"),
        //   AdministrativeArea: value,
        //   locality: "",
        // };
        // apiCallingPost(businessAccountData).then((response) => {
        //   setGlobalFilterLocationsList(response.data);
        // });
        setCityList(data.data.map((obj) => obj.locality));
        setSelectedState(value);
      } else {
        setCityList([]);
      }
    });
  };

  //?Function to handle city change
  const handleChangeCity = async (value, AdministrativeArea) => {
    if (!value) {
      return;
    }
    // const accountList = this.state.backupAccountList.filter(
    //   (eachAcc) => eachAcc.locality === value
    // );
    // this.setState({
    //   accountList,
    // });
    setBusinessNameFilter("");
    setSelectedCity(value);
    await getLocationSearch(selectedState, value);
  };

  //?GetLocaSearch api function
  const getLocationSearch = (state, city) => {
    return new Promise((resolve, reject) => {
      const businessAccountData = {
        functionName: "getLocationSearch",
        account_id: window.sessionStorage.getItem("account_id"),
        AdministrativeArea: state,
        locality: city,
      };
      apiCallingPost(businessAccountData).then((response) => {
        if (response.success === "1") {
          setGlobalFilterLocationsList(
            response.data.map((data) => ({
              ...data,
              value: data.location_id,
              label: `${data.storeCode}- ${data.title}-${data.addressLines},
                  ${data.locality},${data.AdministrativeArea}`,
            }))
          );
          resolve(true);
        } else {
          setGlobalFilterLocationsList([]);
          reject(false);
        }
      });
    });
  };
  //?Function to filter business by name
  const filterLocations = (e) => {
    setBusinessNameFilter(e.target.value);
    var array = [];
    if (selectedState != "" && selectedCity != "") {
      array = globalFilterLocationsList;
    } else {
      array = tempFilterLocationsList;
    }
    var filterArray = array.filter(
      (data) =>
        data.storeCode.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.addressLines
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        (
          data.storeCode +
          "," +
          data.addressLines +
          "," +
          data.locality +
          "," +
          data.AdministrativeArea
        )
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        (data.locality + "," + data.AdministrativeArea)
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
    );
    setGlobalFilterLocationsList(filterArray);
  };
  //?Function to get all the graph data
  const getGraphData = () => {
    if (startDate !== null && endDate !== null) {
      let millsStartDate = new Date(startDate).getTime();
      let millsEndDate = new Date(endDate).getTime();
      if (millsStartDate > millsEndDate) {
        alert("Invalid Dates given");
        return false;
      }
    }
    let locationsList = "";
    if (selectedLocationsArray.length > 0) {
      locationsList = selectedLocationsArray
        .map((obj) => `'${obj.location_id}'`)
        .join(",");
    }
    const apiReq = {
      functionName: "insightGBPAnlyticsNew",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      location_id: locationsList,
      administrativeArea: selectedState,
      locality: selectedCity,
      startDate: startDate,
      endDate: endDate,
    };
    apiCallingPost(apiReq).then((data) => {
      setMetricDataXAxis(
        data.data.map((obj) =>
          moment(new Date(obj.created_on)).format("DD-MMM-YYYY")
        )
      );
      setMetricDataYAxis(data.data.map((obj) => obj?.search_mobile));
      setMetricDataYAxis2(data.data.map((obj) => obj?.maps_mobile));
      setMetricDataYAxis3(data.data.map((obj) => obj?.search_desktop));
      setMetricDataYAxis4(data.data.map((obj) => obj?.maps_desktop));
      setMetricDataYAxis5(data.data.map((obj) => obj?.messages));
      setMetricDataYAxis6(data.data.map((obj) => obj?.calls));
      setMetricDataYAxis7(data.data.map((obj) => obj?.directions));
      setMetricDataYAxis8(data.data.map((obj) => obj?.websiteClicks));
      setMetricDataYAxis9(data.data.map((obj) => obj?.bookings));
      console.log(
        "🚀 ~ file: newGBPInsights.jsx:219 ~ apiCallingPost ~ data:",
        data
      );
    });
  };
  //?Function to clear data
  const getClearData = () => {
    setSelectedState("");
    setSelectedCity("");
    setCityList([]);
    setSelectedLocation("");
    setBusinessNameFilter("");
    setStartDate(null);
    setEndDate(null);
    setSelectedLocationsArray([]);
    const apiReq = {
      functionName: "insightGBPAnlyticsNew",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      location_id: "",
      administrativeArea: "",
      locality: "",
    };
    apiCallingPost(apiReq).then((data) => {
      setMetricDataXAxis(
        data.data.map((obj) =>
          moment(new Date(obj.created_on)).format("DD-MMM-YYYY")
        )
      );
      setMetricDataYAxis(data.data.map((obj) => obj?.search_mobile));
      setMetricDataYAxis2(data.data.map((obj) => obj?.maps_mobile));
      setMetricDataYAxis3(data.data.map((obj) => obj?.search_desktop));
      setMetricDataYAxis4(data.data.map((obj) => obj?.maps_desktop));
      setMetricDataYAxis5(data.data.map((obj) => obj?.messages));
      setMetricDataYAxis6(data.data.map((obj) => obj?.calls));
      setMetricDataYAxis7(data.data.map((obj) => obj?.directions));
      setMetricDataYAxis8(data.data.map((obj) => obj?.websiteClicks));
      setMetricDataYAxis9(data.data.map((obj) => obj?.bookings));
      console.log(
        "🚀 ~ file: newGBPInsights.jsx:219 ~ apiCallingPost ~ data:",
        data
      );
    });
  };
  //?Function to handle location select
  const handleLocationsSelectChange = (selectedOption) => {
    setSelectedLocationsArray(selectedOption);
  };
  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header heading="Traffic Insights" headerImage={Accessibiltyimage} />
        <div className="container-fluid">
          <div className="row row-search px-2 mt-2">
            <div className="col-lg-2 col-md-4 col-sm-6">
              <select
                className="form-control"
                id="stateId"
                value={selectedState}
                onChange={(e) => handleChangeState(e.target.value)}
              >
                <option value="">State</option>
                {stateList.map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <select
                className="form-control"
                id="cityId"
                value={selectedCity}
                onChange={(e) =>
                  handleChangeCity(e.target.value, selectedState)
                }
              >
                <option value="">City</option>
                {cityList.map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-lg-4 col-md-4 location_search">
              <Select
                key={selectedLocationsArray}
                options={globalFilterLocationsList}
                isMulti
                value={selectedLocationsArray}
                onChange={handleLocationsSelectChange}
              />

              {/* <input
                className="form-control"
                value={businessNameFilter}
                onChange={(e) => filterLocations(e)}
                placeholder="Search by location"
              />
              <div className="autocomplete__list location_search_drop">
                <ul class="nav flex-column">
                  {globalFilterLocationsList.map((data) => (
                    <li
                      key={data.location_id}
                      onMouseDown={
                        () => {
                          setSelectedLocation(data.location_id);
                          setBusinessNameFilter(`${data.storeCode}- ${data.title}-${data.addressLines},
                            ${data.locality},${data.AdministrativeArea}`);
                        }
                      }
                    >
                      {data.storeCode}- {data.title}-{data.addressLines},
                      {data.locality},{data.AdministrativeArea}
                    </li>
                  ))}
                </ul>
              </div> */}
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <ReactDatePicker
                showMonthDropdown
                showYearDropdown
                dateFormat="dd/MM/yyyy"
                placeholderText="Start Date"
                selected={startDate}
                maxDate={new Date().setDate(new Date().getDate() - 10)}
                onChange={(date) => setStartDate(date)}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <ReactDatePicker
                showMonthDropdown
                showYearDropdown
                dateFormat="dd/MM/yyyy"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                maxDate={new Date().setDate(new Date().getDate() - 10)}
                placeholderText="End Date"
              />
            </div>
            <div className="col-lg-1 col-md-4 col-sm-6 pr-0">
              <button
                className="btn-search w-100"
                onClick={() => getGraphData()}
              >
                Search
              </button>
            </div>
            <div className="col-lg-1 col-md-4 col-sm-6 pr-0">
              <button
                className="btn-clear-search w-100"
                onClick={() => getClearData()}
              >
                Clear
              </button>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 pr-0">
              <button
                className="btn-search "
                onClick={() => goToInsights()}
              >
                Insights Scheduler
              </button>
            </div>
          </div>
          <hr />
          <div className="customer_actionDiv insight_borderbtm">
            <h3>Customer Actions</h3>
            <div className="customer_actionGraphDiv">
              <NewInsightActionGraph
                key={metricDataXAxis}
                XAxisData={metricDataXAxis}
                YAxisData={metricDataYAxis7}
                YAxisData2={metricDataYAxis8}
                YAxisData3={metricDataYAxis6}
                YAxisName="Request Directions"
                YAxisName2="Web Clicks"
                YAxisName3="Phone Calls"
              />
            </div>
          </div>
          <hr />
          <div className="customer_actionDiv insight_borderbtm">
            <h3>Google Maps and Search</h3>
            <div className="customer_actionGraphDiv">
              <NewInsightActionGraph
                key={metricDataXAxis}
                XAxisData={metricDataXAxis}
                YAxisData={metricDataYAxis}
                YAxisData2={metricDataYAxis2}
                YAxisData3={metricDataYAxis3}
                YAxisData4={metricDataYAxis4}
                YAxisName="Google Search - Mobile"
                YAxisName2="Google Maps - Mobile"
                YAxisName3="Google Search - Desktop"
                YAxisName4="Google Maps - Desktop"
              />
            </div>
          </div>
          <hr />
          <div className="customer_actionDiv insight_borderbtm">
            <h3>Directions and Bookings</h3>
            <div className="customer_actionGraphDiv">
              <NewInsightActionGraph
                key={metricDataXAxis}
                XAxisData={metricDataXAxis}
                YAxisData={metricDataYAxis5}
                YAxisData3={metricDataYAxis9}
                YAxisName="Messages"
                YAxisName3="Bookings"
                // YAxisData4={metricDataYAxis4}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
