import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { backendApi, pythonBackendApi } from "../apiService";
import ProductCatalogimage from "../images/left_icons/Product-Catalog.png";
import * as XLSX from "xlsx";
import $ from "jquery";
import dataProcessImg from "../images/dataProcess.gif";
import Pagination from "react-js-pagination";
const LiveDetails = (props) => {
  const [excelData, setExcelData] = useState([]);
  // const [title,setTitle] = useState('')
  const [uploaded, setUploaded] = useState(false);
  const [completedCount, setCompletedCount] = useState(0);
  const [notStartedCount, setNotStartedCount] = useState(0);
  const [failedCount, setFailedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [processingCount, setProcessingCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [processStatus, setProcessStatus] = useState([]);
  const location = useLocation();
  let pathname = location.pathname;
  var path = pathname.split("/")[2];
  const [tableId, settableId] = useState(path);
  useEffect(() => {
    getProductCatalogByMasterId();
    // getLiveData();
  }, []);
  // setInterval(()=>{
  //   getProductCatalogByMasterId();
  // },60000)
  // const getLiveData = () => {
  //   var req = {
  //     functionName: "liveProductCatalog",
  //     siteId:
  //       window.sessionStorage.getItem("switchingOrgId") === ""
  //         ? window.sessionStorage.getItem("organizationId")
  //         : window.sessionStorage.getItem("switchingOrgId"),
  //   };
  // };
  const startProcess = () => {
    $(".dataProcessImg").show();
    $(".loder_div").attr("hidden", false);
    var apidata = {
      method: "POST",
      body: JSON.stringify({
        masterId: tableId,
        createdBy:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        comment: $("#editCommentPopupTextBox").val(),
      }),
    };
    pythonBackendApi("processExcelData", apidata);
    $(".loder_div").attr("hidden", true);
    getProductCatalogByMasterId();
    $(".dataProcessImg").hide();
  };

  const getProductCatalogByMasterId = (i) => {
    $("#savebutton" + i).attr("hidden", true);
    $("#searchAddressvaluetext" + i).attr("hidden", true);
    $("#accountNametext" + i).attr("hidden", true);
    $("#categorytext" + i).attr("hidden", true);
    $("#productText" + i).attr("hidden", true);
    $("#searchCustomeraddress" + i).show();
    $("#accountName" + i).show();
    $("#category" + i).show();
    $("#product" + i).show();
    $("#titleEditIconMatch" + i).attr("hidden", false);
    $("#titleEditIconMatch" + i).removeAttr("hidden");
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "getProductCatalogByMasterId",
      masterId: tableId,
      offset: offset,
    };

    backendApi(req).then((data) => {
      if (data.success === "1") {
        console.log(data,"ooooooooooooooooooooooooooooo");
        $(".loder_div").attr("hidden", true);
        setProcessStatus(data.processStatus)
        setTotalCount(data.count[0].totalCount);
        setExcelData(data.data);
        setUploaded(false);
        // var filteredArray = excelData.filter(obj =>obj.process_status === "Completed").map(event=>event.id)
        var completedCount = 0;
        var notStartedCount = 0;
        var failedCount = 0;
        var processingCount = 0;
        excelData.forEach((element) => {
          if (element.process_status === "Completed") completedCount++;
          else if (element.process_status === "Not Started") notStartedCount++;
          else if (element.process_status.includes("Failed")) failedCount++;
          else if (element.process_status === "Processing") processingCount++;
        });
        setCompletedCount(completedCount);
        setFailedCount(failedCount);
        setNotStartedCount(notStartedCount);
        setProcessingCount(processingCount);
        // alert(completedCount+" completedCount "+notStartedCount+" notStartedCount "+failedCount+" failedCount "+processingCount+" processingCount ")
      } else if (data.success === "0") {
        alert("Unable to fetch product catalog data");
        $(".loder_div").attr("hidden", true);
      }
    });
  };
  const processProductCatalogData = (id, accNo) => {
    $(".loder_div").attr("hidden", false);
    var req = {
      method: "POST",
      body: JSON.stringify({
        createdBy:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        account_number: accNo,
      }),
    };
    pythonBackendApi("processProductCatalogData", req);
    $(".loder_div").attr("hidden", true);
    alert("Processed Successfully");
    getProductCatalogByMasterId();
  };
  const exportFailedData = () => {
    let postData = {
      functionName: "downloadProductCatalogByStatus",
      status: "failed",
      masterId: tableId,
    };
    backendApi(postData).then((data) => {
      console.log("dataaaaaaa", data.data);
      if (data.success === "1") {
        $(".loder_div").attr("hidden", false);
        var dataArray = [];
        // let filteredArray = excelData.filter(
        //   (obj) => obj.process_status === "Failed"
        // );
        for (let i = 0; i < data.data.length; i++) {
          let obj = {};
          obj.Name = data.data[i].resource;
          obj.caseTitle = data.data[i].case_title;
          obj.accountName = data.data[i].account_name;
          obj.accountAddress = data.data[i].account_address;
          // obj.product = data.data[i].products.replaceAll("\n", " ");
          obj.process_status = data.data[i].process_status;
          dataArray.push(obj);
        }
        let header = [
          "Name",
          "Case Title",
          "Account Name",
          "Account Address",
          "Process Status",
        ];
        $(".loder_div").attr("hidden", true);
        const worksheet = XLSX.utils.json_to_sheet(dataArray);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          "FailedProcessessList"
        );
        XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: "A1" });
        XLSX.writeFile(workbook, "FailedProcessessList.xlsx", {
          compression: true,
        });
      } else {
        alert("Failed data is not available");
      }
    });
  };

  const exportCompletedData = () => {
    let postData = {
      functionName: "downloadProductCatalogByStatus",
      status: "success",
      masterId: tableId,
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(postData).then((data) => {
      console.log("dataaaaaaa", data.data);
      if (data.success === "1") {
        $(".loder_div").attr("hidden", false);
        var dataArray = [];
        for (let i = 0; i < data.data.length; i++) {
          let obj = {};
          obj.Name = data.data[i].resource;
          obj.caseTitle = data.data[i].case_title;
          obj.accountName = data.data[i].account_name;
          obj.accountAddress = data.data[i].account_address;
          // obj.product = data.data[i].products.replaceAll("\n", " ");
          obj.process_status = data.data[i].process_status;
          dataArray.push(obj);
        }
        let header = [
          "Name",
          "Case Title",
          "Account Name",
          "Account Address",
          "Process Status",
        ];
        $(".loder_div").attr("hidden", true);
        const worksheet = XLSX.utils.json_to_sheet(dataArray);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "CompletedProcess");
        XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: "A1" });
        XLSX.writeFile(workbook, "CompletedProcessList.xlsx", {
          compression: true,
        });
      } else if (data.success === "0") {
        // alert("Unable to fetch product catalog data")
        // $(".loder_div").attr("hidden", true);
      }
    });
    console.log("postdataa", postData);
  };
  const exportAllData = () => {
    let postData = {
      functionName: "downloadProductCatalogByStatus",
      status: "",
      masterId: tableId,
    };
    backendApi(postData).then((data) => {
      console.log("dataaaaaaa", data.data);
      if (data.success === "1") {
        $(".loder_div").attr("hidden", false);
        var dataArray = [];
        // let filteredArray = excelData.filter(
        //   (obj) => obj.process_status === "Not Started"
        // );
        for (let i = 0; i < data.data.length; i++) {
          let obj = {};
          obj.Name = data.data[i].resource;
          obj.caseTitle = data.data[i].case_title;
          obj.accountName = data.data[i].account_name;
          obj.accountAddress = data.data[i].account_address;
          // obj.product = data.data[i].products
          //   .replaceAll("\n", " ")
          //   .replaceAll(":", " ");
          obj.process_status = data.data[i].process_status;
          dataArray.push(obj);
        }
        let header = [
          "Name",
          "Case Title",
          "Account Name",
          "Account Address",
          "Process Status",
        ];
        $(".loder_div").attr("hidden", true);
        const worksheet = XLSX.utils.json_to_sheet(dataArray);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "AllDataList");
        XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: "A1" });
        XLSX.writeFile(workbook, "AllDataList.xlsx", {
          compression: true,
        });
      }
    });
  };
  const exportNotStartedData = () => {
    let postData = {
      functionName: "downloadProductCatalogByStatus",
      status: "Not Started",
      masterId: tableId,
    };
    backendApi(postData).then((data) => {
      console.log("dataaaaaaa", data.data);
      if (data.success === "1") {
        $(".loder_div").attr("hidden", false);
        var dataArray = [];
        // let filteredArray = excelData.filter(
        //   (obj) => obj.process_status === "Not Started"
        // );
        for (let i = 0; i < data.data.length; i++) {
          let obj = {};
          obj.Name = data.data[i].resource;
          obj.caseTitle = data.data[i].case_title;
          obj.accountName = data.data[i].account_name;
          obj.accountAddress = data.data[i].account_address;
          // obj.product = data.data[i].products
          //   .replaceAll("\n", " ")
          //   .replaceAll(":", " ");
          obj.process_status = data.data[i].process_status;
          dataArray.push(obj);
        }
        let header = [
          "Name",
          "Case Title",
          "Account Name",
          "Account Address",
          "Process Status",
        ];
        $(".loder_div").attr("hidden", true);
        const worksheet = XLSX.utils.json_to_sheet(dataArray);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "NotStartedList");
        XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: "A1" });
        XLSX.writeFile(workbook, "NotStartedList.xlsx", {
          compression: true,
        });
      }
    });
  };

  const editData = (i, a) => {
    $("#searchCustomeraddress" + i).hide();
    $("#searchAddressvaluetext" + i).attr("hidden", false);
    $("#accountName" + i).hide();
    $("#category" + i).hide();
    $("#accountNametext" + i).attr("hidden", false);
    $("#categorytext" + i).attr("hidden", false);
    $("#product" + i).hide();
    $("#productText" + i).attr("hidden", false);
    $("#savebutton" + i).attr("hidden", false);
    $("#titleEditIconMatch" + i).attr("hidden", true);
  };
  const save = (i, title, name, accountNumber, product, id) => {
    if (
      $("#searchAddressvaluetext" + i).val() === "" &&
      $("#accountNametext" + i).val() === "" &&
      $("#categorytext" + i).val() === "" &&
      $("#productText" + i).val() === ""
    ) {
      alert("Please Fill Details");
      return false;
    }
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "updateProductCatalog",
      caseTitle: $("#searchAddressvaluetext" + i).val(),
      accountName: $("#accountNametext" + i).val(),
      category: $("#categorytext" + i).val(),
      accountNumber: accountNumber,
      products: $("#productText" + i).val(),
      id: id,
    };
    console.log("reqqqqqqqqqqqqq", req);
    backendApi(req).then((data) => {
      if (data.success === "1") {
        let dataIdValue = id;
        $("#saveSuccess" + id).css("display", "block");
        setTimeout(() => {
          $("#saveSuccess" + id).css("display", "none");
        }, 3000);
        $(".loder_div").attr("hidden", true);
        getProductCatalogByMasterId(i);
      } else {
        alert("Something went wrong while updating Search Criteria");
        $(".loder_div").attr("hidden", true);
      }
    });
  };
  const handleActivePage = (e) => {
    var offset;
    if (e === "1") {
      offset = "0";
    } else {
      offset = parseInt(e - 1) * 25;
    }
    setCurrentPage(e);
    setOffset(offset);
    getProductCatalogByMasterId();
  };
  const getStatusClass = (status) => {
    switch (status) {
        case "Not Started":
            return "btn-not-started";
        case "Failed":
            return "btn-failed";
        case "Running":
            return "btn-running";
        case "Completed":
            return "btn-completed";
        default:
            return "btn-default"; // A fallback class if needed
    }
};

  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header heading="Product Catalog" headerImage={ProductCatalogimage} />
        <div className="container">
          <div className="back_tocatalog row">
            <div className="col-lg-1 d-flex">
              <div className="back_tocatalog">
                <div className="back_tocatalog_main">
                  <Link to="/home">
                    <Icon.ArrowLeftCircle className="back-to-link" />
                    {/* Back to Product Catalog */}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-11 settings_form">
            {processStatus.map((data) => (
                <button
                    key={data.process_status}
                    type="button"
                    className={`btn ${getStatusClass(data.process_status)}`}
                    // onClick={() => startProcess(data.process_status)} // Example action
                >
                    {data.process_status}: {data.cnt}
                </button>
            ))}
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => startProcess()}
              >
                Start Process
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => exportCompletedData()}
              >
                Download Completed Data
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={() => exportNotStartedData()}
              >
                Download Not Started Data
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => exportFailedData()}
              >
                Download Failed Data
              </button>

              <button
                type="button"
                className="btn btn-download"
                onClick={() => exportAllData()}
              >
                Download All Data
              </button>
            </div>
            {totalCount > 100 && (
              <Pagination
                hideDisabled
                activePage={currentPage}
                itemsCountPerPage={100}
                totalItemsCount={totalCount}
                onChange={(e) => handleActivePage(e)}
              />
            )}
          </div>
          <div className="dataProcessImg" style={{ display: "none" }}>
            <img src={dataProcessImg} alt="data process"></img>
            <div className="dataProcessLabel">Label label label.</div>
          </div>
          <div className="col-md-12 tableResponsive">
            <div className="tableOuterWidth">
              <table className="table">
                <thead>
                  <tr>
                    <th className="col-1" style={{ maxWidth: "5%" }}>
                      Sl.no
                    </th>
                    <th className="col-1" style={{ maxWidth: "11.66%" }}>
                      Name
                    </th>
                    <th className="col-2">Case Title</th>
                    <th className="col-2">Account Name</th>
                    <th className="col-1">Category</th>
                    <th className="col-2">Description/Products</th>
                    <th className="col-1">Status</th>
                    <th className="col-2">Action</th>
                  </tr>
                </thead>
                <span id="dataIdValue" hidden></span>
                <div
                  className="table_Outerscroll"
                  style={{ overflow: "overlay" }}
                >
                  {excelData.length !== 0 ? (
                    <>
                      {excelData.map((data, i) => (
                        <tbody className="table_Outertbody" key={data.id}>
                          <tr>
                            <td className="col-1" style={{ maxWidth: "5%" }}>
                              {i + 1}
                            </td>
                            <td
                              className="col-1"
                              style={{ maxWidth: "11.66%" }}
                            >
                              {data.resource}
                            </td>
                            <td className="col-2">
                              <span id={"searchCustomeraddress" + i}>
                                {data.case_title}
                              </span>
                              <textarea
                                className="form-control searchEditInput"
                                id={"searchAddressvaluetext" + i}
                                hidden
                              ></textarea>
                            </td>
                            <td className="col-2">
                              <span
                                id={"accountName" + i}
                                style={{
                                  fontFamily: "OpenSans SemiBold",
                                  textDecoration: "underline",
                                }}
                              >
                                {data.account_name}
                              </span>
                              <span style={{ display: "block" }}>
                                {data.account_address}
                              </span>
                              <textarea
                                className="form-control searchEditInput"
                                id={"accountNametext" + i}
                                hidden
                              ></textarea>
                            </td>
                            <td className="col-1">
                              <span id={"category" + i}>{data.category}</span>
                              <textarea
                                className="form-control searchEditInput"
                                id={"categorytext" + i}
                                hidden
                              ></textarea>
                            </td>
                            <td className="col-2">
                              <span id={"product" + i}>{data.products}</span>
                              <textarea
                                className="form-control searchEditInput"
                                id={"productText" + i}
                                hidden
                              ></textarea>
                            </td>
                            <td className="col-1">{data.process_status} </td>
                            <td className="col-2">
                              <div className="d-flex align-items-center">
                                {data.process_status !== "Completed" &&
                                  data.process_status !== "Processing" && (
                                    <span
                                      className="btn-start"
                                      onClick={() =>
                                        processProductCatalogData(
                                          data.id,
                                          data.account_number
                                        )
                                      }
                                    >
                                      Process
                                    </span>
                                  )}
                                {data.process_status !== "Completed" ? (
                                  <div className="searchbuttonDiv ml-auto pr-2">
                                    <Icon.Edit
                                      width="18"
                                      className="audit-search-icon"
                                      id={"titleEditIconMatch" + i}
                                      onClick={() => {
                                        editData(
                                          i,
                                          data.case_title,
                                          data.account_name
                                        );
                                        $("#searchAddressvaluetext" + i).val(
                                          data.case_title
                                        );
                                        $("#categorytext" + i).val(
                                          data.category
                                        );
                                        $("#accountNametext" + i).val(
                                          data.account_name
                                        );
                                        $("#productText" + i).val(
                                          data.products
                                        );
                                        $("#dataIdValue").text(data.id);
                                      }}
                                    />
                                    <span id={"savebutton" + i} hidden>
                                      <Icon.Save
                                        width="18px"
                                        className="audit-save-icon m-0"
                                        onClick={() =>
                                          save(
                                            i,
                                            data.case_title,
                                            data.account_name,
                                            data.account_number,
                                            data.products,
                                            data.id
                                          )
                                        }
                                      />
                                    </span>
                                    {/* <span
                                  className="success-msg success_livedetails"
                                  id={"saveSuccess" + data.id}
                                >
                                  Saved successfully
                                </span> */}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                          </tr>
                          <span
                            className="success-msg"
                            id={"saveSuccess" + data.id}
                          >
                            Saved successfully
                          </span>
                        </tbody>
                      ))}
                    </>
                  ) : (
                    <tr>No Data Available</tr>
                  )}
                </div>
              </table>
            </div>
            {totalCount > 100 && (
              <Pagination
                hideDisabled
                activePage={currentPage}
                itemsCountPerPage={100}
                totalItemsCount={totalCount}
                onChange={(e) => handleActivePage(e)}
              />
            )}
          </div>
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
};
export default LiveDetails;
