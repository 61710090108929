import React, { Component } from "react";
import * as Icon from "react-feather";
import Dashboardimage from "../images/left_icons/dashboard.png";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import OrganisationLeading from "./organisation-leading";
import OrganisationReport from "./organisation-report";
import Tab2 from "./tab2.jsx";
import Tab4 from "./tab4.jsx";
import ProductType from "./product-type";
class OrganisationDivision extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    return (
      <div className="main_wrapper dsh-wrapper">
        <LeftMenu />
        <div className="userListWrapper userListWrapper-dash">
          <Header heading="Dashboard Settings" headerImage={Dashboardimage} />
          <div className="container-fluid tabs-main-wrapper">

            <Tabs>
              <TabList>
                {/* <Tab>Organization Settings</Tab> */}
                <Tab>Organization Division</Tab>
                <Tab>Division Mapping</Tab>
                {/* <Tab>Budget Settings</Tab> */}
                <Tab>Budget Settings</Tab>
              </TabList>
              {/* <TabPanel>
                <ProductType />
              </TabPanel> */}
              <TabPanel>
                <OrganisationLeading />
              </TabPanel>
              <TabPanel>
                <OrganisationReport />
              </TabPanel>
              {/* <TabPanel> */}
                {/* <Tab2 /> */}
              {/* </TabPanel> */}
              <TabPanel>
                <Tab4 />
              </TabPanel>
            </Tabs>
          </div>
          <Footer />
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}
export default OrganisationDivision;
