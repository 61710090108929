import React, { Component } from 'react'
import CustomerActionGraph from './customerAction_insightGraph'
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import Accessibiltyimage from "../images/left_icons/GBP.png";
import ReactDatePicker from "react-datepicker";
import AllSearches from "../GBP/all_searches";
import CustomerViewGraph from "../GBP/customerView_graph"
import PhoneCallsGraph from "../GBP/phoneCall_insightGraph"
import MapContainer from "../GBP/GoogleMap"
import * as Icon from "react-feather";
import { apiCallingPost } from "../apiService";
import $ from "jquery";
import DatePicker from "react-datepicker";
import moment from "moment";
import view from "../images/view-details.png"
import eye from "../images/view.png"
import handPhn from "../images/hand-phone.png"
import Camera from "../images/camera.png"
export default class InsightsGbp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      insightsStartDate: new Date(new Date().getFullYear(),new Date(). getMonth() - 1).toISOString(),
      insightsEndDate: new Date().toISOString(),

      insightsStartDatePhone: new Date(new Date().getFullYear(),new Date(). getMonth() - 1).toISOString(),
      insightsEndDatePhone: new Date().toISOString(),

      insightsStartDateCustomerView:  new Date(new Date().getFullYear(),new Date(). getMonth() - 1).toISOString(),
      insightsEndDateCustomerView: new Date().toISOString(),

      businessName: [],
      getaccountByLocationList: [],
      graphDataViewSearch: [],
      graphDataViewMap: [],
      graphDataPhone: [],
      graphDataWebsite: [],
      getMasterdataXaxis: [],
      graphRequestDirection: [],
      graphQueriesDirect: 0,
      graphQueriesInDirect: 0,
      graphQueriesChain: 0,
      garphphotoViewMerchant: [],
      garphphotoViewCustomer: [],
      garphphotoCountMerchant: [],
      garphphotoCountCustomer: [],
      getAllGraphData: [],
      locationValues: [],
      stateValues: [],
      cityValues: [],
      startDate: "",
      endDate: "",

      currentDataViewSearch:0,
      lastQuaterDataViewSearch: 0,
      lastYearDataViewSearch: 0,

      currentDataViewMap: 0,
      lastQuaterDataViewMap:0,
      lastYearDataViewMap: 0,

      currentWebsite: 0,
      lastQuaterWebsite: 0,
      lastYearWebsite: 0,

      currentPhone: 0,
      lastQuaterPhone: 0,
      lastYearPhone: 0,

      currentDirection: 0,
      lastQuaterDirection: 0,
      lastYearDirection: 0,

      currentphotoViewMerchant: 0,
      lastQuaterphotoViewMerchant: 0,
      lastYearphotoViewMerchant: 0,

      currentphotoViewCustomer: 0,
      lastQuaterphotoViewCustomer: 0,
      lastYearphotoViewCustomer: 0,

      currentphotoCountMerchant: 0,
      lastQuaterphotoCountMerchant: 0,
      lastYearphotoCountMerchant: 0,

      currentphotoCountCustomer: 0,
      lastQuaterphotoCountCustomer: 0,
      lastYearphotoCountCustomer: 0,

      currentDirect: 0,
      lastQuaterDirect: 0,
      lastYearDirect: 0,

      currentInDirect: 0,
      lastQuaterInDirect: 0,
      lastYearInDirect: 0,

      currentBranded: 0,
      lastQuaterBranded: 0,
      lastYearBranded: 0,

      locationLatLongArray: [],

      allLocationData: [],
      allAssertiveLocationData: [],
      administrativeAreaList: [],
      administrativeArea: "0",
      localityList: [],
      locality: "0",
      locationList: [],
      location: "0",
    };
    this.GetInsightsData = this.GetInsightsData.bind(this)
  }
  componentDidMount() {
    this.GetLocationsUnderAccount();
    this.GetInsightsData();
  }
  clearData() {
    this.setState({
      insightsStartDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ).toISOString(),

      insightsEndDate: new Date().toISOString(),
      insightsStartDatePhone: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ).toISOString(),

      insightsEndDatePhone: new Date().toISOString(),

      insightsStartDateCustomerView: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ).toISOString(),
      insightsEndDateCustomerView: new Date().toISOString(),
      getLocationTitle: [],
      getCityList: [],
      getTitleName: "",
      getCityName: "",
      businessName: [],
      getaccountByLocationList: [],
      graphDataViewSearch: [],
      graphDataViewMap: [],
      graphDataPhone: [],
      graphDataWebsite: [],
      getMasterdataXaxis: [],
      graphRequestDirection: [],
      graphQueriesDirect: 0,
      graphQueriesInDirect: 0,
      graphQueriesChain: 0,
      garphphotoViewMerchant: [],
      garphphotoViewCustomer: [],
      garphphotoCountMerchant: [],
      garphphotoCountCustomer: [],
      getAllGraphData: [],
      locality: "0",
      location: "0",
      administrativeArea: "0",
      startDate: "",
      endDate: "",
    }, () => {
      this.GetLocationsUnderAccount();
      this.GetInsightsData();
    })

  }
  GetLocationsUnderAccount(val) {
    const reviewAPIData = {
      functionName: "getMasterDataForFilter",

      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    console.log("reviewAPIData", reviewAPIData)
    apiCallingPost(reviewAPIData)
      .then(async (result) => {
        if (!result.data || result.success !== "1" ) {
          alert(result.errorMessage);
          $(".loder_div").attr("hidden", true);
          return;
        }
        const {
          data,
        } = result;
        // TODO: May need location filter with country. currently only running for india.
        this.setState({
          allLocationData: data,
          allAssertiveLocationData: data,
          administrativeAreaList: new Array(...new Set(data.map((eachLocation) => eachLocation.administrativeArea))),

          localityList: new Array(...new Set(data.map((eachLocation) => eachLocation?.locality))),
          locationList: data.map((eachLocation) => ({ locationId: eachLocation.location_id, location: `${eachLocation?.title} - ${eachLocation?.locality}` })),

          administrativeArea: "0",
        });

        $(".loder_div").attr("hidden", true);
      })
      .catch((error) => {
        console.trace(error);
        alert("Something went wrong in getting Location Data");
        $(".loder_div").attr("hidden", true);
      });

  }
  onStateSelect(administrativeArea) {
    this.setState({
      administrativeArea,
      localityList: new Array(...new Set(this.state.allLocationData.filter((eachLocation) => eachLocation?.administrativeArea === administrativeArea).map((eachLocation) => eachLocation?.locality))),
      allAssertiveLocationData: this.state.allLocationData.filter((eachLocation) => eachLocation?.administrativeArea === administrativeArea),
      locality: "0",
      location: "0",
    });
  }
  onCitySelect(locality) {
    this.setState({
      locality,
      locationList: this.state.allAssertiveLocationData.filter((eachLocation) => eachLocation?.locality === locality).map((eachLocation) => ({ locationId: eachLocation.location_id, location: `${eachLocation?.title} - ${eachLocation?.locality}` })),
      location: "0",
    });
  }
  onLocationSelect(location) {
    this.setState({ location });
  }
  handleChangeStart(value) {
    this.setState({
      startDate: value,
    });
  }

  handleChangeEnd(values) {
    this.setState({
      endDate: values,
    });
  }
  GetInsightsData() {

    var reqGetInsightsData =
    {
      "functionName": "GetInsightsData",
      "siteId": window.sessionStorage.getItem("switchingOrgId") === ""
        ? window.sessionStorage.getItem("organizationId")
        : window.sessionStorage.getItem("switchingOrgId"),
      "location_id": "",
      "startDate":moment(this.state.insightsStartDate).format("YYYY-MM-DD"),
      "endDate":moment(this.state.insightsEndDate).format("YYYY-MM-DD"),

    };
    console.log("reqGetInsightsData", reqGetInsightsData)

    $(".loder_div").attr("hidden", false);
    apiCallingPost(reqGetInsightsData).then((data) => {
      $(".loder_div").attr("hidden", true);
      console.log("data11", data.quarterData[0].VIEWS_SEARCH)
      if (data.success === "1") {
        var viewSearch = [];
        var viewMaps = [];
        var viewPhone = [];
        var viewWebsite = [];
        var createdDate = [];
        var requestDirection = [];
        var queriesDirect = [];
        var queriesInDirect = [];
        var queriesChain = [];
        var photoViewMerchant = [];
        var photoViewCustomer = [];
        var photoCountMerchant = [];
        var photoCountCustomer = [];

        data.data.forEach((object) => {
          createdDate.push(moment(object.created_on).format("DD-MMM-YYYY"))
          viewSearch.push(object.VIEWS_SEARCH)
          viewMaps.push(object.VIEWS_MAPS)
          viewPhone.push(object.ACTIONS_PHONE)
          viewWebsite.push(object.ACTIONS_WEBSITE)
          requestDirection.push(object.ACTIONS_DRIVING_DIRECTIONS)
          queriesDirect.push(object.QUERIES_DIRECT)
          queriesInDirect.push(object.QUERIES_INDIRECT)
          queriesChain.push(object.QUERIES_CHAIN)
          photoViewMerchant.push(object.PHOTOS_VIEWS_MERCHANT)
          photoViewCustomer.push(object.PHOTOS_VIEWS_CUSTOMERS)
          photoCountMerchant.push(object.PHOTOS_COUNT_MERCHANT)
          photoCountCustomer.push(object.PHOTOS_COUNT_CUSTOMERS)
          var queriesDirectArr = queriesDirect.map(Number)
          var sumqueriesDirect = queriesDirectArr.reduce(function (x, y) {
            return x + y;
          }, 0);
          console.log("sumqueriesDirect", sumqueriesDirect)

          var queriesInDirectArr = queriesInDirect.map(Number)
          var sumqueriesInDirect = queriesInDirectArr.reduce(function (x, y) {
            return x + y;
          }, 0);
          console.log("sumqueriesInDirect", sumqueriesInDirect)

          var queriesChainArr = queriesChain.map(Number)
          var sumqueriesChain = queriesChainArr.reduce(function (x, y) {
            return x + y;
          }, 0);
          console.log("sumqueriesChain", sumqueriesChain)
          this.setState({
            getMasterdataXaxis: createdDate,
            graphDataViewSearch: viewSearch,
            graphDataViewMap: viewMaps,
            graphDataPhone: viewPhone,
            graphDataWebsite: viewWebsite,
            graphRequestDirection: requestDirection,

            graphQueriesDirect: sumqueriesDirect,
            graphQueriesInDirect: sumqueriesInDirect,
            graphQueriesChain: sumqueriesChain,

            garphphotoViewMerchant: photoViewMerchant,
            garphphotoViewCustomer: photoViewCustomer,
            garphphotoCountMerchant: photoCountMerchant,
            garphphotoCountCustomer: photoCountCustomer,

          });
        });
        this.setState({
          locationLatLongArray: data.mapLocation,

          currentDataViewSearch: data.currentData.length > 0 ? data.currentData[0].VIEWS_SEARCH : 0,
          lastQuaterDataViewSearch: data.quarterData.length > 0 ? data.quarterData[0].VIEWS_SEARCH : 0,
          lastYearDataViewSearch: data.oneYearData.length > 0 ? data.oneYearData[0].VIEWS_SEARCH : 0,

          currentDataViewMap: data.currentData.length > 0 ? data.currentData[0].VIEWS_MAPS : 0,
          lastQuaterDataViewMap: data.quarterData.length > 0 ? data.quarterData[0].VIEWS_MAPS : 0,
          lastYearDataViewMap: data.oneYearData.length > 0 ? data.oneYearData[0].VIEWS_MAPS : 0,

          currentWebsite: data.currentData.length > 0 ? data.currentData[0].ACTIONS_WEBSITE : 0,
          lastQuaterWebsite: data.quarterData.length > 0 ? data.quarterData[0].ACTIONS_WEBSITE : 0,
          lastYearWebsite: data.oneYearData.length > 0 ? data.oneYearData[0].ACTIONS_WEBSITE : 0,

          currentPhone: data.currentData.length > 0 ? data.currentData[0].ACTIONS_PHONE : 0,
          lastQuaterPhone: data.quarterData.length > 0 ? data.quarterData[0].ACTIONS_PHONE : 0,
          lastYearPhone: data.oneYearData.length > 0 ? data.oneYearData[0].ACTIONS_PHONE : 0,

          currentDirection: data.currentData.length > 0 ? data.currentData[0].ACTIONS_DRIVING_DIRECTIONS : 0,
          lastQuaterDirection: data.quarterData.length > 0 ? data.quarterData[0].ACTIONS_DRIVING_DIRECTIONS : 0,
          lastYearDirection: data.oneYearData.length > 0 ? data.oneYearData[0].ACTIONS_DRIVING_DIRECTIONS : 0,

          currentphotoViewMerchant: data.currentData.length > 0 ? data.currentData[0].PHOTOS_VIEWS_MERCHANT : 0,
          lastQuaterphotoViewMerchant: data.quarterData.length > 0 ? data.quarterData[0].PHOTOS_VIEWS_MERCHANT : 0,
          lastYearphotoViewMerchant: data.oneYearData.length > 0 ? data.oneYearData[0].PHOTOS_VIEWS_MERCHANT : 0,

          currentphotoViewCustomer: data.currentData.length > 0 ? data.currentData[0].PHOTOS_VIEWS_CUSTOMERS : 0,
          lastQuaterphotoViewCustomer: data.quarterData.length > 0 ? data.quarterData[0].PHOTOS_VIEWS_CUSTOMERS : 0,
          lastYearphotoViewCustomer: data.oneYearData.length > 0 ? data.oneYearData[0].PHOTOS_VIEWS_CUSTOMERS : 0,

          currentphotoCountMerchant: data.currentData.length > 0 ? data.currentData[0].PHOTOS_COUNT_MERCHANT : 0,
          lastQuaterphotoCountMerchant: data.quarterData.length > 0 ? data.quarterData[0].PHOTOS_COUNT_MERCHANT : 0,
          lastYearphotoCountMerchant: data.oneYearData.length > 0 ? data.oneYearData[0].PHOTOS_COUNT_MERCHANT : 0,

          currentphotoCountCustomer: data.currentData.length > 0 ? data.currentData[0].PHOTOS_COUNT_CUSTOMERS : 0,
          lastQuaterphotoCountCustomer: data.quarterData.length > 0 ? data.quarterData[0].PHOTOS_COUNT_CUSTOMERS : 0,
          lastYearphotoCountCustomer: data.oneYearData.length > 0 ? data.oneYearData[0].PHOTOS_COUNT_CUSTOMERS : 0,

          currentDirect: data.currentData.length > 0 ? data.currentData[0].QUERIES_DIRECT : 0,
          lastQuaterDirect: data.quarterData.length > 0 ? data.quarterData[0].QUERIES_DIRECT : 0,
          lastYearDirect: data.oneYearData.length > 0 ? data.oneYearData[0].QUERIES_DIRECT : 0,

          currentInDirect: data.currentData.length > 0 ? data.currentData[0].QUERIES_INDIRECT : 0,
          lastQuaterInDirect: data.quarterData.length > 0 ? data.quarterData[0].QUERIES_INDIRECT : 0,
          lastYearInDirect: data.oneYearData.length > 0 ? data.oneYearData[0].QUERIES_INDIRECT : 0,

          currentBranded: data.currentData.length > 0 ? data.currentData[0].QUERIES_CHAIN : 0,
          lastQuaterBranded: data.quarterData.length > 0 ? data.quarterData[0].QUERIES_CHAIN : 0,
          lastYearBranded: data.oneYearData.length > 0 ? data.oneYearData[0].QUERIES_CHAIN : 0,
        }, () => {
          // console.log('currentDataViewSearch....1.....',data.quarterData[0].VIEWS_SEARCH);
          // console.log('currentDataViewSearch....2.....',this.state.currentDataViewSearch);
        });
      }
    });
  }



  setfromtoDate(Dateselected, type) {
    var lastyear = new Date(new Date().getFullYear() - 1, 0, 1);
    var startDateCustomerGraph = new Date(lastyear.getFullYear(), 0, 1).getTime();
    var FromDate = "";
    var ToDate = "";



    var FromDateMainSearch = this.state.startDate == "" ? "" : this.state.startDate;
    var ToDateMainSearch = this.state.endDate == "" ? "" : this.state.endDate;

    if (type === "fromDateCustomerAction") {

      FromDate = Dateselected;
      ToDate = this.state.insightsEndDate;
    } else if (type === "toDateCustomerAction") {
      FromDate = this.state.insightsStartDate
      ToDate = Dateselected;
    }
    else if (type === "fromDatePhone") {
      FromDate = Dateselected;
      ToDate = this.state.insightsEndDatePhone
    }
    else if (type === "toDatePhone") {
      FromDate = this.state.insightsStartDatePhone
      ToDate = Dateselected;
    }
    else if (type === "fromDateCustomerView") {
      FromDate = Dateselected;
      ToDate = this.state.insightsEndDateCustomerView
    }
    else if (type === "toDateCustomerView") {
      FromDate = this.state.insightsStartDateCustomerView
      ToDate = Dateselected;
    }

    else if (type === "MainSearch") {
      if (FromDateMainSearch !== null && ToDateMainSearch !== null) {
        if (FromDateMainSearch > ToDateMainSearch) {
          alert("FromDate must be less than ToDate");
          return false;
        }
      }

      FromDate = FromDateMainSearch == "" ? "" : moment(FromDateMainSearch).format("YYYY-MM-DD");
      ToDate = ToDateMainSearch == "" ? "" : moment(ToDateMainSearch).format("YYYY-MM-DD");
    }


    var reqsetfromtoDate =
    {
      "functionName": "GetInsightsData",
      "siteId": window.sessionStorage.getItem("switchingOrgId") === ""
        ? window.sessionStorage.getItem("organizationId")
        : window.sessionStorage.getItem("switchingOrgId"),
      "location_id": this.state.location == "0" ? "" : this.state.location,
      "startDate":
        FromDate == "null" || FromDate == ""
          ? moment(startDateCustomerGraph).format("YYYY-MM-DD") && ""
          : moment(FromDate).format("YYYY-MM-DD"),
      "endDate":
        ToDate == "null" || ToDate == "" ? moment(new Date).format("YYYY-MM-DD") && ""
          : moment(ToDate).format("YYYY-MM-DD"),
      "state": this.state.administrativeArea == "0" ? "" : this.state.administrativeArea,
      "city": this.state.locality == "0" ? "" : this.state.locality
    };
    console.log("reqsetfromtoDate", reqsetfromtoDate)
    $(".loder_div").attr("hidden", false);
    apiCallingPost(reqsetfromtoDate).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1" && data.data.length > 0) {
        var viewSearch = [];
        var viewMaps = [];
        var viewPhone = [];
        var viewWebsite = [];
        var createdDate = [];
        var requestDirection = [];
        var queriesDirect = [];
        var queriesInDirect = [];
        var queriesChain = [];
        var photoViewMerchant = [];
        var photoViewCustomer = [];
        var photoCountMerchant = [];
        var photoCountCustomer = [];


        data.data.forEach((object) => {
          createdDate.push(moment(object.created_on).format("DD-MMM-YYYY"))
          viewSearch.push(object.VIEWS_SEARCH)
          viewMaps.push(object.VIEWS_MAPS)
          viewPhone.push(object.ACTIONS_PHONE)
          viewWebsite.push(object.ACTIONS_WEBSITE)
          requestDirection.push(object.ACTIONS_DRIVING_DIRECTIONS)
          queriesDirect.push(object.QUERIES_DIRECT)
          queriesInDirect.push(object.QUERIES_INDIRECT)
          queriesChain.push(object.QUERIES_CHAIN)
          photoViewMerchant.push(object.PHOTOS_VIEWS_MERCHANT)
          photoViewCustomer.push(object.PHOTOS_VIEWS_CUSTOMERS)
          photoCountMerchant.push(object.PHOTOS_COUNT_MERCHANT)
          photoCountCustomer.push(object.PHOTOS_COUNT_CUSTOMERS)

          var queriesDirectArr = queriesDirect.map(Number)
          var sumqueriesDirect = queriesDirectArr.reduce(function (x, y) {
            return x + y;
          }, 0);
          console.log("sumqueriesDirect", sumqueriesDirect)

          var queriesInDirectArr = queriesInDirect.map(Number)
          var sumqueriesInDirect = queriesInDirectArr.reduce(function (x, y) {
            return x + y;
          }, 0);
          console.log("sumqueriesInDirect", sumqueriesInDirect)

          var queriesChainArr = queriesChain.map(Number)
          var sumqueriesChain = queriesChainArr.reduce(function (x, y) {
            return x + y;
          }, 0);
          console.log("sumqueriesChain", sumqueriesChain)
          this.setState({
            locationLatLongArray: data.mapLocation,
          });
          if (type === "fromDateCustomerAction" || type === "toDateCustomerAction") {
            this.setState({
              getMasterdataXaxis: createdDate,
              graphDataPhone: viewPhone,
              graphDataWebsite: viewWebsite,
              graphRequestDirection: requestDirection,
            });
          }
          else if (type === "fromDatePhone" || type === "toDatePhone") {
            this.setState({
              getMasterdataXaxis: createdDate,
              garphphotoViewMerchant: photoViewMerchant,
              garphphotoViewCustomer: photoViewCustomer,
              garphphotoCountMerchant: photoCountMerchant,
              garphphotoCountCustomer: photoCountCustomer,
            });
          }
          else if (type === "fromDateCustomerView" || type === "toDateCustomerView") {
            this.setState({
              getMasterdataXaxis: createdDate,
              graphDataViewSearch: viewSearch,
              graphDataViewMap: viewMaps,
            });
          }
          else if (type === "MainSearch") {
            this.setState({
              getMasterdataXaxis: createdDate,
              graphDataViewSearch: viewSearch,
              graphDataViewMap: viewMaps,
              graphDataPhone: viewPhone,
              graphDataWebsite: viewWebsite,
              graphRequestDirection: requestDirection,
              graphQueriesDirect: sumqueriesDirect,
              graphQueriesInDirect: sumqueriesInDirect,
              graphQueriesChain: sumqueriesChain,
              garphphotoViewMerchant: photoViewMerchant,
              garphphotoViewCustomer: photoViewCustomer,
              garphphotoCountMerchant: photoCountMerchant,
              garphphotoCountCustomer: photoCountCustomer,
            });
          }

        });
      }

      else if (data.success === "1" && data.data.length <= 0) {
        this.setState({
          locationLatLongArray: [],
        });
        if (type === "fromDateCustomerAction" || type === "toDateCustomerAction") {
          this.setState({
            getMasterdataXaxis: [],
            graphDataPhone: [],
            graphDataWebsite: [],
            graphRequestDirection: [],
          });
        }
        else if (type === "fromDatePhone" || type === "toDatePhone") {
          this.setState({
            getMasterdataXaxis: [],
            garphphotoViewMerchant: [],
            garphphotoViewCustomer: [],
            garphphotoCountMerchant: [],
            garphphotoCountCustomer: [],
          });
        }
        else if (type === "fromDateCustomerView" || type === "toDateCustomerView") {
          this.setState({
            getMasterdataXaxis: [],
            graphDataViewSearch: [],
            graphDataViewMap: [],
          });
        }
        else if (type === "MainSearch") {
          this.setState({
            getMasterdataXaxis: [],
            graphDataViewSearch: [],
            graphDataViewMap: [],
            graphDataPhone: [],
            graphDataWebsite: [],
            graphRequestDirection: [],
            graphQueriesDirect: 0,
            graphQueriesInDirect: 0,
            graphQueriesChain: 0,
            garphphotoViewMerchant: [],
            garphphotoViewCustomer: [],
            garphphotoCountMerchant: [],
            garphphotoCountCustomer: [],
          });
        }
      }
    });
  }





  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          <Header heading="Insights" headerImage={Accessibiltyimage} />
          <div className="container-fluid">
            <div className="row">

              <div className="col-lg-2 col-md-4 col-sm-6">
                <select className="form-control" required value={this.state.administrativeArea} onChange={(e) => this.onStateSelect(e.target.value)}>
                  <option value="0" disabled>
                    State
                  </option>
                  {this.state.administrativeAreaList?.length > 0 &&
                    this.state.administrativeAreaList.map((adminArea, index) => (
                      <option key={index} value={adminArea}>
                        {adminArea}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <select className="form-control" required value={this.state.locality} onChange={(e) => this.onCitySelect(e.target.value)}>
                  <option value="0" disabled>
                    City
                  </option>
                  {this.state.localityList?.length > 0 &&
                    this.state.localityList.map((eachLocality, index) => (
                      <option key={index} value={eachLocality}>
                        {eachLocality}
                      </option>
                    ))}
                </select>
              </div>

              <div className="col-lg-2 col-md-4 col-sm-6">
                <DatePicker
                  selected={this.state.startDate}
                  onChange={(date) => this.handleChangeStart(date)}
                  name="startDate"
                  id="startDate"
                  showMonthDropdown
                  showYearDropdown
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  placeholderText="From Date"
                />
              </div>

              <div className="col-lg-2 col-md-4 col-sm-6">
                <DatePicker
                  selected={this.state.endDate}
                  onChange={(date) => this.handleChangeEnd(date)}
                  name="endDate"
                  id="endDate"
                  showMonthDropdown
                  showYearDropdown
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  placeholderText="To Date"
                />
              </div>

              <div className="col-lg-2 col-md-4 col-sm-6">
                <select className="form-control" required value={this.state.location} onChange={(e) => this.onLocationSelect(e.target.value)}>
                  <option value="0" disabled>
                    Location
                  </option>
                  {this.state.locationList?.length > 0 &&
                    this.state.locationList.map((eachLocation) => (
                      <option key={eachLocation.locationId} value={eachLocation.locationId}>
                        {eachLocation.location}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <button
                  className="btn-search"
                  onClick={() => this.setfromtoDate("allData", "MainSearch")}>

                  Search
                </button>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <button
                  className="btn-search"
                  onClick={() => this.clearData()}>

                  Clear
                </button>
              </div>
            </div>
            <hr />
            <div className="customer_actionDiv insight_borderbtm">
              <div className="insight_head">Customer actions</div>
              <div className="insight_text">
                The most common action that customer take on your
                listing
              </div>
              <div className="Date_fromToDiv mt-2">
                <span className="Date_fromToText">From</span>
                <div className="Date_fromTo">
                  <ReactDatePicker
                    selected={new Date(this.state.insightsStartDate)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat="dd-MM-yyyy"
                    dateFormatCalendar="MMMM"
                    id="fromdate"
                    onChange={(obj) => {
                      this.setfromtoDate(obj, "fromDateCustomerAction");
                      let startDateGraph = new Date(obj);
                      this.setState({
                        insightsStartDate: startDateGraph.toISOString(),
                        insightsDataKey: startDateGraph.toISOString(),
                      });
                    }}
                  />
                </div>
                <span className="Date_fromToText">To</span>
                <div className="Date_fromTo">
                  <ReactDatePicker
                    selected={new Date(this.state.insightsEndDate)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat="dd-MM-yyyy"
                    dateFormatCalendar="MMMM"
                    onChange={(obj) => {
                      this.setfromtoDate(obj, "toDateCustomerAction");
                      let endDateGraph = new Date(obj);
                      this.setState({
                        insightsEndDate: endDateGraph.toISOString(),
                        insightsDataKey: endDateGraph.toISOString(),
                      });
                    }}
                  />
                </div>
              </div>

              <div className="insight_totalview">
                {/* Total actions 81 */}
              </div>
              <div className="customer_actionGraphDiv">
                <CustomerActionGraph
                  key={this.state.graphRequestDirection}
                  getPhoneData={this.state.graphDataPhone}
                  getRequestDirectionData={this.state.graphRequestDirection}
                  customerActionXaxis={this.state.getMasterdataXaxis}
                  getWebsiteData={this.state.graphDataWebsite} />
              </div>
            </div>




            <div className="customer_actionDiv insight_borderbtm">
              <div className="Date_fromToDiv mt-2">
                {/* //calendar */}
              </div>

              {/* ================ OLD CODE COMMENTED ======================== */}
              {/* <div className="row my-3 mx-0 align-items-center">
                <div className="col-md-6">
                  <div className="insight_head">Directions Requests</div>
                  <div className="insight_text">
                    The areas where customers request directions to your business from
                  </div> 
                  <div className="">
                    <div className="map_directionreq">
                      <div>0</div>
                      <div>0</div>
                    </div>
                    <div className="map_directionreq">
                      <div>0</div>
                      <div>{"<"}0</div>
                    </div>
                    <div className="map_directionreq">
                      <div>0</div>
                      <div>{"<"}0</div>
                    </div>
                    <div className="map_directionreq">
                      <div>0</div>
                      <div>{"<"}0</div>
                    </div>
                    <div className="map_directionreq">
                      <div>0</div>
                      <div>{"<"}0</div>
                    </div>
                  </div>
                </div>

                <div className=" col-md-6 customer_actionGraphDiv"> 
                  <MapContainer
                    key={new Date().getTime()}
                    getGraphlatitude={this.state.locationLatLongArray}
                  />
                </div> 
              </div> */}
              {/* ================ OLD CODE COMMENTED ======================== */}
              {/* ================ MODIFIED CODE BEGINS ======================== */}
              {/* {this.state.locationLatLongArray.length > 0&&(
                <>
              <div className="insight_head">Directions </div>
              <div className="row my-3 mx-0 align-items-center" style={{ display: "flex", justifyContent: 'center' }}>
                <div className=" col-md-6 customer_actionGraphDiv">
               
                  <MapContainer
                    key={new Date().getTime()}
                    getGraphlatitude={this.state.locationLatLongArray}
                  />
               
                </div>
              </div></>)} */}

              {/* ================ MODIFIED CODE ENDS ======================== */}
            </div>




            <div className="phone_CallDiv mt-4 insight_borderbtm">
              <div className="insight_head">Photo Views & Counts</div>
              <div className="insight_text">
                When and how many times customers call your business
              </div>
              <div className="Date_fromToDiv mt-2">
                <span className="Date_fromToText">From</span>
                <div className="Date_fromTo">
                  <ReactDatePicker
                    selected={new Date(this.state.insightsStartDatePhone)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat="dd-MM-yyyy"
                    dateFormatCalendar="MMMM"
                    id="fromdate"
                    onChange={(obj) => {
                      this.setfromtoDate(obj, "fromDatePhone");
                      let startDateGraphPhone = new Date(obj);
                      this.setState({
                        insightsStartDatePhone: startDateGraphPhone.toISOString(),
                        insightsDataKey: startDateGraphPhone.toISOString(),
                      });
                    }}
                  />
                </div>
                <span className="Date_fromToText">To</span>
                <div className="Date_fromTo">
                  <ReactDatePicker
                    selected={new Date(this.state.insightsEndDatePhone)}
                    showMonthDropdown
                    showYearDropdown
                    dateFormat="dd-MM-yyyy"
                    dropdownMode="select"
                    dateFormatCalendar="MMMM"
                    onChange={(obj) => {
                      this.setfromtoDate(obj, "toDatePhone");
                      let endDateGraphPhone = new Date(obj);
                      this.setState({
                        insightsEndDatePhone: endDateGraphPhone.toISOString(),
                        insightsDataKey: endDateGraphPhone.toISOString(),
                      });
                    }}
                  />
                </div>
              </div>

              <div className="insight_totalview">
                {/* Total calls 34 */}
              </div>
              <div className="phone_callGraphDiv">
                <PhoneCallsGraph
                  key={this.state.garphphotoViewMerchant}
                  getphotoViewMerchantData={this.state.garphphotoViewMerchant}
                  xPhoneaxis={this.state.getMasterdataXaxis}
                  getphotoViewCustomerData={this.state.garphphotoViewCustomer}
                  getphotoCountMerchantData={this.state.garphphotoCountMerchant}
                  getphotoCountCustomerData={this.state.garphphotoCountCustomer}
                />
              </div>
            </div>
            <div className="customer_searchDiv insight_borderbtm">
              <div className="insight_head pb-2">How customer search for your business</div>
              <div className="Date_fromToDiv mt-2">
                {/* //calendar */}
              </div>

              <div className="row customer_searchGraph mt-3">
                <div className="col-md-6">
                  <AllSearches
                    key={this.state.graphQueriesDirect}
                    getGraphQueriesDirect={this.state.graphQueriesDirect}
                    getGraphQueriesInDirect={this.state.graphQueriesInDirect}
                    getBranded={this.state.graphQueriesChain}
                  />
                </div>
                <div className="col-md-6 pt-5">
                  <div className="insight_optiondiv">
                    <div className="left_insightopt_div">
                      <Icon.MapPin />
                    </div>
                    <div className="right_insightopt_div">
                      <h6>Direct</h6>
                      <p className='insight_optionPara'>People who find your Business Profile searching for your business name or address</p>
                    </div>
                  </div>
                  <div className="insight_optiondiv">
                    <div className="left_insightopt_div">
                      <Icon.MapPin />
                    </div>
                    <div className="right_insightopt_div">
                      <h6>Discovery</h6>
                      <p className='insight_optionPara'>People who find your Business Profile searching for a category, product or service</p>
                    </div>
                  </div>
                  <div className="insight_optiondiv">
                    <div className="left_insightopt_div">
                      <Icon.MapPin />
                    </div>
                    <div className="right_insightopt_div">
                      <h6>Maps</h6>
                      <p className='insight_optionPara'>People who find your Business Profile searching for a brand related to your business</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="customer_viewDiv mt-4">
              <div className="insight_head">Where customers view your business on Google</div>
              <div className="insight_text">
                The Google services that customers use to find your business
              </div>
              <div className="Date_fromToDiv mt-2">
                <span className="Date_fromToText">From</span>
                <div className="Date_fromTo">
                  <ReactDatePicker
                    selected={new Date(this.state.insightsStartDateCustomerView)}
                    showMonthDropdown
                    showYearDropdown
                    dateFormat="dd-MM-yyyy"
                    dateFormatCalendar="MMMM"
                    dropdownMode="select"
                    id="fromdate"
                    onChange={(obj) => {
                      this.setfromtoDate(obj, "fromDateCustomerView");
                      let startDateGraphCustomerView = new Date(obj);
                      this.setState({
                        insightsStartDateCustomerView: startDateGraphCustomerView.toISOString(),
                        insightsDataKey: startDateGraphCustomerView.toISOString(),
                      });
                    }}
                  />
                </div>
                <span className="Date_fromToText">To</span>
                <div className="Date_fromTo">
                  <ReactDatePicker
                    selected={new Date(this.state.insightsEndDateCustomerView)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat="dd-MM-yyyy"
                    dateFormatCalendar="MMMM"
                    onChange={(obj) => {
                      this.setfromtoDate(obj, "toDateCustomerView");
                      let endDateGraphCustomerView = new Date(obj);
                      this.setState({
                        insightsEndDateCustomerView: endDateGraphCustomerView.toISOString(),
                        insightsDataKey: endDateGraphCustomerView.toISOString(),
                      });
                    }}
                  />
                </div>
              </div>

              <div className="insight_totalview">
                {/* Total views 3.55k */}
              </div>
              <div className="customer_viewGraphDiv">
                <CustomerViewGraph
                  key={this.state.graphDataViewSearch}
                  getMapViewData={this.state.graphDataViewMap}
                  xaxis={this.state.getMasterdataXaxis}
                  listingonSearch={this.state.graphDataViewSearch} />
              </div>
            </div>



            <div className="col-md-12 mb-4 google_prf_insight_wrapr">
              <div className="users-list-head">
                Google Business Profile Insights
              </div>
              <div className="row gbpi">
                <div className="col-md-3 gbpi-left">

                  <div className="gbpi-item">
                    Last Quarter :
                    <span> {isNaN(parseInt(this.state.lastQuaterDataViewMap) + parseInt(this.state.lastQuaterDataViewSearch))
                      ?
                      0
                      :
                      (parseInt(this.state.lastQuaterDataViewMap) + parseInt(this.state.lastQuaterDataViewSearch)).toLocaleString()} </span>
                    {/* <span className="badge-gdpi badge-green">(70.31%)</span> */}
                  </div>
                  <div className="gbpi-item">
                    Current Quarter:{" "}
                    <span>{isNaN(parseInt(this.state.currentDataViewMap) + parseInt(this.state.currentDataViewSearch))
                      ?
                      0
                      :
                      (parseInt(this.state.currentDataViewMap) + parseInt(this.state.currentDataViewSearch)).toLocaleString()}</span>
                    {/* <span className='badge-gdpi badge-green'>(89.22%)</span> */}
                  </div>

                  <div className="gbpi-main">
                    <div className="row">
                      <div className="col-6">

                        <div className="gbpiData">{isNaN(parseInt(this.state.lastYearDataViewMap) + parseInt(this.state.lastYearDataViewSearch))
                          ?
                          0
                          :
                          (parseInt(this.state.lastYearDataViewMap) + parseInt(this.state.lastYearDataViewSearch)).toLocaleString()}</div>
                        <div className="actions"> Views{" "}</div>
                      </div>
                      <div className="col-6 marginAuto">
                        <img src={eye} alt='view' width="40px" />
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-md-9 graph-sec">
                  <table class="table tbl_insightgdp">

                    <thead>
                      <tr className='gdpi-header_tr'>
                        <th className='col-3 gdpi-header'></th>
                        <th className='col-3 gdpi-header'>Current Quarter</th>
                        <th className='col-3 gdpi-header'>Last Quarter</th>
                        <th className='col-3 gdpi-header'>Last Year</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className='gdpi-tdText col-3'>Maps</td>
                        <td className='col-3 span_all_gbt'>{this.state.currentDataViewMap.toLocaleString()}
                          {/* <span className="badge-gdpi badge-green">(73.95%) </span> */}
                        </td>
                        <td className='col-3 span_all_gbt'>{this.state.lastQuaterDataViewMap.toLocaleString()} </td>
                        <td className='col-3 span_all_gbt'>{this.state.lastYearDataViewMap.toLocaleString()} </td>
                      </tr>

                      <tr>
                        <td className='gdpi-tdText col-3'>Search</td>
                        <td className='col-3 span_all_gbt'>{this.state.currentDataViewSearch.toLocaleString()}</td>
                        <td className='col-3 span_all_gbt'>{this.state.lastQuaterDataViewSearch.toLocaleString()}</td>
                        <td className='col-3 span_all_gbt'>{this.state.lastYearDataViewSearch.toLocaleString()}</td>
                      </tr>
                    </tbody>


                  </table>
                </div>
              </div>
              <div className="row gbpi">
                <div className="col-md-3 gbpi-left">

                  <div className="gbpi-item">
                    Last Quarter :
                    <span> {isNaN(parseInt(this.state.lastQuaterWebsite) + parseInt(this.state.lastQuaterDirection) + parseInt(this.state.lastQuaterPhone))
                      ?
                      0
                      :
                      (parseInt(this.state.lastQuaterWebsite) + parseInt(this.state.lastQuaterDirection) + parseInt(this.state.lastQuaterPhone)).toLocaleString()} </span>
                    {/* <span className="badge-gdpi badge-green">(70.31%)</span> */}
                  </div>
                  <div className="gbpi-item">
                    Current Quarter :{" "}
                    <span> {isNaN(parseInt(this.state.currentWebsite) + parseInt(this.state.currentDirection) + parseInt(this.state.currentPhone))
                      ?
                      0
                      :
                      (parseInt(this.state.currentWebsite) + parseInt(this.state.currentDirection) + parseInt(this.state.currentPhone)).toLocaleString()}</span>
                    {/* <span className='badge-gdpi badge-green'>(89.22%)</span> */}
                  </div>

                  <div className="gbpi-main">
                    <div className="row">
                      <div className="col-6">
                        <div className="gbpiData">{isNaN(parseInt(this.state.lastYearWebsite) + parseInt(this.state.lastYearDirection) + parseInt(this.state.lastYearPhone))
                          ?
                          0
                          :
                          (parseInt(this.state.lastYearWebsite) + parseInt(this.state.lastYearDirection) + parseInt(this.state.lastYearPhone)).toLocaleString()}

                        </div>
                        <div className="actions"> Actions{" "}</div>
                      </div>
                      <div className="col-6 marginAuto">
                        <img src={handPhn} alt='view' width="40px" />
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-md-9 graph-sec">
                  <table class="table tbl_insightgdp">

                    <thead>
                      <tr className='gdpi-header_tr'>
                        <th className='col-3 gdpi-header'></th>
                        <th className='col-3 gdpi-header'>Current Quarter</th>
                        <th className='col-3 gdpi-header'>Last Quarter</th>
                        <th className='col-3 gdpi-header'>Last Year</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className='gdpi-tdText col-3'>Website Visits</td>
                        <td className='col-3 span_all_gbt'>{this.state.currentWebsite.toLocaleString()} </td>
                        <td className='col-3 span_all_gbt'>{this.state.lastQuaterWebsite.toLocaleString()} </td>
                        <td className='col-3 span_all_gbt'>{this.state.lastYearWebsite.toLocaleString()} </td>
                      </tr>

                      <tr>
                        <td className='gdpi-tdText col-3'>Request Directions</td>
                        <td className='col-3 span_all_gbt'>{this.state.currentDirection.toLocaleString()}</td>
                        <td className='col-3 span_all_gbt'>{this.state.lastQuaterDirection.toLocaleString()}</td>
                        <td className='col-3 span_all_gbt'>{this.state.lastYearDirection.toLocaleString()}</td>
                      </tr>

                      <tr>
                        <td className='gdpi-tdText col-3'>Phone Calls</td>
                        <td className='col-3 span_all_gbt'>{this.state.currentPhone.toLocaleString()}</td>
                        <td className='col-3 span_all_gbt'>{this.state.lastQuaterPhone.toLocaleString()}</td>
                        <td className='col-3 span_all_gbt'>{this.state.lastYearPhone.toLocaleString()}</td>
                      </tr>
                    </tbody>


                  </table>
                </div>
              </div>
              <div className="row gbpi">
                <div className="col-md-3 gbpi-left">

                  <div className="gbpi-item">
                    Last Quarter :
                    <span> {isNaN(parseInt(this.state.lastQuaterphotoViewMerchant) + parseInt(this.state.lastQuaterphotoViewCustomer) + parseInt(this.state.lastQuaterphotoCountMerchant) + parseInt(this.state.lastQuaterphotoCountCustomer))
                      ?
                      0
                      :
                      (parseInt(this.state.lastQuaterphotoViewMerchant) + parseInt(this.state.lastQuaterphotoViewCustomer) + parseInt(this.state.lastQuaterphotoCountMerchant) + parseInt(this.state.lastQuaterphotoCountCustomer)).toLocaleString()}  </span>
                    {/* <span className="badge-gdpi badge-green">(70.31%)</span> */}
                  </div>
                  <div className="gbpi-item">
                    Current Quarter :{" "}
                    <span> {isNaN(parseInt(this.state.currentphotoViewMerchant) + parseInt(this.state.currentphotoViewCustomer) + parseInt(this.state.currentphotoCountMerchant) + parseInt(this.state.currentphotoCountCustomer))
                      ?
                      0
                      :
                      (parseInt(this.state.currentphotoViewMerchant) + parseInt(this.state.currentphotoViewCustomer) + parseInt(this.state.currentphotoCountMerchant) + parseInt(this.state.currentphotoCountCustomer)).toLocaleString()}
                    </span>
                    {/* <span className='badge-gdpi badge-green'>(89.22%)</span> */}
                  </div>

                  <div className="gbpi-main">
                    <div className="row">
                      <div className="col-6">
                        <div className="gbpiData">  {isNaN(parseInt(this.state.lastYearphotoViewMerchant) + parseInt(this.state.lastYearphotoViewCustomer) + parseInt(this.state.lastYearphotoCountMerchant) + parseInt(this.state.lastYearphotoCountCustomer))
                          ?
                          0
                          :
                          (parseInt(this.state.lastYearphotoViewMerchant) + parseInt(this.state.lastYearphotoViewCustomer) + parseInt(this.state.lastYearphotoCountMerchant) + parseInt(this.state.lastYearphotoCountCustomer)).toLocaleString()}</div>
                        <div className="actions"> Photos{" "}</div>
                      </div>
                      <div className="col-6 marginAuto">
                        <img src={Camera} alt='view' width="40px" />
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-md-9 graph-sec">
                  <table class="table tbl_insightgdp">

                    <thead>
                      <tr className='gdpi-header_tr'>
                        <th className='col-3 gdpi-header'></th>
                        <th className='col-3 gdpi-header'>Current Quarter</th>
                        <th className='col-3 gdpi-header'>Last Quarter</th>
                        <th className='col-3 gdpi-header'>Last Year</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className='gdpi-tdText col-3'>Photo View Merchant</td>
                        <td className='col-3 span_all_gbt'>{this.state.currentphotoViewMerchant.toLocaleString()} </td>
                        <td className='col-3 span_all_gbt'>{this.state.lastQuaterphotoViewMerchant.toLocaleString()} </td>
                        <td className='col-3 span_all_gbt'>{this.state.lastYearphotoViewMerchant.toLocaleString()} </td>
                      </tr>

                      <tr>
                        <td className='gdpi-tdText col-3'>Photo View Customer</td>
                        <td className='col-3 span_all_gbt'>{this.state.currentphotoViewCustomer.toLocaleString()}</td>
                        <td className='col-3 span_all_gbt'>{this.state.lastQuaterphotoViewCustomer.toLocaleString()}</td>
                        <td className='col-3 span_all_gbt'>{this.state.lastYearphotoViewCustomer.toLocaleString()}</td>
                      </tr>
                      <tr>
                        <td className='gdpi-tdText col-3'>Photo Count Merchant</td>
                        <td className='col-3 span_all_gbt'>{this.state.currentphotoCountMerchant.toLocaleString()}</td>
                        <td className='col-3 span_all_gbt'>{this.state.lastQuaterphotoCountMerchant.toLocaleString()}</td>
                        <td className='col-3 span_all_gbt'>{this.state.lastYearphotoCountMerchant.toLocaleString()}</td>
                      </tr>
                      <tr>
                        <td className='gdpi-tdText col-3'>Photo Count Customer</td>
                        <td className='col-3 span_all_gbt'>{this.state.currentphotoCountCustomer.toLocaleString()}</td>
                        <td className='col-3 span_all_gbt'>{this.state.lastQuaterphotoCountCustomer.toLocaleString()}</td>
                        <td className='col-3 span_all_gbt'>{this.state.lastYearphotoCountCustomer.toLocaleString()}</td>
                      </tr>
                    </tbody>


                  </table>
                </div>
              </div>
              <div className="row gbpi">
                <div className="col-md-3 gbpi-left">

                  <div className="gbpi-item">
                    Last Quarter :
                    <span> {isNaN(parseInt(this.state.lastQuaterDirect) + parseInt(this.state.lastQuaterInDirect) + parseInt(this.state.lastQuaterBranded))
                      ?
                      0
                      :
                      (parseInt(this.state.lastQuaterDirect) + parseInt(this.state.lastQuaterInDirect) + parseInt(this.state.lastQuaterBranded)).toLocaleString()}  </span>
                    {/* <span className="badge-gdpi badge-green">(70.31%)</span> */}
                  </div>
                  <div className="gbpi-item">
                    Current Quater :{" "}
                    <span>{isNaN(parseInt(this.state.currentDirect) + parseInt(this.state.currentInDirect) + parseInt(this.state.currentBranded))
                      ?
                      0
                      :
                      (parseInt(this.state.currentDirect) + parseInt(this.state.currentInDirect) + parseInt(this.state.currentBranded)).toLocaleString()}

                    </span>
                    {/* <span className='badge-gdpi badge-green'>(89.22%)</span> */}
                  </div>

                  <div className="gbpi-main">
                    <div className="row">
                      <div className="col-6">
                        <div className="gbpiData"> {isNaN(parseInt(this.state.lastYearDirect) + parseInt(this.state.lastYearInDirect) + parseInt(this.state.lastYearBranded))
                          ?
                          0
                          :
                          (parseInt(this.state.lastYearDirect) + parseInt(this.state.lastYearInDirect) + parseInt(this.state.lastYearBranded)).toLocaleString()}</div>
                        <div className="actions"> Searches{" "}</div>
                      </div>
                      <div className="col-6 marginAuto">
                        <img src={view} alt='view' width="40px" />
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-md-9 graph-sec">
                  <table class="table tbl_insightgdp">

                    <thead>
                      <tr className='gdpi-header_tr'>
                        <th className='col-3 gdpi-header'></th>
                        <th className='col-3 gdpi-header'>Current Quarter</th>
                        <th className='col-3 gdpi-header'>Last Quarter</th>
                        <th className='col-3 gdpi-header'>Last Year</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className='gdpi-tdText col-3'>Discovery</td>
                        <td className='col-3 span_all_gbt'>{this.state.currentInDirect.toLocaleString()} </td>
                        <td className='col-3 span_all_gbt'>{this.state.lastQuaterInDirect.toLocaleString()} </td>
                        <td className='col-3 span_all_gbt'>{this.state.lastYearInDirect.toLocaleString()} </td>
                      </tr>

                      <tr>
                        <td className='gdpi-tdText col-3'>Direct</td>
                        <td className='col-3 span_all_gbt'>{this.state.currentDirect.toLocaleString()}</td>
                        <td className='col-3 span_all_gbt'>{this.state.lastQuaterDirect.toLocaleString()}</td>
                        <td className='col-3 span_all_gbt'>{this.state.lastYearDirect.toLocaleString()}</td>
                      </tr>

                      <tr>
                        <td className='gdpi-tdText col-3'>Branded</td>
                        <td className='col-3 span_all_gbt'>{this.state.currentBranded.toLocaleString()}</td>
                        <td className='col-3 span_all_gbt'>{this.state.lastQuaterBranded.toLocaleString()}</td>
                        <td className='col-3 span_all_gbt'>{this.state.lastYearBranded.toLocaleString()}</td>
                      </tr>
                    </tbody>


                  </table>
                </div>
              </div>
            </div>


          </div>
          <Footer/>
        </div>
        <div className="loder_div" hidden />
      </div>
    )
  }
}
