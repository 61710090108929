import React, { useState, useEffect } from "react";
import { MdHistory } from "react-icons/md";
import { backendApi, pythonBackendApi } from "../../apiService";
import Correct from "../../images/correct.png";
import Select from "react-select";
import AWS from "aws-sdk";
import { FiPlusCircle } from "react-icons/fi";
import AddCampaignPopup from "../../adv_generator/addCampaignPopup";
import ApproverPopup from "../../adv_generator/approverPopup";
import moment from "moment";
import { IoCloseCircleOutline } from "react-icons/io5";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
var bucketParams;
var s3;
const s3ImageUrl =
  "http://tagtree.s3-website.ap-south-1.amazonaws.com/production/images";
const s3Region = "us-east-1";
const s3bucketAccessKey = process.env.REACT_APP_TAGTREE_S3_ACCESS_KEY;
const s3bucketSecretKey = process.env.REACT_APP_TAGTREE_S3_SECRET_KEY;
const s3Bucket = "tagtree";
const s3BucketMainFolder = "production";
const S3BucketImagesFolder = "images";

export default function ContentPlanning({ onGetRequestedData }) {
  const [progress, setProgress] = useState(0);
  const [confirmFlag, setConfirmFlag] = useState(false);
  const [timeRunning, setTimeRunning] = useState(1600);
  const [running, setRunning] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [showQuestionsTable, setShowQuestionsTable] = useState(false);
  const [showHistoryTable, setShowHistoryTable] = useState(false);
  const [groupData, setGroupData] = useState([]);
  const [campaignDetails, setCampaignDetails] = useState("");
  const [campaignPlanningId, setCampaignPlanningId] = useState(null);
  const [brand, setBrand] = useState("");
  const [variations, setVariations] = useState("");
  const [model, setModel] = useState("");
  const [planName, setPlanName] = useState("");
  const [keywords, setKeywords] = useState("");
  const [about, setAbout] = useState("");
  const [imageList, setImageList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [campaign, setCampaign] = useState("");
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [showAddCampaignPopup, setShowAddCampaignPopup] = useState(false);
  const [showApprovalPopup, setShowApprovalPopup] = useState(false);
  const [history, setHistory] = useState([]);
  const [promptId, setPromptId] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [requirement, setRequirement] = useState('');

  const closePopup = () => {
    setSelectedImages([]);
    onGetRequestedData();
    contentInit();
    setShowAddCampaignPopup(false);
    setShowApprovalPopup(false);
  };

  const handleCheckboxClick = (imageUrl) => {
    const selectedIndex = selectedImages.indexOf(imageUrl);
    if (selectedIndex === -1) {
      // Image not selected, add it to the array
      setSelectedImages([...selectedImages, imageUrl]);
    } else {
      // Image already selected, remove it from the array
      setSelectedImages(selectedImages.filter((image) => image !== imageUrl));
    }
  };

  const ShowModalCampaignAdv = () => {
    setShowAddCampaignPopup(true);
    document.body.className = "overflow-hidden";
  };

  const selectRefEntry = (entry) => {
    setRequirement(entry.answer_text);
    setShowQuestionsTable(false);
  };

  const groupDataInit = () => {
    const apiReq = {
      functionName: "content_approver_group_data",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apiReq).then((data) => {
      if (data.success == "1") {
        setGroupData(data.dataSet);
      } else {
        console.log("Error in groupdata api");
      }
    });
  };

  const handleCampaignSelect = (selectedOption) => {
    setCampaign(selectedOption);
    const selectedCampaign = campaigns.find(
      (camp) => camp.id === selectedOption.value
    );
    setCampaignDetails(selectedCampaign);
  };

  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  const getQuestions = () => {
    const convertReq = {
      functionName: "referenceList",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(convertReq).then((response) => {
      if (response.success === "1") {
        setQuestions(response.dataSet);
        // setShowQuestionsTable(!showQuestionsTable);
      } else {
        console.log("Error in history api");
      }
    });
    setShowQuestionsTable(!showQuestionsTable);
  };

  const handleSelectAll = (isChecked) => {
    const allImageUrls = imageList.map((image) => image.image_url);
    if (isChecked) {
      setSelectedImages(allImageUrls);
    } else {
      setSelectedImages([]);
    }
  };

  const hidePopup = () => {
    setShowHistoryTable(false);
    setShowQuestionsTable(false);
  };

  const selectHistoryEntry = (entry) => {
    const camp = campaignOptions.find(
      (item) => item.value === entry.campaign_id
    );
    setCampaign(camp);
    // setCampaign({ value: Math.random(), label: entry.content_campaign_name });
    setModel(entry.plan_model);
    setPlanName(entry.plan_name);
    setAbout(entry.plan_about);
    setKeywords(entry.plan_keyword);
    setVariations(entry.no_of_images);
    setPromptId(entry.id);
    setImageList(entry.images);
    setBrand(entry.brand);

    setShowHistoryTable(false);
  };

  const historypop = () => {
    const convertReq = {
      functionName: "planningHistory",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(convertReq).then((response) => {
      if (response.success === "1") {
        setHistory(response.dataList);
      } else {
        console.log("Error in history api");
      }
    });
    setShowHistoryTable(!showHistoryTable);
  };

  useEffect(() => {
    let intervalId;

    if (running) {
      intervalId = setInterval(() => {
        if (progress < 100) {
          setProgress((prevProgress) => prevProgress + 1);
        } else {
          setTimeRunning(1600);
          clearInterval(intervalId);
          setRunning(false);
          setProgress(0)
        }
      }, timeRunning);
    }

    return () => clearInterval(intervalId);
  }, [progress, running, timeRunning]);

  useEffect(() => {
    if (progress === 100) {
      setConfirmFlag(false);
      // setIsSubmitting(true);
    }
  }, [progress]);

  useEffect(() => {
    contentInit();
    groupDataInit();
    getS3Credentials();
    let orgs = window.sessionStorage.getItem("organizationAllotment");
    if (orgs) {
      let brandName = JSON.parse(
        window.sessionStorage.getItem("organizationAllotment")
      ).find(
        (item) => item.id == window.sessionStorage.getItem("switchingOrgId")
      );
      setBrand(brandName.organisation_name);
    } else {
      setBrand("");
    }
  }, []);

  const getS3Credentials = () => {
    bucketParams = {
      Bucket: s3Bucket,
    };
    AWS.config.update({
      accessKeyId: s3bucketAccessKey,
      secretAccessKey: s3bucketSecretKey,
      region: s3Region,
    });
    s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: bucketParams,
    });
  };

  const sendForApproval = () => {
    setShowApprovalPopup(true);
  };

  const contentInit = () => {
    const apiReq = {
      functionName: "content_init",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apiReq).then((data) => {
      if (data.success == "1") {
        setCampaigns(data.campaign_name);
        const transformedOptions = data.campaign_name.map(
          (campaign, index) => ({
            value: campaign.id,
            label: campaign.campaign_name,
          })
        );
        setCampaignOptions(transformedOptions);
      } else {
        console.log("Error in content init api");
      }
    });
  };

  const generateAd = () => {
    if (isSubmitting) {
      return;
    }
    if (campaign === "") {
      alert("Please select a campaign");
      return false;
    }
    if (brand === "") {
      alert("Please enter brand name");
      return false;
    }
    if (planName === "") {
      alert("Please enter plan name");
      return false;
    } else if (model === "") {
      alert("Please enter model name");
      return false;
    } else if (variations === "") {
      alert("Please choose number of images");
      return false;
    }
    let plan=planName.replace(/['"]/g, "\\$&");
    let modelName=model.replace(/['"]/g, "\\$&");
    let aboutAd=about.replace(/['"]/g, "\\$&");
    let keywordsAd=keywords.replace(/['"]/g, "\\$&");
    setRunning(true);
    setConfirmFlag(true);
    setProgress(0);
    const campaignPlanningData = {
      functionName: "campaign_planning_save",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      campaign_id: campaign.value,
      brand: brand,
      plan_name: plan,
      plan_model: modelName,
      plan_about: aboutAd,
      plan_keyword: keywordsAd,
      no_of_images: variations,
      created_by: window.sessionStorage.getItem("userId"),
    };
    backendApi(campaignPlanningData).then((response) => {
      if (response.success == 1) {
        // setProgress(20);
        setCampaignPlanningId(response.campaign_planning_id);
        let promptAI = `Generate images for this ${brand} showcasing ${model}.`;
        if (planName !== "") {
          promptAI += ` Tailor the ad to fit the theme of ${plan}.`;
        }
        if (keywords !== "") {
          promptAI += ` Incorporate relevant keywords such as ${keywordsAd}.`;
        }
        if (about !== "") {
          promptAI += ` Include ${aboutAd} while generating images.`;
        }
        if (requirement !== "") {
          promptAI += ` Include ${requirement} while generating images.`;
        }
        // const promptAI = generatePromptAI();

        generateImageUrls(promptAI, response.campaign_planning_id);
      }
    });
  };

  const generateImageUrls = (promptAI, campaignId) => {
    const apiPromises = [];
    for (let i = 0; i < variations; i++) {
      const apiReq = {
        functionName: "handleGenerateImage",
        prompt: promptAI,
      };
      apiPromises.push(backendApi(apiReq));
    }

    Promise.all(apiPromises)
      .then((responses) => {
        // setProgress(40);
        const isSuccess = responses.every(
          (response) => response.success == "1"
        );

        if (isSuccess) {
          const imageLists = responses.map((response) => response.data.data);
          const combinedImageList = imageLists.reduce(
            (acc, curr) => acc.concat(curr),
            []
          );
          // setImageList(combinedImageList);
          // setProgress(60);

          convertImagesToBase64(combinedImageList, campaignId);
        } else {
          alert("Error generating Ad");
          setConfirmFlag(false)
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsSubmitting(false);
      });
  };

  const convertImagesToBase64 = (imageList, campaignId) => {
    const base64Promises = imageList.map((image, index) => {
      const apidata = {
        method: "POST",
        body: JSON.stringify({
          image_url: image.url,
        }),
      };
      return pythonBackendApi("convertImageToBase", apidata);
    });

    Promise.all(base64Promises)
      .then((data) => {
        // setProgress(80);
        const base64Urls = data.map((response) => response.data);
        // setSelectedImage(base64Urls);
        // setImageList(base64Urls);

        uploadImagesToS3(base64Urls, campaignId);
      })
      .catch((error) => {
        console.error("Error converting images to base64:", error);
        setIsSubmitting(false);
      });
  };

  const uploadImagesToS3 = (base64Urls, campaignId) => {
    base64Urls.forEach((base64Url) => {
      handleImageUpload(base64Url)
        .then((data) => {
          const uploadData = {
            functionName: "campaign_planning_images_save",
            campaign_planning_id: campaignId,
            image_url: data,
          };
          backendApi(uploadData)
            .then((response) => {
              if (response.success === "1") {
                setImageList(response.dataSet);
              } else {
                console.error("Failed to save image");
              }
            })
            .catch((error) => {
              console.error("Error saving image:", error);
            })
            .finally(() => {
              setIsSubmitting(false);
              setTimeRunning(100);
            });
        })
        .catch((error) => {
          console.error("Error saving image:", error);
        });
    });
  };

  const handleImageUpload = (base64url) => {
    return new Promise((resolve, reject) => {
      const base64Data = base64url.replace(/^data:image\/\w+;base64,/, "");
      const byteString = atob(base64Data);
      const mimeType = base64url.split(";")[0].split(":")[1];

      const arrayBuffer = new ArrayBuffer(byteString.length);
      const intArray = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([intArray], { type: mimeType });
      AWS.config.update({
        accessKeyId: process.env.REACT_APP_TAGTREE_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_TAGTREE_S3_SECRET_KEY,
        region: s3Region,
      });

      const bucketParams = {
        Bucket: s3Bucket,
      };
      const s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: bucketParams,
      });

      const fileName = uuidv4() + ".jpg";
      const fullFilePath =
        s3BucketMainFolder + "/" + S3BucketImagesFolder + "/" + fileName;

      s3.upload(
        {
          Key: fullFilePath,
          Body: blob,
          ACL: "public-read",
        },
        (err, data) => {
          if (err) {
            reject(err);
            alert("There was an error uploading your image");
          } else if (data) {
            resolve(data.Location);
          }
        }
      );
    });
  };

  const resetForm = () => {
    // setBrand("");
    setIsSubmitting(false)
    setProgress(0)
    setTimeRunning(1600)
    setConfirmFlag(false);
    setRequirement('');
    setAbout("");
    setKeywords("");
    setModel("");
    setPlanName("");
    setVariations("");
    setCampaign("");
    setImageList([]);
    setCampaignDetails("");
  };

  window.$(document).ready(function () {
    window.$(".popup-image").magnificPopup({
      delegate: "a",
      type: "image",
      tLoading: "Loading image #%curr%...",
      mainClass: "mfp-img-mobile",
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
      },
      image: {
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        titleSrc: function (item) {
          return item.el.attr("title") + "<small></small>";
        },
      },
    });
    window.$(".popup-video").magnificPopup({
      delegate: "a",
      type: "iframe",
      tLoading: "Loading...",
    });
  });

  return (
    <div className="user_listdiv">
      <div className="mx-3">
        <div className="row ">
          <div className="col-md-5">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">
                Campaign
                <span className="red ml-1">*</span>{" "}
              </label>
              <div className="d-flex align-items-center">
                <Select
                  name="colors"
                  placeholder="Select Campaign"
                  options={campaignOptions}
                  value={campaign}
                  onChange={handleCampaignSelect}
                  className="basic-multi-select w-100"
                  classNamePrefix="select"
                />
                <span>
                  <FiPlusCircle
                    style={{ width: "20px", height: "20px" }}
                    onClick={() => ShowModalCampaignAdv()}
                    className="history-icon-gen ml-1"
                  />
                </span>
              </div>
              {campaignDetails && (
                <span className="text-muted campaign_details_span">
                  {campaignDetails.campaign_summary}
                </span>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">
                Brand
                <span className="red ml-1">*</span>{" "}
              </label>
              <div className="d-flex">
                <input
                  readOnly={brand !== ""}
                  type="text"
                  className="form-control"
                  placeholder="Enter Brand"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">
                Planning Description
                <span className="red ml-1">*</span>{" "}
              </label>
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Planning Description"
                  value={planName}
                  onChange={(e) => setPlanName(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">
                Model
                <span className="red ml-1">*</span>{" "}
              </label>
              <div className="d-md-flex">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Model"
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">From Requirement</label>
              <div className="d-md-flex">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Requirements"
                  value={requirement}
                  onChange={(e) => setRequirement(e.target.value)}
                />
                <span className="ml-1">
                  <MdHistory
                    title="history"
                    className="history-icon-gen"
                    onClick={() => getQuestions()}
                  />
                </span>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">About</label>
              <div className="d-md-flex">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter About"
                  value={about}
                  onChange={(e) => setAbout(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group ">
              <label htmlFor="exampleInputEmail1">Keywords</label>
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Keywords"
                  value={keywords}
                  onChange={(e) => setKeywords(e.target.value)}
                />
                <span className="ml-1">
                  <MdHistory
                    title="history"
                    className="history-icon-gen"
                    onClick={() => historypop()}
                  />
                </span>
              </div>
            </div>

            <div className="form-group ">
              <label htmlFor="exampleInputEmail1">
                No: of Images
                <span className="red ml-1">*</span>{" "}
              </label>
              <div className="d-flex">
                <select
                  className="form-control"
                  value={variations}
                  onChange={(e) => {
                    setVariations(e.target.value);
                  }}
                >
                  <option>Select No: of Images</option>
                  <option value="1" label="1"></option>
                  <option value="2" label="2"></option>
                  <option value="3" label="3"></option>
                  <option value="4" label="4"></option>
                </select>
              </div>
            </div>

            <div class="button-wrapper tag_changePswdHover">
              <button
                type="submit"
                class="btn-cancel"
                onClick={() => resetForm()}
              >
                {" "}
                Reset
              </button>
              <button
                type="submit"
                class="btn-submit"
                onClick={isSubmitting ? null : generateAd}
                disabled={isSubmitting}
              >
                Generate
              </button>
            </div>
          </div>

          <div className="col-md-7 history-popup-main d-md-flex justify-content-center text-center">
            {showQuestionsTable ? (
              <div className="history-popup p-2">
                <div className="text-right pull-right close-history">
                  <IoCloseCircleOutline
                    color="red"
                    onClick={() => hidePopup()}
                  />
                </div>
                <div className="d-md-flex text-center justify-content-center mb-2">
                  References
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th className="col-3">#</th>
                      <th className="col-3">Content</th>
                      <th className="col-3 ">Requested By</th>
                      <th className="col-3 ">Approved By</th>
                    </tr>
                  </thead>
                  <div className="table_Outerscroll">
                    <tbody className="table_Outertbody">
                      {questions.map((qn, index) => (
                        <tr
                          key={index}
                          className="history-div"
                          onClick={() => selectRefEntry(qn)}
                        >
                          <td className="col-3">{index + 1}</td>
                          <td className="col-3">{qn.answer_text}</td>
                          <td className="col-3">{qn.requested_by_name} </td>
                          <td className="col-3">
                            {qn.approved_by_name ? qn.approved_by_name : "NA"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </div>
                </table>
              </div>
            ) : showHistoryTable ? (
              <div className="history-popup p-2">
                <div className="text-right pull-right close-history">
                  <IoCloseCircleOutline
                    color="red"
                    onClick={() => hidePopup()}
                  />
                </div>
                <div className="d-md-flex text-center justify-content-center mb-2">
                  Ad History
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th className="col-1">#</th>
                      <th className="col-2 ">Name</th>
                      <th className="col-2">Model</th>
                      <th className="col-2 ">About</th>
                      <th className="col-3 ">Keywords</th>
                      <th className="col-2 ">Created On</th>
                    </tr>
                  </thead>
                  <div className="table_Outerscroll">
                    <tbody className="table_Outertbody">
                      {history.map((historyItem, index) => (
                        <tr
                          key={index}
                          className="history-div"
                          onClick={() => selectHistoryEntry(historyItem)}
                        >
                          <td className="col-1">{index + 1}</td>
                          <td className="col-2">{historyItem.plan_name}</td>
                          <td className="col-2">{historyItem.plan_model}</td>
                          <td className="col-2">
                            {historyItem.plan_about
                              ? historyItem.plan_about
                              : "NA"}{" "}
                          </td>
                          <td className="col-3">
                            {historyItem.plan_keyword
                              ? historyItem.plan_keyword
                              : "NA"}
                          </td>
                          <td className="col-2">
                            {moment(historyItem.created_on).format(
                              "YYYY-MM-DD"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </div>
                </table>
              </div>
            ) : null}

            {!isSubmitting && confirmFlag ? (
              <div className="row progress-bars">
                <div className="col-md-6 d-md-flex justify-content-center">
                  <CircularProgressbar value={progress} text={`${progress}%`} />
                </div>
                {/* <div className="col-md-6 d-md-flex justify-content-center">
                  <CircularProgressbar value={progress} text={`${progress}%`}/>
                </div>
                <div className="col-md-6 d-md-flex justify-content-center">
                  <CircularProgressbar value={progress} text={`${progress}%`}/>
                </div>
                <div className="col-md-6 d-md-flex justify-content-center">
                  <CircularProgressbar value={progress} text={`${progress}%`}/>
                </div> */}
              </div>
            ) : (
              <div className="col-md-12 history-popup-main">
                <div className="col-md-12">
                  {imageList.length > 0 && (
                    <div className="col-md-12 text-right mb-3 pr-0">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="selectAllCheckbox"
                          checked={selectAll}
                          onChange={(e) => {
                            setSelectAll(e.target.checked);
                            handleSelectAll(e.target.checked);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="selectAllCheckbox"
                        >
                          Select All
                        </label>
                      </div>
                      {selectedImages.length > 0 && (
                        <button
                          class="btn btn-primary ml-2"
                          style={{ minWidth: "100px" }}
                          onClick={() => sendForApproval()}
                        >
                          Send to Approval
                        </button>
                      )}
                    </div>
                  )}
                  <div className="row">
                    {imageList &&
                      imageList.map((image, index) => (
                        <div
                          className={`gen_image col-md-6 mb-4 ${selectedImages.includes(image.image_url)
                              ? "active"
                              : ""
                            }`}
                        // onClick={() => handleImageClick(index)}
                        >
                          <div class="card">
                            <div class="card-header text-left">
                              <input
                                type="checkbox"
                                onChange={() =>
                                  handleCheckboxClick(image.image_url)
                                }
                                checked={selectedImages.includes(
                                  image.image_url
                                )}
                              />
                            </div>
                            <div class="card-body">
                              <div className="popup-image mr-0">
                                <a
                                  href={image.image_url}
                                  title="img"
                                  target="_blank"
                                >
                                  <img
                                    src={image.image_url}
                                    className="img-fluid"
                                  />
                                </a>
                              </div>
                              {selectedImages.includes(image.image_url) && (
                                <img
                                  src={Correct}
                                  className="merge_tick"
                                  width="50"
                                  height="50"
                                  alt="correct"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showAddCampaignPopup && (
        <AddCampaignPopup onClose={closePopup} campaignGetData={contentInit} editProps='' />
      )}

      {showApprovalPopup && (
        <ApproverPopup
          onClose={closePopup}
          groupData={groupData}
          textContent={selectedImages}
          typeOfContent={"Planning"}
          campaignOptions={campaignOptions}
          selectedCampaign={campaign}
        />
      )}
    </div>
  );
}
