import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import CovidUpdtaes from "../images/covid-updates.png";
import * as Icon from "react-feather";

class eventPostDetails extends Component {
  render() {
    return (
      <div className="postDetails">
        <div className="form_group_google">
          <label>Post Details</label>
          <textarea className="form-control" cols="30" rows="5"></textarea>
        </div>
        <div className="right_google_popup">
          <label>
            <Icon.Camera />
            Add photos
            <input type="file" id="imageUpload" />
          </label>
        </div>
        <div className="form_group_google">
          <label>Add a button (optional)</label>
          <select id="callToActionButtons" className="form-control">
            <option value="0">Select</option>
            <option value="BOOK">Book</option>
            <option value="ORDER">Order Online</option>
            <option value="SHOP">Buy</option>
            <option value="LEARN_MORE">Learn More</option>
            <option value="SIGN_UP">Sign Up</option>
            <option value="CALL">Call Now</option>
          </select>
        </div>
        <div className="google_post_footer">
          <button type="button" className="btn btn-light">
            Preview
          </button>
          <button type="button" className="btn btn-primary">
            Post
          </button>
        </div>
      </div>
    );
  }
}

export default eventPostDetails;
