import React, { useState, useEffect } from "react";
import { backendApi, re } from "../../apiService";
import $ from "jquery";
function SaveAndValidation(props) {
  console.log(props, "propsprops");
  var orgadmin = window.sessionStorage.getItem("role") == "ORG_ADMIN";
  var email =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var phNum = /^\d{10}$/;
  var NumberValidation = /^\d+$/;
  const NewChangeAdd = () => {
    if (props.organizationId != "") {
      if (props.saveMode == "organizationSetting") {
        var flagvalue = true;
        // if (props.valueForSaved[1] == "") {
        //     alert("Organization Name Required");
        //     $("#organizationName").focus();
        //     flagvalue = false;
        // } else
        if (props.valueForSaved[2] == "") {
          alert("Organization Address Required");
          $("#organizationAddress").focus();
          flagvalue = false;
        } else if (props.valueForSaved[3] == "") {
          alert("Organization Mobile Number Required");
          $("#organizationmobno").focus();
          flagvalue = false;
        } else if (!props.valueForSaved[3].match(phNum)) {
          flagvalue = false;
          $("#organizationmobno").focus();
          alert("Mobile Number must be 10 digits");
        } else if (props.valueForSaved[4] == "") {
          alert("Organization Email Required");
          $("#organizationEmail").focus();
          flagvalue = false;
        } else if (!props.valueForSaved[4].match(email)) {
          alert("Organization Email id Format is wrong");
          $("#organizationEmail").focus();
          flagvalue = false;
        } else if (props.valueForSaved[5] == "") {
          alert("Organization PinCode Required");
          flagvalue = false;
          $("#organizationPinCode").focus();
        } else if (!props.valueForSaved[5].toString().match(NumberValidation)) {
          flagvalue = false;
          alert("Pin Code Must be a Number");
          $("#organizationPinCode").focus();
        }
        // else if (props.valueForSaved[6] == "") {
        //   alert("Organization Domain Required");

        //   flagvalue = false;
        // }
        if (flagvalue == true) {
          var req = {
            functionName: "editOrganizationDetails",
            organisation_id: props.organizationId,
            userId: window.sessionStorage.getItem("userId"),
            saveMode: "Organization",
            category_id: props.valueForSaved[0],
            organisation_name: props.valueForSaved[1],
            address: props.valueForSaved[2],
            mobile_no: props.valueForSaved[3],
            email_id: props.valueForSaved[4],
            pin_code: props.valueForSaved[5],
            domain: props.valueForSaved[6],
            is_active: props.valueForSaved[7] == true ? 1 : 0,
            address1: props.valueForSaved[8],
            address2: props.valueForSaved[9],
            address3: props.valueForSaved[10],
            administrative_area: props.valueForSaved[11],
            locality: props.valueForSaved[12],
            gstNo: props.valueForSaved[13],
          };

          backendApi(req).then((response) => {
            if (response.success === "1") {
              const myTimeout = setTimeout(props.getUserData(), 3000);
              alert("Saved successfully");
            }
          });
        }
      }
      /////////////////////////////////////////////////////////////
      if (props.saveMode == "Youtube") {
        var flagvalue = true;
        if (props.valueForSaved == "") {
          alert("YouTube Key Required");
          $("#youTubeKey").focus();
          flagvalue = false;
        }
        if (flagvalue == true) {
          var req = {
            functionName: "editOrganizationDetails",
            organisation_id: props.organizationIdFromYoutube,
            userId: window.sessionStorage.getItem("userId"),
            saveMode: "Youtube",
            ytKey: props.valueForSaved,
          };
        }
        backendApi(req).then((response) => {
          if (response.success === "1") {
            const myTimeout = setTimeout(props.getUserData(), 3000);
            alert("Saved successfully");
          }
        });
      }
      ////////////////////////////////////////////////////////////////////
      if (props.saveMode == "DataBaseRename") {
        var flagvalue = true;
        if (props.valueForSaved[0] == "") {
          alert("Database Name Required");
          $("#databasename").focus();
          flagvalue = false;
        }
        if (flagvalue == true) {
          var req = {
            functionName: "editOrganizationDetails",
            organisation_id: props.organizationId,
            userId: window.sessionStorage.getItem("userId"),
            saveMode: "Database",
            dbName: props.valueForSaved[0],
            dbStatus: props.valueForSaved[1] == true ? 1 : 0,
          };

          backendApi(req).then((response) => {
            if (response.success === "1") {
              const myTimeout = setTimeout(props.getUserData(), 3000);
              alert("Saved successfully");
            }
          });
        }
      }
      /////////////////////////////////////////////////////
      if (props.saveMode == "googleToken") {
        var flagvalue = true;
        if (window.sessionStorage.getItem("role") === "ROLE_SUPER_ADMIN") {
          if (props.valueForSaved[0] == "") {
            alert("Google Client mail Required");
            $("#Clientmail").focus();
            flagvalue = false;
          } else if (!props.valueForSaved[0].match(email)) {
            alert("Google Client Email Format is wrong");
            $("#organizationEmail").focus();
            flagvalue = false;
          } else if (props.valueForSaved[1] == "") {
            alert("Google Subject Required");
            $("#GoogleSubject").focus();
            flagvalue = false;
          } else if (props.valueForSaved[2] == "") {
            alert("Google Private Key Required");
            $("#PrivateKey").focus();
            flagvalue = false;
          }
        }
        if (props.valueForSaved[3] == "") {
          alert("Google Account Id Required");
          $("#AccountId").focus();
          flagvalue = false;
        }
        if (flagvalue == true) {
          if (window.sessionStorage.getItem("role") === "ROLE_SUPER_ADMIN") {
            var req = {
              functionName: "editOrganizationDetails",
              organisation_id: props.organizationId,
              userId: window.sessionStorage.getItem("userId"),
              saveMode: "GoogleToken",
              client_email: props.valueForSaved[0],
              subject: props.valueForSaved[1],
              private_key: props.valueForSaved[2],
              account_id: props.valueForSaved[3],
            };
          } else {
            var req = {
              functionName: "editOrganizationDetails",
              organisation_id: props.organizationId,
              userId: window.sessionStorage.getItem("userId"),
              saveMode: "GoogleToken",
              // client_email: props.valueForSaved[0],
              // subject: props.valueForSaved[1],
              // private_key: props.valueForSaved[2],
              account_id: props.valueForSaved[3],
            };
          }
          backendApi(req).then((response) => {
            if (response.success === "1") {
              const myTimeout = setTimeout(props.getUserData(), 3000);
              alert("Saved successfully");
              // window.location.reload();
            }
          });
        }
      }
      /////////////////////////////////////////////////////
      // if (props.saveMode == "facebook/instagram") {
      //   var flagvalue = true;
      //   if (window.sessionStorage.getItem("role") === "ROLE_SUPER_ADMIN") {
      //     if (props.valueForSaved[0] == "") {
      //       alert("Account Id Required");
      //       $("#Clientmail").focus();
      //       flagvalue = false;
      //     }
      //     // else if (!props.valueForSaved[0].match(email)) {
      //     //   alert("Google Client Email Format is wrong");
      //     //   $("#organizationEmail").focus();
      //     //   flagvalue = false;
      //     // }
      //     else if (props.valueForSaved[1] == "") {
      //       alert("Access Token Required");
      //       $("#GoogleSubject").focus();
      //       flagvalue = false;
      //     } else if (props.valueForSaved[2] == "") {
      //       alert("Base Url Required");
      //       $("#PrivateKey").focus();
      //       flagvalue = false;
      //     }
      //   }
      //   // if (props.valueForSaved[3] == "") {
      //   //   alert("Google Account Id Required");
      //   //   $("#AccountId").focus();
      //   //   flagvalue = false;
      //   // }
      //   if (flagvalue == true) {
      //     if (window.sessionStorage.getItem("role") === "ROLE_SUPER_ADMIN") {
      //       var req = {
      //         // {
      //         functionName: "editOrganizationDetails",
      //         saveMode: "facebookInsta",
      //         facebook_access_token: props.valueForSaved[0],
      //         facebook_baseUrl: props.valueForSaved[0],
      //         facebook_ad_account_id: props.valueForSaved[0],
      //         userId: window.sessionStorage.getItem("userId"),
      //         organisation_id: props.organizationId,
      //       };
      //       //   functionName: "editOrganizationDetails",
      //       //   organisation_id: props.organizationId,
      //       //   userId: window.sessionStorage.getItem("userId"),
      //       //   saveMode: "GoogleToken",
      //       //   client_email: props.valueForSaved[0],
      //       //   subject: props.valueForSaved[1],
      //       //   private_key: props.valueForSaved[2],
      //       //   account_id: props.valueForSaved[3],
      //       // };
      //     } else {
      //       var req = {
      //         functionName: "editOrganizationDetails",
      //         organisation_id: props.organizationId,
      //         userId: window.sessionStorage.getItem("userId"),
      //         saveMode: "GoogleToken",
      //         // client_email: props.valueForSaved[0],
      //         // subject: props.valueForSaved[1],
      //         // private_key: props.valueForSaved[2],
      //         account_id: props.valueForSaved[3],
      //       };
      //     }
      //     backendApi(req).then((response) => {
      //       if (response.success === "1") {
      //         const myTimeout = setTimeout(props.getUserData(), 3000);
      //         alert("Saved successfully");
      //         // window.location.reload();
      //       }
      //     });
      //   }
      // }
      ///////////////////////////////////////////////////////////
      if (props.saveMode == "twitterCredential") {
        var flagvalue = true;
        if (props.valueForSaved[1] == "") {
          alert("Twitter ApiKey  Required");
          $("#TwitterApiKey").focus();
          flagvalue = false;
        } else if (props.valueForSaved[2] == "") {
          alert("Twitter KeySecret Required");
          $("#TwitterKeySecret").focus();
          flagvalue = false;
        } else if (props.valueForSaved[0] == "") {
          alert("Twitter Token Required");
          $("#TwitterToken").focus();
          flagvalue = false;
        }
        if (flagvalue == true) {
          var req = {
            functionName: "editOrganizationDetails",
            organisation_id: props.organizationId,
            userId: window.sessionStorage.getItem("userId"),
            saveMode: "Twitter",
            twitterApiKey: props.valueForSaved[1],
            twitterKeySecret: props.valueForSaved[2],
            twitterToken: props.valueForSaved[0],
          };

          backendApi(req).then((response) => {
            if (response.success === "1") {
              const myTimeout = setTimeout(props.getUserData(), 3000);
              alert("Saved successfully");
            }
          });
        }
      }
      if (props.saveMode == "googleBusnessProfile") {
        var flagvalue = true;
        if (props.valueForSaved[0] == "") {
          alert("UserName Required");
          $("#username").focus();
          flagvalue = false;
        } else if (props.valueForSaved[1] == "") {
          alert("Password Required");
          $("#password").focus();
          flagvalue = false;
        } else if (props.valueForSaved[2] == "") {
          alert("Google Address Pin Required");
          $("#addresspin").focus();
          flagvalue = false;
        }
        if (flagvalue == true) {
          var req = {
            functionName: "editOrganizationDetails",
            organisation_id: props.organizationId,
            userId: window.sessionStorage.getItem("userId"),
            saveMode: "GBP",

            GBP_username: props.valueForSaved[0],
            GBP_password: props.valueForSaved[1],
            google_address_pin: props.valueForSaved[2],
          };

          backendApi(req).then((response) => {
            if (response.success === "1") {
              const myTimeout = setTimeout(props.getUserData(), 3000);
              alert("Saved successfully");
              //   window.location.reload();
            }
          });
        }
      }
      ///////////////////////////////////////////////////////////////
      if (props.saveMode == "localePageSetting") {
        var flagvalue = true;
        var regexUrl =
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
        if (props.valueForSaved[2] == "") {
          alert("Local Page Url Required");
          $("#local_page_link").focus();
          flagvalue = false;
        } else if (!props.valueForSaved[2].match(regexUrl)) {
          alert("Local Page Url Not Valid");
          $("#local_page_link").focus();
          flagvalue = false;
        } else if (props.valueForSaved[1] == "") {
          alert("Local Page CMS Link");
          $("#local_page_cms_link").focus();
          flagvalue = false;
        } else if (props.valueForSaved[3] == "") {
          alert("Local Page Folder Name Requred");
          $("#local_page_Folder_Name").focus();
          flagvalue = false;
        }
        if (flagvalue == true) {
          var req = {
            functionName: "editOrganizationDetails",
            organisation_id: props.organizationId,
            userId: window.sessionStorage.getItem("userId"),
            saveMode: "LocalPage",
            local_page_cms_link: props.valueForSaved[1],
            local_page_link: props.valueForSaved[2],
            localFolder: props.valueForSaved[3],
            local_is_active: props.valueForSaved[0] == true ? 1 : 0,
          };
          backendApi(req).then((response) => {
            if (response.success === "1") {
              const myTimeout = setTimeout(props.getUserData(), 3000);
              alert("Saved successfully");
            }
          });
        }
      }
      if (props.saveMode == "socialMediaTag") {
        var flagvalue = true;
        if ($("#socialtags").val() === "") {
          alert("Social Tags Required");
          $("#socialtags").focus();
          flagvalue = false;
        }
        if (flagvalue == true) {
          var req = {
            functionName: "editOrganizationDetails",
            organisation_id: props.organizationId,
            userId: window.sessionStorage.getItem("userId"),
            saveMode: "Social",
            socialTags: props.valueForSaved[0],
          };
          backendApi(req).then((response) => {
            if (response.success === "1") {
              const myTimeout = setTimeout(props.getUserData(), 3000);
              alert("Saved successfully");
            }
          });
        }
      }
      if (props.saveMode == "seoDetails") {
        var flagvalue = true;
        if (props.valueForSaved[0] == "") {
          alert("SEO Title Required");
          $("#seoTitle").focus();
          flagvalue = false;
        } else if (props.valueForSaved[1] == "") {
          alert("SEO Description Required");
          $("#seodescription").focus();
          flagvalue = false;
        } else if (props.valueForSaved[2] == "") {
          alert("SEO Slug Required");
          $("#seoslug").focus();
          flagvalue = false;
        }
        if (flagvalue == true) {
          var req = {
            functionName: "editOrganizationDetails",
            organisation_id: props.organizationId,
            //  orgadmin ? organisationId : flag[0].id,
            userId: window.sessionStorage.getItem("userId"),
            saveMode: "SEO",
            seo_title: props.valueForSaved[0],
            seo_description: props.valueForSaved[1],
            seo_slug: props.valueForSaved[2],
          };
        }
        backendApi(req).then((response) => {
          if (response.success === "1") {
            const myTimeout = setTimeout(props.getUserData(), 3000);
            alert("Saved successfully");
          }
        });
      }
      if (props.saveMode == "trumblerCredential") {
        var flagvalue = true;
        if (props.valueForSaved[3] == "") {
          alert("Tumblr TokenSecret  Required");
          $("#TumblrTokenSecret").focus();
          flagvalue = false;
        } else if (props.valueForSaved[2] == "") {
          alert("Tumblr TokenKey Required");
          $("#TumblrTokenKey").focus();
          flagvalue = false;
        } else if (props.valueForSaved[1] == "") {
          alert("Tumblr ConsumerSecret Required");
          $("#TumblrConsumerSecret").focus();
          flagvalue = false;
        } else if (props.valueForSaved[0] == "") {
          alert("Tumblr ConsumerKey Required");
          $("#TumblrConsumerKey").focus();
          flagvalue = false;
        }
        if (flagvalue == true) {
          var req = {
            functionName: "editOrganizationDetails",
            organisation_id: props.organizationId,
            userId: window.sessionStorage.getItem("userId"),
            saveMode: "Tumblr",
            tumblrConsumerKey: props.valueForSaved[0],
            tumblrConsumerSecret: props.valueForSaved[1],
            tumblrTokenKey: props.valueForSaved[2],
            tumblrTokenSecret: props.valueForSaved[3],
          };

          backendApi(req).then((response) => {
            if (response.success === "1") {
              const myTimeout = setTimeout(props.getUserData(), 3000);
              alert("Saved successfully");
            }
          });
        }
      }
      if (props.saveMode == "facebookInstagram") {
        alert("hi");
        if (props.valueForSaved[0] == "") {
          alert("Facebook/Instagram account I.D. required");
          $("#facebookAccountId").focus();
          flagvalue = false;
        } else if (props.valueForSaved[1] == "") {
          alert("Facebook/Instagram access token required");
          $("#facebookAccessToken").focus();
          flagvalue = false;
        } else if (props.valueForSaved[2] == "") {
          alert("Facebook/Instagram base URL required");
          $("#facebookBaseUrl").focus();
          flagvalue = false;
        } else {
          flagvalue = true;
        }
        if (flagvalue == true) {
          var req = {
            functionName: "editOrganizationDetails",
            organisation_id: props.organizationId,
            userId: window.sessionStorage.getItem("userId"),
            saveMode: "facebookInsta",
            facebook_ad_account_id: props.valueForSaved[0],
            facebook_access_token: props.valueForSaved[1],
            facebook_baseUrl: props.valueForSaved[2],
          };

          backendApi(req).then((response) => {
            if (response.success === "1") {
              const myTimeout = setTimeout(props.getUserData(), 3000);
              alert("Saved successfully");
            }
          });
        }
      }
      if (props.saveMode == "ChatGPTSetting") {
        var req = {
          functionName: "editOrganizationDetails",
          organisation_id: props.organizationId,
          userId: window.sessionStorage.getItem("userId"),
          saveMode: "ChatGPTSetting",
          chatGPTStatus: props.valueForSaved[0] == true ? 1 : 0,
        };

        backendApi(req).then((response) => {
          if (response.success === "1") {
            const myTimeout = setTimeout(props.getUserData(), 3000);
            alert("Saved successfully");
          } else {
            alert("Unable to change status of ChatGPT Setting.");
          }
        });
      }
    } else {
      if (props.valueForSaved[1] == "") {
        alert("Organization Name Required");
        $("#organizationName").focus();
        return false;
      } else if (props.valueForSaved[2] == "") {
        alert("Organization Address Required");
        $("#organizationAddress").focus();
        return false;
      } else if (props.valueForSaved[3] == "") {
        alert("Organization Mobile Number Required");
        $("#organizationmobno").focus();
        return false;
      } else if (!props.valueForSaved[3].match(phNum)) {
        $("#organizationmobno").focus();
        alert("Mobile Number must be 10 digits");
        return false;
      } else if (props.valueForSaved[4] == "") {
        alert("Organization Email Required");
        $("#organizationEmail").focus();
        return false;
      } else if (!props.valueForSaved[4].match(email)) {
        alert("Organization Email id Format is wrong");
        $("#organizationEmail").focus();
        return false;
      } else if (props.valueForSaved[5] == "") {
        alert("Organization PinCode Required");
        $("#organizationPinCode").focus();
        return false;
      } else if (!props.valueForSaved[5].toString().match(NumberValidation)) {
        alert("Pin Code Must be a Number");
        $("#organizationPinCode").focus();
        return false;
      } else if (props.valueForSaved[6] == "") {
        alert("Organization Domain Required");
        return false;
      }
      var req = {
        functionName: "editOrganizationDetails",
        organisation_id: "",
        userId: window.sessionStorage.getItem("userId"),
        saveMode: "Organization",
        category_id: props.valueForSaved[0],
        organisation_name: props.valueForSaved[1],
        address: props.valueForSaved[2],
        mobile_no: props.valueForSaved[3],
        email_id: props.valueForSaved[4],
        pin_code: props.valueForSaved[5],
        domain: props.valueForSaved[6],
        is_active: props.valueForSaved[7] == true ? 1 : 0,
      };

      backendApi(req).then((response) => {
        if (response.success === "1") {
          const myTimeout = setTimeout(props.getUserData(), 3000);
          alert("Saved successfully");
          // window.location.reload();
        }
      });
    }
  };
  return (
    <div className="settings_form text-right">
      <button
        type="button"
        className="btn btn-success"
        onClick={(e) => NewChangeAdd(e)}
      >
        Save
      </button>
    </div>
  );
}
export default SaveAndValidation;
