import React, { useEffect, useState } from 'react'
import { backendDashboardApi } from "../apiService";
function MetaPaidMedia({ formattedDates }) {
    const [metaPaidMedia, setMetaPaidMedia] = useState([]);
    const [getProductsDetails, setGetProductsDetails] = useState([]);
    const [getAdTypeDetails, setGetAdTypeDetails] = useState([]);
    const [productSelectedValue, setProductSelectedValue] = useState("");
    const [adTypeSelectedValue, setAdTypeSelectedValue] = useState("");
    useEffect(() => {
        Listing("", "");
        productList();
        adTypeList();
        console.log("🚀 ~ MetaPaidMedia ~ formattedDates:", formattedDates)
    }, [formattedDates])
    const Listing = (productId, adTypeId) => {
        const postData = {
            "functionName": "metaPaidMedia",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "userId": window.sessionStorage.getItem("userId"),
            "productId": productId,
            "adTypeId": adTypeId,
            "fromDate": formattedDates[0],
            "toDate": formattedDates[1]
        }
        backendDashboardApi(postData).then((data) => {
            console.log("metaPaidMedia", data)
            if (data.success === "1") {
                setMetaPaidMedia(data.result)
            } else {
                setMetaPaidMedia([])
            }
        });

    };
    const productList = () => {
        const postData = {
            "functionName": "getProductsDetails",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "typeId": ""
        }
        backendDashboardApi(postData).then((data) => {
            console.log("getProductsDetails", data)
            if (data.success === "1") {
                setGetProductsDetails(data.result)
            } else {
                setMetaPaidMedia([])
            }
        });
    };
    const adTypeList = () => {
        const postData = {
            "functionName": "getAdTypeDetails",
            "type": ""
        }
        backendDashboardApi(postData).then((data) => {
            console.log("getAdTypeDetails", data)
            if (data.success === "1") {
                setGetAdTypeDetails(data.result)
            } else {
                setGetAdTypeDetails([])
            }
        });
    };
    const handleProductSelectChange = (event) => {
        const productId = event.target.value;
        setProductSelectedValue(productId);
        Listing(productId, adTypeSelectedValue);
    };

    const handleAdTypeSelectChange = (event) => {
        const adTypeId = event.target.value;
        setAdTypeSelectedValue(adTypeId);
        Listing(productSelectedValue, adTypeId);
    };
    return (
        <div className="db-cards-40 gbp-searches google_paid_media">
            <div className="card db-height">
                <div className="card-body">
                    <div class="db-cards-top db-card-top-paid">
                        <h5 class="card-title card-title02">Meta - Paid Media</h5>
                        {console.log("getProductsDetails:", getProductsDetails)}
                        {console.log("productSelectedValue:", productSelectedValue)}
                        {console.log("Selected Product Name:", getProductsDetails.find(product => product.id === productSelectedValue)?.name)}
                        <select name="" id="" value={productSelectedValue} onChange={handleProductSelectChange} className="button_select"
                            title={getProductsDetails.find(product => product.id === parseInt(productSelectedValue))?.name || ""}>
                            <option value="">Vehicle</option>
                            {getProductsDetails?.map((data, i) => (
                                <option key={i} value={data.id}>{data.name}</option>
                            ))}
                        </select>
                        <select name="" id="" value={adTypeSelectedValue} onChange={handleAdTypeSelectChange} className="button_select"
                          title={getAdTypeDetails.find(product => product.id === parseInt(adTypeSelectedValue))?.name || ""}>
                            <option value="">Ad Type</option>
                            {getAdTypeDetails?.map((data, i) => (
                                <option key={i} value={data.id}>{data.name}</option>
                            ))}
                        </select>
                    </div>


                    {metaPaidMedia.length > 0 ?
                        <div className='db-cards-top mb-0 db-cards-check align-items-start'>
                            <div className="db-height w-100">
                                <div className='row db-row-col-common'>
                                    {metaPaidMedia?.map((data, i) => (
                                        <div className='col-md-6 db-row-col-first mb-0'>
                                            <div class="card bg-white shadow-none my-0">
                                                <div className='db-cards-top an-selectBy'>
                                                    <h5 class="db-card-title">{data.name}</h5>
                                                    <span className='db-right-number'>{data.total}
                                                        {data.symbol != "" ?
                                                            <span className='db-percentage'>({data.symbol})</span>
                                                            : ""}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                        : <div className='text-center no-data-found' style={{ fontFamily: 'Gilroy-Medium' }}>No Data Found</div>}
                </div>
            </div>
        </div>
    )
}

export default MetaPaidMedia;