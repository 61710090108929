import React, { useEffect, useState } from "react";
import { backendApi } from "../apiService";
import Header from "../layout/header";
import LeftMenu from "../layout/leftMenu";
import * as Icon from "react-feather";
import Footer from "../layout/footer";
import Pagination from "react-js-pagination";
import Auditimage from "../images/left_icons/processing.png";
import { Link } from "react-router-dom";
import moment from "moment";
import $ from "jquery";

function PushDataHistory() {
  const [pushHistoryList, setPushHistoryList] = useState([]);
  const [pushHistoryListBackup, setPushHistoryListBackup] = useState([]);
  const [pushHistoryListSub, setPushHistoryListSub] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [response, setResponse] = useState("Waiting For Data");
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getUidHistoryList();
    getUidHistoryListSub();
  }, []);
  const getUidHistoryList = () => {
    $(".loder_div").attr("hidden", false);
    const apiReq = {
      functionName: "list_push_bulk_by_uid_history",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apiReq).then((data) => {
      //   console.log("data", data);
      if (data.success == "1") {
        setResponse("");
        setPushHistoryListBackup(data.data);
        setTotalCount(data.data.length);
        let res = data.data;
        const indexOfLastPost = currentPage * 10;
        const indexOfFirstPost = indexOfLastPost - 10;
        const currentHistoryList = res.slice(indexOfFirstPost, indexOfLastPost);
        setPushHistoryList(currentHistoryList);
      } else {
        setResponse("No Data Found");
        setPushHistoryListBackup([]);
        setTotalCount("0");
        setPushHistoryList([]);
      }
    });
  };
  const getUidHistoryListSub = () => {
    const apiReq = {
      functionName: "list_push_bulk_by_uid_history_sub",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apiReq).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success == "1") {
        // console.log("data", data);
        setPushHistoryListSub(data.data);
      } else {
        setPushHistoryListSub([]);
      }
    });
  };
  const handleActivePage = (e) => {
    setCurrentPage(e);
    const indexOfLastPost = e * 10;
    const indexOfFirstPost = indexOfLastPost - 10;
    let tempList = pushHistoryListBackup;
    const currentHistoryList = tempList.slice(
      indexOfFirstPost,
      indexOfLastPost
    );
    // setLocalPostList(currentPosts.reverse());
    setPushHistoryList(currentHistoryList);
  };
  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Push Location(s) History" headerImage={Auditimage} />

        <div className="container-fluid">
          <div className="row mb-3 ml-3">
            <div className="col-md-12 Gbp_headtopAuto">
              <div className="row">
                <div className="col-md-3">
                  {/* <Link
          to="/claim_business/bulkUploadVerifyPage"
          className="back-to-link mr-3"
        > */}
                  <Icon.ArrowLeftCircle onClick={() => window.history.back()} />
                  {/* </Link> */}
                </div>
                {/* <div className="col-md-7 text-right">
                  <button
                    className="btn btn-primary"
                    // onClick={() => BulkUpload()}
                    style={{ padding: "4px 9px", minWidth: "160px" }}
                  >
                    Bulk Push To GBP
                    <Icon.ArrowRight className="mr-1" width={18} />
                  </button>
                </div> */}
                {/* {pushDataList.some((el) => el.status === "Push Initiated") && (
                  <div className="module_popupsearchBtn">
                    <button
                      type="button"
                      className="btn btn-primary createGBP"
                      onClick={(e) => {
                        createNewLocation(pushDataList);
                      }}
                    >
                      Push to GBP
                    </button>
                  </div>
                )} */}
              </div>
            </div>
          </div>
          {pushHistoryList.length > 0 ? (
            <div className="col-md-12 tableResponsive">
              <div className="tableOuterWidth">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="col-1">Serial No.</th>
                      <th className="col-3">Created On</th>
                      <th className="col-2">No of items.</th>
                      <th className="col-4">Status</th>
                      <th className="col-2">View More</th>
                    </tr>
                  </thead>
                  <div class="table_Outerscroll">
                    {pushHistoryList.map((data, i) => (
                      <tbody className="table_Outertbody">
                        <tr>
                          <td className="col-1">
                            {i + 1}
                            <br></br>
                            <span>
                              {/* {data.storeCode != "undefined" &&
                        data.storeCode != "null" &&
                        data?.storeCode
                          ? data.storeCode
                          : ""} */}
                            </span>
                          </td>

                          <td className="col-3">
                            {moment(data.created_on).format(
                              "MMM DD, YYYY, hh:mm a"
                            )}
                            {/* {data.created_on} */}
                          </td>

                          {/* <td className="col-3">{data.address}</td> */}
                          <td className="col-2">{data.cnt}</td>
                          <td className="col-4">
                            {pushHistoryListSub
                              .filter((obj) => obj.UID == data.UID)
                              .map((object) => {
                                return (
                                  <span style={{ display: "block" }}>
                                    {object.STATUS
                                      ? object.STATUS
                                      : "Not Pushed"}
                                    :{object.cnt}
                                  </span>
                                );
                              })}
                          </td>
                          <td className="col-2">
                            <Link
                              to={
                                "/claim_business/listOfPushSaveLocations/" +
                                data.UID
                              }
                            >
                              <span title="View More">
                                <Icon.Eye />
                              </span>
                            </Link>
                          </td>
                          {/* {data.status === "Location Created" && (
                    <td className="col-4">
                      <p>
                        <Icon.CheckCircle
                          style={{ color: "GREEN" }}
                          className="icon_common"
                        />
                        Created Location
                      </p>
                      <button className="btn btn-success">
                        Start Verification Process
                      </button>
                    </td>
                  )} */}
                          {/* {data.status === "Push Initiated" && (
                      <td className="col-2">
                        <p className="mb-0">{data.status}</p>
                      </td>
                    )}
                    {data.status === "Location Creation Failed" && (
                      <>
                        <td className="col-2">
                          <p className="mb-0">
                            <Icon.Globe
                              style={{ color: "RED" }}
                              className="icon_common"
                            />
                            {data.status}
                          </p>
                        </td>
                        <td className="col-2">
                          <button
                            className="btn btn-success btn-success-width"
                            onClick={(e) => {
                              setVisibility(!visibility);
                              setPropsData(data);
                            }}
                          >
                            Edit Listing
                          </button>
                        </td>
                      </>
                    )}
                    {(data.status === "Location Created" ||
                      data.status === "Verification Failed") && (
                      <>
                        <td className="col-2">
                          <p className="mb-0">
                            {data.status === "Location Created" && (
                              <Icon.Globe
                                style={{ color: "GREEN" }}
                                className="icon_common"
                              />
                            )}
                            {data.status === "Verification Failed" && (
                              <Icon.User
                                style={{ color: "RED" }}
                                className="icon_common"
                              />
                            )}
                            {data.status}
                          </p>
                        </td>
                        <td className="col-2">
                          <button
                            className="btn btn-success btn-success-width"
                            onClick={(e) => {
                              setVerifyVisibility(true);
                              setPropsData(data);
                            }}
                          >
                            Verify
                          </button>
                        </td>
                      </>
                    )}
                    {data.status === "Verification Started" && (
                      <>
                        <td className="col-2">
                          <p className="mb-0">
                            <Icon.User
                              style={{ color: "GREEN" }}
                              className="icon_common"
                            />
                            {data.status}
                          </p>
                        </td>
                        <td className="col-2">
                          <button
                            className="btn btn-success"
                            onClick={(e) => {
                              setOTPVisibility(!OTPVisibility);
                              setPropsData(data);
                            }}
                          >
                            Enter Verification Code
                          </button>
                        </td>
                      </>
                    )}
                    {(data.status === "Verified" ||
                      data.status === "Admin Invitation Failed") && (
                      <>
                        <td className="col-2">
                          <p className="mb-0">
                            {data.status === "Verified" && (
                              <Icon.MapPin
                                style={{ color: "Green" }}
                                className="icon_common"
                              />
                            )}
                            {data.status === "Admin Invitation Failed" && (
                              <Icon.CheckCircle
                                style={{ color: "RED" }}
                                className="icon_common"
                              />
                            )}

                            {data.status}
                          </p>
                        </td>
                        <td className="col-3">
                          <button
                            className="btn btn-success"
                            onClick={(e) => {
                              setInviteAdminVisibility(
                                !inviteAdminVisibility
                              );
                              setPropsData(data);
                            }}
                          >
                            Invite Admin to Location
                          </button>
                        </td>
                      </>
                    )}
                    {data.status === "Admin Invited" && (
                      <td className="col-2">
                        <p className="mb-0">
                          <Icon.Compass
                            style={{ color: "GREEN" }}
                            className="icon_common"
                          />
                          {data.status}
                        </p>
                      </td>
                    )} */}
                          {/* {data.error != "" && (
                    <td className="col-2">
                      <button className="btn btn-danger"
                        onClick={() => {
                          setErrorPopup(true);
                          setErrorDetails(data.errorMessage.split(","));
                        }}
                      >
                        Show Errors
                      </button>
                    </td>
                  )} */}
                        </tr>
                      </tbody>
                    ))}
                  </div>
                </table>
              </div>
            </div>
          ) : (
            response
          )}
        </div>
        {totalCount > 10 && (
          <div className="pagination_main  tag_postListNext">
            <Pagination
              hideDisabled
              activePage={currentPage}
              itemsCountPerPage="10"
              totalItemsCount={totalCount}
              pageRangeDisplayed="10"
              onChange={(e) => handleActivePage(e)}
            />
          </div>
        )}
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
}

export default PushDataHistory;
