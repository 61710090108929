import React, { Component } from "react";
import { apiCallingPost } from "../apiService";
import $ from "jquery";
import * as Icon from "react-feather";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
class supportView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listSupport: [],
      insertId: "",
      convertedText: "",
    };


  }
  componentDidMount() {
    this.listSupport();
    $("#nodata").hide();
    $(".ck_send_icon").click(function () {
      $(".alert_div_box").css({
        display: "flex",
      });
    });
    $(".close_alert_box").click(function () {
      $(".alert_div_box").css({
        display: "none",
      });
    });
    console.log("sssssssssssssss", this.state.convertedText);
  }

  listSupport() {
    $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "listSupport",

      created_by: window.sessionStorage.getItem("userId"),
      role:
        window.sessionStorage.getItem("switchUserRole") === ""
          ? window.sessionStorage.getItem("role")
          : window.sessionStorage.getItem("switchUserRole"),
    };

    apiCallingPost(reviewAPIData).then((data) => {
      console.log("data=========", data);

      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        this.setState({
          listSupport: data.data,
        });
      } else {
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  sendIssue() {
    // let text =this.state.convertedText
let newText = (this.state.convertedText).replace(/(<p>|<\/p>|<br>)/gi, "")
if(newText){
    $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "saveSupportData",
      created_by: window.sessionStorage.getItem("userId"),
      issues: this.state.convertedText,
    };

    apiCallingPost(reviewAPIData).then((data) => {
      if (data.success === "1") {
        console.log(data,"jjjjjjjjjjjjjjj")
        $(".loder_div").attr("hidden", true);
      //  alert("Send successfully");
      alert("Your issue has been reported, and this is your token number: "+ data.result.insertId+"." )
        this.listSupport();
        this.setState({
          insertId: data.result.insertId,
          convertedText: "",
        });
      } else {
        $(".loder_div").attr("hidden", true);
        alert("failed");
      }
    });
  }else{alert("Field Cannot Be Empty")}
  }


  convertedText(e) {
    console.log(e);
    this.setState({
      convertedText: e,
    });
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="ck_editer_div">
            <div className="position-relative">
              <ReactQuill
                theme="snow"
                value={this.state.convertedText}
                onChange={(e) => this.convertedText(e)}
                style={{ minHeight: "200px", }}
              />

              {/* <div className="ck_send_icon" onClick={() => this.sendIssue()}>
              
              </div> */}
              <button
             
             type="button"
             className="btn btn-info send_Icon"
             onClick={() => this.sendIssue()}
           >
             Send
           </button>
            </div>
          </div>
        </div>
        <div className="alert_div_box">
          <div className="inner_alert_div_box">
            <p className="text-success mb-1">
              Your request sent successfully.{" "}
            </p>
            <p className="text-secondary mb-1">
              Your Ticket is <span>{this.state.insertId}</span>
            </p>
            <Icon.XCircle className="close_alert_box" />
          </div>
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}

export default supportView;
