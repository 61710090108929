import React, { useEffect, useState } from 'react'
import BudgetGraph from "./budgetGraph"
import Budget from "../../images/budget.png"
import Price from "../../images/pricesymbol.png"
function PaidMedia() {

    return (
        <div className='section-wrapper budget-section-wrap'>
                <div className="budget-leftsec">
                    <div className="budget-leftsec-innersec">
                        <div className="budget-text-sec">
                            <img src={Budget} alt="" className='img-fluid' /><span className="budget-text">Overal Budget</span>
                        </div>
                        <div className="budget-text-sec"><img src={Price} alt="" className='img-fluid' /><span className="price-symbol">985.5 L</span></div>
                    </div>
                    <div className="budget-leftsec-innersec">
                        <div className="budget-text-sec">
                            <img src={Budget} alt="" className='img-fluid' /><span className="budget-text">Budget Spent</span>
                        </div>
                        <div className="budget-text-sec"><img src={Price} alt="" className='img-fluid' /><span className="price-symbol">922.5 L</span></div>
                    </div>
                    <div className="budget-leftsec-innersec">
                        <div className="budgetborder-bottom"></div>
                        <div className="graphlabl-outer">
                            <div className="graphlabl">
                                <span className="graphlabl-blue"></span>
                                <span className="graphlabltext">Google (60.61 %)
                                </span>
                            </div>
                            <div className="graphlabl">
                                <span className="graphlabl-yellow"></span>
                                <span className="graphlabltext">Meta (30 %)
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="">
                    <BudgetGraph style={{ width: '100%', maxWidth: '800px' }}/>
                </div>
        </div>
    )
}

export default PaidMedia;