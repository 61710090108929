import React, { Component } from "react";
import * as Icon from "react-feather";
import ReactApexChart from "react-apexcharts";
class DashChartMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: this.props.valueForGraphMap.map((i) => parseInt(i)),

      options: {
        labels: [
          "Call Clicks(%)",
          "Direction Clicks(%)",
          "Chat Clicks(%)",
          "Website Clicks(%)",
        ],
        chart: {
          width: 150,
          type: "donut",
        },
        colors: ["#008ffb", "#28a745", "#feb019", "#dc3545"],
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                width: 120,
              },
              title: {
                style: {
                  fontSize: "12px",
                },
              },
            },
          },
        ],
        legend: {
          show: false,
        },
        title: {
          text: "",
          align: "left",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263238",
          },
        },
      },
    };
  }
  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="donut"
          width={150}
        />
      </div>
    );
  }
}

export default DashChartMap;
