import React, { Component } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Dashboardimage from "../images/left_icons/dashboard.png";
import DatePicker from "react-datepicker";
import $ from "jquery";
import DragDropFile from "./dragDropFile";
import DataInput from "./dataInput";
import * as Icon from "react-feather";
class BulkPublish extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: "",
            endDate: "",
        }
    }
    handleChangeStart(value) {
        this.setState({
          startDate: value,
        });
      }
    
      handleChangeEnd(values) {
        this.setState({
          endDate: values,
        });
      }
    componentDidMount() {
        $("#headerName").text("Bulk publish");
      }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper">
          <Header heading="Dashboard" headerImage={Dashboardimage}/>
          <div className="container-fluid px-5">
          {/* <div
              className="col-md-12 downloadTemplateDiv"
              onClick={() => this.downloadTemplate()}
            >
              <Icon.Download /> Download template
            </div> */}
          <div className="file_upload_outer">
              <DragDropFile handleFile={this.handleFile}>
                <div className="form-group formBottom" id="form-fileupload">
                    <DataInput handleFile={this.handleFile} />
                </div>
              </DragDropFile>
              {this.state.uploaded === true && this.OutTable()}
              
            <div
                className="col-lg-3 col-md-12 TemplateDowloadDiv"
                onClick={() => this.downloadTemplate()}
              >
                <Icon.Download /> Download template
              </div>
            </div>
                <div className="row">
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <select className="form-control">
                    <option value="">India</option>
                    <option value="">Sri Lanka</option>
                  </select>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <select className="form-control">
                    <option value="0">State</option>
                    <option value="">Kerala</option>
                  </select>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <select className="form-control">
                    <option  value="0">City</option>
                    <option  value="">Tvm</option>
                  </select>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={(date) => this.handleChangeStart(date)}
                    name="startDate"
                    showMonthDropdown
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    placeholderText="From Date"
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <DatePicker
                    selected={this.state.endDate}
                    onChange={(date) => this.handleChangeEnd(date)}
                    name="startDate"
                    id="endDate"
                    showMonthDropdown
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    placeholderText="To Date"
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 location_search">
                    <input className="form-control" type="text" placeholder="Location"/>
                    <div className="location_search_drop">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <span>Location1</span>
                        </li>
                        <li class="nav-item">
                            <span>Location2</span>
                        </li>
                        <li class="nav-item">
                            <span>Location3</span>
                        </li>
                        <li class="nav-item">
                            <span>Location4</span>
                        </li>
                        <li class="nav-item">
                            <span>Location5</span>
                        </li>
                        <li class="nav-item">
                            <span>Location6</span>
                        </li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <button
                    className="btn-search">
                   Search
                  </button>
                </div>
          </div>
          
          <div className="tag_DelBtn text-right my-1">
          {/* <button class=" btn btn-danger">Delete</button>  */}
            <button class="tag_trashBtn">
            <Icon.Trash title="Delete" style={{ width: "25px", color:'red' }} />
              </button> 
          </div>
         

          <div className="col-md-12 tableResponsive">
          <div className="tableOuterWidth">
            <table className="table">
              <thead>
                <tr>
                  <th className="col-1"> 
                    <div className="d-flex align-items-center">
                    <input type="checkbox" name="" /> 
                    {/* <div className="updown_arrow ml-2">
                        <div className="uparrow_publish"></div>
                        <div className="downarrow_publish"></div>
                    </div> */}
                    </div>
                  </th>
                  <th className="col-11">Business Information</th>
                </tr>
              </thead>
              <div className="table_Outerscroll">
              <tbody className="table_Outertbody">
                <tr>
                  <td className="col-1"> <input type="checkbox" name="" />
                  </td>
                  <td className="col-11">
                    <p className="mb-0">Mahindra A Automovers</p>
                      <p className="mb-0">Gate No 1 Hardoi Rd, Andhe ki Chowki, Dubagga Utter Pradesh 226003, 9000739755, SA010541AAUT1DUBAGA</p>
                  </td>
                </tr> 
              </tbody>
              <tbody className="table_Outertbody">
                <tr>
                  <td className="col-1"> <input type="checkbox" name="" />
                  </td>
                  <td className="col-11">
                    <p className="mb-0">Mahindra A Automovers</p>
                      <p className="mb-0">Gate No 1 Hardoi Rd, Andhe ki Chowki, Dubagga Utter Pradesh 226003, 9000739755, SA010541AAUT1DUBAGA</p>
                  </td>
                </tr> 
              </tbody>
               
              </div>
            </table>
          </div>
          </div>

          
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default BulkPublish;
