import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import Accessibiltyimage from "../images/left_icons/Accessibility-and-visibility.png";
import { TagsInput } from "react-tag-input-component";
import cloudImg from "../images/cloud-computing.png";
import deleteImg from "../images/Group122.png";
import DragDropFile from "../audit/dragDropFile";
import DataInput from "../audit/dataInput";
import { backendApi, pythonBackendApi } from "../apiService";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import uploadImg from "../images/upload.png";
import $ from "jquery";
import closeImg1 from "../images/close1.png";
import Address from "../images/address-match.png";
import phone from "../images/phnmatch.png";
import businessName from "../images/business-name.png";
import * as Icon from "react-feather";
import exportFromJSON from "export-from-json";
import moment from "moment";

import VisibilityAccuracyGraph from "./visibility_accuracyGraph";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

var total_location = 0;
var address_match = 0;
var address_match_percentage = 0;
var phone_no_match = 0;
var phoneno_match_percentage = 0;
var business_name_match_percentage = 0;
var business_name_match = 0;
var totalNumber = 0;
var latestUpdate = 0;
const excelMime = [
  "application/vnd.ms-excel",
  "application/msexcel",
  "application/x-msexcel",
  "application/x-ms-excel",
  "application/x-excel",
  "application/x-dos_ms_excel",
  "application/xls",
  "application/x-xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
export default class Visibility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      file: null,
      buffer: null,
      googleLocationsCount: 0,
      excelLocationsCount: 0,
      convertText: "",
      sourceText: "",
      status: "",
      src: "",
      graphMode: "",
      imageLanguage: "",
      imageLanguageLabel: "",
      translateLanguage: "",
      languageListImage: [],
      languageListTTS: [],
      uploaded: false,
      // dataList: [],
      // excelData: [],
      flag: false,
      googleData: [],
      keyword: [],
      keywordLength: 0,
      addressMatchFirst: 0,
      addressMatchFirstPercentage: 0,
      businessNameMatchFirst: 0,
      businessNameMatchFirstPercentage: 0,
      phoneNoMatchFirst: 0,
      phoneNoMatchFirstPercentage: 0,
      viewDetailsId: "",
      getMasterdata: [],
      graphDataListingonSearch: [],
      getMasterdataXaxis: [],
      getGraphAdressData: [],
      getGraphBusnessNameData: [],
      totalLocation: 0,
      insightsStartDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ).toISOString(),
      insightsEndDate: new Date().toISOString(),
    };
  }
  componentDidMount() {
    this.visibilityAndAccuracyGraph();
    this.locationsCountAPI();
  }
  locationsCountAPI() {
    const apiReq = {
      functionName: "visibilityListingMaster",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apiReq).then((data) => {
      console.log("hiiiiii", data);
      this.setState({
        googleLocationsCount: data.gbp_count[0].gbp_location_cnt,
        excelLocationsCount: data.excel_cnt[0].excel_cnt,
      });
    });
  }
  searchFunction(Dateselected, type) {
    totalNumber = 0;
    latestUpdate = 0;
    total_location = 0;
    address_match = 0;
    address_match_percentage = 0;
    phone_no_match = 0;
    phoneno_match_percentage = 0;
    business_name_match = 0;
    business_name_match_percentage = 0;
    var lastyear = new Date(new Date().getFullYear() - 1, 0, 1);
    var startDate = new Date(lastyear.getFullYear(), 0, 1).getTime();
    var FromDate = this.state.insightsStartDate;
    var ToDate = this.state.insightsEndDate;
    if (type === "fromDate") {
      FromDate = Dateselected;
    } else if (type === "toDate") {
      ToDate = Dateselected;
    }
    var req = {
      functionName: "getVisibilityAndAccuracyData",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      start_date:
        FromDate == "null"
          ? moment(startDate).format("YYYY-MM-DD")
          : moment(FromDate).format("YYYY-MM-DD"),
      end_date:
        ToDate == "null"
          ? new Date("YYYY-MM-DD")
          : moment(ToDate).format("YYYY-MM-DD"),
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        let graphDataListingonSearch = [];
        let getMasterdataXaxis = [];
        let getGraphAdressData = [];
        let getGraphBusnessNameData = [];
        let totalLength = data.result.length;
        this.setState({
          addressMatchFirst: parseInt(
            data.result[totalLength - 1].address_match
          ),
          addressMatchFirstPercentage: parseFloat(
            data.result[totalLength - 1].address_match_percentage
          ),
          businessNameMatchFirst: parseInt(
            data.result[totalLength - 1].business_name_match
          ),
          businessNameMatchFirstPercentage: parseFloat(
            data.result[totalLength - 1].business_name_match_percentage
          ),
          phoneNoMatchFirst: parseInt(
            data.result[totalLength - 1].phone_no_match
          ),
          phoneNoMatchFirstPercentage: parseFloat(
            data.result[totalLength - 1].phoneno_match_percentage
          ),
          viewDetailsId: data.result[totalLength - 1].id,
        });
        data.result.forEach((object) => {
          latestUpdate = object.created_on;
          total_location = object.total_location;
          // address_match =
          //   parseInt(object.address_match) + parseInt(address_match);
          // address_match_percentage =
          //   parseInt(object.address_match_percentage) +
          //   parseInt(address_match_percentage);
          // phone_no_match =
          //   parseInt(object.phone_no_match) + parseInt(phone_no_match);
          // phoneno_match_percentage =
          //   parseInt(object.phoneno_match_percentage) +
          //   parseInt(phoneno_match_percentage);
          // business_name_match =
          //   parseInt(object.business_name_match) +
          //   parseInt(business_name_match);
          // business_name_match_percentage =
          //   parseFloat(object.business_name_match_percentage) +
          //   parseFloat(business_name_match_percentage);
          graphDataListingonSearch.push(object.phone_no_match);
          getGraphBusnessNameData.push(object.business_name_match);
          getGraphAdressData.push(object.address_match);
          getMasterdataXaxis.push(
            moment(object.created_on).format("DD-MMM-YYYY")
          );
        });
        this.setState({
          graphDataListingonSearch: graphDataListingonSearch,
          getGraphBusnessNameData: getGraphBusnessNameData,
          getGraphAdressData: getGraphAdressData,
          getMasterdataXaxis: getMasterdataXaxis,
          flag: true,
          graphMode: "visbility",
        });
      } else {
        this.setState({
          flag: false,
        });
        // alert("Something went wrong in getting Master Audit Data");
      }
    });
  }
  visibilityAndAccuracyGraph() {
    var lastyear = new Date(new Date().getFullYear() - 1, 0, 1);
    var startDate = new Date(lastyear.getFullYear(), 0, 1).getTime();

    var req = {
      functionName: "getVisibilityAndAccuracyData",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      start_date: moment(this.state.insightsStartDate).format("YYYY-MM-DD"),
      end_date: moment(new Date()).format("YYYY-MM-DD"),
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        let totalLength = data.result.length;
        this.setState({
          addressMatchFirst: parseInt(
            data.result[totalLength - 1].vis_address_yes
          ),
          totalLocation: parseInt(data.result[totalLength - 1].total_location),
          addressMatchFirstPercentage:
            (parseInt(data.result[totalLength - 1].vis_address_yes) /
              parseInt(data.result[totalLength - 1].total_location)) *
            100,
          businessNameMatchFirst: parseInt(
            data.result[totalLength - 1].vis_name_yes
          ),
          businessNameMatchFirstPercentage:
            (parseInt(data.result[totalLength - 1].vis_name_yes) /
              parseInt(data.result[totalLength - 1].total_location)) *
            100,
          phoneNoMatchFirst: parseInt(
            data.result[totalLength - 1].vis_phone_yes
          ),
          phoneNoMatchFirstPercentage:
            (parseInt(data.result[totalLength - 1].vis_phone_yes) /
              parseInt(data.result[totalLength - 1].total_location)) *
            100,
          viewDetailsId: data.result[totalLength - 1].id,
        });
        data.result.forEach((object) => {
          latestUpdate = object.created_on;
          total_location = object.total_location;
          // address_match =
          //   parseInt(object.address_match) + parseInt(address_match);
          // address_match_percentage =
          //   parseInt(object.address_match_percentage) +
          //   parseInt(address_match_percentage);
          // phone_no_match =
          //   parseInt(object.phone_no_match) + parseInt(phone_no_match);
          // phoneno_match_percentage =
          //   parseInt(object.phoneno_match_percentage) +
          //   parseInt(phoneno_match_percentage);
          // business_name_match =
          //   parseInt(object.business_name_match) +
          //   parseInt(business_name_match);
          // business_name_match_percentage =
          //   parseFloat(object.business_name_match_percentage) +
          //   parseFloat(business_name_match_percentage);
          this.state.graphDataListingonSearch.push(
            !Object.is(object.vis_phone_yes, null) ? object.vis_phone_yes : 0
          );
          this.state.getGraphBusnessNameData.push(
            !Object.is(object.vis_name_yes, null) ? object.vis_name_yes : 0
          );
          this.state.getGraphAdressData.push(
            !Object.is(object.vis_address_yes, null)
              ? object.vis_address_yes
              : 0
          );
          this.state.getMasterdataXaxis.push(
            moment(object.created_on).format("DD-MMM-YYYY")
          );
        });
        this.setState({
          flag: true,
        });
        //  alert("Something went wrong in getting Master Audit Data");
      } else {
        this.setState({
          flag: false,
        });
      }
    });
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          <div className="container-fluid">
            {/* <div className="col-md-12 visibiltyGpagh">
            
            <img src={Accessibiltyimage} alt="Dashboard" className="imgHead"/>
            <h5 className="mb-0 ml-1">Visibility</h5>
        </div> */}
            <div className="col-md-12 mt-5">
              {/* <div className="visAccuracy_head">Latest Update({moment(latestUpdate).format('YYYY-MM-DD')})</div> */}
              {/* {latestUpdate != 0 ? (
                <div className="subhead">
                  Latest <span className="subheadSm ml-3"> Updated on </span>
                  <span className="subheadBold">
                    ({moment(latestUpdate).format("DD-MM-YYYY")})
                  </span>
                </div>
              ) : (
                ""
              )} */}
              {/* <div className="visAccuracy_textDiv">
                <div className="visAccuracy_text">
                  Total Locations <Icon.ArrowRight />{" "}
                  <span className="visAccuracy_textBold">{total_location}</span>
                </div>
                <div className="visAccuracy_text">
                  Address Match <Icon.ArrowRight />
                  <span className="visAccuracy_textBold">
                    <>
                      {address_match}&nbsp;
                      {Math.round(address_match_percentage) != 0 ? (
                        <>
                          {"("}
                          {parseInt(address_match_percentage).toFixed(2)}%{")"}
                        </>
                      ) : (
                        "(0%)"
                      )}
                    </>
                  </span>
                </div>
                <div className="visAccuracy_text">
                  Phone Number Match <Icon.ArrowRight />
                  <span className="visAccuracy_textBold">
                    {phone_no_match}&nbsp;
                    {Math.round(phoneno_match_percentage) != 0 ? (
                      <>
                        {"("}
                        {parseInt(phoneno_match_percentage).toFixed(2)}%{")"}
                      </>
                    ) : (
                      "(0%)"
                    )}
                  </span>
                </div>
                <div className="visAccuracy_text">
                  Business Name Match <Icon.ArrowRight />
                  <span className="visAccuracy_textBold">
                    {business_name_match}&nbsp;
                    {Math.round(business_name_match_percentage) != 0 ? (
                      <>
                        {"("}
                        {parseInt(business_name_match_percentage).toFixed(2)}%
                        {")"}
                      </>
                    ) : (
                      "(0%)"
                    )}
                  </span>
                </div>
              </div> */}
              <div className="row mb-3">
                <div className="col-md-4">
                  <div className="leads">
                    <div className="laedsname">
                      Visibility{" "}
                      {latestUpdate != 0 ? (
                        <div className="leadssub mb-3">
                          <span className="subheadSm">(Last updated on </span>
                          <span className="subheadBold">
                            {moment(latestUpdate).format(
                              "DD MMM, YYYY hh:mm A"
                            )}
                            )
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="leadssub mb-3">Analytics</div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="leadsDigit">
                          {this.state.googleLocationsCount}
                        </div>
                        <div className="leadscount">
                          <Icon.ArrowUp width={15} height={15} />
                          Google Locations
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="leadsDigit">
                          {this.state.excelLocationsCount}
                        </div>
                        <div className="leadscount">
                          <Icon.ArrowUp width={15} height={15} />
                          Excel Listings
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="cardVisibilityREports">
                    <div className="cards">
                      <div className="blue Cardtop">
                        <div>
                          {/* <Icon.MessageCircle /> */}
                          <img
                            src={Address}
                            alt="Address"
                            className="img-fluid"
                          />
                        </div>
                        <div>
                          <div className="totalNumber">
                            {this.state.addressMatchFirst}/
                            {this.state.totalLocation}&nbsp;
                            {Math.round(
                              this.state.addressMatchFirstPercentage
                            ) != 0 ? (
                              <>
                                {"("}
                                {Math.round(
                                  this.state.addressMatchFirstPercentage
                                )}
                                %{")"}
                              </>
                            ) : (
                              "(0%)"
                            )}
                          </div>
                          <div className="comments">Location Visiblility</div>
                        </div>
                      </div>
                      <div className="blue CardBottom">
                        <a
                          href={`/visibilityDetails/visibility/address/${this.state.viewDetailsId}`}
                        >
                          <div>View Details</div>
                        </a>
                        <a
                          href={`/visibilityDetails/visibility/address/${this.state.viewDetailsId}`}
                        >
                          <div>
                            <Icon.ArrowRight width={16} height={16} />
                          </div>
                        </a>
                      </div>
                    </div>
                    {/* <div className="cards">
                      <div className="red Cardtop">
                        <div>
                          <Icon.Activity />
                        </div>
                        <div>
                          <div className="totalNumber">26</div>
                          <div className="comments">Comments</div>
                        </div>
                      </div>
                      <div className="red CardBottom">
                        <div>View Details</div>
                        <div>
                          <Icon.ArrowRight width={16} height={16} />
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <hr className="mt-1" />
              <div className="Date_fromToDiv mt-2">
                <span className="Date_fromToText">From</span>
                <div className="Date_fromTo">
                  <ReactDatePicker
                    selected={new Date(this.state.insightsStartDate)}
                    // selected={new Date(new Date().getFullYear() - 1, 0, 1)}
                    dateFormat="dd-MM-yyyy"
                    dateFormatCalendar="MMMM"
                    id="fromdate"
                    onChange={(obj) => {
                      this.searchFunction(obj, "fromDate");
                      let startDate = new Date(obj);
                      this.setState({
                        insightsStartDate: startDate.toISOString(),
                        insightsDataKey: startDate.toISOString(),
                      });
                    }}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
                <span className="Date_fromToText">To</span>
                <div className="Date_fromTo">
                  <ReactDatePicker
                    selected={new Date(this.state.insightsEndDate)}
                    dateFormat="dd-MM-yyyy"
                    dateFormatCalendar="MMMM"
                    onChange={(obj) => {
                      this.searchFunction(obj, "toDate");
                      let endDate = new Date(obj);
                      this.setState({
                        insightsEndDate: endDate.toISOString(),
                        insightsDataKey: endDate.toISOString(),
                      });
                    }}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
              </div>
            </div>
            {this.state.flag === true ? (
              <div className="mt-3">
                <VisibilityAccuracyGraph
                  key={this.state.graphDataListingonSearch}
                  getBusnessData={[]}
                  // getBusnessData={this.state.getGraphBusnessNameData}
                  getGraphAdressData={this.state.getGraphAdressData}
                  xaxis={this.state.getMasterdataXaxis}
                  listingonSearch={[]}
                  // listingonSearch={this.state.graphDataListingonSearch}
                  getGBPCountData={[]}
                  getCategoryCountData={[]}
                />
              </div>
            ) : (
              <div className="mt-3">No Data Found</div>
            )}
          </div>
          {/* <Footer /> */}
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}
// export default Visibility;
