import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import { backendApi, apiCallingPost } from "../apiService";
import $ from "jquery";
import * as Icon from "react-feather";
import Reviewimage from "../images/left_icons/review.png";
import moment from "moment";
import ReactStars from "react-rating-stars-component";
import DatePicker from "react-datepicker";
import { FaReply } from "react-icons/fa";
import ScaleLoader from "react-spinners/ScaleLoader";
import Delete from "../images/delete.png";
import Wordcloud from "wordcloud";
import "react-datepicker/dist/react-datepicker.css";
import WordCloud from "./wordCloud";

var removeWords = require("remove-words");

const CountWordsArray = require("count-words-array");

let location_id = window.location.pathname.split("/")[2];
let titleName = window.location.pathname.split("/")[3];
class Review extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      file: null,
      buffer: null,
      convertText: "",
      sourceText: "",
      status: "",
      src: "",
      imageLanguage: "",
      imageLanguageLabel: "",
      translateLanguage: "",
      languageListImage: [],
      languageListTTS: [],
      uploaded: false,
      dataList: [],
      excelData: [],
      googleData: [],
      keyword: [],
      keywordLength: 0,
      reviewDatas: [],
      getCompanyData: [],
      companyName: "",
      displayName: "",
      createTime: "",
      comment: "",
      starRating: "",
      reviewList: [],
      rateChange: "",
      messageChange: "",
      reviewId: "",
      replyComments: "",
      radioComment: "",
      profilePhotoUrl: "",
      sentimentalList: [],
      revId: "",
      startDate: "",
      endDate: "",
      newRating: "",
      resultProductData: [],
      resultReviewarray: [],   
      sentimentalDataList:[],
      allLocationdata:[]
    };

    this.setSelected = this.setSelected.bind(this);
    this.handleCompanyDetails = this.handleCompanyDetails.bind(this);
  }
  getAllLocationdata(){

    const reviewAPIData = {
      functionName: "GetLocationsByAccountId",
      accountId: window.sessionStorage.getItem("account_id"),
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    apiCallingPost(reviewAPIData).then((data) => {
      if (data.success === "1") {
        this.setState({
          allLocationdata: data.data.locations
          ,
        });
      }
    });
    
  }
  componentDidMount() {
    this.getAllLocationdata()
    this.getreviewlist();
    $("#worldcloud").hide();
    this.getCompanyData();
    $("#templateView" + this.state.revId).hide();
  }

  getCompanyData() {
    $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "GetLocations",
      location_id: location_id,
      account_id: window.sessionStorage.getItem("account_id"),
      companyName: $("#companyName").val().replace(/%20/g, " "),
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    apiCallingPost(reviewAPIData).then((data) => {
      console.log(data.data.googleLocations);
      if (data.data.googleLocations.length > 0) {
        this.setState({ getCompanyData: data.data.googleLocations }, () => {
          this.getReviewsData();
        });
      }
    });
  }
  getReviewsData() {
    $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "GetReviewsFromLocation",
      location_id: location_id,
      account_id: window.sessionStorage.getItem("account_id"),
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    apiCallingPost(reviewAPIData).then((data) => {
      $(".loder_div").attr("hidden", true);
      console.log(data.data.reviews);
      if (data.data.reviews.length > 0) {
        this.setState(
          {
            reviewDatas: data.data.reviews,
            resultReviewarray: data.data.reviews,
          },
          () => {
            this.recallUpdate();
          }
        );
      }
    });
  }

  getfilterData() {
    $(".loder_div").attr("hidden", false);
    setTimeout(() => {
      $(".loder_div").attr("hidden", true);
    }, 500);
    var resultProductData = this.state.resultReviewarray.filter((a) => {
      var createTimeva = new Date(
        a.createTime.replace("T", " ").replace("Z", "").split(" ")[0]
      );
      let flag = "";
      if (a.starRating == "ONE") {
        flag = "1";
      } else if (a.starRating == "TWO") {
        flag = "2";
      } else if (a.starRating == "THREE") {
        flag = "3";
      } else if (a.starRating == "FOUR") {
        flag = "4";
      } else if (a.starRating == "FIVE") {
        flag = "5";
      } else {
        flag = "0";
      }

      if (this.state.newRating == "" && this.state.startDate != "") {
        return (
          new Date(createTimeva) >= this.state.startDate &&
          new Date(a.createTime) <= this.state.endDate
        );
      } else if (this.state.newRating !== "" && this.state.startDate === "") {
        return flag == this.state.newRating;
      } else {
        return (
          flag == this.state.newRating &&
          new Date(createTimeva) >= this.state.startDate &&
          new Date(a.createTime) <= this.state.endDate
        );
      }
    });
    var text = $("#sentiment").find("option:selected").text();
    var resultProductDataSentimentalList =
      this.state.sentimentalDataList.filter((a) => {
        return text == a.status;
      });
    // var resultProductData = this.state.resultReviewarray.filter(a => {
    //   let flag =""
    //  if(a.starRating =="ONE"){
    //    flag = "1"
    //  }else if (a.starRating =="TWO") {
    //    flag = "2"
    //  }
    //  else if (a.starRating =="THREE") {
    //    flag = "3"
    //  }
    //  else if (a.starRating =="FOUR") {
    //    flag = "4"
    //  }
    //  else if (a.starRating =="FIVE") {
    //    flag = "5"
    //  }
    //    return (flag == this.state.newRating);
    //  });
    this.setState({
      sentimentalList: resultProductDataSentimentalList,
      reviewDatas: 
      resultProductDataSentimentalList.length > 0
        ? this.state.resultReviewarray
        : resultProductData,
    });
  }
  recallUpdate() {
    const reviewAPIData = {
      functionName: "processSentiMental",
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    apiCallingPost(reviewAPIData).then((data) => {
      if (data.success === "1") {
        this.setState({
          sentimentalList: data.data,
          sentimentalDataList: data.data,
        });
      }
    });
  }

  setSelected(val) {
    this.setState({
      keyword: val,
    });
  }
  handleCompanyDetails(e) {
    this.setState({ companyName: e.target.value });
  }
  // newReviewPopup(
  //   displayName,
  //   createTime,
  //   comment,
  //   reviewId,
  //   reviewercomment,
  //   profilePhotoUrl,
  //   starRating
  // ) {
  //   $("body").addClass("newreview_visible");

  //   this.setState({
  //     displayName: displayName,
  //     createTime: createTime,
  //     comment: comment,
  //     reviewId: reviewId,
  //     starRating: starRating,
  //     profilePhotoUrl: profilePhotoUrl,
  //   });
  // }
  // CloseReviewPopup() {
  //   $("body").removeClass("newreview_visible");
  // }

  templateShow(revId, starRating) {
    let tempStarRating =
      starRating === "ONE"
        ? 1
        : starRating === "TWO"
        ? 2
        : starRating === "THREE"
        ? 3
        : starRating === "FOUR"
        ? 4
        : starRating === "FIVE"
        ? 5
        : 0;
    this.setState({ revId: revId, starRating: tempStarRating }, () => {
      $("#templateView" + this.state.revId).show();
      $("#templateListAll" + this.state.revId).show();
      $("#newTemplate").hide();
    });
  }
  newTemplate() {
    $("#newTemplate").show();
    $("#templateListAll" + this.state.revId).hide();
  }
  editReview(message, templateId, starRate) {
    $('#ratedropdown [value="' + starRate + '"]').attr("selected", "true");

    $("#newTemplate").show();
    $("#templateListAll" + this.state.revId).hide();
    this.setState({
      messageChange: message,
      templateId: templateId,
      rateChange: starRate,
    });
  }
  templateViewClose() {
    $("#templateView" + this.state.revId).hide();
  }

  worldCloudClose() {
    $("#worldcloud").hide();
  }
  worldCloudOpen() {
    $("#worldcloud").show();

    $(".loder_div").attr("hidden", false);

    $(".loder_div").attr("hidden", true);
    let reviews = this.state.reviewDatas;
    if (reviews.length > 0) {
      let array = [];
      for (let i = 0; i < reviews.length; i++) {
        if (reviews[i].comment) {
          array.push(reviews[i].comment);
        }
      }
      let dataSet = removeWords(array.join());
      let finalData = "";
      dataSet.map((i) => {
        finalData = finalData + "," + i;
      });
      let countWordsArray = CountWordsArray(finalData);
      countWordsArray = countWordsArray.sort(function (a, b) {
        return b.count - a.count;
      });
      console.log("countWordsArray", countWordsArray);
      let final = countWordsArray;
      if (countWordsArray.length > 50) {
        final = countWordsArray.slice(1, 50);
      } else {
        final = countWordsArray;
      }
      console.log("final.....", final.map(Object.values));
      let finalDa = final.map(Object.values);
      let bbb = [
        ["foo12", 12],
        ["bar222", 2],
        ["bar555", 5],
        ["barwwww99", 9],
        ["bar44", 4],
      ];
      Wordcloud(document.getElementById("my_canvas"), {
        list: finalDa,
        gridSize: Math.round((16 * $("#my_canvas").width()) / 1024),
        weightFactor: 16.1,
        fontFamily: "Trebuchet MS sans-serif",
      });
    }
  }
  getreviewlist() {
    var apidata = {
      functionName: "reviewTemplateListing",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apidata).then((data) => {
      this.setState({
        reviewList: data.result,
      });
    });
  }

  backReview() {
    // $("#templateView" + this.state.revId).hide();
    $("#templateView" + this.state.revId).show();
    $("#templateListAll" + this.state.revId).show();
    $("#newTemplate").hide();
  }
  saveReview() {
    console.log("this.state.templateId", this.state.templateId);
    var apidata = {
      functionName: "reviewTemplateSave",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      template: this.state.messageChange,
      starRate: this.state.rateChange,
      templateId:
        this.state.templateId === undefined ? "" : this.state.templateId,
    };

    backendApi(apidata).then((data) => {
      if (data.success === "1") {
        if (this.state.templateId == undefined) {
          alert("Templated added Successfully");
          $("#templateView").hide();
          this.getreviewlist();
        } else {
          alert("Templated Updated Successfully");
          $("#templateView").hide();
          this.getreviewlist();
        }
      } else {
        return false;
      }
    });
  }
  deleteReview(Id) {
    if (Id != "") {
      var apidata = {
        templateId: Id,
        functionName: "reviewTemplateDelete",
      };
      backendApi(apidata).then((data) => {
        if (data.success === "1") {
          alert("Templated deleted Successfully");
          this.getreviewlist();
        } else {
          return false;
        }
      });
    }
  }
  replaySave(reviewId) {
    const reviewAPIData = {
      functionName: "ReplyToReview",
      comment:
        this.state.replyComments == ""
          ? this.state.radioComment
          : this.state.replyComments,
      reviewID: reviewId,
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    apiCallingPost(reviewAPIData).then((data) => {
      if (data.success === "1") {
        alert("Successfully Sent");
        $("body").removeClass("newreview_visible");
        $("#deleteid"+reviewId).show();

        this.getCompanyData();
      } else {
        alert("Your Replay was not Posted");
      }
    });
  }

  replayDelete(reviewId) {
    const reviewAPIData = {
      functionName: "DeleteReply",
      reviewID: reviewId,
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    apiCallingPost(reviewAPIData).then((data) => {
      if (data.success === "1") {
        alert("Successfully Deleted the post");
        $("body").removeClass("newreview_visible");
        $("#deleteid"+reviewId).hide();
        this.getReviewsData();
        $("#replymessage" + reviewId).val("");
      } else {
        alert("Error on Deleting the post");
      }
    });
  }
  // $("#replymessage").val(reviewercomment);
  selectTemplate(template) {
    this.setState({
      radioComment: template,
    });
    $("#replymessage" + this.state.revId).val(template);
    $("#templateView" + this.state.revId).hide();
  }

  ratingChanged(newRating) {
    this.setState({
      newRating: newRating,
    });
  }
  fetchdata() {
    // this.state.reviewDatas.filter((a) => {
    //   var createTimeva = new Date(
    //     a.createTime.replace("T", " ").replace("Z", "").split(" ")[0]
    //   );
    //   return this.state.endDate !== "" && this.state.endDate !== null
    //     ? new Date(createTimeva) >= this.state.startDate &&
    //         new Date(a.createTime) <= this.state.endDate
    //     : new Date(createTimeva) >= this.state.startDate;
    // });
    // var resultProductData = this.state.reviewDatas.filter(a => {
    //   let flag =""
    //  if(a.starRating =="ONE"){
    //    flag = "1"
    //  }else if (a.starRating =="TWO") {
    //    flag = "2"
    //  }
    //  else if (a.starRating =="THREE") {
    //    flag = "3"
    //  }
    //  else if (a.starRating =="FOUR") {
    //    flag = "4"
    //  }
    //  else if (a.starRating =="FIVE") {
    //    flag = "5"
    //  }
    //    return (flag == this.state.newRating);
    //  });
    // var text = $("#sentiment").find("option:selected").text();
    // var resultProductData = this.state.sentimentalList.filter((a) => {
    //   return text == a.status;
    // });
    // console.log("resultProductData", resultProductData);
  }
  handleChangeStart(value) {
    this.setState({
      startDate: value,
    });
  }

  handleChangeEnd(values) {
    this.setState({
      endDate: values,
    });
  }
  clearSearch(){


    $("#startDate").val("");
    $("#endDate").val("");
    $("#sentiment").val("0");

    
    this.getCompanyData();
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          <Header heading="Google Reviews" headerImage={Reviewimage}/>
          <div className="user_listdiv">
          <div className="col-md-4 col-lg-2 col-sm-3">
                  <select className="form-control" id="Location"
                  onChange={(e)=>this.handleLocation(e)}
                 >
                    <option value="0" >Select Location</option>
                    {this.state.allLocationdata.map((item)=>(

                
                    <option value={item.name}  >{item.title}</option>
                    ))}

                  </select>
                </div>
            <div className="user_main_review mb-2">
              <div className="row d-flex align-items-center mx-0">
                <div className="col-md-4">
                  <input
                    type="text"
                    id="companyName"
                    className="form-control"
                    onChange={this.handleCompanyDetails}
                    defaultValue={titleName.replace(/%20/g, " ")}
                  />
                </div>
                {/* <div className="col-md-3">
                  <button
                    onClick={() => this.getCompanyData()}
                    className="btn btn-primary featch_btn"
                  >
                    Fetch
                  </button>
                </div> */}

                {this.state.reviewDatas.length > 0 && (
                  <div className="col-md-3">
                    <button
                      onClick={() => this.worldCloudOpen()}
                      className="btn btn-info featch_btn"
                    >
                      Word Cloud
                    </button>
                  </div>
                )}
              </div>
            </div>

            <>
              <div className="row d-flex align-items-center mx-0 filterSectionDate">
                <div className="col-md-4 col-lg-2 col-sm-3">
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={(date) => this.handleChangeStart(date)}
                    name="startDate"
                    id="startDate"
                    showMonthDropdown
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    placeholderText="From Date"
                  />
                </div>
                <div className="col-md-4 col-lg-2 col-sm-3">
                  <DatePicker
                    selected={this.state.endDate}
                    onChange={(date) => this.handleChangeEnd(date)}
                    name="startDate"
                    id="endDate"
                    showMonthDropdown
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    placeholderText="To Date"
                  />
                </div>
                <div className="col-md-4 col-lg-2 col-sm-3">
                  <ReactStars
                    count={5}
                    id="starRatevalue"
                    onChange={(newRating) => this.ratingChanged(newRating)}
                    size={24}
                    activeColor="#ffd700"
                  />
                </div>
                <div className="col-md-4 col-lg-2 col-sm-3">
                  <select className="form-control" id="sentiment">
                    <option value="0">Select</option>
                    <option value="POSITIVE">POSITIVE</option>
                    <option value="NEUTRAL">NEUTRAL</option>
                    <option value="NEGATIVE">NEGATIVE</option>
                  </select>
                </div>
                <div className="col-md-4 col-lg-2 col-sm-3">
                  <button
                    className="btn btn-primary fetch_btn"
                    onClick={() => this.getfilterData()}
                  >
                    Search
                  </button>
                </div>
                <div className="col-md-4 col-lg-2 col-sm-3">
                  <button
                    className="btn btn-clear-search fetch_btn"
                    onClick={() => this.clearSearch()}
                  >
                    Clear Search
                  </button>
                </div>
              </div>
              {this.state.getCompanyData.length !== 0 ? (
                <div className="company_review">
                  <div className="col-md-12">
                    <label className="col-form-label">
                      <strong>
                        {this.state.getCompanyData[0].location.title}
                      </strong>
                      <hr></hr>
                    </label>
                    <label className="col-form-label">
                      {this.state.getCompanyData[0].location.storefrontAddress.addressLines.join(
                        " "
                      )}{" "}
                      {
                        this.state.getCompanyData[0].location.storefrontAddress
                          .locality
                      }{" "}
                      {
                        this.state.getCompanyData[0].location.storefrontAddress
                          .administrativeArea
                      }{" "}
                      {
                        this.state.getCompanyData[0].location.storefrontAddress
                          .postalCode
                      }
                    </label>
                  </div>
                </div>
              ) : (
                // <div className="company_review">
                //   <p>
                //     {" "}
                //     <b className="mr-2">Title:</b>
                //     {this.state.getCompanyData[0].location.title}
                //   </p>
                //   <p>
                //     <b className="mr-2">Address:</b>
                //     {this.state.getCompanyData[0].location.storefrontAddress.addressLines.join(
                //       " "
                //     )}{" "}
                //     {
                //       this.state.getCompanyData[0].location.storefrontAddress
                //         .locality
                //     }{" "}
                //     {
                //       this.state.getCompanyData[0].location.storefrontAddress
                //         .administrativeArea
                //     }{" "}
                //     {
                //       this.state.getCompanyData[0].location.storefrontAddress
                //         .postalCode
                //     }
                //   </p>
                // </div>
                ""
              )}
  
            </>
                       
          </div>
          <div className="container mt-4">
          {console.log("resultProductData", this.state.reviewDatas)}
          {this.state.reviewDatas.length>0?
          this.state.reviewDatas.map((data, i) => (
            <div className="table-responsive review_tableList">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Ref Code</th>

                    <th scope="col">Date</th>
                    <th scope="col">Address</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="d-flex align-items-center">
                      <div className="user_review_name">
                        <span>M</span>
                      </div>
                      {data.reviewer.displayName}
                    </td>

                    <td>{moment(data.createTime).format("DD/MM/YYYY")}</td>
                    <td>      <label className="col-form-label">
                      <strong>
                        {this.state.getCompanyData[0].location.title}
                      </strong>
                    &nbsp;
                    </label>
                    <label className="col-form-label">
                      {this.state.getCompanyData[0].location.storefrontAddress.addressLines.join(
                        " "
                      )}{" "}
                      {
                        this.state.getCompanyData[0].location.storefrontAddress
                          .locality
                      }{" "}
                      {
                        this.state.getCompanyData[0].location.storefrontAddress
                          .administrativeArea
                      }{" "}
                      {
                        this.state.getCompanyData[0].location.storefrontAddress
                          .postalCode
                      }
                    </label></td>
                  </tr>
                  <tr key={data.totalReviewCount}>
                    <td colspan="3" className="text-center m-auto">
                      <div className="review_box text-left">
                        <span className="review_box_head">
                          Review:
                          <ReactStars
                            key={data.starRating}
                            count={5}
                            size={24}
                            edit={false}
                            activeColor="#ffd700"
                            value={
                              data.starRating === "ONE"
                                ? 1
                                : data.starRating === "TWO"
                                ? 2
                                : data.starRating === "THREE"
                                ? 3
                                : data.starRating === "FOUR"
                                ? 4
                                : data.starRating === "FIVE"
                                ? 5
                                : 0
                            }
                          />
                        </span>
                        <div className="review_content">{data.comment}</div>
                      </div>
                      <div className="review_box text-left">
                        <div className="review_box_head">
                          Reply:
                          {this.state.sentimentalList
                            .filter((obj) => obj.review_id == data.reviewId)
                            .map((item) => (
                              <span key={item.status} className="review-right">
                                <span
                                  className={
                                    item.status === "POSITIVE"
                                      ? "review_mixedvalue review_green"
                                      : item.status == "NEUTRAL"
                                      ? "review_mixedvalue review_yellow"
                                      : item.status == "NEGATIVE"
                                      ? "review_mixedvalue review_red"
                                      : ""
                                  }
                                >
                                  {item.status}
                                </span>

                                {(data.starRating === "FIVE" ||
                                  data.starRating === "TWO" ||
                                  data.starRating === "ONE") &&
                                data.comment != "" &&
                                item.status === "" ? (
                                  <ScaleLoader
                                    color="#36d7b7"
                                    height={20}
                                    width={2}
                                  />
                                ) : (
                                  ""
                                )}
                              </span>
                            ))}
                        </div>
                        <div>
                          <div className="review_reply">
                            <textarea
                              className="form-control"
                              placeholder="Reply publicly"
                              defaultValue={
                                this.state.radioComment ||
                                data.reviewReply === undefined
                                  ? ""
                                  : data.reviewReply.comment
                              }
                              rows="3"
                              id={"replymessage" + data.reviewId}
                              onChange={(e) =>
                                this.setState({
                                  replyComments: e.target.value,
                                })
                              }
                            ></textarea>
                          </div>
                          <div className="review_btns">
                            <button
                              className="review_buttion"
                              onClick={() =>
                                this.templateShow(
                                  data.reviewId,
                                  data.starRating
                                )
                              }
                            >
                              Template
                            </button>
                            {data.reviewReply === undefined ? (
                              ""
                            ) : data.reviewReply.comment ? (
                              <button
                                id={"deleteid" + data.reviewId}
                                className="review_buttion btn-delete"
                                onClick={() => this.replayDelete(data.reviewId)}
                              >
                                Delete
                              </button>
                            ) : (
                              ""
                            )}
                            <button
                              className="review_buttion btn-post"
                              onClick={() => this.replaySave(data.reviewId)}
                            >
                              Post
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )):<h3>No data Found</h3>}
        </div>
        </div>

        {/* <div className="reply_popup">
          <div className="reply_popup_inner">
            <div className="reply_popup_head d-flex align-items-center">
              <span className="popup_reply_head">
                <Icon.ArrowLeft />
                Reply to review
              </span>
              <span
                className="ml-auto close_popup_review"
                onClick={() => {
                  this.CloseReviewPopup();
                }}
              >
                <Icon.X />
              </span>
            </div>
            <div className="reply_popup_body">
              <div className="reply__bodyouter">
                <div className="reply_body_main">
                  <div className="reply_popup_left">
                    <div className="user_review_name">
                      <span>
                        <img
                          src={this.state.profilePhotoUrl}
                          alt=""
                          srcset=""
                        />
                      </span>
                    </div>
                  </div>
                  <div className="reply_popup_right">
                    <div className="user_right_review">
                      <div className="review_profile">
                        <a href="#">
                          {this.state.displayName}
                        </a>
                      </div>
                      <div className="review_ratedate">
                        <ReactStars
                          key={this.state.starRating}
                          count={this.state.starRating}
                          value={this.state.starRating}
                          size={20}
                          activeColor="#ffd700"
                        />

                        <span className="review_datespan">
                          {this.state.createTime}
                        </span>
                      </div>
                      <p>{this.state.comment}</p>
                    </div>
                  </div>
                </div>
                <div className="reply_body_main">
                  <div className="reply_popup_left">
                    <div className="user_review_name">
                      <span>X</span>
                    </div>
                  </div>
                  <div className="reply_popup_right">
                    <div className="user_right_review">
                      <div className="review_profile">
                        <div className="review_owner_name">
                          <span className="d-block user_blcok_review">
                            Xilligence
                          </span>
                          <span className="d-block">(owner)</span>
                        </div>
                        <div className="review_owner_drop">
                          <ul className="nav">
                            <li>
                              {" "}
                              <label>Template</label>
                            </li>
                            <li className="d-flex align-items-center template_divli">
                              <input
                                type="Button"
                                value="Template"
                                placeholder="Choose Template"
                                onClick={() => this.templateShow()}
                              />
                            </li>
                            <li>
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => this.replayDelete()}
                              >
                                Delete
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="reply_input">
                        <span>0 / 4000</span>
                      </div>
                      <p className="footer_text_review">
                        This customer will be notified about your reply, and it
                        will be publicity visible on your Bussiness Profile
                      </p>
                    </div>
                  </div>
                </div>
                <div className="review_footer">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => this.replaySave()}
                  >
                    Post
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div id="worldcloud" className="template_popup">
          <div className="review_owner_dropdown" id="templateList">
            <div className="review_owner_top">
              <div class="reply_popup_head d-flex align-items-center popup_review_common">
                <span class="popup_reply_head">
                  <Icon.FileText
                    onClick={() => {
                      $("#templateView" + this.state.revId).show();
                      $("#templateListAll" + this.state.revId).show();
                      $("#newTemplate").hide();
                    }}
                  />
                  Word Cloud
                </span>

                <span class="ml-auto close_popup_review">
                  <Icon.X onClick={() => this.worldCloudClose()} />
                </span>
              </div>
              <WordCloud />
            </div>
          </div>
        </div>
        <div id={"templateView" + this.state.revId} className="template_popup">
          <div className="review_owner_dropdown" id="templateList">
            <div className="review_owner_top">
              {/* <div className="template_head">
                <button
                  className="btn bt-transparent p-0 border-0"
                  onClick={() => this.templateViewClose()}
                >
                  <Icon.X />
                </button>
              </div> */}
              <div class="reply_popup_head d-flex align-items-center popup_review_common">
                <span class="popup_reply_head">
                  <Icon.FileText
                    onClick={() => {
                      $("#templateView" + this.state.revId).show();
                      $("#templateListAll" + this.state.revId).show();
                      $("#newTemplate").hide();
                    }}
                  />
                  Template
                </span>
                <span class="ml-auto close_popup_review">
                  <Icon.X onClick={() => this.templateViewClose()} />
                </span>
              </div>

              <ul
                className="nav flex-column"
                id={"templateListAll" + this.state.revId}
              >
                <div className="template_head review_temphead">
                  <button
                    className="btn bg-transparent p-0 border-0"
                    onClick={() => this.newTemplate()}
                  >
                    Create Template
                  </button>
                </div>

                {this.state.reviewList
                  .filter((obj) => obj.star_rate === this.state.starRating)
                  .map((item) => (
                    <li>
                      <p>
                        {" "}
                        {item.template}
                        <div
                          className="review_edit_popup"
                          onClick={() =>
                            this.editReview(
                              item.template,
                              item.id,
                              item.star_rate
                            )
                          }
                        >
                          Do you want to edit?
                        </div>
                      </p>

                      <span>
                        {/* <Icon.Edit
                        className="text-success"
                        onClick={() =>
                          this.editReview(
                            item.template,
                            item.id,
                            item.star_rate
                          )
                        }
                      /> */}
                        {/* <Icon.Trash
                        className="text-danger"
                        onClick={() => this.deleteReview(item.id)}
                      /> */}
                        <span
                          className="text-danger"
                          onClick={() => this.deleteReview(item.id)}
                        >
                          Delete
                        </span>
                        <input
                          type="radio"
                          name="templateradio"
                          id="templateradio"
                          onClick={() => this.selectTemplate(item.template)}
                        />
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="review_Popupdiv" id="newTemplate">
              <div className="review_popup_div text-right"></div>
              <form>
                <div className="popup_formgroup">
                  <label>Rate</label>
                  <select
                    className="form-control"
                    id="ratedropdown"
                    onChange={(e) =>
                      this.setState({
                        rateChange: e.target.value,
                      })
                    }
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
                <div className="popup_formgroup">
                  <label>Message</label>
                  <textarea
                    className="form-control"
                    value={this.state.messageChange}
                    onChange={(e) =>
                      this.setState({
                        messageChange: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="popup_formgroup text-right mt-1">
                  <div
                    className="btn btn-secondary mr-2"
                    type="button"
                    onClick={() => this.backReview()}
                  >
                    Back
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.saveReview()}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>


        <div className="loder_div" hidden />
        <Footer/>
      </div>
    );
  }
}

export default Review;
