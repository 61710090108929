import React, { useEffect, Suspense, lazy, useState, useCallback } from "react";
import Dashboardimage from "../../images/left_icons/dashboard.png";
import LeftMenu from "../../layout/leftMenu";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import DashboardHome from "./dashboard-home";
const DashboardHeader = lazy(() => import("./dashboardNew-header"));

function Dashboard() {
  const roleIdDatas = ["10", "11", "12", "13", "14"];
  const [formattedDates, setFormattedDates] = useState([
    new Date('2024-01-01').toLocaleDateString("en-US", { month: "short", year: "numeric" }),
    new Date().toLocaleDateString("en-US", { month: "short", year: "numeric" }),
  ]);
  const [roleId, setRoleId] = useState(sessionStorage.getItem("roleId"));

  const updateFormattedDates = useCallback((dates) => {
    console.log("newdatesss", dates);
    if (dates.length === 0) {
      dates = [
        new Date('2024-01-01').toLocaleDateString("en-US", {
          month: "short",
          year: "numeric",
        }),
        new Date().toLocaleDateString("en-US", {
          month: "short",
          year: "numeric",
        }),
      ];
    }
    if (dates.length === 1) {
      dates.push(dates[0]);
    }

    setFormattedDates(dates);
  });
  useEffect(() => {
    document.body.classList.add('dashboardNew-body');
    return () => {
      document.body.classList.remove('dashboardNew-body');
    };
  }, []);
  return (
    <div className="main_wrapper">
      {!roleIdDatas.includes(roleId) && <LeftMenu />}
      {!roleIdDatas.includes(roleId) ?
        <div className="userListWrapper">
          <Header heading="Dashboard" headerImage={Dashboardimage} />
          <div className="dashb-wrapper">
            <Suspense fallback={<div>Loading...</div>}>
              <DashboardHeader updateFormattedDates={updateFormattedDates} />
              <DashboardHome formattedDates={formattedDates} />
            </Suspense>
          </div>
          <Footer />
        </div>
        :
        <div className="userListWrapper" style={{ width: '100%', marginLeft: '0px' }}>
          <div className="dash-wrapper">
            <Suspense fallback={<div>Loading...</div>}>
              <DashboardHeader updateFormattedDates={updateFormattedDates} />
              <DashboardHome formattedDates={formattedDates} />
            </Suspense>
          </div>
        </div>
      }
    </div>
  );
}

export default Dashboard;
