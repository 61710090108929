import axios from "axios";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import * as Icon from "react-feather";

// import { backendApi } from "../apiService";

function TwitterRulesList() {
  const [accessToken, setAccessToken] = useState("");
  const [ruleValue, setRuleValue] = useState("");
  const [tagValue, setTagValue] = useState("");
  //   const [ruleDeleteId, setRuleDeleteId] = useState("");
  const [twitterRulesList, setTwitterRulesList] = useState([]);
  useEffect(() => {
    getTwitterToken();
  }, []);
  useEffect(() => {
    if (accessToken != "") getTwitterRulesList();
  }, [accessToken]);
  const getTwitterToken = () => {
    const tokenUrl =
      "https://jxrlgrrlpa.execute-api.ap-south-1.amazonaws.com/default/twitter-token";
    axios.get(tokenUrl).then((response) => {
      setAccessToken(response.data.access_token);
    });
  };
  const getTwitterRulesList = () => {
    const awsUrl =
      "https://ux27pobdk9.execute-api.ap-south-1.amazonaws.com/default/twitter-get-rules";
    var apiParams = {
      access_token: accessToken,
    };
    axios.post(awsUrl, apiParams).then((response) => {
      setTwitterRulesList(response.data.data);
    });
  };
  const addNewRule = () => {
    const awsUrl =
      "https://nutyrh0ym4.execute-api.ap-south-1.amazonaws.com/default/twitter-add-rule";
    const apiReq = {
      access_token: accessToken,
      value: ruleValue,
      tag: tagValue,
    };
    axios.post(awsUrl, apiReq).then((response) => {
      console.log("response", response.data);
      $("#editCommentPopup").attr("hidden", true);
      getTwitterRulesList();
    });
  };
  const deleteRule = (ruleId) => {
    const awsUrl =
      "https://e46r6d1eo1.execute-api.ap-south-1.amazonaws.com/default/twitter-delete-rules";
    const apiReq = {
      access_token: accessToken,
      ids: ruleId,
    };
    axios.post(awsUrl, apiReq).then((response) => {
      console.log("response", response.data);
      $("#editCommentPopup").attr("hidden", true);
      getTwitterRulesList();
    });
  };
  return (
    <div className="main_wrapper">
      <div className="userListWrapper">
        <div className="container-fluid">
          <button
            className="btn btn-info"
            onClick={() => {
              $("#editCommentPopup").attr("hidden", false);
            }}
          >
            Add Rule
          </button>
          {/* <button onClick={() => getTwitterSearch(nextPageToken)}>
          Next Page
        </button> */}
          {/* <button onClick={() => getTwitterSearch(prevPageToken)}>
        Prev Page
      </button> */}
          <div className="col-md-12 tableResponsive">
            <div className="tableOuterWidth">
              <table className="table">
                <thead>
                  <tr>
                    <th className="col-3">Id</th>
                    <th className="col-3">Name</th>
                    <th className="col-3">Username</th>
                    <th className="col-3">Delete</th>
                  </tr>
                  {/* <tr>Text</tr> */}
                </thead>
                {twitterRulesList.map((data) => {
                  return (
                    <tbody className="table_Outertbody">
                      <tr>
                        <td className="col-3">{data.id}</td>
                        <td className="col-3">{data.value}</td>
                        <td className="col-3">{data.tag}</td>
                        <td className="col-3">
                          <span title="Delete">
                            <Icon.Trash
                              color="RED"
                              onClick={() => deleteRule(data.id)}
                            />
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
      <div id="editCommentPopup" className="module_popup" hidden>
        <div className="module_innerpopup">
          <div className="module_close">
            <Icon.XCircle
              onClick={() => {
                $("#editCommentPopup").attr("hidden", true);
                // $(".modules_div_org").removeClass("active");
                // $("#orgainzationSelectAll").prop("checked", false);
              }}
            />
          </div>
          <div className="userListmodal_head">
            <span className="subhead">Add Rule</span>
          </div>
          <div id="editCommentPopupHeader" className="module_header"></div>
          <div className="module_body">
            <div className="module_popupsearch_wrpr">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1"> Value</label>
                    <input
                      type="text"
                      onChange={(e) => setRuleValue(e.target.value)}
                      //   value={firstName}
                      className="form-control"
                      id="firstName"
                      aria-describedby="emailHelp"
                      placeholder="Value"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1"> Tag</label>
                    <input
                      type="text"
                      onChange={(e) => setTagValue(e.target.value)}
                      //   value={firstName}
                      className="form-control"
                      id="firstName"
                      aria-describedby="emailHelp"
                      placeholder="Tag"
                    />
                  </div>
                </div>
              </div>
              <div className="module_popupsearchBtn">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => addNewRule()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TwitterRulesList;
