import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import * as Icon from "react-feather";

class watsNewPost extends Component {
  render() {
    return (
      <div className="postDetails">
        <div className="form_group_google">
          <label>Post Details2</label>
          <textarea className="form-control" cols="30" rows="5"></textarea>
        </div>
        <div className="right_google_popup">
          <label>
            <Icon.Camera />
            Add photos
            <input type="file" id="imageUpload" />
          </label>
        </div>
        <div className="google_post_footer">
          <button type="button" className="btn btn-light">
            Preview
          </button>
          <button
            type="button"
            className="btn btn-primary"
           
          >
            Post
          </button>
        </div>
      </div>
    );
  }
}

export default watsNewPost;
