import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import $, { data } from "jquery";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";

import LeftMenu from "../layout/leftMenu.jsx";
import Auditimage from "../images/left_icons/processing.png";
import { backendApi, pythonBackendApi } from "../apiService";

import * as Icon from "react-feather";
import { Link } from "react-router-dom";

var dataList1 = JSON.parse(sessionStorage.getItem("dataList"));

class GbpMultipleLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AddressCleaningData: [],
      file: null,
      buffer: null,
      uploaded: false,
      dataList: [],
      fileName: "",
      getAddressCleaningDataByID: [],
      gbp: [],
      status: false,
      checkboxvalue: [],
      buttonVisible: true,
    };
  }

  componentDidMount() {
    console.log("dataList1", dataList1);

    //     const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    // const data = JSON.parse(decodeURIComponent(urlParams.get('data')));
  }

  onSaveGbp() {
    // window.location.href = "/showMultipleLocations?data=" + encodeURIComponent(JSON.stringify(this.state.dataList));
    if (!window.confirm("Do you want to Update GBP?")) {
      return false;
    }
    $(".loder_div").attr("hidden", false);
    const dataList = JSON.parse(sessionStorage.getItem("dataList"));
    // const checkedValues = JSON.parse(sessionStorage.getItem("checkedValues"));
    this.setState({ dataList: dataList });
    // this.setState({ checkboxvalue: checkedValues });

    this.setState({
      status: true,
    });

    let periods = [];
    let obj = {};
    var arr = [];
    var obj1 = [];
    var datalistarray = dataList;

    $("#loder_div").attr("hidden", false);

    for (let i = 0; i < datalistarray.length; i++) {
      var scheduleObjectsmultiple = datalistarray[i].Regularhours.split(
        "|"
      ).map(function (schedule) {
        var parts = schedule.trim().split(",");
        return {
          day: parts[0],
          time: parts[1],
        };
      });

      console.log("scheduleObjectsmultiple", scheduleObjectsmultiple);
      for (let i = 0; i <= 6; i++) {
        var openingTime =
          scheduleObjectsmultiple[i] != undefined
            ? scheduleObjectsmultiple[i].time != undefined
              ? scheduleObjectsmultiple[i].time.split("-")[0]
              : ""
            : "";
        var closingTime =
          scheduleObjectsmultiple[i] != undefined
            ? scheduleObjectsmultiple[i].time != undefined
              ? scheduleObjectsmultiple[i].time.split("-")[1]
              : ""
            : "";

        var openingHourMinute = openingTime.split(":");
        var openingHour = openingHourMinute[0];
        var openingMinute = openingHourMinute[1];

        var closingHourMinute = closingTime.split(":");
        var closingHour = closingHourMinute[0];
        var closingMinute = closingHourMinute[1];

        var openTime = {};
        var closeTime = {};
        if (!openingHour) {
          openTime = {};
          closeTime = { hours: 24 };
        } else {
          openTime = {
            hours: !openingHour ? "" : parseInt(openingHour.trim()),

            minutes: !openingMinute ? "" : parseInt(openingMinute.trim()),
          };
          closeTime = {
            hours: !closingHour ? "" : parseInt(closingHour.trim()),
            minutes: !closingMinute ? "" : parseInt(closingMinute.trim()),
          };
        }

        periods.push({
          openDay:
            scheduleObjectsmultiple[i] != undefined
              ? scheduleObjectsmultiple[i].day != undefined
                ? scheduleObjectsmultiple[i].day
                : ""
              : "",
          openTime: !openTime
            ? {}
            : {
                hours: openTime.hours,
                minutes: openTime.minutes,
              },
          closeDay:
            scheduleObjectsmultiple[i] != undefined
              ? scheduleObjectsmultiple[i].day != undefined
                ? scheduleObjectsmultiple[i].day
                : ""
              : "",
          closeTime: !closeTime
            ? { hours: 24 }
            : {
                hours: closeTime.hours,
                minutes: closeTime.minutes,
              },
        });
      }
      // obj1 = {
      //   value: {
      //     periods: periods,
      //   },
      // };

      // let arr2 = [];
      console.log("obj1.value.periods");
      // for (let k = 0; k < obj1.value.periods.length; k++) {
      //   var period = obj1.value.periods[k];
      //   arr2.push({
      //     openDay: period.openDay,
      //     openTime: !period.openTime
      //       ? {}
      //       : {
      //           hours: period.openTime.hours,
      //           minutes: period.openTime.minutes,
      //         },
      //     closeDay: period.closeDay,
      //     closeTime: !period.closeTime
      //       ? { hours: 24 }
      //       : {
      //           hours: period.closeTime.hours,
      //           minutes: period.closeTime.minutes,
      //         },
      //   });
      // }

      // updateMask = value;

      let newObj = {
        storeCode: datalistarray[i].Storecode,
        title: datalistarray[i].LocationName,
        phoneNumbers: {
          primaryPhone: datalistarray[i].phoneNumbers,
          additionalPhones:
            datalistarray.length > 0
              ? datalistarray[i].AdditionalPhone.replace(/ /g, "")
                  .replace(/&/g, " ")
                  .split(" ")
              : [],
        },
        profile: {
          description: datalistarray[i].Description,
        },
        websiteUri: datalistarray[i].websiteUri,
        regularHours: {
          periods: periods,
        },
      };
      periods = [];
      arr.push(newObj);

      var api = {
        functionName: "UpdateGBPLocation",
        validate: false,
        location_id: datalistarray[i].LocationId,
        obj: arr[i],
        updateMask: datalistarray[i].updateMask,
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
      };

      backendApi(api).then((data) => {
        if (data.success === "1") {
          $(".loder_div").attr("hidden", true);
          $("#success" + datalistarray[i].LocationId).attr("hidden", false);
        } else {
          $(".loder_div").attr("hidden", true);
          $("#failed" + datalistarray[i].LocationId).attr("hidden", false);
        }
      });
    }
  }

  uploadToGBP() {
    if (!window.confirm("Do you want to Update GBP?")) {
      return false;
    }
    $(".loder_div").attr("hidden", false);
    const dataList = JSON.parse(sessionStorage.getItem("dataList"));
    console.log("dataList",dataList);
    this.setState({
      buttonVisible: false,
    });
    for (const [index, obj] of dataList.entries()) {
      let updateMask = "";
      console.log(
        "🚀 ~ file: showMultipleLocation.jsx:227 ~ uploadToGBP ~ obj:",
        obj
      );
      let location = {};
      if (
        obj?.address ||
        obj?.regionCode ||
        obj?.postalCode ||
        obj?.administrativeArea ||
        obj?.locality ||
        obj?.sublocality
      ) {
        updateMask = `${
          updateMask !== "" ? `${updateMask},` : ""
        }storefrontAddress`;
        location["address"] = {
          ...(obj?.regionCode && {
            regionCode: obj?.regionCode,
          }),
          ...(obj?.postalCode && {
            postalCode: obj?.postalCode,
          }),
          ...(obj?.administrativeArea && {
            administrativeArea: obj?.administrativeArea,
          }),
          ...(obj?.locality && {
            locality: obj?.locality,
          }),
          ...(obj?.sublocality && {
            sublocality: obj?.sublocality,
          }),
          ...(obj?.addressLines && {
            addressLines: obj?.address.split(","),
          }),
        };
      }
      if (obj?.latitude || obj?.longitude) {
        updateMask = `${updateMask !== "" ? `${updateMask},` : ""}latlng`;
        location["latlng"] = {
          ...(obj?.latitude && {
            latitude: obj?.latitude,
          }),
          ...(obj?.longitude && {
            longitude: obj?.longitude,
          }),
        };
      }
      if (obj?.primaryPhone || obj?.additionalPhones?.split(",").length > 0) {
        updateMask = `${updateMask !== "" ? `${updateMask},` : ""}phoneNumbers`;
        location["phoneNumbers"] = {
          ...(obj?.primaryPhone && {
            primaryPhone: obj?.primaryPhone,
          }),
          ...(obj?.additionalPhones && {
            additionalPhones: obj?.additionalPhones?.split(","),
          }),
        };
      }
      if (obj?.description) {
        updateMask = `${updateMask !== "" ? `${updateMask},` : ""}profile`;
        location["profile"] = {
          ...(obj?.description && {
            description: obj?.description,
          }),
        };
      }
      if(obj?.BusinessName){
        updateMask = `${updateMask !== "" ? `${updateMask},` : ""}title`;
        location["title"] = obj?.BusinessName
      }
      if(obj?.StoreCode){
        updateMask = `${updateMask !== "" ? `${updateMask},` : ""}storeCode`;
        location["storeCode"] = obj?.StoreCode
      }
      if (obj?.websiteUri) {
        updateMask = `${updateMask !== "" ? `${updateMask},` : ""}websiteUri`;
        location["websiteUri"] = {
          ...(obj?.websiteUri && {
            websiteUri: obj?.websiteUri,
          }),
        };
      }
      if (obj?.labels?.split(",").length > 0) {
        updateMask = `${updateMask !== "" ? `${updateMask},` : ""}labels`;
        location["labels"] = {
          ...(obj?.labels && {
            labels: obj?.labels?.split(","),
          }),
        };
      }
      if (obj?.periods?.length > 0) {
        updateMask = `${updateMask !== "" ? `${updateMask},` : ""}regularHours`;
        location["regularHours"] = {
          ...(obj?.periods?.length > 0 && {
            periods: obj?.periods,
          }),
        };
      }
      if (obj?.primaryCategory) {
        updateMask = `${
          updateMask !== "" ? `${updateMask},` : ""
        }primaryCategory`;
        location["primaryCategory"] = obj?.primaryCategory;
      }
      if (obj?.additionalCategories) {
        updateMask = `${
          updateMask !== "" ? `${updateMask},` : ""
        }additionalCategories`;
        location["additionalCategories"] = obj?.additionalCategories;
      }
      console.log(
        "🚀 ~ file: showMultipleLocation.jsx:324 ~ uploadToGBP ~ updateMask:",
        updateMask
      );

      console.log(
        "🚀 ~ file: showMultipleLocation.jsx:328 ~ uploadToGBP ~ location:",
        location
      );
      if (obj.LocationId) {
        var api = {
          functionName: "UpdateGBPLocation",
          validate: false,
          location_id: obj.LocationId,
          obj: location,
          updateMask: updateMask,
          siteId:
            window.sessionStorage.getItem("switchingOrgId") === ""
              ? window.sessionStorage.getItem("organizationId")
              : window.sessionStorage.getItem("switchingOrgId"),
        };
        console.log(
          "🚀 ~ file: showMultipleLocation.jsx:352 ~ uploadToGBP ~ api:",
          api
        );
        // return false
        backendApi(api).then((data) => {
          if (data.success === "1") {
            $(".loder_div").attr("hidden", true);
            $("#success" + obj.LocationId).attr("hidden", false);
          } else {
            $(".loder_div").attr("hidden", true);
            $("#failed" + obj.LocationId).attr("hidden", false);
          }
        });
      }
    }
  }

  ShowTable = () => {
    return (
      <>
        <div className="col-md-12 tableResponsive">
          <table className="table fileupload-table mb-0 tagHorScroll tagTableLarge">
            <thead>
              <tr>
                <th>Location Name</th>
                <th>Location Id</th>
                <th>Store code</th>
                <th>Address</th>
                <th>Sub-Locality</th>
                <th>Locality</th>
                <th>Administrative Area</th>
                <th>Description</th>
                <th>Regular hours</th>
                <th>Primary Phone</th>
                <th>Additional Phone</th>
                <th>Website</th>
                <th>Status</th>
              </tr>
            </thead>

            <div className="table_OuterscrollNew">
              {dataList1.map((r, i) => {
                console.log(
                  "🚀 ~ file: showMultipleLocation.jsx:257 ~ GbpMultipleLocation ~ {dataList1.map ~ r:",
                  r
                );
                const formattedPeriods = r?.periods?.map((period) => {
                  const openTime = `${period.openTime.hours}:${
                    period.openTime.minutes < 10
                      ? "0" + period.openTime.minutes
                      : period.openTime.minutes
                  }`;
                  const closeTime = `${period.closeTime.hours}:${
                    period.closeTime.minutes < 10
                      ? "0" + period.closeTime.minutes
                      : period.closeTime.minutes
                  }`;
                  return `${period.openDay} - ${openTime} - ${closeTime}`;
                });
                const formattedHrs = formattedPeriods?.join(", ");

                return (
                  <tbody key={i} className="table_Outertbody ">
                    <tr>
                      <td>{r?.BusinessName}</td>
                      <td>{r?.LocationId}</td>
                      <td>{r?.StoreCode}</td>
                      <td>{r?.address}</td>
                      <td>{r?.sublocality}</td>
                      <td>{r?.locality}</td>
                      <td>{r?.administrativeArea}</td>
                      <td>{r?.description}</td>
                      <td>
                        {formattedHrs?.split(", ").map((day, index) => {
                          return (
                            <span key={index}>
                              {day}
                              {index !==
                                formattedHrs.split(", ").length - 1 && <br />}
                            </span>
                          );
                        })}
                      </td>
                      <td>{r?.primaryPhone}</td>
                      <td>{r?.additionalPhones}</td>
                      <td>{r?.websiteUri}</td>

                      <td>
                        <span
                          id={"success" + r.LocationId}
                          hidden
                          style={{ color: "green" }}
                        >
                          {" "}
                          Updated
                        </span>
                        <span
                          id={"failed" + r.LocationId}
                          hidden
                          style={{ color: "red" }}
                        >
                          {" "}
                          Failed
                        </span>
                      </td>

                      {/* {mappedArray.length>0?

<span>{value} is updated</span>:
<span>{value} is not updated</span>} */}
                    </tr>
                  </tbody>
                );
              })}
            </div>
          </table>
          {this.state.buttonVisible && (
            <div className="button-wrapper mt-0 mb-4">
              <button
                className="btn btn-primary ml-3 btn-gbpsave"
                onClick={() => {
                  this.uploadToGBP();
                }}
              >
                Update
              </button>
            </div>
          )}
        </div>
      </>
    );
  };

  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          <Header heading="GBP Multiple Locations" headerImage={Auditimage} />
          <div className="container-fluid">
            <div className="back_tocatalog_main">
              <Link to="/multipleLocations" className="back-to-link">
                <Icon.ArrowLeftCircle />
              </Link>
            </div>
            {this.ShowTable()}
          </div>

          <Footer />
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}
export default GbpMultipleLocation;
