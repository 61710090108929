import React, { Component } from "react";
import GoogleAnalyticsimage from "../images/left_icons/google_analytics.png";
//import AnalyticsUserGraph from "../analytics/analytics-userGraph";
import AnalyticsUserGraph from "./analytics-usergraph";
import Linecharts from "./linechartsUser";
import {apiCallingPost } from "../apiService";

class AnalyticsGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
    activeUsers: "",
    totalUsers:"",
    NewUsers:"",
    Sessions:"",
    averageSessionDuration:"",
    UserEngagementDuration:"",
    GoalConversionRate:"",
    Conversions:"",
    BounceRate:"",
    propertyId1:this.props.userDataInThirtyMin

    };
  }
  componentDidMount() {
    this.userInThirtyMinutes();
    console.log("mmmmmmmmmmmmmmmm111111111111111mmm", this.props)
    // this.setState({propertyId1: this.props.userDataInThirtyMin})
  }
  userInThirtyMinutes() {
    const postData = {
      "functionName": "googleAnalyticsReport",
      "duration": "30",
      "propertyId": this.props.userDataInThirtyMin
  }
    console.log("mmmmmmmmmmmmmmmmm222222222222222mm", postData)
    apiCallingPost(postData).then((data) => {
      console.log("mmmmmmmmmmmmmmmmgggggggggggggmmm", data.totalset)
      this.setState(
        {
          activeUsers: data.totalset.ActiveUsers,
          totalUsers:data.totalset.TotalUsers,
          Sessions: data.totalset.Sessions,
          NewUsers: data.totalset.NewUsers,
          averageSessionDuration:data.totalset.AverageSessionDuration,
          BounceRate: data.totalset.BounceRate,
          Conversions: data.totalset.Conversions,
          GoalConversionRate: data.totalset.GoalConversionRate,
          UserEngagementDuration : data.totalset.UserEngagementDuration,
        })
    });
  }
  render() {
    function randomNumber() {
      return Math.random() * (40 - 0) + 0;
    }

    const data = [
      { key: 'Group 1', values: [{ x: 'A', y: randomNumber() }, { x: 'B', y: randomNumber() }, { x: 'C', y: randomNumber() }, { x: 'D', y: randomNumber() }] }
    ];
    return (




      <div className="userCountDivlist">
        <div className="userListMain">
          <div className="usersNumber">
            <div className="analyticsUsersTime">Users in last 30 minutes</div>
            <div className="analyticsUserCount">{this.state.activeUsers}</div>
          </div>
          {/* <div className="userFilterDiv row p-0">
            <div className="cityMain">
              <div className="col-lg-6 padLeft0">
                <select value="city" className="ga-slect-field">
                  <option value="">City</option>
                  <option value="">Delhi</option>
                </select>
              </div>
              <div className="col-lg-6 text-right">
                <select value="city" className="ga-slect-field">
                  <option value="">Users</option>
                  <option value="">User1</option>
                </select>
              </div>
            </div>
            <div className="mainRowDiv">
              <div className="col-lg-6 cityRight">
                <p className="cityDiv">Delhi</p>
                <p className="cityDiv">Ludhiana</p>
              </div>
              <div className="col-lg-6 userLeft">
                <p className="usersDiv">1</p>
                <p className="usersDiv">2</p>
              </div>
            </div>
          </div> */}
        </div>
        <div className="userSectionSubitems">
          <div className="analyticsData">
            <div className="analytics_home">Users</div>
            <div className="analyticsHomeCount user-analytics">
            {this.state.totalUsers}
            </div>
            {/* <div className="chartsUserList">
              <Linecharts
                label="Sessions"
                xAxisLabel="Months"
                yAxisLabel="No. of sessions"
                data={data}
              />
            </div> */}
          </div>
          <div className="analyticsData">
            <div className="analytics_home">New Users</div>
            <div className="analyticsHomeCount user-analytics">
              {this.state.NewUsers}
            </div>
          </div>
          <div className="analyticsData">
            <div className="analytics_home">Sessions</div>
            <div className="analyticsHomeCount user-analytics">
              {this.state.Sessions}
            </div>
          </div>
          {/* <div className="analyticsData">
            <div className="analytics_home">Number of Sessions per User</div>
            <div className="analyticsHomeCount user-analytics">
              9.00
            </div>
          </div>
          <div className="analyticsData">
            <div className="analytics_home">Page / Session</div>
            <div className="analyticsHomeCount user-analytics">
              1
            </div>
          </div> */}
          <div className="analyticsData">
            <div className="analytics_home">Avg Session Duration</div>
            <div className="analyticsHomeCount user-analytics">
            {this.state.averageSessionDuration}
            </div>
          </div>
          <div className="analyticsData">
            <div className="analytics_home">Bounce Rate</div>
            <div className="analyticsHomeCount user-analytics">
             {this.state.BounceRate}
            </div>
          </div>
          <div className="analyticsData">
            <div className="analytics_home">Conversion</div>
            <div className="analyticsHomeCount user-analytics">
             {this.state.Conversions}
            </div>
          </div>
          <div className="analyticsData">
            <div className="analytics_home">Goal Conversion Rate</div>
            <div className="analyticsHomeCount user-analytics">
             {this.state.GoalConversionRate}
            </div>
          </div>
          <div className="analyticsData">
            <div className="analytics_home">User Engagement Duration</div>
            <div className="analyticsHomeCount user-analytics">
             {this.state.UserEngagementDuration}
            </div>
          </div>
        </div>
        <br />
        <div className="col-lg-12">
          <div className="analyticsUsersTime">Users Per Minute</div>
          <div className="userGraph">
            {console.log("this.state.propertyId1",this.state.propertyId1)}
            <AnalyticsUserGraph propertyId={this.state.propertyId1}/>
          </div>
        </div>
      </div>

    );
  }
}
export default AnalyticsGraph;
