import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import * as Icon from "react-feather";
import { apiCallingPost, backendApi, pythonBackendApi } from "../apiService";
import Pagination from "react-js-pagination";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import Footer from "../layout/footer";
import moment from "moment";
import GoogleMap from "./singleClaim/components/GoogleMap";
import GoogleIcon from "../images/google.png";
import GooglePost from "../images/icon01.png";
import GooglePhotos from "../images/icon02.png";
import GoogleDetailsIcon from "../images/icon04.png";
import * as XLSX from "xlsx";

let stringComparison = require("string-comparison");
let cos = stringComparison.levenshtein;
let auditAddress = "32/54, Main Rd, near Vee Mart, Kottakkal, Kerala 676503";
let claimAddress = "32/54, Main Road, Near Vee Mart, Kottakal";
let percentage = cos.similarity(auditAddress, claimAddress);
console.log(percentage * 100);
function FromAudit(props) {
  const [dataList, setDataList] = useState([]);
  const [claimList, setclaimList] = useState([]);
  const [claimedData, setClaimedData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalStatusCount, setTotalStatusCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [allStatusCount, setAllStatusCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [tempSearchList, setTempSearchList] = useState([]);
  // const [totalCount, setTotalCount] = useState(0);
  const [claimedTitle, setclaimedTitle] = useState("");
  const [BusinessName, setBusinessName] = useState("");
  const [googleAddressPin, setGoogleAddressPin] = useState("");
  const [Description, setDescription] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [Address, setAddress] = useState("");
  const [PhoneNo, setPhoneNo] = useState("");
  const [storeCode, setStoreCode] = useState("");
  const [VerificationEmail, setVerificationEmail] = useState("");
  const [Locality, setLocality] = useState("");
  const [PostalCode, setPostalCode] = useState("");
  const [mapRenderKey, setMapRenderKey] = useState("");
  const [AdminstriveArea, setAdminstriveArea] = useState("");
  const [statusType, setStatusType] = useState("");
  // const [statusType, setStatusType] = useState("");
  const [pageOffset, setPageOffset] = useState(0);
  const [claimedCount, setClaimedCount] = useState(0);
  const [backupdata, setbackupdata] = useState([]);
  const [statusCount, setStatusCount] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [nextLocationsList, setNextLocationsList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const inputRef = useRef();
  const navigate = useNavigate();
  useEffect(() => {
    getauditGoogleData("");
    getCategoriesListGoogle();
    getStatusList();
    $("#nodata").hide();
    window.sessionStorage.setItem("pushFromNodata", 0);
  }, [statusType]);
  const getauditGoogleData = (pageOffset) => {
    // alert(pageOffset);
    $(".loder_div").attr("hidden", false);
    var apiJson = {
      functionName: "getDataFromAudit",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      status: statusType,
      offset: pageOffset == "" ? "0" : pageOffset,
    };
    apiCallingPost(apiJson).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1" && data.data.length !== 0) {
        setTotalCount(data.data.length);
        // const indexOfLastPost = currentPage * 25;
        // const indexOfFirstPost = indexOfLastPost - 25;
        // let tempList = data.data;
        // const currentPosts = data.data.slice(indexOfFirstPost, indexOfLastPost);
        setNextLocationsList(
          data.data
            .filter((obj) => obj.GBP_status !== "Claimed")
            .map((data) => data)
        );
        console.log(
          "jellloooo",
          data.data
            .filter((obj) => obj.GBP_status !== "Claimed")
            .map((obj) => obj)
        );
        setDataList(data.data);
        setbackupdata(data.data);
        setclaimList(data.data);
        //! this is the count codes
        // setStatusCount(data.statusCount);
        // setClaimedCount(data.claimed_cnt[0].claimed_cnt);
        // if (statusType === "") {
        //   let statusTempCount = data.statusCount.reduce(
        //     (sum, a) => sum + a.cnt,
        //     0
        //   );
        //   statusTempCount = statusTempCount + data.claimed_cnt[0].claimed_cnt;
        //   setTotalStatusCount(statusTempCount);
        // } else {
        //   let statusTempCount = data.statusCount
        //     .filter((obj) => obj.status == statusType)
        //     .map((obj) => obj.cnt);
        //   setTotalStatusCount(statusTempCount);
        // }
        //! count code till here
      } else {
        $("#nodata").show();
        setDataList([]);
        setclaimList([]);
        setbackupdata([]);
        setTotalCount(0);
      }
    });
  };
  const getStatusList = () => {
    var apiJson = {
      functionName: "claimShowStatus",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    apiCallingPost(apiJson).then((data) => {
      if (data.success === "1") {
        console.log("datatatatatatata", data);
        let countArray = [];
        data.data.map((obj) => {
          if (obj.status != "Ignore") {
            countArray.push({ status: obj.status, cnt: obj.cnt });
          }
        });
        data.data2.map((obj) => {
          if (
            obj.GBP_status == "Claim Initiated" ||
            obj.GBP_status == "Claimed" ||
            obj.GBP_status == "Ignore"
          ) {
            countArray.push({ status: obj.GBP_status, cnt: obj.cnt });
          }
        });
        console.log("countArraycountArray", countArray);

        // setTotalStatusCount(statusTempCount);
        setStatusCount(countArray);
        //! this is the count codes
        if (statusType === "") {
          let statusTempCount = countArray.reduce((sum, a) => sum + a.cnt, 0);
          setTotalStatusCount(statusTempCount);
          setAllStatusCount(statusTempCount);
        } else {
          let allStatusTempCount = countArray.reduce(
            (sum, a) => sum + a.cnt,
            0
          );
          setAllStatusCount(allStatusTempCount);
          let statusTempCount = countArray
            .filter((obj) => obj.status == statusType)
            .map((obj) => obj.cnt);
          setTotalStatusCount(statusTempCount);
        }
        //! count code till here
      } else {
        setTotalCount(0);
      }
    });
  };
  const claimAddress = (data) => {
    $(".loder_div").attr("hidden", false);
    setclaimedTitle(data.location_name);
    var apiJson = {
      functionName: "getDataFromAuditClaim",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      query: data.location_name + "," + data.address,
    };
    console.log("hi", apiJson);
    // return false;
    apiCallingPost(apiJson).then((data) => {
      // navigate("/claim/claimBusiness/claimPrimary",{
      //   state:{
      //     claimedTitle: data.location_name,
      //     claimedData: data.data.googleLocations,
      //   }})
      setClaimedData(data.data.googleLocations);
      // $("#markedSubModal").attr("hidden", false);
      $(".loder_div").attr("hidden", true);
    });
  };

  const handleFilter = () => {
    $(".loder_div").attr("hidden", false);
    setTimeout(() => {
      $(".loder_div").attr("hidden", true);
    }, 500);
    $("#nodata").show();
    var businessname = $("#businessname").val();
    var address = $("#address").val();
    let finalfilter = claimList;
    setTempSearchList(claimList);
    if (address != "" && businessname != "") {
      finalfilter = finalfilter.filter((x) =>
        x.location_name.toLowerCase().includes(businessname.toLowerCase())
      );
      finalfilter = finalfilter.filter((x) =>
        x.address.toLowerCase().includes(address.toLowerCase())
      );
    } else if (address != "") {
      finalfilter = backupdata.filter((x) =>
        x.address.toLowerCase().includes(address.toLowerCase())
      );
    } else if (businessname != "") {
      finalfilter = backupdata.filter((x) =>
        x.location_name.toLowerCase().includes(businessname.toLowerCase())
      );
    } else {
      getauditGoogleData("");
    }
    //   finalfilter = finalfilter.filter((x) =>
    //   x.location_name.toLowerCase().includes(businessname.toLowerCase())
    // );
    // finalfilter = finalfilter.filter((x) =>
    //   x.address.toLowerCase().includes(address.toLowerCase())
    // );
    setTotalCount(finalfilter.length);
    // const indexOfLastPost = currentPage * 25;
    // const indexOfFirstPost = indexOfLastPost - 25;
    // // let tempList = claimList;
    // const currentPosts = finalfilter.slice(indexOfFirstPost, indexOfLastPost);
    setDataList(finalfilter);
    // setDataList(data.data);
    setclaimList(finalfilter);
  };
  const clearSearch = () => {
    getauditGoogleData("");
    $("#businessname").val("");
    $("#address").val("");
  };
  const googleMaping = () => {
    var apidata = {
      method: "POST",
      body: JSON.stringify({
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
      }),
    };
    pythonBackendApi("claimGoogleMappingChecking", apidata).then((data) => {
      if (data.success) {
        alert("Mapping Process started. Please wait a few minutes.");
        // alert("Moved To Fraud");
        // $("#fromData").val("");
        // $("#toData").val("");
        // $("#statusValue").val("0");
        // $("#searchAddress").val("");
        // getAuditExcelDataByMasterId();
        // $(".loder_div").attr("hidden", true);
      } else {
        // $(".loder_div").attr("hidden", true);
      }
    });
  };
  const handleActivePage = (pageNumber) => {
    setCurrentPage(pageNumber);
    // let offset = "0";
    // if (pageNumber == "1") {
    //   offset = "0";
    // } else {
    let offset = (parseInt(pageNumber) - 1) * 50;
    // }
    setPageOffset(offset);
    getauditGoogleData(offset);
    // const indexOfLastPost = e * 25;
    // const indexOfFirstPost = indexOfLastPost - 25;
    // let tempList = claimList;
    // const currentPosts = claimList.slice(indexOfFirstPost, indexOfLastPost);
    // setDataList(currentPosts);
    // setclaimList(tempList);
  };
  const exportDownload = (value) => {
    var apiJson = {
      functionName: "getDataFromAuditDownload",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      status: statusType,
      offset: pageOffset,
    };
    backendApi(apiJson).then((data) => {
      let downloadData = data.data.map((object) => ({
        ID: object.id,
        "Location Name": object.location_name,
        Address: object.address,
        Category: object.category,
        "Phone No.": object.phone_no,
        "Share URL": object.share_url,
        Status: object.status,
      }));
      const now = new Date();
      let pageDate = moment(now).format("DD_MM_YY_HH:mm");
      // alert(pageDate);
      const worksheet = XLSX.utils.json_to_sheet(downloadData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "DataSheet" + pageDate + ".xlsx");
      inputRef.current.value = "0";
    });
  };
  const clickIgnore = (data) => {
    console.log("hhhh", data);
    const apiReq = {
      functionName: "claimUpdateIgnore",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      Id: data.id,
    };
    backendApi(apiReq).then((data) => {
      getauditGoogleData("");
    });
  };
  const navigateToNextPage = (data) => {
    navigate("/createNewPost", {
      state: {
        selectedLocations: [data.location_id],
        completeLocationData: [],
        PostEditField: [],
        fromClaimData: [data],
      },
    });
  };
  const navigateToBusinessEdit = (data) => {
    console.log(
      "🚀 ~ file: fromAudit.jsx:351 ~ navigateToBusinessEdit ~ data:",
      data
    );
    debugger;
    window.sessionStorage.setItem("fromClaimData", JSON.stringify(data));
    window.location.href = "/business-details";
    // setTimeout(() => {
    //   navigate("/business_details");
    // }, 3000);
  };
  const navigateToGooglePhotos = (data) => {
    window.sessionStorage.setItem("fromClaimData", JSON.stringify(data));
    window.location.href = "/GBPPhotos";
  };
  const moreOptionsSelect = (e, data) => {
    if (e.target.value == "1") {
      claimAddress(data);
    } else if (e.target.value == "2") {
      claimAddress(data);
    } else if (e.target.value == "3") {
      // claimAddress(data)
    }
  };
  const validationCheck = () => {
    if (!storeCode) {
      alert("Store Code is Required");
      return false;
    } else if (!BusinessName) {
      alert("Location Name is Required");
      return false;
    } else if (!Address) {
      alert("Address is Required");
      return false;
    } else if (!PhoneNo) {
      alert("Phone Number is Required");
      return false;
    } else if (!Locality) {
      alert("Locality is Required");
      return false;
    } else if (!AdminstriveArea) {
      alert("Administrative Area is Required");
      return false;
    } else if (!PostalCode) {
      alert("Postal Code is Required");
      return false;
    } else {
      return true;
    }
  };
  const saveLocation = (e) => {
    e.preventDefault();
    var validationFlag = validationCheck();
    if (validationFlag == true) {
      let objData = {
        full_name: BusinessName,
        address: Address,
        mobile_no: PhoneNo,
        email_id: VerificationEmail,
        locality: Locality,
        postalCode: PostalCode,
        administrativeArea: AdminstriveArea,
        account_id: window.sessionStorage.getItem("account_id"),
        storeCode: storeCode,
        category: selectedCategory.name,
        site_id:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        location_id: "",
        latitude: latitude,
        longitude: longitude,
        verification_code: "",
        g_pin: "",
        status: "Push Initiated",
        created_by: window.sessionStorage.getItem("userId"),
      };
      var api = {
        functionName: "insertGbp_localDB",
        objectArray: [objData],
      };
      console.log(api, "apiapiapiapiapi");
      // return false;
      backendApi(api).then((data) => {
        if (data.success === "1") {
          window.location.href = `/claim_business/listOfPushSaveLocations/${data.UID}`;
        }
      });
    }
  };
  const changeHandler = (e) => {
    console.log("eeeee", e);
  };
  const splitAddressFunction = (address) => {
    let pinData = [];
    pinData = address.split(", ");
    let re = /^[0-9]{6}$/;
    if (re.test(pinData[pinData.length - 1])) pinData.pop();

    if (pinData.some((obj) => obj.toLowerCase() == "india")) {
      let array = pinData.filter((obj) => obj.toLowerCase() != "india");
      pinData = array;
    }
    // debugger;
    let adminArea = pinData.pop();
    let localArea = pinData.pop();
    setAdminstriveArea(adminArea.toString());
    setLocality(localArea.toString());
    setAddress(pinData.join(", "));
    console.log("its there", pinData);
    // if (
    //   pinData.some((obj) => obj.toLowerCase() == AdminstriveArea.toLowerCase())
    // ) {
    //   let array = pinData.filter(
    //     (obj) => obj.toLowerCase() != AdminstriveArea.toLowerCase()
    //   );
    //   pinData = array;
    //   console.log("its there", pinData);
    // }
    // pinData
    //   .filter((obj) => obj.toLowerCase() == AdminstriveArea.toLowerCase())
    //   .map((adminArea) => {
    //     // console.log("adminArea", adminArea);
    //     setAdminstriveArea();
    //   });
    // if (pinData.toLowerCase().includes(AdminstriveArea.toLowerCase())) {
    //   pinData.toLowerCase().replace(AdminstriveArea.toLowerCase() + ", ");
    //   console.log("its there", pinData);
    // }
  };
  const getCategoriesListGoogle = () => {
    const apiReq = {
      functionName: "googleLocationCategoryList",
      siteId: window.sessionStorage.getItem("switchingOrgId") === ""
      ? window.sessionStorage.getItem("organizationId")
      : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apiReq).then((data) => {
      console.log("googleLocationCategoryList", data);
      setCategoriesList(data.data.categories);
    });
  };
  const filterCategory = (e) => {
    if (e.target.value.length > 2) {
      var filterArray = categoriesList.filter((data) =>
        data.displayName.toLowerCase().includes(e.target.value.toLowerCase())
      );
      console.log("filterArrayfilterArray", filterArray);
      setFilteredCategories(filterArray);
    } else {
      setFilteredCategories([]);
    }
  };

  return (
    <div className="container">
      <div className="row row-search">
        {/* <div className="col-lg-2 col-md-4 col-sm-6">
          <select className="form-control">
            <option value="">India</option>
            <option value="">Sri Lanka</option>
          </select>
        </div>
        <div className="col-lg-2 col-md-4 col-sm-6">
          <select className="form-control">
            <option value="0">State</option>
            <option value="">Kerala</option>
          </select>
        </div>
        <div className="col-lg-2 col-md-4 col-sm-6">
          <select className="form-control">
            <option value="0">City</option>
            <option value="">Tvm</option>
          </select>
        </div> */}
        {/* <div className="col-lg-3 col-md-4 col-sm-6 location_search">
          <input className="form-control" type="text" placeholder="Location" />
          <div className="location_search_drop">
            <ul class="nav flex-column">
              <li class="nav-item">
                <span>Location1</span>
              </li>
              <li class="nav-item">
                <span>Location2</span>
              </li>
              <li class="nav-item">
                <span>Location3</span>
              </li>
              <li class="nav-item">
                <span>Location4</span>
              </li>
              <li class="nav-item">
                <span>Location5</span>
              </li>
              <li class="nav-item">
                <span>Location6</span>
              </li>
            </ul>
          </div>
        </div> */}
        <div className="col-lg-2 col-md-4 col-sm-6 paddingRight">
          <input
            type="text"
            className="form-control"
            placeholder="Business Name"
            id="businessname"
          />
        </div>
        <div className="col-lg-2 col-md-4 col-sm-6 paddingRight">
          <input
            type="text"
            className="form-control"
            placeholder="Address"
            id="address"
          />
        </div>
        <div className="col-lg-8 col-md-4 col-sm-6">
          <button className="btn-search" onClick={handleFilter}>
            Search
          </button>
          <button className="btn btn-clear-search" onClick={clearSearch}>
            Clear Search
          </button>
          <button className="btn btn-info blink" onClick={googleMaping}>
            Google Mapping (GBP)
          </button>
        </div>
      </div>
      <hr className="mt-0"></hr>
      <div className="col-md-12 tableResponsive">
        {/* <div className="tableOuterWidth table_width_md"> */}
        <div className="tableOuterWidth">
          <table className="table">
            <thead className="table_opacity_th">
              <tr>
                <th className="col-2">Claim Businesses</th>
                <th className="col-2"></th>
                <th className="col-2">
                  <button
                    className="btn btn-info"
                    onClick={() => exportDownload()}
                  >
                    Download Listing(s)
                  </button>
                </th>
                <th className="col-3">
                  <select
                    className="form-control"
                    onChange={(obj) => {
                      // changeStatus(obj.target.value);
                      setStatusType(obj.target.value);
                      // getauditGoogleData();
                    }}
                  >
                    <option key="0" value="">
                      All ({allStatusCount})
                    </option>
                    {/* <option value="Claimed"> Claimed ({claimedCount})</option> */}
                    {statusCount.map((data, i) => (
                      <option key={i} value={data.status}>
                        {data.status} ({data.cnt})
                      </option>
                    ))}
                  </select>
                </th>
                <th className="col-3">
                  <select
                    className="form-control"
                    ref={inputRef}
                    id="moreOptionsSelect"
                    onChange={(ref) => {
                      if (ref.target.value === "2")
                        navigate("/claim_business/bulkUploadVerifyPage");
                      else if (ref.target.value == "1") props.changeTab(1);
                      else if (ref.target.value == "3") setShowPopup(true);
                    }}
                  >
                    <option key="0" value="0" disabled selected>
                      More Options{" "}
                    </option>
                    <option key="3" value="3">
                      {" "}
                      Create new Business.
                    </option>
                    <option key="1" value="1">
                      No Data From Google (Audit)
                      {/* Add single Business (From Audit) */}
                    </option>
                    <option key="2" value="2">
                      {" "}
                      Bulk Excel Upload
                      {/* Add multiple Businesses (From Audit) */}
                    </option>

                    {/* <option key="3" value="3">
                      {" "}
                      Download Listings
                    </option> */}
                  </select>
                </th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th className="col-1">Sl. No.</th>
                <th className="col-4">Business</th>
                {/* <th className="col-4">Address</th> */}
                <th className="col-2">Status</th>
                <th className="col-3">
                  {/* <select className="form-control">
                    <option key="0">All ({totalStatusCount})</option>
                    {statusCount.map((data, i) => (
                      <option key={i}>
                        {data.status} ({data.cnt})
                      </option>
                    ))}
                  </select> */}
                </th>
                <th className="col-2" />
              </tr>
            </thead>
            <div class="table_Outerscroll">
              {dataList.length !== 0 ? (
                dataList.map((data) => {
                  let date1 = new Date();
                  let date2 = new Date(data.created_on);
                  var time_difference = date1.getTime() - date2.getTime();
                  var days_difference = Math.floor(
                    time_difference / (1000 * 60 * 60 * 24)
                  );
                  // return days_difference;
                  return (
                    <tbody className="table_Outertbody" key={data.id}>
                      <tr>
                        <td className="col-1">{data.id}</td>
                        <td className="col-4">
                          {data.storeCode && (
                            <span style={{ color: "firebrick" }}>
                              {data.storeCode}-&nbsp;
                            </span>
                          )}
                          {data.location_name}
                          <span style={{ display: "block" }}>
                            {data.address}
                          </span>
                        </td>
                        {/* <td className="col-4"></td> */}
                        <td className="col-2">
                          {/* {data.GBP_status == "Claimed" ? (
                          <>
                            <div className="duplicateDiv">
                              <Icon.CheckCircle
                                style={{ color: "green" }}
                                className="icon_common"
                              />
                              Claimed
                            </div>
                          </>
                        ) : data.GBP_status == "Claim Initiated" ? (
                          <>
                            {data.status == "Primary" && (
                              <div className="duplicateDiv">
                                <Icon.CheckCircle
                                  style={{ color: "green" }}
                                  className="icon_common"
                                />
                                Claim Initiated
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {data.status == "Primary" && (
                              <div className="duplicateDiv">
                                <Icon.CheckCircle
                                  style={{ color: "green" }}
                                  className="icon_common"
                                />
                                Primary
                              </div>
                            )}
                          </>
                        )} */}
                          {data.GBP_status === "Claimed" ? (
                            <>
                              <Icon.CheckCircle
                                style={{ color: "green" }}
                                className="icon_common"
                              />
                              {data.GBP_status}
                              <span
                                style={{ display: "block", marginLeft: "10px" }}
                              >
                                ({data.status})
                              </span>
                            </>
                          ) : (
                            <>
                              {data.GBP_status === "Claim Initiated" ? (
                                <>
                                  <Icon.CheckCircle
                                    style={{ color: "green" }}
                                    className="icon_common"
                                  />
                                  {data.GBP_status}
                                  <span
                                    style={{
                                      display: "block",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    ({data.status})
                                  </span>
                                </>
                              ) : (
                                <>
                                  {data.status == "Primary" && (
                                    <Icon.CheckCircle
                                      style={{ color: "green" }}
                                      className="icon_common"
                                    />
                                  )}
                                  {data.status == "Duplicate" && (
                                    <Icon.AlertTriangle
                                      style={{ color: "red" }}
                                      className="icon_common"
                                    />
                                  )}
                                  {data.status == "Stray" && (
                                    <Icon.AlertOctagon
                                      style={{ color: "orange" }}
                                      className="icon_common"
                                    />
                                  )}

                                  {data.status}
                                </>
                              )}
                            </>
                          )}
                          {/* 
                        {data.status == "Stray" && (
                          <div className="duplicateDiv">
                            <Icon.AlertOctagon
                              style={{ color: "orange" }}
                              className="icon_common"
                            />
                            Stray
                          </div>
                        )} */}
                        </td>
                        <td className="col-3">
                          {data.GBP_status == "Claimed" ? (
                            <>
                              <img
                                src={GoogleDetailsIcon}
                                title="Edit Business Details"
                                className="mr-3"
                                onClick={() => navigateToBusinessEdit(data)}
                              />
                              <img
                                src={GooglePost}
                                title="Create post"
                                className="mr-3"
                                onClick={() => navigateToNextPage(data)}
                              />
                              <img
                                className="mr-3"
                                src={GooglePhotos}
                                title="Add Photos"
                                onClick={() => navigateToGooglePhotos(data)}
                              />
                              <select
                                className="custom_mini_select"
                                onChange={(e) => moreOptionsSelect(e, data)}
                              >
                                <option value="0"></option>
                                <option value="1">Merge Location </option>
                                <option value="2">Delete Location </option>
                                {/* <option value="3">Mark Permanently Closed</option> */}
                              </select>
                            </>
                          ) : data.GBP_status == "Claim Initiated" ? (
                            <>
                              <span
                                style={{
                                  marginLeft: "30px",
                                  marginBottom: "20px",
                                }}
                              >
                                <button
                                  className="btn btn-success mr-2"
                                  // onClick={() => claimAddress(data)}
                                  onClick={() =>
                                    navigate(
                                      `/claim/claimBusiness/claimPrimary/${data.id}`,
                                      {
                                        state: {
                                          auditClaimData: data,
                                          nextLocationsList: nextLocationsList,
                                        },
                                      }
                                    )
                                  }
                                >
                                  Claim
                                </button>
                                <button
                                  className="btn btn-warning mr-2"
                                  onClick={() => clickIgnore(data)}
                                >
                                  Ignore
                                </button>
                                {/* <button
                                className="btn btn-danger mr-2"
                                onClick={() => claimAddress(data)}
                              >
                                Delete
                              </button>
                              <button
                                className="btn btn-primary"
                                onClick={() => claimAddress(data)}
                              >
                                Merge
                              </button> */}
                              </span>
                              <span
                                style={{
                                  display: "block",
                                  fontSize: "10px",
                                }}
                              >
                                {data?.created_on && (
                                  <>
                                    Claim initiated on:{" "}
                                    {moment(data.created_on).format(
                                      "MMM DD, yy"
                                    )}{" "}
                                    ({days_difference} day(s) ago)
                                  </>
                                )}
                              </span>
                            </>
                          ) : data.GBP_status == "Ignore" ? (
                            <>
                              <span
                                style={{
                                  marginLeft: "30px",
                                  marginBottom: "20px",
                                }}
                              >
                                <button
                                  className="btn btn-success mr-2"
                                  // onClick={() => claimAddress(data)}
                                  onClick={() =>
                                    navigate(
                                      `/claim/claimBusiness/claimPrimary/${data.id}`,
                                      {
                                        state: {
                                          auditClaimData: data,
                                          nextLocationsList: nextLocationsList,
                                        },
                                      }
                                    )
                                  }
                                >
                                  Claim
                                </button>
                                <button
                                  className="btn btn-warning mr-2"
                                  onClick={() => clickIgnore(data)}
                                >
                                  Ignore
                                </button>
                                {/* <button
                                className="btn btn-danger mr-2"
                                onClick={() => claimAddress(data)}
                              >
                                Delete
                              </button>
                              <button
                                className="btn btn-primary"
                                onClick={() => claimAddress(data)}
                              >
                                Merge
                              </button> */}
                              </span>
                              <span
                                style={{
                                  display: "block",
                                  fontSize: "10px",
                                }}
                              >
                                {data?.created_on && (
                                  <>
                                    Ignored on:{" "}
                                    {moment(data.created_on).format(
                                      "MMM DD, yy"
                                    )}{" "}
                                    ({days_difference} day(s) ago)
                                    {/* {() => {
                                      let date1 = new Date();
                                      let date2 = new Date(data.created_on);
                                      var time_difference =
                                        date2.getTime() - date1.getTime();
                                      var days_difference =
                                        time_difference / (1000 * 60 * 60 * 24);
                                      return days_difference;
                                    }} */}
                                  </>
                                )}
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                style={{
                                  marginLeft: "30px",
                                  marginBottom: "20px",
                                }}
                              >
                                <button
                                  className="btn btn-success mr-2"
                                  onClick={() =>
                                    navigate(
                                      `/claim/claimBusiness/claimPrimary/${data.id}`,
                                      {
                                        state: {
                                          auditClaimData: data,
                                          nextLocationsList: nextLocationsList,
                                        },
                                      }
                                    )
                                  }
                                >
                                  Claim
                                </button>
                                <button
                                  className="btn btn-warning mr-2"
                                  onClick={() => clickIgnore(data)}
                                >
                                  Ignore
                                </button>
                              </span>
                            </>
                          )}
                        </td>
                        <td className="col-2">
                          {console.log("googledata",data)}
                          {data.GBP_status == "Claimed" && (
                            <a
                              href={
                                "https://business.google.com/n/" +
                                data.google_location_id +
                                "/profile?hl=en-GB&fid=" +
                                data.maps_url?.split("=")[1]
                              }
                              // href={data.maps_url}
                              target="_blank"
                            >
                              <button className="btn btn-outline-secondary">
                                <img src={GoogleIcon} /> See your location
                              </button>
                            </a>
                          )}
                        </td>
                        {/* {data.GBP_status ? (
                        <span className="alert_bussiness text-success ">
                          <Icon.AlertCircle className="mr-1" />
                          {data.GBP_status}
                        </span>
                      ) : (
                        ""
                      )} */}
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <tr id="nodata">
                  <div className="content-sub-wrapper col-md-12">
                    <div className="content-sub">
                      <p>No Data Found</p>
                    </div>
                  </div>
                </tr>
              )}
            </div>
          </table>
        </div>
      </div>
      {showPopup == true && (
        <div className="module_popup inner_module_popup_div">
          <div className="module_innerpopup">
            <div className="module_close">
              <Icon.XCircle
                onClick={() => {
                  setShowPopup(false);
                  document.getElementById("moreOptionsSelect").value = "0";
                }}
              />
            </div>
            <div className="module_header text-center">Create New Business</div>
            {/* <button
            type="button"
            className="btn btn-save mb-1 gobutton_position"
            onClick={() => closeHandler()}
          >
            X
          </button> */}
            <div className="module_body">
              <div className="module_popupsearch_wrpr">
                <div className="module_popupsearch">
                  <div className="container-fluid">
                    <div className="user_formdiv" id="createLocationForm">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              {" "}
                              Store Code
                            </label>
                            <input
                              type="text"
                              onChange={(e) => setStoreCode(e.target.value)}
                              // value={firstName}
                              className="form-control"
                              id="firstName"
                              aria-describedby="emailHelp"
                              placeholder="Store Code"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Business Name
                            </label>
                            <input
                              type="text"
                              onChange={(e) => {
                                setBusinessName(e.target.value);
                                setDescription(e.target.value);
                              }}
                              // value={firstName}
                              className="form-control"
                              id="firstName"
                              aria-describedby="emailHelp"
                              placeholder="Name"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Business Description
                            </label>
                            <input
                              // value={description}
                              value={Description}
                              onChange={(e) => setDescription(e.target.value)}
                              className="form-control"
                              id="emailId"
                              aria-describedby="emailHelp"
                              placeholder="Description"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Business Category
                            </label>
                            <span className="location_search">
                              <input
                                className="form-control"
                                type="text"
                                id="LocationSelectedCategory"
                                // value={selectedCategory?.displayName}
                                onChange={(e) => filterCategory(e)}
                                placeholder="Category"
                              />
                              {filteredCategories.length > 0 && (
                                <div className="autocomplete__list location_search_drop">
                                  <ul
                                    id="categoryListDropdown"
                                    class="nav flex-column"
                                  >
                                    {filteredCategories.map((data) => (
                                      <li
                                        key={data.name}
                                        onMouseDown={() => {
                                          setSelectedCategory(data);
                                          $("#LocationSelectedCategory").val(
                                            data.displayName
                                          );
                                        }}
                                      >
                                        {data.displayName}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </span>
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Phone No</label>
                            <input
                              type="text"
                              className="form-control"
                              id="phoneNo"
                              // value={phone}
                              onChange={(e) => setPhoneNo(e.target.value)}
                              aria-describedby="emailHelp"
                              placeholder="Phone No"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Administrative Area (State)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="phoneNo"
                              // maxLength={10}
                              value={AdminstriveArea}
                              onChange={(e) => {
                                setAdminstriveArea(e.target.value);
                              }}
                              aria-describedby="emailHelp"
                              placeholder=" Administrative Area"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Locality (District)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="phoneNo"
                              // maxLength={10}
                              value={Locality}
                              onChange={(e) => {
                                setLocality(e.target.value);
                              }}
                              aria-describedby="emailHelp"
                              placeholder="Locality"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Address</label>
                            <textarea
                              type="text"
                              className="form-control"
                              id="phoneNo"
                              // maxLength={10}
                              value={Address}
                              onChange={(e) => {
                                setAddress(e.target.value);
                              }}
                              // value={address}
                              aria-describedby="emailHelp"
                              placeholder="Address"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              {" "}
                              Postal Code
                            </label>
                            <input
                              type="text"
                              onChange={(e) => setPostalCode(e.target.value)}
                              // value={postalCode}
                              className="form-control"
                              id="firstName"
                              aria-describedby="emailHelp"
                              placeholder="Postal Code"
                            />
                          </div>
                        </div>
                        <div className="col-md-8">
                          <GoogleMap
                            key={mapRenderKey}
                            longitude={longitude}
                            latitude={latitude}
                            googleAddressPin={googleAddressPin}
                            changeHandler={changeHandler}
                            setGoogleAddressPin={(googleAddressPin) => {
                              setGoogleAddressPin(googleAddressPin);
                              // setAddress(googleAddressPin);
                              // splitAddressFunction(googleAddressPin);
                            }}
                            selectGoogleAddressPin={(
                              googleAddressPin,
                              lat,
                              lng
                            ) => {
                              console.log(
                                googleAddressPin,
                                "googleAddressPingoogleAddressPin"
                              );
                              // splitAddressFunction(googleAddressPin);
                              setGoogleAddressPin(googleAddressPin);
                              // setAddress(googleAddressPin);
                              setLatitude(lat);
                              setLongitude(lng);
                            }}
                            onMarkerDragEnd={(lat, lng) => {
                              setLatitude(lat);
                              setLongitude(lng);
                            }}
                          />
                          <div className="row mt-2">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  {" "}
                                  Latitude
                                </label>
                                <input
                                  type="text"
                                  onChange={(e) => setLatitude(e.target.value)}
                                  // value={postalCode}
                                  className="form-control"
                                  id="firstName"
                                  value={latitude}
                                  aria-describedby="emailHelp"
                                  placeholder="Name"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  {" "}
                                  Longitude
                                </label>
                                <input
                                  type="text"
                                  onChange={(e) => setLongitude(e.target.value)}
                                  // value={postalCode}
                                  className="form-control"
                                  id="firstName"
                                  value={longitude}
                                  aria-describedby="emailHelp"
                                  placeholder="Name"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form_bttn">
                            <button
                              // type="submit"
                              className="btn btn-primary submitButton saveListng"
                              onClick={(e) => saveLocation(e)}
                            >
                              <span>Save and Proceed</span>
                            </button>
                            {/* <Link
                        to="/claimBusiness"
                        className="btn btn-primary submitButton cancel_bttn"
                      >
                        <span>Cancel</span>
                      </Link> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {totalStatusCount > 25 && (
        <div className="pagination_main">
          <Pagination
            hideDisabled
            activePage={currentPage}
            itemsCountPerPage={50}
            totalItemsCount={totalStatusCount}
            onChange={(e) => handleActivePage(e)}
          />
        </div>
      )}
      <div className="loder_div" hidden />
    </div>
  );
}
export default FromAudit;
