import React, { useEffect, useState } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import QandA from "../images/left_icons/qa.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-feather";
import { backendApi } from "../apiService";
import Switch from "react-switch";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Select from "react-select";
import $ from "jquery";

function AddCampaign() {
  const navigate = useNavigate();
  const location = useLocation();
  const [privateData, setPrivateData] = useState([]);
  const [publicData, setPublicData] = useState([]);
  const [campaignName, setCampaignName] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newQuestion, setNewQuestion] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [siteQuestion, setSiteQuestion] = useState([]);
  const [publicQuestion, setPublicQuestion] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [campaignDetails, setCampaignDetails] = useState("");
  const[campaignQuestions,setCampaignQuestions]=useState([]);

  useEffect(() => {
    $(document).ready(function() {
      // Hide all submenus initially
      $(".subMenubar.Creatify").show();
      $(".req-gather").addClass('active');
      $(".req-gather")[0].scrollIntoView();
       });
    campaignInit();
    campaignData();
    campaignHistory();
    if (!Object.is(location.state, null)) {
      const { campaignData } = location.state;
      handleCampaignSelect({
        value: campaignData.id,
        label: campaignData.campaign_name,
      });
    }
  }, []);

  const campaignInit = () => {
    const postData = {
      functionName: "campaign_init",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          const sortedPrivateDataList = data.privateData.sort((a, b) => b.id - a.id);
          const sortedPublicDataList = data.publicData.sort((a, b) => b.id - a.id);
          setPrivateData(sortedPrivateDataList);
          setPublicData(sortedPublicDataList);
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };
  const campaignData = () => {
    const postData = {
      functionName: "campaign_Listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          console.log("SAdfafsf", data);
          setCampaigns(data.dataSet);
          const transformedOptions = data.dataSet
            .filter((campaign) => campaign.is_active === 1)
            .map((campaign, index) => ({
              value: campaign.id,
              label: campaign.campaign_name,
            }));
          setCampaignOptions(transformedOptions);
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };

  const campaignHistory = () => {
    const postData = {
      functionName: "campaign_question_listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          console.log("SAdfafsf", data);
          setPublicQuestion(data.publicQuestion);
          setSiteQuestion(data.siteQuestion);
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };

  const campaignSave = () => {
    if (campaignName === "") {
      alert("Please enter a campaign name");
      return false;
    }
    const newQuestionIds = selectedIds.filter(
      (id) =>
        !campaignQuestions.some(
          (question) => question.campaign_questions_master_id === parseInt(id)
        )
    );
    console.log("campD",campaignDetails);
    const postData = {
      functionName: "generateQuestionForCampaign",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      created_by:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      campaign_id: campaignName.value,
      question_ids: newQuestionIds,
    };
    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          navigate("/requirement");
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };

  const handleCheckboxChange = (id) => {
    console.log("data", id);
    const isSelected = selectedIds.includes(String(id));

    if (isSelected) {
      setSelectedIds((prevIds) =>
        prevIds.filter((prevId) => prevId !== String(id))
      );
    } else {
      setSelectedIds((prevIds) => [...prevIds, String(id)]);
    }
  };

  const showModalClick = () => {
    setShowModal(true);
    document.body.className = "overflow-hidden";
  };

  const closePopup = () => {
    setShowModal(false);
    setNewQuestion("");
    setIsPublic(false);
    document.body.className = "";
  };
  const back = () => {
    navigate("/requirement");
  };

  const questionSave = () => {
    if (newQuestion.trim() === "") {
      alert("Please enter a question");
      return false;
    }
    var postData;
    let question=newQuestion.replace(/['"]/g, "\\$&");
    if (isPublic === true) {
      postData = {
        functionName: "campaign_add_question",
        site_id: "",
        question_text: question,
        is_public: "1",
      };
    } else {
      postData = {
        functionName: "campaign_add_question",
        site_id:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        created_by:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
        question_text: question,
        is_public: "0",
      };
    }

    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          alert('Question saved succesfully')
          closePopup();
          campaignInit();
          campaignHistory();
          setNewQuestion("");
          setIsPublic(false);
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };

  const questionStatus = () => {
    setIsPublic(!isPublic);
  };

  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  const statusChanging = (data, activeCase) => {
    let postData = {
      functionName: "campaign_question_activeDeactive",
      is_active: activeCase,
      id: data.id,
    };
    backendApi(postData)
      .then((response) => {
        if (response?.success === "1") {
          campaignInit();
          campaignHistory();
        }
      })
      .catch((error) => {
        console.error("Error fetching version list:", error);
      });
  };

  const handleCampaignSelect = (selectedOption) => {
    console.log("🚀 ~ handleCampaignSelect ~ selectedOption:", selectedOption);
    setCampaignName(selectedOption);
    const selectedCampaign = campaigns.find(
      (camp) => camp.id === selectedOption.value
    );
    console.log("sc", selectedCampaign);
    setCampaignDetails(selectedCampaign);
    getCampaignQuestions(selectedOption.value);
  };

  const getCampaignQuestions=(campId)=>{
    const postData = {
      functionName: "campaign_question_listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      campaign_id:campId
    };
    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          setCampaignQuestions(data.campaign_question)
          setSelectedIds(
            data?.campaign_question.map(
              (question) => question.campaign_questions_master_id.toString()
            ) || []
          );
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };

  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Requirement gathering" headerImage={QandA} />
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex align-items-center py-2">
                  <div className="summery-heading">Create Requirement</div>
                  <button
                    className="btn btn-primary ml-auto"
                    onClick={() => showModalClick()}
                  >
                    Add New Question
                  </button>
                </div>
              </div>
              <div className="col-md-8">
                <div className="mt-1">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Campaign
                      <span className="red ml-1">*</span>{" "}
                    </label>
                    <Select
                      name="colors"
                      placeholder="Select Campaign"
                      options={campaignOptions}
                      value={campaignName}
                      onChange={handleCampaignSelect}
                      // onKeyDown={(event) => insertNewCampaign(event)}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 col-lg-8 col-10">
                <div className="campaign-box">
                  {privateData?.map((data, i) => (
                    <div key={i} className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`customCheckPrivate${i}`}
                        onChange={() => handleCheckboxChange(data.id)}
                        checked={selectedIds.includes(String(data.id))}
                      />
                      <label
                        className="custom-control-label campaign_label"
                        htmlFor={`customCheckPrivate${i}`}
                      >
                        {data.question_text}
                      </label>
                    </div>
                  ))}
                  {publicData?.map((data, i) => (
                    <div key={i} className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`customCheckPublic${i}`}
                        onChange={() => handleCheckboxChange(data.id)}
                        checked={selectedIds.includes(String(data.id))}
                      />
                      <label
                        className="custom-control-label campaign_label"
                        htmlFor={`customCheckPublic${i}`}
                      >
                        {data.question_text}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-8 mt-3 text-right">
              <button
                type="submit"
                class="btn btn-secondary mr-2"
                onClick={() => back()}
              >
                Cancel
              </button>
              <button
                className="btn btn-success"
                onClick={() => campaignSave()}
              >
                Save
              </button>
            </div>
            <br />
          </div>
        </div>
      </div>
      {showModal && (
        <div className="adv_modal">
          <div className="adv_modal_inner ">
            <div className="adv_modal_header d-flex align-itesm-center">
              <h3 class="image-heading">Add Questions</h3>
              <button
                type="button"
                className="close ml-auto"
                onClick={() => closePopup()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <Tabs>
              <TabList onClick={() => setEditingIndex(-1)}>
                <Tab>Add Question</Tab>
                <Tab>History</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col-md-8">
                    <div className="form-group mb-2 mb-md-0">
                      <label for="exampleInputEmail1">Add Question</label>
                      <span className="red ml-1">*</span>{" "}
                      <div className="d-md-flex">
                        <input
                          type="text"
                          name="festivalName"
                          id="festivalName"
                          onChange={(e) => setNewQuestion(e.target.value)}
                          className="form-control"
                          value={newQuestion}
                          placeholder="Add Question"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group mb-2 mb-md-0">
                      <label for="exampleInputEmail1">Visible to all</label>
                      <div className="d-md-flex">
                        <Switch
                          className="switch_case"
                          onChange={(e) => questionStatus(e)}
                          value={isPublic}
                          checked={isPublic}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-right">
                  <button
                    type="submit"
                    class="btn btn-success mr-2"
                    onClick={questionSave}
                  >
                    Save
                  </button>
                  <button
                    type="submit"
                    class="btn btn-secondary"
                    onClick={() => closePopup()}
                  >
                    Cancel
                  </button>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="col-md-12 tableResponsive tagImageEditrListTble ">
                  <div className="tableOuterWidth table_width_md_sm">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="col-1">Sl </th>
                          <th className="col-7">Question</th>
                          {/* <th className="col-2 ">Description</th> */}
                          <th className="col-2">Status</th>
                          <th className="col-2">Created Date</th>
                        </tr>
                      </thead>
                      <div className="table_Outerscroll tableheight">
                        <div>Campaign Questions</div>
                        {siteQuestion?.map((data, i) => (
                          <tbody className="table_Outertbody">
                            <tr>
                              <td className="col-1">{i + 1}</td>
                              <td className="col-7">
                                <span>{data.question_text}</span>
                              </td>
                              <td className="col-2">
                                <Switch
                                  className="switch_case"
                                  checked={data.is_active === 1}
                                  onChange={(checked) => {
                                    statusChanging(
                                      data,
                                      data.is_active === 1 ? 0 : 1
                                    );
                                  }}
                                ></Switch>
                              </td>
                              <td className="col-2">
                                <div className="d-flex align-items-center">
                                  {formatDate(data.created_on)}
                                  {/* <div className="mt-2 ml-3">
                                        <button
                                        //   onClick={() =>
                                        //     handleUpdateClick(i, data)
                                        //   }
                                          className="btn btn-success"
                                        >
                                          Save
                                        </button>
                                        <button
                                          onClick={handleCancelClick}
                                          className="btn btn-secondary ml-1"
                                        >
                                          Cancel
                                        </button>
                                      </div> */}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                        <div>Public Questions</div>
                        {publicQuestion?.map((data, i) => (
                          <tbody className="table_Outertbody">
                            <tr>
                              <td className="col-1">{i + 1}</td>
                              <td className="col-7">
                                <span>{data.question_text}</span>
                              </td>
                              <td className="col-2">
                                <Switch
                                  className="switch_case"
                                  checked={data.is_active === 1}
                                  onChange={(checked) => {
                                    statusChanging(
                                      data,
                                      data.is_active === 1 ? 0 : 1
                                    );
                                  }}
                                ></Switch>
                              </td>
                              <td className="col-2">
                                <div className="d-flex align-items-center">
                                  {formatDate(data.created_on)}
                                  {/* <div className="mt-2 ml-3">
                                        <button
                                        //   onClick={() =>
                                        //     handleUpdateClick(i, data)
                                        //   }
                                          className="btn btn-success"
                                        >
                                          Save
                                        </button>
                                        <button
                                          onClick={handleCancelClick}
                                          className="btn btn-secondary ml-1"
                                        >
                                          Cancel
                                        </button>
                                      </div> */}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </div>
                    </table>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      )}
      <div className="loder_div" hidden />
    </div>
  );
}

export default AddCampaign;
