import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import LeftMenu from "../layout/leftMenu";
import Lock from "../images/left_icons/lock2.png";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { FiEye } from "react-icons/fi";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import $ from "jquery";
import * as Icon from "react-feather";

import WaitingApproval from "./components/waitingApproval";
import { backendApi } from "../apiService";
import { GoPencil } from "react-icons/go";
// import Loader from "../images/ideas.jpg";

function Approval() {
  const navigate = useNavigate();
  const [groupList, setGroupList] = useState([]);
  const [waitingCount, setWaitingCount] = useState("");
  const [approvedTextListCount, setApprovedTextListCount] = useState("");
  const [approvedPlanningListCount, setApprovedPlanningListCount] =
    useState("");
  const [approvedRequirementListCount, setApprovedRequirementListCount] =
    useState("");
  const [approvedImageListCount, setApprovedImageListCount] = useState("");
  const [approvedMediaListCount, setApprovedMediaListCount] = useState("");
  const [rejectedListCount, setRejectedListCount] = useState("");
  window.$(document).ready(function () {
    window.$(".popup-image").magnificPopup({
      delegate: "a",
      type: "image",
      tLoading: "Loading image #%curr%...",
      mainClass: "mfp-img-mobile",
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1], // Will preload 0 - b*9efore current, and 1 after the current image
      },
      image: {
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        titleSrc: function (item) {
          return item.el.attr("title") + "<small></small>";
        },
      },
    });
    window.$(".popup-video").magnificPopup({
      delegate: "a",
      type: "iframe",
      tLoading: "Loading...",
    });
  });

  const [waitingList, setWaitingList] = useState([]);
  const [approvedTextList, setApprovedTextList] = useState([]);
  const [approvedImageList, setApprovedImageList] = useState([]);
  const [approvedMediaList, setApprovedMediaList] = useState([]);
  const [approvedPlanningList, setApprovedPlanningList] = useState([]);
  const [approvedRequirementList, setApprovedRequirementList] = useState([]);
  const [rejectedList, setRejectedList] = useState([]);
  const [waitingMessage, setWaitingMessage] = useState("Waiting for Data");

  useEffect(() => {
    getWaitingList();
    getApprovedText();
    getApprovedImage();
    getApprovedMedia();
    getApprovedPlanning();
    getApprovedRequirement();
    getRejectedList();
    getContentGroupList();
  }, []);

  const getContentGroupList = () => {
    const api = {
      functionName: "content_request_group",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      sec_user_id:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };
    backendApi(api).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        let totalCountWaiting = 0;
        data.dataList.forEach((item) => {
          item.status_cnt.forEach((statusItem) => {
            if (statusItem.req_status === "Waiting") {
              totalCountWaiting += statusItem.cnt;
            }
          });
        });

        setWaitingCount(totalCountWaiting);
        setGroupList(data.dataList);
      } else {
        console.error("🚀 ~ ", data);
      }
    });
  };

  const getWaitingList = () => {
    $(".loder_div").attr("hidden", false);
    const api = {
      functionName: "content_request_Listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      // sec_user_id:
      //   window.sessionStorage.getItem("switchUserId") === ""
      //     ? window.sessionStorage.getItem("userId")
      //     : window.sessionStorage.getItem("switchUserId"),
      category_type: "",
      req_status: "Waiting",
    };
    backendApi(api).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        setWaitingList(data.dataList);
        setWaitingMessage("");
      } else {
        alert("Something went wrong getting waiting list");
        setWaitingMessage("No Data Available");
        console.error("🚀 ~ ", data);
        setWaitingList([]);
      }
    });
  };
  const getApprovedText = () => {
    const api = {
      functionName: "content_request_Listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      // sec_user_id:
      //   window.sessionStorage.getItem("switchUserId") === ""
      //     ? window.sessionStorage.getItem("userId")
      //     : window.sessionStorage.getItem("switchUserId"),
      category_type: "Text",
      req_status: "Approved",
    };
    backendApi(api).then((data) => {
      if (data.success === "1") {
        setApprovedTextList(data.dataList);
        setApprovedTextListCount(data.dataList.length);
      } else {
        alert("Something went wrong getting approved text list");
        console.error("🚀 ~ ", data);
        setApprovedTextList([]);
      }
    });
  };

  const getApprovedImage = () => {
    const api = {
      functionName: "content_request_Listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      sec_user_id:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      category_type: "Image",
      req_status: "Approved",
    };
    backendApi(api).then((data) => {
      if (data.success === "1") {
        setApprovedImageList(data.dataList);
        setApprovedImageListCount(data.dataList.length);
      } else {
        alert("Something went wrong getting approved image list");
        console.error("🚀 ~ ", data);
        setApprovedImageList([]);
      }
    });
  };
  const getApprovedMedia = () => {
    const api = {
      functionName: "content_request_Listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      category_type: "Media",
      req_status: "Approved",
    };
    backendApi(api).then((data) => {
      if (data.success === "1") {
        setApprovedMediaList(data.dataList);
        setApprovedMediaListCount(data.dataList.length);
      } else {
        alert("Something went wrong getting waiting list");
        console.error("🚀 ~ ", data);
        setApprovedMediaList([]);
      }
    });
  };

  const getApprovedPlanning = () => {
    const api = {
      functionName: "content_request_Listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      // sec_user_id:
      //   window.sessionStorage.getItem("switchUserId") === ""
      //     ? window.sessionStorage.getItem("userId")
      //     : window.sessionStorage.getItem("switchUserId"),
      category_type: "Planning",
      req_status: "Approved",
    };
    backendApi(api).then((data) => {
      if (data.success === "1") {
        setApprovedPlanningList(data.dataList);
        setApprovedPlanningListCount(data.dataList.length);
      } else {
        alert("Something went wrong getting waiting list");
        console.error("🚀 ~ ", data);
        setApprovedPlanningList([]);
      }
    });
  };

  const getApprovedRequirement = () => {
    const api = {
      functionName: "content_request_Listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      // sec_user_id:
      //   window.sessionStorage.getItem("switchUserId") === ""
      //     ? window.sessionStorage.getItem("userId")
      //     : window.sessionStorage.getItem("switchUserId"),
      category_type: "Question",
      req_status: "Approved",
    };
    backendApi(api).then((data) => {
      if (data.success === "1") {
        setApprovedRequirementList(data.dataList);
        setApprovedRequirementListCount(data.dataList.length);
      } else {
        alert("Something went wrong getting waiting list");
        console.error("🚀 ~ ", data);
        setApprovedRequirementList([]);
      }
    });
  };

  const getRejectedList = () => {
    const api = {
      functionName: "content_request_Listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      category_type: "",
      req_status: "Rejected",
    };
    backendApi(api).then((data) => {
      if (data.success === "1") {
        setRejectedList(data.dataList);
        setRejectedListCount(data.dataList.length);
      } else {
        alert("Something went wrong getting waiting list");
        console.error("🚀 ~ ", data);
        setRejectedList([]);
      }
    });
  };
  const deleteContent = (data) => {
    const api = {
      functionName: "content_request_delete",
      content_generation_request_id: data.id,
      sec_user_id:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };
    backendApi(api).then((data) => {
      if (data.success === "1") {
        alert("Deleted Successfully!!");
        getWaitingList();
        getApprovedText();
        getApprovedImage();
        getApprovedMedia();
        getRejectedList();
      } else {
        alert("Something went wrong");
      }
    });
  };

  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Approval Dashboard" headerImage={Lock} />
        <div className="col-md-12 tabs-main-wrapper">
          <Tabs>
            <TabList>
              <Tab>
                Waiting For Approval
                <span className="waitingTxt">{waitingCount}</span>
              </Tab>
              <Tab>
                Approved Requirement Gathering List
                <span className="waitingTxt">
                  {approvedRequirementListCount}
                </span>
              </Tab>
              <Tab>
                Approved Planning List
                <span className="waitingTxt">{approvedPlanningListCount}</span>
              </Tab>
              <Tab>
                Approved Text List
                <span className="waitingTxt">{approvedTextListCount}</span>
              </Tab>
              <Tab>
                Approved Image List
                <span className="waitingTxt">{approvedImageListCount}</span>
              </Tab>
              <Tab>
                Approved Media List
                <span className="waitingTxt">{approvedMediaListCount}</span>
              </Tab>
              <Tab>
                All Rejected List
                <span className="rejectedTxt">{rejectedListCount}</span>
              </Tab>
            </TabList>

            <TabPanel>
              <div className="col-md-12 tableResponsive tagImageEditrListTble mt-4">
                <div className="tableOuterWidth table_width_md">
                  {groupList && groupList.length > 0 ? (
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="col-1">Id </th>
                          <th className="col-4">Campaign</th>
                          <th className="col-2">Total Count</th>
                          <th className="col-3 ">Waiting</th>
                          <th className="col-2 ">Actions</th>
                        </tr>
                      </thead>
                      {groupList?.map((data, i) => {
                        return (
                          <div className="table_Outerscroll table_width_md">
                            <tbody className="table_Outertbody pb-3">
                              <tr key={i}>
                                <td className="col-1">{i + 1}</td>
                                <td className="col-4">
                                  <span>{data.content_campaign_name}</span>
                                </td>
                                <td className="col-2">
                                  {data.status_cnt.map((category, j) => (
                                    <p key={j}>
                                      <b>{category.req_status}</b>:{" "}
                                      {category.cnt}
                                      {/* {j !== data.category_cnt.length - 1 &&
                                        ", "} */}
                                    </p>
                                  ))}
                                </td>
                                <td className="col-3">
                                  {data.category_cnt.map((category, j) => (
                                    <p key={j}>
                                      <b>{category.category_type}</b>:{" "}
                                      {category.cnt}
                                      {/* {j !== data.category_cnt.length - 1 &&
                                        ", "} */}
                                    </p>
                                  ))}
                                  {/* {data.status_cnt.map((status, k) => (
                                    <span key={k}>
                                      {status.req_status}: {status.cnt}
                                      {k !== data.status_cnt.length - 1 &&
                                        ", "}
                                    </span>
                                  ))} */}
                                </td>
                                <td className="col-2">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() =>
                                      navigate(`/viewCampaignList`, {
                                        state: {
                                          props: data.campaign_id,
                                          campaignName:data.content_campaign_name
                                        },
                                      })
                                    }
                                  >
                                    View
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </div>
                        );
                      })}
                    </table>
                  ) : (
                    <div className="message-common">No waiting data</div>
                  )}

                  <div id="editPopup" className="module_popup" hidden>
                    <div className="module_innerpopup">
                      <div className="module_close">
                        <Icon.XCircle
                          onClick={() => {
                            $("#editPopup").attr("hidden", true);
                            $(".modules_div_org").removeClass("active");
                            $("#orgainzationSelectAll").prop("checked", false);
                          }}
                        />
                      </div>
                      <div className="userListmodal_head">
                        <span className="subhead">Share To</span>
                      </div>
                      <div className="module_body">
                        <div className="module_popupsearch_wrpr">
                          <div className="userlistmodal_body">
                            <input
                              type="text"
                              className="form-control m-0"
                              placeholder="user's name"
                            />
                          </div>
                          <div className="module_popupsearchBtn">
                            <button
                              type="button"
                              className="btn btn-primary"
                              // onClick={() => this.saveOrgAllotment()}
                            >
                              Share
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <WaitingApproval
                key={waitingList}
                arrayList={waitingList}
                callData={getWaitingList}
                waitingMessage={waitingMessage}
                approveContent={approveContent}
                rejectContent={rejectContent}
              /> */}
            </TabPanel>

            <TabPanel>
              <WaitingApproval
                key={approvedRequirementList}
                arrayList={approvedRequirementList}
                callData={getApprovedRequirement}
                type="Approved"
                deleteContent={deleteContent}
              />
            </TabPanel>

            <TabPanel>
              <WaitingApproval
                key={approvedPlanningList}
                arrayList={approvedPlanningList}
                callData={getApprovedPlanning}
                type="Approved"
                deleteContent={deleteContent}
              />
            </TabPanel>

            <TabPanel>
              <WaitingApproval
                key={approvedTextList}
                arrayList={approvedTextList}
                callData={getApprovedText}
                deleteContent={deleteContent}
              />
            </TabPanel>

            <TabPanel>
              <WaitingApproval
                key={approvedImageList}
                arrayList={approvedImageList}
                callData={getApprovedImage}
                type="Waiting"
                deleteContent={deleteContent}
              />
            </TabPanel>

            <TabPanel>
              <WaitingApproval
                key={approvedMediaList}
                arrayList={approvedMediaList}
                callData={getApprovedMedia}
                type="Approved"
                deleteContent={deleteContent}
              />
            </TabPanel>

            <TabPanel>
              <WaitingApproval
                key={rejectedList}
                arrayList={rejectedList}
                callData={getRejectedList}
                type="Rejected"
                deleteContent={deleteContent}
              />
            </TabPanel>
          </Tabs>
        </div>

        <div className="loder_div" hidden />
        <Footer />
      </div>
    </div>
  );
}

export default Approval;
