import "../../css/bootstrap.css";
import "../../css/style.css";
import "../../css/responsive.css";
import { useState } from "react";
import React from "react";
import { User, Lock, Navigation, PhoneOutgoing, Mail } from "react-feather";
import { backendApi, re } from "../../apiService";
import Loader from "../../images/loader.gif";
import $ from "jquery";
import Logo from '../../images/logo_login.png'
import LogoSub from '../../images/tagtree_sub.png'
function SignupNew(props) {
  const [showOTPform, setShowOTPform] = useState(false);
  const [showOTPRedirect, setShowOTPRedirect] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [tempUserId, setTempUserId] = useState("");
  const [organization, setOrganization] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [phoneOTP, setPhoneOTP] = useState("");
  const [emailOTP, setEmailOTP] = useState("");
  //   const [address, setAddress] = useState("");

  // const generateOTP = () => {
  //   let randomPhoneOTP = Math.floor(Math.random() * (999999 - 100000 + 1) + 100000)
  //   let randomEmailOTP = Math.floor(Math.random() * (999999 - 100000 + 1) + 100000)
  //   var req = {
  //     method: "POST",
  //     body: JSON.stringify({}),
  //   };
  // }
  const newUserRegistration = (e) => {
    // console.log(email, " ", password, " ", address, " ", phone);
    e.preventDefault();
    if (firstName === "") {
      alert("Enter First Name please");
      return false;
    } else if (lastName === "") {
      alert("Enter Last name please");
      return false;
    } else if (email === "") {
      alert("Enter Email-Id please");
      return false;
    } else if (!re.test(email)) {
      alert("Enter Valid Organization Email-Id please");
      return false;
    } else if (phone === "") {
      alert("Enter user Phone Number please");
      return false;
    }
    var emailExist = false;
    let organizationEmail = email.split("@");
    organizationEmail = organizationEmail[1].split(".");
    var chkEmail = {
      emailId: email,
      functionName: "checkEmailId",
    };
    $(".loder_div").attr("hidden", false);
    backendApi(chkEmail).then((response) => {
      if (response.success === "1") {
        if (response.user_available === false) {
          alert("Email Id already exist");
          $(".loder_div").attr("hidden", true);
          return false;
        } else {
          var req = {
            firstName: firstName,
            lastName: lastName,
            organization: organizationEmail[0],
            emailId: email,
            mobileNo: phone,
            functionName: "createNewUser",
          };
          setShowOTPform(true);
          backendApi(req).then((response) => {
            if (response.success === "1") {
              setTempUserId(response.userId);
              var otpReq = {
                userId: response.userId,
                functionName: "getOTP",
              };
              backendApi(otpReq).then((otpResponse) => {
                $(".loder_div").attr("hidden", true);
                setShowOTPform(true);
              });
            }
          });
        }
      }
    });
  };
  const getOTP = () => {
    $(".loder_div").attr("hidden", false);
    var otpReq = {
      userId: tempUserId,
      functionName: "getOTP",
    };
    backendApi(otpReq).then((otpResponse) => {
      $(".loder_div").attr("hidden", true);
    })
  }
  const verifyOTP = (e) => {
    e.preventDefault();
    if (phoneOTP === "") {
      alert("Enter OTP sent to Phone");
      return false;
    } else if (emailOTP === "") {
      alert("Enter OTP sent to Email Id");
      return false;
    }
    $(".loder_div").attr("hidden", false);
    var otpReq = {
      userId: tempUserId,
      mobileOTP: phoneOTP,
      emailOTP: emailOTP,
      functionName:"verifyOTP"
    };
    $(".loder_div").attr("hidden", false);
    backendApi(otpReq).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        setShowOTPRedirect(true);
      }
    });
  };

  return (
    <div className="innter_loginright">
      {showOTPform === false ? (
        <form>
        <div className="login_headlogo">
           <div className="tagtree_logodiv">
            <img className="tagtree_logoimg" src={Logo}/>            
           </div>      
           <div className="tagtree_logospan">
              <img src={LogoSub}/>
            </div>      
          </div>
          <h1 className="login_head">Registration</h1>
          <div className="form_group">
            {/* <User /> */}
            <label>First Name</label>
            <input
              type="text"
              placeholder="First Name"
              onChange={(event) => setFirstName(event.target.value)}
            />
          </div>
          <div className="form_group">
            {/* <User /> */}
            <label>Last Name</label>
            <input
              type="text"
              placeholder="Last Name"
              onChange={(event) => setLastName(event.target.value)}
            />
          </div>
          <div className="form_group">
            {/* <Mail /> */}
            <label>Email ID</label>
            <input
              type="text"
              placeholder="Email ID"
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className="form_group">
            {/* <PhoneOutgoing /> */}
            <label>Phone No</label>
            <input
              type="text"
              placeholder="Phone No"
              onChange={(event) => setPhone(event.target.value)}
            />
          </div>
          <div className="login_submit">
            <button type="button" onClick={newUserRegistration}>
              Sign Up
            </button>
          </div>
          <div className="login_submit">
            <span type="button" onClick={() => props.newMember(false)}>
              Already a User?
            </span>
          </div>
        </form>
      ) : showOTPRedirect === false ? (
        <form>
          <div className="login_head">OTP Verification</div>
          <div className="form_group">
            <PhoneOutgoing />
            <input
              type="text"
              placeholder="OTP from Mobile"
              onChange={(event) => setPhoneOTP(event.target.value)}
              value={phoneOTP}
            />
          </div>
          <div className="form_group">
            <Mail />
            <input
              type="text"
              placeholder="OTP from Email"
              onChange={(event) => setEmailOTP(event.target.value)}
              value={emailOTP}
            />
          </div>
          <div className="login_submit">
            <button type="button" onClick={verifyOTP}>
              Submit
            </button>
            <div onClick={()=>getOTP()}> Resend OTP</div>
          </div>

        </form>
      ) : (
        <>
          <form>
            <div className="login_head">OTP Verification</div>
            <div className="form_group">
              OTP verified successfully. Please wait for registration approval.
              Thank You
            </div>
            <div className="form_group">
              <a href="/">Proceed to Login</a>
            </div>
          </form>
        </>
      )}
      <div className="loder_div" hidden />
    </div>
  );
}

export default SignupNew;
