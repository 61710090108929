import React, { Component } from "react";
import Chart from 'react-apexcharts'
// import dayjs from "dayjs";
import {apiCallingPost } from "../apiService";

class AnalyticsUserGraph extends Component {
    constructor(props) {
        super(props);

        this.state = {
        
          series: [],
          options: {},
          xArray:[],
        
        };
      }
      componentDidMount() {
        this.graph();
         console.log("props", this.props)
      }
      graph() {
        const postData = {
          "functionName": "googleAnalyticsUserReport",
          "propertyId": this.props.propertyId
      }
        console.log("graph", postData)
        apiCallingPost(postData).then((data) => {
          console.log("graphdata", data)
          this.setState(
            {           
          series: [
            {
            name: 'User Count',
            data: data.yData
          }
        ],
        options: {
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          grid: {
            show: false
        },
          stroke: {
            show: true,
            width: 1,
            colors: ['transparent']
          },
          xaxis: {
            categories: data.xData,
          },
          yaxis: {
            title: {
              text: 'User Count'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return (val == 1 ? val + " user": val + " users")
              }
            }
          }
        },
              // xArray: data.yData,
             
            })
        });
      }
    
    render() {
      return (
        <div className="graph_insight googleAnalyticsChart">
             <Chart  options={this.state.options} series={this.state.series} type="bar"  height={300} />  
        </div>
      )
    }
  }
export default AnalyticsUserGraph;