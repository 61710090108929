import React, { useEffect, useState } from 'react'
import Car from "../../images/car.png"
import Thar from "../../images/Thar1.png"
import XUV700 from "../../images/XUV700 bhopal1.png"
import XUV3XO from "../../images/XUV3XO english 1.png"
import Bolero from "../../images/Bolero Classic 1.png"
function TopPerforming() {
    const [activeFilter, setActiveFilter] = useState('All');

    const handleFilterClick = (filter) => {
        setActiveFilter(filter);
    };
    return (
        <div className='section-wrapper'>
            <div className="top-perfoming-wrapper">
                <div className="top-performing-head-wrapper">
                    <div className="top-performing-head">
                        <img src={Car} alt="" className='img-fluid' />
                        <span className='top-performing-headText'>Top Performing Vehicles </span><span className="top-performing-greyext">(Top 4)</span>
                    </div>
                    <div className="top-performing-li-right">
                        <div
                            className={`top-performing-lisec ${activeFilter === 'All' ? 'active' : ''}`}
                            onClick={() => handleFilterClick('All')}
                        >
                            All
                        </div>
                        <div
                            className={`top-performing-lisec ${activeFilter === 'PV' ? 'active' : ''}`}
                            onClick={() => handleFilterClick('PV')}
                        >
                            PV
                        </div>
                        <div
                            className={`top-performing-lisec ${activeFilter === 'CV' ? 'active' : ''}`}
                            onClick={() => handleFilterClick('CV')}
                        >
                            CV
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="perform-card-sec">
                            <div className="perform-card-head">Mahindra Thar 4X4</div>
                            <img src={Thar} alt="" className='img-fluid' />
                            <div className="perform-bottomsec">
                                <div className="perform-bottomblock">
                                    <div className="perform-bottomblock-head">Listing</div>
                                    <div className="perform-bottomblock-text">20</div>
                                </div>
                                <div className="perform-bottomblock">
                                    <div className="perform-bottomblock-head">ENQUIRY</div>
                                    <div className="perform-bottomblock-text">20</div>
                                </div>
                                <div className="perform-bottomblock">
                                    <div className="perform-bottomblock-head">RETAIL</div>
                                    <div className="perform-bottomblock-text">20</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="perform-card-sec">
                            <div className="perform-card-head">Mahindra XUV 700</div>
                            <img src={XUV700} alt="" className='img-fluid' />
                            <div className="perform-bottomsec">
                                <div className="perform-bottomblock">
                                    <div className="perform-bottomblock-head">Listing</div>
                                    <div className="perform-bottomblock-text">20</div>
                                </div>
                                <div className="perform-bottomblock">
                                    <div className="perform-bottomblock-head">ENQUIRY</div>
                                    <div className="perform-bottomblock-text">20</div>
                                </div>
                                <div className="perform-bottomblock">
                                    <div className="perform-bottomblock-head">RETAIL</div>
                                    <div className="perform-bottomblock-text">20</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="perform-card-sec">
                            <div className="perform-card-head">Mahindra XUV 3X0</div>
                            <img src={XUV3XO} alt="" className='img-fluid' />
                            <div className="perform-bottomsec">
                                <div className="perform-bottomblock">
                                    <div className="perform-bottomblock-head">Listing</div>
                                    <div className="perform-bottomblock-text">20</div>
                                </div>
                                <div className="perform-bottomblock">
                                    <div className="perform-bottomblock-head">ENQUIRY</div>
                                    <div className="perform-bottomblock-text">20</div>
                                </div>
                                <div className="perform-bottomblock">
                                    <div className="perform-bottomblock-head">RETAIL</div>
                                    <div className="perform-bottomblock-text">20</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="perform-card-sec">
                            <div className="perform-card-head">Mahindra Bolero Classic</div>
                            <img src={Bolero} alt="" className='img-fluid' />
                            <div className="perform-bottomsec">
                                <div className="perform-bottomblock">
                                    <div className="perform-bottomblock-head">Listing</div>
                                    <div className="perform-bottomblock-text">20</div>
                                </div>
                                <div className="perform-bottomblock">
                                    <div className="perform-bottomblock-head">ENQUIRY</div>
                                    <div className="perform-bottomblock-text">20</div>
                                </div>
                                <div className="perform-bottomblock">
                                    <div className="perform-bottomblock-head">RETAIL</div>
                                    <div className="perform-bottomblock-text">20</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopPerforming;