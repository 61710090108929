import React, { useState } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import rightArrow from "../images/arrow.png";
import LeftMenu from "../layout/leftMenu";
import { backendApi, re } from "../apiService";
import rolesimage from "../images/left_icons/roles.png";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import Header from "../layout/header";
import Footer from "../layout/footer";
var phNum = /^\d{10}$/;
var emailValidation =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function Newuser(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organization, setOrganization] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [oldUser, setOldUser] = useState(false);
  // const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (location.state !== null) {
      setOldUser(location.state.oldUser);
      getUserData();
    }
  }, []);
  const getUserData = () => {
    var req = {
      functionName: "getUserProfile",
      userId: location.state.id.toString(),
    };
    backendApi(req).then((response) => {
      if (response.success === "1") {
        setFirstName(response.data[0].first_name);
        setLastName(response.data[0].last_name);
        // setOrganization(response[0].data.organization);
        setEmail(response.data[0].email_id);
        setPhone(response.data[0].mobile_no);
      }
    });
  };
  const newUserRegistration = (e) => {
    e.preventDefault();
    if (firstName === "") {
      alert("Enter First Name please");
      return false;
    } else if (lastName === "") {
      alert("Enter Last name please");
      return false;
    } else if (email === "") {
      alert("Enter Email-Id please");
      return false;
    } else if (!email.match(emailValidation)) {
      alert("Enter valid Email-Id");
      return false;
    } else if (phone === "") {
      alert("Enter user Phone Number please");
      return false;
    } else if (!phone.match(phNum)) {
      alert("Mobile Number must be 10 digits");
      return false;
    }
    var emailExist = false;
    let organizationEmail = email.split("@");
    organizationEmail = organizationEmail[1].split(".");
    var chkEmail = {
      emailId: email,
      functionName: "checkEmailId",
    };
    $(".loder_div").attr("hidden", false);
    backendApi(chkEmail).then((response) => {
      if (response.success === "1") {
        if (response.user_available === false) {
          alert("Email Id already exist");
          $(".loder_div").attr("hidden", true);
          return false;
        } else {
          var req = {
            firstName: firstName,
            lastName: lastName,
            emailId: email,
            organization: organizationEmail[0],
            mobileNo: phone,
            functionName: "adminCreateNewUser",
          };
          backendApi(req).then((response) => {
            if (response.success === "1") {
              alert("New User Created Successfully");
              window.location.href = "/userList";
            }
          });
        }
      } else {
        alert("Something went wrong");
        $(".loder_div").attr("hidden", true);
      }
    });
  };
  const changePassword = (e) => {
    e.preventDefault();
    // if (currentPassword === "") {
    //   alert("Enter current password");
    //   return false;
    // } 
     if (newPassword === "") {
      alert("Enter new password");
      return false;
    } else if (confirmNewPassword === "") {
      alert("Enter Confirm Password");
      return false;
    } else if (confirmNewPassword !== newPassword) {
      alert("New Password and Confirm Password are not same");
      return false;
    }
    $(".loder_div").attr("hidden", false);
    var req = {
      userId: location.state.id.toString(),
      // currentPassword: currentPassword,
      newPassword: newPassword,
      functionName: "changePassword",
    };
    backendApi(req).then((response) => {
      $(".loder_div").attr("hidden", true);
      if (response.success === "1") {
        alert("Password Changed Successfully");
        window.location.reload();
      } else {
        alert("Something wrong while changing password");
      }
    });
  };
  const updateUser = (e) => {
    e.preventDefault();
    if (firstName === "") {
      alert("Enter First Name please");
      return false;
    } else if (lastName === "") {
      alert("Enter Last name please");
      return false;
    } else if (email === "") {
      alert("Enter Email-Id please");
      return false;
    } else if (!re.test(email)) {
      alert("Enter valid Email-Id");
      return false;
    } else if (phone === "") {
      alert("Enter user Phone Number please");
      return false;
    } else if (!phone.match(phNum)) {
      alert("Mobile Number must be 10 digits");
      return false;
    }
    var req = {
      userId: location.state.id.toString(),
      firstName: firstName,
      lastName: lastName,
      emailId: email,
      mobileNo: phone,
      functionName: "updateUser",
    };
    console.log(req, "req");
    backendApi(req).then((response) => {
      if (response.success === "1") {
        alert("Updated Successfully");
        window.location.href = "/userList";
      }
    });
  };
  const resetPassword = (e) => {
    e.preventDefault();
    $("#oldPasswordField").val("");
    $("#newPasswordField").val("");
    $("#confirmNewPasswordField").val("");
  };
  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        {oldUser ? (
          <Header heading="Edit User Details" headerImage={rolesimage} />
        ) : (
          <Header heading="Create New User" headerImage={rolesimage} />
        )}

        <div className="container-fluid">
          {/* {oldUser ? (
            <div className="head-new-user">Update User Details</div>
          ) : (
            <div className="head-new-user">New User Registration</div>
          )} */}
          <>
            <form className="user_formdiv col-md-10">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      aria-describedby="emailHelp"
                      placeholder="Enter First Name"
                      onChange={(event) => setFirstName(event.target.value)}
                      value={firstName}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      aria-describedby="emailHelp"
                      placeholder="Enter Last Name"
                      onChange={(event) => setLastName(event.target.value)}
                      value={lastName}
                    />
                  </div>
                </div>
                <hr></hr>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Email Id</label>
                    <input
                      type="email"
                      className="form-control"
                      id="emailId"
                      aria-describedby="emailHelp"
                      placeholder="Enter Email Id"
                      onChange={(event) => setEmail(event.target.value)}
                      value={email}
                    />
                  </div>
                </div>
                {/* {oldUser === false ? (
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Password</label>
                    <input
                      type="password"
                      className="form-control "
                      placeholder="Password"
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </div>
                </div>
              ) : (
                ""
              )} */}

                {/* <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Organization</label>
                  <input
                    type="text"
                    className="form-control"
                    id="organization"
                    aria-describedby="emailHelp"
                    placeholder="Enter Organization"
                    onChange={(event) => setOrganization(event.target.value)}
                    value={organization}
                  />
                </div>
              </div> */}
                {/* <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Address</label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    aria-describedby="emailHelp"
                    placeholder="Enter Address"
                    onChange={(event) => setAddress(event.target.value)}
                  />
                </div>
              </div> */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      id="phoneNo"
                      maxLength={10}
                      aria-describedby="emailHelp"
                      placeholder="Enter Phone No."
                      onChange={(event) => setPhone(event.target.value)}
                      value={phone}
                    />
                  </div>
                </div>
              </div>
              <div className="form_bttn">
                <button
                  type="submit"
                  className="btn btn-primary submitButton cancel_bttn"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "/userList";
                  }}
                >
                  <span to="/userList">Cancel</span>
                </button>
                {oldUser ? (
                  <button
                    type="submit"
                    className="btn btn-primary submitButton"
                    onClick={updateUser}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary submitButton"
                    onClick={newUserRegistration}
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
          </>
          {oldUser ? (
            <>
              <hr className="col-md-10 ml-0 px-0" />
              <form className="user_formdiv col-md-10">
                <div className="row">
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">
                        Current Password
                      </label>
                      <input
                        id="oldPasswordField"
                        type="password"
                        className="form-control "
                        placeholder="Enter Current Password"
                        onChange={(event) =>
                          setCurrentPassword(event.target.value)
                        }
                      />
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">
                        New Password
                      </label>
                      <input
                        id="newPasswordField"
                        type="password"
                        className="form-control "
                        placeholder="Enter New Password"
                        onChange={(event) => setNewPassword(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">
                        Confirm New Password
                      </label>
                      <input
                        id="confirmNewPasswordField"
                        type="password"
                        className="form-control "
                        placeholder="Confirm New Password"
                        onChange={(event) =>
                          setConfirmNewPassword(event.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="form_bttn">
                  <button
                    type="submit"
                    className="btn btn-primary submitButton cancel_bttn"
                  >
                    <span
                      onClick={(e) => {
                        resetPassword(e);
                      }}
                    >
                      Reset
                    </span>
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary submitButton"
                    onClick={changePassword}
                  >
                    Change Password
                  </button>
                </div>
              </form>
            </>
          ) : (
            ""
          )}
        </div>
        <Footer/>
      </div>

      <div className="loder_div" hidden />
    </div>
  );
}
export default Newuser;
