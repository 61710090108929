import React, { useEffect, useState } from 'react'
import LineChart from './line-graph-socialmedia';
function SocialMediaEng() {
    return (
        <div className= {window.sessionStorage.getItem("roleId") === "12" ? "db-cards-75 gbp-searches" : "db-cards-60 gbp-searches"}style={{ height: "inherit" }}>
            <div className="card inactive_div"  style={{ height: "calc(100% - 10px)" }}>
                <div className="card-body">
                    <div class="db-cards-top  all-region-head">
                        <h5 class="card-title card-title-small mb-0">Social Media Engagement</h5>
                        <span class="db-cad-top-units-blue ">
                            <div className="form-check mb-0 pl-0">
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="flexRadioDefault21" name="flexRadioDefaultEng" className="custom-control-input"
                                    />
                                    <label className="custom-control-label" for="flexRadioDefault21">All</label>
                                </div>
                            </div>
                            <div className="form-check mb-0 pl-0">
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="flexRadioDefault22" name="flexRadioDefaultEng" className="custom-control-input"
                                    />
                                    <label className="custom-control-label" for="flexRadioDefault22">Google</label>
                                </div>
                            </div>
                            <div className="form-check mb-0 pl-0">
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="flexRadioDefault23" name="flexRadioDefaultEng" className="custom-control-input"
                                    />
                                    <label className="custom-control-label" for="flexRadioDefault23">Facebook</label>
                                </div>
                            </div>
                            <div className="form-check mb-0 pl-0">
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="flexRadioDefault24" name="flexRadioDefaultEng" className="custom-control-input"
                                    />
                                    <label className="custom-control-label" for="flexRadioDefault24">Instagram</label>
                                </div>
                            </div>
                            <span className="db-right-btn">Sept - Nov</span>
                        </span>
                    </div>
                    <div className='db-cards-top mb-0 db-cards-check align-items-start '>
                        <div className="w-100">
                    social
                    <LineChart fromPage={"socialEngagement"}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SocialMediaEng;