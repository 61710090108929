import React, { Component } from "react";
import Chart from "react-apexcharts";
// import moment from "moment";
class VisibilityAccuracyGraph extends Component {
  constructor(props) {
    super(props);
    console.log("xaxs",props)
    this.state = {
      series: [
        {
          name: "Phone Number Match",
          data: this.props.listingonSearch.map((i) => parseInt(i)),
        },
        {
          name: "Address Match",
          data: this.props.getGraphAdressData.map((i) => parseInt(i)),
        },
        {
          name: "Business Name Match",
          data: this.props.getBusnessData.map((i) => parseInt(i)),
        },
      ],
      options: {
        chart: {
          type: "area",
          toolbar: {
            show: false,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          show: true,
          width: 2,
        },
        xaxis: {
          categories: this.props.xaxis,
        },
        yaxis: {
          min: 0,
        },
        legend: {
          position: "right",
        },
      },
    };

    // this.props.getGBPCountData && {
    //   name: "GBP Visible Match",
    //   data: this.props.getGBPCountData.map((i) => parseInt(i)),
    // },
  }
  componentDidMount() {
    if (this.props.getCategoryCountData.length > 0) {
      this.setState(
        (obj) => ({
          series: [
            ...obj.series,
            {
              name: "Category Match",
              data: this.props.getCategoryCountData.map((i) => parseInt(i)),
            },
          ],
        }),
        () => console.log("serrries1111", this.state.series)
      );
    }
    if (this.props.getGBPCountData.length > 0) {
      this.setState(
        (obj) => ({
          series: [
            ...obj.series,
            {
              name: "GBP Visiblity",
              data: this.props.getGBPCountData.map((i) => parseInt(i)),
            },
          ],
        }),
        () => console.log("serrries2222", this.state.series)
      );
    }
  }
  render() {
    return (
      <div className="graph_insight">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={300}
        />
      </div>
    );
  }
}
export default VisibilityAccuracyGraph;
