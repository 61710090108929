import "../../css/bootstrap.css";
import "../../css/style.css";
import "../../css/responsive.css";
import { useState, useEffect } from "react";
import React from "react";
import { User, Lock, Navigation, PhoneOutgoing } from "react-feather";
import SignupNew from "./signup_new";
import $ from "jquery";
import { backendApi, re } from "../../apiService";
import Alertmsg from "../alert_msg";
import Logo from '../../images/logo_login.png'
import LogoSub from '../../images/tagtree_sub.png'
function SigninOld(props) {
  const [showalert, setshowalert] = useState(false);
  const [showform, setShowform] = useState(true);
  const [newMember, setNewMember] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [forgotForm, setForgotForm] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");

  const login = (e) => {
    e.preventDefault();
    if (email === "" || $("#signInEmailId").val() === "") {
      alert("Enter Email-Id please");
      return false;
    } else if (password === "" || $("#signInPassword").val() === "") {
      alert("Enter Password please");
      return false;
    } else if (!re.test(email)) {
      alert("Enter valid Email-Id");
      return false;
    }
    var req = {
      functionName: "login",
      userName: $("#signInEmailId").val(),
      password: password,
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((response) => {
      console.log();
      if (response.success === "1") {
        setMessage(response.message);
        setshowalert(true);
        window.sessionStorage.setItem("insightsDataSave","")
        window.sessionStorage.setItem("categoryIdPopUpSet", "0");
        window.sessionStorage.setItem("userId", response.userId);
        window.sessionStorage.setItem("userName", response.name);
        window.sessionStorage.setItem("isLogin", true);
        window.sessionStorage.setItem("role", response.role[0].role);
        window.sessionStorage.setItem("roleId", response.role[0].roleId);
        window.sessionStorage.setItem("organization", response.organization);
        window.sessionStorage.setItem("pageIndexMergeAudit", 0);
        window.sessionStorage.setItem("is_privileged", response.is_privileged);
        window.sessionStorage.setItem(
          "organizationId",
          response.organizationId
        );
        window.sessionStorage.setItem(
          "modules",
          JSON.stringify(response.modules)
        );
        window.sessionStorage.setItem(
          "organizationAllotment",
          JSON.stringify(response.organizationAllotment)
        );
        if((response.role[0].roleId == 14)||(response.role[0].roleId == 12)||(response.role[0].roleId == 13)||(response.role[0].roleId == 10)){
          window.sessionStorage.setItem("switchingOrgId", response.organizationAllotment[0].id);
          console.log("response",response)
            window.location.href = "/analytic-dashboard-old";
        }else if((response.role[0].roleId == 11)){
          window.location.href = "/analytic-dashboard"
        }
        else{
          window.sessionStorage.setItem("insightsDataSave","")
        window.sessionStorage.setItem("switchUserModules", "");
        window.sessionStorage.setItem("switchUserId", "");
        window.sessionStorage.setItem("switchUserRole", "");
        window.sessionStorage.setItem("switchingOrgId", "");
        window.sessionStorage.setItem("switchUserOrganization", "");
        window.sessionStorage.setItem("emailsession", "");
        window.sessionStorage.setItem("holidayList", false);
        window.location.href = "/dashboard";

        }
        
        $(".loder_div").attr("hidden", true);
      } else {
        setMessage(response.message);
        alert("Invalid Username/ Password");
        $(".loder_div").attr("hidden", true);
        setTimeout(() => {
          setMessage("");
        }, 5000);
      }
    });
  };
  const submitForgot = () => {
    if ($("#forgotEmail").val() === "") {
      alert("Enter email ID");
      return false;
    }
    var req = {
      functionName: "forgetPassword",
      emailId: $("#forgotEmail").val(),
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        alert("Password Reset Successfully");
        setForgotForm(false);
        window.location.reload()
      } else {
        $(".loder_div").attr("hidden", true);
        alert(data.successMessage);
        setForgotForm(false);
      }
    });
  };
  return (
    <div className="innter_loginright">
      {forgotForm === false ? (
        <form autoComplete="off" onSubmit={login}>
          <div className="login_headlogo">
           <div className="tagtree_logodiv">
            <img className="tagtree_logoimg" src={Logo}/>            
           </div>      
           <div className="tagtree_logospan">
              <img src={LogoSub}/>
            </div>      
          </div>
          <h1 className="login_head">Login</h1>
          <div className="form_group">
            <span>{message}</span>
            {/* <User /> */}
            <label>Email address</label>
            <input
              id="signInEmailId"
              type="text"
              placeholder="Email ID"
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className="form_group">
            {/* <Lock /> */}
            <label>Password</label>
            <input
              id="signInPassword"
              type="password"
              placeholder="Password"
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
          <div className="login_submit">
            <button type="submit">Sign In</button>
          </div>
          <div className="login_submit">
            {/* <span type="button" onClick={() => props.newMember(true)}>
              New Registration?
            </span> */}
            <span
              type="button"
              className="ml-auto"
              onClick={() => {
                setForgotForm(true);
                setEmail("");
              }}
            >
              Forgot Password?
            </span>
          </div>
          <div className="login_link">Don't have an account?<a href="#"  onClick={() => props.newMember(true)}>Sign up</a></div>
          <div className="login_privacy_link">
         <a href="/privacyPolicy">Privacy Policy</a>  <a href="/termsAndConditions" className="ml-auto">Terms & Condition</a>
          </div>
        </form>
      ) : (
        <form autoComplete="off">
          <div className="login_headlogo">
           <div className="tagtree_logodiv">
            <img className="tagtree_logoimg" src={Logo}/>            
           </div>      
           <div className="tagtree_logospan">
              <img src={LogoSub}/>
            </div>      
          </div>
          <h1 className="login_head">Forgot Password?</h1>
          <div className="form_group">
            <span>{message}</span>
            {/* <User /> */}
            <label>Email ID</label>
            <input
              type="text"
              placeholder="Email ID"
              id="forgotEmail"
              onChange={(event) => setForgotEmail(event.target.value)}
            />
          </div>

          <div className="login_submit">
            <button type="button" onClick={submitForgot}>
              Submit
            </button>
          </div>
          <div className="login_submit">
            <span
              type="button"
              className="ml-auto"
              onClick={() => {
                setForgotForm(true);
                setEmail("");
              }}
            >
              Forgot Password?
            </span>
          </div>
          <div className="login_link">Don't have an account?<a href="#"  onClick={() => props.newMember(true)}>Sign up</a></div>
        </form>
      )}

      {showalert ? <Alertmsg /> : ""}
      <div className="loder_div" hidden />
    </div>
  );
}

export default SigninOld;
