import React from "react";
import * as Icon from "react-feather";

export default function dataInput(props) {
  const handleChange = (e) => {
    const files = e.target.files;
    if (files && files[0]) {
      const selectedFile = files[0];
      const maxSize = 5 * 1024 * 1024;
      if (selectedFile.size > maxSize) {
        alert("File size exceeds 5MB limit. Please choose a smaller file.");
      } else {
        props.handleFile(selectedFile, e);
      }
    }
  };

  return (
    <>
      {/* <Icon.UploadCloud className="uploadCloudIcon" /> */}
      <div className="form-label-fileuploaddiv">
        {/* <label className="form-label-fileupload" htmlFor="file">
          Drag & Drop your file &nbsp;
        </label> */}
        {/* <input
          type="file"
          className=""
          id={props.id}
          //name="file"
          accept={AcceptFileFormats}
          onChange={handleChange}
        />

        <span className="form-fileupload-span">Or</span> */}
      </div>
      {/* <div className="form-fileupload-btn-padd">
                <button className="form-fileupload-btn">Browse File</button>
                <input type="file" name="myfile"/>
            </div> */}
      <div className="upload-btn-wrapper">
        <button className="form-fileupload-btn">Upload a file</button>
        <input type="file" name="file" onChange={handleChange} id={props.id} />
        <div className="red-mantatory">
          <small className="red">*File size must be less than 5MB</small>
        </div>
      </div>
    </>
  );
}

const AcceptFileFormats = ["xlsx", "xlsb", "xlsm", "xls", "jpg", "jpeg", "png"];
