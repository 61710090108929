import React, { Component, useEffect, useState } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Dashboardimage from "../images/left_icons/business.png";
import * as Icon from "react-feather";
import NewInsightsEdit from "./newInsightEdit";
import $ from "jquery";
import { apiCallingPost, backendApi } from "../apiService";
import moment from "moment";

function InsightTabs() {
  const [allLocationData, setAllLocationData] = useState([]);
  const [allAssertiveLocationData, setAllAssertiveLocationData] = useState([]);
  const [administrativeAreaList, setAdministrativeAreaList] = useState([]);
  const [localityList, setLocalityList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [administrativeArea, setAdministrativeArea] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [locality, setLocality] = useState("");
  const [metricDataXAxis, setMetricDataXAxis] = useState([]);
  const [metricDataYAxis, setMetricDataYAxis] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState(
    "BUSINESS_IMPRESSIONS_DESKTOP_MAPS"
  );
  const [selectedTimeRange, setSelectedTimeRange] = useState("WEEK");
  //   const [locationList, setLocationList] = useState([]);
  //   const [locationList, setLocationList] = useState([]);
  //   const [locationList, setLocationList] = useState([]);
  useEffect(() => {
    GetLocationsUnderAccount();
  }, []);

  const GetLocationsUnderAccount = (val) => {
    $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "getMasterDataForFilter",

      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    console.log("reviewAPIData", reviewAPIData);
    apiCallingPost(reviewAPIData)
      .then(async (result) => {
        $(".loder_div").attr("hidden", true);
        if (!result.data || result.success !== "1") {
          alert(result.errorMessage);
          $(".loder_div").attr("hidden", true);
          return;
        }
        const { data } = result;
        // TODO: May need location filter with country. currently only running for india.
        setAllLocationData(
          data.map((eachLocation) => ({
            locationId: eachLocation.location_id,
            location: `${eachLocation?.title} - ${eachLocation?.locality}`,
          }))
        );
        setLocationList(data);
        setAllAssertiveLocationData(data);
        setAdministrativeAreaList(
          new Array(
            ...new Set(
              data.map((eachLocation) => eachLocation?.administrativeArea)
            )
          )
        );
        setLocalityList(
          new Array(
            ...new Set(data.map((eachLocation) => eachLocation?.locality))
          )
        );
        // $(".loder_div").attr("hidden", true);
        setSelectedLocation(data[0].location_id);
        setAdministrativeArea("0");
        setLocality("0");
        // getInsightData(
        //   data[0].location_id,
        //   "BUSINESS_IMPRESSIONS_DESKTOP_MAPS",
        //   "WEEK"
        // );
      })
      .catch((error) => {
        console.trace(error);
        alert("Something went wrong in getting Location Data");
        $(".loder_div").attr("hidden", true);
      });
  };

  const getInsightData = (locationId, metric, option) => {
    if (locationId == "0") {
      alert("Please select a location");
      return false;
    } else if (metric == "0") {
      alert("Please select a metric");
      return false;
    } else if (option == "0") {
      alert("Please select a Time Range");
      return false;
    }
    $(".loder_div").attr("hidden", false);
    setMetricDataXAxis([]);
    setMetricDataYAxis([]);
    let startDate = "";
    let endDate = "";
    const now = new Date();
    switch (option) {
      case "WEEK": {
        //*Code to get start and end of week starts here
        startDate = new Date(now);
        startDate.setDate(now.getDate() - now.getDay());

        endDate = new Date(now);
        endDate.setDate(now.getDate() + (6 - now.getDay()));
        //*Code to get start and end of week ends here
        break;
      }
      case "MONTH": {
        const now = new Date();
        //* Code to get start and end of month starts here
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        //* Code to get start and end of month ends here
        break;
      }
      case "YEAR": {
        //*Code to get start and end of Year starts here
        startDate = new Date(now.getFullYear(), 0, 1);
        endDate = new Date(now.getFullYear(), 11, 31);
        //*Code to get start and end of Year ends here
        break;
      }
      default: {
        alert("Invalid Time Range Selected");
        return false;
      }
    }
    if (now < endDate) {
      endDate = now;
    }
    const apiReq = {
      functionName: "newInsightLocationAnlytics",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      locationId: locationId,
      metric: metric,
      startYear: new Date(startDate).getFullYear(),
      startMonth: new Date(startDate).getMonth() + 1,
      startDay: new Date(startDate).getDate(),
      endYear: new Date(endDate).getFullYear(),
      endMonth: new Date(endDate).getMonth() + 1,
      endDay: new Date(endDate).getDate(),
    };
    backendApi(apiReq).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success == "1") {
        console.log(data);
        data.data.timeSeries.datedValues.map((result) => {
          if (result.value) {
            setMetricDataYAxis((object) => [...object, result.value]);
            setMetricDataXAxis((object) => [
              ...object,
              moment(
                new Date(
                  result.date.year,
                  result.date.month - 1,
                  result.date.day
                )
              ).format("DD/MM/YY"),
            ]);
          } else {
            setMetricDataYAxis((object) => [...object, "0"]);
            setMetricDataXAxis((object) => [
              ...object,
              moment(
                new Date(
                  result.date.year,
                  result.date.month - 1,
                  result.date.day
                )
              ).format("DD/MM/YY"),
            ]);
          }
        });
      } else {
        alert("No Insights available for this location");
      }
      //   setMetricDataYAxis([
      //     data.data.timeSeries.datedValues.map(
      //       (data) => new Date(data.date.year, data.date.month, data.date.day)
      //     ),
      //   ]);
      //   setMetricDataXAxis([
      //     data.data.timeSeries.datedValues.map((data) => data.value),
      //   ]);
    });
  };

  const onStateSelect = (administrativeArea) => {
    setAdministrativeArea(administrativeArea);
    setLocalityList(
      new Array(
        ...new Set(
          locationList
            .filter(
              (eachLocation) =>
                eachLocation?.administrativeArea === administrativeArea
            )
            .map((eachLocation) => eachLocation?.locality)
        )
      )
    );
    setAllAssertiveLocationData(
      locationList.filter(
        (eachLocation) => eachLocation?.administrativeArea == administrativeArea
      )
    );
    setLocality("0");
    setSelectedLocation("0");
    setSelectedMetric("0");
    setSelectedTimeRange("0");
  };
  const onCitySelect = (locality) => {
    setLocality(locality);
    setAllLocationData(
      allAssertiveLocationData
        .filter((eachLocation) => eachLocation?.locality === locality)
        .map((eachLocation) => ({
          locationId: eachLocation.location_id,
          location: `${eachLocation?.title} - ${eachLocation?.locality}`,
        }))
    );
    setSelectedLocation("0");
    setSelectedMetric("0");
    setSelectedTimeRange("0");
  };
  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Claim a Business" headerImage={Dashboardimage} />

        <Tabs className="tabs-main">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 col-md-4 col-sm-6">
                <select
                  className="form-control"
                  required
                  value={administrativeArea}
                  onChange={(e) => onStateSelect(e.target.value)}
                >
                  <option value="0">State</option>
                  {administrativeAreaList?.length > 0 &&
                    administrativeAreaList.map((adminArea, index) => (
                      <option key={index} value={adminArea}>
                        {adminArea}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <select
                  className="form-control"
                  required
                  value={locality}
                  onChange={(e) => onCitySelect(e.target.value)}
                >
                  <option value="0">City</option>
                  {localityList?.length > 0 &&
                    localityList.map((eachLocality, index) => (
                      <option key={index} value={eachLocality}>
                        {eachLocality}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <button
                  className="btn-search"
                  onClick={() => GetLocationsUnderAccount()}
                >
                  Clear
                </button>
              </div>
              {/* 
            <div className="col-lg-2 col-md-4 col-sm-6">
              <DatePicker
                selected={startDate}
                // onChange={(date) => this.handleChangeStart(date)}
                name="startDate"
                id="startDate"
                showMonthDropdown
                showYearDropdown
                dateFormat="dd/MM/yyyy"
                className="form-control"
                placeholderText="From Date"
              />
            </div>

            <div className="col-lg-2 col-md-4 col-sm-6">
              <DatePicker
                selected={endDate}
                // onChange={(date) => this.handleChangeEnd(date)}
                name="endDate"
                id="endDate"
                showMonthDropdown
                showYearDropdown
                dateFormat="dd/MM/yyyy"
                className="form-control"
                placeholderText="To Date"
              />
            </div> */}
            </div>
            <hr />
            <span className="Date_fromToText">Location</span>
            <div className="Date_fromTo">
              <select
                className="form-control"
                required
                value={selectedLocation}
                onChange={(obj) => setSelectedLocation(obj.target.value)}
                //   value={this.state.locality}
              >
                <option value="0" disabled>
                  Location
                </option>
                {allLocationData.map((object, i) => (
                  <option key={i} value={object.locationId}>
                    {object.location}
                  </option>
                ))}
              </select>
            </div>
            <span className="Date_fromToText">Time</span>
            <div className="Date_fromTo">
              <select
                className="form-control"
                value={selectedTimeRange}
                required
                onChange={(obj) => setSelectedTimeRange(obj.target.value)}
              >
                <option value="0">Time</option>
                <option key={0} value="WEEK">
                  1 Week
                </option>
                <option key={1} value="MONTH">
                  1 Month
                </option>
                <option key={2} value="YEAR">
                  1 Year
                </option>
              </select>
            </div>
            <div className="row">
              <TabList>
                <Tab>InsightsEdit Tab</Tab>
                {/* <Tab>From GBP</Tab> */}
                <Tab>From Audit Duplicate</Tab>
                <Tab>From Audit Stray</Tab>
                <Tab>No Data From Google</Tab>
                <Tab>Claim Requested</Tab>
              </TabList>
            </div>
          </div>
          <TabPanel>
            {/* <FromAudit /> */}
            <NewInsightsEdit
              allLocationData={allLocationData}
              allAssertiveLocationData={allAssertiveLocationData}
              administrativeAreaList={administrativeAreaList}
              localityList={localityList}
              selectedLocation={selectedLocation}
              administrativeArea={administrativeArea}
              locationList={locationList}
              locality={locality}
              selectedTimeRange={selectedTimeRange}
              getInsightsAPI={getInsightData}
            />
          </TabPanel>
          {/* <TabPanel>
              <FromGoogle />
            </TabPanel> */}
          <TabPanel>{/* <AuditDuplicate /> */}</TabPanel>
          <TabPanel>{/* <AuditStray /> */}</TabPanel>
          <TabPanel>{/* <NoDataFound /> */}</TabPanel>
          <TabPanel>{/* <ClaimCompleted /> */}</TabPanel>
        </Tabs>
        <Footer />
      </div>
    </div>
  );
}
export default InsightTabs;
