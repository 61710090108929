import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import LeftMenu from "../layout/leftMenu";
import Lock from "../images/left_icons/lock2.png";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { MdHistory } from "react-icons/md";
// import { FaPlusCircle } from "react-icons/fa";
import { IoCloseCircleOutline } from "react-icons/io5";
import { backendApi, pythonBackendApi } from "../apiService";
import moment from "moment";
import Select, { useStateManager } from "react-select";
import Switch from "react-switch";
// import Loader from "../images/ideas.jpg";
import { FiPlusCircle } from "react-icons/fi";
import { GoPencil } from "react-icons/go";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ApproverPopup from "./approverPopup";
import AddCampaignPopup from "./addCampaignPopup";

function Generator() {
  const [campaignDetails, setCampaignDetails] = useState("");
  const [festival, setFestival] = useState("");
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [campaign, setCampaign] = useState("");
  const [about, setAbout] = useState("");
  const [keywords, setKeywords] = useState("");
  const [inclusions, setInclusions] = useState("");
  const [exclusions, setExclusions] = useState("");
  const [wordCount, setWordCount] = useState("");
  const [variations, setVariations] = useState("");
  const [festivals, setFestivals] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [adVariations, setAdVariations] = useState([]);
  const [history, setHistory] = useState([]);
  const [inclusionTone, setInclusionTone] = useState("");
  const [exclusionTone, setExclusionTone] = useState("");
  const [scheme, setScheme] = useState("");
  const [font, setFont] = useState("");
  const [fontsize, setFontsize] = useState("");
  const [festivalAndEventSave, setFestivalAndEventSave] = useState({
    festivalName: "",
    festivalDescription: "",
  });
  const [festivalAndEventUpdate, setfestivalAndEventUpdate] = useState({
    festivalName: "",
    festivalDescription: "",
  });
  const [historyList, setHistoryList] = useState([]);
  const [showModal, setModal] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editModeIndex, setEditModeIndex] = useState(-1);
  const [promptId, setPromptId] = useState("");
  const [groupData, setGroupData] = useState([]);
  const [showApprovalPopup, setShowApprovalPopup] = useState(false);
  const [showAddCampaignPopup, setShowAddCampaignPopup] = useState(false);
  const [content, setContent] = useState("");
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [showQuestionsTable, setShowQuestionsTable] = useState(false);
  const [showHistoryTable, setShowHistoryTable] = useState(false);
  const [requestedStatus, setRequestedStatus] = useState({
    approved: [],
    rejected: [],
  });
  const [remark, setRemark] = useState("");
  const [requirement, setRequirement] = useState("");
  const [waitingCount, setWaitingCount] = useState("");
  const [approvedCount, setApprovedCount] = useState("");
  const [rejectedCount, setRejectedCount] = useState("");
  const [languageChange, setLanguageChange] = useState(false);
  const [translatedContent, setTranslatedContent] = useState([]);

  useEffect(() => {
    historyDetailsGet();
    contentInit();
    getRequestedData();
    groupDataInit();
    console.log("languages", languages);

    let brandName = JSON.parse(
      window.sessionStorage.getItem("organizationAllotment")
    ).find(
      (item) => item.id == window.sessionStorage.getItem("switchingOrgId")
    );
    setBrand(brandName.organisation_name);
  }, []);

  const getRequestedData = () => {
    const postData = {
      functionName: "content_request_Listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      category_type: "Text",
      req_status: "",
    };

    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          const newWaiting = [];
          const newApproved = [];
          const newRejected = [];

          // Loop through data and populate each status array
          data?.dataList?.forEach((item) => {
            if (item.req_status === "Waiting") {
              newWaiting.push(item);
            } else if (item.req_status === "Approved") {
              newApproved.push(item);
            } else if (item.req_status === "Rejected") {
              newRejected.push(item);
            }
          });
          setWaitingCount(newWaiting.length);
          setApprovedCount(newApproved.length);
          setRejectedCount(newRejected.length);
          // Update state with new arrays
          setRequestedStatus({
            waiting: newWaiting,
            approved: newApproved,
            rejected: newRejected,
          });
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        // Handle errors that occur during API call
        console.error("Error occurred during API call:", error);
      });
  };

  const handleTranslatedTextareaChange = (event, index) => {
    const { value } = event.target;
    setTranslatedContent((prevTranslations) => {
      const newTranslations = [...prevTranslations];
      newTranslations[index] = value;
      return newTranslations;
    });
  };

  const getContentList = (promptId) => {
    const apiReq = {
      functionName: "content_generation_list",
      content_generation_prompt_id: promptId,
    };
    console.log("req", apiReq);
    backendApi(apiReq).then((data) => {
      if (data.success == "1") {
        setAdVariations(data.data);
      } else {
        console.log("Error in groupdata api");
      }
    });
  };

  const groupDataInit = () => {
    const apiReq = {
      functionName: "content_approver_group_data",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apiReq).then((data) => {
      if (data.success == "1") {
        setGroupData(data.dataSet);
      } else {
        console.log("Error in groupdata api");
      }
    });
  };

  const selectHistoryEntry = (entry) => {
    console.log("entry", entry);
    const camp = campaignOptions.find(
      (item) => item.value === entry.campaign_id
    );
    setCampaign(camp);
    // setCampaign({ value: Math.random(), label: entry.content_campaign_name });
    setModel(entry.model_name);
    setAbout(entry.about_brand);
    setKeywords(entry.brand_keywords);
    setFestival(entry.content_festival_id);
    setFont(entry.font_style);
    setFontsize(entry.font_size);
    setWordCount(entry.no_of_word);
    setVariations(entry.no_of_variation);
    setInclusions(entry.inclusion_Words);
    setExclusions(entry.exclusion_Words);
    setInclusionTone(entry.inclusion_Tone);
    setExclusionTone(entry.exclusion_Tone);
    setScheme(entry.scheme);
    setPromptId(entry.content[0].content_generation_prompt_id);
    const variations = entry.content;
    setAdVariations(variations);

    setShowHistoryTable(false);
  };

  const selectRefEntry = (entry) => {
    setRequirement(entry.answer_text);
    setShowQuestionsTable(false);
  };

  const contentInit = () => {
    const apiReq = {
      functionName: "content_init",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apiReq).then((data) => {
      if (data.success == "1") {
        setFestivals(data.festivalList);
        setLanguages(data.language);
        setCampaigns(data.campaign_name);
        const transformedOptions = data.campaign_name.map(
          (campaign, index) => ({
            value: campaign.id,
            label: campaign.campaign_name,
          })
        );
        setCampaignOptions(transformedOptions);
      } else {
        console.log("Error in content init api");
      }
    });
  };

  const handleEditClickRemark = (index, remarks) => {
    setEditingIndex(index);
    setRemark(remarks);
  };

  const closePopup = () => {
    getRequestedData();
    setShowApprovalPopup(false);
    setShowAddCampaignPopup(false);
  };

  const handleRemarkNameChange = (event) => {
    setRemark(event.target.value);
  };

  const hidePopup = () => {
    setShowHistoryTable(false);
    setShowQuestionsTable(false);
  };

  const resendForApproval = (data) => {
    setContent(data.content_text);
    setShowApprovalPopup(true);
  };

  const sendForApproval = (index) => {
    let text = adVariations[index].content_data;
    setContent(text);
    setShowApprovalPopup(true);
  };

  const tones = [
    "Inspirational",
    "Humorous",
    "Informative",
    "Authentic",
    "Aspirational",
    "Conversational",
    "Empowering",
  ];

  const handleCampaignSelect = (selectedOption) => {
    setCampaign(selectedOption);
    const selectedCampaign = campaigns.find(
      (camp) => camp.id === selectedOption.value
    );
    console.log("sc", selectedCampaign);
    setCampaignDetails(selectedCampaign);
  };

  const handleUpdateClickRemark = (i, data) => {
    let adminRemarks = remark.replace(/['"]/g, "\\$&");
    const postData = {
      functionName: "content_request_update_remarks",
      content_generation_request_id: data.id,
      remarks: adminRemarks,
      remarks_updated_by:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };
    backendApi(postData).then((response) => {
      if (response?.success === "1") {
        setEditingIndex(-1);
        getRequestedData();
      } else {
        setEditingIndex(-1);
        console.log("Error");
      }
    });
  };

  const handleSaveNew = (index) => {
    let replacedVariation;
    if(languageChange){
      const variationToSave = translatedContent[index];
      replacedVariation = variationToSave.replace(/['"]/g, "\\$&");
    }else{
      const variationToSave = adVariations[index].content_data;
      replacedVariation = variationToSave.replace(/['"]/g, "\\$&");
    }
  
    const saveReq = {
      functionName: "content_prompt_save",
      content_generation_prompt_id: promptId,
      created_by: window.sessionStorage.getItem("userId"),
      content_data: replacedVariation,
    };
    console.log("req", saveReq);
    return backendApi(saveReq).then((response) => {
      if (response.success == "1") {
        alert("Content Saved succesfully");
        setEditModeIndex(-1);
        setLanguageChange(false);
        setTranslatedContent([]);
        getContentList(promptId);
      } else {
        alert("Error saving content");
        console.log(`Error in API call ${index + 1}`);
      }
    });
  };

  const handleVariationEditClick = (index) => {
    setEditModeIndex(index);
  };

  const handleVariationCancelClick = (index) => {
    setEditModeIndex(-1);
    setLanguageChange(false);
    setTranslatedContent([]);
  };

  const deleteRequest = (data) => {
    const postData = {
      functionName: "content_request_delete",
      content_generation_request_id: data.id,
      sec_user_id:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };
    backendApi(postData).then((response) => {
      if (response?.success === "1") {
        getRequestedData();
      } else {
        console.log("Error");
      }
    });
  };

  const handleVariationUpdateClick = (index) => {
    let replacedContent;
    let contentId = adVariations[index].id;
    if(languageChange){
      let content = translatedContent[index];
      replacedContent = content.replace(/['"]/g, "\\$&");
    }else{
      let content = adVariations[index].content_data;
       replacedContent = content.replace(/['"]/g, "\\$&");
    }

    const updateReq = {
      functionName: "content_generation_update",
      content_generation_id: contentId,
      sec_user_id: window.sessionStorage.getItem("userId"),
      content_data: replacedContent,
    };
    backendApi(updateReq).then((response) => {
      if (response.success == "1") {
        alert("Ad Content updated successfully");
        getContentList(promptId);
        setTranslatedContent([]);
      } else {
        alert("Error updating ad content");
        console.log(`Error in Update API call`);
      }
    });
    setEditModeIndex(-1);
    setLanguageChange(false);
  
    // const newAdVariations = [...adVariations];
    // setAdVariations(newAdVariations);
  };

  const handleTextareaChange = (event, index) => {
    const { value } = event.target;
    const newAdVariations = [...adVariations];
    newAdVariations[index].content_data = value;
    setAdVariations(newAdVariations);
  };

  const generateAd = () => {
    if (isSubmitting) {
      return;
    }
    if (brand === "") {
      alert("Please select a brand");
      return false;
    }
    if (campaign === "") {
      alert("Please select a campaign");
      return false;
    } else if (model === "") {
      alert("Please enter model name");
      return false;
    } else if (about === "") {
      alert("Please enter about the ad content");
      return false;
    }
    let festivalName;
    if (festival !== "") {
      festivalName =
        festivals.find((data) => data.id == festival)?.festival_name || "";
    }

    let promptAI = `Create a compelling advertisement for ${brand} showcasing ${model}. Highlight its unique features and benefits. Craft a captivating description about ${brand} and its offerings. `;
    promptAI += `Include ${about} in the content`;
    if (festival !== "") {
      promptAI += ` Tailor the ad to fit the theme of ${festivalName}.`;
    }
    if (keywords !== "") {
      promptAI += ` Incorporate relevant keywords such as ${keywords}.`;
    }
    if (requirement !== "") {
      promptAI += ` Include ${requirement} in the content.`;
    }
    if (wordCount !== "") {
      promptAI += ` Aim for ${wordCount} words.`;
    }
    if (variations !== "") {
      promptAI += ` Generate ${variations} variations.`;
    }
    if (inclusions !== "") {
      promptAI += ` Include ${inclusions} in the content.`;
    }
    if (exclusions !== "") {
      promptAI += ` Exclude ${exclusions} from the content.`;
    }
    if (scheme !== "") {
      promptAI += ` Implement ${scheme}.`;
    }
    if (inclusionTone !== "") {
      promptAI += ` In a ${inclusionTone} tone,`;
    }
    if (exclusionTone !== "") {
      promptAI += ` Avoid a ${exclusionTone} tone.`;
    }
    let modelName = model.replace(/['"]/g, "\\$&");
    let aboutAd = about.replace(/['"]/g, "\\$&");
    let keywordAd = keywords.replace(/['"]/g, "\\$&");
    let schemeName = scheme.replace(/['"]/g, "\\$&");
    let inclusionWords = inclusions.replace(/['"]/g, "\\$&");
    let exclusionWords = exclusions.replace(/['"]/g, "\\$&");

    const apiReq = {
      functionName: "getContent_ai",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      brand_name: brand,
      campaign_id: campaign.value,
      model_name: modelName,
      about_brand: aboutAd,
      brand_keywords: keywordAd,
      sec_user_id: window.sessionStorage.getItem("userId"),
      content_festival_id: festival,
      font_style: font,
      font_size: fontsize,
      no_of_word: wordCount,
      no_of_variation: variations,
      scheme: schemeName,
      inclusion_Words: inclusionWords,
      exclusion_Words: exclusionWords,
      inclusion_Tone: inclusionTone,
      exclusion_Tone: exclusionTone,
      prompt: promptAI,
    };
    console.log("req", apiReq);
    setIsSubmitting(true);
    backendApi(apiReq).then((data) => {
      if (data.success == "1") {
        const promptId = data.content_generation_prompt_id;
        const guardPromptApiData = {
          method: "POST",
          body: JSON.stringify({
            guard_prompt: promptAI,
          }),
        };
        pythonBackendApi("guardRailPromptGen", guardPromptApiData).then(
          (guardPromptResponse) => {
            if (guardPromptResponse.success == "1") {
              const ads = guardPromptResponse.data.ads;

              const savePromises = ads.map((ad, index) => {
                let replacedVariation = ad.ad.replace(/['"]/g, "\\$&");
                const saveReq = {
                  functionName: "content_prompt_save",
                  content_generation_prompt_id: promptId,
                  created_by: window.sessionStorage.getItem("userId"),
                  content_data: replacedVariation,
                };
                return backendApi(saveReq).then((response) => {
                  if (response.success == "1") {
                    console.log(`API call ${index + 1} successful`);
                  } else {
                    console.log(`Error in API call ${index + 1}`);
                  }
                });
              });

              Promise.all(savePromises).then(() => {
                console.log("All API calls completed");
                setIsSubmitting(false);
                getContentList(promptId);
              });
            } else {
              alert("Error generating Ad");
              console.error(
                "Error generating ads:",
                guardPromptResponse.errorMessage
              );
              setIsSubmitting(false);
            }
          }
        );
      } else {
        alert("Error generating Ad");
        setIsSubmitting(false);
      }
    });
  };

  const handleLanguageChange = (event, index) => {
    // setEditModeIndex(-1);
    const languageId = parseInt(event.target.value);
    setSelectedLanguages((prevSelected) => {
      const newSelected = [...prevSelected];
      newSelected[index] = languageId;
      return newSelected;
    });
    const languageName = languages.find(
      (language) => language.id === parseInt(event.target.value)
    ).language_name;
    const convertReq = {
      functionName: "content_convert_language",
      prompt: `convert this ${adVariations[index].content_data} to ${languageName}`,
    };
    backendApi(convertReq).then((response) => {
      if (response.success === "1") {
        // const newAdVariations = [...adVariations];
        // newAdVariations[index].content_data = response.data[0].message.content;
        // setAdVariations(newAdVariations);
        const translatedData = response.data[0].message.content;
        setTranslatedContent((prevTranslations) => {
          const newTranslations = [...prevTranslations];
          newTranslations[index] = translatedData;
          return newTranslations;
        });
        // alert("Language converted successfully");
        setEditModeIndex(index);
        setLanguageChange(true);
      } else {
        console.log("Error converting language");
      }
    });
  };

  const resetForm = () => {
    setFestival("");
    setRequirement("");
    // setBrand("");
    setAbout("");
    setKeywords("");
    setWordCount("");
    setVariations("");
    setModel("");
    setInclusions("");
    setExclusions("");
    setInclusionTone("");
    setExclusionTone("");
    setFont("");
    setFontsize("");
    setScheme("");
    setAdVariations([]);
    setCampaignDetails("");
  };

  const generateWordOptions = () => {
    const options = [];
    for (let i = 10; i <= 100; i += 10) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  const generateVariationOptions = () => {
    const options = [];
    for (let i = 1; i <= 10; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  const historypop = () => {
    const convertReq = {
      functionName: "content_prompt_history",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(convertReq).then((response) => {
      if (response.success === "1") {
        setHistory(response.history);
      } else {
        console.log("Error in history api");
      }
    });
    setShowHistoryTable(!showHistoryTable);
    // const historyPopup = document.querySelector(".history-popup");
    // historyPopup.classList.toggle("active");
  };

  const getQuestions = () => {
    const convertReq = {
      functionName: "referenceList",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(convertReq).then((response) => {
      if (response.success === "1") {
        console.log("resp", response);
        setQuestions(response.dataSet);
        setShowQuestionsTable(!showQuestionsTable);
      } else {
        console.log("Error in history api");
      }
    });
    setShowQuestionsTable(!showQuestionsTable);
    // const historyPopup = document.querySelector(".history-popup");
    // historyPopup.classList.toggle("active");
  };
  const ShowModalAdv = () => {
    setModal(true);
    document.body.className = "overflow-hidden";
  };
  const ShowModalCampaignAdv = () => {
    setShowAddCampaignPopup(true);
    document.body.className = "overflow-hidden";
  };
  const closeModal = () => {
    setModal(false);
    setFestivalAndEventSave({ festivalName: "", festivalDescription: "" });
    document.body.className = "";
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFestivalAndEventSave((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const saveFestival = () => {
    if (!festivalAndEventSave.festivalName) {
      alert("Festival Name Required");
      document.getElementById("festivalName").focus();
      return;
    } else if (!festivalAndEventSave.festivalDescription) {
      alert("Festival Description Required");
      document.getElementById("festivalDescription").focus();
      return;
    }
    let festival = festivalAndEventSave.festivalName.replace(/['"]/g, "\\$&");
    let description = festivalAndEventSave.festivalDescription.replace(
      /['"]/g,
      "\\$&"
    );
    const postData = {
      functionName: "content_festival_save",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      festival_name: festival,
      description: description,
    };
    backendApi(postData).then((response) => {
      if (response.success === "1") {
        alert("Festival Added Successfully");
        historyDetailsGet();
        setFestivalAndEventSave({ festivalName: "", festivalDescription: "" });
        contentInit();
      } else {
        alert("Something went wrong please try again");
        closeModal();
        console.log("Error");
      }
    });
  };
  const historyDetailsGet = () => {
    const postData = {
      functionName: "content_festival_list",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(postData).then((response) => {
      console.log(response, "responseresponse");
      if (response?.success === "1") {
        setHistoryList(response.festivalList);
      } else {
        alert("Something went wrong please try again");
        console.log("Error");
      }
    });
  };
  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }
  const statusChanging = (data, activeCase) => {
    let postData = {
      functionName: "content_festival_activeDeactive",
      is_active: activeCase,
      id: data.id,
    };
    backendApi(postData)
      .then((response) => {
        if (response?.success === "1") {
          historyDetailsGet();
        }
      })
      .catch((error) => {
        console.error("Error fetching version list:", error);
      });
  };
  const handleEditClick = (index, festivalName, description) => {
    setEditingIndex(index);
    setfestivalAndEventUpdate({
      festivalName: festivalName,
      festivalDescription: description,
    });
  };
  const handleUpdateClick = (index, data) => {
    if (!festivalAndEventUpdate.festivalName) {
      alert("Festival Name Required");
      // document.getElementById("festivalName").focus()
      return;
    } else if (!festivalAndEventUpdate.festivalDescription) {
      alert("Festival Description Required");
      // document.getElementById("festivalDescription").focus()
      return;
    }
    let festival = festivalAndEventSave.festivalName.replace(/['"]/g, "\\$&");
    let description = festivalAndEventSave.festivalDescription.replace(
      /['"]/g,
      "\\$&"
    );
    const postData = {
      functionName: "content_festival_update",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      festival_name: festival,
      description: description,
      id: data.id,
    };
    backendApi(postData).then((response) => {
      if (response.success === "1") {
        alert("Festival Updated Successfully");
        historyDetailsGet();
      } else {
        alert("Something went wrong please try again");
        closeModal();
        console.log("Error");
      }
    });
    // Handle saving edited values
    setEditingIndex(-1);
    // Call any save function if needed
  };

  const handleCancelClick = () => {
    setEditingIndex(-1);
  };

  const handleFestivalNameChange = (event) => {
    setfestivalAndEventUpdate((prevState) => ({
      ...prevState,
      festivalName: event.target.value,
    }));
  };

  const handleDescriptionChange = (event) => {
    setfestivalAndEventUpdate((prevState) => ({
      ...prevState,
      festivalDescription: event.target.value,
    }));
  };
  ////////
  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Creatify Ad Generator" headerImage={Lock} />
        <div className="col-md-12 tabs-main-wrapper">
          <Tabs>
            <TabList onClick={(e) => setEditingIndex(-1)}>
              <Tab>Content Generation</Tab>
              <Tab>
                Requested
                <span className="waitingTxt">{waitingCount}</span>
              </Tab>
              <Tab>
                Approved
                <span className="waitingTxt">{approvedCount}</span>
              </Tab>
              <Tab>
                Rejected
                <span className="rejectedTxt">{rejectedCount}</span>
              </Tab>
            </TabList>

            <TabPanel>
              <div className="user_listdiv">
                <div className="user_formdiv mx-3">
                  <div className="row ">
                    <div className="col-md-5">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Campaign
                          <span className="red ml-1">*</span>{" "}
                        </label>
                        <div className="d-flex align-items-center">
                          <Select
                            name="colors"
                            placeholder="Select Campaign"
                            options={campaignOptions}
                            value={campaign}
                            onChange={handleCampaignSelect}
                            // onKeyDown={(event) => insertNewCampaign(event)}
                            className="basic-multi-select w-100"
                            classNamePrefix="select"
                          />
                          <span>
                            <FiPlusCircle
                              style={{ width: "20px", height: "20px" }}
                              onClick={() => ShowModalCampaignAdv()}
                              className="history-icon-gen ml-1"
                            />
                          </span>
                        </div>
                      </div>
                      {campaignDetails && (
                        <span className="text-muted campaign_details_span">
                          {campaignDetails.campaign_summary}
                        </span>
                      )}
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Festival</label>
                        <div className="d-flex">
                          <select
                            className="form-control"
                            value={festival}
                            onChange={(e) => {
                              setFestival(e.target.value);
                            }}
                          >
                            <option>Select Festival</option>
                            {festivals.map((festival) => (
                              <option
                                value={festival.id}
                                label={festival.festival_name}
                              >
                                {festival.festival_name}
                              </option>
                            ))}
                          </select>
                          <span className="ml-1">
                            <FiPlusCircle
                              style={{ width: "20px", height: "20px" }}
                              onClick={() => ShowModalAdv()}
                              className="history-icon-gen"
                            />
                          </span>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Brand
                          <span className="red ml-1">*</span>{" "}
                        </label>
                        <div className="d-flex">
                          <input
                            readOnly
                            type="text"
                            className="form-control"
                            placeholder="Enter Brand"
                            value={brand}
                            onChange={(e) => setBrand(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Model
                          <span className="red ml-1">*</span>{" "}
                        </label>
                        <div className="d-md-flex">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Model"
                            value={model}
                            onChange={(e) => setModel(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          From Requirement
                        </label>
                        <div className="d-md-flex">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Requirements"
                            value={requirement}
                            onChange={(e) => setRequirement(e.target.value)}
                          />
                          <span className="ml-1">
                            <MdHistory
                              className="history-icon-gen"
                              onClick={() => getQuestions()}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          About
                          <span className="red ml-1">*</span>{" "}
                        </label>
                        <div className="d-md-flex">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter About"
                            value={about}
                            onChange={(e) => setAbout(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group ">
                        <label htmlFor="exampleInputEmail1">Keywords</label>
                        <div className="d-flex">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Keywords"
                            value={keywords}
                            onChange={(e) => setKeywords(e.target.value)}
                          />
                          <span className="ml-1">
                            <MdHistory
                              title="history"
                              className="history-icon-gen"
                              onClick={() => historypop()}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="form-group ">
                        <label htmlFor="exampleInputEmail1">Scheme</label>
                        <div className="d-md-flex">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="15% Discount"
                            value={scheme}
                            onChange={(e) => setScheme(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label htmlFor="exampleInputEmail1">Font</label>
                          <select
                            id="fontSelect"
                            className="form-control"
                            value={font}
                            onChange={(e) => setFont(e.target.value)}
                          >
                            <option>Select font style</option>
                            <option value="Arial">Arial</option>
                            <option value="Times New Roman">
                              Times New Roman
                            </option>
                            <option value="Helvetica">Helvetica</option>
                            <option value="Verdana">Verdana</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Courier New">Courier New</option>
                            <option value="Tahoma">Tahoma</option>
                            <option value="Palatino">Palatino</option>
                            <option value="Garamond">Garamond</option>
                            <option value="Comic Sans MS">Comic Sans MS</option>
                          </select>
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="exampleInputEmail1">Size</label>
                          <select
                            id="sizeSelect"
                            className="form-control"
                            value={fontsize}
                            onChange={(e) => setFontsize(e.target.value)}
                          >
                            <option>Select font size</option>
                            <option value="12">12</option>
                            <option value="14">14</option>
                            <option value="16">16</option>
                            <option value="16">18</option>
                            <option value="16">20</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label htmlFor="exampleInputEmail1">
                            No: of Words
                          </label>
                          <select
                            className="form-control"
                            value={wordCount}
                            onChange={(e) => {
                              setWordCount(e.target.value);
                            }}
                          >
                            <option>Select No: of Words</option>
                            {generateWordOptions()}
                          </select>
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="exampleInputEmail1">
                            No: of Variations
                          </label>
                          <select
                            className="form-control"
                            value={variations}
                            onChange={(e) => {
                              setVariations(e.target.value);
                            }}
                          >
                            <option>Select No: of Variations</option>
                            {generateVariationOptions()}
                          </select>
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group col-md-6">
                          <label htmlFor="exampleInputEmail1">
                            Inclusion Words
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Inclusions-Words"
                            value={inclusions}
                            onChange={(e) => setInclusions(e.target.value)}
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="exampleInputEmail1">
                            Exclusion Words
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Exclusions-Words"
                            value={exclusions}
                            onChange={(e) => setExclusions(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group col-md-6">
                          <label htmlFor="exampleInputEmail1">
                            Inclusion Tone
                          </label>
                          <select
                            className="form-control"
                            value={inclusionTone}
                            onChange={(e) => {
                              setInclusionTone(e.target.value);
                            }}
                          >
                            <option>Select Tone</option>
                            {tones.map((tone) => (
                              <option value={tone} label={tone}>
                                {tone}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="exampleInputEmail1">
                            Exclusion Tone
                          </label>
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setExclusionTone(e.target.value);
                            }}
                            value={exclusionTone}
                          >
                            <option>Select Tone</option>
                            {tones.map((tone) => (
                              <option value={tone} label={tone}>
                                {tone}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div class="button-wrapper tag_changePswdHover">
                        <button
                          type="submit"
                          class="btn-cancel"
                          onClick={() => resetForm()}
                        >
                          {" "}
                          Reset
                        </button>
                        <button
                          type="submit"
                          class="btn-submit"
                          onClick={isSubmitting ? null : generateAd}
                          disabled={isSubmitting}
                        >
                          Generate
                        </button>
                      </div>
                    </div>

                    <div className="col-md-7 history-popup-main">
                      {showQuestionsTable ? (
                        <div className="history-popup p-2">
                          <div className="text-right pull-right close-history">
                            <IoCloseCircleOutline
                              color="red"
                              onClick={() => hidePopup()}
                            />
                          </div>
                          <div className="d-md-flex text-center justify-content-center mb-2">
                            References
                          </div>
                          <table className="table">
                            <thead>
                              <tr>
                                <th className="col-3">#</th>
                                <th className="col-3">Content</th>
                                <th className="col-3 ">Requested By</th>
                                <th className="col-3 ">Approved By</th>
                              </tr>
                            </thead>
                            <div className="table_Outerscroll">
                              {questions.map((qn, index) => (
                                <tbody className="table_Outertbody">
                                  <tr
                                    key={index}
                                    className="history-div"
                                    onClick={() => selectRefEntry(qn)}
                                  >
                                    <td className="col-3">{index + 1}</td>
                                    <td className="col-3">{qn.answer_text}</td>
                                    <td className="col-3">
                                      {qn.requested_by_name}{" "}
                                    </td>
                                    <td className="col-3">
                                      {qn.approved_by_name
                                        ? qn.approved_by_name
                                        : "NA"}
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                            </div>
                          </table>
                        </div>
                      ) : showHistoryTable ? (
                        <div className="history-popup p-2">
                          <div className="text-right pull-right close-history">
                            <IoCloseCircleOutline
                              color="red"
                              onClick={() => hidePopup()}
                            />
                          </div>
                          <div className="d-md-flex text-center justify-content-center mb-2">
                            Ad History
                          </div>
                          <table className="table">
                            <thead>
                              <tr>
                                <th className="col-1">#</th>
                                <th className="col-2">Model</th>
                                <th className="col-2 ">About</th>
                                <th className="col-3 ">Keywords</th>
                                <th className="col-2 ">Response</th>
                                <th className="col-2 ">Created On</th>
                              </tr>
                            </thead>
                            <div className="table_Outerscroll">
                              {history.map((historyItem, index) => (
                                <tbody className="table_Outertbody">
                                  <tr
                                    key={index}
                                    className="history-div"
                                    onClick={() =>
                                      selectHistoryEntry(historyItem)
                                    }
                                  >
                                    <td className="col-1">{index + 1}</td>
                                    <td className="col-2">
                                      {historyItem.model_name}
                                    </td>
                                    <td className="col-2">
                                      {historyItem.about_brand}{" "}
                                    </td>
                                    <td className="col-3">
                                      {historyItem.brand_keywords
                                        ? historyItem.brand_keywords
                                        : "NA"}
                                    </td>
                                    <td className="col-2">
                                      <span>
                                        {historyItem.no_of_word &&
                                        historyItem.no_of_variation ? (
                                          <>
                                            {historyItem.no_of_word} Words with{" "}
                                            {historyItem.no_of_variation}{" "}
                                            variations
                                          </>
                                        ) : (
                                          "NA"
                                        )}
                                      </span>
                                    </td>
                                    <td className="col-2">
                                      {moment(historyItem.created_on).format(
                                        "YYYY-MM-DD"
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                            </div>
                          </table>
                        </div>
                      ) : null}
                      {/* {questions.length > 0 && (
                        <div className="questions-popup p-2">
                          <div className="text-right pull-right close-history">
                            <IoCloseCircleOutline
                              color="red"
                              onClick={() => hidePopup()}
                            />
                          </div>
                          <div className="d-md-flex text-center justify-content-center mb-2">
                            Questions 
                          </div>
                          <table className="table">
                            <thead>
                              <tr>
                                <th className="col-1">#</th>
                                <th className="col-2">Questions</th>
                                <th className="col-2 ">Answers</th>
                                <th className="col-3 ">Remarks</th>
                                <th className="col-2 ">Requested On</th>
                              </tr>
                            </thead>
                            <div className="table_Outerscroll">
                              <tbody className="table_Outertbody">
                                {questions.map((qn, index) => (
                                  <tr
                                    key={index}
                                    className="history-div"
                                    onClick={() =>
                                      selectHistoryEntry(qn)
                                    }
                                  >
                                    <td className="col-1">{index + 1}</td>
                                    <td className="col-2">
                                      {qn.content_image}
                                    </td>
                                    <td className="col-2">
                                      {qn.content_text}{" "}
                                    </td>
                                    <td className="col-3">
                                      {qn.remarks
                                        ? qn.remarks
                                        : "NA"}
                                    </td>
                                    <td className="col-2">
                                      {moment(qn.requested_date).format(
                                        "YYYY-MM-DD"
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </div>
                          </table>
                        </div>
                      )} */}
                      {isSubmitting ? (
                        <div className="loder_div"></div>
                      ) : (
                        <div className="right-generator-div">
                          {adVariations.length > 0 &&
                            adVariations.map((variation, index) => (
                              <div class="card mb-4 box-shadow" key={index}>
                                <div className="row justify-content-end mx-3">
                                  <div className="mt-3">
                                    <ul class="nav nav_switch pr-0">
                                      <li>
                                        <div class="did-floating-label-content">
                                          <select
                                            class="did-floating-select"
                                            id={`language_select_${index}`}
                                            onChange={(event) =>
                                              handleLanguageChange(event, index)
                                            }
                                            value={selectedLanguages[index]}
                                          >
                                            <option value="0">
                                              Select Language
                                            </option>
                                            {languages.map((language) => (
                                              <option
                                                key={language.id}
                                                value={language.id}
                                                label={language.language_name}
                                              >
                                                {language.language_name}
                                              </option>
                                            ))}
                                          </select>
                                          <label class="did-floating-label">
                                            Language
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="card-body pt-0 pb-0">
                                  <textarea
                                    className="form-control"
                                    value={variation.content_data}
                                    readOnly
                                    wrap="soft"
                                  />
                                </div>
                                <div className="card-body pt-0 pb-0">
                                  {editModeIndex === index && languageChange ? (
                                    <textarea
                                      className="form-control"
                                      value={translatedContent[index] || ""}
                                      onChange={(event) =>
                                        handleTranslatedTextareaChange(event, index)
                                      }
                                      wrap="soft"
                                    />
                                  ) : editModeIndex === index &&
                                    !languageChange ? (
                                    <textarea
                                      className="form-control"
                                      value={adVariations[index].content_data || ""}
                                      onChange={(event) =>
                                        handleTextareaChange(event, index)
                                      }
                                      wrap="soft"
                                    />
                                  ) : null}
                                </div>
                                {/* <div class="card-body pt-0 pb-0">
                                  {editModeIndex === index ? (
                                    <textarea
                                      className="form-control"
                                      value={adVariations[index].content_data}
                                      onChange={(event) =>
                                        handleTextareaChange(event, index)
                                      }
                                      wrap="soft"
                                    />
                                  ) : (
                                    <textarea
                                      className="form-control"
                                      value={variation.content_data}
                                      readOnly
                                      wrap="soft" // or wrap="hard"
                                    />
                                  )}
                                </div> */}
                                <div className="pull-right text-right mb-2">
                                  {editModeIndex === index ? (
                                    <>
                                      <button
                                        className="btn btn-success mr-2"
                                        onClick={() => handleSaveNew(index)}
                                        type="button"
                                      >
                                        Save as New
                                      </button>
                                      <button
                                        className="btn-search mr-2"
                                        onClick={() =>
                                          handleVariationUpdateClick(index)
                                        }
                                        type="button"
                                      >
                                        Update
                                      </button>
                                      <button
                                        className="btn-cancel mr-2"
                                        onClick={() =>
                                          handleVariationCancelClick(index)
                                        }
                                        type="button"
                                      >
                                        Cancel
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        class="btn_worldcloud mr-2"
                                        id="txtFile"
                                        onClick={() =>
                                          handleVariationEditClick(index)
                                        }
                                        type="button"
                                      >
                                        Edit
                                      </button>
                                      <button
                                        id="txtFile"
                                        type="button"
                                        class="btn btn-success mr-1"
                                        onClick={() => sendForApproval(index)}
                                      >
                                        Send For Approval
                                      </button>
                                    </>
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div className="col-md-12 tableResponsive tagImageEditrListTble mt-4">
                <div className="tableOuterWidth table_width_md">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-3">Content </th>
                        <th className="col-3">Remarks</th>
                        <th className="col-2">Requested</th>
                        <th className="col-2">Approved</th>
                        <th className="col-2 ">Action</th>
                      </tr>
                    </thead>
                    {requestedStatus?.waiting?.map((data, i) => (
                      <tbody className="table_Outertbody pb-3">
                        <tr>
                          <td className="col-3">{data.content_text}</td>

                          <td className="col-3">
                            {editingIndex === i ? (
                              <textarea
                                type="text"
                                value={remark}
                                onChange={handleRemarkNameChange}
                                className="w-100 form-control"
                              />
                            ) : (
                              <span>{data.remarks}</span>
                            )}
                            {editingIndex !== i ? (
                              <GoPencil
                                className="ml-2"
                                onClick={() =>
                                  handleEditClickRemark(i, data.remarks)
                                }
                              />
                            ) : (
                              ""
                            )}
                            {editingIndex === i && (
                              <div className="mt-2">
                                <button
                                  onClick={() =>
                                    handleUpdateClickRemark(i, data)
                                  }
                                  className="btn btn-success"
                                >
                                  Save
                                </button>
                                <button
                                  onClick={handleCancelClick}
                                  className="btn btn-secondary ml-1"
                                >
                                  Cancel
                                </button>
                              </div>
                            )}
                          </td>
                          <td className="col-2">
                            <p>
                              {" "}
                              {moment(data.requested_date).format(
                                "DD MMM YYYY"
                              )}
                            </p>
                            <p>{data.requested_by_name}</p>
                          </td>
                          <td className="col-2">
                            {data.approved_by_name ? (
                              <>
                                <p>
                                  {moment(data.approved_date).format(
                                    "DD MMM YYYY"
                                  )}
                                </p>
                                <p>{data.approved_by_name}</p>
                              </>
                            ) : (
                              <p>Not approved</p>
                            )}
                          </td>
                          <td className="col-2">
                            {/* <button type="submit" className="btn btn-success mr-2">Send for Approval</button> */}
                            <button
                              type="submit"
                              className="btn btn-danger"
                              onClick={(e) => deleteRequest(data)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                    {/* </div> */}
                  </table>
                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div className="col-md-12 tableResponsive tagImageEditrListTble mt-4">
                <div className="tableOuterWidth table_width_md">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-3">Content </th>
                        <th className="col-3">Remarks</th>
                        <th className="col-2">Requested</th>
                        <th className="col-2">Approved</th>
                        <th className="col-2 ">Action</th>
                      </tr>
                    </thead>
                    <div className="table_Outerscroll">
                      {requestedStatus?.approved?.map((data, i) => (
                        <tbody className="table_Outertbody pb-3">
                          <tr>
                            <td className="col-3">{data.content_text}</td>

                            <td className="col-3">
                              {editingIndex === i ? (
                                <textarea
                                  type="text"
                                  value={remark}
                                  onChange={handleRemarkNameChange}
                                  className="w-100 form-control"
                                />
                              ) : (
                                <span>{data.remarks}</span>
                              )}
                              {editingIndex !== i ? (
                                <GoPencil
                                  className="ml-2"
                                  onClick={() =>
                                    handleEditClickRemark(i, data.remarks)
                                  }
                                />
                              ) : (
                                ""
                              )}
                              {editingIndex === i && (
                                <div className="mt-2">
                                  <button
                                    onClick={() =>
                                      handleUpdateClickRemark(i, data)
                                    }
                                    className="btn btn-success"
                                  >
                                    Save
                                  </button>
                                  <button
                                    onClick={handleCancelClick}
                                    className="btn btn-secondary ml-1"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              )}
                            </td>
                            <td className="col-2">
                              <p>
                                {" "}
                                {moment(data.requested_date).format(
                                  "DD MMM YYYY"
                                )}
                              </p>
                              <p>{data.requested_by_name}</p>
                            </td>
                            <td className="col-2">
                              <p>
                                {" "}
                                {moment(data.approved_date).format(
                                  "DD MMM YYYY"
                                )}
                              </p>
                              <p>{data.approved_by_name}</p>
                            </td>
                            {/* <td className="col-4">
                              {data.remarks}
                            </td> */}
                            <td className="col-2">
                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={(e) => deleteRequest(data)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </div>
                  </table>
                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div className="col-md-12 tableResponsive tagImageEditrListTble mt-4">
                <div className="tableOuterWidth table_width_md">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-3">Content </th>
                        <th className="col-3">Remarks</th>
                        <th className="col-2">Requested</th>
                        <th className="col-2">Rejected</th>
                        <th className="col-2 ">Action</th>
                      </tr>
                    </thead>
                    <div className="table_Outerscroll">
                      {requestedStatus?.rejected?.map((data, i) => (
                        <tbody className="table_Outertbody pb-3">
                          <tr>
                            <td className="col-3">{data.content_text}</td>

                            <td className="col-3">
                              {editingIndex === i ? (
                                <textarea
                                  type="text"
                                  value={remark}
                                  onChange={handleRemarkNameChange}
                                  className="w-100 form-control"
                                />
                              ) : (
                                <span>{data.remarks}</span>
                              )}
                              {editingIndex !== i ? (
                                <GoPencil
                                  className="ml-2"
                                  onClick={() =>
                                    handleEditClickRemark(i, data.remarks)
                                  }
                                />
                              ) : (
                                ""
                              )}
                              {editingIndex === i && (
                                <div className="mt-2">
                                  <button
                                    onClick={() =>
                                      handleUpdateClickRemark(i, data)
                                    }
                                    className="btn btn-success"
                                  >
                                    Save
                                  </button>
                                  <button
                                    onClick={handleCancelClick}
                                    className="btn btn-secondary ml-1"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              )}
                            </td>
                            <td className="col-2">
                              <p>
                                {" "}
                                {moment(data.requested_date).format(
                                  "DD MMM YYYY"
                                )}
                              </p>
                              <p>{data.requested_by_name}</p>
                            </td>
                            <td className="col-2">
                              <p>
                                {" "}
                                {moment(data.approved_date).format(
                                  "DD MMM YYYY"
                                )}
                              </p>
                              <p>{data.approved_by_name}</p>
                            </td>
                            <td className="col-2">
                              <button
                                type="submit"
                                className="btn btn-success mr-1"
                                onClick={() => resendForApproval(data)}
                              >
                                Resend
                              </button>
                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={(e) => deleteRequest(data)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </div>
                  </table>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
        {showModal && (
          <div className="adv_modal">
            <div className="adv_modal_inner ">
              <div className="adv_modal_header d-flex align-itesm-center">
                <h3 class="image-heading">Festival & Events</h3>
                <button
                  type="button"
                  className="close ml-auto"
                  onClick={() => closeModal()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <Tabs>
                <TabList onClick={() => setEditingIndex(-1)}>
                  <Tab>New</Tab>
                  <Tab>History</Tab>
                </TabList>
                <TabPanel>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-2 mb-md-0">
                        <label for="exampleInputEmail1">Festival</label>
                        <span className="red ml-1">*</span>{" "}
                        <div className="d-md-flex">
                          <input
                            type="text"
                            name="festivalName"
                            id="festivalName"
                            onChange={(e) => handleInputChange(e)}
                            className="form-control"
                            value={festivalAndEventSave.festivalName}
                            placeholder="Festival"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-2 mb-md-0">
                        <label for="exampleInputEmail1">Description</label>
                        <span className="red ml-1">*</span>{" "}
                        <div className="d-md-flex">
                          <input
                            type="text"
                            name="festivalDescription"
                            id="festivalDescription"
                            onChange={(e) => handleInputChange(e)}
                            className="form-control"
                            placeholder="Description"
                            value={festivalAndEventSave.festivalDescription}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <button
                      type="submit"
                      class="btn btn-success mr-2"
                      onClick={saveFestival}
                    >
                      Save
                    </button>
                    <button
                      type="submit"
                      class="btn btn-secondary"
                      onClick={() => closeModal()}
                    >
                      Cancel
                    </button>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="col-md-12 tableResponsive tagImageEditrListTble ">
                    <div className="tableOuterWidth table_width_md_sm">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="col-1">Sl </th>
                            <th className="col-2">Festival</th>
                            <th className="col-2 ">Description</th>
                            <th className="col-2 ">Status</th>
                            <th className="col-5 ">Created Date</th>
                          </tr>
                        </thead>
                        <div className="table_Outerscroll tableheight">
                          {historyList.map((data, i) => (
                            <tbody className="table_Outertbody">
                              <tr>
                                <td className="col-1">{i + 1}</td>
                                <td className="col-2">
                                  {editingIndex === i ? (
                                    <input
                                      type="text"
                                      value={
                                        festivalAndEventUpdate.festivalName
                                      }
                                      onChange={handleFestivalNameChange}
                                      className="w-100 form-control"
                                    />
                                  ) : (
                                    <span>{data.festival_name}</span>
                                  )}
                                  {editingIndex !== i ? (
                                    <GoPencil
                                      className="ml-2"
                                      onClick={() =>
                                        handleEditClick(
                                          i,
                                          data.festival_name,
                                          data.description
                                        )
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td className="col-2">
                                  {editingIndex === i ? (
                                    <input
                                      type="text"
                                      value={
                                        festivalAndEventUpdate.festivalDescription
                                      }
                                      onChange={handleDescriptionChange}
                                      className="w-100 form-control"
                                    />
                                  ) : (
                                    <span>{data.description}</span>
                                  )}
                                </td>
                                <td className="col-2">
                                  <Switch
                                    className="switch_case"
                                    checked={data.is_active === 1}
                                    onChange={(checked) => {
                                      statusChanging(
                                        data,
                                        data.is_active === 1 ? 0 : 1
                                      );
                                    }}
                                  ></Switch>
                                </td>
                                <td className="col-5">
                                  <div className="d-flex align-items-center">
                                    {formatDate(data.created_date)}
                                    {editingIndex === i && (
                                      <div className="mt-2 ml-3">
                                        <button
                                          onClick={() =>
                                            handleUpdateClick(i, data)
                                          }
                                          className="btn btn-success"
                                        >
                                          Save
                                        </button>
                                        <button
                                          onClick={handleCancelClick}
                                          className="btn btn-secondary ml-1"
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </div>
                      </table>
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        )}

        {showApprovalPopup && (
          <ApproverPopup
            onClose={closePopup}
            groupData={groupData}
            textContent={content}
            typeOfContent={"Text Content"}
            // campaignOptions={campaignOptions}
            selectedCampaign={campaign}
          />
        )}

        {showAddCampaignPopup && (
          <AddCampaignPopup
            onClose={closePopup}
            campaignGetData={contentInit}
            editProps=""
          />
        )}
        <div className="loder_div" hidden />
        <Footer />
      </div>
    </div>
  );
}

export default Generator;
