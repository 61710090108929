import React, { Component } from 'react';
import $ from "jquery"
import ImageUploadPopup from "./uploadImagepopup.jsx"
import images1 from "../images/images1.jpg"
import images2 from "../images/images2.jpg"
import images3 from "../images/images3.jpg"
import closeImg from "../images/close.png"
import closeImg1 from "../images/close1.png"
import uploadImg from "../images/upload.png"

class ImageList extends Component {
    constructor(props) {
        super(props);
        this.state={
            status:""
        }
    }
    componentDidMount(){
        $(".image-sublist-img").click(function(){
            $(this).addClass("image-sublist-img-active")
        });
        $("#analisePopup").hide();
    }
    
    addEditSpec = () => {
        $("#analisePopup").show();
    };
    cancelPopup() {
        $("#analisePopup").hide();
    }
    handleCallbackStatus = (status) =>{
        this.setState({status: status})
    }
render() {
	return (
            <div className="upload-right-section">
                <div className="image-list-wrapper">
                    <div className="image-list-head text-right">
                        <button className="image-list-upload" type="submit" 
                        onClick={() =>
                            this.addEditSpec()
                        }>
                            <img src={uploadImg} width={17} className="mr-1"></img> Upload
                        </button>
                    </div>
                    <div className="image-list-section">
                        {
                            this.props.listImage.map((data,i)=>(
                            <div className="image-sublist" >
                            
                            <div  className="checkbox_div">
                                <input type="checkbox" id={"html"+i} className="checkbox_input"/>
                                <label htmlFor={"html"+i} className="checkbox_label"></label>
                            </div>
                            <img  src={data.image} className="img-fluid image-sublist-img"></img>
                            <p className="image-sublist-name">{data.image_name}</p>
                        </div>
                            ))
                        }

                    </div>
                </div>


                
      <div className="popup-overlay" id="analisePopup">
        <div className="popup-contentDiv">
          <div className="modal-popup">
            
            <span
              className="closepopupqual">Upload Files
              <img  id="txtCancel"  onClick={() => this.cancelPopup()} src={closeImg1} alt="Close" className="img-fluid" width="30" />
            </span>
            <div className="content">
              <ImageUploadPopup key={this.state.status}
              imgdata={this.props.listImage}
              setStatus={this.handleCallbackStatus}
              setImageStatus={this.props.setImageStatus(this.state.status)}
              closeBtnClick={this.cancelPopup}
              />

            </div>
          </div>
        </div>
      </div>
            </div>
	);
}
}

export default ImageList;
