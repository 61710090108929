import React, { useState, useEffect, useRef } from 'react'
import { backendDashboardApi } from "../apiService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Icon from "react-feather";
import {
 FaEye,
 FaTrashAlt
} from "react-icons/fa";

import { useNavigate } from 'react-router-dom';
import Pagination from "react-js-pagination";
function GenerateInvoiceTable() {
  const [invoiceLists, setInvoiceLists] = useState([]);
  const [noDataFlag, setNoDataFlag] = useState(false);
  const [activePage, setActivePage] = useState(1)
  const [totalCount, setTotalCount] = useState("")
  const [areaOffice, setAreaOffice] = useState('');
  const [invoiceNo, setInvoiceNo] = useState('');
  const [invoiceDate, setInvoiceDate] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [areaOfficeList, setAreaOfficeList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    handleInvoiceListing();
    handleAreaOfficeListing();

  }, []);



  const handleAreaOfficeListing = () => {
    const postData = {
      "functionName": "areaOfficeListing",
      "accountId": window.sessionStorage.getItem("switchingOrgId"),
    }
    backendDashboardApi(postData).then((data) => {
      console.log("🚀 ~ backendDashboardApi ~ data setAreaOfficeList:", data)
      if (data.success === "1") {
        setAreaOfficeList(data.result)
      } else {
        setAreaOfficeList([])
      }
    });
  }

  const handleInvoiceDateSelect = (date) => {
    console.log("Selected start date:", date);
    setInvoiceDate(date);
  };

  const handleInvoiceDateChange = (date) => {
    console.log("Date changed:", date);
    setInvoiceDate(date);
  };
  const handleDueDateSelect = (date) => {
    console.log("Selected start date:", date);
    setDueDate(date);
  };

  const handleDueDateChange = (date) => {
    console.log("Date changed:", date);
    setDueDate(date);
  };

  const handleInvoiceListing = () => {
    const postData = {
      "functionName": "invoiceLists",
      "account_id": window.sessionStorage.getItem("switchingOrgId"),
      "invoiceNumber": "",
      "areaOffice": "",
      "invoiceDate": "",
      "dueDate": "",
      "offset": 0,
      "max": 10
    }
    backendDashboardApi(postData).then((data) => {
      console.log("🚀 ~ backendDashboardApi ~ data handleInvoiceListing:", data)
      if (data.success === "1") {
        setInvoiceLists(data.result.resultData)
        setNoDataFlag(false)
        setTotalCount(data.result.totalCount)
        setActivePage(1)
      } else {
        setInvoiceLists([])
        setNoDataFlag(true)
      }
    });
  }

  const handleEditClick = (selectedStartDate, areaOffice, receivedAmount,id,invoiceStatus) => {
    console.log("🚀 ~ backendDashboardApi ~ selectedStartDate1111:", selectedStartDate)
    navigate('/invoices/invoicedownload', {
      state: {
        selectedStartDate,
        areaOffice,
        receivedAmount,id,invoiceStatus
      }
    });
  }


  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = 10;
    } else {
      offset =
        parseInt(pageNumber - 1) * 10;
      max = 10;
    }
    setActivePage(pageNumber);
    const postData = {
      "functionName": "invoiceLists",
      "account_id": window.sessionStorage.getItem("switchingOrgId"),
      "invoiceNumber": invoiceNo,
      "areaOffice": areaOffice,
      "invoiceDate": invoiceDate,
      "dueDate": dueDate,
      "max": max,
      "offset": offset
    }
    backendDashboardApi(postData).then((data) => {
      console.log("🚀 ~ backendDashboardApi ~ data handleInvoiceListing:", data)
      if (data.success === "1") {
        setInvoiceLists(data.result.resultData)
        setTotalCount(data.result.totalCount)
        setNoDataFlag(false)

      } else {
        setInvoiceLists([])
        setNoDataFlag(true)
      }
    });
  };

  const handleSearch = () => {
    let invoiceDateParsed;
    let dueDateParsed;
    if (invoiceDate) {
      invoiceDateParsed = Date.parse(invoiceDate);
    } else {
      invoiceDateParsed = "";
    }

    if (dueDate) {
      dueDateParsed = Date.parse(dueDate);
    } else {
      dueDateParsed = "";
    }

    const postData = {
      "functionName": "invoiceLists",
      "account_id": window.sessionStorage.getItem("switchingOrgId"),
      "invoiceNumber": invoiceNo,
      "areaOffice": areaOffice,
      "invoiceDate": invoiceDateParsed,
      "dueDate": dueDateParsed,
      "max": 10,
      "offset": 0
    }
    backendDashboardApi(postData).then((data) => {
      console.log("🚀 ~ backendDashboardApi ~ postData:", postData)
      console.log("🚀 ~ backendDashboardApi ~ data handleInvoiceListing:", data)
      if (data.success === "1") {
        setInvoiceLists(data.result.resultData)
        setTotalCount(data.result.totalCount)
        setNoDataFlag(data.result.resultData.length > 0 ? false : true);

      } else {
        setInvoiceLists([])
        setNoDataFlag(true)
      }
    });
  }

  const handleClear = () => {
    handleInvoiceListing();
    setAreaOffice("");
    setInvoiceNo("");
    setInvoiceDate("");
    setDueDate("");
  }

  const handleDeleteClick = (id) => {
    const confirmation = window.confirm("Are you sure you want to delete this invoice?");

    if (confirmation) {
      const postData = {
        functionName: "invoiceDelete",
        invoiceID: id,
      };

      backendDashboardApi(postData).then((data) => {
        if (data.success === "1") {
          console.log("Invoice deleted successfully.");
          handleInvoiceListing();
        } else {
          setInvoiceLists([]);
        }
      });
    } else {
      console.log("Deletion canceled.");
    }
  };




  return (
    <div>
      <div className="organisation-div organization-card bg-white">
        <div class="subhead mb-3">Invoice History</div>

        <div className="form-row mt-3 mb-3 download-invoice-wrapper" style={{ alignItems: "center" }}>
          <div className="col-md-3 mb-3">
            <label>Area Office</label>
            <select className="form-control" value={areaOffice}
              onChange={(e) => {
                setAreaOffice(e.target.value);
              }}>
              <option value="">Select</option>
              {areaOfficeList && areaOfficeList.map((data) => (
                <option value={data.area_office}>{data.area_office}</option>
              ))}
            </select>
          </div>
          <div className="col-md-3 mb-3">
            <label>Invoice No</label>
            <input type='text' className="form-control" placeholder='Invoice No' value={invoiceNo}
              onChange={(e) => {
                setInvoiceNo(e.target.value);
              }} />
          </div>
          <div className="col-md-2 mb-3">
            <label>Invoice Date</label>
            <DatePicker
              className="form-control datepickerInvoiceInput"
              selected={invoiceDate}
              onSelect={handleInvoiceDateSelect}
              onChange={handleInvoiceDateChange}
              // showMonthDropdown
              autoComplete='off'
              showMonthYearPicker
              showYearDropdown
              dropdownMode="select"
              placeholderText='Select Invoice Date'
              dateFormat="MM/yyyy"
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          </div>
          <div className="col-md-2 mb-3">
            <label>Due Date</label>
            <DatePicker
              className="form-control datepickerInvoiceInput"
              selected={dueDate}
              onSelect={handleDueDateSelect}
              onChange={handleDueDateChange}
              // showMonthDropdown
              showMonthYearPicker
              autoComplete='off'
              showYearDropdown
              dropdownMode="select"
              placeholderText='Select Due Date'
              dateFormat="MM/yyyy"
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          </div>
          <div className="col-md-2 mb-3">
            <button className='btn btn-primary btn-dwonlodInvoice BtnSubmitInv' onClick={() => handleSearch()}>Search</button>
            <button className='btn btn-primary btn-dwonlodInvoice BtnSubmitInv ml-2' onClick={() => handleClear()}>Clear</button>
          </div>

        </div>

        {invoiceLists.length > 0 &&
          <div className="col-md-12 tableResponsive">
            <div className="tableOuterWidth ">
              <table className="table">
                <thead>
                  <tr>
                    <th className="col-1">Sl.No</th>
                    <th className="col-2">Invoice No</th>
                    <th className="col-1">Invoice Date</th>
                    <th className="col-1">Created On</th>
                    <th className="col-1">Approved Date</th>
                    <th className="col-1">Due Date</th>
                    <th className="col-2">Area Office</th>
                    <th className="col-1">Amount Received</th>
                    <th className="col-1">Invoice Status</th>
                    <th className="col-1">Action</th>
                  </tr>
                </thead>
                <div class="table_Outerscroll">
                  <>
                    {invoiceLists.map((item, index) => (
                      <tbody key={{ index }} className="table_Outertbody">
                        <tr>
                          <td className="col-1">{index + 1}</td>
                          <td className="col-2">{item.invoice_no} </td>
                          <td className="col-1">{new Date(item.invoice_date).toLocaleDateString('en-GB')}</td>
                          <td className="col-1"> {item.created_on ? new Date(item.created_on).toLocaleDateString('en-GB') : ""}  </td>
                          <td className="col-1"> {item.approved_date ? new Date(item.approved_date).toLocaleDateString('en-GB') : ""}  </td>
                          <td className="col-1"> {item.due_date ? new Date(item.due_date).toLocaleDateString('en-GB') : ""}  </td>
                          <td className="col-2"> {item.area_office}  </td>
                          <td className="col-1">{item.amount_received.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</td>
                          <td className="col-1">{item.invoice_status_name}</td>
                          <td className="col-1" >
                            
                            <>
                            {item.invoice_status_name !== "Void" &&
                            <FaEye title="View Invoice" style={{ fontSize: "18px" }} onClick={() => handleEditClick(new Date(item.invoice_date), item.area_office, item.amount_received,item.id,item.invoice_status_name)} />
                            }
                            {item.invoice_status_name !== "Finalized" &&
                            <FaTrashAlt title="Delete Invoice" style={{ fontSize: "16px", marginLeft:"7px" }} onClick={() => handleDeleteClick(item.id)} />}
                          </>
                          </td>
                        </tr>

                      </tbody>
                    ))}
                  </>
                </div>
              </table>

              <div className="d-flex justify-content-right">
                {totalCount > 10 ?
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                  />
                  : ""}
              </div>
            </div>
          </div>
        }
        {noDataFlag &&
          <>
            <tr id="nodata">
              <div className="content-sub-wrapper col-md-12 px-0">
                <div className="content-sub">
                  <p>No Data Found</p>
                </div>
              </div>
            </tr>
          </>
        }

      </div>
    </div>
  );
}
export default GenerateInvoiceTable;
