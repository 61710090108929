import React, { Component } from "react";
import * as Icon from "react-feather";
import Dashboardimage from "../images/left_icons/dashboard.png";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import OrganisationLeading from "./organisation-leading";
import OrganisationReport from "./organisation-report";
import GenerateInvoice from '../invoices/generateInvoice.jsx'
import GenerateInvoiceTable from '../invoices/generateInvoiceTable.jsx'
import Tab2 from "./tab2.jsx";
import Tab4 from "./tab4.jsx";
import ProductType from "./product-type";
class CreateInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <div className="main_wrapper dsh-wrapper invHistoryWrap">
                <LeftMenu />
                <div className="userListWrapper userListWrapper-dash">
                    <Header heading="Generate Invoice" headerImage={Dashboardimage} />
                    <div className="container-fluid tabs-main-wrapper">

                        <Tabs>
                            <TabList>
                                <Tab>Invoice History</Tab>
                                <Tab>Generate Invoice</Tab>
                            </TabList>
                            <TabPanel>
                                <GenerateInvoiceTable />
                            </TabPanel>
                            <TabPanel>
                                <GenerateInvoice />
                            </TabPanel>
                        </Tabs>
                    </div>
                    <Footer />
                </div>
                <div className="loder_div" hidden />
            </div>
        );
    }
}
export default CreateInvoice;
