import React, { Component } from "react";
import * as Icon from "react-feather";
import "../css/bootstrap.css";
import "../css/style.css";
import $ from "jquery";
import { backendApi } from "../apiService";
import { Link } from "react-router-dom";
var orgadmin = window.sessionStorage.getItem("role") == "ORG_ADMIN";
const organization = !window.sessionStorage.getItem("switchingOrgId")
  ? window.sessionStorage.getItem("organization")
  : window.sessionStorage.getItem("switchingOrgId");
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationList: [],
      setOrganizationId: "",
      userList: [],
      selectedOfOrganization: "",
      organizationPopUpvalue: "",
      organizationListIFUserLoginedIn: [],
      selectedOrganizationData: [],
      // organizationAllotment:
      //   window.sessionStorage.getItem("organizationAllotment") === "undefined"
      //     ? []
      //     : JSON.parse(window.sessionStorage.getItem("organizationAllotment")),
    };
  }
  fillData() {
    if (window.sessionStorage.getItem("switchUserId") !== "") {
      $("#switchUserId").val(window.sessionStorage.getItem("switchUserId"));
    }
  }
  componentDidMount() {
    this.getOrganizationData();
    if (window.sessionStorage.getItem("role") === "ROLE_USER") {
      $("#roleSelector").attr("hidden", true);
    } else if (window.sessionStorage.getItem("role") !== "ROLE_SUPER_ADMIN") {
      $("#roleSelector").attr("hidden", false);
      this.getUserList("");
    }
    // else {
    //   $("#roleSelector").attr("hidden", true);
    // }
    if (window.sessionStorage.getItem("switchingOrgId") !== "") {
      this.getUserList(window.sessionStorage.getItem("switchingOrgId"));
    }
    if (window.sessionStorage.getItem("switchUserId") !== "") {
      this.getUserList(window.sessionStorage.getItem("switchingOrgId"));
      $("#roleSelector").attr("hidden", false);
      $("#goBackSelector").attr("hidden", false);
    }
  }
  getUserList = (e) => {
    var req = {
      functionName: "listUserByOrganization",
      siteId: e === "" ? window.sessionStorage.getItem("organizationId") : e,
    };
    // $(".loder_div").attr("hidden", false);
    backendApi(req).then((data) => {
      if (data.success === "1") {
        // $(".loder_div").attr("hidden", true);
        this.setState({ userList: data.result });
        this.fillData();
      } else {
        // $(".loder_div").attr("hidden", true);
      }
    });
  };
  selectOrganization = (e, type) => {
    var flag = "";
    if (type == "MainPage") {
      flag = e;
    } else {
      flag = e.target.value;
    }
    if (flag != "") {
      window.sessionStorage.setItem("switchUserName", "");
      window.sessionStorage.setItem("switchUserOrganization", "");
      window.sessionStorage.setItem("switchingOrgId", flag);
      window.sessionStorage.setItem("switchUserModules", "");
      window.sessionStorage.setItem("switchUserId", "");
      window.sessionStorage.setItem("switchUserRole", "");
      if (window.sessionStorage.getItem("role") !== "ROLE_USER")
        flag === "0"
          ? $("#roleSelector").attr("hidden", true)
          : $("#roleSelector").attr("hidden", false);
      this.setState({ setOrganizationId: flag });
      var req = {
        functionName: "listUserByOrganization",
        siteId: flag,
      };
      for (let i = 0; i < this.state.organizationList.length; i++) {
        if (this.state.organizationList[i].id == flag) {
          window.sessionStorage.setItem(
            "social_tags",
            this.state.organizationList[i].social_tags
          );
          window.sessionStorage.setItem(
            "account_id",
            this.state.organizationList[i].account_id
          );
          window.sessionStorage.setItem(
            "switchUserOrganization",
            this.state.organizationList[i].organisation_name
          );
          window.sessionStorage.setItem(
            "enable_insight_scheduler",
            this.state.organizationList[i].enable_insight_scheduler
          );
        }
      }
      backendApi(req).then((data) => {
        if (data.success === "1") {
          this.setState({ userList: data.result });
          this.fillData();
          this.urlLocalPage();
          window.sessionStorage.setItem("categoryIdPopUpSet", "1");
        } else {
          window.location.href = "/dashboard";
        }
      });
    }
  };
  getOrganizationData = () => {
    const req = {
      functionName: "getSiteByUser",
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      role:
        window.sessionStorage.getItem("switchUserRole") === ""
          ? window.sessionStorage.getItem("role")
          : window.sessionStorage.getItem("switchUserRole"),
    };
    backendApi(req).then((data) => {
      if (data.success === "1") {
        this.setState({
          organizationList: data.data,
        });
        //Review this block for future use===============================
        //Code Start=====================================================
        if (orgadmin) {
          window.sessionStorage.setItem(
            "social_tags",
            data.data[0].social_tags
          );
          window.sessionStorage.setItem("account_id", data.data[0].account_id);
        } else {
          var organization =
            window.sessionStorage.getItem("switchUserOrganization") !== ""
              ? window.sessionStorage.getItem("switchUserOrganization")
              : window.sessionStorage.getItem("organization");
          for (let i = 0; i < data.result.length; i++) {
            if (data.data[i].organisation_name == organization.toLowerCase()) {
              window.sessionStorage.setItem(
                "social_tags",
                data.data[i].social_tags
              );
              window.sessionStorage.setItem(
                "account_id",
                data.data[i].account_id
              );
            }
          }
          // window.sessionStorage.setItem(
          //   "social_tags",
          //   data.result[0].social_tags
          // );
        }
        //Code End=====================================================
      }
    });
    /////////////////////////////////////////////////////////
    var postData = {
      functionName: "getSiteByUser",
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      role:
        window.sessionStorage.getItem("switchUserRole") === ""
          ? window.sessionStorage.getItem("role")
          : window.sessionStorage.getItem("switchUserRole"),
    };
    backendApi(postData).then((data) => {
      if (data.success === "1") {
        this.setState({ organizationListIFUserLoginedIn: data.data });
        if (data.data.length == 1) {
          window.sessionStorage.setItem(
            "switchingOrgId",
            JSON.stringify(data.data[0].id)
          );
          this.setState({ setOrganizationId: data.data[0].id });
          if (
            window.sessionStorage.getItem("localpage_url_link") ||
            window.sessionStorage.getItem("local_page_cms_link") ||
            window.sessionStorage.getItem("local_page_Folder_Name") ||
            window.sessionStorage.getItem("local_page_isActive")
          ) {
            this.urlLocalPage();
          }
        }
      }
    });
  };
  removeLoginSessions = () => {
    window.sessionStorage.setItem("isLogin", "");
    window.sessionStorage.setItem("role", "");
    window.sessionStorage.setItem("userId", "");
    window.sessionStorage.setItem("organization", "");
    window.sessionStorage.setItem("modules", "");
    window.sessionStorage.setItem("switchUserModules", "");
    window.sessionStorage.setItem("switchUserId", "");
    window.sessionStorage.setItem("switchUserRole", "");
    window.sessionStorage.setItem("switchingOrgId", "");
    window.sessionStorage.setItem("switchUserOrganization", "");
    window.sessionStorage.setItem("emailsession", "");
    window.sessionStorage.setItem("switchUserName", "");
    window.sessionStorage.setItem("social_tags", "");
    window.sessionStorage.setItem("account_id", "");
    window.sessionStorage.setItem("pageIndexMergeAudit", "");
    window.sessionStorage.setItem("categoryIdPopUpSet", "");
    window.sessionStorage.removeItem("localpage_url_link");
    window.sessionStorage.removeItem("local_page_Folder_Name");
    window.sessionStorage.removeItem("local_page_cms_link");
    window.sessionStorage.removeItem("local_page_isActive");
    window.location.href = "/";
  };
  switchUser() {
    window.location.reload();
  }
  switchBack() {
    window.sessionStorage.setItem("categoryIdPopUpSet", "0");
    window.sessionStorage.setItem("switchingOrgId", "");
    window.sessionStorage.setItem("switchUserModules", "");
    window.sessionStorage.setItem("switchUserId", "");
    window.sessionStorage.setItem("switchUserRole", "");
    window.sessionStorage.setItem("switchUserName", "");
    window.sessionStorage.setItem("switchUserOrganization", "");
    window.location.href = "/dashboard";
    for (let i = 0; i < this.state.organizationList.length; i++) {
      if (
        this.state.organizationList[i].organisation_name ==
        window.sessionStorage.getItem("organization").toLowerCase()
      ) {
        window.sessionStorage.setItem(
          "social_tags",
          this.state.organizationList[i].social_tags
        );
      }
    }
  }
  selectUser(e) {
    console.log("hi");
    debugger;
    let userObject = JSON.parse(e.target.value);
    var req = {
      functionName: "userModuleList",
      userId: userObject.id,
    };
    backendApi(req).then((data) => {
      if (data.success === "1") {
        window.sessionStorage.setItem(
          "switchUserModules",
          JSON.stringify(data.result)
        );
        let something = data.result.some((obj) => obj.moduleId == 6);
        if (data.result.some((obj) => obj.moduleId == 6)) {
          this.urlLocalPage();
        }
        window.sessionStorage.setItem("switchUserId", userObject.id);
        window.sessionStorage.setItem("switchUserRole", userObject.role);
        window.sessionStorage.setItem(
          "switchUserName",
          userObject.first_name + " " + userObject.last_name
        );
        window.sessionStorage.setItem(
          "switchUserOrganization",
          userObject.organisation_name
        );
      } else {
        window.location.href = "/dashboard";
      }
    });
  }
  urlLocalPage = () => {
    var req = {
      functionName: "organizationList",
      role:
        window.sessionStorage.getItem("switchUserRole") === ""
          ? window.sessionStorage.getItem("role")
          : window.sessionStorage.getItem("switchUserRole"),
      organization: !window.sessionStorage.getItem("switchingOrgId")
        ? window.sessionStorage.getItem("organization")
        : window.sessionStorage.getItem("switchingOrgId"),
      namesOnly: orgadmin ? "" : "false",
    };
    backendApi(req).then((data) => {
      if (data.success === "1") {
        if (data.result.length > 0) {
          data.result.filter((item) => {
            if (item.id == this.state.setOrganizationId) {
              if (item.local_is_active === 1) {
                window.sessionStorage.setItem(
                  "localpage_url_link",
                  item.local_page_link
                );
                window.sessionStorage.setItem(
                  "local_page_cms_link",
                  item.local_page_cms_link
                );
                window.sessionStorage.setItem(
                  "local_page_Folder_Name",
                  item.project_folder
                );
                window.sessionStorage.setItem(
                  "local_page_isActive",
                  item.local_is_active
                );
              } else {
                window.sessionStorage.removeItem("localpage_url_link");
                window.sessionStorage.removeItem("local_page_cms_link");
                window.sessionStorage.removeItem("local_page_Folder_Name");
                window.sessionStorage.removeItem("local_page_isActive");
              }
            }
          });
        }
        $("#goBackSelector").attr("hidden", false);
        window.location.href = "/dashboard";
      } else {
        window.sessionStorage.removeItem("localpage_url_link");
        window.sessionStorage.removeItem("local_page_cms_link");
        window.sessionStorage.removeItem("local_page_Folder_Name");
        window.sessionStorage.removeItem("local_page_isActive");
        window.location.href = "/dashboard";
      }
    });
  };

  render() {
    return (
      //   return (
      <>
        <div className="dash_heading">
          <div className="leftdash_heading">
            {/* <Icon.Home /> */}
            <img src={this.props.headerImage} alt="Dashboard" />
            {/* <img src={LocateIt} alt="LocateIt" /> */}
            <span id="headerName" className="headerName">
              {this.props.heading}
              <span className="sub_heading"> {this.props.subheading}</span>
            </span>
          </div>
          <div className="rightdash_heading">
            <ul className="nav nav_switch">
              {window.sessionStorage.getItem("role") === "ROLE_SUPER_ADMIN" ? (
                <li>
                  <div className="did-floating-label-content">
                    <select
                      value={
                        window.sessionStorage.getItem("switchingOrgId") !== ""
                          ? window.sessionStorage.getItem("switchingOrgId")
                          : this.state.setOrganizationId
                      }
                      id="organisation_name"
                      className="did-floating-select"
                      onChange={(e) => this.selectOrganization(e)}
                    >
                      <option value="0">Select</option>
                      {this.state.organizationListIFUserLoginedIn.map(
                        (data, i) => (
                          <option
                            key={i + 1}
                            label={data.organisation_name}
                            value={data.id}
                          >
                            {data.organisation_name}
                          </option>
                        )
                      )}
                    </select>
                    <label className="did-floating-label">Organization</label>
                  </div>
                </li>
              ) : (
                <li>
                  <div className="did-floating-label-content">
                    {/* {console.log(this.state.setOrganizationId, "hhhhhhhhhhhh")} */}
                    <select
                      //  value={this.state.setOrganizationId}
                      value={
                        window.sessionStorage.getItem("switchingOrgId") !== ""
                          ? window.sessionStorage.getItem("switchingOrgId")
                          : this.state.setOrganizationId
                      }
                      className="did-floating-select"
                      onChange={(e) => this.selectOrganization(e)}
                    >
                      <option value="0">Select</option>
                      {JSON.parse(
                        window.sessionStorage.getItem("organizationAllotment")
                      ).map((data, i) => (
                        <option
                          key={i + 1}
                          label={data.organisation_name}
                          value={data.id}
                        ></option>
                      ))}
                    </select>
                    <label className="did-floating-label">Organization</label>
                  </div>
                </li>
              )}
              {/* <li>
              <div className="did-floating-label-content">
                <select className="did-floating-select">
                  <option value="1">Alabama</option>
                  <option value="2">Boston</option>
                  <option value="3">Ohaio</option>
                  <option value="4">New York</option>
                  <option value="5">Washington</option>
                </select>
                <label className="did-floating-label">Select</label>
              </div>
              </li> */}
              <li id="roleSelector">
                <div className="did-floating-label-content">
                  <select
                    value={
                      window.sessionStorage.getItem("switchUserId") !== ""
                        ? window.sessionStorage.getItem("switchUserId")
                        : ""
                    }
                    className="did-floating-select"
                    id="switchUserId"
                    onChange={(e) => this.selectUser(e)}
                  >
                    <option value="0">
                      {window.sessionStorage.getItem("switchUserName") != ""
                        ? window.sessionStorage.getItem("switchUserName")
                        : "Select"}
                    </option>
                    {this.state.userList.map((data, i) => (
                      <>
                        {data.first_name + " " + data.last_name !==
                          window.sessionStorage.getItem("switchUserName") && (
                          <option
                            key={i + 1}
                            label={
                              data.first_name +
                              " " +
                              data.last_name +
                              " (" +
                              data.display_name +
                              ")"
                            }
                            value={JSON.stringify(data)}
                          />
                        )}
                      </>
                    ))}
                  </select>
                  <label className="did-floating-label">User</label>
                </div>
              </li>
              {/* <li>
              <div className="did-floating-label-content">
                <input type='text' className="did-floating-select" value=''/>                     
                <label className="did-floating-label">Input</label>
                </div>
              </li> */}
              <li id="goBackSelector" hidden>
                <Icon.ArrowLeftCircle onClick={() => this.switchBack()} />
                {/* {window.sessionStorage.getItem("switchUserId") ? (
                 
                ) : (
                  <Icon.ArrowRightCircle onClick={() => this.switchUser()} />
                )} */}
                {/* <Icon.ArrowRightCircle onClick={() => this.switchUser()} /> */}
              </li>
            </ul>
            <ul>
              <li>
                <Icon.User />
                <div className="dash_listdrop">
                  <ul>
                    <li className="dash_listdrop_user">
                      <a href="#">
                        <Icon.UserCheck />
                        <span className="dash_listdrop_usertext">
                          {window.sessionStorage.getItem("userName")}
                        </span>
                      </a>
                    </li>
                    {/* <li>
                      <a href="/settings">
                        <Icon.Mail />
                        Email Id
                      </a>
                    </li> */}
                    <li>
                      <a href="/dashboard">
                        <Icon.Grid />
                        Dashboard
                      </a>
                    </li>
                    {window.sessionStorage.getItem("switchUserModules") === ""
                      ? JSON.parse(
                          window.sessionStorage.getItem("modules")
                        ).some((obj) => obj.moduleId === 18) && (
                          <li>
                            <a href="/settings">
                              <Icon.Settings />
                              Settings
                            </a>
                          </li>
                        )
                      : JSON.parse(
                          window.sessionStorage.getItem("switchUserModules")
                        ).some((obj) => obj.moduleId === 18) && (
                          <li>
                            <a href="/settings">
                              <Icon.Settings />
                              Settings
                            </a>
                          </li>
                        )}

                    <li>
                      <a href="/changePassword">
                        <Icon.Lock />
                        Change Password
                      </a>
                    </li>
                    <li>
                      <a onClick={() => this.removeLoginSessions()} href="/">
                        <Icon.LogOut />
                        Logout
                      </a>
                    </li>
                    {/* <li>
                      <Link to="/changePassword"></Link>
                    </li> */}
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* && (organizationListIFUserLoginedIn).length>1) */}
        {window.sessionStorage.getItem("switchingOrgId") == "" &&
          this.state.organizationListIFUserLoginedIn.length > 1 && (
            <div className="headerPopup">
              <div className="module_popup" id="popUporganization">
                <div className="module_innerpopup">
                  <div className="module_close"></div>
                  <div className="module_header text-center">
                    Choose Organization
                  </div>
                  <div className="module_body">
                    <div className="module_popupsearch_wrpr">
                      <div className="module_popupsearch">
                        <div className="row col-lg-9 col-md-12 organizationPop">
                          <div className="col-lg-8 col-md-6">
                            <select
                              id="organizationSelection"
                              className="form-control"
                              value={this.state.selectedOfOrganization}
                              onChange={(e) =>
                                this.setState({
                                  selectedOfOrganization: e.target.value,
                                  organizationPopUpvalue: e.target.value,
                                })
                              }
                            >
                              <option selected="selected" value={""}>
                                {" "}
                                Select Organization{" "}
                              </option>
                              {this.state.organizationListIFUserLoginedIn.map(
                                (item, i) => (
                                  <option key={item.id} value={item.id}>
                                    {item.organisation_name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                          <div className="col-md-3 col-lg-2 p-0 goButton">
                            <button
                              type="button"
                              className="btn btn-save mb-1"
                              onClick={() =>
                                this.selectOrganization(
                                  this.state.organizationPopUpvalue,
                                  "MainPage"
                                )
                              }
                            >
                              Go
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="loder_div" hidden /> */}
            </div>
          )}
      </>
    );
  }
}
export default Header;
