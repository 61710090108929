import React, { Component } from "react";
import * as Icon from "react-feather";
import Dashboardimage from "../images/left_icons/dashboard.png";
import LeftMenu from "../layout/leftMenu";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import "../css/bootstrap.css";
import "../css/style.css";
import ModuleList from "../admin/module_list";
import ProductCatalog from "../images/left_icons/Product-Catalog.png";
import Audit from "../images/left_icons/audit.png";
import Review from "../images/left_icons/review.png";
import Roles from "../images/left_icons/roles.png";
import Module from "../images/left_icons/module.png";
import Data from "../images/left_icons/data.png";
import LiveMonitoring from "../images/left_icons/live.png";

var globalModuleArray = [];
class Newuser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modulesList: window.sessionStorage.getItem("switchUserModules")
        ? JSON.parse(window.sessionStorage.getItem("switchUserModules"))
        : JSON.parse(window.sessionStorage.getItem("modules")),
      role: window.sessionStorage.getItem("switchUserRole")
        ? window.sessionStorage.getItem("switchUserRole")
        : window.sessionStorage.getItem("role"),
      productCatalogStatus: false,
      auditStatus: false,
      reviewStatus: false,
      userManagementStatus: false,
      rolesManagementStatus: false,
      moduleManagementStatus: false,
      organizationSettingsStatus: false,
    };
  }
  componentDidMount() {
    if (
      window.sessionStorage.getItem("role") === "ROLE_SUPER_ADMIN" &&
      window.sessionStorage.getItem("switchingOrgId") === ""
    ) {
      globalModuleArray.push(true);
      this.setState({
        userManagementStatus: true,
        rolesManagementStatus: true,
        moduleManagementStatus: true,
        organizationSettingsStatus: true,
        reviewStatus: true,
        auditStatus: true,
        productCatalogStatus: true,
      });
    } else {
      console.log(this.state.modulesList);
      globalModuleArray.push(
        this.state.modulesList.some((e) => e.moduleId === 8)
      );
      globalModuleArray.push(
        this.state.modulesList.some((e) => e.moduleId === 7)
      );
      globalModuleArray.push(
        this.state.modulesList.some((e) => e.moduleId === 4)
      );
      this.setState({
        userManagementStatus: this.state.modulesList.some(
          (e) => e.moduleId === 2
        ),
        rolesManagementStatus: this.state.modulesList.some(
          (e) => e.moduleId === 3
        ),
        moduleManagementStatus: this.state.modulesList.some(
          (e) => e.moduleId === 1
        ),
        organizationSettingsStatus: this.state.modulesList.some(
          (e) => e.moduleId === 18
        ),
        reviewStatus: this.state.modulesList.some((e) => e.moduleId === 8),
        auditStatus: this.state.modulesList.some((e) => e.moduleId === 7),
        productCatalogStatus: this.state.modulesList.some(
          (e) => e.moduleId === 4
        ),
      });
    }
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper">
          <Header heading="Dashboard" headerImage={Dashboardimage} />
          <div className="container">
            <div className="user_listviewcontent">
              {globalModuleArray.length !== 0 ? (
                <div className="user_listviewhead">
                  <span>Products </span>
                </div>
              ) : (
                ""
              )}
              <div className="row">
                {this.state.productCatalogStatus === true ? (
                  <div className="col-lg-3 col-md-6">
                    <div className="card tagtree_card border_info">
                      <div className="card-body">
                        <div className="tagtree_boxleft">
                          <Link to="/home">
                            <div className="view_cardbody">
                              <img src={ProductCatalog} alt="Product Catalog" />
                              <span className="tagtree_boxheading">
                                Product Catalog
                              </span>
                            </div>
                          </Link>
                        </div>
                        <div className="tagtree_boxrignt"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {this.state.auditStatus === true ? (
                  <div className="col-lg-3 col-md-6">
                    <div className="card tagtree_card  border_danger">
                      <div className="card-body">
                        <div className="tagtree_boxleft">
                          <Link to="/audit">
                            <div className="view_cardbody">
                              <img src={Audit} alt="Audit" />
                              <span className="tagtree_boxheading">Audit</span>
                            </div>
                          </Link>
                        </div>
                        <div className="tagtree_boxrignt"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {this.state.reviewStatus === true ? (
                  <div className="col-lg-3 col-md-6">
                    <div className="card tagtree_card  border_warning">
                      <div className="card-body">
                        <div className="tagtree_boxleft">
                          <Link to="/review/index">
                            <div className="view_cardbody">
                              <img src={Review} alt="Review" />
                              <span className="tagtree_boxheading">Review</span>
                            </div>
                          </Link>
                        </div>
                        <div className="tagtree_boxrignt"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="user_listviewcontent">
              {this.state.role === "ROLE_SUPER_ADMIN" ||
              this.state.role === "ORG_ADMIN" ? (
                <div className="user_listviewhead">
                  <span>Admin</span>
                </div>
              ) : (
                ""
              )}

              <div className="user_listviewcontent">
                {/* <div className="row">
                    <div className="col-md-4 col-lg-4">
                      <div className="card bg_warning">
                        <Link to="/userlist" style={{ color: "#fff" }}>
                          <div className="card-body">
                            <div className="view_cardbody">
                              <Icon.Book />
                              <span>User Management</span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <div className="card bg_info">
                        <Link to="/moduleList" style={{ color: "#fff" }}>
                          <div className="card-body">
                            <div className="view_cardbody">
                              <Icon.CheckSquare />
                              <span>Module Management</span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <div className="card bg_success">
                        <Link to="/roleList" style={{ color: "#fff" }}>
                          <div className="card-body">
                            <div className="view_cardbody">
                              <Icon.Home />
                              <span>Roles Management</span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <div className="card bg_success">
                        <Link to="/settings" style={{ color: "#fff" }}>
                          <div className="card-body">
                            <div className="view_cardbody">
                              <Icon.Settings />
                              <span>Organization Settings</span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div> */}
                <div className="tagtree_boxdesigndiv">
                  <div className="row">
                    {this.state.userManagementStatus === true ? (
                      <div className="col-lg-3 col-md-6">
                        <div className="card tagtree_card  border_info">
                          <div className="card-body">
                            <div className="tagtree_boxleft">
                              <Link to="/userlist">
                                <div className="view_cardbody">
                                  <Icon.User />
                                  <span className="tagtree_boxheading">
                                    User Management
                                  </span>
                                </div>
                              </Link>
                            </div>
                            <div className="tagtree_boxrignt"></div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.rolesManagementStatus === true ? (
                      <div className="col-lg-3 col-md-6">
                        <div className="card tagtree_card  border_danger">
                          <div className="card-body">
                            <div className="tagtree_boxleft">
                              <Link to="/roleList">
                                <div className="view_cardbody">
                                  <img src={Roles} alt="Roles" />
                                  <span>Roles Management</span>
                                </div>
                              </Link>
                            </div>
                            <div className="tagtree_boxrignt"></div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.moduleManagementStatus === true ? (
                      <div className="col-lg-3 col-md-6">
                        <div className="card tagtree_card  border_success">
                          <div className="card-body">
                            <div className="tagtree_boxleft">
                              <Link to="/moduleList">
                                <div className="view_cardbody">
                                  <img src={Module} alt="module" />
                                  <span>Module Management</span>
                                </div>
                              </Link>
                            </div>
                            <div className="tagtree_boxrignt"></div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.role === "ROLE_SUPER_ADMIN" ||
                    this.state.role === "ORG_ADMIN" ||
                    this.state.role === "ROLE_ADMIN" ? (
                      <div className="col-lg-3 col-md-6">
                        <div className="card tagtree_card  border_info">
                          <div className="card-body">
                            <div className="tagtree_boxleft">
                              <Link to="/settings">
                                <div className="view_cardbody">
                                  <Icon.Settings />
                                  <span>Organization Settings</span>
                                </div>
                              </Link>
                            </div>
                            <div className="tagtree_boxrignt"></div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.role === "ROLE_SUPER_ADMIN" ||
                    this.state.role === "ORG_ADMIN" ||
                    this.state.role === "ROLE_ADMIN" ? (
                      <div className="col-lg-3 col-md-6">
                        <div className="card tagtree_card  border_info">
                          <div className="card-body">
                            <div className="tagtree_boxleft">
                              <Link to="/businessAccount/index">
                                <div className="view_cardbody">
                                  <Icon.Settings />
                                  <span>Google Business Account</span>
                                </div>
                              </Link>
                            </div>
                            <div className="tagtree_boxrignt"></div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.role === "ROLE_SUPER_ADMIN" ||
                    this.state.role === "ROLE_ADMIN" ? (
                      <div className="col-lg-3 col-md-6">
                        <div className="card tagtree_card  border_info">
                          <div className="card-body">
                            <div className="tagtree_boxleft">
                              <Link to="/dataDashboard">
                                <div className="view_cardbody">
                                  <img src={Data} alt="Data" />
                                  <span>Data Management</span>
                                </div>
                              </Link>
                            </div>
                            <div className="tagtree_boxrignt"></div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-lg-3 col-md-6">
                      <div className="card tagtree_card  border_warning">
                        <div className="card-body">
                          <div className="tagtree_boxleft">
                            <Link to="/liveMonitoring">
                              <div className="view_cardbody">
                                <img
                                  src={LiveMonitoring}
                                  alt="Live Monitoring"
                                />
                                <span>Live Monitoring</span>
                              </div>
                            </Link>
                          </div>
                          <div className="tagtree_boxrignt"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="card tagtree_card  border_warning">
                        <div className="card-body">
                          <div className="tagtree_boxleft">
                            <Link to="/createzone">
                              <div className="view_cardbody">
                                <img
                                  src={LiveMonitoring}
                                  alt="Live Monitoring"
                                />
                                <span>Create Zone</span>
                              </div>
                            </Link>
                          </div>
                          <div className="tagtree_boxrignt"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="card tagtree_card  border_warning">
                        <div className="card-body">
                          <div className="tagtree_boxleft">
                            <Link to="/mapunmap">
                              <div className="view_cardbody">
                                <img
                                  src={LiveMonitoring}
                                  alt="Live Monitoring"
                                />
                                <span>Map / Unmap Accounts</span>
                              </div>
                            </Link>
                          </div>
                          <div className="tagtree_boxrignt"></div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-6">
                  <div className="card border_danger">
                    <div className="card-body">
                      <div className="tagtree_boxleft">
                        <p className="tagtree_boxheading">Audit</p>
                      </div>
                      <div className="tagtree_boxrignt">sdfsdf</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="card border_success">
                    <div className="card-body">
                      <div className="tagtree_boxleft">
                        <p className="tagtree_boxheading">Review</p>
                      </div>
                      <div className="tagtree_boxrignt">sdfsdf</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="card border_warning">
                    <div className="card-body">
                      <div className="tagtree_boxleft">
                        <p className="tagtree_boxheading">Total Orders</p>
                      </div>
                      <div className="tagtree_boxrignt">sdfsdf</div>
                    </div>
                  </div>
                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default Newuser;
