import React, { useState } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import rightArrow from "../images/arrow.png";
import LeftMenu from "../layout/leftMenu";
import { backendApi, re } from "../apiService";
import rolesimage from "../images/left_icons/roles.png";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import Header from "../layout/header";
import Footer from "../layout/footer";
import * as Icon from "react-feather";
var locationData
var phNum = /^\d{10}$/;
var emailValidation =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
function CreateNewLocationSecondery(props) {
    const location = useLocation();
    const [Title, setTitle] = useState("");
    const [firstName, setFirstName] = useState("");
    const [description, setdescription] = useState(window.sessionStorage.getItem("switchUserOrganization") !== ""
        ? window.sessionStorage.getItem("switchUserOrganization")
        : window.sessionStorage.getItem("organization"));
    const [phone, setPhone] = useState("");
    const [adminstriveArea, setadminstriveArea] = useState("");
    const [locality, setlocality] = useState("");
    const [address, setaddress] = useState("");
    const [postalCode, setpostalCode] = useState("");
    const [websiteUrl, setwebsiteUrl] = useState("");
    const [Verificationmethod, setVerificationmethod] = useState("");
    const [VerificationCodeEmail, setVerificationCodeEmail] = useState("");
    const [verificationRole, setverificationRole] = useState("");
    const [VerificationmethodPhoneNumber, setVerificationmethodPhoneNumber] = useState("");
    const [VerificationCode, setVerificationCode] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    useEffect(() => {
        locationData = JSON.parse(window.sessionStorage.getItem("dataPushData"))
        setlocality(locationData.locality)
        setadminstriveArea(locationData.administrativeArea)
        setpostalCode(locationData.postalCode)
        setFirstName(locationData.full_name);
        setTitle(locationData.full_name);
        setwebsiteUrl(locationData.domain)
        setPhone(locationData.mobile_no)
        setVerificationCodeEmail(locationData.email_id)
        // let array = [];
        // let string = array[0];
        //  let stringArray = locationData.address.split(',');
        // stringArray.splice(-3);
        // let newString = stringArray.join(',');
        setaddress(locationData.address);
        console.log(locationData, "locationData.addresslocationData.address");
    }, [locationData]);
    const claim_initiated_Save = (e) => {
        e.preventDefault()
        var api = {
            functionName: "updatePushStatus",
            site_id:
                window.sessionStorage.getItem("switchingOrgId") === ""
                    ? window.sessionStorage.getItem("organizationId")
                    : window.sessionStorage.getItem("switchingOrgId"),
            id: locationData.id,
            comment: "",
            "full_name": Title,
            "address": address,
            "mobile_no": phone,
            "email_id": VerificationCodeEmail,
            "locality": locality,
            "location_id": "",
            "administrativeArea": locationData.administrativeArea,
            "postalCode": locationData.postalCode,
            "verification_code": "",
            "g_pin": "",
        };
        console.log("apiapiapi",api)
        backendApi(api).then((data) => {
            if (data.success === "1") {
                window.location.href = `/claim_business/nodataFoundBulkUpload/${locationData.UID}`
            }
        })
    }
    return (
        <div className="main_wrapper">
            <LeftMenu />
            <div className="userListWrapper">
                <Header heading="Create New Google Location" headerImage={rolesimage} />
                <div className="container-fluid">
                    <>
                        <div className="tableOuterWidth">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="col-1">ID</th>
                                        <th className="col-2" style={{ maxWidth: "12%" }}>
                                            Business Name
                                        </th>
                                        <th className="col-7" style={{ maxWidth: "63%" }}>
                                            Address
                                        </th>
                                        {/* <th className="col-1">Status</th> */}
                                    </tr>
                                </thead>
                                <div class="table_Outerscroll">
                                    {/* <tbody className="table_Outertbody">
                    <tr>
                      <td className="col-1">
                        {locationData.id}
                      </td>
                      <td className="col-2" style={{ maxWidth: "12%" }}>
                        {locationData.full_name}
                      </td>
                      <td className="col-7" style={{ maxWidth: "63%" }}>
                        {locationData.address}
                      </td>
                      {flag == false && (
                          <td>
                            <button
                              className="btn btn-primary"
                              // onClick={(e) =>
                              //   pushClick(data)
                              // }
                            >
                              Push
                            </button>
                          </td>
                        )}
                      <td className="col-1">
                          {locationData.status}
                        </td>
                    </tr>
                  </tbody> */}
                                </div>
                            </table>
                        </div>
                        <form className="user_formdiv col-md-10" id="createLocationForm">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1"> Name</label>
                                        <input
                                            type="text"
                                            onChange={(e) => setFirstName(e.target.value)}
                                            value={firstName}
                                            className="form-control"
                                            id="firstName"
                                            aria-describedby="emailHelp"
                                            placeholder="Name"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Title</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="lastName"
                                            onChange={(e) => setTitle(e.target.value)}
                                            value={Title}
                                            aria-describedby="emailHelp"
                                            placeholder="Title"
                                        />
                                    </div>
                                </div>
                                <hr></hr>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Description</label>
                                        <input
                                            value={description}
                                            onChange={(e) => setdescription(e.target.value)}
                                            className="form-control"
                                            id="emailId"
                                            aria-describedby="emailHelp"
                                            placeholder="Description"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Phone No</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="phoneNo"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            maxLength={10}
                                            aria-describedby="emailHelp"
                                            placeholder="Phone No"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">
                                            Administrative Area
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="phoneNo"
                                            // maxLength={10}
                                            value={adminstriveArea}
                                            onChange={(e) => setadminstriveArea(e.target.value)}
                                            aria-describedby="emailHelp"
                                            placeholder=" Administrative Area"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Locality</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="phoneNo"
                                            // maxLength={10}
                                            value={locality}
                                            onChange={(e) => setlocality(e.target.value)}
                                            aria-describedby="emailHelp"
                                            placeholder="Locality"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Address</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="phoneNo"
                                            // maxLength={10}
                                            onChange={(e) => setaddress(e.target.value)}
                                            value={address}
                                            aria-describedby="emailHelp"
                                            placeholder="Address"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1"> Postal Code</label>
                                        <input
                                            type="text"
                                            onChange={(e) => setpostalCode(e.target.value)}
                                            value={postalCode}
                                            className="form-control"
                                            id="firstName"
                                            aria-describedby="emailHelp"
                                            placeholder="Name"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Website Url</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="phoneNo"
                                            // maxLength={10}
                                            onChange={(e) => setwebsiteUrl(e.target.value)}
                                            value={websiteUrl}
                                            aria-describedby="emailHelp"
                                            placeholder="Website Url"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form_bttn">
                                <button
                                    type="submit"
                                    className="btn btn-primary submitButton cancel_bttn"
                                    onClick={(e) => claim_initiated_Save(e)}
                                >
                                    <span>Create Location</span>
                                </button>
                                <Link to="/claimBusiness"
                                    className="btn btn-primary submitButton cancel_bttn"
                                >
                                    <span >Cancel</span>
                                </Link>
                            </div>
                        </form>
                    </>
                </div>
                <Footer />
            </div>
            <div className="loder_div" hidden />
        </div>
    );
}
export default CreateNewLocationSecondery;
