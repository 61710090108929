import React, { Component } from "react";
// import FileSaver from 'file-saver';
import "../css/bootstrap.css";
import "../css/style.css";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import Accessibiltyimage from "../images/left_icons/Accessibility-and-visibility.png";
import { TagsInput } from "react-tag-input-component";
import cloudImg from "../images/cloud-computing.png";
import deleteImg from "../images/Group122.png";
import DragDropFile from "../audit/dragDropFile";
import DataInput from "../audit/dataInput";
import { backendApi, pythonBackendApi } from "../apiService";
import { Link } from "react-router-dom";
import GoogleIcon from "../images/google.png";
import * as XLSX from "xlsx";
import uploadImg from "../images/upload.png";
import $ from "jquery";
import closeImg1 from "../images/close1.png";
import * as Icon from "react-feather";
import exportFromJSON from "export-from-json";
import moment from "moment";
import Pagination from "react-js-pagination";
import VisibilityAccuracyGraph from "./visibility_accuracyGraph";
import ReactDatePicker from "react-datepicker";
import GoogleDetailsIcon from "../images/icon04.png";
import "react-datepicker/dist/react-datepicker.css";
var tempPostList = [];
var total_location = 0;
var address_match = 0;
var address_match_percentage = 0;
var phone_no_match = 0;
var phoneno_match_percentage = 0;
var business_name_match_percentage = 0;
var business_name_match = 0;
var totalNumber = 0;
var latestUpdate = 0;
var filteredCount = 0;
var totalReportCount = 0;
export default class VisibilityReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      totalCount: 0,
      backupdata: [],
      tempLocationsList: [],
      languageListTTS: [],
      apiDataList: [],
      csvPushData: [],
      administrativeAreaList: [],
      AdministrativeArea: "",
      localityList: [],
      locality: "",
      tempSearchLocationsList: [],
      locationIdsList: [],
      viewDetailsId: "",
      addressOnlyFlag: false,
      phoneOnlyFlag: false,
      isFilter: false,
      businessOnlyFlag: false,
      response: "Waiting for Data.....",
      insightsStartDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 6
      ).toISOString(),
      insightsEndDate: new Date().toISOString(),
      showAddress: false,
      showGbp: false,
      showCategory: false,
      showBusiness: false,
      showPhone: false,
      submode: "",
    };
  }
  componentDidMount() {
    // this.newDataListing();
    this.visibilityAndAccuracyGraph();
    this.getAdministrativeArea();
  }
  //??ListingFunstion
  dataListing() {
    var count;
    var req = {
      functionName: "getVisibilityAndAccuracyDataListing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((data) => {
      count = data.result.length - 1;
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        tempPostList.push(...data.result);
        this.setState({
          totalCount: data.result.length,
        });
        const indexOfLastPost = this.state.currentPage * 25;
        const indexOfFirstPost = indexOfLastPost - 25;
        const currentPosts = data.result.slice(
          indexOfFirstPost,
          indexOfLastPost
        );
        this.setState({
          apiDataList: currentPosts,
          backupdata: data.result,
        });
        $(".loder_div").attr("hidden", true);
      } else {
        this.setState({
          response: "No data Found",
        });
      }
    });
  }
  newDataListing(id) {
    var count;
    const apiReq = {
      functionName: "getVisibilityAndAccuracyDataByDate",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      visibility_and_accuracy_id: id,
    };
    backendApi(apiReq).then((data) => {
      count = data.result.length - 1;
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        tempPostList.push(...data.result);
        this.setState({
          totalCount: data.result.length,
        });
        const indexOfLastPost = this.state.currentPage * 25;
        const indexOfFirstPost = indexOfLastPost - 25;
        const currentPosts = data.result.slice(
          indexOfFirstPost,
          indexOfLastPost
        );
        totalReportCount = data.result.length;
        console.log("totalcount", totalReportCount);
        this.setState({
          apiDataList: currentPosts,
          backupdata: data.result,
        });
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  //**Pagination
  handleActivePage = (e) => {
    this.setState({
      currentPage: e,
    });
    const indexOfLastPost = e * 25;
    const indexOfFirstPost = indexOfLastPost - 25;
    let tempList = this.state.backupdata;
    const currentPosts = tempList.slice(indexOfFirstPost, indexOfLastPost);
    this.setState({
      apiDataList: currentPosts,
    });
  };
  visibilityAndAccuracyGraph() {
    business_name_match = 0;
    phone_no_match = 0;
    address_match = 0;
    var lastyear = new Date(new Date().getFullYear() - 1, 0, 1);
    var startDate = new Date(lastyear.getFullYear(), 0, 1).getTime();
    var req = {
      functionName: "getVisibilityAndAccuracyData",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      start_date: moment(this.state.insightsStartDate).format("YYYY-MM-DD"),
      end_date: moment(new Date()).format("YYYY-MM-DD"),
    };
    backendApi(req).then((data) => {
      if (data.success === "1") {
        if (!data?.result?.length > 0) {
          this.setState({
            response: "No data Found",
          });
        }
        var totLength = data.result.length;
        data.result.forEach((object) => {
          latestUpdate = object.created_on;
          total_location = object.total_location;
          address_match =
            parseInt(object.address_match) + parseInt(address_match);
          phone_no_match =
            parseInt(object.phone_no_match) + parseInt(phone_no_match);
          business_name_match =
            parseInt(object.business_name_match) +
            parseInt(business_name_match);
        });
        this.setState(
          {
            flag: true,
            viewDetailsId: data.result[totLength - 1].id,
          },
          () => {
            this.newDataListing(this.state.viewDetailsId);
          }
        );
      } else {
        this.setState({
          response: "No data Found",
        });
      }
    });
  }
  //**Export Function
  patientListDownload() {
    var re = this.state.apiDataList;
    const data = [];
    for (var item = 0; item < re.length; item++) {
      data.push({
        "Excel Address": re[item].excelAddress,
        "Address Match": `<span style="color:blue">Address Match Audit:</span>${re[item].googleAddress}<br/><span style="color:red">Address Match Visibility:</span>${re[item].GBPaddressLines}`,
        "Phone Number": `${
          re[item].googlePhoneNO
            ? `<span style="color:blue">${re[item].googlePhoneNO}</span><br/>`
            : `<span style="color:blue">No Phone Number</span><br/>`
        }${
          re[item].excelPhoneNo
            ? `<span style="color:red">${re[item].excelPhoneNo}</span>`
            : `<span style="color:red">No number</span>`
        }`,
        "Business Name": `${
          re[item].googleName
            ? `<span style="color:blue">${re[item].googleName}</span><br/>`
            : `<span style="color:blue">No Phone Number</span><br/>`
        }${
          re[item].GBPtitle
            ? `<span style="color:red">${re[item].GBPtitle}</span>`
            : `<span style="color:red">No number</span>`
        }`,
        // 'Phone Number Audit': re[item].googlePhoneNumber,
        // 'Phone Number Visibility': re[item].phone_no,
        "Address Match ":
          re[item].address_match == 0 || re[item].address_match == "No"
            ? "No"
            : "Yes",
        "Phone Number Match":
          re[item].phoneno_match == 0 || re[item].phoneno_match == "No"
            ? "No"
            : "Yes",
        "Business Match":
          re[item].business_name_match == 0 ||
          re[item].business_name_match == "No"
            ? "No"
            : "Yes",
      });
    }
    const fileName = "download";
    const exportType = "xls";
    exportFromJSON({ data, fileName, exportType });
  }
  navigateToBusinessEdit = (data) => {
    window.sessionStorage.setItem("editBusinessAcountLocationId", data);
    window.location.href = "/business-details";
    // setTimeout(() => {
    //   navigate("/business_details");
    // }, 3000);
  };
  getAdministrativeArea() {
    const businessAccountData = {
      functionName: "getAdministrativeArea",
      account_id: window.sessionStorage.getItem("account_id"),
    };
    backendApi(businessAccountData).then((data) => {
      if (data.success === "1") {
        this.setState({
          administrativeAreaList: data.data.map(
            (obj) => obj.AdministrativeArea
          ),
        });
      } else {
        // $(".loder_div").attr("hidden", true);
      }
    });
  }
  // handleStateChange(value) {
  //   if (value !== "") {
  //     this.setState({
  //       // businessNameFilter: "",
  //       // businessAddress: "",
  //       localityList: [],
  //       // getTitleName: "",
  //     });
  //     const businessAccountData = {
  //       functionName: "getLocalityUnderAdministrativeArea",
  //       account_id: window.sessionStorage.getItem("account_id"),
  //       AdministrativeArea: value,
  //     };
  //     // $(".loder_div").attr("hidden", false);
  //     backendApi(businessAccountData).then((data) => {
  //       // $(".loder_div").attr("hidden", true);
  //       if (data.success === "1") {
  //         this.setState({
  //           localityList: data.data.map((obj) => obj.locality),
  //           AdministrativeArea: value,
  //         });
  //       } else {
  //         this.setState({
  //           localityList: [],
  //         });
  //         // $(".loder_div").attr("hidden", true);
  //       }
  //     });
  //   } else {
  //     this.setState({
  //       AdministrativeArea: "",
  //       LocalityList: "",
  //       getCityList: [],
  //       globalFilterLocationsList: this.state.tempSearchLocationsList,
  //     });
  //   }
  // }
  // handleCityChange(value) {
  //   console.log("hiiii", value);
  //   this.setState({
  //     locality: value,
  //     getTitleName: "",
  //     businessNameFilter: "",
  //     businessAddress: "",
  //   });
  // }
  searchAndFilterLocations() {
    console.log("apiDataListapiDataList", this.state.apiDataList);
    console.log("locationIdsListlocationIdsList", this.state.locationIdsList);
    // const filteredArr = this.state.apiDataList.filter((obj) =>
    //   this.state.locationIdsList.includes(obj.location_id)
    // );
    let filteredArray = this.state.apiDataList;
    if (this.state.AdministrativeArea != "") {
      filteredArray = this.state.apiDataList.filter((obj) =>
        obj.googleAddress.includes(this.state.AdministrativeArea)
      );
    }
    if (this.state.locality != "") {
      filteredArray = this.state.apiDataList.filter((obj) =>
        obj.googleAddress.includes(this.state.locality)
      );
    }

    console.log("filteredArr", filteredArray);
  }
  onLocationFilter(address) {
    console.log(
      "this.state.apiDataListthis.state.apiDataList",
      this.state.apiDataList
    );
    let backupdata = this.state.backupdata;
    let filteredArray = backupdata.filter(
      (obj) =>
        obj.excelAddress?.toLowerCase().includes(address.toLowerCase()) ||
        obj.googleAddress?.toLowerCase().includes(address.toLowerCase())
    );
    console.log("filteredArrayfilteredArray", filteredArray);
    this.setState({
      apiDataList: filteredArray,
      backupdata: backupdata,
    });
  }
  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (!checked) {
      this.setState(
        {
          [name]: checked,
          isFilter: false,
        },
        this.filterData
      );
    } else {
      this.setState(
        {
          [name]: checked,
          isFilter: true,
        },
        this.filterData
      );
    }
  };
  filterData = () => {
    console.log("filteredData", this.state.apiDataList);
    const { backupdata, showAddress, showPhone, showBusiness } = this.state;

    const filteredData = backupdata.filter((item) => {
      if (
        (showAddress && item.visibility_address === "Yes") ||
        (showPhone && item.visibility_phone === "Yes") ||
        (showBusiness && item.visibility_business_name === "Yes")
      ) {
        return true;
      }
      return false;
    });
    filteredCount = filteredData.length;
    console.log("filteredcount", filteredCount);
    this.setState({
      apiDataList: filteredData,
    });
  };
  render() {
    const { backupdata, apiDataList } = this.state;
    const displayData = apiDataList.length > 0 ? apiDataList : backupdata;
    console.log("DData", displayData);
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          {displayData.length ? (
            <div className="float-right ">
              <button
                class="btn btn-primary mr-3 mt-1 float-right export_btn"
                type="button"
                onClick={(e) => this.patientListDownload(e)}
                id="export"
              >
                Export
              </button>
            </div>
          ) : (
            ""
          )}

          <div className="container-fluid">
            <div className="row col-md-12 visibiltyGpagh">
              {/* <img src={Accessibiltyimage} alt="Dashboard" className="imgHead"/> */}
              <h5 className="">Visibility</h5>
            </div>
            <div className="row row-search">
              {/* <div className="col-lg-2 col-md-4 col-sm-6">
                  <select
                    className="form-control"
                    onChange={(e) => this.handleStateChange(e.target.value)}
                    value={this.state.AdministrativeArea}
                  >
                    <option defaultValue value="0">
                      State
                    </option>
                    {this.state.administrativeAreaList.map((data) => (
                      <option value={data}>{data}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <select
                    className="form-control"
                    onChange={(e) => this.handleCityChange(e.target.value)}
                    value={this.state.locality}
                  >
                    <option defaultValue value="0">
                      City
                    </option>
                    {this.state.localityList.map((data) => (
                      <option value={data}>{data}</option>
                    ))}
                  </select>
                </div> */}
              <div className="col-lg-5 col-md-12 col-sm-6 margin_checkBoxes">
                <input
                  className="form-control mb-0"
                  type="text"
                  value={this.state.businessNameFilter}
                  placeholder="Search by Location"
                  onChange={(e) => this.onLocationFilter(e.target.value)}
                />
              </div>
              <div className="col-lg-5 col-md-10 col-sm-6 margin_checkBoxes">
                <span className="check_boxes">
                  <label htmlFor="" className="mr-1 mb-0">
                    Address Match{" "}
                  </label>
                  <input
                    type="checkbox"
                    disabled={
                      this.state.showPhone == true ||
                      this.state.showBusiness == true ||
                      this.state.showCategory == true ||
                      this.state.showGbp == true
                        ? true
                        : false
                    }
                    name="showAddress"
                    onChange={(e) => this.handleCheckboxChange(e)}
                  />
                </span>
                <span className="check_boxes">
                  <label htmlFor="" className="mr-1 mb-0">
                    Phone Number Match{" "}
                  </label>
                  <input
                    type="checkbox"
                    disabled={
                      this.state.showAddress == true ||
                      this.state.showBusiness == true ||
                      this.state.showCategory == true ||
                      this.state.showGbp == true
                        ? true
                        : false
                    }
                    name="showPhone"
                    onChange={(e) => this.handleCheckboxChange(e)}
                  />
                </span>
                <span className="check_boxes">
                  <label htmlFor="" className="mr-1 mb-0">
                    Business Match{" "}
                  </label>
                  <input
                    type="checkbox"
                    name="showBusiness"
                    disabled={
                      this.state.showAddress == true ||
                      this.state.showPhone == true ||
                      this.state.showCategory == true ||
                      this.state.showGbp == true
                        ? true
                        : false
                    }
                    onChange={(e) => this.handleCheckboxChange(e)}
                  />
                </span>
              </div>
              <div className="col-lg-2 col-md-12 margin_checkBoxes">
                {this.state.isFilter && (
                  <div className="outOfReport margin_checkBoxes">
                    {filteredCount + " out of " + totalReportCount + " Reports"}
                  </div>
                )}
              </div>

              {/* <div className="col-lg-2 col-md-1 col-sm-6">
                  Adress Match{" "}
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        addressOnlyFlag: e.target.checked,
                      })
                    }
                  />
                </div>
                <div className="col-lg-2 col-md-1 col-sm-6">
                  Phone Number Match{" "}
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        phoneOnlyFlag: e.target.checked,
                      })
                    }
                  />
                </div>
                <div className="col-lg-2 col-md-1 col-sm-6">
                  Business Match{" "}
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        businessOnlyFlag: e.target.checked,
                      })
                    }
                  />
                </div> */}
              {/* <div className="col-lg-2 col-md-2 col-sm-6">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => this.searchAndFilterLocations()}
                >
                  Search
                </button>
              </div> */}
            </div>
            {/* <div className="visibilityReport mt-2">
              <span className="visibilityReportText"><b>Total Location
              </b>  :{total_location}<div className="">(gfgf)</div></span>
              <span className="visibilityReportText"><b>Address Match
              </b>  : {address_match}<div className="">(gfgf)</div></span>
              <span className="visibilityReportText"><b>Phone Number Match
              </b>  :{phone_no_match}<div className="">(gfgf)</div></span>
              <span className="visibilityReportText"><b>Business Match
              </b>  : {business_name_match}<div className="">(gfgf)</div></span>
            </div> */}
            {/* pagination */}
            <div className="col-md-12 tableResponsive mt-5">
              {displayData.length > 0 ? (
                <div className="tableOuterWidth">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-1">Date</th>
                        {((this.state.addressOnlyFlag == false &&
                          this.state.phoneOnlyFlag == false &&
                          this.state.businessOnlyFlag == false) ||
                          this.state.addressOnlyFlag == true) && (
                          <>
                            <th className="col-2">Address</th>
                            <th className="col-1">Address Visibility </th>
                          </>
                        )}
                        {((this.state.addressOnlyFlag == false &&
                          this.state.phoneOnlyFlag == false &&
                          this.state.businessOnlyFlag == false) ||
                          this.state.phoneOnlyFlag == true) && (
                          <>
                            <th className="col-2">Phone Number</th>
                            {/* <th className="col-3">Bussiness Match</th> */}
                            <th className="col-1">Phone Number Visibility</th>
                          </>
                        )}
                        {((this.state.addressOnlyFlag == false &&
                          this.state.phoneOnlyFlag == false &&
                          this.state.businessOnlyFlag == false) ||
                          this.state.businessOnlyFlag == true) && (
                          <>
                            <th className="col-2">Business Name </th>
                            <th className="col-1">Business Name Visibility </th>
                            <th className="col-2">Website Url </th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <div class="table_Outerscroll">
                      {displayData.map((item, i) => (
                        <tbody className="table_Outertbody">
                          <tr>
                            <td className="col-1" rowspan="2">
                              {item?.maps_uri && (
                                <div className="mb-1">
                                  <a
                                    href={JSON.parse(item.maps_uri)}
                                    target="_blank"
                                    style={{
                                      textDecoration: "underline",
                                      color: "blue",
                                    }}
                                  >
                                    View on Google Maps.
                                  </a>
                                </div>
                              )}
                              <span>
                                {moment(item.created_on).format("DD/MM/YYYY")}
                              </span>
                              <span style={{ display: "block" }}>
                                {moment(item.created_on).format("hh:mm A")}
                              </span>
                              {item.location_id !== null && (
                                <span>
                                  <div className="mb-1">
                                    <img
                                      src={GoogleDetailsIcon}
                                      title="Edit Business Details"
                                      className="mr-3"
                                      onClick={() =>
                                        this.navigateToBusinessEdit(
                                          item.location_id
                                        )
                                      }
                                    />
                                  </div>
                                </span>
                              )}

                              <br></br>

                              {/* {item.category &&
                                            <span>
                                              ({item.category})
                                            </span>} */}
                            </td>
                            {/* <td className="col-3">
                                    <div  className="mb-1">
                                    {item.excel_category}
                                      </div>
                                      <hr/>
                                      <div>{item.category}</div>
                                    </td> */}
                            {((this.state.addressOnlyFlag == false &&
                              this.state.phoneOnlyFlag == false &&
                              this.state.businessOnlyFlag == false) ||
                              this.state.addressOnlyFlag == true) && (
                              <>
                                <td
                                  className={
                                    item.visibility_address === 0 ||
                                    item.visibility_address === "No"
                                      ? "col-2 redActive"
                                      : "col-2 greenActive"
                                  }
                                >
                                  <div className="mb-1">
                                    <label htmlFor="" className="fromAuditText">
                                      From Audit Excel
                                    </label>
                                    <div>{item.excelAddress}</div>
                                    <hr />
                                  </div>
                                  <label
                                    htmlFor=""
                                    className="fromVisibitiText"
                                  >
                                    From Google
                                  </label>
                                  {/* <hr className="m-0"/> */}
                                  <div>{item.googleAddress}</div>
                                </td>
                                {/* {item.visibility_address == 0 ||
                                item.address_match == "No" ? (
                                  <td className="col-1"> No</td>
                                ) : (
                                  <td className="col-1">Yes</td>
                                )} */}
                                <td className="col-1">
                                  {" "}
                                  {item.visibility_address}
                                </td>
                              </>
                            )}
                            {((this.state.addressOnlyFlag == false &&
                              this.state.phoneOnlyFlag == false &&
                              this.state.businessOnlyFlag == false) ||
                              this.state.phoneOnlyFlag == true) && (
                              <>
                                <td
                                  className={
                                    item.visibility_phone === 0 ||
                                    item.visibility_phone === "No"
                                      ? "col-2 redActive"
                                      : "col-2 greenActive"
                                  }
                                >
                                  <div className="mb-1">
                                    <label htmlFor="" className="fromAuditText">
                                      From Audit Excel
                                    </label>
                                    <div>
                                      {item?.excelPhoneNo
                                        ? item?.excelPhoneNo
                                        : "NA"}
                                    </div>
                                    <hr />
                                  </div>
                                  <label
                                    htmlFor=""
                                    className="fromVisibitiText"
                                  >
                                    From Google
                                  </label>
                                  {/* <hr className="m-0"/> */}
                                  <div>
                                    {item?.googlePhoneNO
                                      ? item?.googlePhoneNO
                                      : "NA"}
                                  </div>
                                </td>

                                <td className="col-1">
                                  {" "}
                                  {item.visibility_phone}
                                </td>
                              </>
                            )}
                            {((this.state.addressOnlyFlag == false &&
                              this.state.phoneOnlyFlag == false &&
                              this.state.businessOnlyFlag == false) ||
                              this.state.businessOnlyFlag == true) && (
                              <>
                                <td
                                  className={
                                    item.visibility_business_name === 0 ||
                                    item.visibility_business_name === "No"
                                      ? "col-2 redActive"
                                      : "col-2 greenActive"
                                  }
                                >
                                  <div className="mb-1">
                                    <label htmlFor="" className="fromAuditText">
                                      From Audit Excel
                                    </label>
                                    <div>
                                      {item?.excelName ? item?.excelName : "NA"}
                                    </div>
                                    <hr />
                                  </div>
                                  <label
                                    htmlFor=""
                                    className="fromVisibitiText"
                                  >
                                    From Google
                                  </label>
                                  {/* <hr className="m-0"/> */}
                                  <div>
                                    {item?.googleName ? item?.googleName : "NA"}
                                  </div>
                                </td>
                                <td className="col-1">
                                  {" "}
                                  {item.visibility_business_name}
                                </td>
                                {!Object.is(item?.Website, null) ? (
                                  <td className="col-1">
                                    <a
                                      href={item?.Website}
                                      target="_blank"
                                      style={{
                                        textDecoration: "underline",
                                        color: "blue",
                                      }}
                                    >
                                      {item?.Website}
                                    </a>
                                  </td>
                                ) : (
                                  <td className="col-1">
                                    <b>Website not available</b>
                                  </td>
                                )}
                              </>
                            )}
                            {/* <td>
                                            <div className="mb-1">
                                              <img
                                                src={GoogleDetailsIcon}
                                                title="Edit Business Details"
                                                className="mr-3"
                                                onClick={() =>
                                                  this.navigateToBusinessEdit(
                                                    item.location_id
                                                  )
                                                }
                                              />
                                            </div>
                                          </td> */}
                          </tr>
                        </tbody>
                      ))}
                    </div>
                  </table>
                </div>
              ) : (
                <h3>{this.state.response}</h3>
              )}
            </div>
            <div className="col-lg-10">
              <div className="pagination_main  tag_postListNext">
                <Pagination
                  hideDisabled
                  activePage={this.state.currentPage}
                  itemsCountPerPage={25}
                  totalItemsCount={this.state.totalCount}
                  pageRangeDisplayed={10}
                  onChange={(e) => this.handleActivePage(e)}
                />
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>

        <div className="loder_div" hidden />
      </div>
    );
  }
}
// export default VisibilityReports;
