import React, { useEffect, useState } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import QandA from "../images/left_icons/qa.png";
import { FiArrowRightCircle } from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { backendApi } from "../apiService";

function RequirementGatheringView() {
  const location = useLocation();
  const navigate = useNavigate();
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString();
  const [campaignData, setCampaignData] = useState("");
  const [textValue, setTextValue] = useState("");
  const [textValueDraft, setTextValueDraft] = useState("");
  const [answerStatus, setAnswerStatus] = useState("");
  const [edit, setEdit] = useState(false);
  const [answerId, setAnswerId] = useState();


  useEffect(() => {
    setCampaignData(location.state.props);
    console.log("AAAA", location.state.props);
    const filteredText = location.state.props.answer.filter((answer) =>
      answer.status == "draft"
    );
    if (filteredText) {
      setTextValueDraft(filteredText);
      setTextValue(filteredText);
    }
  }, []);

  const campaignInit = () => {
    const postData = {
      functionName: "campaign_listing_details",
      campaign_id: location.state.props.campaign_id
    };
    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          console.log("SAdfafsf", data);
          const filteredData = data.campaign_question.filter((answer) =>
            answer.campaign_questions_master_id
            === location.state.props.campaign_questions_master_id
          );
          console.log("Sbbbbb", filteredData);
          setCampaignData(filteredData[0]);
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };

  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  const campaignSave = (value) => {
    if (textValue.trim() === "") {
      alert("Please enter a question");
      return false;
    }
    const postData = {
      functionName: "campaign_question_answer_save",
      created_by:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      campaign_question_id: location.state.props.id,
      answer_text: textValue,
      status: value
    };
    console.log("SAdfafsf", postData);
    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          console.log("SAdfafsf", data);
          campaignInit();
          setTextValue("");
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };

  const campaignSaveDirect = (value,data,id) => {
    
    const postData = {
      functionName: "campaign_question_answer_update",
      sec_user_id:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      id: id,
      answer_text: data,
      status: value
    };
    console.log("SAdfafsf", postData);
    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          console.log("SAdfafsf", data);
          campaignInit();
          setTextValue();
          setEdit(false);
          setAnswerId();
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };

  const campaignSaveDirectEdit = (value) => {
    if (textValue.trim() === "") {
      alert("Please enter a question");
      return false;
    }
    const postData = {
      functionName: "campaign_question_answer_update",
      sec_user_id:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      id: answerId,
      answer_text: textValue,
      status: value
    };
    console.log("SAdfafsf", postData);
    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          console.log("SAdfafsf", data);
          campaignInit();
          setTextValue("");
          setEdit(false);
          setAnswerId();
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };

  const changeTextValue = (e,id) => {
    setEdit(true);
    setTextValue(e);
    setAnswerId(id)
  };
  const back = () => {
    navigate(-1);
  };

  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Requirement Gathering" headerImage={QandA} />
        <div className="container-fluid">
          <div className="container">
            <div className="requirement">
              <ul className="requirement_ul">
                <li className="requirement_li requirement_view_li">
                  <div className=" text-right">
                    <button
                      type="submit"
                      class="btn btn-secondary mr-2"
                      onClick={() => back()}
                    >
                      Back
                    </button>
                  </div>
                  <div className="requriement_summary">
                    <div className="summary_header w-100">
                      <div className="row mb-3 aling-items-center">
                        <div className="col-3 col-lg-1 col-md-2 col-sm-2">
                          <div className="summery-heading text-nowrap">Qus 1 :</div>
                        </div>
                        <div className="col-9 col-lg-11 col-md-10 col-sm-10 pl-0">
                          <div className="summary-para">
                            {campaignData.question_text}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3 col-lg-1 col-md-2 col-sm-2">
                          <div className="summery-heading  text-nowrap">Ans : </div>
                        </div>
                        <div className="col-9 col-lg-11 col-md-10 col-sm-10 pl-0">
                          {campaignData.answer?.map((answer, i) => (<div className="row mb-3">
                            <div className="col-md-10">
                              <div className="card">
                                <div className="card-body">
                                  {answer.answer_text}
                                </div>
                              </div>
                              <div className="requirement_date mt-2">Added by {answer.created_by_name}
                                <span className="text-muted">{formatDate(answer.created_on)}
                                </span></div>
                            </div>
                            <div className="col-md-2">
                              <div className="request_btns">
                                {answer.status !== "Approved" && <button className="btn btn-warning"
                                  onClick={() => changeTextValue(answer.answer_text,answer.id)} >Edit</button>}
                                {answer.status !== "Save" && answer.status !== "Approved" && <button className="btn btn-primary" onClick={() => campaignSaveDirect("Save", answer.answer_text,answer.id)}>Save</button>}
                                {answer?.status !== "Approved" && <button className="btn btn-success" onClick={() => campaignSaveDirect("Approved", answer.answer_text,answer.id)}>Approve</button>}
                              </div>
                            </div>
                          </div>))}
                          {edit?(<div className="row">
                           <div className="col-md-10">
                              <textarea
                                rows="4"
                                className="form-control-text-brdr form-control-text w-100"
                                value={textValue}
                                onChange={(e) => setTextValue(e.target.value)}
                              ></textarea>
                            </div>
                            <div className="col-md-2">
                              <div className="request_btns">
                                <button className="btn btn-warning" onClick={() => campaignSaveDirectEdit("Draft")}>Draft</button>
                                <button className="btn btn-primary" onClick={() => campaignSaveDirectEdit("Save")}>Save</button>
                                {/* <button className="btn btn-success" onClick={() => campaignSaveDirectEdit("Approved")}>Approve</button> */}
                              </div>
                            </div>
                          </div>):(
                            <div className="row">
                            <div className="col-md-10">
                               <textarea
                                 rows="4"
                                 className="form-control-text-brdr form-control-text w-100"
                                 value={textValue}
                                 onChange={(e) => setTextValue(e.target.value)}
                               ></textarea>
                             </div>
                             <div className="col-md-2">
                               <div className="request_btns">
                                 <button className="btn btn-warning" onClick={() => campaignSave("Draft")}>Draft</button>
                                 <button className="btn btn-primary" onClick={() => campaignSave("Save")}>Save</button>
                                 {/* <button className="btn btn-success" onClick={() => campaignSave("Approved")}>Approve</button> */}
                               </div>
                             </div>
                           </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>
      <div className="loder_div" hidden />
    </div>
  );
};

export default RequirementGatheringView;
