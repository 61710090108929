import React, { useState, useEffect } from "react";
import SaveAndValidation from "./settingsSaveAndValidation"
function SettingsTwitterCredentials(props) {
  var orgadmin = window.sessionStorage.getItem("role") == "ORG_ADMIN";
  const [twitterToken, settwitterToken] = useState((props.listingValuesForPassing.twitter_token != "null") ? props.listingValuesForPassing.twitter_token : "");
  const [twitterApiKey, settwitterApiKey] = useState((props.listingValuesForPassing.twitter_apikey != "null") ? props.listingValuesForPassing.twitter_apikey : "");
  const [twitterKeySecret, settwitterKeySecret] = useState((props.listingValuesForPassing.twitter_keySecret != "null") ? props.listingValuesForPassing.twitter_keySecret : "");
  useEffect(() => {
    if (props.listingValuesForPassing.twitter_token && (props.listingValuesForPassing.twitter_token != "null" || props.listingValuesForPassing.twitter_token != "undefined")) {
      settwitterToken((props.listingValuesForPassing.twitter_token))
    } else { settwitterToken("") }
    ////////////////
    if (props.listingValuesForPassing.twitter_apikey && (props.listingValuesForPassing.twitter_apikey != "null" || props.listingValuesForPassing.twitter_apikey != "undefined")) {
      settwitterApiKey((props.listingValuesForPassing.twitter_apikey))
    } else { settwitterApiKey("") }
    //////////////////////
    if (props.listingValuesForPassing.twitter_keySecret && (props.listingValuesForPassing.twitter_keySecret != "null" || props.listingValuesForPassing.twitter_keySecret != "undefined")) {
      settwitterKeySecret((props.listingValuesForPassing.twitter_keySecret))
    } else { settwitterKeySecret("") }
  }, [props.listingValuesForPassing.twitter_token, props.listingValuesForPassing.twitter_apikey, props.listingValuesForPassing.twitter_keySecret])
  return (
     
     <div className="row">
 <div className="col-md-12">
        <div className="user_formdiv">
          <div className="userform_head">Twitter Credentials</div>
          <div className="row userrow_form">
            <div className="col-md-4">
              <label className="col-form-label">
                Twitter ApiKey
              </label>
            </div>
            <div className="col-md-1">
              <span>:</span>
          </div>
            <div className="col-md-7">
              <input
                type="text"
                id="TwitterApiKey"
                onChange={(e) => {
                  settwitterApiKey(e.target.value);
                }}
                value={twitterApiKey}
                className="form-control"
                placeholder="Twitter ApiKey"
              />
            </div>
          </div>
          <div className="row userrow_form">
            <div className="col-md-4">
              <label className="col-form-label">
                Twitter KeySecret
              </label>
            </div>
            <div className="col-md-1">
              <span>:</span>
          </div>
            <div className="col-md-7">
              <input
                type="text"
                id="TwitterKeySecret"
                value={twitterKeySecret}
                onChange={(e) => {
                  settwitterKeySecret(e.target.value);
                }}
                className="form-control"
                placeholder=" Twitter KeySecret"
              />
            </div>
          </div>

          <div className="row userrow_form">
            <div className="col-md-4">
              <label className="col-form-label">
                Twitter Token
              </label>
            </div>
            <div className="col-md-1">
              <span>:</span>
          </div>
            <div className="col-md-7">
              <input
                type="text"
                id="TwitterToken"
                onChange={(e) => {
                  settwitterToken(e.target.value);
                }}
                value={twitterToken}
                className="form-control"
                placeholder="Twitter Token"
              />
            </div>
          </div>
          <SaveAndValidation
           getUserData={() => props.getUserData()}
            valueForSaved={[twitterToken, twitterApiKey, twitterKeySecret]}
            saveMode={"twitterCredential"}
            organizationId={props.organizationId} />
        </div>
      </div>
     </div>
 
  );

}
export default SettingsTwitterCredentials;
