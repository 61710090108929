import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { backendApi } from "../apiService";

const EnableInsights = () => {
  const [status, setStatus] = useState(
    window.sessionStorage.getItem("enable_insight_scheduler") == 1
  );

  const changeInsightStatus = (status) => {
    console.log("changedstatus",status);
    const newStatus = status === "active" ? 1 : 0;
    const apiReq = {
      functionName: "enableInsightSchedule",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      sec_user_id: window.sessionStorage.getItem("userId"),
      enable_insight_scheduler: newStatus,
    };
    console.log("req", apiReq);
    backendApi(apiReq).then((data) => {
      if (data.success == "1") {
        if (status === "active") {
          alert("Insights Scheduler Activated");
        } else {
          alert("Insights Scheduler Deactivated");
        }
        window.sessionStorage.setItem(
            "enable_insight_scheduler",
            newStatus
          );
          setStatus(newStatus == 1);
      } else {
        alert("Something went wrong");
        setStatus(
          window.sessionStorage.getItem("enable_insight_scheduler") == 1
        );
      }
    });
  };

  return (
    <div className="row tag_formLabel ">
      <span htmlFor="" className=" col-sm-4 col-xl-5 insightlabel">
        Enable Insights Scheduler
      </span>
      <div className="col-sm-8 col-xl-2">
        <Switch
          className="switch_case"
          checked={status}
          onChange={(checked) => {
            const status = checked ? "active" : "inactive";
            changeInsightStatus(status);
          }}
        />
      </div>
    </div>
  );
};

export default EnableInsights;
