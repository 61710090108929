import React, { useEffect, useState } from "react";
import Select from "react-select";
import { FiPlusCircle, FiTrash } from "react-icons/fi";
import { backendApi,re } from "../apiService";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import * as Icon from "react-feather";
import GroupsList from "../adv_generator/components/groupsList";
import AddGroup from "../adv_generator/components/addGroup";
import $ from "jquery";
import AddCampaignPopup from "../adv_generator/addCampaignPopup";


const SendApprovalPopup = ({
  onClose,
  groupData,
  textContent,
  questionContent,
  typeOfCandent,
  // campaignOptions,
  selectedCampaign,
  questionAnswerId,
  campaignCall,
}) => {
  console.log("questionAnswerId", questionAnswerId);
  console.log("questionContent", questionContent);
  const [approverGroup, setApproverGroup] = useState([]);
  const [campaign, setCampaign] = useState("");
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groupDatas, setGroupDatas] = useState([]);
  const [invitePopup, setInvitePopup] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedApproverGrp, setSelectedApproverGrp] = useState("");
  const [organizationList, setOrganizationList] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [statesList, setStatesList] = useState([]);
  const [showAddCampaignPopup, setShowAddCampaignPopup] = useState(false);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [allGroups, setAllGroups] = useState([]);

  useEffect(() => {
    contentInit();
    groupDataInit();
    getSiteByUser();
    getAdministrativeArea();
    setApproverGroup(
      groupData.map((data) => ({
        value: data.id,
        label: data.group_name,
      }))
    );
    if (selectedCampaign) {
      setCampaign(selectedCampaign);
    }
  }, []);

  const getAdministrativeArea = () => {
    const businessAccountData = {
      functionName: "getAdministrativeArea",
      account_id: window.sessionStorage.getItem("account_id"),
    };
    backendApi(businessAccountData).then((data) => {
      if (data.success == "1") {
        setStatesList(data.data.map((obj) => obj.AdministrativeArea));
      } else {
        setStatesList([]);
      }
    });
  };

  const getSiteByUser = () => {
    const api = {
      functionName: "getSiteByUser",
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      role:
        window.sessionStorage.getItem("switchUserRole") === ""
          ? window.sessionStorage.getItem("role")
          : window.sessionStorage.getItem("switchUserRole"),
    };
    const site_id =
      window.sessionStorage.getItem("switchingOrgId") === ""
        ? window.sessionStorage.getItem("organizationId")
        : window.sessionStorage.getItem("switchingOrgId");

    backendApi(api).then((data) => {
      console.log("🚀 ~ backendApi ~ data:", data);
      setOrganizationList(data.data.filter((o) => o.id == site_id)[0]);
    });
  };

  const handleAddGroup = () => {
    setSelectedGroups([]);
    setInvitePopup(true);
    // $("#assignGroup").attr("hidden", false);
  };

  const toggleModal3 = () => {
    $("#assignGroup").attr("hidden", false);
  };

  const closePopup = () => {
    setInvitePopup(false);
    setName("");
    setEmail("");
    setSelectedApproverGrp("");
    setShowAddCampaignPopup(false);
  };

  const editModer = (f) => {
    setEditMode(f);
  };

  const contentInit = () => {
    const api = {
      functionName: "content_init",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(api).then((response) => {
      if (response.success === "1") {
        console.log("🚀 ~ backendApi ~ response:", response);
        const transformedOptions = response.campaign_name.map(
          (campaign, index) => ({
            value: campaign.id,
            label: campaign.campaign_name,
          })
        );
        setCampaignOptions(transformedOptions);
        setAllGroups(
          response.content_approver_group.map((data) => ({
            ...data,
            value: data.id,
            label: data.group_name,
          }))
        );
      } else {
        setAllGroups([]);
      }
    });
  };

  const activeDeactive = (checked, id) => {
    console.log("🚀 ~ activeDeactive ~ checked:", checked);
    const api = {
      functionName: "content_approver_group_activeDeactive",
      is_active: checked === true ? 1 : 0,
      content_approver_group_id: id,
    };
    backendApi(api).then((data) => {
      if (data.success === "1") {
        alert("Status changed successfully!!");
        groupDataInit();
      }
    });
  };

  const ShowModalCampaignAdv = () => {
    setShowAddCampaignPopup(true);
    document.body.className = "overflow-hidden";
  };

  const saveUser = () => {
    if (name === "") {
      alert("Name required!!");
      return false;
    } else if (email === "") {
      alert("Email-Id required");
      return false;
    } else if (!re.test(email)) {
      alert("Invalid Email Id ");
      return false;
    } else if (selectedApproverGrp === "") {
      alert("Please select a group");
      return false;
    }
    const api = {
      functionName: "content_approver_save",
      full_name: name,
      email_id: email,
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      content_approver_group_id: selectedApproverGrp.id,
      created_by:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };
    let domain = organizationList.domain;
    if (!email.includes(domain)) {
      if (
        window.confirm(
          "Email domain is not same of this organization. Do you want to proceed?"
        )
      ) {
        backendApi(api).then((data) => {
          if (data.success === "1") {
            alert("Saved Successfully");
            groupDataInit();
            setInvitePopup(false);
            // approverList();
            setName("");
            setEmail("");
            setSelectedApproverGrp("");
          } else alert("Something went wrong");
        });
      }
    } else {
      backendApi(api).then((data) => {
        if (data.success === "1") {
          alert("Saved Successfully");
          groupDataInit();
          setInvitePopup(false);
          // approverList();
        } else alert("Something went wrong");
      });
    }
  };

  const handleGroupSelect = (selectedOption) => {
    // setApprover(selectedOption);
    let group = groupDatas?.find((data) => data?.id == selectedOption?.value);
    console.log("ggp", group);
    setSelectedGroups([...selectedGroups, group]);
    // setApprover('')
  };
  const handleCampaignSelect = (selectedOption) => {
    setCampaign(selectedOption);
  };
  const sendForApproval = () => {
    if (campaign === "") {
      alert("Please select a campaign");
      return false;
    }
    if (selectedGroups.length == 0) {
      alert("Please select a group");
      return false;
    }
    // const contentText = textContent;
    // let replacedText = contentText.replace(/['"]/g, "\\$&");
    const groupIds = selectedGroups.map((group) => String(group.id));

    if (!groupIds) {
      alert("Please Choose A group First");
      return;
    }
    const apiReq = {
      functionName: "content_request",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      dataArray: questionAnswerId,
      category_type: "Question",
      requested_by: window.sessionStorage.getItem("userId"),
      content_approver_group_id: groupIds,
      campaign_id: campaign.value,
      //   campaign_question_answer_id:questionAnswerId
    };
    console.log("req", apiReq);
    backendApi(apiReq).then((data) => {
      if (data.success == "1") {
        alert("Approval Request sent succesfully");
        campaignCall();
        onClose();
      } else {
        alert("Error sending approval request");
        console.log("Error in content init api");
      }
    });
  };

  const handleDeleteGroup = (index) => {
    const updatedGroups = [...selectedGroups];
    updatedGroups.splice(index, 1); // Remove the item at the specified index
    setSelectedGroups(updatedGroups);
  };

  const groupDataInit = () => {
    const apiReq = {
      functionName: "content_approver_group_data",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    console.log("req", apiReq);
    backendApi(apiReq).then((data) => {
      if (data.success == "1") {
        setGroupDatas(data.dataSet);
        setApproverGroup(
          data.dataSet.map((data) => ({
            value: data.id,
            label: data.group_name,
          }))
        );
      } else {
        console.log("Error in groupdata api");
      }
    });
  };

  return (
    <div className="adv_modal">
      <div className="adv_modal_inner ">
        <div className="adv_modal_header d-flex align-itesm-center">
          <h3 class="image-heading">Send For Approval</h3>
          <button
            type="button"
            className="close ml-auto"
            onClick={() => {
              onClose();
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="adv_modal_body">
          <div className="row">
          <div className="col-lg-6 qaheight">
            {questionAnswerId?.map((qusAns, index) => (
             <div>
                <div className="small-text mb-2">Qn {index + 1}:{qusAns.content_image}</div>
                <div className="small-text">
                  Ans:
                  {qusAns.content_text !== ""
                    ? qusAns.content_text
                    : "Not Answered"}
                </div>
             </div>
            ))}
             </div>
            <div className="col-lg-6">
              <label>
                Select Campaign
                <span className="red ml-1">*</span>{" "}
              </label>
              <div className="d-md-flex select-camp">
                <Select
                  className="w-100"
                  options={campaignOptions}
                  onChange={handleCampaignSelect}
                  value={campaign}
                />
                <span className="ml-2 mt-1">
                  <FiPlusCircle
                    style={{ width: "20px", height: "20px" }}
                    onClick={() => ShowModalCampaignAdv()}
                    className="history-icon-gen"
                  />
                </span>
              </div>
              <label>
                Select Group
                <span className="red ml-1">*</span>{" "}
              </label>
              <div className="d-md-flex select-group">
                <Select
                  className="w-100"
                  options={approverGroup}
                  onChange={handleGroupSelect}
                  // value={approver}
                />
                <span className="ml-2 mt-1">
                  <FiPlusCircle
                    style={{ width: "20px", height: "20px" }}
                    title="add group"
                    onClick={handleAddGroup}
                    className="history-icon-gen"
                  />
                </span>
              </div>
            </div>
            {selectedGroups.length > 0 && (
              <div className="col-lg-12">
                <div className="mt-2">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-1">#</th>
                        <th className="col-3">Group</th>
                        <th className="col-3 ">Locations</th>
                        <th className="col-3 ">Approvers</th>
                        <th className="col-2 ">Actions</th>
                      </tr>
                    </thead>
                    <div className="table_Outerscroll">
                      <tbody className="table_Outertbody">
                        {selectedGroups.map((group, index) => (
                          <tr>
                            <td className="col-1">{index + 1}</td>
                            <td className="col-3">{group.group_name}</td>
                            <td className="col-3">{group.locations}</td>
                            <td className="col-3">{group.user_data}</td>
                            <td className="col-2">
                              <FiTrash
                                color="red"
                                onClick={() => handleDeleteGroup(index)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </div>
                  </table>
                </div>

                <button
                  id="txtFile"
                  type="button"
                  class="btn btn-success mr-1 pull-right"
                  onClick={() => sendForApproval()}
                >
                  Send For Approval
                </button>
              </div>
            )}
          </div>
        </div>
        {invitePopup && (
          <div className="adv_modal">
            <div className="adv_modal_inner ">
              <div className="adv_modal_header d-flex align-itesm-center">
                <h3 class="image-heading">Invite / Add Users</h3>
                <Icon.XCircle
                  color="red"
                  className="ml-auto"
                  onClick={() => closePopup()}
                />
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-2 mb-md-0">
                    <label for="exampleInputEmail1">
                      Name<sup style={{ color: "red" }}>*</sup>
                    </label>
                    <div className="d-md-flex">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Full Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2 mb-md-0">
                    <label for="exampleInputEmail1">
                      Email Id<sup style={{ color: "red" }}>*</sup>
                    </label>
                    <div className="d-md-flex">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter email-id"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* </div>
              <div className="row"> */}
                <div className="col-md-6">
                  <div className="form-group mb-2 mb-md-0">
                    <label for="exampleInputEmail1">
                      Approver Group<sup style={{ color: "red" }}>*</sup>
                    </label>
                    <div className="d-md-flex align-items-center">
                      <Select
                        className="w-75"
                        options={allGroups}
                        onChange={(e) => setSelectedApproverGrp(e)}
                      />
                      <span
                        className="p-0 ml-2 add_usser_listplus"
                        title="Add New Group"
                        onClick={() => {
                          toggleModal3();
                        }}
                      >
                        <button className="btn btn-info">New Group</button>
                        {/* <Icon.PlusCircle /> */}
                      </span>
                    </div>
                  </div>
                </div>
                {selectedApproverGrp !== "" &&
                  selectedApproverGrp.locations !== "" && (
                    <div className="loc-selected w-100 col-md-12">
                      <b>Locations Selected:</b> {selectedApproverGrp.locations}
                    </div>
                  )}
              </div>
              <div className="text-right">
                <button
                  type="submit"
                  class="btn btn-success mr-2"
                  onClick={saveUser}
                >
                  Save
                </button>
                <button
                  type="submit"
                  class="btn btn-secondary"
                  onClick={() => closePopup()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        <div id="assignGroup" className="adv_modal" hidden>
          <div className="adv_modal_inner">
            <Tabs>
              <TabList>
                <Tab>Add New Group</Tab>
                <Tab>History</Tab>
              </TabList>
              <TabPanel>
                <AddGroup
                  editMode={editMode}
                  key={editMode}
                  setEditMode={editModer}
                  statesList={statesList}
                  contentInit={contentInit}
                />
              </TabPanel>
              <TabPanel>
                <GroupsList
                  key={editMode}
                  contentInit={contentInit}
                  editMode={editMode}
                  setEditMode={editModer}
                  approverGroup={allGroups}
                  activeDeactive={activeDeactive}
                  statesList={statesList}
                />
              </TabPanel>
            </Tabs>
          </div>
        </div>
        {showAddCampaignPopup && (
          <AddCampaignPopup
            onClose={closePopup}
            campaignGetData={contentInit}
            editProps=''
          />
        )}
      </div>
    </div>
  );
};

export default SendApprovalPopup;
