import React, { Component } from "react";
// import FileSaver from 'file-saver';
import "../css/bootstrap.css";
import "../css/style.css";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import Accessibiltyimage from "../images/left_icons/Accessibility-and-visibility.png";
import Auditimage from "../images/left_icons/audit.png";
import { TagsInput } from "react-tag-input-component";
import cloudImg from "../images/cloud-computing.png";
import deleteImg from "../images/Group122.png";
import DragDropFile from "../audit/dragDropFile";
import DataInput from "../audit/dataInput";
import { backendApi, pythonBackendApi } from "../apiService";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import uploadImg from "../images/upload.png";
import $ from "jquery";
import closeImg1 from "../images/close1.png";
import * as Icon from "react-feather";
import exportFromJSON from "export-from-json";
import moment from "moment";
import Pagination from "react-js-pagination";
import VisibilityAccuracyGraph from "./visibility_accuracyGraph";
import ReactDatePicker from "react-datepicker";

import MergeIndex from "./visibilityAccuracyDetailsYesData";
import "react-datepicker/dist/react-datepicker.css";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
var tempPostList = [];
var total_location = 0;
var address_match = 0;
var address_match_percentage = 0;
var phone_no_match = 0;
var phoneno_match_percentage = 0;
var business_name_match_percentage = 0;
var business_name_match = 0;
var totalNumber = 0;
var latestUpdate = 0;
export default class VisibilityAccuracyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      totalCount: 0,
      backupdata: [],
      tempLocationsList: [],
      languageListTTS: [],
      apiDataYesList: [],
      apiDataNoList: [],
      csvPushData: [],
      apiVisYesList: [],
      apiVisNoList: [],
      filter: "",
      mode: "",
    };
  }
  componentDidMount() {
    // this.dataListing();
    // this.visibilityAndAccuracyGraph();
    this.setState({
      filter: window.location.pathname.split("/")[3],
      mode: window.location.pathname.split("/")[2],
    });
    this.getVisibilityAndAccuracyDataByDate();
  }
  getVisibilityAndAccuracyDataByDate() {
    // console.log("this.propsssss", window.location.pathname.split("/"));
    const apiReq = {
      functionName: "getVisibilityAndAccuracyDataByDate",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      visibility_and_accuracy_id: window.location.pathname.split("/")[4],
    };
    backendApi(apiReq).then((data) => {
      console.log("🚀 ~ VisibilityAccuracyDetails ~ backendApi ~ data:", data);
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        let mode = window.location.pathname.split("/")[3];
        let finalYesData = [];
        let finalNoData = [];
        let finalVisYesData = [];
        let finalVisNoData = [];
        debugger;
        if (mode == "address") {
          finalVisYesData = data.result.filter(
            (addressFilter) => addressFilter.visibility_address == "Yes"
          );
          finalVisNoData = data.result.filter(
            (addressFilter) => addressFilter.visibility_address == "No"
          );
          finalYesData = data.result.filter(
            (addressFilter) => addressFilter.address_match == "Yes"
          );
          finalNoData = data.result.filter(
            (addressFilter) => addressFilter.address_match == "No"
          );
        } else if (mode == "phone") {
          finalVisYesData = data.result.filter(
            (addressFilter) => addressFilter.visibility_phone == "Yes"
          );
          finalVisNoData = data.result.filter(
            (addressFilter) => addressFilter.visibility_phone == "No"
          );
          finalYesData = data.result.filter(
            (phoneFilter) => phoneFilter.phoneno_match == "Yes"
          );
          finalNoData = data.result.filter(
            (phoneFilter) => phoneFilter.phoneno_match == "No"
          );
        } else if (mode == "business") {
          finalVisYesData = data.result.filter(
            (addressFilter) => addressFilter.visibility_business_name == "Yes"
          );
          finalVisNoData = data.result.filter(
            (addressFilter) => addressFilter.visibility_business_name == "No"
          );
          finalYesData = data.result.filter(
            (businessNAmeFilter) =>
              businessNAmeFilter.business_name_match == "Yes"
          );
          finalNoData = data.result.filter(
            (businessNAmeFilter) =>
              businessNAmeFilter.business_name_match == "No"
          );
        } else if (mode == "category") {
          finalYesData = data.result.filter(
            (categoryFilter) => categoryFilter.category_match == "Yes"
          );
          finalNoData = data.result.filter(
            (categoryFilter) => categoryFilter.category_match == "No"
          );
        } else if (mode == "gbp") {
          finalYesData = data.result.filter(
            (categoryFilter) => categoryFilter.location_id !== null
          );
          finalNoData = data.result.filter(
            (categoryFilter) => categoryFilter.GBP_status == null
          );
        } else if (mode == "website") {
          finalYesData = data.result.filter(
            (categoryFilter) =>
              categoryFilter.Web_match?.toLowerCase() == "match"
          );
          finalNoData = data.result.filter(
            (categoryFilter) =>
              categoryFilter.Web_match?.toLowerCase() !== "match"
          );
        } else if (mode === "hours") {
          finalYesData = data.result;
        }
        console.log("finalYesData", finalYesData);
        console.log("finalNoData", finalNoData);
        console.log("finalVisYesData", finalVisYesData);
        console.log("finalVisNoData", finalVisNoData);
        debugger;
        this.setState({
          apiDataYesList: finalYesData,
          apiDataNoList: finalNoData,
          apiVisYesList: finalVisYesData,
          apiVisNoList: finalVisNoData,
        });
        // tempPostList.push(...data.result);
        // this.setState({
        //   totalCount: data.result.length,
        // });
        // const indexOfLastPost = this.state.currentPage * 25;
        // const indexOfFirstPost = indexOfLastPost - 25;
        // const currentPosts = data.result.slice(
        //   indexOfFirstPost,
        //   indexOfLastPost
        // );
        // this.setState({
        //   apiDataList: currentPosts,
        //   backupdata: data.result,
        // });
      }
    });
  }
  //**Pagination
  handleActivePage = (e) => {
    this.setState({
      currentPage: e,
    });
    const indexOfLastPost = e * 25;
    const indexOfFirstPost = indexOfLastPost - 25;
    let tempList = this.state.backupdata;
    const currentPosts = tempList.slice(indexOfFirstPost, indexOfLastPost);
    this.setState({
      apiDataList: currentPosts,
    });
  };
  visibilityAndAccuracyGraph() {
    business_name_match = 0;
    phone_no_match = 0;
    address_match = 0;
    var lastyear = new Date(new Date().getFullYear() - 1, 0, 1);
    var startDate = new Date(lastyear.getFullYear(), 0, 1).getTime();
    var req = {
      functionName: "getVisibilityAndAccuracyData",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      start_date: moment(this.state.insightsStartDate).format("YYYY-MM-DD"),
      end_date: moment(new Date()).format("YYYY-MM-DD"),
    };
    backendApi(req).then((data) => {
      if (data.success === "1") {
        data.result.forEach((object) => {
          latestUpdate = object.created_on;
          total_location = object.total_location;
          address_match =
            parseInt(object.address_match) + parseInt(address_match);
          phone_no_match =
            parseInt(object.phone_no_match) + parseInt(phone_no_match);
          business_name_match =
            parseInt(object.business_name_match) +
            parseInt(business_name_match);
        });
        this.setState({
          flag: true,
        });
      }
    });
  }
  //**Export Function
  patientListDownload() {
    var re = this.state.apiDataList;
    const data = [];
    for (var item = 0; item < re.length; item++) {
      data.push({
        "Excel Address": re[item].excel_address,
        "Address Match": `<span style="color:blue">Address Match Audit:</span>${re[item].google_address}<br/><span style="color:red">Address Match Visibility:</span>${re[item].address}`,
        "Phone Number": `${
          re[item].googlePhoneNumber
            ? `<span style="color:blue">${re[item].googlePhoneNumber}</span><br/>`
            : `<span style="color:blue">No Phone Number</span><br/>`
        }${
          re[item].phone_no
            ? `<span style="color:red">${re[item].phone_no}</span>`
            : `<span style="color:red">No number</span>`
        }`,
        // 'Phone Number Audit': re[item].googlePhoneNumber,
        // 'Phone Number Visibility': re[item].phone_no,
        "Address Match ":
          re[item].address_match == 0 || re[item].address_match == "No"
            ? "No"
            : "Yes",
        "Phone Number Match":
          re[item].phoneno_match == 0 || re[item].phoneno_match == "No"
            ? "No"
            : "Yes",
        "Business Match":
          re[item].business_name_match == 0 ||
          re[item].business_name_match == "No"
            ? "No"
            : "Yes",
      });
    }
    const fileName = "download";
    const exportType = "xls";
    exportFromJSON({ data, fileName, exportType });
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          <Header heading="Analytics" headerImage={Auditimage} />
          <div className="container-fluid">
            {window.location.pathname.split("/")[3] !== "hours" ? (
              <Tabs
                // selectedIndex={tabIndex}
                className="tabs-main"
                // onSelect={(index) => {
                //   setTabIndex(index);
                //   window.sessionStorage.setItem("pageIndexMergeAudit", index);
                // }}
              >
                <TabList>
                  <Tab>
                    {this.state.mode === "visibility"
                      ? `Visible Data`
                      : `Matching ${this.state.mode} ${this.state.filter} data`}
                  </Tab>
                  <Tab>
                    {this.state.mode === "visibility"
                      ? `Non Visible Data`
                      : `Non Matching ${this.state.mode} ${this.state.filter} data`}
                  </Tab>
                </TabList>

                <TabPanel>
                  <MergeIndex
                    key={`${this.state.mode}-${this.state.apiDataYesList.length}-${this.state.apiVisYesList.length}`}
                    apiDataList={this.state.apiDataYesList}
                    apiDataVisList={this.state.apiVisYesList}
                    subModeFlag="Yes"
                  />
                </TabPanel>
                <TabPanel>
                  <MergeIndex
                    key={`${this.state.mode}-${this.state.apiDataNoList.length}-${this.state.apiVisNoList.length}`}
                    apiDataList={this.state.apiDataNoList}
                    apiDataVisList={this.state.apiVisNoList}
                    subModeFlag="No"
                  />
                </TabPanel>
              </Tabs>
            ) : (
              <MergeIndex
                key={`${this.state.mode}-${this.state.apiDataYesList.length}-${this.state.apiVisYesList.length}`}
                apiDataList={this.state.apiDataYesList}
                apiDataVisList={this.state.apiVisYesList}
                subModeFlag=""
              />
            )}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
// export default VisibilityReports;
