import React, { useState } from "react";
// import AdminHeader from "./admin_header";
import Header from "../layout/header";
import "../Project_Folder/UnicornStores/css/edit_style.css";
import LeftMenu from "../layout/leftMenu";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";

import UploadImg from '../images/upload.png'
import Delete from '../images/delete.png'
function FileUpload() {
    const [activepage,SetActivepage] = useState(15)
    const [upload,SetUpload] =useState(true)
    const pageChange =()=>{
        SetActivepage(activepage)
    }
    const FileUpladChange =()=>{
        SetUpload(false)
    }
    const FileUploadCancel =()=>{
        SetUpload(true)
    }
  return (
    <div className="dashboard_wrapper">
       {/* <AdminHeader/> */}
       <Header/>
       <LeftMenu></LeftMenu>
       <div className="dash_breadcrumbs">           
            <nav aria-label="breadcrumb">
            <div className="container">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                <li className="breadcrumb-item active" aria-current="page">CMS</li>
            </ol>
            </div>
        </nav>   
        <div className="container">
            <div className="heading_wrapper_div">
            <h1 className="view_reportsheading">File Upload</h1>
            </div>
            <div className="file_upload_div">
                <div className="file_upload_innerdiv">
                    {upload ?
                    <div className="file_upload_inputfile">
                        <div className="file_upload_divtag">
                            <img src={UploadImg} className="file_upload_img img-fluid" alt="upload" width="100%"/>
                        </div>
                        <div className="file_drag_drop">
                                <span className="span_drag_drop">Drag & Drop you file here</span>
                                <span className="span_drag_drop_or">Or</span>
                        </div>
                        <div className="file_upload_btndiv">
                                <button className="file_upload_btn">Browse File</button>
                        </div>
                        <input type="file" className="file_upload_input" onChange={()=>FileUpladChange()}/>
                   </div>
                   :
                   <div className="file_upload_table_div">
                        <div className="table_fileupload_btn">
                            <button type="button" className="file_upload">Upload</button>
                            <button type="button" className="file_upload_cancel" onClick={()=> FileUploadCancel()}>Cancel</button>
                        </div>
                        <div className="table-responsive">
                            <div className="file_upload_table_responsive">
                            <table className="table table-bordered file_upload_table_tag">
                            <thead>
                                <tr>
                                    <th>Dealer Name</th>
                                    <th>Location</th>
                                    <th>GM Name</th>
                                    <th>GM Email ID</th>
                                    <th>DEM Email ID</th>
                                    <th>ASM Email ID</th>
                                    <th>ASM</th>
                                    <th>DEM Name</th>
                                    <th>State</th>
                                    <th>City</th>
                                    <th>Vehicle Type</th>
                                    <th>Outlet Type</th>
                                    <th>Open Hr.</th>
                                    <th>Address</th>
                                    <th>Mobile No</th>
                                    <th>Week Days</th>
                                    <th>Weekends</th>
                                    <th>Languages</th>
                                    <th>contact</th>
                                    <th>latitude</th>
                                    <th>longitude</th>
                                    <th>SEO Title</th>
                                    <th>SEO Keyword</th>
                                    <th>SEO Description</th>
                                    <th>Google Analytics</th>
                                    <th>Schema</th>
                                    <th>Parking Options</th>
                                    <th>Payment Method</th>
                                    </tr>
                                    </thead>
                                    <tbody></tbody>
                            </table>
                            </div>
                        </div>
                        <div className="table_fileupload_btn">
                            <button type="button" className="file_upload">Upload</button>
                            <button type="button" className="file_upload_cancel" onClick={()=> FileUploadCancel()}>Cancel</button>
                        </div>
                   </div>
}
                </div>
            </div>
            <div className="react_pagination">
            <Pagination
            activePage={activepage}
            itemsCountPerPage={5}
            totalItemsCount={450}
            pageRangeDisplayed={5}
            onChange={()=> pageChange()}
        />
            </div>
            <div className="table-responsive">
            <div className="fileupload_table">
                <div className="fileupload_headdiv">
                    <div className="fileupload_head">Dealer Name</div>
                    <div className="fileupload_head fileupload_bodytext_uname">User Name</div>
                    <div className="fileupload_head">State</div>
                    <div className="fileupload_head">City</div>
                    <div className="fileupload_head">Location</div>
                    <div className="fileupload_head fileupload_delteImgdiv"></div>
                </div>
                <div className="fileupload_bodydiv">
                    <div className="fileupload_body">
                    <span className="fileupload_body_head_span">Dealer Name :</span>
                        Axis Bank Branch, Ahmedabad Main Branch
                    </div>
                    <div className="fileupload_body fileupload_bodytext_uname">
                    <span className="fileupload_body_head_span">User Name :</span>
                        47563
                    </div>
                    <div className="fileupload_body">
                    <span className="fileupload_body_head_span">State :</span>
                        Gujarat
                    </div>
                    <div className="fileupload_body">
                    <span className="fileupload_body_head_span">City :</span>
                        Ahmedabad
                    </div>
                    <div className="fileupload_body">
                    <span className="fileupload_body_head_span">Location :</span>
                        Trishul
                    </div>
                    <div className="fileupload_body fileupload_delteImgdiv">
                        <img src={Delete} className="file_upload_imgtag" alt="delete"/>
                    </div>
                </div>
                <div className="fileupload_bodydiv">
                    <div className="fileupload_body">Axis Bank Branch, Ahmedabad Main Branch</div>
                    <div className="fileupload_body fileupload_bodytext_uname">47563</div>
                    <div className="fileupload_body">Gujarat</div>
                    <div className="fileupload_body">Ahmedabad</div>
                    <div className="fileupload_body">Trishul</div>
                    <div className="fileupload_body fileupload_delteImgdiv">
                        <img src={Delete} className="file_upload_imgtag" alt="delete"/>
                    </div>
                </div>
            </div>
            </div>
            <div className="react_pagination">
            <Pagination
            activePage={activepage}
            itemsCountPerPage={5}
            totalItemsCount={450}
            pageRangeDisplayed={5}
            onChange={()=> pageChange()}
        />
            </div>
        </div>       
       </div>
    </div>
  );
}

export default FileUpload;
