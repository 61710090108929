import axios from "axios";
const awsUrl = "https://jkzdxlws1i.execute-api.ap-south-1.amazonaws.com/prod";
const dashboardApiUrl = "https://g2ujx41aki.execute-api.ap-south-1.amazonaws.com/prod"
const awsRecentSearchUrl =
  "https://e8uj5vhpfj.execute-api.ap-south-1.amazonaws.com/default/twitter-recent-search";

// const socialMeAwsUrl = "https://jkzdxlws1i.execute-api.ap-south-1.amazonaws.com/prod";
// const googleAnalyticsAwsUrl = "https://jkzdxlws1i.execute-api.ap-south-1.amazonaws.com/prod";
// const privateURL = "http://localhost:8080/";

// export function apiCall(postData) {
// 	return axios
// 		.post(socialMeAwsUrl, postData)
// 		.then((data) => data.data)
// 		.then((res) => res);
// }
const googleAnalyticsAwsUrl = "https://vmt5u3tjuc.execute-api.ap-south-1.amazonaws.com/test_iocl/getcmsdata";

export function apiCallingPost3(postData) {
    return axios
        .post(googleAnalyticsAwsUrl, postData)
        .then((data) => data)
        .then((res) => res);
}
export function pythonBackendApi(url, req) {
  return (
    // fetch("http://192.168.0.112:8080/" + url, req)
    fetch("https://tagtree.xilligence.com:3333/" + url, req)
      .then((data) => data.json())
      .then((res) => res)
  );
}
export function backendApi(postData) {
  return axios
    .post(awsUrl, postData)
    .then((data) => data.data)
    .then((res) => res);
}

export function backendDashboardApi(postData) {
  return axios
    .post(dashboardApiUrl, postData)
    .then((data) => data.data)
    .then((res) => res);
}
export function apiCallingPost(postData) {
  return axios
    .post(awsUrl, postData)
    .then((data) => data.data)
    .then((res) => res);
}
export function apiCallingPost2(postData) {
  return axios
    .post(awsUrl, postData)
    .then((data) => data)
    .then((res) => res);
}

export function twitterRecentSearch(postData) {
  return axios
    .post(awsRecentSearchUrl, postData)
    .then((data) => data)
    .then((res) => res);
}

export const re =
  /^[a-zA-Z0-9+_.-]+@(?!gmail.com)(?!outlook.com)(?!rediffmail.com)(?!hotmail.com)(?!yahoo.com)(?!aol.com)(?!msn.com)[a-zA-Z0-9.-]+$/;
