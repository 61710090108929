import React, { useState, useEffect } from "react";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import Fraudimage from "../images/left_icons/fraud.png";
import "./style.css";
import MahindraLogo from "./images/mahindra-logo.png";
import LocationImg from "./images/location-img.png";
import { FaChevronRight } from "react-icons/fa";
import $ from "jquery"
export default function FraudDetectedList() {
    const [tabIndex, setTabIndex] = useState(1);
    useEffect(() => {
        window.sessionStorage.getItem("pageIndexMergeAudit") !== "" ? setTabIndex(parseInt(window.sessionStorage.getItem("pageIndexMergeAudit"))) : setTabIndex(0)
        $(".fraud-platform-list").click(function(){
            $(this).toggleClass("fraud-platform-list-active");
        })
    })
    const bulkRaise = () => {
        window.location.href ="/fraud-dashboard-list"
    }
    return (
            <div className="main_wrapper">
                <div className="userListWrapper fraudulent_detection_div">
                    <Header heading="Convergensee - Fraud Dashboard" headerImage={Fraudimage} />
                    <div className="container-fluid bg-white pt-4">
                        <div className="fraud-table-wrapper">
                            <div className="fraud-table-subwrapper">
                                <div className="fraud-table-head row">
                                    <div className="fraud-table-list col-2">Unique ID</div>
                                    <div className="fraud-table-list col-2">Platform</div>
                                    <div className="fraud-table-list col-2">Date</div>
                                    <div className="fraud-table-list col-4">Detected Data</div>
                                    <div className="fraud-table-list col-2">Raise</div>
                                </div>
                                <div className="fraud-table-body">
                                    <div className="fraud-table-subbody row">
                                        <div className="fraud-table-list col-2">
                                            <span>#12</span>
                                        </div>
                                        <div className="fraud-table-list col-2">
                                            <span>Twitter</span>
                                        </div>
                                        <div className="fraud-table-list col-2">
                                            <span>26/01/23</span>
                                        </div>
                                        <div className="fraud-table-list col-4">
                                            <span>Review :  If need of contact service, No : . .</span>
                                        </div>
                                        <div className="fraud-table-list col-2">
                                            <span>Raise</span>
                                        </div>
                                    </div>
                                    <div className="fraud-table-subbody row">
                                        <div className="fraud-table-list col-2">
                                            <span>#12</span>
                                        </div>
                                        <div className="fraud-table-list col-2">
                                            <span>Twitter</span>
                                        </div>
                                        <div className="fraud-table-list col-2">
                                            <span>26/01/23</span>
                                        </div>
                                        <div className="fraud-table-list col-4">
                                            <span>Review :  If need of contact service, No : . .</span>
                                        </div>
                                        <div className="fraud-table-list col-2">
                                            <span>Raise</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 fraud-list-btn-wrapper"> 
                                <p>( Now You can raise all the frauds at once )*</p>
                                <input className="btn-submit button-lg" type={"submit"} value="Bulk Raise" onClick={ () => bulkRaise() }></input>
                            </div>
                        </div>
                    </div>
                    {/* <Footer/> */}
                </div>
            </div>
    )
}
