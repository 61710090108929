import React, { Component } from "react";
import * as Icon from "react-feather";
import Dashboardimage from "../images/left_icons/dashboard.png";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import DashChart from "./dash_Chart";
import DashChartGpp from "./dashBoard2GppGraph";
import Popup from "./popup";
import DashChartVissibillity from "./dashBoard2VissibillityAccuracyChart";
import LiveMonitoring from "../images/left_icons/live.png";
import DashChartMap from "./dashBoard2MapGraph";
import { Line } from "rc-progress";
import moment from "moment";
import { Link } from "react-router-dom";
import $ from "jquery";
import Dashboard from "../images/left_icons/dashboard.png";
import LocateIt from "../images/left_icons/locateIT.png";
import Processing from "../images/left_icons/processing.png";
import Audit from "../images/left_icons/audit.png";
import GBP from "../images/left_icons/GBP.png";
import Business from "../images/left_icons/business.png";
import Fraud from "../images/left_icons/fraud.png";
import Review from "../images/left_icons/review.png";
import QA from "../images/left_icons/qa.png";
import ProductCatalog from "../images/left_icons/Product-Catalog.png";
import GooglePost from "../images/left_icons/Google-Post.png";
import Accessibilty from "../images/left_icons/Accessibility-and-visibility.png";
import Analysis from "../images/left_icons/analysis.png";
import PushGBP from "../images/left_icons/Push-GBP-Data.png";
import Roles from "../images/left_icons/roles.png";
import Module from "../images/left_icons/module.png";
import noDataImage from "../images/donutGraph.png";
import { backendApi } from "../apiService";
import UserProfile from "../images/user-profile.png";
import Footer from "../layout/footer";
var totalCount = 0;
var totalCompletedCount = 0;
var totalFailedCount = 0;
var totalProcessCount = 0;
var business_name_match = 0;
var phone_no_match = 0;
var address_match = 0;
var total_location = 0;
var latestUpdate = 0;
var action_Website = 0;
var local_Post_Actions_CALL_TO_ACTION = 0;
var local_Post_VIEWS_SEARCH = 0;
var photos_VIEWS_CUSTOMERS = 0;
var PHOTOS_VIEWS_MERCHANT = 0;
var QUERIES_DIRECT = 0;
var VIEWS_MAPS = 0;
var VIEWS_SEARCH = 0;
class Newdashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      excelData: [],
      modulesList: window.sessionStorage.getItem("switchUserModules")
        ? JSON.parse(window.sessionStorage.getItem("switchUserModules"))
        : JSON.parse(window.sessionStorage.getItem("modules")),
      userManagement: false,
      roleManagement: false,
      moduleManagement: false,
      organisationSetting: false,
      googleBusnessAccount: false,
      dataManagement: false,
      liveMonitoring: false,
      createZone: false,
      mapAndUnmapAccount: false,
      productCatalogStatus: false,
      auditStatus: false,
      reviewStatus: false,
      googlePostStatus: false,
      googleAnalyticsStatus: false,
      socialMeStatus: false,
      localPagesStatus: false,
      competitorAnalysisStatus: false,
      googleChatStatus: false,
      gbpStatus: false,
      fraudStatus: false,
      chatbotStatus: false,
      addressProcessingStatus: false,
      visibilityaccuracyStatus: false,
      facebookAdsStatus: false,
      googleAdsStatus: false,
      pushGBPDataStatus: false,
      qAndAStatus: false,
      locateItStatus: false,
      businessClaimStatus: false,
      organizationListIFUserLoginedIn: [],
      dataForAuditGraph: [],
      flag: false,
      flagvalueForGraphVissibillityAcuuracy: false,
      lineGraphValues: [],
      categoryIdSet: "",
      acountIdCheck: "",
      businessInsightsStatus: false,
      ProcessGBP: false,
      googleSearchDesktop: 0,
      googleSearchMobile: 0,
      googleMapsDesktop: 0,
      googleMapsMobile: 0,
      googleBookings: 0,
      googleCalls: 0,
      googleDirections: 0,
      googleMessages: 0,
      googleWebsiteClicks: 0,
      showPopup: false,
      holidayList: [],
    };
    this.popUpcategoryClose = this.popUpcategoryClose.bind(this);
  }
  componentDidMount() {
    if (
      window.sessionStorage.getItem("organizationId") != "" &&
      window.sessionStorage.getItem("switchingOrgId") != ""
    ) {
      this.getMasterGBPAuditData();
      this.visibilityAndAccuracyGraph();
      this.moduleListAuthentication();
      // this.removeDuplicate();
      this.updateAuditStatusGeneral();
    }
  }
  updateAuditStatusGeneral() {
    var req = {
      functionName: "updateAuditStatusGeneral",
    };
    backendApi(req).then((data) => {});
  }
  removeDuplicate() {
    var req = {
      functionName: "removeDuplicate",
    };
    backendApi(req).then((data) => {});
  }
  moduleListAuthentication() {
    if (
      window.sessionStorage.getItem("role") === "ROLE_SUPER_ADMIN" &&
      window.sessionStorage.getItem("switchUserId") === ""
    ) {
      this.setState({
        userManagement: true,
        roleManagement: true,
        moduleManagement: true,
        organisationSetting: true,
        googleBusnessAccount: true,
        dataManagement: true,
        liveMonitoring: true,
        createZone: true,
        mapAndUnmapAccount: true,
        reviewStatus: true,
        auditStatus: true,
        googlePostStatus: true,
        googleAnalyticsStatus: true,
        socialMeStatus: true,
        localPagesStatus: true,
        competitorAnalysisStatus: true,
        googleChatStatus: true,
        gbpStatus: true,
        fraudStatus: true,
        chatbotStatus: true,
        addressProcessingStatus: true,
        productCatalogStatus: true,
        visibilityaccuracyStatus: true,
        facebookAdsStatus: true,
        googleAdsStatus: true,
        pushGBPDataStatus: true,
        qAndAStatus: true,
        locateItStatus: true,
        businessClaimStatus: true,
        businessInsightsStatus: true,
        ProcessGBP: true,
      });
    } else {
      this.setState({
        userManagement: this.state.modulesList.some((e) => e.moduleId === 2),
        // roleManagement: this.state.modulesList.some((e) => e.moduleId === 3),
        // moduleManagement: this.state.modulesList.some((e) => e.moduleId === 1),
        organisationSetting: this.state.modulesList.some(
          (e) => e.moduleId === 18
        ),
        googleBusnessAccount: this.state.modulesList.some(
          (e) => e.moduleId === 30
        ),
        ProcessGBP: this.state.modulesList.some((e) => e.moduleId === 36),
        dataManagement: this.state.modulesList.some((e) => e.moduleId === 31),
        liveMonitoring: this.state.modulesList.some((e) => e.moduleId === 32),
        createZone: this.state.modulesList.some((e) => e.moduleId === 33),
        mapAndUnmapAccount: this.state.modulesList.some(
          (e) => e.moduleId === 34
        ),
        reviewStatus: this.state.modulesList.some((e) => e.moduleId === 8),
        auditStatus: this.state.modulesList.some((e) => e.moduleId === 7),
        googlePostStatus: this.state.modulesList.some((e) => e.moduleId === 10),
        googleAnalyticsStatus: this.state.modulesList.some(
          (e) => e.moduleId === 9
        ),
        socialMeStatus: this.state.modulesList.some((e) => e.moduleId === 14),
        localPagesStatus: this.state.modulesList.some((e) => e.moduleId === 6),
        competitorAnalysisStatus: this.state.modulesList.some(
          (e) => e.moduleId === 11
        ),
        googleChatStatus: this.state.modulesList.some((e) => e.moduleId === 13),
        gbpStatus: this.state.modulesList.some((e) => e.moduleId === 15),
        fraudStatus: this.state.modulesList.some((e) => e.moduleId === 12),
        chatbotStatus: this.state.modulesList.some((e) => e.moduleId === 17),
        addressProcessingStatus: this.state.modulesList.some(
          (e) => e.moduleId === 16
        ),
        visibilityaccuracyStatus: this.state.modulesList.some(
          (e) => e.moduleId === 19
        ),
        productCatalogStatus: this.state.modulesList.some(
          (e) => e.moduleId === 4
        ),
        facebookAdsStatus: this.state.modulesList.some(
          (e) => e.moduleId === 21
        ),
        googleAdsStatus: this.state.modulesList.some((e) => e.moduleId === 22),
        pushGBPDataStatus: this.state.modulesList.some(
          (e) => e.moduleId === 23
        ),
        qAndAStatus: this.state.modulesList.some((e) => e.moduleId === 24),
        locateItStatus: this.state.modulesList.some((e) => e.moduleId === 25),
        businessClaimStatus: this.state.modulesList.some(
          (e) => e.moduleId === 26
        ),
        businessInsightsStatus: this.state.modulesList.some(
          (e) => e.moduleId === 35
        ),
      });
    }
  }
  getMasterGBPAuditData() {
    var postData = {
      functionName: "getSiteByUser",
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      role:
        window.sessionStorage.getItem("switchUserRole") === ""
          ? window.sessionStorage.getItem("role")
          : window.sessionStorage.getItem("switchUserRole"),
    };
    backendApi(postData).then((data) => {
      $(".loder_div").attr("hidden", false);
      if (data.success === "1") {
        data.data.filter((item) => {
          if (
            item.id ==
            (window.sessionStorage.getItem("switchingOrgId")
              ? window.sessionStorage.getItem("organizationId")
              : window.sessionStorage.getItem("switchingOrgId"))
          ) {
            this.setState({
              categoryIdSet: item.category_id,
              acountIdCheck: item.account_id,
            });
            //    if(item.category_id==null||item.category_id==undefined||item.category_id==""){
            // {window.sessionStorage.setItem("categoryIdPopUpSet",true)}}
          }
        });
        this.setState({
          organizationListIFUserLoginedIn: data.data,
        });
        if (data.data.length == 1) {
          window.sessionStorage.setItem("switchingOrgId", data.data[0].id);
          this.setState({ setOrganizationId: data.data[0].id });
        }
      }
      $(".loder_div").attr("hidden", true);
    });
    var PostData = {
      functionName: "dashboardListing",
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      role:
        window.sessionStorage.getItem("switchUserRole") === ""
          ? window.sessionStorage.getItem("role")
          : window.sessionStorage.getItem("switchUserRole"),
    };
    backendApi(PostData).then((data) => {

      if (data.success === "1") {
        this.setState({
          dataForAuditGraph: data,
          flag: true,
          lineGraphValues: data.accountList,
          excelData: data.data,
        });
        data.data.forEach((object) => {
          totalCount += object.totalcount;
        });
        if (data.gbp.length > 0) {
          // data.gbp.forEach((object) => {
          //   photos_VIEWS_CUSTOMERS += object.PHOTOS_VIEWS_CUSTOMERS;
          //   local_Post_VIEWS_SEARCH += object.LOCAL_POST_VIEWS_SEARCH;
          //   action_Website += object.ACTIONS_WEBSITE;
          //   local_Post_Actions_CALL_TO_ACTION +=
          //     object.LOCAL_POST_ACTIONS_CALL_TO_ACTION;
          //   VIEWS_SEARCH += object.VIEWS_SEARCH;
          //   VIEWS_MAPS += object.VIEWS_MAPS;
          //   PHOTOS_VIEWS_MERCHANT += object.PHOTOS_VIEWS_MERCHANT;
          //   QUERIES_DIRECT += object.QUERIES_DIRECT;
          // });
          this.setState({
            googleSearchDesktop:
              data.gbp[0].search_desktop != null
                ? data.gbp[0].search_desktop
                : 0,
            googleSearchMobile:
              data.gbp[0].search_mobile != null ? data.gbp[0].search_mobile : 0,
            googleMapsDesktop:
              data.gbp[0].maps_desktop != null ? data.gbp[0].maps_desktop : 0,
            googleMapsMobile:
              data.gbp[0].maps_mobile != null ? data.gbp[0].maps_mobile : 0,
            googleBookings:
              data.gbp[0].bookings != null ? data.gbp[0].bookings : 0,
            googleCalls: data.gbp[0].calls != null ? data.gbp[0].calls : 0,
            googleDirections:
              data.gbp[0].directions != null ? data.gbp[0].directions : 0,
            googleMessages:
              data.gbp[0].messages != null ? data.gbp[0].messages : 0,
            googleWebsiteClicks:
              data.gbp[0].websiteClicks != null ? data.gbp[0].websiteClicks : 0,
          });
        }
        if (data.progress.length > 0) {
          data.progress.forEach((object) => {
            if (object.process_status === "Completed") {
              totalCompletedCount += object.cnt;
            }
            if (object.process_status === "Failed") {
              totalFailedCount += object.cnt;
            }
            if (object.process_status === "") {
              totalProcessCount += object.cnt;
            }
          });
        }
        $(".loder_div").attr("hidden", true);
        $(".alertCommon").css("display", "block");
        if(window.sessionStorage.getItem("holidayList") == "false"){
          this.getHolidayList();
        }
      }
    });
  }
  selectOrganization = (value, i) => {
    window.sessionStorage.setItem("categoryIdPopUpSet", "1");
    $("#className" + i).addClass("activeOrg");
    window.sessionStorage.setItem("classid", "className" + i);
    window.sessionStorage.setItem("switchingOrgId", value.id);
    window.sessionStorage.setItem("social_tags", value.social_tags);
    window.sessionStorage.setItem("account_id", value.account_id);
    window.sessionStorage.setItem("organisation_name", value.organisation_name);
    window.location.reload();
  };
  drDetailsSection() {
    window.sessionStorage.setItem("pageNumberDrDetails", 1);
    window.sessionStorage.setItem("offsetDrDetails", 0);
    window.sessionStorage.setItem("offsetDrDetailsAudit", 0);
  }
  /////////////////////////////////////////////////////////////////////////
  visibilityAndAccuracyGraph() {
    var lastyear = new Date(new Date().getFullYear() - 1, 0, 1);
    var startDate = new Date(lastyear.getFullYear(), 0, 1).getTime();
    var req = {
      functionName: "getVisibilityAndAccuracyData",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(new Date()).format("YYYY-MM-DD"),
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        if (data.result.length > 0) {
          data.result.forEach((object) => {
            latestUpdate = object.created_on;
            total_location = object.total_location;
            // address_match = object.address_match;
            address_match =
              parseInt(object.address_match) + parseInt(address_match);
            // phone_no_match = object.phone_no_match;
            phone_no_match =
              parseInt(object.phone_no_match) + parseInt(phone_no_match);
            business_name_match =
              parseInt(object.business_name_match) +
              parseInt(business_name_match);
            // business_name_match = object.business_name_match;
          });
          this.setState({
            flagvalueForGraphVissibillityAcuuracy: true,
          });
          //     if((address_match+phone_no_match+business_name_match)!=0){
          //         this.setState({
          //             flagvalueForGraphVissibillityAcuuracy:true,
          //         });
          // }
        }
      }
    });
    // $(".alertCommon").css("display","block")
  }
  popUpcategoryClose() {
    {
      window.sessionStorage.setItem("categoryIdPopUpSet", "0");
    }
    $(".alertCommon").attr("hidden", true);
  }
  getHolidayList() {
    var req = {
      functionName: "getHolidayList",
    };
    backendApi(req).then((data) => {
      if (data.success === "1") {
        this.setState({
          holidayList: data.festivalList
        });
        this.setState({showPopup:true})
      }
    });
  }
  closePopup = () => {
    this.setState({ showPopup: false });
    window.sessionStorage.setItem("holidayList", true);
  };
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper">
          <Header heading="Dashboard" headerImage={Dashboardimage} />
          <div className="container-fluid dashboard_second">
            {(!this.state.categoryIdSet || !this.state.acountIdCheck) &&
              window.sessionStorage.getItem("categoryIdPopUpSet") != "0" && (
                <div className="alertCommon">
                  <div className="alertPopup">
                    <div className="alert alert-warnings " role="alert">
                      {this.state.categoryIdSet ? (
                        <>
                          {this.state.acountIdCheck ? (
                            ""
                          ) : (
                            <>
                              <span className="categoryNotSelected">
                                {" "}
                                <Icon.AlertTriangle
                                  style={{ color: "black" }}
                                  className="icon_common"
                                />{" "}
                                Google Account details are missing from the site
                                settings.
                              </span>
                              <span className="categoryNotSelected">
                                {" "}
                                <Icon.ArrowRight
                                  style={{ color: "black" }}
                                  className="icon_common"
                                />{" "}
                                <Link className="alertLink" to={"/settings"}>
                                  {" "}
                                  <span
                                    onClick={() => this.popUpcategoryClose()}
                                  >
                                    Click here
                                  </span>
                                </Link>
                              </span>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <span className="categoryNotSelected">
                            {" "}
                            <Icon.AlertTriangle
                              style={{ color: "black" }}
                              className="icon_common"
                            />{" "}
                            Category details are missing in Site Settings.
                          </span>
                          {this.state.acountIdCheck ? (
                            <span className="categoryNotSelected">
                              {" "}
                              <Icon.ArrowRight
                                style={{ color: "black" }}
                                className="icon_common"
                              />{" "}
                              <Link className="alertLink" to={"/settings"}>
                                {" "}
                                <span onClick={() => this.popUpcategoryClose()}>
                                  Click here
                                </span>
                              </Link>
                            </span>
                          ) : (
                            <>
                              <br />
                              <span className="categoryNotSelected">
                                {" "}
                                <Icon.AlertTriangle
                                  style={{ color: "black" }}
                                  className="icon_common"
                                />{" "}
                                Google Account details are missing in the site
                                settings.
                              </span>
                              <span className="categoryNotSelected">
                                {" "}
                                <Icon.ArrowRight
                                  style={{ color: "black" }}
                                  className="icon_common"
                                />{" "}
                                <Link className="alertLink" to={"/settings"}>
                                  {" "}
                                  <span
                                    onClick={() => this.popUpcategoryClose()}
                                  >
                                    Click here
                                  </span>
                                </Link>
                              </span>
                            </>
                          )}
                        </>
                      )}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        onClick={() => this.popUpcategoryClose()}
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            {/* <div className="alertCommon" >
                                <div className="alertPopup">
                                    <div className="alert alert-warnings " role="alert">
                                        <span className="categoryNotSelected">
                                            {" "}
                                            <Icon.AlertTriangle
                                                style={{ color: "YELLOW" }}
                                                className="icon_common"
                                            />{" "}
                                            Google Account details are missing from the site settings.
                                        </span>
                                        <span className="categoryNotSelected">
                                            {" "}
                                            <Icon.ArrowRight
                                                style={{ color: "WHITE" }}
                                                className="icon_common"
                                            />{" "}
                                            <Link className="alertLink" to={"/settings"}>
                                                {" "}
                                                Click here
                                            </Link>
                                        </span>
                                        <button
                                            type="button"
                                            className="close"
                                            data-dismiss="alert"
                                            onClick={() => this.popUpcategoryClose()}
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
        )} */}
            <div className="row">
              <div className="col-md-7 col-lg-9">
                <div className="row">
                  <div className="col-lg-9 col-md-7">
                    <div className="row">
                      <div className="col-lg-6 col-md-12 mb-4">
                        <div className="card">
                          <div className="createdDateDash">
                            {" "}
                            <div className="">AUDIT</div>
                          </div>
                          <div className="chart_dash">
                            {(this.state.flag == true && totalCount !== 0) ||
                            totalCompletedCount !== 0 ||
                            totalProcessCount !== 0 ||
                            totalFailedCount !== 0 ? (
                              <DashChart
                                valueForGraph={[
                                  totalCompletedCount !== 0
                                    ? (totalCompletedCount / totalCount) * 100
                                    : 0,
                                  (totalProcessCount / totalCount) * 100,
                                  (totalFailedCount / totalCount) * 100,
                                ]}
                              />
                            ) : (
                              <div className="mr-5">
                                <img
                                  src={noDataImage}
                                  alt="donut graph"
                                  width="100%"
                                  height="100%"
                                />
                              </div>
                            )}
                            <div className="chart_dash_head">
                              <div className="chart__head">Total Count</div>
                              <div className="chart__headtag">{totalCount}</div>
                              <div className="chart__head">Completed</div>
                              <div className="chart__headtag text-success">
                                {totalCompletedCount}
                              </div>
                              <div className="chart__head">Under Process</div>
                              <div className="chart__headtag text-warning">
                                {totalProcessCount}
                              </div>
                              <div className="chart__head">Failed</div>
                              <div className="chart__headtag text-danger">
                                {totalFailedCount}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 mb-4">
                        <div className="card">
                          <div className="createdDateDash">
                            <div>Visibilty & Accuracy</div>
                            {latestUpdate !== 0 && (
                              <div className="mr-2">
                                Updated Date:{" "}
                                <b>
                                  {" "}
                                  {moment(latestUpdate).format("DD-MMM-YYYY")}
                                </b>{" "}
                              </div>
                            )}
                          </div>
                          <div className="chart_dash">
                            {this.state.flagvalueForGraphVissibillityAcuuracy ==
                              true &&
                            address_match +
                              phone_no_match +
                              business_name_match !=
                              0 &&
                            (address_match !== 0 ||
                              phone_no_match !== 0 ||
                              business_name_match !== 0) ? (
                              <DashChartVissibillity
                                valueForGraphVissibillityAcuuracy={[
                                  address_match !== 0 && total_location !== 0
                                    ? (address_match /
                                        (address_match +
                                          phone_no_match +
                                          business_name_match)) *
                                      100
                                    : 0,
                                  phone_no_match !== 0 && total_location !== 0
                                    ? (phone_no_match /
                                        (address_match +
                                          phone_no_match +
                                          business_name_match)) *
                                      100
                                    : 0,
                                  business_name_match !== 0 &&
                                  total_location !== 0
                                    ? (business_name_match /
                                        (address_match +
                                          phone_no_match +
                                          business_name_match)) *
                                      100
                                    : 0,
                                ]}
                              />
                            ) : (
                              <div className="mr-5">
                                <img
                                  src={noDataImage}
                                  alt="donut graph"
                                  width="100%"
                                  height="100%"
                                />
                              </div>
                            )}
                            <div className="chart_dash_head">
                              <div className="chart__head">Total Location</div>
                              <div className="chart__headtag">
                                {total_location}
                              </div>
                              <div className="chart__head">Address Match</div>
                              <div className="chart__headtag text-success">
                                {address_match}
                              </div>
                              <div className="chart__head">
                                Phone Number Match
                              </div>
                              <div className="chart__headtag text-warning">
                                {phone_no_match}
                              </div>
                              <div className="chart__head">
                                Business Name Match
                              </div>
                              <div className="chart__headtag text-danger">
                                {business_name_match}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 mb-4">
                        <div className="card">
                          <div className="createdDateDash">
                            {" "}
                            <div className="">
                              Search & Maps{" "}
                              <span
                                style={{ color: "firebrick", fontSize: "11px" }}
                              >
                                (Last 10 days)
                              </span>
                            </div>
                          </div>
                          <div className="chart_dash">
                            {this.state.flag == true &&
                            (this.state.googleSearchDesktop != 0 ||
                              this.state.googleSearchMobile !== 0 ||
                              this.state.googleMapsDesktop != 0 ||
                              this.state.googleMapsMobile != 0) ? (
                              <DashChartGpp
                                valueForGraphGpp={[
                                  (this.state.googleSearchDesktop /
                                    (this.state.googleSearchDesktop +
                                      this.state.googleSearchMobile +
                                      this.state.googleMapsDesktop +
                                      this.state.googleMapsMobile)) *
                                    100,
                                  (this.state.googleSearchMobile /
                                    (this.state.googleSearchDesktop +
                                      this.state.googleSearchMobile +
                                      this.state.googleMapsDesktop +
                                      this.state.googleMapsMobile)) *
                                    100,
                                  (this.state.googleMapsDesktop /
                                    (this.state.googleSearchDesktop +
                                      this.state.googleSearchMobile +
                                      this.state.googleMapsDesktop +
                                      this.state.googleMapsMobile)) *
                                    100,
                                  (this.state.googleMapsMobile /
                                    (this.state.googleSearchDesktop +
                                      this.state.googleSearchMobile +
                                      this.state.googleMapsDesktop +
                                      this.state.googleMapsMobile)) *
                                    100,
                                ]}
                              />
                            ) : (
                              <div className="mr-5">
                                <img
                                  src={noDataImage}
                                  alt="donut graph"
                                  width="100%"
                                  height="100%"
                                />
                              </div>
                            )}
                            <div className="chart_dash_head">
                              <div className="chart__head">
                                Google Search - Desktop
                              </div>
                              <div className="chart__headtag">
                                {this.state.googleSearchDesktop}
                              </div>
                              <div className="chart__head">
                                Google Search - Mobile
                              </div>
                              <div className="chart__headtag text-success">
                                {this.state.googleSearchMobile}
                              </div>
                              <div className="chart__head">
                                Google Maps - Desktop
                              </div>
                              <div className="chart__headtag text-warning">
                                {this.state.googleMapsDesktop}
                              </div>
                              <div className="chart__head">
                                Google Maps - Mobile
                              </div>
                              <div className="chart__headtag text-danger">
                                {this.state.googleMapsMobile}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 mb-4">
                        <div className="card">
                          <div className="createdDateDash">
                            {" "}
                            <div className="">
                              Other Actions{" "}
                              <span
                                style={{ color: "firebrick", fontSize: "11px" }}
                              >
                                (Last 10 days)
                              </span>
                            </div>
                          </div>
                          <div className="chart_dash">
                            {this.state.flag == true &&
                            (this.state.googleBookings != 0 ||
                              this.state.googleCalls !== 0 ||
                              this.state.googleDirections != 0 ||
                              this.state.googleMessages != 0 ||
                              this.state.googleWebsiteClicks != 0) ? (
                              <DashChartMap
                                valueForGraphMap={[
                                  (this.state.googleCalls /
                                    (this.state.googleBookings +
                                      this.state.googleCalls +
                                      this.state.googleDirections +
                                      this.state.googleMessages +
                                      this.state.googleWebsiteClicks)) *
                                    100,
                                  (this.state.googleDirections /
                                    (this.state.googleBookings +
                                      this.state.googleCalls +
                                      this.state.googleDirections +
                                      this.state.googleMessages +
                                      this.state.googleWebsiteClicks)) *
                                    100,
                                  (this.state.googleMessages /
                                    (this.state.googleBookings +
                                      this.state.googleCalls +
                                      this.state.googleDirections +
                                      this.state.googleMessages +
                                      this.state.googleWebsiteClicks)) *
                                    100,
                                  (this.state.googleWebsiteClicks /
                                    (this.state.googleBookings +
                                      this.state.googleCalls +
                                      this.state.googleDirections +
                                      this.state.googleMessages +
                                      this.state.googleWebsiteClicks)) *
                                    100,
                                ]}
                              />
                            ) : (
                              <div className="mr-5">
                                <img
                                  src={noDataImage}
                                  alt="donut graph"
                                  width="100%"
                                  height="100%"
                                />
                              </div>
                            )}
                            <div className="chart_dash_head">
                              <div className="chart__head">Call Clicks</div>
                              <div className="chart__headtag ">
                                {this.state.googleCalls}
                              </div>
                              <div className="chart__head">
                                Direction Clicks
                              </div>
                              <div className="chart__headtag text-success">
                                {this.state.googleDirections}
                              </div>
                              <div className="chart__head">Chat Clicks</div>
                              <div className="chart__headtag text-warning">
                                {this.state.googleMessages}
                              </div>
                              <div className="chart__head">Website Clicks</div>
                              <div className="chart__headtag text-danger">
                                {this.state.googleWebsiteClicks}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-5">
                    <div className="row">
                      <div className="col-md-12 mb-3 card_rightHeader">
                        {this.state.lineGraphValues.map((data, i) => (
                          <div className="card card_user_data mb-3">
                            <div className="user_datahead">
                              {data.accountName}
                            </div>
                            <div className="used_datafree">
                              {" "}
                              Total {data.totalcnt}- With Place ID (
                              {data.approved})
                            </div>
                            <Line
                              percent={(data.approved / data.totalcnt) * 100}
                              strokeWidth={3}
                              strokeColor="#ff6d91"
                              trailWidth={3}
                              trailColor="#f6f9ff"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mx-0 bg-white mt-1 py-3 mb-3">
                  {this.state.excelData.length > 0 && (
                    <div className="col-lg-4 mb-3">
                      <div class="user_datahead">Recently Uploaded</div>
                      {this.state.excelData.slice(0, 5).map((data, i) => {
                        let createdDate = moment(data.created_on).format(
                          "DD MMM YYYY hh:mm a"
                        );
                        return (
                          <>
                            <div className="uploaded_divdash dashboard_breakall">
                              <Link
                                className="user_datacontentdiv"
                                onClick={() => this.drDetailsSection()}
                                to={"/auditDetails/" + data.id}
                                state={{
                                  tableId: data.id,
                                  status: data.status,
                                }}
                              >
                                <>
                                  <div className="user_dataconttleft">
                                    <img src={Dashboard} alt="Dashboard" />
                                  </div>
                                  <div className="user_dataconttcenter">
                                    <div className="user_datacntrhead">
                                      {data.excel_name}
                                    </div>
                                    <div className="user_datacntrdate">
                                      {createdDate}
                                    </div>
                                  </div>
                                </>
                                <div className="user_dataconttright">
                                  {data.totalcount} Nos
                                </div>
                              </Link>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  )}
                  {(this.state.locateItStatus === true ||
                    this.state.addressProcessingStatus === true ||
                    this.state.auditStatus === true ||
                    this.state.fraudStatus === true ||
                    this.state.businessClaimStatus === true ||
                    this.state.pushGBPDataStatus === true ||
                    this.state.reviewStatus === true ||
                    this.state.qAndAStatus === true ||
                    this.state.googlePostStatus === true ||
                    this.state.visibilityaccuracyStatus === true ||
                    this.state.competitorAnalysisStatus === true ||
                    this.state.productCatalogStatus === true) && (
                    <>
                      <div className="col-lg-1"></div>
                      <div className="col-lg-7">
                        <div className="user_datahead">Active Modules</div>
                        <ul className="nav dashboard_modulesdivul uploaded_divdash">
                          {this.state.locateItStatus === true ? (
                            <li className="nav-item">
                              <a href="/locateIt">
                                <div className="dashboard_modulesdiv bg-info">
                                  <img src={LocateIt} alt="LocateIt" />
                                </div>
                              </a>
                              <span className="dashboard_modulespan">
                                LocateIT
                              </span>
                            </li>
                          ) : (
                            ""
                          )}
                          {this.state.addressProcessingStatus === true ? (
                            <li className="nav-item">
                              <a href="/processAddress">
                                <div className="dashboard_modulesdiv bg-warning">
                                  <img src={Processing} alt="Address" />
                                </div>{" "}
                              </a>
                              <span className="dashboard_modulespan">
                                Address Processing
                              </span>
                            </li>
                          ) : (
                            ""
                          )}
                          {this.state.auditStatus === true ? (
                            <li className="nav-item">
                              <a href="/audit">
                                <div className="dashboard_modulesdiv bg-success">
                                  <img src={Audit} alt="Audit" />
                                </div>{" "}
                              </a>
                              <span
                                className="dashboard_modulespan"
                                onClick={() => {
                                  window.sessionStorage.setItem(
                                    "offsetDrDetailsMasterData",
                                    ""
                                  );
                                  window.sessionStorage.setItem(
                                    "pageNumberDrDetailsMasterData",
                                    1
                                  );
                                }}
                              >
                                Audit
                              </span>
                            </li>
                          ) : (
                            ""
                          )}
                          {this.state.fraudStatus === true ? (
                            <li className="nav-item">
                              <a href="/fraud">
                                <div className="dashboard_modulesdiv bg-danger">
                                  <img src={Fraud} alt="Fraud" />
                                </div>{" "}
                              </a>
                              <span className="dashboard_modulespan">
                                Fraud
                              </span>
                            </li>
                          ) : (
                            ""
                          )}
                          {this.state.businessClaimStatus === true && (
                            <li className="nav-item">
                              <a href="/claimBusiness">
                                <div className="dashboard_modulesdiv bg-secondary">
                                  <img src={Business} alt="GoogleAds" />
                                </div>{" "}
                              </a>
                              <span className="dashboard_modulespan">
                                Claim a Business
                              </span>
                            </li>
                          )}
                          {this.state.pushGBPDataStatus === true ? (
                            <li className="nav-item">
                              {" "}
                              <a href="/pushGBPData">
                                <div className="dashboard_modulesdiv bg-primary">
                                  <img src={PushGBP} alt="PushGBP" />
                                </div>{" "}
                              </a>
                              <span className="dashboard_modulespan">
                                Push GBP Data
                              </span>
                            </li>
                          ) : (
                            ""
                          )}
                          {this.state.reviewStatus === true ? (
                            <li className="nav-item">
                              <a
                                href="review/index"
                                className="submenu__isopen"
                              >
                                <div className="dashboard_modulesdiv bg-dark">
                                  <img src={Review} alt="Review" />
                                </div>{" "}
                              </a>
                              <span className="dashboard_modulespan">
                                Review
                              </span>
                            </li>
                          ) : (
                            ""
                          )}
                          {this.state.qAndAStatus === true ? (
                            <li className="nav-item">
                              <a href="/questionAnswer">
                                <div className="dashboard_modulesdiv bg-danger">
                                  <img src={QA} alt="Audit" />
                                </div>
                              </a>
                              <span className="dashboard_modulespan">
                                Q & A
                              </span>
                            </li>
                          ) : (
                            ""
                          )}
                          {this.state.googlePostStatus === true ? (
                            <li className="nav-item">
                              <a href="/googlepost/postListing">
                                <div className="dashboard_modulesdiv bg-info">
                                  <img src={GooglePost} alt="GooglePost" />
                                </div>{" "}
                              </a>
                              <span
                                className="dashboard_modulespan"
                                onClick={() => {
                                  window.sessionStorage.setItem(
                                    "GooglePost",
                                    ""
                                  );
                                  window.sessionStorage.setItem(
                                    "GooglePostData",
                                    1
                                  );
                                }}
                              >
                                Google Post
                              </span>
                            </li>
                          ) : (
                            ""
                          )}
                          {this.state.gbpStatus === true ? (
                            <li className="nav-item">
                              <a href="/GBPInsights">
                                <div className="dashboard_modulesdiv bg-warning">
                                  <img src={GBP} alt="Audit" />
                                </div>{" "}
                              </a>
                              <span className="dashboard_modulespan">GBP</span>
                            </li>
                          ) : (
                            ""
                          )}
                          {this.state.visibilityaccuracyStatus === true ? (
                            <li className="nav-item">
                              <a href="/visibility">
                                <div className="dashboard_modulesdiv bg-success">
                                  <img src={Accessibilty} alt="Accessibilty" />
                                </div>
                              </a>
                              <span className="dashboard_modulespan">
                                Visibility & Accuracy
                              </span>
                            </li>
                          ) : (
                            ""
                          )}
                          {this.state.competitorAnalysisStatus === true ? (
                            <li className="nav-item">
                              <a href="/competiterAnalysis/result">
                                <div className="dashboard_modulesdiv bg-secondary">
                                  <img
                                    src={Analysis}
                                    alt="Competitor Analysis"
                                  />
                                </div>{" "}
                              </a>
                              <span className="dashboard_modulespan">
                                Competitor Analysis
                              </span>
                            </li>
                          ) : (
                            ""
                          )}
                          {this.state.productCatalogStatus === true ? (
                            <li className="nav-item">
                              <a href="/home">
                                <div className="dashboard_modulesdiv bg-warning">
                                  <img
                                    src={ProductCatalog}
                                    alt="Product-Catalog"
                                  />
                                </div>
                              </a>
                              <span className="dashboard_modulespan">
                                Product Catalog
                              </span>
                            </li>
                          ) : (
                            ""
                          )}
                          {/* {this.state.googleAnalyticsStatus === true ? (
                                                <li className="nav-item">
                                                    <a href="/analytics">
                                                        <div className="dashboard_modulesdiv bg-info">
                                                            <img src={GoogleAnalytics} alt="Google Analytics" />
                                                        </div> </a>
                                                    <span className="dashboard_modulespan">Google Analytics</span>
                                                </li>
                                            ) : (
                                                ""
                                            )} */}
                          {/* {this.state.googleChatStatus === true ? (
                                                <li className="nav-item">
                                                    <a href="#" >
                                                        <div className="dashboard_modulesdiv bg-danger">
                                                            <img src={GoogleChat} alt="Google-Chat" />
                                                        </div> </a>
                                                    <span className="dashboard_modulespan">Review</span>
                                                </li>
                                            ) : (
                                                ""
                                            )} */}
                          {/* {this.state.socialMeStatus === true ? (
                                                <li className="nav-item">
                                                    <a href="/social-me">
                                                        <div className="dashboard_modulesdiv bg-success">
                                                            <img src={SocialMedia} alt="Social Media" />
                                                        </div></a>
                                                    <span className="dashboard_modulespan">Social Me</span>
                                                </li>
                                            ) : (
                                                ""
                                            )} */}
                          {/* {this.state.localPagesStatus === true ? (
                                                <li className="nav-item">
                                                    <a href="#" >
                                                        <div className="dashboard_modulesdiv bg-dark">
                                                            <img src={LocalPages} alt="LocalPages" />
                                                        </div>  </a>
                                                    <span className="dashboard_modulespan">Local Pages </span>
                                                </li>
                                            ) : (
                                                ""
                                            )} */}
                          {/* {this.state.chatbotStatus === true ? (
                                                <li className="nav-item">
                                                    <a href="#">
                                                        <div className="dashboard_modulesdiv bg-primary">
                                                            <img src={ChatBoat} alt="ChatBoat" />
                                                        </div></a>
                                                    <span className="dashboard_modulespan">AI Chat bot</span>
                                                </li>
                                            ) : (
                                                ""
                                            )} */}
                          {/* {this.state.facebookAdsStatus === true ? (
                                                <li className="nav-item">
                                                    <a href="#" >
                                                        <div className="dashboard_modulesdiv bg-success">
                                                            <img src={FbAd} alt="FacebookAds" />
                                                        </div>    </a>
                                                    <span className="dashboard_modulespan">Facebook Ads</span>
                                                    <span className="dashboard_modulespan">Review</span>
                                                </li>
                                            ) : (
                                                ""
                                            )} */}
                          {/* {this.state.googleAdsStatus === true ? (
                                                <li className="nav-item">
                                                    <a href="#" >
                                                        <div className="dashboard_modulesdiv bg-secondary">
                                                            <img src={GoogleAds} alt="GoogleAds" />
                                                        </div>  </a>
                                                    <span className="dashboard_modulespan">Google Ads</span>
                                                </li>
                                            ) : (
                                                ""
                                            )} */}
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card user_divcard">
                      <div className="user_divcarduser">
                        <div className="user_profile_dash">
                          <img src={UserProfile} />
                        </div>
                        <div className="user_profile_contt">
                          <div className="user_profile_name">
                            {" "}
                            {window.sessionStorage.getItem("userName")}
                          </div>
                          {window.sessionStorage.getItem("role") ===
                          "ROLE_SUPER_ADMIN"
                            ? "Super Admin"
                            : window.sessionStorage.getItem("role") ===
                              "ORG_ADMIN"
                            ? "Organization Admin"
                            : window.sessionStorage.getItem("role") ===
                              "ROLE_ADMIN"
                            ? "Admin"
                            : window.sessionStorage.getItem("role") === "Member"
                            ? "Member"
                            : window.sessionStorage.getItem("role") ===
                              "ROLE_USER"
                            ? "User"
                            : window.sessionStorage.getItem("role") ===
                              "CHAT_MANAGER"
                            ? "Chat Manager"
                            : "Chat Agent"}
                          {/* //   <div className="user_profile_subname">
                        //     {window.sessionStorage.getItem("role")}
                        //   </div> */}
                        </div>
                      </div>
                      <ul className="nav mt-2">
                        {this.state.organizationListIFUserLoginedIn.map(
                          (item, i) =>
                            window.sessionStorage.getItem("switchingOrgId") ==
                            item.id ? (
                              <li
                                className="nav-item activeOrg"
                                id={"className" + item.id}
                              >
                                <Icon.CheckCircle
                                  style={{ color: "#0a8908" }}
                                  className="icon_common"
                                />
                                <span
                                  onClick={(e) =>
                                    this.selectOrganization(item, item.id)
                                  }
                                >
                                  {item.organisation_name}
                                </span>
                              </li>
                            ) : (
                              <li
                                className="nav-item "
                                id={"className" + item.id}
                              >
                                <span
                                  onClick={(e) =>
                                    this.selectOrganization(item, item.id)
                                  }
                                >
                                  {item.organisation_name}
                                </span>
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                  </div>
                  {(this.state.userManagement === true ||
                    this.state.roleManagement === true ||
                    this.state.moduleManagement === true ||
                    this.state.organisationSetting === true ||
                    this.state.googleBusnessAccount === true ||
                    this.state.dataManagement === true ||
                    this.state.liveMonitoring === true ||
                    this.state.createZone === true ||
                    this.state.businessInsightsStatus === true ||
                    this.state.mapAndUnmapAccount === true ||
                    this.state.modulesList === true) && (
                    <>
                      <div className="col-md-12 mt-3">
                        <div className="card user_divcard">
                          <div class="user_datahead">Settings </div>
                          <div className="uploaded_divdash">
                            {this.state.userManagement === true ? (
                              <div className="user_datacontentdiv">
                                <Link
                                  to="/userlist"
                                  className="user_datacontentdiv"
                                >
                                  <div className="user_dataconttleft">
                                    <img src={Dashboard} alt="Dashboard" />
                                  </div>
                                  <div className="user_dataconttcenter">
                                    <div className="user_datacntrhead">
                                      User Management
                                    </div>
                                    <div className="user_datacntrdate"></div>
                                  </div>
                                </Link>
                              </div>
                            ) : (
                              ""
                            )}
                            {window.sessionStorage.getItem("is_privileged") ==
                              1 && (
                              <>
                                <div className="user_datacontentdiv">
                                  <Link
                                    to="/roleList"
                                    className="user_datacontentdiv"
                                  >
                                    <div className="user_dataconttleft">
                                      <img src={Roles} alt="Dashboard" />
                                    </div>
                                    <div className="user_dataconttcenter">
                                      <div className="user_datacntrhead">
                                        Roles Management
                                      </div>
                                      <div className="user_datacntrdate"></div>
                                    </div>
                                  </Link>
                                </div>
                                <div className="user_datacontentdiv">
                                  <Link
                                    to="/moduleList"
                                    className="user_datacontentdiv"
                                  >
                                    <div className="user_dataconttleft">
                                      <img src={Module} alt="Dashboard" />
                                    </div>
                                    <div className="user_dataconttcenter">
                                      <div className="user_datacntrhead">
                                        Module Management
                                      </div>
                                      <div className="user_datacntrdate"></div>
                                    </div>
                                  </Link>
                                </div>
                              </>
                            )}
                            {this.state.organisationSetting === true &&
                            (window.sessionStorage.getItem("role") ===
                              "ROLE_SUPER_ADMIN" ||
                              window.sessionStorage.getItem("role") ===
                                "ORG_ADMIN" ||
                              window.sessionStorage.getItem("role") ===
                                "ROLE_ADMIN") ? (
                              <div className="user_datacontentdiv">
                                <Link
                                  to="/settings"
                                  className="user_datacontentdiv"
                                >
                                  <div className="user_dataconttleft">
                                    <img src={Dashboard} alt="Dashboard" />
                                  </div>
                                  <div className="user_dataconttcenter">
                                    <div className="user_datacntrhead">
                                      Organization Settings
                                    </div>
                                    <div className="user_datacntrdate"></div>
                                  </div>
                                </Link>
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.googleBusnessAccount === true ? (
                              <div className="user_datacontentdiv">
                                <Link
                                  to="/businessAccount/index"
                                  className="user_datacontentdiv"
                                >
                                  <div className="user_dataconttleft">
                                    <img src={LocateIt} alt="Dashboard" />
                                  </div>
                                  <div className="user_dataconttcenter">
                                    <div className="user_datacntrhead">
                                      Google Business Account
                                    </div>
                                    <div className="user_datacntrdate"></div>
                                  </div>
                                </Link>
                              </div>
                            ) : (
                              ""
                            )}
                            {/* {this.state.businessInsightsStatus === true ? (
                                <div className="user_datacontentdiv">
                                  <Link
                                    to="/businessInsights"
                                    className="user_datacontentdiv"
                                  >
                                    <div className="user_dataconttleft">
                                      <img src={LocateIt} alt="Dashboard" />
                                    </div>
                                    <div className="user_dataconttcenter">
                                      <div className="user_datacntrhead">
                                        Fetch Business Insight Data
                                      </div>
                                      <div className="user_datacntrdate"></div>
                                    </div>
                                  </Link>
                                </div>
                              ) : (
                                ""
                              )} */}
                            {this.state.dataManagement === true ? (
                              <div className="user_datacontentdiv">
                                <Link
                                  to="/dataDashboard"
                                  className="user_datacontentdiv"
                                >
                                  <div className="user_dataconttleft">
                                    <img src={Processing} alt="Dashboard" />
                                  </div>
                                  <div className="user_dataconttcenter">
                                    <div className="user_datacntrhead">
                                      Data Management
                                    </div>
                                    <div className="user_datacntrdate"></div>
                                  </div>
                                </Link>
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.liveMonitoring === true ? (
                              <div className="user_datacontentdiv">
                                <Link
                                  to="/liveMonitoring"
                                  className="user_datacontentdiv"
                                >
                                  <div className="user_dataconttleft">
                                    <img src={LiveMonitoring} alt="Dashboard" />
                                  </div>
                                  <div className="user_dataconttcenter">
                                    <div className="user_datacntrhead">
                                      Live Monitoring
                                    </div>
                                    <div className="user_datacntrdate"></div>
                                  </div>
                                </Link>
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.liveMonitoring === true ? (
                              <div className="user_datacontentdiv">
                                <Link
                                  to="/serverProcessStatus"
                                  className="user_datacontentdiv"
                                >
                                  <div className="user_dataconttleft">
                                    <img src={LiveMonitoring} alt="Dashboard" />
                                  </div>
                                  <div className="user_dataconttcenter">
                                    <div className="user_datacntrhead">
                                      Server Process Status
                                    </div>
                                    <div className="user_datacntrdate"></div>
                                  </div>
                                </Link>
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.createZone === true ? (
                              <div className="user_datacontentdiv">
                                <Link
                                  to="/createzone"
                                  className="user_datacontentdiv"
                                >
                                  <div className="user_dataconttleft">
                                    <img src={LiveMonitoring} alt="Dashboard" />
                                  </div>
                                  <div className="user_dataconttcenter">
                                    <div className="user_datacntrhead">
                                      Create Zone
                                    </div>
                                    <div className="user_datacntrdate"></div>
                                  </div>
                                </Link>
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.mapAndUnmapAccount === true ? (
                              <div className="user_datacontentdiv">
                                <Link
                                  to="/mapunmap"
                                  className="user_datacontentdiv"
                                >
                                  <div className="user_dataconttleft">
                                    <img src={LiveMonitoring} alt="Dashboard" />
                                  </div>
                                  <div className="user_dataconttcenter">
                                    <div className="user_datacntrhead">
                                      Map / Unmap Accounts
                                    </div>
                                    <div className="user_datacntrdate"></div>
                                  </div>
                                </Link>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="user_datacontentdiv">
                              <Link
                                to="/support"
                                className="user_datacontentdiv"
                              >
                                <div className="user_dataconttleft">
                                  <img src={Dashboard} alt="Dashboard" />
                                </div>
                                <div className="user_dataconttcenter">
                                  <div className="user_datacntrhead">
                                    Support
                                  </div>
                                  <div className="user_datacntrdate"></div>
                                </div>
                              </Link>
                            </div>
                            {this.state.ProcessGBP === true ? (
                              <div className="user_datacontentdiv">
                                <Link
                                  to="/liveMonitoringProcessing"
                                  className="user_datacontentdiv"
                                >
                                  <div className="user_dataconttleft">
                                    <img src={Dashboard} alt="Dashboard" />
                                  </div>
                                  <div className="user_dataconttcenter">
                                    <div className="user_datacntrhead">
                                      Process Restart
                                    </div>
                                    <div className="user_datacntrdate"></div>
                                  </div>
                                </Link>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="user_datacontentdiv">
                              <Link
                                to="/categoriesSettings"
                                className="user_datacontentdiv"
                              >
                                <div className="user_dataconttleft">
                                  <img src={Dashboard} alt="Dashboard" />
                                </div>
                                <div className="user_dataconttcenter">
                                  <div className="user_datacntrhead">
                                    Categories Settings
                                  </div>
                                  <div className="user_datacntrdate"></div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-12 mt-3">
                                                <div className="card user_divcard">
                                                    <div class="user_datahead">Header </div>
                                                </div>
                                                <div className="user_suggest_edit">
                                                    <span>Suggest Edit</span>
                                                    <button type="button" className="btn btn-success">Check Suggest Edit</button>
                                                </div>
                            <div className="tableResponsive">
                                <div className="tableOuterWidth table_width_sm">
                                <table className="table mb-0">
                                <thead>
                                    <tr>
                                    <th className="col-4">Purple Health</th>
                                    <th className="col-5">Ac/ No.</th>
                                    <th className="col-3"></th>
                                    </tr>
                                </thead>
                                <div class="table_Outerscroll">
                                <tbody className="table_Outertbody">
                                    
                                        <tr>
                                            <td className="col-4">1</td>
                                            <td className="col-5">1</td>
                                            <td className="col-3">
                                            <div className="">
                                                <span type="button" className="btn-approve d-block">Approve</span>
                                                <span type="button" className="btn-approve text-danger">Reject</span>                                            
                                            </div>
                                            </td>
                                        </tr>
                                </tbody>
                                </div>
                                </table>
                                 </div>
                        </div>
                                            </div> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {this.state.showPopup && (
                <>
                    <Popup
                        holidayList={this.state.holidayList}
                        onClose={this.closePopup}
                    />
                </>
            )}
          <Footer />
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}
export default Newdashboard;
