import React, { Component } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import "../css/bootstrap.css";
import "../home/style.css";
import $ from "jquery";
// import ReactTooltip from 'react-tooltip';
import { BsHouseDoor } from "react-icons/bs";

class Newuser extends Component {
  componentDidMount() {
    $(function () {
      $(".genealogy-tree ul").hide();
      $(".genealogy-tree>ul").show();
      $(".genealogy-tree ul.active").show();
      $(".genealogy-tree li").on("click", function (e) {
        var children = $(this).find("> ul");
        if (children.is(":visible"))
          children.hide("fast").removeClass("active");
        else children.show("fast").addClass("active");
        e.stopPropagation();
      });
    });

    $("#tree_detail_1").hover(
      function () {
        $("#tree_box_1").show();
      },
      function () {
        $("#tree_box_1").hide();
      }
    );
    $("#tree_detail_2").hover(
      function () {
        $("#tree_box_2").show();
      },
      function () {
        $("#tree_box_2").hide();
      }
    );
    $("#tree_detail_3").hover(
      function () {
        $("#tree_box_3").show();
      },
      function () {
        $("#tree_box_3").hide();
      }
    );
    $("#tree_detail_4").hover(
      function () {
        $("#tree_box_4").show();
      },
      function () {
        $("#tree_box_4").hide();
      }
    );
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper">
          <Header heading="Review Details" />
        </div>

        <div className="body genealogy-body genealogy-scroll">
          <div className="genealogy-tree">
            <ul>
              <li>
                <a href="javascript:void(0);">
                  <div className="tree-view-box">
                    <div className="tree-details" id="tree_detail_1">
                      <BsHouseDoor />
                      {/* <ReactTooltip id='global' aria-haspopup='true' role='example' class="tree_tooltipbox">
                                                    <h3 className="tooltip_head">Audit</h3>
                                                </ReactTooltip> */}
                      <div className="tree_tooltipbox" id="tree_box_1">
                        <h3 className="tooltip_head">Audit</h3>
                        <div className="">
                          <button className="toolbox_buttn">show</button>
                          <button className="toolbox_buttn">show</button>
                        </div>
                        <div className="">
                          <button className="toolbox_buttn">show</button>
                          <button className="toolbox_buttn">show</button>
                        </div>
                        <div className="">
                          <button className="toolbox_buttn">show</button>
                          <button className="toolbox_buttn">show</button>
                        </div>
                        <div className="">
                          <button className="toolbox_buttn">show</button>
                          <button className="toolbox_buttn">show</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <ul className="active">
                  <li>
                    <a href="javascript:void(0);">
                      <div className="tree-view-box">
                        <div className="member-image">
                          <div className="tree-details" id="tree_detail_2">
                            <BsHouseDoor />
                            <div className="tree_tooltipbox" id="tree_box_2">
                              <h3 className="tooltip_head">Audit</h3>
                              <div className="">
                                <button className="toolbox_buttn">show</button>
                                <button className="toolbox_buttn">show</button>
                              </div>
                              <div className="">
                                <button className="toolbox_buttn">show</button>
                                <button className="toolbox_buttn">show</button>
                              </div>
                              <div className="">
                                <button className="toolbox_buttn">show</button>
                                <button className="toolbox_buttn">show</button>
                              </div>
                              <div className="">
                                <button className="toolbox_buttn">show</button>
                                <button className="toolbox_buttn">show</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                    <ul>
                      <li>
                        <a href="javascript:void(0);">
                          <div className="tree-view-box">
                            <div className="member-image">
                              <div className="tree-details" id="tree_detail_3">
                                <BsHouseDoor />
                                <div
                                  className="tree_tooltipbox"
                                  id="tree_box_3"
                                >
                                  <h3 className="tooltip_head">Audit</h3>
                                  <div className="">
                                    <button className="toolbox_buttn">
                                      show
                                    </button>
                                    <button className="toolbox_buttn">
                                      show
                                    </button>
                                  </div>
                                  <div className="">
                                    <button className="toolbox_buttn">
                                      show
                                    </button>
                                    <button className="toolbox_buttn">
                                      show
                                    </button>
                                  </div>
                                  <div className="">
                                    <button className="toolbox_buttn">
                                      show
                                    </button>
                                    <button className="toolbox_buttn">
                                      show
                                    </button>
                                  </div>
                                  <div className="">
                                    <button className="toolbox_buttn">
                                      show
                                    </button>
                                    <button className="toolbox_buttn">
                                      show
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <div className="tree-view-box">
                            <div className="member-image">
                              <div className="tree-details">
                                <BsHouseDoor />
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <div className="tree-view-box">
                            <div className="member-image">
                              {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                              <div className="tree-details">
                                <BsHouseDoor />
                              </div>
                            </div>
                          </div>
                        </a>
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div className="tree-view-box">
                                <div className="member-image">
                                  {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                                  <div className="tree-details">
                                    <BsHouseDoor />
                                  </div>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div className="tree-view-box">
                                <div className="member-image">
                                  {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                                  <div className="tree-details">
                                    <BsHouseDoor />
                                  </div>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div className="tree-view-box">
                                <div className="member-image">
                                  {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                                  <div className="tree-details">
                                    <BsHouseDoor />
                                  </div>
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <div className="tree-view-box">
                            <div className="member-image">
                              {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                              <div className="tree-details">
                                <BsHouseDoor />
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <div className="tree-view-box">
                            <div className="member-image">
                              {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                              <div className="tree-details">
                                <BsHouseDoor />
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <div className="tree-view-box">
                            <div className="member-image">
                              {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                              <div className="tree-details">
                                <BsHouseDoor />
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <div className="tree-view-box">
                            <div className="member-image">
                              {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                              <div className="tree-details">
                                <BsHouseDoor />
                              </div>
                            </div>
                          </div>
                        </a>
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div className="tree-view-box">
                                <div className="member-image">
                                  {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                                  <div className="tree-details">
                                    <BsHouseDoor />
                                  </div>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div className="tree-view-box">
                                <div className="member-image">
                                  {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                                  <div className="tree-details">
                                    <BsHouseDoor />
                                  </div>
                                </div>
                              </div>
                            </a>
                            <ul>
                              <li>
                                <a href="javascript:void(0);">
                                  <div className="tree-view-box">
                                    <div className="member-image">
                                      {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                                      <div className="tree-details">
                                        <BsHouseDoor />
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <div className="tree-view-box">
                                    <div className="member-image">
                                      {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                                      <div className="tree-details">
                                        <BsHouseDoor />
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <div className="tree-view-box">
                                    <div className="member-image">
                                      {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                                      <div className="tree-details">
                                        <BsHouseDoor />
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div className="tree-view-box">
                                <div className="member-image">
                                  {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                                  <div className="tree-details">
                                    <BsHouseDoor />
                                  </div>
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <div className="tree-view-box">
                        <div className="member-image">
                          <div className="tree-details" id="tree_detail_4">
                            <BsHouseDoor />
                            <div className="tree_tooltipbox" id="tree_box_4">
                              <h3 className="tooltip_head">Audit</h3>
                              <div className="">
                                <button className="toolbox_buttn">show</button>
                                <button className="toolbox_buttn">show</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                    <ul>
                      <li>
                        <a href="javascript:void(0);">
                          <div className="tree-view-box">
                            <div className="member-image">
                              {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                              <div className="tree-details">
                                <BsHouseDoor />
                              </div>
                            </div>
                          </div>
                        </a>
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div className="tree-view-box">
                                <div className="member-image">
                                  {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                                  <div className="tree-details">
                                    <BsHouseDoor />
                                  </div>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div className="tree-view-box">
                                <div className="member-image">
                                  {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                                  <div className="tree-details">
                                    <BsHouseDoor />
                                  </div>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div className="tree-view-box">
                                <div className="member-image">
                                  {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                                  <div className="tree-details">
                                    <BsHouseDoor />
                                  </div>
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <div className="tree-view-box">
                            <div className="member-image">
                              {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                              <div className="tree-details">
                                <BsHouseDoor />
                              </div>
                            </div>
                          </div>
                        </a>
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div className="tree-view-box">
                                <div className="member-image">
                                  {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                                  <div className="tree-details">
                                    <BsHouseDoor />
                                  </div>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div className="tree-view-box">
                                <div className="member-image">
                                  {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                                  <div className="tree-details">
                                    <BsHouseDoor />
                                  </div>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div className="tree-view-box">
                                <div className="member-image">
                                  {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                                  <div className="tree-details">
                                    <BsHouseDoor />
                                  </div>
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <div className="tree-view-box">
                            <div className="member-image">
                              {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                              <div className="tree-details">
                                <BsHouseDoor />
                              </div>
                            </div>
                          </div>
                        </a>
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div className="tree-view-box">
                                <div className="member-image">
                                  {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                                  <div className="tree-details">
                                    <BsHouseDoor />
                                  </div>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div className="tree-view-box">
                                <div className="member-image">
                                  {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                                  <div className="tree-details">
                                    <BsHouseDoor />
                                  </div>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div className="tree-view-box">
                                <div className="member-image">
                                  {/* <img src="https://image.flaticon.com/icons/svg/145/145867.svg" alt="Member" /> */}
                                  <div className="tree-details">
                                    <BsHouseDoor />
                                  </div>
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default Newuser;
