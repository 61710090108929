import React, { Component } from "react";
import * as Icon from "react-feather";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import AllSearches from "../GBP/all_searches";
import TotalView from "../GBP/total_view";
import Gbpimage from "../images/left_icons/Google-business-profile.png";
import { backendApi } from "../apiService";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import moment from "moment";
import $, { data } from "jquery";
import AWS from "aws-sdk";

const s3ImageUrl =
  "http://tagtree.s3-website.ap-south-1.amazonaws.com/production/images";
const s3Region = "us-east-1";
const s3Bucket = "tagtree";
const s3BucketMainFolder = "production";
const S3BucketImagesFolder = "images";
var addressLinesConstant = 0;
var phoneNumbersConstant = 0;
const daysofThisMonth = Array.from(
  Array(moment().daysInMonth()),
  (_, i) => i + 1
);
var timeList = [
  "0:00",
  "0:30",
  "1:00",
  "1:30",
  "2:00",
  "2:30",
  "3:00",
  "3:30",
  "4:00",
  "4:30",
  "5:00",
  "5:30",
  "6:00",
  "6:30",
  "7:00",
  "7:30",
  "8:00",
  "8:30",
  "9:00",
  "9:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];
class Info extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completeGbpList: "",
      insightsData: [],
      insightsDataKey: "",
      openingDate: "",
      openInfoMonth: "",
      imageUrlListStatus: 0,
      todayDate: new Date(),
      insightsStartDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ).toISOString(),
      insightsEndDate: new Date().toISOString(),
      imageUrl: [],
      imageName: "",
      fileArrayImageList: [],
    };
  }
  componentDidMount() {
    this.getGbpData();
    // this.getInsights();
    console.log("heloooo", this.state.insightsStartDate);
    console.log("byeeeee", this.state.insightsEndDate);
  }
  // getInsights() {
  //   var req = {
  //     functionName: "GetInsights",
  //     account: window.sessionStorage.getItem("account_id"),
  //     location: "53904911361864879",
  //     startDate: this.state.insightsStartDate,
  //     endTime: this.state.insightsEndDate,
  //     siteId:
  //       window.sessionStorage.getItem("switchingOrgId") === ""
  //         ? window.sessionStorage.getItem("organizationId")
  //         : window.sessionStorage.getItem("switchingOrgId"),
  //   };
  //   backendApi(req).then((data) => {
  //     if (data.success === "1") {
  //       this.setState({ insightsData: data.data, insightsDataKey: 1 }, () => {
  //         console.log(
  //           "insightsDatainsightsDatainsightsDatainsightsData",
  //           this.state.insightsData
  //         );
  //       });
  //     }
  //   });
  // }
  // selectInsightDate() {
  //   this.getInsights();
  // }
  getGbpData() {
    var req = {
      functionName: "GBPAddress",
      googleUpdated: false,
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(req).then((data) => {
      if (data.success === "1") {
        this.setState({ completeGbpList: data.data }, () => {});
      } else {
        alert("Something went wrong!!");
      }
    });
  }
  updateGBPDetails = (object) => {
    if (object === "title") {
      $("#titleShow").attr("hidden", true);
      $("#titleEdit").attr("hidden", false);
      $("#titleEditButton").attr("hidden", false);
      $("#titleEdit").val($("#titleShow").text());
      $("#titleEditIcon").attr("hidden", true);
      $("#titleCancelEdit").removeAttr("hidden");
    } else if (object === "profile") {
      $("#profileShow").attr("hidden", true);
      $("#profileEdit").attr("hidden", false);
      $("#profileEditButton").attr("hidden", false);
      $("#profileEdit").val($("#profileShow").text());
      $("#profileEditIcon").attr("hidden", true);
      $("#profileCancelEdit").removeAttr("hidden");
    } else if (object === "storefrontAddress") {
      let storefrontAddress = this.state.completeGbpList.storefrontAddress;
      //   console.log(storefrontAddress);
      $("#storefrontAddressShow").attr("hidden", true);
      $("#storefrontAddressEditIcon").attr("hidden", true);
      $("#storefrontAddressCancelEdit").removeAttr("hidden");
      $("#addMoreAddressLines").removeAttr("hidden");
      $("#storefrontAddressEditButton").attr("hidden", false);
      $("#storefrontAddressEditLocalityShow").attr("hidden", true);
      $("#storefrontAddressEditAdministrativeAreaShow").attr("hidden", true);
      $("#storefrontAddressEditPostalCodeShow").attr("hidden", true);
      $("#storefrontAddressEditPostalCodeEdit").attr("hidden", false);
      $("#storefrontAddressEditPostalCodeEdit").val(
        storefrontAddress.postalCode
      );
      $("#storefrontAddressEditLocalityEdit").attr("hidden", false);
      $("#storefrontAddressEditLocalityEdit").val(storefrontAddress.locality);
      $("#storefrontAddressEditAdministrativeAreaEdit").attr("hidden", false);
      $("#storefrontAddressEditAdministrativeAreaEdit").val(
        storefrontAddress.administrativeArea
      );
      if (storefrontAddress.addressLines.length > 0) {
        $("#storefrontAddressEditAddressLinesIntial").attr("hidden", false);
        $("#storefrontAddressEditAddressLinesIntial").val(
          storefrontAddress.addressLines[0]
        );
        for (let i = 1; i < storefrontAddress.addressLines.length; i++) {
          this.addIntialDiv(storefrontAddress.addressLines[i]);
        }
      }
      //   $("#storefrontAddressShow").attr("hidden", true);
    } else if (object === "websiteUri") {
      $("#websiteUriShow").attr("hidden", true);
      $("#websiteUriEdit").attr("hidden", false);
      $("#websiteUriEditButton").attr("hidden", false);
      $("#websiteUriEdit").val($("#websiteUriShow").text());
      $("#websiteUriEditIcon").attr("hidden", true);
      $("#websiteUriCancelEdit").removeAttr("hidden");
    } else if (object === "phoneNumbers") {
      let phoneNumbers = this.state.completeGbpList.phoneNumbers;
      $("#phoneNumbersShowPrimary").attr("hidden", true);
      $("#phoneNumbersEditPrimary").attr("hidden", false);
      $("#phoneNumbersEditButton").attr("hidden", false);
      $("#phoneNumbersEditPrimaryValue").val(phoneNumbers.primaryPhone);
      $("#phoneNumbersEditIcon").attr("hidden", true);
      $("#phoneNumbersCancelEdit").removeAttr("hidden");
      $("#phoneNumbersShowAdditional").attr("hidden", true);
      $("#addMorephoneNumbersAdd").removeAttr("hidden");
      if (phoneNumbers.additionalPhones.length > 0) {
        $("#phoneNumbersEditAdditionalInitial").attr("hidden", false);
        $("#phoneNumbersEditAdditionalInitial").val(
          phoneNumbers.additionalPhones[0]
        );
        for (let i = 1; i < phoneNumbers.additionalPhones.length; i++) {
          this.addIntialDivPhones(phoneNumbers.additionalPhones[i]);
        }
      }
    } else if (object === "openInfo") {
      $("#openInfoEditButton").attr("hidden", true);
      $("#openInfoSaveEditButton").attr("hidden", false);
      $("#openInfoCancelEdit").removeAttr("hidden");
      $("#openingDateShow").attr("hidden", true);
      $("#openInfoMonthEdit").attr("hidden", false);
      $("#openInfoDayEdit").attr("hidden", false);
      $("#openInfoYearEdit").attr("hidden", false);
    } else if (object === "regularHours") {
      $("#workingHoursShow").attr("hidden", true);
      $("#workingHoursEdit").attr("hidden", false);
      $("#regularHoursEditIcon").attr("hidden", true);
      $("#regularHoursCancelEdit").removeAttr("hidden");
      $("#workingHoursEditButton").attr("hidden", false);
      for (let i = 0; i <= 6; i++) {
        if (this.state.completeGbpList.regularHours.periods[i]) {
          $("#" + i + "Fromtime").val(
            this.state.completeGbpList.regularHours.periods[i].openTime.hours
              ? this.state.completeGbpList.regularHours.periods[i].openTime
                  .minutes
                ? this.state.completeGbpList.regularHours.periods[i].openTime
                    .hours +
                  ":" +
                  this.state.completeGbpList.regularHours.periods[i].openTime
                    .minutes
                : this.state.completeGbpList.regularHours.periods[i].openTime
                    .hours + ":00"
              : ""
          );
          $("#" + i + "Totime").val(
            this.state.completeGbpList.regularHours.periods[i].closeTime.hours
              ? this.state.completeGbpList.regularHours.periods[i].closeTime
                  .minutes
                ? this.state.completeGbpList.regularHours.periods[i].closeTime
                    .hours +
                  ":" +
                  this.state.completeGbpList.regularHours.periods[i].closeTime
                    .minutes
                : this.state.completeGbpList.regularHours.periods[i].closeTime
                    .hours + ":00"
              : ""
          );
        } else {
          $("#" + i + "TimeCheck").prop("checked", true);
          $("#" + i + "Fromtime, #" + i + "Totime").attr("hidden", true);
        }
      }
    }
  };
  hidePhoneNumbers() {
    $("#phoneNumbersShowPrimary").attr("hidden", false);
    $("#phoneNumbersEditPrimary").attr("hidden", true);
    $("#phoneNumbersEditIcon").removeAttr("hidden");
    $("#phoneNumbersCancelEdit").attr("hidden", true);
    $("#phoneNumbersShowAdditional").attr("hidden", false);
    $("#addMorephoneNumbersAdd").attr("hidden", true);
    $("#phoneNumbersEditAdditionalInitial").attr("hidden", true);
    $(".app_clone_category_phone_numbers").empty();
    $("#phoneNumbersEditButton").attr("hidden", true);
  }
  hideAddresDatas() {
    let storefrontAddress = this.state.completeGbpList.storefrontAddress;
    //   console.log(storefrontAddress);
    $("#storefrontAddressShow").attr("hidden", false);
    $("#storefrontAddressEditIcon").removeAttr("hidden");
    $("#storefrontAddressCancelEdit").attr("hidden", true);
    $("#addMoreAddressLines").attr("hidden", false);
    $("#storefrontAddressEditButton").attr("hidden", true);
    $("#storefrontAddressEditLocalityShow").attr("hidden", false);
    $("#storefrontAddressEditAdministrativeAreaShow").attr("hidden", false);
    $("#storefrontAddressEditPostalCodeShow").attr("hidden", false);
    $("#storefrontAddressEditPostalCodeEdit").attr("hidden", true);
    $("#storefrontAddressEditPostalCodeEdit").val("");
    $("#storefrontAddressEditLocalityEdit").attr("hidden", true);
    $("#storefrontAddressEditLocalityEdit").val("");
    $("#storefrontAddressEditAdministrativeAreaEdit").attr("hidden", true);
    $("#storefrontAddressEditAdministrativeAreaEdit").val(
      storefrontAddress.administrativeArea
    );
    $("#storefrontAddressEditAddressLinesIntial").attr("hidden", true);
    $("#storefrontAddressEditAddressLinesIntial").val("");
    $(".app_clone_category").empty();
    //   $("#storefrontAddressShow").attr("hidden", true);
  }
  addAddressLines() {
    if (addressLinesConstant > 6) {
      alert("Can only enter upto 6 lines");
      return false;
    }
    $(".app_clone_category").append(
      '<div class="append_inputdiv "><input id="storefrontAddressEditAddressLinesClone' +
        addressLinesConstant +
        '" type="text" class="editInput form-control" placeholder="Enter Address Line" /></div>'
    );
    addressLinesConstant++;
  }
  addIntialDiv(e) {
    let initial = e;
    if (addressLinesConstant > 6) {
      alert("Can only enter upto 6 lines");
      return false;
    }
    $(".app_clone_category").append(
      '<div class="append_inputdiv"><input value="' +
        initial +
        '" id="storefrontAddressEditAddressLinesClone' +
        addressLinesConstant +
        '" type="text" class="editInput form-control" placeholder="Enter Categories" /></div>'
    );
    addressLinesConstant++;
  }
  addIntialDivPhones(e) {
    let initial = e;
    if (phoneNumbersConstant > 6) {
      alert("Can only enter upto 6 lines");
      return false;
    }
    $(".app_clone_category_phone_numbers").append(
      '<div class="append_inputdiv"><input value="' +
        initial +
        '" id="phoneNumbersEditAdditionalClone' +
        phoneNumbersConstant +
        '" type="text" class="editInput form-control" placeholder="Enter Additional Phone Number" /></div>'
    );
    phoneNumbersConstant++;
  }
  addPhoneNumbers() {
    $(".app_clone_category_phone_numbers").append(
      '<div class="append_inputdiv"><input id="phoneNumbersEditAdditionalClone' +
        phoneNumbersConstant +
        '" type="text" class="editInput form-control" placeholder="Enter Additional Phone Number" /></div>'
    );
    phoneNumbersConstant++;
  }
  UpdateGBPLocationAPI = (object) => {
    let updateMask = "";
    let obj = {};
    let periods = [];
    if (object === "title") {
      updateMask = "title";
      obj = {
        title: $("#titleEdit").val(),
      };
    } else if (object === "profile") {
      updateMask = "profile";
      obj = {
        profile: {
          description: $("#profileEdit").val(),
        },
      };
    } else if (object === "storefrontAddress") {
      var addressLines = [];
      addressLines.push($("#storefrontAddressEditAddressLinesIntial").val());
      if (addressLinesConstant > 0) {
        for (let i = 0; i < addressLinesConstant; i++) {
          if ($("#storefrontAddressEditAddressLinesClone" + i).val() !== "") {
            addressLines.push(
              $("#storefrontAddressEditAddressLinesClone" + i).val()
            );
          }
        }
      }
      updateMask = "storefrontAddress";
      obj = {
        storefrontAddress: {
          postalCode: $("#storefrontAddressEditPostalCodeEdit").val(),
          administrativeArea: $(
            "#storefrontAddressEditAdministrativeAreaEdit"
          ).val(),
          locality: $("#storefrontAddressEditLocalityEdit").val(),
          addressLines: addressLines,
        },
      };
    } else if (object === "websiteUri") {
      updateMask = "websiteUri";
      obj = {
        websiteUri: $("#websiteUriEdit").val(),
      };
    } else if (object === "phoneNumbers") {
      updateMask = "phoneNumbers";
      var additionalPhoneNos = [];
      additionalPhoneNos.push($("#phoneNumbersEditAdditionalInitial").val());
      for (let i = 0; i < phoneNumbersConstant; i++) {
        additionalPhoneNos.push(
          $("#phoneNumbersEditAdditionalClone" + i).val()
        );
      }
      obj = {
        phoneNumbers: {
          primaryPhone: $("#phoneNumbersEditPrimaryValue").val(),
          additionalPhones: additionalPhoneNos,
        },
      };
    } else if (object === "openInfo") {
      updateMask = "openInfo";
      obj = {
        status: "OPEN",
        canReopen: true,
        openingDate: {
          year: parseInt($("#openInfoYearEdit").val()),
          month: parseInt($("#openInfoMonthEdit").val()),
          ...($("#openInfoDayEdit").val() && {
            day: parseInt(this.state.openInfoDayEdit),
          }),
        },
      };
    } else if (object === "regularHours") {
      updateMask = "regularHours";
      for (let i = 0; i <= 6; i++) {
        // console.log("sadasd", $("#" + i + "FromTime :selected").text());

        if (!$("#" + i + "TimeCheck").is(":checked")) {
          periods.push({
            openDay: $("#" + i + "DayValue")
              .text()
              .toUpperCase(),
            openTime: {
              hours: parseInt(
                $("#" + i + "Fromtime")
                  .val()
                  .split(":")[0]
              ),
              minutes: parseInt(
                $("#" + i + "Fromtime")
                  .val()
                  .split(":")[1]
              ),
            },
            closeDay: $("#" + i + "DayValue")
              .text()
              .toUpperCase(),
            closeTime: {
              hours: parseInt(
                $("#" + i + "Totime")
                  .val()
                  .split(":")[0]
              ),
              minutes: parseInt(
                $("#" + i + "Totime")
                  .val()
                  .split(":")[1]
              ),
            },
          });
        }
      }
      obj = {
        periods: periods,
      };
    }
    var req = {
      functionName: "UpdateGBPLocation",
      validate: false,
      obj: obj,
      updateMask: updateMask,
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(req).then((data) => {
      if (data.success === "1") {
        alert("Saved Successfully");
        window.location.reload();
      }
    });
  };
  handleImageUpload(image) {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_TAGTREE_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_TAGTREE_S3_SECRET_KEY,
      region: s3Region,
    });
    var bucketParams = {
      Bucket: s3Bucket,
    };
    // s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: bucketParams,
    });
    let s3BucketMainFolder1 = s3BucketMainFolder + "/" + S3BucketImagesFolder;
    for (let i = 0; i < image.length; i++) {
      console.log("image[i]image[i]image[i]", image[i]);
      let fileName = this.uuidv4() + image[i].name;
      let fullFilePath = s3BucketMainFolder1 + "/" + fileName;
      console.log("fullFilePath", fullFilePath);
      s3.upload(
        {
          Key: fullFilePath,
          Body: image[i],
          ACL: "public-read",
        },
        (err, data) => {
          if (err) {
            alert("There was an error uploading your image");
            console.log(err.message);
          } else if (data) {
            alert("Image Uploaded Successfully");
            console.log(data.Location);
            $("#uploadedImage").attr("hidden", false);
            this.state.imageUrl.push(data.Location);
            console.log("imageUrlimageUrlimageUrl", this.state.imageUrl);
            this.setState({
              imageUrlListStatus: i + 1,
            });
            // this.state.imageName.push(data.fileName);
            // this.setState({ imageUrl: data.Location, imageName: fileName });

            return;
          }
        }
      );
    }
  }
  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  postPhotoToGBP() {
    this.state.imageUrl.forEach((data, i) => {
      var req = {
        functionName: "PostMediaGBP",
        account: "113149385002384039024",
        locationId: "53904911361864879",
        category: $("#categoryDropdown").val(),
        mediaFormat: $("#formatTypeDropdown").val(),
        source: data,
        site_id:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
      };
      // console.log("reqreqreqreqreqreq",req);
      backendApi(req).then(async (data) => {
        console.log(i);
        alert("Image posted successfully");
      });
    });
  }
  imageListing = () => {
    debugger;
    return (
      <div id="uploadedImage" className="imageUploaded">
        {this.state.imageUrl.map((data, i) => (
          <>
            {console.log("datadata", data)}
            <img src={data} alt="" className="imageUploaded_img" />;
            <span className="close_popup_imageUpload">
              <Icon.X
                onClick={() => {
                  $("#uploadedImage").attr("hidden", true);
                  let items = [...this.state.imageUrl];
                  items.splice(i, 1);
                  this.setState({ imageUrl: items });

                  // setImageName("");
                  // setPhotos(true);
                }}
              />
            </span>
            ;
          </>
        ))}
      </div>
    );
  };
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper">
          <Header heading="GBP" headerImage={Gbpimage} />
          <div className="user_listdiv">
            <div className="user_listview">
              <div className="user_listviewhead">
                <span>Info</span>
              </div>
              {this.state.completeGbpList !== "" ? (
                <>
                  <div className="info_location">
                    <div className="info_inneraddress">
                      <div className="info_iconlocation">
                        <Icon.MapPin />
                      </div>
                      <div className="info_contentlocation">
                        <p>
                          <b>Business Name</b>
                        </p>
                        <p id="titleShow">{this.state.completeGbpList.title}</p>
                        <div className="editInfo">
                          <input
                            type="text"
                            id="titleEdit"
                            hidden
                            className="form-control"
                          />
                          <button
                            id="titleEditButton"
                            hidden
                            type="button"
                            className="btn btn-success btn-infosight"
                            onClick={() => this.UpdateGBPLocationAPI("title")}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                      <div className="info_editlocation">
                        <Icon.Edit2
                          id="titleEditIcon"
                          onClick={() => this.updateGBPDetails("title")}
                        />
                        <Icon.XCircle
                          id="titleCancelEdit"
                          hidden
                          onClick={() => {
                            $("#titleCancelEdit").attr("hidden", true);
                            $("#titleEditIcon").removeAttr("hidden");
                            $("#titleShow").attr("hidden", false);
                            $("#titleEdit").attr("hidden", true);
                            $("#titleEditButton").attr("hidden", true);
                          }}
                        />
                      </div>
                    </div>
                    <div className="info_inneraddress">
                      <div className="info_iconlocation">
                        <Icon.Clipboard />
                      </div>
                      <div className="info_contentlocation">
                        <p>
                          <b>Business Category</b>
                        </p>
                        <p>
                          {
                            this.state.completeGbpList.categories
                              .primaryCategory.displayName
                          }{" "}
                          -{" "}
                          <span>
                            <b>PRIMARY</b>
                          </span>
                        </p>
                        {this.state.completeGbpList.categories.additionalCategories.map(
                          (data, i) => (
                            <p key={i}>{data.displayName}</p>
                          )
                        )}
                      </div>
                      <div className="info_editlocation">
                        <Icon.Edit2 />
                      </div>
                    </div>
                    <div className="info_inneraddress">
                      <div className="info_iconlocation">
                        <Icon.AlignJustify />
                      </div>
                      <div className="info_contentlocation">
                        <p>
                          <b>Business Description</b>
                        </p>
                        <p id="profileShow">
                          {this.state.completeGbpList.profile.description}
                        </p>
                        <div className="editInfo">
                          <textarea
                            id="profileEdit"
                            hidden
                            className="form-control"
                          />
                          <button
                            id="profileEditButton"
                            hidden
                            type="button"
                            className="btn btn-success btn-infosight"
                            onClick={() => this.UpdateGBPLocationAPI("profile")}
                          >
                            Save
                          </button>
                        </div>
                      </div>

                      <div className="info_editlocation">
                        <Icon.Edit2
                          id="profileEditIcon"
                          onClick={() => this.updateGBPDetails("profile")}
                        />
                        <Icon.XCircle
                          id="profileCancelEdit"
                          hidden
                          onClick={() => {
                            $("#profileCancelEdit").attr("hidden", true);
                            $("#profileEditIcon").removeAttr("hidden");
                            $("#profileShow").attr("hidden", false);
                            $("#profileEdit").attr("hidden", true);
                            $("#profileEditButton").attr("hidden", true);
                          }}
                        />
                      </div>
                    </div>
                    <div className="info_inneraddress">
                      <div className="info_iconlocation">
                        <Icon.MapPin className="service_infoicon" />
                      </div>
                      <div className="info_contentlocation">
                        <p>
                          <b>Business Address</b>
                        </p>
                        <p id="storefrontAddressShow">
                          {this.state.completeGbpList.storefrontAddress.addressLines.join(
                            ", "
                          )}
                        </p>
                        <div className="append_inputdiv">
                          <input
                            type="text"
                            id="storefrontAddressEditAddressLinesIntial"
                            hidden
                            className="form-control"
                          />
                          <Icon.Plus
                            id="addMoreAddressLines"
                            className="service_infoicon"
                            onClick={() => this.addAddressLines()}
                            hidden
                          />
                        </div>
                        <div className="app_clonediv_link app_clone_category"></div>
                        {/* <input
                          type="text"
                          id="storefrontAddressEditAddressLinesClone"
                          hidden
                        /> */}
                        <p>
                          <span id="storefrontAddressEditLocalityShow">
                            {
                              this.state.completeGbpList.storefrontAddress
                                .locality
                            }
                            ,{" "}
                          </span>
                          <span id="storefrontAddressEditAdministrativeAreaShow">
                            {
                              this.state.completeGbpList.storefrontAddress
                                .administrativeArea
                            }
                            ,{" "}
                          </span>
                          <span id="storefrontAddressEditPostalCodeShow">
                            {
                              this.state.completeGbpList.storefrontAddress
                                .postalCode
                            }
                          </span>
                        </p>
                        <p className="administrative_area">
                          <input
                            type="text"
                            id="storefrontAddressEditAdministrativeAreaEdit"
                            placeholder="Enter Administrative Area"
                            hidden
                            className="form-control"
                          />
                          <input
                            type="text"
                            id="storefrontAddressEditLocalityEdit"
                            placeholder="Enter Locality"
                            hidden
                            className="form-control"
                          />
                          {/* <input
                            type="text"
                            id="storefrontAddressEditSublocality"
                            hidden
                          />
                          <input
                            type="text"
                            id="storefrontAddressEditSortingCode"
                            hidden
                          /> */}
                          <input
                            type="text"
                            id="storefrontAddressEditPostalCodeEdit"
                            placeholder="Enter Postal Code"
                            hidden
                            className="form-control"
                          />
                          {/* <input
                            type="text"
                            id="storefrontAddressEditLanguageCode"
                            hidden
                          />
                          <input
                            type="text"
                            id="storefrontAddressEditRegionCode"
                            hidden
                          /> */}
                        </p>
                        <button
                          id="storefrontAddressEditButton"
                          hidden
                          type="button"
                          className="btn btn-success storefrontAddressEditButton"
                          onClick={() =>
                            this.UpdateGBPLocationAPI("storefrontAddress")
                          }
                        >
                          Save
                        </button>
                      </div>
                      <div className="info_editlocation">
                        <Icon.Edit2
                          id="storefrontAddressEditIcon"
                          onClick={() =>
                            this.updateGBPDetails("storefrontAddress")
                          }
                        />
                        <Icon.XCircle
                          id="storefrontAddressCancelEdit"
                          hidden
                          onClick={() => this.hideAddresDatas()}
                        />
                      </div>
                    </div>

                    <div className="info_inneraddress">
                      <div className="info_iconlocation">
                        <Icon.Clock />
                      </div>
                      <div
                        className="info_contentlocation"
                        id="workingHoursShow"
                      >
                        {this.state.completeGbpList.regularHours.periods.map(
                          (data, i) => {
                            let openMeridianStatus = "";
                            let closeMeridianStatus = "";
                            data.openTime.hours > "12"
                              ? (openMeridianStatus = "PM")
                              : (openMeridianStatus = "AM");
                            data.closeTime.hours > "12"
                              ? (closeMeridianStatus = "PM")
                              : (closeMeridianStatus = "AM");
                            return (
                              <>
                                <p key={i} className="info_dayslocation">
                                  <span className="info_locationspan">
                                    {data.openDay}
                                  </span>
                                  <span className="info_locationspan">
                                    {data.openTime.hours}:
                                    {data.openTime.minutes
                                      ? data.openTime.minutes
                                      : "00"}{" "}
                                    {openMeridianStatus} -{" "}
                                    {data.closeTime.hours}:
                                    {data.closeTime.minutes
                                      ? data.closeTime.minutes
                                      : "00"}{" "}
                                    {closeMeridianStatus}
                                  </span>
                                </p>
                              </>
                            );
                          }
                        )}
                      </div>
                      <div>
                        <p
                          id="workingHoursEdit"
                          hidden
                          className="workingHoursEdit"
                        >
                          <div className="mainWeek">
                            <div className="weekdays">
                              <span
                                id="0DayValue"
                                className="info_locationspan"
                              >
                                Monday
                              </span>
                              <label>
                                <input
                                  type="checkbox"
                                  name=""
                                  id="0TimeCheck"
                                  onChange={(e) =>
                                    e.target.checked === true
                                      ? $("#0Fromtime, #0Totime").attr(
                                          "hidden",
                                          true
                                        )
                                      : $("#0Fromtime, #0Totime").attr(
                                          "hidden",
                                          false
                                        )
                                  }
                                />
                                Closed
                              </label>
                            </div>

                            <span className="info_locationspan">
                              <div class="did-floating-label-content">
                                <select
                                  id="0Fromtime"
                                  className="did-floating-select"
                                >
                                  {timeList.map((data) => (
                                    <option value={data}>{data}</option>
                                  ))}
                                </select>
                                <label class="did-floating-label">
                                  Opens At
                                </label>
                              </div>
                              <div class="did-floating-label-content">
                                <select
                                  id="0Totime"
                                  className="did-floating-select"
                                >
                                  {timeList.map((data) => (
                                    <option>{data}</option>
                                  ))}
                                </select>
                                <label class="did-floating-label">
                                  Closes At
                                </label>
                              </div>
                            </span>
                          </div>

                          <div className="mainWeek">
                            <div className="weekdays">
                              <span
                                id="1DayValue"
                                className="info_locationspan"
                              >
                                Tuesday
                              </span>
                              <label>
                                <input
                                  type="checkbox"
                                  name=""
                                  id="1TimeCheck"
                                  onChange={(e) =>
                                    e.target.checked === true
                                      ? $("#1Fromtime, #1Totime").attr(
                                          "hidden",
                                          true
                                        )
                                      : $("#1Fromtime, #1Totime").attr(
                                          "hidden",
                                          false
                                        )
                                  }
                                />
                                Closed
                              </label>
                            </div>

                            <span className="info_locationspan">
                              <div class="did-floating-label-content">
                                <select
                                  id="1Fromtime"
                                  className="did-floating-select"
                                >
                                  {timeList.map((data) => (
                                    <option value={data}>{data}</option>
                                  ))}
                                </select>
                                <label class="did-floating-label">
                                  Opens At
                                </label>
                              </div>
                              <div class="did-floating-label-content">
                                <select
                                  id="1Totime"
                                  className="did-floating-select"
                                >
                                  {timeList.map((data) => (
                                    <option>{data}</option>
                                  ))}
                                </select>
                                <label class="did-floating-label">
                                  Closes At
                                </label>
                              </div>
                            </span>
                          </div>
                          <div className="mainWeek">
                            <div className="weekdays">
                              <span
                                id="2DayValue"
                                className="info_locationspan"
                              >
                                Wednesday
                              </span>
                              <label>
                                <input
                                  type="checkbox"
                                  name=""
                                  id="2TimeCheck"
                                  onChange={(e) =>
                                    e.target.checked === true
                                      ? $("#2Fromtime, #2Totime").attr(
                                          "hidden",
                                          true
                                        )
                                      : $("#2Fromtime, #2Totime").attr(
                                          "hidden",
                                          false
                                        )
                                  }
                                />
                                Closed
                              </label>
                            </div>
                            <span className="info_locationspan">
                              <div class="did-floating-label-content">
                                <select
                                  id="2Fromtime"
                                  className="did-floating-select"
                                >
                                  {timeList.map((data) => (
                                    <option value={data}>{data}</option>
                                  ))}
                                </select>
                                <label class="did-floating-label">
                                  Opens At
                                </label>
                              </div>
                              <div class="did-floating-label-content">
                                <select
                                  id="2Totime"
                                  className="did-floating-select"
                                >
                                  {timeList.map((data) => (
                                    <option>{data}</option>
                                  ))}
                                </select>
                                <label class="did-floating-label">
                                  Closes At
                                </label>
                              </div>
                            </span>
                          </div>
                          <div className="mainWeek">
                            <div className="weekdays">
                              <span
                                id="3DayValue"
                                className="info_locationspan"
                              >
                                Thursday
                              </span>
                              <label>
                                <input
                                  type="checkbox"
                                  name=""
                                  id="3TimeCheck"
                                  onChange={(e) =>
                                    e.target.checked === true
                                      ? $("#3Fromtime, #3Totime").attr(
                                          "hidden",
                                          true
                                        )
                                      : $("#3Fromtime, #3Totime").attr(
                                          "hidden",
                                          false
                                        )
                                  }
                                />
                                Closed
                              </label>
                            </div>
                            <span className="info_locationspan">
                              <div class="did-floating-label-content">
                                <select
                                  id="3Fromtime"
                                  className="did-floating-select"
                                >
                                  {timeList.map((data) => (
                                    <option value={data}>{data}</option>
                                  ))}
                                </select>
                                <label class="did-floating-label">
                                  Opens At
                                </label>
                              </div>
                              <div class="did-floating-label-content">
                                <select
                                  id="3Totime"
                                  className="did-floating-select"
                                >
                                  {timeList.map((data) => (
                                    <option>{data}</option>
                                  ))}
                                </select>
                                <label class="did-floating-label">
                                  Closes At
                                </label>
                              </div>
                            </span>
                          </div>
                          <div className="mainWeek">
                            <div className="weekdays">
                              <span
                                id="4DayValue"
                                className="info_locationspan"
                              >
                                Friday
                              </span>
                              <label>
                                <input
                                  type="checkbox"
                                  name=""
                                  id="4TimeCheck"
                                  onChange={(e) =>
                                    e.target.checked === true
                                      ? $("#4Fromtime, #4Totime").attr(
                                          "hidden",
                                          true
                                        )
                                      : $("#4Fromtime, #4Totime").attr(
                                          "hidden",
                                          false
                                        )
                                  }
                                />
                                Closed
                              </label>
                            </div>
                            <span className="info_locationspan">
                              <div class="did-floating-label-content">
                                <select
                                  id="4Fromtime"
                                  className="did-floating-select"
                                >
                                  {timeList.map((data) => (
                                    <option value={data}>{data}</option>
                                  ))}
                                </select>
                                <label class="did-floating-label">
                                  Opens At
                                </label>
                              </div>
                              <div class="did-floating-label-content">
                                <select
                                  id="4Totime"
                                  className="did-floating-select"
                                >
                                  {timeList.map((data) => (
                                    <option>{data}</option>
                                  ))}
                                </select>
                                <label class="did-floating-label">
                                  Closes At
                                </label>
                              </div>
                            </span>
                          </div>
                          <div className="mainWeek">
                            <div className="weekdays">
                              <span
                                id="5DayValue"
                                className="info_locationspan"
                              >
                                Saturday
                              </span>
                              <label>
                                <input
                                  type="checkbox"
                                  name=""
                                  id="5TimeCheck"
                                  onChange={(e) =>
                                    e.target.checked === true
                                      ? $("#5Fromtime, #5Totime").attr(
                                          "hidden",
                                          true
                                        )
                                      : $("#5Fromtime, #5Totime").attr(
                                          "hidden",
                                          false
                                        )
                                  }
                                />
                                Closed
                              </label>
                            </div>
                            <span className="info_locationspan">
                              <div class="did-floating-label-content">
                                <select
                                  id="5Fromtime"
                                  className="did-floating-select"
                                >
                                  {timeList.map((data) => (
                                    <option value={data}>{data}</option>
                                  ))}
                                </select>
                                <label class="did-floating-label">
                                  Opens At
                                </label>
                              </div>
                              <div class="did-floating-label-content">
                                <select
                                  id="5Totime"
                                  className="did-floating-select"
                                >
                                  {timeList.map((data) => (
                                    <option>{data}</option>
                                  ))}
                                </select>
                                <label class="did-floating-label">
                                  Closes At
                                </label>
                              </div>
                            </span>
                          </div>
                          <div className="mainWeek">
                            <div className="weekdays">
                              <span
                                id="6DayValue"
                                className="info_locationspan"
                              >
                                Sunday
                              </span>
                              <label>
                                <input
                                  type="checkbox"
                                  name=""
                                  id="6TimeCheck"
                                  onChange={(e) =>
                                    e.target.checked === true
                                      ? $("#6Fromtime, #6Totime").attr(
                                          "hidden",
                                          true
                                        )
                                      : $("#6Fromtime, #6Totime").attr(
                                          "hidden",
                                          false
                                        )
                                  }
                                />
                                Closed
                              </label>
                            </div>
                            <span className="info_locationspan">
                              <div class="did-floating-label-content">
                                <select
                                  id="6Fromtime"
                                  className="did-floating-select"
                                >
                                  {timeList.map((data) => (
                                    <option value={data}>{data}</option>
                                  ))}
                                </select>
                                <label class="did-floating-label">
                                  Opens At
                                </label>
                              </div>
                              <div class="did-floating-label-content">
                                <select
                                  id="6Totime"
                                  className="did-floating-select"
                                >
                                  {timeList.map((data) => (
                                    <option>{data}</option>
                                  ))}
                                </select>
                                <label class="did-floating-label">
                                  Closes At
                                </label>
                              </div>
                            </span>
                          </div>
                        </p>
                        <button
                          id="workingHoursEditButton"
                          hidden
                          type="button"
                          className="btn btn-success"
                          onClick={() =>
                            this.UpdateGBPLocationAPI("regularHours")
                          }
                        >
                          Save
                        </button>
                      </div>
                      <div className="info_editlocation">
                        <Icon.Edit2
                          id="regularHoursEditIcon"
                          onClick={() => this.updateGBPDetails("regularHours")}
                        />
                        <Icon.XCircle
                          id="regularHoursCancelEdit"
                          hidden
                          onClick={() => {
                            $("#workingHoursShow").attr("hidden", false);
                            $("#workingHoursEdit").attr("hidden", true);
                            $("#regularHoursEditIcon").removeAttr("hidden");
                            $("#regularHoursCancelEdit").attr("hidden", true);
                            $("#workingHoursEditButton").attr("hidden", true);
                          }}
                        />
                      </div>
                    </div>
                    <div className="info_inneraddress">
                      <div className="info_iconlocation">
                        <Icon.Calendar />
                      </div>
                      <div className="info_contentlocation">
                        <p>
                          <b>Business Opening Date</b>
                        </p>
                        <p id="openingDateShow">
                          {this.state.completeGbpList.openInfo.openingDate
                            .day && (
                            <>
                              {
                                this.state.completeGbpList.openInfo.openingDate
                                  .day
                              }
                              /
                            </>
                          )}
                          {this.state.completeGbpList.openInfo.openingDate
                            .month && (
                            <>
                              {
                                this.state.completeGbpList.openInfo.openingDate
                                  .month
                              }
                              /
                            </>
                          )}
                          {this.state.completeGbpList.openInfo.openingDate
                            .year && (
                            <>
                              {
                                this.state.completeGbpList.openInfo.openingDate
                                  .year
                              }
                            </>
                          )}
                        </p>
                        <span className="businessOpenDate">
                          <input
                            id="openInfoYearEdit"
                            hidden
                            className="form-control"
                            type="text"
                            value={
                              this.state.completeGbpList.openInfo.openingDate
                                .year
                            }
                          />
                          <div className="append_inputdiv">
                            <select
                              id="openInfoMonthEdit"
                              hidden
                              className="form-control openInfoMonthEdit"
                              value={
                                this.state.completeGbpList.openInfo.openingDate
                                  .month
                              }
                              onChange={(obj) =>
                                this.setState({
                                  openInfoMonth: obj.target.value,
                                })
                              }
                            >
                              <option value="1">January</option>
                              <option value="2">February</option>
                              <option value="3">March</option>
                              <option value="4">April</option>
                              <option value="5">May</option>
                              <option value="6">June</option>
                              <option value="7">July</option>
                              <option value="8">August</option>
                              <option value="9">Septempber</option>
                              <option value="10">October</option>
                              <option value="11">November</option>
                              <option value="12">December</option>
                            </select>
                            <select
                              id="openInfoDayEdit"
                              hidden
                              className="form-control"
                              value={
                                this.state.completeGbpList.openInfo.openingDate
                                  .day
                              }
                              onChange={(e) =>
                                this.setState({ openInfoDay: e.target.value })
                              }
                            >
                              <option value=""></option>
                              {daysofThisMonth.map((data) => (
                                <option value={data}>{data}</option>
                              ))}
                            </select>
                          </div>
                        </span>
                        <div>
                          {" "}
                          <button
                            id="openInfoSaveEditButton"
                            hidden
                            type="button"
                            className="btn btn-success openInfoSaveEditButton"
                            onClick={() =>
                              this.UpdateGBPLocationAPI("openInfo")
                            }
                          >
                            Save
                          </button>
                        </div>
                      </div>
                      <div className="info_editlocation">
                        <Icon.Edit2
                          id="openInfoEditButton"
                          onClick={() => this.updateGBPDetails("openInfo")}
                        />
                        <Icon.XCircle
                          id="openInfoCancelEdit"
                          hidden
                          onClick={() => {
                            $("#openInfoEditButton").removeAttr("hidden");
                            $("#openInfoSaveEditButton").attr("hidden", true);
                            $("#openInfoCancelEdit").attr("hidden", true);
                            $("#openingDateShow").attr("hidden", false);
                            $("#openInfoMonthEdit").attr("hidden", true);
                            $("#openInfoDayEdit").attr("hidden", true);
                            $("#openInfoYearEdit").attr("hidden", true);
                          }}
                        />
                      </div>
                    </div>
                    {/* <div className="info_inneraddress">
                      <div className="info_iconlocation">
                        <Icon.Clock />
                      </div>
                      <div className="info_contentlocation">
                        <p>
                          <b>More hours</b>
                        </p>
                        <p>
                          <i>Add hours</i>
                        </p>
                      </div>
                      <div className="info_editlocation">
                        <Icon.Edit2 />
                      </div>
                    </div> */}

                    <div className="info_inneraddress">
                      <div className="info_iconlocation">
                        <Icon.Phone />
                      </div>
                      <div className="info_contentlocation">
                        <p id="phoneNumbersShowPrimary">
                          {this.state.completeGbpList.phoneNumbers.primaryPhone}{" "}
                          -{" "}
                          <span>
                            <b>PRIMARY</b>
                          </span>
                        </p>
                        <label id="phoneNumbersEditPrimary" hidden>
                          <input
                            type="text"
                            id="phoneNumbersEditPrimaryValue"
                            placeholder="Enter Primary Phone Number"
                            className="form-control"
                          />
                          - Primary
                        </label>
                        <span id="phoneNumbersShowAdditional">
                          {this.state.completeGbpList.phoneNumbers.additionalPhones.map(
                            (data, i) => {
                              return <p key={i}>{data}</p>;
                            }
                          )}
                        </span>
                        <div className="append_inputdiv">
                          <input
                            type="text"
                            className="form-control"
                            id="phoneNumbersEditAdditionalInitial"
                            hidden
                          />

                          <Icon.Plus
                            id="addMorephoneNumbersAdd"
                            className="service_infoicon"
                            onClick={() => this.addPhoneNumbers()}
                            hidden
                          />
                        </div>
                        <div className="app_clonediv_link app_clone_category_phone_numbers"></div>
                        <button
                          id="phoneNumbersEditButton"
                          hidden
                          type="button"
                          className="btn btn-success phoneNumbersEditButton"
                          onClick={() =>
                            this.UpdateGBPLocationAPI("phoneNumbers")
                          }
                        >
                          Save
                        </button>
                      </div>
                      <div className="info_editlocation">
                        <Icon.Edit2
                          id="phoneNumbersEditIcon"
                          onClick={() => this.updateGBPDetails("phoneNumbers")}
                        />
                        <Icon.XCircle
                          id="phoneNumbersCancelEdit"
                          hidden
                          onClick={() => this.hidePhoneNumbers()}
                        />
                      </div>
                    </div>
                    <div className="info_inneraddress">
                      <div className="info_iconlocation">
                        <Icon.Globe />
                      </div>
                      <div className="info_contentlocation">
                        <p id="websiteUriShow">
                          {this.state.completeGbpList.websiteUri}
                        </p>
                        <div className="editInfo">
                          <textarea
                            id="websiteUriEdit"
                            hidden
                            className="form-control"
                          ></textarea>
                          <button
                            id="websiteUriEditButton"
                            type="button"
                            hidden
                            className="btn btn-success"
                            onClick={() =>
                              this.UpdateGBPLocationAPI("websiteUri")
                            }
                          >
                            Save
                          </button>
                        </div>
                      </div>

                      <div className="info_editlocation">
                        <Icon.Edit2
                          id="websiteUriEditIcon"
                          onClick={() => this.updateGBPDetails("websiteUri")}
                        />
                        <Icon.XCircle
                          id="websiteUriCancelEdit"
                          hidden
                          onClick={() => {
                            $("#websiteUriShow").attr("hidden", false);
                            $("#websiteUriEdit").attr("hidden", true);
                            $("#websiteUriEditButton").attr("hidden", true);
                            $("#websiteUriEditIcon").removeAttr("hidden");
                            $("#websiteUriCancelEdit").attr("hidden", true);
                          }}
                        />
                      </div>
                    </div>

                    <div className="info_inneraddress">
                      <div className="info_iconlocation">
                        <Icon.Camera />
                      </div>
                      <div className="info_contentlocation">
                        <div className="right_google_popup">
                          <label>
                            <Icon.Camera />
                            Add photos
                            <input
                              type="file"
                              multiple
                              id="imageUpload"
                              onChange={(e) =>
                                this.handleImageUpload(e.target.files)
                              }
                            />
                          </label>
                        </div>
                        <div className="editInfo">
                          <select id="categoryDropdown">
                            {/* <option value="COVER">Cover</option>
                            <option value="PROFILE">Profile</option> */}
                            <option value="CATEGORY_UNSPECIFIED">
                              General
                            </option>
                            <option value="LOGO">Logo</option>
                            <option value="EXTERIOR">Exterior</option>
                            <option value="INTERIOR">Interior</option>
                            <option value="PRODUCT">Product</option>
                            <option value="AT_WORK">At Work</option>
                            <option value="FOOD_AND_DRINK">Food & Drink</option>
                            <option value="MENU">Menu</option>
                            <option value="COMMON_AREA">Common Area</option>
                            <option value="ROOMS">Rooms</option>
                            <option value="TEAMS">Teams</option>
                            <option value="ADDITIONAL">Additional</option>
                          </select>
                          <select id="formatTypeDropdown">
                            <option value="PHOTO">Photo</option>
                            <option value="VIDEO">Video</option>
                          </select>
                          <button
                            id="websiteUriEditButton"
                            type="button"
                            className="btn btn-success"
                            onClick={() => this.postPhotoToGBP()}
                          >
                            Save
                          </button>
                        </div>
                      </div>

                      {this.state.imageUrlListStatus && this.imageListing()}
                      {/* <div className="info_editlocation">
                        <Icon.Edit2 id="websiteUriEditIcon" />
                        <Icon.XCircle id="websiteUriCancelEdit" hidden />
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="info_advancedinformation">
                    <div className="user_listviewhead">
                      <span>Advanced information</span>
                    </div>
                    <div className="info_advanced_code">
                      <div className="left_infocode">
                        <p>Store code</p>
                      </div>
                      <div className="middle_infocode">
                        <p>
                          <i>Enter store code</i>
                        </p>
                      </div>
                      <div className="right_infocode">
                        {" "}
                        <Icon.Edit2 />
                      </div>
                    </div>
                    <div className="info_advanced_code">
                      <div className="left_infocode">
                        <p>Label</p>
                      </div>
                      <div className="middle_infocode">
                        <p>
                          <b>Xilligence India</b>
                        </p>
                      </div>
                      <div className="right_infocode">
                        {" "}
                        <Icon.Edit2 />
                      </div>
                    </div>
                    <div className="info_advanced_code">
                      <div className="left_infocode">
                        <p>Google Ads location extensions pnone</p>
                      </div>
                      <div className="middle_infocode">
                        <p>09447729993</p>
                      </div>
                      <div className="right_infocode">
                        {" "}
                        <Icon.Edit2 />
                      </div>
                    </div>
                  </div> */}
                  <div className="insight_div">
                    <div className="user_listviewhead">
                      <span>Insight</span>
                    </div>
                    <div className="insight_view">
                      <div className="row insight_div">
                        <div className="col-md-12">
                          <div className="insight_head">
                            How customer search for your business
                          </div>
                          <div>
                            <ReactDatePicker
                              selected={new Date(this.state.insightsStartDate)}
                              showMonthDropdown
                              showYearDropdown
                              dateFormatCalendar="MMMM"
                              onChange={(obj) => {
                                let startDate = new Date(obj);
                                this.setState({
                                  insightsStartDate: startDate.toISOString(),
                                  insightsDataKey: startDate.toISOString(),
                                });
                              }}
                            />{" "}
                            to{" "}
                            <ReactDatePicker
                              selected={new Date(this.state.insightsEndDate)}
                              showMonthDropdown
                              showYearDropdown
                              dateFormatCalendar="MMMM"
                              onChange={(obj) => {
                                let endDate = new Date(obj);
                                this.setState({
                                  insightsEndDate: endDate.toISOString(),
                                  insightsDataKey: endDate.toISOString(),
                                });
                              }}
                            />
                          </div>

                          {/* <select className="insight_select">
                            <option>1 month</option>
                          </select> */}
                        </div>
                        <div className="col-md-6">
                          <AllSearches
                            key={this.state.insightsDataKey}
                            startDate={this.state.insightsStartDate}
                            endDate={this.state.insightsEndDate}
                          />
                        </div>
                        <div className="col-md-6">
                          <div className="insight_optiondiv">
                            <div className="left_insightopt_div">
                              <Icon.MapPin />
                            </div>
                            <div className="right_insightopt_div">
                              <h6>Direct Search(es)</h6>
                              <p>
                                The number of times your business was shown when
                                searching for it directly
                              </p>
                            </div>
                          </div>
                          <div className="insight_optiondiv">
                            <div className="left_insightopt_div">
                              <Icon.MapPin />
                            </div>
                            <div className="right_insightopt_div">
                              <h6>Search</h6>
                              <p>
                                The number of times your business was viewed on
                                Google Search.
                              </p>
                            </div>
                          </div>
                          <div className="insight_optiondiv">
                            <div className="left_insightopt_div">
                              <Icon.MapPin />
                            </div>
                            <div className="right_insightopt_div">
                              <h6>Maps</h6>
                              <p>
                                The number of times the resource was viewed on
                                Google Maps.
                              </p>
                            </div>
                          </div>
                          <div className="insight_optiondiv">
                            <div className="left_insightopt_div">
                              <Icon.MapPin />
                            </div>
                            <div className="right_insightopt_div">
                              <h6>Indirect Searches</h6>
                              <p>
                                The number of times the resource was shown as a
                                result of a categorical search (for example,
                                restaurant).
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row insight_div">
                        <div className="col-md-12">
                          <div className="insight_head">
                            How customer view your bussiness on Google
                          </div>
                          <div className="insight_text">
                            The Google services that customers use to find your
                            bussiness
                          </div>
                          <select className="insight_select">
                            <option>1 month</option>
                          </select>
                          <div className="insight_totalview">
                            Total views 2.57k
                          </div>
                        </div>
                        <div className="col-md-12">
                          <TotalView />
                        </div>
                      </div>
                      <div className="row insight_div">
                        <div className="col-md-12">
                          <div className="insight_head">Customer actions</div>
                          <div className="insight_text">
                            The most common action that customer take on your
                            listing
                          </div>
                          <select className="insight_select">
                            <option>1 month</option>
                          </select>
                          <div className="insight_totalview">
                            Total actions 81
                          </div>
                        </div>
                        <div className="col-md-12">
                          <TotalView />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="loder_div" hidden />
        <Footer />
      </div>
    );
  }
}

export default Info;
