
import axios from "axios";

//const getDetailsPageWiseURL = 'https://vxrf65nsk3.execute-api.ap-south-1.amazonaws.com/unicorn_test/pagewisecms'
const getDetailsPageWiseURL=window.sessionStorage.getItem("localpage_url_link");
const getGoogleReviews = 'https://jkzdxlws1i.execute-api.ap-south-1.amazonaws.com/prod'

export function apiCallingPost(postData) {
  return axios.post(getDetailsPageWiseURL, postData)
    .then((data) => data)
    .then((res) => res)
}

export function getGoogleReviewDetails(postData) {
  return axios.post(getGoogleReviews, postData)
    .then((data) => data)
    .then((res) => res)
}
