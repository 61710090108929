import React, { Component } from "react";
import LogoLg from "../images/logo.png";
import Logosm from "../images/logo_sm.png";
import Dashboard from "../images/left_icons/dashboard.png";
import LocateIt from "../images/left_icons/locateIT.png";
import Processing from "../images/left_icons/processing.png";
import Address from "../images/left_icons/address.png";
import Audit from "../images/left_icons/audit.png";
import GBP from "../images/left_icons/GBP.png";
import Business from "../images/left_icons/business.png";
import { Link } from "react-router-dom";
import Fraud from "../images/left_icons/fraud.png";
import Review from "../images/left_icons/review.png";
import QA from "../images/left_icons/qa.png";
import ProductCatalog from "../images/left_icons/Product-Catalog.png";
import GooglePost from "../images/left_icons/Google-Post.png";
import GoogleChat from "../images/left_icons/Google-Chat.png";
import Gbp from "../images/left_icons/Google-business-profile.png";
import LocalPages from "../images/left_icons/Local-Page.png";
import ChatBoat from "../images/left_icons/chatbot.png";
import Accessibilty from "../images/left_icons/Accessibility-and-visibility.png";
import Analysis from "../images/left_icons/analysis.png";
import UploadGbp from "../images/left_icons/upload-file.png";
import GoogleAnalytics from "../images/left_icons/google_analytics.png";
import SocialMedia from "../images/left_icons/social-media.png";
import PushGBP from "../images/left_icons/Push-GBP-Data.png";
import FbAd from "../images/left_icons/Facebook-ads.png";
import GoogleAds from "../images/left_icons/google-ads.png";
import * as Icon from "react-feather";

import {
  BsCurrencyPound,
  BsBook,
  BsClockHistory,
  BsHouseDoor,
  BsShieldExclamation,
  BsFilePlus,
  BsStar,
  BsChat,
  BsHash,
  BsBookmarkCheck,
  BsBarChartSteps,
  BsSpeaker,
  BsCheckSquare,
} from "react-icons/bs";
import $ from "jquery";
import { backendApi } from "../apiService";

class LeftMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modulesList: window.sessionStorage.getItem("switchUserModules")
        ? JSON.parse(window.sessionStorage.getItem("switchUserModules"))
        : JSON.parse(window.sessionStorage.getItem("modules")),
      productCatalogStatus: false,
      auditStatus: false,
      reviewStatus: false,
      googlePostStatus: false,
      googleAnalyticsStatus: false,
      socialMeStatus: false,
      localPagesStatus: false,
      competitorAnalysisStatus: false,
      googleChatStatus: false,
      gbpStatus: false,
      fraudStatus: false,
      chatbotStatus: false,
      addressProcessingStatus: false,
      visibilityaccuracyStatus: false,
      facebookAdsStatus: false,
      googleAdsStatus: false,
      pushGBPDataStatus: false,
      qAndAStatus: false,
      locateItStatus: false,
      businessClaimStatus: false,
      imageEditorStatus: false,
      instagramAdsStatus: false,
      accountId: window.sessionStorage.getItem("account_id"),
      customDashboard: false,
      creatifyModule: false,
      fraudulentDetect: false,
      contentApprover: "Disable",
    };
  }

  componentDidMount() {
    // console.log(this.state.modulesList, "modulesListmodulesList");
    this.checkContentApprover();
    $(document).ready(function () {
      var pathname = window.location.pathname;
      // $('.nav_sidemenu').find('.active').removeClass('active');
      $(".subMenubar").hide();
      // $(".nav_sidemenu li a").click(function () {
      //   $(this).siblings(".subMenubar").slideToggle();
      //   $(this).toggleClass("is-active");
      //   $(".nav_sidemenu li a").not(this).siblings(".subMenubar").slideUp();
      //   $("nav_sidemenu li a").not(this).removeClass("is-active");
      // });
      $(".nav_sidemenu li .submenu__isopen")
        .unbind("click")
        .click(function (e) {
          // $(this).next('.subMenubar').toggleClass('is-active');
          // $('.nav_sidemenu li a').not($(this)).next('.subMenubar').removeClass('is-active');
          $(this).siblings(".subMenubar").slideToggle();
          // $(".nav_sidemenu li .submenu__isopen")
          //   .not(this)
          //   .siblings(".subMenubar")
          //   .slideUp();
          // $(this).removeClass("active");
          //e.preventDefault();
        });
      $(".nav_sidemenu li a").each(function () {
        if ($(this).attr("href") == pathname) {
          $(this).addClass("active");
          $(this).addClass("active")
            .parents(".subMenubar")
            .css("display", "block");
          $(".nav_sidemenu li a.active")[0].scrollIntoView();
        }
      });
    });
    if (
      window.sessionStorage.getItem("role") === "ROLE_SUPER_ADMIN" &&
      window.sessionStorage.getItem("switchUserRole") === ""
    ) {
      this.setState({
        reviewStatus: true,
        auditStatus: true,
        googlePostStatus: true,
        googleAnalyticsStatus: true,
        socialMeStatus: true,
        localPagesStatus: true,
        competitorAnalysisStatus: true,
        googleChatStatus: true,
        gbpStatus: true,
        fraudStatus: true,
        chatbotStatus: true,
        addressProcessingStatus: true,
        productCatalogStatus: true,
        visibilityaccuracyStatus: true,
        facebookAdsStatus: true,
        googleAdsStatus: true,
        pushGBPDataStatus: true,
        qAndAStatus: true,
        locateItStatus: true,
        businessClaimStatus: true,
        imageEditorStatus: true,
        instagramAdsStatus: true,
        customDashboard: true,
        creatifyModule: true,
        fraudulentDetect: true,
      });
    } else {
      // alert("hi")

      this.setState({
        reviewStatus: this.state.modulesList.some((e) => e.moduleId === 8),
        auditStatus: this.state.modulesList.some((e) => e.moduleId === 7),
        googlePostStatus: this.state.modulesList.some((e) => e.moduleId === 10),
        googleAnalyticsStatus: this.state.modulesList.some(
          (e) => e.moduleId === 9
        ),
        socialMeStatus: this.state.modulesList.some((e) => e.moduleId === 14),
        localPagesStatus: this.state.modulesList.some((e) => e.moduleId === 6),
        competitorAnalysisStatus: this.state.modulesList.some(
          (e) => e.moduleId === 11
        ),
        googleChatStatus: this.state.modulesList.some((e) => e.moduleId === 13),
        gbpStatus: this.state.modulesList.some((e) => e.moduleId === 15),
        fraudStatus: this.state.modulesList.some((e) => e.moduleId === 12),
        chatbotStatus: this.state.modulesList.some((e) => e.moduleId === 17),
        addressProcessingStatus: this.state.modulesList.some(
          (e) => e.moduleId === 16
        ),
        visibilityaccuracyStatus: this.state.modulesList.some(
          (e) => e.moduleId === 19
        ),
        productCatalogStatus: this.state.modulesList.some(
          (e) => e.moduleId === 4
        ),
        facebookAdsStatus: this.state.modulesList.some(
          (e) => e.moduleId === 21
        ),
        googleAdsStatus: this.state.modulesList.some((e) => e.moduleId === 22),
        pushGBPDataStatus: this.state.modulesList.some(
          (e) => e.moduleId === 23
        ),
        qAndAStatus: this.state.modulesList.some((e) => e.moduleId === 24),
        locateItStatus: this.state.modulesList.some((e) => e.moduleId === 25),
        businessClaimStatus: this.state.modulesList.some(
          (e) => e.moduleId === 26
        ),
        imageEditorStatus: this.state.modulesList.some(
          (e) => e.moduleId === 27
        ),
        instagramAdsStatus: this.state.modulesList.some(
          (e) => e.moduleId === 28
        ),
        customDashboard: window.sessionStorage.getItem("roleId") ==="10" || window.sessionStorage.getItem("roleId") ==="14"|| window.sessionStorage.getItem("roleId") ==="13" || this.state.modulesList.some((e) => e.moduleId === 37 ),
        creatifyModule: this.state.modulesList.some((e) => e.moduleId === 38),
        fraudulentDetect: this.state.modulesList.some((e) => e.moduleId === 39),
      });
    }
  }
  checkContentApprover() {
    const api = {
      functionName: "checkContentApprover",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      sec_user_id:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };
    backendApi(api).then((data) => {
      if (data.success === "1") {
        this.setState({ contentApprover: data.enable });
      }
      console.log("🚀 ~ LeftMenu ~ backendApi ~ data:", data);
    });
  }
  render() {
    return (
      <div className="user_listmenu">
        <div className="logo_user">
          <div className="userlistlogo userlistlogo_lg">
            <span className="tag_logo">
              <img src={LogoLg} />
            </span>
            <span className="tag_version">Beta Version 1.0</span>
          </div>
          <div className="userlistlogo userlistlogo_sm">
            <img src={Logosm} />
          </div>
        </div>
        <ul className="nav_sidemenu">
          {/* <li>
						<a href="/dashboard2" placeholder="Product Tree">
							<img src={Dashboard} alt="Dashboard" />
							<span>Dashboard2</span>
						</a>
					</li> */}
          <li>
            <a href="/dashboard" placeholder="Product Tree">
              <img src={Dashboard} alt="Dashboard" />
              <span>Dashboard</span>
            </a>
          </li>
          {this.state.locateItStatus === true ? (
            <li
              onClick={() => {
                window.sessionStorage.setItem("offsetlocateIt", "0");
                window.sessionStorage.setItem("activePageNumberlocateIt", 1);
              }}
            >
              <a href="/locateIt" placeholder="Product Tree">
                <img src={LocateIt} alt="LocateIt" />
                <span>LocateIT</span>
              </a>
            </li>
          ) : (
            ""
          )}
          {/* <li>
              <a href="#" className="submenu__isopen">
                <img src={Audit} alt="Backend" />
                <span>
                  CMS
                </span>
                <div className="selectSubmenu">
                  <Icon.ChevronDown />
                </div>
              </a>
              <ul className="subMenubar">
                <li>
                  <a href="/cmsBackend">Backend</a>
                </li>
                <li>
                  <a href="/cmsfrontend">Frontend</a>
                </li>
              </ul>
            </li> */}
          {/* <li>
            <a href="/#" placeholder="Product Tree">
              <img src={UploadGbp} alt="Upload Gbp" />
              <span>Upload Local Businesses to GBP</span>
            </a>
          </li> */}

          {this.state.auditStatus === true ? (
            <li>
              <a href="#" className="submenu__isopen">
                <img src={Audit} alt="Audit" />
                <span
                  onClick={() => {
                    window.sessionStorage.setItem(
                      "offsetDrDetailsMasterData",
                      ""
                    );
                    window.sessionStorage.setItem(
                      "pageNumberDrDetailsMasterData",
                      1
                    );
                  }}
                >
                  Audit
                </span>
                <div className="selectSubmenu">
                  <Icon.ChevronDown />
                </div>
              </a>
              <ul className="subMenubar">
                <li>
                  <a
                    href="/mergeIndex"
                    onClick={() => {
                      window.sessionStorage.setItem(
                        "offsetDrDetailsMasterData",
                        ""
                      );
                      window.sessionStorage.setItem(
                        "pageNumberDrDetailsMasterData",
                        1
                      );
                      window.sessionStorage.setItem("pageIndexMergeAudit", "0");
                    }}
                  >
                    Match Search
                  </a>
                </li>
                <li
                  onClick={() => {
                    window.sessionStorage.setItem("offsetauditmain", "0");
                    window.sessionStorage.setItem(
                      "activePageNumberauditmain",
                      1
                    );
                  }}
                >
                  <a href="/audit">Run Audit</a>
                </li>
              </ul>
            </li>
          ) : (
            ""
          )}
          {this.state.fraudStatus === true ? (
            <li
              onClick={() => {
                window.sessionStorage.setItem("offsetfraudmain", "0");
                window.sessionStorage.setItem("activePageNumberfraudmain", 1);
              }}
            >
              <a href="/fraud">
                <img src={Fraud} alt="Fraud" />
                <span>Fraud</span>
              </a>
            </li>
          ) : (
            ""
          )}

          {this.state.businessClaimStatus === true && (
            <li>
              <a href="/claimBusiness">
                <img src={Business} alt="GoogleAds" />
                <span>Claim a Business</span>
              </a>
            </li>
          )}
          {this.state.addressProcessingStatus === true ? (
            <li>
              <a href="/processAddress">
                <img src={Processing} alt="Address" />
                <span>Address Processing</span>
              </a>
            </li>
          ) : (
            ""
          )}
          {/* {this.state.businessClaimStatus === true && (
            <li>
              <a href="#" className="submenu__isopen">
                <img src={GoogleAds} alt="GoogleAds" />
                <span>Claim a Business</span>
                <div className="selectSubmenu">
                  <Icon.ChevronDown />
                </div>
              </a>
              <ul className="subMenubar">
                <li>
                  <a href="/claim">Claim</a>
                </li>
                <li>
                  <a href="/mark">Mark Permanently closed</a>
                </li>
                <li>
                  <a href="/merge">Merge Duplicate listing</a>
                </li>
              </ul>
            </li>
          )} */}
          {this.state.pushGBPDataStatus === true ? (
            <li>
              <a href="/pushDataHistory">
                <img src={PushGBP} alt="PushGBP" />
                <span>Push GBP Data</span>
              </a>
            </li>
          ) : (
            ""
          )}
          {this.state.reviewStatus === true ? (
            <li>
              <a href="#" className="submenu__isopen">
                <img src={Review} alt="Review" />
                <span>Review</span>
                <div className="selectSubmenu">
                  <Icon.ChevronDown />
                </div>
              </a>
              <ul className="subMenubar">
                <li>
                  <a href="/review/index">Google Review</a>
                </li>
                <li>
                  <a href="/review/approve">Approve Review</a>
                </li>
                <li>
                  <a href="/review/analytics">Review Analytics</a>
                </li>
              </ul>
            </li>
          ) : (
            ""
          )}
          {this.state.qAndAStatus === true ? (
            <li>
              <a href="/questionAnswer">
                <img src={QA} alt="Audit" />
                <span>Q & A</span>
                {/* <div className="selectSubmenu">
									<Icon.ChevronDown />
								</div> */}
              </a>
              {/* <ul className="subMenubar">
								<li>
									<a href="/questionAnswer">Question Answer</a>
								</li> 
								 <li>
                  <a href="/bulkpublish">Bulk publish</a>
                </li> 
                </ul> */}
            </li>
          ) : (
            ""
          )}
          {this.state.googlePostStatus === true ? (
            // <li>
            //   <a href="/googlepost/index">
            //     <img src={GooglePost} alt="GooglePost" />
            //     <span>Google Post</span>
            //   </a>
            // </li>

            <li>
              <a href="#" className="submenu__isopen">
                <img src={GooglePost} alt="GooglePost" />
                <span
                // onClick={() => {
                //   window.sessionStorage.setItem("GooglePost", "");
                //   window.sessionStorage.setItem("GooglePostData", 1);
                // }}
                >
                  Google Post
                </span>
                <div className="selectSubmenu">
                  <Icon.ChevronDown />
                </div>
              </a>
              <ul className="subMenubar">
                <li>
                  <a
                    href="/googlepost/postListing"
                    onClick={() => {
                      window.sessionStorage.setItem("GooglePost", "");
                      window.sessionStorage.setItem("GooglePostData", 1);
                    }}
                  >
                    {" "}
                    Add Post{" "}
                  </a>
                </li>
                <li>
                  <a href="/googlepost/postHistory"> Post Report</a>
                </li>
              </ul>
            </li>
          ) : (
            ""
          )}
          {this.state.gbpStatus === true ? (
            // <li>
            //   <a href="/info">
            //     <img src={Gbp} alt="Gbp" />
            //     <span>GBP</span>
            //   </a>
            // </li>
            <li>
              <a href="#" className="submenu__isopen">
                <img src={GBP} alt="Audit" />
                <span>GBP</span>
                <div className="selectSubmenu">
                  <Icon.ChevronDown />
                </div>
              </a>
              <ul className="subMenubar">
                {this.state.accountId ? (
                  <li>
                    <a href={`/googleBusinessLocation/${this.state.accountId}`}>
                      Business details
                    </a>
                  </li>
                ) : (
                  <></>
                )}
                {/* <li>
                  <a href="/insightsEdit">Insights By Location</a>
                </li> */}
                <li>
                  <a href="/trafficInsights">Insights By Account</a>
                </li>
                <li>
                  <a href="/insightsScheduler">Insights Scheduler</a>
                </li>
                {/* <li>
                  <a href="/info">Info</a>
                </li> */}
                <li>
                  <a href="/GBPPhotos">Photos</a>
                </li>
                <li>
                  <a href="/suggestededit">Suggested Edits</a>
                </li>
                <li>
                  <a href="/multipleLocations">Bulk Location Update</a>
                </li>
              </ul>
            </li>
          ) : (
            ""
          )}
          {this.state.visibilityaccuracyStatus === true ? (
            <li>
              <a href="#" className="submenu__isopen">
                <img src={Accessibilty} alt="Accessibilty" />
                <span>Visibility & Accuracy</span>
                <div className="selectSubmenu">
                  <Icon.ChevronDown />
                </div>
              </a>
              <ul className="subMenubar">
                <li>
                  <a href="/VisibilityAccuracyTable">
                    Visibility & Accuracy Dashboard
                  </a>
                </li>
                <li>
                  <a href="/visibility">Analysis</a>
                </li>
                {/* <li>
                   <a href="/info">Info</a>
                 </li> */}
                <li>
                  <a href="/visibilityReport">Detailed Report</a>
                </li>
              </ul>
            </li>
          ) : (
            // <li>
            //   <a href="/visibility">
            //     <img src={Accessibilty} alt="Accessibilty" />
            //     <span>Visibility & Accuracy</span>
            //   </a>
            // </li>
            ""
          )}
          {this.state.competitorAnalysisStatus === true ? (
            <li>
              <a href="#" className="submenu__isopen">
                <img src={Analysis} alt="Competitor Analysis" />
                <span>Competitor Analysis</span>
                <div className="selectSubmenu">
                  <Icon.ChevronDown />
                </div>
              </a>
              <ul className="subMenubar">
                {/* <li>
                <a href="/competiterAnalysis">
                  Competitor analysis
                </a>
              </li> */}
                <li>
                  <a href="/competiterAnalysis/result">Analysis</a>
                </li>
                <li>
                  <a href="/competiterAnalysis/audit">Upload Data</a>
                </li>
                <li>
                  <a href="/competiterAnalysis/competitorListing">
                    Competitors
                  </a>
                </li>
                {/* <li>
                  <a href="/competiterAnalysis/details">Details</a>
                </li> */}
              </ul>
            </li>
          ) : (
            // <li>
            //   <a href="/competiterAnalysis">
            //     <img src={Analysis} alt="Competitor Analysis" />
            //     <span>Competitor analysis </span>
            //   </a>
            // </li>
            ""
          )}
          {this.state.productCatalogStatus === true ? (
            <li>
              <a href="/home">
                <img src={ProductCatalog} alt="Product-Catalog" />
                <span>Product Catalog</span>
              </a>
            </li>
          ) : (
            ""
          )}

          {/* {this.state.googleAnalyticsStatus === true ? (
						<li>
							<a href="/analytics">
								<img src={GoogleAnalytics} alt="Google Analytics" />
								<span>Local Page Analytics</span>
							</a>
						</li>
					) : (
						""
					)} */}
          {this.state.googleChatStatus === true ? (
            <li>
              <a href="#" className="submenu__isopen">
                <img src={GoogleChat} alt="Google-Chat" />
                <span>Google Chat</span>
                <div className="selectSubmenu">
                  <Icon.ChevronDown />
                </div>
              </a>
              <ul className="subMenubar">
                <li>
                  <a href="/new-chat">Google Chat</a>
                </li>
                <li>
                  <a href="/chatForm">Create a Form</a>
                </li>
                <li>
                  <a href="/assignLocation">Assign to Locations</a>
                </li>
                <li>
                  <a href="/chat">Chat</a>
                </li>
              </ul>
            </li>
          ) : (
            ""
          )}

          {this.state.socialMeStatus === true ? (
            <li>
              <a href="/social-me">
                <img src={SocialMedia} alt="Social Media" />
                <span>Social Me </span>
              </a>
            </li>
          ) : (
            ""
          )}

          {this.state.localPagesStatus === true &&
          window.sessionStorage.getItem("local_page_isActive") == 1 ? (
            <li>
              {/* <a href="/local/index">
                <img src={LocalPages} alt="LocalPages" />
                <span>Local Pages </span>
              </a> */}
              <a href="#" className="submenu__isopen">
                <img src={LocalPages} alt="LocalPages" />
                <span>Local Pages </span>
                <div className="selectSubmenu">
                  <Icon.ChevronDown />
                </div>
              </a>
              <ul className="subMenubar">
                <li>
                  <a href="/landing_page">Local Pages</a>
                </li>
                {/* <li>
                  <a href="/uploadfile">Migration</a>
                </li> */}
                <li>
                  <a href="/local/Localpages">Local Page CMS</a>
                </li>
                <li>
                  <a href="/analytics">Local Page Analytics</a>
                </li>

                <li>
                  <a href="/exportExcel">Export Excel</a>
                </li>
                <li>
                  <a href="/generateUrl">Generate Url</a>
                </li>
                <li>
                  <a href="/liveUpdate">Live Update</a>
                </li>
              </ul>
            </li>
          ) : (
            ""
          )}

          {this.state.chatbotStatus === true ? (
            <li>
              <a href="#">
                <img src={ChatBoat} alt="ChatBoat" />
                <span>AI Chat bot</span>
              </a>
            </li>
          ) : (
            ""
          )}
          {this.state.instagramAdsStatus === true ? (
            <li>
              <a href="#" className="submenu__isopen">
                <img src={FbAd} alt="InstagramAds" />
                <span>Instagram Ads</span>
                <div className="selectSubmenu">
                  <Icon.ChevronDown />
                </div>
              </a>
              <ul className="subMenubar">
                <li href="#">
                  <a>Publish Ads</a>
                </li>
                <li>
                  {/* <a href="/Ads/instagramAdsReport">Reports</a> */}
                  <Link
                    to="/Ads/instagramAdsReport"
                    state={{ sectionName: "Instagram Ads" }}
                  >
                    Reports
                  </Link>
                </li>
              </ul>
            </li>
          ) : (
            ""
          )}
          {this.state.facebookAdsStatus === true ? (
            <li>
              <a href="#" className="submenu__isopen">
                <img src={FbAd} alt="FacebookAds" />
                <span>Facebook Ads</span>
                <div className="selectSubmenu">
                  <Icon.ChevronDown />
                </div>
              </a>
              <ul className="subMenubar">
                <li href="#">
                  <a>Publish Ads</a>
                </li>
                <li>
                  <Link
                    to="/Ads/facebookAdsReport"
                    state={{ sectionName: "Facebook Ads" }}
                  >
                    Reports
                  </Link>
                </li>
              </ul>
            </li>
          ) : (
            ""
          )}

          {this.state.googleAdsStatus === true ? (
            <li>
              <a href="#" className="submenu__isopen">
                <img src={GoogleAds} alt="GoogleAds" />
                <span>Google Ads</span>
                <div className="selectSubmenu">
                  <Icon.ChevronDown />
                </div>
              </a>
              <ul className="subMenubar">
                <li>
                  <a href="#">Publish Ads</a>
                </li>
                <li>
                  <a href="/googleAdsReport">Reports</a>
                </li>
              </ul>
            </li>
          ) : (
            ""
          )}
          {this.state.imageEditorStatus === true && (
            <li>
              <a href="#" className="submenu__isopen">
                <img src={SocialMedia} alt="Image Editor" />
                <span>Ad Generator</span>
                <div className="selectSubmenu">
                  <Icon.ChevronDown />
                </div>
              </a>
              <ul className="subMenubar">
                <li>
                  <a href="/imageEditer/imageEditerList">
                    <span>Ad Generator</span>
                  </a>
                </li>
                <li>
                  <a href="/image-editor-settings">
                    <span>Ad Generator Text/Images</span>
                  </a>
                </li>
              </ul>
            </li>
          )}

          {this.state.customDashboard === true && (
            <li>
              <a href="#" className="submenu__isopen">
                <img src={Dashboard} alt="Image Editor" />
                <span>Analytic Dashboard</span>
                <div className="selectSubmenu">
                  <Icon.ChevronDown />
                </div>
              </a>
              <ul className="subMenubar">
                <li>
                  <a href="/organisation-division">
                    <span>Location Mapping</span>
                  </a>
                </li>
                <li>
                  <a href="/user-creation">
                    <span>User Mapping</span>
                  </a>
                </li>
                <li>
                  <a href="/budget-allocation">
                    <span>Budget Allocation</span>
                  </a>
                </li>
                <li>
                  <a href="/analytic-dashboard-old">
                    <span>Dashboard</span>
                  </a>
                </li>
                <li>
                  <a href="/createInvoice">
                    <span>Generate Invoice</span>
                  </a>
                </li>
              </ul>
            </li>
          )}
          {this.state.fraudulentDetect === true && (
            <li>
              <a href="/fraud-dashboard">
                <img src={Fraud} alt="Fraud" />
                <span>Fraudulent Detection</span>
              </a>
            </li>
          )}
          {/* <li>
            <a href="/adv-generator">
            <img src={SocialMedia} alt="creatify" />
              <span>Creatify</span>
            </a>
          </li> */}

          {this.state.creatifyModule === true ? (
            <li>
              <a href="#" className="submenu__isopen">
                <img src={SocialMedia} alt="Image Editor" />
                <span>Creatify</span>
                <div className="selectSubmenu">
                  <Icon.ChevronDown />
                </div>
              </a>
              <ul className="subMenubar Creatify">
                <li>
                  <a href="/campaign">
                    <span>Create Campaign</span>
                  </a>
                </li>
                <li>
                  <a href="/requirement" className="req-gather">
                    <span>Requirement Gathering</span>
                  </a>
                </li>
                <li>
                  <a href="/planning">
                    <span>Planning</span>
                  </a>
                </li>
                <li>
                  <a href="/adv-generator">
                    <span>Ad Content</span>
                  </a>
                </li>

                <li>
                  <a href="/add-images">
                    <span>Ad Images</span>
                  </a>
                </li>
                <li>
                  <a href="/image-content-merge">
                    <span>Ad Content Merge</span>
                  </a>
                </li>
                {(window.sessionStorage.getItem("role") ===
                  "ROLE_SUPER_ADMIN" ||
                  window.sessionStorage.getItem("role") === "ROLE_ADMIN" ||
                  window.sessionStorage.getItem("role") === "ORG_ADMIN") &&
                  window.sessionStorage.getItem("switchUserRole") === "" && (
                    <li>
                      <a href="/add-user">
                        <span>Create Approver</span>
                      </a>
                    </li>
                  )}
                {/* <li>
                  <a href="/approved-content">
                    <span>Approved Content</span>
                  </a>
                </li> */}
              </ul>
            </li>
          ) : (
            ""
          )}

          {/* {this.state.creatifyModule === true ? ( */}
          <>
            <li hidden={this.state.contentApprover === "Enable" ? false : true}>
              <a href="#" className="submenu__isopen">
                <img src={SocialMedia} alt="Image Editor" />
                <span>Creatify Approval</span>
                <div className="selectSubmenu">
                  <Icon.ChevronDown />
                </div>
              </a>
              <ul className="subMenubar">
                <li>
                  <a href="/approval">
                    <span>Approve/Reject for Publish</span>
                  </a>
                </li>
              </ul>
            </li>
          </>
          {/* ) : (
            ""
          )} */}

          {/* <ul className="subMenubar">
                <li>
                  <a href="/add-user">
                    <span>Creatify Approver</span>
                  </a>
                </li>
                {window.sessionStorage.getItem("role") === "ROLE_SUPER_ADMIN" &&
                  window.sessionStorage.getItem("switchUserRole") === "" && (
                    <li>
                      <a href="/approval">
                        <span>Approved for Publish</span>
                      </a>
                    </li>
                  )}
              </ul> */}
          {/* <li>
            <a href="/add-user">
              <img src={Dashboard} alt="support" />
              <span>Content Approver</span>
            </a>
          </li> */}
          <li>
            <a href="/support">
              <img src={Dashboard} alt="support" />
              <span>Support</span>
            </a>
          </li>

          {/* {this.state.googleAdsStatus === true ? (
          <li>
            <a href="/planning">
              <img src={Dashboard} alt="planning" />
              <span>Planning</span>
            </a>
          </li>
          )} */}

          {/* {this.state.googleAdsStatus === true ? (
            <li>
              <a href="#" className="submenu__isopen">
                <img src={SocialMedia} alt="Planning" />
                <span>Planning</span>
                <div className="selectSubmenu">
                  <Icon.ChevronDown />
                </div>
              </a>
              <ul className="subMenubar">
                <li>
                  <a href="/planning">
                    <span>Generate</span>
                  </a>
                </li>
                <li>
                  <a href="/add-images">
                    <span>Approval</span>
                  </a>
                </li>
              </ul>
            </li>
          ) : (
            ""
          )} */}

          {/* <li>
            <a href="/campaign">
              <img src={Dashboard} alt="planning" />
              <span>Requirement Gathering</span>
            </a>
          </li> */}
        </ul>
      </div>
    );
  }
}

export default LeftMenu;
