import React, { Component } from "react";
import * as Icon from "react-feather";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import AllSearches from "../GBP/all_searches";
import TotalView from "../GBP/total_view";
import Gbpimage from "../images/left_icons/GBP.png";
import { backendApi, apiCallingPost } from "../apiService";
import ReactDatePicker from "react-datepicker";
import GoogleMap from "./businessMap";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import moment from "moment";
import $, { data } from "jquery";
import { Link } from "react-router-dom";
import AWS from "aws-sdk";

const s3ImageUrl =
  "http://tagtree.s3-website.ap-south-1.amazonaws.com/production/images";
const s3Region = "us-east-1";
const s3bucketAccessKey = "AKIAIO4K2KIE4OR7QACQ";
const s3bucketSecretKey = "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";
const s3Bucket = "tagtree";
const s3BucketMainFolder = "production";
const S3BucketImagesFolder = "images";
let extractedData = [];
let finalarray = [];
let gbpdata = window.sessionStorage?.getItem("businessClipboardData");
window.sessionStorage.removeItem("businessClipboardData");
console.log("gbp", gbpdata);
if (gbpdata) {
  const gbpobject = JSON.parse(gbpdata);
  extractedData = gbpobject.map((item) => {
    const {
      created_on,
      excelName,
      googleName,
      excelAddress,
      googleAddress,
      excelCategory,
      googleCategory,
      excelPhoneNo,
      googlePhoneNO,
      GBP_status,
    } = item;

    return {
      created_on,
      excelName,
      googleName,
      excelAddress,
      googleAddress,
      excelCategory,
      googleCategory,
      excelPhoneNo,
      googlePhoneNO,
      GBP_status,
    };
  });

  console.log("xdata", extractedData);
}

var addressLinesConstant = 0;
var phoneNumbersConstant = 0;
var categoriesConstant = 0;
const daysofThisMonth = Array.from(
  Array(moment().daysInMonth()),
  (_, i) => i + 1
);
var timeList = [
  "0:00",
  "0:30",
  "1:00",
  "1:30",
  "2:00",
  "2:30",
  "3:00",
  "3:30",
  "4:00",
  "4:30",
  "5:00",
  "5:30",
  "6:00",
  "6:30",
  "7:00",
  "7:30",
  "8:00",
  "8:30",
  "9:00",
  "9:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];
class Info extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completeGbpList: "",
      openingDate: "",
      openInfoMonth: "",
      openInfoDay: "",
      imageUrlListStatus: 0,
      todayDate: new Date(),
      imageUrl: [],
      initialState: true,
      imageName: "",
      accountList: [],
      locationValues: "",
      locationAuto: [],
      fromClaimData: "",
      categoriesList: [],
      locationMedia: [],
      globalFilterLocationsList: [],
      tempSearchLocationsList: [],
      locationIdsList: "",
      getLocationTitle: [],
      getCityList: [],
      categoriesList: [],
      AdministrativeArea: "",
    };
  }
  componentDidMount() {
    $(".toast-header").click(function () {
      // console.log("test")
      $(".toast-body").toggle();
    });
    // $(document).click(function(event) {
    //   var $trigger = $(".toast-header");
    //         if($trigger !== event.target && !$trigger.has(event.target).length){
    //           $(".toast-body").hide()
    //         }
    // });
    console.log("this.props", this.props);
    // this.getAccountLocations();
    if (window.sessionStorage?.getItem("fromClaimData")) {
      let selectedLocationId = JSON.parse(
        window.sessionStorage?.getItem("fromClaimData")
      ).google_location_id;
      this.getLocationSearchClaim(selectedLocationId);
    } else if (window.sessionStorage?.getItem("editBusinessAcountLocationId")) {
      let selectedLocationId = window.sessionStorage?.getItem(
        "editBusinessAcountLocationId"
      );
      window.sessionStorage.removeItem("editBusinessAcountLocationId");
      // alert("hi");
      this.getLocationSearchClaim(selectedLocationId);
    } else {
      this.getAdministrativeArea();
      this.getLocationSearch();
    }
    this.getCategoriesListGoogle();
  }
  // componentDidUpdate(prevProps, prevState) {
  //   if (this.state.initialState == true) {
  //     if (this.state.getLocationTitle !== prevState.getLocationTitle) {
  //       console.log("component Changed", this.state.getLocationTitle[0]);
  //       this.handleChangeState(this.state.getLocationTitle[0]);
  //       this.setState({
  //         AdministrativeArea: this.state.getLocationTitle[0],
  //       });
  //     }
  //     if (this.state.getCityList !== prevState.getCityList) {
  //       console.log("city component Changed", this.state.getCityList[0]);
  //       this.handleChangeCity(
  //         this.state.getCityList[0],
  //         this.state.AdministrativeArea
  //       );
  //       this.setState({
  //         LocalityList: this.state.getCityList[0],
  //       });
  //     }
  //     if (this.state.LocalityList !== prevState.LocalityList) {
  //       console.log(
  //         "location component Changed",
  //         this.state.globalFilterLocationsList[0]
  //       );
  //       this.getBusinessChange(
  //         this.state.globalFilterLocationsList[0].location_id,
  //         this.state.globalFilterLocationsList[0]
  //       );
  //       // this.handleChangeCity(
  //       //   this.state.getCityList[0],
  //       //   this.state.AdministrativeArea
  //       // );
  //       this.getGbpData(this.state.globalFilterLocationsList[0].location_id);
  //       this.setState({
  //         initialState: false,
  //       });
  //     }
  //   }
  // }
  initialData(value) {
    const businessAccountData = {
      functionName: "getLocalityUnderAdministrativeArea",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea: value,
    };
    // $(".loder_div").attr("hidden", false);
    apiCallingPost(businessAccountData).then(async (data) => {
      this.setState({
        getCityList: data.data.map((obj) => obj.locality),
        AdministrativeArea: value,
      });
      // this.handleChangeCity(data.data[0].locality, value);
      const businessAccountData2 = {
        functionName: "getLocationSearch",
        account_id: window.sessionStorage.getItem("account_id"),
        AdministrativeArea: value,
        locality: data.data[0].locality,
      };
      apiCallingPost(businessAccountData2).then((newObj) => {
        this.setState({
          globalFilterLocationsList: newObj.data,
          tempSearchLocationsList: newObj.data,
          LocalityList: data.data[0].locality,
          // locationIdsList: data.data.map((obj) => obj.location_id),
        });

        this.getBusinessChange(newObj.data[0].location_id, newObj.data[0]);
        this.getGbpData(newObj.data[0].location_id);
      });
    });
  }
  getCategoriesListGoogle = () => {
    const apiReq = {
      functionName: "googleLocationCategoryList",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apiReq).then((data) => {
      // console.log("googleLocationCategoryList", data);
      this.setState({
        categoriesList: data.data.categories,
      });
    });
  };
  getAdministrativeArea() {
    const businessAccountData = {
      functionName: "getAdministrativeArea",
      account_id: window.sessionStorage.getItem("account_id"),
    };
    apiCallingPost(businessAccountData).then((data) => {
      if (data.success === "1") {
        this.setState({
          getLocationTitle: data.data.map((obj) => obj.AdministrativeArea),
        });
        this.initialData(data.data[0].AdministrativeArea);
      } else {
        // $(".loder_div").attr("hidden", true);
      }
    });
  }
  getLocationAutoComplete() {
    const locationAutoData = {
      functionName: "getAccountLocationsSearch",
      searchParams: document.getElementById("hdnLocationName").value,
      //  "account_id": window.sessionStorage.getItem("account_id"),
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };

    if (
      document.getElementById("hdnLocationName").value.length > 1 ||
      document.getElementById("hdnLocationName").value === ""
    ) {
      apiCallingPost(locationAutoData)
        .then((res) => {
          if (res.success === "1" && res.data.length > 0) {
            document.getElementById("locationList").style.display = "block";
            this.setState({
              locationAuto: res.data,
            });
          } else {
            // document.getElementById("locationList").style.display = "none";
            this.setState({
              locationAuto: [],
            });
          }
        })
        .catch();
    } else if (document.getElementById("hdnLocationName").value.length === 0) {
      // document.getElementById("locationList").style.display = "none";
      this.setState({
        locationAuto: [],
      });
    }
  }

  locationClick = (name, id) => {
    document.getElementById("hdnLocationName").value = name;
    document.getElementById("hdnLocationId").value = id;
    this.getGbpData(id);
    this.setState({
      locationAuto: [],
    });
    // document.getElementById("locationList").style.display = "none";
  };

  getAccountLocations() {
    $(".loder_div").attr("hidden", false);
    const businessAccountData = {
      functionName: "getAccountLocations",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      accounts_id: window.sessionStorage.getItem("account_id"),
    };
    apiCallingPost(businessAccountData).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        if (window.sessionStorage?.getItem("editBusinessAcountLocationId")) {
          this.setState({
            accountList: data.data,
            locationIdsList: window.sessionStorage?.getItem(
              "editBusinessAcountLocationId"
            ),
          });
          let newLocationData = data.data.filter(
            (oni) =>
              oni.location_id ===
              window.sessionStorage?.getItem("editBusinessAcountLocationId")
          );
          // document.getElementById("hdnLocationName").value =
          //   newLocationData[0].storeCode +
          //   " - " +
          //   newLocationData[0].title +
          //   " - " +
          //   newLocationData[0].locality +
          //   " - " +
          //   newLocationData[0].addressLines;
          // console.log("loooooo", newLocationData);
          this.setState(
            {
              locationIdsList: newLocationData[0].location_id,
            },
            () => {
              console.log("locationValues", this.state.locationIdsList);
            }
          );
          this.getGbpData(newLocationData[0].location_id);
        }
        // this.setState({
        //   fromClaimData: JSON.parse(
        //     window.sessionStorage?.getItem("fromClaimData")
        //   ),
        // });
        else if (window.sessionStorage?.getItem("fromClaimData")) {
          let location = JSON.parse(
            window.sessionStorage?.getItem("fromClaimData")
          );
          // document.getElementById("hdnLocationName").value =
          //   location.storeCode +
          //   " - " +
          //   location.location_name +
          //   " - " +
          //   location.address;
          this.setState(
            {
              locationIdsList: location.google_location_id,
            },
            () => {
              console.log("locationValues", this.state.locationIdsList);
            }
          );
          this.getGbpData(location.google_location_id);
        } else {
          this.setState(
            {
              accountList: data.data,
              locationIdsList: data.data[0].location_id,
            },
            () => {
              console.log("locationValues", this.state.locationIdsList);
            }
          );
          // document.getElementById("hdnLocationName").value =
          //   data.data[0].storeCode +
          //   " - " +
          //   data.data[0].title +
          //   " - " +
          //   data.data[0].locality +
          //   " - " +
          //   data.data[0].addressLines;
          this.getGbpData(data.data[0].location_id);
        }
        // else this.getGbpData(data.data[0].location_id);
        // this.getGbpData(data.data[0].location_id);
      } else {
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  // locationDetails(values) {
  //   this.setState({
  //     locationValues: values,
  //   });
  //   this.getGbpData(values);
  // }

  getGbpData(locationValues) {
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "GBPAddress",
      googleUpdated: false,
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      location_id: locationValues,
    };
    backendApi(req).then((data) => {
      window.sessionStorage?.removeItem("fromClaimData");
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        // document.getElementById("locationList").style.display = "none";
        this.setState({ completeGbpList: data.data }, () => {});
        this.getLocationMedia(locationValues);
      } else {
        $(".loder_div").attr("hidden", true);
        alert("Something went wrong while getting Business Details!!");
      }
    });
  }
  updateGBPDetails = (object) => {
    if (object === "title") {
      $("#titleShow").attr("hidden", true);
      $("#titleEdit").attr("hidden", false);
      $("#titleEditButton").attr("hidden", false);
      $("#titleEdit").val($("#titleShow").text());

      $("#titleEditIcon").attr("hidden", true);
      $("#titleCancelEdit").removeAttr("hidden");
    } else if (object === "serviceArea") {
      $("#serviceAreaShow").attr("hidden", true);
      $("#serviceAreaEdit").attr("hidden", false);
      $("#serviceAreaEditButton").attr("hidden", false);
      $("#serviceAreaEdit").val($("#serviceAreaShow").text());
      $("#serviceAreaEditIcon").attr("hidden", true);
      $("#serviceAreaCancelEdit").removeAttr("hidden");
    } else if (object === "BusinessCategory") {
      let categories = this.state.completeGbpList.categories;
      // $("#BusinessCategoryShow").attr("hidden", true);
      $("#BusinessCategoryEditIcon").attr("hidden", true);
      $("#BusinessCategoryCancelEdit").removeAttr("hidden");

      $("#businessCategoryShowPrimary").attr("hidden", true);
      $("#categoriesEditPrimary").removeAttr("hidden");
      $("#categoriesShowAdditional").attr("hidden", true);
      $("#addMoreCategoriesAdd").removeAttr("hidden");
      $("#categoriesEditPrimaryValue").val(
        categories?.primaryCategory?.displayName
      );
      if (categories.additionalCategories.length > 0) {
        $("#categoriesEditAdditionalInitial").attr("hidden", false);
        $("#categoriesEditAdditionalInitial").val(
          categories.additionalCategories[0].displayName
        );
        $("#BusinessCategoryEditButton").attr("hidden", false);
        for (let i = 1; i < categories.additionalCategories.length; i++) {
          this.addIntialDivCategories(categories.additionalCategories[i]);
        }
      }
      return false;
    } else if (object === "profile") {
      $("#profileShow").attr("hidden", true);
      $("#profileEdit").attr("hidden", false);
      $("#profileEditButton").attr("hidden", false);
      $("#profileEdit").val($("#profileShow").text());
      $("#profileEditIcon").attr("hidden", true);
      $("#profileCancelEdit").removeAttr("hidden");
    } else if (object === "storefrontAddress") {
      let storefrontAddress = this.state.completeGbpList.storefrontAddress;
      //   console.log(storefrontAddress);
      $("#storefrontAddressShow").attr("hidden", true);

      $("#storefrontAddressEditIcon").attr("hidden", true);
      $("#storefrontAddressCancelEdit").removeAttr("hidden");
      $("#addMoreAddressLines").removeAttr("hidden");
      $("#storefrontAddressEditButton").attr("hidden", false);
      $("#storefrontAddressEditLocalityShow").attr("hidden", true);
      $("#storefrontAddressEditAdministrativeAreaShow").attr("hidden", true);
      $("#storefrontAddressEditPostalCodeShow").attr("hidden", true);
      $("#storefrontAddressEditPostalCodeEdit").attr("hidden", false);
      $("#storefrontAddressEditPostalCodeEdit").val(
        storefrontAddress.postalCode
      );
      $("#storefrontAddressEditLocalityEdit").attr("hidden", false);
      $("#storefrontAddressEditLocalityEdit").val(storefrontAddress.locality);
      $("#storefrontAddressEditRegionCodeEdit").attr("hidden", false);
      $("#storefrontAddressEditRegionCodeEdit").val(
        storefrontAddress.regionCode
      );
      $("#storefrontAddressEditAdministrativeAreaEdit").attr("hidden", false);
      $("#storefrontAddressEditAdministrativeAreaEdit").val(
        storefrontAddress.administrativeArea
      );
      if (storefrontAddress.addressLines.length > 0) {
        $("#storefrontAddressEditAddressLinesIntial").attr("hidden", false);
        $("#storefrontAddressEditAddressLinesIntial").val(
          storefrontAddress.addressLines[0]
        );
        for (let i = 1; i < storefrontAddress.addressLines.length; i++) {
          this.addIntialDiv(storefrontAddress.addressLines[i]);
        }
      }
      //   $("#storefrontAddressShow").attr("hidden", true);
    } else if (object === "websiteUri") {
      $("#websiteUriShow").attr("hidden", true);
      $("#websiteUriEdit").attr("hidden", false);
      $("#websiteUriEditButton").attr("hidden", false);
      $("#websiteUriEdit").val($("#websiteUriShow").text());
      $("#websiteUriEditIcon").attr("hidden", true);
      $("#websiteUriCancelEdit").removeAttr("hidden");
    } else if (object === "Appointment") {
      $("#AppointmentShow").attr("hidden", true);
      $("#AppointmentEdit").attr("hidden", false);
      $("#AppointmentEditButton").attr("hidden", false);
      $("#AppointmentEdit").val($("#AppointmentShow").text());
      $("#AppointmentEditIcon").attr("hidden", true);
      $("#AppointmentCancelEdit").removeAttr("hidden");
    } else if (object === "Products") {
      $("#ProductsShow").attr("hidden", true);
      $("#ProductsEdit").attr("hidden", false);
      $("ProductsEditButton").attr("hidden", false);
      $("#ProductsEdit").val($("#ProductsShow").text());
      $("#ProductsEditIcon").attr("hidden", true);
      $("#ProductsCancelEdit").removeAttr("hidden");
    } else if (object === "Services") {
      $("#ServicesShow").attr("hidden", true);
      $("#ServicesEdit").attr("hidden", false);
      $("#ServicesEditButton").attr("hidden", false);
      $("#ServicesEdit").val($("#ServicesShow").text());
      $("#ServicesEditIcon").attr("hidden", true);
      $("#ServicesCancelEdit").removeAttr("hidden");
    } else if (object === "StoreCode") {
      $("#StoreCodeShow").attr("hidden", true);
      $("#StoreCodeEdit").attr("hidden", false);
      $("#StoreCodeEditButton").attr("hidden", false);
      $("#StoreCodeEdit").val($("#StoreCodeShow").text());
      $("#StoreCodeEditIcon").attr("hidden", true);
      $("#StoreCodeCancelEdit").removeAttr("hidden");
    } else if (object === "websiteUri55") {
      // $("#websiteUriShow55").attr("hidden", true);
      $("#websiteUriEdit55").attr("hidden", false);
      $("#websiteUriEdit66").attr("hidden", false);
      $("#websiteUriEdit12").attr("hidden", false);
      $("#websiteUriEdit77").attr("hidden", false);
      $("#websiteUriEdit88").attr("hidden", false);
      $("#websiteUriEdit99").attr("hidden", false);
      $("#websiteUriEdit666").attr("hidden", false);

      $("#websiteUriEditButton55").attr("hidden", false);
      $("#websiteUriEditIcon55").attr("hidden", true);
      $("#websiteUriCancelEdit55").removeAttr("hidden");
      $("#websiteUriEdit55").val($("#websiteUriShow55").text());
      $("#websiteUriEdit66").val($("#websiteUriShow66").text());
      $("#websiteUriEdit12").val($("#websiteUriShow12").text());

      // $("#websiteUriEdit666").val($("#websiteUriShow666").text());
      $("#websiteUriEdit77").val($("#websiteUriShow77").text());
      $("#websiteUriEdit88").val($("#websiteUriShow88").text());
      $("#websiteUriEdit99").val($("#websiteUriShow99").text());
    } else if (object === "phoneNumbers") {
      let phoneNumbers = this.state.completeGbpList.phoneNumbers;
      $("#phoneNumbersShowPrimary").attr("hidden", true);
      $("#phoneNumbersEditPrimary").attr("hidden", false);
      $("#phoneNumbersEditButton").attr("hidden", false);
      $("#phoneNumbersEditPrimaryValue").val(phoneNumbers.primaryPhone);
      $("#phoneNumbersEditIcon").attr("hidden", true);
      $("#phoneNumbersCancelEdit").removeAttr("hidden");
      $("#phoneNumbersShowAdditional").attr("hidden", true);
      $("#addMorephoneNumbersAdd").removeAttr("hidden");
      if (phoneNumbers.additionalPhones.length > 0) {
        $("#phoneNumbersEditAdditionalInitial").attr("hidden", false);
        $("#phoneNumbersEditAdditionalInitial").val(
          phoneNumbers.additionalPhones[0]
        );
        for (let i = 1; i < phoneNumbers.additionalPhones.length; i++) {
          this.addIntialDivPhones(phoneNumbers.additionalPhones[i]);
        }
      }
    } else if (object === "openInfo") {
      $("#openInfoEditButton").attr("hidden", true);
      $("#openInfoSaveEditButton").attr("hidden", false);
      $("#openInfoCancelEdit").removeAttr("hidden");
      $("#openingDateShow").attr("hidden", true);
      $("#openInfoMonthEdit").attr("hidden", false);
      $("#openInfoDayEdit").attr("hidden", false);
      $("#openInfoYearEdit").attr("hidden", false);
    } else if (object === "regularHours") {
      $("#workingHoursShow").attr("hidden", true);
      $("#workingHoursEdit").attr("hidden", false);
      $("#regularHoursEditIcon").attr("hidden", true);
      $("#regularHoursCancelEdit").removeAttr("hidden");
      $("#workingHoursEditButton").attr("hidden", false);
      let daysArray = [
        {
          id: 0,
          day: "SUNDAY",
        },
        {
          id: 1,
          day: "MONDAY",
        },
        {
          id: 2,
          day: "TUESDAY",
        },
        {
          id: 3,
          day: "WEDNESDAY",
        },
        {
          id: 4,
          day: "THURSDAY",
        },
        {
          id: 5,
          day: "FRIDAY",
        },
        {
          id: 6,
          day: "SATURDAY",
        },
      ];
      const missingDays = daysArray.filter(
        (day) =>
          !this.state.completeGbpList.regularHours.periods.some(
            (period) => period.openDay === day.day
          )
      );
      for (let i = 0; i <= 6; i++) {
        if (missingDays.some((obj) => obj.id === i)) {
          $("#" + i + "TimeCheck").prop("checked", true);
          $("#" + i + "Fromtime, #" + i + "Totime")
            .closest("div")
            .attr("hidden", true);
        }
      }
      for (let i = 0; i <= 6; i++) {
        // alert(
        //   this.state.completeGbpList.regularHours.periods[i].closeTime.hours
        // );
        if (
          this.state.completeGbpList.regularHours.periods[i].closeTime.hours ===
          24
        ) {
          // $("#" + i + "TimeCheck").prop("checked", true);
          $("#" + i + "Fromtime").val("00:00");
          $("#" + i + "Totime").val("11:59");
          // $("#" + i + "Fromtime, #" + i + "Totime")
          //   .closest("div")
          //   .attr("hidden", true);
        } else {
          if (this.state.completeGbpList.regularHours.periods[i]) {
            $("#" + i + "Fromtime").val(
              this.state.completeGbpList.regularHours.periods[i].openTime.hours
                ? this.state.completeGbpList.regularHours.periods[i].openTime
                    .minutes
                  ? this.state.completeGbpList.regularHours.periods[i].openTime
                      .hours +
                    ":" +
                    this.state.completeGbpList.regularHours.periods[i].openTime
                      .minutes
                  : this.state.completeGbpList.regularHours.periods[i].openTime
                      .hours + ":00"
                : ""
            );
            $("#" + i + "Totime").val(
              this.state.completeGbpList.regularHours.periods[i].closeTime.hours
                ? this.state.completeGbpList.regularHours.periods[i].closeTime
                    .minutes
                  ? this.state.completeGbpList.regularHours.periods[i].closeTime
                      .hours +
                    ":" +
                    this.state.completeGbpList.regularHours.periods[i].closeTime
                      .minutes
                  : this.state.completeGbpList.regularHours.periods[i].closeTime
                      .hours + ":00"
                : ""
            );
          }
        }
      }
    }
  };
  hidePhoneNumbers() {
    $("#phoneNumbersShowPrimary").attr("hidden", false);
    $("#phoneNumbersEditPrimary").attr("hidden", true);
    $("#phoneNumbersEditIcon").removeAttr("hidden");
    $("#phoneNumbersCancelEdit").attr("hidden", true);
    $("#phoneNumbersShowAdditional").attr("hidden", false);
    $("#addMorephoneNumbersAdd").attr("hidden", true);
    $("#phoneNumbersEditAdditionalInitial").attr("hidden", true);
    $(".app_clone_category_phone_numbers").empty();
    $("#phoneNumbersEditButton").attr("hidden", true);
  }
  hideAddresDatas() {
    let storefrontAddress = this.state.completeGbpList.storefrontAddress;
    //   console.log(storefrontAddress);
    $("#storefrontAddressShow").attr("hidden", false);
    $("#serviceAreaShow").attr("hidden", false);
    $("#storefrontAddressEditIcon").removeAttr("hidden");
    $("#storefrontAddressCancelEdit").attr("hidden", true);
    $("#addMoreAddressLines").attr("hidden", false);
    $("#storefrontAddressEditButton").attr("hidden", true);
    $("#storefrontAddressEditLocalityShow").attr("hidden", false);
    $("#storefrontAddressEditAdministrativeAreaShow").attr("hidden", false);
    $("#storefrontAddressEditPostalCodeShow").attr("hidden", false);
    $("#storefrontAddressEditPostalCodeEdit").attr("hidden", true);
    $("#storefrontAddressEditPostalCodeEdit").val("");
    $("#storefrontAddressEditLocalityEdit").attr("hidden", true);
    $("#storefrontAddressEditLocalityEdit").val("");
    $("#storefrontAddressEditRegionCodeEdit").attr("hidden", true);
    $("#storefrontAddressEditRegionCodeEdit").val("");
    $("#storefrontAddressEditAdministrativeAreaEdit").attr("hidden", true);
    $("#storefrontAddressEditAdministrativeAreaEdit").val(
      storefrontAddress.administrativeArea
    );
    $("#storefrontAddressEditAddressLinesIntial").attr("hidden", true);
    $("#storefrontAddressEditAddressLinesIntial").val("");
    $(".app_clone_category").empty();
    //   $("#storefrontAddressShow").attr("hidden", true);
  }
  addAddressLines() {
    if (addressLinesConstant > 6) {
      alert("Can only enter upto 6 lines");
      return false;
    }
    $(".app_clone_category").append(
      '<div class="append_inputdiv "><input id="storefrontAddressEditAddressLinesClone' +
        addressLinesConstant +
        '" type="text" class="editInput form-control" placeholder="Enter Address Line" /></div>'
    );
    addressLinesConstant++;
  }
  addIntialDiv(e) {
    let initial = e;
    if (addressLinesConstant > 6) {
      alert("Can only enter upto 6 lines");
      return false;
    }
    $(".app_clone_category").append(
      '<div class="append_inputdiv"><input value="' +
        initial +
        '" id="storefrontAddressEditAddressLinesClone' +
        addressLinesConstant +
        '" type="text" class="editInput form-control" placeholder="Enter Categories" /></div>'
    );
    addressLinesConstant++;
  }
  addIntialDivPhones(e) {
    let initial = e;
    if (phoneNumbersConstant > 6) {
      alert("Can only enter upto 6 lines");
      return false;
    }
    $(".app_clone_category_phone_numbers").append(
      '<div class="append_inputdiv"><input value="' +
        initial +
        '" id="phoneNumbersEditAdditionalClone' +
        phoneNumbersConstant +
        '" type="text" class="editInput form-control" placeholder="Enter Additional Phone Number" /></div>'
    );
    phoneNumbersConstant++;
  }
  addPhoneNumbers() {
    $(".app_clone_category_phone_numbers").append(
      '<div class="append_inputdiv"><input id="phoneNumbersEditAdditionalClone' +
        phoneNumbersConstant +
        '" type="text" class="editInput form-control" placeholder="Enter Additional Phone Number" /></div>'
    );
    phoneNumbersConstant++;
  }
  addIntialDivCategories(e) {
    let initial = e;
    // if (categoriesConstant > 6) {
    //   alert("Can only enter upto 6 lines");
    //   return false;
    // }
    $(".app_clone_category_business_categories").append(
      '<div class="append_inputdiv"><input value="' +
        initial.displayName +
        '" id="categoriesEditAdditionalClone' +
        categoriesConstant +
        '" type="text" class="editInput form-control" placeholder="Enter Additional Categories" /></div>'
    );
    categoriesConstant++;
  }
  addCategories() {
    if (
      $(`#categoriesEditAdditionalClone${categoriesConstant - 1}`).val() === ""
    ) {
      alert("Please fill category before adding more!");
      return false;
    }
    $(".app_clone_category_business_categories").append(
      '<div class="append_inputdiv"><input id="categoriesEditAdditionalClone' +
        categoriesConstant +
        '" type="text" class="editInput form-control" placeholder="Enter Additional Categories" /></div>'
    );
    categoriesConstant++;
  }
  hideCategories() {
    let categories = this.state.completeGbpList.categories;
    $("#BusinessCategoryEditIcon").removeAttr("hidden");
    $("#BusinessCategoryCancelEdit").attr("hidden", true);

    $("#businessCategoryShowPrimary").removeAttr("hidden");
    $("#categoriesEditPrimary").attr("hidden", true);
    $("#categoriesShowAdditional").removeAttr("hidden");
    $("#categoriesEditAdditionalInitial").attr("hidden", true);
    $("#addMoreCategoriesAdd").attr("hidden", true);
    $(".app_clone_category_business_categories").empty();

    $("#categoriesEditPrimaryValue").val(
      categories?.primaryCategory?.displayName
    );
  }
  UpdateGBPLocationAPI = (object) => {
    $(".loder_div").attr("hidden", false);
    let updateMask = "";
    let obj = {};
    let periods = [];
    if (object === "title") {
      updateMask = "title";
      obj = {
        title: $("#titleEdit").val(),
      };
    } else if (object === "StoreCode") {
      updateMask = "StoreCode";
      obj = {
        storeCode: $("#StoreCodeEdit").val(),
      };
    } else if (object === "profile") {
      updateMask = "profile";
      obj = {
        profile: {
          description: $("#profileEdit").val(),
        },
      };
    } else if (object === "storefrontAddress") {
      var addressLines = [];
      addressLines.push($("#storefrontAddressEditAddressLinesIntial").val());
      if (addressLinesConstant > 0) {
        for (let i = 0; i < addressLinesConstant; i++) {
          if ($("#storefrontAddressEditAddressLinesClone" + i).val() !== "") {
            addressLines.push(
              $("#storefrontAddressEditAddressLinesClone" + i).val()
            );
          }
        }
      }
      updateMask = "storefrontAddress";
      obj = {
        storefrontAddress: {
          postalCode: $("#storefrontAddressEditPostalCodeEdit").val(),
          administrativeArea: $(
            "#storefrontAddressEditAdministrativeAreaEdit"
          ).val(),
          locality: $("#storefrontAddressEditLocalityEdit").val(),
          regionCode: $("#storefrontAddressEditRegionCodeEdit").val(),
          addressLines: addressLines,
        },
      };
      console.log("adresssss", obj);
    } else if (object === "websiteUri") {
      updateMask = "websiteUri";
      obj = {
        websiteUri: $("#websiteUriEdit").val(),
      };
    } else if (object === "phoneNumbers") {
      updateMask = "phoneNumbers";
      var additionalPhoneNos = [];
      $("#phoneNumbersEditAdditionalInitial").val() !== "" &&
        additionalPhoneNos.push($("#phoneNumbersEditAdditionalInitial").val());

      for (let i = 0; i < phoneNumbersConstant; i++) {
        additionalPhoneNos.push(
          $("#phoneNumbersEditAdditionalClone" + i).val()
        );
      }
      obj = {
        phoneNumbers: {
          primaryPhone: $("#phoneNumbersEditPrimaryValue").val(),
          additionalPhones: additionalPhoneNos,
        },
      };
    } else if (object === "openInfo") {
      updateMask = "openInfo";
      obj = {
        openInfo: {
          status: "OPEN",
          canReopen: true,
          openingDate: {
            year: parseInt($("#openInfoYearEdit").val()),
            month: parseInt($("#openInfoMonthEdit").val()),
            // ...($("#openInfoDayEdit").val() && {
            //   day: parseInt($("#openInfoDayEdit").val()),
            //   // day: parseInt(this.state.openInfoDayEdit),
            // }),
          },
        },
      };
    } else if (object === "regularHours") {
      updateMask = "regularHours";
      for (let i = 0; i <= 6; i++) {
        // console.log("sadasd", $("#" + i + "FromTime :selected").text());

        if (!$("#" + i + "TimeCheck").is(":checked")) {
          periods.push({
            openDay: $("#" + i + "DayValue")
              .text()
              .toUpperCase(),
            openTime: {
              hours: parseInt(
                $("#" + i + "Fromtime")
                  .val()
                  .split(":")[0]
              ),
              minutes: parseInt(
                $("#" + i + "Fromtime")
                  .val()
                  .split(":")[1]
              ),
            },
            closeDay: $("#" + i + "DayValue")
              .text()
              .toUpperCase(),
            closeTime: {
              hours: parseInt(
                $("#" + i + "Totime")
                  .val()
                  .split(":")[0]
              ),
              minutes: parseInt(
                $("#" + i + "Totime")
                  .val()
                  .split(":")[1]
              ),
            },
          });
        }
        // else {
        //   periods.push({
        //     openDay: $("#" + i + "DayValue")
        //       .text()
        //       .toUpperCase(),
        //     openTime: {},
        //     closeDay: $("#" + i + "DayValue")
        //       .text()
        //       .toUpperCase(),
        //     closeTime: {
        //       hours: 24,
        //     },
        //   });
        // }
      }
      obj = {
        regularHours: {
          periods: periods,
        },
      };
    } else if (object === "BusinessCategory") {
      updateMask = "categories";
      var additionalCategories = [];
      $("#categoriesEditAdditionalInitial").val() !== "" &&
        additionalCategories.push($("#categoriesEditAdditionalInitial").val());

      for (let i = 0; i < categoriesConstant; i++) {
        additionalCategories.push(
          $("#categoriesEditAdditionalClone" + i).val()
        );
      }
      if ($("#categoriesEditPrimaryValue").val() === "") {
        alert("Please enter a Primary Category");
        return false;
      }
      let primaCat = "";
      let primaCatValue = $("#categoriesEditPrimaryValue").val();
      let filteredPrima = this.state.categoriesList.filter(
        (data) => data.displayName.toLowerCase() === primaCatValue.toLowerCase()
      );
      if (filteredPrima.length === 0) {
        alert(
          `${primaCatValue} category is not available in list of categories`
        );
        return false;
      }
      obj = {
        primaryCategory: {
          name: filteredPrima[0].name,
          displayName: filteredPrima[0].displayName,
          ...(filteredPrima[0].moreHoursTypes && {
            moreHoursTypes: filteredPrima[0].moreHoursTypes,
          }),
          ...(filteredPrima[0].serviceTypes && {
            serviceTypes: filteredPrima[0].serviceTypes,
          }),
        },
      };
      if (additionalCategories.length > 0) {
        let completeAdditionalCategories = [];
        for (const obj of additionalCategories) {
          let filteredCategory = this.state.categoriesList.filter(
            (category) =>
              category.displayName.toLowerCase() === obj.toLowerCase()
          );
          if (filteredCategory.length == 0) {
            alert(`${obj} category is not available in list of categories`);
            return false;
          } else {
            completeAdditionalCategories.push({
              name: filteredCategory[0].name,
              displayName: filteredCategory[0].displayName,
              ...(filteredCategory[0].moreHoursTypes && {
                moreHoursTypes: filteredCategory[0].moreHoursTypes,
              }),
              ...(filteredCategory[0].serviceTypes && {
                serviceTypes: filteredCategory[0].serviceTypes,
              }),
            });
          }
        }
        obj["additionalCategories"] = completeAdditionalCategories;
      }
      console.log("🚀 ~ file: business-details.jsx:982 ~ Info ~ obj:", obj);
    }
    var req = {
      functionName: "UpdateGBPLocation",
      validate: false,
      location_id: this.state.locationIdsList,
      obj: obj,
      updateMask: updateMask,
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    console.log(req);
    backendApi(req).then((data) => {
      if (data.success === "1") {
        if (object === "StoreCode") {
          const request = {
            functionName: "updateLocationDetails",
            tableField: "storeCode",
            fieldValue: obj.storeCode,
            location_id: this.state.locationIdsList,
          };
          backendApi(request);
        }
        $("#loder_div").attr("hidden", true);
        alert("Saved Successfully");
        this.hideCategories();
        this.hideAddresDatas();
        this.hidePhoneNumbers();
        this.getLocationSearchClaim(this.state.locationIdsList);
        // window.location.reload();
      } else {
        $("#loder_div").attr("hidden", true);
        alert("Failed to update Business information");
        // window.location.reload();
      }
    });
  };
  handleImageUpload(image) {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_TAGTREE_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_TAGTREE_S3_SECRET_KEY,
      region: s3Region,
    });
    var bucketParams = {
      Bucket: s3Bucket,
    };
    // s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    let s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: bucketParams,
    });
    let s3BucketMainFolder1 = s3BucketMainFolder + "/" + S3BucketImagesFolder;
    for (let i = 0; i < image.length; i++) {
      console.log("image[i]image[i]image[i]", image[i]);
      let fileName = this.uuidv4() + image[i].name;
      let fullFilePath = s3BucketMainFolder1 + "/" + fileName;
      console.log("fullFilePath", fullFilePath);
      s3.upload(
        {
          Key: fullFilePath,
          Body: image[i],
          ACL: "public-read",
        },
        (err, data) => {
          if (err) {
            alert("There was an error uploading your image");
            console.log(err.message);
          } else if (data) {
            alert("Image Uploaded Successfully");
            console.log(data.Location);
            $("#uploadedImage").attr("hidden", false);
            this.state.imageUrl.push(data.Location);
            console.log("imageUrlimageUrlimageUrl", this.state.imageUrl);
            this.setState({
              imageUrlListStatus: i + 1,
            });
            // this.state.imageName.push(data.fileName);
            // this.setState({ imageUrl: data.Location, imageName: fileName });

            return;
          }
        }
      );
    }
  }
  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  postPhotoToGBP() {
    this.state.imageUrl.forEach((data, i) => {
      var req = {
        functionName: "PostMediaGBP",
        account: "113149385002384039024",
        locationId: "53904911361864879",
        category: $("#categoryDropdown").val(),
        mediaFormat: $("#formatTypeDropdown").val(),
        source: data,
        site_id:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
      };
      // console.log("reqreqreqreqreqreq",req);
      backendApi(req).then(async (data) => {
        console.log(i);
        alert("Image posted successfully");
      });
    });
  }
  imageListing = () => {
    return (
      <div id="uploadedImage" className="imageUploaded">
        {this.state.imageUrl.map((data, i) => (
          <>
            {console.log("datadata", data)}
            <img src={data} alt="" className="imageUploaded_img" />;
            <span className="close_popup_imageUpload">
              <Icon.X
                onClick={() => {
                  $("#uploadedImage").attr("hidden", true);
                  let items = [...this.state.imageUrl];
                  items.splice(i, 1);
                  this.setState({ imageUrl: items });

                  // setImageName("");
                  // setPhotos(true);
                }}
              />
            </span>
            ;
          </>
        ))}
      </div>
    );
  };
  getLocationMedia(location_id) {
    $(".loder_div").attr("hidden", false);
    const apiRequest = {
      functionName: "GetLocationMedia",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      location_id: location_id,
    };
    backendApi(apiRequest).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        if (Object.keys(data.data).length > 0) {
          this.setState(
            {
              locationMedia: data.data.mediaItems,
            },
            () => console.log("thissssssss", this.state.locationMedia)
          );
        } else {
          this.setState({
            locationMedia: [],
          });
        }
        $("#multipleImagesSlider").attr("hidden", false);
      } else {
        this.setState({
          locationMedia: [],
        });
      }
    });
  }
  handleChangeCity(value, AdministrativeArea) {
    console.log("hiiii", value);
    this.setState({
      LocalityList: value,
      getTitleName: "",
    });
    this.setState({
      businessNameFilter: "",
      businessAddress: "",
    });

    const businessAccountData = {
      functionName: "getLocationSearch",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea: AdministrativeArea,
      locality: value,
    };
    // $(".loder_div").attr("hidden", false);
    apiCallingPost(businessAccountData).then((data) => {
      // $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        this.setState({
          globalFilterLocationsList: data.data,
          tempSearchLocationsList: data.data,
          // locationIdsList: data.data.map((obj) => obj.location_id),
        });
        finalarray = [];
      } else {
        this.setState({
          globalFilterLocationsList: [],
          tempSearchLocationsList: [],
          // locationIdsList: [],
        });
        // $(".loder_div").attr("hidden", true);
      }
    });
  }

  handleChangeState = (value) => {
    $("#cityId").val("");
    if (value !== "") {
      this.setState({
        businessNameFilter: "",
        businessAddress: "",
        LocalityList: "",
        getTitleName: "",
      });
      const businessAccountData = {
        functionName: "getLocalityUnderAdministrativeArea",
        account_id: window.sessionStorage.getItem("account_id"),
        AdministrativeArea: value,
      };
      // $(".loder_div").attr("hidden", false);
      apiCallingPost(businessAccountData).then((data) => {
        // $(".loder_div").attr("hidden", true);
        if (data.success === "1") {
          const businessAccountData = {
            functionName: "getLocationSearch",
            account_id: window.sessionStorage.getItem("account_id"),
            AdministrativeArea: value,
            locality: "",
          };
          backendApi(businessAccountData).then((response) => {
            this.setState({
              globalFilterLocationsList: response.data,
              tempSearchLocationsList: response.data,
              // locationIdsList: response.data.map((obj) => obj.location_id),
            });
            finalarray = [];
            // response.data.forEach((data, i) => {
            //   if (i <= 7)
            //     finalarray.push(
            //       `accounts/${window.sessionStorage.getItem(
            //         "account_id"
            //       )}/locations/${data.location_id}`
            //     );
            // });
            // this.newGetLocationReviews();
          });
          this.setState({
            getCityList: data.data.map((obj) => obj.locality),
            AdministrativeArea: value,
          });
        } else {
          this.setState({
            getCityList: [],
          });
          // $(".loder_div").attr("hidden", true);
        }
      });
    } else {
      this.setState({
        AdministrativeArea: "",
        LocalityList: "",
        getCityList: [],
        globalFilterLocationsList: this.state.tempSearchLocationsList,
      });
    }
  };
  filterLocations(e) {
    this.setState({
      businessNameFilter: e.target.value,
    });
    var array = [];
    if (this.state.AdministrativeArea != "" && this.state.LocalityList != "") {
      array = this.state.tempSearchLocationsList;
      console.log("hello111", array);
    } else {
      array = this.state.allLocationdata;
      console.log("hello222", array);
    }
    var filterArray = array.filter(
      (data) =>
        data.storeCode.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.addressLines
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        (
          data.storeCode +
          "," +
          data.addressLines +
          "," +
          data.locality +
          "," +
          data.AdministrativeArea
        )
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        (data.locality + "," + data.AdministrativeArea)
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
    );
    this.setState({
      globalFilterLocationsList: filterArray,
    });
  }
  getBusinessChange(item, data) {
    // let filterData = this.state.allLocationdata.filter((obj, i) => {
    //   return obj.location_id === item;
    // });
    // console.log("dataaaa",data)
    this.setState(
      {
        getTitleName: item,
        getTitleNameCopy: data.title,
        locationIdsList: data.location_id,
        // selectedLocation: data.location_id,
        businessNameFilter: `${data.storeCode}- ${data.title}-${data.addressLines},
      ${data.locality},${data.AdministrativeArea}`,
        businessAddress: `${data.title}, ${data.addressLines}, ${data.locality}, ${data.AdministrativeArea}`,
      },
      () => {
        console.log(
          "this.state.businessNameFilter",
          this.state.businessNameFilter
        );
      }
    );
  }
  getLocationSearch() {
    const businessAccountData = {
      functionName: "getLocationSearch",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea: "",
      locality: "",
    };
    apiCallingPost(businessAccountData).then((data) => {
      if (data.success === "1") {
        this.setState(
          {
            allLocationdata: data.data,
            businessName: data.data,
            globalFilterLocationsList: data.data,
          },
          () => {
            console.log("alllLocationData", this.state.allLocationdata);
          }
        );
      } else {
      }
    });
  }
  // getLocationSearchEdit() {}
  getLocationSearchClaim(selectedLocationId) {
    let locationData = JSON.parse(
      window.sessionStorage?.getItem("fromClaimData")
    );
    window.sessionStorage.removeItem("fromClaimData");
    const businessAccountData1 = {
      functionName: "getAdministrativeArea",
      account_id: window.sessionStorage.getItem("account_id"),
    };
    apiCallingPost(businessAccountData1).then((data) => {
      if (data.success === "1") {
        this.setState({
          getLocationTitle: data.data.map((obj) => obj.AdministrativeArea),
        });
      }
    });
    const businessAccountData = {
      functionName: "getLocationSearch",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea: "",
      locality: "",
    };
    apiCallingPost(businessAccountData).then((data) => {
      if (data.success === "1") {
        console.log(
          "🚀 ~ file: business-details.jsx:1254 ~ Info ~ apiCallingPost ~ data.data:",
          data.data
        );
        this.setState(
          {
            allLocationdata: data.data,
            businessName: data.data,
            globalFilterLocationsList: data.data,
          },
          () => {
            let tempSelectedLocation = this.state.allLocationdata.filter(
              (selectData) => selectData.location_id == selectedLocationId
            );
            console.log("alllLocationData", tempSelectedLocation);
            if (tempSelectedLocation.length > 0) {
              this.handleChangeState(
                tempSelectedLocation[0].AdministrativeArea
              );
              setTimeout(() => {
                this.handleChangeCity(
                  tempSelectedLocation[0].locality,
                  tempSelectedLocation[0].AdministrativeArea
                );
              }, 1000);
              setTimeout(() => {
                this.getBusinessChange(
                  tempSelectedLocation[0].location_id,
                  tempSelectedLocation[0]
                );
              }, 2000);
              this.getGbpData(tempSelectedLocation[0].location_id);
            } else {
              alert(
                "Something went wrong while getting data for this business.."
              );
            }
          }
        );
      } else {
      }
    });
  }
  getClearData() {
    // this.getAllLocationdata();
    // $("#stateId").val("");
    // $("#cityId").val("");
    // $("#buisnessId").val("");
    // console.log(
    //   "🚀 ~ file: business-details.jsx:1203 ~ Info ~ businessName ~ allLocationdata:",
    //   this.state.allLocationdata
    // );
    let businessName = this.state.allLocationdata.map((obj) => {
      return obj.title;
    });
    this.setState(
      {
        LocalityList: "",
        AdministrativeArea: "",
        startDate: "",
        endDate: "",
        starReview: "",
        sentimental: "",
        reply: "",
        getTitleName: "",
        getCityList: [],
        keyword: [],
        businessName: businessName,
        businessNameFilter: "",
        locationIdsList: "",
        globalFilterLocationsList: this.state.allLocationdata,
      },
      () => {
        // this.newReviewList();
        // this.getfilterData();
      }
    );
  }
  NewSearchAPIFunction() {
    this.getGbpData(this.state.locationIdsList);
  }
  getLocationSearch() {
    const businessAccountData = {
      functionName: "getLocationSearch",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea: "",
      locality: "",
    };
    // $(".loder_div").attr("hidden", false);
    apiCallingPost(businessAccountData).then((data) => {
      // $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        this.setState(
          {
            allLocationdata: data.data,
            businessName: data.data,
            globalFilterLocationsList: data.data,
          },
          () => {
            console.log("alllLocationData", this.state.allLocationdata);
          }
        );
      } else {
        // $(".loder_div").attr("hidden", true);
      }
    });
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper">
          <Header heading="GBP" headerImage={Gbpimage} />
          <div className="container-fluid">
            <div className="back_tocatalog_main">
              <Link
                onClick={() => window.history.back()}
                className="back-to-link"
              >
                <Icon.ArrowLeftCircle />
              </Link>
            </div>
            <div className="user_listviewhead">
              <span>Business Details</span>
            </div>
            <div className="row row-search px-2 mt-2">
              <div className="col-lg-2 col-md-4 col-sm-6">
                <select
                  className="form-control"
                  id="stateId"
                  value={this.state.AdministrativeArea}
                  onChange={(e) => this.handleChangeState(e.target.value)}
                >
                  <option value="">State</option>
                  {this.state.getLocationTitle.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <select
                  className="form-control"
                  id="cityId"
                  value={this.state.LocalityList}
                  onChange={(e) =>
                    this.handleChangeCity(
                      e.target.value,
                      this.state.AdministrativeArea
                    )
                  }
                >
                  <option value="">City</option>
                  {this.state.getCityList.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="col-lg-5 col-md-4 location_search">
                <input
                  className="form-control"
                  value={this.state.businessNameFilter}
                  onChange={(e) => this.filterLocations(e)}
                  placeholder="Search by location"
                />
                <div className="autocomplete__list location_search_drop">
                  <ul class="nav flex-column">
                    {this.state.globalFilterLocationsList.map((data) => (
                      <li
                        key={data.location_id}
                        onMouseDown={() =>
                          this.getBusinessChange(data.location_id, data)
                        }
                      >
                        {data.storeCode}- {data.title}-{data.addressLines},
                        {data.locality},{data.AdministrativeArea}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* <div className="col-lg-5 tagsInput">
                <TagsInput
                  // key={this.state.keywordLength}
                  classNames="new-react-tag-classes"
                  value={this.state.keyword}
                  onChange={this.setSelected}
                  name="keywords"
                  isEditOnRemove={true}
                  placeHolder="Write and press ENTER after each word"
                  style={{ width: "100%" }}
                />
              </div> */}
              <div className="col-lg-1 col-md-4 col-sm-6 pr-0">
                <button
                  className="btn-search w-100"
                  onClick={() => this.getGbpData(this.state.locationIdsList)}
                >
                  Search
                </button>
              </div>
              <div className="col-lg-1 col-md-4 col-sm-6 pr-0">
                <button
                  className="btn-clear-search w-100"
                  onClick={() => this.getClearData()}
                >
                  Clear
                </button>
              </div>
            </div>
            <div
              className="toast pull-right"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              {gbpdata && (
                <div className="toast-header">
                  <strong className="mr-auto">Details</strong>
                  <button
                    type="button"
                    className="ml-2 mb-1 closeToast"
                    data-dismiss="toast"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&#8645;</span>
                  </button>
                </div>
              )}
              {gbpdata && (
                <div className="toast-body table_mapwidth">
                  <div className="col-md-12 tableResponsive">
                    <div className="tableOuterWidth">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th className="col-2">Date</th>

                            <th className="col-3">Address</th>
                            <th className="col-2">Business Name</th>
                            <th className="col-2">Phone Number</th>
                            <th className="col-3">Category</th>
                            {/* {this.state.mode == "accuracy" && (
                            <>
                              <th className="col-4">Category</th>
                              <th className="col-4">GBP Status</th>
                            </>
                          )} */}
                          </tr>
                        </thead>
                        <div class="table_Outerscroll">
                          <tbody className="table_Outertbody">
                            <tr>
                              <td className="col-2" rowspan="2">
                                <span>
                                  {" "}
                                  {moment(extractedData[0].created_on).format(
                                    "DD/MM/YYYY"
                                  )}
                                </span>
                                <span style={{ display: "block" }}>
                                  {" "}
                                  {moment(extractedData[0].created_on).format(
                                    "hh:mm A"
                                  )}
                                </span>

                                <br></br>
                              </td>
                              {/* <td className="col-2" rowspan="2">
                              <span>fdf</span>
                              <span style={{ display: "block" }}>dffdffd</span>

                              <br></br>
                            </td> */}
                              <td className="col-3">
                                <div className="mb-1">
                                  <label htmlFor="" className="fromAuditText">
                                    Excel Address
                                  </label>
                                  <div>{extractedData[0].excelAddress}</div>
                                  <hr />
                                </div>
                                <label htmlFor="" className="fromVisibitiText">
                                  Google Address
                                </label>
                                <div>{extractedData[0].googleAddress}</div>
                              </td>
                              <td className="col-2">
                                <span style={{ display: "block" }}>
                                  {extractedData[0].googleName}
                                </span>

                                <br></br>
                              </td>
                              <td className="col-2">
                                <div className="mb-1">
                                  <label htmlFor="" className="fromAuditText">
                                    From Audit Excel
                                  </label>
                                  <div>{extractedData[0].excelPhoneNo}</div>
                                  <hr />
                                </div>
                                <label htmlFor="" className="fromVisibitiText">
                                  From Google
                                </label>
                                <div>{extractedData[0].googlePhoneNO}</div>
                              </td>
                              <td className="col-3">
                                <div className="mb-1">
                                  <label htmlFor="" className="fromAuditText">
                                    From Audit Excel
                                  </label>
                                  <div>{extractedData[0].excelCategory}</div>
                                  <hr />
                                </div>
                                <label htmlFor="" className="fromVisibitiText">
                                  From Google
                                </label>
                                <div>{extractedData[0].googleCategory}</div>
                              </td>
                            </tr>
                          </tbody>
                        </div>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="row row-search">
              <div className="col-lg-4 col-md-4 col-sm-6">
                {/* <div class="">
                  <input
                    autoComplete="off"
                    type="text"
                    id="hdnLocationName"
                    name="hdnLocationName"
                    autofocus="autofocus"
                    placeholder=" Enter Name/State/Location/Address"
                    onKeyUp={() => this.getLocationAutoComplete()}
                    tabindex="-1"
                    className="form-control mb-0"
                  />
                  <ul
                    className="autoSearch autoSearch-p0 appointmentNumberSearch itemNameSearch tag_autoSerach"
                    id="locationList"
                    style={{ display: "none" }}
                  >
                    {this.state.locationAuto.map((item, i) => (
                      <li
                        onClick={() =>
                          this.locationClick(
                            item.storeCode +
                              " - " +
                              item.title +
                              " - " +
                              item.locality +
                              " - " +
                              item.addressLines,
                            item.location_id
                          )
                        }
                        style={{ listStyle: "none", fontSize: "14px" }}
                      >
                        {item.storeCode +
                          " - " +
                          item.title +
                          " - " +
                          item.locality}
                        <span className="dblock_addressline">
                          ({item.addressLines})
                        </span>
                      </li>
                    ))}
                  </ul>
                  <input type="hidden" id="hdnLocationId" />
                </div> */}
                {/* <select
                  className="form-control"
                  id="state"
                  value={this.state.locationValues}
                  onChange={(e) => this.locationDetails(e.target.value)}
                >
                 
                  {this.state.accountList.map((item, idix) => (
                    <option value={item.location_id}>
                      {`${item.title} - ${item.locality}`}
                    </option>
                  ))}
                </select> */}
              </div>
              {/* <div className="col-lg-2 col-md-4 col-sm-6">
                <button 
                  className="btn-search">Search</button>
              </div> */}
            </div>
            {this.state.completeGbpList !== "" ? (
              <>
                <div className="info_location mt-4">
                  <div className="row">
                    <div className="col-md-6">
                      {/*Bussiness Name */}
                      <div className="info_inneraddress">
                        <div className="info_iconlocation">
                          <Icon.Briefcase />
                        </div>
                        <div className="info_contentlocation">
                          <p className="info_contentlocation_p">
                            <b>Business Name</b>
                          </p>
                          <p className="info_contentlocation_p" id="titleShow">
                            {this.state.completeGbpList.title}
                          </p>
                          <div className="editInfo">
                            <input
                              type="text"
                              id="titleEdit"
                              hidden
                              className="form-control"
                            />
                            <button
                              id="titleEditButton"
                              hidden
                              type="button"
                              className="btn-save button-sm"
                              onClick={() => this.UpdateGBPLocationAPI("title")}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                        <div className="info_editlocation">
                          <Icon.Edit
                            id="titleEditIcon"
                            onClick={() => this.updateGBPDetails("title")}
                          />
                          <Icon.XCircle
                            id="titleCancelEdit"
                            hidden
                            onClick={() => {
                              $("#titleCancelEdit").attr("hidden", true);
                              $("#titleEditIcon").removeAttr("hidden");
                              $("#titleShow").attr("hidden", false);
                              $("#titleEdit").attr("hidden", true);
                              $("#titleEditButton").attr("hidden", true);
                            }}
                          />
                        </div>
                      </div>
                      {/*Service Area */}
                      <div className="info_inneraddress">
                        <div className="info_iconlocation">
                          <Icon.AlignJustify />
                        </div>
                        <div className="info_contentlocation">
                          <p className="info_contentlocation_p">
                            <b>Service Area</b>
                          </p>
                          <div id="serviceAreaShow">
                            {Object.keys(this.state.completeGbpList)
                              .toString()
                              .indexOf("serviceArea") == "-1"
                              ? "NA"
                              : this.state.completeGbpList.serviceArea.places.placeInfos.map(
                                  (data, i) => (
                                    <p
                                      className="info_contentlocation_p"
                                      key={i}
                                    >
                                      {data.placeName}
                                    </p>
                                  )
                                )}
                          </div>
                          <div className="editInfo">
                            <input
                              type="text"
                              id="serviceAreaEdit"
                              hidden
                              className="form-control"
                            />
                            <button
                              id="serviceAreaEditButton"
                              hidden
                              type="button"
                              className="btn-save button-sm"
                              onClick={() =>
                                this.UpdateGBPLocationAPI("serviceArea")
                              }
                            >
                              Save
                            </button>
                          </div>
                        </div>

                        <div className="info_editlocation">
                          <Icon.Edit
                            style={{ display: "none" }}
                            id="serviceAreaEditIcon"
                            onClick={() => this.updateGBPDetails("serviceArea")}
                          />
                          <Icon.XCircle
                            id="serviceAreaCancelEdit"
                            hidden
                            onClick={() => {
                              $("#serviceAreaCancelEdit").attr("hidden", true);
                              $("#serviceAreaEditIcon").removeAttr("hidden");
                              $("#serviceAreaShow").attr("hidden", false);
                              $("#serviceAreaEdit").attr("hidden", true);
                              $("#serviceAreaEditButton").attr("hidden", true);
                            }}
                          />
                        </div>
                      </div>
                      {/* Business Address*/}
                      <div className="info_inneraddress">
                        <div className="info_iconlocation">
                          <Icon.MapPin className="service_infoicon" />
                        </div>
                        <div className="info_contentlocation">
                          <p className="info_contentlocation_p">
                            <b>Business Address</b>
                          </p>
                          <p
                            className="info_contentlocation_p"
                            id="storefrontAddressShow"
                          >
                            {Object.keys(this.state.completeGbpList)
                              .toString()
                              .indexOf("storefrontAddress") == "-1"
                              ? "NA"
                              : this.state.completeGbpList.storefrontAddress.addressLines.join(
                                  ", "
                                )}
                          </p>
                          <div className="append_inputdiv">
                            <input
                              type="text"
                              id="storefrontAddressEditAddressLinesIntial"
                              hidden
                              className="form-control"
                            />
                            <Icon.Plus
                              id="addMoreAddressLines"
                              className="service_infoicon"
                              onClick={() => this.addAddressLines()}
                              hidden
                            />
                          </div>
                          <div className="app_clonediv_link app_clone_category"></div>

                          <p>
                            <span id="storefrontAddressEditLocalityShow">
                              {Object.keys(this.state.completeGbpList)
                                .toString()
                                .indexOf("storefrontAddress") == "-1"
                                ? "NA"
                                : this.state.completeGbpList.storefrontAddress
                                    .locality}
                              ,{" "}
                            </span>
                            <span id="storefrontAddressEditAdministrativeAreaShow">
                              {Object.keys(this.state.completeGbpList)
                                .toString()
                                .indexOf("storefrontAddress") == "-1"
                                ? "NA"
                                : this.state.completeGbpList.storefrontAddress
                                    .administrativeArea}
                              ,{" "}
                            </span>
                            {/* <span id="storefrontAddressEditAdministrativeAreaShow">
                              {Object.keys(this.state.completeGbpList).toString().indexOf("storefrontAddress") == '-1' ? "NA" :
                                this.state.completeGbpList.storefrontAddress
                                  .regionCode
                              }
                              ,{" "}
                            </span> */}
                            <span id="storefrontAddressEditPostalCodeShow">
                              {Object.keys(this.state.completeGbpList)
                                .toString()
                                .indexOf("storefrontAddress") == "-1"
                                ? "NA"
                                : this.state.completeGbpList.storefrontAddress
                                    .postalCode}
                            </span>
                          </p>
                          <p className="administrative_area">
                            <input
                              type="text"
                              id="storefrontAddressEditAdministrativeAreaEdit"
                              placeholder="Enter Administrative Area"
                              hidden
                              className="form-control"
                            />
                            <input
                              type="text"
                              id="storefrontAddressEditLocalityEdit"
                              placeholder="Enter Locality"
                              hidden
                              className="form-control"
                            />
                            <input
                              type="text"
                              id="storefrontAddressEditRegionCodeEdit"
                              placeholder="Enter Region Code"
                              hidden
                              disabled
                              className="form-control"
                            />
                            <input
                              type="text"
                              id="storefrontAddressEditPostalCodeEdit"
                              placeholder="Enter Postal Code"
                              hidden
                              disabled
                              className="form-control"
                            />
                          </p>
                          <button
                            id="storefrontAddressEditButton"
                            hidden
                            type="button"
                            className="btn-save button-sm"
                            onClick={() =>
                              this.UpdateGBPLocationAPI("storefrontAddress")
                            }
                          >
                            Save
                          </button>
                        </div>
                        <div className="info_editlocation">
                          <Icon.Edit
                            id="storefrontAddressEditIcon"
                            onClick={() =>
                              this.updateGBPDetails("storefrontAddress")
                            }
                          />
                          <Icon.XCircle
                            id="storefrontAddressCancelEdit"
                            hidden
                            onClick={() => this.hideAddresDatas()}
                          />
                        </div>
                      </div>
                      {/* Business Description*/}
                      <div className="info_inneraddress">
                        <div className="info_iconlocation">
                          <Icon.Info />
                        </div>
                        <div className="info_contentlocation">
                          <p className="info_contentlocation_p">
                            <b>Business Description</b>
                          </p>
                          <p
                            className="info_contentlocation_p"
                            id="profileShow"
                          >
                            {Object.keys(this.state.completeGbpList)
                              .toString()
                              .indexOf("profile") == "-1"
                              ? "NA"
                              : this.state.completeGbpList.profile.description}
                          </p>
                          <div className="editInfo">
                            <textarea
                              id="profileEdit"
                              hidden
                              className="form-control"
                            />
                            <button
                              id="profileEditButton"
                              hidden
                              type="button"
                              className="btn-save button-sm"
                              onClick={() =>
                                this.UpdateGBPLocationAPI("profile")
                              }
                            >
                              Save
                            </button>
                          </div>
                        </div>

                        <div className="info_editlocation">
                          <Icon.Edit
                            id="profileEditIcon"
                            onClick={() => this.updateGBPDetails("profile")}
                          />
                          <Icon.XCircle
                            id="profileCancelEdit"
                            hidden
                            onClick={() => {
                              $("#profileCancelEdit").attr("hidden", true);
                              $("#profileEditIcon").removeAttr("hidden");
                              $("#profileShow").attr("hidden", false);
                              $("#profileEdit").attr("hidden", true);
                              $("#profileEditButton").attr("hidden", true);
                            }}
                          />
                        </div>
                      </div>
                      {/* Business Category*/}
                      <div className="info_inneraddress">
                        <div className="info_iconlocation">
                          <Icon.Clipboard />
                        </div>
                        <div className="info_contentlocation">
                          <p className="info_contentlocation_p">
                            <b>Business Category</b>
                          </p>

                          <p
                            className="info_contentlocation_p"
                            id="businessCategoryShowPrimary"
                          >
                            {Object.keys(this.state.completeGbpList.categories)
                              .toString()
                              .indexOf("primaryCategory") == "-1"
                              ? ""
                              : this.state.completeGbpList.categories
                                  .primaryCategory.displayName}{" "}
                            -{" "}
                            <span>
                              <b style={{ fontSize: "12px" }}>PRIMARY</b>
                            </span>
                            {Object.keys(this.state.completeGbpList.categories)
                              .toString()
                              .indexOf("additionalCategories") == "-1"
                              ? ""
                              : this.state.completeGbpList.categories.additionalCategories.map(
                                  (data, i) => <p key={i}>{data.displayName}</p>
                                )}
                          </p>
                          <label id="categoriesEditPrimary" hidden>
                            <input
                              type="text"
                              id="categoriesEditPrimaryValue"
                              placeholder="Enter Primary Category"
                              className="form-control"
                            />
                            - Primary
                          </label>
                          <div className="append_inputdiv">
                            <input
                              type="text"
                              className="form-control"
                              id="categoriesEditAdditionalInitial"
                              hidden
                            />

                            <Icon.Plus
                              id="addMoreCategoriesAdd"
                              className="service_infoicon"
                              onClick={() => this.addCategories()}
                              hidden
                            />
                          </div>

                          <div className="app_clonediv_link app_clone_category_business_categories"></div>

                          {/* <div className="editInfo"> */}
                          {/* <textarea
                              id="BusinessCategoryEdit"
                              hidden
                              className="form-control"
                            /> */}
                          <button
                            id="BusinessCategoryEditButton"
                            hidden
                            type="button"
                            className="btn-save button-sm"
                            onClick={() =>
                              this.UpdateGBPLocationAPI("BusinessCategory")
                            }
                          >
                            Save
                          </button>
                          {/* </div> */}
                        </div>

                        <div className="info_editlocation">
                          <Icon.Edit
                            id="BusinessCategoryEditIcon"
                            onClick={() =>
                              this.updateGBPDetails("BusinessCategory")
                            }
                          />
                          <Icon.XCircle
                            id="BusinessCategoryCancelEdit"
                            onClick={() => this.hideCategories()}
                            hidden
                          />
                        </div>
                      </div>
                      {/* Business Opening Date*/}
                      <div className="info_inneraddress">
                        <div className="info_iconlocation">
                          <Icon.Calendar />
                        </div>
                        <div className="info_contentlocation">
                          <p className="info_contentlocation_p">
                            <b>Business Opening Date</b>
                          </p>
                          <p
                            className="info_contentlocation_p"
                            id="openingDateShow"
                          >
                            {Object.keys(this.state.completeGbpList.openInfo)
                              .toString()
                              .indexOf("openingDate") == "-1"
                              ? "NA"
                              : this.state.completeGbpList.openInfo.openingDate
                                  .day && (
                                  <>
                                    {
                                      this.state.completeGbpList.openInfo
                                        .openingDate.day
                                    }
                                    /
                                  </>
                                )}
                            {Object.keys(this.state.completeGbpList.openInfo)
                              .toString()
                              .indexOf("openingDate") == "-1"
                              ? ""
                              : this.state.completeGbpList.openInfo.openingDate
                                  .month && (
                                  <>
                                    {
                                      this.state.completeGbpList.openInfo
                                        .openingDate.month
                                    }
                                    /
                                  </>
                                )}
                            {Object.keys(this.state.completeGbpList.openInfo)
                              .toString()
                              .indexOf("openingDate") == "-1"
                              ? ""
                              : this.state.completeGbpList.openInfo.openingDate
                                  .year && (
                                  <>
                                    {
                                      this.state.completeGbpList.openInfo
                                        .openingDate.year
                                    }
                                  </>
                                )}
                          </p>
                          <span className="businessOpenDate">
                            <input
                              id="openInfoYearEdit"
                              hidden
                              className="form-control"
                              type="text"
                              defaultValue={
                                Object.keys(this.state.completeGbpList.openInfo)
                                  .toString()
                                  .indexOf("openingDate") == "-1"
                                  ? ""
                                  : this.state.completeGbpList.openInfo
                                      .openingDate.year
                              }
                            />
                            <div className="append_inputdiv">
                              <select
                                id="openInfoMonthEdit"
                                hidden
                                className="form-control openInfoMonthEdit"
                                defaultValue={
                                  Object.keys(
                                    this.state.completeGbpList.openInfo
                                  )
                                    .toString()
                                    .indexOf("openingDate") == "-1"
                                    ? ""
                                    : this.state.completeGbpList.openInfo
                                        .openingDate.month
                                }
                                onChange={(obj) =>
                                  this.setState({
                                    openInfoMonth: obj.target.value,
                                  })
                                }
                              >
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">Septempber</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                              </select>
                              <select
                                id="openInfoDayEdit"
                                hidden
                                className="form-control"
                                defaultValue={
                                  Object.keys(
                                    this.state.completeGbpList.openInfo
                                  )
                                    .toString()
                                    .indexOf("openingDate") == "-1"
                                    ? ""
                                    : this.state.completeGbpList.openInfo
                                        .openingDate.day
                                }
                                onChange={(e) =>
                                  this.setState({ openInfoDay: e.target.value })
                                }
                              >
                                <option value=""></option>
                                {daysofThisMonth.map((data) => (
                                  <option value={data}>{data}</option>
                                ))}
                              </select>
                            </div>
                          </span>
                          <div>
                            {" "}
                            <button
                              id="openInfoSaveEditButton"
                              hidden
                              type="button"
                              className="btn-save button-sm"
                              onClick={() =>
                                this.UpdateGBPLocationAPI("openInfo")
                              }
                            >
                              Save
                            </button>
                          </div>
                        </div>
                        <div
                          style={{ display: "none" }}
                          className="info_editlocation"
                        >
                          <Icon.Edit
                            id="openInfoEditButton"
                            onClick={() => this.updateGBPDetails("openInfo")}
                          />
                          <Icon.XCircle
                            id="openInfoCancelEdit"
                            hidden
                            onClick={() => {
                              $("#openInfoEditButton").removeAttr("hidden");
                              $("#openInfoSaveEditButton").attr("hidden", true);
                              $("#openInfoCancelEdit").attr("hidden", true);
                              $("#openingDateShow").attr("hidden", false);
                              $("#openInfoMonthEdit").attr("hidden", true);
                              $("#openInfoDayEdit").attr("hidden", true);
                              $("#openInfoYearEdit").attr("hidden", true);
                            }}
                          />
                        </div>
                      </div>
                      {/* Phone*/}
                      <div className="info_inneraddress">
                        <div className="info_iconlocation">
                          <Icon.Phone />
                        </div>
                        <div className="info_contentlocation">
                          <p className="info_contentlocation_p">
                            <b>Phone</b>
                          </p>
                          <p
                            className="info_contentlocation_p"
                            id="phoneNumbersShowPrimary"
                          >
                            {Object.keys(
                              this.state.completeGbpList.phoneNumbers
                            )
                              .toString()
                              .indexOf("primaryPhone") == "-1"
                              ? "NA"
                              : this.state.completeGbpList.phoneNumbers
                                  .primaryPhone}{" "}
                            -{" "}
                            <span>
                              <b style={{ fontSize: "12px" }}>PRIMARY</b>
                            </span>
                          </p>
                          <label id="phoneNumbersEditPrimary" hidden>
                            <input
                              type="text"
                              id="phoneNumbersEditPrimaryValue"
                              placeholder="Enter Primary Phone Number"
                              className="form-control"
                            />
                            - Primary
                          </label>
                          {Object.keys(this.state.completeGbpList.phoneNumbers)
                            .toString()
                            .indexOf("additionalPhones") == "-1"
                            ? "NA"
                            : this.state.completeGbpList.phoneNumbers
                                .additionalPhones && (
                                <span id="phoneNumbersShowAdditional">
                                  {this.state.completeGbpList.phoneNumbers.additionalPhones.map(
                                    (data, i) => {
                                      return <p key={i}>{data}</p>;
                                    }
                                  )}
                                </span>
                              )}

                          <div className="append_inputdiv">
                            <input
                              type="text"
                              className="form-control"
                              id="phoneNumbersEditAdditionalInitial"
                              hidden
                            />

                            <Icon.Plus
                              id="addMorephoneNumbersAdd"
                              className="service_infoicon"
                              onClick={() => this.addPhoneNumbers()}
                              hidden
                            />
                          </div>
                          <div className="app_clonediv_link app_clone_category_phone_numbers"></div>
                          <button
                            id="phoneNumbersEditButton"
                            hidden
                            type="button"
                            className="btn-save button-sm"
                            onClick={() =>
                              this.UpdateGBPLocationAPI("phoneNumbers")
                            }
                          >
                            Save
                          </button>
                        </div>
                        <div className="info_editlocation">
                          <Icon.Edit
                            id="phoneNumbersEditIcon"
                            onClick={() =>
                              this.updateGBPDetails("phoneNumbers")
                            }
                          />
                          <Icon.XCircle
                            id="phoneNumbersCancelEdit"
                            hidden
                            onClick={() => this.hidePhoneNumbers()}
                          />
                        </div>
                      </div>
                      {/* Website*/}
                      <div className="info_inneraddress">
                        <div className="info_iconlocation">
                          <Icon.Globe />
                        </div>
                        <div className="info_contentlocation">
                          <p className="info_contentlocation_p">
                            <b>Website</b>
                          </p>
                          <p
                            className="info_contentlocation_p"
                            id="websiteUriShow"
                          >
                            {this.state.completeGbpList.websiteUri !== "NA"
                              ? this.state.completeGbpList.websiteUri
                              : ""}
                          </p>
                          <div className="editInfo">
                            <textarea
                              id="websiteUriEdit"
                              hidden
                              className="form-control"
                            ></textarea>
                            <button
                              id="websiteUriEditButton"
                              type="button"
                              hidden
                              className="btn-save button-sm"
                              onClick={() =>
                                this.UpdateGBPLocationAPI("websiteUri")
                              }
                            >
                              Save
                            </button>
                          </div>
                        </div>

                        <div className="info_editlocation">
                          <Icon.Edit
                            id="websiteUriEditIcon"
                            onClick={() => this.updateGBPDetails("websiteUri")}
                          />
                          <Icon.XCircle
                            id="websiteUriCancelEdit"
                            hidden
                            onClick={() => {
                              $("#websiteUriShow").attr("hidden", false);
                              $("#websiteUriEdit").attr("hidden", true);
                              $("#websiteUriEditButton").attr("hidden", true);
                              $("#websiteUriEditIcon").removeAttr("hidden");
                              $("#websiteUriCancelEdit").attr("hidden", true);
                            }}
                          />
                        </div>
                      </div>
                      {/* Open Hours*/}
                      <div className="info_inneraddress">
                        <div className="info_iconlocation">
                          <Icon.Clock />
                        </div>
                        <div
                          className="info_contentlocation"
                          id="workingHoursShow"
                        >
                          <p className="info_contentlocation_p">
                            <b>Open Hours</b>
                          </p>
                          {Object.keys(this.state.completeGbpList)
                            .toString()
                            .indexOf("regularHours") == "-1"
                            ? "NA"
                            : this.state.completeGbpList.regularHours.periods.map(
                                (data, i) => {
                                  let openMeridianStatus = "";
                                  let closeMeridianStatus = "";
                                  data.openTime.hours > "12"
                                    ? (openMeridianStatus = "PM")
                                    : (openMeridianStatus = "AM");
                                  data.closeTime.hours > "12"
                                    ? (closeMeridianStatus = "PM")
                                    : (closeMeridianStatus = "AM");
                                  return (
                                    <>
                                      <p
                                        key={i}
                                        className="info_dayslocation info_contentlocation_p"
                                      >
                                        {" "}
                                        {data.closeTime.hours === 24 ? (
                                          <span className="info_locationspan">
                                            {data.openDay}:&nbsp;Open 24 Hours
                                          </span>
                                        ) : (
                                          <>
                                            <span className="info_locationspan">
                                              {data.openDay}:&nbsp;
                                            </span>
                                            <span className="info_locationspan">
                                              {data.openTime.hours}:
                                              {data.openTime.minutes
                                                ? data.openTime.minutes
                                                : "00"}{" "}
                                              {openMeridianStatus} -{" "}
                                              {data.closeTime.hours}:
                                              {data.closeTime.minutes
                                                ? data.closeTime.minutes
                                                : "00"}{" "}
                                              {closeMeridianStatus}
                                            </span>
                                          </>
                                        )}
                                      </p>
                                    </>
                                  );
                                }
                              )}
                        </div>
                        <div>
                          <p
                            id="workingHoursEdit"
                            hidden
                            className="workingHoursEdit info_contentlocation_p"
                          >
                            <div className="mainWeek">
                              <div className="weekdays">
                                <span
                                  id="0DayValue"
                                  className="info_locationspan"
                                >
                                  Sunday
                                </span>
                                <label>
                                  <input
                                    className="mr-1"
                                    type="checkbox"
                                    name=""
                                    id="0TimeCheck"
                                    onChange={(e) =>
                                      e.target.checked === true
                                        ? $("#0Fromtime, #0Totime")
                                            .closest("div")
                                            .attr("hidden", true)
                                        : $("#0Fromtime, #0Totime")
                                            .closest("div")
                                            .attr("hidden", false)
                                    }
                                  />
                                  Closed
                                </label>
                              </div>
                              <span className="info_locationspan">
                                <div class="did-floating-label-content">
                                  <select
                                    id="0Fromtime"
                                    className="did-floating-select"
                                  >
                                    {timeList.map((data) => (
                                      <option value={data}>{data}</option>
                                    ))}
                                  </select>
                                  <label class="did-floating-label">
                                    Opens At
                                  </label>
                                </div>
                                <div class="did-floating-label-content">
                                  <select
                                    id="0Totime"
                                    className="did-floating-select"
                                  >
                                    {timeList.map((data) => (
                                      <option>{data}</option>
                                    ))}
                                  </select>
                                  <label class="did-floating-label">
                                    Closes At
                                  </label>
                                </div>
                              </span>
                            </div>
                            <div className="mainWeek">
                              <div className="weekdays">
                                <span
                                  id="1DayValue"
                                  className="info_locationspan"
                                >
                                  Monday
                                </span>
                                <label>
                                  <input
                                    className="mr-1"
                                    type="checkbox"
                                    name=""
                                    id="1TimeCheck"
                                    onChange={(e) =>
                                      e.target.checked === true
                                        ? $("#1Fromtime, #1Totime")
                                            .closest("div")
                                            .attr("hidden", true)
                                        : $("#1Fromtime, #1Totime")
                                            .closest("div")
                                            .attr("hidden", false)
                                    }
                                  />
                                  Closed
                                </label>
                              </div>

                              <span className="info_locationspan">
                                <div class="did-floating-label-content">
                                  <select
                                    id="1Fromtime"
                                    className="did-floating-select"
                                  >
                                    {timeList.map((data) => (
                                      <option value={data}>{data}</option>
                                    ))}
                                  </select>
                                  <label class="did-floating-label">
                                    Opens At
                                  </label>
                                </div>
                                <div class="did-floating-label-content">
                                  <select
                                    id="1Totime"
                                    className="did-floating-select"
                                  >
                                    {timeList.map((data) => (
                                      <option>{data}</option>
                                    ))}
                                  </select>
                                  <label class="did-floating-label">
                                    Closes At
                                  </label>
                                </div>
                              </span>
                            </div>

                            <div className="mainWeek">
                              <div className="weekdays">
                                <span
                                  id="2DayValue"
                                  className="info_locationspan"
                                >
                                  Tuesday
                                </span>
                                <label>
                                  <input
                                    className="mr-1"
                                    type="checkbox"
                                    name=""
                                    id="2TimeCheck"
                                    onChange={(e) =>
                                      e.target.checked === true
                                        ? $("#2Fromtime, #2Totime")
                                            .closest("div")
                                            .attr("hidden", true)
                                        : $("#2Fromtime, #2Totime")
                                            .closest("div")
                                            .attr("hidden", false)
                                    }
                                  />
                                  Closed
                                </label>
                              </div>

                              <span className="info_locationspan">
                                <div class="did-floating-label-content">
                                  <select
                                    id="2Fromtime"
                                    className="did-floating-select"
                                  >
                                    {timeList.map((data) => (
                                      <option value={data}>{data}</option>
                                    ))}
                                  </select>
                                  <label class="did-floating-label">
                                    Opens At
                                  </label>
                                </div>
                                <div class="did-floating-label-content">
                                  <select
                                    id="2Totime"
                                    className="did-floating-select"
                                  >
                                    {timeList.map((data) => (
                                      <option>{data}</option>
                                    ))}
                                  </select>
                                  <label class="did-floating-label">
                                    Closes At
                                  </label>
                                </div>
                              </span>
                            </div>
                            <div className="mainWeek">
                              <div className="weekdays">
                                <span
                                  id="3DayValue"
                                  className="info_locationspan"
                                >
                                  Wednesday
                                </span>
                                <label>
                                  <input
                                    className="mr-1"
                                    type="checkbox"
                                    name=""
                                    id="3TimeCheck"
                                    onChange={(e) =>
                                      e.target.checked === true
                                        ? $("#3Fromtime, #3Totime")
                                            .closest("div")
                                            .attr("hidden", true)
                                        : $("#3Fromtime, #3Totime")
                                            .closest("div")
                                            .attr("hidden", false)
                                    }
                                  />
                                  Closed
                                </label>
                              </div>
                              <span className="info_locationspan">
                                <div class="did-floating-label-content">
                                  <select
                                    id="3Fromtime"
                                    className="did-floating-select"
                                  >
                                    {timeList.map((data) => (
                                      <option value={data}>{data}</option>
                                    ))}
                                  </select>
                                  <label class="did-floating-label">
                                    Opens At
                                  </label>
                                </div>
                                <div class="did-floating-label-content">
                                  <select
                                    id="3Totime"
                                    className="did-floating-select"
                                  >
                                    {timeList.map((data) => (
                                      <option>{data}</option>
                                    ))}
                                  </select>
                                  <label class="did-floating-label">
                                    Closes At
                                  </label>
                                </div>
                              </span>
                            </div>
                            <div className="mainWeek">
                              <div className="weekdays">
                                <span
                                  id="4DayValue"
                                  className="info_locationspan"
                                >
                                  Thursday
                                </span>
                                <label>
                                  <input
                                    className="mr-1"
                                    type="checkbox"
                                    name=""
                                    id="4TimeCheck"
                                    onChange={(e) =>
                                      e.target.checked === true
                                        ? $("#4Fromtime, #4Totime")
                                            .closest("div")
                                            .attr("hidden", true)
                                        : $("#4Fromtime, #4Totime")
                                            .closest("div")
                                            .attr("hidden", false)
                                    }
                                  />
                                  Closed
                                </label>
                              </div>
                              <span className="info_locationspan">
                                <div class="did-floating-label-content">
                                  <select
                                    id="4Fromtime"
                                    className="did-floating-select"
                                  >
                                    {timeList.map((data) => (
                                      <option value={data}>{data}</option>
                                    ))}
                                  </select>
                                  <label class="did-floating-label">
                                    Opens At
                                  </label>
                                </div>
                                <div class="did-floating-label-content">
                                  <select
                                    id="4Totime"
                                    className="did-floating-select"
                                  >
                                    {timeList.map((data) => (
                                      <option>{data}</option>
                                    ))}
                                  </select>
                                  <label class="did-floating-label">
                                    Closes At
                                  </label>
                                </div>
                              </span>
                            </div>
                            <div className="mainWeek">
                              <div className="weekdays">
                                <span
                                  id="5DayValue"
                                  className="info_locationspan"
                                >
                                  Friday
                                </span>
                                <label>
                                  <input
                                    className="mr-1"
                                    type="checkbox"
                                    name=""
                                    id="5TimeCheck"
                                    onChange={(e) =>
                                      e.target.checked === true
                                        ? $("#5Fromtime, #5Totime")
                                            .closest("div")
                                            .attr("hidden", true)
                                        : $("#5Fromtime, #5Totime")
                                            .closest("div")
                                            .attr("hidden", false)
                                    }
                                  />
                                  Closed
                                </label>
                              </div>
                              <span className="info_locationspan">
                                <div class="did-floating-label-content">
                                  <select
                                    id="5Fromtime"
                                    className="did-floating-select"
                                  >
                                    {timeList.map((data) => (
                                      <option value={data}>{data}</option>
                                    ))}
                                  </select>
                                  <label class="did-floating-label">
                                    Opens At
                                  </label>
                                </div>
                                <div class="did-floating-label-content">
                                  <select
                                    id="5Totime"
                                    className="did-floating-select"
                                  >
                                    {timeList.map((data) => (
                                      <option>{data}</option>
                                    ))}
                                  </select>
                                  <label class="did-floating-label">
                                    Closes At
                                  </label>
                                </div>
                              </span>
                            </div>
                            <div className="mainWeek">
                              <div className="weekdays">
                                <span
                                  id="6DayValue"
                                  className="info_locationspan"
                                >
                                  Saturday
                                </span>
                                <label>
                                  <input
                                    className="mr-1"
                                    type="checkbox"
                                    name=""
                                    id="6TimeCheck"
                                    onChange={(e) =>
                                      e.target.checked === true
                                        ? $("#6Fromtime, #6Totime")
                                            .closest("div")
                                            .attr("hidden", true)
                                        : $("#6Fromtime, #6Totime")
                                            .closest("div")
                                            .attr("hidden", false)
                                    }
                                  />
                                  Closed
                                </label>
                              </div>
                              <span className="info_locationspan">
                                <div class="did-floating-label-content">
                                  <select
                                    id="6Fromtime"
                                    className="did-floating-select"
                                  >
                                    {timeList.map((data) => (
                                      <option value={data}>{data}</option>
                                    ))}
                                  </select>
                                  <label class="did-floating-label">
                                    Opens At
                                  </label>
                                </div>
                                <div class="did-floating-label-content">
                                  <select
                                    id="6Totime"
                                    className="did-floating-select"
                                  >
                                    {timeList.map((data) => (
                                      <option>{data}</option>
                                    ))}
                                  </select>
                                  <label class="did-floating-label">
                                    Closes At
                                  </label>
                                </div>
                              </span>
                            </div>
                          </p>
                          <button
                            id="workingHoursEditButton"
                            hidden
                            type="button"
                            className="btn-save button-sm"
                            onClick={() =>
                              this.UpdateGBPLocationAPI("regularHours")
                            }
                          >
                            Save
                          </button>
                        </div>
                        <div className="info_editlocation">
                          <Icon.Edit
                            id="regularHoursEditIcon"
                            onClick={() =>
                              this.updateGBPDetails("regularHours")
                            }
                          />
                          <Icon.XCircle
                            id="regularHoursCancelEdit"
                            hidden
                            onClick={() => {
                              $("#workingHoursShow").attr("hidden", false);
                              $("#workingHoursEdit").attr("hidden", true);
                              $("#regularHoursEditIcon").removeAttr("hidden");
                              $("#regularHoursCancelEdit").attr("hidden", true);
                              $("#workingHoursEditButton").attr("hidden", true);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <>
                        <div className="info_inneraddress">
                          <div className="info_iconlocation">
                            <Icon.MapPin />
                          </div>
                          <div className="info_contentlocation">
                            <p className="info_contentlocation_p">
                              <b>Business Location on Maps </b>
                            </p>
                            <p
                              className="info_contentlocation_p"
                              id="LocationMapsShow"
                            >
                              {Object.keys(this.state.completeGbpList)
                                .toString()
                                .indexOf("latlng") == "-1" ? (
                                "NA"
                              ) : (
                                <GoogleMap
                                  longitude={
                                    this.state.completeGbpList.latlng.longitude
                                  }
                                  latitude={
                                    this.state.completeGbpList.latlng.latitude
                                  }
                                />
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="info_inneraddress">
                          <div className="info_iconlocation">
                            <Icon.Tool />
                          </div>
                          <div className="info_contentlocation">
                            <p className="info_contentlocation_p">
                              <b>Services</b>
                            </p>
                            {Object.keys(
                              this.state.completeGbpList.categories
                                .primaryCategory
                            )
                              .toString()
                              .indexOf("serviceTypes") == "-1"
                              ? "NA"
                              : this.state.completeGbpList.categories.primaryCategory.serviceTypes.map(
                                  (data, i) => (
                                    <p
                                      className="info_contentlocation_p"
                                      id="ServicesShow"
                                      key={i}
                                    >
                                      {data.displayName}
                                    </p>
                                  )
                                )}

                            <div className="editInfo">
                              <textarea
                                id="ServicesEdit"
                                hidden
                                className="form-control"
                              ></textarea>
                              <button
                                id="ServicesEditButton"
                                type="button"
                                hidden
                                className="btn-save button-sm"
                                onClick={() =>
                                  this.UpdateGBPLocationAPI("Services")
                                }
                              >
                                Save
                              </button>
                            </div>
                          </div>

                          <div className="info_editlocation">
                            <Icon.Edit
                              hidden
                              id="ServicesEditIcon"
                              onClick={() => this.updateGBPDetails("Services")}
                            />
                            <Icon.XCircle
                              id="ServicesCancelEdit"
                              hidden
                              onClick={() => {
                                $("#ServicesShow").attr("hidden", false);
                                $("#ServicesEdit").attr("hidden", true);
                                $("#ServicesEditButton").attr("hidden", true);
                                $("#ServicesEditIcon").removeAttr("hidden");
                                $("#ServicesCancelEdit").attr("hidden", true);
                              }}
                            />
                          </div>
                        </div>
                        {/* <div className="info_inneraddress">
                        <div className="info_iconlocation">
                          <Icon.Camera />
                        </div>
                        <div className="info_contentlocation">
                          <div className="right_google_popup">
                            <label>
                              <Icon.Camera />
                              Add photos
                              <input
                                type="file"
                                multiple
                                id="imageUpload"
                                onChange={(e) =>
                                  this.handleImageUpload(e.target.files)
                                }
                              />
                            </label>
                          </div>
                          <div className="editInfo">
                            <select id="categoryDropdown">
                            
                              <option value="CATEGORY_UNSPECIFIED">
                                General
                              </option>
                              <option value="LOGO">Logo</option>
                              <option value="EXTERIOR">Exterior</option>
                              <option value="INTERIOR">Interior</option>
                              <option value="PRODUCT">Product</option>
                              <option value="AT_WORK">At Work</option>
                              <option value="FOOD_AND_DRINK">Food & Drink</option>
                              <option value="MENU">Menu</option>
                              <option value="COMMON_AREA">Common Area</option>
                              <option value="ROOMS">Rooms</option>
                              <option value="TEAMS">Teams</option>
                              <option value="ADDITIONAL">Additional</option>
                            </select>
                            <select id="formatTypeDropdown">
                              <option value="PHOTO">Photo</option>
                              <option value="VIDEO">Video</option>
                            </select>
                            <button
                              id="websiteUriEditButton"
                              type="button"
                              className="btn-save button-sm"
                              onClick={() => this.postPhotoToGBP()}
                            >
                              Save
                            </button>
                          </div>
                        </div>

                        {this.state.imageUrlListStatus && this.imageListing()}
                        
                      </div> */}
                        <div className="info_inneraddress">
                          <div className="info_iconlocation">
                            <Icon.Watch />
                          </div>
                          <div className="info_contentlocation">
                            <p className="info_contentlocation_p">
                              <b>More Hours</b>
                            </p>
                            <p
                              className="info_contentlocation_p"
                              id="AppointmentShow"
                            >
                              {Object.keys(this.state.completeGbpList)
                                .toString()
                                .indexOf("morehours") == "-1"
                                ? "NA"
                                : this.state.completeGbpList.morehours}
                            </p>
                          </div>
                        </div>
                        <div className="info_inneraddress">
                          <div className="info_iconlocation">
                            <Icon.ExternalLink />
                          </div>
                          <div className="info_contentlocation">
                            <p className="info_contentlocation_p">
                              <b>Appointment Link</b>
                            </p>
                            <p className="info_contentlocation_p">
                              {Object.keys(this.state.completeGbpList)
                                .toString()
                                .indexOf("AppointmentLink") == "-1"
                                ? "NA"
                                : this.state.completeGbpList.AppointmentLink}
                            </p>
                            <div className="editInfo">
                              <textarea
                                hidden
                                className="form-control"
                              ></textarea>
                              <button
                                type="button"
                                hidden
                                className="btn-save button-sm"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="info_inneraddress">
                          <div className="info_iconlocation">
                            <Icon.Box />
                          </div>
                          <div className="info_contentlocation">
                            <p className="info_contentlocation_p">
                              <b>Products</b>
                            </p>
                            <p
                              className="info_contentlocation_p"
                              id="ProductsShow"
                            >
                              {Object.keys(this.state.completeGbpList)
                                .toString()
                                .indexOf("addEditProducts") == "-1"
                                ? "NA"
                                : this.state.completeGbpList.addEditProducts}
                            </p>
                            <div className="editInfo">
                              <textarea
                                id="ProductsEdit"
                                hidden
                                className="form-control"
                              ></textarea>
                              <button
                                id="ProductsEditButton"
                                type="button"
                                hidden
                                className="btn-save button-sm"
                                onClick={() =>
                                  this.UpdateGBPLocationAPI("Products")
                                }
                              >
                                Save
                              </button>
                            </div>
                          </div>

                          <div className="info_editlocation">
                            <Icon.Edit
                              hidden
                              id="ProductsEditIcon"
                              onClick={() => this.updateGBPDetails("Products")}
                            />
                            <Icon.XCircle
                              id="ProductsCancelEdit"
                              hidden
                              onClick={() => {
                                $("#ProductsShow").attr("hidden", false);
                                $("#ProductsEdit").attr("hidden", true);
                                $("#ProductsEditButton").attr("hidden", true);
                                $("#ProductsEditIcon").removeAttr("hidden");
                                $("#ProductsCancelEdit").attr("hidden", true);
                              }}
                            />
                          </div>
                        </div>
                        <div className="info_inneraddress">
                          <div className="info_iconlocation">
                            <Icon.Code />
                          </div>
                          <div className="info_contentlocation">
                            <p className="info_contentlocation_p">
                              <b>Store Code </b>
                            </p>
                            <p
                              className="info_contentlocation_p"
                              id="StoreCodeShow"
                            >
                              {Object.keys(this.state.completeGbpList)
                                .toString()
                                .indexOf("storeCode") == "-1"
                                ? "NA"
                                : this.state.completeGbpList.storeCode}
                            </p>
                            <div className="editInfo">
                              <input
                                type="text"
                                id="StoreCodeEdit"
                                hidden
                                className="form-control"
                              />
                              <button
                                id="StoreCodeEditButton"
                                hidden
                                type="button"
                                className="btn-save button-sm"
                                onClick={() =>
                                  this.UpdateGBPLocationAPI("StoreCode")
                                }
                              >
                                Save
                              </button>
                            </div>
                          </div>
                          {/* <div className="editInfo">
                            <input
                              type="text"
                              id="StoreCodeEdit"
                              hidden
                              className="form-control"
                            />
                            <button
                              id="StoreCodeEditButton"
                              hidden
                              type="button"
                              className="btn-save button-sm"
                              onClick={() => this.UpdateGBPLocationAPI("StoreCode")}
                            >
                              Save
                            </button>
                          </div> */}
                          <div className="info_editlocation">
                            <Icon.Edit
                              id="StoreCodeEditIcon"
                              onClick={() => this.updateGBPDetails("StoreCode")}
                            />
                            <Icon.XCircle
                              id="StoreCodeCancelEdit"
                              hidden
                              onClick={() => {
                                $("#StoreCodeShow").attr("hidden", false);
                                $("#StoreCodeEdit").attr("hidden", true);
                                $("#StoreCodeEditButton").attr("hidden", true);
                                $("#StoreCodeEditIcon").removeAttr("hidden");
                                $("#StoreCodeCancelEdit").attr("hidden", true);
                              }}
                            />
                          </div>
                        </div>
                        <div className="info_inneraddress">
                          <div className="info_iconlocation">
                            <Icon.Camera />
                          </div>
                          <div className="info_contentlocation">
                            <p className="info_contentlocation_p">
                              <b>Photos</b>
                            </p>
                            <p
                              className="info_contentlocation_p"
                              id="PhotosShow"
                            >
                              {this.state.locationMedia.length == 0 ? (
                                "NA"
                              ) : (
                                <div className="tag_imgWrap">
                                  <img
                                    src={this.state.locationMedia[0].googleUrl}
                                  />
                                </div>
                              )}
                            </p>
                          </div>
                          {/* <div className="editInfo">
                            <input
                              type="text"
                              id="StoreCodeEdit"
                              hidden
                              className="form-control"
                            />
                            <button
                              id="StoreCodeEditButton"
                              hidden
                              type="button"
                              className="btn-save button-sm"
                              onClick={() => this.UpdateGBPLocationAPI("StoreCode")}
                            >
                              Save
                            </button>
                          </div> */}
                          <div className="info_editlocation">
                            <Icon.ArrowRight
                              id="StoreCodeEditIcon"
                              onClick={() => {
                                window.sessionStorage.setItem(
                                  "fromGBP",
                                  this.state.completeGbpList.name
                                    .split("/")
                                    .pop()
                                );
                                window.location.href = "/GBPPhotos";
                              }}
                            />
                          </div>
                        </div>

                        {/* yes or no question not available now thats why below code get commended ----- start Here */}

                        {/* <div className="info_inneraddress">
                          <div className="info_iconlocation">
                            <Icon.Archive />
                          </div>
                          <div className="info_contentlocation">
                            <p className="info_contentlocation_p">
                              <b>From the business</b>
                            </p>
                            <p lassName="info_contentlocation_p">
                              {Object.keys(this.state.completeGbpList)
                                .toString()
                                .indexOf("business") == "-1"
                                ? "NA"
                                : this.state.completeGbpList.business}
                            </p>
                          </div>
                        </div> */}

                        {/* <div className="info_inneraddress">
                          <div className="info_iconlocation">
                            <Icon.Server />
                          </div>
                          <div className="info_contentlocation">
                            <p className="info_contentlocation_p">
                              <b>Accessibility</b>
                            </p>
                            <p className="info_contentlocation_p">
                              {Object.keys(this.state.completeGbpList)
                                .toString()
                                .indexOf("Accessibility") == "-1"
                                ? "NA"
                                : this.state.completeGbpList.Accessibility}
                            </p>
                          </div>
                        </div> */}

                        {/* <div className="info_inneraddress">
                          <div className="info_iconlocation">
                            <Icon.Home />
                          </div>
                          <div className="info_contentlocation">
                            <p className="info_contentlocation_p">
                              <b>Amenities</b>
                            </p>
                            <p className="info_contentlocation_p">
                              {Object.keys(this.state.completeGbpList)
                                .toString()
                                .indexOf("Amenities") == "-1"
                                ? "NA"
                                : this.state.completeGbpList.Amenities}
                            </p>
                          </div>
                        </div> */}

                        {/* <div className="info_inneraddress">
                          <div className="info_iconlocation">
                            <Icon.Users />
                          </div>
                          <div className="info_contentlocation">
                            <p className="info_contentlocation_p">
                              <b>Crowd</b>
                            </p>
                            <p className="info_contentlocation_p">
                              {Object.keys(this.state.completeGbpList)
                                .toString()
                                .indexOf("Crowd") == "-1"
                                ? "NA"
                                : this.state.completeGbpList.Crowd}
                            </p>
                          </div>
                        </div> */}

                        {/* <div className="info_inneraddress">
                          <div className="info_iconlocation">
                            <Icon.List />
                          </div>
                          <div className="info_contentlocation">
                            <p className="info_contentlocation_p">
                              <b>Planning</b>
                            </p>
                            <p className="info_contentlocation_p">
                              {Object.keys(this.state.completeGbpList)
                                .toString()
                                .indexOf("Planning") == "-1"
                                ? "NA"
                                : this.state.completeGbpList.Planning}
                            </p>
                          </div>
                        </div> */}

                        {/* <div className="info_inneraddress">
                          <div className="info_iconlocation">
                            <Icon.Layers />
                          </div>
                          <div className="info_contentlocation">
                            <p className="info_contentlocation_p">
                              <b>Service Options </b>
                            </p>
                            <p className="info_contentlocation_p">
                              {Object.keys(this.state.completeGbpList)
                                .toString()
                                .indexOf("ServiceOptions") == "-1"
                                ? "NA"
                                : this.state.completeGbpList.ServiceOptions}
                            </p>
                          </div>
                        </div> */}

                        {/* yes or no question not available now thats why below code get commended ----- ENDS Here */}
                      </>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}

export default Info;
