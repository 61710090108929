import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import Pagination from "react-js-pagination";
import { backendApi, pythonBackendApi } from "../apiService";
import * as XLSX from "xlsx";
import $ from "jquery";
import exportFromJSON from "export-from-json";
import DragDropFile from "./dragDropFile";
import DataInput from "./dataInput";
import { Link } from "react-router-dom";
import moment from "moment";
import * as Icon from "react-feather";
import { TabList } from "react-tabs";
const excelMime = [
  "application/vnd.ms-excel",
  "application/msexcel",
  "application/x-msexcel",
  "application/x-ms-excel",
  "application/x-excel",
  "application/x-dos_ms_excel",
  "application/xls",
  "application/x-xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
// export default function MasterData() {
class MasterData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataList: [],
      uploaded: false,
      excelData: [],
      outTableList: [],
      count: "",
      activePage: 1,
      pageOffset: 0,
      totalCnt: "",
      mergeId: [],
      finalArray: [],
      idSet: [],
      // organizationList: [],
    };
    this.handleFile = this.handleFile.bind(this);
  }
  componentDidMount() {
    this.setState({
      activePage: Number(
        window.sessionStorage.getItem("pageNumberDrDetailsMasterData")
      ),
    });
    this.getListData();
    $("#moveButton").hide();
    $("#nodata").hide();
  }
  getListData = (type) => {
    const req = {
      functionName: "getAuditMaster",
      search: "",
      offset: window.sessionStorage.getItem("offsetDrDetailsMasterData"),
      startId: "",
      endId: "",
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        let array = [];
        for (let i = 0; i < data.data.length; i++) {
          array.push({
            id: data.data[i].id,
            search_Criteria: data.data[i].search_Criteria,
            created_by: data.data[i].created_by,
            checked: this.state.mergeId[i] == data.data[i].id ? true : false,
          });
        }
        this.setState({
          dataList: data.data,
          finalArray: array,
          count: data.totalCount[0].totalCount,
          totalCnt: data.totalCount[0].totalCount,
        });
      }
    });
  };
  textAreachange = (i) => {
    $("#searchaddress" + i).hide();
    $("#searchAddressvalue" + i).attr("hidden", false);
    $("#button" + i).attr("hidden", false);
    $("#titleEditIcon" + i).attr("hidden", true);
    $("#titleCancelEdit" + i).removeAttr("hidden");
  };
  saveforAddress(i, search, id, createdBy) {
    if ($("#searchAddressvalue" + i).val() === "") {
      alert("Search Criteria cannot be empty");
      return false;
    }
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "updateAuditMasterData",
      master_id: id,
      search_Criteria:
        search == "" ? search : $("#searchAddressvalue" + i).val(),
      created_by:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };
    backendApi(req).then((data) => {
      if (data.success === "1") {
        $("#saveSuccess" + i).css("display", "block");
        setTimeout(() => {
          $("#saveSuccess" + i).css("display", "none");
        }, 1500);
        $(".loder_div").attr("hidden", true);
        $("#reprocessModal").attr("hidden", true);
        this.getListData();
        $("#searchAddressvalue" + i).attr("hidden", true);
        $("#button" + i).attr("hidden", true);
        $("#titleCancelEdit" + i).attr("hidden", true);
        $("#titleEditIcon" + i).attr("hidden", false);
        $("#searchaddress" + i).show();
        $("#titleEditIcon" + i).removeAttr("hidden");
      } else {
        alert("Something went wrong while updating Search Criteria");
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  OutTable = () => {
    return (
      <div className="col-md-12 tableResponsive">
        <table className="table fileupload-table">
          <thead>
            <tr>
              <th className="col-9">SearchCriteria</th>
              <th className="col-3">CreatedOn</th>
              {/* <th>Address</th>
                                <th>Search</th> */}
            </tr>
          </thead>
          <div className="table_Outerscroll">
            {this.state.outTableList.map((r, i) => (
              <tbody key={i} className="table_Outertbody">
                <tr>
                  <td className="col-9">{r.search_Criteria}</td>
                  <td className="col-3">{r.created_on}</td>
                  {/* <td>{r.Address}</td>
                                    <td>{r.Search}</td> */}
                </tr>
              </tbody>
            ))}
          </div>
        </table>
        <div className="button-wrapper mt-0 mb-4 text-right">
          <button
            className="btn-submit ml-3"
            onClick={() => this.saveAndStartUpload()}
          >
            Upload
          </button>
          <button
            className="btn-cancel ml-3"
            onClick={() => {
              window.location.reload();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };
  async handleFile(files, e) {
    if (files) {
      if (!excelMime.includes(files.type)) {
        alert("Please upload valid excel file");
        $(".loder_div").attr("hidden", true);
        return false;
      }
      $(".loder_div").attr("hidden", false);
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          isLoader: 0,
        });
        /* Parse data */
        const ab = e.target.result;
        const wb = XLSX.read(ab, { type: "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { raw: false, header: 1 });
        if (data[0][0] !== "Sl Number" && data[0][1] !== "Search Criteria") {
          alert("The Header Fields of Excel data is incorrect");
          $(".loder_div").attr("hidden", true);
          return false;
        }
        let DataList = [];
        let DataListTemp = [];
        let keys = [];
        keys = data[0];
        let record = {};
        var pushData = [];
        var newDate = moment(new Date()).format("DD-MM-YYYY");
        for (let i = 1; i < data.length; i++) {
          pushData.push({
            search_Criteria: data[i][1],
            created_by: window.sessionStorage.getItem("userId"),
            created_on: newDate,
          });
        }
        for (let i = 1; i < data.length; i++) {
          let dd = data[i];
          if (dd.length > 0) {
            for (let j = 0; j < dd.length; j++) {
              record[keys[j].trim().split(" ").join("")] =
                dd[j] === undefined ? "" : dd[j].toString().trim();
            }
          }
        }
        this.setState({
          outTableList: pushData,
        });
        $(".loder_div").attr("hidden", true);
        /* Update state */
        this.setState(
          {
            // dataList: DataList,
            fileName: files.name,
            uploaded: true,
            isLoader: 1,
          },
          () => {
            if (DataListTemp.length !== DataList.length) {
              alert("File contains duplicate record(s)");
            }
          }
        );
      };
      reader.readAsArrayBuffer(files);
    }
  }
  downloadTemplate() {
    let header = ["Sl Number", "Search Criteria"];
    let data = [];
    let date =
      "Master Data" +
      moment(new Date())
        .format("DD-MM-YYYY hh:mm:ss a")
        .toString()
        .replaceAll("-", "_")
        .replaceAll(" ", "_")
        .replaceAll(":", "_");
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, data);
    const successFileName = "final-excel";
    XLSX.utils.sheet_add_aoa(data, [header], { origin: "A1" });
    XLSX.writeFile(workbook, date + ".xlsx", {
      compression: true,
    });
  }
  saveAndStartUpload() {
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "saveAuditMaster",
      dataList: this.state.outTableList,
    };
    $("#editCommentPopup").attr("hidden", true);
    backendApi(req).then((data) => {
      if (data.success === "1") {
        alert("Uploaded Successfully");
        window.location.reload();
      } else {
        alert("Something went wrong in uploading file");
      }
    });
  }
  saveSearchCriteria = () => {
    if ($("#searchCriteriaInput").val() === "") {
      alert("Search Criteria cannot be empty");
      return false;
    }
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "auditMastereditUpdate",
      master_id: $("#dataId").text(),
      searchcriteria: $("#searchCriteriaInput").val(),
    };
    backendApi(req).then((data) => {
      if (data.success === "1") {
        alert("Search Criteria Changed Successfully");
        this.getListData();
        $(".loder_div").attr("hidden", true);
        $("#reprocessModal").attr("hidden", true);
      } else {
        alert("Something went wrong while updating Search Criteria");
        this.getListData();
        $(".loder_div").attr("hidden", true);
      }
    });
  };
  deleteAuditMaster = (idForDelete) => {
    if (!window.confirm("Do you want to Delete Data?")) {
      return false;
    }
    var req = {
      functionName: "auditMasterDelete",
      master_id: idForDelete,
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        alert("Deleted Successfully");
        this.getListData();
      } else {
        $(".loder_div").attr("hidden", true);
        alert("Something went wrong in Deleting the Data");
        this.getListData();
      }
    });
  };
  deleteAuditMasterFulldata = () => {
    if (!window.confirm("Do you want to Delete All Data?")) {
      return false;
    }
    var req = {
      functionName: "deleteAuditmaster",
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        alert("Deleted Successfully");
        this.getListData();
      } else {
        $(".loder_div").attr("hidden", true);
        alert("Something went wrong in Deleting the Data");
        this.getListData();
      }
    });
  };
  handlePageChange = (pageNumber) => {
    let fromdata = $("#fromData").val();
    let todata = $("#toData").val();
    this.setState({
      activePage: pageNumber,
    });
    var offset;
    if (pageNumber === 1) {
      offset = "0";
    } else {
      offset = parseInt(pageNumber - 1) * 25;
    }
    this.setState({
      activePage: pageNumber,
      pageOffset: offset,
    });
    let PostData = {
      functionName: "getAuditMaster",
      search: "",
      offset: offset.toString(),
      startId: fromdata == "" ? "1" : fromdata,
      endId: todata == "" ? "500000" : todata,
    };
    window.sessionStorage.setItem("pageNumberDrDetailsMasterData", pageNumber);
    window.sessionStorage.setItem("offsetDrDetailsMasterData", offset);
    backendApi(PostData).then((data) => {
      for (let i = 0; i < this.state.dataList.length; i++) {
        $("#radiotf" + this.state.dataList[i].id).prop("checked", false);
        $("#searchAddressvalue" + this.state.dataList[i].id).attr(
          "hidden",
          true
        );
        $("#button" + this.state.dataList[i].id).attr("hidden", true);
        $("#titleCancelEdit" + this.state.dataList[i].id).attr("hidden", true);
        $("#titleEditIcon" + this.state.dataList[i].id).attr("hidden", false);
        $("#titleEditIcon" + this.state.dataList[i].id).removeAttr("hidden");
        $("#searchaddress" + this.state.dataList[i].id).show();
        // $("#selectLocationPost" + i).prop("checked", false)
      }
      let array = [];
      if (data.success === "1") {
        let idsChecked = this.state.idSet;
        let flag = false;
        for (let i = 0; i < data.data.length; i++) {
          if (idsChecked.includes(data.data[i].id)) {
            flag = true;
          } else {
            flag = false;
          }
          array.push({
            id: data.data[i].id,
            search_Criteria: data.data[i].search_Criteria,
            created_by: data.data[i].created_by,
            checked: flag,
          });
        }
        this.setState({
          dataList: data.data,
          finalArray: array,
          count: this.state.count,
        });
      } else {
        this.setState({
          dataList: [],
        });
      }
    });
  };
  clearFilters() {
    // $(".loder_div").attr("hidden", false);
    // setTimeout(() => {
    //   $(".loder_div").attr("hidden", true);
    // }, 500);
    $("#searchAddress").val("");
    $("#fromData").val("");
    $("#toData").val("");
    window.sessionStorage.setItem("offsetDrDetailsMasterData", 0);
    this.setState({
      activePage: 1,
      mergeId: [],
    });
    this.getListData();
    for (let i = 0; i < this.state.dataList.length; i++) {
      $("#selectLocationPost" + this.state.dataList[i].id).prop(
        "checked",
        false
      );
      $("#radiotf" + this.state.dataList[i].id).prop("checked", false);
      $("#searchAddressvalue" + this.state.dataList[i].id).attr("hidden", true);
      $("#button" + this.state.dataList[i].id).attr("hidden", true);
      $("#titleCancelEdit" + this.state.dataList[i].id).attr("hidden", true);
      $("#titleEditIcon" + this.state.dataList[i].id).attr("hidden", false);
      $("#titleEditIcon" + this.state.dataList[i].id).removeAttr("hidden");
      $("#searchaddress" + this.state.dataList[i].id).show();
      $("#moveButton").hide();
    }
  }
  handleSort() {
    $("#nodata").show();
    this.setState({
      activePage: 1,
    });
    var flagvalue = true;
    let fromdata = $("#fromData").val();
    let todata = $("#toData").val();
    let dropdowndata = $("#statusValue").find("option:selected").val();
    let fromdatavalue = "";
    let todatavalue = "";
    if (todata === "") {
      todata = 500000;
    }
    if (fromdata === "") {
      fromdata = 1;
    }
    if (todata < fromdata) {
      alert("Please Provide Valid ID");
      flagvalue = false;
    } else if (fromdata !== "" && todata !== "") {
      fromdatavalue = fromdata;
      todatavalue = todata;
      flagvalue = true;
    }
    if (flagvalue == true) {
      $(".loder_div").attr("hidden", false);
      var req = {
        functionName: "getAuditMaster",
        search:
          $("#searchAddress").val() === " " ? "" : $("#searchAddress").val(),
        offset: "",
        startId: fromdatavalue,
        endId: todatavalue,
      };
      backendApi(req).then((data) => {
        if (data.success === "1") {
          this.setState({
            finalArray: data.data,
            dataList: data.data,
            count: data.totalCnt[0].totalCnt,
          });
          $(".loder_div").attr("hidden", true);
        } else {
          this.setState({
            dataList: [],
            count: 0,
          });
          $(".loder_div").attr("hidden", true);
        }
      });
    }
  }
  allowNumbersOnly(e) {
    var code = e.which ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
      e.preventDefault();
    }
  }
  checkboxValidation(e, ids) {
    let idArray = this.state.idSet;
    if ($("#selectLocationPost" + ids).is(":checked")) {
      $("#selectLocationPost" + ids).prop("checked", true);
      idArray.push(ids);
    } else {
      $("#selectLocationPost" + ids).prop("checked", false);
      var index = idArray.indexOf(ids);
      if (index > -1) {
        idArray.splice(index, 1);
      }
    }
    this.setState({ idSet: idArray });
    if (idArray.length > 0) {
      $("#moveButton").show();
    } else {
      $("#moveButton").hide();
    }
  }
  checkboxAll() {
    $("#selectLocationPost").prop("checked", true);
  }
  moveupload() {
    if (this.state.idSet.length > 0) {
      if (!window.confirm("Do you want to Delete Data(s)?")) {
        return false;
      }
      $(".loder_div").attr("hidden", false);
      const req = {
        functionName: "auditMasterDelete",
        master_id: this.state.idSet.toString().replace(/[\[\]']+/g, ""),
      };
      backendApi(req).then((data) => {
        $("#moveCommentPopup").attr("hidden", true);
        if (data.success === "1") {
          $(".loder_div").attr("hidden", true);
          alert("Deleted Successfully");
          $("#moveButton").hide();
          this.setState({
            idSet: [],
          });
          this.getListData();
        } else {
          alert("Deleted Failed");
          this.getListData();
          $(".loder_div").attr("hidden", true);
        }
      });
    } else {
      alert("Please select at least one file(s) to move");
      $(".loder_div").attr("hidden", true);
    }
  }
  downloadExcel() {
    $(".loder_div").attr("hidden", false);
    var apidata = {
      functionName: "downloadAllData"
    };
    backendApi(apidata).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        const successFileName = "auditMaster";
        const exportType = "xls";
let array= []
for (let i = 0; i < data.data.length; i++) {
  array.push({
    id:data.data[i].id,
    is_active:data.data[i].is_active ==1? "True":"False",
    search_Criteria:data.data[i].search_Criteria,
    created_on:
    moment(data.data[i].created_on).format(
      "DD MMM YYYY hh:mm a"
    )
,
  })
  
}
console.log("array",array);


        this.exportXlsx(array, successFileName, exportType);
      } else {
        alert("Failed to Download Audit Excel");
        $(".loder_div").attr("hidden", true);
      }
    });
  };
  exportXlsx = (data, fileName, exportType) => {
    exportFromJSON({ data, fileName, exportType });
  };
  render() {
    return (
      <div className="container">
        {/* <div className="user_listdiv removeBorder"> */}
        {/* <div
                        onClick={() => this.downloadTemplate()}
                        className="col-md-12 downloadTemplateDiv" title="Download template">
                        <Icon.Download /> Download template
                    </div> */}
        <div className="file_upload_outer container">
          <DragDropFile handleFile={this.handleFile}>
            <div className="form-group formBottom" id="form-fileupload">
              <DataInput handleFile={this.handleFile} />
            </div>
          </DragDropFile>
          <div
            className="col-lg-3 col-md-12 TemplateDowloadDiv"
            title="Download template"
            onClick={() => this.downloadTemplate()}
          >
            <Icon.Download /> Download template
          </div>
          {this.state.uploaded === true && this.OutTable()}
        </div>
        {/* <div className="row"> */}
        <div className="row row-search">
          <div className="col-lg-1 col-md-2">
            <input
              type="text"
              id="fromData"
              className="form-control"
              placeholder="From ID"
              onKeyPress={(e) => this.allowNumbersOnly(e)}
            />
          </div>
          <div className="col-lg-1 col-md-2">
            <input
              type="text"
              id="toData"
              className="form-control"
              placeholder="To ID"
              onKeyPress={(e) => this.allowNumbersOnly(e)}
            />
          </div>
          <div className="col-lg-2 col-md-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              id="searchAddress"
            />
          </div>
          <div className="col-lg-3 col-md-5 mb-2 col-sm-4">
            <button
              className="btn-search mr-2"
              id="txtFile"
              type="button"
              onClick={(e) => this.handleSort(e)}
            >
              Search
            </button>
            <button
              id="txtFile"
              type="button"
              className="btn-clear-search"
              onClick={(e) => this.clearFilters(e)}
            >
              Clear Search
            </button>
          </div>
          <div className="col-lg-5 mb-3 d-flex align-items-center flex-wrap col-sm-8">
            <div class="total_list ml-auto">
              <span class="audit-txt">Total :</span>
              <span class="audit-txt-bld">{this.state.totalCnt}</span>
            </div>
            <div className="">
              <button
                id="moveButton"
                type="button"
                className="btn btn-danger mr-2"
                onClick={() => this.moveupload()}
              >
                Delete
              </button>
              {this.state.finalArray.length > 0 &&
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => this.deleteAuditMasterFulldata()}
                >
                  Delete All
                </button>}
              {this.state.finalArray.length > 0 &&
                <button
                  id="moveButton"
                  type="button"
                  className="btn btn-download"
                  onClick={(e) => {
                    this.downloadExcel();
                  }}
                >
                  Download Excel
                </button>}
            </div>
          </div>
        </div>
        {this.state.count > 25 && (
          <div className="pagination_main">
            <Pagination
              hideDisabled
              activePage={this.state.activePage}
              itemsCountPerPage={25}
              totalItemsCount={this.state.count}
              pageRangeDisplayed={10}
              onChange={(e) => this.handlePageChange(e)}
            />
          </div>
        )}
        <div className="col-md-12 tableResponsive">
          <div className="tableOuterWidth">
            <table className="table">
              <thead>
                <tr>
                  <th className="col-1 check_size_sm"></th>
                  <th className="col-1">ID</th>
                  <th className="col-6">Search Criteria</th>
                  <th className="col-3">User Name </th>
                  <th className="col-1">Action</th>
                </tr>
              </thead>
              <div className="table_Outerscroll">
                {this.state.finalArray.length > 0 ? (
                  this.state.finalArray.map((data, i) => (
                    <tbody className="table_Outertbody" key={data.id}>
                      <tr>
                        <td className="col-1 check_size_sm">
                          <input
                            type="checkbox"
                            id={"selectLocationPost" + data.id}
                            defaultChecked={data.checked}
                            onClick={(e) =>
                              this.checkboxValidation(e, data.id, i)
                            }
                          />
                        </td>
                        <td className="col-1">{data.id}</td>
                        <td className="col-6">
                          <div className="searchbuttonDiv">
                            <Icon.Search
                              id={"titleEditIcon" + data.id}
                              className="audit-search-icon"
                              onClick={() => {
                                this.textAreachange(data.id);
                                $("#searchAddressvalue" + data.id).val(
                                  data.search_Criteria
                                );
                                $("#dataId").text(data.id);
                              }}
                            />
                            <p
                              id={"searchaddress" + data.id}
                              className="mb-0 succes_textdiv"
                            >
                              {data.search_Criteria}
                            </p>
                            <textarea
                              type="text"
                              className="form-control searchEditInput"
                              placeholder="Search"
                              id={"searchAddressvalue" + data.id}
                              hidden
                            />
                            <span id={"button" + data.id} hidden>
                              <Icon.Save
                                className="audit-save-icon"
                                onClick={() =>
                                  this.saveforAddress(
                                    data.id,
                                    data.search_Criteria,
                                    data.id,
                                    data.id
                                  )
                                }
                              />
                            </span>
                          </div>
                          <span
                            className="success-msg"
                            id={"saveSuccess" + data.id}
                          >
                            Saved successfully
                          </span>
                        </td>
                        <td className="col-3">{data.created_by}</td>
                        <td
                          className="showEdit_icons col-1"
                          onClick={() => this.deleteAuditMaster(data.id)}
                        >
                          <Link title="Delete" style={{ color: "red" }}>
                            <Icon.Trash />
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  ))
                ) : (
                  <span id="nodata">No Data Found</span>
                )}
              </div>
            </table>
          </div>
        </div>
        {/* </div> */}
        <div className="row"></div>
        {/* </div> */}
        <div className="row">
          <div className="module_popup" id="reprocessModal" hidden>
            <div className="module_innerpopup">
              <div className="module_close">
                <Icon.XSquare
                  color="red"
                  onClick={() => {
                    $("#reprocessModal").attr("hidden", true);
                  }}
                />
              </div>
              <div className="module_body">
                <h5>Search Criteria</h5>
                <span id="dataId" hidden></span>
                <textarea
                  id="searchCriteriaInput"
                  className="reprocessArea"
                ></textarea>
              </div>
              <div className="module_popupsearch">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.saveSearchCriteria()}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    $("#reprocessModal").attr("hidden", true);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}
export default MasterData;
