import React, { Component } from "react";
import "../css/bootstrap.css";
import LiveMonitoringimage from "../images/left_icons/live.png";
import { backendApi, apiCallingPost, pythonBackendApi } from "../apiService";
import "../css/style.css";
import close from "../images/close.png";
import checked from "../images/checked.png";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import $ from "jquery";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import moment from "moment";

class Monitoring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountList: [],
    };
  }
  componentDidMount() {
    this.getAccountList();
  }
  getAccountList() {
    $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "getAccountList",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      role: window.sessionStorage.getItem("role"),
    };
    apiCallingPost(reviewAPIData).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        this.setState({
          accountList: data.data,
        });
      } else {
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  fetchAccountFromGoogle() {
    var req = {
      method: "POST",
      body: JSON.stringify({
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        userId:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
      }),
    };
    $(".loder_div").attr("hidden", false);
    pythonBackendApi("fetchAccountFromGoogle", req).then((data) => {
      if (data.success == 1) {
        $(".loder_div").attr("hidden", true);
        alert("Success");
        this.getAccountList();
      } else {
        $(".loder_div").attr("hidden", true);
        alert(" Failed");
      }
    });
  }
  Reviewone(Id) {
    window.location.href = "/googleBusinessLocation/" + Id;
  }
  onMapGoogle(Id) {
    window.location.href = "/googleMapIndia/" + Id;
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          <Header
            heading="Google Business Account"
            headerImage={LiveMonitoringimage}
          />

          <div className="container-fluid">
              <div className="back_tocatalog flex-wrap">
                <div className="back_tocatalog_main">
                  <Link to="/dashboard" className="back-to-link">
                    <Icon.ArrowLeftCircle />
                  </Link>
                </div>
              <div class="total_list mr-">
              <span class="audit-txt">Total :</span>
              <span class="audit-txt-bld">{this.state.accountList.length !="0"?this.state.accountList.length:"0"}</span>
            </div>
                <div className="text-right btn_popupdiv ml-auto">
                  <Link
                    className="btn-addNew"
                    to="#"
                    onClick={() => {
                      this.fetchAccountFromGoogle();
                    }}
                  >
                    Fetch Google Business Accounts{" "}
                    <span>
                      <Icon.PlusCircle />{" "}
                    </span>
                  </Link>
                </div>
            </div>

            <div className="col-md-12 tableResponsive">
              <div className="tableOuterWidth">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="col-2">ID</th>
                      <th className="col-3">Name</th>
                      <th className="col-2">Account Id</th>
                      <th className="col-1">Active</th>
                      <th className="col-2">Locations</th>
                      <th className="col-2">View On Google Map</th>

                    </tr>
                  </thead>
                  <div className="table_Outerscroll">
                    {this.state.accountList.map((item) => (
                      <tbody className="table_Outertbody" key={item.id}>
                        <tr>
                          <td className="col-2">{item.id}</td>
                          <td className="col-3">{item.accountName}</td>
                          <td className="col-2">{item.account_id}</td>
               
                          <td className="col-1">
                            <span>
                              {item.is_active == "1" ? (
                                <Icon.CheckCircle
                                  style={{ color: "GREEN" }}
                                  className="icon_common"
                                />
                              ) : (
                                <Icon.XCircle
                                  style={{ color: "red" }}
                                  className="icon_common"
                                />
                              )}
                            </span>
                          </td>
                          <td className="col-2">
                            <button
                              type="button"
                              className="btn btn-info location-btn-div"
                              onClick={() => this.Reviewone(item.account_id)}
                            >
                             View Locations
                            </button>
                          </td>
                          <td className="col-2">
                            <button
                              type="button"
                              className="btn btn-info location-btn-div"
                              onClick={() => this.onMapGoogle(item.account_id)}
                            >
                             View on Map
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </div>
                </table>
              </div>
            </div>
          </div>
          <Footer/>
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}

export default Monitoring;
