import React, { Component } from "react";
import { backendApi, apiCallingPost, pythonBackendApi } from "../apiService";
import { Map, Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Link } from "react-router-dom";
import moment from "moment";
import $ from "jquery";
var googleAccountId = window.location.pathname.split("/")[2];
var mapKey = ""
export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      addressData: [],
      dealerName: "",
      seoSlug: "",
      siteData: [],
      title: "",
      administrativeArea: "",
      categories_displayName: "",
      postalCode: ""
    };
  }
  componentDidMount() {
    this.getAccountLocations();
  }
  getAccountLocations() {
    $(".loder_div").attr("hidden", false);
    const reviewAPIData = {
      functionName: "getAccountLocations",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      accounts_id: googleAccountId,
    };
    apiCallingPost(reviewAPIData).then((data) => {
      if (data.success === "1") {
        console.log(data.data, "data.datadata.data");
        $(".loder_div").attr("hidden", true);
        this.setState({
          siteData: data.data,
        });
      } else {
        $(".loder_div").attr("hidden", true);
      }
    });
  }
  onMarkerDragEnd = (props, marker, e) => {
    let position = marker.getPosition();
    this.props.onMarkerDragEnd(position.lat(), position.lng());
  };
  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.props.selectGoogleAddressPin(address, latLng.lat, latLng.lng);
      })
      .catch((error) => console.error("Error", error));
  };
  onMarkerClick = (props, marker, e, data) => {
    this.setState({
      activeMarker: marker,
      showingInfoWindow: true,
      title: data.title,
      administrativeArea: data.administrativeArea,
      addressData: data.addressLines,
      dealerName: data.dealerName,
      categories_displayName: data.categories_displayName,
      seoSlug: "",
      postalCode: data.postalCode
    });
  };
  render() {
    const searchOptions = {
      componentRestrictions: { country: ["ind"] },
    };
    const mapStyles = {
      width: "100%",
      height: "100%",
    };
    return (
      <div className="popupImageWrapper-div">
        <div id="googleMap">
          <div className="row">
            <Map
              google={this.props.google}
              onClick={this.onMapClicked}
              zoom={5.5}
              initialCenter={{
                lat:
                  this.state.siteData.length === 0
                    ? 28.6448
                    : this.state.siteData[0].latitude,
                lng:
                  this.state.siteData.length === 0
                    ? 77.216721
                    : this.state.siteData[0].longitude,
              }}
              center={{
                lat:
                  this.state.siteData.length === 0
                    ? 28.6448
                    : this.state.siteData[0].latitude,
                lng:
                  this.state.siteData.length === 0
                    ? 77.216721
                    : this.state.siteData[0].longitude,
              }}
            >
              {this.state.siteData.map((data, id) => {
                return (
                  <Marker
                    onClick={(props, marker, e) =>
                      this.onMarkerClick(props, marker, e, data)
                    }
                    key={parseFloat(data.id)}
                    name={"Current location"}
                    position={{
                      lat: parseFloat(data.latitude ? data.latitude : ""),
                      lng: parseFloat(data.longitude ? data.longitude : ""),
                    }}
                    draggable={false}
                    onDragend={this.onMarkerDragEnd}
                    style={mapStyles}
                  >
                  </Marker>
                )
              })}
              <InfoWindow
                marker={this.state.activeMarker}
                visible={this.state.showingInfoWindow}
              >
                <div>
                  <div>
                    <a className="mapinfo_texthead"><span className="mapinfo_icon"></span>{this.state.title}</a>
                  </div>
                  <div className="mapinfodetail_div">
                    <span className="maplabel_axis">category</span>
                    <span className="mapcoumn_axis">:</span>
                    <span className="mapaddress_axis">{this.state.categories_displayName}</span>
                  </div>
                  <div className="mapinfodetail_div">
                    <span className="maplabel_axis">Address</span>
                    <span className="mapcoumn_axis">:</span>
                    <span className="mapaddress_axis">{this.state.addressData}</span>
                  </div>
                  <div className="mapinfodetail_div">
                    <span className="maplabel_axis">Area</span>
                    <span className="mapcoumn_axis">:</span>
                    <span className="mapaddress_axis ">
                      {this.state.administrativeArea}
                    </span>
                  </div>
                  <div className="mapinfodetail_div">
                    <span className="maplabel_axis">Postal Code</span>
                    <span className="mapcoumn_axis">:</span>
                    <span className="mapaddress_axis ">
                      {this.state.postalCode}
                    </span>
                  </div>
                </div>
              </InfoWindow>
            </Map>
          </div>
        </div>
      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
})(MapContainer);
