import React, { useEffect, useState } from 'react'
import { backendDashboardApi } from "../apiService";
function DashboardTable({formattedDates}) {
    const [list, setList] = useState([])
    useEffect(() => {
        listCallHandle()
    }, [])
    const listCallHandle = () => {
        const postData = {
            
                "functionName": "topPerformingDealership",
                "userId": window.sessionStorage.getItem("userId"),
                "fromDate": formattedDates[0],
                "toDate": formattedDates[1]
            
          
        }
        backendDashboardApi(postData).then((data) => {
            console.log("bbbbbbbbbbbb",data)
            setList(data.result)
        })
    }
    const convertToK = (num) => {
        if (num >= 1000) {
            return (num / 1000).toFixed(1);
        } else if (num >= 10) {
            return (num / 1000).toFixed(3);
        } else {
            return (num / 1000).toFixed(3);
        }
    };
    return (
        <div className="db-cards-75">
            <div class="card">
                <div class="card-body px-0 pb-0">
                    <div className='db-cards-top mb-2 px-3'>
                        <h5 class="card-title card-title-small">Top Performing Dealership

                        </h5>
                        <span className='db-right-btn'>{formattedDates[0] === formattedDates[1] ? formattedDates[0] :formattedDates[0]+"-"+formattedDates[1]}</span>
                    </div>
                    {/* <div className="db-table-overflow">
                                <div className='table-responsive'>
                                    <table class="table table-custom">
                                        <thead className='sticky-top'>
                                            <tr>
                                                <th scope="col">Dealership Name</th>
                                                <th scope="col">Address</th>
                                                <th scope="col">Total Sales</th>
                                                <th scope="col">Performing Model</th>
                                                <th scope="col">Google Searches <span className='db-span-grey'>(k)</span></th>
                                                <th scope="col">Web Clicks <span className='db-span-grey'>(k)</span></th>
                                                <th scope="col">Request Directions <span className='db-span-grey'>(k)</span></th>
                                                <th scope="col">Phone
                                                    Calls <span className='db-span-grey'>(k)</span></th>
                                            </tr>
                                        </thead>
                                        <tbody class="scrollable-body">
                                            <tr>
                                                <td scope="row" className='db-table-field'>sdsdsfs</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td scope="row">1</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" className='db-table-field'>sdsdsfs</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td scope="row">1</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" className='db-table-field'>sdsdsfs</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td scope="row">1</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" className='db-table-field'>sdsdsfs</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td scope="row">1</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                            </tr> <tr>
                                                <td scope="row" className='db-table-field'>sdsdsfs</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td scope="row">1</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                            </tr> <tr>
                                                <td scope="row" className='db-table-field'>sdsdsfs</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td scope="row">1</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                            </tr> <tr>
                                                <td scope="row" className='db-table-field'>sdsdsfs</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td scope="row">1</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" className='db-table-field'>sdsdsfs</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td scope="row">1</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" className='db-table-field'>sdsdsfs</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td scope="row">1</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    
                                </div>
                                </div> */}

                    <div class="gridtable">
                        <table class="table table-bordered db-table">
                            <thead class="">
                                <tr>
                                    <th scope="col" className='table-dealer-name'>Dealership Name</th>
                                    <th scope="col" className='db-data-address'>Address</th>
                                    <th scope="col" className='active_div'>Total Sales</th>
                                    <th scope="col" className='db-performing active_div'>Performing Model</th>
                                    <th scope="col"  className='db-phone-searches'>Google Searches <span className='db-span-grey'>(k)</span></th>
                                    <th scope="col"  className='db-phone-call'>Web Clicks<span className='db-span-grey'>(k)</span></th>
                                    <th scope="col">Request Directions <span className='db-span-grey'>(k)</span></th>
                                    <th scope="col" className='db-phone-call'>Phone Calls <span className='db-span-grey'>(k)</span></th>
                                </tr>

                            </thead>
                            <tbody>
                                {list && list.map((data, i) =>
                                    <tr>
                                        <td className='db-content-name table-dealer-name'>{data.dealerName}</td>
                                        <td className='db-data-address'>{data.dealerAddress}</td>
                                        <td>{data.totalSales.toLocaleString()}</td>
                                        <td  className='db-performing'>{data.productName}</td>
                                        {/* <td>{data.googleSearch}</td> */}
                                        <td  className='db-phone-searches'>{convertToK(data.googleSearches)}</td>
                                        <td className='db-phone-call'>{convertToK(data.webClicks)}</td>
                                        <td>{convertToK(data.requestDirections)}</td>
                                        <td className='db-phone-call'>{convertToK(data.phoneCalls)}</td>

                                    </tr>
                                )}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardTable;