import { useState, useEffect } from "react";
import { backendApi } from "../apiService";
import LeftMenu from "../layout/leftMenu";
import { Link } from "react-router-dom";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import deleteImg from "../images/close.png";
import Checked from "../images/checked.png";
import rolesimage from "../images/left_icons/roles.png";
import * as Icon from "react-feather";
import $ from "jquery";
var moduleList = [];
var newRoleObject = null;
function RoleList() {
  const [userId, setUserId] = useState("");
  const [roleListing, setRoleListing] = useState([]);
  const [showRole, setShowRole] = useState(false);
  const [newRole, setNewRole] = useState(false);
  const [moduleData, setModuleData] = useState([]);
  const [roleModuleId, setRoleModuleId] = useState("");
  const [moduleListing, setModuleListing] = useState([]);
  const [searchModuleListing, setSearchModuleListing] = useState([]);
  const [newRoleName, setNewRoleName] = useState("");
  const [newRoleDisplayName, setNewRoleDisplayName] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchModuleList, setSearchModuleList] = useState([]);
  const [editModuleName, setEditModuleName] = useState("");
  const [editRoleId, setEditRoleId] = useState("");
  useEffect(() => {
    getRoleList();
  }, []);

  const getRoleList = () => {
    var req = {
      functionName: "roleList",
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((response) => {
      $(".loder_div").attr("hidden", true);
      console.log(response, "listAllRoles");
      setSearchModuleList(response.result)
      setRoleListing(response.result);
      $("#dropdownRole").attr("hidden", true);
    });
  };
  const addRole = (id) => {
    if (newRoleName === "") {
      alert("Enter Role name");
      return false;
    } else if (newRoleDisplayName === "") {
      alert("Enter Role Display name");
      return false;
    }
    var req = {
      roleId: id,
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      role: newRoleName.toLocaleUpperCase(),
      displayName: newRoleDisplayName,
      functionName: "roleAddOrEdit",
    };
    backendApi(req).then((response) => {
      if (response.success === "1") {
        if (id) {
          alert("Module updated successfully");
        } else {
          alert("New module added successfully");
        }
        setNewRole(false);
        getRoleList();
      }
    });
  };
  const searchRoles = () => {
    if (searchValue !== "") {
      let search = roleListing.filter((x) =>
        x.display_name
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase())
      );
      setSearchModuleList(search);
    }
     else {
      setSearchModuleList(roleListing);
    }
  };
  const editRole = (id, name, role) => {
    setNewRole(true);
    setEditRoleId(id);
    setNewRoleDisplayName(name);
    setNewRoleName(role);
  };
  const updateRoleStatus = (id) => {
    var statusTrue =
      $('input[name="status' + id + '"]:checked').val() === "on" ? true : false;
    var req = {
      functionName: "changeRoleStatus",
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      roleStatus: statusTrue,
      roleId: id,
    };
    backendApi(req).then((data) => {
      if (data.success === "1") {
        alert("Status Changed Successfully");
      } else {
        alert("Something went wrong while updating status");
      }
    });
  };
  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header heading="Role List" headerImage={rolesimage} />
        <div className="container-fluid">
          <div className="row justify-content-between mb-3 mx-0">
          <div className="searchSection">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search by keyword"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-search"
                  type="button"
                  onClick={() => searchRoles()}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className="">
          <button
            className="btn-addNew ml-2"
            onClick={() => {
              setNewRole(true);
              setEditRoleId("");
              setNewRoleName("");
              setNewRoleDisplayName("");
            }}
          >
            Add New Role
          </button>
          <a href="/moduleList">
            <button className="add_newmodule">Add New Module</button>
          </a>
          </div>
          </div>
         
          {/* <div className="searchSection">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search by keyword"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-search"
                  type="button"
                  onClick={() => searchRoles()}
                >
                  Search
                </button>
              </div>
            </div>
          </div> */}

          <div className="col-md-12 tableResponsive">
            <div className="tableOuterWidth table_width_sm">
            <table className="table">
              <thead>
                <tr>
                  <th className="col-1">#</th>
                  <th className="col-7">Role Name</th>
                  <th className="col-2">Actions</th>
                  <th className="col-2">Status</th>
                </tr>
              </thead>
              <div class="table_Outerscroll">
              {roleListing ? (
                  <>
                    {searchModuleList.map((data, i) => (
              <tbody className="table_Outertbody">
                
                      <tr>
                        <td className="col-1">{data.id}</td>
                        <td className="col-7">{data.display_name}</td>

                        <td className="col-2">
                          <button
                            type="button"
                            className="edit_btnIcon border-0 text-primary"
                            onClick={() => {
                              editRole(data.id, data.display_name, data.role);
                            }}
                          >
                            <Icon.Edit style={{ width: "18px" }} />
                          </button>
                        </td>
                        <td className="col-2">
                          <label className="switch_items">
                            <input
                              id={"status" + data.id}
                              name={"status" + data.id}
                              type="checkbox"
                              onClick={() => updateRoleStatus(data.id)}
                              defaultChecked={
                                data.is_active === 1 ? true : false
                              }
                            />
                            <span className="switch round"></span>
                          </label>
                        </td>
                      </tr>
              </tbody>
                  ))}
                  </>
                ) : (
                  ""
                )}
            </div>
            </table>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
      {newRole ? (
        <div className="module_popup">
          <div className="module_innerpopup">
            <div className="module_close">
              <Icon.XCircle onClick={() => setNewRole(false)} />
            </div>
            {editRoleId ? (
              <div className="module_header">Edit Role Info</div>
            ) : (
              <div className="module_header">Add new Role</div>
            )}
            <div className="module_body">
              <div className="module_popupsearch_wrpr">
                <div className="module_popupsearch">
                  <input
                    id="searchText"
                    type="text"
                    placeholder="Enter Role Display Name"
                    onChange={(e) => setNewRoleDisplayName(e.target.value)}
                    defaultValue={newRoleDisplayName}
                  />
                  <input
                    id="searchText"
                    type="text"
                    placeholder="Enter Role Name"
                    onChange={(e) => setNewRoleName(e.target.value)}
                    defaultValue={newRoleName}
                  />
                  <button
                    type="button"
                    className="btn btn-save mb-1"
                    onClick={() => addRole(editRoleId)}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
       <div className="loder_div" hidden />
    </div>
  );
}
export default RoleList;
