import React, { useState, useEffect } from "react";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import Fraudimage from "../images/left_icons/fraud.png";
import "./style.css";
import MahindraLogo from "./images/mahindra-logo.png";
import LocationImg from "./images/location-img.png";
import { FaChevronRight } from "react-icons/fa";
import $ from "jquery";
import { backendApi } from "../apiService.js";
import { useNavigate } from "react-router-dom";

export default function FraudDashboard() {
  const [tabIndex, setTabIndex] = useState(1);
  const [brands, setBrands] = useState([]);
  const [brandname, setBrandName] = useState("");
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  const [newKeyword, setNewKeyword] = useState("");
  const navigate = useNavigate();
  const handleRemoveKeyword = () => {
    debugger;
    setKeywords(keywords.filter((keyword) => keyword !== selectedKeyword));
    setSelectedKeyword("");
  };

  const getFraudDetails = () => {
    var req = {
      functionName: "getfraudulentDetectionDetails",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(req)
      .then((data) => {
        console.log("frauddetails", data);
        if (data.success == "1") {
          setBrands(data.result);
          //setFraudKeywords(data.result.keywords)
        } else {
          console.log("Error getting brand details");
        }
      })
      .catch((error) => {
        console.log("Error connecting to server");
      });
  };

  const handleAddKeyword = () => {
    if (newKeyword.trim() !== "") {
      setKeywords([...keywords, newKeyword]);
      console.log("keywords", keywords);
      setNewKeyword("");
    }
  };

  const handlePopupSubmit = () => {
    handleAddKeyword();
    setShowPopup(false);
  };

  const handleNewKeywordChange = (event) => {
    setNewKeyword(event.target.value);
  };

  useEffect(() => {
    console.log("selbrand", selectedBrand);
    if (selectedBrand.brand_name) {
      console.log("ddd");
      const extractedKeywords = selectedBrand.keywords
        .split(",")
        .map((keyword) => keyword.trim());

      setKeywords(extractedKeywords);
    }

    console.log("kwords", keywords);
  }, [selectedBrand]);

  useEffect(() => {
    window.sessionStorage.getItem("pageIndexMergeAudit") !== ""
      ? setTabIndex(
          parseInt(window.sessionStorage.getItem("pageIndexMergeAudit"))
        )
      : setTabIndex(0);
    $(".fraud-platform-list").click(function () {
      $(this).toggleClass("fraud-platform-list-active");
    });
  });

  const handleAddKeywordClick = () => {
    setShowPopup(true);
  };

  useEffect(() => {
    getFraudDetails();
  }, []);

  const fraudDetected = () => {
    var req = {
      functionName: "saveORupdatefraudulentDetectionDetails",
      id: selectedBrand.id,
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      brand_name: brandname,
      keywords: keywords,
      platform: "Twitter",
    };
    console.log("reqqqqq", req);
    backendApi(req)
      .then((data) => {
        console.log("savefraud", data);
        if (data.success == "1") {
          // alert("success");
          navigate("/fraud-dashboard-list", {
            state: {
              insertId:
                data?.result?.insertId != 0
                  ? data?.result?.insertId
                  : selectedBrand.id,
            },
          });
          // window.location.href = "/fraud-detected-list";
        } else {
          console.log("Error getting brand details");
        }
      })
      .catch((error) => {
        console.log("Error connecting to server");
      });
  };
  return (
    <div className="main_wrapper">
      <div className="userListWrapper fraudulent_detection_div">
        <Header
          heading="Convergensee - Fraud Dashboard"
          headerImage={Fraudimage}
        />
        <div className="container-fluid bg-white pt-4">
          <div className="row">
            <div className="col-md-3">
              <div className="fraud-left-wrapper">
                <div className="fraud-left-top">
                  <div className="fraud-left-top-mainhead">Home</div>
                  <div className="fraud-left-top-subhead">Frauds</div>
                </div>
                {/* <div className="fraud-left-bottom">
                  <p className="m-0">
                    <strong>Report on :</strong>
                  </p>
                  <img className="my-3" src={MahindraLogo} width={100}></img>
                  <p className="fraud-left-bottom-subtext">
                    ( A leading automobile company in india region )
                  </p>
                </div> */}
              </div>
            </div>
            <div className="col-md-9 position-relative">
              <div className="col-md-9 p-0">
                <h1 className="fraud-main-head">Fraudulent Detection</h1>
              </div>
              {/* <div className="fraud-location-wrapper">
                <div className="fraud-location-top">
                  <p>Locations</p>
                  <div className="fraud-location-number">585</div>
                  <FaChevronRight className="fraud-location-arrow" />
                </div>
                <img src={LocationImg} className="img-fluid"></img>
              </div> */}

              {/* <div className="input-group input-group-outline mb-3 organization_search">
                <input
                  type="text"
                  className="form-control"
                  id="brand"
                  name="brand"
                  placeholder="Brand"
                  value={brandname}
                  onChange={(event) => setBrandName(event.target.value)}
                  // onFocus={() => setClicked(true)}
                />
                <div className="autocomplete__list organization_search_drop">
                  <ul className="nav flex-column">
                    {brands
                      .filter((brand) =>
                        brand.brand_name
                          .toLowerCase()
                          .includes(brandname.toLowerCase())
                      )
                      .map((brand) => (
                        <li
                          key={brand.id}
                          onMouseDown={() => {
                            setBrandName(brand.brand_name);
                            setSelectedBrand(brand);
                          }}
                        >
                          {brand.brand_name}
                        </li>
                      ))}
                  </ul>
                </div>
              </div> */}

              <div className="col-lg-9 col-md-4 location_search location_searchBrand">
                <input
                  className="form-control"
                  value={brandname}
                  onChange={(event) => setBrandName(event.target.value)}
                  placeholder="Brand"
                />
                <div className="autocomplete__list location_search_drop">
                  <ul class="nav flex-column">
                    {brands.map((brand) => (
                      <li
                        key={brand.id}
                        onMouseDown={() => {
                          setBrandName(brand.brand_name);
                          setSelectedBrand(brand);
                        }}
                      >
                        {brand.brand_name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    value={keywords}
                    onChange={(event) => setKeywords(event.target.value)}
                    placeholder="Fraudulent Keywords"
                  />
                </div>
                {/* <div className="col-md-3">
                  <div className="updated-date">Updated date</div>
                  <input
                    type="text"
                    className="input-box dateUpdatedfrad"
                    value={
                      selectedBrand.updateDate
                        ? new Date(selectedBrand.updateDate).toLocaleDateString(
                            "en-GB"
                          )
                        : ""
                    }
                    placeholder="Date"
                  ></input>
                </div> */}
                <div className="col-md-12 fraud-platform-wrapper">
                  <h2 className="fraud-sub-head">
                    Choose platform to run on :
                  </h2>
                  <div className="row mt-4">
                    {/* <div className="fraud-platform-list"> Google </div>
                    <div className="fraud-platform-list"> Facebook </div>
                    <div className="fraud-platform-list"> Instagram </div> */}
                    <div className="fraud-platform-list"> Twitter </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <input
                    className="btn-submit button-lg"
                    type={"submit"}
                    value="Run"
                    onClick={() => fraudDetected()}
                  ></input>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer/> */}
      </div>
    </div>
  );
}
