import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as Icon from "react-feather";
import dataProcessImg from "../images/dataProcess.gif";
import Accessibiltyimage from "../images/left_icons/Accessibility-and-visibility.png";
import { Link } from "react-router-dom";
import { backendApi, pythonBackendApi } from "../apiService";
import Header from "../layout/header";
import moment from "moment";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu";
import $ from "jquery";
const VisibilityAccuracyTable = () => {
  const location = useLocation();
  const [VisibilityAccuracy, setVisibilityAccuracy] = useState([]);
  const [response, setResponse] = useState("Waiting for Data...");
  //   const [visibility, setvisibility] = useState([]);
  //   const [accuracy, setaccuracy] = useState([]);
  useEffect(() => {
    getVisibilityAccuracy();
  }, []);
  const getVisibilityAccuracy = () => {
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "visibilityListing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(req).then((data) => {
      console.log(data.result, "datadata");
      $(".loder_div").attr("hidden", true);
      if (data.success == "1" && data.result.length != 0) {
        setVisibilityAccuracy(data.result);
      } else {
        setVisibilityAccuracy([]);
        setResponse("No data available");
      }
    });
  };
  const startVisibilityNow = (id) => {
    const apidata = {
      method: "POST",
      body: JSON.stringify({
        audit_excel_id: id,
      }),
    };
    pythonBackendApi("startNowVisibility", apidata).then((obj) => {
      alert("Started successfully");
    });
  };
  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header
          heading="Visibility & Accuracy Dashboard"
          headerImage={Accessibiltyimage}
        />
        <div className="container">
          {VisibilityAccuracy.length > 0 ? (
            <div className="col-md-11 tableResponsive mt-5">
              <div className="tableOuterWidth">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="col-1">Id</th>
                      <th className="col-3">Batch Name</th>
                      <th className="col-2">Created On</th>
                      <th className="col-2">Last Visibility Checking</th>
                      <th className="col-2">Status</th>
                      <th className="col-2"></th>
                    </tr>
                  </thead>
                  <div class="table_Outerscroll">
                    {VisibilityAccuracy.map((item, i) => (
                      <tbody className="table_Outertbody">
                        <tr>
                          <td className="col-1" rowspan="2">
                            {i + 1}
                          </td>
                          <td className="col-3">
                            {item.audit_excel_id}-{item.excel_name}
                          </td>
                          <td className="col-2">
                            {moment(item.created_on).format(
                              "DD MMM, YYYY hh:mm A"
                            )}
                          </td>
                          <td className="col-2">
                            {item?.last_visibility_checking
                              ? moment(item?.last_visibility_checking).format(
                                  "DD MMM, YYYY hh:mm A"
                                )
                              : "None"}
                          </td>
                          <td className="col-2">Scheduled</td>
                          <td className="col-2">
                            {/* <button
                              className="btn btn-success"
                              onClick={() =>
                                startVisibilityNow(item.audit_excel_id)
                              }
                            >
                              Start Now
                            </button>{" "} */}
                            <button
                              className="btn btn-info"
                              onClick={() =>
                                (window.location.href = "/visibilityReport")
                              }
                            >
                              Report
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </div>
                </table>
              </div>
            </div>
          ) : (
            <div className="visibilityNoDataFound">
              <h1>{response}</h1>
            </div>
          )}
        </div>
      </div>
      <div className="loder_div" hidden />
      <Footer />
    </div>
  );
};
export default VisibilityAccuracyTable;
