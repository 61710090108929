import React, { Component } from "react";
class Footer extends Component {
  render() {
    return (
      <div className="footer">
       <div className="footer_copy">
         © Copyright 2023, Tagtree. All Rights Reserved
       </div>
            <ul className="nav footer_terms">
                <li className="nav-item">
                    <a className="" href="/termsAndConditions">Terms & Conditions</a>
                </li>
                <li className="nav-item">
                    <a className="" href="/privacyPolicy">Privacy Policy</a>
                </li>
                </ul>
      </div>
    );
  }
}

export default Footer;
