import React, { useState, useEffect } from "react";
import SaveAndValidation from "./settingsSaveAndValidation";
function LocalPageSettings(props) {
  const [local_is_active, setlocal_is_active] = useState(
    props.listingValuesForPassing.local_is_active != "null "
      ? props.listingValuesForPassing.local_is_active == 0
        ? false
        : true
      : ""
  );
  const [local_page_cms_link, setlocal_page_cms_link] = useState(
    props.listingValuesForPassing.local_page_cms_link != "null "
      ? props.listingValuesForPassing.local_page_cms_link
      : ""
  );
  const [local_page__Folder_Name, setlocal_page_Folder_Name] = useState(
    props.listingValuesForPassing.local_page_cms_link != "null "
      ? props.listingValuesForPassing.local_page_cms_link
      : ""
  );
  const [local_page_Url, setlocal_page_Url] = useState(
    props.listingValuesForPassing.local_page_link != "null "
      ? props.listingValuesForPassing.local_page_link
      : ""
  );
  useEffect(() => {
    if (
      props.listingValuesForPassing.local_is_active &&
      (props.listingValuesForPassing.local_is_active != "null" ||
        props.listingValuesForPassing.local_is_active != "undefined")
    ) {
      setlocal_is_active(
        props.listingValuesForPassing.local_is_active == 0 ? false : true
      );
    } else {
      setlocal_is_active("");
    }
    ////////////////
    if (
      props.listingValuesForPassing.local_page_cms_link &&
      (props.listingValuesForPassing.local_page_cms_link != "null" ||
        props.listingValuesForPassing.local_page_cms_link != "undefined")
    ) {
      setlocal_page_cms_link(props.listingValuesForPassing.local_page_cms_link);
    } else {
      setlocal_page_cms_link("");
    }
    //////////////////////////////////
    if (
      props.listingValuesForPassing.project_folder &&
      (props.listingValuesForPassing.project_folder != "null" ||
        props.listingValuesForPassing.project_folder != "undefined")
    ) {
      setlocal_page_Folder_Name(props.listingValuesForPassing.project_folder );
    } else {
      setlocal_page_Folder_Name("");
    }
    //////////////////////
    if (
      props.listingValuesForPassing.local_page_link &&
      (props.listingValuesForPassing.local_page_link != "null" ||
        props.listingValuesForPassing.local_page_link != "undefined")
    ) {
      setlocal_page_Url(props.listingValuesForPassing.local_page_link);
    } else {
      setlocal_page_Url("");
    }
    ///////////
  }, [
    props.listingValuesForPassing.local_is_active,
    props.listingValuesForPassing.local_page_cms_link,
    props.listingValuesForPassing.local_page_link,
  ]);
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="user_formdiv">
          <div className="userform_head">Local Page Settings</div>
          <div className="row userrow_form">
            <div className="col-md-4">
              <label className="col-form-label">Local Page Url</label>
            </div>
            <div className="col-md-1">
              <span>:</span>
            </div>
            <div className="col-md-7">
              <input
                type="text"
                id="local_page_link"
                value={local_page_Url}
                onChange={(e) => {
                  setlocal_page_Url(e.target.value);
                }}
                className="form-control"
                placeholder="Local Page Url"
              />
            </div>
          </div>
          <div className="row userrow_form">
            <div className="col-md-4">
              <label className="col-form-label">Local Page CMS Link</label>
            </div>
            <div className="col-md-1">
              <span>:</span>
            </div>
            <div className="col-md-7">
              <input
                type="text"
                id="local_page_cms_link"
                value={local_page_cms_link}
                onChange={(e) => {
                  setlocal_page_cms_link(e.target.value);
                }}
                className="form-control"
                placeholder="Local Page CMS Link"
              />
            </div>
          </div>
          <div className="row userrow_form">
            <div className="col-md-4">
              <label className="col-form-label">Local Page Folder Name</label>
            </div>
            <div className="col-md-1">
              <span>:</span>
            </div>
            <div className="col-md-7">
              <input
                type="text"
                id="local_page_Folder_Name"
                value={local_page__Folder_Name}
                onChange={(e) => {
                  setlocal_page_Folder_Name(e.target.value);
                }}
                className="form-control"
                placeholder="Local Page Folder Name"
              />
            </div>
          </div>
          <div className="row userrow_form">
            <div className="col-md-4">
              <label className="col-form-label">Is Active</label>
            </div>
            <div className="col-md-1">
              <span>:</span>
            </div>
            <div className="col-md-7">
              <span>
                <>
                  <label className="switch_items">
                    <input
                      id={"status"}
                      name={"status"}
                      type="checkbox"
                      checked={local_is_active}
                      onClick={(event) =>
                        setlocal_is_active(event.target.checked)
                      }
                    />
                    <span className="switch round"></span>
                  </label>
                </>
              </span>
            </div>
          </div>
          <SaveAndValidation
             getUserData={() => props.getUserData()}
            valueForSaved={[
              local_is_active,
              local_page_cms_link,
              local_page_Url,
              local_page__Folder_Name
            ]}
            saveMode={"localePageSetting"}
            organizationId={props.organizationId}
          />
        </div>
      </div>
    </div>
  );
}
export default LocalPageSettings;
