import React, { Component } from "react";
import * as Icon from "react-feather";
import Slider from "react-slick";
import Dashboardimage from "../images/left_icons/dashboard.png";
import img1 from "../images/bg_02.jpg";
import LeftMenu from "../layout/leftMenu";
import { backendApi } from "../apiService";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "../css/slick.css";
import "../css/slick-theme.css";
import $ from "jquery";
import AWS from "aws-sdk";
import moment from "moment";
import NoDataFound from "../noDataFound";
const s3ImageUrl =
  "http://tagtree.s3-website.ap-south-1.amazonaws.com/production/images";
var s3;
// AWS.config.update({
//   accessKeyId: s3bucketAccessKey,
//   secretAccessKey: s3bucketSecretKey,
//   region: s3Region,
// });
// var bucketParams = {
//   Bucket: s3Bucket,
// };
// let s3 = new AWS.S3({
//   apiVersion: "2006-03-01",
//   params: bucketParams,
// });
const s3Region = "us-east-1",
  s3bucketAccessKey = process.env.REACT_APP_TAGTREE_S3_ACCESS_KEY,
  s3bucketSecretKey = process.env.REACT_APP_TAGTREE_S3_SECRET_KEY,
  s3Bucket = "tagtree",
  s3BucketMainFolder = "production",
  S3BucketImagesFolder = "images",
  bucketParams = {
    Bucket: s3Bucket,
  };
class newListing extends Component {
  constructor() {
    super();
    this.state = {
      newRowList: [],
      list: [],
      imageArray: [],
      file: null,
      image: "",
      uploadSet: [],
      uploadImage: [],
      base64: "",
      imagePreview: "",
      imageListArray: [],
      imageUrl: [],
      showPreview: false,
      displayImages: [],
      tempImagesList: [],
      flag: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
    this.handleMonth = this.handleMonth.bind(this);
    this.addImageSet = this.addImageSet.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
  }

  handleImageUpload = () => {
    // debugger
    return new Promise((resolve, reject) => {
      for (let i = 0; i < this.state.uploadSet.length; i++) {
        let fullFilePath = this.state.uploadSet[i].image_url;
        s3.upload(
          {
            Key: fullFilePath,
            Body: this.state.uploadSet[i].imageFile,
            ACL: "public-read",
          },
          (err, data) => {
            if (err) {
              alert("There was an error uploading your image");
            } else if (data) {
              this.state.imageUrl.push({ image_url: data.Location });
              if (this.state.uploadSet.length === this.state.imageUrl.length) {
                resolve(true);
              }
              return;
            }
          }
        );
      }
      this.setState({
        flag: false,
      });
    });
  };
  addImageSet(e) {
    var fileNameType = e.target.files[0].name.split(".").pop();
    var fileType = e.target.files[0].type;
    if (
      (fileType !== "image/jpg" &&
        fileType !== "image/png" &&
        fileType !== "image/jpeg") ||
      fileNameType === "jfif"
    ) {
      alert("Please Upload jpeg, jpg or png image");
      return false;
    }
    var a = e.target.files[0].type;
    this.setState({ imagePreview: URL.createObjectURL(e.target.files[0]) });
    let uploadSet = [...this.state.uploadSet];
    uploadSet.push({
      imageNameArray: URL.createObjectURL(e.target.files[0]),
      image_url:
        s3BucketMainFolder +
        "/" +
        S3BucketImagesFolder +
        "/" +
        this.uuidv4() +
        e.target.files[0].name,
      imageFile: e.target.files[0],
    });
    this.setState(
      {
        uploadSet: uploadSet,
      },
      () => {}
    );
  }
  removeImageSet(e, i) {
    let uploadSet = [...this.state.uploadSet];
    uploadSet.splice(i, 1);
    this.setState({ uploadSet });
  }
  handleChange(date) {
    this.setState({
      startDate: date,
    });
  }
  handleChangeTo(dateTo) {
    this.setState({
      endDate: dateTo,
    });
  }
  handleMonth(txtMonth) {
    this.setState({
      month: txtMonth,
    });
  }
  getS3Credentials() {
    // const credentialReq = {
    //   functionName: "s3Credentials",
    // };
    // backendApi(credentialReq).then((data) => {
    // setS3Credentials(data.data[0]);
    AWS.config.update({
      accessKeyId: s3bucketAccessKey,
      secretAccessKey: s3bucketSecretKey,
      region: s3Region,
    });
    s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: bucketParams,
    });
    // console.log("s3", s3);
    // });
  }
  componentDidMount() {
    this.imageEditorListing();
    this.getS3Credentials();
    $("#vmModal").attr("hidden", true);
  }
  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  addNewRow() {
    if (document.getElementById("inputText").value === "") {
      alert("Text Required");
      document.getElementById("inputText").focus();
      return false;
    }
    let newRowList = [...this.state.newRowList];
    newRowList.push($("#inputText").val());
    this.setState({ newRowList });
    $("#inputText").val("");
  }
  removeRowItem(e, i) {
    let newRowList = [...this.state.newRowList];
    newRowList.splice(i, 1);
    this.setState({ newRowList });
  }
  imageEditorListing() {
    var postData = {
      functionName: "imageEditorMasterListing",
      created_by: "1",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(postData).then((data) => {
      if (data.success === "1") {
        this.setState({
          list: data.data,
          imageArray: data.data.imageArray,
          imageListArray: data.data.textArray,
        });
      }
    });
  }
  async saveImageEditor() {
    if (this.state.startDate > this.state.endDate) {
      alert("To date should be greater than or equal to from date");
    }
    let flag1 = true;
    if (document.getElementById("txtName").value === "") {
      alert("Event name required");
      this.setState({
        flag: true,
      });
      return false;
    }
    var textArray = [];
    if (document.getElementById("inputText").value !== "") {
      textArray.push({ text_data: document.getElementById("inputText").value });
    }
    for (var i = 0; i < this.state.newRowList.length; i++) {
      var y = this.state.newRowList[i];
      textArray.push({ text_data: y });
    }
    // }
    // var imageArray = []
    // for (var i = 0; i < this.state.uploadSet.length; i++) {
    //   var z = this.state.uploadSet[i].image_url;
    //   imageArray.push({ text_data: y });
    // }
    await this.handleImageUpload();
    if (this.state.flag === false) {
      // if (this.state.startDate && this.state.endDate) {
      $(".loder_div").attr("hidden", false);
      var postData = {
        functionName: "imageEditorMasterDataSave",
        event_name: $("#txtName").val(),
        from_date: $("#txtFrom").val(),
        to_date: $("#txtTo").val(),
        month_name: $("#txtMonth").val(),
        is_repeat: "true",
        created_by: "1",
        site_id:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        TextArray: textArray,
        mediaList: this.state.imageUrl,
      };
      // }
      backendApi(postData).then((data) => {
        if (data.success === "1") {
          $(".loder_div").attr("hidden", true);
          // alert(data.successMessage);
          alert("Added Successfully");
          window.location.href = "/image-editor-settings";
        } else {
          alert(data.errorMessage);
        }
      });
    }
  }
  render() {
    var settings = {
      dots: false,
      arrows: true,
      infinite: false,
      draggable: true,
      touchMove: true,
      speed: 500,
      autoplay: false,
      slidesToShow: 3,
      slidesToScroll: 1,
    };
    window.$(document).ready(function () {
      window.$(".popup-image").magnificPopup({
        delegate: "a",
        type: "image",
        tLoading: "Loading image #%curr%...",
        mainClass: "mfp-img-mobile",
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0, 1],
        },
        image: {
          tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
          titleSrc: function (item) {
            return item.el.attr("title") + "<small></small>";
          },
        },
      });
      window.$(".popup-video").magnificPopup({
        delegate: "a",
        type: "iframe",
        tLoading: "Loading...",
      });
    });
    let { newRowList } = this.state;
    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper mapunmap_div">
          <Header
            heading="Image Editor Settings"
            headerImage={Dashboardimage}
          />
          <div className="container-fluid">
            <div className="row mt-5">
              <div className="col-lg-10 d-flex"></div>
              <div className="col-lg-2 ml-auto">
                <Link
                  className="btn-addNew mr-2"
                  to="#"
                  onClick={() => {
                    $("#vmModal").attr("hidden", false);
                  }}
                >
                  Add New
                  <span>
                    <Icon.PlusCircle />{" "}
                  </span>
                </Link>
              </div>
            </div>
            <br />
            <div className="col-md-12 tableResponsive">
              <div className="tableOuterWidth">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="col-2">Title</th>
                      <th className="col-2">Date</th>
                      <th className="col-4">Decsription</th>
                      <th className="col-4 imageEditor">Images</th>
                    </tr>
                  </thead>
                  {this.state.list.length > 0 ? (
                    <div className="table_Outerscroll">
                      {this.state.list.map((object, i) => (
                        <tbody className="table_Outertbody">
                          <tr>
                            <td className="col-2">{object.event_name}</td>
                            <td className="col-2">
                              {moment(object.created_on).format("DD-MM-YYYY")}
                            </td>
                            <td className="col-4 description_edit">
                              <>
                                {Object.keys(object)
                                  .toString()
                                  .indexOf("imageArray") == "-1" ? (
                                  ""
                                ) : (
                                  <>
                                    {object.imageArray.length > 0 ? (
                                      <>
                                        {object.imageArray.map((item, j) => (
                                          <>
                                            <p
                                              className="description_ellipse"
                                              title={item.text_name}
                                            >
                                              {item.text_name}
                                            </p>
                                          </>
                                        ))}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </>
                            </td>
                            <td className="col-4 imageText imageTextslider">
                              <div className="" style={{ width: "100%" }}>
                                {Object.keys(object)
                                  .toString()
                                  .indexOf("textArray") == "-1" ? (
                                  ""
                                ) : (
                                  <>
                                    {object.textArray.length > 0 ? (
                                      <>
                                        <Slider {...settings} key={i}>
                                          {object.textArray.map((item, j) => (
                                            <div className="popup-image">
                                              <div className="popupGallerysection">
                                                <a
                                                  href={item.image_url}
                                                  title="img"
                                                  target="_blank"
                                                >
                                                  <img
                                                    title=""
                                                    src={item.image_url}
                                                  />
                                                </a>
                                              </div>
                                            </div>
                                          ))}
                                        </Slider>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </div>
                  ) : (
                    <NoDataFound />
                  )}
                </table>
              </div>
            </div>
            <div className="module_popup vmModal" id="vmModal" hidden>
              <div className="module_innerpopup module_bodypop">
                <div className="module_headdiv">
                  Add Text/images
                  <div className="module_close">
                    <Icon.XCircle
                      color="red"
                      onClick={() => {
                        $("#vmModal").attr("hidden", true);
                      }}
                    />
                  </div>
                </div>
                <div className="module_body textEditorScroll">
                  <div className="row tag_formLabel ">
                    <label for="" className="m-0 col-sm-3">
                      Name<sup className="functionSup">*</sup>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="txtName"
                        placeholder="Event Name"
                      />
                    </div>
                  </div>
                  <div className="row tag_formLabel ">
                    <label for="" className="col-sm-3 col-form-label">
                      From Date
                    </label>
                    <div className="col-sm-9">
                      <DatePicker
                        name="startDate"
                        dateFormat="MMMM"
                        showMonthYearPicker
                        className="form-control  "
                        placeholderText="From Date"
                        id="txtFrom"
                        selected={this.state.startDate}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="row tag_formLabel ">
                    <label for="" className="col-sm-3 col-form-label">
                      To Date
                    </label>
                    <div className="col-sm-9">
                      <DatePicker
                        name="endDate"
                        dateFormat="MMMM"
                        showMonthYearPicker
                        className="form-control "
                        placeholderText="To Date"
                        id="txtTo"
                        selected={this.state.endDate}
                        onChange={this.handleChangeTo}
                      />
                    </div>
                  </div>
                  <div className="row tag_formLabel ">
                    <label for="" className="col-sm-3 col-form-label">
                      Month
                    </label>
                    <div className="col-sm-9 imageEditorMonthPicker">
                      <DatePicker
                        dateFormat="MMMM"
                        showMonthYearPicker
                        className="form-control  "
                        placeholderText="Month"
                        id="txtMonth"
                        value={this.state.month}
                        selected={this.state.month}
                        onChange={this.handleMonth}
                      />
                    </div>
                  </div>
                  <div className="row tag_formLabel ">
                    <label for="" className="col-sm-3 col-form-label">
                      Add Text
                    </label>
                    <div className="col-sm-7">
                      <input
                        type="text"
                        id="inputText"
                        className="form-control"
                      />
                    </div>
                    <div className="col-sm-2">
                      <button
                        className="btn btn-primary p-0 append_btnclick"
                        id="txtNewRow"
                        onClick={() => this.addNewRow()}
                      >
                        <Icon.Plus />
                      </button>
                    </div>
                  </div>
                  <div className="row tag_formLabel ">
                    <label for="" className="col-sm-3 col-form-label"></label>
                    <div className="col-sm-9">
                      <div className="textScroll">
                        {newRowList.map((data, i) => {
                          return (
                            <div key={i}>
                              <span className="textAdded" title={data}>
                                {data}
                                <div className="new_chatclose_text">
                                  <Icon.XCircle
                                    color="red"
                                    onClick={(e) => this.removeRowItem(e, i)}
                                  />
                                </div>
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="row tag_formLabel ">
                    <label for="" className="col-sm-3 col-form-label">
                      Image
                    </label>
                    <div className="col-sm-9">
                      <div className="tag_upload-btn-wrapper">
                        <button className="btn">Upload Image</button>
                        <input
                          type="file"
                          name="imageLoader"
                          className="controls__input"
                          accept="image/*"
                          id="uploadEvent"
                          onChange={(e) => this.addImageSet(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row imageScroll ">
                    {this.state.uploadSet.map((data, i) => {
                      return (
                        <div className="col-md-3">
                          <div className="imageUploadWrapper">
                            <div className="new_chatclose">
                              <Icon.XCircle
                                color="red"
                                onClick={(e) => this.removeImageSet(e, i)}
                              />
                            </div>
                            <img src={data.imageNameArray} alt="" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="button-wrapper">
                  <button
                    type="button"
                    className="btn-save"
                    id="txtSave"
                    onClick={() => this.saveImageEditor()}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn-cancel"
                    onClick={() => {
                      $("#vmModal").attr("hidden", true);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <div className="loder_div" hidden />
      </div>
    );
  }
}
export default newListing;
