import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import * as Icon from "react-feather";
import Pagination from "react-js-pagination";
import { backendApi, apiCallingPost } from "../apiService";
import { useNavigate } from "react-router-dom";
import GoogleMap from "./singleClaim/components/GoogleMap";
import { Link } from "react-router-dom";
import $ from "jquery";
function NoDataFound() {
  const [dataListFromApi, setdataListFromApi] = useState([]);
  const [claimList, setclaimList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [storeCode, setStoreCode] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [data2List, setdata2List] = useState([]);
  const [data3List, setData3List] = useState([]);
  const [dataListTopassBulk, setdataListTopassBulk] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [BusinessName, setBusinessName] = useState("");
  const [Description, setDescription] = useState("");
  const [Address, setAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [googleAddressPin, setGoogleAddressPin] = useState("");
  const [PhoneNo, setPhoneNo] = useState("");
  const [VerificationEmail, setVerificationEmail] = useState("");
  const [Locality, setLocality] = useState("");
  const [PostalCode, setPostalCode] = useState("");
  const [AdminstriveArea, setAdminstriveArea] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedLocations, setSelectedLocations] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    notFoundClaimList();
    getCategoriesListGoogle();
  }, []);
  useEffect(() => {
    console.log("selectedLocations", selectedLocations);
    console.log("claimListclaimList", claimList);
    let flag = false;
    for (const obj of claimList) {
      if (obj.checked == true) {
        flag = true;
      } else {
        flag = false;
        break;
      }
    }
    // alert(flag);
    if (flag == true) {
      $("#selectAllBox").prop("checked", true);
    } else if (flag == false) {
      $("#selectAllBox").prop("checked", false);
    }
  }, [selectedLocations, claimList]);

  const notFoundClaimList = (e) => {
    let fullDataArray = [];
    var req = {
      functionName: "notFountClaimList",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        fullDataArray.push(...data.data);
        fullDataArray.push(...data.data3);
        // setdataListFromApi(fullDataArray);
        setdataListTopassBulk(data.data);
        setdata2List(data.data2);
        setData3List(data.data3);
        setTotalCount(data.data.length);
        const indexOfLastPost = currentPage * 25;
        const indexOfFirstPost = indexOfLastPost - 25;
        let tempList = fullDataArray;
        const currentPosts = fullDataArray.slice(
          indexOfFirstPost,
          indexOfLastPost
        );
        setdataListFromApi(
          currentPosts.map((data) => ({ ...data, checked: false }))
        );
        setclaimList(tempList);
        console.log(data, "datadatadata");
        console.log(tempList, "tempList");

      } else {
        $("#nodata").show();
        setdataListFromApi([]);
        setclaimList([]);
        setTotalCount(0);
      }
    });
  };
  const handleActivePage = (e) => {
    setCurrentPage(e);
    const indexOfLastPost = e * 25;
    const indexOfFirstPost = indexOfLastPost - 25;
    let tempList = claimList;
    const currentPosts = claimList.slice(indexOfFirstPost, indexOfLastPost);
    setdataListFromApi(currentPosts);
    setclaimList(tempList);
  };

  const newBulkPush = () => {
    let saveArray = [];
    for (const obj of claimList) {
      // let element = claimList.filter((data) => data.checked == true);
      if (obj.checked == true) {
        // let objData = {
        //   full_name: obj.location_name,
        //   address: obj.address,
        //   mobile_no: obj.phone_no,
        //   email_id: "",
        //   category: obj.category,
        //   locality: obj.address.split(/[\s,]+/).at(-2),
        //   administrativeArea: obj.address.split(/[\s,]+/).at(-1),
        //   postalCode: obj.address.split(/[\s,]+/).pop(),
        //   account_id: window.sessionStorage.getItem("account_id"),
        //   site_id:
        //     window.sessionStorage.getItem("switchingOrgId") === ""
        //       ? window.sessionStorage.getItem("organizationId")
        //       : window.sessionStorage.getItem("switchingOrgId"),
        //   location_id: "",
        //   verification_code: "",
        //   g_pin: "",
        //   storeCode: obj.reference_code,
        //   status: "Push Initiated",
        //   created_by: obj.created_by,
        // };
        const addressArray = obj.address.split(',').map(value => value.trim());
        const filteredAddressArray = addressArray.filter(value => value !== obj.locality && value !== obj.administrativeArea);
        const newAddress = filteredAddressArray.join(', ');
        let objData = {
          full_name: obj.location_name,
          address: newAddress,
          mobile_no: obj.phone_no,
          email_id: "",
          category: obj.category,
          locality: obj.locality?obj.locality:obj.address.split(/[\s,]+/).at(-2),
          administrativeArea: obj.administrativeArea?obj.administrativeArea:obj.address.split(/[\s,]+/).at(-1),
          postalCode: obj.Postcode?obj.Postcode:obj.address.split(/[\s,]+/).pop(),
          account_id: window.sessionStorage.getItem("account_id"),
          site_id:
            window.sessionStorage.getItem("switchingOrgId") === ""
              ? window.sessionStorage.getItem("organizationId")
              : window.sessionStorage.getItem("switchingOrgId"),
          location_id: "",
          verification_code: "",
          g_pin: "",
          storeCode: obj.reference_code,
          status: "Push Initiated",
          created_by: obj.created_by,
          longitude:obj.Longitude,
          latitude:obj.Latitude
        };
        saveArray.push(objData);
      }
    }
    var api = {
      functionName: "insertGbp_localDB",
      objectArray: saveArray,
    };
    console.log(api, "apiapiapi");
    // return false;
    backendApi(api).then((data) => {
      if (data.success === "1") {
        window.location.href = `/claim_business/listOfPushSaveLocations/${data.UID}`;
      }
    });
  };

  //this api is needed for bulk upload loop
  const pushClick = (e, data) => {
    e.preventDefault();
    console.log("addasdas", data);
    const addressArray = data.address.split(',').map(value => value.trim());
    const filteredAddressArray = addressArray.filter(value => value !== data.locality && value !== data.administrativeArea);
    const newAddress = filteredAddressArray.join(', ');
    let saveArray = [];
    let objData = {
      full_name: data.location_name,
      address: newAddress,
      mobile_no: data.phone_no,
      email_id: "",
      category: data.category,
      locality: data.locality?data.locality:data.address.split(/[\s,]+/).at(-2),
      administrativeArea: data.administrativeArea?data.administrativeArea:data.address.split(/[\s,]+/).at(-1),
      postalCode: data.Postcode,
      account_id: window.sessionStorage.getItem("account_id"),
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      location_id: "",
      verification_code: "",
      g_pin: "",
      storeCode: data.reference_code,
      status: "Push Initiated",
      created_by: data.created_by,
      longitude:data.Longitude,
      latitude:data.Latitude
    };
    saveArray.push(objData);
    var api = {
      functionName: "insertGbp_localDB",
      objectArray: saveArray,
    };
    console.log(api, "apiapiapi");
    backendApi(api).then((data) => {
      if (data.success === "1") {
        window.location.href = `/claim_business/listOfPushSaveLocations/${data.UID}`;
      }
    });
  };
  const pushClickBulkUpload = (data) => {
    window.sessionStorage.setItem("pushFromNodata", 1);
    navigate("/claim_business/bulkUploadVerifyPage", {
      // state: {
      //   auditClaimData: data,
      // },
    });
  };
  const validationCheck = () => {
    if (!storeCode) {
      alert("Store Code is Required");
      return false;
    } else if (!BusinessName) {
      alert("Location Name is Required");
      return false;
    } else if (!Address) {
      alert("Address is Required");
      return false;
    } else if (!PhoneNo) {
      alert("Phone Number is Required");
      return false;
    } else if (!Locality) {
      alert("Locality is Required");
      return false;
    } else if (!AdminstriveArea) {
      alert("Administrative Area is Required");
      return false;
    } else if (!PostalCode) {
      alert("Postal Code is Required");
      return false;
    } else {
      return true;
    }
  };
  const saveLocation = (e) => {
    e.preventDefault();
    var validationFlag = validationCheck();
    if (validationFlag == true) {
      let objData = {
        full_name: BusinessName,
        address: Address,
        mobile_no: PhoneNo,
        email_id: VerificationEmail,
        locality: Locality,
        postalCode: PostalCode,
        administrativeArea: AdminstriveArea,
        category: selectedCategory.name,
        account_id: window.sessionStorage.getItem("account_id"),
        site_id:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        location_id: "",
        verification_code: "",
        g_pin: "",
        status: "Push Initiated",
        created_by: window.sessionStorage.getItem("userId"),
      };
      var api = {
        functionName: "insertGbp_localDB",
        objectArray: [objData],
      };
      console.log(api, "apiapiapiapiapi");
      backendApi(api).then((data) => {
        if (data.success === "1") {
          window.location.href = `/claim_business/listOfPushSaveLocations/${data.UID}`;
        }
      });
    }
  };
  const changeHandler = (e) => {
    console.log("eeeee", e);
  };
  const splitAddressFunction = (address) => {
    console.log("eeeee", address);
    let pinData = [];
    pinData = address.split(", ");
    let re = /^[0-9]{6}$/;
    if (re.test(pinData[pinData.length - 1])) pinData.pop();

    if (pinData.some((obj) => obj.toLowerCase() == "india")) {
      let array = pinData.filter((obj) => obj.toLowerCase() != "india");
      pinData = array;
    }
    debugger;
    let adminArea = pinData.pop();
    let localArea = pinData.pop();
    setAdminstriveArea(adminArea.toString());
    setLocality(localArea.toString());
    setAddress(pinData.join(", "));
    console.log("its there", pinData);
    // if (
    //   pinData.some((obj) => obj.toLowerCase() == AdminstriveArea.toLowerCase())
    // ) {
    //   let array = pinData.filter(
    //     (obj) => obj.toLowerCase() != AdminstriveArea.toLowerCase()
    //   );
    //   pinData = array;
    //   console.log("its there", pinData);
    // }
    // pinData
    //   .filter((obj) => obj.toLowerCase() == AdminstriveArea.toLowerCase())
    //   .map((adminArea) => {
    //     // console.log("adminArea", adminArea);
    //     setAdminstriveArea();
    //   });
    // if (pinData.toLowerCase().includes(AdminstriveArea.toLowerCase())) {
    //   pinData.toLowerCase().replace(AdminstriveArea.toLowerCase() + ", ");
    //   console.log("its there", pinData);
    // }
  };
  const getCategoriesListGoogle = () => {
    const apiReq = {
      functionName: "googleLocationCategoryList",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apiReq).then((data) => {
      console.log("googleLocationCategoryList", data);
      setCategoriesList(data.data.categories);
    });
  };
  const filterCategory = (e) => {
    if (e.target.value.length > 2) {
      var filterArray = categoriesList.filter((data) =>
        data.displayName.toLowerCase().includes(e.target.value.toLowerCase())
      );
      console.log("filterArrayfilterArray", filterArray);
      setFilteredCategories(filterArray);
    } else {
      setFilteredCategories([]);
    }
  };
  const selectEachLocation = (e, data) => {
    // alert(e.target.checked);
    // let noDataArray = [];
    if (e.target.checked == true) {
      // console.log("datataaaa", data);
      // noDataArray = [...selectedLocations, data.id];
      setSelectedLocations([...selectedLocations, data.id]);
      setdataListFromApi(
        dataListFromApi.map((obj) => {
          if (obj.id === data.id) {
            return { ...obj, ["checked"]: true };
          } else {
            return obj;
          }
        })
      );
      setclaimList(
        claimList.map((obj) => {
          if (obj.id === data.id) {
            return { ...obj, ["checked"]: true };
          } else {
            return obj;
          }
        })
      );
      // let objData = {
      //   full_name: obj.BusinessName,
      //   address: obj.Address,
      //   mobile_no: obj.PhoneNo,
      //   email_id: obj.VerificationEmail,
      //   locality: obj.Locality,
      //   postalCode: obj.PostalCode,
      //   storeCode: obj.StoreCode,
      //   administrativeArea: obj.AdministrativeArea,
      //   account_id: window.sessionStorage.getItem("account_id"),
      //   site_id:
      //     window.sessionStorage.getItem("switchingOrgId") === ""
      //       ? window.sessionStorage.getItem("organizationId")
      //       : window.sessionStorage.getItem("switchingOrgId"),
      //   location_id: "",
      //   verification_code: "",
      //   g_pin: "",
      //   status: "Push Initiated",
      //   created_by: window.sessionStorage.getItem("userId"),
      // };
    } else {
      setdataListFromApi(
        dataListFromApi.map((obj) => {
          if (obj.id === data.id) {
            return { ...obj, ["checked"]: false };
          } else {
            return obj;
          }
        })
      );
      setclaimList(
        claimList.map((obj) => {
          if (obj.id === data.id) {
            return { ...obj, ["checked"]: false };
          } else {
            return obj;
          }
        })
      );
      const index = selectedLocations.indexOf(data.id);
      const newArray = [...selectedLocations];
      newArray.splice(index, 1);
      setSelectedLocations(newArray);
    }
  };
  const selectAllLocation = (e) => {
    if (e.target.checked == true) {
      let tempArray = [];
      claimList.forEach((obj, i) => {
        const index = selectedLocations.indexOf(obj.id);
        if (index == -1) {
          tempArray = [...tempArray, obj.id];
        }
      });
      setSelectedLocations(tempArray);
      setdataListFromApi(
        dataListFromApi.map((obj) => {
          return { ...obj, ["checked"]: true };
        })
      );
      setclaimList(
        claimList.map((obj) => {
          return { ...obj, ["checked"]: true };
        })
      );
    } else {
      setdataListFromApi(
        dataListFromApi.map((obj) => {
          return { ...obj, ["checked"]: false };
        })
      );
      setclaimList(
        claimList.map((obj) => {
          return { ...obj, ["checked"]: false };
        })
      );
      setSelectedLocations([]);
    }
  };
  return (
    <div className="container">
      <div className="row row-search"></div>
      <hr className="mt-0"></hr>
      <div className="col-md-12 tableResponsive">
        {/* <Link 
      to={{
        pathname: "/claim_business/NodataFoundBulkUpload",
        state: dataListFromApi // your data array of objects
      }}
                  className="btn btn-primary submitButton cancel_bttn"
                > */}
        {selectedLocations.length > 0 ? (
          <button
            className="btn btn-primary mb-2"
            onClick={(e) => newBulkPush()}
          >
            For Bulk Upload Click Here
            <Icon.ArrowRight className="mr-1" width={18} />
          </button>
        ) : (
          <button
            className="btn btn-primary mb-2"
            onClick={(e) => setShowPopup(true)}
          >
            Click here to create new Business.
            <Icon.ArrowRight className="mr-1" width={18} />
          </button>
        )}

        {/* </Link> */}
        {/* <div className="tableOuterWidth table_width_md"> */}
        <div className="tableOuterWidth">
          <table className="table table_width_md">
            <thead>
              <tr>
                <th className="col-2">Store Code</th>
                <th className="col-6">Business Name</th>
                {/* <th className="col-6">Address</th> */}
                <th className="col-2"></th>
                <th className="col-2">
                  <input
                    type="checkbox"
                    id="selectAllBox"
                    onClick={(e) => selectAllLocation(e)}
                  />
                </th>
              </tr>
            </thead>
            <div class="table_Outerscroll">
              {dataListFromApi.length !== 0 ? (
                dataListFromApi.map((data) => (
                  <tbody className="table_Outertbody">
                    <tr
                      className={
                        data.process_status == "Failed"
                          ? "table_active_firebrick"
                          : ""
                      }
                    >
                      <td className="col-2">{data.reference_code}</td>
                      <td className="col-6">
                        <span
                          style={{
                            textDecoration: "underline",
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                        >
                          {data.location_name}
                        </span>
                        <span style={{ display: "block" }}>{data.address}</span>
                      </td>
                      <td className="col-2">
                        {/* <Link
                     to="/claim_business/PushNodata"
                    // pathname:"/claim_business/PushNodata",
                    state={{
                      process_status:"data.process_status"
                    }}
                  
                    > */}
                        <button
                          className="btn btn-primary"
                          onClick={(e) => pushClick(e, data)}
                        >
                          Push
                        </button>
                        {/* </Link> */}
                      </td>
                      <td className="col-2">
                        <input
                          id={`selectLocation${data.id}`}
                          type="checkbox"
                          checked={data.checked}
                          onClick={(e) => selectEachLocation(e, data)}
                        />
                      </td>
                    </tr>
                  </tbody>
                ))
              ) : (
                <tr id="nodata">
                  <div className="content-sub-wrapper col-md-12">
                    <div className="content-sub">
                      <p>No Data Found</p>
                    </div>
                  </div>
                </tr>
              )}
            </div>
          </table>
        </div>
      </div>
      {showPopup == true && (
        <div className="module_popup inner_module_popup_div">
          <div className="module_innerpopup">
            <div className="module_close">
              <Icon.XCircle
                onClick={() => {
                  setShowPopup(false);
                }}
              />
            </div>
            <div className="module_header text-center">Create New Business</div>
            <div className="module_body">
              <div className="module_popupsearch_wrpr">
                <div className="module_popupsearch">
                  <div className="container-fluid">
                    <div className="user_formdiv" id="createLocationForm">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              {" "}
                              Store Code
                            </label>
                            <input
                              type="text"
                              onChange={(e) => setStoreCode(e.target.value)}
                              value={storeCode}
                              className="form-control"
                              id="firstName"
                              aria-describedby="emailHelp"
                              placeholder="Store Code"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Business Name
                            </label>
                            <input
                              type="text"
                              onChange={(e) => {
                                setBusinessName(e.target.value);
                                setDescription(e.target.value);
                              }}
                              // value={firstName}
                              className="form-control"
                              id="firstName"
                              aria-describedby="emailHelp"
                              placeholder="Name"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Business Description
                            </label>
                            <input
                              value={Description}
                              onChange={(e) => setDescription(e.target.value)}
                              className="form-control"
                              id="emailId"
                              aria-describedby="emailHelp"
                              placeholder="Description"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Business Category
                            </label>
                            <span className="location_search">
                              <input
                                className="form-control"
                                type="text"
                                id="LocationSelected"
                                value={selectedCategory?.displayName}
                                onChange={(e) => filterCategory(e)}
                                placeholder="Category"
                              />
                              {filteredCategories.length > 0 && (
                                <div className="autocomplete__list location_search_drop">
                                  <ul
                                    id="categoryListDropdown"
                                    class="nav flex-column"
                                  >
                                    {filteredCategories.map((data) => (
                                      <li
                                        key={data.name}
                                        onMouseDown={() =>
                                          setSelectedCategory(data)
                                        }
                                      >
                                        {data.displayName}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </span>
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Phone No</label>
                            <input
                              type="text"
                              className="form-control"
                              id="phoneNo"
                              // value={phone}
                              onChange={(e) => setPhoneNo(e.target.value)}
                              aria-describedby="emailHelp"
                              placeholder="Phone No"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Administrative Area (State)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="phoneNo"
                              // maxLength={10}
                              value={AdminstriveArea}
                              onChange={(e) => {
                                setAdminstriveArea(e.target.value);
                              }}
                              aria-describedby="emailHelp"
                              placeholder=" Administrative Area"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Locality (District)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="phoneNo"
                              // maxLength={10}
                              value={Locality}
                              onChange={(e) => {
                                setLocality(e.target.value);
                              }}
                              aria-describedby="emailHelp"
                              placeholder="Locality"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Address</label>
                            <textarea
                              type="text"
                              className="form-control"
                              id="phoneNo"
                              // maxLength={10}
                              value={Address}
                              onChange={(e) => {
                                setAddress(e.target.value);
                              }}
                              // value={address}
                              aria-describedby="emailHelp"
                              placeholder="Address"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              {" "}
                              Postal Code
                            </label>
                            <input
                              type="text"
                              onChange={(e) => setPostalCode(e.target.value)}
                              // value={postalCode}
                              className="form-control"
                              id="firstName"
                              aria-describedby="emailHelp"
                              placeholder="Postal Code"
                            />
                          </div>
                        </div>
                        <div className="col-md-8">
                          <GoogleMap
                            // key={mapRenderKey}
                            longitude={longitude}
                            latitude={latitude}
                            googleAddressPin={googleAddressPin}
                            changeHandler={changeHandler}
                            setGoogleAddressPin={(googleAddressPin) => {
                              setGoogleAddressPin(googleAddressPin);
                              // setAddress(googleAddressPin);
                            }}
                            selectGoogleAddressPin={(
                              googleAddressPin,
                              lat,
                              lng
                            ) => {
                              console.log(
                                googleAddressPin,
                                "googleAddressPingoogleAddressPin"
                              );
                              // splitAddressFunction(googleAddressPin);
                              // setGoogleAddressPin(googleAddressPin);
                              // setAddress(googleAddressPin);
                              setLatitude(lat);
                              setLongitude(lng);
                            }}
                            onMarkerDragEnd={(lat, lng) => {
                              setLatitude(lat);
                              setLongitude(lng);
                            }}
                          />
                          <div className="row mt-2">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  {" "}
                                  Latitude
                                </label>
                                <input
                                  type="text"
                                  onChange={(e) => setLatitude(e.target.value)}
                                  // value={postalCode}
                                  className="form-control"
                                  id="firstName"
                                  value={latitude}
                                  aria-describedby="emailHelp"
                                  placeholder="Name"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  {" "}
                                  Longitude
                                </label>
                                <input
                                  type="text"
                                  onChange={(e) => setLongitude(e.target.value)}
                                  // value={postalCode}
                                  className="form-control"
                                  id="firstName"
                                  value={longitude}
                                  aria-describedby="emailHelp"
                                  placeholder="Name"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form_bttn">
                            <button
                              // type="submit"
                              className="btn btn-primary submitButton saveListng"
                              onClick={(e) => saveLocation(e)}
                            >
                              <span>Save and Proceed</span>
                            </button>
                            {/* <Link
                        to="/claimBusiness"
                        className="btn btn-primary submitButton cancel_bttn"
                      >
                        <span>Cancel</span>
                      </Link> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {totalCount > 25 && (
        <div className="pagination_main">
          <Pagination
            hideDisabled
            activePage={currentPage}
            itemsCountPerPage={25}
            totalItemsCount={totalCount}
            pageRangeDisplayed={10}
            onChange={(e) => handleActivePage(e)}
          />
        </div>
      )}
      <div className="loder_div" hidden />
    </div>
  );
}

export default NoDataFound;
