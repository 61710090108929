import React, { useEffect, useState } from "react";
import Select from "react-select";
import { FiPlusCircle, FiTrash } from "react-icons/fi";
import { backendApi } from "../apiService";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Campaign from "../planning/campaign";
import Switch from "react-switch";

const AddCampaignPopup = ({ onClose, campaignGetData, editProps }) => {
  const [campaign, setCampaign] = useState("");
  const [campaignSummary, setCampaignSummary] = useState("");
  const [campaignData, setCampaignData] = useState([]);
  const [campaignData1, setCampaignData1] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    campaignInit();
    if (editProps !== "") {
      setCampaign(editProps?.campaign_name);
      setCampaignSummary(editProps?.campaign_summary);
      setIsEdit(true);
    }
  }, []);

  const campaignInit = () => {
    const postData = {
      functionName: "campaign_Listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          setCampaignData(data.dataSet);
          setCampaignData1(data.dataSet.slice(0, 10));
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };

  const closePopup = () => {
    onClose();
    document.body.className = "";
  };

  const campaignSave = () => {
    if (campaign.trim() === "") {
      alert("Please enter a campaign");
      return false;
    }

    let campaignName=campaign?.replace(/['"]/g, "\\$&");
    let summary=campaignSummary?.replace(/['"]/g, "\\$&")
    const postData = {
      functionName: "campaign_save",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      sec_user_id:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      campaign_name: campaignName,
      campaign_summary: summary
    };
    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          closePopup();
          campaignGetData();
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };

  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  const statusChanging = (data, activeCase) => {
    let postData = {
      functionName: "campaign_activeDeactive",
      is_active: activeCase,
      id: data.id,
    };
    backendApi(postData)
      .then((response) => {
        if (response?.success === "1") {
          campaignInit();
          campaignGetData();
        }
      })
      .catch((error) => {
        console.error("Error fetching version list:", error);
      });
  };
  const campaignUpdate = () => {
    let campaignName=campaign.replace(/['"]/g, "\\$&");
    let summary=campaignSummary.replace(/['"]/g, "\\$&")
    const api = {
      functionName: "campaign_update",
      id: editProps.id,
      campaign_name: campaignName,
      campaign_summary: summary,
    };
    backendApi(api)
      .then((response) => {
        if (response && response.success === "1") {
          closePopup();
          campaignGetData();
        } else {
          console.error("Error in API response:", response);
        }
      })
      .catch((error) => {
        console.error("Error occurred during API call:", error);
      });
  };
  return (
    <div className="adv_modal">
      <div className="adv_modal_inner ">
        <div className="adv_modal_header d-flex align-itesm-center">
          <h3 class="image-heading">Add Campaign</h3>
          <button
            type="button"
            className="close ml-auto"
            onClick={() => closePopup()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Tabs>
          <TabList onClick={() => setEditingIndex(-1)}>
            <Tab>Add Campaign</Tab>
            <Tab>History</Tab>
          </TabList>
          <TabPanel>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mb-2 mb-md-0">
                  <label for="exampleInputEmail1">Add Campaign</label>
                  <span className="red ml-1">*</span>{" "}
                  <div className="d-md-flex">
                    <input
                      type="text"
                      name="Campaign"
                      id="Campaign"
                      onChange={(e) => setCampaign(e.target.value)}
                      className="form-control"
                      value={campaign}
                      placeholder="Add Campaign"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mb-2 mb-md-0">
                  <label for="exampleInputEmail1">
                    Add Campaign Description
                  </label>
                  <div className="d-md-flex">
                    <input
                      type="text"
                      name="Campaign"
                      id="Campaign"
                      onChange={(e) => setCampaignSummary(e.target.value)}
                      className="form-control"
                      value={campaignSummary}
                      placeholder="Add Campaign Description"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-right">
              {isEdit ? (
                <button
                  type="submit"
                  class="btn btn-success mr-2"
                  onClick={campaignUpdate}
                >
                  Update
                </button>
              ) : (
                <button
                  type="submit"
                  class="btn btn-success mr-2"
                  onClick={campaignSave}
                >
                  Save
                </button>
              )}
              <button
                type="submit"
                class="btn btn-secondary"
                onClick={() => closePopup()}
              >
                Cancel
              </button>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="col-md-12 tableResponsive tagImageEditrListTble ">
              <div
                className="tableOuterWidth"
                style={{ width: "100%", minWidth: "700px" }}
              >
                <table className="table">
                  <thead>
                    <tr>
                      <th className="col-1">Sl </th>
                      <th className="col-2">campaign</th>
                      <th className="col-5 ">Description</th>
                      <th className="col-2">Status</th>
                      <th className="col-2">Created Date</th>
                    </tr>
                  </thead>
                  <div className="table_Outerscroll tableheight">
                    {campaignData?.map((data, i) => (
                      <tbody className="table_Outertbody">
                        <tr>
                          <td className="col-1">{i + 1}</td>
                          <td className="col-2">
                            <span>{data?.campaign_name}</span>
                          </td>
                          <td className="col-5">
                            <span>{data?.campaign_summary}</span>
                          </td>
                          <td className="col-2">
                            <Switch
                              className="switch_case"
                              checked={data.is_active === 1}
                              onChange={(checked) => {
                                statusChanging(
                                  data,
                                  data.is_active === 1 ? 0 : 1
                                );
                              }}
                            ></Switch>
                          </td>
                          <td className="col-2">
                            <div className="d-flex align-items-center">
                              {formatDate(data.created_on)}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </div>
                </table>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default AddCampaignPopup;
