import React, { useState } from "react";
import $ from "jquery";
import * as XLSX from "xlsx";

import QuestionTemplate from "../docs/q-and-a-template.xlsx";
import DragDropFile from "../audit/dragDropFile";
import { backendApi } from "../apiService";
import * as Icon from "react-feather";
import DataInput from "../home/dataInput";
import SavedQandAPreview from "./SavedQandAPreview";

function BulkAddQuestions({ locationsList, excelMime }) {
  const [bulkQuestionData, setBulkQuestionData] = useState([]);
  const [savedQandAData, setSavedQandAData] = useState([]);
  const [uniqueIdForDataset, setUniqueIdForDataset] = useState([]);
  const [previewButton, setPreviewButton] = useState(true);

  const handleFile = (file) => {
    $(".loder_div").attr("hidden", true);
    console.log(file);
    if (!excelMime.includes(file.type)) {
      alert("Please upload valid excel file");
      $(".loder_div").attr("hidden", true);
      return false;
    }
    const reader = new FileReader();

    reader.onload = (e) => {
      const fileParse = e.target.result;
      const spreadSheet = XLSX.read(fileParse, { type: "array" });
      const wsname = spreadSheet.SheetNames[0];
      const ws = spreadSheet.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { raw: false, header: 1 });
      const [header] = data;
      const sheetData = data.slice(1);
      console.log(
        "🚀 ~ file: BulkAddQuestions.jsx:35 ~ handleFile ~ sheetData:",
        sheetData
      );
      if (
        header.length != 4 &&
        (header[0] != "Reference Code" ||
          header[1] != "Location Number" ||
          header[2] != "Question" ||
          header[3] != "Answer")
      ) {
        alert("Please don't change the table headers in the template.");
        $(".loder_div").attr("hidden", true);
        return;
      }

      const refLocList = locationsList.map((loc) => loc.location_id);

      for (const [, locNum] of sheetData) {
        console.log(
          "🚀 ~ file: BulkAddQuestions.jsx:49 ~ handleFile ~ locNum:",
          locNum
        );
        if (isNaN(locNum)) {
          setPreviewButton(false);
          // alert(
          //   "Seems like some of the Location Numbers you added are not valid. Please recheck the file."
          // );
          // $(".loder_div").attr("hidden", true);
          // return;
        }
        // if (!refLocList.includes(locNum)) {
        //   alert(
        //     "One or more of the location numbers mentioned is not available at the moment."
        //   );
        //   $(".loder_div").attr("hidden", true);
        //   return;
        // }
      }
      setBulkQuestionData(sheetData);
    };
    reader.readAsArrayBuffer(file);
    $(".loder_div").attr("hidden", true);
  };

  const handleBulkAddQuestiontoDB = async () => {
    // if (previewButton == false) {
    //   alert("Please fill location numbers before proceeding.");
    //   return false;
    // }
    $(".loder_div").attr("hidden", false);
    if (bulkQuestionData.length <= 0) {
      alert("You haven't added any questions.");
      $(".loder_div").attr("hidden", true);
      return;
    }
    const questionArray = [];
    for (const [, location_id, question, answer] of bulkQuestionData) {
      questionArray.push({ location_id, question, answer });
    }
    if (!questionArray.length) {
      alert("Something went wrong, Try again.");
      $(".loder_div").attr("hidden", true);
      return;
    }
    try {
      const { success, uniqueId } = await backendApi({
        functionName: "saveQuestionAndAnswerBulk",
        site_id:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
        account_id: sessionStorage.getItem("account_id"),
        created_by: sessionStorage.getItem("userId"),
        objectArray: questionArray,
      });
      if (success != "1") {
        alert("Adding Question and Answer stopped abruptly!");
        $(".loder_div").attr("hidden", true);
        return;
      }
      setUniqueIdForDataset(uniqueId);
      const savedQandA = await backendApi({
        functionName: "listQueAndAnsByUniqueId",
        uniqueId: uniqueId,
      });
      console.log(savedQandA);
      if (savedQandA.success != "1") {
        alert("Something went wrong in fetching uploaded Q and A!");
        $(".loder_div").attr("hidden", true);
        return;
      }
      setSavedQandAData(savedQandA.data);
    } catch (error) {
      alert("Adding Question and Answer stopped abruptly!");
      $(".loder_div").attr("hidden", true);
      console.trace(error);
      return;
    }
    setBulkQuestionData([]);
    $(".loder_div").attr("hidden", true);
  };
  const resetFileInput = () => {
    window.location.reload();
  };
  const fillLocationNumbers = () => {
    console.log(
      "🚀 ~ file: BulkAddQuestions.jsx:144 ~ fillLocationNumbers ~ locationsList:",
      locationsList
    );
    console.log(
      "🚀 ~ file: BulkAddQuestions.jsx:147 ~ fillLocationNumbers ~ bulkQuestionData:",
      bulkQuestionData
    );
    // const locationData = bulkQuestionData.map((obj) => {
    //   return {
    //     ...obj,
    //     location_id: locationsList
    //       .filter((location) => location.storeCode == obj[0])
    //       .map((id) => id.location_id),
    //   };
    // });

    const updated2DArray = bulkQuestionData.map((subarray) => {
      const storeCode = subarray[0]?.trim(); // Get the storeCode from the subarray
      console.log(
        "🚀 ~ file: BulkAddQuestions.jsx:161 ~ updated2DArray ~ storeCode:",
        storeCode
      );

      // Use Array.prototype.filter() to find matching location objects
      const matchingLocations = locationsList.filter(
        (location) => location.storeCode === storeCode
      );
      console.log(
        "🚀 ~ file: BulkAddQuestions.jsx:170 ~ updated2DArray ~ locationsList:",
        locationsList
      );
      console.log(
        "🚀 ~ file: BulkAddQuestions.jsx:170 ~ updated2DArray ~ matchingLocations:",
        matchingLocations
      );

      // Use Array.prototype.map() to extract the location_id values
      const locationIds = matchingLocations.map(
        (location) => location.location_id
      );

      // Flatten the locationIds array if it's an array
      // const flattenedLocationIds = Array.isArray(locationIds[0])
      //   ? locationIds.flat()
      //   : locationIds;

      // Add the location_ids to the subarray at index 1
      subarray[1] = locationIds[0];

      return subarray;
    });
    console.log(
      "🚀 ~ file: BulkAddQuestions.jsx:175 ~ updated2DArray ~ updated2DArray:",
      updated2DArray
    );
    setBulkQuestionData(updated2DArray);
    setPreviewButton(true);
  };
  return (
    <div className="row">
      {!savedQandAData.length ? (
        <div className="col-md-12 order-1">
          <div className="file_upload_outer mt-3 container">
            <DragDropFile handleFile={handleFile}>
              <div>
                <div className="form-group formBottom" id="form-fileupload">
                  <DataInput handleFile={handleFile} />
                </div>
              </div>
            </DragDropFile>
            <div
              className="col-lg-3 col-md-12 TemplateDowloadDiv"
              title="Download template"
              //   onClick={() => this.downloadTemplate()}
            >
              <a
                href={QuestionTemplate}
                // className="TemplateDowloadDiv d-flex justify-content-end"
                title="Download template"
              >
                <Icon.Download /> Download template
              </a>
            </div>
            {bulkQuestionData.length ? (
              <div>
                <div className="col-md-12 tableResponsive">
                  <table
                    className="table fileupload-table mb-0"
                    style={{ minWidth: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th className="col-2">Reference Code</th>
                        <th className="col-2">Location Number</th>
                        <th className="col-4">Question</th>
                        <th className="col-4">Answer</th>
                      </tr>
                    </thead>
                    {/* TODO: uncomment */}
                    {/* <div className="table_Outerscroll"> */}
                    <tbody className="table_Outertbody">
                      {bulkQuestionData.map(
                        (
                          [referenceCode, locationNumber, eachQuestion, answer],
                          index
                        ) => (
                          <tr key={index}>
                            <td className="col-2">{referenceCode}</td>
                            <td className="col-2">{locationNumber}</td>
                            <td className="col-4">{eachQuestion}</td>
                            <td className="col-4">{answer}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                    {/* </div> */}
                  </table>
                </div>

                <div className="button-wrapper mt-0 mb-4">
                  <button
                    className="btn-submit ml-3"
                    onClick={() => fillLocationNumbers()}
                  >
                    Fill Location Numbers
                  </button>
                  {previewButton && (
                    <button
                      className="btn-submit ml-3"
                      onClick={() => handleBulkAddQuestiontoDB()}
                      // disabled={!previewButton}
                    >
                      Preview
                    </button>
                  )}
                  <button
                    className="btn-cancel ml-3"
                    onClick={() => resetFileInput()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      {savedQandAData.length ? (
        <SavedQandAPreview
          setSavedQandAData={setSavedQandAData}
          savedQandAData={savedQandAData}
          uniqueIdForDataset={uniqueIdForDataset}
          locationsList={locationsList}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default BulkAddQuestions;
