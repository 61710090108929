import React, { useState, useEffect } from "react";
import SaveAndValidation from "./settingsSaveAndValidation"
function SettingGoogleBusnessProfile(props) {
  var orgadmin = window.sessionStorage.getItem("role") == "ORG_ADMIN";
  const [GBP_username, setGBP_username] = useState((props.listingValuesForPassing.GBP_username != "undefined") ? props.listingValuesForPassing.GBP_username : "");
  const [GBP_password, setGBP_password] = useState((props.listingValuesForPassing.GBP_password != "null ") ? props.listingValuesForPassing.GBP_password : "");
  const [google_address_pin, setgoogle_address_pin] = useState((props.listingValuesForPassing.google_address_pin != "null ") ? props.listingValuesForPassing.google_address_pin : "");
  useEffect(() => {
    if (props.listingValuesForPassing.GBP_username && (props.listingValuesForPassing.GBP_username != "null" || props.listingValuesForPassing.GBP_username != "undefined")) {
      setGBP_username((props.listingValuesForPassing.GBP_username))
    } else { setGBP_username("") }
    ////////////////
    if (props.listingValuesForPassing.GBP_password && (props.listingValuesForPassing.GBP_password != "null" || props.listingValuesForPassing.GBP_password != "undefined")) {
      setGBP_password((props.listingValuesForPassing.GBP_password))
    } else { setGBP_password("") }
    //////////////////////
    if (props.listingValuesForPassing.google_address_pin && (props.listingValuesForPassing.google_address_pin != "null" || props.listingValuesForPassing.google_address_pin != "undefined")) {
      setgoogle_address_pin((props.listingValuesForPassing.google_address_pin))
    } else { setgoogle_address_pin("") }
    ////////////////
  }, [props.listingValuesForPassing.GBP_username, props.listingValuesForPassing.GBP_password, props.listingValuesForPassing.google_address_pin
  ])
  return (
    
      <div className="row">
        <div className="col-md-12">
        <div className="user_formdiv">
          <div className="userform_head">
            Google Business Profile
          </div>
          <div className="row userrow_form">
            <div className="col-md-4">
              <label className="col-form-label">GB UserName</label>
            </div>
            <div className="col-md-1">
              <span>:</span>
          </div>
            <div className="col-md-7">
              <input
                type="text"
                id="username"
                value={GBP_username}
                onChange={(e) => {
                  setGBP_username(e.target.value);
                }}
                className="form-control"
                placeholder="GB UserName"
              />
            </div>
          </div>
          <div className="row userrow_form">
            <div className="col-md-4">
              <label className="col-form-label">GB Password</label>
            </div>
            <div className="col-md-1">
              <span>:</span>
          </div>
            <div className="col-md-7">
              <input
                type="password"
                id="password"
                value={GBP_password}
                onChange={(e) => {
                  setGBP_password(e.target.value);
                }}
                className="form-control"
                placeholder="GB Password"
              />
            </div>
          </div>
          <div className="row userrow_form">
            <div className="col-md-4">
              <label className="col-form-label">
                Google Address Pin
              </label>
            </div>
            <div className="col-md-1">
              <span>:</span>
          </div>
            <div className="col-md-7">
              <input
                type="text"
                id="addresspin"
                value={google_address_pin}
                onChange={(e) => {
                  setgoogle_address_pin(e.target.value);
                }}
                className="form-control"
                placeholder="Google Address Pin"
              />
            </div>
          </div>
          <SaveAndValidation
             getUserData={() => props.getUserData()}
            valueForSaved={[GBP_username, GBP_password, google_address_pin]}
            saveMode={"googleBusnessProfile"}
            organizationId={props.organizationId} />
        </div>
      </div>
      </div>
    
  );
}
export default SettingGoogleBusnessProfile;
