import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import * as Icon from "react-feather";
import moment from "moment";
import { backendApi, apiCallingPost } from "../apiService";
import $ from "jquery";
function ClaimCompleted() {
  const [dataListFromApi, setdataListFromApi] = useState([]);
  useEffect(() => {
    listPushFullData();
  }, []);
  const listPushFullData = () => {
    var req = {
      functionName: "listPushFullData",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((result) => {
      setdataListFromApi(result.data);
      $(".loder_div").attr("hidden", true);

      console.log(result, "resultresult");
      // if (result.success === "1") {
      //   navigate("/claim_business/PushNodataFromGoogle", {
      //     state: {
      //       auditClaimData: data,
      //     },
      //   });
      // } else {
      // }
    });
    // navigate("/claim_business/PushNodata", {
    //   state: {
    //     auditClaimData:data,
    //   },
    // })
  };
  const pushtoNextPage = (data) => {
    window.location.href = `/pushGBPData`;
  };
  return (
    <div className="container">
      <div className="row row-search"></div>
      <hr className="mt-0"></hr>
      <div className="col-md-12 tableResponsive">
        <div className="tableOuterWidth table_width_md">
          <table className="table">
            <thead>
              <tr>
                <th className="col-1">Sl No</th>
                {/* <th className="col-1">Id</th> */}
                <th className="col-2">Id</th>
                <th className="col-3">Created On</th>
                <th className="col-4">Account Id</th>
                <th className="col-3">Action</th>
              </tr>
            </thead>
            <div class="table_Outerscroll">
              {dataListFromApi.map((data, i) => (
                <tbody className="table_Outertbody">
                  <tr>
                    <td className="col-1">{i + 1}</td>
                    <td className="col-2">{data.UID}</td>
                    <td className="col-3">
                      {moment(data.created_on).format("DD-MM-YYYY")}
                    </td>
                    <td className="col-4"> {data.account_id}</td>
                    <td className="col-1">
                      <div className="button-wrapper mt-0 mb-4">
                        <Icon.Eye
                          onClick={() => {
                            pushtoNextPage(data);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))}
            </div>
          </table>
          {/* <tr id="nodata">
                  <div className="content-sub-wrapper col-md-12">
                    <div className="content-sub">
                      <p>No Data Found</p>
                    </div>
                  </div>
                </tr> */}
        </div>
      </div>

      <div className="loder_div" hidden />
    </div>
  );
}

export default ClaimCompleted;
