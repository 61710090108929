import { useState, useEffect } from "react";
import { backendApi } from "../apiService";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import { Link } from "react-router-dom";
import deleteImg from "../images/close.png";
import Checked from "../images/checked.png";
import * as Icon from "react-feather";
import rolesimage from "../images/left_icons/roles.png";
import $ from "jquery";

var newRoleObject = null;
function ModuleList() {
  const [moduleList, setModuleList] = useState([]);
  const [newModule, setNewModule] = useState(false);
  const [newModuleName, setNewModuleName] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchModuleList, setSearchModuleList] = useState([]);
  const [editModuleName, setEditModuleName] = useState("");
  const [editModuleId, setEditModuleId] = useState("");
  useEffect(() => {
    getModuleList();
  }, []);
  const getModuleList = () => {
    var req = {
      functionName: "moduleList",
    };
    $(".loder_div").attr("hidden", false);
    backendApi(req).then((response) => {
      $(".loder_div").attr("hidden", true);
      console.log(response);
      setModuleList(response.result);
      setSearchModuleList(response.result);
    });
  };

  const updateModuleStatus = (id, status) => {
    var statusTrue =
      $('input[name="status' + id + '"]:checked').val() === "on" ? true : false;
    var req = {
      functionName: "moduleStatus",
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      status: statusTrue,
      moduleId: id,
    };
    backendApi(req).then((data) => {
      if (data.success === "1") {
        alert("Status Changed Successfully");
        getModuleList();
      } else {
        alert("Something went wrong while updating status");
      }
    });
  };
  const addModule = (id) => {
    if (newModuleName === "") {
      alert("Enter module name");
      return false;
    }
    var functionName = "";
    if (id !== "") {
      functionName = "moduleEdit";
    } else {
      functionName = "moduleAdd";
    }
    var req = {
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      moduleName: newModuleName,
      moduleId: id,
      functionName: functionName,
    };
    console.log(req);
    backendApi(req).then((response) => {
      if (response.success === "1") {
        if (id) {
          alert("Module updated successfully");
        } else {
          alert("New module added successfully");
        }
        setNewModule(false);
        setNewModuleName("");
        setEditModuleId("");
        getModuleList();
      }
    });
  };
  const searchModules = () => {
    if (searchValue !== "") {
      let search = moduleList.filter((x) =>
        x.module_name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setModuleList(search);
    } else {
      setModuleList(searchModuleList);
    }
  };
  const editModule = (id, name) => {
    setEditModuleId(id);
    setEditModuleName(name);
  };
  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header heading="Modules List" headerImage={rolesimage} />
        <div className="container-fluid">
          <div className="row justify-content-between mb-3 mx-0">
            <div className="searchSection">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by keyword"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-search"
                    type="button"
                    onClick={() => searchModules()}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className="">
              <button
                className="btn-addNew ml-2"
                onClick={() => {
                  setNewModule(true);
                  setEditModuleId("");
                  setEditModuleName("");
                }}
              >
                Add New Module
              </button>
              <a href="/roleList">
                <button className="add_newmodule">Add Roles</button>
              </a>
            </div>
          </div>

          <div className="col-md-12 tableResponsive">
            <div className="tableOuterWidth table_width_sm">
              <table className="table">
                <thead>
                  <tr>
                    <th className="col-1">Sl.No.</th>
                    <th className="col-7">Module Name</th>
                    {/* <th>Roles</th> */}
                    <th className="col-2">Action</th>
                    <th className="col-2"></th>
                  </tr>
                </thead>
                <div class="table_Outerscroll">
                  {moduleList ? (
                    <>
                      {moduleList.map((data, i) => (
                        <tbody className="table_Outertbody">
                          <tr>
                            <td className="col-1">{i + 1}</td>
                            <td className="col-7">{data.module_name}</td>
                            <td className="col-2">
                              <button
                                type="button"
                                className="edit_btnIcon border-0 text-primary"
                                onClick={() => {
                                  setNewModule(true);
                                  editModule(data.moduleId, data.module_name);
                                }}
                              >
                                <Icon.Edit style={{ width: "18px" }} />
                              </button>
                            </td>
                            {/* <td className="col-3">
                          <label className="switch_items">
                            <input
                              id={"status" + data.id}
                              name={"status" + data.id}
                              type="checkbox"
                              onClick={() => updateModuleStatus(data.moduleId)}
                              defaultChecked={
                                data.is_active === 1 ? true : false
                              }
                            />
                            <span className="switch round"></span>
                          </label>
                        </td> */}
                            <td className="col-2">
                              <Icon.Trash className="trashGraph" />
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </table>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {/* {showModul ? (
        <div className="module_popup">
          <div className="module_innerpopup">
            <div className="module_close">
              <Icon.XSquare onClick={() => ShowPopup(false)} />
            </div>
            <div className="module_header">Product Category</div>
            <div className="module_body">
              <div className="module_popupsearch_wrpr">
                <div className="module_popupsearch">
                  <input
                    id="searchText"
                    type="text"
                    placeholder="Search"
                    onKeyUp={() => filterRole()}
                  />
                  <button type="button" onClick={() => addRole()}>
                    Add
                  </button>
                </div>
                <ul
                  id="dropdownRole"
                  className="module_popupsearch_list"
                  hidden
                >
                  {roleList ? (
                    <>
                      {roleList.map((data) => (
                        <li value={data.id} onClick={(e) => setRoleIds(data)}>
                          {data.display_name}
                        </li>
                      ))}
                    </>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Sl.no</th>
                      <th>Role</th>
                      <td>Remove</td>
                    </tr>
                  </thead>
                  <tbody>
                    {roleData.length > 0 ? (
                      <>
                        {roleData.map((data) => (
                          <tr>
                            <td>{data.id}</td>
                            <td>{data.display_name}</td>
                            <td>
                              <div className="remove_divs remove_icon">
                                <Icon.XSquare
                                  onClick={() =>
                                    removeRole(roleModuleId, data.id)
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )} */}
      {newModule ? (
        <div className="module_popup">
          <div className="module_innerpopup">
            <div className="module_close">
              <Icon.XCircle onClick={() => setNewModule(false)} />
            </div>
            {editModuleId ? (
              <div className="module_header">Edit Module Name</div>
            ) : (
              <div className="module_header">Add new Module</div>
            )}
            <div className="module_body">
              <div className="module_popupsearch_wrpr">
                <div className="module_popupsearch">
                  <input
                    id="searchText"
                    type="text"
                    placeholder="Enter New Module Name"
                    onChange={(e) => setNewModuleName(e.target.value)}
                    defaultValue={editModuleName}
                  />
                  <button
                    type="button"
                    className="btn btn-save mb-1"
                    onClick={() => addModule(editModuleId)}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="loder_div" hidden />
    </div>
  );
}
export default ModuleList;
