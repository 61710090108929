import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import Header from "../layout/header.jsx";
import LeftMenu from "../layout/leftMenu.jsx";
import cloudImg from "../images/cloud-computing.png";
import deleteImg from "../images/Group122.png";
import DragDropFile from "../audit/dragDropFile";
import DataInput from "../audit/dataInput";
import { backendApi, pythonBackendApi } from "../apiService";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import uploadImg from "../images/upload.png";
import $ from "jquery";
import closeImg1 from "../images/close1.png";
import * as Icon from "react-feather";
import exportFromJSON from "export-from-json";
import moment from "moment";
const excelMime = [
  "application/vnd.ms-excel",
  "application/msexcel",
  "application/x-msexcel",
  "application/x-ms-excel",
  "application/x-excel",
  "application/x-dos_ms_excel",
  "application/xls",
  "application/x-xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
class WordCloud extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      file: null,
      buffer: null,
      convertText: "",
      sourceText: "",
      status: "",
      src: "",
      imageLanguage: "",
      imageLanguageLabel: "",
      translateLanguage: "",
      languageListImage: [],
      languageListTTS: [],
      uploaded: false,
      dataList: [],
      excelData: [],
      googleData: [],
      keyword: [],
      keywordLength: 0,
      getMasterdata: [],
    };
  }
  componentDidMount() {
    // this.getDatas();
    //this.getMasterGBPAuditData();
  }
  // getMasterGBPAuditData() {
  //   var req = {
  //     functionName: "getAuditMasterData",
  //     userId:
  //       window.sessionStorage.getItem("switchUserId") === ""
  //         ? window.sessionStorage.getItem("userId")
  //         : window.sessionStorage.getItem("switchUserId"),
  //     siteId:
  //       window.sessionStorage.getItem("switchingOrgId") === ""
  //         ? window.sessionStorage.getItem("organizationId")
  //         : window.sessionStorage.getItem("switchingOrgId"),
  //     role:
  //       window.sessionStorage.getItem("switchUserRole") === ""
  //         ? window.sessionStorage.getItem("role")
  //         : window.sessionStorage.getItem("switchUserRole"),
  //   };
  //   $(".loder_div").attr("hidden", false);
  //   backendApi(req).then((data) => {
  //     if (data.success === "1") {
  //       $(".loder_div").attr("hidden", true);
  //       this.setState({
  //         uploaded: false,
  //         excelData: data.data,
  //       });
  //     } else {
  //       alert("Something went wrong in getting Master Audit Data");
  //       $(".loder_div").attr("hidden", true);
  //     }
  //   });
  // }

  render() {
    return (
      <div className="main_wrapper">
        <div className="wordCloud">
          <div id="my_canvas" style={{ height: 400, width: "100%" }}></div>
        </div>
      </div>
    );
  }
}

export default WordCloud;
