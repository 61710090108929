import React, { Component } from "react";
import "../css/bootstrap.css";
import "../css/style.css";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import moment from "moment";
import { TagsInput } from "react-tag-input-component";
import cloudImg from "../images/cloud-computing.png";
import deleteImg from "../images/Group122.png";
import DragDropFile from "../audit/dragDropFile";
import Analysisimage from "../images/left_icons/analysis.png";
import DataInput from "../audit/dataInput";
import { backendApi, pythonBackendApi } from "../apiService";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import uploadImg from "../images/upload.png";
import $ from "jquery";
import closeImg1 from "../images/close1.png";
import * as Icon from "react-feather";
import exportFromJSON from "export-from-json";
const excelMime = [
  "application/vnd.ms-excel",
  "application/msexcel",
  "application/x-msexcel",
  "application/x-ms-excel",
  "application/x-excel",
  "application/x-dos_ms_excel",
  "application/xls",
  "application/x-xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
class CompetiterAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      file: null,
      buffer: null,
      convertText: "",
      sourceText: "",
      status: "",
      src: "",
      imageLanguage: "",
      imageLanguageLabel: "",
      translateLanguage: "",
      languageListImage: [],
      languageListTTS: [],
      uploaded: false,
      dataList: [],
      excelData: [],
      googleData: [],
      keyword: [],
      keywordLength: 0,
      getMasterdata: [],
      orgId:"",
      organizationList:[]
    };

    this.setSelected = this.setSelected.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.onSave = this.onSave.bind(this);
  }
  getOrganizationData = () => {
    const req = {
      functionName: "organizationList",
      role: window.sessionStorage.getItem("role"),
      organization: window.sessionStorage.getItem("organization"),
    };
    backendApi(req).then((data) => {
      console.log(data);
      if (data.success === "1") {
        this.setState({
          organizationList: data.result,
        });
    
      }
    });
  };
  componentDidMount() {
    // this.getDatas();
    this.getCompitetorMasterData();
    this.getOrganizationData()
  }
  getCompitetorMasterData() {
    var req = {
      functionName: "getCompitetorMasterData",
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      role:
        window.sessionStorage.getItem("switchUserRole") === ""
          ? window.sessionStorage.getItem("role")
          : window.sessionStorage.getItem("switchUserRole"),
    };

    $(".loder_div").attr("hidden", false);
    backendApi(req).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        this.setState({
          uploaded: false,
          getMasterdata: data.data,
        });
      }
    });
  }

  OutTable = () => {
    return (
      <div className="col-md-12 table-responsive">
        <div
          style={{
            maxHeight: "400px",
            overflow: "scroll",
            color: "#fff",
          }}
        >
          <table
            className="table table-striped fileupload-table"
            style={{ background: "#fff" }}
          >
            <thead>
              <tr>
                <th>Ref Code</th>
                <th>Name</th>
                <th>Address</th>
                <th>Search</th>
              </tr>
            </thead>

            <tbody>
              {this.state.dataList.map((r, i) => (
                <tr key={i}>
                  <td>{r.ReferenceCode}</td>
                  <td>{r.LocationName}</td>
                  <td>{r.Address}</td>
                  <td>{r.Search}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="text-right">
          <button className=" btn-save ml-3" onClick={this.onSave}>
            Upload
          </button>
          <button
            className=" btn-cancel ml-3"
            onClick={() => window.location.reload()}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  migrateCompetitor(masterId) {
    $(".loder_div").attr("hidden", false);
    var req = {
      method: "POST",
      body: JSON.stringify({
        masterId: masterId,
        userId:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
      }),
    };

    pythonBackendApi("runCompetitor", req).then((data) => {
      if(data.success){
        $(".loder_div").attr("hidden", true);
        alert("Competitor Started Successfully")
      // alert("competitor  Successfully");
        // this.getCompitetorMasterData();
        window.sessionStorage.setItem("pageNumbercompe", 1)
        window.sessionStorage.setItem("offsetcompe", 0)
        window.location.href = "/competiterDetails/" + masterId
      }
      else{
        $(".loder_div").attr("hidden", true);
        alert("competitor Failed");
      }

      //  window.location.reload()
    });
  }

  setSelected(val) {
    this.setState({
      keyword: val,
    });
  }
  comparecompetiter(masterId) {
    var apidata = {
      method: "POST",
      body: JSON.stringify({
        masterId: masterId,
        userId:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
      }),
    };
    $(".loder_div").attr("hidden", false);
    pythonBackendApi("compareCompitetorData", apidata).then((data) => {
      console.log("data=========", data);
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);

        this.getCompitetorMasterData();
      } else {
        alert("Failed");
      }
    });
  }
  onSave() {
    $("#editCommentPopup").attr("hidden", false);
    $("#editCommentPopupHeader").text("Insert Comment");
  }
  saveAndStartUpload() {
    if ($("#editCommentPopupTextBox").val() === "") {
      alert("Enter comment please");
      return false;
    }
    var apidata = {
      method: "POST",
      body: JSON.stringify({
        competitor: this.state.dataList,
        userId:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),     
        comment: this.state.orgId,
        fileName: this.state.fileName,
      }),
    };
    $(".loder_div").attr("hidden", false);
    pythonBackendApi("uploadCompetitor", apidata).then((data)=>{
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        alert("Uploaded Successfully");
        console.log("...................",data)
        window.location.reload()
        // window.sessionStorage.setItem("pageNumbercompe", 1)
        // window.sessionStorage.setItem("offsetcompe", 0)
//  window.location.href = "/competiterDetails/" + data.masterId
      } else {
        alert("Something went wrong in uploading file");
      }
    });
    $("#editCommentPopup").attr("hidden", true);

  }
  async handleFile(files, e) {
    var buffer;
    if (files) {
      if (!excelMime.includes(files.type)) {
        alert("Please upload valid excel file");
        return false;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          isLoader: 0,
        });
        /* Parse data */
        const ab = e.target.result;
        const wb = XLSX.read(ab, { type: "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { raw: false, header: 1 });
        let DataList = [];
        let DataListTemp = [];

        let keys = [];
        keys = data[0];
        let record = {};
        for (let i = 1; i < data.length; i++) {
          let dd = data[i];
          if (dd.length > 0) {
            for (let j = 0; j < dd.length; j++) {
              record[keys[j].trim().split(" ").join("")] =
                dd[j] === undefined ? "" : dd[j].toString().trim();
            }

            let index = DataList.findIndex(
              (d) =>
                d.ReferenceCode === record.ReferenceCode &&
                d.LocationName === record.LocationName &&
                d.Address === record.Address &&
                d.Search === record.Search
            );
            if (index === -1) {
              DataList.push(record);
            }
            DataListTemp.push(record);
            record = {};
          }
        }
        /* Update state */
        this.setState(
          {
            dataList: DataList,
            fileName: files.name,
            uploaded: true,
            isLoader: 1,
          },
          () => {
            if (DataListTemp.length !== DataList.length) {
              alert("File contains duplicate record(s)");
            }
          }
        );
      };
      reader.readAsArrayBuffer(files);
    }
  }
  competitordata  (){
    window.sessionStorage.setItem("pageNumbercompe", 1)
        window.sessionStorage.setItem("offsetcompe", 0)
  }
  downloadTemplate() {
    let header = ["Reference Code", "Location Name", "Address", "Search"];
    let data = [];
    let date =
      "Audit_" +
      moment(new Date())
        .format("DD-MM-YYYY hh:mm:ss a")
        .toString()
        .replaceAll("-", "_")
        .replaceAll(" ", "_")
        .replaceAll(":", "_");
    const workbook = XLSX.utils.book_new();
    console.log(workbook, "workbookworkbookworkbook");
    XLSX.utils.book_append_sheet(workbook, data, date);
    XLSX.utils.sheet_add_aoa(data, [header], { origin: "A1" });
    XLSX.writeFile(workbook, date + ".xlsx", {
      compression: true,
    });
  }
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu></LeftMenu>
        <div className="userListWrapper">
          <Header heading="Competitor Analysis" headerImage={Analysisimage}  />

          {/* <div className="dash_heading">
            <div className="leftdash_heading">
              <Icon.Home />
              <span>Review</span>
            </div>
            <div className="rightdash_heading">
              <ul>
                <li>
                  <Icon.User />
                  <div className="dash_listdrop">
                    <ul>
                      <li>
                        <a href="">
                          <Icon.Settings />
                          Settings
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <Icon.LogOut />
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div> */}
           <div
              className="col-md-12 downloadTemplateDiv"
              onClick={() => this.downloadTemplate()}
            >
              <Icon.Download /> Download template
            </div>
          <div className="user_listdiv">
            <div className="file_upload_outer">
              <DragDropFile handleFile={this.handleFile}>
                <div className="form-group formBottom" id="form-fileupload">
                    <DataInput handleFile={this.handleFile} />
                </div>
              </DragDropFile>
              {this.state.uploaded === true && this.OutTable()}
            </div>
            {/* <div className="file_upload_outer">
              <div className="file-upload-border">
                <div className="file-upload-div">
                  <TagsInput
                    key={this.state.keywordLength}
                    // value={this.state.keyword}
                    onChange={this.setSelected}
                    name="keywords"
                    placeHolder="Enter Competitor"
                    style={{ width: "100%" }}
                  />
                  <em>Press Enter to Add New Competitor</em>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <button
                      id="proceedBtn"
                      className="ocr_startbtn"
                      type="button"
                      onClick={() => this.migrateCompetitor()}
                    >
                      Process
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="ocr_progress_bar"></div>
            <div className="container reprocessing_wrapr">
              <div className="row table_height">
                <div className="table-responsive">
                  <table className="table table-striped audit_table">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Competitor Name</th>
                        <th>Created On</th>
                        <th>Created </th>
                        <th>Actions</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.getMasterdata.map((data, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                
                          <td>
                            {data.competitor
                              .toString()
                              .replace(/[\[\]']+/g, "")}
                          </td>
                          <td>{data.created_on}</td>
                          <td>{data.createdBy}</td>
                          {/* <td>
                            <div class="create_popst_btn01">
                          
                              <div class="create_popst_btn01">
                                <button
                                  id="txtFile"
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={(e) => {
                                    this.migrateCompetitor(data.id);
                                  }}
                                >
                                  Process
                                </button>
                              </div>
                              <div class="create_popst_btn01">
                                <button
                                  id="txtFile"
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={(e) => {
                                    this.comparecompetiter(data.id);
                                  }}
                                >
                                  Compare
                                </button>
                              </div>
                            </div>
                          </td> */}
                          <td>                             <div class="create_popst_btn01">
                                {/* <button type="button" class="btn btn-primary">
                            button  
                          </button> */}
                                {data.status === "" ? (
                                  <div class="create_popst_btn01">
                                    <button
                                      type="button"
                                      class="btn btn-primary"
                             onClick={(e) => {
                                    this.migrateCompetitor(data.id);
                                  }}
                                    >
                                      Start
                                    </button>
                                  </div>
                                ) : data.status === "Started" ? (
                                  <div class="create_popst_btn01">
                                    <button
                                      type="button"
                                      class="btn btn-secondary"
                                      onClick={(e) => {
                                        this.comparecompetiter(data.id);
                                      }}
                                    >
                                      Compare
                                    </button>
                                  </div>
                                ) : data.status === "Compared" ? (
                                  <div class="create_popst_btn01">
                                    <button
                                      type="button"
                                      class="btn btn-secondary"
                                      onClick={(e) => {
                                        this.comparecompetiter(data.id);
                                      }}
                                    >
                                      Compare
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div></td>
                          <td>
                            <Link
                            onClick={()=>this.competitordata()}
                              to={"/competiterDetails/"+data.id }
                              state={{
                                tableId: data.id,
                                visibility: data.visibility,
                              }}
                            >
                          <Icon.Eye />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div className="reprocessing_btndiv">
                <div className="repro_btn">
                  <button
                    id="txtFile"
                    type="button"
                    className="btn-download"
                    onClick={(e) => {
                      this.migrateReview();
                    }}
                  >
                    Migrate Review
                  </button>
                  <button
                    id="txtFile"
                    type="button"
                    className="btn-download"
                    onClick={(e) => {
                      this.runFraud();
                    }}
                  >
                    Run Fraud
                  </button>
                  <button
                    id="txtFile"
                    type="button"
                    className="btn-download"
                    onClick={(e) => {
                      this.downloadExcel();
                    }}
                  >
                    Download Excel
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div id="editCommentPopup" className="module_popup" hidden>
          <div className="module_innerpopup">
            <div className="module_close">
              <Icon.XCircle
                onClick={() => {
                  $("#editCommentPopup").attr("hidden", true);
                }}
              />
            </div>
            <div id="editCommentPopupHeader" className="module_header"></div>
            <div className="module_body">
              <div className="module_popupsearch_wrpr">
                {/* <div className="module_popupsearch">
                  <input
                    id="editCommentPopupTextBox"
                    type="text"
                    placeholder="Comments"
                  />
                </div> */}
                  <div className="py-3">
               <div class="did-floating-label-content">
                    
                    <select

                      id="organisationName"
                      class="did-floating-select"
                      onChange={(e)=>this.setState({
                        orgId:e.target.value,

                      })}

                    >
       
                      <option value="">Select</option>
                      {this.state.organizationList.map((data, i) => (
                 
                        <option
                        
                          key={i + 1}
                      label={data.organisation_name}
                          value={data.id}
                        >{data.organisation_name}
                    
                        </option>
                        
                      ))}
                    </select>
                    <label class="did-floating-label">Organization</label>
                  </div>
               </div>
                <div className="module_popupsearchBtn">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.saveAndStartUpload()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="loder_div" hidden />
        <Footer/>
      </div>
    );
  }
}

export default CompetiterAnalysis;
