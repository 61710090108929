import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Header from "../layout/header.jsx";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu.jsx";
import Auditimage from "../images/left_icons/audit.png";
import AuditDetails from "./visibility_Graph.jsx";
import MasterData from "./accuracy_Graph.jsx";

export default function MergeIndex() {
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    window.sessionStorage.getItem("pageIndexMergeAudit") !== ""
      ? setTabIndex(
          parseInt(window.sessionStorage.getItem("pageIndexMergeAudit"))
        )
      : setTabIndex(0);
  });
  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header heading="Analytics" headerImage={Auditimage} />
        <div className="container-fluid">
          <Tabs
            selectedIndex={tabIndex}
            className="tabs-main"
            onSelect={(index) => {
              setTabIndex(index);
              window.sessionStorage.setItem("pageIndexMergeAudit", index);
            }}
          >
            <TabList>
              <Tab>Visibility</Tab>
              <Tab>Accuracy</Tab>
            </TabList>

            <TabPanel>
              <AuditDetails />
            </TabPanel>
            <TabPanel>
              <MasterData />
            </TabPanel>
          </Tabs>
        </div>
        <Footer />
      </div>
    </div>
  );
}
