import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import exportFromJSON from "export-from-json";
import { backendApi, pythonBackendApi } from "../apiService";
import Header from "../layout/header";
import Footer from "../layout/footer";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import Auditimage from "../images/left_icons/audit.png";
import LeftMenu from "../layout/leftMenu";
// import ReactPaginate from "react-paginate";
import Pagination from "react-js-pagination";
// import "../css/bootstrap.css";
import ScaleLoader from "react-spinners/ScaleLoader";
import $ from "jquery";
import dataProcessImg from "../images/dataProcess.gif";
const AuditDetails = () => {
  const location = useLocation();
  let pathname = location.pathname;
  var path = pathname.split("/")[2];
  console.log(path, "pathpathpath");
  const [tableId, settableId] = useState(path);
  const [count, setcount] = useState("");
  const [excelData, setExcelData] = useState([]);
  const [excelDatasort, setExcelDatasort] = useState([]);
  const [googleData, setGoogleData] = useState([]);
  const [totalComplete, setTotalComplete] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [finalarray, setfinalarray] = useState([]);
  const [AuditStatusStarted, setAuditStatusStarted] = useState("");
  const [popupData, setPopupData] = useState("");
  const [AuditStatusCompleted, setAuditStatusCompleted] = useState("");
  const [AuditStatusFailed, setAuditStatusFailed] = useState("");
  const [AuditStatusInProgress, setAuditStatusInProgress] = useState("");
  const [
    AuditStatusInProgressCountSuccsess,
    setAuditStatusInProgressCountSuccsess,
  ] = useState([]);
  const [AuditStatusInProgressCountFail, setAuditStatusInProgressCountFail] =
    useState([]);
  const [pageOffset, setPageOffset] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [CountStatus, setCountStatus] = useState("");
  const [blank, setblank] = useState("");
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    var offset;
    let fromdata = $("#fromData").val();
    let todata = $("#toData").val();
    let dropdowndata = $("#statusValue").find("option:selected").val();
    if (pageNumber === "1") {
      offset = "0";
    } else {
      offset = parseInt(pageNumber - 1) * 25;
      console.log("offset", offset);
    }
    setActivePage(pageNumber);
    setPageOffset(offset);
    let PostData = {
      functionName: "getAuditExcelDataByMasterId",
      masterId: tableId,
      processStatus: blank == "blank" ? "blank" : "",
      offset: offset.toString(),
      search:
        $("#searchAddress").val() === " " ? "" : $("#searchAddress").val(),
      category: dropdowndata == "0" ? "" : dropdowndata,
      startId: fromdata == "" ? "1" : fromdata,
      endId: todata == "" ? "500000" : todata,
    };
    window.sessionStorage.setItem("pageNumberDrDetails", pageNumber);
    window.sessionStorage.setItem("offsetDrDetails", offset);
    setfinalarray([]);
    backendApi(PostData).then((data) => {
      for (let i = 0; i < data.data.length; i++) {
        $("#radiotf" + i).prop("checked", false);
        $("#searchAddressvalue" + i).attr("hidden", true);
        $("#button" + i).attr("hidden", true);
        $("#titleCancelEdit" + i).attr("hidden", true);
        $("#titleEditIcon" + i).attr("hidden", false);
        $("#titleEditIcon" + i).removeAttr("hidden");
      }
      if (data.success === "1") {
        setExcelData(data.data);
        setcount(data.cntPaginationSearch[0].cntPaginationSearch);
        setTotalComplete(data.paginationCount[0].cntPagination);
        setTotalCount(data.totalCount);
        setExcelDatasort(data.data);
        if (data.totalComplete !== data.totalCount) {
          $(".dataProcessImg").show();
        } else {
          $(".dataProcessImg").hide();
        }
      } else {
        setExcelData([]);
      }
    });
  };
  const getAuditExcelDataByMasterId = (val, fromdata, todata, search, type) => {
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "getAuditExcelDataByMasterId",
      masterId: tableId,
      processStatus: val == "blank" ? "blank" : "",
      search: search != undefined && search != 0 ? search : "",
      offset: window.sessionStorage.getItem("offsetDrDetails"),
      category: "",
      startId: fromdata == "" ? "1" : fromdata,
      endId: todata == "" ? "500000" : todata,
    };
    setfinalarray([]);
    backendApi(req).then((data) => {
      if (data.success === "1") {
        $("#Approve").show();
        $("#Match").show();
        if (type != "clear") {
          for (let i = 0; i < data.data.length; i++) {
            $("#radiotf" + data.data.id).prop("checked", false);
            $("#searchAddressvalue" + data.data.id).attr("hidden", true);
            $("#button" + data.data.id).attr("hidden", true);
            $("#titleCancelEdit" + data.data.id).attr("hidden", true);
            $("#titleEditIcon" + data.data.id).attr("hidden", false);
            $("#titleEditIcon" + data.data.id).removeAttr("hidden");
          }
        } else {
          for (let i = 0; i < data.data.length; i++) {
            $("#radiotf" + i).prop("checked", false);
            $("#searchAddressvalue" + i).attr("hidden", true);
            $("#button" + i).attr("hidden", true);
            $("#titleCancelEdit" + i).attr("hidden", true);
            $("#titleEditIcon" + i).attr("hidden", false);
            $("#titleEditIcon" + i).removeAttr("hidden");
          }
        }
        setExcelData(data.data);
        setcount(data.cntPaginationSearch[0].cntPaginationSearch);
        setTotalComplete(data.paginationCount[0].cntPagination);
        setTotalCount(data.totalCount);
        setExcelDatasort(data.data);
        if (data.totalComplete !== data.totalCount) {
          $(".dataProcessImg").show();
        } else {
          $(".dataProcessImg").hide();
        }
        $(".loder_div").attr("hidden", true);
      } else {
        $(".loder_div").attr("hidden", true);
        setExcelData([]);
      }
    });
  };
  useEffect(() => {
    $("#editCommentPopup").attr("hidden", true);
    setActivePage(Number(window.sessionStorage.getItem("pageNumberDrDetails")));
    $("#reprocessModal").attr("hidden", true);
    getAuditExcelDataByMasterId();
    getStatusdata();
    getAuditMergeCount();
    $("#statusValue").val("0");
    $("#nodata").hide();

    $("#Approve").hide();
    $("#Match").hide();
    // setInterval(() => {
    //     $("#fromData").val("");
    //     $("#toData").val("");
    //     $("#statusValue").val("0");
    //   getAuditExcelDataByMasterId();setCountStatus
    // }, 60000);
    setInterval(() => {
      getStatusdata();
    }, 60000);
  }, []);
  const getStatusdata = () => {
    var apidata = {
      functionName: "auditStatus",
      masterId: tableId,
    };
    backendApi(apidata).then((data) => {
      console.log("data=========", data);
      if (data.success === "1") {
        const arr = data.data.filter((element) => {
          return element.process_status == "Started";
        });
        const arr2 = data.data.filter((element) => {
          return element.process_status == "Completed";
        });
        const arr3 = data.data.filter((element) => {
          return element.process_status == "Failed";
        });
        const arr4 = data.data.filter((element) => {
          return element.process_status == "";
        });
        setAuditStatusStarted(arr);
        setAuditStatusCompleted(arr2);
        setAuditStatusFailed(arr3);
        setAuditStatusInProgress(arr4);
      }
    });
  };
  const getAuditMergeCount = () => {
    var apidata = {
      functionName: "auditMergeCount",
      master_id: tableId,
    };

    let array = finalarray;
    backendApi(apidata).then((data) => {
      if (data.success === "1") {
        setCountStatus(data.data[0].status);
        setAuditStatusInProgressCountSuccsess(data.successCnt);
        setAuditStatusInProgressCountFail(data.failedCnt);
        console.log(data, "kkkkkkkkkkkkkkkkkkkkkkkk");
      }
    });
  };
  const handleProcessData = (id) => {
    var apidata = {
      functionName: "getAuditGoogleData",
      excelId: id,
    };
    let array = finalarray;
    backendApi(apidata).then((data) => {
      console.log("data=========", data);
      if (data.success === "1") {
        array = [];
        for (let i = 0; i < data.data.length; i++) {
          array.push({
            Address: data.data[i].address,
            Reviews: data.data[i].reviews,
            status: data.data[i].status,
            location_name: data.data[i].location_name,
            statusValue:
              data.data[i].status === "Primary"
                ? "40"
                : data.data[i].status === "Stray"
                ? "10"
                : data.data[i].status === "Duplicate"
                ? "30"
                : "20",
          });
        }
        setfinalarray(array);
      }
    });
  };
  const handleUpdateStatus = (e, excelId) => {
    $(".loder_div").attr("hidden", false);
    if (e.target.value === "0") {
      return false;
    }
    if (window.confirm("Are you sure to change status?")) {
      var apidata = {
        method: "POST",
        body: JSON.stringify({
          id: e.target.id,
          status: e.target.value,
        }),
      };
      $(".loder_div").attr("hidden", false);
      pythonBackendApi("updateAuditStatus", apidata).then((data) => {
        $(".loder_div").attr("hidden", true);
        if (data.success === "1") {
          alert("Status changed successfully");
          getAuditExcelDataByMasterId();
        } else {
          // alert("Something went wrong");
        }
      });
    }
  };
  const downloadExcel = () => {
    $(".loder_div").attr("hidden", false);
    var apidata = {
      functionName: "downloadAuditExcel",
      userId:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      masterId: tableId,
    };
    backendApi(apidata).then((data) => {
      console.log("data=========", data);
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        const successFileName = "auditReport";
        const exportType = "xls";
        exportXlsx(data.data, successFileName, exportType);
      } else {
        alert("Failed to Download Audit Excel");
        $(".loder_div").attr("hidden", true);
      }
    });
  };
  const exportXlsx = (data, fileName, exportType) => {
    exportFromJSON({ data, fileName, exportType });
  };
  const reprocessSingleData = (id) => {
    $(".loder_div").attr("hidden", false);
    var req = {
      method: "POST",
      body: JSON.stringify({
        id: id,
        userId:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
      }),
    };
    pythonBackendApi("reProcessSingleAuditData", req);
    $("#fromData").val("");
    $("#toData").val("");
    $("#statusValue").val("0");
    $("#searchAddress").val("");
    getAuditExcelDataByMasterId();
    $(".loder_div").attr("hidden", true);
  };
  const reprocessFullDataAproove = () => {
    var apidata = {
      functionName: "auditMergeApprove",
      master_id: path.toString(),
    };
    backendApi(apidata).then((data) => {
      console.log("data=========", data);
      if (data.success === "1") {
        alert("Approved Successfully");
        window.location.href = "/mergeIndex";
      }
    });
  };
  // const reprocessFullDataAproove = () => {
  //   $(".dataProcessImg").show();
  //   // $(".loder_div").attr("hidden", false);
  //   var apidata = {
  //       "functionName": "auditMergeApprove",
  //       "master_id": path.toString()
  //   }
  //   pythonBackendApi("reProcessAuditData", apidata).then((data) => {
  //     if (data.success === "1") {
  //       alert("Approved Successfully");
  //       $(".dataProcessImg").hide();
  //       //  $(".loder_div").attr("hidden", true);
  //       window.location.reload();
  //     }
  //     //  else {
  //     //   alert("Failed");
  //     //   $(".loder_div").attr("hidden", true);
  //     // }
  //   });
  //   // $(".loder_div").attr("hidden", true);
  // };
  const processFile = () => {
    if (!window.confirm("Do you want to start comparison?")) {
      return false;
    }
    $(".dataProcessImgCompareData").show();
    $(".loder_div").attr("hidden", false);
    var apidata = {
      method: "POST",
      body: JSON.stringify({
        masterId: tableId,
        userId:
          window.sessionStorage.getItem("switchUserId") === ""
            ? window.sessionStorage.getItem("userId")
            : window.sessionStorage.getItem("switchUserId"),
        siteId:
          window.sessionStorage.getItem("switchingOrgId") === ""
            ? window.sessionStorage.getItem("organizationId")
            : window.sessionStorage.getItem("switchingOrgId"),
      }),
    };
    pythonBackendApi("compareAuditData", apidata).then((data) => {
      if (data.success == "1") {
        alert("Process Completed Successfully");
        $(".loder_div").attr("hidden", true);
      } else {
        alert("Failed");
        $(".loder_div").attr("hidden", true);
      }
      $(".dataProcessImgCompareData").hide();
    });
    // window.location.reload();
    // getAuditExcelDataByMasterId();
  };
  const saveSearchCriteria = (created_by) => {
    let fromdata = $("#fromData").val();
    let todata = $("#toData").val();
    let searchAddress = $("#searchAddress").val();
    if ($("#searchCriteriaInput").val() === "") {
      alert("Search Criteria cannot be empty");
      return false;
    }
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "updateSearchAuditExcelData",
      excelId: $("#dataId").text(),
      search: $("#searchCriteriaInput").val(),
      created_by: $("#createdBy").text(),
    };
    backendApi(req).then((data) => {
      if (data.success === "1") {
        alert("Search Criteria Changed Successfully");
        $(".loder_div").attr("hidden", true);
        $("#reprocessModal").attr("hidden", true);
        // reprocessFullData();

        getAuditExcelDataByMasterId(
          blank == "" ? "" : "blank",
          fromdata,
          todata,
          searchAddress,
          ""
        );
      } else {
        alert("Something went wrong while updating Search Criteria");
        $(".loder_div").attr("hidden", true);
      }
    });
  };
  const handleSort = (value) => {
    if (value == "blank") {
      setblank(value);
    } else {
      setblank("");
    }

    $("#nodata").show();
    setActivePage(1);
    var flagvalue = true;
    let fromdata = $("#fromData").val();
    let todata = $("#toData").val();
    let dropdowndata = $("#statusValue").find("option:selected").val();
    let fromdatavalue = "";
    let todatavalue = "";

    if (todata === "") {
      todata = 500000;
    }
    if (fromdata === "") {
      fromdata = 1;
    }

    if (todata < fromdata) {
      alert("Please Provide Valid ID");
      flagvalue = false;
    } else if (fromdata !== "" && todata !== "") {
      fromdatavalue = fromdata;
      todatavalue = todata;
      flagvalue = true;
    }
    if (flagvalue == true) {
      $(".loder_div").attr("hidden", false);
      var req = {
        functionName: "getAuditExcelDataByMasterId",
        masterId: tableId,
        processStatus: value == "blank" ? "blank" : "",
        offset: "",
        search:
          $("#searchAddress").val() === " " ? "" : $("#searchAddress").val(),
        category: dropdowndata == "0" ? "" : dropdowndata,
        startId: fromdatavalue,
        endId: todatavalue,
      };
      setfinalarray([]);
      backendApi(req).then((data) => {
        for (let i = 0; i < data.data.length; i++) {
          $("#radiotf" + i).prop("checked", false);
          $("#searchAddressvalue" + i).attr("hidden", true);
          $("#button" + i).attr("hidden", true);
          $("#titleCancelEdit" + i).attr("hidden", true);
          $("#titleEditIcon" + i).attr("hidden", false);
          $("#titleEditIcon" + i).removeAttr("hidden");
        }
        if (data.success === "1") {
          setExcelData(data.data);
          setcount(data.cntPaginationSearch[0].cntPaginationSearch);
          $(".loder_div").attr("hidden", true);
        } else {
          setExcelData([]);
          setcount(0);
          $(".loder_div").attr("hidden", true);
        }
      });
    }
  };
  function allowNumbersOnly(e) {
    var code = e.which ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
      e.preventDefault();
    }
  }
  const clearFilters = () => {
    // $(".loder_div").attr("hidden", false);
    // setTimeout(() => {
    //   $(".loder_div").attr("hidden", true);
    // }, 500);
    setblank("");
    $("#searchAddress").val("");
    $("#fromData").val("");
    $("#toData").val("");
    $("#statusValue").val("0");

    window.sessionStorage.setItem("offsetDrDetails", 0);
    setActivePage(1);
    getAuditExcelDataByMasterId("", "", "", "", "clear");
  };

  // const migrateProcess = () => {
  //   if (!window.confirm("Do you want to Run Match Algorithm?")) {
  //     return false;
  //   }
  //   $(".dataProcessImgCompareData").show();
  //   var apidata = {
  //     method: "POST",
  //     body: JSON.stringify({
  //       masterId: path.toString(),
  //       userId:
  //         window.sessionStorage.getItem("switchUserId") === ""
  //           ? window.sessionStorage.getItem("userId")
  //           : window.sessionStorage.getItem("switchUserId"),
  //       siteId:
  //         window.sessionStorage.getItem("switchingOrgId") === ""
  //           ? window.sessionStorage.getItem("organizationId")
  //           : window.sessionStorage.getItem("switchingOrgId"),
  //     }),
  //   };
  //   pythonBackendApi("runMergeAddress", apidata).then((data) => {
  //     if (data.success == 1) {
  //       alert(
  //         "Your request has been sent successfully and result will be shown shortly"
  //       );
  //       window.location.reload();
  //     } else {
  //       alert("Failed");
  //     }
  //     $(".dataProcessImgCompareData").hide();
  //   });

  //   // window.location.reload();
  //   // getAuditExcelDataByMasterId();
  // };
  const migrateProcess = () => {
    if (!window.confirm("Are you sure you want to run  matching Algorithm")) {
      return false;
    }
    $(".loder_div").attr("hidden", false);
    var apidata = {
      functionName: "runSearchingAlgoritham",
      masterId: tableId.toString(),
    };
    backendApi(apidata).then((data) => {
      if (data.success == 1) {
        $(".loder_div").attr("hidden", true);
        alert(
          "Your Request Sent Successfully and Result Will Be Shows Shortly"
        );
        getAuditExcelDataByMasterId();
      } else {
        $(".loder_div").attr("hidden", true);
        alert("Failed");
      }
    });
  };
  const textAreachange = (i) => {
    $("#searchaddress" + i).hide();
    $("#searchAddressvalue" + i).attr("hidden", false);
    $("#button" + i).attr("hidden", false);
    $("#titleEditIcon" + i).attr("hidden", true);
    $("#titleCancelEdit" + i).removeAttr("hidden");
  };

  const saveforAddress = (i, search, id, createdBy) => {
    let fromdata = $("#fromData").val();
    let todata = $("#toData").val();
    let searchAddress = $("#searchAddress").val();
    if ($("#searchAddressvalue" + i).val() === "") {
      alert("Search Criteria cannot be empty");
      return false;
    }
    $(".loder_div").attr("hidden", false);
    var req = {
      functionName: "updateSearchAuditExcelData",
      excelId: id,
      search: search == "" ? search : $("#searchAddressvalue" + i).val(),
      created_by:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };

    backendApi(req).then((data) => {
      if (data.success === "1") {
        $("#saveSuccess" + i).css("display", "block");
        setTimeout(() => {
          $("#saveSuccess" + i).css("display", "none");
        }, 1500);
        $(".loder_div").attr("hidden", true);
        $("#reprocessModal").attr("hidden", true);

        getAuditExcelDataByMasterId(
          blank == "" ? "" : "blank",
          fromdata,
          todata,
          searchAddress,
          ""
        );

        $("#searchAddressvalue" + i).attr("hidden", true);
        $("#button" + i).attr("hidden", true);
        $("#titleCancelEdit" + i).attr("hidden", true);
        $("#titleEditIcon" + i).attr("hidden", false);
        $("#searchaddress" + i).show();
        $("#titleEditIcon" + i).removeAttr("hidden");
      } else {
        alert("Something went wrong while updating Search Criteria");
        $(".loder_div").attr("hidden", true);
      }
    });
  };
  const popupShow = (data) => {
    $("#editCommentPopup").attr("hidden", false);
    setPopupData(data);
  };
  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header heading="Audit Match Search" headerImage={Auditimage} />
        <div className="container-fluid">
          <div className="user_listdiv_sub">
            <div className="container">
              <div className="back_tocatalog row">
                <div className="col-lg-5 d-flex">
                  <div className="back_tocatalog_main">
                    <Link to="/mergeIndex" className="back-to-link">
                      <Icon.ArrowLeftCircle />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 text-lg-left ml-auto count_status_col">
                  {/* {CountStatus !=="Searching" && */}
                  <>
                    {CountStatus == "Search Completed" && (
                      <>
                        <button
                          id="Approve"
                          type="button"
                          className="btn btn-info mr-2"
                          onClick={() => migrateProcess()}
                        >
                          Re-Process
                        </button>
                        <button
                          id="Approve"
                          type="button"
                          className="btn btn-info mr-2"
                          onClick={() => reprocessFullDataAproove()}
                        >
                          Approve
                        </button>
                      </>
                    )}
                    {/* // &nbsp; &nbsp; */}
                    {CountStatus == "" && (
                      <button
                        id="Match"
                        type="button"
                        className="btn btn-success"
                        onClick={() => migrateProcess()}
                      >
                        Match
                      </button>
                    )}
                  </>
                  {/* } */}
                  <div class="audit-list-box">
                    <span class="audit-txt">Total :</span>
                    <span class="audit-txt-bld">{totalComplete}</span>
                  </div>

                  {CountStatus != "" && (
                    <>
                      <div className="audit-list-head">
                        Searching Algorithm:{" "}
                      </div>
                      <div
                        className="audit-list-box"
                        style={{ color: "rgb(40, 167, 69)" }}
                      >
                        <span className="audit-txt">Match:</span>
                        <span className="audit-txt-bld">
                          {AuditStatusInProgressCountSuccsess == ""
                            ? 0
                            : AuditStatusInProgressCountSuccsess.map(
                                (data) => data.successCnt
                              )}
                        </span>
                      </div>
                    </>
                  )}
                  {CountStatus != "" && CountStatus != "Searching" && (
                    <div
                      className="audit-list-box"
                      style={{ color: "rgb(205, 0, 0)" }}
                    >
                      <span className="audit-txt">Not Match:</span>
                      <span className="audit-txt-bld">
                        {AuditStatusInProgressCountSuccsess == ""
                          ? 0
                          : AuditStatusInProgressCountFail.map(
                              (data) => data.failedCnt
                            )}
                      </span>
                    </div>
                  )}

                  {CountStatus == "Searching" ? (
                    <div
                      className="audit-list-box"
                      style={{ color: "rgb(0, 122, 205)" }}
                    >
                      <span className="audit-txt">In Progress:</span>
                      <span className="audit-txt-bld">
                        {AuditStatusInProgressCountSuccsess == ""
                          ? 0
                          : AuditStatusInProgressCountFail.map(
                              (data) => data.failedCnt
                            )}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  {CountStatus == "Searching" && (
                    <div className="col-md-12 position-relative">
                      <span className="warning-msg">
                        Searching algoritham is in progress and we will update
                        you once done
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="row row-search">
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <input
                    type="text"
                    id="fromData"
                    className="form-control"
                    placeholder="From ID"
                    onKeyPress={(e) => allowNumbersOnly(e)}
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <input
                    type="text"
                    id="toData"
                    className="form-control"
                    placeholder="To ID"
                    onKeyPress={(e) => allowNumbersOnly(e)}
                  />
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    id="searchAddress"
                  />
                </div>

                <div className="col-xl-6 col-md-6 col-sm-12 col-lg-6">
                  <button
                    className="btn-search mr-2"
                    id="txtFile"
                    type="button"
                    onClick={() => handleSort("val")}
                  >
                    Search
                  </button>
                  <button
                    id="txtFile"
                    type="button"
                    className="btn btn-dark fetchDataBtn mr-2"
                    onClick={() => handleSort("blank")}
                  >
                    Blank Search
                  </button>
                  <button
                    id="txtFile"
                    type="button"
                    className="btn-clear-search"
                    onClick={(e) => clearFilters(e)}
                  >
                    Clear Search
                  </button>
                </div>
              </div>
              {count > 25 && (
                <div className="pagination_main">
                  <Pagination
                    hideDisabled
                    activePage={activePage}
                    itemsCountPerPage={25}
                    totalItemsCount={count}
                    pageRangeDisplayed={10}
                    onChange={(e) => handlePageChange(e)}
                  />
                </div>
              )}
              <div className="col-md-12 tableResponsive">
                <div className="tableOuterWidth">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-1">ID</th>
                        <th className="col-2">Name</th>
                        <th className="col-2">Category</th>
                        <th className="col-6">Address</th>
                        <th className="col-1"></th>
                        {/* <th>Reprocess</th> */}
                        {/* <th></th> */}
                      </tr>
                    </thead>

                    <div className="table_Outerscroll">
                      {excelData.length > 0 ? (
                        <>
                          {excelData.map((data, i) => {
                            return (
                              <tbody
                                key={i + 1}
                                className="stickyDiv table_Outertbody"
                              >
                                <tr>
                                  <td className="col-1">{data.id}</td>
                                  <td className="col-2">
                                    {data.location_name}
                                  </td>
                                  <td className="col-2">{data.category}</td>
                                  <td className="col-6">
                                    {data.address}

                                    <div className="searchbuttonDiv">
                                      <Icon.Search
                                        id={"titleEditIcon" + i}
                                        className="audit-search-icon"
                                        onClick={() => {
                                          textAreachange(i, data.search);
                                          $("#searchAddressvalue" + i).val(
                                            data.search
                                          );
                                          $("#dataId").text(data.id);
                                        }}
                                      />
                                      <p
                                        id={"searchaddress" + i}
                                        className="mb-0 succes_textdiv"
                                      >
                                        {data.search}
                                      </p>
                                      <textarea
                                        type="text"
                                        className="form-control searchEditInput"
                                        placeholder="Search"
                                        id={"searchAddressvalue" + i}
                                        hidden
                                      />
                                      <span id={"button" + i} hidden>
                                        <Icon.Save
                                          className="audit-save-icon"
                                          onClick={() =>
                                            saveforAddress(
                                              i,
                                              data.search,
                                              data.id,
                                              data.createdBy
                                            )
                                          }
                                        />
                                      </span>
                                    </div>

                                    <span
                                      className="success-msg"
                                      id={"saveSuccess" + i}
                                    >
                                      Saved successfully
                                    </span>
                                  </td>
                                  <td className="col-1 cursor">
                                    <small
                                      onClick={() => {
                                        popupShow(data);
                                      }}
                                    >
                                      <Icon.Info size="18px"/>
                                    </small>
                                  </td>
                                  {/* <td className="text-center">
                                {data.process_status === "Failed" && (
                                  <a
                                    role="button"
                                    className="btn-link"
                                    onClick={() => reprocessSingleData(data.id)}
                                  >
                                    Restart
                                  </a>
                                )}
                              </td> */}
                                  {/* <td>
                                <input
                                  type="radio"
                                  id={"radiotf" + i}
                                  name="process"
                                  onClick={() => handleProcessData(data.id)}
                                />
                              </td> */}
                                </tr>
                              </tbody>
                            );
                          })}
                        </>
                      ) : (
                        <tr id="nodata">No Data Found</tr>
                      )}
                    </div>
                  </table>
                </div>
              </div>
              {count > 25 && (
                <div className="pagination_main">
                  <Pagination
                    hideDisabled
                    activePage={activePage}
                    itemsCountPerPage={25}
                    totalItemsCount={count}
                    pageRangeDisplayed={10}
                    onChange={(e) => handlePageChange(e)}
                  />
                </div>
              )}
            </div>
            {/* <div className="slNosection col-lg-8 col-md-10">
              <select
                className="form-control slNoselection_field"
                id="statusValue"
              >
                {" "}
                <option value="0">Select Status</option>
                <option value="Completed">Completed</option>
                <option value="Started">On Progress</option>
              
                <option value="Failed">Failed</option>
              </select>
              <span className="slNosectionSpan">
                <input
                  type="text"
                  id="fromData"
                  className="form-control"
                  placeholder="From Serial No."
                  onKeyPress={(e) => allowNumbersOnly(e)}
                />
              </span>
              &nbsp;&nbsp; To
              <span className="slNosectionSpan">
                <input
                  type="text"
                  id="toData"
                  className="form-control"
                  placeholder="To Serial No."
                  onKeyPress={(e) => allowNumbersOnly(e)}
                />
              </span>
              <span className="slNosectionSpan">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  id="searchAddress"
                />
              </span>
              <button
                id="txtFile"
                type="button"
                className="btn btn-info fetchDataBtn"
                onClick={(e) => handleSort(e)}
              >
                Search
              </button>
              <button
                id="txtFile"
                type="button"
                className="btn btn-info fetchDataBtn"
                onClick={(e) => clearFilters(e)}
              >
                Clear Search
              </button>
            </div> */}
            <div id="editCommentPopup" className="module_popup" hidden>
              <div className="module_innerpopup">
                <div className="module_close">
                  <Icon.XCircle
                    onClick={() => {
                      $("#editCommentPopup").attr("hidden", true);
                    }}
                  />
                </div>
                <div
                  id="editCommentPopupHeader"
                  className="module_header"
                ></div>
                <div className="module_body">
                  <div className="module_popupsearch_wrpr">
                    <div className="py-3">
                      {console.log("asdasd", popupData)}
                      <div class="did-floating-label-content">
                        <div className="moreDetails">
                          <span>
                            <b>Reference Code</b>:{" "}
                            {popupData.reference_code
                              ? popupData.reference_code
                              : "N/A"}
                          </span>
                          <span>
                            <b>Location Name</b>:{" "}
                            {popupData.location_name
                              ? popupData.location_name
                              : "N/A"}
                          </span>
                          <span>
                            <b>Address Line 1</b>:{" "}
                            {popupData.address_line1
                              ? popupData.address_line1
                              : "N/A"}
                          </span>
                          <span>
                            <b>Address Line 2</b>:{" "}
                            {popupData.address_line2
                              ? popupData.address_line2
                              : "N/A"}
                          </span>
                          <span>
                            <b>Address Line 3</b>:{" "}
                            {popupData.address_line3
                              ? popupData.address_line3
                              : "N/A"}
                          </span>
                          <span>
                            <b>Address Line 4</b>:{" "}
                            {popupData.address_line4
                              ? popupData.address_line4
                              : "N/A"}
                          </span>
                          <span>
                            <b>Address Line 5</b>:{" "}
                            {popupData.address_line5
                              ? popupData.address_line5
                              : "N/A"}
                          </span>
                          <span>
                            <b>Sub-locality</b>:{" "}
                            {popupData.sub_locality
                              ? popupData.sub_locality
                              : "N/A"}
                          </span>
                          <span>
                            <b>Locality</b>:{" "}
                            {popupData.locality ? popupData.locality : "N/A"}
                          </span>
                          <span>
                            <b>Administrative Area</b>:{" "}
                            {popupData.administrativeArea
                              ? popupData.administrativeArea
                              : "N/A"}
                          </span>
                          <span>
                            <b>Country/ Region</b>:{" "}
                            {popupData.country_Region
                              ? popupData.country_Region
                              : "N/A"}
                          </span>
                          <span>
                            <b>Postal Code</b>:{" "}
                            {popupData.Postcode ? popupData.Postcode : "N/A"}
                          </span>
                          <span>
                            <b>Latitude</b>:{" "}
                            {popupData.Latitude ? popupData.Latitude : "N/A"}
                          </span>
                          <span>
                            <b>Longitude</b>:{" "}
                            {popupData.Longitude ? popupData.Longitude : "N/A"}
                          </span>
                          <span>
                            <b>Primary Phone</b>:{" "}
                            {popupData.phone_no ? popupData.phone_no : "N/A"}
                          </span>
                          <span>
                            <b>Additional Phone Nos.</b>:{" "}
                            {popupData.Additional_Phones
                              ? popupData.Additional_Phones
                              : "N/A"}
                          </span>
                          <span>
                            <b>Category</b>:{" "}
                            {popupData.category ? popupData.category : "N/A"}
                          </span>
                          <span>
                            <b>Additional categories</b>:{" "}
                            {popupData.Additional_categories
                              ? popupData.Additional_categories
                              : "N/A"}
                          </span>
                          <span>
                            <b>Sunday Hours</b>:{" "}
                            {popupData.Sunday_hours
                              ? popupData.Sunday_hours
                              : "N/A"}
                          </span>
                          <span>
                            <b>Monday Hours</b>:{" "}
                            {popupData.Monday_hours
                              ? popupData.Monday_hours
                              : "N/A"}
                          </span>
                          <span>
                            <b>Tuesday Hours</b>:{" "}
                            {popupData.Tuesday_hours
                              ? popupData.Tuesday_hours
                              : "N/A"}
                          </span>
                          <span>
                            <b>Wednesday Hours</b>:{" "}
                            {popupData.Wednesday_hours
                              ? popupData.Wednesday_hours
                              : "N/A"}
                          </span>
                          <span>
                            <b>Thursday Hours</b>:{" "}
                            {popupData.Thursday_hours
                              ? popupData.Thursday_hours
                              : "N/A"}
                          </span>
                          <span>
                            <b>Friday Hours</b>:{" "}
                            {popupData.Friday_hours
                              ? popupData.Friday_hours
                              : "N/A"}
                          </span>
                          <span>
                            <b>Saturday Hours</b>:{" "}
                            {popupData.Saturday_hours
                              ? popupData.Saturday_hours
                              : "N/A"}
                          </span>
                          <span>
                            <b>Special Hours</b>:{" "}
                            {popupData.Special_hours
                              ? popupData.Special_hours
                              : "N/A"}
                          </span>
                          <span>
                            <b>From The Business</b>:{" "}
                            {popupData.From_the_business
                              ? popupData.From_the_business
                              : "N/A"}
                          </span>
                          <span>
                            <b>Opening Date</b>:{" "}
                            {popupData.Opening_date
                              ? popupData.Opening_date
                              : "N/A "}
                          </span>
                          <span>
                            <b>Logo Photo</b>:{" "}
                            {popupData.Logo_photo ? popupData.Logo_photo : "N/A"}
                          </span>
                          <span>
                            <b>Cover Photo</b>:{" "}
                            {popupData.Cover_photo
                              ? popupData.Cover_photo
                              : "N/A"}
                          </span>
                          <span>
                            <b>Other Photos</b>:{" "}
                            {popupData.Other_photos
                              ? popupData.Other_photos
                              : "N/A"}
                          </span>
                          <span>
                            <b>Labels</b>:{" "}
                            {popupData.Labels ? popupData.Labels : "N/A"}
                          </span>
                          <span>
                            <b>AdWords location extensions phone</b>:{" "}
                            {popupData.AdWords_location_extensions_phone
                              ? popupData.AdWords_location_extensions_phone
                              : "N/A"}
                          </span>
                          <span>
                            <b>Amenities: Wi-Fi</b>:{" "}
                            {popupData.Wi_Fi ? popupData.Wi_Fi : "N/A"}
                          </span>
                          <span>
                            <b>From the business: Identifies as women-owned</b>:{" "}
                            {popupData.is_owned_by_women
                              ? popupData.is_owned_by_women
                              : "N/A"}
                          </span>
                          <span>
                            <b>Payments: Credit cards: American Express</b>:{" "}
                            {popupData.Payments_american_express
                              ? popupData.Payments_american_express
                              : "N/A"}
                          </span>
                          <span>
                            <b>Payments: Credit cards : Mastercard</b>:{" "}
                            {popupData.Payments_mastercard
                              ? popupData.Payments_mastercard
                              : "N/A"}
                          </span>
                          <span>
                            <b>Payments: Credit cards : Visa</b>:{" "}
                            {popupData.Payments_visa
                              ? popupData.Payments_visa
                              : "N/A"}
                          </span>
                          <span>
                            <b>Place page URLs: Menu link</b>:{" "}
                            {popupData.url_menu ? popupData.url_menu : "N/A"}
                          </span>
                          <span>
                            <b>Place page URLs: Order ahead links</b>:{" "}
                            {popupData.url_order_ahead
                              ? popupData.url_order_ahead
                              : "N/A"}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* <div className="module_popupsearchBtn">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.saveAndStartUpload()}
                  >
                    Save
                  </button>
                </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="module_popup" id="reprocessModal" hidden>
              <div className="module_innerpopup">
                <div className="module_close">
                  <Icon.XSquare
                    color="red"
                    onClick={() => {
                      $("#reprocessModal").attr("hidden", true);
                    }}
                  />
                </div>
                <div className="module_body">
                  <h5 className="mainHead_popup">Search Criteria</h5>
                  <span id="dataId" hidden></span>
                  <span id="createdBy" hidden></span>
                  <textarea
                    id="searchCriteriaInput"
                    className="reprocessArea"
                  ></textarea>
                </div>
                <div className="button-wrapper">
                  <button
                    type="button"
                    className="btn-submit"
                    onClick={() => saveSearchCriteria()}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn-cancel"
                    onClick={() => {
                      $("#reprocessModal").attr("hidden", true);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="col-md-4 stickyDivRight">
                <div className="table-responsive righttable_responsive">
                  {finalarray.length !== 0 && (
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th width="100px">Address</th>
                          <th>Reviews</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {finalarray
                          .sort((a, b) => b.statusValue - a.statusValue)
                          .map((data, i) => (
                            <tr key={i + 1} className="stickyDiv">
                              <td>{data.location_name}</td>
                              <td className="hoverText"> */}
            {/* <div id="tooltip" className="top tooltips">
                                  <div className="tooltip-arrow" />
                                  <div className="tooltip-label">
                                    {data.Address}
                                  </div>
                                </div> */}
            {/* {data.Address}
                              </td>
                              <td>{data.Reviews}</td>
                              <td>
                                <select
                                  className="form-control"
                                  id={data.id}
                                  defaultValue={data.status ? data.status : ""}
                                  onChange={(e) =>
                                    handleUpdateStatus(e, data.excel_id)
                                  }
                                >
                                  {" "}
                                  <option value="0">Select</option>
                                  <option value="Primary">Primary</option>
                                  <option value="Stray">Stray</option>
                                  <option value="Duplicate">Duplicate</option>
                                  <option value="Ignore">Ignore</option>
                                </select>
                              </td> */}
            {/* <td colspan="3" class="text-center m-auto">
                              <div class="audit_detailsbox text-left"><b>Address:</b> {data.address}</div>
                              <div class="audit_detailsbox text-left"><b>Status:</b> <select
                                className="form-control"
                                id={data.id}
                                defaultValue={data.status ? data.status : ""}
                                onChange={(e) =>
                                  handleUpdateStatus(e, data.excel_id)
                                }
                              >
                                {" "}
                                <option value="0">Select</option>
                                <option value="Primary">Primary</option>
                                <option value="Stray">Stray</option>
                                <option value="Duplicate">Duplicate</option>
                                <option value="Ignore">Ignore</option>
                              </select></div>
                              <div class="audit_detailsbox text-left"><b>Reviews:</b> {data.reviews}</div>
                            
                              </td>   */}
            {/* </tr>
                          ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div> */}
          </div>
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden />
      {/* <Footer/> */}
    </div>
  );
};
export default AuditDetails;
