import React, { useState, useEffect } from "react";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer";
import DragDropFile from "../home/dragDropFile";

import DataInputGpost from "../google-post/dataInputGpost";

import cloudImg from "../images/cloud-computing.png";
import GooglePostimage from "../images/left_icons/Google-Post.png";
import * as Icon from "react-feather";

import $ from "jquery";
import moment from "moment";
import { backendApi, apiCallingPost } from "../apiService";
import * as XLSX from "xlsx";
import googlePostTemplate from "../docs/googlePostTemplate_excel.xlsx";
import { Link, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
// import TimePicker from "rc-time-picker";
// import "rc-time-picker/assets/index.css";
// import TimePicker from "react-time-picker";
const format = "h:mm a";

var hour = [];
var minute = [];
var globalLocationId = 0;
function GooglePost() {
  // const [firstDescription, setFirstDescription] = useState(false);
  // const [eventTitle, setEventTitle] = useState("");
  // const [photos, setPhotos] = useState(false);
  // const [addButton, setAddButton] = useState(false);
  // const [linkForButton, setLinkForButton] = useState(false);
  // const [title, setTitle] = useState(false);
  // const [datePickers, setDatePickers] = useState(false);
  // const [startTime, setStartTime] = useState("");
  // const [endTime, setEndTime] = useState("");
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  // const [description, setDescription] = useState("");
  // const [eventType, setEventType] = useState("");
  // const [imageUrl, setImageUrl] = useState("");
  // const [imageName, setImageName] = useState("");
  // const [localPostList, setLocalPostList] = useState([]);
  // const [isLoader, setIsLoader] = useState(0);
  // const [dataList, setDataList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const [temporaryLocationsList, setTemporaryLocationsList] = useState([]);
  const [selectedLocationsArray, setSelectedLocationsArray] = useState([]);
  const [administrativeAreaList, setAdministrativeAreaList] = useState([]);
  const [localityList, setLocalityList] = useState([]);
  const [localityListBackup, setLocalityListBackup] = useState([]);
  const [administrativeAreaFilterEnable, setAdministrativeAreaFilterEnable] =
    useState(0);
  const [
    administrativeAreaFilterEnableArray,
    setAdministrativeAreaFilterEnableArray,
  ] = useState(0);
  const [
    selectedLocationsCompleteDataArray,
    setSelectedLocationsCompleteDataArray,
  ] = useState([]);
  const [createPostButton, setCreatePostButton] = useState(false);
  const location = useLocation();
  const excelMime = [
    "application/vnd.ms-excel",
    "application/msexcel",
    "application/x-msexcel",
    "application/x-ms-excel",
    "application/x-excel",
    "application/x-dos_ms_excel",
    "application/xls",
    "application/x-xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  useEffect(() => {
    console.log("locationlocation", location);
    // getLocationsList();
    newGetLocationsList();
    // Hours();
    // Minutes();
    // getPostList();
  }, []);
  const newGetLocationsList = () => {
    $(".loder_div").attr("hidden", false);
    const apiJson = {
      functionName: "getMasterDataForFilter",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    apiCallingPost(apiJson).then((data) => {
      $(".loder_div").attr("hidden", true);
      if (data.success === "1") {
        // setLocationsList((data) => [...data, data])
        if (location.state?.completeLocationData) {
          setLocationsList(
            data.data
              .filter(
                (obj) =>
                  !location.state.completeLocationData.some(
                    (data) => obj.location_id === data.location_id
                  )
              )
              .map((data) => ({ ...data, checked: false }))
          );
          setTemporaryLocationsList(
            data.data
              .filter(
                (obj) =>
                  !location.state.completeLocationData.some(
                    (data) => obj.location_id === data.location_id
                  )
              )
              .map((data) => ({ ...data, checked: false }))
          );
          setSelectedLocationsCompleteDataArray(
            location.state.completeLocationData.map((data) => ({
              ...data,
              checked: true,
            }))
          );
          setCreatePostButton(true);
          setSelectedLocationsArray(
            location.state.completeLocationData.map((data) => data.location_id)
          );
          // console.log(
          //   "filtering two arrays",
          //   data.data.filter(
          //     (obj) =>
          //       !location.state.completeLocationData.some(
          //         (data) => obj.location_id === data.location_id
          //       )
          //   )
          // );
        } else {
          setLocationsList(
            data.data.map((data) => ({ ...data, checked: false }))
          );
          setTemporaryLocationsList(
            data.data.map((data) => ({ ...data, checked: false }))
          );
        }
        globalLocationId = data.data.length;
        data.data.forEach((obj) => {
          !administrativeAreaList.includes(obj.administrativeArea) &&
            administrativeAreaList.push(obj.administrativeArea);
          !localityList.includes(obj.locality) &&
            localityList.push(obj.locality);
        });
        setLocalityListBackup(localityList);
      } else {
        setLocationsList([]);
      }
    });
  };
  const setFilter = async (e) => {
    if (e.target.id === "administrativeList") {
      if (e.target.value !== "0") {
        setAdministrativeAreaFilterEnable(1);
        var filteredLocations = temporaryLocationsList.filter((obj) => {
          if (
            obj.administrativeArea === e.target.value ||
            obj.addressLines === e.target.value
          )
            return obj;
        });
        var localityArray = [];
        filteredLocations.forEach((obj) => {
          return (
            !localityArray.includes(obj.locality) &&
            localityArray.push(obj.locality)
          );
        });
        setLocationsList(filteredLocations);
        setAdministrativeAreaFilterEnableArray(filteredLocations);
        setLocalityList(localityArray);
      } else {
        setLocationsList(temporaryLocationsList);
        setLocalityList(localityListBackup);
        setAdministrativeAreaFilterEnable(0);
      }
    } else if (e.target.id === "localityFilter") {
      if (e.target.value !== "0") {
        setAdministrativeAreaFilterEnable(1);
        var filteredLocations = temporaryLocationsList.filter((obj) => {
          return obj.locality === e.target.value;
        });
        // var localityArray = [];
        // filteredLocations.forEach((obj) => {
        //   return (
        //     localityArray.includes(obj.storefrontAddress.locality) &&
        //     localityArray.push(obj.storefrontAddress.locality)
        //   );
        // });
        setLocationsList(filteredLocations);
        // setLocalityList(localityArray);
      } else {
        setAdministrativeAreaFilterEnable(0);
        setLocationsList(temporaryLocationsList);
      }
    }
  };
  const getLocationsList = () => {
    $(".loder_div").attr("hidden", false);
    const apiJson = {
      functionName: "GetLocationsUnderAccount",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    apiCallingPost(apiJson).then((data) => {
      if (data.success === "1") {
        $(".loder_div").attr("hidden", true);
        setLocationsList(data.data.locations);
        setTemporaryLocationsList(data.data.locations);
        globalLocationId = data.data.locations.length;
        data.data.locations.forEach((obj) => {
          !administrativeAreaList.includes(
            obj.storefrontAddress.administrativeArea
          ) &&
            administrativeAreaList.push(
              obj.storefrontAddress.administrativeArea
            );
          !localityList.includes(obj.storefrontAddress.locality) &&
            localityList.push(obj.storefrontAddress.locality);
        });
        setLocalityListBackup(localityList);
      } else {
        setLocationsList([]);
      }
    });
  };
  const selectLocation = (e, data, id) => {
    debugger;
    if (
      e.target.checked === true &&
      !selectedLocationsArray.includes(data.location_id)
    ) {
      // setSelectedLocationsCompleteDataArray((object) => [...object, data]);
      setSelectedLocationsArray((object) => [...object, data.location_id]);
      setCreatePostButton(true);
      let index = locationsList.findIndex((obj) => {
        return obj.location_id === data.location_id;
      });
      let index3 = temporaryLocationsList.findIndex((obj) => {
        return obj.location_id === data.location_id;
      });
      let a = locationsList.map((object) => {
        if (
          object.location_id === data.location_id &&
          object.checked === false
        ) {
          return { ...object, checked: true };
        }
        return object;
      });
      let deletedElA = a.splice(index, 1);
      locationsList.splice(index, 1);
      temporaryLocationsList.splice(index3, 1);
      setSelectedLocationsCompleteDataArray((object) => {
        console.log("deletedElAdeletedElA", object);
        return [...object, ...deletedElA];
      });
    } else if (e.target.checked === false) {
      if (selectedLocationsArray.length !== locationsList.filter) {
        $("#selectAllLocations").prop("checked", false);
      }

      let index = selectedLocationsCompleteDataArray.findIndex(
        (x) => x.location_id == data.location_id
      );
      let c = selectedLocationsCompleteDataArray.map((object) => {
        if (
          object.location_id === data.location_id &&
          object.checked === true
        ) {
          return { ...object, checked: false };
        }
        return object;
      });
      // selectedLocationsArray.splice(index, 1);
      selectedLocationsCompleteDataArray.splice(index, 1);
      let deletedElA = c.splice(index, 1);

      setLocationsList((object) => [...object, ...deletedElA]);
      setTemporaryLocationsList((object) => [...object, ...deletedElA]);

      let index2 = selectedLocationsArray.findIndex(
        (x) => x.location_id == data.location_id
      );
      selectedLocationsArray.splice(index2, 1);

      if (selectedLocationsArray.length === 0) {
        setCreatePostButton(false);
      } else {
        setCreatePostButton(true);
      }
    }
  };
  const selectAllLocations = (e) => {
    if (e.target.checked === true) {
      // for (let i = 0; i < globalLocationId; i++) {
      //   $("#selectLocationPost" + i).prop("checked", true);
      // }
      if (selectedLocationsArray.length > 0) {
        let filteredArray = locationsList.filter(
          (value) => !selectedLocationsArray.includes(value.location_id)
        );
        filteredArray.map((obj) => {
          selectedLocationsArray.push(obj.location_id);
          // selectedLocationsCompleteDataArray.push(obj);
        });
      } else {
        locationsList.map((obj) => {
          selectedLocationsArray.push(obj.location_id);
          // selectedLocationsCompleteDataArray.push(obj);
        });
      }
      setSelectedLocationsCompleteDataArray((obj) =>
        locationsList.map((data) => ({ ...data, checked: true }))
      );
      setLocationsList([]);
      setTemporaryLocationsList([]);
      if (selectedLocationsArray.length === 0) {
        setCreatePostButton(false);
      } else {
        setCreatePostButton(true);
      }
    } else {
      // for (let i = 0; i < globalLocationId; i++) {
      //   $("#selectLocationPost" + i).prop("checked", false);
      // }
      setLocationsList((data) =>
        selectedLocationsCompleteDataArray.map((data) => ({
          ...data,
          checked: false,
        }))
      );
      setTemporaryLocationsList((data) =>
        selectedLocationsCompleteDataArray.map((data) => ({
          ...data,
          checked: false,
        }))
      );
      setSelectedLocationsArray([]);
      setSelectedLocationsCompleteDataArray([]);
      setCreatePostButton(false);
    }
    console.log(selectedLocationsArray, "gooooooooooooooooooo");
    var selectAllList = [];
    // let includes = locationsList.some(item => selectedLocationsArray.includes(item.name.split("/")[1]))
    // console.log("foundfound", filteredArray);
  };
  return (
    <div className="main_wrapper  ">
      <LeftMenu />
      <div className="userListWrapper ">
        <Header heading="Google Post" headerImage={GooglePostimage} />
        <div className="container-fluid">
          <div className="row mb-3 ml-1">
            <Link to="/googlepost/postListing" className="back-to-link mr-3">
              <Icon.ArrowLeftCircle />
            </Link>
            <div className="subhead"> Create New Post</div>
          </div>
          <div className="row row-search ">
            <div className="col-lg-2 col-md-4 col-sm-6">
              <select
                id="administrativeList"
                onChange={(e) => {
                  setFilter(e);
                }}
                className="form-control"
              >
                <option value="0">State</option>
                {administrativeAreaList.map((data) => (
                  <option value={data}>{data}</option>
                ))}
              </select>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 ">
              <select
                id="localityFilter"
                onChange={(e) => setFilter(e)}
                className="form-control"
              >
                <option value="0">City</option>
                {localityList.map((data) => (
                  <option value={data}>{data}</option>
                ))}
              </select>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 location_search">
              <input
                className="form-control"
                type="text"
                placeholder="Search Business Address "
                onChange={(e) => {
                  if (e.target.value.length > 3) {
                    var local = locationsList.filter((obj) => {
                      let addressLines = obj.addressLines.toLocaleLowerCase();
                      return addressLines.includes(
                        e.target.value.toLocaleLowerCase()
                      );
                    });
                    setLocationsList(local);
                  } else {
                    administrativeAreaFilterEnable === 1
                      ? setLocationsList(administrativeAreaFilterEnableArray)
                      : setLocationsList(temporaryLocationsList);
                  }
                }}
              />
              {/* <div className="location_search_drop">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <span>Location1</span>
                  </li>
                  <li className="nav-item">
                    <span>Location2</span>
                  </li>
                  <li className="nav-item">
                    <span>Location3</span>
                  </li>
                  <li className="nav-item">
                    <span>Location4</span>
                  </li>
                  <li className="nav-item">
                    <span>Location5</span>
                  </li>
                  <li className="nav-item">
                    <span>Location6</span>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
          <br />
          <div className="gpostHead">
            <div className="subhead" style={{ fontSize: "16px" }}>
              {" "}
              Select Location(s) to post
            </div>{" "}
            {/* {createPostButton === true && (
              <div className="color_prim selectedCnt">
                Selected Count: {selectedLocationsCompleteDataArray.length}
              </div>
            )} */}
            {createPostButton === true && (
              <>
                <div>
                  <span className="color_prim selectedCnt">
                    (
                    <span style={{ fontWeight: "bold" }}>
                      {selectedLocationsCompleteDataArray.length}
                    </span>
                    /
                    <span style={{ fontWeight: "bold" }}>
                      {locationsList?.length +
                        selectedLocationsCompleteDataArray?.length}{" "}
                    </span>
                    locations selected)
                  </span>
                  <Link
                    to="/createNewPost"
                    state={{
                      selectedLocations: selectedLocationsArray,
                      completeLocationData: selectedLocationsCompleteDataArray,
                      PostEditField: [],
                    }}
                  >
                    <button
                      type="button"
                      className="btn-save ml-3 tag_Next btn-primary "
                    >
                      Next
                    </button>
                  </Link>
                </div>
              </>
            )}
          </div>
          <hr className="mt-0"></hr>
          <div className="col-md-12 tableResponsive">
            <div className="tableOuterWidth">
              <table className="table">
                <thead>
                  <tr>
                    <th className="col-1">
                      {" "}
                      <input
                        type="checkbox"
                        name=""
                        id="selectAllLocations"
                        onClick={(e) => selectAllLocations(e)}
                      />{" "}
                    </th>
                    <th className="col-2">Location(s)</th>
                    <th className="col-4">Address</th>
                    <th className="col-3">Locality</th>
                    <th className="col-2">Area</th>
                  </tr>
                </thead>
                <div className="table_Outerscroll">
                  {selectedLocationsCompleteDataArray.map((data, i) => (
                    <tbody className="table_Outertbody">
                      <tr>
                        <td className="col-1">
                          {" "}
                          <input
                            id={"selectLocationPost" + i}
                            type="checkbox"
                            checked={data.checked}
                            name=""
                            onClick={(e) => selectLocation(e, data, i)}
                          />
                        </td>
                        <td className="col-2">{data.title}</td>
                        <td className="col-4">
                          {data.storeCode}-{data.addressLines}
                        </td>
                        <td className="col-3">{data.locality}</td>
                        <td className="col-2">{data.administrativeArea}</td>
                      </tr>
                    </tbody>
                  ))}
                  {locationsList.map((data, i) => (
                    <tbody className="table_Outertbody">
                      <tr>
                        <td className="col-1">
                          {" "}
                          <input
                            id={"selectLocationPost" + i}
                            type="checkbox"
                            checked={data.checked}
                            name=""
                            onClick={(e) => selectLocation(e, data, i)}
                          />
                        </td>
                        <td className="col-2">{data.title}</td>
                        <td className="col-4">
                          {data.storeCode}-{data.addressLines}
                        </td>
                        <td className="col-3">{data.locality}</td>
                        <td className="col-2">{data.administrativeArea}</td>
                      </tr>
                    </tbody>
                  ))}
                </div>
              </table>
            </div>
          </div>

          <br />
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden />
    </div>
  );
}

export default GooglePost;
