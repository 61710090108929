import React, { useEffect } from "react";
import $, { data } from "jquery";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { backendApi } from "../../../apiService";
import * as Icon from "react-feather";

function InviteAdminToListingComponent(props) {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [Title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [description, setdescription] = useState("");
  const [phone, setPhone] = useState("");
  const [adminstriveArea, setadminstriveArea] = useState("");
  const [locality, setlocality] = useState("");
  const [address, setaddress] = useState("");
  const [postalCode, setpostalCode] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  //   const [Verificationmethod, setVerificationmethod] = useState("");
  //   const [VerificationCodeEmail, setVerificationCodeEmail] = useState("");
  //   const [verificationRole, setverificationRole] = useState("");
  //   const [VerificationmethodPhoneNumber, setVerificationmethodPhoneNumber] =
  //     useState("");
  //   const [VerificationCode, setVerificationCode] = useState("");
  //   const [currentPassword, setCurrentPassword] = useState("");
  //   const [newPassword, setNewPassword] = useState("");
  //   const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const closeHandler = (e) => {
    setShow(false);
    props.onClose(false);
  };
  useEffect(() => {
    setShow(props.show);
    setTitle(props.props.full_name);
    setFirstName(props.props.full_name);
    setdescription(
      window.sessionStorage.getItem("switchUserOrganization") !== ""
        ? window.sessionStorage.getItem("switchUserOrganization")
        : window.sessionStorage.getItem("organization")
    );
    setPhone(props.props.mobile_no);
    setadminstriveArea(props.props.administrativeArea);
    setlocality(props.props.locality);
    setaddress(props.props.address);
    setpostalCode(props.props.postalCode);
    console.log("loooog", props);
  }, [props.show]);

  const InviteAdminToLocation = (e) => {
    e.preventDefault();
    const apiReq = {
      functionName: "InviteAdminToLocationPush",
      siteId:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      email: adminEmail,
      role: "OWNER",
      locationid: props.props.location_id,
    };
    $(".loder_div").attr("hidden", false);
    backendApi(apiReq).then((result) => {
      $(".loder_div").attr("hidden", true);
      if (result.success === "1") {
        const apiReq = {
          functionName: "updatePushStatus",
          location_id: props.props.location_id,
          g_pin: props.props.g_pin,
          status: "Admin Invited",
          id: props.props.id,
          full_name: props.props.full_name,
          address: props.props.address,
          mobile_no: props.props.mobile_no,
          email_id: props.props.email_id,
          locality: props.props.locality,
          category: props.props.category,
          administrativeArea: props.props.administrativeArea,
          postalCode: props.props.postalCode,
          storeCode: props.props.storeCode,
          verification_code: props.props.verification_code,
          errorMessage: "",
        };
        props.updateData(apiReq);
        closeHandler();
      } else {
        const apiReq = {
          functionName: "updatePushStatus",
          location_id: props.props.location_id,
          g_pin: props.props.g_pin,
          status: "Verification Completed",
          id: props.props.id,
          full_name: props.props.full_name,
          address: props.props.address,
          mobile_no: props.props.mobile_no,
          email_id: props.props.email_id,
          category: props.props.category,
          locality: props.props.locality,
          administrativeArea: props.props.administrativeArea,
          postalCode: props.props.postalCode,
          storeCode: props.props.storeCode,
          verification_code: props.props.verification_code,
          errorMessage: "",
        };
        props.updateData(apiReq);
        closeHandler();
      }
    });
  };

  return (
    <div
      className="headerPopup"
      id="verificationCode"
      hidden={show ? false : true}
    >
      <div className="module_popup">
        <div className="module_innerpopup">
          <div className="module_close">
            <Icon.XCircle onClick={() => closeHandler()} />
          </div>
          <div className="module_header text-center">
            Invite Admin to Location
          </div>
          {/* <button
            type="button"
            className="btn btn-save mb-1 gobutton_position"
            onClick={() => closeHandler()}
          >
            X
          </button> */}
          <div className="module_body">
            <div className="module_popupsearch_wrpr">
              <div className="module_popupsearch">
                <div className="container">
                  <form
                    className="user_formdiv col-md-10"
                    id="createLocationForm"
                  >
                    <div className="row userrow_form" id="gbpPassword">
                      <div className="col-md-4 d-flex align-items-center ">
                        <label className="col-form-label pt-0">
                          {" "}
                          Admin Email Id
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          onChange={(e) => setAdminEmail(e.target.value)}
                          id="Admin Email Id"
                          className="form-control"
                          placeholder="Admin Email Id"
                        />
                      </div>
                    </div>
                    <div className="form_bttn">
                      <button
                        type="submit"
                        className="btn btn-primary submitButton saveListng"
                        onClick={(e) => InviteAdminToLocation(e)}
                      >
                        <span>Invite Admin</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InviteAdminToListingComponent;
