import React from "react";
import * as Icon from "react-feather";

export default function DataInput({ handleFile }) {
	const handleChange = (e) => {
		console.log(e.target.files);
		const files = e.target.files;
		if (files && files[0]) handleFile(files[0]);
	};
	return (
		<>
			<Icon.UploadCloud className="uploadCloudIcon" />
			<div className="form-label-fileuploaddiv">
				<label className="form-label-fileupload" htmlFor="file">
					Drop a file
				</label>
				<input type="file" className="" id="file" accept={AcceptFileFormats} onChange={handleChange} />
				<span className="form-fileupload-span">Or</span>
			</div>
			<div className="upload-btn-wrapper">
				<button className="form-fileupload-btn">Upload a file</button>
				<input type="file" name="file" onChange={handleChange} accept={AcceptFileFormats} />
			</div>
		</>
	);
}

const AcceptFileFormats = ["xlsx", "xlsb", "xlsm", "xls"];
