import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { backendDashboardApi } from "../apiService";

const LineChart = ({ fromPage, formattedDates }) => {
  // Sample data for demonstration
  const sampleData = {
    series: [
      {
        name: 'Series 1',
        data: [30, 40, 35, 50, 49]
      },
      {
        name: 'Series 2',
        data: [45, 55, 50, 60, 61]
      }
    ]
  };

  const [chartData, setChartData] = useState(sampleData);

  useEffect(() => {
    const fetchData = () => {
      console.log("deeeeeeeeeeeeeeeeeeeeeeeeeeeee", fromPage)
      setTimeout(() => {
        setChartData(sampleData);
      }, 3000);
    };

    fetchData();
  }, []);






  // Options for ApexCharts
  const chartOptions = {
    chart: {
      type: 'line',
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [], // Empty array for X-axis
      labels: {
        text: 'Leads',
        show: fromPage === "leadsGenerated" ? false : true
      }
    },
    yaxis: {
      labels: {
        text: 'Time'
      }
    },
    stroke: {
      width: 3
    }
  };

  return (
    <div className={fromPage == "leadsGenerated" ? 'custom-line-chart inactive_div' : 'custom-line-chart custom-line-chart-full'}>
      <Chart
        options={chartOptions}
        series={chartData.series}
        type="line"
        width="100%"
        height={fromPage == "leadsGenerated" ? "150px" : "250px"}
      />
    </div>
  );
};

export default LineChart;
