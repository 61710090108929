import React, { Component } from "react";
import * as Icon from "react-feather";
import LeftMenu from "../layout/leftMenu";
import { Link } from "react-router-dom";
import { backendApi } from "../apiService";
import Header from "../layout/header";
import Footer from "../layout/footer";
import "../css/bootstrap.css";
import GoogleChatimage from "../images/left_icons/Google-Chat.png";
import "../css/style.css";
import ModuleList from "../admin/module_list";
import $ from "jquery";
import Samle01 from "../images/images1.jpg";
import deletebut from "../images/Group122.png";
class NewChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatList: [],
      chatAgent: [],
      chatName: "",
      agentName: "",
      startTime: "",
      endTime: "",
      agentEmail: "",
      agentMobile: "",
      agentList: [],
      createdAgent: false,
      searchPagesCriteria: false,
      finalAgentPageList: [],
      finalAgentPageListCriteria: false,
      gbpList: [
        {
          id: "1",
          name: "HDFC 1",
          url: "www.hdfc1.com",
        },
        {
          id: "2",
          name: "HDFC 2",
          url: "www.hdfc2.com",
        },
        {
          id: "3",
          name: "HDFC 3",
          url: "www.hdfc3.com",
        },
        {
          id: "4",
          name: "HDFC 4",
          url: "www.hdfc4.com",
        },
      ],
      tempGBPList: [],
    };
  }
  componentDidMount(e) {
    this.getchatList();
    this.getchatAgents();
    // $("#headerName").text("Local Pages");
    $(".search_input_values").click(function () {
      $(this).siblings().show();
    });
    $(document).mouseup(function (e) {
      var container = $(".dropdown_chat_select");

      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.hide();
      }
    });
  }
  NewChatPopup() {
    $("body").addClass("newchat_visible");
  }
  ClosePopup() {
    $("body").removeClass("newchat_visible");
  }
  NewAgentPopup() {
    $("body").addClass("newchat_agentvisible");
  }
  CloseAgentPopup() {
    $("body").removeClass("newchat_agentvisible");
  }
  NewpagePopup() {
    $("body").addClass("newpage_popup");
  }
  CloseNewpagePopup() {
    $("body").removeClass("newpage_popup");
  }

  getchatList() {
    var json = {
      functionName:"getchat"
    }
    backendApi(json).then((data) => {
      if (data.success === "1") {
        this.setState({ chatList: data.data });
      }
    });
  }

  getchatAgents() {
    var json = {
      functionName:"chatAgent"
    }
    backendApi(json).then((data) => {
      if (data.success === "1") {
        this.setState({ chatAgent: data.response.data });
      }
    });
  }

  saveAgent() {
    var obj = {};
    obj.agentName = this.state.agentName;
    obj.startTime = this.state.startTime;
    obj.endTime = this.state.endTime;
    obj.agentEmail = this.state.agentEmail;
    obj.agentMobile = this.state.agentMobile;
    this.state.agentList.push(obj);
    this.setState({ createdAgent: true });
    $("body").removeClass("newchat_agentvisible");
  }
  outTable = () => {
    return (
      <div id="agentTableId" className="newchatpopup_content">
        <div className="table-responsive">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Si.no</th>
                <th>Agent Name</th>
                <th>Start Time</th>
                <th>End Time</th>
              </tr>
            </thead>
            {this.state.agentList.length > 0 ? (
              <tbody>
                {this.state.agentList.map((item, i) => (
                  <tr key={item.agentName}>
                    <td>{i + 1}</td>
                    <td>{item.agentName}</td>
                    <td>{item.startTime}</td>
                    <td>{item.endTime}</td>
                  </tr>
                ))}
              </tbody>
            ) : (
              ""
            )}
          </table>
        </div>
      </div>
    );
  };
  saveChat() {
    console.log("agent_list",this.state.agentList);
    console.log("finalPagesList",this.state.finalAgentPageList)
    var agent_list = []
    var agent_pages = []
    for(let i=0;i<this.state.agentList.length;i++){
      var obj={}
      obj.agent_name=this.state.agentList[i].agentName
      obj.start_time=this.state.agentList[i].startTime
      obj.end_time=this.state.agentList[i].endTime
      obj.agent_mobileno=this.state.agentList[i].agentMobile
      obj.agent_emailid=this.state.agentList[i].agentEmail
      agent_list.push(obj)
    }
    for(let j=0;j<this.state.finalAgentPageList.length;j++){
      let json = {}
      json.local_site_id = this.state.finalAgentPageList[j].id
      agent_pages.push(json)
    }
    console.log(agent_list);
    console.log(agent_pages);
    let id="108668271910901830220"
    var apidata = {
      functionName:"createAgent",
      chatId: "",
      chat_name: this.state.chatName,
      created_by: "20",
      agent_list: agent_list,
      agent_pages: agent_pages,
      accountId:parseInt(id)
    };

    console.log("req======",apidata);
    // var apidata = {
    //   chatId: "",
    //   chat_name: this.state.chatName,
    //   created_by: "20",
    //   agent_list: [
    //     {
    //       agent_name: this.state.agentName,
    //       start_time:
    //         this.state.startTime.split(":")[0] >= 12
    //           ? this.state.startTime + "PM"
    //           : this.state.startTime + "AM",
    //       end_time:
    //         this.state.endTime.split(":")[0] >= 12
    //           ? this.state.endTime + "PM"
    //           : this.state.endTime + "AM",
    //       agent_mobileno: this.state.agentEmail,
    //       agent_emailid: this.state.agentMobile,
    //     },
    //   ],
    //   agent_pages: [
    //     {
    //       local_site_id: "23",
    //     },
    //     {
    //       local_site_id: "25",
    //     },
    //     {
    //       local_site_id: "26",
    //     },
    //   ],
    // };

    backendApi(apidata).then((data) => {
      if (data.success === "1") {
        if (this.state.templateId == undefined) {
          alert("Agent created Successfully");
          window.location.reload();
        } else {
          alert("Error on ");
        }
      } else {
        return false;
      }
    });
  }
  searchPages = (e) => {
    // console.log(e.target.value);
    if (e.target.value.length > 2) {
      this.setState({ tempGBPList: this.state.gbpList });
      var newList = this.state.gbpList.filter((obj) => {
        return obj.name.toLowerCase().includes(e.target.value.toLowerCase());
      });
      console.log(newList);
      this.setState({ gbpList: newList, searchPagesCriteria: true }, () => {
        // this.agentPagesList()
      });
    }
  };
  agentPagesList = () => {
    console.log("this.state.gbpList", this.state.gbpList);
    return (
      <>
        {this.state.gbpList.length > 0 ? (
          <ul className="list-group mt-2">
            {this.state.gbpList.map((data, i) => (
              <li
                key={data.id}
                className="list-group-item listgrp_spacebtw"
                onClick={() => this.finalPageListing(data)}
              >
                <span>
                  {i + 1}-{data.name}
                </span>
                <img src={deletebut} width="40px" />
              </li>
            ))}
          </ul>
        ) : (
          ""
        )}
      </>
    );
  };
  finalPageListing = (data) => {
    this.setState({ searchPagesCriteria: false });
    this.state.finalAgentPageList.push(data);
    console.log(this.state.gbpList);
    console.log(this.state.finalAgentPageList);
    var index = this.state.gbpList.findIndex((obj) => obj.name === data.name);
    this.state.gbpList.splice(index, 1);
  };
  saveAgentPages = () => {
    this.CloseNewpagePopup();
    this.setState({ finalAgentPageListCriteria: true, gbpList: this.state.tempGBPList });
  };
  render() {
    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper">
          <Header heading="Google Chat" headerImage={GoogleChatimage}/>
          <div className="container-fluid">
          <div className="new_chatbtndiv w-100">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                this.NewChatPopup();
              }}
            >
              New Chat
            </button>
          </div>
          <div className="user_listdiv new_chat mt-1 w-100">
            <div className="new_div_view">
              <div className="newchat_leftpopup">
                <div className="chat_name_input mb-2">
                  <span>Chat Name :</span>
                  <input
                    type="text"
                    placeholder="Chat Name"
                    className="form-control mb-0"
                    onChange={(e) =>
                      this.setState({
                        chatName: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="search_div_view search_edit_view">
                  <div className="input_edit_view">
                    {/* <input
                      type="text"
                      className="form-control search_input_values"
                      placeholder="Search"
                    />
                    <div className="dropdown_chat_select">
                      <ul className="nav flex-column">
                        <li>Testing</li>
                        <li>Testing</li>
                        <li>Testing</li>
                        <li>Testing</li>
                      </ul>
                    </div> */}
                  </div>
                  <div className="search_edit_button">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        this.NewAgentPopup();
                      }}
                    >
                      New Agent
                    </button>
                  </div>
                </div>
                <div className="newchatpopup_head"></div>
                {this.state.createdAgent === true && this.outTable()}
              </div>
              <div className="newchat_rightpopup">
                <div className="chat_name_input mb-2 invisible">
                  <span>invisible</span>
                  <input
                    type="text"
                    placeholder="Chat Name"
                    className="form-control"
                  />
                </div>
                <div className="search_div_view search_edit_view">
                  <div className="input_edit_view">
                    {/* <input type='text' className="form-control search_input_values" placeholder="Search"/>
                    <div className="dropdown_chat_select">
                        <ul className="nav flex-column">
                            <li>Testing</li>
                            <li>Testing</li>
                            <li>Testing</li>
                            <li>Testing</li>
                        </ul>
                    </div> */}
                  </div>
                  <div className="search_edit_button">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        this.NewpagePopup();
                      }}
                    >
                      Add Page
                    </button>
                  </div>
                </div>
                {this.state.finalAgentPageListCriteria === true && (
                  <div className="newchatpopup_content">
                    {this.state.finalAgentPageList.length > 0 && (
                      <ul className="list-group">
                        {this.state.finalAgentPageList.map((data, i) => (
                          <li className="list-group-item listgrp_spacebtw">
                            <span>
                              {i + 1}-{data.name}
                            </span>
                            <img src={deletebut} width="40px" />
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
                {/* <div className="newchatpopup_content">
                  <ul className="list-group">
                    <li className="list-group-item listgrp_spacebtw">
                      <span>Cras justo odio</span>
                      <img src={deletebut} width="40px" />
                    </li>
                    <li className="list-group-item listgrp_spacebtw">
                      <span>Cras justo odio</span>
                      <img src={deletebut} width="40px" />
                    </li>
                    <li className="list-group-item listgrp_spacebtw">
                      <span>Cras justo odio</span>
                      <img src={deletebut} width="40px" />
                    </li>
                  </ul>
                </div> */}
              </div>
              <div className="newagent_popupmodal">
                <div className="newagent_popupinner">
                  <Icon.XSquare
                    className="new_chatclose"
                    onClick={() => {
                      this.CloseAgentPopup();
                    }}
                  />
                  <div className="form_row">
                    <label>Agent Name</label>
                    <input
                      type="text"
                      placeholder="Agent Name"
                      className="form-control"
                      onChange={(e) =>
                        this.setState({
                          agentName: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form_row">
                    <label>Start Time</label>
                    <input
                      type="time"
                      className="form-control"
                      onChange={(e) =>
                        this.setState({
                          startTime: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form_row">
                    <label>End Time</label>
                    <input
                      type="time"
                      className="form-control"
                      onChange={(e) =>
                        this.setState({
                          endTime: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="form_row">
                    <label>Agent Email</label>
                    <input
                      type="text"
                      placeholder="Agent Email"
                      className="form-control"
                      onChange={(e) =>
                        this.setState({
                          agentEmail: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form_row">
                    <label>Agent mobile No.</label>
                    <input
                      type="text"
                      placeholder="Agent Mobile number"
                      className="form-control"
                      onChange={(e) =>
                        this.setState({
                          agentMobile: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="newchatpop_footer">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => this.saveAgent()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>

              <div className="newchatpop_footer">
                <button
                  type="button"
                  className="btn btn-danger mr-2"
                  onClick={() => this.ClosePopup()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.saveChat()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <div className="table_wrapper col-md-12 tableResponsive">
            <div className="tableOuterWidth table_width_sm">
              <table className="table">
                <thead>
                  <tr>
                    <th className="col-3">Sl.no</th>
                    <th className="col-3">Chat Name</th>
                    <th className="col-3">Action</th>
                    <th className="col-3">Edit</th>
                  </tr>
                </thead>
                <div class="table_Outerscroll">
                {this.state.chatList.map((item) => (
                <tbody  className="table_Outertbody">                 
                    <tr>
                      <td className="col-3">{item.id}</td>
                      <td className="col-3">{item.chat_name}</td>
                      <td className="col-3">
                        <span>
                          <label className="switch_items">
                            <input
                              type="checkbox"
                              defaultChecked={
                                item.is_active === 1 ? true : false
                              }
                            />
                            <span className="switch round"></span>
                          </label>
                        </span>
                      </td>
                      <td  className="col-3"
                        onClick={() => {
                          this.NewChatPopup();
                        }}
                      >
                        {" "}
                        <Icon.Edit />
                      </td>
                    </tr>                 
                </tbody>
                ))}
                </div>
              </table>
            </div>
          </div>
          </div>
          <Footer/>
        </div>

        <div className="addpages_popup">
          <div className="addpages_popupinner">
            <Icon.XSquare
              className="new_chatclose"
              onClick={() => {
                this.CloseNewpagePopup();
              }}
            />
            <div className="newchatpopup_content">
              <div className="newchatpopup_search">
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control"
                  onKeyUp={(e) => this.searchPages(e)}
                />
              </div>
              {this.state.searchPagesCriteria === true && this.agentPagesList()}
              {/* <ul className="list-group mt-2">
                <li className="list-group-item listgrp_spacebtw">
                  <span>Cras justo odio</span>
                  <img src={deletebut} width="40px" />
                </li>
                <li className="list-group-item listgrp_spacebtw">
                  <span>Cras justo odio</span>
                  <img src={deletebut} width="40px" />
                </li>
                <li className="list-group-item listgrp_spacebtw">
                  <span>Cras justo odio</span>
                  <img src={deletebut} width="40px" />
                </li>
              </ul> */}
              <div className="newchatpop_footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.saveAgentPages()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewChat;
