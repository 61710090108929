import React, { Component } from "react";
import {LineChart} from 'react-charts-d3';

class LinechartUser extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            data : props.data,
            axisConfig:{ showXAxis: true, showXAxisLabel: true, xLabel: props.xAxisLabel, xLabelPosition: 'center', showYAxis: true, showYAxisLabel: true, yLabel: props.yAxisLabel, yLabelPosition: 'middle', }

        };
    }

    render() {
        return (
            <LineChart margin={	{ top: 50, left: 40, bottom: 30, right: 10 }} strokeWidth={3} width={400} height={100} data={this.state.data} axisConfig={false} showGrid={false} drawScatterPointers={false}  />
        );
    }
}
export default LinechartUser;
