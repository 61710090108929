import React, { useEffect, useState } from 'react'
import Img from "../images/arrow-up.png"
import Sales from "./db-sales-card"
import Zones from "./db-zones-card"
import Graph from "./db-graph"
import Table from "./db-table"
import { backendDashboardApi } from "../apiService";
import TopPerformnce from './top-performance'
import TopPerformnceCRE from './top-performance-cre'
import LeadsGenerated from './leads-generated'
import GbpSearches from './gbp-searches'
import SocialMediaEng from './social-meida-engagement'
import GooglePaidMedia from './google-paid-media'
import MetaPaidMedia from './meta-paid-media'
function Dashboardmain({ formattedDates }) {

    const Select = () => {
        document.querySelectorAll('.an-select-drop').forEach(function (div) {
            div.classList.toggle('an-select-toggle');

        });
    };
    const [budgetList, setBudgetList] = useState([]);
    const [graphArrayOne, setGraphArrayOne] = useState([]);
    const [graphArrayTwo, setGraphArrayTwo] = useState([]);
    const [graphArrayThree, setGraphArrayThree] = useState([]);
    const [leadsList, setLeadsList] = useState([]);
    const [topPerformingList, setTopPerformingList] = useState([]);
    const [topPerformingCREList, setTopPerformingCREList] = useState([]);
    const [productValueResult, setProductValueResult] = useState("");
    const [activeButton, setActiveButton] = useState('');
    const [activeButtonState, setActiveButtonState] = useState('');
    const [stateHeading, setStateHeading] = useState('');
    const [stateZoneHeading, setStateZoneHeading] = useState('');
    const [clickedOnce, setClickedOnce] = useState(true);
    const [lastClickedType, setLastClickedType] = useState("");
    const [zoneId, setZoneId] = useState("");
    const [stateId, setStateId] = useState("");
    const [stateZoneId, setStateZoneId] = useState("");
    const [districtId, setDistrictId] = useState("");
    const [cityId, setCityId] = useState("");
    const [localityId, setLocalityId] = useState("");
    const [dealerMasterId, setDealerMasterId] = useState("");
    useEffect(() => {
        // getProductTypeValue()
        budgetListing()
        leadsListing()
        graphData()
    }, [formattedDates]);

    useEffect(() => {
        topPerforming()
        topPerformingCRE()
    }, [zoneId, stateId, stateZoneId, districtId, cityId, localityId, dealerMasterId]);

    useEffect(() => {
        // Render the component whenever topPerformingCREList has values
        if (topPerformingCREList.length > 0) {
            console.log("topPerformingCREList has values, re-rendering...");
            return;
        }
    }, [topPerformingCREList]);

    // const getProductTypeValue = () => {
    //     const postData = {
    //         "functionName": "getOrganizationSettings",
    //         "accountId": window.sessionStorage.getItem("switchingOrgId"),
    //     }
    //     backendDashboardApi(postData).then((data) => {
    //         console.log("🚀setProductValueResult ~ apiCallingPost ~ data:", data.result.isHighValue)
    //         if (data.success === "1") {
    //             setProductValueResult(data.result.isHighValue)
    //             if (data.result.isHighValue === true) {

    //             }
    //         } else {
    //         }
    //     });
    // }
    const budgetListing = () => {
        const postData = {
            "functionName": "budgetAllocationDashboard",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "fromDate": formattedDates[0],
            "toDate": formattedDates[1],
            "userId": window.sessionStorage.getItem("userId"),
        }
        backendDashboardApi(postData).then((data) => {
            console.log("dataaaaaaaaa", data)
            if (data.success === "1") {
                setBudgetList(data.accountBudgetMasterDetails)
            } else {
                setBudgetList([])
            }
        });
    };
    const leadsListing = () => {
        const postData = {
            "functionName": "getleadsDetailsByAccountId",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "userId": window.sessionStorage.getItem("userId"),
            "startDate": formattedDates[0],
            "endDate": formattedDates[1],
        };
        backendDashboardApi(postData).then((data) => {
            console.log("leads data", data)
            if (data.success === "1") {
                setLeadsList(data.result)
            } else {
                setLeadsList([])
            }
        });
    };
    const zonalGraphData = (typeId) => {
        setActiveButton(typeId);
        // setActiveButtonState("")
        const postData = {
            "functionName": "userDashboard",
            "userId": window.sessionStorage.getItem("userId"),
            "zoneId": zoneId,
            "stateId": stateId,
            "stateZoneId": stateZoneId,
            "districtId": districtId,
            "cityId": cityId,
            "localityId": localityId,
            "fromDate": formattedDates[0],
            "toDate": formattedDates[1],
            "arrayValue": "1",
            "dealerMasterId": dealerMasterId,
            "typeId": typeId ? typeId : ""
        }
        backendDashboardApi(postData).then((data) => {
            console.log("zonalgraph dataaaaaaaaaaaaa", data)
            if (data.success === "1") {
                setGraphArrayOne(data.array1)
            } else {

            }
        });
    };
    const stateGraphData = (typeId, data) => {
        debugger
        setActiveButtonState(typeId)
        // setActiveButton("");
        const postData = {
            "functionName": "userDashboard",
            "userId": window.sessionStorage.getItem("userId"),
            "zoneId": zoneId,
            "stateId": stateId,
            "stateZoneId": stateZoneId,
            "districtId": districtId,
            "cityId": cityId,
            "localityId": localityId,
            "fromDate": formattedDates[0],
            "toDate": formattedDates[1],
            "arrayValue": "1",
            "dealerMasterId": dealerMasterId,
            "typeId": typeId ? typeId : ""
        }
        backendDashboardApi(postData).then((data) => {
            console.log("graph dataaaaaaaaaaaaa", data)
            if (data.success === "1") {
                setGraphArrayTwo(data.array2)
            } else {

            }
        });
    };
    const graphData = (data, mode, type) => {
        setActiveButton("");
        setActiveButtonState("")
        setStateHeading(type)
        setStateZoneHeading(type)
        const postData = {
            "functionName": "userDashboard",
            "userId": window.sessionStorage.getItem("userId"),
            "zoneId": "",
            "stateId": "",
            "stateZoneId": "",
            "districtId": "",
            "cityId": "",
            "localityId": "",
            "fromDate": formattedDates[0],
            "toDate": formattedDates[1],
            "arrayValue": "",
            "dealerMasterId": "",
            "typeId": ""
        }
        backendDashboardApi(postData).then((data) => {
            console.log("graph dataaaaaaaaaaaaa", data)
            if (data.success === "1") {
                setGraphArrayOne(data.array1)
                setGraphArrayTwo(data.array2)
                setStateHeading(data.array2[0].masterName)
                setGraphArrayThree(data.array3)
                setStateZoneHeading(data.array3[0].masterName)
            } else {

            }
        });
    };
    const graphDataClick = (data, mode, type) => {
        debugger
        if (type == lastClickedType) {
            graphData();
            setClickedOnce(!clickedOnce);
            setLastClickedType("");
            clearStates()
        } else {
            if (mode === "Zone") {
                setZoneId(data)
            }
            if (mode === "Region") {
                setStateId(data)
            }
            if (mode === "State Zone") {
                setStateZoneId(data)
            }
            if (mode === "District") {
                setDistrictId(data)
            }
            if (mode === "City") {
                setCityId(data)
            } if (mode === "Locality") {
                setLocalityId(data)
            }
            if (mode === "Dealer") {
                setDealerMasterId(data)
            }
            setActiveButton("");
            setActiveButtonState("")
            setStateHeading(type)
            setStateZoneHeading(type)
            setLastClickedType(type);
            setClickedOnce(!clickedOnce);
            const postData = {
                "functionName": "userDashboard",
                "userId": window.sessionStorage.getItem("userId"),
                "zoneId": mode === "Zone" ? data : "",
                "stateId": mode === "Region" ? data : "",
                "stateZoneId": mode === "State Zone" ? data : "",
                "districtId": mode === "District" ? data : "",
                "cityId": mode === "City" ? data : "",
                "localityId": mode === "Locality" ? data : "",
                "fromDate": formattedDates[0],
                "toDate": formattedDates[1],
                "arrayValue": "1",
                "dealerMasterId": mode === "Dealer" ? data : "",
                "typeId": ""
            }
            backendDashboardApi(postData).then((data) => {
                console.log("graph dataaaaaaaaaaaaa", data)
                if (data.success === "1") {
                    setGraphArrayOne(data.array1)
                    setGraphArrayTwo(data.array2)
                    setGraphArrayThree(data.array3)
                    //  topPerforming()

                } else {

                }
            });

        }
    };
    const clearStates = () => {
        setZoneId("")
        setStateId("")
        setStateZoneId("")
        setDistrictId("")
        setCityId("")
        setLocalityId("")
        setDealerMasterId("")
    }
    const topPerforming = () => {
        const postData = {
            "functionName": "topPerformingProductList",
            "userId": window.sessionStorage.getItem("userId"),
            "startDate": formattedDates[0],
            "endDate": formattedDates[1],
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "zoneId": zoneId,
            "stateId": stateId,
            "stateZoneId": stateZoneId,
            "districtId": districtId,
            "cityId": cityId,
            "localityId": localityId,
            "dealerMasterId": dealerMasterId
        }
        backendDashboardApi(postData).then((data) => {
            console.log("top performing", data)
            if (data.success === "1") {
                setTopPerformingList(data.result)
            } else {

            }
        });
    };

    const topPerformingCRE = () => {
        const postData = {
            "functionName": "topPerformingSalesPerson",
            "userId": window.sessionStorage.getItem("userId"),
            "startDate": formattedDates[0],
            "endDate": formattedDates[1],
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "zoneId": zoneId,
            "stateId": stateId,
            "stateZoneId": stateZoneId,
            "districtId": districtId,
            "cityId": cityId,
            "localityId": localityId,
            "dealerMasterId": dealerMasterId
        }
        console.log("🚀 ~ topPerformingCRE ~ postData:", postData)
        backendDashboardApi(postData).then((data) => {
            console.log("top performing", data)
            if (data.success === "1") {
                setTopPerformingCREList(data.result)
            } else {

            }
        });
    };


    const [activeChild, setActiveChild] = useState(null);
    const handleChildClick = (childId) => {
        setActiveChild(activeChild === childId ? null : childId);
    };
    const [activeChild1, setActiveChild1] = useState(null);
    const handleChildClick1 = (childId) => {
        setActiveChild1(activeChild1 === childId ? null : childId);
    };
    const [activeChild2, setActiveChild2] = useState(null);
    const handleChildClick2 = (childId) => {
        setActiveChild1(activeChild2 === childId ? null : childId);
    };
    return (
        <div className='db-main pt-3'>
            <div className='container-fluid'>
                <div className="d-lg-flex">
                    <Sales formattedDates={formattedDates} />
                    {(window.sessionStorage.getItem("roleId") !== "11") && (window.sessionStorage.getItem("roleId") !== "12") ?
                        <div className="db-cards-left ">
                            <div class="card db-height">
                                {leadsList && leadsList.map((data, index) =>
                                    <div class="card-body mb-0 p-0 pt-2 pb-2">
                                        <div className='db-cards-top'>
                                            <h5 class="card-title">{data.total_leads.toLocaleString('en-IN')} <span className='db-cad-top-units'>Units</span>
                                                <span>
                                                    <img src={Img} alt="Img" className='img-fluid ms-2' width="17" height="17" />
                                                </span>
                                            </h5>
                                            {console.log("<span className='db-line-span'>", formattedDates[0])}
                                            <span className='db-right-btn'>{formattedDates[0] === formattedDates[1] ? formattedDates[0] : formattedDates[0] + "-" + formattedDates[1]}</span>
                                        </div>
                                        <h6 class="card-subtitle mb-3">Total Leads</h6>
                                        <div class="card bg-transparent border-0 shadow-none cards-width px-0 justify-content-center text-center w-100 pb-2 mb-0 pt-0">
                                            <div className='db-head invisible'>
                                                Budget Allocation :
                                            </div>
                                        </div>
                                        <div class="card bg-white shadow-none">
                                            <div className='db-cards-top'>
                                                <h5 class="db-card-title">Cost/Lead</h5>
                                                <span className='db-right-number'>&#8377;{data.cost_lead}
                                                    <span className='db-percentage'>{(data.cost_lead_symbol) && `(${data.cost_lead_symbol})`}</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="card bg-white shadow-none active_div" >
                                            <div className='db-cards-top'>
                                                <h5 class="db-card-title">Enquiries </h5>
                                                <span className='db-right-number'>{data.enquiries.toLocaleString('en-IN')}
                                                    {/* <span className='db-percentage'>%</span> */}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="card bg-white shadow-none active_div">
                                            <div className='db-cards-top'>
                                                <h5 class="db-card-title">Lead 2 Enquiries </h5>
                                                <span className='db-right-number'>
                                                    {data.leads_2_enquiry.toFixed(2)}
                                                    <span className='db-percentage'>%</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div> :
                        <LeadsGenerated formattedDates={formattedDates} />}
                    {budgetList.length > 0 && (window.sessionStorage.getItem("roleId") !== "12") ?
                        <>
                            {budgetList.map((data) => (
                                <div className="db-cards-right">
                                    <div class="card db-height">
                                        <div class="card-body mb-0 p-0 pt-2">
                                            <div className="d-md-flex">
                                                <div className="db-card-width">
                                                    <div className='db-cards-top db-cards-bar'>
                                                        <h5 class="card-title">{'\u20B9'}{data.overallSpent}{data.ovarAllSpendSymbol && <span className='db-cad-top-units'>({data.ovarAllSpendSymbol})</span>}
                                                        </h5>
                                                        <div class="progress custom-progress">
                                                            <div class="progress-bar" role="progressbar" style={{
                                                                width: (data.overallSpent > 0 && data.overallBudget > 0 ? (
                                                                    Math.round(
                                                                        (
                                                                            (
                                                                                parseFloat(data.overallSpent.replace(/[^0-9.]/g, '')) / parseFloat(data.overallBudget.replace(/[^0-9.]/g, ''))
                                                                            ) * 100
                                                                        )
                                                                    )
                                                                ) + '%' : 0 + '%')
                                                            }} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                                                            <span className='db-custom-span'>{data.overallSpent > 0 && data.overallBudget > 0 ? (((parseFloat(data.overallSpent.replace(/[^0-9.]/g, '')) / parseFloat(data.overallBudget.replace(/[^0-9.]/g, ''))) * 100).toFixed(2)) + '%' : 0 + '%'}</span>
                                                        </div>
                                                        {/* <div className="progress custom-progress">
                                                            <div className="progress-bar" role="progressbar" aria-valuenow="65"
                                                                aria-valuemin="0" aria-valuemax="100" style={{ width: (data.overallSpent > 0 && data.overallBudget > 0 ? (((parseFloat(data.overallSpent.replace(/[^0-9.]/g, '')) / parseFloat(data.overallBudget.replace(/[^0-9.]/g, ''))) * 100).toFixed(2)) : 0) }}>
                                                            </div>
                                                            <span className='db-custom-span'>{data.overallSpent > 0 && data.overallBudget > 0 ? (((parseFloat(data.overallSpent.replace(/[^0-9.]/g, '')) / parseFloat(data.overallBudget.replace(/[^0-9.]/g, ''))) * 100).toFixed(2)) + '%' : 0 + '%'}</span>
                                                        </div> */}
                                                    </div>
                                                    <h6 class="card-subtitle mb-3">Budget Spent</h6>
                                                </div>
                                                <div className="db-card-width">
                                                    <div className='db-cards-top'>
                                                        <h5 class="card-title">{'\u20B9'}{data.overallBudget} {data.ovarAllBudgetSymbol && <span className='db-cad-top-units'>({data.ovarAllBudgetSymbol})</span>}
                                                        </h5>
                                                        <span className='db-right-btn'>{formattedDates[0] === formattedDates[1] ? formattedDates[0] : formattedDates[0] + "-" + formattedDates[1]}</span>
                                                    </div>
                                                    <h6 class="card-subtitle mb-3">Overall Budget</h6>
                                                </div>
                                            </div>
                                            <div className="row db-budget-allocation">
                                                <div className="d-md-flex px-2 gap-cards pb-2">
                                                    <div class="card bg-transparent border-0 shadow-none cards-width px-0 justify-content-center text-center w-100 pb-2 mb-0 pt-0">
                                                        {/* <div className='db-head'> */}
                                                        <div className={window.sessionStorage.getItem("roleId") === "11" ? "db-head db-head-11" : "db-head"}>
                                                            Budget Allocation :
                                                        </div>
                                                    </div>
                                                    {budgetList[0].budgetAllocation.map((item, index) => (
                                                        <div class="card bg-white shadow-none me-2 cards-width" style={{ width: index === 4 ? "100%" : "" }}>
                                                            <div className='db-cards-top an-selectBy' onClick={() => handleChildClick1(item)}>
                                                                <h5 class="db-card-title">{item.name}</h5>
                                                                <span className='db-right-number'>
                                                                <b>&#8377;</b>{item.budgetAmount}
                                                                    {item.budgetSymbol &&
                                                                        <span className='db-percentage'>({item.budgetSymbol})</span>}
                                                                </span>
                                                            </div>
                                                            <div className={`an-select-drop ${activeChild1 === item ? 'an-select-toggle' : ''}`}
                                                            >
                                                                <div className='db-cards-top card-drop-bottom' >
                                                                    <h5 class="db-card-title">Allocated</h5>
                                                                    <span className='db-right-number'>
                                                                        {item.budgetAmount}
                                                                        {item.budgetSymbol &&
                                                                            <span className='db-percentage'>({item.budgetSymbol})</span>}
                                                                    </span>
                                                                </div>
                                                                <div className='db-cards-top card-drop-bottom' >
                                                                    <h5 class="db-card-title">Spent</h5>
                                                                    <span className='db-right-number'>
                                                                        {item.spentAmount}
                                                                        {item.spentSymbol &&
                                                                            <span className='db-percentage'>({item.spentSymbol})</span>}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                        :
                        <GbpSearches formattedDates={formattedDates} />
                    }
                </div>
                <div className={window.sessionStorage.getItem("roleId") === "12" ? "d-lg-flex google-Paid-Media" : "d-lg-flex"}>
                    {window.sessionStorage.getItem("roleId") === "12" &&
                        <>
                            <GooglePaidMedia formattedDates={formattedDates} />
                            <MetaPaidMedia formattedDates={formattedDates} />
                        </>}
                    {(graphArrayOne.length > 0 && window.sessionStorage.getItem("roleId") !== "12") && (
                        // <Zones graphArrayOne={graphArrayOne}/>
                        <>
                            {/* {graphArrayOne[0].Array1.length > 0 && ( */}
                            <div className="db-cards-40">
                                <div class="card db-height active_div" >
                                    <div class="card-body">
                                        <div className='db-cards-top mb-2'>
                                            <h5 class="card-title card-title-small">{graphArrayOne[0].masterName} Sales Distribution
                                                {/* <span className='db-cad-top-units-blue'>{formattedDates[0] === formattedDates[1] ? formattedDates[0] : formattedDates[0] + "-" + formattedDates[1]}</span> */}
                                            </h5>
                                            <div class="btn-group">
                                                <span className={`btn btn-link ${activeButton === '' ? 'active' : ''}`} aria-current="page" onClick={() => zonalGraphData("")}>All</span>
                                                <span className={`btn btn-link ${activeButton === '1' ? 'active' : ''}`} onClick={() => zonalGraphData("1")}>Pv</span>
                                                <span className={`btn btn-link ${activeButton === '2' ? 'active' : ''}`} onClick={() => zonalGraphData("2")}>Cv</span>
                                                <span className={`btn btn-link ${activeButton === '3' ? 'active' : ''}`} onClick={() => zonalGraphData("3")}>SCv</span>
                                            </div>
                                        </div>
                                        <h6 class="card-subtitle mb-4 mt-1 d-flex align-items-center flex-wrap">{graphArrayOne[0].masterName} <span className='db-cad-top-units-blue ml-auto'>{formattedDates[0] === formattedDates[1] ? formattedDates[0] : formattedDates[0] + "-" + formattedDates[1]}</span></h6>
                                        {graphArrayOne[0].Array1.length > 0 ? (graphArrayOne[0].Array1.slice(0, 4).map((item) => (
                                            <div className='d-flex mb-4' style={{ cursor: 'pointer' }} onClick={() => graphDataClick(item.id, item.masterName, item.name)}>
                                                <div style={{ width: (item.value) + "%" }} className={`progress-focus ${activeChild === item.id ? 'active' : ''}`} onClick={() => handleChildClick(item.id)}>
                                                    <div class="progress-bar" role="progressbar" style={{ width: "100%", background: `rgba(84, 112, 233, ${(item.value / 100) < .1 ? .1 : (item.value / 100)})` }} aria-valuenow={item.value} aria-valuemin="0" aria-valuemax="100"><span className='db-line-span' style={{ visibility: "hidden" }}>{item.value}%</span></div>
                                                </div>
                                                <div className='db-lines'>
                                                    <div className='db-top-lines' onClick={() => handleChildClick(item.id)}>({item.value + "%"}) {item.name}</div>
                                                </div>
                                            </div>))) : <div className='text-center no-data-found' style={{ fontFamily: 'Gilroy-Medium' }}>No Data Found</div>}
                                    </div>
                                </div>
                            </div>
                            {/* )} */}
                        </>
                    )}
                    {(graphArrayTwo.length > 0 && window.sessionStorage.getItem("roleId") !== "11" && window.sessionStorage.getItem("roleId") !== "12") && (
                        <>
                            {/* {graphArrayTwo[0].Array2.length > 0 && ( */}
                            <div className="db-cards-40 ">
                                <div class="card db-height active_div">
                                    <div class="card-body">
                                        <div className='db-cards-top mb-2'>
                                            <h5 class="card-title card-title-small">{stateHeading} Sales Distribution
                                                {/* <span className='db-cad-top-units-blue'>{formattedDates[0] === formattedDates[1] ? formattedDates[0] : formattedDates[0] + "-" + formattedDates[1]}</span> */}
                                            </h5>
                                            <div class="btn-group">
                                                <span className={`btn btn-link ${activeButtonState === '' ? 'active' : ''}`} aria-current="page" onClick={() => stateGraphData("")}>All</span>
                                                <span className={`btn btn-link ${activeButtonState === '1' ? 'active' : ''}`} onClick={() => stateGraphData("1")}>Pv</span>
                                                <span className={`btn btn-link ${activeButtonState === '2' ? 'active' : ''}`} onClick={() => stateGraphData("2")}>Cv</span>
                                                <span className={`btn btn-link ${activeButtonState === '3' ? 'active' : ''}`} onClick={() => stateGraphData("3")}>SCv</span>
                                            </div>
                                        </div>
                                        {/* <h6 class="card-subtitle mb-4">{stateHeading} </h6> */}
                                        <h6 class="card-subtitle mb-4 mt-1 d-flex align-items-center flex-wrap">{stateHeading} <span className='db-cad-top-units-blue ml-auto'>{formattedDates[0] === formattedDates[1] ? formattedDates[0] : formattedDates[0] + "-" + formattedDates[1]}</span></h6>
                                        {graphArrayTwo[0].Array2.length > 0 ? (graphArrayTwo[0].Array2.slice(0, 4).map((item) => (
                                            <div className='d-flex mb-4'  >
                                                <div style={{ width: (item.value) + "%" }} className={`progress-focus ${activeChild2 === 'one' ? 'active' : ''}`} onClick={() => handleChildClick2('one')}>
                                                    <div class="progress-bar" role="progressbar" style={{ width: "100%", background: `rgba(84, 112, 233, ${(item.value / 100) < .1 ? .1 : (item.value / 100)})` }} aria-valuenow={item.value} aria-valuemin="0" aria-valuemax="100"><span className='db-line-span' style={{ visibility: "hidden" }}>{item.value}%</span></div>
                                                </div>
                                                <div className='db-lines'>
                                                    <div className='db-top-lines'>({item.value + "%"}) {item.name}</div>
                                                </div>
                                            </div>))) : <div className='text-center no-data-found' style={{ fontFamily: 'Gilroy-Medium' }}>No Data Found</div>}
                                    </div>
                                </div>
                            </div>
                            {/* )} */}
                        </>)}
                    {(graphArrayThree.length > 0 && productValueResult === false && window.sessionStorage.getItem("roleId") !== "11" && window.sessionStorage.getItem("roleId") !== "12") && (
                        <>
                            {/* {graphArrayThree[0].Array3.length > 0 && ( */}
                            <div className="db-cards-40">
                                <div class="card db-height">
                                    <div class="card-body">
                                        <div className='db-cards-top mb-2'>
                                            <h5 class="card-title card-title-small">{stateZoneHeading} Sales Distribution
                                                {/* <span className='db-cad-top-units-blue'>{formattedDates[0] === formattedDates[1] ? formattedDates[0] : formattedDates[0] + "-" + formattedDates[1]}</span> */}
                                            </h5>
                                        </div>
                                        {/* <h6 class="card-subtitle mb-4">{stateZoneHeading}</h6> */}
                                        <h6 class="card-subtitle mb-4 mt-1 d-flex align-items-center flex-wrap">{stateZoneHeading} <span className='db-cad-top-units-blue ml-auto'>{formattedDates[0] === formattedDates[1] ? formattedDates[0] : formattedDates[0] + "-" + formattedDates[1]}</span></h6>
                                        {graphArrayThree[0].Array3.slice(0, 4).map((item) => (
                                            <div className='d-flex mb-4'>
                                                <div style={{ width: (item.value) + "%" }} className={`progress-focus ${activeChild === 'one' ? 'active' : ''}`} onClick={() => handleChildClick('one')}>
                                                    <div class="progress-bar" role="progressbar" style={{ width: "100%", background: `rgba(84, 112, 233, ${item.value / 100})` }} aria-valuenow={item.value} aria-valuemin="0" aria-valuemax="100"><span className='db-line-span'>{item.value}%</span></div>
                                                </div>
                                                <div className='db-lines'>
                                                    <div className='db-top-lines'>{item.name}</div>
                                                </div>
                                            </div>))}
                                    </div>
                                </div>
                            </div>
                            {/* )} */}
                        </>
                    )}
                    {/* {topPerformingList.length > 0 && */}
                    <div className={window.sessionStorage.getItem("roleId") === "11" ? 'db-cards-30 db-card-margin-right' : 'db-cards-20'}>
                        <div class="card db-height active_div" >
                            <div class="card-body">
                                <div className='db-cards-top  all-region-head'>
                                    <h5 class="card-title card-title-small mb-0">Top Performing Models
                                    </h5>
                                    {/* <span className='db-cad-top-units-blue'>{formattedDates[0] === formattedDates[1] ? formattedDates[0] : formattedDates[0] + "-" + formattedDates[1]}</span> */}
                                </div>
                                {/* <h6 class="card-subtitle mb-4">All Regions</h6> */}
                                <h6 class="card-subtitle mb-4 mt-1 d-flex align-items-center flex-wrap">{stateZoneHeading} <span className='db-cad-top-units-blue ml-auto'>{formattedDates[0] === formattedDates[1] ? formattedDates[0] : formattedDates[0] + "-" + formattedDates[1]}</span></h6>
                                {topPerformingList.map((data) => (
                                    <div className="d-flex all-region-div">
                                        <div className='db-sub-models-width db-names'>
                                            {data.productName}
                                        </div>
                                        <div className='db-sub-models-width text-right'>
                                            {data.saleCount.toLocaleString('en-IN')}
                                        </div>
                                    </div>))}
                            </div>
                        </div>
                    </div>
                    {window.sessionStorage.getItem("roleId") === "11" &&
                        <>
                            {/* <TopPerformnce/> */}
                            {topPerformingCREList.length > 0 &&
                                <TopPerformnceCRE formattedDates={formattedDates} topPerformingCREList={topPerformingCREList} stateZoneHeading={stateZoneHeading} />}
                        </>}
                    {/* } */}
                </div>

                <div className="d-md-flex db-card-wrap mt-0">
                    {(window.sessionStorage.getItem("roleId") !== "11" && window.sessionStorage.getItem("roleId") !== "12") ?
                        <>
                            <Graph />
                            <Table formattedDates={formattedDates} />
                        </>
                        :
                        (window.sessionStorage.getItem("roleId") === "12") ?
                            <>
                                <Graph />
                                <SocialMediaEng />
                            </>
                            :
                            <>
                                <GbpSearches formattedDates={formattedDates} />
                                <SocialMediaEng />
                            </>}
                </div>

            </div>
        </div>
    )
}

export default Dashboardmain;