import React, { useEffect, useState } from 'react'
import Img from "../images/arrow-up.png"
import Sales from "./db-sales-card"
import Zones from "./db-zones-card"
import Graph from "./db-graph"
import Table from "./db-table"
import { apiCallingPost } from "../apiService";
import LeadsGenerated from './leads-generated'
import TopPerformnce from './top-performance'
import TopPerformnceCRE from './top-performance-cre'
import GbpSearches from './gbp-searches'
import SocialMediaEng from './social-meida-engagement'
import GooglePaidMedia from './google-paid-media'

function DashboardmainFive({formattedDates}) {

    const Select = () => {
        document.querySelectorAll('.an-select-drop').forEach(function (div) {
            div.classList.toggle('an-select-toggle');

        });
    };
    const [budgetList, setBudgetList] = useState([]);
    const [graphArrayOne, setGraphArrayOne] = useState([]);
    const [graphArrayTwo, setGraphArrayTwo] = useState([]);
    const [graphArrayThree, setGraphArrayThree] = useState([]);
    const [leadsList, setLeadsList] = useState([]);
    const [topPerformingList, setTopPerformingList] = useState([]);
    const [productValueResult, setProductValueResult] = useState("");
    const [activeButton, setActiveButton] = useState('');
    const [activeButtonState, setActiveButtonState] = useState('');
    const [stateHeading, setStateHeading] = useState('');
    const [stateZoneHeading, setStateZoneHeading] = useState('');

    useEffect(() => {
        getProductTypeValue()
        budgetListing()
        leadsListing()
        graphData()
    }, [formattedDates]);
    const getProductTypeValue = () => {
        const postData = {
            "functionName": "getOrganizationSettings",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
        }
        apiCallingPost(postData).then((data) => {
            console.log("🚀setProductValueResult ~ apiCallingPost ~ data:", data.result.isHighValue)
            if (data.success === "1") {
                setProductValueResult(data.result.isHighValue)
                if (data.result.isHighValue === true) {
                    topPerforming()
                }
            } else {
            }
        });
    }
    const budgetListing = () => {
        const postData = {
            "functionName": "budgetAllocationDashboard",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "fromDate": formattedDates[0],
            "toDate":formattedDates[1],
            "userId": window.sessionStorage.getItem("userId"),
        }
        apiCallingPost(postData).then((data) => {
            console.log("dataaaaaaaaa", data)
            if (data.success === "1") {
                setBudgetList(data.accountBudgetMasterDetails)
            } else {
                setBudgetList([])
            }
        });
    };
    const leadsListing = () => {
        const postData = {
            "functionName": "getleadsDetailsByAccountId",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "userId": window.sessionStorage.getItem("userId"),
            "startDate":formattedDates[0],
            "endDate":formattedDates[1],
        };
        apiCallingPost(postData).then((data) => {
            console.log("leads data", data)
            if (data.success === "1") {
                setLeadsList(data.result)
            } else {
                setLeadsList([])
            }
        });
    };
    const zonalGraphData = (typeId) => {
        setActiveButton(typeId);
        setActiveButtonState("")
        const postData = {
            "functionName": "userDashboard",
            "userId": window.sessionStorage.getItem("userId"),
            "zoneId": "",
            "stateId": "",
            "stateZoneId": "",
            "districtId": "",
            "cityId": "",
            "localityId": "",
            "fromDate": formattedDates[0],
            "toDate":formattedDates[1],
            "typeId": typeId ? typeId : ""
        }
        apiCallingPost(postData).then((data) => {
            console.log("zonalgraph dataaaaaaaaaaaaa", data)
            if (data.success === "1") {
                setGraphArrayOne(data.array1)
            } else {

            }
        });
    };
    const stateGraphData = (typeId) => {
        setActiveButtonState(typeId)
        setActiveButton("");
        const postData = {
            "functionName": "userDashboard",
            "userId":window.sessionStorage.getItem("userId"),
            "zoneId": "",
            "stateId": "",
            "stateZoneId": "",
            "districtId": "",
            "cityId": "",
            "localityId": "",
            "fromDate": formattedDates[0],
            "toDate":formattedDates[1],
            "typeId": typeId ? typeId : ""
        }
        apiCallingPost(postData).then((data) => {
            console.log("graph dataaaaaaaaaaaaa", data)
            if (data.success === "1") {
                setGraphArrayTwo(data.array2)
            } else {

            }
        });
    };
    const graphData = (data, mode, type) => {
        setActiveButton("");
        setActiveButtonState("")
        setStateHeading(type)
        setStateZoneHeading(type)
        const postData = {
            "functionName": "userDashboard",
            "userId": window.sessionStorage.getItem("userId"),
            "zoneId": mode === "zoneId" ? data : "",
            "stateId": "",
            "stateZoneId": "",
            "districtId": "",
            "cityId": "",
            "localityId": "",
            "fromDate": formattedDates[0],
            "toDate":formattedDates[1],
            "typeId": "" 
        }
        apiCallingPost(postData).then((data) => {
            console.log("graph dataaaaaaaaaaaaa", data)
            if (data.success === "1") {
                setGraphArrayOne(data.array1)
                setGraphArrayTwo(data.array2)
                setStateHeading(data.array2[0].masterName)
                setGraphArrayThree(data.array3)
                setStateZoneHeading(data.array3[0].masterName)
            } else {

            }
        });
    };
    const graphDataClick = (data, mode, type) => {
        setActiveButton("");
        setActiveButtonState("")
        setStateHeading(type)
        setStateZoneHeading(type)
        const postData = {
            "functionName": "userDashboard",
            "userId":window.sessionStorage.getItem("userId"),
            "zoneId": mode === "zoneId" ? data : "",
            "stateId": "",
            "stateZoneId": "",
            "districtId": "",
            "cityId": "",
            "localityId": "",
            "fromDate": formattedDates[0],
            "toDate":formattedDates[1],
            "typeId": "" 
        }
        apiCallingPost(postData).then((data) => {
            console.log("graph dataaaaaaaaaaaaa", data)
            if (data.success === "1") {
                setGraphArrayOne(data.array1)
                setGraphArrayTwo(data.array2)
                setGraphArrayThree(data.array3)
            } else {

            }
        });
    };
    const topPerforming = () => {
        const postData = {
            "functionName": "topPerformingProductList",
            "userId":window.sessionStorage.getItem("userId"),
            "startDate":formattedDates[0],
            "endDate":formattedDates[1],
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
        }
        apiCallingPost(postData).then((data) => {
            console.log("top performing", data)
            if (data.success === "1") {
                setTopPerformingList(data.result)
            } else {

            }
        });
    };
    const [activeChild, setActiveChild] = useState(null);
    const handleChildClick = (childId) => {
        setActiveChild(activeChild === childId ? null : childId);
    };

    const [activeChild1, setActiveChild1] = useState(null);
    const handleChildClick1 = (childId) => {
        setActiveChild1(activeChild1 === childId ? null : childId);
    };
    return (
        <div className='db-main pt-3'>
            <div className='container-fluid'>
                <div className="d-lg-flex">
                    <Sales formattedDates={formattedDates}/>
                    <LeadsGenerated/>
                        <div className="db-cards-right">
                            <GbpSearches/>
                        </div>
                </div>
                <div className="d-lg-flex mb-1 gbp_google_search">
                            <GooglePaidMedia/>
                            <GooglePaidMedia/>
                        <TopPerformnceCRE/>
                       
                </div>
                <div className="d-md-flex db-card-wrap mt-4">
                        <Graph/>
                <SocialMediaEng/>
                </div>
            </div>
        </div>
    )
}

export default DashboardmainFive;