import React, { Component, Suspense } from "react";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import LeftMenu from "../../layout/leftMenu";
import $ from "jquery";
let folderName = window.sessionStorage.getItem("local_page_Folder_Name");
let LandingPage = React.lazy(() =>
import("../Project_Folder/../"+folderName+"/landing_page")
);

class Index extends Component {
  componentDidMount() {
    $("#headerName").text("Local Pages");
  }
  render() {
    return (
      <div className="col-md-12">
          <LeftMenu></LeftMenu>
        
      <Header />
        {/* <SubHeaderMenu></SubHeaderMenu> */}
          {/* <Header heading="Local Pages" headerImage={LocalPagesimage} /> */}
           
            <Suspense fallback={<div></div>}>
              <section>
                <LandingPage />
              </section>
            </Suspense>
          {/* <Footermenu></Footermenu> */}
        <Footer />
      </div>
    );
  }
}

export default Index;
