import React, { useEffect, useState } from "react";
import ContentPlanning from "./components/contentPlanning";
import Lock from "../images/left_icons/lock2.png";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Footer from "../layout/footer";
import { backendApi } from "../apiService";
import { GoPencil } from "react-icons/go";
import moment from "moment";
import ApproverPopup from "../adv_generator/approverPopup";
export default function PlanningMain() {
  const [editingIndex, setEditingIndex] = useState(-1);
  const [profilePic, setProfilePic] = useState("");
  const [remark, setRemark] = useState("");
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [content_approver_group_id, setContent_approver_group_Data] = useState(
    []
  );
  const [requestedStatus, setRequestedStatus] = useState({
    approved: [],
    rejected: [],
  });
  const [waitingCount, setWaitingCount] = useState("");
  const [approvedCount, setApprovedCount] = useState("");
  const [rejectedCount, setRejectedCount] = useState("");

  useEffect(() => {
    getRequestedData();
    contentInit();
    content_approver_group_data();
  }, []);

  const closePopup = () => {
    getRequestedData();
    setProfilePic("");
  };

  const contentInit = () => {
    const apiReq = {
      functionName: "content_init",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apiReq).then((data) => {
      if (data.success == "1") {
        const transformedOptions = data.campaign_name.map(
          (campaign, index) => ({
            value: campaign.id,
            label: campaign.campaign_name,
          })
        );
        setCampaignOptions(transformedOptions);
      } else {
        console.log("Error in content init api");
      }
    });
  };

  const content_approver_group_data = () => {
    const postData = {
      functionName: "content_approver_group_data",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(postData).then((response) => {
      console.log(response, "responseresponseresponsekkkkkkkkkkkkkkkkkkkkkkk");
      if (response?.success === "1") {
        setContent_approver_group_Data(response.dataSet);
      } else {
        console.log("Error");
      }
    });
  };

  const deleteRequest = (data) => {
    const postData = {
      functionName: "content_request_delete",
      content_generation_request_id: data.id,
      sec_user_id:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };
    backendApi(postData).then((response) => {
      if (response?.success === "1") {
        getRequestedData();
      } else {
        console.log("Error");
      }
    });
  };

  const resendForApproval = (data) => {
    setProfilePic(data.content_image);
  };

  const handleRemarkNameChange = (event) => {
    setRemark(event.target.value);
  };

  const handleEditClick = (index, remarks) => {
    setEditingIndex(index);
    setRemark(remarks);
  };
  const handleCancelClick = () => {
    setEditingIndex(-1);
  };

  const handleUpdateClick = (i, data) => {
    if (!remark) {
      alert("Please Fill Remarks First");
      return;
    }
    let adminRemarks = remark.replace(/['"]/g, "\\$&");
    const postData = {
      functionName: "content_request_update_remarks",
      content_generation_request_id: data.id,
      remarks: adminRemarks,
      remarks_updated_by:
        window.sessionStorage.getItem("switchUserId") === ""
          ? window.sessionStorage.getItem("userId")
          : window.sessionStorage.getItem("switchUserId"),
    };
    backendApi(postData).then((response) => {
      if (response?.success === "1") {
        setEditingIndex(-1);
        getRequestedData();
      } else {
        setEditingIndex(-1);
        console.log("Error");
      }
    });
  };

  const getRequestedData = () => {
    const postData = {
      functionName: "content_request_Listing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),

      category_type: "Planning",
      req_status: "",
    };

    backendApi(postData)
      .then((data) => {
        if (data && data.success === "1") {
          const newWaiting = [];
          const newApproved = [];
          const newRejected = [];

          // Loop through data and populate each status array
          data?.dataList?.forEach((item) => {
            if (item.req_status === "Waiting") {
              newWaiting.push(item);
            } else if (item.req_status === "Approved") {
              newApproved.push(item);
            } else if (item.req_status === "Rejected") {
              newRejected.push(item);
            }
          });
          setWaitingCount(newWaiting.length);
          setApprovedCount(newApproved.length);
          setRejectedCount(newRejected.length);

          // Update state with new arrays
          setRequestedStatus({
            waiting: newWaiting,
            approved: newApproved,
            rejected: newRejected,
          });
        } else {
          console.error("Error in API response:", data);
        }
      })
      .catch((error) => {
        // Handle errors that occur during API call
        console.error("Error occurred during API call:", error);
      });
  };

  const handleGetRequestedData = () => {
    getRequestedData();
  };

  return (
    <div className="main_wrapper">
      <LeftMenu />
      <div className="userListWrapper">
        <Header heading="Planning" headerImage={Lock} />
        <div className="col-md-12 tabs-main-wrapper">
          <Tabs>
            <TabList onClick={(e) => setEditingIndex(-1)}>
              <Tab>Planning</Tab>
              <Tab>
                Requested
                <span className="waitingTxt">{waitingCount}</span>
              </Tab>
              <Tab>
                Approved
                <span className="waitingTxt">{approvedCount}</span>
              </Tab>
              <Tab>
                Rejected
                <span className="rejectedTxt">{rejectedCount}</span>
              </Tab>
            </TabList>

            <TabPanel>
              <ContentPlanning onGetRequestedData={handleGetRequestedData} />
            </TabPanel>

            <TabPanel>
              <div className="col-md-12 tableResponsive tagImageEditrListTble mt-4">
                <div className="tableOuterWidth table_width_md">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-3">Image </th>
                        <th className="col-3">Remarks</th>
                        <th className="col-2">Requested</th>
                        <th className="col-2">Approved</th>
                        <th className="col-2">Action</th>
                      </tr>
                    </thead>

                    {requestedStatus?.waiting?.map((data, i) => (
                      <tbody className="table_Outertbody pb-3">
                        <tr>
                          <td className="col-3">
                            <div className="popup-image">
                              <div className="popupGallerysection">
                                <a
                                  href={data.content_image}
                                  title="img"
                                  target="_blank"
                                >
                                  <img
                                    src={data.content_image}
                                    className="img-fluid"
                                  />
                                </a>
                              </div>
                            </div>
                          </td>
                          <td className="col-3">
                            {editingIndex === i ? (
                              <textarea
                                type="text"
                                value={remark}
                                onChange={handleRemarkNameChange}
                                className="w-100 form-control"
                              />
                            ) : (
                              <span>{data.remarks}</span>
                            )}
                            {editingIndex !== i ? (
                              <GoPencil
                                className="ml-2"
                                onClick={() => handleEditClick(i, data.remarks)}
                              />
                            ) : (
                              ""
                            )}
                            {editingIndex === i && (
                              <div className="mt-2">
                                <button
                                  onClick={() => handleUpdateClick(i, data)}
                                  className="btn btn-success"
                                >
                                  Save
                                </button>
                                <button
                                  onClick={handleCancelClick}
                                  className="btn btn-secondary ml-1"
                                >
                                  Cancel
                                </button>
                              </div>
                            )}
                          </td>
                          <td className="col-2">
                            <p>
                              {" "}
                              {moment(data.requested_date).format(
                                "DD MMM YYYY"
                              )}
                            </p>
                            <p>{data.requested_by_name}</p>
                          </td>
                          <td className="col-2">
                            {data.approved_by_name ? (
                              <>
                                <p>
                                  {moment(data.approved_date).format(
                                    "DD MMM YYYY"
                                  )}
                                </p>
                                <p>{data.approved_by_name}</p>
                              </>
                            ) : (
                              <p>Not approved</p>
                            )}
                          </td>
                          <td className="col-2">
                            {/* <button type="submit" className="btn btn-success mr-2">Send for Approval</button> */}
                            <button
                              type="submit"
                              className="btn btn-danger"
                              onClick={(e) => deleteRequest(data)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div className="col-md-12 tableResponsive tagImageEditrListTble mt-4">
                <div className="tableOuterWidth">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-3">Image </th>
                        <th className="col-3">Remarks</th>
                        <th className="col-2">Requested</th>
                        <th className="col-2">Approved</th>
                        <th className="col-2">Action</th>
                      </tr>
                    </thead>
                    <div className="table_Outerscroll">
                      {requestedStatus?.approved?.map((data, i) => (
                        <tbody className="table_Outertbody pb-3">
                          <tr>
                            <td className="col-3">
                              <div className="popup-image">
                                <div className="popupGallerysection">
                                  <a
                                    href={data.content_image}
                                    title="img"
                                    target="_blank"
                                  >
                                    <img
                                      src={data.content_image}
                                      className="img-fluid"
                                    />
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td className="col-3">
                              {editingIndex === i ? (
                                <textarea
                                  type="text"
                                  value={remark}
                                  onChange={handleRemarkNameChange}
                                  className="w-100 form-control"
                                />
                              ) : (
                                <span>{data.remarks}</span>
                              )}
                              {editingIndex !== i ? (
                                <GoPencil
                                  className="ml-2"
                                  onClick={() =>
                                    handleEditClick(i, data.remarks)
                                  }
                                />
                              ) : (
                                ""
                              )}
                              {editingIndex === i && (
                                <div className="mt-2">
                                  <button
                                    onClick={() => handleUpdateClick(i, data)}
                                    className="btn btn-success"
                                  >
                                    Save
                                  </button>
                                  <button
                                    onClick={handleCancelClick}
                                    className="btn btn-secondary ml-1"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              )}
                            </td>
                            <td className="col-2">
                              <p>
                                {" "}
                                {moment(data.requested_date).format(
                                  "DD MMM YYYY"
                                )}
                              </p>
                              <p>{data.requested_by_name}</p>
                            </td>
                            <td className="col-2">
                              <p>
                                {" "}
                                {moment(data.approved_date).format(
                                  "DD MMM YYYY"
                                )}
                              </p>
                              <p>{data.approved_by_name}</p>
                            </td>
                            <td className="col-2">
                              {/* <button type="submit" className="btn btn-success mr-2">Send for Approval</button> */}
                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={(e) => deleteRequest(data)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </div>
                  </table>
                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div className="col-md-12 tableResponsive tagImageEditrListTble mt-4">
                <div className="tableOuterWidth">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-3">Image </th>
                        <th className="col-3">Remarks</th>
                        <th className="col-2">Requested</th>
                        <th className="col-2">Rejected</th>
                        <th className="col-2">Action</th>
                      </tr>
                    </thead>
                    <div className="table_Outerscroll">
                      {requestedStatus?.rejected?.map((data, i) => (
                        <tbody className="table_Outertbody pb-3">
                          <tr>
                            <td className="col-3">
                              <div className="popup-image">
                                <div className="popupGallerysection">
                                  <a
                                    href={data.content_image}
                                    title="img"
                                    target="_blank"
                                  >
                                    <img
                                      src={data.content_image}
                                      className="img-fluid"
                                    />
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td className="col-3">
                              {editingIndex === i ? (
                                <textarea
                                  type="text"
                                  value={remark}
                                  onChange={handleRemarkNameChange}
                                  className="w-100 form-control"
                                />
                              ) : (
                                <span>{data.remarks}</span>
                              )}
                              {editingIndex !== i ? (
                                <GoPencil
                                  className="ml-2"
                                  onClick={() =>
                                    handleEditClick(i, data.remarks)
                                  }
                                />
                              ) : (
                                ""
                              )}
                              {editingIndex === i && (
                                <div className="mt-2">
                                  <button
                                    onClick={() => handleUpdateClick(i, data)}
                                    className="btn btn-success"
                                  >
                                    Save
                                  </button>
                                  <button
                                    onClick={handleCancelClick}
                                    className="btn btn-secondary ml-1"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              )}
                            </td>
                            <td className="col-2">
                              <p>
                                {" "}
                                {moment(data.requested_date).format(
                                  "DD MMM YYYY"
                                )}
                              </p>
                              <p>{data.requested_by_name}</p>
                            </td>
                            <td className="col-2">
                              <p>
                                {" "}
                                {moment(data.approved_date).format(
                                  "DD MMM YYYY"
                                )}
                              </p>
                              <p>{data.approved_by_name}</p>
                            </td>
                            <td className="col-2">
                              <button
                                type="submit"
                                className="btn btn-success mr-1"
                                onClick={() => resendForApproval(data)}
                              >
                                Resend
                              </button>
                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={(e) => deleteRequest(data)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </div>
                  </table>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>

        {profilePic && (
          <ApproverPopup
            onClose={closePopup}
            groupData={content_approver_group_id}
            textContent={profilePic}
            typeOfContent={"Image Content"}
            campaignOptions={campaignOptions}
          />
        )}
        <div className="loder_div" hidden />
        <Footer />
      </div>
    </div>
  );
}
