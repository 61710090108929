import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
export default function GoogleCampaigns() {
  return (<div>
    <div className="card">
      <div className="col-md-12 tableResponsive">
        <div className="tableOuterWidth">
          <table className="table">
            <thead>
              <tr>
                <th className="col-2">ID</th>
                <th className="col-2">Account</th>
                <th className="col-1">Created</th>
                <th className="col-1">Budget</th>
                <th className="col-2">Name</th>
                <th className="col-1">objective</th>
              </tr>
            </thead>
            <div className="table_Outerscroll">
                <tbody className="table_Outertbody">
                  <tr>
                    <td className="col-2">id</td>
                    <td className="col-2">
                    Account
                    </td>
                    <td className="col-1">
                    created_time
                    </td>
                    <td className="col-1">
                    budget_remaining
                    </td>
                    <td className="col-2">
                    name
                    </td>
                    <td className="col-1">
                    objective
                    </td>

                  </tr>
                </tbody>

            </div>
          </table>

        </div>
        {/* <div className="pagination_main  tag_postListNext">
              <Pagination
                hideDisabled
                activePage={currentPage}
                itemsCountPerPage="25"
                totalItemsCount={totalCount}
                pageRangeDisplayed="10"
                onChange={(e) => handleActivePage(e)}
              />
            </div> */}
      </div>

    </div>
  </div>)
}