import React, { useEffect, useState } from "react";
import Footer from "../layout/footer";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import $ from "jquery";
import moment from "moment";
import { Link } from "react-router-dom";
import PostImg from "../images/bg_01.jpg";
import * as Icon from "react-feather";
import GooglePostimage from "../images/left_icons/Google-Post.png";
import { backendApi } from "../apiService";
var XLSX = require("xlsx");

function PostHistory() {
  const [response, setResponse] = useState("Waiting for data...");
  const [postList, setPostList] = useState([]);
  const [postListBackup, setPostListBackup] = useState([]);
  const [failedStatus, setFailedStatus] = useState(false);
  const [deletedStatus, setDeletedStatus] = useState(false);
  const [postedStatus, setPostedStatus] = useState(false);
  const [clearStatus, setClearStatus] = useState(false);
  const [showClear, setShowClear] = useState(false);
  const[posted,setPosted]=useState("");
  const[deleted,setDeleted]=useState("");
  const[failed,setFailed]=useState("");

  useEffect(() => {
    getPostReport();
  }, []);

  useEffect(() => {
    if (postedStatus == true) {
      setFailedStatus(false);
      setDeletedStatus(false);
      setShowClear(true);
      setClearStatus(false);
      let newArray = postListBackup;
      newArray = newArray.filter((obj) =>
        obj.status.toLowerCase().includes("posted")
      );
      setPostList(newArray);
    }
  }, [postedStatus]);
  useEffect(() => {
    if (deletedStatus == true) {
      setFailedStatus(false);
      setPostedStatus(false);
      setClearStatus(false);
      setShowClear(true);
      let newArray = postListBackup;
      newArray = newArray.filter((obj) =>
        obj.status.toLowerCase().includes("deleted")
      );
      console.log("newArraynewArray", newArray);
      setPostList(newArray);
    }
  }, [deletedStatus]);
  useEffect(() => {
    if (failedStatus == true) {
      setPostedStatus(false);
      setClearStatus(false);
      setDeletedStatus(false);
      setShowClear(true);
      let newArray = postListBackup;
      newArray = newArray.filter((obj) =>
        obj.status.toLowerCase().includes("failed")
      );
      setPostList(newArray);
    }
  }, [failedStatus]);
  useEffect(() => {
    if (clearStatus == true) {
      setPostedStatus(false);
      setDeletedStatus(false);
      setFailedStatus(false);
      setShowClear(false);
      setPostList(postListBackup);
    }
  }, [clearStatus]);
  const getPostReport = () => {
    const apiReq = {
      functionName: "postHistoryListing",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
    };
    backendApi(apiReq).then((data) => {
      if (data.success == 1 && data.data.length > 0) {
        const statusCounts = {};
        for (const item of data.dataCnt) {
          statusCounts[item.status] = item.cnt;
        }
      
        // Access the count for each status using the statusCounts object
        const deletedCount = statusCounts["Deleted"] || 0;

        const failedCount = statusCounts["Failed"] || 0;

        const postedCount = statusCounts["Posted"] || 0;

        setPosted(postedCount)
        setDeleted(deletedCount)
        setFailed(failedCount)

        setPostList(data.data);
        setPostListBackup(data.data);
        setResponse("");
      } else {
        setPostList([]);
        setResponse("No Post(s) available");
      }
    });
  };
  const exportPosts = () => {
    const data = postList.map((obj) => ({
      "Business Name": obj.locationTitle,
      "Business Address": `${obj.addressLines}, ${obj.locality}, ${obj.administrativeArea}`,
      "Post Type": obj.eventType,
      "Post Status": obj.status,
      "Post Date/ Update Date": `Posted On: ${moment(obj.posted_on).format(
        "MMM DD, YYYY hh:mm a"
      )}`,
      "Post Media": obj.bannerImages != "" ? obj.bannerImages : "N/A",
      "Call To Action":
        obj?.actionType != ""
          ? `${obj.actionType.replace("_", " ")}${
              obj.action_url != "" && `-${obj.action_url}`
            }`
          : "None",
    }));
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Add a custom number format for the rating column
    worksheet["!cols"] = [
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 50 },
      { width: 50 },
      { width: 50 },
    ];
    // worksheet['!numfmt'] = [{}, {}, { numFmt: '[>=1][<2]★;General;General;@' }];
    worksheet["!cols"].splice(3, 0, { width: 20 }); // Add a column with width 20 at index 3 (after the 'Rating' column)

    // Add the worksheet to a new workbook and save it
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Post History");
    XLSX.writeFile(workbook, "GooglePostsReport.xlsx");
  };
  const capitalizeFirstWord = (str) => {
    str = str[0].toUpperCase() + str.slice(1);
    return str;
  };
  // const
  return (
    <div className="main_wrapper">
      <LeftMenu></LeftMenu>
      <div className="userListWrapper">
        <Header heading=" Google Post" headerImage={GooglePostimage} />
        <div className="container-fluid">
          <div className="subhead">Post(s) Report</div>
          <div className="user_listdiv_sub">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-8">
                <button className="btn btn-info" onClick={() => exportPosts()}>
                  Download Full Google Post Report
                </button>
              </div>
              <div className="col-xl-5">
                <div className="d-flex">
                  <div className="classify_databox">
                    {" "}
                    <div className="classify_sub color_prim">
                      <span
                        className="classify_subtxt"
                        onClick={() => setPostedStatus(true)}
                      >
                        <Icon.CheckSquare />
                        Posted :{" "}
                      </span>
                      <span className="classify_subtxt"> {posted}</span>
                    </div>
                    <div className="classify_sub color_stray">
                      <span
                        className="classify_subtxt"
                        onClick={() => setFailedStatus(true)}
                      >
                        <Icon.XCircle />
                        Failed :{" "}
                      </span>
                      <span className="classify_subtxt">{failed} </span>
                    </div>
                    <div className="classify_sub color_duplic">
                      <span
                        className="classify_subtxt"
                        onClick={() => setDeletedStatus(true)}
                      >
                        <Icon.Trash />
                        Deleted :{" "}
                      </span>
                      <span className="classify_subtxt">{deleted} </span>
                    </div>
                    {showClear == true && (
                      <div className="classify_sub">
                        <span
                          className="classify_subtxt"
                          onClick={() => setClearStatus(true)}
                        >
                          <Icon.File />
                          Clear
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 tableResponsive">
              <div className="tableOuterWidth">
                {postList.length > 0 ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-2">Post Id </th>
                        <th className="col-2">Post Type </th>
                        <th className="col-3">Business </th>
                        <th className="col-1">Status</th>
                        <th className="col-2">Post date</th>
                        <th className="col-2">Details</th>
                        {/* <th>Views/Clicks</th> */}
                      </tr>
                    </thead>
                    <div className="table_Outerscroll">
                      {postList.map((obj) => {
                        return (
                          <tbody className="table_Outertbody">
                            <tr>
                              <td className="col-2">{obj.post_id}</td>
                              <td className="col-2">
                                {obj.eventType == "STANDARD"
                                  ? "What's New"
                                  : capitalizeFirstWord(
                                      obj.eventType.toLowerCase()
                                    )}
                              </td>
                              <td className="col-3">
                                <span
                                  style={{
                                    textDecoration: "underline",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                  }}
                                >
                                  {obj.locationTitle}
                                </span>
                                <span style={{ display: "block" }}>
                                  {obj.addressLines},{obj.locality},
                                  {obj.administrativeArea}
                                </span>{" "}
                              </td>
                              <td className="col-1">{obj.status}</td>
                              <td className="col-2">
                                {moment(obj.posted_on).format(
                                  "DD MMM, YYYY hh:mm A"
                                )}
                              </td>
                              <td className="col-2">
                                {obj?.title && obj?.title !== "undefined"
                                  ? obj?.title
                                  : ""}
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </div>
                  </table>
                ) : (
                  response
                )}
              </div>
              <div
                className="module_popup editMatchAddress"
                id="editMatchAddress"
                hidden
              >
                <div className="module_innerpopup">
                  <div className="module_close">
                    <Icon.XCircle
                      color="red"
                      onClick={() => {
                        $("#editMatchAddress").attr("hidden", true);
                      }}
                    />
                  </div>
                  <div className="module_body">
                    <div className="form-group row">
                      <img
                        id="postImage"
                        src={PostImg}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="pagination_main  tag_postListNext">
              <Pagination
                hideDisabled
                activePage={currentPage}
                itemsCountPerPage="25"
                totalItemsCount={totalCount}
                pageRangeDisplayed="10"
                onChange={(e) => handleActivePage(e)}
              />
            </div> */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <div className="loder_div" hidden></div>
    </div>
  );
}

export default PostHistory;
