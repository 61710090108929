
import React, { useEffect,Suspense, lazy ,useState,useCallback} from 'react'
import Dashboardimage from "../images/left_icons/dashboard.png";
import LeftMenu from "../layout/leftMenu";
import Header from "../layout/header";
import Footer from "../layout/footer"
import DashboardmainFive from './dashboard-main05';
const DashboardHeader = lazy(() => import('./dashboard-header'));

function DashboardFive() {
  const [formattedDates, setFormattedDates] = useState([new Date().toLocaleDateString('en-US', { month: 'short', year: 'numeric' }),new Date().toLocaleDateString('en-US', { month: 'short', year: 'numeric' })]);
  
  const updateFormattedDates = useCallback((dates) => {
    console.log("newdatesss",dates)
    if(dates.length === 0){
      dates = [new Date().toLocaleDateString('en-US', { month: 'short', year: 'numeric' }),new Date().toLocaleDateString('en-US', { month: 'short', year: 'numeric' })]
    }
    if(dates.length === 1){
      dates.push(dates[0])
    }
    
    setFormattedDates(dates);
  })
    return (
      <div className="main_wrapper">
        <LeftMenu />
        <div className="userListWrapper">
          <Header heading="Dashboard" headerImage={Dashboardimage} />
          <div className='db-wrapper'>
         <Suspense fallback={<div>Loading...</div>}>
         <DashboardHeader updateFormattedDates={updateFormattedDates}/>
            <DashboardmainFive formattedDates={formattedDates}/>
        </Suspense>
        </div>
        <Footer/>
        </div>
      </div>
    );
  }

export default DashboardFive;
