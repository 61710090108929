import axios from "axios";
import React, { useEffect, useState } from "react";

function TwitterRecentSearch() {
  const [accessToken, setAccessToken] = useState("");
  const [nextPageToken, setNextPageToken] = useState("");
  const [prevPageToken, setPrevPageToken] = useState("");
  const [twitterSearchList, setTwitterSearchList] = useState([]);
  useEffect(() => {
    getTwitterToken();
  }, []);
  useEffect(() => {
    if (accessToken != "") getTwitterSearch(nextPageToken);
  }, [accessToken]);
  useEffect(() => {
    console.log("nextpage", nextPageToken);
    // console.log("prevp", prevPageToken);
  }, [nextPageToken]);
  const getTwitterToken = () => {
    const tokenUrl =
      "https://jxrlgrrlpa.execute-api.ap-south-1.amazonaws.com/default/twitter-token";
    axios.get(tokenUrl).then((response) => {
      setAccessToken(response.data.access_token);
    });
  };
  const getTwitterSearch = (token) => {
    const awsUrl =
      "https://e8uj5vhpfj.execute-api.ap-south-1.amazonaws.com/default/twitter-recent-search";
    var apiParams = {
      access_token: accessToken,
      query: "Kotak,Mahindra",
      next_token: token,
    };
    // setPrevPageToken(token);
    axios.post(awsUrl, apiParams).then((response) => {
      setTwitterSearchList(response.data.data);
      console.log("response.meta", response);
      setNextPageToken(response.data.meta.next_token);
    });
  };
  return (
    <div className="main_wrapper">
      <div className="userListWrapper">
        <div className="container-fluid">
          <button onClick={() => getTwitterSearch(nextPageToken)}>
            Next Page
          </button>
          {/* <button onClick={() => getTwitterSearch(prevPageToken)}>
            Prev Page
          </button> */}
          <div className="col-md-12 tableResponsive">
            <div className="tableOuterWidth">
              <table className="table">
                <thead>
                  <tr>
                    <th className="col-2">Id</th>
                    <th className="col-10">Text</th>
                  </tr>
                  {/* <tr>Text</tr> */}
                </thead>
                {twitterSearchList.map((data) => {
                  return (
                    <tbody className="table_Outertbody">
                      <tr>
                        <td className="col-2">{data.id}</td>
                        <td className="col-10">{data.text}</td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TwitterRecentSearch;
