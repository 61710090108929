import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import Select from "react-select";
import $ from "jquery";
import { backendApi } from "../../apiService";
export default function AddGroup(props) {
  const [groupName, setGroupName] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    if (props.editMode === true) {
      // setGroupName(props.groupName);
      setGroupName(props.editProps.group_name);
      const cities =
        props.editProps.locations !== ""
          ? props.editProps.locations.split(",")
          : [];
      console.log("🚀 ~ useEffect ~ cities:", cities);
      cities.length > 0 &&
        setSelectedCity(
          cities.map((data) => ({
            label: data,
            value: data,
          }))
        );

      console.log("🚀 ~ useEffect ~ props:", props);
    }
  }, [props.editMode]);

  const handleChangeState = async (value) => {
    if (!value) {
      setSelectedState("");
      setCityList([]);
      return;
    } else {
      setSelectedState(value);
    }
    const businessAccountData = {
      functionName: "getLocalityUnderAdministrativeArea",
      account_id: window.sessionStorage.getItem("account_id"),
      AdministrativeArea: value,
    };
    const citiesList = [];
    backendApi(businessAccountData).then(async (data) => {
      if (data.success === "1") {
        citiesList.push({ label: "Select All", value: "-1" });
        citiesList.push(
          ...data.data.map((obj) => ({
            label: obj.locality,
            value: obj.locality,
          }))
        );
        console.log("🚀 ~ backendApi ~ citiesList:", citiesList);

        setCityList(citiesList);
      } else {
        setCityList([]);
      }
    });
  };
  const handleChangeCity = async (value) => {
    console.log("🚀 ~ handleChangeCity ~ value:", value);
    if (!value) {
      return;
    }
    const selectAllIsSelected = value.some((o) => o.value === "-1");
    console.log(
      "🚀 ~ handleChangeCity ~ cityList:",
      cityList.filter((o) => o.value !== "-1")
    );
    if (selectAllIsSelected === true) {
      setSelectedCity((city) => [
        ...city,
        ...cityList.filter((o) => o.value !== "-1"),
      ]);
    } else {
      setSelectedCity(value);
    }
    // setSelectedCity(value);
  };

  const updateUser = () => {
    const api = {
      functionName: "content_approver_group_update",
      locations:
        selectedCity.length !== 0
          ? selectedCity.map((data) => data.label).join(",")
          : "",
      group_name: groupName,
      content_approver_group_id: props.editProps.id,
    };
    console.log("🚀 ~ updateUser ~ props.editProps:", api);
    backendApi(api).then((data) => {
      $("#assignGroupEdit").attr("hidden", true);
      alert("Group Updated successfully!!");
      props.contentInit();
      setSelectedCity([]);
      setSelectedState("");
      setGroupName("");
    });
  };
  const saveGroup = () => {
    if (groupName === "") {
      alert("Group name required");
      return false;
    }

    const api = {
      functionName: "content_approver_group_save",
      site_id:
        window.sessionStorage.getItem("switchingOrgId") === ""
          ? window.sessionStorage.getItem("organizationId")
          : window.sessionStorage.getItem("switchingOrgId"),
      locations:
        selectedCity.length !== 0
          ? selectedCity.map((data) => data.label).join(",")
          : "",
      group_name: groupName,
    };
    console.log("grpcreate",api);
    backendApi(api).then((data) => {
      if (data.success === "1") {
        alert("Group Saved Successfully");
        $("#assignGroup").attr("hidden", true);
        setSelectedCity([]);
        setSelectedState("");
        setGroupName("");
        props.contentInit();
      } else {
        alert("Something went wrong!!");
        console.error("error found", data);
      }
    });
  };
  const closeFirstPopup = (e) => {
    e.preventDefault();
    if (props.editMode === true) {
      console.log("hiii");
      $("#assignGroupEdit").attr("hidden", true);
      props.setEditMode(false);
      return;
    } else {
      console.log("byee");
      $("#assignGroup").attr("hidden", true);
      props.setEditMode(false);
      return;
    }
  };
  const insertNewLocation = (event) => {
    if (event.key == "Enter") {
      // console.log("🚀 ~ insertNewLocation ~ event:", event.target.value);
      // return false;
      const newOption = {
        label: event.target.value,
        value: event.target.value,
      };

      if (!cityList.some((option) => option.value === newOption.value)) {
        setCityList([...cityList, newOption]);
      }
    }
  };
  return (
    <>
      <div className="module_close">
        <Icon.XCircle onClick={(e) => closeFirstPopup(e)} />
      </div>
      <div className="ml-2 mb-2">{`${
        props.editMode === true ? "Edit" : "Add New"
      } Group`}</div>
      <div className="row row-search px-2 mt-3">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <label for="groupId">
            Group Name<sup style={{ color: "red" }}>*</sup>
          </label>
          <input
            className="form-control"
            id="groupId"
            placeholder="Group Name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6">
          <label for="stateId">Administrative Area</label>
          <select
            className="form-control"
            id="stateId"
            value={selectedState}
            onChange={(e) => handleChangeState(e.target.value)}
          >
            <option value="">State</option>
            {props.statesList.map((item) => {
              return (
                <option value={item} key={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-6">
          <label for="localityId">Locality</label>
          <Select
            className="w-00"
            id="localityId"
            key={selectedCity}
            value={selectedCity}
            isMulti
            onKeyDown={(event) => insertNewLocation(event)}
            options={cityList}
            onChange={(e) => handleChangeCity(e)}
          ></Select>
        </div>
        <div className="col-lg-12 col-md-4 col-sm-6 mt-3 text-right">
          {props.editMode === true ? (
            <>
              <button
                type="submit"
                class="btn btn-success mr-2"
                onClick={updateUser}
              >
                Update
              </button>
              <button
                type="submit"
                class="btn btn-secondary mr-2"
                onClick={closeFirstPopup}
              >
                Cancel
              </button>
            </>
          ) : (
            <>
              <button
                type="submit"
                class="btn btn-success mr-2"
                onClick={saveGroup}
              >
                Save
              </button>
              <button
                type="submit"
                class="btn btn-secondary mr-2"
                onClick={closeFirstPopup}
              >
                Cancel
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
