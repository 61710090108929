import React, { useEffect, useState } from 'react'
import { backendDashboardApi } from "../apiService";

function TopPerformnceCRE({ formattedDates, topPerformingCREList, stateZoneHeading }) {

    useEffect(() => {
        console.log("🚀 ~ TopPerformnceCRE ~ zoneId:", topPerformingCREList)
    }, []);


    return (
      
            <div className={window.sessionStorage.getItem("roleId") === "11" ? 'db-cards-30 db-card-margin-right' : 'db-cards-20'}>
                <div class="card db-height active_div">
                    <div class="card-body">
                        <div className='db-cards-top  all-region-head'>
                            <h5 class="card-title card-title-small mb-0">Top Performing CRE’s
                            </h5>
                            {/* <span className='db-cad-top-units-blue'>{formattedDates[0] === formattedDates[1] ? formattedDates[0] : formattedDates[0] + "-" + formattedDates[1]}</span> */}
                        </div>
                        {/* <h6 class="card-subtitle mb-4">All Regions</h6> */}
                        <h6 class="card-subtitle mb-4 mt-1 d-flex align-items-center flex-wrap">{stateZoneHeading} <span className='db-cad-top-units-blue ml-auto'>{formattedDates[0] === formattedDates[1] ? formattedDates[0] : formattedDates[0] + "-" + formattedDates[1]}</span></h6>
                        {topPerformingCREList.map((data) => (
                            <div className="d-flex all-region-div">
                                <div className='db-sub-models-width db-names'>
                                    {data.SalesPerson}
                                </div>
                                <div className='db-sub-models-width text-right'>
                                    {data.saleCount}
                                </div>
                            </div>))}
                    </div>
                </div>
            </div>

    )
}

export default TopPerformnceCRE;