import React, { useState, useEffect, useRef } from 'react'
import { backendDashboardApi } from "../apiService";

const ReportInvoice = ({ selectedStartDate, areaOffice, invoiceStatus, finalisedData, handleIncrementApiCount , onDataReceived}) => {

    // Google
    const [performanceReportList, setPerformanceReportList] = useState([])
    const [totalAmountSpent, setTotalAmountSpent] = useState([])

    const [performanceReportListPV, setPerformanceReportListPV] = useState([])
    const [totalAmountSpentPV, setTotalAmountSpentPV] = useState([])

    const [performanceReportListSCV, setPerformanceReportListSCV] = useState([])
    const [totalAmountSpentSCV, setTotalAmountSpentSCV] = useState([])

    // Meta
    const [performanceReportListMeta, setPerformanceReportListMeta] = useState([])
    const [totalAmountSpentMeta, setTotalAmountSpentMeta] = useState([])

    const [performanceReportListMetaPV, setPerformanceReportListMetaPV] = useState([])
    const [totalAmountSpentMetaPV, setTotalAmountSpentMetaPV] = useState([])

    const [performanceReportListMetaSCV, setPerformanceReportListMetaSCV] = useState([])
    const [totalAmountSpentMetaSCV, setTotalAmountSpentMetaSCV] = useState([])

    let formattedStartDate = new Date(selectedStartDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
    });

    useEffect(() => {
        debugger
        if (finalisedData == null && invoiceStatus !== "Finalized") {
        handlePerformanceReportListGoogleCV();
        handlePerformanceReportListMetaCV();
        handlePerformanceReportListGooglePV();
        handlePerformanceReportListMetaPV();
        handlePerformanceReportListGoogleSCV();
        handlePerformanceReportListMetaSCV();
        }
        else{
            console.log("finalised147", finalisedData)
            if (finalisedData) {
                //googleCv
                setPerformanceReportList(finalisedData.googleCv.perfomanceDetails)
                setTotalAmountSpent(finalisedData.googleCv);
                //googlePv
                setPerformanceReportListPV(finalisedData.googlePv.perfomanceDetails)
                setTotalAmountSpentPV(finalisedData.googlePv);
                //googleScv
                setPerformanceReportListSCV(finalisedData.googleScv.perfomanceDetails)
                setTotalAmountSpentSCV(finalisedData.googleScv);
                //metaCv
                setPerformanceReportListMeta(finalisedData.metaCv.perfomanceDetails)
                setTotalAmountSpentMeta(finalisedData.metaCv)
                //metaPv
                setPerformanceReportListMetaPV(finalisedData.metaPv.perfomanceDetails)
                setTotalAmountSpentMetaPV(finalisedData.metaPv)
                //metaScv
                setPerformanceReportListMetaSCV(finalisedData.metaScv.perfomanceDetails)
                setTotalAmountSpentMetaSCV(finalisedData.metaScv)
            }
        }

    }, [finalisedData]);

    // Google

    const handlePerformanceReportListGoogleCV = () => {

        const postData = {
            "functionName": "typeWisePerformanceReport",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "date": formattedStartDate,
            "userId": window.sessionStorage.getItem("userId"),
            "typeId": "CV",
            "areaOffice": areaOffice,
            "max": "",
            "offset": "0",
            "platform": "Google"
        }
        backendDashboardApi(postData).then((data) => {
            console.log("🚀 ~ backendDashboardApi ~ data handlePerformanceReportList:", data)
            if (data.success === "1") {
                onDataReceived("googleCv",data.result)
                setPerformanceReportList(data.result.perfomanceDetails)
                setTotalAmountSpent(data.result);
                handleIncrementApiCount();
            } else {
                setPerformanceReportList([])
            }
        });
    }


    const handlePerformanceReportListGooglePV = () => {

        const postData = {
            "functionName": "typeWisePerformanceReport",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "date": formattedStartDate,
            "userId": window.sessionStorage.getItem("userId"),
            "typeId": "PV",
            "areaOffice": areaOffice,
            "max": "",
            "offset": "0",
            "platform": "Google"
        }
        backendDashboardApi(postData).then((data) => {
            console.log("🚀 ~ backendDashboardApi ~ data handlePerformanceReportList:", data)
            if (data.success === "1") {
                onDataReceived("googlePv",data.result)
                setPerformanceReportListPV(data.result.perfomanceDetails)
                setTotalAmountSpentPV(data.result);
                handleIncrementApiCount();
            } else {
                setPerformanceReportListPV([])
            }
        });
    }

    const handlePerformanceReportListGoogleSCV = () => {

        const postData = {
            "functionName": "typeWisePerformanceReport",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "date": formattedStartDate,
            "userId": window.sessionStorage.getItem("userId"),
            "typeId": "SCV",
            "areaOffice": areaOffice,
            "max": "",
            "offset": "0",
            "platform": "Google"
        }
        backendDashboardApi(postData).then((data) => {
            console.log("🚀 ~ backendDashboardApi ~ data handlePerformanceReportList:", data)
            if (data.success === "1") {
                onDataReceived("googleScv",data.result)
                setPerformanceReportListSCV(data.result.perfomanceDetails)
                setTotalAmountSpentSCV(data.result);
                handleIncrementApiCount();
            } else {
                setPerformanceReportListSCV([])
            }
        });
    }


    // Meta

    const handlePerformanceReportListMetaCV = () => {
        const postData = {
            "functionName": "typeWisePerformanceReport",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "date": formattedStartDate,
            "userId": window.sessionStorage.getItem("userId"),
            "typeId": "CV",
            "areaOffice": areaOffice,
            "max": "",
            "offset": "0",
            "platform": "Meta"
        }
        backendDashboardApi(postData).then((data) => {
            console.log("🚀 ~ backendDashboardApi ~ data handlePerformanceReportList:", data)
            if (data.success === "1") {
                onDataReceived("metaCv",data.result)
                setPerformanceReportListMeta(data.result.perfomanceDetails)
                setTotalAmountSpentMeta(data.result)
                handleIncrementApiCount();
            } else {
                setPerformanceReportListMeta([])
            }
        });
    }


    const handlePerformanceReportListMetaPV = () => {
        const postData = {
            "functionName": "typeWisePerformanceReport",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "date": formattedStartDate,
            "userId": window.sessionStorage.getItem("userId"),
            "typeId": "PV",
            "areaOffice": areaOffice,
            "max": "",
            "offset": "0",
            "platform": "Meta"
        }
        backendDashboardApi(postData).then((data) => {
            console.log("🚀 ~ backendDashboardApi ~ data handlePerformanceReportList:", data)
            if (data.success === "1") {
                onDataReceived("metaPv",data.result)
                setPerformanceReportListMetaPV(data.result.perfomanceDetails)
                setTotalAmountSpentMetaPV(data.result)
                handleIncrementApiCount();
            } else {
                setPerformanceReportListMetaPV([])
            }
        });
    }


    const handlePerformanceReportListMetaSCV = () => {
        const postData = {
            "functionName": "typeWisePerformanceReport",
            "accountId": window.sessionStorage.getItem("switchingOrgId"),
            "date": formattedStartDate,
            "userId": window.sessionStorage.getItem("userId"),
            "typeId": "SCV",
            "areaOffice": areaOffice,
            "max": "",
            "offset": "0",
            "platform": "Meta"
        }
        backendDashboardApi(postData).then((data) => {
            console.log("🚀 ~ backendDashboardApi ~ data handlePerformanceReportList:", data)
            if (data.success === "1") {
                onDataReceived("metaScv",data.result)
                setPerformanceReportListMetaSCV(data.result.perfomanceDetails)
                setTotalAmountSpentMetaSCV(data.result)
                handleIncrementApiCount();
            } else {
                setPerformanceReportListSCV([])
            }
        });
    }


    const container = { width: "711px", margin: "0px auto", padding: "5px" };
    const page = { width: "711px", padding: 0, margin: "0 auto", background: "white" };
    const colContent = { fontSize: "8px", margin: "2px 0px 0px", padding: "5px" };
    const col1 = { width: "711px", float: "left", textAlign: "center" };
    const tableStr = { width: "711px", float: "left", display: "flex", fontSize: "8px", fontWeight: "600", borderBottom: "2px solid rgb(178 199 233)", border: "1px solid #000", borderRadius: "0px", background: "#d9e7fd", borderBottom: "none" };
    const tableStrBdy = { width: "711px", float: "left", display: "flex", fontSize: "8px", fontWeight: "600", borderBottom: "2px solid rgb(178 199 233)", border: "1px solid #000", borderRadius: "0px", borderTop: "none" };
    const tblHead = { fontSize: "8px", width: "62px", textAlign: "left", border: "none", borderRight: "1px solid #000", padding: "5px 3px", textAlign: "center", verticalAlign: "middle", borderRadius: "0px", margin: "0px", fontWeight: "normal" };
    const tblHeadDelrName = { fontSize: "8px", width: "62px", textAlign: "left", border: "none", borderRight: "1px solid #000", padding: "5px 3px", textAlign: "center", verticalAlign: "middle", borderRadius: "0px", margin: "0px", fontWeight: "normal" }
    const tblHeadReach = { fontSize: "8px", width: "62px", textAlign: "left", border: "none", borderRight: "1px solid #000", padding: "5px 3px", textAlign: "center", verticalAlign: "middle", borderRadius: "0px", margin: "0px", fontWeight: "normal" }
    const tblHeadLeftAlign = { fontSize: "8px", width: "62px", textAlign: "left", border: "none", borderRight: "1px solid #000", padding: "5px 3px", verticalAlign: "middle", borderRadius: "0px", margin: "0px", fontWeight: "normal" };
    const tblHeadRightAlign = { fontSize: "8px", width: "62px", textAlign: "right", border: "none", borderRight: "1px solid #000", padding: "5px 3px", verticalAlign: "middle", borderRadius: "0px", margin: "0px", fontWeight: "normal" };
    const tblHeadImp = { fontSize: "8px", width: "62px", textAlign: "left", border: "none", borderRight: "1px solid #000", padding: "5px 3px", textAlign: "center", verticalAlign: "middle", borderRadius: "0px", margin: "0px", fontWeight: "normal" };
    const tblHeadImpRight = { fontSize: "8px", width: "62px", textAlign: "right", border: "none", borderRight: "1px solid #000", padding: "5px 3px", verticalAlign: "middle", borderRadius: "0px", margin: "0px", fontWeight: "normal" };
    const tblHeadLast = { fontSize: "8px", width: "60px", textAlign: "left", border: "none", padding: "5px 3px", textAlign: "center", verticalAlign: "middle", borderRadius: "0px", margin: "0px", fontWeight: "normal" };
    const tblHeadLastRight = { fontSize: "8px", width: "60px", textAlign: "right", border: "none", padding: "5px 3px", verticalAlign: "middle", borderRadius: "0px", margin: "0px", fontWeight: "normal" };
    const Total = { width: "720px", marginBottom: "0px", fontSize: "8px", textAlign: "right", fontWeight: "bolder" };
    const TotalAmnt = { width: "200px", marginBottom: "0px", fontSize: "8px", textAlign: "center", fontWeight: "bolder" };
    const TotalInfo = { width: "711px", margin: "0px auto", padding: "15px 0", display: "flex" };

    return (
        <div id="container" class="container" style={container}>
            {/* Google Invoice  CV*/}

            {performanceReportList.length > 0 ?
                <div class="invoice-page page" style={page}>
                    <div class="subpage" style={{ clear: "both" }}>
                        <div style={col1}>
                            <p style={colContent}><b>{areaOffice} AO CV Performance report - {formattedStartDate} (Google)</b></p>
                        </div>
                        <br />
                        <div className="table1">
                            <div style={{ padding: "0px" }}>
                                <div style={tableStr}>
                                    <p style={tblHead}><b>Area Office</b></p>
                                    <p style={tblHeadDelrName}><b>Dealer Name</b></p>
                                    <p style={tblHeadDelrName}><b>Location</b></p>
                                    <p style={tblHead}><b>Segment</b> </p>
                                    <p style={tblHead}><b>Objective</b></p>

                                    <p style={tblHead}><b>Vehicle</b></p>
                                    <p style={tblHeadReach}><b>Results</b></p>
                                    <p style={tblHeadReach}><b>Reach</b></p>
                                    <p style={tblHeadImp}><b>Impressions</b> </p>
                                    <p style={tblHead}><b>Cost per results</b></p>
                                    <p style={tblHead}><b>Amount spent (INR)</b> </p>
                                    <p style={tblHeadLast}><b>Link clicks</b></p>
                                </div>
                            </div>
                            <div style={{ borderRadius: "0px", boxShadow: "none", margin: "0px", padding: "0px" }}>
                                {performanceReportList && performanceReportList.map((data) => (
                                    <div style={tableStrBdy}>
                                        <p style={tblHeadDelrName}>{data.area_office}</p>
                                        <p style={tblHeadDelrName}>{data.dealerName} </p>
                                        <p style={tblHeadLeftAlign}>{data.location}</p>
                                        <p style={tblHeadLeftAlign}>{data.segment} </p>
                                        <p style={tblHeadLeftAlign}>{data.adType}</p>

                                        <p style={tblHeadLeftAlign}>{data.productName}</p>
                                        <p style={tblHeadRightAlign}>{data.results}</p>
                                        <p style={tblHeadRightAlign}>{data.reach.toLocaleString('en-IN')}</p>
                                        <p style={tblHeadImpRight}>{data.impression.toLocaleString('en-IN')}</p>
                                        <p style={tblHeadReach}>{data.cost_per_results.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                                        <p style={tblHeadRightAlign}>{data.amount_spent.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })} </p>
                                        <p style={tblHeadLastRight}>{data.link_clicks.toLocaleString('en-IN')}</p>
                                    </div>
                                ))}

                            </div>
                            <div style={TotalInfo}>
                                <p style={Total}>TOTAL</p>
                                <p style={TotalAmnt}>{totalAmountSpent.grandTotalAmountSpent.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                            </div>



                        </div>
                    </div>
                </div>
                : ""}
            <br />
            {/* Google PV */}

            {performanceReportListPV.length > 0 ?
                <div class="invoice-page page" style={page}>
                    <div class="subpage" style={{ clear: "both" }}>
                        <div style={col1}>
                            <p style={colContent}><b>{areaOffice} AO PV Performance report - {formattedStartDate} (Google)</b></p>
                        </div>
                        <br />
                        <div className="table1">
                            <div style={{ padding: "0px" }}>
                                <div style={tableStr}>
                                    <p style={tblHead}><b>Area Office</b></p>
                                    <p style={tblHead}><b>Dealer Name</b></p>
                                    <p style={tblHead}><b>Location</b></p>
                                    <p style={tblHead}><b>Segment</b> </p>
                                    <p style={tblHead}><b>Objective</b></p>

                                    <p style={tblHead}><b>Vehicle</b></p>
                                    <p style={tblHead}><b>Results</b></p>
                                    <p style={tblHead}><b>Reach</b></p>
                                    <p style={tblHeadImp}><b>Impressions</b> </p>
                                    <p style={tblHead}><b>Cost per results</b></p>
                                    <p style={tblHead}><b>Amount spent (INR)</b> </p>
                                    <p style={tblHeadLast}><b>Link clicks</b></p>
                                </div>
                            </div>
                            <div style={{ borderRadius: "0px", boxShadow: "none", margin: "0px", padding: "0px" }}>
                                {performanceReportListPV && performanceReportListPV.map((data) => (
                                    <div style={tableStrBdy}>
                                        <p style={tblHeadLeftAlign}>{data.area_office}</p>
                                        <p style={tblHeadLeftAlign}>{data.dealerName} </p>
                                        <p style={tblHeadLeftAlign}>{data.location}</p>
                                        <p style={tblHeadLeftAlign}>{data.segment} </p>
                                        <p style={tblHeadLeftAlign}>{data.adType}</p>

                                        <p style={tblHeadLeftAlign}>{data.productName}</p>
                                        <p style={tblHeadRightAlign}>{data.results}</p>
                                        <p style={tblHeadRightAlign}>{data.reach.toLocaleString('en-IN')}</p>
                                        <p style={tblHeadImpRight}>{data.impression.toLocaleString('en-IN')}</p>
                                        <p style={tblHeadRightAlign}>{data.cost_per_results.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                                        <p style={tblHeadRightAlign}>{data.amount_spent.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })} </p>
                                        <p style={tblHeadLastRight}>{data.link_clicks.toLocaleString('en-IN')}</p>
                                    </div>
                                ))}

                            </div>
                            <div style={TotalInfo}>
                                <p style={Total}>TOTAL</p>
                                <p style={TotalAmnt}>{totalAmountSpentPV.grandTotalAmountSpent.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                                </p>
                            </div>



                        </div>
                    </div>
                </div>
                : ""}
            <br />

            {/* Google SCV */}

            {performanceReportListSCV.length > 0 ?
                <div class="invoice-page page" style={page}>
                    <div class="subpage" style={{ clear: "both" }}>
                        <div style={col1}>
                            <p style={colContent}><b>{areaOffice} AO SCV Performance report - {formattedStartDate} (Google)</b></p>
                        </div>
                        <br />
                        <div className="table1">
                            <div style={{ padding: "0px" }}>
                                <div style={tableStr}>
                                    <p style={tblHead}><b>Area Office</b></p>
                                    <p style={tblHead}><b>Dealer Name</b></p>
                                    <p style={tblHead}><b>Location</b></p>
                                    <p style={tblHead}><b>Segment</b> </p>
                                    <p style={tblHead}><b>Objective</b></p>

                                    <p style={tblHead}><b>Vehicle</b></p>
                                    <p style={tblHead}><b>Results</b></p>
                                    <p style={tblHead}><b>Reach</b></p>
                                    <p style={tblHeadImp}><b>Impressions</b> </p>
                                    <p style={tblHead}><b>Cost per results</b></p>
                                    <p style={tblHead}><b>Amount spent (INR)</b> </p>
                                    <p style={tblHeadLast}><b>Link clicks</b></p>
                                </div>
                            </div>
                            <div style={{ borderRadius: "0px", boxShadow: "none", margin: "0px", padding: "0px" }}>
                                {performanceReportListSCV && performanceReportListSCV.map((data) => (
                                    <div style={tableStrBdy}>
                                        <p style={tblHeadLeftAlign}>{data.area_office}</p>
                                        <p style={tblHeadLeftAlign}>{data.dealerName} </p>
                                        <p style={tblHeadLeftAlign}>{data.location}</p>
                                        <p style={tblHeadLeftAlign}>{data.segment} </p>
                                        <p style={tblHeadLeftAlign}>{data.adType}</p>

                                        <p style={tblHeadLeftAlign}>{data.productName}</p>
                                        <p style={tblHeadRightAlign}>{data.results}</p>
                                        <p style={tblHeadRightAlign}>{data.reach.toLocaleString('en-IN')}</p>
                                        <p style={tblHeadImpRight}>{data.impression.toLocaleString('en-IN')}</p>
                                        <p style={tblHeadRightAlign}>{data.cost_per_results.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                                        <p style={tblHeadRightAlign}>{data.amount_spent.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })} </p>
                                        <p style={tblHeadLastRight}>{data.link_clicks.toLocaleString('en-IN')}</p>
                                    </div>
                                ))}

                            </div>
                            <div style={TotalInfo}>
                                <p style={Total}>TOTAL</p>
                                <p style={TotalAmnt}>{totalAmountSpentSCV.grandTotalAmountSpent.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                                </p>
                            </div>



                        </div>
                    </div>
                </div>
                : ""}
            <br />

            {/* Meta Invoice CV*/}
            {performanceReportListMeta.length > 0 ?
                <div class="invoice-page page" style={page}>
                    <div class="subpage" style={{ clear: "both" }}>
                        <div style={col1}>
                            <p style={colContent}><b>{areaOffice} AO CV Performance report - {formattedStartDate} (Meta)</b></p>
                        </div>
                        <br />
                        <div className="table1">
                            <div style={{ padding: "0px" }}>
                                <div style={tableStr}>
                                    <p style={tblHead}><b>Area Office</b></p>
                                    <p style={tblHead}><b>Dealer Name</b></p>
                                    <p style={tblHead}><b>Location</b></p>
                                    <p style={tblHead}><b>Segment</b> </p>
                                    <p style={tblHead}><b>Objective</b></p>

                                    <p style={tblHead}><b>Vehicle</b></p>
                                    <p style={tblHead}><b>Results</b></p>
                                    <p style={tblHead}><b>Reach</b></p>
                                    <p style={tblHeadImp}><b>Impressions</b> </p>
                                    <p style={tblHead}><b>Cost per results</b></p>
                                    <p style={tblHead}><b>Amount spent (INR)</b> </p>
                                    <p style={tblHeadLast}><b>Link clicks</b></p>
                                </div>
                            </div>
                            <div style={{ borderRadius: "0px", boxShadow: "none", margin: "0px", padding: "0px" }}>
                                {performanceReportListMeta && performanceReportListMeta.map((data) => (
                                    <div style={tableStrBdy}>
                                        <p style={tblHeadLeftAlign}>{data.area_office}</p>
                                        <p style={tblHeadLeftAlign}>{data.dealerName} </p>
                                        <p style={tblHeadLeftAlign}>{data.location}</p>
                                        <p style={tblHeadLeftAlign}>{data.segment} </p>
                                        <p style={tblHeadLeftAlign}>{data.adType}</p>

                                        <p style={tblHeadLeftAlign}>{data.productName}</p>
                                        <p style={tblHeadRightAlign}>{data.results}</p>
                                        <p style={tblHeadRightAlign}>{data.reach.toLocaleString('en-IN')}</p>
                                        <p style={tblHeadImpRight}>{data.impression.toLocaleString('en-IN')}</p>
                                        <p style={tblHeadRightAlign}>{data.cost_per_results.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                                        <p style={tblHeadRightAlign}>{data.amount_spent.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                                        <p style={tblHeadLastRight}>{data.link_clicks.toLocaleString('en-IN')}</p>
                                    </div>
                                ))}

                            </div>
                            <div style={TotalInfo}>
                                <p style={Total}>TOTAL</p>
                                <p style={TotalAmnt}>{totalAmountSpentMeta.grandTotalAmountSpent.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                            </div>



                        </div>
                    </div>
                </div>
                : ""}
            <br />
            {/* Meta Invoice PV */}
            {performanceReportListMetaPV.length > 0 ?
                <div class="invoice-page page" style={page}>
                    <div class="subpage" style={{ clear: "both" }}>
                        <div style={col1}>
                            <p style={colContent}><b>{areaOffice} AO PV Performance report - {formattedStartDate} (Meta)</b></p>
                        </div>
                        <br />
                        <div className="table1">
                            <div style={{ padding: "0px" }}>
                                <div style={tableStr}>
                                    <p style={tblHead}><b>Area Office</b></p>
                                    <p style={tblHead}><b>Dealer Name</b></p>
                                    <p style={tblHead}><b>Location</b></p>
                                    <p style={tblHead}><b>Segment</b> </p>
                                    <p style={tblHead}><b>Objective</b></p>

                                    <p style={tblHead}><b>Vehicle</b></p>
                                    <p style={tblHead}><b>Results</b></p>
                                    <p style={tblHead}><b>Reach</b></p>
                                    <p style={tblHeadImp}><b>Impressions</b> </p>
                                    <p style={tblHead}><b>Cost per results</b></p>
                                    <p style={tblHead}><b>Amount spent (INR)</b> </p>
                                    <p style={tblHeadLast}><b>Link clicks</b></p>
                                </div>
                            </div>
                            <div style={{ borderRadius: "0px", boxShadow: "none", margin: "0px", padding: "0px" }}>
                                {performanceReportListMetaPV && performanceReportListMetaPV.map((data) => (
                                    <div style={tableStrBdy}>
                                        <p style={tblHeadLeftAlign}>{data.area_office}</p>
                                        <p style={tblHeadLeftAlign}>{data.dealerName} </p>
                                        <p style={tblHeadLeftAlign}>{data.location}</p>
                                        <p style={tblHeadLeftAlign}>{data.segment} </p>
                                        <p style={tblHeadLeftAlign}>{data.adType}</p>

                                        <p style={tblHeadLeftAlign}>{data.productName}</p>
                                        <p style={tblHeadRightAlign}>{data.results}</p>
                                        <p style={tblHeadRightAlign}>{data.reach.toLocaleString('en-IN')}</p>
                                        <p style={tblHeadImpRight}>{data.impression.toLocaleString('en-IN')}</p>
                                        <p style={tblHeadRightAlign}>{data.cost_per_results.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                                        </p>
                                        <p style={tblHeadRightAlign}>{data.amount_spent.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                                        </p>
                                        <p style={tblHeadLastRight}>{data.link_clicks.toLocaleString('en-IN')} </p>
                                    </div>
                                ))}

                            </div>
                            <div style={TotalInfo}>
                                <p style={Total}>TOTAL</p>
                                <p style={TotalAmnt}>{totalAmountSpentMetaPV.grandTotalAmountSpent.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                            </div>



                        </div>
                    </div>
                </div>
                : ""}
            <br />
            {/* Meta Invoice SCV */}
            {performanceReportListMetaSCV.length > 0 ?
                <div class="invoice-page page" style={page}>
                    <div class="subpage" style={{ clear: "both" }}>
                        <div style={col1}>
                            <p style={colContent}><b>{areaOffice} AO SCV Performance report - {formattedStartDate} (Meta)</b></p>
                        </div>
                        <br />
                        <div className="table1">
                            <div style={{ padding: "0px" }}>
                                <div style={tableStr}>
                                    <p style={tblHead}><b>Area Office</b></p>
                                    <p style={tblHead}><b>Dealer Name</b></p>
                                    <p style={tblHead}><b>Location</b></p>
                                    <p style={tblHead}><b>Segment</b> </p>
                                    <p style={tblHead}><b>Objective</b></p>

                                    <p style={tblHead}><b>Vehicle</b></p>
                                    <p style={tblHead}><b>Results</b></p>
                                    <p style={tblHead}><b>Reach</b></p>
                                    <p style={tblHeadImp}><b>Impressions</b> </p>
                                    <p style={tblHead}><b>Cost per results</b></p>
                                    <p style={tblHead}><b>Amount spent (INR)</b> </p>
                                    <p style={tblHeadLast}><b>Link clicks</b></p>
                                </div>
                            </div>
                            <div style={{ borderRadius: "0px", boxShadow: "none", margin: "0px", padding: "0px" }}>
                                {performanceReportListMetaSCV && performanceReportListMetaSCV.map((data) => (
                                    <div style={tableStrBdy}>
                                        <p style={tblHeadLeftAlign}>{data.area_office}</p>
                                        <p style={tblHeadLeftAlign}>{data.dealerName} </p>
                                        <p style={tblHeadLeftAlign}>{data.location}</p>
                                        <p style={tblHeadLeftAlign}>{data.segment} </p>
                                        <p style={tblHeadLeftAlign}>{data.adType}</p>

                                        <p style={tblHeadLeftAlign}>{data.productName}</p>
                                        <p style={tblHeadRightAlign}>{data.results}</p>
                                        <p style={tblHeadRightAlign}>{data.reach.toLocaleString('en-IN')}</p>
                                        <p style={tblHeadImpRight}>{data.impression.toLocaleString('en-IN')}</p>
                                        <p style={tblHeadRightAlign}>{data.cost_per_results.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                                        </p>
                                        <p style={tblHeadRightAlign}>{data.amount_spent.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                                        </p>
                                        <p style={tblHeadLastRight}>{data.link_clicks.toLocaleString('en-IN')} </p>
                                    </div>
                                ))}

                            </div>
                            <div style={TotalInfo}>
                                <p style={Total}>TOTAL</p>
                                <p style={TotalAmnt}>{totalAmountSpentMetaSCV.grandTotalAmountSpent.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</p>
                            </div>



                        </div>
                    </div>
                </div>
                : ""}
            <br />
        </div>

    );
};

export default ReportInvoice;