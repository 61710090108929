import React, { Component } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker,InfoWindow } from "react-google-maps";

import { compose, withProps } from 'recompose';

// let markers=[
//     {
//       shelter: "Maharashtra-Mumbai",
//       latitude: "19.0591603",
//       longitude: "72.8528765",
//       id: "14649306580257912734"

//     },
//     {
//       shelter:"Telangana-Hyderabad",
// latitude:"17.3600783",
// longitude:"78.5073457",
// id:"1953213905454131304"

//     },
// ]
    // let markers = [];
    // markers= this.state.lagLongData  
   

const MapWithAMarkerClusterer = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyAkJ_3Ghme-T6sLIAQIrZmYD-iMt3pTqEU&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)(props =>
    <GoogleMap
        defaultZoom={4}
        defaultCenter={{ lat: parseFloat(props.lagLongData[0].latitude), lng:parseFloat(props.lagLongData[0].longitude) }}
    >
        {props.lagLongData.map(marker => {
             return (
                <Marker
                // onClick={() => this.handleToggleOpen()}
                    key={parseFloat(marker.id)}
                   
                    position={{ 
                      lat: marker.latitude!==null?parseFloat(marker.latitude):parseFloat(marker[0].latitude), 
                      lng: marker.longitude!==null?parseFloat(marker.longitude): parseFloat(marker[0].longitude) }}
                >
                   
                    {/* <InfoWindow>
                        <div>
                            {marker.shelter}
                        </div>
                    </InfoWindow> */}
                </Marker>
            )
        })}
    </GoogleMap>
);

class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    
    lagLongData :this.props.getGraphlatitude,
   
  };
  {console.log("markers",this.state.lagLongData)}
}


    render() {
        return (
          <div className="gmap-div">
          <div id="googleMap">
            <div className="adrsmap-hspadmin">
            <MapWithAMarkerClusterer lagLongData={this.state.lagLongData}/>
            </div>
            </div>
            </div>
        )
    }
}
export default MapContainer;

